import request from '@/utils/request'

// 添加资源空间文件
export function addIotSpaceResource(data) {
    return request({
      url: '/iot/spaceresource/add',
      method: 'post',
      data: data
    })
}

// 获取资源空间文件
export function getIotSpaceResource(id) {
    return request({
      url: '/iot/spaceresource/get?id=' + id,
      method: 'get'
    })
}

// 更新资源空间文件
export function updateIotSpaceResource(data) {
    return request({
      url: '/iot/spaceresource/update',
      method: 'post',
      data: data
    })
  }
  
// 删除资源空间文件
export function delIotSpaceResource(id) {
    return request({
      url: '/iot/spaceresource/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 资源空间文件列表
export function listIotSpaceResource(query) {
  return request({
    url: '/iot/spaceresource/list',
    method: 'get',
    params: query
  })
}
