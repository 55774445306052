export default{
  "widgetList": [
    {
      "type": "static-text",
      "icon": "static-text",
      "formItemFlag": false,
      "options": {
        "name": "statictext111193",
        "columnWidth": "200px",
        "hidden": false,
        "textContent": "响应式表单",
        "customClass": [],
        "onCreated": "",
        "onMounted": "",
        "label": "static-text"
      },
      "id": "statictext111193"
    },
    {
      "type": "divider",
      "icon": "divider",
      "formItemFlag": false,
      "options": {
        "name": "divider102346",
        "label": "",
        "columnWidth": "200px",
        "direction": "horizontal",
        "contentPosition": "center",
        "hidden": false,
        "customClass": [],
        "onCreated": "",
        "onMounted": ""
      },
      "id": "divider102346"
    },
    {
      "type": "card",
      "category": "container",
      "icon": "card",
      "widgetList": [
        {
          "type": "grid",
          "category": "container",
          "icon": "grid",
          "cols": [
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "input",
                  "icon": "text-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "input53759",
                    "label": "input",
                    "labelAlign": "",
                    "type": "text",
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "showPassword": false,
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "prefixIcon": "",
                    "suffixIcon": "",
                    "appendButton": false,
                    "appendButtonDisabled": false,
                    "buttonIcon": "el-icon-search",
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "input53759"
                }
              ],
              "options": {
                "name": "gridCol23424",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-23424"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "switch",
                  "icon": "switch-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "switch25409",
                    "label": "switch",
                    "labelAlign": "",
                    "defaultValue": false,
                    "columnWidth": "200px",
                    "labelWidth": null,
                    "labelHidden": false,
                    "disabled": false,
                    "hidden": false,
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "switchWidth": 40,
                    "activeText": "",
                    "inactiveText": "",
                    "activeColor": null,
                    "inactiveColor": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onValidate": ""
                  },
                  "id": "switch25409"
                }
              ],
              "options": {
                "name": "gridCol72605",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-72605"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "rate",
                  "icon": "rate-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "rate39397",
                    "label": "rate",
                    "labelAlign": "",
                    "defaultValue": 0,
                    "columnWidth": "200px",
                    "labelWidth": null,
                    "labelHidden": false,
                    "disabled": false,
                    "hidden": false,
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "max": 5,
                    "lowThreshold": 2,
                    "highThreshold": 4,
                    "allowHalf": false,
                    "showText": false,
                    "showScore": false,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onValidate": ""
                  },
                  "id": "rate39397"
                }
              ],
              "options": {
                "name": "gridCol93439",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-93439"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "number",
                  "icon": "number-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "number38618",
                    "label": "number",
                    "labelAlign": "",
                    "defaultValue": 0,
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "disabled": false,
                    "hidden": false,
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "min": -100000000000,
                    "max": 100000000000,
                    "precision": 0,
                    "step": 1,
                    "controlsPosition": "right",
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "number38618"
                }
              ],
              "options": {
                "name": "gridCol67236",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-67236"
            }
          ],
          "options": {
            "name": "grid121983",
            "hidden": false,
            "gutter": 12,
            "customClass": ""
          },
          "id": "grid121983"
        }
      ],
      "options": {
        "name": "card28639",
        "label": "PC一行显示为3栏",
        "hidden": false,
        "folded": false,
        "showFold": false,
        "cardWidth": "100%",
        "shadow": "never",
        "customClass": ""
      },
      "id": "card28639"
    },
    {
      "type": "divider",
      "icon": "divider",
      "formItemFlag": false,
      "options": {
        "name": "divider74438",
        "label": "",
        "columnWidth": "200px",
        "direction": "horizontal",
        "contentPosition": "center",
        "hidden": false,
        "customClass": "",
        "onCreated": "",
        "onMounted": ""
      },
      "id": "divider74438"
    },
    {
      "type": "card",
      "category": "container",
      "icon": "card",
      "widgetList": [
        {
          "type": "grid",
          "category": "container",
          "icon": "grid",
          "cols": [
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "select",
                  "icon": "select-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "select67535",
                    "label": "select",
                    "labelAlign": "",
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "filterable": false,
                    "allowCreate": false,
                    "remote": false,
                    "automaticDropdown": false,
                    "multiple": false,
                    "multipleLimit": 0,
                    "optionItems": [
                      {
                        "label": "select 1",
                        "value": 1
                      },
                      {
                        "label": "select 2",
                        "value": 2
                      },
                      {
                        "label": "select 3",
                        "value": 3
                      }
                    ],
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onRemoteQuery": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "select67535"
                }
              ],
              "options": {
                "name": "gridCol62883",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-62883"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "number",
                  "icon": "number-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "number23364",
                    "label": "number",
                    "labelAlign": "",
                    "defaultValue": 0,
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "disabled": false,
                    "hidden": false,
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "min": -100000000000,
                    "max": 100000000000,
                    "precision": 0,
                    "step": 1,
                    "controlsPosition": "right",
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "number23364"
                }
              ],
              "options": {
                "name": "gridCol19529",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-19529"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "date",
                  "icon": "date-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "date57792",
                    "label": "date",
                    "labelAlign": "",
                    "type": "date",
                    "defaultValue": null,
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "editable": false,
                    "format": "yyyy-MM-dd",
                    "valueFormat": "yyyy-MM-dd",
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "date57792"
                }
              ],
              "options": {
                "name": "gridCol117567",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-117567"
            }
          ],
          "options": {
            "name": "grid89232",
            "hidden": false,
            "gutter": 12,
            "customClass": ""
          },
          "id": "grid89232"
        }
      ],
      "options": {
        "name": "card77922",
        "label": "Pad一行显示为2栏",
        "hidden": false,
        "folded": false,
        "showFold": false,
        "cardWidth": "100%",
        "shadow": "never",
        "customClass": ""
      },
      "id": "card77922"
    },
    {
      "type": "divider",
      "icon": "divider",
      "formItemFlag": false,
      "options": {
        "name": "divider34525",
        "label": "",
        "columnWidth": "200px",
        "direction": "horizontal",
        "contentPosition": "center",
        "hidden": false,
        "customClass": "",
        "onCreated": "",
        "onMounted": ""
      },
      "id": "divider34525"
    },
    {
      "type": "card",
      "category": "container",
      "icon": "card",
      "widgetList": [
        {
          "type": "grid",
          "category": "container",
          "icon": "grid",
          "cols": [
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "time",
                  "icon": "time-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "time82855",
                    "label": "time",
                    "labelAlign": "",
                    "defaultValue": null,
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "editable": false,
                    "format": "HH:mm:ss",
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "time82855"
                }
              ],
              "options": {
                "name": "gridCol19026",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-19026"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "cascader",
                  "icon": "cascader-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "cascader66483",
                    "label": "cascader",
                    "defaultValue": "",
                    "placeholder": "",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "filterable": false,
                    "optionItems": [
                      {
                        "label": "select 1",
                        "value": 1,
                        "children": [
                          {
                            "label": "child 1",
                            "value": 11
                          }
                        ]
                      },
                      {
                        "label": "select 2",
                        "value": 2
                      },
                      {
                        "label": "select 3",
                        "value": 3
                      }
                    ],
                    "required": false,
                    "requiredHint": "",
                    "customRule": "",
                    "customRuleHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "cascader66483"
                }
              ],
              "options": {
                "name": "gridCol16648",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-16648"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "input",
                  "icon": "text-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "input31771",
                    "label": "input",
                    "labelAlign": "",
                    "type": "text",
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "showPassword": false,
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "prefixIcon": "",
                    "suffixIcon": "",
                    "appendButton": false,
                    "appendButtonDisabled": false,
                    "buttonIcon": "el-icon-search",
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "input31771"
                }
              ],
              "options": {
                "name": "gridCol81013",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": true,
                "md": 8,
                "sm": 12,
                "xs": 24,
                "customClass": ""
              },
              "id": "grid-col-81013"
            }
          ],
          "options": {
            "name": "grid106590",
            "hidden": false,
            "gutter": 12,
            "customClass": ""
          },
          "id": "grid106590"
        }
      ],
      "options": {
        "name": "card68084",
        "label": "移动一行显示为1栏",
        "hidden": false,
        "folded": false,
        "showFold": false,
        "cardWidth": "100%",
        "shadow": "never",
        "customClass": ""
      },
      "id": "card68084"
    }
  ],
  "formConfig": {
    "modelName": "formData",
    "refName": "vForm",
    "rulesName": "rules",
    "labelWidth": 150,
    "labelPosition": "left",
    "size": "",
    "labelAlign": "label-right-align",
    "cssCode": "",
    "customClass": [],
    "functions": "",
    "layoutType": "PC",
    "onFormCreated": "",
    "onFormMounted": "",
    "onFormDataChange": ""
  }
}