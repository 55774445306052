<template>
  <div style="width: 100%; height: 100%;">
    <div style="width: 100%; height: 150px; background: url(/images/gd/bg.png) center top no-repeat; display: flex; justify-content: center;">
      <div style="width: 1000px;">
        <img src="/images/gd/gd.png" style="display: block;">
        <div style="height: 60px; display: flex; align-items: center; padding-left: 15px; color: rgb(255, 255, 255); font-size: 28px; font-weight: bold; font-family: 宋体;">广西干部教育培训桂林电子科技大学基地</div>
      </div>
    </div>
    <div
      style="
        display: flex;
        width: 100%;
        height: 65%;
        justify-content: center;
        padding-top: 20px;
      "
    >
      <el-card class="box-card" style="width: 960px; height: 500px;">
        <div
          style="
            margin-bottom: 30px;
            padding-bottom: 15px;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            border-bottom: solid 1px #eee;
          "
        >
          培训预约
        </div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人" prop="name">
                <el-input
                  v-model="form.name"
                  placeholder="请输入联系人"
                  clearable
                  :disabled="submitted"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="phone">
                <el-input
                  v-model="form.phone"
                  placeholder="请输入手机号"
                  clearable
                  :disabled="submitted"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="培训预约单位" prop="unit">
                <el-input
                  v-model="form.unit"
                  placeholder="请输入培训预约单位"
                  clearable
                  :disabled="submitted"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="form.remark"
                  placeholder="请输入备注"
                  clearable
                  type="textarea"
                  :disabled="submitted"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div style="display: flex; justify-content: center">
          <el-button type="primary" @click="submitForm" :disabled="submitted">提 交</el-button>
          <el-button @click="login" style="margin-left: 50px">登 录</el-button>
        </div>

      </el-card>
    </div>
    <footer class="el-footer" style="height: auto; background-color: rgb(0, 99, 187);">
      <div class="ZX" style="background-color: rgb(0, 99, 187);">
        <div class="ZX" style="color: rgb(115, 172, 222); font-size: 14px; line-height: 2em; height: 200px;">
          <p> 版权所有：2002-2019 桂林电子科技大学 </p>
          <p> 地址：广西桂林市 桂林电子科技大学 (541004) </p>
          <p> 联系电话：0773-2292296 传真：0773-2290076 </p>
          <p> 备案号：桂ICP备 05000961 号 </p>
          <p> 版权所有：2002-2019 桂林电子科技大学 </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      form: {
        name: undefined,
        phone: undefined,
        unit: undefined,
        remark: undefined,
      },
      rules: {
        name: [{ required: true, message: "联系人不能为空", trigger: "blur" }],
        phone: [{ required: true, message: "手机号不能为空", trigger: "blur" }],
        unit: [
          { required: true, message: "培训预约单位不能为空", trigger: "blur" },
        ]
      },
      submitted: false
    };
  },
  methods: {
    reset() {
      this.form = {
        name: undefined,
        phone: undefined,
        unit: undefined,
        remark: undefined,
      };
      this.$refs["form"].resetFields();
    },

    addGbpxReservation(data) {
      return request({
        url: "/gbpx/gbpxreservation/resv",
        method: "post",
        data: {
          ...data,
          date: (data.date && data.date / 1000) || 0,
        },
      });
    },

    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            background: "rgba(0, 0, 0, 0.2)",
          });
          this.addGbpxReservation(this.form)
            .then((response) => {
              loading.close();
              if (response.code === 0) {
                this.$notify({
                  title: "提示",
                  message: "您的培训预约已提交，请等待管理员审核",
                  type: "success",
                });
                this.submitted = true;
              } else {
                this.$notify({
                  title: "提示",
                  message: response.msg || "培训预约提交失败",
                  type: "error",
                });
              }
            })
            .catch((e) => {
              loading.close();
              this.$notify({
                title: "提示",
                message: "培训预约提交失败",
                type: "error",
              });
            });
        }
      });
    },
    login() {
      window.open("#/login");
    },
  },
};
</script>

<style scoped>
.ZX {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ZX p {
  margin: 0;
}
</style>