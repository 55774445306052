<template>
  <div class="app-flows">
    <!--流程数据-->
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="分类" prop="category_id" style="display: none">
        <el-input
          v-model="queryParams.category_id"
          placeholder="请输入分类ID"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入流程名称"
          clearable
          size="small"
          @clear="handleQuery"
          @input.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-collapse v-model="actives" v-if="!queryParams.name">
      <el-collapse-item title="我的常用" name="commonkey" v-if="commonList&&commonList.length>0">
        <div>
          <div v-for="(item, index) in commonList" :key="index" class="form-item" @click="startFlow(item)">
            <div>
              <template v-if="item.icon && item.icon!=''">
                <img  :src="item.icon"  style="width: 36px; height: 36px;margin: 0"/>
                <ellipsis hover-tip :content="item.name" style="margin-left: 5px"/>
              </template>
              <template v-else>
                <i   class="el-icon-menu" style="background: rgb(30, 144, 255);"></i>
                <ellipsis hover-tip :content="item.name"/>
              </template>
              <span>发起</span>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item v-for="key in flowMap.keys()" v-if="flowMap.get(key).length>0"
                        :title="key" :name="key">
        <div>
          <div v-for="(item, index) in flowMap.get(key)" :key="index" class="form-item" @click="startFlow(item)">
            <div>
              <template v-if="item.icon && item.icon!=''">
                <img  :src="item.icon"  style="width: 36px; height: 36px;margin: 0"/>
                <ellipsis hover-tip :content="item.name" style="margin-left: 5px"/>
              </template>
              <template v-else>
              <i   class="el-icon-menu" style="background: rgb(30, 144, 255);"></i>
              <ellipsis hover-tip :content="item.name"/>
              </template>
              <span>发起</span>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <template v-else>
      <div >
        <div v-for="(item, index) in flowList" :key="index" class="form-item" @click="startFlow(item)">
          <div>
            <template v-if="item.icon && item.icon!=''">
              <img  :src="item.icon"  style="width: 36px; height: 36px;margin: 0"/>
              <ellipsis hover-tip :content="item.name" style="margin-left: 5px"/>
            </template>
            <template v-else>
              <i   class="el-icon-menu" style="background: rgb(30, 144, 255);"></i>
              <ellipsis hover-tip :content="item.name"/>
            </template>
            <span>发起</span>
          </div>
        </div>
        <div v-if="queryParams.name && flowList.length === 0 && !loading ">
          <el-empty description="找不到相关记录" ></el-empty>
        </div>
      </div>
    </template>

    <div style="margin-bottom:50px"></div>
  </div>
</template>

<script>
import {listBpmFlow} from "@/api/bpm/flow";
import {listBpmCategory} from '@/api/bpm/category'
import Ellipsis from '@/components/Ellipsis'
export default {
  name: 'bpm_task_flow_list',
  components: {
    Ellipsis
  },
  data() {
    return {
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: 'name', title: '流程名称'},
        {field: 'flow_code', title: '流程编码'},
        {field: 'category_name', title: '分类'},
        {field: 'version', title: '版本'},
        {field: 'status', title: '状态'},
        {field: 'update_time', title: '更新时间', formatter: "formatDate"},
      ],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      actives: [],
      // 流程表格数据
      flowMap: new Map(),
      categoryList:[],
      flowList: [],
      commonList:[],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 9999,
        name: undefined,
        category_id: undefined,
        status: '1',
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        category_id: [
          {required: true, message: "分类不能为空", trigger: "blur"}
        ],
        name: [
          {required: true, message: "名称不能为空", trigger: "blur"}
        ]
      },
      category_name: '',
    };
  },
  async created() {
    let category = await listBpmCategory({
      pageNum: 1,
      pageSize: 9999,
    });
    this.categoryList = category.data.list
    this.getList();
  },
  methods: {
    /** 查询流程列表 */
    getList() {
      this.loading = true;
      this.$nextTick(()=>{
        this.flowList = []
        this.commonList = []
        this.flowMap = new Map()
        this.actives.push("commonkey")
        this.categoryList.forEach((item) => {
          this.flowMap.set(item.name, [])
          this.actives.push(item.name)
        })
      })

      listBpmFlow(this.queryParams).then(response => {
        let list = response.data.list
        this.commonList = response.data.commonlist
        list.forEach((item) => {
          if (this.flowMap.has(item.category_name)) {
            let list = this.flowMap.get(item.category_name)
            list.push(item)
          } else {
            this.flowMap.set(item.category_name, [item])
          }
        })
        this.flowList = response.data.list;
        this.total = response.data.total;
        this.loading = false;
        this.$forceUpdate()
      });


    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        name: undefined,
        category_id: undefined,
        status: undefined,
      };
      this.resetForm("form");
      this.handleQuery()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    startFlow(row) {
      this.$router.push({
        path: "/bpm/task/task",
        query: {
         /* resourceId: row.resource_id,*/
          /*time: this.XEUtils.now(),*/
          flowId: row.id,
          title: `发起流程【${row.name}】`
        }
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate(valid => {
        if (valid) {
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.app-flows {
  padding: 20px 20px 0;
  position: relative;
  /deep/ .el-collapse {
    padding: 0 15px;
    background: #ffffff;
    .el-collapse-item__header {
      font-size: medium;
    }
    .el-collapse-item__wrap {
      padding: 20px 10px;
    }
  }

  .form-item {
    padding: 15px 10px;
    width: 200px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #d9dada;
    border-radius: 5px;
    float: left;
    margin: 5px 10px;
    height:67px;
    &:hover {
       border: 1px solid #1890ff;
       span {
         display: inline-block !important;
       }
    }
    i {
      padding: 8px;
      border-radius: 8px;
      float: left;
      font-size: 20px;
      color: #ffffff;
      background: #38adff;
    }
    div {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      div{
        display: inline-block;
        margin-left: 10px;
        width: 100px;
      }
      span {
        display: none;
        float: right;
        color: #38adff;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .form-item {
    padding: 12px 10px !important;
    width: 150px !important;
    margin: 5px !important;
    &:hover {
      span:last-child {
        display: none !important;
      }
    }
  }
}
</style>
