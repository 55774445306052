import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'

class Embedding {
    // 查询embedding列表
    listEmbedding(query) {
        var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

        return request({
            url: '/ai/embedding/list',
            method: 'post',
            data: data
        })
    }

    // 查看embedding
    getEmbedding(id) {
        return request({
            url: '/ai/embedding/get?id=' + id,
            method: 'get'
        })
    }


    // 新增embedding
    addEmbedding(ctype,data) {
        var headers = {}

        if (ctype != "1") {
            headers = {
                'Content-Type': 'multipart/form-data'
            }
        }


        return request({
            url: '/ai/embedding/addtext',
            method: 'post',
            data: data,
            headers: headers
        })



    }

    // 修改embedding
    editEmbedding(mid, data) {
        var postdata = {
            formdata: JSON.stringify(data),
            mid: mid,
            dataid: data.id
        }

        return request({
            url: '/ai/embedding/edit',
            method: 'post',
            data: postdata
        })
    }

    // 删除embedding
    delEmbedding(ids) {
        return request({
            url: '/ai/embedding/del',
            method: 'post',
            data: { ids: ids }
        })
    }



    //导入数据
    embeddingImport(mid, rows, opts) {
        var data = {
            mid,
            options: JSON.stringify(opts),
            rows: JSON.stringify(rows)
        }

        return request({
            url: "/ai/embedding/import/" + mid,
            method: 'post',
            data: data
        })

    }


    //获取导出下载连接
    embeddingExportUrl(mid, query, fieldlist) {
        var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {},
            fieldlist: Array.isArray(fieldlist) ? fieldlist.join("|") : fieldlist
        }

        var tjson = ReFormatQueryStr(query)
        data.qstr = JSON.stringify(tjson)

        var parata = []
        for (let i in data) {
            parata.push(i + "=" + data[i])
        }


        return `/ai/embedding/export?` + parata.join("&");


    }

    //下导入模板
    embeddingimportTemplate() {

        return `/ai/embedding/importTemplate`;

    }

}

const instance = new Embedding();
export default instance;