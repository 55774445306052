<template>
    <div
        class="student-list"
        :style="{
            color: settings.text.color || 'black',
            fontSize: settings.text.fontSize + 'px' || '14px',
            width: '100%',
        }"
        style="
            height: 100%;
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
        "
    >
        <div
            style="
                width: 100%;
                height: 35px;
                overflow: hidden;
                border-bottom: 1px solid rgb(187, 187, 187);
                color: rgb(255, 255, 255);
            "
        >
            <table
                style="width: 100%; border-collapse: collapse"
                cellspacing="0"
                cellpadding="0"
            >
                <tbody>
                    <tr ref="th" style="height: 35px">
                        <td
                            v-for="(item, index) in tableColumn"
                            class="text-center"
                            :key="index"
                            v-text="item"
                            style="padding: 0"
                            :style="{ width: colWidths[index] + '%' }"
                        ></td>
                    </tr>
                    <tr v-for="(item, index) in data" :key="index">
                        <td
                            v-for="(Column, Column_index) in tableColumn"
                            class="text-center"
                            :key="Column_index"
                            v-text="item[Column]"
                        ></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <vue-seamless-Scroll
            :data="data"
            class="warp"
            :class-option="optionSetting"
            ><table
                ref="table"
                style="width: 100%; border-collapse: collapse"
                cellspacing="0"
                cellpadding="0"
            >
                <tbody>
                    <tr
                        v-for="(item, index) in data"
                        style="height: 35px"
                        :key="index"
                    >
                        <td
                            v-for="(column, column_index) in tableColumn"
                            class="text-center"
                            :style="[
                                { color: index < 3 ? color[index] : '' },
                                { width: colWidths[column_index] + '%' },
                            ]"
                            :key="column_index"
                            
                        >
                            <div
                                v-text="item[column] || ''"
                                v-if="column.indexOf('占比') === -1"
                            ></div>
                            <!--  -->
                            <!-- -->

                            <pie-chart
                                style="width: 100%; height: 50px"
                                :value="item[column]"
                                :color="color[index]"
                                :id="index"
                                :fontSize="settings.text.fontSize"
                                v-else
                            ></pie-chart>
                        </td>
                    </tr>
                </tbody>
            </table>
        </vue-seamless-Scroll>
        <!-- <div id="ratio" style="width: 48px;height: 48px;"></div> -->
    </div>
</template>
  
  <script>
import vueSeamlessScroll from "@/components/SeamlessScroll";
import pieChart from "./piechart";
export default {
    components: {
        vueSeamlessScroll,
        pieChart,
    },
    data() {
        return {
            tableData: [],
            isPaused: false,
            scrollKey: 0,
            tableColumn: [],
            color: ["#FF0000", "#FFE658", "#2791FC"],
            colWidths: [],
            echarts: undefined,
        };
    },
    props: {
        data: {
            type: Array,
            default: () => {
                return undefined;
            },
        },
        settings: {
            type: Object,
            default: () => {
                return undefined;
            },
        },
        defaultSettings: {
            type: Object,
            default: () => {
                return undefined;
            },
        },
    },
    watch: {
        data: {
            handler(val) {},
            immediate: true,
            deep: true,
        },
        settings: {
            handler(val) {
                console.log(val);
                let tableColumn = val.dimension || [];
                this.tableColumn = JSON.parse(JSON.stringify(tableColumn));
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        window.addEventListener("resize", this.onWindowResize);
        this.onWindowResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.onWindowResize);
    },
    computed: {
        optionSetting() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 9, // 开始无缝滚动的数据量 this.dataList.length
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            };
        },
    },
    methods: {
        onWindowResize() {
            this.calcColWidths();
        },

        calcColWidths() {
            if (this.$refs.th) {
                const colWidths = [];
                const children = this.$refs.th.children;
                let total = 0;
                for (let i = 0; i < children.length; i++) {
                    const width = children[i].offsetWidth;
                    total += width;
                    colWidths.push(width);
                }
                if (total > 0) {
                    for (let i = 0; i < colWidths.length; i++) {
                        colWidths[i] = (colWidths[i] / total) * 100;
                    }
                }
                this.colWidths = colWidths;
            }
        },

        startScrolling() {
            setInterval(() => {
                if (!this.isPaused) {
                    this.dataList.push(this.dataList.shift());
                    this.scrollKey += 1; // Update the key to trigger the transition
                }
            }, 2000); // Adjust the interval based on your requirements
        },
        pauseScroll() {
            this.isPaused = true;
        },
        resumeScroll() {
            this.isPaused = false;
        },
    },
};
</script>
  
<style  lang="scss" scoped>
.student-list {
    .text-center {
        text-align: center;
    }
    .flex-1 {
        flex: 1;
    }
    .d-flex {
        display: flex;
    }
    .align-items-center {
        align-items: center;
    }
    .justify-content-center {
        justify-content: center;
    }
    .py-10 {
        padding: 10px 0;
    }
    .mr-10 {
        margin-right: 10px;
    }
    .divider {
        border-bottom: 1px solid rgba(187, 187, 187, 1);
        padding-top: 10px;
    }
    .overflow-hidden {
        overflow: hidden;
    }
    .min-height {
        min-height: 0;
    }
    .min-width {
        // width: 120px;
        border: true;
    }
    .warp {
        flex: 1;
        overflow: hidden;
        table {
            padding: 0;
            tr:hover {
                background-color: rgba(255, 255, 255, 0.05);
            }
        }
    }
    .over-item:hover {
        cursor: pointer;
    }
}
</style>
  
