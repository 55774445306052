import Vue from 'vue'

import Cookies from 'js-cookie'

import 'font-awesome/css/font-awesome.min.css' //font-awesome css
import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import '@/assets/styles/element-variables.scss'

// vxe-table
import VXETable from 'vxe-table'
import XEUtils from 'xe-utils'
import 'vxe-table/lib/index.css'

// v-charts
import VCharts from 'v-charts'

// datav
import DataV from '@jiaminghi/data-view'

// 编辑器
import VueUeditorWrap from 'vue-ueditor-wrap' // ES6 Module

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import App from './App'
import store from '@/store'
import router from './router'
import permission from '@/directive/permission'

import '@/assets/icons' // icon

//路由·配置
import '@/config/routeGuard' // permission control
import '@/directive'
import { getDicts } from "@/api/core/data/dict/data";
import { getConfigKey } from "@/api/core/data/config/config";
import { parseTime, resetForm, addDateRange, selectDictLabel, download, downloadbyurl, handleTree,defaultQlogic } from "@/utils/ruoyi";
import Components from '@/components'
import BiComponents from './components'

// 全局方法挂载
Vue.prototype.XEUtils = XEUtils
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.download = download
Vue.prototype.downloadbyurl = downloadbyurl
Vue.prototype.handleTree = handleTree
Vue.prototype.defaultQlogic = defaultQlogic
Vue.prototype.apiUrl = process.env.VUE_APP_BASE_API

Vue.prototype.msgSuccess = function (msg) {
    this.$message({showClose: true, message: msg, type: "success"});
}

Vue.prototype.msgError = function (msg) {
    this.$message({showClose: true, message: msg, type: "error"});
}

Vue.prototype.msgInfo = function (msg) {
    this.$message.info(msg);
}

// 全局组件挂载
Vue.component('vue-ueditor-wrap', VueUeditorWrap)

Vue.use(VCharts)
Vue.use(DataV)
Vue.use(Components)
Vue.use(BiComponents)

Vue.use(permission)

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
    size: Cookies.get('size') || 'medium' // set element-ui default size
})

//  VXETable 全局配置
Vue.use(VXETable, {
    size: Cookies.get('size') || 'medium', // set element-ui default size
    pager: {
        size: null,
        autoHidden: false,
        perfect: true,
        pageSize: 10,
        pagerCount: 7,
        pageSizes: [10, 15, 20, 50, 100],
        layouts: ['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']
    },
    grid: {
        toolbarConfig: {
            perfect: true,
            zoom: true,
            custom: true
        }

    },
    icon: {
        TABLE_TREE_OPEN:"el-icon-arrow-right el-table__expand-icon--expanded",
        TABLE_TREE_CLOSE: 'el-icon-arrow-right',
    }
})
// 自定义全局的格式化处理函数
VXETable.formats.mixin({
    // 格式化日期，默认 yyyy-MM-dd HH:mm:ss
    formatDate ({ cellValue }, format) {
        var regPos = /^\d{10}$/; //时间戳
        //处理Unix时间戳
        if (regPos.test(cellValue)) {
            var ttime = cellValue * 1000;
            cellValue = new Date(ttime)
        }
        return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd HH:mm:ss')
    },
    //时间戳转日期
    unixTimestampToDate(value){
        var ttime = value * 1000;
        return new Date(ttime)
    },
    // 四舍五入金额，每隔3位逗号分隔，默认2位数
    formatAmount ({ cellValue }, digits = 2) {
        return XEUtils.commafy(XEUtils.toNumber(cellValue), {digits})
    },
    // 格式化银行卡，默认每4位空格隔开
    formatBankcard ({ cellValue }) {
        return XEUtils.commafy(XEUtils.toValueString(cellValue), {spaceNumber: 4, separator: ' '})
    },
    // 四舍五入,默认两位数
    formatFixedNumber ({ cellValue }, digits = 2) {
        return XEUtils.toFixed(XEUtils.round(cellValue, digits), digits)
    },
    // 向下舍入,默认两位数
    formatCutNumber ({ cellValue }, digits = 2) {
        return XEUtils.toFixed(XEUtils.floor(cellValue, digits), digits)
    }
});

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
