<!-- 生成时间:2023-10-12 10:59:36 -->
<template>
  <div class="app-container">
    <!-- 渲染查询表单 -->
    <VFormRender
      v-if="queryformData"
      class="query-form-data"
      ref="queryform"
      :formJson="queryformData"
    ></VFormRender>

    <el-form>
      <!-- label-width="68px" -->
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <jf-table
      :tree-config="treeConfig"
      :loading="loading"
      :query-params="queryParams"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="dataList"
      @sort-change="sortChange"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
      @toggle-tree-expand="toggleExpandChangeEvent"
      @cell-dblclick="Oncelldblclick"
      @page-change="handleSizeChange"
      :total="total"
      :filterBtn="switchshowbtn('filter')"
      :sortBtn="switchshowbtn('sort')"
      @change="tableChange"
      defaultoprWidth="520"
    >
      <template slot="toolbar_btn_left">
        <el-button
          v-if="switchshowbtn('add')"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermiZc="['/eyeplan/plan/add']"
          >新增</el-button
        >
        <el-button
          v-if="switchshowbtn('edit')"
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermiZc.all="['/eyeplan/plan/get', '/eyeplan/plan/edit']"
          >修改</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermiZc="['/eyeplan/plan/del']"
          v-if="switchshowbtn('delete')"
          >删除</el-button
        >
        <el-button
          v-if="switchshowbtn('import')"
          type="warning"
          size="mini"
          @click="handleImport(1)"
          v-hasPermiZc="['/eyeplan/plan/import']"
        >
          <i class="iconfont icon-daoru_o"></i>
          导入
        </el-button>

        <el-button
          v-if="switchshowbtn('export')"
          class="btn-orange"
          size="mini"
          @click="handleImport(2)"
          v-hasPermiZc="['/eyeplan/plan/export']"
        >
          <i class="iconfont icon-daochu_o"></i>
          导出
        </el-button>

        <el-dropdown @command="handleCommand">
          <el-button size="mini" type="text" style="margin-left: 10px">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="operationLogOpen"
              >操作记录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <!-- 	<template slot="toolbar_btn_right">

					</template> -->
      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-date"
          @click="scheduleClick({ row })"
          >日程管理
        </el-button>
        <el-button v-if="isPublic"
          size="mini"
          type="text"
          icon="el-icon-star-off"
          @click="recommendedSettingClick({ row })"
          >推荐设置
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-user"
          @click="usageClick({ row })"
          >使用记录
        </el-button>
        <el-button
          size="mini"
          v-if="switchshowbtn('get')"
          type="text"
          icon="el-icon-view"
          @click="Oncelldblclick({ row })"
          v-hasPermiZc="['/eyeplan/plan/get']"
          >查看
        </el-button>

        <el-button
          size="mini"
          v-if="switchshowbtn('get')"
          type="text"
          icon="el-icon-share"
          @click="shareclick(row)"
          v-hasPermiZc="['/eyeplan/plan/get']"
          >分享
        </el-button>

        <el-button
          v-if="switchshowbtn('edit')"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          v-hasPermiZc="['/eyeplan/plan/edit']"
          >修改
        </el-button>

        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          v-hasPermiZc="['/eyeplan/plan/del']"
          @click="handleDelete(row)"
          v-if="switchshowbtn('delete')"
          >删除
        </el-button>
      </template>
      <template slot="status" slot-scope="{ row }">
        <el-switch
          v-model="row.status"
          active-value="1"
          inactive-value="0"
          @change="handleStatusChange(row)"
        ></el-switch>
      </template>
      <template slot="roles" slot-scope="{ row }">
        <span v-for="(item, index) of row.roles" :key="index">
          {{ item.name }}
        </span>
      </template>
      <template
        :slot="item.slots.default"
        slot-scope="{ row }"
        v-for="item in columnSlots"
      >
        <div v-html="getFun(item, row)"></div>
      </template>
    </jf-table>

    <!-- 添加或修改对话框  数据 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      :width="
        formData && formData.formConfig && formData.formConfig.dialogWidth
          ? formData.formConfig.dialogWidth
          : '1000px'
      "
      :close-on-click-modal="false"
      append-to-body
      :before-close="cancel"
      custom-class="scrolldialog"
    >
      <VFormRender
        v-if="formData != null"
        ref="form"
        :disabledMode="isview"
        :isReadOnly="isview"
        :formJson="formData"
        :userInfo="userInfo"
      ></VFormRender>

      <!-- <el-tabs v-model="activeTab">
        <el-tab-pane label="计划项" name="planitem" :lazy="true">
          <el-button
            style="margin-bottom: 10px"
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAddPlanitem"
            >添加</el-button
          >
          <vxe-grid
                        resizable
                        stripe
                        :columns="teacherTableColumn"
                        :data="form.teachers"
                        :max-height="350"
                    >
                        <template #defaultopr="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeletePlanitem(row)"
                                >删除
                            </el-button>
                        </template>

                        <template #empty>
                            <span>
                                <p>暂无数据</p>
                            </span>
                        </template>
                    </vxe-grid>
          <jf-table
            :columns="planitemTableColumn"
            :data="form.planitems"
            :max-height="350"
            :checkbox="false"
            :pager="false"
            :is-query="false"
          >
            <template slot="toolbar_btn_row" slot-scope="{ row, rowIndex }">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click.stop="handleEditPlanitem(row, rowIndex)"
                >编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click.stop="handleDeletePlanitem(row, rowIndex)"
                >删除
              </el-button>
            </template>
          </jf-table>
        </el-tab-pane> -->
      <!-- </el-tabs> -->
      <div slot="footer" class="dialog-footer">
        <el-button v-if="!isview" type="primary" @click="submitForm"
          >确 定</el-button
        >
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <!-- 导入导出 -->
    <jf-import
      :show.sync="importOpen"
      v-if="importOpen"
      :type="importType"
      :columns="tableColumn"
      :isfinish="isupladfinish"
      :downloadtplurl="downloadtplurl"
      @importsubmit="importsubmit"
      :importError="importError"
      :exportTotal="total"
      @exportSubmit="exportSubmit"
      @saveExportTemplate="saveExportTemplate"
      :exportTemplateList="exportTemplateList"
    ></jf-import>
    <!-- 操作记录 -->
    <jf-operation-log
      :show.sync="operationLogOpen"
      v-if="operationLogOpen"
      :defaultMid:="mid"
    ></jf-operation-log>

    <!-- 导入提示 -->
    <el-dialog
      title="导入提示"
      :close-on-click-modal="false"
      :visible.sync="showerrinfo"
      width="750px"
      append-to-body
      custom-class="scrolldialog"
    >
      <p class="erinfo" v-for="(item, idx) in upload_errinfo" :key="idx">
        {{ item }}
      </p>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :title="titlePlanitem"
      :visible.sync="openPlanitem"
      width="960px"
      append-to-body
    >
      <el-form
        ref="formPlanitem"
        :model="formPlanitem"
        :rules="rulesPlanitem"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="时间" prop="time">
              <el-time-select
                v-model="formPlanitem.time"
                :picker-options="{
                  start: '06:00',
                  step: '00:15',
                  end: '23:45',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="卡片" prop="card">
              <el-select v-model="formPlanitem.card" placeholder="请选择">
                <el-option
                  v-for="item in cards"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="formPlanitem.remark"
                type="textarea"
                placeholder="请输入备注"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFormPlanitem">确 定</el-button>
        <el-button @click="cancelPlanitem">返 回</el-button>
      </div>
    </el-dialog>

    <!-- 日程管理 -->
    <el-dialog
      title="日程管理"
      :close-on-click-modal="false"
      :visible.sync="scheduleOpen"
      width="1080px"
      append-to-body
    >
      <div v-loading="scheduleLoading">
        <div class="schedule-tab">
          <el-button
            v-if="scheduleDayList.length >= 9"
            type="primary"
            :disabled="isLeftBtn"
            icon="el-icon-arrow-left"
            @click="scrollLeft('scrollContainer1')"
          ></el-button>
          <div ref="scrollContainer1" class="schedule-tab-content">
            <el-button
              type="primary"
              v-for="item in scheduleDayList"
              :key="item.id"
              :plain="btnplain == item.id ? false : true"
              @click="scheduleTabButton(item)"
              >第{{ item.id }}天</el-button
            >
          </div>
          <el-button
            v-if="scheduleDayList.length >= 9"
            type="primary"
            :disabled="isRightBtn"
            icon="el-icon-arrow-right"
            @click="scrollRight('scrollContainer1')"
          ></el-button>
        </div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="scheduleAdd"
          >新增</el-button
        >

        <el-table
          :data="scheduleList"
          border
          tooltip-effect="dark"
          style="width: 100%; margin-top: 10px"
        >
          <el-table-column
            prop="time"
            label="开始时间"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="card"
            label="卡片"
            align="center"
            show-overflow-tooltip
            :formatter="cardFormat"
          >
          </el-table-column>
          <el-table-column
            prop="repeat"
            label="重复天数"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="interval"
            label="间隔天数"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button
                type="text"
                icon="el-icon-edit"
                size="mini"
                @click="scheduleHandleUpdate(scope.row, scope.$index)"
                >修改</el-button
              >
              <el-button
                type="text"
                icon="el-icon-delete"
                size="mini"
                @click="scheduleHandleDelete(scope.row, scope.$index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="scheduleSubmitForm"
          >确定</el-button
        >
        <el-button size="mini" @click="scheduleOpen = false">返回</el-button>
      </span>
    </el-dialog>

    <!-- 添加数据 修改数据 -->
    <el-dialog
      :title="addTitle"
      :close-on-click-modal="false"
      :visible.sync="scheduleAddOpen"
      width="1080px"
      append-to-body
    >
      <el-form
        :model="scheduleForm"
        :rules="scheduleRules"
        ref="scheduleForms"
        label-width="100px"
      >
        <el-form-item label="开始时间" prop="time">
          <el-time-select
            v-model="scheduleForm.time"
            :picker-options="{
              start: '06:00',
              step: '00:15',
              end: '23:45',
            }"
            placeholder="选择时间"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="选择卡片" prop="card">
          <el-select v-model="scheduleForm.card" placeholder="请选择卡片">
            <el-option
              :label="ele.name"
              :value="ele.id + ''"
              v-for="ele in cards"
              :key="ele.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重复天数" prop="repeat">
          <el-select v-model="scheduleForm.repeat" placeholder="请选择重复天数" :disabled="isSelect">
            <el-option
              v-for="(sub, subIndex) in scheduleDays"
              :key="subIndex"
              :label="subIndex + '天'"
              :value="subIndex"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="间隔天数" prop="interval">
          <el-select
            v-model="scheduleForm.interval"
            placeholder="请选择间隔天数"  :disabled="isSelect"
          >
            <el-option
              v-for="(sub, subIndex) in scheduleDays"
              :key="subIndex"
              :label="subIndex + '天'"
              :value="subIndex"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="scheduleAddConfirm"
          >确定</el-button
        >
        <el-button size="mini" @click="scheduleAddOpen = false">返回</el-button>
      </span>
    </el-dialog>

    <!-- 使用记录 -->
    <el-dialog
      title="使用记录"
      :close-on-click-modal="false"
      :visible.sync="usageOpen"
      width="1080px"
      append-to-body
    >
      <div class="usage">
        <el-button
          type="primary"
          :plain="usageplain == 0 ? false : true"
          @click="usageBtbClick(0)"
          >全部</el-button
        >
        <el-button
          type="primary"
          :plain="usageplain == 1 ? false : true"
          @click="usageBtbClick(1)"
          >正在使用</el-button
        >
        <el-button
          type="primary"
          :plain="usageplain == 2 ? false : true"
          @click="usageBtbClick(2)"
          >曾经使用</el-button
        >
      </div>

      <jf-table
        :loading="usageLoading"
        :operate="false"
        :filterBtn="false"
        :columnsBtn="false"
        :sortBtn="false"
        :columns="usageTableColumn"
        :data="usageList"
        :toolbar-config="usageTableToolbar"
        :query-params="usageQueryParams"
        @page-change="handleUsagePageChange"
        :total="usageTotal"
      >
      </jf-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="usageOpen = false">返回</el-button>
      </span>
    </el-dialog>

    <!-- 推荐设置 -->
    <el-dialog
      title="推荐设置"
      :close-on-click-modal="false"
      :visible.sync="recommendedOpen"
      width="50%"
      append-to-body
    >
      <div v-loading="recommendedLoading" class="recommended">
        <div
          class="recommended-item"
          v-for="(item, index) in filterActive"
          :key="index"
        >
          <el-row :gutter="20">
            <el-col :span="11">
              <el-select v-model="item.field" placeholder="请选择">
                <el-option
                  v-for="(title, i) in filterList"
                  :key="i"
                  :label="title.title"
                  :value="title.field"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="11">
              <el-select v-model="item.type" placeholder="请选择">
                <el-option
                  v-for="(sub, sunindex) in calculationTypes"
                  :key="sunindex"
                  :label="sub.lable"
                  :value="sub.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <i
                  class="el-icon-remove-outline"
                  style="font-size: 30px"
                  @click="filterTypeChange(item, index, 2)"
                ></i>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-popover
          placement="bottom"
          width="200"
          trigger="click"
          v-model="filterVisible"
        >
          <div class="filter-type">
            <el-input
              placeholder="输入筛选条件"
              v-model="filterKeywords"
              suffix-icon="el-icon-search"
            ></el-input>
            <div class="filter-list">
              <div
                class="filter-list-item"
                v-for="(item, index) in filterTypes"
                :key="index"
                :class="{
                  active: isExist(filterActive, item.field) > -1,
                }"
                @click="filterTypeChange(item, index, 1)"
              >
                <div v-text="item.title"></div>
                <i
                  class="el-icon-success"
                  v-if="isExist(filterActive, item.field) > -1"
                ></i>
              </div>
            </div>
          </div>

          <el-button
            type="text"
            size="medium"
            icon="el-icon-circle-plus-outline"
            slot="reference"
            @click="filterKeywords = ''"
            >添加</el-button
          >
        </el-popover>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="submitRecommended"
          >确定</el-button
        >
        <el-button @click="recommendedOpen = false">返回</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="分享"
      :close-on-click-modal="true"
      :visible.sync="shareOpen"
      width="400px"
      custom-class="share"
      append-to-body
    >
      <div style="padding-left: 80px;">
        <canvas ref="qrCodeCanvas" style="width: 200px; height: 200px;"></canvas>
      </div>
      <div slot="footer">
        <el-button @click="shareOpen = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import planService from "@/api/eyeplan/plan";
import { form } from "@/mixins/form.js";

import { execModel } from "@/api/core/data/datasource/model";
import { getToken } from "@/utils/auth";

import userplanService from "@/api/eyeplan/userplan";

import Treeselect from "@riophae/vue-treeselect";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import jfImport from "@/components/jfimport/jfimport";
import JfOperationLog from "@/components/jfoperationlog/jfoperationlog";

import { getFieldWidgetByName, deepClone } from "@/vform/utils/util";

import VFormRender from "@/vform/components/form-render/index.vue";
import { getUserProfile } from "@/api/core/system/user";

import card from "@/api/eyeplan/card";

import QRCode from 'qrcode';

export default {
  mixins: [form],
  name: "eyeplan-plan-index",
  components: {
    Treeselect,
    VFormRender,
    jfImport,
    JfOperationLog,
  },

  data() {
    return {
      org_optionItems: {},
      istree: false,
      hidequeryparamform: false,
      exportTemplateList: [],
      listconf: null,

      importError: false,
      downloadtplurl: "",
      upload_errinfo: null,
      showerrinfo: false,
      isupladfinish: false,
      //导入导出弹窗
      importOpen: false,
      //导入导出类型
      imporType: 1,
      //操作记录
      operationLogOpen: false,
      //功能id
      mid: "1711567116785713152", //不可修改
      queryformData: null, //查询表单数据
      queryformDataorgstr: "",

      formData: null, //表单数据
      isview: false,
      model_info: {},
      child_model_info: {},
      childcollist: [],
      collist: [],
      LeftTreeData: [],
      LeftTreeDataProc: {},
      treeOptions: [],
      treeselectprops: {},
      treeConfig: {
        trigger: "row",
        expandRowKeys: [],
      },
      TreeAttr: {},

      //左侧分栏比例
      leftrolspan: 0,
      //checkbox 的数据
      CheckBoxData: {},
      //字典库数据
      DictsData: {},
      DsData: {},

      area_dist: {},
      province_dist: {},
      city_dist: {},

      //上传的文件列表
      upload_fileList: {},

      //上传文件参数
      upload: {
        headers: {
          Authorization: getToken(),
        },
        url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
      },

      //编辑器配置
      ueConfig: {
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
        enableAutoSave: true,
        elementPathEnable: false,
        wordCount: false,
        UEDITOR_HOME_URL: "/ueditor/",
      },

      //图片预览地址
      dialogImageUrl: "",
      //图片预览对话框
      dialogVisible: false,

      loadingstu: false,
      openselstu: false,

      // 工具栏
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 数据列
      tableColumn: [
        // {
        // 	type: "checkbox",
        // 	width: 60,
        // 	fixed: "left"
        // },
        {
          field: "id",
          title: "ID",
          width: 100,
          fixed: "left",
        },

        // {
        //   field: "",
        //   title: "操作",
        //   resizable: false,
        //   width: 300,
        //   fixed: "right",
        //   align: "center",
        //   slots: {
        //     default: "defaultopr",
        //   },
        // },
      ],

      orgcolumnList: [],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      totalstu: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 快速检索数据
      Quitfilter_data: {},
      // 快速检索数据 字典类型
      Quitfilter_keys: [],
      // 是否显示弹出层
      open: false,
      // 机构名称
      deptname: undefined,

      activeTab: "planitem",
      planitemTableColumn: [
        //{ field: "id", title: "id", minWidth: 60, fixed: "left" },
        { field: "time", title: "时间", minWidth: 60 },
        {
          field: "card",
          title: "卡片",
          minWidth: 120,
          formatter: (e) => {
            for (let i = 0; i < this.cards.length; i++) {
              const c = this.cards[i];
              if (c.id == e.cellValue) {
                return c.name;
              }
            }
            return "";
          },
        },
        { field: "remark", title: "备注", minWidth: 120 },
        {
          field: "",
          title: "操作",
          width: 150,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],

      titlePlanitem: "添加",
      openPlanitem: false,
      formPlanitem: {},
      rulesPlanitem: {
        time: [
          {
            required: true,
            message: "时间不能为空",
            trigger: "blur",
          },
        ],
        card: [
          {
            required: true,
            message: "卡片不能为空",
            trigger: "blur",
          },
        ],
      },
      cards: [],

      //表单
      form: {
        planitems: [],
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      // 表单校验
      rules: {},
      userInfo: {},
      //  日程管理弹窗
      scheduleOpen: false,
      scheduleLoading: false,
      // 天数列表
      scheduleDayList: [],
      // 天数按钮active
      btnplain: 1,
      // 天数
      scheduleDays: 0,
      // 是否显示左右按钮
      isLeftBtn: true,
      isRightBtn: false,
      // 日程管理 表格数据
      scheduleList: [],
      // 日程管理添加弹窗
      scheduleAddOpen: false,
      // 弹窗名称
      addTitle: "添加数据",
      // 日程管理数据
      scheduleInfo: {},
      // 记录修改index
      updateIndex: 0,
      // 日程管理添加数据字段
      scheduleForm: {
        day: undefined,
        time: undefined,
        card: undefined,
        repeat: 0,
        interval: 0,
      },
      // 日程管理添加数据规则
      scheduleRules: {
        time: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "blur",
          },
        ],
        card: [
          {
            required: true,
            message: "请选择卡片",
            trigger: "blur",
          },
        ],
        repeat: [
          {
            required: true,
            message: "请选择重复天数",
            trigger: "change",
          },
        ],
        interval: [
          {
            required: true,
            message: "请选择间隔天数",
            trigger: "change",
          },
        ],
      },
      //   使用记录 弹窗
      usageOpen: false,
      //   使用记录 表格加载
      usageLoading: false,
      //   使用记录 表格数据
      usageList: [],
      //   使用记录 Total
      usageTotal: 0,
      // 使用记录 查询
      usageQueryParams: {
        pageNum: 1,
        pageSize: 10,
        fieldval: {
          status: "",
        },
        querytype: {
          status: "",
        },
      },
      //   使用记录 表格字段
      usageTableColumn: [
        {
          field: "nickname",
          title: "昵称",
          align: "center",
        },
        {
          field: "realname",
          title: "姓名",
          align: "center",
        },
        {
          field: "phone",
          title: "电话号码",
          align: "center",
        },
        {
          field: "status",
          title: "使用情况",
          align: "center",
          formatter: (e) => {
            if (e.cellValue == "0") {
              return "正在使用";
            } else {
              return "曾经使用";
            }
          },
        },
      ],
      //   使用记录 表格配置
      usageTableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
      },
      // 使用记录按钮active
      usageplain: 0,
      // 推荐设置
      recommendedOpen: false,
      recommendedLoading: false,
      recommendedInfo: {},
      shareOpen: false,
      shareContent: '',
      filterActive: [],
      filterList: [],
      calculationTypes: [
        {
          value: "and",
          lable: "与",
        },
        {
          value: "or",
          lable: "或",
        },
      ],
      filterVisible: true,
      filterKeywords: "",
      //   是否可以修改重复天数和间隔天数
      isSelect: false,
      isPublic: false,
    };
  },

  computed: {
    columnSlots: function () {
      return this.tableColumn.filter((item) => {
        return item.slots;
      });
      // console.log(this.tableColumn.filter((item) => {return item.slots}))
    },
    filterTypes: function () {
      return this.filterList.filter(
        (o) =>
          o.title &&
          o.title !== "操作" &&
          o.title.indexOf(this.filterKeywords) !== -1
      );
    },
  },
  watch: {
    // 根据名称筛选机构树
    deptname(val) {
      this.$refs.tree.filter(val);
    },
  },
  beforeCreate() {},
  created() {
    this.downloadtplurl = planService.planimportTemplate(); //下载模版地址
    // this.usageQueryParams.sortinfo = this.defsort;

    this.getforminfo(this.mid);
    this.getUser();

    card.listCard({ pageSize: 100 }).then((res) => {
      this.cards = res.data.list;
    });

    this.getDicts('eye_assessment_sub_type').then(res => {
      const stags = []; // ['优秀', '良好', '合格', '不合格']
      for (let i = 0; i < res.data.values.length; i++) {
        const d = res.data.values[i]
        stags.push(d.value)
      }
      this.getDicts('eye_assessment_type').then(res => {
        const filterList = []
        for (let i = 0; i < res.data.values.length; i++) {
          const d = res.data.values[i]
          for (let j = 0; j < stags.length; j++) {
            const sd = stags[j]
            filterList.push({
              field: d.value + sd,
              title: d.value + sd
            })
          }
        }
        this.filterList = filterList
      })
    })
  },
  methods: {
    getUser() {
      getUserProfile().then((response) => {
        this.userInfo = response.data.user;
      });
    },
    getName(row) {
      console.log(row, "测试");
    },

    exportSubmit(fieldlist) {
      var that = this;
      var tfa = [];
      for (var i in fieldlist) {
        var byname = "";
        if (fieldlist[i].byname != null) {
          byname = fieldlist[i].byname;
        }
        tfa.push(fieldlist[i].field + "=" + byname);
      }
      var durl = planService.planExportUrl(this.mid, this.queryParams, tfa);
      try {
        this.downloadbyurl(durl, null, function (ers) {
          if (ers.currentTarget.status != 200) {
            that.msgError("导出错误");
          }
        });
      } catch (error) {
        console.log(chalk.red(error));
      }
    },
    importsubmit(rows) {
      //开始导入
      planService
        .planImport(this.mid, rows.datalist, rows.importoption)
        .then((rs) => {
          if (rs.data.row.Failmsg != null && rs.data.row.Failmsg != "") {
            this.upload_errinfo = rs.data.row.Failmsg.split("|");
            this.showerrinfo = true;
            this.importError = true;
          } else {
            this.isupladfinish = true;
            this.msgSuccess("导入成功");
            this.getList(this.mid);
          }
        });
    },
    //导入导出
    handleImport(type) {
      this.importError = false;
      this.isupladfinish = false;
      this.importType = type;
      this.importOpen = true;
    },
    handleCommand(e) {
      //  console.log(e);
      this[e] = true;
    },

    getFun(item, row) {
      return this[item.slotsFun.funName](
        item.slotsFun.dt,
        row[item.slotsFun.key],
        item.slotsFun.key
      );
    },

    //查看
    Oncelldblclick(row) {
      this.handleUpdate(row.row, "查看");
      this.isview = true;
    },
    // 分享
    shareclick(row) {
      console.log(row)
      let content = ''
      if (row.public == '1') {
        content = JSON.stringify({
          id: row.id,
          type: 'systemPlan',
          userpool: row.userpool || "admin",
          user_id: row.create_by
        })
      } else {
        content = JSON.stringify({
          id: row.id,
          type: 'doctorPlan',
          userpool: row.userpool || "doctor",
          user_id: row.create_by
        })
      }
      this.shareOpen = true
      this.$nextTick(()=>{
        QRCode.toCanvas(this.$refs.qrCodeCanvas, content, {
          width: 200,
          height: 200
        })
      })
    },
    // 左滑
    scrollLeft(row1) {
      this.$refs[row1].scrollBy({
        left: -800, // 每次点击滚动的距离
        behavior: "smooth",
      });
      this.isRightBtn = false;
      // 获取容器的横向滚动位置
      var scrollLeft = this.$refs.scrollContainer1.scrollLeft;
      // 获取容器的宽度
      var offsetW = this.$refs.scrollContainer1.offsetWidth;
      this.isLeftBtn = scrollLeft < offsetW ? true : false;
    },

    // 右滑
    scrollRight(row1) {
      this.$refs[row1].scrollBy({
        left: 800, // 每次点击滚动的距离
        behavior: "smooth",
      });
      this.isLeftBtn = false;
      // 获取容器的横向滚动条宽度
      var scrollWidth = this.$refs.scrollContainer1.scrollWidth;
      // 获取容器的横向滚动位置
      var scrollLeft = this.$refs.scrollContainer1.scrollLeft;
      // 获取容器的宽度
      var offsetW = this.$refs.scrollContainer1.offsetWidth;
      let sum = scrollLeft + 800 + offsetW;
      this.isRightBtn = sum >= scrollWidth ? true : false;
    },

    // 日程管理
    scheduleClick(row) {
      this.btnplain = 1;
      this.scheduleList = [];
      this.scheduleDayList = [];
      this.scheduleLoading = true;
      planService.getPlan(row.row.id).then((response) => {
        this.scheduleInfo = response.data.row;
        this.scheduleDays = Number(response.data.row.days);
        for (let i = 0; i < this.scheduleDays; i++) {
          this.scheduleDayList.push({
            id: i + 1,
            day: i + 1,
            list: [],
          });
        }
        let arr = response.data.row.planitems;
        if (arr != null) {
          this.$nextTick(() => {
            for (let i = 0; i < arr.length; i++) {
              for (let j = 0; j < this.scheduleDayList.length; j++) {
                if (this.scheduleDayList[j].id === arr[i].day) {
                  this.scheduleDayList[j].list.push(arr[i]);
                }
              }
            }
            this.scheduleList = this.scheduleDayList[this.btnplain - 1].list;
          });
        }

        this.scheduleLoading = false;
      });
      this.scheduleOpen = true;
    },

    // 日程管理 点击天数
    scheduleTabButton(item) {
      this.btnplain = item.id;
      let tabLists = item.list;
      // tabLists.sort((a, b) => a.time.localeCompare(b.time));
      this.scheduleList = this.deepCopy(tabLists);
    },

    // 卡片
    cardFormat(row) {
      let find = this.cards.find((v) => {
        return v.id == row.card;
      });
      if (find) {
        return find.name;
      } else {
        return "";
      }
    },

    // 日程管理 添加
    scheduleAdd() {
      this.addTitle = "添加数据";
      this.scheduleForm.time = undefined;
      this.scheduleForm.card = undefined;
      this.scheduleForm.repeat = 0;
      this.scheduleForm.interval = 0;
      this.isSelect = false;
      this.scheduleAddOpen = true;
    },

    // 日程管理 修改
    scheduleHandleUpdate(row, rowIndex) {
      this.addTitle = "修改数据";
      this.scheduleForm = row;
      this.updateIndex = rowIndex;
      if(row.id>0&&(row.from_day==undefined || row.from_day == 0)){
        this.isSelect = false;
      }else{
        this.isSelect = true;
      }
      this.scheduleAddOpen = true;
    },

    // 日程管理 添加修改 ruleForm
    scheduleAddConfirm() {
      this.$refs.scheduleForms.validate((valid) => {
        if (valid) {
          let updateArr = this.scheduleList;
          if (this.addTitle == "添加数据") {
            this.scheduleForm.day = this.btnplain;
            this.scheduleForm.id = "";
            this.scheduleForm.from_planitem_id = 0;
            this.scheduleForm.from_day = this.btnplain;
            let addForms = this.deepCopy(this.scheduleForm);
            updateArr.push(addForms);
            updateArr.sort((a, b) => a.time.localeCompare(b.time));
            this.scheduleList = this.deepCopy(updateArr);
            this.$set(
              this.scheduleDayList[this.btnplain - 1],
              "list",
              this.scheduleList
            );
            // 再添加重复数据
            if (addForms.repeat > 0) {
              let repeated = 0;
              for (
                let i = addForms.day + addForms.interval;
                i < this.scheduleDayList.length;
                i += addForms.interval + 1
              ) {
                // 从哪天开始间隔多少天添加
                const d = this.scheduleDayList[i];
                for (let we = 0; we < d.list.length; we++) {
                  if (
                    (we.from_planitem_id == 0 || we.from_day > addForms.day) &&
                    d.time == addForms.time
                  ) {
                    // 和其他的冲突了
                    break;
                  }
                }
                const nd = this.deepCopy(addForms);
                nd.id = 0;
                nd.from_planitem_id = addForms.id;
                nd.from_day = addForms.day;
                nd.day = d.day;
                d.list.push(nd);
                d.list.sort((a, b) => a.time.localeCompare(b.time));
                repeated++;
                if (repeated >= addForms.repeat - 1) {
                  // 只能重复这么多天
                  break;
                }
              }
            }
          } else {
            let updateForms = this.deepCopy(this.scheduleForm);
            // 从这这条数据开始，先删除重复数据
            for (
              let i = updateForms.day;
              i < this.scheduleDayList.length;
              i++
            ) {
              const d = this.scheduleDayList[i];
              for (let j = 0; j < d.list.length; j++) {
                const sd = d.list[j];
                if (
                  sd.from_planitem_id == updateForms.id ||
                  (sd.from_planitem_id == updateForms.from_planitem_id &&
                    sd.time == updateForms.time)
                ) {
                  d.list.splice(j, 1);
                  break;
                }
              }
            }
            // 再添加重复数据
            if (updateForms.repeat > 0) {
              let repeated = 0;
              for (
                let i = updateForms.day + updateForms.interval;
                i < this.scheduleDayList.length;
                i += updateForms.interval + 1
              ) {
                // 从哪天开始间隔多少天添加
                const d = this.scheduleDayList[i];
                for (let qw = 0; qw < d.list.length; qw++) {
                  if (
                    (qw.from_planitem_id == 0 ||
                      qw.from_day > updateForms.day) &&
                    d.time == updateForms.time
                  ) {
                    // 和其他的冲突了
                    break;
                  }
                }
                const nd = this.deepCopy(updateForms);
                nd.id = 0;
                nd.from_planitem_id = updateForms.id;
                nd.from_day = updateForms.day;
                nd.day = d.day;
                d.list.push(nd);
                d.list.sort((a, b) => a.time.localeCompare(b.time));
                repeated++;
                if (repeated >= updateForms.repeat - 1) {
                  // 只能重复这么多天
                  break;
                }
              }
            }
            updateArr[this.updateIndex] = updateForms;
            updateArr.sort((a, b) => a.time.localeCompare(b.time));
            this.scheduleList = this.deepCopy(updateArr);
            this.$set(
              this.scheduleDayList[this.btnplain - 1],
              "list",
              this.scheduleList
            );
          }
          //   updateArr.sort((a, b) => a.time.localeCompare(b.time));
          //   this.scheduleList = this.deepCopy(updateArr);
          //   this.$set(
          //     this.scheduleDayList[this.btnplain - 1],
          //     "list",
          //     this.scheduleList
          //   );
          this.scheduleAddOpen = false;
        } else {
          this.msgError("请填写完整！");
        }
      });
    },

    // 日程管理 确定
    scheduleSubmitForm() {
      if (this.scheduleLoading) {
        return
      }
      let arr = {};
      arr.id = this.scheduleInfo.id;
      arr.days = this.scheduleInfo.days;
      arr.favourite = this.scheduleInfo.favourite;
      arr.name = this.scheduleInfo.name;
      arr.public = this.scheduleInfo.public;
      arr.remark = this.scheduleInfo.remark;
      arr.type = this.scheduleInfo.type;
      let aeelist = [];
      this.scheduleDayList.forEach((ele) => {
        aeelist = aeelist.concat(ele.list);
      });
      arr.planitems = aeelist;
      // console.log("日程管理 确定", arr);
      planService
        .editPlan(this.mid, arr)
        .then((rs) => {
          this.msgSuccess("操作成功");
          this.scheduleOpen = false;
        })
        .catch(() => {
          this.msgError("提交失败!");
        });
    },

    // 日程管理 删除
    scheduleHandleDelete(row, rowIndex) {
      this.$confirm("是否确认删除所选数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.btnplain == 1) {
            for (let i = 0; i < this.scheduleDayList.length; i++) {
              const d = this.scheduleDayList[i];
              for (let j = 0; j < d.list.length; j++) {
                const sd = d.list[j];
                if (
                  sd.from_planitem_id == row.id ||
                  (sd.from_planitem_id == row.from_planitem_id &&
                    sd.time == row.time)
                ) {
                  d.list.splice(j, 1);
                  break;
                }
              }
            }
            this.scheduleList.splice(rowIndex, 1);
          } else {
            this.scheduleList.splice(rowIndex, 1);
            this.$set(
              this.scheduleDayList[this.btnplain - 1],
              "list",
              this.scheduleList
            );
          }
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    // 推荐设置
    recommendedSettingClick(row) {
      this.recommendedLoading = true
      this.filterActive = []
      planService.getPlan(row.row.id).then((response) => {
        this.recommendedLoading = false
        this.recommendedInfo = response.data.row;
        if (this.recommendedInfo.settings) {
          this.filterActive = JSON.parse(this.recommendedInfo.settings).recommend || []
        }
      })
      this.recommendedOpen = true;
    },
    // 推荐设置
    isExist(list, field) {
      return list.findIndex((o) => o.field === field);
    },
    // 推荐设置 添加点击
    filterTypeChange(row, index, type) {
      this.filterVisible = false;
      if (type === 2) {
        this.filterActive.splice(index, 1);
        return false;
      }
      let i = this.isExist(this.filterActive, row.field);
      if (i === -1) {
        let obj = this.deepCopy(row);

        // obj.logic = "and";
        obj.field = obj.field;
        obj.type = this.calculationTypes[0].value;
        obj.value = this.calculationTypes[0].lable;

        this.filterActive.push(obj);
        console.log(this.filterActive, "this.filterActive1111");
        return false;
      }
      this.filterActive.splice(i, 1);
    },
    // 使用记录
    usageClick(row) {
      this.usageplain = 0;
      this.usageQueryParams.fieldval.status = "";
      this.usageOpen = true;
      this.getUsageData();
    },
    // 获取使用记录
    getUsageData() {
      this.usageLoading = true;
      userplanService
        .listUserplan(this.usageQueryParams)
        .then((response) => {
          this.usageLoading = false;
          this.usageList = response.data.list;
          this.usageTotal = response.data.total;
        })
        .catch(() => {
          this.usageLoading = false;
        });
    },
    // 分页
    handleUsagePageChange({ currentPage, pageSize }) {
      if (this.usageQueryParams.pageSize == pageSize) {
        this.getUsageData();
      } else {
        this.usageQueryParams.pageSize = pageSize;
        this.usageQueryParams.pageNum = 1;
        this.getUsageData();
      }
    },

    // 使用记录 按钮
    usageBtbClick(type) {
      this.usageplain = type;
      this.usageQueryParams.fieldval.status =
        type == 0 ? "" : type == 1 ? "0" : "1";
      this.usageQueryParams.pageNum = 1;
      this.getUsageData();
    },

    getTreeselect() {
      if (this.model_info.Category != "4" && this.model_info.Category != "5") {
        return false;
      }
      this.treeOptions = [];
      let opt = {
        id: 0,
        children: [],
      };
      opt[this.TreeAttr.tree_name] = "(无上级)";

      if (this.model_info.Category == "5") {
        opt.children = this.LeftTreeData;
      } else {
        opt.children = this.dataList;
      }

      this.treeOptions.push(opt);
      // console.log(this.treeOptions);
    },

    /** 查询数据 */
    getList(mid) {
      this.loading = true;

      this.queryParams.querytype['public'] = ''
      if (this.$route.path.indexOf("/plan/index") < 0) {
        this.isPublic = false
        this.queryParams.fieldval['public'] = '0'
      } else {
        this.isPublic = true
        this.queryParams.fieldval['public'] = '1'
      }

      planService
        .listPlan(this.queryParams)
        .then((response) => {
          this.loading = false;
          if (this.treeConfig.parentField != null) {
            var tlist = [];
            for (let k in response.data.list) {
              let tonef = response.data.list[k];
              tonef["label"] = tonef[this.treeConfig.treeNode];

              tlist.push(JSON.parse(JSON.stringify(tonef)));
            }
            try {
              this.dataList = this.handleTree(
                tlist,
                "id",
                this.treeConfig.parentField
              );
            } catch (error) {
              console.log(" this.dataList error", error);
            }
          } else {
            this.dataList = response.data.list;
          }

          this.total = response.data.total;
        })
        .catch(() => {
          this.loading = false;
          // this.msgError("加载数据出错,请先检查表单配置!");
        });
    },

    // 筛选节点
    filterNode(value, data) {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 取消按钮
    cancel() {
      this.reset();
      this.open = false;
      this.isview = false;
    },
    // 表单重置
    reset() {
      this.form = {
        planitems: [],
      };
      this.formData = null;

      this.resetQuery();
    },

    /** 新增按钮操作 */
    handleAdd() {
      //this.reset();
      // this.getTreeselect();
      this.hidequeryparamform = true;
      this.formData = null;
      let tformdata = JSON.parse(this.model_info.Formresource);
      tformdata.formstatus = "add";

      this.$nextTick((_) => {
        this.formData = this.binddicttotablecolumn(tformdata);
      });

      this.open = true;
      this.title = "添加数据";
    },

    /** 修改按钮操作 */
    handleUpdate(row, til) {
      this.reset();
      // this.getTreeselect();
      //this.getTreeselect();
      const id = row.id || this.ids[0];
      planService.getPlan(id).then((response) => {
        this.form.id = response.data.row.id;
        const planitems = [];
        if (response.data.row.planitems) {
          for (let i = 0; i < response.data.row.planitems.length; i++) {
            const d = response.data.row.planitems[i];
            planitems.push({
              id: d.id,
              time: d.time,
              card: d.card,
              remark: d.remark,
            });
          }
        }
        this.form.planitems = planitems;

        //填充表单值
        let formvalue = response.data.row; //表单填写内容
        let array = Object.keys(formvalue);
        this.reformatformdata(formvalue);
        let formData = JSON.parse(this.model_info.Formresource); //表单数据(字段,布局等)
        formData.formstatus = "edit";

        this.formData = formData;
        this.$nextTick((_) => {
          //    formData;
          this.formData = this.binddicttotablecolumn(formData);
          this.$refs.form.setFormData(formvalue);
        });

        this.open = true;
        this.title = til || "修改";
      });
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form
        .getFormData()
        .then((data) => {
          if (this.$route.path.indexOf("/plan/index") < 0) {
            data.public = '0'
          } else {
            data.public = '1'
          }
          data.planitems = this.form.planitems;
          if (this.form.id != null) {
            //修改
            data.id = this.form.id;
            planService
              .editPlan(this.mid, data)
              .then((rs) => {
                this.msgSuccess("操作成功");
                this.cancel();
                this.handleQuery();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          } else {
            //新增
            planService
              .addPlan(this.mid, data)
              .then((rs) => {
                this.msgSuccess("操作成功");
                this.cancel();
                this.handleQuery();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          }
        })
        .catch((e) => {
          this.msgError("表单验证不通过,请检查输入");
        });

      return true;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const did = row.id || (this.ids ? this.ids.join(",") : "");
      this.$confirm(this.islogicdelTips, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return planService.delPlan(did);
        })
        .then(() => {
          this.handleQuery();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    normalizerDeptTreeNode(e) {
      //      e.id = e.id
      e.label = e.name;
      return e;
    },

    handleAddPlanitem() {
      this.edittingPlanitem = undefined;
      this.edittingPlanitemIndex = undefined;
      this.formPlanitem = {
        time: undefined,
        card: undefined,
        remark: undefined,
      };
      this.titlePlanitem = "添加";
      this.openPlanitem = true;
    },

    handleEditPlanitem(row, rowIndex) {
      this.edittingPlanitem = { ...row };
      this.edittingPlanitemIndex = rowIndex;
      this.formPlanitem = this.edittingPlanitem;
      this.titlePlanitem = "编辑";
      this.openPlanitem = true;
    },

    handleDeletePlanitem(row, rowIndex) {
      this.$confirm("确定删除吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.form.planitems.splice(rowIndex, 1);
          this.form.planitems = [].concat(this.form.planitems);
        })
        .catch((e) => {});
    },

    submitFormPlanitem() {
      this.$refs["formPlanitem"].validate((valid) => {
        if (valid) {
          if (this.edittingPlanitem === undefined) {
            this.form.planitems.push(this.formPlanitem);
          } else {
            this.form.planitems[this.edittingPlanitemIndex] =
              this.edittingPlanitem;
          }
          this.form.planitems = [].concat(this.form.planitems).sort((a, b) => {
            return (
              parseInt(a.time.split(":").join("")) -
              parseInt(b.time.split(":").join(""))
            );
          });
          this.openPlanitem = false;
        }
      });
    },

    cancelPlanitem() {
      this.openPlanitem = false;
    },

    submitRecommended() {
      planService
        .editPlan(this.mid, {...this.recommendedInfo, settings: {recommend: this.filterActive}})
        .then((rs) => {
          this.msgSuccess("操作成功");
          this.recommendedOpen = false;
        })
        .catch(() => {
          this.msgError("提交失败!");
        });
    }
  },
};
</script>
<style rel="stylesheet/scss" scoped>
.form-data {
  margin: 0 -2rem;
}
.btn-orange {
  background-color: #ff9648;
  color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #2196f3;
  color: #fff;
}

::v-deep .scrolldialog {
  max-height: 82vh;
}

::v-deep .el-dialog__body {
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

::v-deep .share .el-dialog__body {
  height: 270px;
  overflow-y: scroll;
}

.head-container {
  font-size: 14px;
}
.schedule-tab {
  display: flex;
  height: 100%;
  align-items: center;
  margin-bottom: 20px;
}
.schedule-tab-left {
  width: 80px;
  text-align: center;
  font-size: 30px;
}
.schedule-tab-content {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  margin: 0 10px;
}

.schedule-tab-content >>> .el-button--primary {
  border: none;
}
.schedule-tab-content button {
  height: 40px;
  padding: 0 35px;
}
::-webkit-scrollbar {
  /* 隐藏滚动条 */
  display: none;
}
/* ::-webkit-scrollbar {
  width: 0;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #d3d3d3;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
  border-radius: 10px;
  background: #ededed;
} */

.usage {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.usage >>> .el-button--primary {
  border: none;
}
.usage button {
  width: 240px;
  height: 70px;
  font-size: 16px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}
.recommended {
  height: 570px;
  overflow-y: scroll;
}
.recommended .recommended-item {
  margin-bottom: 20px;
}

.filter-list {
  padding-top: 10px;
  max-height: 200px;
  overflow-y: auto;
}
.filter-list .filter-list-item {
  padding: 0 10px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.55);
}
.filter-list .filter-list-item:hover {
  background: rgba(24, 144, 255, 0.2);
  color: #1890ff;
}

.filter-list-item.active {
  background: rgba(24, 144, 255, 0.2);
  color: #1890ff;
}
</style>
