import request from '@/utils/request'

// 查询cms栏目列表
export function listCmsChannel(query) {
  return request({
    url: '/cms/channel/list',
    method: 'get',
    params: query
  })
}

// 查询cms栏目详细
export function getCmsChannel(id) {
  return request({
    url: '/cms/channel/get?id=' + id,
    method: 'get'
  })
}

// 新增cms栏目
export function addCmsChannel(data) {
  return request({
    url: '/cms/channel/add',
    method: 'post',
    data: data
  })
}

// 修改cms栏目
export function updateCmsChannel(data) {
  return request({
    url: '/cms/channel/edit',
    method: 'post',
    data: data
  })
}

// 删除cms栏目
export function delCmsChannel(id) {
  return request({
    url: '/cms/channel/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
