<template>
  <div class="app-container app-store-wrapper">
    <!--<el-tabs v-model="activeName" >
      <el-tab-pane label="我的应用" name="my">-->
        <div class="app-item" v-if="false">
          <el-link href="#"  type="primary" :underline="false" @click.native="handleAppItemClick('type',null, appTypeOptions)">来源</el-link>
          <el-link href="#" :class="{'is-active':item.active}" @click.native="handleAppItemClick('type',item, appTypeOptions)" :underline="false" v-for="(item,index) in appTypeOptions" :key="index">{{item.value}}</el-link>
        </div>
        <div class="app-item">
          <el-link href="#" type="primary" :underline="false" @click.native="handleAppItemClick('category',null, appCategoryOptions)">分类</el-link>
          <el-link href="#" :class="{'is-active':item.active}" @click.native="handleAppItemClick('category',item,appCategoryOptions)" :underline="false" v-for="(item,index) in appCategoryOptions" :key="index">{{item.value}}</el-link>
        </div>
        <!--<div class="app-item">
          <el-link href="#" type="primary" :underline="false" @click.native="handleAppItemClick('cost',null, appCostOptions)">价格</el-link>
          <el-link href="#" :class="{'is-active':item.active}" @click.native="handleAppItemClick('cost',item,appCostOptions)" :underline="false" v-for="(item,index) in appCostOptions" :key="index">{{item.value}}</el-link>
        </div>-->
        <div class="app-item-condition">
          <el-row :gutter="24">
            <el-col :span="21">
              <el-link href="#" type="primary" :underline="false" v-if="queryTags.length == 0">推荐</el-link>
              <el-tag
                v-for="tag in queryTags"
                :key="tag.name"
                closable
                @close="handleTagClose(tag)"
                type="">
                {{tag.name}}
              </el-tag>
            </el-col>
            <el-col :span="3">
              <el-input v-model="queryParams.keyword" placeholder="请输入关键词" prefix-icon="el-icon-search" clearable @clear="handleInputKeyword" @input.native="handleInputKeyword"/>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="20">
          <el-col :span="4" v-for="(item, index) in appList" :key="item.id"  class="app-card-container">
            <el-popover
              placement="right-start"
              :title="item.display_name"
              width="250"
              trigger="hover"
              :content="item.description" popper-class="app-card-popover">
              <div class="app-card-popover-content">
                <p>{{item.description}}</p>
                <el-divider></el-divider>
                <el-descriptions class="margin-top" title="" :column="2" >
                  <el-descriptions-item label="分类" :colon="true">
                    <el-tag size="mini" :type="appCategoryFormatType(item.category)">{{appCategoryFormat(item.category)}}</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item label="版本" :colon="true">V{{item.version}}</el-descriptions-item>
                  <el-descriptions-item label="浏览" :colon="true">{{item.views}}</el-descriptions-item>
                  <el-descriptions-item label="安装" :colon="true">{{item.installs}}</el-descriptions-item>
                </el-descriptions>
              </div>
            <el-card :body-style="{ padding: '0px' }"  @click.native="openApp(item)"  shadow="hover" class="app-card" slot="reference">
              <div class="category">
                <el-tag size="small" :type="appCategoryFormatType(item.category)" >{{appCategoryFormat(item.category)}}</el-tag>
              </div>
              <img src="#" class="image" v-if="false">
              <div class="image" v-else>
                <i v-if="item.icon && item.icon.indexOf('el-icon-') === 0" slot="prefix" :class="'el-icon ' +item.icon" />
                <i v-else-if="item.icon && item.icon.indexOf('fa fa-') === 0" slot="prefix" :class="item.icon" />
                <svg-icon
                  v-else-if="item.icon"
                  slot="prefix"
                  :icon-class="item.icon"
                  class="el-input__icon"
                />
              </div>
              <div style="padding: 14px;">
                <div class="title" >{{item.display_name}}</div>
                <div class="bottom clearfix">
                  <span><el-link type="info" :underline="false">V{{item.version}}</el-link></span>
                  <span class="cost">
                    <!--<el-link type="danger" :underline="false" v-if="item.sell_price>0">￥{{item.sell_price.toFixed(2)}}</el-link>
                    <el-link type="success" :underline="false" v-else>免费</el-link>-->
                    <el-link type="info" icon="el-icon-view" :underline="false" class="views">{{item.views}}</el-link>
                  </span>
                </div>
              </div>
            </el-card>
            </el-popover>
          </el-col>
          <template v-if="!loading && appList.length <= 0"><el-empty description="暂无数据" ></el-empty></template>
        </el-row>
        <div style="width: 100%; height: 100px;">
          <pagination
            v-show="total>0"
            layout="total, prev, pager, next"
            :total="total"
            :limit.sync="queryParams.pageSize"
            :page.sync="queryParams.pageNum"
            @pagination="getList"
          />
        </div>
      <!--</el-tab-pane>
      <el-tab-pane label="应用市场" name="store">
        <el-link :underline="false">敬请期待...</el-link>
      </el-tab-pane>
    </el-tabs>-->


  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import path from 'path'
  import { getAppRouters } from '@/api/menu'
  import { marketApplication, applicationIncViews } from '@/api/core/system/application'
  export default {
    name: "AppHome",
    data() {
      return {
        activeName:'my',
        appTypeOptions: [],
        appCostOptions: [
          {key:"0",value:'免费', active:false},
          {key:"1",value:'付费', active:false},
        ],
        appCategoryOptions: [],
        appCategoryMap:{},
        queryTags: [],
        appList:[],
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 总条数
        total: 0,
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 12,
          keyword: undefined,
        },
      };
    },
    created() {
      this.loading = true
      this.getDicts('sys_app_category').then(res => {
        this.appCategoryOptions = res.data.values
        const appCategoryMap = {}
        for (let i = 0; i < res.data.values.length; i++) {
          const d = res.data.values[i]
          appCategoryMap[d.key] = d
        }
        this.appCategoryMap = appCategoryMap
      })
      this.getList()
    },
    methods: {
      appCategoryFormatType(category) {
        if(this.appCategoryMap.hasOwnProperty(category)) {
          return this.appCategoryMap[category].remark;
        }
        return '';
      },
      appCategoryFormat(category) {
        if(this.appCategoryMap.hasOwnProperty(category)) {
          return this.appCategoryMap[category].value;
        }
        return '';
      },
      handleAppItemClick(type, item,options){
        options.forEach( row => {
          row.active = false
        })
        this.removeTypeTags(type)
        if(item) {
          item.active = true
          this.queryTags.push({key: item.key, name: item.value, type: type})
        }
        this.handleQuery();
      },
      handleInputKeyword() {
        this.removeTypeTags('keyword')
        if(this.queryParams.keyword) {
          this.queryTags.push({key: this.queryParams.keyword, name: '关键词: '+this.queryParams.keyword, type: 'keyword'})
        }
        this.handleQuery();
      },
      removeTypeTags(type) {
        for(let i=0;i<this.queryTags.length;i++){
          let item = this.queryTags[i]
          //删除掉所有为2的元素
          if(item.type == type){
            this.queryTags.splice(i,1);
          }
        }
      },
      handleTagClose(tag) {
        this.queryTags.splice(this.queryTags.indexOf(tag), 1);
        if(tag.type == 'cost') {
          this.appCostOptions.forEach( row => {
            row.active = false
          })
        }else if(tag.type == 'category') {
          this.appCategoryOptions.forEach(row => {
            row.active = false
          })
        }else if(tag.type == 'keyword') {
          this.queryParams.keyword = undefined
        }
        this.handleQuery();
      },
      handleSizeChange ({ currentPage, pageSize }) {
        this.queryParams.pageNum = currentPage;
        if(this.queryParams.pageSize == pageSize){
          this.getList();
        }else{
          this.queryParams.pageSize = pageSize;
          this.handleQuery();
        }

      },
      /** 查询流程列表 */
      getList() {
        const queryParams = {
          ...this.queryParams
        }
        for (let i = 0; i < this.queryTags.length; i++) {
          const d = this.queryTags[i]
          if (d.type == 'category') {
            queryParams.category = d.key
          } else if (d.type == 'cost') {
            queryParams.price = d.key
          }
        }
        marketApplication(queryParams).then(response => {
          this.appList = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        });
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.resetForm("queryForm");
        this.handleQuery();
      },
      getFirstMenuPath(route,basePath="/") {
        if(route == null) {
          return "";
        }
        if(route.menu_type ==  "1") {
          return path.resolve(basePath, route.path);
        }
        for( let key in route.children ) {
          let item = route.children[key];
          return this.getFirstMenuPath(item, basePath+ route.path + "/")
        }
        return "";
      },
      openApp(row) {
        /*let routePath = this.getFirstMenuPath(row)
        if(routePath == "") {
          this.$alert('没有子菜单', "提示", { });
          return;
        }
        this.$router.push({ path: routePath });*/
        applicationIncViews(row.id)
        let url = ''
        if (row.homepage_url) {
          url = row.homepage_url
        } else {
          url = window.location.origin + '/' + row.name + '/'
        }
        if (row.open_type == 'current_window') {
          window.location.href = url
        } else {
          window.open(url, row.userpool + "_" + row.name)
        }
      },
    }
  };
</script>
<style lang="scss" >
  .app-card-popover {
    padding-bottom: 5px;
    .app-card-popover-content {
      .el-divider--horizontal {
        margin-bottom: 10px;
        margin-top: 0;
      }
      .el-descriptions-item__container {
        color: #909399;
      }
    }
  }
  .app-store-wrapper {
    .app-item {
      height: 30px;
      line-height: 30px;
      a {
        margin-left: 15px;
        &:hover {
          text-decoration: underline dotted;
        }
      }
      a:first-child {
        margin-right: 5px;
        margin-left: 0;
        text-decoration: none;
        /*color:#303133;*/
      }
      a.is-active {
        color: #1890ff;
        text-decoration: underline dotted;
      }
    }
    .app-item-condition {
      height: 33px;
      line-height: 33px;
      /*margin-top: 10px;*/
      margin-bottom: 5px;
    }
    .app-card-container {
      position:relative;
      margin-bottom: 20px;
      cursor: pointer;
      .category {
        position: absolute;
        top:10px;
        right:15px;
      }
      .el-card {
        /*height: 260px;*/
        &:hover{
          border: 1px solid #1890ff;
        }
      }
      .app-card {
        .title {
          display: block;
          overflow: hidden;
          /*font-size: 14px;*/
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .bottom {
          margin-top: 13px;
          line-height: 12px;
          font-size: 13px;
        }
        .cost {
          padding: 0;
          float: right;
          .el-icon-view {
            margin-left: 10px;
          }
        }
        .image {
          width: 100%;
          height: 180px;
          line-height: 180px;
          text-align: center;
          color:#1890ff;
          font-size: 5em;
          display: block;
        }
        .clearfix:before,
        .clearfix:after {
          display: table;
          content: "";
        }
        .clearfix:after {
          clear: both
        }
      }
    }
  }
</style>
