import request from '@/utils/request'

export function listGbpxCourseTeacher(query) {
  return request({
    url: '/gbpx/gbpxcourseteacher/list',
    method: 'get',
    params: query
  })
}

export function getGbpxCourseTeacher(id) {
  return request({
    url: '/gbpx/gbpxcourseteacher/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxCourseTeacher(data) {
  return request({
    url: '/gbpx/gbpxcourseteacher/add',
    method: 'post',
    data: data
  })
}

export function editGbpxCourseTeacher(data) {
  return request({
    url: '/gbpx/gbpxcourseteacher/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxCourseTeacher(ids) {
  return request({
    url: '/gbpx/gbpxcourseteacher/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

