<template>
    <div v-if="designer.isTopic">
        <el-form-item :label="i18nt('designer.setting.answerKey')">
        </el-form-item>
        <el-input
            type="textarea"
            rows="4"
            placeholder="请输入内容"
            v-model="optionModel.answerKey"
        >
        </el-input>
    </div>
</template>
<script>
import i18n from "@/vform/utils/i18n";
export default {
    name: "answer-key-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {};
    },
    created() {},
};
</script>