<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">  
      <el-button icon="el-icon-question" style="font-weight:bolder;border: 0;">设备联动</el-button>    
      <el-form-item label="所属资源空间" prop="space_id">
        <el-select v-model="queryParams.space_id" placeholder="请选择资源空间"  size="small" @change="handleQuery">
          <el-option
            v-for="dict in spaceList"
            :key="dict.Id"
            :label="dict.space_name"
            :value="dict.Id"
          />
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item> -->
    </el-form>
    <el-row/>

    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="linkageList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >创建规则</el-button>
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </template>
      <!--状态栏-->
      <template #status="{row}">
        <el-switch
                v-model="row.status"
                :active-value="1"
                :inactive-value="0"
                @change="handleStatusChange(row)"
        ></el-switch>
      </template>
      <!--默认操作按钮-->
      <template #defaultopr="{ row }">        
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
        >修改
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>      
      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="900px" append-to-body >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">        
        <el-form-item label="所属资源空间" >
          <el-select v-if="form.id==undefined" v-model="queryParams.space_id" placeholder="请选择所属资源空间" clearable size="small" disabled>
            <el-option
              v-for="dict in spaceList"
              :key="dict.Id"
              :label="dict.space_name"
              :value="dict.Id"
            />
          </el-select>
          <el-select v-else v-model="form.space_id" placeholder="请选择所属资源空间" clearable size="small" disabled>
            <el-option
              v-for="dict in spaceList"
              :key="dict.Id"
              :label="dict.space_name"
              :value="dict.Id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="规则名称" prop="linkage_name">
          <el-input v-model="form.linkage_name" placeholder="输入规则名称" />
        </el-form-item> 
        <el-form-item label="激活规则" >
          <el-switch v-model="form.status" :active-value="1" :inactive-value="0" />        
        </el-form-item>
        <el-form-item label="生效时间" >          
          <div style="display:inline-block;">
                <el-radio-group v-model="form.running_time">
                    <el-radio-button :label="0">一直生效</el-radio-button>
                    <el-radio-button :label="1">指定时间</el-radio-button>            
                </el-radio-group >                
                <div v-if="form.running_time==1" style="display:inline-block;font-size:13px;color:#859094;margin-left:20px" >(不勾选则默认每天时间段生效)</div>
            </div>  
            <el-checkbox-group v-if="form.running_time==1" v-model="runningTimeCheckList" :min="0">
                <el-checkbox :label="1">周一</el-checkbox>
                <el-checkbox :label="2">周二</el-checkbox>
                <el-checkbox :label="3">周三</el-checkbox>
                <el-checkbox :label="4">周四</el-checkbox>
                <el-checkbox :label="5">周五</el-checkbox>
                <el-checkbox :label="6">周六</el-checkbox>
                <el-checkbox :label="7">周日</el-checkbox>
            </el-checkbox-group>
            <el-time-picker
                    v-if="form.running_time==1"
                    is-range
                    v-model="timeValue"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围">
                </el-time-picker>                      
        </el-form-item>
        <el-form-item label="描述" >
          <el-input v-model="form.remark" type="textarea" placeholder="可选填" />
        </el-form-item>
        <el-form-item label="触发条件" ><!--参照设备详情里的设备影子-->
          <vxe-grid            
                ref="attributeGrid"
                stripe   
                show-overflow
                highlight-hover-row
                :loading="conditionloading"
                :toolbar-config="conditiontableToolbar"
                :columns="conditiontableColumn"
                :data="conditionList"
                :column-config="{resizable: false}"
                @checkbox-change="conditioncheckboxChangeEvent"
                @checkbox-all="conditioncheckboxChangeEvent">
                <template #toolbar_buttons>
                    <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="conditionhandleAdd"
                    >添加条件</el-button>
                    <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="conditionsingle"
                    @click="conditionhandleUpdate"
                    >修改</el-button>
                    <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="conditionmultiple"
                    @click="conditionhandleDelete"
                    >删除</el-button>
                </template>
                <!--默认操作按钮-->
              <template #defaultopr="{ row }">                
                <el-button size="mini" type="text" icon="el-icon-edit" @click="conditionhandleUpdate(row)"
                >修改
                </el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="conditionhandleDelete(row)"
                >删除
                </el-button>
              </template>
                <!--自定义空数据模板-->
                <template #empty>
                      <span >
                        <p>暂无数据</p>
                      </span>
                </template>

          </vxe-grid>          
        </el-form-item>
        <el-form-item label="执行动作" ><!--参照设备详情里的命令-->
          <vxe-grid            
                ref="commandGrid"
                stripe   
                show-overflow
                highlight-hover-row
                :loading="commandloading"
                :toolbar-config="commandtableToolbar"
                :columns="commandtableColumn"
                :data="commandList"
                :column-config="{resizable: false}"
                @radio-change="commandcheckboxChangeEvent">
                <template #toolbar_buttons>
                    <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="commandhandleAdd"
                    >添加动作</el-button>
                    <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="commandsingle"
                    @click="commandhandleUpdate"
                    >修改</el-button>
                    <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="commandmultiple"
                    @click="commandhandleDelete"
                    >删除</el-button>
                </template>
                <template #defaultopr="{ row }">                
                <el-button size="mini" type="text" icon="el-icon-edit" @click="commandhandleUpdate(row)"
                >修改
                </el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="commandhandleDelete(row)"
                >删除
                </el-button>
              </template>
                <!--自定义空数据模板-->
                <template #empty>
                      <span >
                        <p>暂无数据</p>
                      </span>
                </template>

          </vxe-grid>          
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 添加修改触发条件 -->
    <el-dialog :close-on-click-modal="false" :title="conditiontitle" :visible.sync="conditionopen" width="600px" append-to-body>
      <el-form ref="conditionform" :model="conditionform" :rules="conditionrules" label-width="80px">          
       <el-form-item label="触发类型" prop="condition_type">
        <el-select v-model="conditionform.condition_type" disabled placeholder="请选择触发类型" clearable size="small" @change="conditionTypeChange">
          <el-option
            v-for="dict in conditionTypeList"
            :key="dict.id"
            :label="dict.name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="触发对象" prop="object">
        <el-select v-if="conditionform.condition_type==0" v-model="conditionform.object" placeholder="请选择触发对象" clearable size="small" @change="conditionObjectChange">
          <el-option            
            v-for="dict in conditionObjectList"
            :key="dict.id"
            :label="dict.name"
            :value="dict.id"
          />
        </el-select>
        <el-select v-else v-model="conditionform.object" placeholder="请选择触发对象" clearable size="small" @change="conditionObjectChange">
          <el-option            
            v-for="dict in conditionObjectList"
            :key="dict.id"
            :label="dict.device_name"
            :value="dict.id"
          />
        </el-select>
        
      </el-form-item>
      <el-form-item label="服务选择" prop="service">
        <el-select v-model="conditionform.service" placeholder="请选择服务" clearable size="small" @change="conditionServiceChange">
          <el-option
            v-for="dict in conditionServiceList"
            :key="dict.id"
            :label="dict.service_name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="属性名称" prop="attribute">
        <el-select v-model="conditionform.attribute" placeholder="请选择属性" clearable size="small">
          <el-option
            v-for="dict in conditionAttributeList"
            :key="dict.id"
            :label="dict.name"
            :value="dict.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="条件选择" prop="compare">
        <el-select v-model="conditionform.compare" placeholder="请选择条件" clearable size="small">
          <el-option
            v-for="dict in conditionCompareList"
            :key="dict.id"
            :label="dict.name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="值" prop="value">
        <el-input v-model="conditionform.value" placeholder="输入值" />
      </el-form-item>
      <el-form-item label="触发时效" prop="limit">
        <el-select v-model="conditionform.limit" placeholder="请选择触发时效" clearable size="small">
          <el-option
            v-for="dict in conditionLimitList"
            :key="dict.id"
            :label="dict.name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="conditionsubmitForm">确 定</el-button>
        <el-button @click="conditioncancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 添加修改执行动作 -->
    <el-dialog :close-on-click-modal="false" :title="commandtitle" :visible.sync="commandopen" width="600px" append-to-body>
      <el-form ref="commandform" :model="commandform" :rules="commandrules" label-width="80px">          
       <el-form-item label="动作类型" prop="command_type">
        <el-select v-model="commandform.command_type" placeholder="请选择动作类型" clearable size="small" >
          <el-option
            v-for="dict in commandTypeList"
            :key="dict.id"
            :label="dict.name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="触发对象" prop="object">        
        <el-select v-model="commandform.object" placeholder="请选择触发对象" clearable size="small" @change="commandObjectChange">
          <el-option            
            v-for="dict in commandObjectList"
            :key="dict.id"
            :label="dict.device_name"
            :value="dict.id"
          />
        </el-select>
        
      </el-form-item>
      <el-form-item label="服务选择" prop="service">
        <el-select v-model="commandform.service" placeholder="请选择服务" clearable size="small" @change="commandServiceChange">
          <el-option
            v-for="dict in commandServiceList"
            :key="dict.id"
            :label="dict.service_name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="动作名称" prop="command">
        <el-select v-model="commandform.command" placeholder="请选择动作" clearable size="small" @change="commandserviceCommandChange">
          <el-option
            v-for="dict in commandserviceCommandList"
            :key="dict.id"
            :label="dict.name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>  
        
            <table  style="display:inline-block;width: 100%" cellspacing=0>             
                <tbody style="display:inline-block;margin-left:50px;">
                    <tr v-for="(item,index) in sendcommandAttributeList" :key="index" style="width: 300px" >                  
                    <td ><div class="cell"  >{{item.name}}</div></td><el-input v-model="sendcommandAttributeList[index].value" placeholder="值" style="display:inline-block;margin-left:20px;margin-top:5px;"/>
                    </tr>
                </tbody>
            </table>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commandsubmitForm">确 定</el-button>
        <el-button @click="commandcancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listIotProduct, getIotProduct, addIotProduct, updateIotProduct, delIotProduct } from "@/api/iot/product";
import { addIotRuleLinkage, getIotRuleLinkage, updateIotRuleLinkage, delIotRuleLinkage, listIotRuleLinkage } from "@/api/iot/rulelinkage";
import { listIotProductService, getIotProductService, addIotProductService, updateIotProductService, delIotProductService } from "@/api/iot/productservice";
import { listIotServiceAttribute, getIotServiceAttribute, addIotServiceAttribute, updateIotServiceAttribute, delIotServiceAttribute ,delIotServiceAttributeByCommandId} from "@/api/iot/serviceattribute";
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";
import { listIotServiceCommand, getIotServiceCommand, addIotServiceCommand, updateIotServiceCommand, delIotServiceCommand } from "@/api/iot/servicecommand";
import { addIotDeviceCommand, getIotDeviceCommand, updateIotDeviceCommand, delIotDeviceCommand, listIotDeviceCommand } from "@/api/iot/devicecommand";
import { addIotDeviceAttribute, getIotDeviceAttribute, updateIotDeviceAttribute, delIotDeviceAttribute,delIotDeviceAttributeByCommandId, listIotDeviceAttribute } from "@/api/iot/deviceattribute";
import { listIotCase} from "@/api/iot/case";
export default {
  name: "IotLinkage",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: 'status', title: '状态' , slots: {default: 'status'} },
        {field: 'linkage_name', title: '规则名称' },
        {field: 'running_time', title: '生效时间' ,formatter:this.runningtimeFormat},
        {field: '', title: '所属资源空间' ,formatter:this.spaceFormat},
        {field: 'remark', title: '描述' },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 分类表格数据
      linkageList: [],
      spaceList:[],
      productList:[],
      serviceList:[],
      serviceAttributeList:[],
      serviceCommandList:[],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        case_id: undefined,
        space_id: undefined
      },
      space_id:0,
      // 表单参数
      form: {},
      // 表单校验
      runningtime:"",
      rules: {
        linkage_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ]
      },
      conditionqueryParams:{
        pageNum: 1,
        pageSize: 10,
      },      
      conditiontableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,        
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      commandtableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,        
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      conditiontableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: 'condition_type', title: '类型' ,formatter:this.typeFormat },
        {field: 'object', title: '对象' ,formatter:this.objectFormat },
        {field: '', title: '属性' ,formatter:this.attributeFormat },
        {field: 'value', title: '值' },
        {field: '', title: '操作',resizable:false, width: 150, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      conditionloading: false,
      // 选中数组
      conditionids: [],
      // 非单个禁用
      conditionsingle: true,
      // 非多个禁用
      conditionmultiple: true,
      // 总条数
      conditiontotal: 0,
      conditionrules:{},
      conditionopen:false,
      conditionform:{},
      conditiontitle:"添加触发条件",
      conditionList: [],
      conditionTypeList:[{id:0,name:"匹配产品触发"},{id:1,name:"指定设备触发"},{id:2,name:"每日定时触发"},{id:3,name:"按策略定时触发"}],      
      conditionObjectList:[],
      conditionServiceList:[],
      conditionAttributeList:[],
      conditionCompareList:[{id:0,name:"="},{id:1,name:">"},{id:2,name:"<"},{id:3,name:">="},{id:4,name:"<="}],
      conditionLimitList:[{id:100,name:"100S"},{id:200,name:"200S"},{id:300,name:"300S"}],
        deviceList:[],
        // 列
      commandtableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: 'command_type', title: '类型' ,formatter:this.commandtypeFormat },
        {field: 'object', title: '设备' ,formatter:this.commandobjectFormat },
        {field: 'command_name', title: '动作'},
        {field: '', title: '操作',resizable:false, width: 150, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      commandloading: false,
      // 选中数组
      commandids: [],
      // 非单个禁用
      commandsingle: true,
      // 非多个禁用
      commandmultiple: true,
      // 总条数
      commandtotal: 0,
      commandrules:{
          value: [
          { required: true, message: "值不能为空", trigger: "blur" }
        ]
      },
      commandopen:false,
      commandform:{},
      commandtitle:"添加执行动作",
      commandList: [],
      commandTypeList:[{id:0,name:"下发命令"},{id:1,name:"发送通知"},{id:2,name:"上报警告"}], 
      commandObjectList:[],
      commandServiceList:[],
      commandserviceCommandList:[],
      sendcommandAttributeList:[],
      runningTimeCheckList:[],//1234567指定时间
      timeValue:[new Date(2022, 9, 10, 8, 30,0), new Date(2022, 9, 10, 10, 30,0)],
    };
  },
  created() {
    this.queryParams.space_id = this.$route.query && this.$route.query.space_id;
    this.getParams();        
  },
  methods: {
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length
    },
    conditioncheckboxChangeEvent({ records }) {
      this.conditionids = records.map(item => item.id)
      this.conditionsingle = records .length!=1;
      this.conditionmultiple = !records .length
    },
    commandcheckboxChangeEvent({ records }) {
      this.commandids = records.map(item => item.id)
      this.commandsingle = records .length!=1;
      this.commandmultiple = !records .length
    },
    getParams(){
      var casequeryParams={
        pageNum: 1,
        pageSize: 10,
        node_id: undefined      
      };
      listIotCase(casequeryParams).then(response =>{
          for (var index=0;index<response.data.list.length;index++){
            if (response.data.list[index].is_choose >= 1){
              this.queryParams.case_id=response.data.list[index].Id;
              
            }              
          }   
          this.getList();
          this.getDeviceList(); 
        })
    },
    /** 查询分类列表 */
    getList() {
      this.loading = true;          
      listIotRuleLinkage(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;           
        this.total = response.data.total;
        this.linkageList = response.data.list; 
        this.spaceList = response.data.space_list; 
        this.productList = response.data.product_list; 
        this.serviceList = response.data.service_list; 
        this.serviceAttributeList = response.data.serviceAttribute_list;
        this.serviceCommandList = response.data.serviceCommand_list; 
        this.statusOptions = response.data.statusOptions.values;   
        //if(this.spaceList.length>0)  
        //    this.queryParams.space_id=this.spaceList[0].Id;
        this.loading = false;
        console.log(response.data.list)
        }).catch(function (error){
          console.log(error);
        });
    },
    getDeviceList(){
        var devicequeryParams={
            pageNum: 1,
            pageSize: 10,
            group_id:-1,
            device_name: "",
            device_check:"",
            device_code:""
        };
        listIotDevice(devicequeryParams).then(response => {        
        this.deviceList = response.data.list;        
      });
    },
    conditionTypeChange(){
        this.conditionObjectList=[];
        this.conditionServiceList=[];
        this.conditionAttributeList=[];
        if(this.conditionform.condition_type==0){
            this.conditionObjectList=this.productList;
        }
        if(this.conditionform.condition_type==1){
            this.conditionObjectList=this.deviceList;
        }
    },
    conditionObjectChange(){        
        this.conditionServiceList=[];
        this.conditionAttributeList=[];
        if(this.conditionform.condition_type==0){
            var sevicequeryParams={
                    pageNum: 1,
                    pageSize: 10,
                    product_id:this.conditionform.object
            };
            listIotProductService(sevicequeryParams).then(response =>{
                this.conditionServiceList=response.data.list;
            });
        }
        if(this.conditionform.condition_type==1){
            var productId=0;
            this.deviceList.forEach(element=>{
                if(element.id==this.conditionform.object){
                    productId=element.product_id;
                }
            });
            if(productId>0){
                var sevicequeryParams={
                    pageNum: 1,
                    pageSize: 10,
                    product_id:productId
                };            
                listIotProductService(sevicequeryParams).then(response =>{
                    this.conditionServiceList=response.data.list;
                });
            }
            else
                this.msgError("产品ID出错!");
           
        }
    },
    commandObjectChange(){
        var productId=0;
            this.deviceList.forEach(element=>{
                if(element.id==this.commandform.object){
                    productId=element.product_id;
                }
            });
            if(productId>0){
                var sevicequeryParams={
                    pageNum: 1,
                    pageSize: 10,
                    product_id:productId
                };            
                listIotProductService(sevicequeryParams).then(response =>{
                    this.commandServiceList=response.data.list;
                });
            }
            else
                this.msgError("产品ID出错!");
    },
    conditionServiceChange(){
        this.conditionAttributeList=[];
        var attqueryParams={            
            pageNum: 1,
            pageSize: 10,
            service_id: this.conditionform.service,
            rule_id: 0,
            command_id:0     
        };
        listIotServiceAttribute(attqueryParams).then(response =>{
          if (response.code ===0){
            this.conditionAttributeList=response.data.list;  
          }else {
            this.msgError(response.msg);
          }
        })
    },
    commandServiceChange(){
        this.commandserviceCommandList=[];
        var attqueryParams={            
            pageNum: 1,
            pageSize: 10,              
            service_id: this.commandform.service,   
        };
        listIotServiceCommand(attqueryParams).then(response =>{
          if (response.code ===0){
            this.commandserviceCommandList=response.data.list;             
          }else {
            this.msgError(response.msg);
          }
        })
    },
    commandserviceCommandChange(){
        //this.sendcommandAttributeList=[];
        var sendattr={
                  pageNum: 1,
                  pageSize: 10,
                  service_id: this.commandform.service,
                  rule_id: -1,
                  command_id:this.commandform.command
                };
                listIotServiceAttribute(sendattr).then(response3 =>{ 
                    this.sendcommandAttributeList=   response3.data.list;             
                  
                });
    },
    typeFormat({row}){
        var label="";
        this.conditionTypeList.forEach(element =>{
            if(element.id==row.condition_type)
                label=element.name;
        })
        return label
    },
    commandtypeFormat({row}){
        var label="";
        this.commandTypeList.forEach(element =>{
            if(element.id==row.command_type)
                label=element.name;
        })
        return label
    },
    spaceFormat({row}){
        var label="";
        this.spaceList.forEach(element =>{
            if(element.Id==row.space_id)
                label=element.space_name;
        })
        return label
    },
    objectFormat({row}){
        var label="";
        if(row.condition_type==0){
            this.productList.forEach(element =>{
            if(element.id==row.object)
                label="产品："+element.name;
            })
        }else if(row.condition_type==1){
            this.deviceList.forEach(element =>{
            if(element.id==row.object)
                label="设备："+element.device_name;
            })
        }
        
        return label
    },
    commandobjectFormat({row}){
        var label="";
        this.deviceList.forEach(element =>{
            if(element.id==row.object)
                label="设备："+element.device_name;
            })
        
        return label
    },
    attributeFormat({row}){
        var label="";
        this.serviceAttributeList.forEach(element =>{
            if(element.name ==row.attribute)
                label=element.name;
        })
        return label
    },
    conditionhandleAdd(){  
        this.conditionreset();
        this.conditionform.condition_type=1;
        this.conditionform.limit=100;
        this.conditionTypeChange();
        this.conditionopen = true;

    },
    conditionhandleUpdate(row){        
        this.conditionform=row;
        this.conditiontitle="编辑触发条件";
        if(this.conditionform.condition_type==0){
                this.conditionObjectList=this.productList;
                var sevicequeryParams={
                        pageNum: 1,
                        pageSize: 10,
                        product_id:this.conditionform.object
                };
                listIotProductService(sevicequeryParams).then(response =>{
                    this.conditionServiceList=response.data.list;                
                });
            }            
            else if(this.conditionform.condition_type==1){
                this.conditionObjectList=this.deviceList;
                var productId=0;
                this.deviceList.forEach(element=>{
                    if(element.id==this.conditionform.object){
                        productId=element.product_id;
                    }
                });
                if(productId>0){
                    var sevicequeryParams={
                        pageNum: 1,
                        pageSize: 10,
                        product_id:productId
                    };            
                    listIotProductService(sevicequeryParams).then(response =>{
                        this.conditionServiceList=response.data.list;
                    });
                }
                else
                    this.msgError("产品ID出错!");
            }
        var attqueryParams={            
                pageNum: 1,
                pageSize: 10,
                service_id: this.conditionform.service,
                rule_id: 0,
                command_id:0     
            };
            listIotServiceAttribute(attqueryParams).then(response =>{
            if (response.code ===0){
                this.conditionAttributeList=response.data.list;  
            }else {
                this.msgError(response.msg);
            }
            })
        this.conditionopen = true;
       
        /* this.conditionreset();
        this.conditionList.forEach(element=>{
            if(row.id==element.id)
                this.conditionform=element;
        });
        this.conditionform=row;
        if( this.conditionform.id>0){
            this.conditiontitle="编辑触发条件";
            if(this.conditionform.condition_type==0){
                this.conditionObjectList=this.productList;
                var sevicequeryParams={
                        pageNum: 1,
                        pageSize: 10,
                        product_id:this.conditionform.object
                };
                listIotProductService(sevicequeryParams).then(response =>{
                    this.conditionServiceList=response.data.list;                
                });
            }            
            else if(this.conditionform.condition_type==1){
                this.conditionObjectList=this.deviceList;
                var productId=0;
                this.deviceList.forEach(element=>{
                    if(element.id==this.conditionform.object){
                        productId=element.product_id;
                    }
                });
                if(productId>0){
                    var sevicequeryParams={
                        pageNum: 1,
                        pageSize: 10,
                        product_id:productId
                    };            
                    listIotProductService(sevicequeryParams).then(response =>{
                        this.conditionServiceList=response.data.list;
                    });
                }
                else
                    this.msgError("产品ID出错!");
            }
            var attqueryParams={            
                pageNum: 1,
                pageSize: 10,
                service_id: this.conditionform.service,
                rule_id: 0,
                command_id:0     
            };
            listIotServiceAttribute(attqueryParams).then(response =>{
            if (response.code ===0){
                this.conditionAttributeList=response.data.list;  
            }else {
                this.msgError(response.msg);
            }
            })
            this.conditionopen = true;
        }    */
        
    },
    conditionsubmitForm:function(){
        this.$refs["conditionform"].validate(valid => {
            if (valid) { 
                if (this.conditionform.id != undefined){
                    this.conditionform.device_id=this.conditionform.object;
                    this.conditionform.command_id=0;  
                    this.conditionform.linkage_id=this.form.id;
                    this.conditionform.service_id=this.conditionform.service;
                    this.conditionform.name=this.conditionform.attribute
                    this.conditionform.happen_type=0;
                    this.conditionform.happen_value=this.conditionform.limit;
                    this.conditionform.remark=this.conditionform.compare;                                           
                    updateIotDeviceAttribute(this.conditionform).then(resp=>{
                        if (resp.code ===0){
                            this.msgSuccess("修改成功！")
                            this.conditionopen = false;
                        }                        
                    });                    
                }else{
                    this.conditionform.id=undefined;
                    this.conditionList.push(this.conditionform);
                    this.conditionopen = false;
                }
            }
        });

        
    },
    // 取消按钮
    conditioncancel() {
      this.conditionopen = false;
      this.conditionreset();
    },
    // 表单重置
    conditionreset() {
        this.conditionform = {
            id:undefined,
            value:""
        };
        //this.conditionObjectList=[];
        //this.conditionServiceList=[];
        //this.conditionAttributeList=[];
        //this.resetForm("conditionform");
    },
    commandhandleUpdate(row){
        this.commandreset();
        this.commandform=row;
        this.commandtitle="编辑动作";
        this.commandObjectList=this.deviceList;
        this.commandObjectChange();
        this.commandServiceChange();
        this.sendcommandAttributeList=row.valueList;
        this.commandopen = true;
    },
    commandhandleAdd(){           
        this.commandreset();
        this.commandObjectList=this.deviceList;
        this.commandopen = true;
    },
    commandsubmitForm(){
        this.$refs["commandform"].validate(valid => {
            if (valid) { 
                this.commandserviceCommandList.forEach(element=>{
                    if(this.commandform.command==element.id)
                        this.commandform.command_name=element.name;
                });
                if(this.sendcommandAttributeList.length>0)
                    this.commandform.valueList=this.sendcommandAttributeList;//id,name,value
                if (this.commandform.id != undefined){
                    console.log(this.commandform);
                    var att=0;//有没有参数
                    if(this.commandform.valueList.length>0)
                        att=0
                    else
                        att=-1
                    var devcom={
                        id:this.commandform.id,
                        device_id:this.commandform.object,
                        command_name:this.commandform.command_name,
                        status:0,
                        attribute_id:att,
                        send_time:0,
                        service_id:this.commandform.service,
                        command_id:this.commandform.command,
                        linkage_id:this.form.id
                    };
                    
                    updateIotDeviceCommand(devcom).then(resp=>{
                        this.commandform.valueList.forEach(element2=>{
                            console.log(this.element2);
                            element2.device_id=this.commandform.object;
                            element2.command_id=resp.data.id;  
                            element2.linkage_id=this.form.id;
                            element2.service_id=this.commandform.service;
                            if(element2.id>0){                                
                                updateIotDeviceAttribute(element2);
                            }else{                                
                                addIotDeviceAttribute(element2);
                            }
                            
                        });
                    });
                    this.commandopen = false;

                }else{
                    this.commandList.push(this.commandform);
                    this.commandopen = false;
                }
            }
        });
    },
    // 取消按钮
    commandcancel() {
      this.commandopen = false;
      this.commandreset();
    },
    // 表单重置
    commandreset() {
        this.commandform = {
            id:undefined,
            value:""
        };
        //this.commandObjectList=[];
        //this.commandServiceList=[];
        //this.commandserviceCommandList=[];
        this.sendcommandAttributeList=[];
        //this.resetForm("commandform");
    },
    // 取消按钮
    cancel() {
      this.open = false;      
      //this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id:undefined,
        linkage_name:""
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      if(this.queryParams.space_id>0){
        this.reset();
        this.open = true;
        this.title = "创建规则";
        //this.getDeviceList();
        this.conditionreset();
        this.commandreset();
        this.conditionList=[];
        this.commandList=[];
      }else
        this.msgError("请选择一个资源空间去创建规则！");
      
    },
    runningtimeFormat({row}){
        if(row.running_time>0)
            return this.timestampToTime(row.running_time);
        else
            return "一直生效";
    },
    protocolFormat({row}){
      this.labname="";
      this.statusOptions.forEach(element => {
        if (row.protocol_id == element.key)
          this.labname= element.value;        
      });
      return this.labname;
    },
    handleStatusChange(row) {
        let text = row.status === 1 ? "启用" : "停用";
        this.$confirm('确认要"' + text + '""' + row.linkage_name + '"吗?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            }).then(function() {
                updateIotRuleLinkage(row).then(response => {
                    if (response.code ===0) {
                        this.msgSuccess("修改成功");
                    } else {
                        this.msgError(response.msg);
                    }
                    }).catch(function (error){
                    console.log(error);
                });
                
            }).then(() => {
                this.msgSuccess(text + "成功");
            }).catch(function() {
            row.status = row.status === 0 ? 1 : 0;
        });        
    },
    
    /** 修改联动操作 */
    handleUpdate(row) {
        //this.getList();
        //this.reset();
        this.form = row;     
        this.conditionList=[];   
        var condition={
            pageNum: 1,
            pageSize: 10,
            linkage_id: this.form.id,
            command_id:0  
        };     
        this.conditionloading=true;
        listIotDeviceAttribute(condition).then(response=>{
            response.data.list.forEach(element=>{
                var object={
                    id:element.id,
                    condition_type:1,
                    object:element.device_id,
                    service:element.service_id,
                    attribute:element.name,
                    compare:parseInt(element.remark),
                    value:element.value,
                    limit:element.happen_value,
                };
                this.conditionList.push(object);

            })
            //console.log(this.conditionList);
            this.conditionloading=false;
        });
        this.commandList=[];
        var command={
            pageNum: 1,
            pageSize: 10,
            linkage_id: this.form.id 
        };
        this.commandloading=true;
        listIotDeviceCommand(command).then(response=>{
            response.data.list.forEach(element=>{ 
                var index=0;
               /*  var productid=0; 
                var serviceid=0; 
                var commandid=0; 
                thi.deviceList.forEach(el=>{
                    if(el.id==element.device_id){
                        productid=el.productid;
                    }
                }); 
                var sevicequeryParams={
                    pageNum: 1,
                    pageSize: 10,
                    product_id:productid
                };            
                listIotProductService(sevicequeryParams).then(response2 =>{
                    response2.data.list;
                }); */
                          
                var object={
                    id:element.id,
                    command_type:0,                    
                    object:element.device_id, 
                    service:element.service_id,
                    command:element.command_id,
                    command_name: element.command_name,
                    valueList:[]                 
                };
                if(element.attribute_id==0){
                    var condition={
                        pageNum: 1,
                        pageSize: 10,
                        linkage_id: this.form.id,
                        command_id:element.id  
                    };     
                    listIotDeviceAttribute(condition).then(response2=>{                        
                        object.valueList=response2.data.list; 
                        this.commandList.push(object);                       
                    });
                }
                else{
                    this.commandList.push(object);
                }
                
            });            
            this.commandloading=false;
        });       
        this.open = true;
        this.title = "修改规则";      
    },
    /** 更新提交联动规则 */
    submitForm: function() {        
      this.$refs["form"].validate(valid => {
        if (valid) {            
          if (this.form.id != undefined) {            
            updateIotRuleLinkage(this.form).then(response=>{
                if (response.code ===0) {
                //更新或添加条件
                this.conditionList.forEach(element=>{                    
                    if(element.id==undefined){
                        if(element.condition_type==0){

                        }else if(element.condition_type==1){  
                            element.device_id=element.object;
                            element.command_id=0;  
                            element.linkage_id=this.form.id;
                            element.service_id=element.service;
                            element.name=element.attribute
                            element.happen_type=0;
                            element.happen_value=element.limit;
                            element.remark=element.compare;                          
                            addIotDeviceAttribute(element);
                        }
                    }else if(element.id>0){                        
                        //updateIotDeviceAttribute(element);
                    }
                            
                });
                //更新或添加动作命令
                this.commandList.forEach(element=>{
                    if(element.id==undefined){
                            var att=0;//有没有参数
                            if(element.valueList.length>0)
                                att=0
                            else
                                att=-1
                            var devcom={
                                device_id:element.object,
                                command_name:element.command_name,
                                status:0,
                                attribute_id:att,
                                send_time:0,
                                service_id:element.service,
                                command_id:element.command,
                                linkage_id:this.form.id
                            };
                            addIotDeviceCommand(devcom).then(resp=>{
                                element.valueList.forEach(element2=>{
                                        element2.device_id=element.object;
                                        element2.command_id=resp.data.id;  
                                        element2.linkage_id=this.form.id;
                                        element2.service_id=element.service;
                                        addIotDeviceAttribute(element2);
                                });
                            });
                        }
                    else if(element.id>0){

                    }
                            
                });
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
              if(this.form.linkage_name!=""){
                    this.form.space_id=this.queryParams.space_id;
                    this.form.case_id=this.queryParams.case_id;
                    addIotRuleLinkage(this.form).then(response=>{
                        this.conditionList.forEach(element=>{
                            if(element.condition_type==0){

                            }else if(element.condition_type==1){
                                element.device_id=element.object;
                                element.command_id=0;  
                                element.linkage_id=response.data.id;
                                element.service_id=element.service;
                                element.name=element.attribute
                                element.happen_type=0;
                                element.happen_value=element.limit;
                                element.remark=element.compare;
                                addIotDeviceAttribute(element);
                            }
                        });
                        this.commandList.forEach(element=>{
                            var att=0;//有没有参数
                            if(element.valueList.length>0)
                                att=0
                            else
                                att=-1
                            var devcom={
                                device_id:element.object,
                                command_name:element.command_name,
                                status:0,
                                attribute_id:att,
                                send_time:0,
                                service_id:element.service,
                                command_id:element.command,
                                linkage_id:response.data.id
                            };
                            addIotDeviceCommand(devcom).then(resp=>{
                                element.valueList.forEach(element2=>{
                                        element2.device_id=element.object;
                                        element2.command_id=resp.data.id;  
                                        element2.linkage_id=response.data.id;
                                        element2.service_id=element.service;
                                        addIotDeviceAttribute(element2);
                                });
                            });
                        });
                        this.getList();
                        this.open = false;
                    });
              }              
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
        const ids = row.id || (this.ids ? this.ids.join(',') : '');
        this.$confirm('是否确认删除规则编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotRuleLinkage(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    conditionhandleDelete(row){
        const conditionids = row.id || (this.conditionids ? this.conditionids.join(',') : '');
        this.$confirm('是否确认删除规则编号为"' + conditionids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotDeviceAttribute(conditionids);
        }).then(() => {
            for(var i=0;i<this.conditionList.length;i++){
                if(this.conditionList[i].id==conditionids||this.conditionids.indexOf(this.conditionList[i].id)>-1)
                    this.conditionList.splice(i,1);
            }
          
            this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    commandhandleDelete(row){
        const commandids = row.id || (this.commandids ? this.commandids.join(',') : '');
        this.$confirm('是否确认删除规则编号为"' + commandids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotDeviceCommand(commandids);
        }).then(() => {
            for(var i=0;i<this.commandList.length;i++){
                if(this.commandList[i].id==commandids||this.commandids.indexOf(this.commandList[i].id)>-1)
                    this.commandList.splice(i,1);
            }
          
            this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
  }
};
</script>
