<template>
  <el-card>
    <el-tabs v-model="activeName">
      <el-tab-pane label="字段信息" name="cloum">
        <vxe-grid
          resizable
          ref="xGrid"
          id="id_xGrid"
          stripe
          highlight-hover-row
          :loading="loading"
          :toolbar-config="tableToolbar"
          :columns="tableColumn"
          :data="colList"
          :keep-source="true"
          :custom-config="{ storage: true }"
          :edit-config="editConfig"
          @checkbox-change="checkboxChangeEvent"
          @edit-disabled="editDisabledEvent"
          @checkbox-all="checkboxChangeEvent"
        >
          <template #html_type_default="{ row }">
            <span>{{ formathtml_type(row.html_type) }}</span>
          </template>
          <template #html_type="{ row }">
            <vxe-select v-model="row.html_type" type="text" transfer>
              <vxe-option
                v-for="item in html_type_list"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></vxe-option>
            </vxe-select>
          </template>

          <template #query_type="{ row }">
            <vxe-select v-model="row.query_type" type="text" transfer>
              <vxe-option
                v-for="item in query_type_list"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></vxe-option>
            </vxe-select>
          </template>

          <template #column_type="{ row }">
            <vxe-select v-model="row.column_type" type="text" transfer>
              <vxe-option
                v-for="item in column_type_list"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></vxe-option>
            </vxe-select>
          </template>

          <template #dict_type="{ row }">
            <vxe-select
              :clearable="true"
              v-model="row.dict_type"
              type="text"
              transfer
            >
              <vxe-option
                v-for="item in dictOptions"
                :key="item.type"
                :value="item.type"
                :label="item.name"
              ></vxe-option>
            </vxe-select>
          </template>

          <template #is_insert="{ row }">
            <el-switch
              active-value="1"
              inactive-value="0"
              v-model="row.is_insert"
            ></el-switch>
          </template>

          <template #is_edit="{ row }">
            <el-switch
              active-value="1"
              inactive-value="0"
              v-model="row.is_edit"
            ></el-switch>
          </template>

          <template #is_list="{ row }">
            <el-switch
              active-value="1"
              inactive-value="0"
              v-model="row.is_list"
            ></el-switch>
          </template>

          <template #is_query="{ row }">
            <el-switch
              active-value="1"
              inactive-value="0"
              v-model="row.is_query"
            ></el-switch>
          </template>

          <template #is_required="{ row }">
            <el-switch
              active-value="1"
              inactive-value="0"
              v-model="row.is_required"
            ></el-switch>
          </template>

          <template #toolbar_buttons>
            <el-button
              type="danger"
              style="margin-left: 10px"
              icon="el-icon-plus"
              size="mini"
              @click="addone"
              >添加字段</el-button
            >

            <el-button
              type="danger"
              style="margin-left: 10px"
              icon="el-icon-plus"
              size="mini"
              @click="adddef"
              >添加默认字段</el-button
            >

            <el-button
              type="danger"
              style="margin-left: 10px"
              icon="el-icon-plus"
              size="mini"
              @click="savecolumn"
              >保存</el-button
            >

            <el-button
              type="danger"
              style="margin-left: 10px"
              icon="el-icon-plus"
              size="mini"
              @click="applycolumn"
              >应用</el-button
            >
          </template>

          <!--默认操作按钮-->
          <template #defaultopr="{ row }">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(row)"
              >删除
            </el-button>
          </template>

          <!--自定义空数据模板-->
          <template #empty>
            <span>
              <p>暂无数据</p>
            </span>
          </template>
        </vxe-grid>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>
<script>
import {
  indextableinfoList,
  indextableinfoSave,
  indextableinfoApply,
} from "@/api/growthindicators/gyxy/indexform";
import { optionselect as getDictOptionselect } from "@/api/core/data/dict/type";
//import basicInfoForm from "./basicInfoForm";
//import genInfoForm from "./genInfoForm";
import Sortable from "sortablejs";
export default {
  name: "GenEdit",
  components: {},
  data() {
    return {
      editConfig: {
        trigger: "click",
        mode: "cell",
        showStatus: true,
        icon: "none",

        activeMethod: this.activeCellMethod,
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        table_name: undefined,
        table_comment: undefined,
      },
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      colList: [],
      html_type_list: [
        { label: "文本框", value: "input" },
        { label: "文本域", value: "textarea" },
        { label: "html编辑器", value: "editor" },
        { label: "下拉框", value: "select" },
        { label: "单选框", value: "radio" },
        { label: "复选框", value: "checkbox" },
        { label: "日期控件", value: "datetime" },
        { label: "文件上传", value: "upload" },
        { label: "省市区", value: "city" },
      ],
      query_type_list: [
        { label: "=", value: "EQ" },
        { label: "!=", value: "NE" },
        { label: ">", value: "GT" },
        { label: ">=", value: "GTE" },
        { label: "<", value: "LT" },
        { label: "<=", value: "LTE" },
        { label: "LIKE", value: "LIKE" },
        { label: "BETWEEN", value: "BETWEEN" },
      ],
      column_type_list: [
        { label: "字符串(50)", value: "varchar(50)" },
        { label: "字符串(64)", value: "varchar(64)" },
        { label: "字符串(150)", value: "varchar(150)" },
        { label: "字符串(250)", value: "varchar(250)" },
        { label: "字符串(500)", value: "varchar(500)" },
        { label: "文本", value: "text" },
        { label: "大文本", value: "longtext" },
        { label: "整型", value: "bigint" },
        { label: "浮点(2位小数)", value: "float(10,2)" },
        { label: "金额(2位小数)", value: "decimal(18,2)" },
      ],
      // 列
      tableColumn: [
        { type: "seq", title: "序号", width: 60, fixed: "left" },
        {
          field: "sort",
          title: "排序",
          width: 80,
          titleHelp: { message: "数值越大越靠前" },
          fixed: "left",
          editRender: { name: "input" },
        },
        {
          field: "column_name",
          title: "字段名",
          titleHelp: { message: "生成物理字段后不允许修改" },
          minWidth: 180,
          fixed: "left",
          editRender: { name: "input" },
          slots: {
            // 使用 JSX 渲染
            default: ({ row }) => {
              if (row.is_create == "1") {
                return [
                  <span style="color: rgb(24,144,255);font-weight: bold;">
                    {row.column_name}
                  </span>,
                ];
              }

              return row.column_name;
            },
          },
        },
        {
          field: "column_comment",
          title: "描述",
          minWidth: 180,
          editRender: { name: "input" },
        },
        {
          field: "column_type",
          title: "类型",
          width: 150,
          editRender: {},
          slots: { default: "column_type", edit: "column_type" },
        },
        {
          field: "is_insert",
          title: "插入",
          width: 60,
          editRender: {},
          slots: { default: "is_insert", edit: "is_insert" },
        },
        {
          field: "is_edit",
          title: "编辑",
          width: 60,
          editRender: {},
          slots: { default: "is_edit", edit: "is_edit" },
        },
        {
          field: "is_list",
          title: "列表",
          width: 60,
          editRender: {},
          slots: { default: "is_list", edit: "is_list" },
        },
        {
          field: "is_query",
          title: "查询",
          width: 60,
          editRender: {},
          slots: { default: "is_query", edit: "is_query" },
        },
        {
          field: "query_type",
          title: "查询方式",
          width: 120,
          editRender: {},
          slots: { default: "query_type", edit: "query_type" },
        },
        {
          field: "is_required",
          title: "必填",
          width: 60,
          editRender: {},
          slots: { default: "is_required", edit: "is_required" },
        },
        {
          field: "html_type",
          title: "显示类",
          width: 160,
          editRender: {},
          slots: { default: "html_type", edit: "html_type" },
        },
        {
          field: "dict_type",
          title: "字典类型",
          width: 160,
          editRender: {},
          slots: { default: "dict_type", edit: "dict_type" },
        },

        {
          field: "",
          title: "操作",
          resizable: false,
          minWidth: 90,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],

      defsort: { order: "ascending", prop: "sort" },
      // 选中选项卡的 name
      activeName: "cloum",
      // 表格的高度
      tableHeight: document.documentElement.scrollHeight - 245 + "px",
      // 表列信息
      cloumns: [],
      // 字典信息
      dictOptions: [],
      form_typeOptions: [],
      // 表详细信息
      info: {},
      multiple: false,
      loading: false,
      index_id: 0,
    };
  },
  beforeCreate() {
    const { index_id } = this.$route.query;
    if (index_id) {
      this.index_id = index_id;
      // 获取表详细信息
      indextableinfoList({ index_id: index_id }).then((res) => {
        this.colList = res.data.list;
      });
      /** 查询字典下拉列表 */
      getDictOptionselect().then((response) => {
        this.dictOptions = response.data;
      });

      this.getDicts("gen_form_type").then((response) => {
        this.form_typeOptions = response.data.values || [];
      });
    }
  },
  created() {
    const { index_id } = this.$route.query;
    this.index_id = index_id;
  },
  methods: {
    activeCellMethod(row) {
      if (
        row.columnIndex == 2 &&
        row.data[row.rowIndex] &&
        row.data[row.rowIndex].is_create == "1"
      ) {
        return false;
      }
      return true;
    },
    editDisabledEvent({ row, column }) {
      //      console.log('禁止编辑')
      this.msgError("该列已生成物理字段,无法修改");
    },

    handleQuery() {
      this.loading = true;
      const { index_id } = this.$route.query;
      indextableinfoList({ index_id: index_id }).then((res) => {
        this.colList = res.data.list;
        this.loading = false;
      });
    },
    checkfields() {
      const $grid = this.$refs.xGrid;
      var alldata = $grid.getTableData();
      var tableData = alldata.tableData;

      for (var i = 0; i < tableData.length; i++) {
        if (!/^[\w]+$/.test(alldata.tableData[i].column_name)) {
          this.msgError(
            "第" + (i + 1) + "行有错误:字段名只允许字母,数字和下划线"
          );
          return false;
        }

        if (alldata.tableData[i].column_comment == "") {
          this.msgError("第" + (i + 1) + "行有错误:字段描述不能为空");
          return false;
        }

        //检测 select  radio checkbox 是否正确配置了数据字典
        if (
          alldata.tableData[i].html_type == "select" ||
          alldata.tableData[i].html_type == "radio" ||
          alldata.tableData[i].html_type == "checkbox"
        ) {
          if (alldata.tableData[i].dict_type == "") {
            this.msgError(
              "第" + (i + 1) + "行有错误:该显示类型必须配置字典类型!"
            );

            return false;
          }
        }

        for (var j = i + 1; j < tableData.length; j++) {
          if (
            alldata.tableData[i].column_name == alldata.tableData[j].column_name
          ) {
            this.msgError(
              "第" +
                (j + 1) +
                "行的字段名" +
                alldata.tableData[j].column_name +
                "与现有字段重复,请检查"
            );
            return false;
          }
        }
      }
      return true;
    },
    applycolumn() {
      if (!this.checkfields()) {
        return false;
      }
      this.loading = true;
      indextableinfoApply({
        index_id: this.index_id,
      })
        .then((response) => {
          this.loading = false;
          this.msgSuccess("操作成功");
          setTimeout(() => {
            this.handleQuery();
          }, 300);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    savecolumn() {
      if (!this.checkfields()) {
        return false;
      }
      const $grid = this.$refs.xGrid;
      var alldata = $grid.getTableData();
      this.loading = true;
      indextableinfoSave({
        index_id: this.index_id,
        fields: JSON.stringify(alldata.tableData),
      })
        .then((response) => {
          this.loading = false;
          this.msgSuccess("操作成功");
          setTimeout(() => {
            this.handleQuery();
          }, 300);
        })
        .catch(() => {
          this.loading = false;
        });
      //      console.log('alldata', alldata)
    },
    formathtml_type(value) {
      for (var i in this.html_type_list) {
        var item = this.html_type_list[i];
        if (item.value == value) {
          return item.label;
        }
      }
    },

    handleDelete(row) {
      const ids = row.id || this.ids;
      const $grid = this.$refs.xGrid;
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          $grid.remove(row);
        })
        .then(() => {
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    addone() {
      const $grid = this.$refs.xGrid;
      var row = $grid.insertAt(
        {
          column_name: "field1",
          column_comment: "字段1",
          column_type: "varchar(50)",
          is_insert: "1",
          is_edit: "1",
          is_list: "1",
          is_query: "0",
          query_type: "=",
          is_required: "0",
          html_type: "input",
          dict_type: "",
          sort: 1,
        },
        -1
      );

      setTimeout(() => {
        window.scroll({ top: 99999, left: 0, behavior: "smooth" });
      }, 500);
    },
    adddef() {
      const $grid = this.$refs.xGrid;
      var alldata = $grid.getTableData();
      var tableData = alldata.tableData;

      for (var i = 0; i < tableData.length; i++) {
        if (tableData[i].column_name == "student_no") {
          this.msgError("默认字段已添加,请勿重新添加!");
          return false;
        }
      }
      var dfcol = [
        {
          column_name: "stdname",
          column_comment: "姓名",
          column_type: "varchar(64)",
          is_query: "1",
          is_list: "1",
          is_insert: "1",
          is_edit: "1",
        },
        {
          column_name: "student_no",
          column_comment: "学号",
          column_type: "varchar(64)",
          is_query: "1",
          is_list: "1",
          is_insert: "1",
          is_edit: "1",
        },
        {
          column_name: "gender",
          column_comment: "性别",
          column_type: "varchar(50)",
          is_query: "1",
          is_list: "1",
          is_insert: "1",
          is_edit: "1",
        },

        {
          column_name: "turid",
          column_comment: "所属学期",
          column_type: "bigint",
          is_list: "0",
        },

        {
          column_name: "college_id",
          column_comment: "所属院系id",
          column_type: "bigint",
          is_list: "0",
        },
        {
          column_name: "college_name",
          column_comment: "所属院系",
          column_type: "varchar(50)",
          is_query: "1",
          is_list: "1",
          is_insert: "1",
          is_edit: "1",
        },

        {
          column_name: "major_id",
          column_comment: "所属专业id",
          column_type: "varchar(50)",
          is_query: "0",
          is_list: "0",
        },
        {
          column_name: "major_name",
          column_comment: "所属专业",
          column_type: "varchar(50)",
          is_query: "1",
          is_list: "1",
          is_insert: "1",
          is_edit: "1",
        },

        {
          column_name: "grade",
          column_comment: "所属年级",
          column_type: "varchar(50)",
          is_query: "1",
          is_list: "1",
          is_insert: "1",
          is_edit: "1",
        },
        {
          column_name: "classes_no",
          column_comment: "所属班级",
          column_type: "varchar(64)",
          is_query: "1",
          is_list: "1",
          is_insert: "1",
          is_edit: "1",
        },
        {
          column_name: "frequency",
          column_comment: "频率",
          column_type: "float(10,2)",
          is_query: "1",
          is_list: "1",
          is_insert: "1",
          is_edit: "1",
        },
      ];

      var sort = 99;
      for (var i in dfcol) {
        var citem = dfcol[i];
        var row = $grid.insertAt(
          {
            ...citem,

            query_type: "=",
            html_type: "input",
            dict_type: "",
            sort: sort--,
          },
          -1
        );
      }
    },
    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.table_id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },
    nexttab() {
      if (this.activeName == "basic") {
        this.activeName = "cloum";
      } else if (this.activeName == "cloum") {
        this.activeName = "genInfo";
      }
    },
    /** 提交按钮 */
    submitForm() {
      const basicForm = this.$refs.basicInfo.$refs.basicInfoForm;
      const genForm = this.$refs.genInfo.$refs.genInfoForm;
      Promise.all([basicForm, genForm].map(this.getFormPromise)).then((res) => {
        const validateResult = res.every((item) => !!item);
        if (validateResult) {
          const genTable = Object.assign({}, basicForm.model, genForm.model);
          genTable.columns = this.cloumns;
          //          genTable.options = {
          //            tree_code: genTable.tree_code,
          //            tree_name: genTable.tree_name,
          //            tree_parent_code: genTable.tree_parent_code
          //          };

          genTable.options = JSON.stringify(genTable.options);

          updateGenTable(genTable).then((res) => {
            this.msgSuccess(res.msg);
            if (res.code === 0) {
              this.close();
            }
          });
        } else {
          this.msgError("表单校验未通过，请重新检查提交内容");
        }
      });
    },
    //子组件修改info的column
    setInfoColumn(column) {
      this.info.column = column;
    },
    getFormPromise(form) {
      return new Promise((resolve) => {
        form.validate((res) => {
          resolve(res);
        });
      });
    },
    /** 关闭按钮 */
    close() {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.$router.push({
        path: "/fastdev/lesscodedev/gencode",
        query: { t: Date.now() },
      });
    },
  },
  mounted() {
    //    const el = this.$refs.dragTable.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
    //    const sortable = Sortable.create(el, {
    //      handle: ".allowDrag",
    //      onEnd: evt => {
    //              var tempcloumns =this.cloumns.concat();
    //            const targetRow = tempcloumns.splice(evt.oldIndex, 1)[0];
    //            tempcloumns.splice(evt.newIndex, 0, targetRow);
    //            for (let index in tempcloumns) {
    //              tempcloumns[index].sort = parseInt(index) + 1;
    //
    //              for (var i in this.cloumns) {
    //                if (this.cloumns[i].column_name == tempcloumns[index].column_name) {
    //                  this.cloumns[i].sort = tempcloumns[index].sort
    //                  break
    //                }
    //
    //              }
    //            }
    //    console.log(this.cloumns, tempcloumns, evt.oldIndex, evt.newIndex)
    //      }
    //    });
  },
};
</script>
