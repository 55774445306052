import request from '@/utils/request'

export function listProvider(query) {
  return request({
    url: '/provider/list',
    method: 'get',
    params: query
  })
}

export function getProvider(id) {
  return request({
    url: '/provider/get?id=' + id,
    method: 'get'
  })
}

export function addProvider(data) {
  return request({
    url: '/provider/add',
    method: 'post',
    data: data
  })
}

export function editProvider(data) {
  return request({
    url: '/provider/edit',
    method: 'post',
    data: data
  })
}

export function delProvider(ids) {
  return request({
    url: '/provider/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function testProvider(data) {
  return request({
    url: '/provider/test',
    method: 'post',
    data: data
  })
}