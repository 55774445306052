<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
      label-position="left"
    >
      <el-form-item label="id" prop="id">
        <el-input
          v-model="queryParams.id"
          placeholder="请输入id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="分类" prop="category_id">
        <el-select
          v-model="queryParams.category_id"
          placeholder="分类"
          clearable
          size="small"
        >
          <el-option
            v-for="item in categoryList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="20">
      <el-col :span="4" class="col-item-container">
        <el-card
          :body-style="{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }"
        >
          <div class="card-add" @click="handleAdd">
            <div>
              <i class="el-icon-plus" style="font-size: 40px"></i>
            </div>
            <div style="font-size: 14px; margin-top: 10px">新建大屏</div>
          </div>
        </el-card>
      </el-col>
      <el-col
        :span="4"
        v-for="(o, index) in datascreenList"
        :key="o.id"
        class="col-item-container"
      >
        <el-card :body-style="{ padding: '0px' }">
          <img :src="o.screenshot || '/images/bg/bg1.png'" class="image" />
          <div style="padding: 10px">
            <div
              style="
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 15px;
              "
              :title="o.name"
            >
              <span :style="'color:' + (o.status == '1' ? '#000' : '#999')">{{
                o.name
              }}</span>
            </div>
            <div class="bottom">
              <div>
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="handleDesign(o)"
                  >设计
                </el-button>
                <!-- <el-button type="text" icon="el-icon-edit">
                  <a
                    :href="'http://192.168.1.5:5173/#/chart/home/' + o.id"
                    rel="nofollow noreferrer"
                    target="_blank"
                    >设计</a
                  >
                </el-button> -->
                <el-button
                  type="text"
                  icon="el-icon-view"
                  @click="handleView(o)"
                  >预览</el-button
                >
              </div>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    icon="el-icon-copy-document"
                    :command="makeCommand('copy', o)"
                    >复制</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-edit"
                    :command="makeCommand('edit', o)"
                    >修改</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-delete"
                    :command="makeCommand('delete', o)"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <pagination
      v-show="total > 0"
      layout="total, prev, pager, next, jumper"
      :total="total"
      :limit.sync="queryParams.pageSize"
      :page.sync="queryParams.pageNum"
      @pagination="getList"
    />

    <!-- 添加或修改大屏对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form
        ref="form"
        :model="form"
        :rules="form.id ? rulesEdit : rulesAdd"
        label-width="80px"
      >
        <el-form-item label="分类" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择">
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="名称" />
        </el-form-item>
        <el-row v-if="!form.id">
          <el-col :span="4">
            <el-form-item label="尺寸" prop="size"> </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="宽" prop="width">
              <el-input-number
                controls-position="right"
                v-model="form.width"
                placeholder="请输入大屏宽度"
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="高" prop="height">
              <el-input-number
                controls-position="right"
                v-model="form.height"
                placeholder="请输入大屏高度"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in statusOptions"
              :key="dict.key"
              :label="dict.key"
              >{{ dict.value }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入备注"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listBiDatascreen,
  getBiDatascreen,
  addBiDatascreen,
  editBiDatascreen,
  delBiDatascreen,
  copyBiDatascreen,
} from "@/api/bi/bi_datascreen";

import { getAccessToken } from "@/utils/tokenTool";

export default {
  name: "bi-bidatascreen-list",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 大屏表格数据
      datascreenList: [],
      // 分类数据
      categoryList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 11,
        category_id: undefined,
        name: undefined,
        status: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rulesAdd: {
        category_id: [
          { required: true, message: "大屏分类不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "大屏名称不能为空", trigger: "blur" },
        ],
        width: [
          { required: true, message: "大屏宽度不能为空", trigger: "blur" },
        ],
        height: [
          { required: true, message: "大屏高度不能为空", trigger: "blur" },
        ],
      },
      rulesEdit: {
        category_id: [
          { required: true, message: "大屏分类不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "大屏名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询大屏列表 */
    getList() {
      this.loading = true;
      listBiDatascreen(this.queryParams).then((response) => {
        this.single = true;
        this.multiple = true;
        this.categoryList = response.data.categoryList;
        this.statusOptions = response.data.statusOptions.values;
        this.datascreenList = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    categoryFormat({ row }) {
      for (let i = 0; i < this.categoryList.length; i++) {
        const c = this.categoryList[i];
        if (c.id === row.category_id) {
          return c.name;
        }
      }
      return "";
    },
    statusFormat({ row }) {
      return this.selectDictLabel(this.statusOptions, row.status);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        code: undefined,
        name: undefined,
        sort: 0,
        status: "1",
        remark: undefined,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    handleCommand(cmd) {
      if (cmd.cmd === "copy") {
        this.handleCopy(cmd.item);
      } else if (cmd.cmd === "edit") {
        this.handleUpdate(cmd.item);
      } else if (cmd.cmd === "delete") {
        this.handleDelete(cmd.item);
      }
    },
    makeCommand(cmd, item) {
      return {
        cmd: cmd,
        item: item,
      };
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加大屏";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      getBiDatascreen(id).then((response) => {
        //this.form = response.data;
        let data = response.data;
        if (data) {
          this.form = {
            id: data.id,
            category_id: data.category_id,
            name: data.name,
            status: data.status,
            remark: data.remark,
          };
        }

        this.open = true;
        this.title = "修改大屏";
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            editBiDatascreen(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            const defaultBg = "/images/bg/bg1.png";
            const detail = {
              name: this.form.name,
              width: this.form.width,
              height: this.form.height,
              scale: 1,
              backgroundImage: defaultBg,
              url: "",
              mark: {},
              gradeShow: false,
              gradeLen: 30,
            };
            this.form.detail = JSON.stringify(detail);
            this.form.component = "[]";
            this.form.screenshot = defaultBg;
            this.form.background_url = defaultBg;
            addBiDatascreen(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id;
      this.$confirm('确定删除大屏"' + row.name + '"?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delBiDatascreen(ids);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    /** 删除按钮操作 */
    handleCopy(row) {
      const ids = row.id;
      this.$confirm('确定复制大屏"' + row.name + '"?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(function () {
          return copyBiDatascreen(ids);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("复制成功");
        })
        .catch(function () {});
    },
    /** 导出按钮操作 */
    handleExport() {},
    handleDesign(row) {
      // window.open("/datav.html#/design?id=" + row.id);
      window.open(
        "http://192.168.1.5:3000/#/chart/home/" +
          row.id +
          "?token=" +
          getAccessToken()
      );
    },
    handleView(row) {
      // window.open("/datav.html#/view?id=" + row.id);
      window.open(
        "http://192.168.1.5:3000/#/chart/preview/" +
          row.id +
          "?token=" +
          getAccessToken()
      );
    },
  },
};
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.col-item-container {
  margin-bottom: 20px;
}
.col-item-container .el-card {
  height: 260px;
}
.card-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #409eff;
  cursor: pointer;
}
.col-item-container img {
  width: 100%;
  height: 180px;
}
.col-item-container .bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.col-item-container .bottom .el-dropdown {
  height: 16px;
}
</style>
