import request from '@/utils/request'
// 生成时间:2022-03-28 09:01:59

// 查询actstudeclare列表
export function listActstudeclare(query) {
    var data = {
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
        page_size: query.pageSize,
        page: query.pageNum,
        extfilter: query.extfilter || ""
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr" && k != "extfilter") {
            filterstr[k] = item;
        }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/growthindicators/actstudeclare/list',
        method: 'post',
        data: data
    })
}

// 查询actstudeclare详细
export function getActstudeclare(id) {
    return request({
        url: '/growthindicators/actstudeclare/get?id=' + id,
        method: 'get'
    })
}


// 新增actstudeclare
export function addActstudeclare(data) {
    return request({
        url: '/growthindicators/actstudeclare/add',
        method: 'post',
        data: data
    })
}

// 修改actstudeclare
export function editActstudeclare(data) {
    return request({
        url: '/growthindicators/actstudeclare/edit',
        method: 'post',
        data: data
    })
}

// 删除actstudeclare
export function delActstudeclare(ids) {
    return request({
        url: '/growthindicators/actstudeclare/del',
        method: 'post',
        data: { ids: ids }
    })
}

// 删除actstudeclare查询模板
export function advquerydelActstudeclare(ids) {
    return request({
        url: '/growthindicators/actstudeclare/advquerydel',
        method: 'post',
        data: { ids: ids }
    })
}

// 添加actstudeclare查询模板
export function advquerysaveActstudeclare(data) {
    return request({
        url: '/growthindicators/actstudeclare/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表actstudeclare查询模板列表
export function advquerylistActstudeclare(businessname) {
    return request({
        url: '/growthindicators/actstudeclare/advquerylist?businessname=' + businessname,
        method: 'get'
    })
}
// 导出actstudeclare
export function exportActstudeclare(query, exportfields, tableColumn) {
    var data = {
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr") {
            filterstr[k] = item;
        }
    }
    var tarexportfieldslist = {};
    for (var k in tableColumn) {
        var tlbf = tableColumn[k];
        for (var i in exportfields) {
            var ef = exportfields[i]
            if (tlbf.field != "" && tlbf.field && tlbf.field != null && tlbf.field == ef) {
                tarexportfieldslist.push({ "filed": ef, "title": tlbf.title });
                break;
            }
        }
    }

    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    data["exportfields"] = JSON.stringify(tarexportfieldslist);
    return request({
        url: '/growthindicators/actstudeclare/export',
        method: 'post',
        data: data
    })
}
