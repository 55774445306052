<template>
	<div class="app-container">
		<!-- <el-form
		:model="queryParams"
		ref="queryForm"
		:inline="true"
		label-width="68px"
		>
		<el-form-item>
			<el-button
				type="cyan"
				icon="el-icon-search"
				size="mini"
				@click="handleQuery"
				>搜索</el-button
			>
			<el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
				>重置</el-button
			>
		</el-form-item>
	</el-form> -->
		<jf-table :loading="loading" :columns="tableColumn" :data="dataList" :toolbar-config="tableToolbar"
			:query-params="queryParams" @checkbox-change="checkboxChangeEvent" @page-change="handlePageChange"
			:total="total" @change="tableChange">
			<template slot="toolbar_btn_left">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="['/update/add']">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="['/update/get', '/update/edit']">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="['/update/del']">删除</el-button>
			</template>

			<template slot="toolbar_btn_row" slot-scope="{ row }">
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>
			<template slot="platform" slot-scope="{row , columnIndex }">
				<!-- cellValue columnIndex -->
				{{ dictFormat({cellValue:row.platform,columnIndex:columnIndex-1}) }}
			</template>
			<template slot="force_update" slot-scope="{ row , columnIndex }">
				{{ dictFormat({cellValue:row.force_update,columnIndex:columnIndex-1}) }}
			</template>
		</jf-table>

		<!-- <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/update/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/update/get', '/update/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/update/del']"
                    >删除</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->

		<el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
			<el-form ref="form" :model="form" :rules="rules" label-width="100px">
				<el-row>
          <el-col :span="24">
						<el-form-item label="用户池" prop="userpool">
              <el-select
                v-model="form.userpool"
                placeholder="选择用户池"
                size="small"
              >
                <el-option
                  v-for="item in userpoolList"
                  :key="item.id"
                  :label="item.display_name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="平台" prop="platform">
							<dict-select v-model="form.platform" placeholder="请选择平台" type="platform" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="新版本" prop="new_version">
							<el-input v-model="form.new_version" placeholder="请输入新版本" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="旧版本" prop="old_version">
							<el-input v-model="form.old_version" placeholder="指定需要升级的版本，不填写则全部升级" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="强制更新" prop="force_update">
							<dict-select v-model="form.force_update" placeholder="请选择强制更新" type="sys_yes_no" />
						</el-form-item>
					</el-col>
					<!--<el-col :span="24">
					<el-form-item label="更新标题" prop="title">
						<el-input
							v-model="form.title"
							placeholder="请输入更新标题"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
					<el-col :span="24">
						<el-form-item label="更新内容" prop="content">
							<el-input v-model="form.content" placeholder="请输入更新内容" clearable type="textarea" row="6" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="更新地址" prop="url">
							<file-upload v-model="form.url" />
						</el-form-item>
					</el-col>
					<!--<el-col :span="24">
					<el-form-item label="更新类型" prop="type">
						<el-input
							v-model="form.type"
							placeholder="请输入更新类型"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
					<el-col :span="24">
						<el-form-item label="备注" prop="remark">
							<el-input v-model="form.remark" placeholder="请输入备注" clearable type="textarea" />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm">确 定</el-button>
				<el-button @click="cancel">返 回</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		listUpdate,
		getUpdate,
		addUpdate,
		editUpdate,
		delUpdate,
	} from "@/api/core/system/update";
  import { listUserPool } from "@/api/core/system/userpool";
	export default {
		name: "update-list",
		data() {
			return {
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery,
					},
					slots: {
						buttons: "toolbar_buttons",
					},
				},
				tableColumn: [
					// { type: "checkbox", width: 60, fixed: "left" },
					//{ field: "id", title: "id", minWidth: 120, fixed: "left" },
          {
            field: "userpool",
            title: "用户池",
            minWidth: 120,
            formatter: (e) => {
              for (let i = 0; i < this.userpoolList.length; i++) {
                const c = this.userpoolList[i];
                if (c.name == e.cellValue) {
                  return c.display_name;
                }
              }
              return "";
            },
          },
					{
						field: "platform",
						title: "平台",
						minWidth: 120,
						// formatter: this.dictFormat,
						dictType: "platform",
						// slots: {
						// 	default: "platform",
						// },
					},
					{
						field: "new_version",
						title: "新版本",
						minWidth: 120
					},
					{
						field: "old_version",
						title: "旧版本",
						minWidth: 120
					},
					{
						field: "force_update",
						title: "强制更新",
						minWidth: 120,
						// formatter: this.dictFormat,
						dictType: "sys_yes_no",
						slots: {
							default: "force_update",
						},
					},
					//{ field: "title", title: "更新标题", minWidth: 120 },
					{
						field: "content",
						title: "更新内容",
						minWidth: 120
					},
					{
						field: "url",
						title: "更新地址",
						minWidth: 120
					},
					//{ field: "type", title: "更新类型", minWidth: 120 },
					{
						field: "update_num",
						title: "更新次数",
						minWidth: 120,
						visible: false,
					},
					{
						field: "remark",
						title: "备注",
						minWidth: 120
					},
					{
						field: "create_by",
						title: "创建者",
						minWidth: 120,
						visible: false,
						form_type: 3
					},
					{
						field: "create_time",
						title: "创建时间",
						minWidth: 120,
						formatter: "formatDate",
						visible: false,
						form_type: 3
					},
					{
						field: "update_by",
						title: "更新者",
						minWidth: 120,
						visible: false,
					},
					{
						field: "update_time",
						title: "更新时间",
						minWidth: 120,
						formatter: "formatDate",
					},
					// {
					//     field: "",
					//     title: "操作",
					//     width: 180,
					//     fixed: "right",
					//     align: "center",
					//     slots: { default: "defaultopr" },
					// },
				],
				loading: true,
				ids: [],
				single: true,
				multiple: true,
				total: 0,
				dataList: [],
				title: "",
				open: false,
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					id: undefined,
				},
				form: {},
				rules: {
					platform: [{
						required: true,
						message: "平台不能为空",
						trigger: "blur",
					}, ],
					new_version: [{
						required: true,
						message: "新版本不能为空",
						trigger: "blur",
					}, ],
					force_update: [{
						required: true,
						message: "强制更新不能为空",
						trigger: "blur",
					}, ],
					content: [{
						required: true,
						message: "更新内容不能为空",
						trigger: "blur",
					}, ],
					url: [{
						required: true,
						message: "更新地址不能为空",
						trigger: "blur",
					}, ],
				},
        //   用户池数据
        userpoolList: [],
			};
		},
		created() {
			for (let i = 0; i < this.tableColumn.length; i++) {
				const col = this.tableColumn[i];
				if (col.dictType) {
					this.getDicts(col.dictType).then((response) => {
						if (response.code == 0) {
							col.dictData = response.data;
							this.dataList = [].concat(this.dataList);
						}
					});
				}
			}
      console.log(this.tableColumn)
			listUserPool({ pageNum: 1, pageSize: 9999 }).then((response) => {
        this.userpoolList = response.data.list;
        if (this.userpools) {
          this.queryParams.userpool = this.userpools;
        } else {
          this.queryParams.userpool = this.userpoolList[0].name;
        }
        this.getList();
      });
		},
		methods: {
			tableChange(e) {
				console.log(e);
			},
			getList() {
				this.loading = true;
				this.ids = [];
				this.single = true;
				this.multiple = true;

				listUpdate(this.queryParams).then((response) => {
					this.dataList = response.data.list;
					this.total = response.data.total;
					this.loading = false;
				});
			},

			dictFormat(e) {
				console.log(e)
				const col = this.tableColumn[e.columnIndex];
				if (col.dictData) {
					const values = e.cellValue ? e.cellValue.split(",") : [];
					const labels = [];
					for (let i = 0; i < values.length; i++) {
						const v = values[i];
						for (let j = 0; j < col.dictData.values.length; j++) {
							const item = col.dictData.values[j];
							if (v == item.key) {
								labels.push(item.value);
								break;
							}
						}
					}
					return labels.join(",");
				}
				return e.cellValue;
			},

			cancel() {
				this.open = false;
				this.reset();
			},

			reset() {
				this.form = {
					platform: undefined,
					old_version: undefined,
					new_version: undefined,
					force_update: undefined,
					title: undefined,
					content: undefined,
					url: undefined,
					type: undefined,
					remark: undefined,
				};
				this.resetForm("form");
			},

			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},

			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},

			handleAdd() {
				this.reset();
				this.open = true;
				this.title = "添加";
			},

			handleUpdate(row) {
				this.reset();
				const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
				getUpdate(id).then((response) => {
					this.form = response.data;
					this.open = true;
					this.title = "修改";
				});
			},

			submitForm() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						if (this.form.id != null) {
							editUpdate(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("修改成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						} else {
							addUpdate(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("新增成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						}
					}
				});
			},

			handleDelete(row) {
				const ids =
					row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
				this.$confirm("确定删除码?", "警告", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(function() {
						return delUpdate(ids);
					})
					.then(() => {
						setTimeout(() => {
							this.getList();
						}, 300);
						this.msgSuccess("删除成功");
					})
					.catch(function() {});
			},

			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map((item) => item.id);
				this.single = records.length != 1;
				this.multiple = !records.length;
			},

			handlePageChange({
				currentPage,
				pageSize
			}) {
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}
			},
		},
	};
</script>