import { render, staticRenderFns } from "./placeholder-editor.vue?vue&type=template&id=071d2ffa&scoped=true&"
import script from "./placeholder-editor.vue?vue&type=script&lang=js&"
export * from "./placeholder-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "071d2ffa",
  null
  
)

export default component.exports