import request from '@/utils/request'

//添加用户操作
export function addIotUserChoose(data) {
  return request({
    url: '/iot/userchoose/add',
    method: 'post',
    data: data
  })
}

// 获取用户操作
export function getIotUserChoose(id) {
    return request({
      url: '/iot/userchoose/get?id=' + id,
      method: 'get'
    })
}

// 获取用户操作by user_id
export function getIotUserChooseByUserId(id) {
  return request({
    url: '/iot/userchoose/getbyuserid?user_id=' + id,
    method: 'get'
  })
}

// 更新用户操作
export function updateIotUserChoose(data) {
  return request({
    url: '/iot/userchoose/update',
    method: 'post',
    data: data
  })
}
  
// 删除用户操作
export function delIotUserChoose(id) {
    return request({
      url: '/iot/userchoose/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 用户操作列表
export function listIotUserChoose(query) {
  return request({
    url: '/iot/userchoose/list',
    method: 'get',
    params: query
  })
}