import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2023-12-18 18:10:37
class Pdfrec {
    // 查询pdfrec列表
    listPdfrec(query) {
        var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

        return request({
            url: '/pdftool/pdfrec/list',
            method: 'post',
            data: data
        })
    }

    // 查看pdfrec
    getPdfrec(id) {
        return request({
            url: '/pdftool/pdfrec/get?id=' + id,
            method: 'get'
        })
    }


    // 新增pdfrec
    addPdfrec(mid, data) {
        var postdata = {
            formdata: JSON.stringify(data),
            mid: mid
        }
        return request({
            url: '/pdftool/pdfrec/add',
            method: 'post',
            data: postdata
        })
    }

    // 修改pdfrec
    editPdfrec(mid, data) {
        var postdata = {
            formdata: JSON.stringify(data),
            mid: mid,
            dataid: data.id
        }

        return request({
            url: '/pdftool/pdfrec/edit',
            method: 'post',
            data: postdata
        })
    }

    // 删除pdfrec
    delPdfrec(ids) {
        return request({
            url: '/pdftool/pdfrec/del',
            method: 'post',
            data: { ids: ids }
        })
    }



    //导入数据
    pdfrecImport(mid, rows, opts) {
        var data = {
            mid,
            options: JSON.stringify(opts),
            rows: JSON.stringify(rows)
        }

        return request({
            url: "/pdftool/pdfrec/import/" + mid,
            method: 'post',
            data: data
        })

    }


    //获取导出下载连接
    pdfrecExportUrl(mid, query, fieldlist) {
        var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {},
            fieldlist: Array.isArray(fieldlist) ? fieldlist.join("|") : fieldlist
        }

        var tjson = ReFormatQueryStr(query)
        data.qstr = JSON.stringify(tjson)

        var parata = []
        for (let i in data) {
            parata.push(i + "=" + data[i])
        }


        return `/pdftool/pdfrec/export?` + parata.join("&");


    }

    //下导入模板
    pdfrecimportTemplate() {

        return `/pdftool/pdfrec/importTemplate`;

    }

    // 提取书签
    extPdftext(mid, data) {
        var postdata = {
            formdata: JSON.stringify(data),
            mid: mid,
            dataid: data.id
        }

        return request({
            url: `/pdftool/pdfrec/extracttextfrompdf`,
            method: 'post',
            data: postdata
        })
    }

    // 写入书签
    writeBookmarkByFile(mid, data) {
        var postdata = {
            formdata: JSON.stringify(data),
            mid: mid,
            dataid: data.id
        }
        return request({
            url: `/pdftool/pdfrec/edittextfrompdf`,
            method: 'post',
            data: postdata
        })
    }
    editBookmarke(mid, data) {
        var postdata = {
            formdata: JSON.stringify(data),
            mid: mid,
            dataid: data.id
        }
        return request({
            url: `/pdftool/pdfrec/edittextfrompdf`,
            method: 'post',
            data: postdata
        })
    }

}

const instance = new Pdfrec();
export default instance;