<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">  
      <el-button icon="el-icon-question" style="font-weight:bolder;border: 0;">数据转发</el-button>    
      <el-form-item label="所属资源空间" prop="space_id">
        <el-select v-model="queryParams.space_id" placeholder="所有资源空间"  size="small" @change="handleQuery">
          <el-option
            v-for="dict in spaceList"
            :key="dict.Id"
            :label="dict.space_name"
            :value="dict.Id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row/>

    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="forwareList">
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >创建规则</el-button>
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >导入规则</el-button>       
      </template>
      <!--状态栏-->
      <template #status="{row}">
        <el-switch
                v-model="row.status"
                :active-value="1"
                :inactive-value="0"
                @change="handleStatusChange(row)"
        ></el-switch>
      </template>
      <!--默认操作按钮-->
      <template #defaultopr="{ row }">        
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
        >修改
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>      
      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="900px" append-to-body >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="规则名称" prop="forware_name">
          <el-input v-model="form.forware_name"  />
        </el-form-item>
        <el-form-item label="激活规则" >
          <el-switch v-model="form.status" :active-value="1" :inactive-value="0" />        
        </el-form-item>
        <el-form-item label="描述" >
          <el-input v-model="form.remark" type="textarea" placeholder="可选填" />
        </el-form-item>
        <el-form-item label="数据来源" prop="data_type">
          <el-select v-model="form.data_type" placeholder="请选择数据来源" clearable size="small" @change="dataTypeChange">
            <el-option
              v-for="(item,key) in dateTypeOptions"
              :key="key"
              :label="item.value"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="触发事件" prop="happen">
          <el-select v-model="form.happen" placeholder="请选择触发事件" clearable size="small">
            <el-option
              v-for="(item,key) in temphappenOptions"
              :key="key"
              :label="item.value"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="资源空间" prop="space_id">
          <el-select v-model="form.space_id" placeholder="请选择资源空间" clearable size="small">
            <el-option
              v-for="dict in spaceList"
              :key="dict.Id"
              :label="dict.space_name"
              :value="dict.Id"
            />
          </el-select>
        </el-form-item>
        <el-divider/>
        <el-form-item label="转发目标" prop="forwareDestination_id">
          <el-select v-model="form.forwareDestination_id" placeholder="请选择触发事件" clearable size="small">
            <el-option
              v-for="(item,key) in destinationOptions"
              :key="key"
              :label="item.value"
              :value="key"
              :disabled="key!=0"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="推送URL" prop="forwareDestination_value">
          <el-input v-model="form.forwareDestination_value"  />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>    
  </div>
</template>

<script>
import { addIotRuleForware, getIotRuleForware, updateIotRuleForware, delIotRuleForware, listIotRuleForware } from "@/api/iot/ruleforware";
import { listIotCase} from "@/api/iot/case";
export default {  
  data() {   
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [   
        {field: 'forware_name', title: '规则名称' },         
        {field: '', title: '所属资源空间' ,formatter:this.spaceFormat},
        {field: '', title: '数据来源' ,formatter:this.dataFormat},
        {field: '', title: '触发事件' ,formatter:this.happenFormat},
        {field: 'status', title: '状态' , slots: {default: 'status'} },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,      
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      dateTypeOptions: [],
      happenOptions: [],
      temphappenOptions:[],
      destinationOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        case_id: undefined,
        forware_name: undefined
      },
      forwareList:[],
      spaceList:[],
      form: {},
      rules: {
        forware_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
        data_type: [
          { required: true, message: "数据来源不能为空", trigger: "blur" }
        ],
        happen: [
          { required: true, message: "触发事件为空", trigger: "blur" }
        ],
        space_id: [
          { required: true, message: "资源空间不能为空", trigger: "blur" }
        ],
        forwareDestination_id: [
          { required: true, message: "转发目标不能为空", trigger: "blur" }
        ],
        forwareDestination_value: [
          { required: true, message: "推送URL不能为空", trigger: "blur" }
        ]
      },
    }
  }, 
  created() {   
    this.getParams();
  },
  methods: {
    handleStatusChange(row) {
        let text = row.status === 1 ? "启用" : "停用";
        this.$confirm('确认要"' + text + '""' + row.forware_name + '"吗?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            }).then(function() {
                updateIotRuleForware(row).then(response => {
                    if (response.code ===0) {
                        this.msgSuccess("修改成功");
                    } else {
                        this.msgError(response.msg);
                    }
                    }).catch(function (error){
                    console.log(error);
                });
                
            }).then(() => {
                this.msgSuccess(text + "成功");
            }).catch(function() {
            row.status = row.status === 0 ? 1 : 0;
        });        
    },
    handleUpdate(row){
      this.reset();
      const id = row.id || this.ids      
      getIotRuleForware(id).then(response => {
        this.form = response.data;
        this.temphappenOptions=this.happenOptions;
        this.open = true;
        this.title = "修改规则";
      });
    },
    happenFormat({row}){
      this.labname="";
      this.happenOptions.forEach(element => {
        if (row.happen == element.key)
          this.labname= element.value;        
      });
      return this.labname;
    },
    dataFormat({row}){
      this.labname="";
      this.dateTypeOptions.forEach(element => {
        if (row.data_type == element.key)
          this.labname= element.value;        
      });
      return this.labname;
    },
    spaceFormat({row}){
      this.labname="";
      this.spaceList.forEach(element => {
        if (row.space_id == element.Id)
          this.labname= element.space_name;        
      });
      return this.labname;
    },
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {            
            updateIotRuleForware(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            this.form.case_id=this.queryParams.case_id;            
            addIotRuleForware(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("添加规则成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    dataTypeChange(){
      this.temphappenOptions=[];
      if(this.form.data_type==0){//设备
        this.temphappenOptions.push(this.happenOptions[0]);
        this.temphappenOptions.push(this.happenOptions[1]);
        this.temphappenOptions.push(this.happenOptions[2]);
      }else if(this.form.data_type==1){
        this.temphappenOptions.push(this.happenOptions[3]);       
      }else if(this.form.data_type==2){
        this.temphappenOptions.push(this.happenOptions[4]);       
      }else if(this.form.data_type==3){
        this.temphappenOptions.push(this.happenOptions[5]);       
      }else if(this.form.data_type==4){
        this.temphappenOptions.push(this.happenOptions[6]);       
      }else if(this.form.data_type==5){
        this.temphappenOptions.push(this.happenOptions[7]);       
      }else if(this.form.data_type==6){//产品
        this.temphappenOptions.push(this.happenOptions[8]);
        this.temphappenOptions.push(this.happenOptions[9]);
        this.temphappenOptions.push(this.happenOptions[10]);       
      }else if(this.form.data_type==7){
        this.temphappenOptions.push(this.happenOptions[11]);       
      }else if(this.form.data_type==8){
        this.temphappenOptions.push(this.happenOptions[12]);       
      }       
      
    },  
    // 取消按钮
    cancel() {
      this.open = false;      
      //this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id:undefined,
        forware_name:"",
        status:0,
        forwareDestination_id:undefined,
        forwareDestination_value:undefined,
        data_type:undefined,
        space_id:undefined,
        happen:undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    handleAdd(){
      this.reset();
      this.open=true;
      this.title = "创建规则";
    },
    getParams(){
      var casequeryParams={
        pageNum: 1,
        pageSize: 10,
        node_id: undefined      
      };
      listIotCase(casequeryParams).then(response =>{
          for (var index=0;index<response.data.list.length;index++){
            if (response.data.list[index].is_choose >= 1){
              this.queryParams.case_id=response.data.list[index].Id;              
            }              
          }   
          this.getList();          
        })
    },
    /** 查询分类列表 */
    getList() {
      this.loading = true;          
      listIotRuleForware(this.queryParams).then(response => {        
        this.single = true;
        this.multiple = true;           
        this.total = response.data.total;
        this.forwareList = response.data.list; 
        this.spaceList = response.data.space_list;        
        this.statusOptions = response.data.statusOptions.values;   
        this.dateTypeOptions = response.data.dateTypeOptions.values;   
        this.happenOptions = response.data.happenOptions.values;  
        this.destinationOptions = response.data.destinationOptions.values;  
        this.loading = false;
        }).catch(function (error){
          console.log(error);
        });
    },
  }
}
</script>