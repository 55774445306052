import request from '@/utils/request'
// 生成时间:2021-09-29 10:37:14

// 查询books列表
export function listBooks(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/gen/library/books/list',
        method: 'post',
        data: data
    })
}

// 查询books详细
export function getBooks (id) {
    return request({
        url: '/gen/library/books/get?id='+id,
        method: 'get'
    })
}


// 新增books
export function addBooks(data) {
    return request({
        url: '/gen/library/books/add',
        method: 'post',
        data: data
    })
}

// 修改books
export function editBooks(data) {
    return request({
        url: '/gen/library/books/edit',
        method: 'post',
        data: data
    })
}

// 删除books
export function delBooks(ids) {
    return request({
        url: '/gen/library/books/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 删除books查询模板
export function advquerydelBooks(ids) {
    return request({
        url: '/gen/library/books/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加books查询模板
export function advquerysaveBooks(data) {
    return request({
        url: '/gen/library/books/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表books查询模板列表
export function advquerylistBooks(businessname) {
    return request({
        url: '/gen/library/books/advquerylist?businessname='+businessname,
        method: 'get'
    })
}