<template>
  <el-form ref="form" :model="value" :rules="rules" label-position="left">
    <el-form-item style="margin-top: 5px" prop="username">
      <span class="qz-svg-container svg-container-admin">
        <i class="el-icon-user"></i>
        手机号
      </span>
      <el-input
        v-model.trim="value.username"
        v-focus
        placeholder="请输入手机号"
        tabindex="1"
        type="text"
      />
    </el-form-item>
    <el-form-item prop="verifiyCode">
      <span class="qz-svg-container">
        <i class="el-icon-chat-line-round"></i>
        验证码
      </span>
      <el-input
        ref="verifiyCode"
        v-model.trim="value.verifiyCode"
        type="text"
        tabindex="3"
        placeholder="请输入短信验证码"
        @keyup.enter.native="handleLoginByMsg"
        class="verifiy-code-input"
      />
      <div :class="{ 'send-code': true, sending: sending }" @click="sendCode">
        {{ text }}
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { login } from "@/mixins/login.js";
export default {
  mixins: [login],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      passwordType: "password",
      sending: false, //验证码发送状态
      countDown: 60,
      text: "获取验证码",
    };
  },
  methods: {
    sendCode() {
      if (this.sending) return;
      this.$baseMessage("已发送验证码，请注意查收...", "success");
      this.sending = true;
      this.text = `${this.countDown} s`;
      const timer = setInterval(() => {
        this.countDown = --this.countDown;
        this.text = `${this.countDown} s`;
        if (this.countDown <= 0) {
          this.sending = false;
          this.text = "获取验证码";
          clearInterval(timer);
          this.countDown = 60;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.qz-svg-container {
  position: absolute;
  top: 14px;
  left: 5px;
  z-index: $base-z-index;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
  user-select: none;
}

.qz-show-password {
  position: absolute;
  top: 14px;
  right: 25px;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
  user-select: none;
}

.qz-remember-password {
  margin-bottom: 20px;
  display: flex;
  margin-left: 10px;
}

.send-code {
  position: absolute;
  right: 0;
  top: 14px;
  cursor: pointer;
  background-color: #d9ebfa;
  text-align: center;
  border-radius: 20px;
  color: #088af5;
  width: 95px;
  &.sending {
    background-color: #e4e3e3;
    color: #515151;
    cursor: no-drop;
  }
}
</style>