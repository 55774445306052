<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" v-if="search">
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="queryParams.title"
          placeholder="请输入标题"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="flow_id" label="流程名称">
        <el-select v-model="queryParams.flow_id" placeholder="请选择" clearable style="width: 100%;" filterable>
          <el-option
            v-for="item in flowList"
            :key="item.id"
            :label='item.name'
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属部门" prop="dept_id">
        <dept-select v-model="queryParams.dept_id" placeholder="请选择部门" valueField="id" size="small" @change="handleQuery" />
      </el-form-item>
      <el-form-item label="是否结束" prop="is_finished" v-if="false">
        <el-select
          v-model="queryParams.is_finished"
          placeholder="是否结束"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in finishedOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="发起用户" prop="start_user_account">
        <el-input
          v-model="queryParams.start_user_account"
          placeholder="请输入发起人登录名"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="发起时间">
        <el-date-picker
          v-model="dateRange"
          size="small"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :picker-options="pickerOptions"
          @change="handleQuery"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      stripe
      highlight-hover-row
      :loading="loading"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="dataList"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
      @toolbar-tool-click="toolbarToolClickEvent"
    >
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleOpenExport"
          v-hasPermi="['/bpm/process/export']"
        >导出</el-button>
      </template>

      <template #flowstatus="{ row }">
        <el-tag
          v-for="dict in statusOptions"
          :type="dict.type"
          v-if="dict.key == row.status"
        >{{dict.value}}</el-tag>
      </template>
      <template #flowversion="{ row }">
        <el-tag type="primary" >V:{{row.version}}</el-tag>
      </template>
      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button type="text" size="mini" icon="el-icon-view" @click.stop="handleView(row)">查看</el-button>
        <el-dropdown  size="small" style="margin-left: 10px;" v-if="false">
          <el-button type="text" size="small">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item><el-button type="text" size="mini" icon="el-icon-alarm-clock" @click.stop="handelActive(row)">催办</el-button></el-dropdown-item>
            <el-dropdown-item><el-button type="text" size="mini"  icon="el-icon-back" @click.stop="handleSuspend(row)">撤销</el-button></el-dropdown-item>
            <el-dropdown-item><el-button type="text" size="mini"  icon="el-icon-edit" @click.stop="handleSuspend(row)">编辑</el-button></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!--
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>-->
      </template>

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
      background
      size="small"
      :loading="loading"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      :total="total"
      @page-change="handleSizeChange">
    </vxe-pager>
    <el-dialog title="导出查询结果" :visible.sync="openExport" width="680px" append-to-body>
      <el-form :model="queryParams" ref="formExport" :inline="true" label-width="100px">
        <el-form-item prop="flow_id" label="流程名称" required :rules="[{ required: true, message: '请选择流程', trigger: 'blur' }]">
          <el-select v-model="queryParams.flow_id" placeholder="请选择" clearable style="width: 100%;" filterable >
            <el-option
              v-for="item in flowList"
              :key="item.id"
              :label='item.name'
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="queryParams.title"
            placeholder="请输入标题"
            clearable
            size="small"

          />
        </el-form-item>
        <el-form-item label="所属部门" prop="dept_id">
          <dept-select v-model="queryParams.dept_id" placeholder="请选择部门" valueField="id" size="small" />
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select
            v-model="queryParams.status"
            placeholder="状态"
            clearable
            size="small"
          >
            <el-option
              v-for="dict in statusOptions"
              :key="dict.key"
              :label="dict.value"
              :value="dict.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="发起用户" prop="start_user_account">
          <el-input
            v-model="queryParams.start_user_account"
            placeholder="请输入发起人登录名"
            clearable
            size="small"
          />
        </el-form-item>

        <el-form-item label="发起时间">
          <el-date-picker
            v-model="dateRange"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="pickerOptions"
            @change="handleQuery"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleExport">确定导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { historyProcessList,ExportProcess  } from "@/api/bpm/process";
  import { listBpmFlow } from "@/api/bpm/flow";
  import Vue from "vue";
  export default {
    name: "bpm_process_history_list",
    data() {
      return {
        // 工具栏
        tableToolbar: {
          perfect: true,
          zoom: true,
          custom: true,
          refresh: {
            query: this.handleQuery
          },
          tools: [
            { code: 'search', name: '', size:'medium',icon: 'vxe-icon--search', circle:true}
          ],
          slots: {
            buttons: 'toolbar_buttons'
          }
        },
        // 列
        tableColumn: [
          {type: 'checkbox', width: 60, fixed: "left"},
          {field: 'title', title: '标题' ,width:400,fixed:"left"},
          {field: 'start_user_account', title: '发起用户',width:100 },
          {field: 'flow_name', title: '流程名称' },
          {field: 'node_name', title: '当前节点' },
          {field: 'dept_name', title: '部门' },
          {field: 'status', title: '流程状态', slots: {default: 'flowstatus'}},
          {field: 'processTime', title: '流程时间', width:220,type:'html',formatter:function({cellValue, row, column}) {
              let html = "开始："+Vue.prototype.parseTime(row.start_time)
              html += '<br/>结束：' + Vue.prototype.parseTime(row.end_time)
              return html
            }},
          {field: 'start_time', title: '开始时间', width:150, formatter: "formatDate",visible:false},
          {field: 'end_time', title: '结束时间', width:150, formatter: "formatDate",visible:false},
          {field: 'duration', title: '用时',  formatter:function({cellValue, row, column}) {
              if(row.is_finished == '1') {
                return Vue.prototype.formatDuration(cellValue);
              }
              return '-';
            }},
          {field: '', title: '操作',resizable:false, width: 150, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
        ],
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 总条数
        total: 0,
        // 流程表格数据
        dataList: [],
        flowList:[],
        //是否打开搜索
        search:true,
        // 日期范围
        dateRange: [],
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick (picker) {
              const start = Vue.prototype.moment().startOf('day').toDate()
              const end = Vue.prototype.moment().endOf('day').toDate()
              picker.$emit('pick', [start, end])
            }
          },{
            text: '昨天',
            onClick (picker) {
              const start = Vue.prototype.moment().subtract(1, 'days').startOf('day').toDate()
              const end = Vue.prototype.moment().subtract(1, 'days').endOf('day').toDate()
              picker.$emit('pick', [start, end])
            }
          },{
            text: '最近7天',
            onClick (picker) {
              const start = Vue.prototype.moment().subtract(6, 'days').startOf('day').toDate()
              const end = Vue.prototype.moment().endOf('day').toDate()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近30天',
            onClick (picker) {
              const start = Vue.prototype.moment().subtract(29, 'days').startOf('day').toDate()
              const end = Vue.prototype.moment().endOf('day').toDate()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '本月',
            onClick (picker) {
              const start = Vue.prototype.moment().startOf('month').toDate()
              const end = Vue.prototype.moment().endOf('month').toDate()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '上月',
            onClick (picker) {
              const start = Vue.prototype.moment().subtract(1, 'month').startOf('month').toDate()
              const end = Vue.prototype.moment().subtract(1, 'month').endOf('month').toDate()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '本年',
            onClick (picker) {
              const start = Vue.prototype.moment().startOf('year').toDate()
              const end = Vue.prototype.moment().endOf('year').toDate()
              picker.$emit('pick', [start, end])
            }
          }]
        },
        //导出框
        openExport:false,
        // 弹出层标题
        title: "",
        // 状态数据字典
        statusOptions: [
          {key:"-1",value:'挂起', type:'danger'},
          {key:"0",value:'草稿', type:''},
          {key:"1",value:'待审批', type:'warning'},
          {key:"2",value:'审批通过', type:'success'},
          {key:"3",value:'撤回', type:'danger'},
          {key:"4",value:'驳回', type:'danger'},
          {key:"5",value:'终止', type:'danger'},
          {key:"6",value:'作废', type:'danger'},
        ],
        finishedOptions:[
          {key:"0",value:'否', type:''},
          {key:"1",value:'是', type:'success'},
        ],
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          viewType:'history',
          dept_id:undefined,
          dept_name: undefined,
          flow_id:undefined,
          title: undefined,
          status:undefined,
          start_user_account:undefined,
          start_user_name:undefined,
          is_finished:'1',
        },
      };
    },
    components: {
    },
    created() {
      listBpmFlow({ pageNum: 1,pageSize: 500,}).then(response => {
        this.flowList = response.data.list;
      });
      this.getList();
    },
    methods: {
      handleOpenExport() {
        if(this.total>0){
          this.queryParams.pageNum = 1;
          this.openExport = true
        }else{
          this.msgError("暂无数据可导出!");
        }
      },
      handleExport(){
        this.$refs["formExport"].validate(valid => {
          if (!valid) {
            return
          }
          const flows = this.flowList.filter(item=>item.id==this.queryParams.flow_id)
          if(flows.length < 1) {
            this.msgError("请选择要导出的流程");
            return;
          }
          let flow = flows[0]
          this.openExport = false
          ExportProcess(this.addDateRange(this.queryParams, this.dateRange)).then(res => {
            // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
            let blob = new Blob([res], {
              type: "application/vnd.ms-excel"
            });
            // 3.创建一个临时的url指向blob对象
            let url = window.URL.createObjectURL(blob);
            // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
            let a = document.createElement("a");
            a.href = url;
            a.download = `导出【${flow.name}】历史数据_` + new Date().getTime() + ".xlsx"; //自定义导出文件名
            a.click();
            // 5.释放这个临时的对象url
            window.URL.revokeObjectURL(url);
          }).catch(function(e) {});

        })
      },
      toolbarToolClickEvent ({ code }) {
        //const $grid = this.$refs.xGrid
        switch (code) {
          case 'search':
            this.search = !this.search
            break
        }
      },
      handleSizeChange ({ currentPage, pageSize }) {
        this.queryParams.pageNum = currentPage;
        if(this.queryParams.pageSize == pageSize){
          this.getList();
        }else{
          this.queryParams.pageSize = pageSize;
          this.handleQuery();
        }

      },
      checkboxChangeEvent ({ records }) {
        this.ids = records.map(item => item.id)
        this.single = records .length!=1;
        this.multiple = !records .length

      },
      handleView(row) {
        this.$router.push({
          path: "/bpm/task/view",
          query: {
            resourceId: row.resource_id,
            id: row.id,
            taskId:row.task_id,
            title: row.title,
          }
        });
      },
      handleTodo(row) {
        let title = `${row.title || '审批'}【${row.node_name || ''}】`
        if(row.node_name == '开始') {
          title = `编辑发起【${row.title || ''}】`
        }
        this.$router.push({
          path: "/bpm/task/task",
          query: {
            resourceId: row.resource_id,
            id: row.id,
            taskId:row.task_id,
            title: title
          }
        });
      },
      /** 查询流程列表 */
      getList() {
        this.loading = true;
        historyProcessList(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
          this.single = true;
          this.multiple = true;
          this.dataList = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        });
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.dateRange = []
        this.resetForm("queryForm");
        this.handleQuery();
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id)
        this.single = selection.length!=1
        this.multiple = !selection.length
      },
      /** 提交按钮 */
      submitForm: function() {
        this.$refs["form"].validate(valid => {
          if (valid) {
          }
        });
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        const ids = row.id || (this.ids ? this.ids.join(',') : '');
        this.$confirm('确定删除该流程（影响流程实例及相关历史数据，不可恢复）吗?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delBpmFlow(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
      }
    }
  };
</script>
<style lang="scss">
  .vxe-tools--wrapper {
    margin-right: 1em;
  }
</style>
