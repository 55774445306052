import request from '@/utils/request'

// 查询会员余额流水列表
export function listUserMoneyLog(userpool, query) {
  return request({
    url: '/ucenter/' + userpool + '/usermoneylog/list',
    method: 'get',
    params: query
  })
}

// 查询会员余额流水详细
export function getUserMoneyLog(userpool, id) {
  return request({
    url: '/ucenter/' + userpool + '/usermoneylog/get?id=' + id,
    method: 'get'
  })
}
