<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
			<el-form-item label="用户池" prop="userpool">
				<el-input v-model="queryParams.userpool" placeholder="请输入用户池" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="名称" prop="name">
				<el-input v-model="queryParams.name" placeholder="请输入名称" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>
<!-- 
		<vxe-grid resizable ref="xGrid" row-id="id" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="dataList"
			@checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="[
					'/core/provider/add',
				]">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[
					'/core/provider/get',
					'/core/provider/edit',
				]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="[
					'/core/provider/del',
				]">删除</el-button>
			</template>

			<template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>

			<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>
		</vxe-grid>

		<vxe-pager background size="small" :loading="loading" :current-page.sync="queryParams.pageNum"
			:page-size.sync="queryParams.pageSize" :total="total" @page-change="handlePageChange">
		</vxe-pager> -->

		<jf-table :loading="loading" :columns="tableColumn" :data="dataList" :toolbar-config="tableToolbar"
			:query-params="queryParams" @checkbox-change="checkboxChangeEvent" @page-change="handlePageChange"
			:total="total" @change="tableChange">
			<template slot="toolbar_btn_left">
				<el-button  type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="[
					'/core/provider/add',
				]">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[
					'/core/provider/get',
					'/core/provider/edit',
				]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="[
					'/core/provider/del',
				]">删除</el-button>
			</template>
		

			<template slot="toolbar_btn_row" slot-scope="{ row }">
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>


		</jf-table>
		<el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
			<el-form ref="form" :model="form" :rules="rules" label-width="150px">
				<el-row>
					<!--<el-col :span="24">
					<el-form-item label="" prop="userpool">
						<el-input
							v-model="form.userpool"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
					<el-col :span="24">
						<el-form-item label="名称" prop="name">
							<el-input v-model="form.name" placeholder="请输入名称" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="显示名称" prop="display_name">
							<el-input v-model="form.display_name" placeholder="请输入显示名称" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="24">
					<el-form-item label="分类" prop="category">
						<dict-select v-model="form.category" type="sys_provider_category" placeholder="请选择分类" @change="handleCategoryChange" />
					</el-form-item> 
				</el-col>
				<el-col v-if="form.category" :span="24">
					<el-form-item label="类型" prop="type">
						<dict-select v-model="form.type" :type="'sys_provider_type_'+form.category" placeholder="请选择类型" />
					</el-form-item> 
				</el-col>
				<!--<el-col :span="24">
					<el-form-item label="" prop="sub_type">
						<el-input
							v-model="form.sub_type"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="method">
						<el-input
							v-model="form.method"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
					<el-col v-if="form.category=='oauth' && (form.type=='wechat' || form.type=='wechat_oa' || form.type=='wechat_mini_program')" :span="24">
						<el-form-item label="AppId" prop="client_id">
							<el-input v-model="form.client_id" placeholder="请输入AppId" clearable />
						</el-form-item>
					</el-col>
          <el-col v-else-if="form.category=='oauth' && form.type" :span="24">
						<el-form-item label="client_id" prop="client_id">
							<el-input v-model="form.client_id" placeholder="请输入client_id" clearable />
						</el-form-item>
					</el-col>
          <el-col v-if="form.category=='sms'" :span="24">
						<el-form-item label="client_id" prop="client_id">
							<el-input v-model="form.client_id" placeholder="请输入client_id" clearable />
						</el-form-item>
					</el-col>
          <el-col v-if="form.category=='email'" :span="24">
						<el-form-item label="用户名" prop="client_id">
							<el-input v-model="form.client_id" placeholder="请输入用户名" clearable />
						</el-form-item>
					</el-col>
          <el-col v-if="form.category=='push' && form.type == 'unipush'" :span="24">
						<el-form-item label="AppId" prop="client_id">
							<el-input v-model="form.client_id" placeholder="请输入AppId" clearable />
						</el-form-item>
					</el-col>
          <el-col v-if="form.category=='oauth' && (form.type=='wechat' || form.type=='wechat_oa' || form.type=='wechat_mini_program')" :span="24">
						<el-form-item label="AppSecret" prop="client_secret">
							<el-input v-model="form.client_secret" placeholder="请输入AppSecret" clearable />
						</el-form-item>
					</el-col>
					<el-col v-else-if="form.category=='oauth' && form.type" :span="24">
						<el-form-item label="client_secret" prop="client_secret">
							<el-input v-model="form.client_secret" placeholder="请输入client_secret" clearable />
						</el-form-item>
					</el-col>
          <el-col v-if="form.category=='sms'" :span="24">
						<el-form-item label="client_secret" prop="client_secret">
							<el-input v-model="form.client_secret" placeholder="请输入client_secret" clearable />
						</el-form-item>
					</el-col>
          <el-col v-if="form.category=='email'" :span="24">
						<el-form-item label="密码" prop="client_secret">
							<el-input v-model="form.client_secret" placeholder="请输入密码" clearable />
						</el-form-item>
					</el-col>
          <el-col v-if="form.category=='push' && form.type == 'unipush'" :span="24">
						<el-form-item label="AppKey" prop="client_secret">
							<el-input v-model="form.client_secret" placeholder="请输入AppKey" clearable />
						</el-form-item>
					</el-col>
					<el-col v-if="form.category=='email'" :span="24">
            <el-form-item label="发件人" prop="client_id2">
              <el-input
                v-model="form.client_id2"
                placeholder="请输入发件人"
                clearable
              />
            </el-form-item> 
          </el-col>
          <el-col v-if="form.category=='oauth' && form.type == 'wechat_oa'" :span="24">
						<el-form-item label="Token" prop="token">
							<el-input v-model="form.token" placeholder="请输入Token" clearable />
						</el-form-item>
            <el-form-item label="EncodingAESKey" prop="key">
							<el-input v-model="form.key" placeholder="请输入EncodingAESKey" clearable />
						</el-form-item>
					</el-col>
          <el-col v-if="form.category=='push' && form.type == 'unipush'" :span="24">
						<el-form-item label="MasterSecret" prop="client_secret2">
							<el-input v-model="form.client_secret2" placeholder="请输入MasterSecret" clearable />
						</el-form-item>
					</el-col>
				<!--<el-col :span="24">
					<el-form-item label="" prop="cert">
						<el-input
							v-model="form.cert"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
					<el-col v-if="form.category=='oauth'" :span="24">
						<el-form-item label="auth_url" prop="custom_auth_url">
							<el-input v-model="form.custom_auth_url" placeholder="请输入auth_url" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="24">
					<el-form-item label="" prop="custom_scope">
						<el-input
							v-model="form.custom_scope"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
					<el-col v-if="form.category=='oauth'" :span="24">
						<el-form-item label="token_url" prop="custom_token_url">
							<el-input v-model="form.custom_token_url" placeholder="请输入token_url" clearable />
						</el-form-item>
					</el-col>
					<el-col v-if="form.category=='oauth'" :span="24">
						<el-form-item label="user_info_url" prop="custom_user_info_url">
							<el-input v-model="form.custom_user_info_url" placeholder="请输入user_info_url" clearable />
						</el-form-item>
					</el-col>-->
					<!--<el-col :span="24">
					<el-form-item label="" prop="custom_logo">
						<el-input
							v-model="form.custom_logo"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
        <el-col v-if="form.category=='oauth' && form.type == 'yiban_mini_app'" :span="24">
						<el-form-item label="站内地址" prop="redirect_url">
							<el-input v-model="form.redirect_url" placeholder="请输入站内地址" clearable />
						</el-form-item>
					</el-col>
				<el-col v-if="form.category=='email'" :span="24">
					<el-form-item label="主机" prop="host">
						<el-input
							v-model="form.host"
							placeholder="请输入主机"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col v-if="form.category=='email'" :span="24">
					<el-form-item label="端口" prop="port">
						<el-input
							v-model="form.port"
							placeholder="请输入端口"
						/>
					</el-form-item> 
				</el-col>
				<el-col v-if="form.category=='email'" :span="24">
					<el-form-item label="禁用SSL" prop="disable_ssl">
            <el-switch
              v-model="form.disable_ssl"
              active-value="1"
              inactive-value="0">
            </el-switch>
					</el-form-item> 
				</el-col>
				<el-col v-if="form.category=='email'" :span="24">
					<el-form-item label="邮件标题" prop="title">
						<el-input
							v-model="form.title"
							placeholder="请输入邮件标题"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col v-if="form.category=='email'" :span="24">
					<el-form-item label="邮件内容" prop="content">
						<el-input
							v-model="form.content"
							placeholder="请输入邮件内容"
              type="textarea"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<!--<el-col :span="24">
					<el-form-item label="" prop="region_id">
						<el-input
							v-model="form.region_id"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
				<el-col v-if="form.category=='sms'" :span="24">
					<el-form-item label="签名名称" prop="sign_name">
						<el-input
							v-model="form.sign_name"
							placeholder="请输入签名名称"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col v-if="form.category=='sms'" :span="24">
					<el-form-item label="模版代码" prop="template_code">
						<el-input
							v-model="form.template_code"
							placeholder="请输入模版代码"
							clearable
						/>
					</el-form-item> 
				</el-col>
        <el-col v-if="form.category=='push' && form.type == 'unipush'" :span="24">
					<el-form-item label="Android包名" prop="app_id">
						<el-input
							v-model="form.app_id"
							placeholder="请输入Android包名"
							clearable
						/>
					</el-form-item> 
				</el-col>
        <el-col v-if="form.category=='email'" :span="24">
					<el-form-item label="测试邮件配置" prop="receiver">
						<el-input style="width: 50%"
							v-model="form.receiver"
							placeholder="请输入接收者邮箱"
							clearable
						/>
            <el-button type="primary" style="margin-left: 10px;" @click="handleTest">发送测试邮件</el-button>
					</el-form-item> 
				</el-col>
        <el-col v-if="form.category=='sms'" :span="24">
					<el-form-item label="测试短信配置" prop="receiver">
						<el-input style="width: 50%"
							v-model="form.receiver"
							placeholder="请输入接收者手机号"
							clearable
						/>
            <el-button type="primary" style="margin-left: 10px;" @click="handleTest">发送测试短信</el-button>
					</el-form-item> 
				</el-col>
        <el-col v-if="form.category=='push' && form.type=='unipush'" :span="24">
					<el-form-item label="测试UniPush配置" prop="receiver">
						<el-input style="width: 50%"
							v-model="form.receiver"
							placeholder="请输入接收者client_id"
							clearable
						/>
            <el-button type="primary" style="margin-left: 10px;" @click="handleTest">推送测试</el-button>
					</el-form-item> 
				</el-col>
        <el-col v-if="form.category=='cas'" :span="24">
          <el-form-item label="endpoint" prop="endpoint">
            <el-input v-model="form.endpoint" placeholder="请输入endpoint" clearable />
          </el-form-item>
        </el-col>
					<!--<el-col :span="24">
					<el-form-item label="" prop="intranet_endpoint">
						<el-input
							v-model="form.intranet_endpoint"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="domain">
						<el-input
							v-model="form.domain"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="bucket">
						<el-input
							v-model="form.bucket"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="path_prefix">
						<el-input
							v-model="form.path_prefix"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="metadata">
						<el-input
							v-model="form.metadata"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="id_p">
						<el-input
							v-model="form.id_p"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="issuer_url">
						<el-input
							v-model="form.issuer_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_sign_authn_request">
						<el-input
							v-model="form.enable_sign_authn_request"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
				<el-col :span="24">
					<el-form-item label="提供商url" prop="provider_url">
						<el-input
							v-model="form.provider_url"
							placeholder="请输入提供商url"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<!--<el-col :span="24">
					<el-form-item label="" prop="remark">
						<el-input
							v-model="form.remark"
							placeholder="请输入"
							clearable
							type="textarea"
						/>
					</el-form-item> 
				</el-col>-->
				</el-row>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm">确 定</el-button>
				<el-button @click="cancel">返 回</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		listProvider,
		getProvider,
		addProvider,
		editProvider,
		delProvider,
    testProvider
	} from "@/api/core/system/provider";
	export default {
		name: "provider-list",
		data() {
			return {
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery,
					},
					slots: {
						buttons: "toolbar_buttons",
					},
				},
				tableColumn: [
					// {
					// 	type: "checkbox",
					// 	width: 60,
					// 	fixed: "left"
					// },
					//{ field: "id", title: "", minWidth: 120, fixed: "left" },
					{
						field: "userpool",
						title: "用户池",
						minWidth: 120
					},
					{
						field: "name",
						title: "名称",
						minWidth: 120
					},
					{
						field: "display_name",
						title: "显示名称",
						minWidth: 120
					},
					{ field: "category", title: "分类", minWidth: 120 },
					{ field: "type", title: "类型", minWidth: 120 },
					/*{ field: "sub_type", title: "", minWidth: 120 },
					{ field: "method", title: "", minWidth: 120 },
					{
						field: "client_id",
						title: "client_id",
						minWidth: 120
					},
					{ field: "client_secret", title: "client_secret", minWidth: 120 },
					{ field: "client_id2", title: "", minWidth: 120 },
					{ field: "client_secret2", title: "", minWidth: 120 },
					{ field: "cert", title: "", minWidth: 120 },
					{ field: "custom_auth_url", title: "", minWidth: 120 },
					{ field: "custom_scope", title: "", minWidth: 120 },
					{ field: "custom_token_url", title: "", minWidth: 120 },
					{ field: "custom_user_info_url", title: "", minWidth: 120 },
					{ field: "custom_logo", title: "", minWidth: 120 },
					{ field: "host", title: "", minWidth: 120 },
					{ field: "port", title: "", minWidth: 120 },
					{ field: "disable_ssl", title: "", minWidth: 120 },
					{ field: "title", title: "", minWidth: 120 },
					{ field: "content", title: "", minWidth: 120 },
					{ field: "receiver", title: "", minWidth: 120 },
					{ field: "region_id", title: "", minWidth: 120 },
					{ field: "sign_name", title: "", minWidth: 120 },
					{ field: "template_code", title: "", minWidth: 120 },
					{ field: "app_id", title: "", minWidth: 120 },*/
					/*{
						field: "endpoint",
						title: "endpoint",
						minWidth: 120
					},
					{ field: "intranet_endpoint", title: "", minWidth: 120 },
					{ field: "domain", title: "", minWidth: 120 },
					{ field: "bucket", title: "", minWidth: 120 },
					{ field: "path_prefix", title: "", minWidth: 120 },
					{ field: "metadata", title: "", minWidth: 120 },
					{ field: "id_p", title: "", minWidth: 120 },
					{ field: "issuer_url", title: "", minWidth: 120 },
					{ field: "enable_sign_authn_request", title: "", minWidth: 120 },*/
					{ field: "provider_url", title: "提供商url", minWidth: 120 },
					/*{ field: "remark", title: "", minWidth: 120 },*/
					{
						field: "create_by",
						title: "创建者",
						minWidth: 120,
						visible: false
					},
					{
						field: "create_time",
						title: "创建时间",
						minWidth: 120,
						formatter: "formatDate",
						visible: false
					},
					{
						field: "update_by",
						title: "更新者",
						minWidth: 120,
						visible: false
					},
					{
						field: "update_time",
						title: "更新时间",
						minWidth: 120,
						formatter: "formatDate"
					},
					// {
					// 	field: "",
					// 	title: "操作",
					// 	width: 180,
					// 	fixed: "right",
					// 	align: "center",
					// 	slots: {
					// 		default: "defaultopr"
					// 	},
					// },
				],
				loading: true,
				ids: [],
				single: true,
				multiple: true,
				total: 0,
				dataList: [],
				title: "",
				open: false,
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					id: undefined,
					userpool: undefined,
					name: undefined,
				},
				form: {},
				rules: {
					userpool: [{
						required: true,
						message: "用户池不能为空",
						trigger: "blur"
					}],
					name: [{
						required: true,
						message: "名称不能为空",
						trigger: "blur"
					}],
          display_name: [{
						required: true,
						message: "显示名称不能为空",
						trigger: "blur"
					}],
          category: [{
						required: true,
						message: "分类不能为空",
						trigger: "blur"
					}],
          type: [{
						required: true,
						message: "类型不能为空",
						trigger: "blur"
					}],
          client_id: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          client_secret: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          client_id2: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          client_secret2: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          token: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          host: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          port: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          title: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          content: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          custom_auth_url: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          custom_token_url: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          custom_user_info_url: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          redirect_url: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          endpoint: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          sign_name: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          template_code: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
          app_id: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
				}
			};
		},
		created() {
			this.getList();
		},
		methods: {
			//列表筛选以及排序
			tableChange(e) {
				console.log(e)
			},
			getList() {
				this.loading = true;
				this.ids = [];
				this.single = true;
				this.multiple = true;

				listProvider(this.queryParams).then((response) => {
					this.dataList = response.data.list;
					this.total = response.data.total;
					this.loading = false;
				});
			},

			cancel() {
				this.open = false;
				this.reset();
			},

			reset() {
				this.form = {
					userpool: undefined,
					name: undefined,
					display_name: undefined,
					category: undefined,
					type: undefined,
					sub_type: undefined,
					method: undefined,
					client_id: undefined,
					client_secret: undefined,
					client_id2: undefined,
					client_secret2: undefined,
					cert: undefined,
					custom_auth_url: undefined,
					custom_scope: undefined,
					custom_token_url: undefined,
					custom_user_info_url: undefined,
					custom_logo: undefined,
					host: undefined,
					port: undefined,
					disable_ssl: undefined,
					title: undefined,
					content: undefined,
					receiver: undefined,
					region_id: undefined,
					sign_name: undefined,
					template_code: undefined,
					app_id: undefined,
					endpoint: undefined,
					intranet_endpoint: undefined,
					domain: undefined,
					bucket: undefined,
					path_prefix: undefined,
					metadata: undefined,
					id_p: undefined,
					issuer_url: undefined,
					enable_sign_authn_request: undefined,
					provider_url: undefined,
					remark: undefined,
				};
				this.resetForm("form");
			},

			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},

			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},

			handleAdd() {
				this.reset();
				this.open = true;
				this.title = "添加";
			},

			handleUpdate(row) {
				this.reset();
				const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
				getProvider(id).then((response) => {
					this.form = response.data;
					this.open = true;
					this.title = "修改";
				});
			},

			submitForm() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						if (this.form.id != null) {
							editProvider(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("修改成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						} else {
							addProvider(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("新增成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						}
					}
				});
			},

      handleTest() {
        this.$refs["form"].validate((valid) => {
					if (valid) {
            if (!this.form.receiver) {
              if (this.form.category == 'email') {
                this.msgError('请填写接收者邮箱')
              } else if (this.form.category == 'email') {
                this.msgError('请填写接收者手机号')
              } else if (this.form.category == 'push' && this.form.type == 'unipush') {
                this.msgError('请填写接收者client_id')
              }
              return
            }
            const form = {
              ...this.form,
              test_ext_data: ''
            }
            const loading = this.$loading({
              title: '请稍候'
            })
            testProvider(form).then((response) => {
              loading.close()
              if (response.code === 0) {
                this.msgSuccess(response.data.msg);
              }
            }).catch((e) => [
              loading.close()
            ]);
					}
				});
      },

			handleDelete(row) {
				const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
				this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(function() {
						return delProvider(ids);
					})
					.then(() => {
						setTimeout(() => {
							this.getList();
						}, 300);
						this.msgSuccess("删除成功");
					})
					.catch(function() {});
			},

			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map((item) => item.id);
				this.single = records.length != 1;
				this.multiple = !records.length;
			},

			handlePageChange({
				currentPage,
				pageSize
			}) {
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}
			},

      handleCategoryChange() {
        this.form.type = undefined
      }
		}
	};
</script>