<template>
    <el-form-item :label="i18nt('designer.setting.dicttypeName')" v-if="!designer.isTopic">
        <el-select
            v-model="optionModel.dicttype"
            filterable
            clearable
            placeholder="请选择"
            :filter-method="dicttype_filter_method"
            :style="{ width: '100%' }"
        >
            <el-option
                v-for="item in dicttypelist"
                :key="item.type"
                :label="item.name"
                :value="item.type"
            >
                <span> {{ item.name }}-{{ item.type }}</span>
            </el-option>
        </el-select>
    </el-form-item>
</template>
<script>
import i18n from "@/vform/utils/i18n";
import { optionselect as getDictOptionselect } from "@/api/core/data/dict/type";
export default {
    name: "dict-type-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
       
    },
    data() {
        return {
            dicttypelist:[]
        };
    },
    beforeCreate() {
     

            /** 查询字典下拉列表 */
            getDictOptionselect().then((response) => {
                this.dicttypelist = response.data;
            });

            // this.getDicts("gen_form_type").then((response) => {
            //   this.form_typeOptions = response.data.values || [];
            // });
        
    },
    methods:{
        //搜索字典库类型
        dicttype_filter_method(val) {
            if (val) {
                let value = val;
                this.dicttypelist = this.dicttypelistCopy.filter((item) => {
                    if (
                        item.name.indexOf(value) > -1 ||
                        item.type.indexOf(value) > -1
                    ) {
                        return true;
                    }
                });
            } else {
                this.dicttypelist = this.channelCopy;
            }
        },
    }
};
</script>