import request from '@/utils/request'
// 生成时间:2022-04-01 17:14:04

// 查询blackwhitelist列表
export function listBlackwhitelist(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/dncblacklist/blackwhitelist/list',
        method: 'post',
        data: data
    })
}

// 查询blackwhitelist详细
export function getBlackwhitelist (id) {
    return request({
        url: '/dncblacklist/blackwhitelist/get?id='+id,
        method: 'get'
    })
}


// 新增blackwhitelist
export function addBlackwhitelist(data) {
    return request({
        url: '/dncblacklist/blackwhitelist/add',
        method: 'post',
        data: data
    })
}

// 修改blackwhitelist
export function editBlackwhitelist(data) {
    return request({
        url: '/dncblacklist/blackwhitelist/edit',
        method: 'post',
        data: data
    })
}

// 删除blackwhitelist
export function delBlackwhitelist(ids) {
    return request({
        url: '/dncblacklist/blackwhitelist/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 删除blackwhitelist查询模板
export function advquerydelBlackwhitelist(ids) {
    return request({
        url: '/dncblacklist/blackwhitelist/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加blackwhitelist查询模板
export function advquerysaveBlackwhitelist(data) {
    return request({
        url: '/dncblacklist/blackwhitelist/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表blackwhitelist查询模板列表
export function advquerylistBlackwhitelist(businessname) {
    return request({
        url: '/dncblacklist/blackwhitelist/advquerylist?businessname='+businessname,
        method: 'get'
    })
}
// 导出blackwhitelist
export function exportBlackwhitelist(query,exportfields,tableColumn) {
     var data={
            filterstr:"",
            orderby:"",
            opstr:query.opstr ||{},
        };
        var filterstr={};
        for(var k in query){
         var item=query[k];
         if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
           filterstr[k]=item;
         }
        }
        var tarexportfieldslist={};
        for(var k in tableColumn){
          var tlbf = tableColumn[k];
           for(var i in exportfields){
             var ef = exportfields[i]
             if(tlbf.field!="" && tlbf.field && tlbf.field!=null && tlbf.field == ef){
               tarexportfieldslist.push({"filed":ef,"title":tlbf.title});
               break;
             }
           }
        }

        data["filterstr"] = JSON.stringify(filterstr);
        data["opstr"] = JSON.stringify(data["opstr"]);
        data["exportfields"] = JSON.stringify(tarexportfieldslist);
        return request({
            url: '/dncblacklist/blackwhitelist/export',
            method: 'post',
            data: data
        })
}
