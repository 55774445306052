import { render, staticRenderFns } from "./dzz.vue?vue&type=template&id=9fb4931a&scoped=true&"
import script from "./dzz.vue?vue&type=script&lang=js&"
export * from "./dzz.vue?vue&type=script&lang=js&"
import style0 from "./dzz.vue?vue&type=style&index=0&id=9fb4931a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fb4931a",
  null
  
)

export default component.exports