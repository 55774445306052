<template>
  <div class="container">
    <div v-if="!bindInfo" class="info">登录中...</div>
    <div style="display: flex; flex-direction: column; align-items: center;" v-else>
      <div v-if="bindInfo.avatar">
        <img style="width: 64px; height: 64px;" :src="bindInfo.avatar" />
      </div>
      <br/>
      <div style="font-size: 16px; font-weight: bold;">{{bindInfo.nickname}}</div>
      <br/>
      <div>账号未绑定</div>
      <br/>
      <div>
        <el-button type="primary" @click="bindLogin">登录并绑定现有账号</el-button>
        <el-button type="primary" :disabled="true">注册新账号</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { oauthLoginCallback } from '@/api/user';
import Qs from 'qs'
// import img from '../../../pages/datav/option/components/img.vue';
export default {
  // components: { img },
  beforeCreate() {
    let code = this.$route.query.code
    let state = this.$route.query.state
    if (!code) {
      const sps = window.location.href.substring(window.location.href.indexOf('?') + 1).split('#')
      for (let i = 0; i < sps.length; i++) {
        const s = sps[i]
        if (s.indexOf("code") >= 0) {
          const q = Qs.parse(s)
          code = q.code
          state = q.state
          break
        } else if (s.indexOf("verify_request") >= 0) {
          const q = Qs.parse(s)
          code = q.verify_request
          state = q.state
          break
        }
      }
    }
    oauthLoginCallback(code, state).then(res => {
      if (res.data.access_token) {
        this.$store.commit('user/setAccessToken', res.data.access_token)
        const sps = window.location.href.split('#')
        if (sps[0].indexOf('code=') >= 0 || sps[0].indexOf('verify_request=') >= 0) {
          window.location.href = '/'
        } else {
          this.$router.push('/')
        }
      } else if (res.data.bind_token) {
        this.bindInfo = res.data
      }
    }).catch(e => {
      console.log(e)
    })
  },
  data() {
    return {
      bindInfo: undefined
    }
  },
  methods: {
    bindLogin() {
      window.location.href = '/#/login?bind_token=' + this.bindInfo.bind_token
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.info {
  margin-bottom: 40px;
}
</style>