import request from '@/utils/request'
// 生成时间:2021-09-14 16:15:42

// 查询querytemp列表
export function listQuerytemp(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/gen/gen/querytemp/list',
        method: 'post',
        data: data
    })
}

// 查询querytemp详细
export function getQuerytemp (id) {
    return request({
        url: '/gen/gen/querytemp/get?id='+id,
        method: 'get'
    })
}


// 新增querytemp
export function addQuerytemp(data) {
    return request({
        url: '/gen/gen/querytemp/add',
        method: 'post',
        data: data
    })
}

// 修改querytemp
export function editQuerytemp(data) {
    return request({
        url: '/gen/gen/querytemp/edit',
        method: 'post',
        data: data
    })
}

// 删除querytemp
export function delQuerytemp(ids) {
    return request({
        url: '/gen/gen/querytemp/del',
        method: 'post',
        data:{ids:ids}
    })
}

