import { isPassword } from '@/utils/validate'
import { decryptedData, encryptedData } from '@/utils/encrypt'
import { getCodeImg } from "@/api/user";
const Base64 = require('js-base64').Base64
import { mapGetters, mapActions } from 'vuex'

export const login = {
    directives: {
        focus: {
            inserted(el) {
                el.querySelector('input').focus()
            },
        },
    },
    data() {
        const validateusername = (rule, value, callback) => {
            if ('' == value) {
                callback(new Error('用户名不能为空'))
            } else {
                callback()
            }
        }
        const validatePassword = (rule, value, callback) => {
            if (value ? !isPassword(value) : false) {
                callback(new Error('密码不能少于6位'))
            } else {
                callback()
            }
        }
        const validateverifiyCode = (rule, value, callback) => {
            if ('' == value) {
                callback(new Error('验证码不能为空'))
            } else {
                callback()
            }
        }
        return {
            form: {
                userpool: process.env.VUE_APP_USERPOOL,
                username: '',
                password: '',
                captcha_value: '',
                captcha_id: '',
                rememberPSW: false
            },
            codeUrl: "",
            rules: {
                username: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validateusername,
                    },
                ],
                password: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validatePassword,
                    },
                ],
                captcha_value: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validateverifiyCode,
                    },
                ]
            },
            passwordType: 'password',
            redirect: undefined
        }
    },
    computed: {
        ...mapGetters({
            accessToken: 'user/accessToken',
            application: 'application/application'
        })
    },
    watch: {
        $route: {
            handler(route) {
                //console.log(route,'route')
                this.redirect = (route.query && route.query.redirect) || '/'
            },
            immediate: true,
        },
    },
    created() {
        document.body.style.overflow = 'hidden'
    },
    beforeDestroy() {
        document.body.style.overflow = 'auto'
    },
    mounted() {
        //     if(  process.env.ENV!="production"){
        //     this.form.username = 'admin'
        //     this.form.password = '123456'
        // }
        let username = localStorage.getItem('username')

        if (username) {
            this.form.username = username
            let psw = localStorage.getItem('password')
            this.form.password = Base64.decode(psw)
            this.form.rememberPSW = localStorage.getItem('rememberPSW') == 'true' ? true : false
        }
    },
    methods: {
        getCode() {
            if (this.application.enable_captcha != '1') {
                return
            }
            getCodeImg().then(res => {
                this.codeUrl = res.data.base64;
                this.codeId = res.data.id;
                this.form.captcha_id = res.data.id;
            });
        },

        handlePassword() {
            this.passwordType === 'password'
                ? (this.passwordType = '')
                : (this.passwordType = 'password')
            this.$nextTick(() => {
                this.$refs.password.focus()
            })
        },
        handleLogin(e) {

            this.$refs.form.validate((valid) => {
                if (valid) {
                    // if(!e.agreement){
                    //     this.$baseMessage("请阅读并同意学工系统用户协议及用户隐私协议", 'warning')
                    //     return false
                    // }
                    this.loading = true
                    this.$emit('change', this.loading)

                    if (this.$route.query.bind_token) {
                        this.form.bind_token = this.$route.query.bind_token
                    }

                    this.$store.dispatch('user/login', this.form).then(() => {
                        //判断是否勾选了记住密码
                        document.body.style.overflow = 'auto'
                        if (this.form.rememberPSW) {
                            this.handleRememberPSW()
                        }
                        const routerPath =
                            this.redirect === '/404' || this.redirect === '/401'
                                ? '/'
                                : this.redirect
                        this.$router.push(routerPath).catch(() => { })
                        this.loading = false
                        this.$emit('change', this.loading)
                    }).catch((err) => {
                        console.log('登录失败', err);
                        this.getCode()
                        this.loading = false
                        this.$emit('change', this.loading)
                    })
                } else {
                    return false
                }
            })
        },
        async handleRememberPSW() {
            let psw = Base64.encode(this.form.password)
            localStorage.setItem('password', psw)
            localStorage.setItem('username', this.form.username)
            localStorage.setItem('rememberPSW', this.form.rememberPSW)
        },
        handleLoginByMsg() {
            this.$baseMessage("暂不支持短信登录", 'error')
        }
    },
}