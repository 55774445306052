<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
    >
      <!--<el-form-item label="id" prop="id">
				<el-input
					v-model="queryParams.id"
					placeholder="请输入id"
					clearable
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="任务ID" prop="task_id">
				<el-input
					v-model="queryParams.task_id"
					placeholder="请输入任务ID"
					clearable
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="接收人id" prop="user_id">
				<el-input
					v-model="queryParams.user_id"
					placeholder="请输入接收人id"
					clearable
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>-->
      <el-form-item label="任务类型" prop="task_type">
        <dict-select
          v-model="queryParams.task_type"
          placeholder="请选择任务类型"
          size="small"
          type="oa_task_type"
        />
      </el-form-item>
      <el-form-item label="任务名称" prop="task_title">
        <el-input
          v-model="queryParams.task_title"
          placeholder="请输入任务名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="派发性质" prop="task_distribute_type">
        <dict-select
          v-model="queryParams.task_distribute_type"
          placeholder="请选择派发性质"
          size="small"
          type="oa_task_distribute_type"
        />
      </el-form-item>
      <el-form-item label="接收人" prop="user_name">
        <el-input
          v-model="queryParams.user_name"
          placeholder="请输入接收人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="所属部门" prop="dept_name">
        <el-input
          v-model="queryParams.dept_name"
          placeholder="请输入所属部门"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="月份" prop="date">
        <datetime-select
          v-model="queryParams.date"
          type="month"
          placeholder="请选月份"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="cyan"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <jf-table
      :loading="loading"
      :columns="tableColumn"
      :data="dataList"
      :toolbar-config="tableToolbar"
      :query-params="queryParams"
      @checkbox-change="checkboxChangeEvent"
      @page-change="handlePageChange"
      :total="total"
      @change="tableChange"
      @handleQuery="handleQuery"
    >
      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-view"
          @click.stop="handleUpdate(row, '查看')"
          >查看
        </el-button>
        <!--<el-button
      size="mini"
      type="text"
      icon="el-icon-circle-check"
      @click.stop="handleUpdate(row, '评分')"
      v-hasPermi="[
        '/oa/oataskrecord/approve',
      ]"
      >评分
    </el-button>-->
      </template>
    </jf-table>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
    >
      <jf-form
        v-if="open"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        :readonly="true"
        :readonly-column="1"
      >
        <template #attachments>
          <el-descriptions-item>
            <template slot="label"> 任务附件 </template>
            <file-upload-ex
              v-model="form.task.attachments"
              list-type="text"
              :readonly="true"
            />
          </el-descriptions-item>
        </template>
        <template #score>
          <el-descriptions-item>
            <template slot="label"> 分数 </template>
            <el-input-number
              v-model="form.score"
              placeholder="请输入分数"
              controls-position="right"
              :min="0"
              :max="100"
              :precision="0"
              :step="1"
            />
          </el-descriptions-item>
        </template>
        <template #submit_files>
          <el-descriptions-item>
            <template slot="label"> 提交材料 </template>
            <file-upload-ex
              v-model="form.submit_files"
              list-type="text"
              :readonly="title != '提交'"
            />
          </el-descriptions-item>
        </template>
        <el-row>
          <el-col :span="12">
            <jf-form-item label="任务类型" prop="type">
              <dict-select
                v-model="form.task.type"
                placeholder=""
                type="oa_task_type"
              />
            </jf-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="任务名称" prop="task_title">
              <el-input v-model="form.task.title" placeholder="" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="任务内容" prop="task_content">
              <el-input
                v-model="form.task.content"
                placeholder=""
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="form.task.attachments" :span="12">
            <jf-form-item
              label="任务附件"
              prop="attachments"
              slots="attachments"
            >
              <file-upload-ex
                v-model="form.task.attachments"
                list-type="text"
              />
            </jf-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="接收人" prop="user_name">
              <el-input
                v-model="form.user_name"
                placeholder=""
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属部门" prop="dept_name">
              <el-input
                v-model="form.dept_name"
                placeholder=""
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-hasPermi="[
              '/oa/oataskrecord/transfer',
              '/oa/oataskrecord/submit',
              '/oa/oataskrecord/reject',
              '/oa/oataskrecord/approve',
            ]"
          >
            <el-form-item label="状态" prop="status">
              <dict-select
                v-model="form.status"
                placeholder="请选择状态"
                type="oa_task_record_status"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="title == '提交' || form.submit_status == '1'"
            v-hasPermi="['/oa/oataskrecord/submit']"
          >
            <jf-form-item
              v-if="title == '提交'"
              label="提交内容"
              prop="submit_content"
              slots="submit_content"
            >
              <el-input
                v-model="form.submit_content"
                placeholder="请输入提交内容"
                clearable
                type="textarea"
              />
            </jf-form-item>
            <jf-form-item v-else label="提交内容" :value="form.submit_content">
            </jf-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="title == '提交' || form.submit_status == '1'"
            v-hasPermi="['/oa/oataskrecord/submit']"
          >
            <jf-form-item
              label="提交材料"
              prop="submit_files"
              slots="submit_files"
            >
              <el-input
                v-model="form.submit_files"
                placeholder="请输入提交材料"
                clearable
              />
            </jf-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="title != '提交' && form.submit_status == '1'"
            v-hasPermi="['/oa/oataskrecord/submit']"
          >
            <el-form-item label="提交时间" prop="submit_time">
              <datetime-select
                v-model="form.submit_time"
                type="datetime"
                placeholder="请选择提交时间"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="form.submit_status == '1'"
            v-hasPermi="['/oa/oataskrecord/submit']"
          >
            <jf-form-item
              v-if="title == '评分'"
              label="分数"
              prop="score"
              slots="score"
            >
              <el-input-number
                v-model="form.score"
                placeholder="请输入分数"
                controls-position="right"
              />
            </jf-form-item>
            <el-form-item v-else label="分数" prop="score">
              <el-input-number
                v-model="form.score"
                placeholder="请输入分数"
                controls-position="right"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="截止时间" prop="submit_closing_date">
              <datetime-select
                v-model="form.task.submit_closing_date"
                type="datetime"
                placeholder="请选截止时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="阅读时间" prop="read_time">
              <datetime-select
                v-model="form.read_time"
                type="datetime"
                placeholder="请选择阅读时间"
              />
            </el-form-item>
          </el-col>
          <el-col
            v-if="title == '查看' && form.submit_status == '1'"
            :span="12"
          >
            <el-form-item label="评分时间" prop="approve_time">
              <datetime-select
                v-model="form.approve_time"
                type="datetime"
                placeholder="请选择评分时间"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </jf-form>

      <div slot="footer" class="dialog-footer">
        <template v-if="title == '查看'">
          <el-button @click="cancel">关 闭</el-button>
        </template>
        <template v-else-if="title == '评分'">
          <el-button type="primary" @click="handleApprove">确 定</el-button>
          <el-button @click="cancel">返 回</el-button>
        </template>
      </div>
    </el-dialog>
  </div>
</template>
	<script>
import {
  listOaTaskRecord,
  approveListOaTaskRecord,
  getOaTaskRecord,
  addOaTaskRecord,
  editOaTaskRecord,
  delOaTaskRecord,
  readOaTaskRecord,
  transferOaTaskRecord,
  submitOaTaskRecord,
  rejectOaTaskRecord,
  approveOaTaskRecord,
} from "@/api/oa/oa_task_record";
import moment from "moment";
export default {
  name: "oa-task-record-approve",
  data() {
    return {
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      tableColumn: [
        { type: "checkbox", width: 60, fixed: "left" },
        //{ field: "id", title: "id", minWidth: 120, fixed: "left" },
        //{ field: "task_id", title: "任务ID", minWidth: 120 },
        {
          field: "task.type",
          title: "任务类型",
          minWidth: 120,
          formatter: this.dictFormat,
          dictType: "oa_task_type",
        },
        { field: "task_title", title: "任务名称", minWidth: 120 },
        //{ field: "user_id", title: "接收人id", minWidth: 120 },
        { field: "user_name", title: "接收人", minWidth: 120 },
        //{ field: "dept_id", title: "接收人部门id", minWidth: 120 },
        { field: "dept_name", title: "所属部门", minWidth: 120 },
        //{ field: "transfer_ids", title: "转发人id", minWidth: 120 },
        //{ field: "transfer_names", title: "转派给", minWidth: 120, visible: this.hasPermi(['/oa/oataskrecord/transfer']) },
        {
          field: "status",
          title: "状态",
          minWidth: 120,
          formatter: this.dictFormat,
          dictType: "oa_task_record_status",
        },
        //{ field: "submit_position", title: "位置", minWidth: 120 },
        //{ field: "submit_position_remark", title: "位置描述", minWidth: 120 },
        //{ field: "submit_status", title: "提交状态", minWidth: 120 },
        //{ field: "submit_content", title: "提交内容", minWidth: 120 },
        //{ field: "submit_files", title: "提交材料", minWidth: 120 },
        {
          field: "submit_time",
          title: "提交时间",
          minWidth: 120,
          formatter: "formatDate",
          visible: this.hasPermi(["/oa/oataskrecord/submit"]),
        },
        {
          field: "score",
          title: "分数",
          minWidth: 120,
          visible: this.hasPermi(["/oa/oataskrecord/submit"]),
        },
        //{ field: "approve_status", title: "审核状态", minWidth: 120 },
        //{ field: "approve_content", title: "审核结果及原因", minWidth: 120 },
        //{ field: "approve_by", title: "审核人", minWidth: 120 },
        //{ field: "approve_time", title: "审核时间", minWidth: 120 },
        //{ field: "readed", title: "是否已读", minWidth: 120 },
        {
          field: "task.submit_closing_date",
          title: "截止时间",
          minWidth: 120,
          formatter: "formatDate",
        },
        {
          field: "read_time",
          title: "阅读时间",
          minWidth: 120,
          formatter: "formatDate",
        },
        {
          field: "approve_time",
          title: "评分时间",
          minWidth: 120,
          formatter: "formatDate",
        },
        {
          field: "create_time",
          title: "创建时间",
          minWidth: 120,
          formatter: "formatDate",
          visible: false,
        },
        {
          field: "update_time",
          title: "更新时间",
          minWidth: 120,
          formatter: "formatDate",
          visible: false,
        },
        {
          field: "",
          title: "操作",
          width: 180,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],
      loading: true,
      ids: [],
      single: true,
      multiple: true,
      total: 0,
      dataList: [],
      title: "",
      open: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        id: undefined,
        task_id: undefined,
        user_id: undefined,
        dept_id: undefined,
        task_title: undefined,
        user_name: undefined,
        dept_name: undefined,
        date: undefined,
      },
      form: {
        task: {},
      },
      rules: {
        task_id: [
          { required: true, message: "任务ID不能为空", trigger: "blur" },
        ],
        user_id: [
          { required: true, message: "接收人id不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    for (let i = 0; i < this.tableColumn.length; i++) {
      const col = this.tableColumn[i];
      if (col.dictType) {
        this.getDicts(col.dictType).then((response) => {
          if (response.code == 0) {
            col.dictData = response.data;
            this.dataList = [].concat(this.dataList);
          }
        });
      }
    }
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.ids = [];
      this.single = true;
      this.multiple = true;

      const queryParams = {
        ...this.queryParams,
      };

      if (queryParams.date) {
        queryParams.begin_time = queryParams.date;
        queryParams.end_time = parseInt(
          moment(queryParams.date * 1000)
            .add(1, "months")
            .valueOf() / 1000
        );
      }

      approveListOaTaskRecord(queryParams).then((response) => {
        this.dataList = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      });
    },

    dictFormat(e) {
      const col = this.tableColumn[e.columnIndex];
      if (col.dictData) {
        const values = e.cellValue ? e.cellValue.split(",") : [];
        const labels = [];
        for (let i = 0; i < values.length; i++) {
          const v = values[i];
          for (let j = 0; j < col.dictData.values.length; j++) {
            const item = col.dictData.values[j];
            if (v == item.key) {
              labels.push(item.value);
              break;
            }
          }
        }
        return labels.join(",");
      }
      return e.cellValue;
    },

    cancel() {
      this.open = false;
      this.reset();
    },

    reset() {
      this.form = {
        task_id: undefined,
        user_id: undefined,
        user_name: undefined,
        dept_id: undefined,
        dept_name: undefined,
        transfer_ids: undefined,
        transfer_names: undefined,
        transfer_from_ids: undefined,
        transfer_from_names: undefined,
        readed: undefined,
        read_time: undefined,
        status: undefined,
        submit_position: undefined,
        submit_position_remark: undefined,
        submit_status: undefined,
        submit_content: undefined,
        submit_files: undefined,
        submit_time: undefined,
        score: undefined,
        approve_status: undefined,
        approve_content: undefined,
        approve_by: undefined,
        approve_time: undefined,
        task: {},
      };
      this.resetForm("form");
    },

    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },

    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
    },

    handleUpdate(row, title) {
      this.reset();
      const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
      getOaTaskRecord(id).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = title;
      });
    },

    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            editOaTaskRecord(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          } else {
            addOaTaskRecord(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          }
        }
      });
    },

    handleDelete(row) {
      const ids = row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
      this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delOaTaskRecord(ids);
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },

    handlePageChange({ currentPage, pageSize }) {
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },

    handleApprove() {
      approveOaTaskRecord({
        id: this.form.id,
        score: this.form.score,
        approve_status: "1",
      }).then((response) => {
        this.msgSuccess("评分成功");
        this.open = false;
        this.getList();
      });
    },
  },
};
</script>
	