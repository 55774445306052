<template>
  <div style="display: none"></div>
</template>

<script>
  export default {
    name: "select-defaultValue-editor",
    props: {
      designer: Object,
      selectedWidget: Object,
      optionModel: Object,
    },
  }
</script>

<style scoped>

</style>
