<template>
    <div>
      <el-form-item :label="i18nt('designer.setting.requestParams')">
        <el-input  v-model="optionModel.requestParams" ></el-input>
      </el-form-item>
   
    </div>
  </template>
  
  <script>
    import i18n from "@/vform/utils/i18n"
  
    export default {
      name: "request-params-editor",
      mixins: [i18n],
      props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
      },
    }
  </script>
  
  <style scoped>
    
  </style>