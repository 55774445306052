<template>
    <el-form-item
        v-if="!hasConfig('optionItems')&&!designer.isTopic"
        :label="i18nt('designer.setting.defaultValue')"
    >
        <!-- <el-input
            type="text"
            v-model="optionModel.defaultValue"
            @change="emitDefaultValueChange"
            v-if="selectedWidget.type !== 'personnel-select'"
        ></el-input> -->
        <!-- v-else -->
        <el-input
    
            :value="setDefaultValue(optionModel.defaultValue)"
            placeholder="请输入默认值"
            @input="onDefaultValueInput"
        >
            <el-select
                slot="append"
                v-model="chooseDefaultValue"
                @change="onDefaultValueInput"
                placeholder="请选择"
                style="width: 90px"
            >
                <el-option
                    v-for="item in chooseDefaultValueOption"
                    :key="item.value"
                    v-if="showDefaultValueOption(item)"
                    :title="item.label"
                    :label="item.label"
                    :value="item.value"
                >
                    <span style="float: left">{{ item.label }}</span>
                    <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.value }}</span
                    >
                </el-option>
            </el-select>
        </el-input>
    </el-form-item>
</template>

<script>
import i18n from "@/vform/utils/i18n";
import propertyMixin from "@/vform/components/form-designer/setting-panel/property-editor/propertyMixin";
import { isArray } from "util";
import { isNumberStr } from "@/vform/utils/util";
export default {
    name: "defaultValue-editor",
    mixins: [i18n, propertyMixin],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            chooseDefaultValueOption: [
                {
                    value: "",
                    label: "请选择",
                },
                {
                    value: "${user.username}",
                    label: "账号名",
                },
                {
                    value: "${user.realname}",
                    label: "姓名",
                },
                {
                    value: "${user.nickname}",
                    label: "昵称",
                },
                /* {
          value: '${user.no}',
          label: '工号'
        }, */ {
                    value: "${user.dept.name}",
                    label: "部门",
                },
                {
                    value: "${user.dept}",
                    label: "所在部门",
                },
                {
                    value: "${user.idcard}",
                    label: "证件号",
                },
                {
                    value: "${user.mobile}",
                    label: "手机号",
                },
                {
                    value: "${user.gender}",
                    label: "性别",
                },
                {
                    value: "${moment.year}",
                    label: "年份",
                },
                {
                    value: "${moment.date}",
                    label: "日期",
                },
                {
                    value: "${moment.datetime}",
                    label: "时间",
                },
            ],
            chooseDefaultValue: "",
        };
    },
    mounted() {

    },
    methods: {
        showDefaultValueOption(item) {
            if (this.selectedWidget.type === "dep-select" || this.selectedWidget.type === "personnel-select") {
                if (item.value === "${user.dept}") {
                    return true;
                }
                return false;
            }
            if (this.selectedWidget.type === "picture-upload") {
                // this.optionModel.type === "image" &&
                if (
                    item.value === "${user.avatar}"
                ) {
                    return true;
                }
                return false;
            }
            return !["${user.dept}", "${user.avatar}"].includes(item.value);
        },
        setDefaultValue(val) {
            if (Array.isArray(val)) {
                return val.join(",");
            }
            if (["string", "number"].indexOf(val) > -1) {
                return val;
            }
            if (typeof val === "boolean") {
                return `${val}`;
            }
            let chooseOption = this.chooseDefaultValueOption.find((item) => {
                return item.value == val;
            });
            if (chooseOption) {
                this.chooseDefaultValue = val;
            } else {
                this.chooseDefaultValue = "";
            }
            return val;
        },
        onDefaultValueInput(str) {
            if (isArray(this.optionModel.defaultValue)) {
                // 数组
                this.$set(
                    this.optionModel,
                    "defaultValue",
                    str.split(",").map((val) => (isNumberStr(val) ? +val : val))
                );
            } else if (["true", "false"].indexOf(str) > -1) {
                // 布尔
                this.$set(this.optionModel, "defaultValue", JSON.parse(str));
            } else {
                // 字符串和数字
                this.$set(
                    this.optionModel,
                    "defaultValue",
                    isNumberStr(str) ? +str : str
                );
            }
        },
    },
};
</script>

<style scoped>
</style>
