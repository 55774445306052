<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="userGroupList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template> -->

            <!--默认操作按钮-->
            <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

            <!--自定义空数据模板-->
            <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="userGroupList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>
         
            <template slot="status" slot-scope="{ row }">
                {{ statusFormat({ row }) }}
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改用户分组对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="500px"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" placeholder="名称" />
                </el-form-item>
                <el-form-item label="权限">
                    <el-tree
                        :data="ruleOptions"
                        show-checkbox
                        ref="rule"
                        node-key="id"
                        :check-strictly="roleCheckStrictly"
                        empty-text="暂无数据"
                        :props="defaultProps"
                        @check="ruleCheck"
                        @check-change="ruleChange"
                    ></el-tree>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio-group v-model="form.status">
                        <el-radio
                            v-for="dict in statusOptions.values"
                            :key="dict.key"
                            :label="dict.key"
                            >{{ dict.value }}</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listUserGroup,
    getUserGroup,
    addUserGroup,
    updateUserGroup,
    delUserGroup,
} from "@/api/ucenter/usergroup";
import { listUserRule } from "@/api/ucenter/userrule";

export default {
    name: "ucenter-usergroup-list",
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // {type: 'checkbox', width: 60, fixed: "left"},
                { field: "id", title: "编号", width: 100, fixed: "left", visible: false },
                { field: "name", title: "名称" },
                {
                    field: "status",
                    title: "状态",
                    // formatter: this.statusFormat,
                    slots:{
                        default:'status'
                    },
                    form_type:2,
                    options:[],
                    options_label:'label',
                    options_value:'value'
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    formatter: "formatDate",
                 
                },
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 用户分组表格数据
            userGroupList: [],
            ruleOptions: [],
            defaultProps: {
                children: "children",
                label: "title",
            },
            roleCheckStrictly: false,
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 状态数据字典
            statusOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                name: [
                    {
                        required: true,
                        message: "用户分组名称不能为空",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "用户分组顺序不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();

        listUserRule(this.getUserPool()).then((response) => {
            this.ruleOptions = this.handleTree(response.data.list, "id", "pid");
        });
    },
    methods: {
      getUserPool() {
        const sps = this.$route.path.split('/')
        return sps[2]
      },
        //列表的排序和筛选
        tableChange(e){},
        ruleChange(){},
        ruleCheck(){},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询用户分组列表 */
        getList() {
            this.loading = true;
            listUserGroup(this.getUserPool(), this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.userGroupList = response.data.list;
                this.statusOptions = response.data.statusOptions;
                this.total = response.data.total;
                this.loading = false;
                for(let i=0;i<this.tableColumn.length;i++){
                    if(this.tableColumn[i].field === 'status'){
                        this.tableColumn[i].options =  this.statusOptions.values
                        return
                    }
                }
            });
        },
        statusFormat({ row }) {
            return this.selectDictLabel(this.statusOptions.values, row.status);
        },
        getRuleAllCheckedKeys() {
            let checkedKeys = this.$refs.rule.getHalfCheckedKeys();
            let halfCheckedKeys = this.$refs.rule.getCheckedKeys();
            checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
            return checkedKeys;
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.roleCheckStrictly = false;
            if (this.$refs.rule != undefined) {
                this.$refs.rule.setCheckedKeys([]);
            }

            this.form = {
                id: undefined,
                name: undefined,
                status: "1",
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加用户分组";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getUserGroup(this.getUserPool(), id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    this.form = {
                        id: data.id,
                        name: data.name,
                        rules: data.rules,
                        status: data.status,
                    };
                }

                this.open = true;
                this.title = "修改用户分组";

                this.roleCheckStrictly = true;
                this.$nextTick(() => {
                    this.$refs.rule.setCheckedKeys(data.rules.split(","));
                    this.roleCheckStrictly = false;
                });
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.form.rules = this.getRuleAllCheckedKeys().join(",");
                    if (this.form.id != undefined) {
                        updateUserGroup(this.getUserPool(), this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addUserGroup(this.getUserPool(), this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(
                '确定删除吗?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    return delUserGroup(this.getUserPool(), ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有用户分组数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportCategory(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
    },
};
</script>
