import request from '@/utils/request'
// 生成时间:2022-06-16 16:37:28

// 查询teacher列表
export function listTeacher(query) {
  console.log(query)
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/jyzx/teacher/list',
        method: 'post',
        data: data
    })
}

// 查询teacher详细
export function getTeacher (id) {
    return request({
        url: '/jyzx/teacher/get?id='+id,
        method: 'get'
    })
}


// 新增teacher
export function addTeacher(data) {
    return request({
        url: '/jyzx/teacher/add',
        method: 'post',
        data: data
    })
}

// 修改teacher
export function editTeacher(data) {
    return request({
        url: '/jyzx/teacher/edit',
        method: 'post',
        data: data
    })
}

// 删除teacher
export function delTeacher(ids) {
    return request({
        url: '/jyzx/teacher/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 删除teacher查询模板
export function advquerydelTeacher(ids) {
    return request({
        url: '/jyzx/teacher/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加teacher查询模板
export function advquerysaveTeacher(data) {
    return request({
        url: '/jyzx/teacher/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表teacher查询模板列表
export function advquerylistTeacher(businessname) {
    return request({
        url: '/jyzx/teacher/advquerylist?businessname='+businessname,
        method: 'get'
    })
}
export function syncAccount(query) {
  var data={
    filterstr:"",
    orderby:"",
    opstr:query.opstr ||{},
    page_size:query.pageSize,
    page:query.pageNum
  };
  var filterstr={};
  for(var k in query){
    var item=query[k];
    if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
      filterstr[k]=item;
    }
  }
  data["filterstr"] = JSON.stringify(filterstr);
  data["opstr"] = JSON.stringify(data["opstr"]);
  return request({
    url: '/jyzx/teacher/syncAccount',
    method: 'post',
    data: data
  })
}

// 导出teacher
export function exportTeacher(query,exportfields,tableColumn) {
     var data={
            filterstr:"",
            orderby:"",
            opstr:query.opstr ||{},
        };
        var filterstr={};
        for(var k in query){
         var item=query[k];
         if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
           filterstr[k]=item;
         }
        }
        var tarexportfieldslist=[];
        for(var k in tableColumn){
          var tlbf = tableColumn[k];
           for(var i in exportfields){
             var ef = exportfields[i]
             if(tlbf.field!="" && tlbf.field && tlbf.field!=null && tlbf.field == ef){
               tarexportfieldslist.push({"filed":ef,"title":tlbf.title});
               break;
             }
           }
        }

        data["filterstr"] = JSON.stringify(filterstr);
        data["opstr"] = JSON.stringify(data["opstr"]);
        data["fields"] = JSON.stringify(tarexportfieldslist);
        return request({
            url: '/jyzx/teacher/export',
            method: 'post',
            data: data
        })
}
