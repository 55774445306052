import province from "./province.js";
export const containers = [];

export const basicFields = [
  {
    type: "input",
    alias: "", //组件别名，可以自定义
    icon: "text-field",
    formItemFlag: true,
    options: {
      scoringMethod: "2",
      answer: "",
      scoring: {},
      answerKey: "",
 
      name: "",
      label: "填空题",
      //   labelAlign: "",
      //   type: "text",
  
      //   disabled: false,
      hidden: false,
     
      //-------------------
      //   onCreated: "",
      //   onMounted: "",
      //   onInput: "",
      //   onChange: "",
      //   onFocus: "",
      //   onBlur: "",
      //   onValidate: "",
      //   onAppendButtonClick: "",
    },
  },
  //   {
  //     type: "list-selection",
  //     alias: "", //组件别名，可以自定义
  //     icon: "text-field",
  //     formItemFlag: true,
  //     options: {
  //       api: "",
  //       apiMethod: "",
  //       requestParams:"",
  //       tableColumn:[],
  //       fieldType: "",
  //       dsConf_dstid: "",
  //       dsConf_dstattr: "",
  //       showpos: ["列表"],
  //       querytype: "",
  //       showsortbtn: false,
  //       istreeparent: false,
  //       istreelabel: "",
  //       name: "",
  //       label: "",
  //       labelAlign: "",
  //       type: "text",
  //       defaultValue: "",
  //       placeholder: "",
  //       columnWidth: "200px",
  //       size: "",
  //       labelWidth: null,
  //       labelHidden: false,
  //       labelWrap: false, //自动换行
  //       readonly: true,
  //       disabled: false,
  //       hidden: false,
  //       clearable: true,
  //       showPassword: false,
  //       required: false,
  //       requiredHint: "",
  //       validation: "",
  //       validationHint: "",
  //       //-------------------
  //       title: "选择列表",
  //       width: "50%",
  //       fullscreen: false,
  //       showModal: true,
  //       showClose: true,
  //       closeOnClickModal: false,
  //       closeOnPressEscape: false,
  //       center: false,
  //       readMode: false,
  //       disabledMode: false,
  //       okButtonLabel: "确认",
  //       okButtonHidden: false,
  //       cancelButtonLabel: "关闭",
  //       cancelButtonHidden: false,
  //       //-------------------
  //       customClass: "", //自定义css类名
  //       labelIconClass: null,
  //       labelIconPosition: "rear",
  //       labelTooltip: null,
  //       minLength: null,
  //       maxLength: null,
  //       showWordLimit: false,
  //       prefixIcon: "",
  //       suffixIcon: "",
  //       appendButton: true,
  //       appendButtonDisabled: false,
  //       buttonIcon: "el-icon-search",
  //       //-------------------
  //       onCreated: "",
  //       onMounted: "",
  //       onInput: "",
  //       onChange: "",
  //       onFocus: "",
  //       onBlur: "",
  //       onValidate: "",
  //       onAppendButtonClick: "",

  //       onOkButtonClick: "",
  //       onCancelButtonClick: "",
  //       onDialogOpened: "",
  //       onDialogBeforeClose: "",
  //     },
  //   },
  {
    type: "textarea",
    icon: "textarea-field",
    formItemFlag: true,
    options: {
      scoringMethod: "2",
      answer: "",
      scoring: {},
      answerKey: "",
   
      name: "",
      label: "简答题",
      labelAlign: "",
      rows: 3,
   
      hidden: false,
   
    },
  },

  //   {
  //     type: "number",
  //     icon: "number-field",
  //     formItemFlag: true,
  //     options: {
  //       fieldType: "",
  //       dsConf_dstid: "",
  //       dsConf_dstattr: "",
  //       showpos: [],
  //       querytype: "",
  //       showsortbtn: false,
  //       name: "",
  //       label: "",
  //       labelAlign: "",
  //       defaultValue: 0,
  //       placeholder: "",
  //       columnWidth: "200px",
  //       size: "",
  //       labelWidth: null,
  //       labelHidden: false,
  //       labelWrap: false, //自动换行
  //       disabled: false,
  //       hidden: false,
  //       required: false,
  //       requiredHint: "",
  //       validation: "",
  //       validationHint: "",
  //       //-------------------
  //       customClass: "", //自定义css类名
  //       labelIconClass: null,
  //       labelIconPosition: "rear",
  //       labelTooltip: null,
  //       min: -100000000000,
  //       max: 100000000000,
  //       precision: 0,
  //       step: 1,
  //       controlsPosition: "right",
  //       //-------------------
  //       onCreated: "",
  //       onMounted: "",
  //       onChange: "",
  //       onFocus: "",
  //       onBlur: "",
  //       onValidate: "",
  //     },
  //   },

  {
    type: "radio",
    icon: "radio-field",
    formItemFlag: true,
    options: {
      scoringMethod: "1",
      answer: "",
      scoring: {},
      answerKey: "",
      //   dsConf_dstid: "",
      //   fieldType: "",
      //   dsConf_dstattr: "",
      //   showpos: [],
      //   querytype: "",
      //   showsortbtn: false,
      //   dicttype: "",
      name: "",
      label: "单选题",
      //   labelAlign: "",
      //   defaultValue: null,
      //   columnWidth: "200px",
      //   size: "",
      //   displayStyle: "inline",
      //   buttonStyle: false,
      //   border: false,
      //   labelWidth: null,
      //   labelHidden: false,
      //   labelWrap: false, //自动换行
      //   disabled: false,
      hidden: false,
      //   dsEnabled: false, // 是否使用数据源数据
      //   dsName: "", // 数据源名称
      //   dsConf_dst_label: "",
      //   dsConf_dst_value: "",
      //   dataSetName: "", //数据集名称
      //   labelKey: "label",
      //   valueKey: "value",
      //   optionValueType: "String",
      optionItems: [
        { label: "A.选项1", value: "A" },
        { label: "B.选项2", value: "B" },
        { label: "C.选项3", value: "C" },
        { label: "D.选项4", value: "D" },
      ],
     
    },
  },
  {
    type: "radio-judge",
    icon: "radio-field",
    formItemFlag: true,
    options: {
      scoringMethod: "1",
      answer: "",
      scoring: {},
      answerKey: "",
      //   dsConf_dstid: "",
      //   fieldType: "",
      //   dsConf_dstattr: "",
      //   showpos: [],
      //   querytype: "",
      //   showsortbtn: false,
      //   dicttype: "",
      name: "",
      label: "判断题",
      //   labelAlign: "",
      //   defaultValue: null,
      //   columnWidth: "200px",
      //   size: "",
      //   displayStyle: "inline",
      //   buttonStyle: false,
      //   border: false,
      //   labelWidth: null,
      //   labelHidden: false,
      //   labelWrap: false, //自动换行
      //   disabled: false,
      hidden: false,
 
      optionItems: [
        { label: "A.正确", value: "A" },
        { label: "B.错误", value: "B" },
        
      ],
     
    },
  },

  {
    type: "checkbox",
    icon: "checkbox-field",
    formItemFlag: true,
    options: {
      scoringMethod: "2",
      answer: [],
      scoring: {},
      answerKey: "",
     
      name: "",
      label: "多选题",
    
      hidden: false,
   
      labelKey: "label",
      valueKey: "value",
      defaultValue: [],
      optionValueType: "String",
      optionItems: [
        { label: "A.选项1", value: 'A' },
        { label: "B.选项2", value: 'B' },
        { label: "C.选项3", value: 'C' },
        { label: "D.选项4", value: "D" },
      ],
 
    },
  },

  {
    type: "select",
    icon: "select-field",
    formItemFlag: true,
    options: {
      // istreeparent:false,//树形表父类
      name: "",
      showpos: [],
      scoringMethod: "1",
      answer: "",
      scoring: {},
      answerKey: "",
     
      label: "下拉题",
     
      labelWrap: false, //自动换行
     
      hidden: false,
    
      labelKey: "label",
      valueKey: "value",
      optionItems: [
        { label: "A.选项1", value: "A" },
        { label: "B.选项2", value: "B" },
        { label: "C.选项3", value: "C" },
        { label: "D.选项4", value: "D" },
      ],
      
    },
  },

//   {
//     type: "personnel-select",
//     icon: "button",
//     formItemFlag: true,
//     options: {
//       fieldType: "",
//       dsConf_dstid: "",
//       dsConf_dstattr: "",
//       showpos: [],
//       querytype: "",
//       showsortbtn: false,
//       name: "",
//       label: "成员",
//       title: "成员",
//       maxNum: 1,
//       columnWidth: "200px",
//       defaultValue: null,
//       size: "",
//       displayStyle: "block",
//       labelAlign: "",
//       disabled: false,
//       hidden: false,
//       type: "primary",
//       plain: false,
//       round: false,
//       circle: false,
//       icon: "el-icon-plus",
//       required: false,
//       requiredHint: "",
//       customRule: "",
//       customRuleHint: "",
//       //-------------------
//       customClass: "", //自定义css类名
//       //-------------------
//       onCreated: "",
//       onMounted: "",
//       onInput: "",

//       // onClick: '',
//     },
//   },
//   {
//     type: "dep-select",
//     icon: "button",
//     formItemFlag: true,
//     options: {
//       fieldType: "",
//       dsConf_dstid: "",
//       dsConf_dstattr: "",
//       showpos: [],
//       querytype: "",
//       showsortbtn: false,
//       name: "",
//       label: "部门",
//       title: "部门",
//       labelAlign: "",
//       maxNum: 1,
//       columnWidth: "200px",
//       defaultValue: null,
//       size: "",
//       displayStyle: "block",
//       disabled: false,
//       hidden: false,
//       type: "primary",
//       plain: false,
//       round: false,
//       circle: false,
//       icon: "el-icon-plus",
//       required: false,
//       requiredHint: "",
//       customRule: "",
//       customRuleHint: "",
//       //-------------------
//       customClass: "", //自定义css类名
//       //-------------------
//       onCreated: "",
//       onMounted: "",
//       onChange: "",
//       // onClick: '',
//     },
//   },

  //
];

export const advancedFields = [];

export const customFields = [];

export function addContainerWidgetSchema(containerSchema) {
  containers.push(containerSchema);
}

export function addBasicFieldSchema(fieldSchema) {
  basicFields.push(fieldSchema);
}

export function addAdvancedFieldSchema(fieldSchema) {
  advancedFields.push(fieldSchema);
}

export function addCustomWidgetSchema(widgetSchema) {
  customFields.push(widgetSchema);
}
