import request from '@/utils/request'
// 生成时间:2023-01-16 18:05:56

// 查询oss列表
export function listOss(query) {
  var data={
    filterstr:"",
    orderby:"",
    opstr:query.opstr ||{},
    page_size:query.pageSize,
    page:query.pageNum
  };
  var filterstr={};
  for(var k in query){
    var item=query[k];
    if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
      filterstr[k]=item;
    }
  }
  data["filterstr"] = JSON.stringify(filterstr);
  data["opstr"] = JSON.stringify(data["opstr"]);
  return request({
    url: '/dss/oss/list',
    method: 'post',
    data: data
  })
}

// 查询oss列表
export function listOssShare(query) {
  var data={
    filterstr:"",
    orderby:"",
    opstr:query.opstr ||{},
    page_size:query.pageSize,
    page:query.pageNum
  };
  var filterstr={};
  for(var k in query){
    var item=query[k];
    if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
      filterstr[k]=item;
    }
  }
  data["filterstr"] = JSON.stringify(filterstr);
  data["opstr"] = JSON.stringify(data["opstr"]);
  return request({
    url: '/dss/oss/share/list',
    method: 'post',
    data: data
  })
}


export function delOssShare(ids) {
  return request({
    url: '/dss/oss/share/del',
    method: 'post',
    data:{ids:ids}
  })
}


// 查询oss详细
export function getOss (id) {
  return request({
    url: '/dss/oss/get?id='+id,
    method: 'get'
  })
}

// oss 分享
export function shareOss (data) {
  return request({
    url: '/dss/oss/share/add',
    method: 'post',
    data: data
  })
}


// 新增oss
export function addOss(data) {
  return request({
    url: '/dss/oss/add',
    method: 'post',
    data: data
  })
}

// 修改oss
export function editOss(data) {
  return request({
    url: '/dss/oss/edit',
    method: 'post',
    data: data
  })
}

// 删除oss
export function delOss(ids) {
  return request({
    url: '/dss/oss/del',
    method: 'post',
    data:{ids:ids}
  })
}

// 删除oss查询模板
export function advquerydelOss(ids) {
  return request({
    url: '/dss/oss/advquerydel',
    method: 'post',
    data:{ids:ids}
  })
}

// 添加oss查询模板
export function advquerysaveOss(data) {
  return request({
    url: '/dss/oss/advquerysave',
    method: 'post',
    data: data
  })
}
// 列表oss查询模板列表
export function advquerylistOss(businessname) {
  return request({
    url: '/dss/oss/advquerylist?businessname='+businessname,
    method: 'get'
  })
}
// 导出oss
export function exportOss(query,exportfields,tableColumn) {
  var data={
    filterstr:"",
    orderby:"",
    opstr:query.opstr ||{},
  };
  var filterstr={};
  for(var k in query){
    var item=query[k];
    if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
      filterstr[k]=item;
    }
  }
  var tarexportfieldslist={};
  for(var k in tableColumn){
    var tlbf = tableColumn[k];
    for(var i in exportfields){
      var ef = exportfields[i]
      if(tlbf.field!="" && tlbf.field && tlbf.field!=null && tlbf.field == ef){
        tarexportfieldslist.push({"filed":ef,"title":tlbf.title});
        break;
      }
    }
  }

  data["filterstr"] = JSON.stringify(filterstr);
  data["opstr"] = JSON.stringify(data["opstr"]);
  data["exportfields"] = JSON.stringify(tarexportfieldslist);
  return request({
    url: '/dss/oss/export',
    method: 'post',
    data: data
  })
}
