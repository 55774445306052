<template>
<div class="app-container">
	<el-form
		:model="queryParams"
		ref="queryForm"
		:inline="true"
		label-width="68px"
        label-position="left"
		>
		<el-form-item label="名称" prop="course_name">
			<el-input
				v-model="queryParams.course_name"
				placeholder="请输入名称"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="分类" prop="category_id">
			<gbpx-course-category-select
				v-model="queryParams.category_id"
				placeholder="请选择分类"
				valueField="id"
				size="small"
			/>
		</el-form-item>
		<!--<el-form-item label="主讲人" prop="teacher_id">
			<gbpx-teacher-select
				v-model="queryParams.teacher_id"
				placeholder="请选择主讲人"
				valueField="id"
				size="small"
			/>
		</el-form-item>-->
		<el-form-item>
			<el-button
				type="primary"
				icon="el-icon-search"
		
				@click="handleQuery"
				>搜索</el-button
			>
			<el-button icon="el-icon-refresh"  @click="resetQuery"
				>重置</el-button
			>
		</el-form-item>
	</el-form>

  <el-row :gutter="20">
      <el-col :span="4" v-for="(o, index) in dataList" :key="o.id" class="col-item-container">
        <el-card :body-style="{ padding: '0px' }">
          <img :src="o.screenshot || '/images/gbpx/book.gif'" class="image">
          <div style="padding: 10px;">
            <div style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;font-size:15px;" :title="o.course_name">
              <span style="color: #000">{{o.course_name}}</span>
            </div>
            <div style="font-size: 14px; color: #555; margin-top: 10px;">
              <span>主讲人：</span>
              <span>{{o.teacher_name}}</span>
            </div>
            <div style="font-size: 14px; color: #555; margin-top: 10px;">
              <span>选课：</span>
              <span style="color: #999">100</span>
              <span style="margin-left: 5px">人次</span>
              <span style="margin-left: 20px">学时：</span>
              <span style="color: #999">1.0</span>
            </div>
            <div class="bottom">
              <div style="font-size: 14px; color: #555; margin-top: 5px; display: flex; flex-direction: row;">
                <div>评分：</div>
                <el-rate
                  :value="5"
                  :max="5"
                  disabled
                  text-color="#ff9900">
                </el-rate>
              </div>
              <div>
                <el-button type="primary" size="small" @click="handleClick(o)" :disabled="o.selected">{{o.selected ? '已选' : '选课'}}</el-button>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <pagination
      v-show="total>0"
      layout="total, prev, pager, next, jumper"
      :total="total"
      :limit.sync="queryParams.pageSize"
      :page.sync="queryParams.pageNum"
      @pagination="getList"
    />
</div>
</template>
<script>
import {
	addGbpxCourse,
	editGbpxCourse,
} from "@/api/gbpx/gbpx_course";
import { listGbpxTeacher } from "@/api/gbpx/gbpx_teacher";
import { listGbpxCourseTeacher } from "@/api/gbpx/gbpx_course_teacher";
import { AddBatchGbpxCourseUnit } from "@/api/gbpx/gbpx_course_unit";
import { listGbpxCourseUnit } from "@/api/gbpx/gbpx_course_unit";
export default {
	name: "gbpx-gbpxcourseteacher-list",
	data() {
		return { 
			loading: true,
			ids: [],
			total: 0,
			dataList: [],
      myCourseList: {},
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				id: undefined,
				course_name: undefined,
				category_id: undefined,
				teacher_id: undefined,
			},
      listGbpxTeacher: listGbpxTeacher,
		};
	},
	created() { 
		this.getList();

    listGbpxCourseUnit({pageSize: 1000}).then(response => {
      const myCourseList = {}
      for (let i = 0; i < response.data.list.length; i++) {
        const d = response.data.list[i]
        myCourseList[d.course_teacher_id] = d.course_teacher_id
      }
      if (this.dataList) {
        for (let i = 0; i < this.dataList.length; i++) {
          const d = this.dataList[i]
          if (myCourseList[d.id]) {
            d.selected = true
          }
        }
        this.dataList = [].concat(this.dataList)
      }
      this.myCourseList = myCourseList
    })
	},
	methods: {
		getList() {
			this.loading = true;
			this.ids = [];
			this.single = true;
			this.multiple = true;

      listGbpxCourseTeacher(this.queryParams).then(response => {
        if (response.data.list) {
          for (let i = 0; i < response.data.list.length; i++) {
            const d = response.data.list[i]
            if (this.myCourseList[d.id]) {
              d.selected = true
            }
          }
        }
        this.dataList = response.data.list;
				this.total = response.data.total;
				this.loading = false;
      })
		},
		
		handleQuery() {
			this.queryParams.pageNum = 1;
			this.getList();
		},

		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},

		submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if (this.form.id != null) {
						editGbpxCourse(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("修改成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					} else {
						addGbpxCourse(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("新增成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					}
				}
			});
		},

		handlePageChange({ currentPage, pageSize }) {
			if (this.queryParams.pageSize == pageSize) {
				this.getList();
			} else {
				this.queryParams.pageSize = pageSize;
				this.handleQuery();
			}
		},

    handleClick(o) {
      console.log(o)
      AddBatchGbpxCourseUnit(o.id).then(response => {
        o.selected = true
        this.myCourseList[o.id] = o.id
        this.dataList = [].concat(this.dataList)
      })
    }
	}
};
</script>

<style scoped>
.col-item-container {
  margin-bottom: 20px;
}
.col-item-container .el-card {
  /*height: 260px;*/
}
.col-item-container img {
  width: 100%;
  height: 200px;
}
.col-item-container .bottom {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>