<!-- 生成时间:2021-11-13 10:32:32 -->

<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
    >
      <el-form-item label="指标名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入指标名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="请选择状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="queryParams.remark"
          placeholder="请输入备注"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          type="cyan"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['growthindicators:gyxy:indexs:add']"
          >新增</el-button
        >

        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="[
            'growthindicators:gyxy:indexs:get',
            'growthindicators:gyxy:indexs:edit',
          ]"
          >修改</el-button
        >
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      row-id="id"
      :tree-config="treeConfig"
      show-overflow
      highlight-hover-row
      :loading="loading"
      :columns="tableColumn"
      :data="dataList"
      :keep-source="true"
    >
      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <template v-if="showformbtn(row)">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-plus"
            @click.stop="handleForm(row)"
          >
            表单
          </el-button>
        </template>

        <el-button
          size="mini"
          type="text"
          icon="el-icon-plus"
          @click.stop="handleAdd(row)"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          >修改
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除
        </el-button>

        <el-button
          v-if="istopnode(row)"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleSend(row)"
          >发送
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
        <span>
          <p>暂无数据</p>
        </span>
      </template>
    </vxe-grid>

    <!-- 添加或修改对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="上级编码" prop="pid">
          <treeselect
            v-model="form.pid"
            :options="treeOptions"
            :normalizer="normalizer"
            :show-count="true"
            placeholder="选择上级"
          />
        </el-form-item>
        <el-form-item label="指标名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入指标名称" />
        </el-form-item>
        <el-form-item label="分值" prop="score">
          <el-input v-model="form.score" placeholder="请输入分值" />
        </el-form-item>
        <!-- <el-form-item label="学校标准_频率" prop="school_standard">
                                           <el-input v-model="form.school_standard" placeholder="请输入学校标准_频率" />

                    </el-form-item> -->

        <el-form-item label="大一上学期" prop="school_standard_sesson_11">
          <el-input
            v-model="form.school_standard_sesson_11"
            placeholder="请输入"
          />
        </el-form-item>

        <el-form-item label="大一下学期" prop="school_standard_sesson_12">
          <el-input
            v-model="form.school_standard_sesson_12"
            placeholder="请输入"
          />
        </el-form-item>

        <el-form-item label="大二上学期" prop="school_standard_sesson_21">
          <el-input
            v-model="form.school_standard_sesson_21"
            placeholder="请输入"
          />
        </el-form-item>

        <el-form-item label="大二下学期" prop="school_standard_sesson_22">
          <el-input
            v-model="form.school_standard_sesson_22"
            placeholder="请输入"
          />
        </el-form-item>

        <el-form-item label="大三上学期" prop="school_standard_sesson_31">
          <el-input
            v-model="form.school_standard_sesson_31"
            placeholder="请输入"
          />
        </el-form-item>

        <el-form-item label="大三下学期" prop="school_standard_sesson_32">
          <el-input
            v-model="form.school_standard_sesson_32"
            placeholder="请输入"
          />
        </el-form-item>

        <el-form-item label="数据来源" prop="datasouce">
          <el-checkbox-group v-model="datasouce" size="small">
            <el-checkbox label="学生申报" border></el-checkbox>
            <el-checkbox label="导入" border></el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="额外属性" prop="extattr">
          <el-checkbox-group v-model="extattr" size="small">
            <el-checkbox label="操行" border></el-checkbox>
            <el-checkbox label="综合测评" border></el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <!--<el-form-item label="学院标准_数值" prop="college_standard">-->
        <!--<el-input v-model="form.college_standard" placeholder="请输入学院标准_数值" />-->

        <!--</el-form-item>-->

        <el-form-item label="状态" prop="status">
          <el-select
            v-model="form.status"
            placeholder="请选择状态"
            clearable
            size="small"
          >
            <el-option
              v-for="dict in statusOptions"
              :key="dict.key"
              :label="dict.value"
              :value="dict.key + ''"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  listIndexs,
  getIndexs,
  delIndexs,
  addIndexs,
  editIndexs,
  sendIndexs,
} from "@/api/growthindicators/gyxy/indexs";
import Treeselect from "@riophae/vue-treeselect";
// import { listMenu,treeselect } from "@/api/core/system/menu";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "indexs",
  components: { Treeselect },
  data() { 
    return {
      expandRowKeys: [],
      treeConfig: {
        trigger: "row",
        expandRowKeys: [1, 2],
      },
      // 列
      tableColumn: [
        {
          field: "id",
          title: "id",
          minWidth: 50,
          maxWidth: 50,
          fixed: "left",
          visible: false,
        },
        { field: "name", title: "指标名称", minWidth: 220, treeNode: true },
        { field: "score", title: "分值", minWidth: 60 },
        //            {field: 'college_name', title: '学院名称', minWidth: 80   },
        // {field: 'school_standard', title: '学校标准_数值', minWidth: 80   },
        {
          field: "school_standard_sesson_11",
          title: "大一上学期",
          minWidth: 80,
        },
        {
          field: "school_standard_sesson_12",
          title: "大一下学期",
          minWidth: 80,
        },
        {
          field: "school_standard_sesson_21",
          title: "大二上学期",
          minWidth: 80,
        },
        {
          field: "school_standard_sesson_22",
          title: "大二下学期",
          minWidth: 80,
        },
        {
          field: "school_standard_sesson_31",
          title: "大三上学期",
          minWidth: 80,
        },
        {
          field: "school_standard_sesson_32",
          title: "大三下学期",
          minWidth: 80,
        },
        //            {field: 'college_standard', title: '学院标准_数值', minWidth: 80   },
        {
          field: "status",
          title: "状态",
          minWidth: 60,
          formatter: this.statusFormat,
        },
        {
          field: "create_time",
          title: "创建时间",
          minWidth: 80,
          formatter: "formatDate",
        },
        {
          field: "update_time",
          title: "更新时间",
          minWidth: 80,
          formatter: "formatDate",
        },
        { field: "remark", title: "备注", minWidth: 80 },

        {
          field: "",
          title: "操作",
          width: 260,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],

      advqueryColumn: [
        {
          field: "qfield",
          title: "查询字段",
          width: 180,
          fixed: "left",
          editRender: {
            name: "select",
            options: [
              { value: "dd", label: "cxvs" },
              { value: "zz", label: "cxvs" },
            ],
          },
        },
        {
          field: "qopr",
          title: "操作符",
          width: 180,
          fixed: "left",
          editRender: { name: "select", options: [] },
        },
        {
          field: "qvalue",
          title: "值",
          width: 180,
          fixed: "left",
          editRender: { name: "input" },
        },
        {
          field: "qlogic",
          title: "逻辑",
          width: 180,
          fixed: "left",
          editRender: { name: "select", options: [] },
        },
        {
          field: "",
          title: "操作",
          resizable: false,
          width: 180,
          fixed: "right",
          align: "center",
          slots: { default: "defaultadvqueryopr" },
        },
      ],
      advqueryopen: false,
      advqueryData: [],
      advqueryToolbar: {
        buttons: [{ code: "myInsert", name: "新增" }],
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      treeOptions: [],
      open: false,
      // 字典
      school_standard_unitOptions: [],
      college_standard_unitOptions: [],
      statusOptions: [],

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 9999,
        opstr: {
          name: "LIKE",
          score: "=",
          college_name: "LIKE",
          school_standard: "=",
          college_standard: "=",
          status: "=",
          remark: "LIKE",
        },
        name: undefined,

        status: undefined,
        remark: undefined,
      },
      datasouce: [],
      extattr: [],
      // 表单参数
      form: {
        status: "1",

        pid: "",
        name: "",
        score: "",
        college_id: "",
        college_name: "",
        school_standard: "",
        school_standard_unit: "",
        college_standard: "",
        college_standard_unit: "",

        school_standard_sesson_11: "",
        school_standard_sesson_12: "",
        school_standard_sesson_21: "",
        school_standard_sesson_22: "",
        school_standard_sesson_31: "",
        school_standard_sesson_32: "",
      },
      // 表单校验
      rules: {
        pid: [{ required: true, message: "上级编码不能为空", trigger: "blur" }],

        name: [
          { required: true, message: "指标名称不能为空", trigger: "blur" },
        ],

        score: [{ required: true, message: "分值不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    var rec = localStorage.getItem("ExpandRecords");
    if (rec) {
      rec = JSON.parse(rec);
      this.expandRowKeys = [];
      this.getallchild(rec);
      this.treeConfig.expandRowKeys = this.expandRowKeys;
    }
    this.getList();

    setTimeout(() => {
      const $grid = this.$refs.xAdvqueryGrid;
      // 异步更新下拉选项
      var ta = [{ value: "", label: "请选择" }];
      for (var i in this.tableColumn) {
        var e = this.tableColumn[i];
        if (e.field != null && e.field != "") {
          ta.push({ value: e.field, label: e.title });
        }
      }
      this.advqueryColumn[0].editRender.options = ta;

      this.advqueryColumn[1].editRender.options = this.defaultQlogic();

      ta = [];
      ta.push({ value: "AND", label: "并且" });
      ta.push({ value: "OR", label: "或者" });
      this.advqueryColumn[3].editRender.options = ta;
    }, 300);
    // 加载字典数据
    this.getDicts("gyxy_indexs_unit").then((response) => {
      this.school_standard_unitOptions = response.data.values || [];
      this.college_standard_unitOptions = response.data.values || [];
    });

    this.getDicts("gyxy_indexsta").then((response) => {
      this.statusOptions = response.data.values || [];
    });
  },
  beforeDestroy() {
    const $grid = this.$refs.xGrid;
    localStorage.setItem(
      "ExpandRecords",
      JSON.stringify($grid.getTreeExpandRecords())
    );
  },
  methods: {
    getallchild(rows) {
      for (var j in rows) {
        var row = rows[j];

        if (row.children) {
          this.getallchild(row.children);
        }
        this.expandRowKeys.push(row.id);
      }
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },

    handleUpload() {},
    handleExport() {},
    handleAdvquery() {
      this.advqueryopen = true;
    },
    //保存条件
    advquery_save() {
      const $grid = this.$refs.xAdvqueryGrid;
      var alldata = $grid.getTableData();
      console.log("alldata", alldata);
      $grid.reloadData(alldata.fullData); //去掉编辑状态
      //      this.advqueryopen=false;
    },
    handleadvqueryDeleted(row) {
      const $grid = this.$refs.xAdvqueryGrid;

      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          $grid.remove(row);
        })
        .then(() => {
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    handleSizeChange({ currentPage, pageSize }) {
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },

    /** 查询列表 */
    getList() {
      this.loading = true;

      listIndexs(this.queryParams).then((response) => {
        this.dataList = this.handleTree(response.data.list, "id", "pid");
        this.loading = false;
      });
    },
    // $comment字典翻译
    school_standard_unitFormat({ cellValue }) {
      return this.selectDictLabel(
        this.school_standard_unitOptions,
        "" + cellValue
      );
    },

    college_standard_unitFormat({ cellValue }) {
      return this.selectDictLabel(
        this.college_standard_unitOptions,
        "" + cellValue
      );
    },

    statusFormat({ cellValue }) {
      return this.selectDictLabel(this.statusOptions, "" + cellValue);
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.advqueryopen = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        pid: undefined,
        name: undefined,
        score: undefined,
        college_id: undefined,
        college_name: undefined,
        school_standard: undefined,
        school_standard_unit: undefined,
        college_standard: undefined,
        college_standard_unit: undefined,

        school_standard_sesson_11: undefined,
        school_standard_sesson_12: undefined,
        school_standard_sesson_21: undefined,
        school_standard_sesson_22: undefined,
        school_standard_sesson_31: undefined,
        school_standard_sesson_32: undefined,
        datasouce: undefined,
        extattr: undefined,

        status: "1",
      };
      this.datasouce=[];
      this.extattr=[];
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    getTreeselect() {
      listIndexs({ pageNum: 1, pageSize: 999 }).then((response) => {
        this.treeOptions = [];
        const opt = { id: 0, name: "主类目", children: [] };
        opt.children = this.handleTree(response.data.list, "id", "pid");
        this.treeOptions.push(opt);
      });
    },

    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset();
      this.open = true;
      this.title = "添加";
      this.getTreeselect();
      if (row != null) {
        this.form.pid = row.id;
      }
    },
    showformbtn(row) {
      return !row.children || row.children.length == 0;
    },
    istopnode(row) {
      return row.pid == 0 && row.issend == "1";
    },
    handleForm(row) {
      const tableId = row.id || this.ids[0];
      this.$router.push({
        path: "/growthindicators/indextableinfo/index",
        query: { index_id: tableId },
      });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.getTreeselect();
      const id = row.id || this.ids;
      getIndexs(id).then((response) => {
        response.data.data.status = "" + response.data.data.status;
        this.form = response.data.data;

       this.form.extattr= this.form.extattr? this.form.extattr.split(","):[];
        this.form.datasouce= this.form.datasouce? this.form.datasouce.split(","):[];
        this.open = true;
        this.title = "修改";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form["datasouce"] = this.datasouce.join(",");
          this.form["extattr"] = this.extattr.join(",");

          if (this.form.id != null) {
            this.form.score = parseFloat(this.form.score);
            editIndexs(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.reset() ;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          } else {
            addIndexs(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.reset() ;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          }
        }
      });
    },
    //发送到下级
    handleSend(row) {
      const ids = row.id;

      this.$confirm("是否确认发送指标到下级填写?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return sendIndexs(ids, "2");
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("发送成功");
        })
        .catch(function () {});
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;

      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delIndexs(ids);
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
  }, //methods结束
};
</script>