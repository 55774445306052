import request from '@/utils/request'

// 查询cms文章列表
export function listCmsArchives(query) {
  return request({
    url: '/cms/archives/list',
    method: 'get',
    params: query
  })
}

// 查询cms文章详细
export function getCmsArchives(id) {
  return request({
    url: '/cms/archives/get?id=' + id,
    method: 'get'
  })
}

// 新增cms文章
export function addCmsArchives(data) {
  return request({
    url: '/cms/archives/add',
    method: 'post',
    data: data
  })
}

// 修改cms文章
export function updateCmsArchives(data) {
  return request({
    url: '/cms/archives/edit',
    method: 'post',
    data: data
  })
}

// 删除cms文章
export function delCmsArchives(id) {
  return request({
    url: '/cms/archives/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
