<template>
  <div style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: flex-start;">
    <div style="font-size: 20px; font-weight: bold; color: #06BAEB;">指标选择</div>
    <div style="margin-top: 20px;">
      <el-menu 
        class="el-menu-demo" 
        mode="horizontal" 
        @select="handleSelect"
        background-color="rgba(230, 247, 255)"
        text-color="#000"
        active-text-color="rgb(22, 132, 252)"
      >
        <el-submenu index="1">
          <template slot="title">{{zb1}}</template>
          <el-menu-item v-for="(item, index) in m1.zb" :key="index" :index="'1-' + index">{{item}}</el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">{{zb2}}</template>
          <el-menu-item v-for="(item, index) in m2.zb" :key="index" :index="'2-' + index">{{item}}</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">{{zb3}}</template>
          <el-menu-item v-for="(item, index) in m3.zb" :key="index" :index="'3-' + index">{{item}}</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div style="font-size: 20px; font-weight: bold; color: #06BAEB; margin-top: 20px;">指标完成情况</div>
    <div style="flex: 1; width: 100%; background-color: rgba(255, 255, 255, 0.1); border-radius: 20px; margin-top: 20px;"></div>
  </div>
</template>

<script>
export default {
  name: 'xczx',
  data() {
    const zb = [
      ['组织推进（18分）', '落实《中国共产党农村工作条例》（4分）', '五级书记抓乡村振兴'],
      ['组织推进（18分）', '落实《中国共产党农村工作条例》（4分）', '明确党委负责农村工作的人员'],
      ['组织推进（18分）', '农业农村优先发展（11分）', '实施“领头雁”工程'],
      ['组织推进（18分）', '农业农村优先发展（11分）', '干部配备'],
      ['组织推进（18分）', '农业农村优先发展（11分）', '吸引优秀教师到乡村学校'],
      ['组织推进（18分）', '农业农村优先发展（11分）', '资金投入'],
      ['组织推进（18分）', '农业农村优先发展（11分）', '农村公共基础设施管护'],
      ['组织推进（18分）', '制度建设（0.5分）', '建立乡村振兴督查（考核）、通报、协调“三项制度”'],
      ['组织推进（18分）', '典型示范推广（2.5分）', '打造乡村振兴试点'],
      ['组织推进（18分）', '典型示范推广（2.5分）', '改革经验推广'],
      ['产业兴旺（24分）', '粮食安全（8.5分）', '粮食面积及产量'],
      ['产业兴旺（24分）', '粮食安全（8.5分）', '做好政策性粮食收购、储备粮管理，完成粮食仓储设施项目建设任务，做好军粮供应工作'],
      ['产业兴旺（24分）', '粮食安全（8.5分）', '永久基本农田保护'],
      ['产业兴旺（24分）', '粮食安全（8.5分）', '国家粮食安全省长责任制结果运用'],
      ['产业兴旺（24分）', '重要农产品供给（5分）', '壮大现代种植业、畜牧业、渔业'],
      ['产业兴旺（24分）', '农业产业化（5.5分）', '推进农商文旅深度融合'],
      ['产业兴旺（24分）', '农业产业化（5.5分）', '大力发展现代种业'],
      ['产业兴旺（24分）', '农业产业化（5.5分）', '发展优势特色农业产业，特色农业现代化示范区建设和培育农民合作社示范社'],
      ['产业兴旺（24分）', '农业产业化（5.5分）', '发展优势特色农业产业，特色农业现代化示范区建设和培育农民合作社示范社'],
      ['产业兴旺（24分）', '农产品加工及流通（2分）', '培育发展农产品加工业及流通情况'],
      ['产业兴旺（24分）', '农业基础设施（3分）', '高标准农田建设'],
      ['产业兴旺（24分）', '农业基础设施（3分）', '水利基础设施建设'],
      ['生态宜居（18分）', '人居环境（16分）', '农村厕所革命'],
      ['生态宜居（18分）', '人居环境（16分）', '农村生活垃圾治理'],
      ['生态宜居（18分）', '人居环境（16分）', '农村生活污水治理'],
      ['生态宜居（18分）', '人居环境（16分）', '基础设施建设'],
      ['生态宜居（18分）', '人居环境（16分）', '农房管控'],
      ['生态宜居（18分）', '人居环境（16分）', '危房改造'],
      ['生态宜居（18分）', '人居环境（16分）', '乡村风貌提升行动'],
      ['生态宜居（18分）', '人居环境（16分）', '村庄规划编制'],
      ['生态宜居（18分）', '人居环境（16分）', '“两违”综合治理'],
      ['生态宜居（18分）', '人居环境（16分）', '电信普遍服务'],
      ['生态宜居（18分）', '人居环境（16分）', '村庄绿化'],
      ['生态宜居（18分）', '人居环境（16分）', '林长制'],
      ['乡风文明（6分）', '农村精神文明建设（6分）', '加强新时代农村精神文明建设'],
      ['乡风文明（6分）', '农村精神文明建设（6分）', '移风易俗'],
      ['乡风文明（6分）', '农村精神文明建设（6分）', '传承发展提升农村优秀传统文化'],
      ['治理有效（5分）', '抓好“自治”(2分）', '加强乡村振兴题材作品创作传播'],
      ['治理有效（5分）', '抓好“自治”(2分）', '推进乡村自治建设'],
      ['治理有效（5分）', '乡村应急管理和消防安全体系建设（1分）', '做好对自然灾害、公共卫生、安全隐患等重大事件的风险评估、监测预警、应急处置'],
      ['治理有效（5分）', '农民收入（3分）', '农村居民人均可支配收入'],
      ['治理有效（5分）', '农村教育（3分）', '农村幼儿园建设'],
      ['治理有效（5分）', '农村教育（3分）', '农村义务教育学校建设'],
      ['治理有效（5分）', '农村教育（3分）', '农村教师队伍建设'],
      ['治理有效（5分）', '病有所医（1分）', '医疗卫生体系建设'],
      ['治理有效（5分）', '老有所养（0.5分）', '增加优化养老服务供给'],
      ['生活富裕（11分）', '农民就业与社会保障（1分）', '抓好农民就业创业'],
      ['生活富裕（11分）', '发展集体经济（2.5分）', '扶持壮大村级集体经济'],
      ['生活富裕（11分）', '土地制度改革（2分）', '农村全域土地综合整治'],
      ['生活富裕（11分）', '宅基地改革（1.5分）', '农村宅基地管理'],
      ['深化改革（8分）', '集体产权制度改革（1.5分）', '加快推进农村集体经营性资产股份合作制改革'],
      ['深化改革（8分）', '金融支持与改革（2分）', '建立健全农村金融服务平台，创新金融支农产品和服务'],
      ['深化改革（8分）', '金融支持与改革（2分）', '开展生产、供销、信用“三位一体”综合合作试点工作']
    ]

    const m1 = {
      zb: []
    }
    for (let i = 0; i < zb.length; i++) {
      const z = zb[i]
      const zb1 = z[0]
      const zb2 = z[1]
      const zb3 = z[2]

      let m2 = m1[zb1]
      if (!m2) {
        m2 = {
          zb: []
        }
        m1[zb1] = m2
        m1.zb.push(zb1)
      }
      let m3 = m2[zb2]
      if (!m3) {
        m3 = {
          zb: []
        }
        m2[zb2] = m3
        m2.zb.push(zb2)
      }
      m3[zb3] = zb3
      m3.zb.push(zb3)
    }

    console.log(m1)

    return {
      zb: zb,
      zb1: '一级指标',
      zb2: '二级指标',
      zb3: '三级指标',
      m1: m1,
      m2: {zb: []},
      m3: {zb: []}
    }
  },
  methods: {
    handleSelect(e1, e2) {
      if (e2[0] == '1') {
        const key = this.m1.zb[e2[1].split('-')[1]]
        this.zb1 = key
        this.zb2 = '二级指标'
        this.zb3 = '三级指标'
        this.m2 = this.m1[key]
        this.m3 = {zb: []}
      } else if (e2[0] == '2') {
        const key = this.m2.zb[e2[1].split('-')[1]]
        this.zb2 = key
        this.zb3 = '三级指标'
        this.m3 = this.m2[key]
      } else if (e2[0] == '3') {
        const key = this.m3.zb[e2[1].split('-')[1]]
        this.zb3 = key
      }
    }
  }
}
</script>

<style scoped>
.el-menu-demo {

}
::v-deep .el-submenu__title {
  font-size: 16px;
}
::v-deep .el-menu-item {
  font-size: 16px;
}
</style>