import request from '@/utils/request'
// 查询api列表
export function listApi(query) {
  return request({
    url: '/data/api/list',
    method: 'get',
    params: query
  })
}
// 查询api详细
export function getApi (id) {
  return request({
    url: '/data/api/get?id='+id,
    method: 'get'
  })
}


// 新增api
export function addApi(data) {
  return request({
    url: '/data/api/add',
    method: 'post',
    data: data
  })
}

// 修改api
export function editApi(data) {
  return request({
    url: '/data/api/edit',
    method: 'post',
    data: data
  })
}

// 删除api
export function delApi(ids) {
  return request({
    url: '/data/api/del',
    method: 'post',
    data:{ids:ids}
  })
}

