<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
        >
            <el-form-item label="分类" prop="category_id">
                <el-select
                    v-model="queryParams.category_id"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in categoryList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="计费类型" prop="charge_type">
                <el-select
                    v-model="queryParams.charge_type"
                    placeholder="计费类型"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in chargeTypeOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="是否显示" prop="is_show">
                <el-select
                    v-model="queryParams.is_show"
                    placeholder="请选择是否显示"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in is_showOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['data:data:api:add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['data:data:api:get', 'data:data:api:edit']"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['data:data:api:del']"
                    >删除</el-button
                >
            </template> -->

        <!--默认操作按钮-->
        <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
            <template #pricetpl="{ row }">
                <el-tag type="" effect="light" v-if="row.charge_type == 0"
                    >{{ row.price }}元</el-tag
                >
                <el-tag
                    type="success"
                    effect="light"
                    v-if="row.charge_type == 1"
                    >{{ row.price }}%</el-tag
                >
            </template>
            <template #chargetypetpl="{ row }">
                <el-tag type="" effect="light" v-if="row.charge_type == 0">{{
                    chargeTypeFormat(row)
                }}</el-tag>
                <el-tag type="" effect="light" v-if="row.charge_type == 1">{{
                    chargeTypeFormat(row)
                }}</el-tag>
            </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['data:data:api:add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['data:data:api:get', 'data:data:api:edit']"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['data:data:api:del']"
                    >删除</el-button
                >
            </template>
            <template slot="category" slot-scope="{ row }">
                {{ categoryFormat({ row }) }}
            </template>
            <template slot="is_show" slot-scope="{ row }">
                {{ is_showFormat({ cellValue: row.is_show }) }}
            </template>
            <template slot="pricetpl" slot-scope="{ row }">
                <el-tag type="" effect="light" v-if="row.charge_type == 0"
                    >{{ row.price }}元</el-tag
                >
                <el-tag
                    type="success"
                    effect="light"
                    v-if="row.charge_type == 1"
                    >{{ row.price }}%</el-tag
                >
            </template>
            <template slot="chargetypetpl" slot-scope="{ row }">
                <el-tag type="" effect="light" v-if="row.charge_type == 0">{{
                    chargeTypeFormat(row)
                }}</el-tag>
                <el-tag type="" effect="light" v-if="row.charge_type == 1">{{
                    chargeTypeFormat(row)
                }}</el-tag>
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改对话框 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="分类" prop="category_id">
                    <el-select v-model="form.category_id" placeholder="请选择">
                        <el-option
                            v-for="item in categoryList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="图标" prop="icon">
                    <el-input v-model="form.icon" placeholder="请输入图标" />
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入名称" />
                </el-form-item>
                <el-form-item label="接口地址" prop="endpoint">
                    <el-input
                        v-model="form.endpoint"
                        placeholder="请输入接口地址"
                    />
                </el-form-item>
                <el-form-item label="申请数" prop="apps" v-if="form.id">
                    <el-input v-model="form.apps" placeholder="请输入申请数" />
                </el-form-item>
                <el-form-item label="收藏数" prop="likes" v-if="form.id">
                    <el-input v-model="form.likes" placeholder="请输入收藏数" />
                </el-form-item>
                <el-form-item label="标签" prop="tags" v-show="false">
                    <el-input v-model="form.tags" placeholder="请输入标签" />
                </el-form-item>

                <el-form-item label="单价" prop="price">
                    <el-input placeholder="请输入单价" v-model="form.price">
                        <template slot="prepend" v-if="form.id">
                            {{ chargeTypeFormat(form) }}
                        </template>
                        <template slot="prepend" v-else>
                            <el-radio-group v-model="form.charge_type">
                                <el-radio
                                    v-for="dict in chargeTypeOptions"
                                    :key="dict.key"
                                    :label="dict.key"
                                    :value="dict.value"
                                    >{{ dict.value }}</el-radio
                                >
                            </el-radio-group>
                        </template>
                        <el-button slot="append">{{
                            form.charge_type == 0 ? "元" : "%"
                        }}</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input
                        v-model="form.description"
                        placeholder="请输入描述"
                        type="textarea"
                        :rows="2"
                    />
                </el-form-item>
                <el-form-item label="是否显示" prop="is_show">
                    <el-radio-group v-model="form.is_show">
                        <el-radio
                            v-for="dict in is_showOptions"
                            :key="dict.key"
                            :label="dict.key"
                            >{{ dict.value }}</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input
                        v-model="form.remark"
                        placeholder="请输入备注"
                        type="textarea"
                        :rows="2"
                    />
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm" v-no-more-click
                    >确 定</el-button
                >
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { listApi, getApi, delApi, addApi, editApi } from "@/api/data/api";
export default {
    name: "data-api-list",
    data() {
        return {
            businessname: "api", //请勿改动
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },

            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "ID", minWidth: 80, fixed: "left" },
                {
                    field: "category",
                    title: "分类",
                    minWidth: 80,
                    is_query: true,
                    // formatter: this.categoryFormat,
                    slots: {
                        default: "category",
                    },
                    form_type: 2,
                    options: [],
                    options_label: "name",
                    options_value: "id",
                },
                {
                    field: "category_id",
                    title: "分类ID",
                    minWidth: 80,
                    visible: false,
                },
                { field: "icon", title: "图标", minWidth: 80 },
                { field: "name", title: "名称", minWidth: 80, is_query: true },
                { field: "endpoint", title: "接口地址", minWidth: 80 },
                { field: "apps", title: "申请数", minWidth: 80 },
                { field: "likes", title: "收藏数", minWidth: 80 },
                { field: "tags", title: "标签", minWidth: 80, visible: false },
                {
                    field: "price",
                    title: "单价",
                    minWidth: 80,

                    slots: { default: "pricetpl" },
                },
                {
                    field: "charge_type",
                    title: "计费类型",
                    minWidth: 90,
                    slots: { default: "chargetypetpl" },
                    options: [
                        { key: "0", value: "计次" },
                        { key: "1", value: "按实际付费" },
                    ],
                    is_query: true,
                    options_label: "value",
                    options_value: "key",
                    form_type: 2,
                },
                {
                    field: "description",
                    title: "描述",
                    minWidth: 80,
                    visible: false,
                },
                {
                    field: "is_show",
                    title: "是否显示",
                    minWidth: 80,
                    is_query: true,
                    // formatter: this.is_showFormat,
                    options: [],
                    options_label: "value",
                    options_value: "key",
                    form_type: 2,
                    slots: {
                        default: "is_show",
                    },
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 80,
                    formatter: "formatDate",
                    form_type: 3,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 80,
                    formatter: "formatDate",
                    visible: false,
                    form_type: 3,
                },
                // {
                //     field: "",
                //     title: "操作",
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 表格数据
            dataList: [],
            categoryList: [],
            chargeTypeOptions: [
                { key: "0", value: "计次" },
                { key: "1", value: "按实际付费" },
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 字典
            is_showOptions: [],
            //分类数据
            categoryOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                category_id: undefined,
                name: undefined,
                is_show: undefined,
                charge_type: undefined,
            },
            // 表单参数
            form: {
                charge_type: 0,
                is_show: 1,
            },
            // 表单校验
            rules: {
                category_id: [
                    {
                        required: true,
                        message: "分类不能为空",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                endpoint: [
                    {
                        required: true,
                        message: "接口地址不能为空",
                        trigger: "blur",
                    },
                ],
                price: [
                    {
                        required: true,
                        message: "单价不能为空",
                        trigger: "blur",
                    },
                ],
                charge_type: [
                    {
                        required: true,
                        message: "计费类型不能为空",
                        trigger: "blur",
                    },
                ],
                is_show: [
                    {
                        required: true,
                        message: "是否显示不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
        // 加载字典数据
        this.getDicts("sys_show_hide").then((response) => {
            this.is_showOptions = response.data.values || [];
            for (let i = 0; i < this.tableColumn.length; i++) {
                if (this.tableColumn[i].field === "is_show") {
                    this.tableColumn[i].options = this.is_showOptions;
                    return;
                }
            }
        });
    },
    methods: {
        tableChange(e) {},
        chargeTypeFormat(row, column) {
            return this.selectDictLabel(
                this.chargeTypeOptions,
                row.charge_type
            );
        },
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询列表 */
        getList() {
            this.loading = true;

            listApi(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
                this.categoryList = response.data.categoryList;
                for (let i in this.categoryList) {
                    let e = this.categoryList[i];
                    this.categoryOptions[e.id] = e;
                }
                for (let i = 0; i < this.tableColumn.length; i++) {
                    if (this.tableColumn[i].field === "category") {
                        this.tableColumn[i].options = this.categoryList;
                        return;
                    }
                }
            });
        },
        // $comment字典翻译
        is_showFormat({ cellValue }) {
            return this.selectDictLabel(this.is_showOptions, "" + cellValue);
        },
        categoryFormat({ row }) {
            let actions = [];
            Object.keys(this.categoryOptions).map((key) => {
                if (this.categoryOptions[key].id === row.category_id) {
                    actions.push(this.categoryOptions[key].name);
                    return false;
                }
            });
            return actions.join("");
        },

        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                category_id: undefined,
                icon: undefined,
                name: undefined,
                endpoint: undefined,
                apps: 0,
                likes: 0,
                tags: undefined,
                price: undefined,
                charge_type: undefined,
                description: undefined,
                is_show: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
            this.form.charge_type = "0";
            this.form.is_show = "1";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getApi(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editApi(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addApi(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;

            this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delApi(ids);
                })
                .then((response) => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功" + response.data.count + "项");
                })
                .catch(function () {});
        },
    }, //methods结束
};
</script>
<style scoped>
.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
</style>
