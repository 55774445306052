import request from '@/utils/request'

export function listOaTaskRecord(query) {
  return request({
    url: '/oa/oataskrecord/list',
    method: 'get',
    params: query
  })
}

export function myListOaTaskRecord(query) {
  return request({
    url: '/oa/oataskrecord/mylist',
    method: 'get',
    params: query
  })
}

export function approveListOaTaskRecord(query) {
  return request({
    url: '/oa/oataskrecord/approvelist',
    method: 'get',
    params: query
  })
}

export function getOaTaskRecord(id) {
  return request({
    url: '/oa/oataskrecord/get?id=' + id,
    method: 'get'
  })
}

export function addOaTaskRecord(data) {
  return request({
    url: '/oa/oataskrecord/add',
    method: 'post',
    data: data
  })
}

export function editOaTaskRecord(data) {
  return request({
    url: '/oa/oataskrecord/edit',
    method: 'post',
    data: data
  })
}

export function delOaTaskRecord(ids) {
  return request({
    url: '/oa/oataskrecord/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function readOaTaskRecord(data) {
  return request({
    url: '/oa/oataskrecord/read',
    method: 'post',
    data: data
  })
}

export function transferOaTaskRecord(data) {
  return request({
    url: '/oa/oataskrecord/transfer',
    method: 'post',
    data: data
  })
}

export function submitOaTaskRecord(data) {
  return request({
    url: '/oa/oataskrecord/submit',
    method: 'post',
    data: data
  })
}

export function rejectOaTaskRecord(data) {
  return request({
    url: '/oa/oataskrecord/reject',
    method: 'post',
    data: data
  })
}

export function approveOaTaskRecord(data) {
  return request({
    url: '/oa/oataskrecord/approve',
    method: 'post',
    data: data
  })
}