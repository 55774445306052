import { render, staticRenderFns } from "./field-pos-editor.vue?vue&type=template&id=033f72d8&scoped=true&"
import script from "./field-pos-editor.vue?vue&type=script&lang=js&"
export * from "./field-pos-editor.vue?vue&type=script&lang=js&"
import style0 from "./field-pos-editor.vue?vue&type=style&index=0&id=033f72d8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "033f72d8",
  null
  
)

export default component.exports