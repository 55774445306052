<template>
    <el-form-item :label="i18nt('designer.setting.istreeparentName')" v-if="!designer.isTopic">
      <el-switch v-model="optionModel.istreeparent" @change="onRemoteChange"></el-switch>
    </el-form-item>
  </template>
  
  <script>
    import i18n from "@/vform/utils/i18n"
    import propertyMixin from "@/vform/components/form-designer/setting-panel/property-editor/propertyMixin"
  
    export default {
      name: "istreeparent-editor",
      mixins: [i18n, propertyMixin],
      props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
      },
  
    }
  </script>
  
  <style scoped>
  
  </style>
  