<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
            <!--<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
            <!-- <el-form-item label="班级名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入班级名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="班级编号" prop="no">
                <el-input
                    v-model="queryParams.no"
                    placeholder="请输入班级编号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="院系" prop="dept_no">
                <dept-select
                    v-model="queryParams.dept_no"
                    placeholder="请选择院系"
                    valueField="no"
                    size="small"
                />
            </el-form-item>
            <el-form-item label="专业" prop="major_no">
                <major-select
                    v-model="queryParams.major_no"
                    placeholder="请选择专业"
                    :dept_no="queryParams.dept_no"
                    valueField="no"
                    size="small"
                />
            </el-form-item>
            <el-form-item label="辅导员" prop="assistant_nos">
                <el-input
                    v-model="queryParams.assistant_nos"
                    placeholder="请输入辅导员姓名或编号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/stu/stuclasses/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/stu/stuclasses/get', '/stu/stuclasses/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/stu/stuclasses/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-refresh-left"
                    size="mini"
                    @click="handleSync"
                    v-hasPermi="['/stu/stuclasses/sync']"
                    >同步班级</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-upload"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['/stu/stuclasses/import']"
                    >导入</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/stu/stuclasses/export']"
                    >导出</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="query_qarams_item">
                <el-form-item label="专业" prop="major_no">
                    <major-select
                        v-model="queryParams.major_no"
                        placeholder="请选择专业"
                        :dept_no="queryParams.dept_no"
                        valueField="no"
                        size="small"
                    />
                </el-form-item>
                <el-form-item label="院系" prop="dept_no">
                    <dept-select
                        v-model="queryParams.dept_no"
                        placeholder="请选择院系"
                        valueField="no"
                        size="small"
                    />
                </el-form-item>
                <el-form-item label="辅导员" prop="assistant_nos">
                    <el-input
                        v-model="queryParams.assistant_nos"
                        placeholder="请输入辅导员姓名或编号"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
            </template>
            <template slot="toolbar_btn_left">
                <el-button
                  
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/stu/stuclasses/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/stu/stuclasses/get', '/stu/stuclasses/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/stu/stuclasses/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-refresh-left"
                    size="mini"
                    @click="handleSync"
                    v-hasPermi="['/stu/stuclasses/sync']"
                    >同步班级</el-button
                >
                <el-button
                    type="warning"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['/stu/stuclasses/import']"
                >
                    <i class="iconfont icon-daoru_o"></i>
                    导入
                </el-button>

                <el-button
                    class="btn-orange"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/stu/stuclasses/export']"
                >
                    <i class="iconfont icon-daochu_o"></i>
                    导出
                </el-button>
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="班级名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入班级名称"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="班级编号" prop="no">
                            <el-input
                                v-model="form.no"
                                placeholder="请输入班级编号"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="院系" prop="dept_no">
                            <dept-select
                                v-model="form.dept_no"
                                :defaultLabel="form.dept_name"
                                placeholder="请选择院系"
                                valueField="no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专业" prop="major_no">
                            <major-select
                                v-model="form.major_no"
                                :defaultLabel="form.major_name"
                                placeholder="请选择专业"
                                :dept_no="form.dept_no"
                                valueField="no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="年级" prop="grade">
                            <dict-select
                                v-model="form.grade"
                                placeholder="请选择年级"
                                type="stu_grade"
                            />
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="12">
					<el-form-item label="辅导员" prop="assistant_nos">
						<assistant-select
							v-model="form.assistant_nos"
							:defaultLabel="form.assistant_names"
							placeholder="请选择辅导员"
              @change="handleAssistantChange"
						/>
					</el-form-item> 
				</el-col>-->
                    <el-col :span="12">
                        <el-form-item label="备注信息" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入备注信息"
                                clearable
                                type="textarea"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="upload.title"
            :visible.sync="upload.open"
            width="400px"
            append-to-body
        >
            <el-upload
                ref="upload"
                :limit="1"
                accept=".xlsx"
                :headers="upload.headers"
                :action="upload.url + '?update=' + upload.update"
                :disabled="upload.isUploading"
                :on-progress="handleImportProgress"
                :on-success="handleImportSuccess"
                :on-error="handleImportError"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <el-checkbox
                        v-model="upload.update"
                    />是否更新已经存在的数据
                    <el-link
                        type="info"
                        style="font-size: 12px"
                        @click="handleImportTemplate"
                        >下载模板</el-link
                    >
                    <el-link
                        type="info"
                        style="font-size: 12px"
                        @click="handleImportReport"
                        >下载报告</el-link
                    >
                </div>
                <div class="el-upload__tip" style="color: red" slot="tip">
                    提示：仅允许导入“xlsx”格式文件！
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitImportForm"
                    >确 定</el-button
                >
                <el-button @click="upload.open = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Qs from "qs";
import { getAccessToken } from "@/utils/tokenTool";
import {
    listStuClasses,
    getStuClasses,
    addStuClasses,
    editStuClasses,
    delStuClasses,
    syncStuClasses,
} from "@/api/stu/stu_classes";
import MajorSelect from "../components/major_select/index.vue";
export default {
    name: "stu-stuclasses-list",
    components: {
        MajorSelect,
    },
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                {
                    field: "id",
                    title: "id",
                    minWidth: 120,
                    fixed: "left",
                    visible: false,
                },
                {
                    field: "name",
                    title: "班级名称",
                    minWidth: 120,
                    is_query: true,
                },
                {
                    field: "no",
                    title: "班级编号",
                    minWidth: 120,
                    is_query: true,
                },
                { field: "dept_name", title: "院系", minWidth: 120 },
                { field: "major_name", title: "专业", minWidth: 120 },
                {
                    field: "grade",
                    title: "年级",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_grade",
                },
                { field: "assistant_nos", title: "辅导员编号", minWidth: 120 },
                {
                    field: "assistant_names",
                    title: "辅导员姓名",
                    minWidth: 120,
                },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                { field: "remark", title: "备注信息", minWidth: 120 },
                // {
                //     field: "",
                //     title: "操作",
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
                no: undefined,
                dept_no: undefined,
                major_no: undefined,
                assistant_nos: undefined,
            },
            form: {},
            upload: {
                open: false,
                title: "",
                isUploading: false,
                update: false,
                headers: { Authorization: getAccessToken() },
                url: process.env.VUE_APP_BASE_API + "/stu/stuclasses/import",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "班级名称不能为空",
                        trigger: "blur",
                    },
                ],
                no: [
                    {
                        required: true,
                        message: "班级编号不能为空",
                        trigger: "blur",
                    },
                ],
                dept_no: [
                    {
                        required: true,
                        message: "院系不能为空",
                        trigger: "blur",
                    },
                ],
                major_no: [
                    {
                        required: true,
                        message: "专业不能为空",
                        trigger: "blur",
                    },
                ],
                grade: [
                    {
                        required: true,
                        message: "年级不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        // for (let i = 0; i < this.tableColumn.length; i++) {
        //     const col = this.tableColumn[i];
        //     if (col.dictType) {
        //         this.getDicts(col.dictType).then((response) => {
        //             if (response.code == 0) {
        //                 col.dictData = response.data;
        //                 this.dataList = [].concat(this.dataList);
        //             }
        //         });
        //     }
        // }
        this.getList();
    },
    methods: {
        tableChange(e) {
            console.log(e);
        },
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listStuClasses(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        dictFormat(e) {
            const col = this.tableColumn[e.columnIndex];
            if (col.dictData) {
                const values = e.cellValue ? e.cellValue.split(",") : [];
                const labels = [];
                for (let i = 0; i < values.length; i++) {
                    const v = values[i];
                    for (let j = 0; j < col.dictData.values.length; j++) {
                        const item = col.dictData.values[j];
                        if (v == item.key) {
                            labels.push(item.value);
                            break;
                        }
                    }
                }
                return labels.join(",");
            }
            return e.cellValue;
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.form = {
                name: undefined,
                no: undefined,
                dept_no: undefined,
                major_no: undefined,
                grade: undefined,
                assistant_nos: undefined,
                assistant_names: undefined,
                remark: undefined,
            };
            this.resetForm("form");
        },

        handleQuery(e) {
            if(e){  this.queryParams = this.deepCopy(e);}
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getStuClasses(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editStuClasses(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addStuClasses(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delStuClasses(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        handleAssistantChange(e1, e2) {
            this.form.assistant_names = e2
                ? e2.map((v) => v.name).join(",")
                : undefined;
        },

        handleSync() {
            this.$confirm("确定同步班级吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: "正在同步账号",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                syncStuClasses(this.queryParams)
                    .then((response) => {
                        loading.close();
                        if (response.code == 0) {
                            this.msgSuccess(
                                "成功同步" + response.data.count + "个班级"
                            );
                            setTimeout(() => {
                                this.getList();
                            }, 300);
                        }
                    })
                    .catch((err) => {
                        loading.close();
                    });
            });
        },

        handleImport() {
            this.upload.title = "数据导入";
            this.upload.update = false;
            this.upload.open = true;
        },

        handleImportProgress(event, file, fileList) {
            this.upload.isUploading = true;
            if (event.percent == 100) {
                this.upload.open = false;
                this.upload.loading = this.$loading({
                    lock: true,
                    text: "正在导入数据",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
        },

        handleImportSuccess(response, file, fileList) {
            console.log(response);
            if (this.upload.loading) {
                this.upload.loading.close();
            }
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            if (response.code == 0) {
                const msg =
                    "导入成功" +
                    response.data.success_count +
                    "条数据，失败" +
                    response.data.fail_count +
                    "条数据" +
                    (response.data.msg ? "，" + response.data.msg : "");
                //this.$alert(msg, "提示", { dangerouslyUseHTMLString: true });
                this.$confirm(msg, "提示", {
                    distinguishCancelAndClose: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "下载导入报告",
                })
                    .then(() => {})
                    .catch((action) => {
                        if (action === "cancel") {
                            // 下载导入报告
                            this.downloadbyurl("/stu/stuclasses/importReport");
                        }
                    });
            } else {
                this.$alert(response.msg, "提示", {
                    dangerouslyUseHTMLString: true,
                });
            }
            this.getList();
        },

        handleImportError(err, file, fileList) {
            if (this.upload.loading) {
                this.upload.loading.close();
            }
            this.upload.isUploading = false;
            this.$alert("导入失败", "提示", { dangerouslyUseHTMLString: true });
        },

        submitImportForm() {
            this.$refs.upload.submit();
        },

        handleImportTemplate() {
            this.downloadbyurl("/stu/stuclasses/importTemplate");
        },

        handleImportReport() {
            this.downloadbyurl("/stu/stuclasses/importReport");
        },

        handleExport() {
            this.$confirm("确定导出数据吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.downloadbyurl(
                    "/stu/stuclasses/export?" + Qs.stringify(this.queryParams)
                );
            });
        },
    },
};
</script>
