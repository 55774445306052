<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
        >
            <el-form-item label="会员ID" prop="user_id">
                <el-input
                    v-model="queryParams.user_id"
                    placeholder="请输入会员ID"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item> -->

            <!-- <el-form-item label="状态" prop="status">
         <el-select v-model="queryParams.status" placeholder="请选择状态" clearable size="small">
           <el-option label="待审核" value="0"/>
           <el-option  label="已通过" value="1"/>
           <el-option label="已拒绝" value="2"/>
         </el-select>
       </el-form-item>-->

            <!-- <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['data:api:access:add']"
                    >设置接口访问</el-button
                >
            </template> -->

            <!--自定义空数据模板-->
            <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
            :operate="false"
            :checkbox="false"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['data:api:access:add']"
                    >设置接口访问</el-button
                >
            </template>
          
        </jf-table>

        <!-- 添加或修改对话框 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item
                    label="会员"
                    prop="user_id"
                    :rules="[
                        {
                            required: true,
                            message: '会员不能为空',
                            trigger: 'blur',
                        },
                    ]"
                >
                    <user-select
                        size="small"
                        placeholder="请选择会员"
                        :limit="1"
                        :value="form.user_id"
                        :readonly="true"
                        @getValue="handleUserSelect"
                    ></user-select>
                </el-form-item>
                <el-form-item label="ApI接口" prop="app_id">
                    <el-checkbox-group v-model="form.app_id">
                        <el-checkbox
                            v-for="item in appList"
                            :label="item.id"
                            :key="item.id"
                            :checked="appOption[item.id] ? true : false"
                        >
                            {{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="IP列表" prop="ip">
                    <el-input
                        v-model="form.ip"
                        type="textarea"
                        placeholder="IP列表"
                        :autosize="{ minRows: 6, maxRows: 20 }"
                    />
                    <span style="color: #f00">*</span> 一行一个，不限制请留空
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { listAccess, setAccess, getAccess } from "@/api/data/api/access";
import { listApp } from "@/api/data/api/app";
import UserSelect from "@/components/UserSelect";
export default {
    name: "data-api-access-list",
    data() {
        return {
            name: "access", //请勿改动
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },

            // 列
            tableColumn: [
                /*{type: 'checkbox', width: 60, fixed: "left"},*/
                { field: "id", title: "ID", minWidth: 80, fixed: "left" },
                { field: "user_id", title: "会员ID", minWidth: 80,is_query:true },
                { field: "api_id", title: "ApiId", minWidth: 80 },
                { field: "name", title: "名称", minWidth: 80,is_query:true },
                { field: "ip", title: "IP", minWidth: 80 },
                { field: "create_time", title: "创建时间", minWidth: 80,form_type:3 },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 表格数据
            dataList: [],
            appList: [],
            appOption: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                user_id: undefined,
                name: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {},
        };
    },
    created() {
        this.getList();
    },
    components: {
        UserSelect,
    },
    methods: {
        tableChange(e){},
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handleSizeChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        handleUserSelect(value) {
            this.form.user_id = value;
            if (!value) {
                this.appList = [];
                this.appOption = [];
                this.form.ip = "";
                return;
            }
            this.$nextTick(() => {
                listApp({
                    user_id: value,
                }).then((response) => {
                    this.appList = response.data.list;
                    for (let i in response.data.accessList) {
                        let e = response.data.accessList[i];
                        this.appOption[e.app_id] = e;
                        this.form.ip += e.ip + "\n";
                    }
                });
            });
        },
        /** 查询列表 */
        getList() {
            this.loading = true;
            listAccess(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.apiList = response.data.apiList;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                user_id: undefined,
                app_id: [],
                ip: "",
            };
            this.appList = [];
            this.appOption = [];
            this.form.ip = "";
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "设置接口访问";
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    setAccess(this.form).then((response) => {
                        if (response.code === 0) {
                            this.msgSuccess("设置成功");
                            this.open = false;
                            setTimeout(() => {
                                this.getList();
                            }, 300);
                        }
                    });
                }
            });
        },
    }, //methods结束
};
</script>
