<!-- 生成时间:2023-01-12 13:43:41 -->

<template>
  <div class="app-container">
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="目标文件夹" prop="type">
          <treeselect v-model="form.bucket_id" :options="imenuOptions" :normalizer="normalizer" placeholder="请选择目标文件夹" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;margin-top: 20px;">
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </div>

  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import {
  listPath, delPath
} from '@/api/dss/path'
import { editOss } from '@/api/dss/oss'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  name: "ossEdit",

  data() {
    return {
      imenuOptions:[],
      // 字典
      pathList:[],
      // 表单参数
      form: {
        useed: 1,
      },
    };
  },

  props: {
    ids: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getList();
    this.getTreeselect();
  },
  // 在子组件中watch是用来监听props的监听器,只需要给要监听的props属性在watch声明同名的方法,即可监听对应data属性的变化.
  watch: {
    id(){
      getOss
    }
  },


  methods: {
    /** 查询部门下拉树结构 */
    getTreeselect() {
      listPath({
        pageNum: 1,
            pageSize: 10}).then(response => {
        this.imenuOptions = [];
        const data = { id: 0, name: '顶级节点（如选此项，则新增bucket）', children: [] };
        data.children = this.handleTree(response.data.list, "id", "pid");
        this.imenuOptions.push(data);

        this.pathList = this.handleTree(response.data.list, "id", "pid");
      });
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },


    /** 查询列表 */

    // $comment字典翻译

    // 取消按钮
    cancel() {
      this.open = false;
      this.advqueryopen = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        app_id: undefined,
        storage: undefined,
        name: undefined,
        useed: 1,

      };
      this.resetForm("form");
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {

          if (this.form.id != null) {
            editOss(this.form).then(response => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                setTimeout(() => { this.getList(); }, 300)
              }
            });
          }

        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids.join(',');

      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delPath(ids);
      }).then(() => {
        setTimeout(() => { this.getList(); }, 300)
        this.msgSuccess("删除成功");
      }).catch(function () { });
    },
  } //methods结束
};
</script>
