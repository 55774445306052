<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
            <!--<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
            <!-- <el-form-item
                label="学院"
                prop="dept_id"
                v-hasPermi="['/dept/list']"
            >
                <dept-select
                    v-model="queryParams.dept_id"
                    placeholder="请选择学院"
                    size="small"
                />
            </el-form-item>
            <el-form-item label="日期" prop="dateRange">
                <el-date-picker
                    v-model="queryParams.dateRange"
                    size="small"
                    style="width: 240px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="活动名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入活动名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/xssq/xssqhdtb/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/xssq/xssqhdtb/get', '/xssq/xssqhdtb/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/xssq/xssqhdtb/del']"
                    >删除</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="handleView(row)"
                    >查看
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="query_qarams_item">
                <el-form-item
                label="学院"
                prop="dept_id"
                v-hasPermi="['/dept/list']"
            >
                <dept-select
                    v-model="queryParams.dept_id"
                    placeholder="请选择学院"
                    size="small"
                />
            </el-form-item>
            </template>
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/xssq/xssqhdtb/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/xssq/xssqhdtb/get', '/xssq/xssqhdtb/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/xssq/xssqhdtb/del']"
                    >删除</el-button
                >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="handleView(row)"
                    >查看
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="960px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <!--<el-col :span="12">
					<el-form-item label="学院" prop="dept_id">
						<dept-select
							v-model="form.dept_id"
							:defaultLabel="form.dept_name"
							placeholder="请选择学院"
						/>
					</el-form-item> 
				</el-col>-->
                    <el-col :span="12">
                        <el-form-item label="日期" prop="date">
                            <datetime-select
                                v-model="form.date"
                                type="date"
                                placeholder="请输入日期"
                                controls-position="right"
                            />
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="12">
					<el-form-item label="开始时间" prop="begin_time">
						<el-input-number
							v-model="form.begin_time"
							placeholder="请输入开始时间"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="结束时间" prop="end_time">
						<el-input-number
							v-model="form.end_time"
							placeholder="请输入结束时间"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>-->
                    <el-col :span="12">
                        <el-form-item label="时间" prop="dateRange">
                            <el-time-picker
                                is-range
                                arrow-control
                                v-model="form.dateRange"
                                :default-value="new Date(2022, 5, 12, 8, 0)"
                                format="HH:mm"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="选择时间范围"
                            >
                            </el-time-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地点" prop="place">
                            <el-input
                                v-model="form.place"
                                placeholder="请输入地点"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="活动名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入活动名称"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="活动内容" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入活动内容"
                                clearable
                                type="textarea"
                                rows="3"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="参加人员" prop="attendance">
                            <el-input
                                v-model="form.attendance"
                                placeholder="请输入参加人员"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="级别" prop="level">
                            <dict-select
                                v-model="form.level"
                                placeholder="请选择级别"
                                type="xssq_level"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="负责单位（部门）" prop="unit">
                            <el-input
                                v-model="form.unit"
                                placeholder="请输入负责单位（部门）"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="活动照片" prop="photos">
                            <image-upload
                                v-model="form.photos"
                                tip="只能上传jpg/png文件，且不超过10M"
                                :readonly="readonly"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button v-if="!readonly" type="primary" @click="submitForm"
                    >确 定</el-button
                >
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listXssqHdtb,
    getXssqHdtb,
    addXssqHdtb,
    editXssqHdtb,
    delXssqHdtb,
} from "@/api/xssq/xssq_hdtb";
import moment from "moment";
export default {
    name: "xssq-xssqhdtb-list",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                //{ field: "id", title: "id", minWidth: 120, fixed: "left" },
                { field: "dept_name", title: "学院", minWidth: 120 },
                {
                    field: "date",
                    title: "日期",
                    minWidth: 120,
                    formatter: ["formatDate", "yyyy-MM-dd"],
                    is_query: true,
                    form_type: 3,
                },
                //{ field: "begin_time", title: "开始时间", minWidth: 120 },
                //{ field: "end_time", title: "结束时间", minWidth: 120 },
                {
                    field: "datetime_range",
                    title: "时间",
                    minWidth: 120,
                    formatter: this.formatDatetimeRang,
                },
                { field: "place", title: "地点", minWidth: 120 },
                {
                    field: "name",
                    title: "活动名称",
                    minWidth: 120,
                    is_query: true,
                },
                { field: "remark", title: "活动内容", minWidth: 120 },
                { field: "attendance", title: "参加人员", minWidth: 120 },
                {
                    field: "level",
                    title: "级别",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "xssq_level",
                },
                { field: "unit", title: "负责单位（部门）", minWidth: 120 },
                //{ field: "photos", title: "活动照片", minWidth: 120 },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                // 	field: "",
                // 	title: "操作",
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: { default: "defaultopr" },
                // },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            readonly: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                dept_id: undefined,
                date: undefined,
                dateRange: undefined,
                name: undefined,
            },
            form: {},
            rules: {
                dept_id: [
                    {
                        required: true,
                        message: "学院不能为空",
                        trigger: "blur",
                    },
                ],
                date: [
                    {
                        required: true,
                        message: "日期不能为空",
                        trigger: "blur",
                    },
                ],
                //begin_time: [{ required: true, message: "开始时间不能为空", trigger: "blur" }],
                //end_time: [{ required: true, message: "结束时间不能为空", trigger: "blur" }],
                dateRange: [
                    {
                        required: true,
                        message: "时间不能为空",
                        trigger: "blur",
                    },
                ],
                place: [
                    {
                        required: true,
                        message: "地点不能为空",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "活动名称不能为空",
                        trigger: "blur",
                    },
                ],
                remark: [
                    {
                        required: true,
                        message: "活动内容不能为空",
                        trigger: "blur",
                    },
                ],
                attendance: [
                    {
                        required: true,
                        message: "参加人员不能为空",
                        trigger: "blur",
                    },
                ],
                level: [
                    {
                        required: true,
                        message: "级别不能为空",
                        trigger: "blur",
                    },
                ],
                unit: [
                    {
                        required: true,
                        message: "负责单位（部门）不能为空",
                        trigger: "blur",
                    },
                ],
                photos: [
                    {
                        required: true,
                        message: "活动照片不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        // for (let i = 0; i < this.tableColumn.length; i++) {
        //     const col = this.tableColumn[i];
        //     if (col.dictType) {
        //         this.getDicts(col.dictType).then((response) => {
        //             if (response.code == 0) {
        //                 col.dictData = response.data;
        //                 this.dataList = [].concat(this.dataList);
        //             }
        //         });
        //     }
        // }
        this.getList();
    },
    methods: {
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            const queryParams = {
                ...this.queryParams,
            };

            if (
                this.queryParams.dateRange &&
                this.queryParams.dateRange.length >= 2
            ) {
                queryParams.begin_time =
                    moment(
                        this.queryParams.dateRange[0],
                        "YYYY-MM-DD"
                    ).valueOf() / 1000;
                queryParams.end_time =
                    moment(
                        this.queryParams.dateRange[1],
                        "YYYY-MM-DD"
                    ).valueOf() /
                        1000 +
                    3600 * 24;
            }

            listXssqHdtb(queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        dictFormat(e) {
            const col = this.tableColumn[e.columnIndex];
            if (col.dictData) {
                const values = e.cellValue ? e.cellValue.split(",") : [];
                const labels = [];
                for (let i = 0; i < values.length; i++) {
                    const v = values[i];
                    for (let j = 0; j < col.dictData.values.length; j++) {
                        const item = col.dictData.values[j];
                        if (v == item.key) {
                            labels.push(item.value);
                            break;
                        }
                    }
                }
                return labels.join(",");
            }
            return e.cellValue;
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        formatDatetimeRang(e) {
            if (e.row.begin_time && e.row.end_time) {
                return (
                    moment(e.row.begin_time * 1000).format("HH:mm") +
                    "-" +
                    moment(e.row.end_time * 1000).format("HH:mm")
                );
            }
            return "";
        },

        reset() {
            this.form = {
                dept_id: 0,
                date: undefined,
                begin_time: undefined,
                end_time: undefined,
                place: undefined,
                name: undefined,
                remark: undefined,
                attendance: undefined,
                level: undefined,
                unit: undefined,
                photos: undefined,
                dateRange: undefined,
            };
            this.resetForm("form");
        },

        handleQuery(e) {
            if (e && Object.keys(e).length > 0) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.readonly = false;
            this.title = "添加";
        },

        handleView(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getXssqHdtb(id).then((response) => {
                this.form = response.data;
                if (this.form.begin_time && this.form.end_time) {
                    //this.form.dateRange = [new Date(this.form.begin_time * 1000), new Date(this.form.end_time * 1000)]
                    this.$set(this.form, "dateRange", [
                        new Date(this.form.begin_time * 1000),
                        new Date(this.form.end_time * 1000),
                    ]);
                }
                this.open = true;
                this.readonly = true;
                this.title = "查看";
            });
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getXssqHdtb(id).then((response) => {
                this.form = response.data;
                if (this.form.begin_time && this.form.end_time) {
                    //this.form.dateRange = [new Date(this.form.begin_time * 1000), new Date(this.form.end_time * 1000)]
                    this.$set(this.form, "dateRange", [
                        new Date(this.form.begin_time * 1000),
                        new Date(this.form.end_time * 1000),
                    ]);
                }
                this.open = true;
                this.readonly = false;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (
                        this.form.dateRange &&
                        this.form.dateRange.length == 2
                    ) {
                        this.form.begin_time = parseInt(
                            this.form.dateRange[0].getTime() / 1000
                        );
                        this.form.end_time = parseInt(
                            this.form.dateRange[1].getTime() / 1000
                        );
                    }
                    if (this.form.id != null) {
                        editXssqHdtb(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addXssqHdtb(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm("确定删除吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delXssqHdtb(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
    },
};
</script>
