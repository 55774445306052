<template>
  <div class="app-container">    
    <el-row>
      <span >
        <p>默认的资源空间 | </p>
      </span>
    </el-row>

    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="spaceList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"     
          @click="handleAdd"
        >创建资源空间</el-button>
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
        >修改
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>      
      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="资源空间名称" prop="space_name" :required=true>
          <el-input v-model="form.space_name" placeholder="资源空间名称" />
        </el-form-item>       
      </el-form>
      <el-card v-if="this.form.Id != undefined" style="position: relative;"> 
        <table cellspacing="0" style="width: 100%;">            
          <el-button style="width: 100%;margin:5px" @click="linkProduct(form.Id)" >                 
            <div style="display:inline-block;float:left">产品数</div>
            <div style="display:inline-block;float:right;font-weight:bolder;">{{ productCount }}</div>
          </el-button>       
          <el-button style="width: 100%;margin:5px"  @click="linkRuleLinkage(form.Id)" >                 
            <div style="display:inline-block;float:left">联动规则数</div>
            <div style="display:inline-block;float:right;font-weight:bolder;">{{ ruleLinkageCount }}</div>
          </el-button>    
          <el-button style="width: 100%;margin:5px"  @click="linkDevice(form.Id)" >
            <div style="display:inline-block;float:left">设备数</div>
            <div style="display:inline-block;float:right;font-weight:bolder;">{{ deviceCount }}</div>
          </el-button>     
        </table>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listIotSpace, getIotSpace, addIotSpace, updateIotSpace, delIotSpace } from "@/api/iot/space";
import { listIotCase, getIotCase, addIotCase, updateIotCase, delIotCase } from "@/api/iot/case";
export default {
  name: "IotSpace",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'Id', title: '编号', width: 100, fixed: "left"},
        {field: 'space_name', title: '空间名称' },
        {field: 'is_default', title: '默认',formatter:this.DefaultFormat },
        {field: 'app_id', title: 'APPID' },
        {field: 'create_time', title: '创建时间',formatter:this.CreateFormat },        
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 分类表格数据
      spaceList: [],
      //productList:[],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        case_id: undefined
      },
      casequeryParams: {
        pageNum: 1,
        pageSize: 10,
        node_id: undefined
      }, 
      productCount:0,
      ruleLinkageCount:0,
      deviceCount:0,
      // 表单参数
      form: {},      
      // 表单校验
      rules: {
        space_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getCaseList();   
  },
  methods: {
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.Id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },
    getCaseList(){
      this.loading = true;
      listIotCase(this.casequeryParams).then(response =>{
          for (var index=0;index<response.data.list.length;index++){
            if (response.data.list[index].is_choose >= 1){
              this.queryParams.case_id=response.data.list[index].Id;
              
            }              
          }   
          this.getList(); 
        })
    },    
    //获取空间列表
    getList() {      
      listIotSpace(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;
        this.spaceList = response.data.list;
        this.total = response.data.total;
        this.statusOptions = response.data.statusOptions.values        
        this.loading = false;
      }).catch(function (error){
        console.log(error);
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        Id: undefined,
        space_name: undefined,        
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      if(this.queryParams.case_id == undefined){
        this.msgError("请选择一个当前使用实例！");
      }else{
        this.reset();
        this.open = true;
        this.title = "创建逻辑隔离的资源空间";
      }      
    },
    DefaultFormat({row}){
      var labname="";
      if (row.is_default == 0){
        labname="否"
      }else{
        labname="是"
      }      
      return labname;
    }, 
    CreateFormat({row}){
      return this.timestampToTime(row.create_time);
    },
    linkProduct(id){      
      this.$router.push({path:'/iot/product', query:{space_id : id}});
    },
    linkRuleLinkage(id){
      this.$router.push({path:'/iot/rule', query:{space_id : id}});
    },
    linkDevice(id){
      this.$router.push({path:'/iot/device/list', query:{space_id : id}});
    },   
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.Id || this.ids
      getIotSpace(id).then(response => {       
        this.form = response.data        
        this.open = true;
        this.title = "修改资源空间";
        this.productCount=response.data.product_count;
        this.ruleLinkageCount=response.data.ruleLinkage_count;
        this.deviceCount=response.data.device_count;
      });
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.Id != undefined) {
            updateIotSpace(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            this.form.case_id=this.queryParams.case_id;
            addIotSpace(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除协议编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotSpace(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
  }
};
</script>
