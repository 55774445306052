import request from '@/utils/request'

// 查询会员列表
export function listUser(userpool, query) {
  return request({
    url: '/ucenter/' + userpool + '/list',
    method: 'get',
    params: query
  })
}

// 查询会员详细
export function getUser(userpool, id) {
  return request({
    url: '/ucenter/' + userpool + '/get?id=' + id,
    method: 'get'
  })
}

// 新增会员
export function addUser(userpool, data) {
  return request({
    url: '/ucenter/' + userpool + '/add',
    method: 'post',
    data: data
  })
}

// 修改会员
export function updateUser(userpool, data) {
  return request({
    url: '/ucenter/' + userpool + '/edit',
    method: 'post',
    data: data
  })
}
// 充值会员
export function rechargeUser(userpool, data) {
  return request({
    url: '/ucenter/' + userpool + '/recharge',
    method: 'post',
    data: data
  })
}

// 删除会员
export function delUser(userpool, ids) {
  return request({
    url: '/ucenter/' + userpool + '/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}
