<template>
    <div class="container">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-card shadow="hover" class="card-1">
                    <div slot="header">
                        <span>本月人次</span>
                    </div>
                    <vue-echarts :loading="loading" autoresize :option="byrc" />
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-card shadow="hover" class="card-1">
                    <div slot="header">
                        <span>咨询师</span>
                    </div>
                    <vue-echarts :loading="loading" autoresize :option="zxs" />
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="14" :lg="16" :xl="16">
                <el-card shadow="hover" class="card-2">
                    <div slot="header">
                        <span>学生预约</span>
                    </div>
                    <div class="card-content-2">
                        <el-table
                        :data="tableData"
                        :border="true"
                        style="width: 100%"
                        height="320"
                        :key="timeStamp"
                        :header-cell-style="{'background': '#EBEEF5', 'border-right': '1px solid #dddddd'}"
                        >
                        <!-- <el-table-column
                            fixed
                            prop="no"
                            label="序号"
                            width="50"
                            align="center" show-overflow-tooltip>
                        </el-table-column> -->
                        <el-table-column label="序号" type="index" width="50" align="center" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            prop="number"
                            label="学号"
                            width="110"
                            align="center" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="姓名"
                            align="center"
                            width="90" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            prop="gender"
                            label="性别"
                            width="50"
                            align="center" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            prop="content"
                            label="预约内容"
                            align="center"
                            min-width="200" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            label="预约状态"
                            align="center"
                            width="80" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-link :underline="false" :class="scope.row.status">{{ scope.row.statusStr }}</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="time"
                            label="时间"
                            align="center"
                            min-width="100" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="pageSize"
                        @current-change="currentChange">
                    </el-pagination>
                    </div>

                </el-card>
            </el-col>
            <el-col :xs="24" :sm="10" :lg="8" :xl="8">
                <el-card shadow="hover" class="card-2">
                    <div slot="header">
                        <span>学院汇总</span>
                    </div>
                    <div class="card-content-2">
                        <el-table
                            :data="tableData2"
                            style="width: 100%"
                            height="320"
                            :show-header="false"
                            :key="timeStamp">
                            <el-table-column
                                align="left"
                                prop="name"
                                label="学院名"
                                >
                            </el-table-column>
                            <el-table-column label="人数" align="right">
                                <template slot-scope="scope">
                                    <span style="margin-right: 10px;" :style="{'color': scope.row.color}">{{ scope.row.value}}</span>
                                    <span :style="{'color': scope.row.color}">人</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
          </el-col>
          <el-col :xs="24" :sm="14" :lg="16" :xl="16">
                <el-card shadow="hover" class="card-2">
                    <div slot="header">
                        <span>重点关注</span>
                    </div>
                    <vue-echarts autoresize :option="zdgz" />
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="10" :lg="8" :xl="8">
                <el-card shadow="hover" class="card-2">
                    <div slot="header">
                        <span style="opacity: 0;">饼状图</span>
                    </div>
                    <vue-echarts autoresize :option="pieOption" />
                </el-card>
          </el-col>
        </el-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import VueEcharts from '../../common/echarts';
    import { studentAppointmentList, collegesList } from '@/api/index'
    export default {
        name: 'index',
        components: {
            VueEcharts
        },
        data() {
            return {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                byrc: {
                    tooltip: {
                        trigger: 'axis'
                    },
                    color: ['#5087EC', '#68BBC4'],
                    grid: {
                        top: '16%',
                        left: '3%',
                        right: '3%',
                        bottom: '10%',
                        containLabel: true,
                    },
                    legend: {
                        data: ['健康', '严重']
                    },
                    xAxis: {
                        type: 'category',
                        data: ['0.2', '0.4', '0.6', '0.8'],
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [100, 150, 230, 100],
                            type: 'line',
                            smooth: true,
                            name: '健康'
                        },
                        {
                            data: [250, 240, 440, 250],
                            type: 'line',
                            smooth: true,
                            name: '严重'
                        }
                    ]
                },
                zxs: {
                    tooltip: {
                        trigger: 'axis'
                    },
                    color: ['#5087EC', '#68BBC4'],
                    grid: {
                        top: '8%',
                        left: '3%',
                        right: '3%',
                        bottom: '10%',
                        containLabel: true,
                    },
                    dataset: {
                        source: [
                            ['product', '一级', '二级'],
                            ['08-01', 100, 150],
                            ['08-02', 140, 100],
                            ['08-03', 230, 200],
                            ['08-04', 100, 140]
                        ]
                    },
                    xAxis: {
                        type: 'category',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            type: 'bar'
                        },
                        {
                            type: 'bar',
                            barGap: '0%'
                        }
                    ]
                },
                tableData: [],
                tableData2: [],
                zdgz: {
                    tooltip: {
                        trigger: 'axis'
                    },
                    color: ['#f39423','#f9c78b' ],
                    grid: {
                        top: '16%',
                        left: '3%',
                        right: '3%',
                        bottom: '10%',
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: ['一月', '二月', '三月', '四月'],
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [100, 150, 230, 100],
                            type: 'line',
                            smooth: true,
                            name: '数据',
                            areaStyle: {
                                opacity: 0.8,
                                color: '#f39423'
                            }
                        },
                        {
                            data: [250, 240, 440, 250],
                            type: 'line',
                            smooth: true,
                            name: '数据2',
                            areaStyle: {
                                opacity: 0.5,
                                color: '#f9c78b'
                            }
                        }
                    ]
                },
                pieOption: {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: 'top',
                        left: 'left',
                        orient: 'vertical'
                    },
                    series: [
                        {
                        
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                            show: false,
                            fontSize: '40',
                            fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 1048, name: '一般' },
                            { value: 735, name: '特别严重' },
                            { value: 580, name: '健康' },
                            { value: 484, name: '严重' }
                        ]
                        }
                    ]
                },
                loading: false,
                timeStamp: 0
            }
        },
        computed: {
            ...mapGetters({
                avatar: 'user/avatar',
                username: 'user/username',
                themeColor: 'settings/themeColor'
            }),
        },
        watch: {
            themeColor: {
                handler(newVal) {
                    this.afterThemeColorChange(newVal)
                },
                deep: true
            },
        },
        mounted() {
            console.log(this.avatar, this.username)
            this.afterThemeColorChange()
            this.fetchData()
        },
        methods: {
            afterThemeColorChange() {
                let { themeColor } = this
                let themeColorV = themeColor
                const customColor = localStorage.getItem('customColor')
                customColor && customColor.toUpperCase() != '#FFFFFF' ? themeColorV = customColor : null
                
                this.loading = true
                let color = ['#5087EC', '#68BBC4']
                color[0] = themeColorV
                if(themeColorV.toUpperCase() == '#41B584') {
                    color[0] = '#5087EC'
                    color[1] = '#41B584'
                }
                let newColor = color
                this.$set(this.zxs, 'color', newColor)
                this.$set(this.byrc, 'color', newColor)
                setTimeout(()=>{
                    this.loading = false
                }, 50)
            },
            fetchData() {
                this.getStudentAppointmentList()
                this.getCollegesList()
            },
            async getStudentAppointmentList() {
                const s = {
                    '已通过': 'primary',
                    '待审批': 'warning',
                    '待咨询': 'success',
                    '已取消': 'danger'
                }
                let { currentPage, pageSize } = this
                let res = await studentAppointmentList({ pageNum: parseInt(currentPage), pageSize })
                
                if(res && res.code == 200) {
                    let { data } = res
                    data = data.map(item => {
                        return {
                            ...item,
                            status: s[item.statusStr]
                        }
                    })
                    this.tableData = data
                    this.total = res.total
                }
                this.timeStamp++
            },
            getCollegesList() {
                collegesList().then(res => {
                    if(res && res.code == 200) {
                        let data = res.data
                        data = data.map((item, index) => {
                            let colors = ['rgb(80,135,236)', 'rgb(104,187,196)', 'rgb(251,205,76)', 'rgb(147,147,136)'] 
                            let color = index <= 2 ? colors[index] : colors[colors.length-1]
                            return {
                                ...item,
                                color
                            }
                        })
                        this.tableData2 = data
                    }
                })
            },
            currentChange(e) {
                this.currentPage = e
                this.getStudentAppointmentList()
            },
        }
    }
</script>

<style lang="scss" scoped>
.container{
    background-color: $base-container-color;
    ::v-deep {
        .el-card__header {
            border-bottom: unset;
        }
        .card-1 {
            .el-card__body {
                padding: 0;
                .echarts {
                    width: 100%;
                    height: 260px;
                }
            }
        }
        .card-2 {
            .el-card__body {
                padding: 0;
                .echarts {
                    width: 100%;
                    height: 380px;
                }
            }
        }

        .card-content-2{
            height: 380px;
            padding: 0 20px;
            box-sizing: border-box;
            
        }

        .el-link{
            &.primary{
                color: $--color-primary;
            }
            &.warning{
                color: $--color-warning;
            }
            &.danger{
                color: $--color-danger;
            }
            &.success{
                color: $--color-success;
            }
        }
    }
    
}
</style>