import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2023-11-17 16:36:04
class Bill {
    // 查询bill列表
    listBill(query) {
        var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

        return request({
            url: '/ai/bill/list',
            method: 'post',
            data: data
        })
    }

    // 查看bill
    getBill(id) {
        return request({
            url: '/ai/bill/get?id=' + id,
            method: 'get'
        })
    }


    // 新增bill
    addBill(mid, data) {
        var postdata = {
            formdata: JSON.stringify(data),
            mid: mid
        }
        return request({
            url: '/ai/bill/add',
            method: 'post',
            data: postdata
        })
    }

    // 修改bill
    editBill(mid, data) {
        var postdata = {
            formdata: JSON.stringify(data),
            mid: mid,
            dataid: data.id
        }

        return request({
            url: '/ai/bill/edit',
            method: 'post',
            data: postdata
        })
    }

    // 删除bill
    delBill(ids) {
        return request({
            url: '/ai/bill/del',
            method: 'post',
            data: { ids: ids }
        })
    }



    //导入数据
    billImport(mid, rows, opts) {
        var data = {
            mid,
            options: JSON.stringify(opts),
            rows: JSON.stringify(rows)
        }

        return request({
            url: "/ai/bill/import/" + mid,
            method: 'post',
            data: data
        })

    }


    //获取导出下载连接
    billExportUrl(mid, query, fieldlist) {
        var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {},
            fieldlist: Array.isArray(fieldlist) ? fieldlist.join("|") : fieldlist
        }

        var tjson = ReFormatQueryStr(query)
        data.qstr = JSON.stringify(tjson)

        var parata = []
        for (let i in data) {
            parata.push(i + "=" + data[i])
        }


        return `/ai/bill/export?` + parata.join("&");


    }

    //下导入模板
    billimportTemplate() {

        return `/ai/bill/importTemplate`;

    }

}

const instance = new Bill();
export default instance;