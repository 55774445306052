<template>
    <el-form-item
        :label="i18nt('designer.setting.scoringMethodName')"
        v-if="designer.isTopic"
    >
        <el-select
            v-model="optionModel.scoringMethod"
            placeholder="请选择"
            @change="scoringMethodChange"
            :style="{ width: '100%' }"
        >
            <el-option
                v-for="item in scoringMethodLsit"
                :key="item.label"
                :label="item.label"
                :value="item.value"
            >
                <span> {{ item.label }}</span>
            </el-option>
        </el-select>
    </el-form-item>
</template>
<script>
import i18n from "@/vform/utils/i18n";
export default {
    name: "scoring-method-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            lsit: [
                {
                    value: "1",
                    label: "此题有唯一答案和分值",
                    type: 1,
                },
                {
                    value: "2",
                    label: "全部答对才得分",
                    type: 2,
                },
                {
                    value: "3",
                    label: "答对几项得几分，答错不得分",
                    type: 2,
                },
                {
                    value: "4",
                    label: "每个选项都有对应的分值",
                },

                {
                    value: "5",
                    label: "不计分",
                },
                {
                    value: "6",
                    label: "人工评分",
                },
            ],
        };
    },
    computed: {
        scoringMethodLsit: function () {
            if (this.selectedWidget.type === "radio"||this.selectedWidget.type === "select") {
                return this.lsit.filter((o) => o.type * 1 !== 2 &&o.value !=6);
            } else if (
                this.selectedWidget.type === "input" ||
                this.selectedWidget.type === "textarea"
            ) {
              
                return this.lsit.filter((o) => o.value * 1 == 2||o.value * 1 == 6);
            } else {
                return this.lsit.filter((o) => o.type * 1 !== 1 &&o.value !=6);
            }
        },
        // if()
    },
    created() {    
        // console.log(this.selectedWidget);
    },
    methods: {
        scoringMethodChange(e) {
            this.optionModel.answer = null;
            this.optionModel.scoring = {};
            console.log(this.optionModel,'this.optionModel')
        },
    },
};
</script>