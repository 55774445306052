<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        >
            <el-form-item label="id" prop="id">
                <el-input
                    v-model="queryParams.id"
                    placeholder="请输入id"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="课程" prop="course_id">
                <grid-select
                    v-model="queryParams.course_id"
                    :multiple="false"
                    placeholder="请选择课程"
                    width="960px"
                    :searchItems="[
                        { field: 'name', title: '名称' },
                        {
                            field: 'category_id',
                            title: '分类',
                            ctype: 'gbpx-course-category',
                        },
                        {
                            field: 'teacher_id',
                            title: '教师',
                            ctype: 'gbpx-teacher',
                        },
                    ]"
                    :gridItems="[
                        { field: 'name', title: '名称' },
                        { field: 'category_name', title: '分类' },
                        { field: 'teacher_name', title: '教师' },
                        { field: 'remark', title: '简介' },
                    ]"
                    :getDataList="listGbpxCourse"
                    size="small"
                >
                </grid-select>
            </el-form-item>
            <el-form-item label="问卷" prop="questionnaire_id">
                <grid-select
                    v-model="queryParams.questionnaire_id"
                    :multiple="false"
                    placeholder="请选择问卷"
                    width="960px"
                    :searchItems="[{ field: 'name', title: '名称' }]"
                    :gridItems="[
                        { field: 'name', title: '名称' },
                        { field: 'remark', title: '简介' },
                    ]"
                    :getDataList="listGbpxQuestionnaire"
                    size="small"
                >
                </grid-select>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxteachingevaluation/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxteachingevaluation/get',
                        '/gbpx/gbpxteachingevaluation/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxteachingevaluation/del']"
                    >删除</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="query_qarams_item">
                <el-form-item label="课程" prop="course_id">
                <grid-select
                    v-model="queryParams.course_id"
                    :multiple="false"
                    placeholder="请选择课程"
                    width="960px"
                    :searchItems="[
                        { field: 'name', title: '名称' },
                        {
                            field: 'category_id',
                            title: '分类',
                            ctype: 'gbpx-course-category',
                        },
                        {
                            field: 'teacher_id',
                            title: '教师',
                            ctype: 'gbpx-teacher',
                        },
                    ]"
                    :gridItems="[
                        { field: 'name', title: '名称' },
                        { field: 'category_name', title: '分类' },
                        { field: 'teacher_name', title: '教师' },
                        { field: 'remark', title: '简介' },
                    ]"
                    :getDataList="listGbpxCourse"
                    size="small"
                >
                </grid-select>
            </el-form-item>
            <el-form-item label="问卷" prop="questionnaire_id">
                <grid-select
                    v-model="queryParams.questionnaire_id"
                    :multiple="false"
                    placeholder="请选择问卷"
                    width="960px"
                    :searchItems="[{ field: 'name', title: '名称' }]"
                    :gridItems="[
                        { field: 'name', title: '名称' },
                        { field: 'remark', title: '简介' },
                    ]"
                    :getDataList="listGbpxQuestionnaire"
                    size="small"
                >
                </grid-select>
            </el-form-item>
            </template>
            <template slot="toolbar_btn_left">
                <el-button
                 
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxteachingevaluation/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxteachingevaluation/get',
                        '/gbpx/gbpxteachingevaluation/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxteachingevaluation/del']"
                    >删除</el-button
                >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="960px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入名称"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="课程" prop="course_id">
                            <grid-select
                                v-model="form.course_id"
                                :multiple="false"
                                placeholder="请选择课程"
                                width="960px"
                                :defaultLabel="form.course_name"
                                :searchItems="[
                                    { field: 'name', title: '名称' },
                                    {
                                        field: 'category_id',
                                        title: '分类',
                                        ctype: 'gbpx-course-category',
                                    },
                                    {
                                        field: 'teacher_id',
                                        title: '教师',
                                        ctype: 'gbpx-teacher',
                                    },
                                ]"
                                :gridItems="[
                                    { field: 'name', title: '名称' },
                                    { field: 'category_name', title: '分类' },
                                    { field: 'teacher_name', title: '教师' },
                                    { field: 'remark', title: '简介' },
                                ]"
                                :getDataList="listGbpxCourse"
                            >
                            </grid-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="问卷" prop="questionnaire_id">
                            <grid-select
                                v-model="form.questionnaire_id"
                                :multiple="false"
                                placeholder="请选择问卷"
                                width="960px"
                                :defaultLabel="form.questionnaire_name"
                                :searchItems="[
                                    { field: 'name', title: '名称' },
                                ]"
                                :gridItems="[
                                    { field: 'name', title: '名称' },
                                    { field: 'remark', title: '简介' },
                                ]"
                                :getDataList="listGbpxQuestionnaire"
                            >
                            </grid-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="简介" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入简介"
                                clearable
                                type="textarea"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="评教时间" prop="dateRange">
                            <el-date-picker
                                v-model="form.dateRange"
                                type="datetimerange"
                                format="yyyy-MM-dd HH:mm"
                                :default-time="['08:00:00', '08:00:00']"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listGbpxTeachingEvaluation,
    getGbpxTeachingEvaluation,
    addGbpxTeachingEvaluation,
    editGbpxTeachingEvaluation,
    delGbpxTeachingEvaluation,
} from "@/api/gbpx/gbpx_teaching_evaluation";
import { listGbpxCourse } from "@/api/gbpx/gbpx_course";
import { listGbpxQuestionnaire } from "@/api/gbpx/gbpx_questionnaire";
import moment from "moment";
export default {
    name: "gbpx-gbpxteachingevaluation-list",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                {
                    field: "id",
                    title: "id",
                    minWidth: 120,
                    fixed: "left",
                    is_query: true,
                },
                { field: "name", title: "名称", minWidth: 120, is_query: true },
                { field: "course_name", title: "课程", minWidth: 120 },
                { field: "questionnaire_name", title: "问卷", minWidth: 120 },
                {
                    field: "begin_time",
                    title: "评教时间",
                    minWidth: 120,
                    formatter: this.timeFormat,
                },
                { field: "remark", title: "简介", minWidth: 120 },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                // 	field: "",
                // 	title: "操作",
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: { default: "defaultopr" },
                // },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
                course_id: undefined,
                questionnaire_id: undefined,
            },
            form: {},
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                course_id: [
                    {
                        required: true,
                        message: "课程不能为空",
                        trigger: "blur",
                    },
                ],
                questionnaire_id: [
                    {
                        required: true,
                        message: "问卷不能为空",
                        trigger: "blur",
                    },
                ],
                //dateRange: [{ required: true, message: "评教时间不能为空", trigger: "blur" }],
            },
            listGbpxCourse,
            listGbpxQuestionnaire,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listGbpxTeachingEvaluation(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        timeFormat(e) {
            if (e.row.begin_time && e.row.end_time) {
                return (
                    moment(e.row.begin_time * 1000).format("yyyy-MM-DD HH:mm") +
                    " 至 " +
                    moment(e.row.end_time * 1000).format("yyyy-MM-DD HH:mm")
                );
            }
            return e.cellValue;
        },

        reset() {
            this.form = {
                name: undefined,
                course_id: undefined,
                questionnaire_id: undefined,
                remark: undefined,
                dateRange: undefined,
            };
            this.resetForm("form");
        },

        handleQuery(e) {
            if (e) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getGbpxTeachingEvaluation(id).then((response) => {
                this.form = response.data;
                if (this.form.begin_time && this.form.end_time) {
                    //this.form.dateRange = [new Date(this.form.begin_time * 1000), new Date(this.form.end_time * 1000)]
                    this.$set(this.form, "dateRange", [
                        new Date(this.form.begin_time * 1000),
                        new Date(this.form.end_time * 1000),
                    ]);
                }
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (
                        this.form.dateRange &&
                        this.form.dateRange.length >= 2
                    ) {
                        this.form.begin_time = parseInt(
                            this.form.dateRange[0].getTime() / 1000
                        );
                        this.form.end_time = parseInt(
                            this.form.dateRange[1].getTime() / 1000
                        );
                    }
                    if (this.form.id != null) {
                        editGbpxTeachingEvaluation(this.form).then(
                            (response) => {
                                if (response.code === 0) {
                                    this.msgSuccess("修改成功");
                                    this.open = false;
                                    setTimeout(() => {
                                        this.getList();
                                    }, 300);
                                }
                            }
                        );
                    } else {
                        addGbpxTeachingEvaluation(this.form).then(
                            (response) => {
                                if (response.code === 0) {
                                    this.msgSuccess("新增成功");
                                    this.open = false;
                                    setTimeout(() => {
                                        this.getList();
                                    }, 300);
                                }
                            }
                        );
                    }
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delGbpxTeachingEvaluation(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
    },
};
</script>
