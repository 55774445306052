<template>
  <generate-form :key="2" ref="queryform" :conf="conf" v-if="conf" />
</template>
<script>
import Vue from "vue";
import GenerateForm from "./GenerateForm";

export default {
  props: {
    conf: Object,
  },
  
  data() {
    return {
      loading: false,
      dialogVisible: false,
      showRenderJsx: true,
      formData: {},
    };
  },
  components: {
    GenerateForm,
  },
  methods: {},
  beforeDestroy() {},
};
</script>
 
