<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <!-- <el-form-item label="名称" prop="top_name">
        <el-input
          v-model="queryParams.top_name"
          placeholder="请输入名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="主题路径" prop="path_name">
        <el-input
          v-model="queryParams.path_name"
          placeholder="请输入主题路径"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="类型" prop="topic_type">
        <el-select v-model="queryParams.topic_type" placeholder="类型" clearable size="small">
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="模型" prop="mould_id">
        <el-select v-model="queryParams.mould_id" placeholder="模型" clearable size="small">
          <el-option
            v-for="dict in mouldList"
            :key="dict.id"
            :label="dict.name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row/>

    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="topicList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>

        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-view"
          size="mini"
          :disabled="multiple"
          @click="handledownload"
        >一键烧录</el-button>
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
        >修改
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="top_name">
          <el-input v-model="form.top_name" placeholder="名称" />
        </el-form-item>
        <el-form-item label="主题路径" prop="path_name">
          <el-input v-model="form.path_name" placeholder="主题路径" />
        </el-form-item>
        <el-form-item label="类型" prop="topic_type">
          <el-select v-model="form.topic_type" placeholder="类型" clearable size="small">
            <el-option
              v-for="dict in statusOptions"
              :key="dict.key"
              :label="dict.value"
              :value="dict.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="功能描述" prop="usefor_des">
          <el-input v-model="form.usefor_des" placeholder="功能描述" />
        </el-form-item>
        <!-- <el-form-item label="所属模型" prop="mould_id">
          <el-select v-model="form.mould_id" placeholder="模型" clearable size="small">
            <el-option
              v-for="dict in mouldList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listIotTopic, getIotTopic, addIotTopic, updateIotTopic, delIotTopic } from "@/api/iot/topic";

export default {
  name: "IotTopic",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: 'mould_id', title: '模型',formatter:this.MouldFormat },
        {field: 'top_name', title: '名称' },
        {field: 'path_name', title: '主题' },
        {field: 'topic_type', title: '类型' ,formatter:this.TopicFormat },
        {field: 'usefor_des', title: '功能' },
        {field: 'remark', title: '备注' },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 分类表格数据
      topicList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      mouldList:[],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        is_system: 0
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        /* mould_id: [
          { required: true, message: "模型不能为空", trigger: "blur" }
        ],
        top_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ] */
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },

    /** 查询分类列表 */
    getList() {
      this.loading = true;
      listIotTopic(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;
        this.topicList = response.data.list;
        //this.mouldList = response.data.mouldList;
        this.total = response.data.total;
        this.statusOptions = response.data.statusOptions.values;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        mould_id: undefined,
        top_name: undefined,
        path_name: undefined,
        topic_type: "1",
        usefor_des: undefined,
        remark: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加主题";
    },
    handledownload(row){
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认生成固件功能编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {

        }).then(() => {

          this.msgSuccess("等待服务器生成......");
        }).catch(function() {});
    },
    MouldFormat({row}){
      this.labname="";
      this.mouldList.forEach(element => {
        if (row.mould_id === element.id)
          this.labname= element.name;
      });
      return this.labname;
    },
    TopicFormat({row}){
      return this.selectDictLabel(this.statusOptions, row.topic_type + '');
    },

    /** 修改按钮操作 */
    handleUpdate(row) {

      this.reset();
      const id = row.id || this.ids
      getIotTopic(id).then(response => {
        this.form = response.data;
        let data = response.data
        if (data) {
          this.form = {
            id: data.id,
            mould_id: data.mould_id,
            top_name: data.top_name,
            path_name: data.path_name,
            topic_type: data.topic_type,
            usefor_des: data.usefor_des,
            remark: data.remark
          }
        }
        this.open = true;
        this.title = "修改主题";
      });
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
            updateIotTopic(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addIotTopic(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除主题编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotTopic(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    }
  }
};
</script>
