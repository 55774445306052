<template>
    <div class="p-20">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span> <i class="el-icon-s-platform mr-5"></i> 基本信息</span>
            </div>
            <div>
                <el-row class="mb-20">
                    <el-col :span="3">Redis本</el-col>
                    <el-col :span="3">{{data.Server ? data.Server.redis_version : ''}}</el-col>
                    <el-col :span="3">运行模式</el-col>
                    <el-col :span="3">{{data.Server ? (data.Server.redis_mode == 'standalone' ? '单机' : '集群') : ''}}</el-col>
                    <el-col :span="3">端口</el-col>
                    <el-col :span="3">{{data.Server ? data.Server.tcp_port : ''}}</el-col>
                    <el-col :span="3">客户端数</el-col>
                    <el-col :span="3">{{data.Clients ? data.Clients.connected_clients : ''}}</el-col>
                </el-row>
                <el-divider></el-divider>
            </div>
            <div>
                <el-row class="mb-20">
                    <el-col :span="3"> 运行时间(天)</el-col>
                    <el-col :span="3">{{data.Server ? data.Server.uptime_in_days : ''}}</el-col>
                    <el-col :span="3">使用CPU</el-col>
                    <el-col :span="3">{{data.CPU ? data.CPU.used_cpu_sys : ''}}</el-col>
                    <el-col :span="3">使用内存</el-col>
                    <el-col :span="3">{{data.Memory ? data.Memory.used_memory_human : ''}}</el-col>
                    <el-col :span="3">内存配置</el-col>
                    <el-col :span="3">{{data.Memory ? data.Memory.maxmemory_human : ''}}</el-col>
                </el-row>
                <el-divider></el-divider>
            </div>
            <div>
                <el-row class="mb-20">
                    <el-col :span="3">AOF否开启</el-col>
                    <el-col :span="3">{{data.Persistence ? (data.Persistence.aof_enabled == '1' ? '是' : '否') : ''}}</el-col>
                    <el-col :span="3">RDB是否成功</el-col>
                    <el-col :span="3">{{data.Persistence ? data.Persistence.rdb_last_bgsave_status : ''}}</el-col>
                    <el-col :span="3">Key数量</el-col>
                    <el-col :span="3">81</el-col>
                    <el-col :span="3">网络入口/出口</el-col>
                    <el-col :span="3">{{data.Stats ? (data.Stats.instantaneous_input_kbps + 'kbps/' + data.Stats.instantaneous_output_kbps + 'kbps') : '0.00kbps/0.00kbps'}}</el-col>
                </el-row>
                <el-divider></el-divider>
            </div>
        </el-card>
        <el-row :gutter="20" class="mt-20">
            <el-col :span="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span><i class="el-icon-pie-chart  mr-5"></i>命令统计</span>
                    </div>
                    <div
                        id="countChart"
                        style="width: 100%; height: 350px"
                    ></div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span><i class="el-icon-odometer  mr-5"></i>内存信息</span>
                    </div>
                    <div
                        id="memoryChart"
                        style="width: 100%; height: 350px"
                    ></div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import $echarts from "echarts";
import { info } from '@/api/core/monitor/cache' 
export default {
    name:'core-monitor-cache-index',
    data() {
        return {
          data: {}
        };
    },
    mounted() {
        // this.$nextTick(() => {
        this.setCountChart();
        this.setMemoryChart();
        // });
    },
    created() {
      info().then(res => {
        this.data = res.data

        this.memoryChart.setOption({
            series: [
                {
                    data: [
                        {
                            value: this.data.Memory.used_memory * 100 / this.data.Memory.maxmemory,
                        },
                    ],
                },
            ],
        });
      })
    },
    methods: {
        setCountChart() {
            var app = {};

            var chartDom = document.getElementById("countChart");
            var myChart = $echarts.init(chartDom);
            var option;

            const data = [
                {
                    name: "dbsize",
                    value: 1,
                },
                {
                    name: "setex",
                    value: 1,
                },
                {
                    name: "get",
                    value: 99,
                },
                {
                    name: "keys",
                    value: 1,
                },
                {
                    name: "set",
                    value: 1,
                },
                {
                    name: "del",
                    value: 1,
                },
                {
                    name: "info",
                    value: 1,
                },
                {
                    name: "exists",
                    value: 1,
                },
                {
                    name: "auth",
                    value: 1,
                },
            ];
            option = {
                series: [
                    {
                        type: "pie",
                        radius: "50%",
                        
                        center: ["50%", "50%"],
                        data: data,
                        label: {
                            position: "outer",
                            alignTo: "none",
                            bleedMargin: 5,
                        },
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    },
                ],
            };

            option && myChart.setOption(option);
        },
        setMemoryChart() {
            var chartDom = document.getElementById("memoryChart");
            var myChart = $echarts.init(chartDom);
            var option;

            option = {
                series: [
                    {
                        type: "gauge",
                        radius: "80%",
                        center: ["50%", "50%"],
                        axisLine: {
                            lineStyle: {
                                width: 20,
                                color: [
                                    [0.3, "#67e0e3"],
                                    [0.7, "#37a2da"],
                                    [1, "#fd666d"],
                                ],
                            },
                        },
                        pointer: {
                            itemStyle: {
                                color: "auto",
                            },
                        },
                        axisTick: {
                            distance: -20,
                            length: 8,
                            lineStyle: {
                                color: "#fff",
                                width: 2,
                            },
                        },
                        splitLine: {
                            distance: -20,
                            length: 20,
                            lineStyle: {
                                color: "#fff",
                                width: 4,
                            },
                        },
                        axisLabel: {
                            color: "inherit",
                            distance: 10,
                            fontSize: 15,
                        },
                        detail: {
                            valueAnimation: true,
                            formatter: "{value} km/h",
                            color: "inherit",
                        },
                        data: [
                            {
                                value: 0,
                            },
                        ],
                    },
                ],
            };
            /*setInterval(function () {
                myChart.setOption({
                    series: [
                        {
                            data: [
                                {
                                    value: +(Math.random() * 100).toFixed(2),
                                },
                            ],
                        },
                    ],
                });
            }, 2000);*/

            option && myChart.setOption(option);

            this.memoryChart = myChart
        },
    },
};
</script>
<style lang="scss" scoped>
.p-20 {
    padding: 20px;
}
</style>