import request from '@/utils/request'

// 查询 
export function listRoombook(query) {
  // 发起请求并返回结果
  return request({
    url: '/cms/roombook/list',  // 请求的URL地址
    method: 'get',  // 请求的方法为GET
    params: query  // 请求的参数为query
  })
}

// 查询 详细
export function getRoombook(id) {
  return request({
    url: '/cms/roombook/get?id=' + id,
    method: 'get'
  })
}

 

// 删除 
export function delRoombook(id) {
  return request({
    url: '/cms/roombook/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
