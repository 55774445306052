import request from '@/utils/request'
// 生成时间:2021-08-26 17:45:23

// 查询test_men列表
export function listTestMen(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/gentest/test_men/list',
        method: 'post',
        data: data
    })
}

// 查询test_men详细
export function getTestMen (id) {
    return request({
        url: '/gentest/test_men/get?id='+id,
        method: 'get'
    })
}


// 新增test_men
export function addTestMen(data) {
    return request({
        url: '/gentest/test_men/add',
        method: 'post',
        data: data
    })
}

// 修改test_men
export function editTestMen(data) {
    return request({
        url: '/gentest/test_men/edit',
        method: 'post',
        data: data
    })
}

// 删除test_men
export function delTestMen(ids) {
    return request({
        url: '/gentest/test_men/del',
        method: 'post',
        data:{ids:ids}
    })
}

