import request from '@/utils/request'

// 查询会员分组列表
export function listUserGroup(userpool, query) {
  return request({
    url: '/ucenter/' + userpool + '/usergroup/list',
    method: 'get',
    params: query
  })
}

// 查询会员分组详细
export function getUserGroup(userpool, id) {
  return request({
    url: '/ucenter/' + userpool + '/usergroup/get?id=' + id,
    method: 'get'
  })
}

// 新增会员分组
export function addUserGroup(userpool, data) {
  return request({
    url: '/ucenter/' + userpool + '/usergroup/add',
    method: 'post',
    data: data
  })
}

// 修改会员分组
export function updateUserGroup(userpool, data) {
  return request({
    url: '/ucenter/' + userpool + '/usergroup/edit',
    method: 'post',
    data: data
  })
}

// 删除会员分组
export function delUserGroup(userpool, ids) {
  return request({
    url: '/ucenter/' + userpool + '/usergroup/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}
