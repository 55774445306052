<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
            <!--<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
            <!-- <el-form-item label="工号" prop="no">
                <el-input
                    v-model="queryParams.no"
                    placeholder="请输入工号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="姓名" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入姓名"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="院系" prop="dept_no">
                <dept-select
                    v-model="queryParams.dept_no"
                    placeholder="请选择院系"
                    valueField="no"
                    size="small"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/stu/stuassistant/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/stu/stuassistant/get',
                        '/stu/stuassistant/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/stu/stuassistant/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-set-up"
                    size="mini"
                    :disabled="multiple"
                    @click="handleBind"
                    v-hasPermi="['/stu/stuassistant/bind']"
                    >辅导员绑定</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-refresh-left"
                    size="mini"
                    @click="handleSyncAccount"
                    v-hasPermi="['/stu/stuassistant/syncAccount']"
                    >同步账号</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-upload"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['/stu/stuassistant/import']"
                    >导入</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/stu/stuassistant/export']"
                    >导出</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="query_qarams_item">
                <el-form-item label="院系" prop="dept_no">
                    <dept-select
                        v-model="queryParams.dept_no"
                        placeholder="请选择院系"
                        valueField="no"
                        size="small"
                    />
                </el-form-item>
            </template>
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/stu/stuassistant/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/stu/stuassistant/get',
                        '/stu/stuassistant/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/stu/stuassistant/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-set-up"
                    size="mini"
                    :disabled="multiple"
                    @click="handleBind"
                    v-hasPermi="['/stu/stuassistant/bind']"
                    >辅导员绑定</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-refresh-left"
                    size="mini"
                    @click="handleSyncAccount"
                    v-hasPermi="['/stu/stuassistant/syncAccount']"
                    >同步账号</el-button
                >
                <el-button
                    type="warning"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['/stu/stuassistant/import']"
                >
                    <i class="iconfont icon-daoru_o"></i>
                    导入
                </el-button>

                <el-button
                    class="btn-orange"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/stu/stuassistant/export']"
                >
                    <i class="iconfont icon-daochu_o"></i>
                    导出
                </el-button>
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="130px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工号" prop="no">
                            <el-input
                                v-model="form.no"
                                placeholder="请输入工号"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="姓名" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入姓名"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="院系" prop="dept_no">
                            <dept-select
                                v-model="form.dept_no"
                                :defaultLabel="form.dept_name"
                                placeholder="请选择院系"
                                valueField="no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证号" prop="idcard">
                            <el-input
                                v-model="form.idcard"
                                placeholder="请输入身份证号"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="gender">
                            <dict-select
                                v-model="form.gender"
                                placeholder="请选择性别"
                                type="stu_gender"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号" prop="mobile">
                            <el-input
                                v-model="form.mobile"
                                placeholder="请输入手机号"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备注信息" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入备注信息"
                                clearable
                                type="textarea"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="是否已生成账号"
                            prop="sync_account"
                        >
                            <dict-select
                                v-model="form.sync_account"
                                placeholder="请选择是否已生成账号"
                                type="stu_yes_no"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>

        <el-dialog
            :close-on-click-modal="false"
            title="辅导员绑定"
            :visible.sync="bind"
            width="800px"
            append-to-body
        >
            <el-form
                ref="formBind"
                :model="formBind"
                :rules="rulesBind"
                label-width="150px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="辅导员工号" prop="nos">
                            <el-input
                                v-model="formBind.nos"
                                placeholder="辅导员工号"
                                clearable
                                :readonly="true"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="辅导员姓名" prop="names">
                            <el-input
                                v-model="formBind.names"
                                placeholder="辅导员姓名"
                                clearable
                                :readonly="true"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="绑定院系" prop="dept_no">
                            <dept-select
                                v-model="formBind.dept_no"
                                :defaultLabel="formBind.dept_name"
                                placeholder="请选择绑定院系"
                                valueField="no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="绑定专业" prop="major_no">
                            <major-select
                                v-model="formBind.major_no"
                                :defaultLabel="formBind.major_name"
                                placeholder="请选择绑定专业"
                                :dept_no="formBind.dept_no"
                                :multiple="true"
                                valueField="no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="绑定班级" prop="classes_no">
                            <!--<el-input
							v-model="formBind.classes_no"
							placeholder="请输入绑定班级"
							clearable
						/>-->
                            <classes-select
                                v-model="formBind.classes_no"
                                :defaultLabel="formBind.classes_name"
                                placeholder="请选择绑定班级"
                                :dept_no="formBind.dept_no"
                                :major_no="formBind.major_no"
                                :multiple="true"
                                valueField="no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col
                        v-if="formBind.dept_no || formBind.major_no"
                        :span="24"
                    >
                        <el-form-item
                            label="专业辅导员绑定方式"
                            prop="replace_major_assistant"
                        >
                            <el-select
                                v-model="formBind.replace_major_assistant"
                                placeholder="请选择"
                            >
                                <el-option
                                    label="添加"
                                    :value="false"
                                ></el-option>
                                <el-option
                                    label="替换"
                                    :value="true"
                                ></el-option>
                            </el-select>
                            <label
                                v-if="formBind.replace_major_assistant"
                                style="font-weight: normal; color: red"
                                >专业原绑定的辅导员将被替换成新的辅导员</label
                            >
                            <label v-else style="font-weight: normal"
                                >保留专业原绑定的辅导员并添加新的辅导员</label
                            >
                        </el-form-item>
                    </el-col>
                    <el-col
                        v-if="
                            formBind.dept_no ||
                            formBind.major_no ||
                            formBind.classes_no
                        "
                        :span="24"
                    >
                        <el-form-item
                            label="班级辅导员绑定方式"
                            prop="replace_classes_assistant"
                        >
                            <el-select
                                v-model="formBind.replace_classes_assistant"
                                placeholder="请选择"
                            >
                                <el-option
                                    label="添加"
                                    :value="false"
                                ></el-option>
                                <el-option
                                    label="替换"
                                    :value="true"
                                ></el-option>
                            </el-select>
                            <label
                                v-if="formBind.replace_classes_assistant"
                                style="font-weight: normal; color: red"
                                >班级原绑定的辅导员将被替换成新的辅导员</label
                            >
                            <label v-else style="font-weight: normal"
                                >保留班级原绑定的辅导员并添加新的辅导员</label
                            >
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="学生辅导员绑定方式"
                            prop="replace_student_assistant"
                        >
                            <el-select
                                v-model="formBind.replace_student_assistant"
                                placeholder="请选择"
                            >
                                <el-option
                                    label="添加"
                                    :value="false"
                                ></el-option>
                                <el-option
                                    label="替换"
                                    :value="true"
                                ></el-option>
                            </el-select>
                            <label
                                v-if="formBind.replace_student_assistant"
                                style="font-weight: normal; color: red"
                                >学生原绑定的辅导员将被替换成新的辅导员</label
                            >
                            <label v-else style="font-weight: normal"
                                >保留学生原绑定的辅导员并添加新的辅导员</label
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitBind">确 定</el-button>
                <el-button @click="cancelBind">返 回</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="upload.title"
            :visible.sync="upload.open"
            width="400px"
            append-to-body
        >
            <el-upload
                ref="upload"
                :limit="1"
                accept=".xlsx"
                :headers="upload.headers"
                :action="upload.url + '?update=' + upload.update"
                :disabled="upload.isUploading"
                :on-progress="handleImportProgress"
                :on-success="handleImportSuccess"
                :on-error="handleImportError"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <el-checkbox
                        v-model="upload.update"
                    />是否更新已经存在的数据
                    <el-link
                        type="info"
                        style="font-size: 12px"
                        @click="handleImportTemplate"
                        >下载模板</el-link
                    >
                    <el-link
                        type="info"
                        style="font-size: 12px"
                        @click="handleImportReport"
                        >下载报告</el-link
                    >
                </div>
                <div class="el-upload__tip" style="color: red" slot="tip">
                    提示：仅允许导入“xlsx”格式文件！
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitImportForm"
                    >确 定</el-button
                >
                <el-button @click="upload.open = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="同步账号"
            :visible.sync="syncAccountOpen"
            width="500px"
            append-to-body
        >
            <el-form
                ref="syncAccountForm"
                :model="syncAccountForm"
                :rules="syncAccountRules"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="密码规则" prop="password_rule">
                            <dict-select
                                v-model="syncAccountForm.password_rule"
                                placeholder="请选密码规则"
                                type="stu_assistant_password_rule"
                            />
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="24">
						<el-form-item label="是否需要验证账号" prop="needs_validate">
							<dict-select v-model="syncAccountForm.needs_validate" placeholder="请选择是否需要验证账号" type="stu_yes_no" />
						</el-form-item>
					</el-col>-->
                </el-row>
            </el-form>

            <table
                v-if="syncAccountProgressData.total"
                style="width: 100%"
                cellpadding="5px"
            >
                <tr>
                    <td width="50px">进度</td>
                    <td style="">
                        <el-progress
                            :text-inside="true"
                            :stroke-width="16"
                            :percentage="
                                syncAccountProgressData.total
                                    ? parseInt(
                                          (syncAccountProgressData.count *
                                              100) /
                                              syncAccountProgressData.total
                                      )
                                    : 0
                            "
                        ></el-progress>
                    </td>
                </tr>
                <tr>
                    <td>总数</td>
                    <td>
                        {{ syncAccountProgressData.count }} /
                        {{ syncAccountProgressData.total }}
                    </td>
                </tr>
                <tr>
                    <td>成功</td>
                    <td>{{ syncAccountProgressData.success }}</td>
                </tr>
                <tr>
                    <td>失败</td>
                    <td>
                        {{ syncAccountProgressData.fail }}&nbsp;&nbsp;{{
                            syncAccountProgressData.msg
                        }}
                    </td>
                </tr>
                <tr>
                    <td>耗时</td>
                    <td>
                        {{
                            parseInt(syncAccountProgressData.time / 60 / 60)
                        }}时{{
                            parseInt(syncAccountProgressData.time / 60) % 60
                        }}分{{ syncAccountProgressData.time % 60 }}秒
                    </td>
                </tr>
            </table>

            <div slot="footer" class="dialog-footer">
                <el-button
                    v-if="
                        !syncAccountProgressData.total ||
                        syncAccountProgressData.total ==
                            syncAccountProgressData.count
                    "
                    type="primary"
                    @click="submitSyncAccountForm"
                    >同 步</el-button
                >
                <el-button @click="syncAccountOpen = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Qs from "qs";
import { getAccessToken } from "@/utils/tokenTool";
import {
    listStuAssistant,
    getStuAssistant,
    addStuAssistant,
    editStuAssistant,
    delStuAssistant,
    bindStuAssistant,
    syncAccount,
    syncAccountProgress,
} from "@/api/stu/stu_assistant";
export default {
    name: "stu-stuassistant-list",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                {
                    field: "id",
                    title: "id",
                    minWidth: 120,
                    fixed: "left",
                    visible: false,
                },
                { field: "no", title: "工号", minWidth: 120 ,is_query:true},
                { field: "name", title: "姓名", minWidth: 120 ,is_query:true},
                { field: "dept_name", title: "院系", minWidth: 120 },
                { field: "idcard", title: "身份证号", minWidth: 120 },
                {
                    field: "gender",
                    title: "性别",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_gender",
                },
                { field: "mobile", title: "手机号", minWidth: 120 },
                { field: "remark", title: "备注信息", minWidth: 120 },
                {
                    field: "sync_account",
                    title: "是否已生成账号",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_yes_no",
                },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                //     field: "",
                //     title: "操作",
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            bind: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                no: undefined,
                name: undefined,
                dept_no: undefined,
            },
            form: {},
            formBind: {},
            upload: {
                open: false,
                title: "",
                isUploading: false,
                update: false,
                headers: { Authorization: getAccessToken() },
                url: process.env.VUE_APP_BASE_API + "/stu/stuassistant/import",
            },
            rules: {
                no: [
                    {
                        required: true,
                        message: "工号不能为空",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "姓名不能为空",
                        trigger: "blur",
                    },
                ],
                dept_no: [
                    {
                        required: true,
                        message: "院系不能为空",
                        trigger: "blur",
                    },
                ],
                //idcard: [{ required: true, message: "身份证号不能为空", trigger: "blur" }],
                gender: [
                    {
                        required: true,
                        message: "性别不能为空",
                        trigger: "blur",
                    },
                ],
            },
            rulesBind: {
                nos: [
                    {
                        required: true,
                        message: "工号不能为空",
                        trigger: "blur",
                    },
                ],
                names: [
                    {
                        required: true,
                        message: "姓名不能为空",
                        trigger: "blur",
                    },
                ],
            },
            syncAccountOpen: false,
            syncAccountForm: {},
            syncAccountRules: {
                password_rule: [
                    {
                        required: true,
                        message: "密码规则不能为空",
                        trigger: "blur",
                    },
                ],
                needs_validate: [
                    {
                        required: true,
                        message: "是否需要验证账号不能为空",
                        trigger: "blur",
                    },
                ],
            },
            syncAccountProgressData: {},
        };
    },
    created() {
        this.syncAccountProgress();

        // for (let i = 0; i < this.tableColumn.length; i++) {
        //     const col = this.tableColumn[i];
        //     if (col.dictType) {
        //         this.getDicts(col.dictType).then((response) => {
        //             if (response.code == 0) {
        //                 col.dictData = response.data;
        //                 this.dataList = [].concat(this.dataList);
        //             }
        //         });
        //     }
        // }
        this.getList();
    },
    methods: {
        tableChange(e){
            console.log(e)
        },
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listStuAssistant(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        dictFormat(e) {
            const col = this.tableColumn[e.columnIndex];
            if (col.dictData) {
                const values = e.cellValue ? e.cellValue.split(",") : [];
                const labels = [];
                for (let i = 0; i < values.length; i++) {
                    const v = values[i];
                    for (let j = 0; j < col.dictData.values.length; j++) {
                        const item = col.dictData.values[j];
                        if (v == item.key) {
                            labels.push(item.value);
                            break;
                        }
                    }
                }
                return labels.join(",");
            }
            return e.cellValue;
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.form = {
                no: undefined,
                name: undefined,
                idcard: undefined,
                dept_no: undefined,
                gender: undefined,
                mobile: undefined,
                remark: undefined,
                sync_account: undefined,
            };
            this.resetForm("form");
        },

        handleQuery(e) {
            if(e){  this.queryParams = this.deepCopy(e);}
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getStuAssistant(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editStuAssistant(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addStuAssistant(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delStuAssistant(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.nos = records.map((item) => item.no).join(",");
            this.names = records.map((item) => item.name).join(",");
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        handleBind() {
            this.formBind = {
                nos: this.nos,
                names: this.names,
                dept_no: undefined,
                major_no: undefined,
                classes_no: undefined,
                replace_dept_assistant: false,
                replace_major_assistant: false,
                replace_classes_assistant: false,
                replace_student_assistant: false,
            };
            this.bind = true;
        },

        submitBind() {
            this.$refs["formBind"].validate((valid) => {
                if (valid) {
                    if (
                        !this.formBind.dept_no &&
                        !this.formBind.major_no &&
                        !this.formBind.classes_no
                    ) {
                        this.$message.info("绑定院系、专业和班级不能同时为空");
                        return;
                    }
                    bindStuAssistant(this.formBind).then((response) => {
                        if (response.code == 0) {
                            this.msgSuccess(
                                "成功绑定" +
                                    response.data.major_count +
                                    "个专业，" +
                                    response.data.classes_count +
                                    "个班级，" +
                                    response.data.student_count +
                                    "个学生"
                            );
                            this.bind = false;
                        }
                    });
                }
            });
        },

        cancelBind() {
            this.bind = false;
        },

        handleSyncAccount() {
            this.resetForm("syncAccountForm");
            this.syncAccountOpen = true;
        },

        submitSyncAccountForm() {
            this.$refs["syncAccountForm"].validate((valid) => {
                if (valid) {
                    if (this.syncAccountLoading) {
                        this.syncAccountLoading.close();
                    }
                    this.syncAccountLoading = this.$loading({
                        lock: true,
                        text: "正在同步账号",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    const queryParams = this.queryParams;
                    queryParams.password_rule =
                        this.syncAccountForm.password_rule;
                    queryParams.needs_validate =
                        this.syncAccountForm.needs_validate;
                    syncAccount(queryParams)
                        .then((response) => {
                            this.syncAccountLoading.close();
                            this.syncAccountLoading = undefined;
                            //this.syncAccountOpen = false
                            if (response.code == 0) {
                                this.msgSuccess(
                                    "同步" + response.data.count + "个账号"
                                );
                            }
                            this.syncAccountForm.password_rule = "";
                            this.syncAccountProgress();
                        })
                        .catch((err) => {
                            this.syncAccountLoading.close();
                            this.syncAccountLoading = undefined;
                        });
                }
            });
        },

        syncAccountProgress() {
            syncAccountProgress().then((res) => {
                this.syncAccountProgressData = res.data;
                if (res.data.total > 0 && res.data.count < res.data.total) {
                    if (!this.syncAccountOpen) {
                        this.syncAccountForm.password_rule = "";
                        this.syncAccountOpen = true;
                    }
                    setTimeout(() => {
                        this.syncAccountProgress();
                    }, 1000);
                }
            });
        },

        handleImport() {
            this.upload.title = "数据导入";
            this.upload.update = false;
            this.upload.open = true;
        },

        handleImportProgress(event, file, fileList) {
            this.upload.isUploading = true;
            if (event.percent == 100) {
                this.upload.open = false;
                this.upload.loading = this.$loading({
                    lock: true,
                    text: "正在导入数据",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
        },

        handleImportSuccess(response, file, fileList) {
            console.log(response);
            if (this.upload.loading) {
                this.upload.loading.close();
            }
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            if (response.code == 0) {
                const msg =
                    "导入成功" +
                    response.data.success_count +
                    "条数据，失败" +
                    response.data.fail_count +
                    "条数据" +
                    (response.data.msg ? "，" + response.data.msg : "");
                //this.$alert(msg, "提示", { dangerouslyUseHTMLString: true });
                this.$confirm(msg, "提示", {
                    distinguishCancelAndClose: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "下载导入报告",
                })
                    .then(() => {})
                    .catch((action) => {
                        if (action === "cancel") {
                            // 下载导入报告
                            this.downloadbyurl(
                                "/stu/stuassistant/importReport"
                            );
                        }
                    });
            } else {
                this.$alert(response.msg, "提示", {
                    dangerouslyUseHTMLString: true,
                });
            }
            this.getList();
        },

        handleImportError(err, file, fileList) {
            if (this.upload.loading) {
                this.upload.loading.close();
            }
            this.upload.isUploading = false;
            this.$alert("导入失败", "提示", { dangerouslyUseHTMLString: true });
        },

        submitImportForm() {
            this.$refs.upload.submit();
        },

        handleImportTemplate() {
            this.downloadbyurl("/stu/stuassistant/importTemplate");
        },

        handleImportReport() {
            this.downloadbyurl("/stu/stuassistant/importReport");
        },

        handleExport() {
            this.$confirm("确定导出数据吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.downloadbyurl(
                    "/stu/stuassistant/export?" + Qs.stringify(this.queryParams)
                );
            });
        },
    },
};
</script>
