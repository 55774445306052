import request from '@/utils/request'

// 查询cms区块列表
export function listCmsBlock(query) {
  return request({
    url: '/cms/block/list',
    method: 'get',
    params: query
  })
}

// 查询cms区块详细
export function getCmsBlock(id) {
  return request({
    url: '/cms/block/get?id=' + id,
    method: 'get'
  })
}

// 新增cms区块
export function addCmsBlock(data) {
  return request({
    url: '/cms/block/add',
    method: 'post',
    data: data
  })
}

// 修改cms区块
export function updateCmsBlock(data) {
  return request({
    url: '/cms/block/edit',
    method: 'post',
    data: data
  })
}

// 删除cms区块
export function delCmsBlock(id) {
  return request({
    url: '/cms/block/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
