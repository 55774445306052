<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
        >
            <el-form-item label="会员ID" prop="user_id">
                <el-input
                    v-model="queryParams.user_id"
                    placeholder="请输入会员ID"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>

            <el-form-item label="状态" prop="status">
                <el-select
                    v-model="queryParams.status"
                    placeholder="状态"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in statusOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="计费类型" prop="charge_type">
                <el-select
                    v-model="queryParams.charge_type"
                    placeholder="计费类型"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in chargeTypeOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['data:api:app:add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['data:api:app:get', 'data:api:app:edit']"
                    >修改</el-button
                >
            </template> -->

        <!--默认操作按钮-->
        <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #pricetpl="{ row }">
                <el-tag type="" effect="light" v-if="row.charge_type == 0"
                    >{{ row.price }}元</el-tag
                >
                <el-tag
                    type="success"
                    effect="light"
                    v-if="row.charge_type == 1"
                    >{{ row.price }}%</el-tag
                >
            </template> -->
        <!-- <template #chargetypetpl="{ row }">
                <el-tag type="" effect="light" v-if="row.charge_type == 0">{{
                    chargeTypeFormat(row)
                }}</el-tag>
                <el-tag type="" effect="light" v-if="row.charge_type == 1">{{
                    chargeTypeFormat(row)
                }}</el-tag>
            </template>
            <template #statustpl="{ row }">
                <el-tag type="warning" effect="light" v-if="row.status == 0">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="success" effect="light" v-if="row.status == 1">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="danger" effect="light" v-if="row.status == 2">{{
                    statusFormat(row)
                }}</el-tag>
            </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['data:api:app:add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['data:api:app:get', 'data:api:app:edit']"
                    >修改</el-button
                >
            </template>
            <template slot="pricetpl" slot-scope="{ row }">
                <el-tag type="" effect="light" v-if="row.charge_type == 0"
                    >{{ row.price }}元</el-tag
                >
                <el-tag
                    type="success"
                    effect="light"
                    v-if="row.charge_type == 1"
                    >{{ row.price }}%</el-tag
                >
            </template>
            <template slot="chargetypetpl" slot-scope="{ row }">
                <el-tag type="" effect="light" v-if="row.charge_type == 0">{{
                    chargeTypeFormat(row)
                }}</el-tag>
                <el-tag type="" effect="light" v-if="row.charge_type == 1">{{
                    chargeTypeFormat(row)
                }}</el-tag>
            </template>
            <template slot="statustpl" slot-scope="{ row }">
                <el-tag type="warning" effect="light" v-if="row.status == 0">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="success" effect="light" v-if="row.status == 1">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="danger" effect="light" v-if="row.status == 2">{{
                    statusFormat(row)
                }}</el-tag>
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改对话框 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item
                    label="会员"
                    prop="user_id"
                    :rules="[
                        {
                            required: true,
                            message: '会员不能为空',
                            trigger: 'blur',
                        },
                    ]"
                >
                    <user-select
                        size="small"
                        placeholder="请选择会员"
                        :limit="1"
                        :value="form.user_id"
                        :readonly="true"
                        @getValue="
                            (value) => {
                                form.user_id = value;
                            }
                        "
                        :disabled="form.id?true:false"
                    ></user-select>
                </el-form-item>
                <el-form-item label="Api" prop="api_id" v-show="!form.id">
                    <el-select
                        v-model="form.api_id"
                        style="width: 100%"
                        placeholder="请选择"
                        clearable
                        filterable
                        @change="changeApi"
                    >
                        <el-option
                            v-for="api in apiList"
                            :key="api.id"
                            :label="api.name"
                            :value="api.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="name" v-show="form.id">
                    <el-input
                        v-model="form.name"
                        placeholder="请输入名称"
                        :disabled="true"
                    />
                </el-form-item>
                <el-form-item label="AppKey" prop="app_key" v-if="form.id">
                    {{ form.app_key }}
                    <el-tag
                        style="cursor: pointer"
                        v-clipboard:copy="form.app_key"
                        type="success"
                        v-clipboard:success="onCopySuccess"
                        v-clipboard:error="onCopyError"
                        >复制</el-tag
                    >
                </el-form-item>
                <el-form-item label="App密钥" prop="app_secret" v-if="form.id">
                    {{ form.app_secret }}
                    <el-tag
                        style="cursor: pointer"
                        v-clipboard:copy="form.app_secret"
                        type="success"
                        v-clipboard:success="onCopySuccess"
                        v-clipboard:error="onCopyError"
                        >复制</el-tag
                    >
                </el-form-item>
                <el-form-item label="剩余次数" prop="used" v-if="form.id">
                    <el-input
                        v-model="form.surplus"
                        placeholder="请输入剩余次数"
                        :readonly="true"
                    />
                </el-form-item>
                <el-form-item label="使用次数" prop="used" v-if="form.id">
                    <el-input
                        v-model="form.used"
                        placeholder="请输入使用次数"
                        :readonly="true"
                    />
                </el-form-item>
                <el-form-item label="单价" prop="price">
                    <el-input v-model="form.price" placeholder="请输入单价">
                        <template slot="prepend">{{
                            chargeTypeFormat(form)
                        }}</template>
                        <template slot="append">{{
                            form.charge_type == 0 ? "元" : "%"
                        }}</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio
                            v-for="dict in statusOptions"
                            :key="dict.key"
                            :label="dict.key"
                            >{{ dict.value }}</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" placeholder="请输入描述" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { listApp, getApp, delApp, addApp, editApp } from "@/api/data/api/app";
import UserSelect from "@/components/UserSelect";
export default {
    name: "data-api-app-list",
    data() {
        return {
            name: "app", //请勿改动
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 状态数据字典
            statusOptions: [
                { key: "0", value: "待审批" },
                { key: "1", value: "已通过" },
                { key: "2", value: "已拒绝" },
            ],
            chargeTypeOptions: [
                { key: "0", value: "计次" },
                { key: "1", value: "按实际付费" },
            ],

            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "ID", minWidth: 80, fixed: "left" },
                {
                    field: "user_id",
                    title: "会员ID",
                    minWidth: 80,
                    is_query: true,
                },
                { field: "api_id", title: "接口ID", minWidth: 80 },
                { field: "name", title: "名称", minWidth: 80, is_query: true },
                { field: "app_key", title: "AppKey", minWidth: 80 },
                {
                    field: "total",
                    title: "总次数",
                    minWidth: 80,
                    visible: false,
                },
                { field: "surplus", title: "剩余次数", minWidth: 80 },
                { field: "used", title: "使用次数", minWidth: 80 },
                {
                    field: "price",
                    title: "单价",
                    minWidth: 80,
                    slots: { default: "pricetpl" },
                },
                {
                    field: "charge_type",
                    title: "计费类型",
                    minWidth: 90,
                    slots: { default: "chargetypetpl" },
                    form_type: 2,
                    options: [
                        { key: "0", value: "计次" },
                        { key: "1", value: "按实际付费" },
                    ],
                    options_value: "key",
                    options_label: "value",
                    is_query: true,
                },
                {
                    field: "status",
                    title: "状态",
                    minWidth: 80,
                    slots: { default: "statustpl" },
                    form_type: 2,
                    options: [
                        { key: "0", value: "待审批" },
                        { key: "1", value: "已通过" },
                        { key: "2", value: "已拒绝" },
                    ],
                    options_value: "key",
                    options_label: "value",
                    is_query: true,
                },
                {
                    field: "remark",
                    title: "备注",
                    minWidth: 80,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 80,
                    formatter: "formatDate",
                    form_type: 3,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 80,
                    formatter: "formatDate",
                    visible: false,
                    form_type: 3,
                },
                // {
                //     field: "",
                //     title: "操作",
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 表格数据
            dataList: [],
            apiList: [],

            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                user_id: undefined,
                name: undefined,
                status: undefined,
                charge_type: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                api_id: [
                    { required: true, message: "API不能为空", trigger: "blur" },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    components: {
        UserSelect,
    },
    methods: {
        //列表的筛选和排序
        tableChange(e) {},
        onCopySuccess(e) {
            this.$message({
                message: "复制成功！",
                type: "success",
            });
        },
        onCopyError(e) {
            this.$message({
                message: "复制失败！",
                type: "error",
            });
        },
        statusFormat(row, column) {
            return this.selectDictLabel(this.statusOptions, row.status);
        },
        chargeTypeFormat(row, column) {
            return this.selectDictLabel(
                this.chargeTypeOptions,
                row.charge_type
            );
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handleSizeChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        /** 查询列表 */
        getList() {
            this.loading = true;

            listApp(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.apiList = response.data.apiList;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        changeApi(value) {
            let obj = {};
            obj = this.apiList.find((item) => {
                return item.id === value;
            });
            if (obj) {
                this.form.name = obj.name;
                this.form.charge_type = obj.charge_type;
            }
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                user_id: undefined,
                api_id: undefined,
                name: undefined,
                used: undefined,
                surplus: undefined,
                price: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getApp(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editApp(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addApp(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;

            this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delApp(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
    }, //methods结束
};
</script>
