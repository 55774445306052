<template>
    <el-form-item :label="i18nt('designer.setting.minLength')">
        <el-input
            type="number"
            @input.native="inputNumberHandler"
            class="hide-spin-button"
            min="0"
            v-model="minLength"
            style="width: 100%"
        ></el-input>
    </el-form-item>
</template>

<script>
import i18n from "@/vform/utils/i18n";
import propertyMixin from "@/vform/components/form-designer/setting-panel/property-editor/propertyMixin";

export default {
    name: "minLength-editor",
    mixins: [i18n, propertyMixin],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    computed: {
        minLength: {
            get() {
                return this.optionModel["minLength"];
            },

            set(newValue) {
                if (!newValue || isNaN(newValue)) {
                    this.optionModel.minLength = null;
                } else {
                    this.optionModel.minLength = Number(newValue);
                }
            },
        },
    },
    // watch: {
    //     "optionModel.minLength": {
    //         handler(val) {
    //             if (val && val * 1 > 0) {
    //                 let validation = `/^.{${val},}$/`;
    //                 this.optionModel.validation = validation;
    //                 this.optionModel.required = true;
    //                 this.optionModel.validationHint=`请输入${val}个以上`
    //             }
    //         },
    //         deep: true,
    //         immediate: true,
    //     },
    // },
};
</script>

<style scoped>
</style>
