<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
        <!--<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
        <!-- <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="分类" prop="category_id">
                <gbpx-course-category-select
                    v-model="queryParams.category_id"
                    placeholder="请选择分类"
                    valueField="id"
                    size="small"
                />
            </el-form-item> -->
        <!--<el-form-item label="教师" prop="teacher_id">
			<gbpx-teacher-select
				v-model="queryParams.teacher_id"
				placeholder="请选择教师"
				valueField="id"
				size="small"
			/>
		</el-form-item>-->
        <!-- <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            border
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxcourse/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxcourse/get',
                        '/gbpx/gbpxcourse/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxcourse/del']"
                    >删除</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="query_qarams_item">
                <el-form-item label="分类" prop="category_id">
                    <gbpx-course-category-select
                        v-model="queryParams.category_id"
                        placeholder="请选择分类"
                        valueField="id"
                        size="small"
                    />
                </el-form-item>
            </template>
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxcourse/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxcourse/get',
                        '/gbpx/gbpxcourse/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxcourse/del']"
                    >删除</el-button
                >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="960px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入名称"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="分类" prop="category_id">
                            <gbpx-course-category-select
                                v-model="form.category_id"
                                :defaultLabel="form.category_name"
                                placeholder="请选择分类"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="简介" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入简介"
                                clearable
                                type="textarea"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-tabs v-model="activeTab">
                <el-tab-pane label="教师" name="teacher" :lazy="true">
                    <grid-select
                        ref="selectTeacherGrid"
                        :multiple="true"
                        placeholder="请选择教师"
                        width="960px"
                        :searchItems="[{ field: 'name', title: '姓名' }]"
                        :gridItems="[
                            { field: 'name', title: '姓名' },
                            { field: 'remark', title: '简介' },
                        ]"
                        :getDataList="listGbpxTeacher"
                        @change="handleSelectTeacher"
                    >
                        <el-button
                            style="margin-bottom: 10px"
                            type="primary"
                            icon="el-icon-plus"
                            size="mini"
                            @click="handleAddTeacher"
                            >添加</el-button
                        >
                        <span style="margin-left: 10px"
                            >教师数:{{ form.teachers.length }}</span
                        >
                    </grid-select>
                    <!-- <vxe-grid
                        resizable
                        stripe
                        :columns="teacherTableColumn"
                        :data="form.teachers"
                        :max-height="350"
                    >
                        <template #defaultopr="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeleteTeacher(row)"
                                >删除
                            </el-button>
                        </template>

                        <template #empty>
                            <span>
                                <p>暂无数据</p>
                            </span>
                        </template>
                    </vxe-grid> -->
                    <jf-table
                        :loading="loading"
                        :columns="teacherTableColumn"
                        :data="form.teachers"
                        :max-height="350"
                        :checkbox="false"
                        :pager="false"
                        :is-query="false"
                    >
                        <template slot="toolbar_btn_row" slot-scope="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeleteTeacher(row)"
                                >删除
                            </el-button>
                        </template>
                    </jf-table>
                </el-tab-pane>
            </el-tabs>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listGbpxCourse,
    getGbpxCourse,
    addGbpxCourse,
    editGbpxCourse,
    delGbpxCourse,
} from "@/api/gbpx/gbpx_course";
import { listGbpxTeacher } from "@/api/gbpx/gbpx_teacher";
export default {
    name: "gbpx-gbpxcourse-list-a",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                //{ field: "id", title: "id", minWidth: 120, fixed: "left" },
                { field: "name", title: "名称", minWidth: 120, is_query: true },
                { field: "category_name", title: "分类", minWidth: 120 },
                { field: "remark", title: "简介", minWidth: 120 },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                // 	field: "",
                // 	title: "操作",
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: { default: "defaultopr" },
                // },
            ],
            teacherTableColumn: [
                //{ field: "id", title: "id", minWidth: 60, fixed: "left" },
                { field: "teacher.name", title: "教师", minWidth: 60 },
                { field: "teacher.remark", title: "简介", minWidth: 120 },
                {
                    field: "",
                    title: "操作",
                    width: 80,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
                category_id: undefined,
                teacher_id: undefined,
            },
            form: {
                teachers: [],
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                category_id: [
                    {
                        required: true,
                        message: "分类不能为空",
                        trigger: "blur",
                    },
                ],
                teacher_id: [
                    {
                        required: true,
                        message: "教师不能为空",
                        trigger: "blur",
                    },
                ],
            },
            listGbpxTeacher: listGbpxTeacher,
            activeTab: "teacher",
        };
    },
    created() {
        this.getList();
    },
    methods: {
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listGbpxCourse(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.form = {
                name: undefined,
                category_id: undefined,
                teacher_id: undefined,
                remark: undefined,
                teachers: [],
            };
            this.resetForm("form");
        },

        handleQuery(e) {
            if (e) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleAddTeacher() {
            this.$refs.selectTeacherGrid.open();
        },

        handleSelectTeacher(e1, e2) {
            for (let i = 0; i < e2.length; i++) {
                const teacher = e2[i];
                let exist = false;
                for (let j = 0; j < this.form.teachers.length; j++) {
                    if (this.form.teachers[j].teacher_id == teacher.id) {
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    this.form.teachers.push({
                        teacher_id: teacher.id,
                        teacher: teacher,
                    });
                }
            }
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getGbpxCourse(id).then((response) => {
                if (!response.data.teachers) {
                    response.data.teachers = [];
                }
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editGbpxCourse(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addGbpxCourse(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm("确定删除吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delGbpxCourse(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        handleDeleteTeacher(row) {
            this.$confirm("确定删除" + row.teacher.name + "?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.form.teachers.splice(
                    this.form.teachers.map((row) => row.id).indexOf(row.id),
                    1
                );
            });
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
    },
};
</script>
