<template>
    <div class="step-two">
        <div class="tip">数据预览最多显示1000条数据</div>
        <div class="table-container">
        <el-table
           ref="multipleTable" 
          :data="tableData"
          :border="true"
          style="width: 100%"
          :height="height"
          :key="timeStamp"
          :header-cell-style="{
            background: '#EBEEF5',
            'border-right': '1px solid #dddddd',
          }"
          align="center"
        >
          <el-table-column label="序号" type="index" width="50" align="center" fixed="left" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="number" label="学号" width="110" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center" width="90" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="gender" label="性别" width="50" align="center">
          </el-table-column>
          <el-table-column prop="academy" label="院系名称" width="110" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="major" label="专业" width="100" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="content" label="预约内容" align="center" min-width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="预约状态" align="center" width="80">
            <template slot-scope="scope">
              <el-link :underline="false" :class="scope.row.status">{{
                  scope.row.statusStr
              }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="时间" align="center" width="160" show-overflow-tooltip>
          </el-table-column>
        </el-table>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        
    },
    name: "StepTwo",
    data() {
        return {
            tableData: [],
            height: '300px',
            timeStamp: 0,
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.tableData = [
                {
                    'no': 1,
                    'number': '86786',
                    'name': '不卡就是',
                    'gender': '女',
                    'content': '@csentence(10,20)',
                    'statusStr': '已通过',
                    'time': '@date(yyyy-MM-dd HH:mm:ss)',
                    'academy': '@ctitle(2,4)学院',
                    'major': '财务管理'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.step-two {
    position: relative;
}
.table-container {
    margin-top: 20px;
    overflow: scroll;
}
</style>