import request from '@/utils/request'

// 添加设备群组
export function addIotDeviceGroup(data) {
    return request({
      url: '/iot/device/group/add',
      method: 'post',
      data: data
    })
}

// 获取设备群组
export function getIotDeviceGroup(id) {
    return request({
      url: '/iot/device/group/get?id=' + id,
      method: 'get'
    })
}

// 更新设备群组
export function updateIotDeviceGroup(data) {
    return request({
      url: '/iot/device/group/update',
      method: 'post',
      data: data
    })
  }
  
// 删除设备群组
export function delIotDeviceGroup(id) {
    return request({
      url: '/iot/device/group/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 绑定设备群组
export function bindIotDeviceGroup(gid,id) {
  return request({
    url: '/iot/device/group/bind',
    method: 'post',
    data: {
      ids:id,
      group_id:gid
    }
  })
}
// 绑定设备群组
export function unbindIotDeviceGroup(gid,id) {
  return request({
    url: '/iot/device/group/unbind',
    method: 'post',
    data: {
      ids:id,
      group_id:gid
    }
  })
}
// 设备列表群组
export function listIotDeviceGroup(query) {
  return request({
    url: '/iot/device/group/list',
    method: 'get',
    params: query
  })
}