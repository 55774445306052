import request from '@/utils/request'

// 添加资源空间任务
export function addIotSpaceCommand(data) {
    return request({
      url: '/iot/spacecommand/add',
      method: 'post',
      data: data
    })
}

// 获取资源空间任务
export function getIotSpaceCommand(id) {
    return request({
      url: '/iot/spacecommand/get?id=' + id,
      method: 'get'
    })
}

// 更新资源空间任务
export function updateIotSpaceCommand(data) {
    return request({
      url: '/iot/spacecommand/update',
      method: 'post',
      data: data
    })
  }
  
// 删除资源空间任务
export function delIotSpaceCommand(id) {
    return request({
      url: '/iot/spacecommand/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 资源空间任务列表
export function listIotSpaceCommand(query) {
  return request({
    url: '/iot/spacecommand/list',
    method: 'get',
    params: query
  })
}
