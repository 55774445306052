<!-- 生成时间:2022-04-09 13:00:16 -->

<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
    >
      <el-form-item label="学院名称" prop="dept_name">
        <el-input
          v-model="queryParams.dept_name"
          placeholder="请输入学院名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="学号" prop="stuno">
        <el-input
          v-model="queryParams.stuno"
          placeholder="请输入学号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="学生姓名" prop="stuname">
        <el-input
          v-model="queryParams.stuname"
          placeholder="请输入学生姓名"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="指标项目名称" prop="index_name">
        <el-input
          v-model="queryParams.index_name"
          placeholder="请输入指标项目名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="完成时间" prop="finishtime">
        <el-date-picker
          clearable
          size="small"
          style="width: 200px"
          v-model="queryParams.finishtime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择完成时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="加分值" prop="score">
        <el-input
          v-model="queryParams.score"
          placeholder="请输入加分值"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="相关情况说明" prop="note">
        <el-input
          v-model="queryParams.note"
          placeholder="请输入相关情况说明"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          type="cyan"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      stripe
      show-overflow
      highlight-hover-row
      :loading="loading"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="dataList"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
    >
      <template #toolbar_buttons>
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="[
            'growthindicators:extrascore:get',
            'growthindicators:extrascore:edit',
          ]"
          >修改</el-button
        >

        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['growthindicators:extrascore:del']"
          >删除</el-button
        >
        <el-select
          v-model="datafiltersel"
          filterable
          placeholder="数据过滤"
          @change="handledatafilterChange"
        >
          <el-option
            v-for="item in datafilter"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
         <el-button
          v-if="row.flow_inst_status == 0"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          >修改
        </el-button>

        <el-button
          v-if="row.flow_inst_status == 1"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          >查看审核
        </el-button>

        <el-button
          v-if="row.flow_inst_status == 2"
          size="mini"
          type="text"
          icon="el-icon"
 
          >审核已通过
        </el-button>

        <el-button
          v-if="row.flow_inst_status == 0"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleBpmStart(row)"
          >提交审核
        </el-button>
        <el-button
          v-if="row.flow_inst_status == 0"
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
        <span>
          <p>暂无数据</p>
        </span>
      </template>
    </vxe-grid>

    <vxe-pager
      background
      size="small"
      :loading="loading"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      :total="total"
      @page-change="handleSizeChange"
    >
    </vxe-pager>

    <!-- 添加或修改对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="学号" prop="stuno">
          <el-input v-model="form.stuno" placeholder="请输入学号" />
        </el-form-item>
        <el-form-item label="学生姓名" prop="stuname">
          <el-input v-model="form.stuname" placeholder="请输入学生姓名" />
        </el-form-item>
        <el-form-item label="指标项目名称" prop="index_name">
          <el-input
            v-model="form.index_name"
            placeholder="请输入指标项目名称"
          />
        </el-form-item>
        <el-form-item label="完成时间" prop="finishtime">
          <el-date-picker
            clearable
            size="small"
            style="width: 200px"
            v-model="form.finishtime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择完成时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="加分值" prop="score">
          <el-input v-model.number="form.score" placeholder="请输入加分值" />
        </el-form-item>
        <el-form-item label="佐证材料" prop="docfiles">
          <el-input v-model="form.docfiles" placeholder="请输入佐证材料" />
        </el-form-item>
        <el-form-item label="相关情况说明" prop="note">
          <el-input v-model="form.note" placeholder="请输入相关情况说明" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <div v-if="form.flow_inst_status == 1">
          <el-select
            v-model="opinion"
            filterable
            placeholder="请选择常用语"
            @change="handleOpinionChange"
          >
            <el-option
              v-for="item in opinions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入处理意见"
            resize="none"
            v-model="submitcomment"
            maxlength="100"
            show-word-limit
          >
          </el-input>
          <el-button type="primary" @click="doSubmit('submit')">提交</el-button>
          <el-button @click="cancel">返 回</el-button>
        </div>

        <div v-if="form.flow_inst_status == 0">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">返 回</el-button>
        </div>
      </div>
    </el-dialog>

    <!--高级查询对话框-->
    <el-dialog
      title="高级查询"
      :visible.sync="advqueryopen"
      width="1300px"
      append-to-body
    >
      <el-row :gutter="20">
        <el-col :span="15" :xs="24">
          <vxe-grid
            border
            resizable
            keep-source
            ref="xAdvqueryGrid"
            id="id_xAdvqueryGrid"
            height="500"
            :columns="advqueryColumn"
            :toolbar-config="advqueryToolbar"
            :data="advqueryData"
            :custom-config="{ storage: true }"
            :edit-config="{ trigger: 'click', mode: 'row', showStatus: true }"
            @toolbar-button-click="advqtoolbarButtonClickEvent"
          >
            <template #defaultadvqueryopr="{ row }"> </template>
          </vxe-grid>
        </el-col>
        <el-col :span="9" :xs="24">
          <vxe-grid
            border
            resizable
            keep-source
            ref="xAdvqueryGrid_tempfilter"
            id="id_xAdvqueryGrid_tempfilter"
            height="500"
            :columns="advqueryColumn_tempfilter"
            :data="advqueryData_tempfilter"
          >
            <template #defaultadvqueryopr_tempfilter="{ row }">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleadvqueryApplytpl(row)"
                >应用
              </el-button>

              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleadvqueryDeltpl(row)"
                >删除
              </el-button>
            </template>
          </vxe-grid>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="advquery_save">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <!--保存模版-->
    <el-dialog
      :close-on-click-modal="false"
      title="保存模版"
      :visible.sync="advquery_tempsave"
      width="800px"
      append-to-body
    >
      <el-form
        ref="advquery_tempsaveform"
        :model="advquery_tempsaveform"
        :rules="advquery_tempsaverules"
        label-width="80px"
      >
        <el-form-item label="模版名称" prop="tempname">
          <el-input
            v-model="advquery_tempsaveform.tempname"
            placeholder="请输入模版名称"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="advquery_save">确 定</el-button>
      </div>
    </el-dialog>

    <!--导出数据-->
    <el-dialog
      :close-on-click-modal="false"
      title="导出数据"
      :visible.sync="exportdialog"
      width="800px"
      append-to-body
    >
      <el-checkbox
        :indeterminate="exportisIndeterminate"
        v-model="exportcheckAll"
        @change="handleexportCheckAllChange"
        >全选</el-checkbox
      >
      <div style="margin: 15px 0"></div>

      <el-checkbox-group v-model="exportfields">
        <el-checkbox
          v-for="field in tableColumn"
          v-if="field.field"
          :label="field.field"
          :key="field.field"
          >{{ field.title }}</el-checkbox
        >
      </el-checkbox-group>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doExport">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { getAccessToken } from '@/utils/tokenTool'
import {
  listExtrascore,
  getExtrascore,
  delExtrascore,
  addExtrascore,
  editExtrascore,
  advquerydelExtrascore,
  advquerysaveExtrascore,
  advquerylistExtrascore,
  exportExtrascore,
} from "@/api/growthindicators/extrascore";
// import VDistpicker from "v-distpicker";
export default {
  components: {},
  name: "extrascore",

  data() {
    return {
      dialogImageUrl: "",
      submitcomment: "",
      opinions: [
        { value: "1", label: "同意" },
        { value: "3", label: "不同意" },
        { value: "4", label: "材料不齐全" },
      ],
      datafiltersel: "2",
      datafilter: [
        {
          label: "全部",
          value: "1",
        },
        {
          label: "审核通过",
          value: "4",
        },
        {
          label: "待提交",
          value: "2",
        },
        {
          label: "待我审核",
          value: "3",
        },
      ],

      businessname: "extrascore", //请勿改动
      exportfields: [],
      exportcheckAll: false,
      exportisIndeterminate: false,
      exportdialog: false,

      //编辑器配置
      ueConfig: {
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
        enableAutoSave: true,
        elementPathEnable: false,
        wordCount: false,
        UEDITOR_HOME_URL: "/ueditor/",
      },
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },

      // 列
      tableColumn: [
        { type: "checkbox", width: 60, fixed: "left" },
        { field: "id", title: "id", minWidth: 80, fixed: "left" },
        { field: "dept_name", title: "学院名称", minWidth: 80 },
        { field: "stuno", title: "学号", minWidth: 80 },
        { field: "stuname", title: "学生姓名", minWidth: 80 },
        { field: "index_name", title: "指标项目名称", minWidth: 80 },
        {
          field: "finishtime",
          title: "完成时间",
          minWidth: 80,
          formatter: "formatDate",
        },
        { field: "score", title: "加分值", minWidth: 80 },
        { field: "note", title: "相关情况说明", minWidth: 80 },
        {
          field: "create_time",
          title: "创建时间",
          minWidth: 80,
          formatter: "formatDate",
        },
        {
          field: "update_time",
          title: "更新时间",
          minWidth: 80,
          formatter: "formatDate",
        },

        {
          field: "",
          title: "操作",
          width: 220,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],

      advqueryColumn: [
        {
          field: "qfield",
          title: "查询字段",
          width: 180,
          fixed: "left",
          editRender: {
            name: "select",
            options: [
              { value: "dd", label: "cxvs" },
              { value: "zz", label: "cxvs" },
            ],
          },
        },
        {
          field: "qopr",
          title: "操作符",
          width: 180,
          fixed: "left",
          editRender: { name: "select", options: [] },
        },
        {
          field: "qvalue",
          title: "值",
          width: 180,
          fixed: "left",
          editRender: { name: "input" },
        },
        {
          field: "qlogic",
          title: "逻辑",
          width: 180,
          fixed: "left",
          editRender: { name: "select", options: [] },
        },
        {
          field: "",
          title: "操作",
          resizable: false,
          width: 110,
          fixed: "right",
          align: "center",
          slots: { default: "defaultadvqueryopr" },
        },
      ],
      advqueryColumn_tempfilter: [
        { field: "tempname", title: "模版名称", width: 200, fixed: "left" },
        {
          field: "",
          title: "操作",
          resizable: false,
          width: 180,
          fixed: "right",
          align: "center",
          slots: { default: "defaultadvqueryopr_tempfilter" },
        },
      ],
      advqueryData_tempfilter: [],
      advqueryopen: false,
      advquery_tempsave: false,
      advqueryData: [],
      advqueryToolbar: {
        buttons: [
          { code: "myInsert", name: "新增" },
          { code: "dofilter", name: "使用此组合条件查询" },
        ],
      },
      upload: {
        headers: { Authorization: getAccessToken() },
        url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
      },
      dialogVisible: false,

      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 字典

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        opstr: {
          dept_name: "LIKE",
          stuno: "=",
          stuname: "LIKE",
          index_name: "LIKE",
          finishtime: "=",
          score: "=",
          note: "=",
        },
        dept_name: undefined,
        stuno: undefined,
        stuname: undefined,
        index_name: undefined,
        finishtime: undefined,
        score: undefined,
        note: undefined,
      },
      // 表单参数
      form: {},
      advquery_tempsaveform: {},
      advquery_tempsaverules: {
        tempname: [
          { required: true, message: "模版名称不能为空", trigger: "blur" },
        ],
      },
      // 表单校验
      rules: {},
    };
  },
  created() {
    this.getList();

    setTimeout(() => {
      const $grid = this.$refs.xAdvqueryGrid;
      // 异步更新下拉选项
      var ta = [{ value: "", label: "请选择" }];
      for (var i in this.tableColumn) {
        var e = this.tableColumn[i];
        if (e.field != null && e.field != "") {
          ta.push({ value: e.field, label: e.title });
        }
      }
      this.advqueryColumn[0].editRender.options = ta;

      this.advqueryColumn[1].editRender.options = this.defaultQlogic();

      ta = [];
      ta.push({ value: "AND", label: "并且" });
      ta.push({ value: "OR", label: "或者" });
      this.advqueryColumn[3].editRender.options = ta;
    }, 300);
    // 加载字典数据
  },
  methods: {
    handleOpinionChange(value) {
      if (value != "") {
        this.submitcomment = value;
      }
    },
    handledatafilterChange(value) {
      console.log("value", value);
      if (value != "") {
        this.datafiltersel = value;
      }
      if (value == "2") {
        this.queryParams.flow_inst_status = "0";
      } else if (value == "4") {
        this.queryParams.flow_inst_status = "2";
      }
      else {
        this.queryParams.flow_inst_status = undefined;
        if (value == "3") {
          this.queryParams.extfilter = "3";
        } else {
          this.queryParams.extfilter = "";
        }
      }
      this.getList();
    },

    handleBpmStart(row) {
      this.$confirm("是否确认提交审核?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var starttime = this.$route.query.time || this.XEUtils.now();
          // console.log("startProcess", startProcess);
          startProcess({
            flowId: 48,
            code: "submit",
            dataId: row.id,
            time: starttime,
            title: `${this.$store.state.user.name} 在 ${this.XEUtils.toDateString(
              starttime
            )} 提交了 [加分申报审核]`,
            resourceId: 102,
            vars: "{}",
          }).then((response) => {
            // console.log(response);
            //let ret = response.data
            this.msgSuccess("发起流程成功");
            // this.$store.dispatch("tagsView/delView", this.$route);
            // this.$router.push({ path: "/bpm/process/myList" });
            return afterBpmAction({
              resource_id: 102,
              data_id: "" + row.id,
              code: "start",
            });
          });
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
        })
        .catch(function () {}); 
    },
    doSubmit(code) {
      completeTask("submit", {
        code: "submit",
        comment: this.submitcomment,
        candidates: null,
        ccs: null,
        resourceId: 100,
        processId: this.form.flow_inst_id,
        taskId: this.form.task_id,
        vars: "{}",
      }).then(() => {
        afterBpmAction({
          resource_id: 100,
          data_id: ''+this.form.id,
          code: "submit",
        });
        this.msgSuccess("提交成功");
        this.open = false;
        setTimeout(() => {
          this.getList();
        }, 300);
      });
    },

    //区域选择事件

    //表单上传字段成功处理
    handleImportSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      var field = response.data.return_field;

      this.form[field] = this.form[field] || [];
      this.form[field].push(response.data.url);
      this[field + "_fileList"].push({ url: response.data.url });
    },
    handleUpload() {},
    handleExport() {
      if (this.total > 0) {
        this.exportdialog = true;
      } else {
        this.msgError("暂无数据可导出!");
      }
    },
    doExport() {
      const queryParams = this.queryParams;
      this.$confirm("是否确认导出?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return exportExtrascore(
            queryParams,
            this.exportfields,
            this.tableColumn
          );
        })
        .then((response) => {
          this.download(response.file);
        })
        .catch(function () {});
    },
    handleexportCheckAllChange(val) {
      this.exportfields = val
        ? this.tableColumn.map((item) => {
            return item.field;
          })
        : [];
      this.exportisIndeterminate = false;
    },
    //删除查询模版
    handleadvqueryDeltpl(row) {
      var ids = row.id;
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          advquerydelExtrascore(ids)
            .then(() => {
              const $grid = this.$refs.xAdvqueryGrid_tempfilter;
              $grid.remove(row);
            })
            .then(() => {
              this.msgSuccess("删除成功");
            });
        })
        .then(() => {
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    //打开高级查询
    handleAdvquery() {
      advquerylistExtrascore(this.businessname).then((response) => {
        this.advqueryData_tempfilter = response.data.list;
        this.advqueryopen = true;
      });
    },
    //保存条件
    advquery_save() {
      const $grid = this.$refs.xAdvqueryGrid;
      var alldata = $grid.getTableData();
      if (alldata.fullData.length == 0) {
        return false;
      }

      var opstr = {};
      var filteroptview = [];
      var Qlogicar = this.defaultQlogic();
      var queryParams = {};
      var fields = {},
        Qlogic = {};

      for (var i in this.tableColumn) {
        var col = this.tableColumn[i];
        if (col.field != null && col.field != "") {
          fields[col.field] = col.title;
        }
      }

      for (var i in Qlogicar) {
        var lgc = Qlogicar[i];
        Qlogic[lgc.value] = lgc.label;
      }

      for (var i in alldata.fullData) {
        var onerow = alldata.fullData[i];
        queryParams[onerow.qfield] = onerow.qvalue;
        opstr[onerow.qfield] = onerow.qopr;
        filteroptview.push(
          fields[onerow.qfield] + " " + Qlogic[onerow.qopr] + "" + onerow.qvalue
        );
      }

      queryParams.opstr = opstr;
      this.advquery_tempsaveform["businessname"] = this.businessname;
      this.advquery_tempsaveform["filteropt"] = JSON.stringify(queryParams);
      this.advquery_tempsaveform["filteroptview"] = filteroptview.join(";");

      this.$refs["advquery_tempsaveform"].validate((valid) => {
        if (valid) {
          advquerysaveExtrascore(this.advquery_tempsaveform).then(
            (response) => {
              if (response.code === 0) {
                this.msgSuccess("保存成功");
                this.advquery_tempsave = false;
                this.handleAdvquery();
              }
            }
          );
        }
      });
    },

    //应用查询模版
    handleadvqueryApplytpl(row) {
      var Filteropt = JSON.parse(row.filteropt);

      Filteropt["pageNum"] = 1;
      Filteropt["pageSize"] = 10;
      this.queryParams = Filteropt;
      this.advqueryopen = false;
      setTimeout(() => {
        this.getList();
      }, 300);
    },
    //删除临时条件
    handleadvqueryDeleted(row) {
      const $grid = this.$refs.xAdvqueryGrid;

      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          $grid.remove(row);
        })
        .then(() => {
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    advqtoolbarButtonClickEvent({ code }) {
      const $grid = this.$refs.xAdvqueryGrid;
      switch (code) {
        case "myInsert":
          var row = $grid.insert({
            qfield: "",
            qopr: "=",
            qlogic: "AND",
          });

          $grid.setActiveRow($grid.getData(0));
          break;

        case "dosavefilter":
          var alldata = $grid.getTableData();
          if (alldata.fullData.length == 0) {
            return false;
          }
          this.advquery_tempsave = true;
          break;
        case "dofilter":
          var alldata = $grid.getTableData();
          if (alldata.fullData.length == 0) {
            return false;
          }

          var opstr = {};

          var queryParams = {
            pageNum: 1,
            pageSize: 10,
            opstr: {},
          };

          for (var i in alldata.fullData) {
            var onerow = alldata.fullData[i];
            queryParams[onerow.qfield] = onerow.qvalue;
            opstr[onerow.qfield] = onerow.qopr;
          }

          queryParams.opstr = opstr;
          this.queryParams = queryParams;
          this.advqueryopen = false;
          setTimeout(() => {
            this.getList();
          }, 300);
          break;
      }
    },

    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },

    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },

    /** 查询列表 */
    getList() {
      this.loading = true;

      listExtrascore(this.queryParams).then((response) => {
        this.dataList = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    // $comment字典翻译

    // 取消按钮
    cancel() {
      this.open = false;
      this.advqueryopen = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        dtype: undefined,
        stuno: undefined,
        stuname: undefined,
        index_id: undefined,
        index_name: undefined,
        finishtime: undefined,
        score: undefined,
        docfiles: undefined,
        note: undefined,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      getExtrascore(id).then((response) => {
        this.form = response.data.data;
        this.form.finishtime = this.unixTimestamptoDate(this.form.finishtime);

        this.open = true;
        this.title = "修改";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.finishtime = this.DatetounixTimestamp(this.form.finishtime);

          if (this.form.id != null) {
            editExtrascore(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          } else {
            addExtrascore(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;

      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delExtrascore(ids);
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
  }, //methods结束
};
</script>