<template>
 <div>
   <el-row>
      <el-col :span="24" class="card-box">
        <el-card style="position: relative;">
          <div slot="header">            
            <tr style=" display:inline-block ;">              
              <td>
                <div style="display:inline-block;font-weight:bolder;">
                  同步命令下发             
                </div>
              </td>  
            </tr>
            <div style=" display:inline-block ; float:right">  
              <el-button                 
                style="margin-left: 10px"                              
                size="mini"
                @click="sySendCommand"
              >命令下发</el-button>
            </div>
            <div  style = "clear:both;" ></div >           
          </div>
          <div>
            <table style="width: 100%;">              
              <tbody>
                <tr >
                  <td><div class="cell" style="margin-top:10px;font-size:13px;color:#859094;">同步命令暂不支持历史记录查看。</div></td>                  
                  <td/>
                </tr>                  
              </tbody>
            </table>
          </div>        
                 
        </el-card>
      </el-col>
     <el-col :span="24" class="card-box" >
        <el-card style="height: 500px;">
          <div slot="header">
            <div style="display:inline-block;font-weight:bolder;">异步命令下发</div> 
            <div style=" display:inline-block ; float:right">  
              <el-button                 
                style="margin-left: 10px"                              
                size="mini"
                @click="asySendCommand"
              >命令下发</el-button>
            </div>
            <div  style = "clear:both;" ></div >   
          </div>
          <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">      
            <el-form-item label="命令状态" prop="status">
              <el-select v-model="queryParams.status" placeholder="命令状态" clearable size="small" @change="statusChange">
                <el-option
                  v-for="(item,key) in statusOptions"
                  :key="key"
                  :label="item.value"
                  :value="key"
                />
              </el-select>
            </el-form-item> 
            <el-form-item label="搜索命令" prop="command_name">
              <el-input
                v-model="queryParams.command_name"
                placeholder="请输入命令名称"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-form>
          <el-row/>
          <vxe-grid            
            stripe
            height="320px"
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="commandList"
            :column-config="{resizable: false}"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
            <template #toolbar_buttons >   
              <el-button
                style="margin-left: 10px"
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
              >删除</el-button>
            </template>

            <!--默认操作按钮-->
            <template #defaultopr="{ row }">              
              <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
              >删除
              </el-button>
            </template>

            <!--自定义空数据模板-->
            <template #empty>
                  <span >
                    <p>暂无数据</p>
                  </span>
            </template>

          </vxe-grid>
          <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
          </vxe-pager>
        </el-card>
      </el-col>      
    </el-row>

    <!-- 命令下发 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="选择命令" prop="command_id">
          <el-select ref="operateName" v-model="form.command_id" placeholder="请选择需要下发的命令" clearable  @change="commandChange">
            <el-option
              v-for="dict in serviceCommandList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
            />
          </el-select>
        </el-form-item> 
        <table  style="display:inline-block;width: 100%" cellspacing=0>             
          <tbody style="display:inline-block;margin-left:50px;">
            <tr v-for="(item,index) in sendcommandAttributeList" :key="index" style="width: 300px" >                  
              <td ><div class="cell"  >{{item.name}}</div></td><el-input v-model="sendcommandAttributeList[index].value" placeholder="值" style="display:inline-block;margin-left:20px;margin-top:5px;"/>
            </tr>
          </tbody>
        </table>
        <el-form-item v-if="asy==1"  label="下发策略" prop="is_now" style="margin-top: 20px">
          <el-select v-model="is_now" placeholder="请选择下发策略" clearable size="small">
            <el-option
              v-for="dict in sendmaster"                
              :key="dict.key"
              :label="dict.value"
              :value="dict.key"
              />
            </el-select>
        </el-form-item>
        <el-form-item v-if="is_now == 0 && asy==1" label="缓存时间(S)" prop="delay_time">
          <el-input v-model="form.delay_time" type="number"/>
        </el-form-item> 
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
 </div>
</template>

<script>
import { listIotProductService, getIotProductService, addIotProductService, updateIotProductService, delIotProductService } from "@/api/iot/productservice";
import { listIotServiceAttribute, getIotServiceAttribute, addIotServiceAttribute, updateIotServiceAttribute, delIotServiceAttribute ,delIotServiceAttributeByCommandId} from "@/api/iot/serviceattribute";
import { listIotServiceCommand, getIotServiceCommand, addIotServiceCommand, updateIotServiceCommand, delIotServiceCommand } from "@/api/iot/servicecommand";
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";
import { addIotDeviceCommand, getIotDeviceCommand, updateIotDeviceCommand, delIotDeviceCommand, listIotDeviceCommand } from "@/api/iot/devicecommand";
import { addIotDeviceAttribute, getIotDeviceAttribute, updateIotDeviceAttribute, delIotDeviceAttribute,delIotDeviceAttributeByCommandId, listIotDeviceAttribute } from "@/api/iot/deviceattribute";
import { publishMessage, subscripteMessage} from "@/api/iot/platform";
export default {
  props: {
    deviceId: {
      type: String
    }
  },
  data() {   
    return {
      device_id:0,
      product_id:0,
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: 'status', title: '状态', width: 80,formatter:this.ConnectFormat },
        {field: 'command_name', title: '命令名称' },
        {field: 'command_id', title: '命令ID' },
        {field: 'commandCreate_time', title: '命令创建时间' ,formatter:this.CreateFormat },
        {field: 'send_time', title: '平台发送时间',formatter:this.SendFormat  },
        {field: 'arrive_time', title: '送达时间',formatter:this.ArriveFormat  },
        {field: '', title: '操作',resizable:false, width: 60, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      title: "",
      asy:0,
      // 是否显示弹出层
      open: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        command_name: "",
        status: 0,
        device_id:0
      },
      isQueueList:[{key:0,value:"队列中的命令"},{key:1,value:"历史命令"}],
      sendmaster:[{key:0,value:"缓存下发"},{key:1,value:"立即下发"}],
      is_now:0,
      commandList:[],
      statusOptions:[],
      // 表单参数
      form: {},
      // 表单校验
      rules: {        
        command_id: [
          { required: true, message: "必须选择一个命令", trigger: "blur" }
        ]
      },
      sendcommandAttributeList:[],
      serviceCommandList:[],
      serviceCommandAttributeList:[]
      
    }
  }, 
  created() {   
    if(this.deviceId != undefined){
      this.device_id=parseInt(this.deviceId);
      this.queryParams.device_id=this.device_id;
      this.form.delay_time=86400
      this.getdevice();
      this.handleQuery();
    }else{
      this.msgError("获取设备信息失败！");
    }
    
  },
  methods: {
    getdevice(){
      if(this.device_id > 0){
        getIotDevice(this.device_id).then(response =>{
          if(response.code===0){
            this.product_id=response.data.product_id;  
            this.getServiceList();
            this.getCommandList();       

          }
        });        
      }      
    },
    getServiceList(){
      if(this.product_id>0){
          var queryParams={  
          pageNum: 1,
          pageSize: 10,
          product_id:this.product_id
        };
        listIotProductService(queryParams).then(response =>{          
          response.data.list.forEach(element => {
            var comqueryParams={
              pageNum: 1,
              pageSize: 10,
              service_id: element.id
            };
            listIotServiceCommand(comqueryParams).then(response2 =>{               
              response2.data.list.forEach(element2 =>{
                element2.oldname=element2.name;
                element2.service_name=element.service_name;
                element2.name=(element.service_name+":"+element2.name);
                this.serviceCommandList.push(element2);
                var sendattr={
                  pageNum: 1,
                  pageSize: 10,
                  service_id: element.id,
                  rule_id: -1,
                  command_id:element2.id
                };
                listIotServiceAttribute(sendattr).then(response3 =>{                  
                  response3.data.list.forEach(element3 =>{
                    element3.service_name=element.service_name;
                    this.serviceCommandAttributeList.push(element3);
                   });
                });
              });               
            });
          });
        });        
      }
      
    },
    //命令列表
    getCommandList(){
      listIotDeviceCommand(this.queryParams).then(response=>{        
          this.single = true;
          this.multiple = true;
          this.total = response.data.total;
          this.commandList = response.data.list;
          this.statusOptions = response.data.statusOptions.values;        
      })
    },
    //同步命令下发按钮
    sySendCommand(){
      this.title="下发命令";
      this.open=true;
      this.asy=0;
    },
    //异步命令下发按钮this.createtime=this.timestampToTime(this.form.create_time);
    asySendCommand(){
      this.title="下发命令";
      this.open=true;
      this.asy=1;
    },
    ConnectFormat({row}){      
      return this.selectDictLabel(this.statusOptions, row.status + '');
    },
    CreateFormat({row}){
      if(row.commandCreate_time>0)
        return this.timestampToTime(row.commandCreate_time);
      else
        return ""
    },
    SendFormat({row}){
      if(row.send_time>0)
        return this.timestampToTime(row.send_time);
       else
        return ""
    },
    ArriveFormat({row}){
      if(row.arrive_time>0)
        return this.timestampToTime(row.arrive_time);
       else
        return ""
    },   
    statusChange(){
      this.queryParams.pageNum = 1;      
      this.getCommandList();
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form={};
      this.form.delay_time=86400;
      this.sendcommandAttributeList=[];
      this.sendcommandAttributeList=new Array();
    },  
    commandChange(){
      this.sendcommandAttributeList=[];
      this.sendcommandAttributeList=new Array();
      this.serviceCommandAttributeList.forEach(element =>{
        element.value=""
        if(element.command_id==this.form.command_id)
          this.sendcommandAttributeList.push(element);
      }); 
           
    },
    submitForm: function() {      
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.command_id >0 ) {            
            this.form.device_id=this.device_id;
            this.form.command_name=this.$refs.operateName.selectedLabel;
            this.form.status=0;                  
            if(this.sendcommandAttributeList.length>0)
              this.form.attribute_id=0;
            else
              this.form.attribute_id=-1;   
            if(this.is_now==0)
              this.form.send_time=this.form.delay_time;
            else
              this.form.send_time=0;
            if(this.asy==1)
              addIotDeviceCommand(this.form).then(response => {
                if (response.code ===0) {                
                  this.sendcommandAttributeList.forEach(element =>{
                    element.device_id=this.device_id;
                    element.command_id=response.data.id;                  
                    addIotDeviceAttribute(element);
                  });
                  this.msgSuccess("添加成功");
                  this.sendPbulish();
                  this.open = false;
                  this.reset();
                  this.getCommandList();
                } else {
                  this.msgError(response.msg);
                }
              });
            else{
              this.sendPbulish();
            }
          }
        }
      });

    },
    sendPbulish(){
              var objdata={
                oldname:"",
                service_id:0,
                service_name:""
              };              
              this.serviceCommandList.forEach(element=>{
                if(this.form.command_id==element.id){
                  objdata=element;
                }
              });
              var paylosd={
                command_name:objdata.oldname,
                command_service_id:objdata.service_id,
                command_service_name:objdata.service_name,
                attribute_list:[],
                publish_time:0
              };
              if(this.sendcommandAttributeList.length>0){
                this.sendcommandAttributeList.forEach(element=>{
                  var attributeListone={
                    id:element.id,
                    attribute_name:element.name,
                    value:element.value,
                    service_id:element.service_id,
                    service_name:element.service_name
                  };
                  paylosd.attribute_list.push(attributeListone);
                });
                
              }else
                paylosd.attribute_list=[];

              if(this.is_now==0)
                this.form.send_time=this.form.delay_time;
              else
                this.form.send_time=0;
              var data={
                device_id:this.device_id,
                delay_time:this.form.send_time,
                topic:"command",
                payload:JSON.stringify(paylosd)
              }
              publishMessage(data).then(response=>{
                if (response.code ===0){
                  this.msgSuccess("下发成功");
                  this.open = false;
                  this.reset();
                }
              })
    },
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getCommandList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;      
      this.getCommandList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除命令编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          delIotDeviceAttributeByCommandId(ids);
          
        }).then(() => {
          delIotDeviceCommand(ids).then(resp=>{
            this.getCommandList();
            this.msgSuccess("删除成功");
          });          
        }).catch(function() {});
    },     
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
  }
}
</script>