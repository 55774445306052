export default {
  "widgetList": [
    {
      "type": "static-text",
      "icon": "static-text",
      "formItemFlag": false,
      "options": {
        "name": "statictext111193",
        "columnWidth": "200px",
        "hidden": false,
        "textContent": "分组表单",
        "customClass": [],
        "onCreated": "",
        "onMounted": "",
        "label": "static-text"
      },
      "id": "statictext111193"
    },
    {
      "type": "divider",
      "icon": "divider",
      "formItemFlag": false,
      "options": {
        "name": "divider102346",
        "label": "",
        "columnWidth": "200px",
        "direction": "horizontal",
        "contentPosition": "center",
        "hidden": true,
        "customClass": [],
        "onCreated": "",
        "onMounted": ""
      },
      "id": "divider102346"
    },
    {
      "type": "card",
      "category": "container",
      "icon": "card",
      "widgetList": [
        {
          "type": "grid",
          "category": "container",
          "icon": "grid",
          "cols": [
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "input",
                  "icon": "text-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "input12931",
                    "label": "发件人姓名",
                    "labelAlign": "",
                    "type": "text",
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "showPassword": false,
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": [],
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "prefixIcon": "",
                    "suffixIcon": "",
                    "appendButton": false,
                    "appendButtonDisabled": false,
                    "buttonIcon": "el-icon-search",
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "input12931"
                }
              ],
              "options": {
                "name": "gridCol25469",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-25469"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "input",
                  "icon": "text-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "input23031",
                    "label": "发件人号码",
                    "labelAlign": "",
                    "type": "text",
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "showPassword": false,
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "prefixIcon": "",
                    "suffixIcon": "",
                    "appendButton": false,
                    "appendButtonDisabled": false,
                    "buttonIcon": "el-icon-search",
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "input23031"
                }
              ],
              "options": {
                "name": "gridCol25125",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-25125"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "switch",
                  "icon": "switch-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "switch96070",
                    "label": "是否保密",
                    "labelAlign": "",
                    "defaultValue": true,
                    "columnWidth": "200px",
                    "labelWidth": null,
                    "labelHidden": false,
                    "disabled": false,
                    "hidden": false,
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "switchWidth": 40,
                    "activeText": "",
                    "inactiveText": "",
                    "activeColor": null,
                    "inactiveColor": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onValidate": ""
                  },
                  "id": "switch96070"
                }
              ],
              "options": {
                "name": "gridCol38707",
                "hidden": false,
                "span": 24,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-38707"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "textarea",
                  "icon": "textarea-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "textarea21654",
                    "label": "发件人地址",
                    "labelAlign": "",
                    "rows": 3,
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "textarea21654"
                }
              ],
              "options": {
                "name": "gridCol36174",
                "hidden": false,
                "span": 24,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-36174"
            }
          ],
          "options": {
            "name": "grid35834",
            "hidden": false,
            "gutter": 12,
            "customClass": ""
          },
          "id": "grid35834"
        }
      ],
      "options": {
        "name": "card27350",
        "label": "发件信息",
        "hidden": false,
        "folded": false,
        "showFold": true,
        "cardWidth": "100%",
        "shadow": "never",
        "customClass": ""
      },
      "id": "card27350"
    },
    {
      "type": "divider",
      "icon": "divider",
      "formItemFlag": false,
      "options": {
        "name": "divider69240",
        "label": "",
        "columnWidth": "200px",
        "direction": "horizontal",
        "contentPosition": "center",
        "hidden": true,
        "customClass": "",
        "onCreated": "",
        "onMounted": ""
      },
      "id": "divider69240"
    },
    {
      "type": "card",
      "category": "container",
      "icon": "card",
      "widgetList": [
        {
          "type": "grid",
          "category": "container",
          "icon": "grid",
          "cols": [
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "input",
                  "icon": "text-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "input113152",
                    "label": "收件人姓名",
                    "labelAlign": "",
                    "type": "text",
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "showPassword": false,
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": [],
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "prefixIcon": "",
                    "suffixIcon": "",
                    "appendButton": false,
                    "appendButtonDisabled": false,
                    "buttonIcon": "el-icon-search",
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "input113152"
                }
              ],
              "options": {
                "name": "gridCol47242",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-47242"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "input",
                  "icon": "text-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "input40240",
                    "label": "收件人号码",
                    "labelAlign": "",
                    "type": "text",
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "showPassword": false,
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "prefixIcon": "",
                    "suffixIcon": "",
                    "appendButton": false,
                    "appendButtonDisabled": false,
                    "buttonIcon": "el-icon-search",
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "input40240"
                }
              ],
              "options": {
                "name": "gridCol27970",
                "hidden": false,
                "span": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-27970"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "checkbox",
                  "icon": "checkbox-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "checkbox63174",
                    "label": "接收时间段",
                    "labelAlign": "",
                    "defaultValue": [],
                    "columnWidth": "200px",
                    "size": "",
                    "displayStyle": "inline",
                    "buttonStyle": false,
                    "border": false,
                    "labelWidth": null,
                    "labelHidden": false,
                    "disabled": false,
                    "hidden": false,
                    "optionItems": [
                      {
                        "label": "上午9:00 - 11:30",
                        "value": 1
                      },
                      {
                        "label": "下午12:30 - 18:00",
                        "value": 2
                      },
                      {
                        "label": "晚上18:00 - 21:00",
                        "value": 3
                      }
                    ],
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onValidate": ""
                  },
                  "id": "checkbox63174"
                }
              ],
              "options": {
                "name": "gridCol93630",
                "hidden": false,
                "span": 24,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-93630"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "input",
                  "icon": "text-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "input78584",
                    "label": "收件人地址",
                    "labelAlign": "",
                    "type": "text",
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "showPassword": false,
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "prefixIcon": "",
                    "suffixIcon": "",
                    "appendButton": false,
                    "appendButtonDisabled": false,
                    "buttonIcon": "el-icon-search",
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "input78584"
                }
              ],
              "options": {
                "name": "gridCol65188",
                "hidden": false,
                "span": 24,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-65188"
            }
          ],
          "options": {
            "name": "grid114672",
            "hidden": false,
            "gutter": 12,
            "customClass": ""
          },
          "id": "grid114672"
        }
      ],
      "options": {
        "name": "card111229",
        "label": "收件信息",
        "hidden": false,
        "folded": false,
        "showFold": true,
        "cardWidth": "100%",
        "shadow": "never",
        "customClass": ""
      },
      "id": "card111229"
    },
    {
      "type": "divider",
      "icon": "divider",
      "formItemFlag": false,
      "options": {
        "name": "divider75887",
        "label": "",
        "columnWidth": "200px",
        "direction": "horizontal",
        "contentPosition": "center",
        "hidden": true,
        "customClass": [],
        "onCreated": "",
        "onMounted": ""
      },
      "id": "divider75887"
    },
    {
      "type": "card",
      "category": "container",
      "icon": "card",
      "widgetList": [
        {
          "type": "grid",
          "category": "container",
          "icon": "grid",
          "cols": [
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "time-range",
                  "icon": "time-range-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "timerange47503",
                    "label": "送货时间",
                    "labelAlign": "",
                    "defaultValue": null,
                    "startPlaceholder": "",
                    "endPlaceholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "editable": false,
                    "format": "HH:mm:ss",
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "timerange47503"
                }
              ],
              "options": {
                "name": "gridCol109912",
                "hidden": false,
                "span": 24,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-109912"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "slider",
                  "icon": "slider-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "slider54714",
                    "label": "价格保护",
                    "labelAlign": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "disabled": false,
                    "hidden": false,
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": [],
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "min": 0,
                    "max": 100,
                    "step": 10,
                    "range": false,
                    "height": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onValidate": "",
                    "showStops": true
                  },
                  "id": "slider54714"
                }
              ],
              "options": {
                "name": "gridCol114653",
                "hidden": false,
                "span": 24,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-114653"
            },
            {
              "type": "grid-col",
              "category": "container",
              "icon": "grid-col",
              "internal": true,
              "widgetList": [
                {
                  "type": "textarea",
                  "icon": "textarea-field",
                  "formItemFlag": true,
                  "options": {
                    "name": "textarea64794",
                    "label": "其他信息",
                    "labelAlign": "",
                    "rows": 3,
                    "defaultValue": "",
                    "placeholder": "",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": false,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "required": false,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": ""
                  },
                  "id": "textarea64794"
                }
              ],
              "options": {
                "name": "gridCol80867",
                "hidden": false,
                "span": 24,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "responsive": false,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "customClass": ""
              },
              "id": "grid-col-80867"
            }
          ],
          "options": {
            "name": "grid28709",
            "hidden": false,
            "gutter": 12,
            "customClass": ""
          },
          "id": "grid28709"
        }
      ],
      "options": {
        "name": "card106066",
        "label": "补充信息",
        "hidden": false,
        "folded": false,
        "showFold": true,
        "cardWidth": "100%",
        "shadow": "never",
        "customClass": ""
      },
      "id": "card106066"
    }
  ],
  "formConfig": {
    "modelName": "formData",
    "refName": "vForm",
    "rulesName": "rules",
    "labelWidth": 150,
    "labelPosition": "left",
    "size": "",
    "labelAlign": "label-right-align",
    "cssCode": "",
    "customClass": [],
    "functions": "",
    "layoutType": "PC",
    "onFormCreated": "",
    "onFormMounted": "",
    "onFormDataChange": ""
  }
}