<template>
    <div class="step-one">
        <el-upload
            class="upload-demo"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">点击或拖拽文件至虚线框内上传</div>
            <div slot="tip">
                <div class="el-upload__tip">
                    <i class="el-icon-download"></i>
                    请先下载 <strong class="strong-text" @click="downloadFormwork">导入模板</strong>，并按照规范示例导入数据后上传。
                </div>
                <div class="el-upload__tip-list">
                    上传的Excel符合以下规范
                    <ul>
                        <li>文件大小不超过10M，且单个sheet页数据量不超过5000行。当触发校验规则、关联业务规则和第三方服务回调时，单个sheet页数据量不超过5000行</li>
                        <li>仅支持(".cils和“.xlx)文件</li>
                        <li>请确保您需要导入的sheet表头中不包含空的单元格。否则该sheet页数据系统将不做导入</li>
                    </ul>
                </div>
            </div>
        </el-upload>
    </div>
</template>

<script>
export default {
    setup() {
        
    },
    name: "StepOne",
    methods: {
        downloadFormwork() {
            
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-demo {
    ::v-deep {
        .el-upload {
            width: 100%;
            &__tip {
                color: #000 !important;
                i {
                    font-weight: bolder;
                }
            }
            &__tip-list {
                font-size: 12px;
                margin-top: 30px;
                color: #606266;
                ul {
                    padding-inline-start: 20px !important;
                    margin-block-start: .5em !important;
                    li {
                        margin-bottom: 6px;
                        line-height: 18px;
                    }
                }
            }
        }
        .el-upload-dragger {
            width: 100%;
        }
    }
}
.strong-text {
    color: $base-color-default;
    cursor: pointer;
}
</style>