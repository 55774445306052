<template>
<div>
    <el-input :placeholder="placeholder" :size="size" :disabled="disabled"  :readonly="readonly" style="line-hight:40px" v-model="name" class="input-with-select">
      <el-button slot="append" :disabled="disabled"  :readonly="readonly" @click="showUserSelect" icon="el-icon-search"></el-button>
    </el-input>
    <user-select ref="userSelect" @doSubmit="selectUsersToInput" :limit="limit" :selectData="selectData"></user-select>
</div>
</template>
<script>
import userSelect from './UserSelect'
import { getUser } from "@/api/ucenter/user"; 
export default {
  data () {
    return {
      name: '',
      labelValue: this.value,
      selectData: []
    }
  },
  props: {
    //字段属性 用于回调方法方便取值
    field: {
      type: String,
      default: () => { return '' }
    },
    limit: Number,
    value: [String, Number],
    size: {
      type: String,
      default: () => { return 'small' }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    readonly: {
      type: Boolean,
      default: () => { return false }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    }
  },
  components: {
    userSelect
  },
  created () {

  },
  watch: {
    value: {
      handler (newVal) {
        this.selectData = []
        if (newVal) {
          newVal = newVal + ""
          newVal.split(',').forEach((id) => {
            getUser(id).then(({data}) => {
              if (data && data.id !== '') {
                this.selectData.push(data)
              }
            })
          })
        }
      },
      immediate: true,
      deep: false
    },
    selectData: {
      handler (newVal) {
        this.name = newVal.map(user => { return user.nickname }).join(',')
      },
      immediate: true,
      deep: false
    }
  },
  methods: {
    selectUsersToInput (users) {
      this.selectData = users
      let ids = users.map(user => { return user.id }).join(',')
      let names = users.map(user => { return user.nickname }).join(',')
      this.labelValue = ids
      this.name = names
      this.$emit('getValue', ids, names,this.field)//多返回一个属性
    },
    showUserSelect () {
      this.$refs.userSelect.init()
    }
  }
}
</script>
<style>
  .el-form-item__content .el-input-group {
      vertical-align: middle;
  }
 .el-tag + .el-tag {
    margin-left: 5px;
    margin-bottom: 5px;
  }
</style>


