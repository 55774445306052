var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-scrollbar',{staticClass:"side-scroll-bar"},[_c('div',{staticClass:"panel-container"},[_c('el-tabs',{staticClass:"no-bottom-margin indent-left-margin",model:{value:(_vm.firstTab),callback:function ($$v) {_vm.firstTab=$$v},expression:"firstTab"}},[_c('el-tab-pane',{attrs:{"name":"componentLib"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"el-icon-set-up"}),_vm._v(" "+_vm._s(_vm.i18nt("designer.componentLib")))]),_c('el-collapse',{staticClass:"widget-collapse",model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[(!_vm.designer.isTopic && _vm.containers.length)?_c('el-collapse-item',{attrs:{"name":"1","title":_vm.i18nt('designer.containerTitle')}},[_c('draggable',{attrs:{"tag":"ul","list":_vm.containers,"group":{
                                name: 'dragGroup',
                                pull: 'clone',
                                put: false,
                            },"clone":_vm.handleContainerWidgetClone,"ghost-class":"ghost","sort":false,"move":_vm.checkContainerMove},on:{"end":_vm.onContainerDragEnd}},_vm._l((_vm.containers),function(ctn,index){return _c('li',{key:index,staticClass:"container-widget-item",attrs:{"title":ctn.displayName},on:{"dblclick":function($event){return _vm.addContainerByDbClick(ctn)}}},[_c('span',[_c('svg-icon',{attrs:{"icon-class":ctn.icon,"class-name":"color-svg-icon"}}),_vm._v(_vm._s(_vm.getWidgetLabel(ctn)))],1)])}),0)],1):_vm._e(),(_vm.basicFields && _vm.basicFields.length)?_c('el-collapse-item',{attrs:{"name":"2","title":_vm.i18nt('designer.basicFieldTitle')}},[_c('draggable',{attrs:{"tag":"ul","list":_vm.basicFields,"group":{
                                name: 'dragGroup',
                                pull: 'clone',
                                put: false,
                            },"move":_vm.checkFieldMove,"clone":_vm.handleFieldWidgetClone,"ghost-class":"ghost","sort":false}},_vm._l((_vm.basicFields),function(fld,index){return _c('li',{key:index,staticClass:"field-widget-item",attrs:{"title":fld.displayName},on:{"dblclick":function($event){return _vm.addFieldByDbClick(fld)}}},[_c('span',[_c('svg-icon',{attrs:{"icon-class":fld.icon,"class-name":"color-svg-icon"}}),_vm._v(_vm._s(_vm.getWidgetLabel(fld)))],1)])}),0)],1):_vm._e(),(
                            !_vm.designer.isTopic &&
                            _vm.advancedFields &&
                            _vm.advancedFields.length
                        )?_c('el-collapse-item',{attrs:{"name":"3","title":_vm.i18nt('designer.advancedFieldTitle')}},[_c('draggable',{attrs:{"tag":"ul","list":_vm.advancedFields,"group":{
                                name: 'dragGroup',
                                pull: 'clone',
                                put: false,
                            },"move":_vm.checkFieldMove,"clone":_vm.handleFieldWidgetClone,"ghost-class":"ghost","sort":false}},_vm._l((_vm.advancedFields),function(fld,index){return _c('li',{key:index,staticClass:"field-widget-item",attrs:{"title":fld.displayName},on:{"dblclick":function($event){return _vm.addFieldByDbClick(fld)}}},[_c('span',[_c('svg-icon',{attrs:{"icon-class":fld.icon,"class-name":"color-svg-icon"}}),_vm._v(_vm._s(_vm.getWidgetLabel(fld)))],1)])}),0)],1):_vm._e(),(
                            !_vm.designer.isAddTopic &&
                            _vm.customFields &&
                            _vm.customFields.length
                        )?_c('el-collapse-item',{attrs:{"name":"4","title":_vm.i18nt('designer.customFieldTitle')}},[_c('draggable',{attrs:{"tag":"ul","list":_vm.customFields,"group":{
                                name: 'dragGroup',
                                pull: 'clone',
                                put: false,
                            },"move":_vm.checkFieldMove,"clone":_vm.handleFieldWidgetClone,"ghost-class":"ghost","sort":false}},_vm._l((_vm.customFields),function(fld,index){return _c('li',{key:index,staticClass:"field-widget-item",attrs:{"title":fld.displayName},on:{"dblclick":function($event){return _vm.addFieldByDbClick(fld)}}},[_c('span',[_c('svg-icon',{attrs:{"icon-class":fld.icon,"class-name":"color-svg-icon"}}),_vm._v(_vm._s(_vm.getWidgetLabel(fld)))],1)])}),0)],1):_vm._e()],1)],1),(_vm.showFormTemplates() && !_vm.designer.isTopic)?_c('el-tab-pane',{staticStyle:{"padding":"8px"},attrs:{"name":"formLib"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"el-icon-c-scale-to-original"}),_vm._v(" "+_vm._s(_vm.i18nt("designer.formLib")))]),_vm._l((_vm.formTemplates),function(ft,idx){return [_c('el-card',{key:idx,staticClass:"ft-card",attrs:{"bord-style":{ padding: '0' },"shadow":"hover"}},[_c('el-popover',{attrs:{"placement":"right","trigger":"hover"}},[_c('img',{staticStyle:{"width":"200px"},attrs:{"slot":"reference","src":ft.imgUrl},slot:"reference"}),_c('img',{staticStyle:{"height":"600px","width":"720px"},attrs:{"src":ft.imgUrl}})]),_c('div',{staticClass:"bottom clear-fix"},[_c('span',{staticClass:"ft-title"},[_vm._v("#"+_vm._s(idx + 1)+" "+_vm._s(_vm.getFTTitle(ft)))]),_c('el-button',{staticClass:"right-button",attrs:{"type":"text"},on:{"click":function($event){return _vm.loadFormTemplate(ft.jsonUrl, ft.jsonStr)}}},[_vm._v(" "+_vm._s(_vm.i18nt("designer.hint.loadFormTemplate")))])],1)],1)]})],2):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }