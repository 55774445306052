import request from '@/utils/request'




// 查询
export function Datalist (data) {
    return request({
        url: '/growthindicators/gyxy/achievementstandard/index',
        method: 'post',
        data: data
    })
}


//保存
export function Savedata(data) {
    return request({
        url: '/growthindicators/gyxy/achievementstandard/save',
        method: 'post',
        data: data
    })
}

