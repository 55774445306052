<template>
    <div>
        <el-form-item
            v-if="!showForm&&!designer.isTopic"
            :label="i18nt('designer.setting.dsConf_dstattr')"
        >
            <el-select
                v-model="optionModel.dsConf_dstattr"
                filterable
                clearable
                @change="getDataSetList"
                class="ds-conf-dstattr"
            >
                <el-option
                    v-for="(item, idx) in activeDstattrlist"
                    :key="idx"
                    :title="item"
                    :label="item"
                    :value="item"
                ></el-option>
            </el-select>
        </el-form-item>
        <template v-if="showForm && !!this.optionModel.dsEnabled">
            <el-form-item :label="i18nt('designer.setting.dsConf_dst_label')">
                <el-select
                    v-model="optionModel.dsConf_dst_label"
                    filterable
                    clearable
                    @change="getDataSetList"
                    class="ds-conf-dstattr"
                >
                    <el-option
                        v-for="(item, idx) in activeDstattrlist"
                        :key="idx"
                        :title="item"
                        :label="item"
                        :value="item"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="i18nt('designer.setting.dsConf_dst_value')">
                <el-select
                    v-model="optionModel.dsConf_dst_value"
                    filterable
                    clearable
                    @change="getDataSetList"
                    class="ds-conf-dst-value"
                >
                    <el-option
                        v-for="(item, idx) in activeDstattrlist"
                        :key="idx"
                        :title="item"
                        :label="item"
                        :value="item"
                    ></el-option>
                </el-select>
            </el-form-item>
        </template>
    </div>
</template>
<script>
import i18n from "@/vform/utils/i18n";
import { execModel, getModel } from "@/api/core/data/datasource/model";
export default {
    name: "data-source-attr-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    computed: {
        dataSourceList() {
            if (!this.optionModel.dsConf_dstid) {
                return [];
            } else {
                return this.activeDstattrlist;
            }
        },
        showForm() {
            let array = Object.keys(this.optionModel);
            // if (array.indexOf("dsName") > -1) {
            //     return !!this.optionModel.dsEnabled;
            // }
            return array.indexOf("dsName") > -1;
        },
    },
    data() {
        return {
            activeDstattrlist: [],
            DsData:[]
        };
    },
    created() {},
    watch: {
        "optionModel.dsConf_dstid": {
            handler: function () {
                if (this.optionModel.dsConf_dstid) {
                    this.dstchange(this.optionModel.dsConf_dstid);
                } else {
                    this.activeDstattrlist = [];
                }
                this.setTips();
            },
            // 立即以obj.name的当前值触发回调
            immediate: true,
        },
    },

    methods: {
        setTips() {
            if (!this.optionModel.dsConf_dstid) {
                this.$nextTick(() => {
                    let text = document.querySelector(
                        ".ds-conf-dstattr .el-select-dropdown__empty"
                    );
                    if (text) {
                        text.innerHTML = "请先指定数据源";
                    }
                    if (this.showForm) {
                        let text2 = document.querySelector(
                            ".ds-conf-dst-value .el-select-dropdown__empty"
                        );
                        if (text2) {
                            text2.innerHTML = "请先指定数据源";
                        }
                        this.optionModel.dsConf_dst_label = "";
                        this.optionModel.dsConf_dst_value = "";
                    }
                });
            }
        },
        getDataSetList(e) {
        
           
        },
        dstchange(value) {
            console.log("dstchange", value);
            this.activeDstattrlist = [];

            // if (this.activeData.dsConf.dstattr) {
            //     this.activeData.dsConf.dstattr = "";
            // }
            var ta = [];
            execModel(value, JSON.stringify({ __max_count__: 1 }))
                .then((res) => {
                    console.log("dstchange execModel res", res);
                    if (res.data && res.data.length > 0) {
                        this.DsData = res.data
                        let odata = res.data[0];

                        for (let j in odata) {
                            ta.push(j + ":" + odata[j]);
                        }
                        this.activeDstattrlist = ta;
                    }
                    console.log(this.activeDstattrlist);
                })
                .catch();
        },
    },
};
</script>