import { render, staticRenderFns } from "./onUploadSuccess-editor.vue?vue&type=template&id=24ba728d&scoped=true&"
import script from "./onUploadSuccess-editor.vue?vue&type=script&lang=js&"
export * from "./onUploadSuccess-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ba728d",
  null
  
)

export default component.exports