<!-- 生成时间:2023-01-12 13:43:57 -->
<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--路径数据-->
      <el-col :span="4" :xs="24">
        <div class="head-container" style="margin-top: 10px;">
          <div>
            <el-select v-model="treeQuery.source" placeholder="请选择" @change="publicChange">
              <el-option label="系统文件柜" value="system">
                <span style="float: left">系统文件柜</span>
                <span style="float: right; color: #8492a6; font-size: 13px">></span>
              </el-option>
              <el-option label="个人文件柜" value="seft">
                <span style="float: left">个人文件柜</span>
                <span style="float: right; color: #8492a6; font-size: 13px">></span>
              </el-option>

            </el-select>
          </div>
        </div>
        <div class="head-container" style="margin-top: 10px;">
          <el-tree :data="deptOptions" :filter-node-method="filterNode" ref="tree" default-expand-all
                   @node-click="handleNodeClick"/>
        </div>
      </el-col>
      <!--文件数据-->
      <el-col :span="20" :xs="24">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch">
          <el-form-item label="名称" prop="filename">
            <el-input v-model="queryParams.filename" placeholder="请输入文件名称" clearable size="small"
                      @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="上传者" prop="create_by_username">
            <el-input v-model="queryParams.create_by_username" placeholder="请输入上传者" clearable size="small"
                      @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item>
            <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>

        <div>
          <span v-for="(item,index) in pathList">{{ item.remark }} / </span>
        </div>

        <jf-table :loading="loading" :defaultoprWidth="250" :columns="tableColumn" :data="dataList"
                  :toolbar-config="tableToolbar" :query-params="queryParams" @checkbox-change="checkboxChangeEvent"
                  @page-change="handleSizeChange" :total="total" @change="tableChange">
          <template #toolbar_btn_left>
            <el-button style="margin-left: 10px" icon="el-icon-back"
                       size="mini" @click="handleFolderBack">返回
            </el-button>
            <!--            <el-button v-if="treeQuery.source == '2'" style="margin-left: 10px" icon="el-icon-plus"-->
            <!--                       size="mini" @click="handlePathUpdate(undefined,'add')"-->
            <!--                       v-hasPermi="['/filecenter/oss/add']">新建文件夹-->
            <!--            </el-button>-->
            <el-button  style="margin-left: 10px" type="primary" icon="el-icon-upload"
                        size="mini" @click="handleAdd"
                        v-hasPermi="['/filecenter/oss/add']">上传文件
            </el-button>
            <el-button type="success" icon="el-icon-share" size="mini" :disabled="single" @click="showShareDiv"
                       v-hasPermi="['/filecenter/oss/get', '/filecenter/oss/edit']">分享
            </el-button>
            <el-button type="danger"  icon="el-icon-delete" size="mini" :disabled="multiple"
                       @click="handleDelete"
                       v-hasPermi="['/filecenter/oss/del']">删除
            </el-button>
          </template>


          <!--默认操作按钮-->
          <template #publictopr="{ row }">
            {{ row.public == "1" ? "公开" : "需要登录" }}
          </template>
          <template #nametopr="{ row }">
            <span v-if="row.mtype == 'folder'" class="pointer" @click="selectListByPid(row)">    <i
                class="el-icon-folder-opened"></i><span class="lpadding">{{ row.filename }}</span></span>
            <span v-else class="pointer" @click="handleView(row)">    <i
                class="el-icon-document"></i><span class="lpadding">{{ row.filename }}</span></span>
          </template>

          <!--默认操作按钮-->
          <template slot="toolbar_btn_row" slot-scope="{ row }">
            <el-button v-if="row.mtype=='folder'" size="mini" type="text" icon="el-icon-edit"
                       @click="handlePathUpdate(row,'edit')">修改
            </el-button>
            <el-button size="mini" v-if="row.mtype=='file'" type="text" icon="el-icon-download"
                       @click="handleDownload(row)">下载
            </el-button>
            <el-button size="mini" v-if="row.mtype=='file'" type="text" icon="el-icon-eyes" @click="handleView(row)">查看
            </el-button>
            <el-button  size="mini" type="text" icon="el-icon-delete"
                       @click="handleDelete(row)">删除
            </el-button>
          </template>

          <!--自定义空数据模板-->
          <template #empty>
            <span>
              <p>暂无数据</p>
            </span>
          </template>

        </jf-table>

        <!-- 添加或修改对话框 -->
        <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800" @close="closeDialog"
                   :modal-append-to-body="false">
          
            <!-- <minio-upload
              :source="treeQuery.source"
              :key="uuid"
              @progress="getProgress"
              @finish="finish"/> -->

              <minio-upload
              :source="treeQuery.source"
              :key="uuid"
              @progress="getProgress"
              @finish="finish"/>

              <!-- <upload-by-oss
                @uploadFile="getUploadFile"
                :fileType="['rar', 'zip', 'doc', 'docx', 'pdf']"
                :fileSize="0"
                :limit="5"
                :fileURL="fileURL"
                :fileList="form.companyUrlList ? form.companyUrlList : []"
                :btnDisabled="title == '添加公司任务' ? false : true"
              ></upload-by-oss> -->


          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="cancel">确 定</el-button>
            <el-button @click="cancel">返 回</el-button>
          </div>
        </el-dialog>


        <!-- 添加文件夹 -->
        <el-dialog :close-on-click-modal="false" :title="bucketTitle" :visible.sync="bucketOpen" width="600px"
                   append-to-body
                   @close="closeBucketDialog">
          <bucketAdd :key="uuid"
                     :defaultValue="pathData"
                     :storage="platform.storage"
                     :source="treeQuery.source"/>
        </el-dialog>


        <!-- 文件移动 -->
        <el-dialog :close-on-click-modal="false" title="文件" :visible.sync="ossEditOpen" width="600px"
                   append-to-body
                   @close="closeDialog">
          <ossEdit :ids="editId"/>
        </el-dialog>


        <!--        文件分享-->
        <el-dialog :visible.sync="ossShareOpen" title="文件分享" width="800px" append-to-body>
          <el-input readonly type="hidden" v-model="shareData.id"></el-input>
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="分享对象">
              <admin-selectex
                  size="small"
                  placeholder="请选择用户"
                  title="用户"
                  idField="id"
                  type="assistant"
                  value=""
                  label=""
                  :readonly="true"
                  @getValue="
                                (ids, names) => {
                                  shareData.recno=ids;shareData.recname=names;
                                }
                            "
              />
            </el-form-item>

            <el-form-item label="链接">
              <el-input readonly v-model="links">

                <div slot="append">
                  <el-button icon="el-icon-document-copy" @click="handCopy"></el-button>
                  <el-button style="border-left: 1px solid #DCDFE6;" icon="el-icon-refresh-right"
                             @click="handCreate"></el-button>
                </div>
              </el-input>
            </el-form-item>
            <el-form-item label="过期时间">
              <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="6">
                  <div style="display: flex;">
                    <el-input-number :min="0" :max="7" v-model="shareData.day"
                                     @change="changeTimes($event,'day')"></el-input-number>
                    <span class="dw">天</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div style="display: flex;">
                    <el-input-number :min="0" :max="24" :disabled="shareData.hoursDisabled" v-model="shareData.hours"
                                     @change="changeTimes($event,'hours')"></el-input-number>
                    <span class="dw">时</span></div>
                </el-col>
                <el-col :span="6">
                  <div style="display: flex;">
                    <el-input-number :min="0" :max="60" :disabled="shareData.minuteDisabled" v-model="shareData.minute"
                                     @change="changeTimes($event,'minute')"></el-input-number>
                    <span class="dw">分</span></div>
                </el-col>
              </el-row>


              <!--              <el-date-picker-->
              <!--                  v-model="shareData.expire"-->
              <!--                  type="datetime"-->
              <!--                  placeholder="选择日期时间"-->
              <!--                  align="right"-->
              <!--                  :picker-options="pickerOptions">-->
              <!--              </el-date-picker>-->

            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="handShare">分享</el-button>
              <el-button type="primary" @click="handCreate">重新生成链接</el-button>
              <el-button>取消</el-button>
            </el-form-item>
          </el-form>


        </el-dialog>


        <!--高级查询对话框-->
        <el-dialog title="高级查询" :visible.sync="advqueryopen" width="1300px" append-to-body>
          <el-row :gutter="20">
            <el-col :span="15" :xs="24">
              <vxe-grid border resizable keep-source ref="xAdvqueryGrid" id="id_xAdvqueryGrid" height="500"
                        :columns="advqueryColumn" :toolbar-config="advqueryToolbar" :data="advqueryData"
                        :custom-config="{ storage: true }"
                        :edit-config="{ trigger: 'click', mode: 'row', showStatus: true }"
                        @toolbar-button-click="advqtoolbarButtonClickEvent">
                <template #defaultadvqueryopr="{ row }">

                </template>
              </vxe-grid>

            </el-col>
            <el-col :span="9" :xs="24">
              <vxe-grid border resizable keep-source ref="xAdvqueryGrid_tempfilter" id="id_xAdvqueryGrid_tempfilter"
                        height="500" :columns="advqueryColumn_tempfilter" :data="advqueryData_tempfilter">
                <template #defaultadvqueryopr_tempfilter="{ row }">
                  <el-button size="mini" type="text" icon="el-icon-delete" @click="handleadvqueryApplytpl(row)">应用
                  </el-button>

                  <el-button size="mini" type="text" icon="el-icon-delete" @click="handleadvqueryDeltpl(row)">删除
                  </el-button>
                </template>
              </vxe-grid>

            </el-col>
          </el-row>


          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="advquery_save">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
        <!--保存模版-->
        <el-dialog :close-on-click-modal="false" title="保存模版" :visible.sync="advquery_tempsave" width="800px"
                   append-to-body>
          <el-form ref="advquery_tempsaveform" :model="advquery_tempsaveform" :rules="advquery_tempsaverules"
                   label-width="80px">
            <el-form-item label="模版名称" prop="tempname">
              <el-input v-model="advquery_tempsaveform.tempname" placeholder="请输入模版名称"/>

            </el-form-item>
          </el-form>

          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="advquery_save">确 定</el-button>

          </div>

        </el-dialog>
        <!--导出数据-->
        <el-dialog :close-on-click-modal="false" title="导出数据" :visible.sync="exportdialog" width="800px"
                   append-to-body>
          <el-checkbox :indeterminate="exportisIndeterminate" v-model="exportcheckAll"
                       @change="handleexportCheckAllChange">全选
          </el-checkbox>
          <div style="margin: 15px 0;"></div>

          <el-checkbox-group v-model="exportfields">
            <el-checkbox v-for="field in tableColumn" v-if="field.field" :label="field.field" :key="field.field">{{
                field.title
              }}
            </el-checkbox>
          </el-checkbox-group>

          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="doExport">确 定</el-button>

          </div>

        </el-dialog>
        <el-dialog :key="dialogVisibleCount" :visible.sync="dialogVisible">
          <img v-if="dialogFileType == 'jpg'" width="100%" :src="dialogFileUrl" :alt="dialogFileUrl"/>
          <img v-if="dialogFileType == 'png'" width="100%" :src="dialogFileUrl" :alt="dialogFileUrl"/>


          <video v-if="dialogFileType == 'mp4'" width="320" height="240" controls autoplay>
            <source :src="dialogFileUrl" type="video/ogg">
            <source :src="dialogFileUrl" type="video/mp4">
            <source :src="dialogFileUrl" type="video/webm">
            <object data="dialogFileUrl" width="320" height="240">
              <embed width="320" height="240" :src="dialogFileUrl">
            </object>
          </video>

        </el-dialog>

      </el-col>
    </el-row>

  </div>
</template>
<script>
import {saveAs} from 'file-saver'
import {getToken} from "@/utils/auth";
import {
  listOss, getOss, delOss, advquerydelOss,
  advquerysaveOss, advquerylistOss, addOss,
  exportOss, shareOss
} from '@/api/dss/oss'

import {
  listPath, getPath, delPath,addPath
} from '@/api/dss/path'

import VueClipboard from 'vue-clipboard2';
import VDistpicker from "@/components/VDistpicker"
import MinioUpload from './components/MinioUpload.vue'
import bucketAdd from '../bucket/add.vue'
import ossEdit from './edit.vue'
import {matchType, convertToTree} from "@/api/dss/fastdfsServer.js"

export default {
  components: {VDistpicker, MinioUpload, bucketAdd, ossEdit},
  name: "oss",
  data() {
    return {
      treeQuery: {
        source: "system",

        // pid: {"<>": "0"},
        pageNum: 1,
        pageSize: 10,
        storage: undefined
      },
      platform: {
        storage: 'MinIO'
      },
      ossShareOpen: false,
      shareData: {
        day: 5,
        hours: 0,
        minute: 0,
        hoursDisabled: false,
        minuteDisabled: false,
        recno: undefined,
        recname: undefined,
        recdeptid: undefined,
        recdeptname: undefined,
      },
      links: "",

      // 部门树选项
      deptOptions: undefined,
      bucketList: [{id: 1, name: 'jpgfast'}],

      appId: "1",
      uploadPrefix: "",


      businessname: "oss",
      exportfields: [],
      exportcheckAll: false,
      exportisIndeterminate: false,
      exportdialog: false,

      //编辑器配置
      ueConfig: {
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口
        serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
        enableAutoSave: true,
        elementPathEnable: false,
        wordCount: false,
        UEDITOR_HOME_URL: '/ueditor/',
      },
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },

      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'filename', title: '文件名称', minWidth: 280, slots: {default: 'nametopr'}},
        {field: 'filesizeText', title: '大小', minWidth: 80},
        {field: 'category', title: '类型', minWidth: 80},
        // { field: 'storage', title: '存储平台', minWidth: 80 },
        // {field: 'filepath', title: '文件路径', width: 280},
        // { field: 'bucket', title: 'bucket', minWidth: 80 },
        // { field: 'path', title: '存储路径', minWidth: 200 },

        // { field: 'mime_type', title: 'mime类型', minWidth: 80 },
        // { field: 'image_width', title: '图片宽度', minWidth: 80 },
        // { field: 'image_height', title: '图片高度', minWidth: 80 },
        // { field: 'image_type', title: '图片类型', minWidth: 80 },
        // { field: 'image_frames', title: '图片帧数', minWidth: 80 },
        // { field: 'extparam', title: '透传数据', minWidth: 80 },

        // { field: 'hash', title: '文件哈希', minWidth: 80 },
        { field: '', title: '是否公开', minWidth: 80, slots: { default: 'publictopr' } },
        // { field: 'remark', title: '备注', minWidth: 80 },
        {field: 'create_time', title: '上传日期', minWidth: 120, formatter: "formatDate"},
        // { field: 'update_time', title: '更新时间', minWidth: 120, formatter: "formatDate" },
        {field: 'create_by_username', title: '上传者', minWidth: 100},
        {field: '', title: '操作', width: 230, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],

      advqueryColumn: [
        {
          field: 'qfield',
          title: '查询字段',
          width: 180,
          fixed: "left",
          editRender: {name: 'select', options: [{value: "dd", label: "cxvs"}, {value: "zz", label: "cxvs"}]},
        },
        {field: 'qopr', title: '操作符', width: 180, fixed: "left", editRender: {name: 'select', options: []}},
        {field: 'qvalue', title: '值', width: 180, fixed: "left", editRender: {name: 'input'}},
        {field: 'qlogic', title: '逻辑', width: 180, fixed: "left", editRender: {name: 'select', options: []}},
        {
          field: '',
          title: '操作',
          resizable: false,
          width: 110,
          fixed: "right",
          align: "center",
          slots: {default: 'defaultadvqueryopr'}
        }
      ],
      advqueryColumn_tempfilter: [
        {field: 'tempname', title: '模版名称', width: 200, fixed: "left"},
        {
          field: '',
          title: '操作',
          resizable: false,
          width: 180,
          fixed: "right",
          align: "center",
          slots: {default: 'defaultadvqueryopr_tempfilter'}
        }
      ],
      advqueryData_tempfilter: [],
      advqueryopen: false,
      advquery_tempsave: false,
      advqueryData: [],
      advqueryToolbar: {
        buttons: [
          {code: 'myInsert', name: '新增'},
          {code: 'dofilter', name: '使用此组合条件查询'},

        ],

      },
      upload: {
        headers: {Authorization: getToken()},
        url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
      },
      dialogVisible: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      mtypes: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 字典
      deptName: "",

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        opstr: {},
        category: undefined,
        filename: undefined,
        filesize: undefined,
        mime_type: undefined,
        image_width: undefined,
        image_height: undefined,
        image_type: undefined,
        image_frames: undefined,
        extparam: undefined,
        storage: undefined,
        hash: undefined,
        path: undefined,
        url: undefined,
        public: undefined,
        remark: undefined,
        source: undefined,
        mtype: undefined,
        create_by_username: undefined,
        pid: undefined,

      },
      // 表单参数
      form: {storage: "Minio"},
      advquery_tempsaveform: {},
      advquery_tempsaverules: {
        tempname: [{required: true, message: "模版名称不能为空", trigger: "blur"}]
      },
      // 表单校验
      rules: {
        filename: [
          {required: true, message: "文件名称不能为空", trigger: "blur"}
        ],
        filesize: [
          {required: true, message: "文件大小不能为空", trigger: "blur"}
        ],
        storage: [
          {required: true, message: "存储平台不能为空", trigger: "blur"}
        ],
        bucket: [
          {required: true, message: "存储空间不能为空", trigger: "blur"}
        ],


      },
      fileList: [],
      // 查询参数
      bucketParams: {
        pageNum: 1,
        pageSize: 10,
        opstr: {},



        app_id: undefined,
        storage: undefined,
        name: undefined,
        remark: undefined,
        useed: undefined,
      },

      appList: [],
      dialogVisibleCount: 0,
      dialogFileUrl: "",
      dialogFileType: "img",

      bucketOpen: false,
      filterText: '',
      ossEditOpen: false,
      editId: undefined,
      uuid: "",
      backData: [],
      backIndex: 0,
      pathList: [],
      //新建文件夹
      bucketTitle: '',
      pathData: {}

    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.queryParams.storage = this.treeQuery.storage = this.platform.title;
    this.getList();
    // this.getTreeselect();
    setTimeout(() => {
      const $grid = this.$refs.xAdvqueryGrid
      // 异步更新下拉选项
      var ta = [{value: "", label: "请选择"}];
      for (var i in this.tableColumn) {
        var e = this.tableColumn[i]
        if (e.field != null && e.field != "") {
          ta.push({value: e.field, label: e.title})
        }
      }
      this.advqueryColumn[0].editRender.options = ta;

      this.advqueryColumn[1].editRender.options = this.defaultQlogic();
      ta = [];
      ta.push({value: "AND", label: "并且"});
      ta.push({value: "OR", label: "或者"});
      this.advqueryColumn[3].editRender.options = ta;

    }, 300);
    // 加载字典数据


  },
  methods: {
    //表单上传字段成功处理
    handleImportSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      var field = response.data.return_field;

      this.form[field] = this.form[field] || [];
      this.form[field].push(response.data.url);
      this[field + "_fileList"].push({url: response.data.url});
    },
    handleExport() {
      if (this.total > 0) {
        this.exportdialog = true;
      } else {
        this.msgError("暂无数据可导出!");
      }
    },
    doExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportOss(queryParams, this.exportfields, this.tableColumn);
      }).then(response => {
        this.download(response.file);
      }).catch(function () {
      });
    },
    handleexportCheckAllChange(val) {
      this.exportfields = val ? this.tableColumn.map((item) => {
        return item.field;
      }) : [];
      this.exportisIndeterminate = false;
    },
    //删除查询模版
    handleadvqueryDeltpl(row) {
      var ids = row.id;
      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        advquerydelOss(ids).then(() => {
          const $grid = this.$refs.xAdvqueryGrid_tempfilter;
          $grid.remove(row)
        }).then(() => {
          this.msgSuccess("删除成功");
        });

      }).then(() => {
        this.msgSuccess("删除成功");
      }).catch(function () {
      });
    },
    //打开高级查询
    handleAdvquery() {
      advquerylistOss(this.businessname).then(response => {
        this.advqueryData_tempfilter = response.data.list
        this.advqueryopen = true
      });
    },
    //保存条件
    advquery_save() {
      const $grid = this.$refs.xAdvqueryGrid;
      var alldata = $grid.getTableData();
      if (alldata.fullData.length == 0) {
        return false
      }
      var opstr = {};
      var filteroptview = [];
      var Qlogicar = this.defaultQlogic();
      var queryParams = {};
      var fields = {}, Qlogic = {};

      for (var i in this.tableColumn) {
        var col = this.tableColumn[i];
        if (col.field != null && col.field != "") {
          fields[col.field] = col.title
        }
      }
      for (var i in Qlogicar) {
        var lgc = Qlogicar[i];
        Qlogic[lgc.value] = lgc.label
      }
      for (var i in alldata.fullData) {
        var onerow = alldata.fullData[i];
        queryParams[onerow.qfield] = onerow.qvalue;
        opstr[onerow.qfield] = onerow.qopr;
        filteroptview.push(fields[onerow.qfield] + " " + Qlogic[onerow.qopr] + "" + onerow.qvalue)
      }

      queryParams.opstr = opstr;
      this.advquery_tempsaveform["businessname"] = this.businessname;
      this.advquery_tempsaveform["filteropt"] = JSON.stringify(queryParams);
      this.advquery_tempsaveform["filteroptview"] = filteroptview.join(";");

      this.$refs["advquery_tempsaveform"].validate(valid => {
        if (valid) {
          advquerysaveOss(this.advquery_tempsaveform).then(response => {
            if (response.code === 0) {
              this.msgSuccess("保存成功");
              this.advquery_tempsave = false;
              this.handleAdvquery();
            }
          });

        }
      });
    },
    //应用查询模版
    handleadvqueryApplytpl(row) {
      var Filteropt = JSON.parse(row.filteropt)
      Filteropt['pageNum'] = 1;
      Filteropt['pageSize'] = 10;
      this.queryParams = Filteropt
      this.advqueryopen = false
      setTimeout(() => {
        this.getList()
      }, 300)

    },
    //删除临时条件
    handleadvqueryDeleted(row) {
      const $grid = this.$refs.xAdvqueryGrid;

      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        $grid.remove(row)
      }).then(() => {
        this.msgSuccess("删除成功");
      }).catch(function () {
      });
    },
    advqtoolbarButtonClickEvent({code}) {
      const $grid = this.$refs.xAdvqueryGrid
      switch (code) {
        case 'myInsert':
          var row = $grid.insert({
            qfield: '',
            qopr: '=',
            qlogic: 'AND',
          })

          $grid.setActiveRow($grid.getData(0));
          break;

        case 'dosavefilter':
          var alldata = $grid.getTableData();
          if (alldata.fullData.length == 0) {
            return false
          }
          this.advquery_tempsave = true;
          break;
        case 'dofilter':
          var alldata = $grid.getTableData();
          if (alldata.fullData.length == 0) {
            return false
          }

          var opstr = {}

          var queryParams = {
            pageNum: 1,
            pageSize: 10,
            opstr: {}
          };
          for (var i in alldata.fullData) {
            var onerow = alldata.fullData[i]
            queryParams[onerow.qfield] = onerow.qvalue
            opstr[onerow.qfield] = onerow.qopr
          }

          queryParams.opstr = opstr;
          this.queryParams = queryParams;
          this.advqueryopen = false;
          setTimeout(() => {
            this.getList()
          }, 300)
          break;

      }
    },
    checkboxChangeEvent({records}) {
      this.ids = records.map(item => item.id)
      this.mtypes = records.map(item => item.mtype)
      this.single = records.length != 1
      this.multiple = !records.length

    },
    handleSizeChange({currentPage, pageSize}) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize
        this.handleQuery();
      }
    },

    /** 查询列表 */
    getList() {
      this.loading = true;
      this.queryParams.source = this.treeQuery.source;

      listOss(this.queryParams).then(response => {
        let dataList = response.data.list;
        for (var i = 0; i < dataList.length; i++) {
          dataList[i].filesizeText = this.sizeTostr(dataList[i].filesize);
          dataList[i].category = matchType(dataList[i].filename);
          dataList[i].filepath = dataList[i].bucket + '/' + dataList[i].path;
          if (dataList[i].mtype == "folder") {
            dataList[i].filename = dataList[i].remark;
            dataList[i].filesize = 0;
            dataList[i].filepath = "";
          }
        }
        this.dataList = dataList;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    //下拉框选择事件
    publicChange(data) {
      this.treeQuery.source = data;
      this.getTreeselect();

      this.queryParams.source = data;
      this.queryParams.pid = undefined;
      this.getList();
    },
    storageChange(data) {
      this.treeQuery.storage = data;

      this.getTreeselect();
      this.queryParams.pid = undefined;
      this.queryParams.storage = data;
      this.getList();
    },

    handlePathUpdate(row, flag) {
      if (flag == "add") {
        this.bucketTitle = "添加文件夹"
        this.pathData = {};
      } else {
        if (row.remark == "个人文件柜" || row.remark == "公共文件柜" || row.remark == "其他文件柜") {
          this.msgError("暂不支持修改系统默认文件夹");
          return;
        }
        this.bucketTitle = "修改文件夹"
        this.pathData = row;
      }

      this.bucketOpen = true;
      this.uuid = Date.now();
    },

    /** 查询目录下拉树结构 */
    getTreeselect() {
      listPath(this.treeQuery).then(response => {
        this.bucketList = [];
        let res = response.data.list;
        var cars = new Array();
        for (var i = 0; i < res.length; i++) {
          let item = {
            id: res[i].id,
            label: res[i].filename,
            parentId: res[i].pid,
          }
          cars.push(item);
        }
        this.deptOptions = []
        // this.bucketList = res;
        // this.deptOptions = convertToTree(cars,0);
        this.deptOptions = this.handleTree(cars, "id", "parentId");
        console.log(this.deptOptions);
        console.log(cars);


      });
    },

    // 选择文件、移除文件、上传文件成功/失败后，都会触发
    closeDialog() {
      this.getList();
    },
    closeBucketDialog() {
      // this.getTreeselect();
    },

    // $comment字典翻译

    // 取消按钮
    cancel() {
      this.open = false;
      this.advqueryopen = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        admin_id: undefined,
        user_id: undefined,
        category: undefined,
        filename: undefined,
        filesize: undefined,
        mime_type: undefined,
        image_width: undefined,
        image_height: undefined,
        image_type: undefined,
        image_frames: undefined,
        extparam: undefined,
        storage: undefined,
        hash: undefined,
        path: undefined,
        url: undefined,
        public: undefined,
        bucket: undefined,

      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.mtypes = selection.map(item => item.mtype)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.uuid = Date.now();
      this.title = this.treeQuery.source=="system"?"系统文件柜":"私人文件柜";
    },

    /** 修改按钮操作 */
    handleView(row) {
      this.reset();
      const id = row.id || this.ids
      getOss(id).then(response => {
        let resp = response.data.data;
        if (resp.category == "png" || resp.category == "jpg") {
          this.dialogFileUrl = resp.url+"?auth_token="+resp.token;
          this.dialogFileType = resp.category;
          this.dialogVisible = true;
        } else {
          window.open(resp.url+"?auth_token="+resp.token, '_blank')
        }
      });
    },

    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getOss(id).then(response => {
        let resp = response.data.data;
        if (resp.category == "png" || resp.category == "jpg") {
          this.dialogFileUrl = resp.url;
          this.dialogFileType = resp.category;
          this.dialogVisible = true;
        } else {
          window.open(resp.url, '_blank')
        }
      });
    },
    selectListByPid(row) {
      this.backData.push(row);
      this.queryParams.pid = row.id;
      this.getList();
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.pid = data.id;
      this.getList();
    },
    handleFolderBack() {
      if (null != this.backData) {
        this.backData.splice(0, this.backData.length - 1);
        this.queryParams.pid = this.backData[this.backData.length - 1].pid;
        this.getList()
      }
    },
    showShareDiv(row) {
      this.shareData = {
        recno: [],
        recname: [],
        day: 5,
        hours: 0,
        minute: 0,
        hoursDisabled: false,
        minuteDisabled: false,
        recno: undefined,
        recname: undefined,
        recdeptid: undefined,
        recdeptname: undefined,
      };
      const ids = row.id || this.ids.join(',');
      const mtypes = row.mtype || this.mtypes.join(',');

      if (mtypes == "2") {
        this.msgError("暂不支持文件夹分享!");
        return false;
      }

      this.getOssData(ids, this.shareData);
      this.ossShareOpen = true;
    },
    changeTimes(kk, action) {
      if (action == 'day') {
        if (kk >= 7) {
          this.shareData.hours = 0;
          this.shareData.minute = 0;
          this.shareData.hoursDisabled = this.shareData.minuteDisabled = true;
        } else {
          this.shareData.hoursDisabled = this.shareData.minuteDisabled = false;
        }
      }

    },
    getOssData(id, shareData, text) {

      let expire = shareData.day * 24 * 60 + shareData.hours + shareData.minute
      let parm = {
        id: id,
        expire: expire,
        share: 1,
        recno: shareData.recno,
        recname: shareData.recname,
        recdeptid: undefined,
        recdeptname: undefined,
      }
      this.links = "";
      shareOss(parm).then(response => {
        let resp = response.data.data;
        this.links = resp.url;
        if (text != ""&&text!=undefined &&text.length > 0) {
          this.msgSuccess(text);
          setTimeout(() => {  this.ossShareOpen = false; }, 3000)
        }


      });


    },
    handCopy() {
      this.$copyText(this.links)
          .then(() => {
            this.msgSuccess("复制成功");
          })
          .catch(() => {
            console.error('无法复制文本');
          });

    },
    handCreate() {
      const ids = this.ids.join(',');
      this.getOssData(ids, this.shareData, "已生成新的链接");
    },
    handShare() {
      if (this.shareData.recno == undefined) {
        this.msgError("请先选择要分享的用户");
        return;
      }
      const ids = this.ids.join(',');
      this.getOssData(ids, this.shareData, "分享成功");

    },
    handleRemove(row) {
      const ids = row.id || this.ids.join(',');
      this.editId = ids;
      this.ossEditOpen = true;
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids.join(',');
      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delOss(ids);
      }).then(() => {
        setTimeout(() => {
          this.getList();
        }, 300)
        this.msgSuccess("删除成功");
      }).catch(function () {
      });
    },
    // /** 下载事件 */
    handleDownload(row) {
      const id = row.id || this.ids
      getOss(id).then(response => {
        let resp = response.data.data;
        saveAs(resp.url+"?auth_token="+resp.token, row.filename)
      });

    },
    //子组件文件上传进度事件
    getProgress(val) {
      console.log("进度：" + val);
    },
    //子组件完成上传回调事件
    finish(val) {
      for (var i = 0; i < val.length; i++) {
        if (val[i].code == 200) {
          addOss(val[i].info).then(response => {
            if (response.code === 0) {
              this.msgSuccess(val[i].info.fileName + "保存成功");
            }
          });
        }

      }
    },
    //文件大小格式转换
    sizeTostr(size) {
      var data = "";
      if (size < 0.1 * 1024) { //如果小于0.1KB转化成B
        data = size.toFixed(2) + "B";
      } else if (size < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB
        data = (size / 1024).toFixed(2) + "KB";
      } else if (size < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else { //其他转化成GB
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      var sizestr = data + "";
      var len = sizestr.indexOf("\.");
      var dec = sizestr.substr(len + 1, 2);
      if (dec == "00") {//当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
      }
      return sizestr;
    }
  },
  //methods结束
  directives: {
    clipboard: VueClipboard
  }
};
</script>
<style>
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.pointer {
  color: #1e5edb;
  cursor: pointer;

}

.lpadding {
  padding-left: 5px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.dw {
  padding-left: 5px;
  font-weight: bolder;
  font-size: larger;
}
</style>
