<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
            <!--<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
            <!-- <el-form-item label="联系人" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入联系人"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input
                    v-model="queryParams.phone"
                    placeholder="请输入手机号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="审核状态" prop="status">
                <el-select
                    v-model="queryParams.status"
                    placeholder="请选择"
                    size="small"
                >
                    <el-option label="未审核" value="0"> </el-option>
                    <el-option label="通过" value="1"> </el-option>
                    <el-option label="未通过" value="2"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            border
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #statustpl="{ row }">
                <el-tag type="success" effect="light" v-if="row.status == '1'"
                    >通过</el-tag
                >
                <el-tag
                    type="danger"
                    effect="light"
                    v-else-if="row.status == '2'"
                    >未通过</el-tag
                >
                <el-tag type="info" effect="light" v-else>未审核</el-tag>
            </template>
            <template #toolbar_buttons> -->
                <!--<el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAdd"
				v-hasPermi="[
					'/gbpx/gbpxreservation/add',
				]"
				>新增</el-button
			>-->

                <!-- <el-button
                    style="margin-left: 10px"
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxreservation/get',
                        '/gbpx/gbpxreservation/edit',
                    ]"
                    >审核</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxreservation/del']"
                    >删除</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >审核
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxreservation/get',
                        '/gbpx/gbpxreservation/edit',
                    ]"
                    >审核</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxreservation/del']"
                    >删除</el-button
                >
            </template>
            <template slot="statustpl" slot-scope="{ row }">
                <el-tag type="success" effect="light" v-if="row.status == '1'"
                    >通过</el-tag
                >
                <el-tag
                    type="danger"
                    effect="light"
                    v-else-if="row.status == '2'"
                    >未通过</el-tag
                >
                <el-tag type="info" effect="light" v-else>未审核</el-tag>
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >审核
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="联系人" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入联系人"
                                clearable
                                :readonly="form.id > 0"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="form.phone"
                                placeholder="请输入手机号"
                                clearable
                                :readonly="form.id > 0"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="培训预约单位" prop="unit">
                            <el-input
                                v-model="form.unit"
                                placeholder="请输入培训预约单位"
                                clearable
                                :readonly="form.id > 0"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备注" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入备注"
                                clearable
                                type="textarea"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="审核状态" prop="status">
                            <el-radio-group v-model="form.status">
                                <el-radio label="1">通过</el-radio>
                                <el-radio label="2">未通过</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listGbpxReservation,
    getGbpxReservation,
    addGbpxReservation,
    editGbpxReservation,
    delGbpxReservation,
} from "@/api/gbpx/gbpx_reservation";
export default {
    name: "gbpx-gbpxreservation-list",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                //{ field: "id", title: "id", minWidth: 120, fixed: "left" },
                { field: "name", title: "联系人", minWidth: 120,is_query:true },
                { field: "phone", title: "手机号", minWidth: 120,is_query:true },
                { field: "unit", title: "培训预约单位", minWidth: 120 },
                { field: "remark", title: "备注", minWidth: 120 },
                {
                    field: "status",
                    title: "审核状态",
                    minWidth: 120,
                    slots: { default: "statustpl" },
                    options: [
                        { name: "未审核", value: 0 },
                        { name: "通过", value: 1 },
                        { name: "未通过", value: 2 },
                    ],
                    options_value: "value",
                    options_label: "name",
                    form_type:2,
                    is_query:true
                },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                // 	field: "",
                // 	title: "操作",
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: { default: "defaultopr" },
                // },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
                phone: undefined,
                status: undefined,
            },
            form: {},
            rules: {
                name: [
                    {
                        required: true,
                        message: "联系人不能为空",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "手机号不能为空",
                        trigger: "blur",
                    },
                ],
                unit: [
                    {
                        required: true,
                        message: "培训预约单位不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listGbpxReservation(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.form = {
                name: undefined,
                phone: undefined,
                unit: undefined,
                remark: undefined,
                status: undefined,
            };
            this.resetForm("form");
        },

        handleQuery(e) {
            if (e) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getGbpxReservation(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editGbpxReservation(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addGbpxReservation(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm("确定删除吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delGbpxReservation(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
    },
};
</script>
