<template>
  <div class="app-container">
    <el-form :inline="true" label-position="left">
      <el-form-item label="状态">
        <el-select
          v-model="queryParams.status"
          placeholder="菜单状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions.values"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否主面板">
        <el-select
          v-model="queryParams.is_main"
          placeholder="是否主面板"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in yesNotOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否应用">
        <el-select
          v-model="queryParams.is_app"
          placeholder="是否应用"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in yesNotOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          >新增</el-button
        >
      </el-form-item>
      <el-form-item v-hasPermi="['/urlwhitelist/list']">
        <el-button
          type="warning"
          icon="el-icon-tickets"
          size="mini"
          @click="handleWhitelist"
          >权限黑白名单</el-button
        >
      </el-form-item>
    </el-form>
    <jf-table
      :loading="loading"
      ref="jfTable"
      :stripe="false"
      :columns="tableColumn"
      :data="menuList"
      :query-params="queryParams"
      @change="tableChange"
      :checkbox="false"
      :pager="false"
      @toggle-tree-expand="toggleExpandChangeEvent"
      :tree-config="treeConfig"
      :defaultopr-width="'280'"
    >
      <template slot="icon" slot-scope="{ row }">
        <i
          v-if="row.icon.indexOf('el-icon-') === 0"
          slot="prefix"
          :class="'el-icon ' + row.icon"
        />
        <i
          v-else-if="row.icon.indexOf('fa fa-') === 0"
          slot="prefix"
          :class="row.icon"
        />
        <svg-icon v-else :icon-class="row.icon" />
      </template>
      <template slot="is_show" slot-scope="{ row }">
        {{ visibleFormat(row) }}
      </template>
      <template slot="status" slot-scope="{ row }">
        {{ statusFormat(row) }}
      </template>

      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-view"
          @click.stop="handleUpdate(row, true)"
          >查看
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click.stop="handleUpdate(row)"
          >修改
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-plus"
          @click.stop="handleAdd(row)"
          >新增
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click.stop="handleDelete(row)"
          >删除
        </el-button>
      </template>
    </jf-table>
    <!-- 	<vxe-grid resizable ref="xGrid" row-id="id" :tree-config="treeConfig" show-overflow highlight-hover-row
			:loading="loading" :columns="tableColumn" :data="menuList" @toggle-tree-expand="toggleExpandChangeEvent">
			>
			<template #icon="{row}">
				<i v-if="row.icon.indexOf('el-icon-') === 0" slot="prefix" :class="'el-icon ' +row.icon" />
				<i v-else-if="row.icon.indexOf('fa fa-') === 0" slot="prefix" :class="row.icon" />
				<svg-icon v-else :icon-class="row.icon" />
			</template> -->
    <!--默认操作按钮-->
    <!-- 	<template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-view" @click.stop="handleUpdate(row, true)">查看
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-plus" @click.stop="handleAdd(row)">新增
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template> -->
    <!--自定义空数据模板-->
    <!-- 		<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>
		</vxe-grid> -->
    <!-- 添加或修改菜单对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="900px"
      append-to-body
      :close-on-click-modal="false"
      v-dialogDrag
    >
      <jf-form
        v-if="open"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="90px"
        :readonly="readonly"
      >
        <template #menu>
          <el-descriptions-item>
            <template slot="label"> 菜单图标 </template>
            <i
              v-if="form.icon && form.icon.indexOf('el-icon-') === 0"
              :class="'el-icon ' + form.icon"
              style="font-size: 1.2em"
            />
            <i
              v-else-if="form.icon && form.icon.indexOf('fa fa-') === 0"
              :class="form.icon"
              style="font-size: 1.2em"
            />
            <svg-icon
              v-else-if="form.icon"
              :icon-class="form.icon"
              class="el-input__icon"
            />
          </el-descriptions-item>
        </template>
        <el-row>
          <el-col :span="24">
            <el-form-item label="上级菜单">
              <treeselect
                v-model="form.pid"
                :options="menuOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="选择上级菜单"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜单类型" prop="menu_type">
              <el-radio-group v-model="form.menu_type">
                <el-radio label="0">目录</el-radio>
                <el-radio label="1">菜单</el-radio>
                <el-radio label="2">按钮</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <jf-form-item
              v-if="form.menu_type != '2'"
              label="菜单图标"
              slots="menu"
            >
              <el-popover
                placement="bottom-start"
                width="630"
                trigger="click"
                @show="$refs['iconSelect'].reset()"
              >
                <IconSelect ref="iconSelect" @selected="selected" />
                <el-input
                  slot="reference"
                  v-model="form.icon"
                  placeholder="点击选择图标"
                  readonly
                >
                  <i
                    v-if="form.icon && form.icon.indexOf('el-icon-') === 0"
                    slot="prefix"
                    :class="'el-icon ' + form.icon"
                    style="font-size: 1.2em"
                  />
                  <i
                    v-else-if="form.icon && form.icon.indexOf('fa fa-') === 0"
                    slot="prefix"
                    :class="form.icon"
                    style="font-size: 1.2em"
                  />
                  <svg-icon
                    v-else-if="form.icon"
                    slot="prefix"
                    :icon-class="form.icon"
                    class="el-input__icon"
                    style="height: 32px; width: 16px"
                  />
                  <i
                    v-else
                    slot="prefix"
                    class="el-icon-search el-input__icon"
                  />
                </el-input>
              </el-popover>
            </jf-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="title">
              <el-input v-model="form.title" placeholder="请输入菜单名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示排序" prop="sort">
              <el-input-number
                v-model="form.sort"
                controls-position="right"
                :min="0"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menu_type != '2'" label="是否外链">
              <el-radio-group v-model="form.is_frame">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="form.menu_type != '2'"
              label="路由地址"
              prop="path"
            >
              <el-input v-model="form.path" placeholder="请输入路由地址" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="权限" prop="auth">
              <el-input v-model="form.auth" placeholder="请输入权限" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menu_type != '2'" label="显示状态">
              <el-radio-group v-model="form.is_show">
                <el-radio
                  v-for="dict in visibleOptions.values"
                  :key="dict.key"
                  :label="dict.key"
                  >{{ dict.value }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="12">
            <el-form-item v-if="form.menu_type != '2'" label="菜单状态">
              <el-radio-group v-model="form.status">
                <el-radio
                  v-for="dict in statusOptions.values"
                  :key="dict.key"
                  :label="dict.key"
                  >{{ dict.value }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menu_type == '1'">
            <el-form-item label="组件路径" prop="component">
              <el-input v-model="form.component" placeholder="请输入组件路径" />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menu_type != '2'">
            <el-form-item label="是否缓存">
              <el-radio-group v-model="form.is_cache">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menu_type == '0'">
            <el-form-item label="是否主面板">
              <el-radio-group v-model="form.is_main">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menu_type == '0'">
            <el-form-item label="是否应用">
              <el-radio-group v-model="form.is_app">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menu_type == '1'">
            <el-form-item label="折叠类型" prop="fold_type">
              <el-radio-group v-model="form.fold_type">
                <el-radio label="0">展开</el-radio>
                <el-radio label="1">折叠</el-radio>
                <el-radio label="2">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <template v-if="form.menu_type == '0'">
            <el-col :span="12">
              <el-form-item label="版本号">
                <el-input
                  v-model="form.version"
                  type="input"
                  placeholder="请输入版本号"
                />
              </el-form-item>
            </el-col>
            <template v-if="form.is_app == '1'">
              <el-col :span="12">
                <el-form-item label="应用类型" prop="app_type">
                  <el-radio-group v-model="form.app_type">
                    <el-radio
                      v-for="dict in appTypeOptions"
                      :key="dict.key"
                      :label="dict.key"
                      :value="dict.value"
                      >{{ dict.value }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="应用分类" prop="app_category">
                  <el-select
                    v-model="form.app_category"
                    placeholder="应用分类"
                    size="small"
                  >
                    <el-option
                      v-for="dict in appCategoryOptions"
                      :key="dict.key"
                      :label="dict.value"
                      :value="dict.key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="应用价格" prop="app_price">
                  <el-input v-model="form.app_price" placeholder="请输入价格" />
                </el-form-item>
              </el-col>
            </template>
          </template>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="form.remark"
                type="input"
                placeholder="请输入备注"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </jf-form>
      <el-tabs v-model="activeTab">
        <el-tab-pane label="数据规则" name="datarule" :lazy="true">
          <!-- <vxe-grid v-if="activeTab == 'datarule'" ref="datarulesGrid" row-key max-height="400" border
						:columns="datarulesColumn" :data="datarules"
						:toolbar-config="readonly ? undefined : tableToolbar1">
						<template #toolbar_buttons>
							<el-button type="primary" icon="el-icon-plus" size="mini"
								@click="handleAddDatarule">添加数据规则</el-button>
						</template>
						<template #headersdefaultopr="{ row, rowIndex }">
							<el-button v-if="readonly" size="mini" type="text" icon="el-icon-view"
								@click="handleEditDatarule(row, rowIndex)">查看
							</el-button>
							<template v-else>
								<el-button size="mini" type="text" icon="el-icon-edit"
									@click="handleEditDatarule(row, rowIndex)">修改
								</el-button>
								<el-button size="mini" type="text" icon="el-icon-delete"
									@click="handleDeleteDatarule(row, rowIndex)">删除
								</el-button>
							</template>
						</template>
					</vxe-grid> -->
          <jf-table
            v-if="activeTab == 'datarule'"
            ref="datarulesGrid"
            row-key
            :max-height="400"
            border
            :columns="datarulesColumn"
            :data="datarules"
            :operate="false"
            :checkbox="false"
            :columns-btn="false"
            :filter-btn="false"
            :pager="false"
            :sort-btn="false"
            :toolbar-config="readonly ? undefined : tableToolbar1"
          >
            <template slot="toolbar_btn_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="handleAddDatarule"
                >添加数据规则</el-button
              >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row, rowIndex }">
              <el-button
                v-if="readonly"
                size="mini"
                type="text"
                icon="el-icon-view"
                @click="handleEditDatarule(row, rowIndex)"
                >查看
              </el-button>
              <template v-else>
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleEditDatarule(row, rowIndex)"
                  >修改
                </el-button>
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDeleteDatarule(row, rowIndex)"
                  >删除
                </el-button>
              </template>
            </template>
          </jf-table>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <template v-if="!readonly">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </template>
        <template v-else>
          <el-button @click="cancel">关 闭</el-button>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="数据规则"
      :visible.sync="openDatarule"
      width="800px"
      append-to-body
      custom-class="scrolldialog"
    >
      <jf-form
        ref="formDatarule"
        :model="formDatarule"
        :rules="rulesDatarule"
        label-width="100px"
        :readonly="readonly"
        :readonly-column="1"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="formDatarule.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="自定义sql" prop="sql">
          <el-input
            v-model="formDatarule.sql"
            placeholder="请输入自定义sql"
            type="textarea"
            :rows="6"
          />
          <el-popover
            placement="bottom-start"
            title=""
            width="550"
            trigger="hover"
          >
            <div>自定义sql用于拼接sql查询条件达到过滤数据的目的</div>
            <div>
              例如仅查询本人和本部门数据：a.create_by=#{_user_.id} or
              a.dept_no=#{_user_.dept.no}
            </div>
            <div>特殊参数：</div>
            <div>#{_user_.id}：当前用户ID</div>
            <div>#{_user_.no}：当前用户工号</div>
            <div>#{_user_.dept_id}：当前部门ID</div>
            <div>#{_user_.dept.no}：当前部门编号</div>
            <div>#{_user_.role_ids}：当前角色id</div>
            <div>#{_user_.role_codes}：当前角色编码</div>
            <div>#{_user_.post_ids}：当前岗位id</div>
            <div>#{_user_.post_codes}：当前岗位编码</div>
            <div>#{_user_.permissions}：当前权限</div>
            <el-button
              icon="el-icon-question"
              type="text"
              slot="reference"
              style="color: #606266"
              >自定义sql说明</el-button
            >
          </el-popover>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="formDatarule.remark"
            placeholder="请输入备注"
            type="textarea"
            :rows="3"
          />
        </el-form-item>
      </jf-form>
      <div slot="footer" class="dialog-footer">
        <template v-if="!readonly">
          <el-button type="primary" @click="handleSubmitDatarule"
            >确 定</el-button
          >
          <el-button @click="openDatarule = false">返 回</el-button>
        </template>
        <template v-else>
          <el-button @click="openDatarule = false">关 闭</el-button>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="权限黑白名单"
      :visible.sync="openUrlWhitelist"
      width="1080px"
      append-to-body
      custom-class="scrolldialog"
    >
      <UrlWhitelist v-if="openUrlWhitelist" :userpools="userpools" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="openUrlWhitelist = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listMenu,
  treeselect,
  getMenu,
  delMenu,
  addMenu,
  updateMenu,
} from "@/api/core/system/menu";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import IconSelect from "@/components/IconSelect";
import UrlWhitelist from "@/views/core/system/urlwhitelist";

export default {
  name: "authrule-list",
  components: {
    Treeselect,
    IconSelect,
    UrlWhitelist,
  },
  props: {
    userpools: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      treeConfig: {
        trigger: "row",
        expandRowKeys: [],
      },
      // 列
      tableColumn: [
        {
          field: "title",
          title: "菜单名称",
          treeNode: true,
          width: 160,
        },
        {
          field: "icon",
          title: "图标",
          align: "center",
          slots: {
            default: "icon",
          },
        },
        {
          field: "auth",
          title: "权限标识",
        },
        {
          field: "path",
          title: "路由路径",
        },
        {
          field: "component",
          title: "组件路径",
        },
        {
          field: "sort",
          title: "排序",
          width: 60,
        },
        {
          field: "is_show",
          title: "是否显示",
          width: 100,
          slots: {
            default: "is_show",
          },
          // formatter: this.visibleFormat
        },
        {
          field: "status",
          title: "状态",
          width: 100,
          slots: {
            default: "status",
          },
          // formatter: this.statusFormat
        },
        {
          field: "create_time",
          title: "创建时间",
          width: 180,
          formatter: "formatDate",
        },
        // {
        // 	field: '',
        // 	title: '操作',
        // 	width: 240,
        // 	fixed: "right",
        // 	align: "center",
        // 	slots: {
        // 		default: 'defaultopr'
        // 	}
        // }
      ],
      // 遮罩层
      loading: true,
      // 菜单表格树数据
      menuList: [],
      // 菜单树选项
      menuOptions: [],
      // 弹出层标题
      title: "",
      // 是否查看
      readonly: false,
      // 是否显示弹出层
      open: false,
      // 显示状态数据字典
      visibleOptions: [],
      // 菜单状态数据字典
      statusOptions: [],
      // 类型数据字典
      yesNotOptions: [
        {
          key: "1",
          value: "是",
        },
        {
          key: "0",
          value: "否",
        },
      ],
      appTypeOptions: [],
      appCategoryOptions: [],
      // 查询参数
      queryParams: {
        title: undefined,
        visible: undefined,
        is_main: undefined,
        is_app: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        title: [
          {
            required: true,
            message: "菜单名称不能为空",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "菜单顺序不能为空",
            trigger: "blur",
          },
        ],
        path: [
          {
            required: true,
            message: "路由地址不能为空",
            trigger: "blur",
          },
        ],
        auth: [
          {
            required: true,
            message: "权限不能为空",
            trigger: "blur",
          },
        ],
      },
      tableToolbar1: {
        perfect: false,
        zoom: true,
        custom: false,
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      activeTab: "datarule",
      datarulesColumn: [
        {
          field: "index",
          title: "序号",
          width: 80,
          align: "center",
        },
        {
          field: "name",
          title: "名称",
        },
        {
          field: "sql",
          title: "自定义sql",
        },
        {
          field: "remark",
          title: "备注",
        },
        // {
        // 	field: '',
        // 	title: '操作',
        // 	resizable: false,
        // 	width: 120,
        // 	fixed: "right",
        // 	align: "center",
        // 	slots: {
        // 		default: 'headersdefaultopr'
        // 	}
        // }
      ],
      rulesDatarule: {
        name: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
        ],
        sql: [
          {
            required: true,
            message: "自定义sql不能为空",
            trigger: "blur",
          },
        ],
      },
      datarules: [],
      openDatarule: false,
      formDatarule: {},
      dataruleList: [],
      openUrlWhitelist: false,
    };
  },
  created() {
    this.getList();
    this.getDicts("sys_app_type").then((response) => {
      this.appTypeOptions = response.data.values;
    });
    this.getDicts("sys_app_category").then((response) => {
      this.appCategoryOptions = response.data.values;
    });
  },
  methods: {
    // 列表筛选
    tableChange(e) {
      console.log(e);
    },
    // 选择图标
    selected(name) {
      this.form.icon = name;
    },
    /** 查询菜单列表 */
    getList() {
      if (this.userpools) {
        this.queryParams.userpool = this.userpools;
      }
      this.loading = true;
      listMenu(this.queryParams).then((response) => {
        this.menuList = this.handleTree(response.data.list, "id", "pid");
        this.visibleOptions = response.data.visibleOptions;
        this.statusOptions = response.data.statusOptions;
        this.loading = false;

        this.$nextTick(() => {
          console.log(this.$refs.jfTable);
          const jfTable = this.$refs.jfTable;
          const xGrid = jfTable.$refs.xGrid;
          const tableFullData = xGrid.getTableData().fullData;
          const rows = [];
          for (let j = 0; j < this.treeConfig.expandRowKeys.length; j++) {
            const k = this.treeConfig.expandRowKeys[j];
            for (let i = 0; i < tableFullData.length; i++) {
              const d = tableFullData[i];
              if (k == d.id) {
                rows.push(d);
                break;
              }
            }
          }
          xGrid.setTreeExpand(rows, true);
        });
      });
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      node.label = node.title;
      return {
        id: node.id,
        label: node.title,
        children: node.children,
      };
    },
    /** 查询菜单下拉树结构 */
    getTreeselect() {
      let poolinfo = {};
      if (this.userpools) {
        poolinfo.userpool = this.userpools;
      }
      listMenu(poolinfo).then((response) => {
        this.menuOptions = [];
        const menu = {
          id: 0,
          title: "主类目",
          children: [],
        };
        menu.children = this.handleTree(response.data.list, "id", "pid");
        this.menuOptions.push(menu);
      });
    },
    // 显示状态字典翻译
    visibleFormat(row) {
      if (row.menu_type == "2") {
        return "";
      }
      return this.selectDictLabel(this.visibleOptions.values, row.is_show + "");
    },
    // 菜单状态字典翻译
    statusFormat(row) {
      if (row.menu_type == "2") {
        return "";
      }
      return this.selectDictLabel(this.statusOptions.values, row.status + "");
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        pid: 0,
        auth: "",
        title: undefined,
        icon: undefined,
        menu_type: "0",
        sort: "0",
        is_frame: "0",
        is_show: "1",
        status: "1",
        path: "",
        component: "",
        remark: "",
        is_cache: "0",
        is_main: "0",
        is_app: "0",
        fold_type: "0",
        version: "",
        app_type: "0",
        app_category: "0",
        app_price: 0,
        data_rules: [],
      };
      this.datarules = [];
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset();
      this.getTreeselect();
      if (row != null) {
        this.form.pid = row.id;
      }
      this.readonly = false;
      this.title = "添加菜单";
      this.open = true;
    },
    /** 修改按钮操作 */
    handleUpdate(row, readonly) {
      this.reset();
      getMenu(row.id).then((menuResp) => {
        let poolinfos = {};
        if (this.userpools) {
          poolinfos.userpool = this.userpools;
        }
        listMenu(poolinfos).then((listResp) => {
          this.menuOptions = [];
          const menu = {
            id: 0,
            title: "主类目",
            children: [],
          };
          menu.children = this.handleTree(listResp.data.list, "id", "pid");
          this.menuOptions.push(menu);
          const menuInfo = menuResp.data;
          this.form = {
            id: menuInfo.id,
            pid: menuInfo.pid,
            auth: menuInfo.auth,
            title: menuInfo.title,
            icon: menuInfo.icon,
            menu_type: "" + menuInfo.menu_type,
            sort: menuInfo.sort,
            is_frame: "" + menuInfo.is_frame,
            is_show: "" + menuInfo.is_show,
            status: "" + menuInfo.status,
            path: menuInfo.path,
            component: menuInfo.component,
            remark: menuInfo.remark,
            is_cache: "" + menuInfo.is_cache,
            is_main: "" + menuInfo.is_main,
            is_app: "" + menuInfo.is_app,
            fold_type: "" + menuInfo.fold_type,
            version: menuInfo.version,
            app_type: menuInfo.app_type,
            app_category: menuInfo.app_category,
            app_price: menuInfo.app_price,
            data_rules: menuInfo.data_rules,
          };
          this.datarules = [].concat(menuInfo.data_rules);
          for (let i = 0; i < this.datarules.length; i++) {
            this.datarules[i].index = i + 1;
          }
          this.readonly = readonly;
          this.title = readonly ? "查看菜单" : "修改菜单";
          this.open = true;
        });
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.data_rules = [].concat(this.datarules);
          if (this.form.is_app == "0") {
            this.form.app_type = "0";
            this.form.app_category = "0";
            this.form.app_price = "0";
          }
          if (this.userpools) {
            this.form.userpool = this.userpools;
          }
          if (this.form.id != undefined) {
            updateMenu(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addMenu(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.title + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delMenu(row.id);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    toggleExpandChangeEvent() {
      const jfTable = this.$refs.jfTable;
      const xGrid = jfTable.$refs.xGrid;
      const treeExpandRecords = xGrid.getTreeExpandRecords();
      const expandRowKeys = [];
      for (let i = 0; i < treeExpandRecords.length; i++) {
        expandRowKeys.push(treeExpandRecords[i].id);
      }
      this.treeConfig.expandRowKeys = expandRowKeys;
    },
    handleAddDatarule() {
      this.formDataruleRowIndex = undefined;
      this.formDatarule = {};
      this.resetForm("formDatarule");
      this.openDatarule = true;
    },
    handleEditDatarule(row, rowIndex) {
      this.formDataruleRowIndex = rowIndex;
      this.formDatarule = {
        ...row,
      };
      this.resetForm("formDatarule");
      this.openDatarule = true;
    },
    handleDeleteDatarule(row, rowIndex) {
      this.$confirm("确定删除吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const datarules = [].concat(this.datarules);
          datarules.splice(rowIndex, 1);
          for (let i = 0; i < datarules.length; i++) {
            datarules[i].index = i + 1;
          }
          this.datarules = datarules;
        })
        .catch(function () {});
    },
    handleSubmitDatarule() {
      this.$refs["formDatarule"].validate((valid) => {
        if (valid) {
          this.openDatarule = false;
          if (this.formDataruleRowIndex == undefined) {
            this.datarules.push({
              ...this.formDatarule,
              index: this.datarules.length + 1,
            });
          } else {
            this.datarules[this.formDataruleRowIndex] = {
              ...this.formDatarule,
              index: this.formDataruleRowIndex + 1,
            };
            this.datarules = [].concat(this.datarules);
          }
        }
      });
    },
    handleWhitelist() {
      this.openUrlWhitelist = true;
    },
  },
};
</script>
<style scoped>
.sql_tip {
  margin-top: 5px;
  line-height: 20px;
  color: #999;
}
</style>
