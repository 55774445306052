<template>
    <div class="app-container">
        <el-row :gutter="20">
            <el-col :span="8">
                <el-card class="box-card">
                    <div class="d-flex justify-content-end">
                        <el-button
                            icon="el-icon-refresh"
                            type="text"
                            @click="refreshRoot"
                            >刷新</el-button
                        >
                    </div>
                    <el-tree
                        :props="props"
                        :load="loadNode"
                        lazy
                        ref="tree"
                        @node-click="nodeChange"
                        highlight-current
                        :default-expanded-keys="defaultExpandedKey"
                    >
                        <span
                            class="custom-tree-node"
                            slot-scope="{ node, data }"
                        >
                            <span>{{ node.label }}</span>
                            <span v-if="node.level == 3">
                                <el-button
                                    type="text"
                                    size="mini"
                                    @click.stop="() => remove(node, data)"
                                >
                                    删除
                                </el-button>
                            </span>
                        </span>
                    </el-tree>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card class="">
                    <div
                        class="syslog-list box-card"
                        @scroll="handleScroll"
                        ref="scrollContainer"
                    >
                        <div class="syslog-list-total" v-if="list.length > 0">
                            <el-input
                                placeholder="请输入关键字"
                                v-model="keywords"
                                class="input-with-select"
                                style="flex: 1;margin-right: 10px;"
                                @keydown.enter.prevent.native="nodeChange"
                            >
                                <el-button
                                    slot="append"
                                    icon="el-icon-search"
                                    @click="nodeChange"
                                ></el-button>
                            </el-input>
                            共{{ list.length }}条
                        </div>
                        <div
                            class="syslog-list-item"
                            v-for="(item, index) in tableData"
                            :key="index"
                        >
                            <span
                                v-text="index + 1"
                                class=""
                                style="padding-right: 10px"
                            ></span>
                            <span v-text="item.field"></span>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";
import { getData, deldata } from "@/api/core/monitor/syslog";
export default {
    name: "syslog",
    components: {
        MarkdownItVue,
    },
    data() {
        return {
            props: {
                label: "field",
                children: "children",
                isLeaf: "leaf",
            },
            detail: ``,
            options: {
                linkAttributes: {
                    attrs: {
                        target: "_blank",
                        rel: "noopener",
                    },
                },
                katex: {
                    throwOnError: false,
                    errorColor: "#cc0000",
                },
                icons: "font-awesome",
                githubToc: {
                    tocFirstLevel: 2,
                    tocLastLevel: 3,
                    tocClassName: "toc",
                    anchorLinkSymbol: "",
                    anchorLinkSpace: false,
                    anchorClassName: "anchor",
                    anchorLinkSymbolClassName: "octicon octicon-link",
                },
                mermaid: {
                    theme: "default",
                },
                image: {
                    hAlign: "left",
                    viewer: true,
                },
            },
            service_name: "",
            list: [],
            tableData: [],
            queryParams: {
                pageNum: 1,
                pageSize: 50,
            },
            rootNode: undefined,
            rootResolve: undefined,
            defaultExpandedKey: [],
            keywords: "",
        };
    },
    created() {},
    methods: {
        refreshRoot() {
            this.rootNode.childNodes = [];
            this.loadNode(this.rootNode, this.rootResolve);
            this.list = [];
            this.tableData = [];
            // this.$message.success('刷新成功')
        },
        loadNode(node, resolve) {
            if (node.level === 0) {
                this.rootNode = node;
                console.log(this.rootNode);
                this.rootResolve = resolve;
                this.getTreeData({
                    data_type: 1,
                }).then((res) => {
                    return resolve(res);
                });
            }
            if (node.level === 1) {
                this.getTreeData({
                    data_type: 2,
                    service_name: node.data.kfield,
                }).then((res) => {
                    if (!res.length) {
                        this.partialRefreshpartialRefresh(node.parent);
                    }
                    return resolve(res);
                });
            } else if (node.level === 2) {
                this.getTreeData({
                    data_type: 3,
                    service_name: node.parent.data.kfield,
                    level: node.data.kfield,
                }).then((res) => {
                    if (!res.length) {
                        this.partialRefreshpartialRefresh(node.parent);
                    }
                    return resolve(res);
                });
            }
            return resolve([]);
        },
        async getTreeData(query) {
            return new Promise(function (resolve, reject) {
                getData(query).then((res) => {
                    if (res.code == 0) {
                        resolve(res.data.data || []);
                    }
                });
            });
        },

        nodeChange(nodeData) {
            let tree = this.$refs.tree;
            if (
                tree.store &&
                tree.store.currentNode &&
                tree.store.currentNode.level == 3
            ) {
                let node = tree.store.currentNode;
                this.getTreeData({
                    data_type: 4,
                    service_name: node.parent.parent.data.kfield,
                    level: node.parent.data.kfield,
                    logdate: node.data.kfield,
                    sw:this.keywords
                }).then((res) => {
                    this.list = res;
                    this.queryParams.pageNum = 1;
                    this.getList();
                });
            }
        },
        //触底加载
        handleScroll() {
            const container = this.$refs.scrollContainer;
            // 判断是否接近底部
            const isNearBottom =
                container.scrollHeight - container.scrollTop <=
                container.clientHeight + 50;

            if (isNearBottom && this.list.length > this.tableData.length) {
                // 滚动到底部且table的长度小于接口返回列表长度，加载更多内容
                this.queryParams.pageNum++;
                this.getList();
            }
        },
        getList() {
            this.tableData = this.list.slice(
                0,
                this.queryParams.pageSize * this.queryParams.pageNum
            );
        },
        remove(node, data) {
            // console.log(node , this.$refs.tree.getNode(node.parent.data.id))
            // return
            let that = this;
            this.$confirm("是否确认删除该条日志?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    deldata({
                        service_name: node.parent.parent.data.kfield,
                        level: node.parent.data.kfield,
                        logdate: node.data.kfield,
                    }).then((res) => {
                        if (res.code == 0) {
                            this.list = [];
                            this.tableData = [];
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            that.partialRefreshpartialRefresh(node.parent);
                        }
                    });
                })
                .catch(() => {});
        },
        // 实现局部刷新，在点击弹窗处调用的
        partialRefreshpartialRefresh(node) {
            node.loaded = false;

            node.expand();
            // if (expand) {
            //     this.$nextTick(() => {
            //         let index = rootNode.childNodes.findIndex(
            //             (o) => o.data.kfield === node.data.kfield
            //         );
            //         console.log(rootNode,node, index);
            //         let nodedata = rootNode.childNodes[index];
            //         nodedata.expanded = true;
            //         nodedata.loadData();
            //     });
            // }
        },
    },
};
</script>
<style lang="scss" scoped>
.box-card {
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
    padding: 15px;
}
.syslog-list {
    &-total {
        font-size: 20px;
        display: flex;
        align-items: center;
    }
    &-item {
        padding: 10px;
        margin: 16px 0;
        background: rgba($color: #000000, $alpha: 0.08);
        font-size: 15px;
        line-height: 1.7;
    }
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
::v-deep .el-card__body {
    padding: 0;
}
</style>