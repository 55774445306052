<template>
  <dashboard :readonly="false" />
</template>

<script>
import Dashboard from './dashboard.vue'

export default {
  name: "dashboard-design",
  components: {
    'dashboard': Dashboard
  }
};
</script>
