<template>
    <el-form-item
        :label="i18nt('designer.setting.labelAlign')"
        v-if="!noLabelSetting && selectedWidget.type !== 'button'&& selectedWidget.options.labelPosition !=='top'&&!designer.isTopic"
    >
        <!-- <el-radio-group v-model="optionModel.labelAlign" class="radio-group-custom">
      <el-radio-button label="label-left-align">
        {{i18nt('designer.setting.leftAlign')}}</el-radio-button>
      <el-radio-button label="label-center-align">
        {{i18nt('designer.setting.centerAlign')}}</el-radio-button>
      <el-radio-button label="label-right-align">
        {{i18nt('designer.setting.rightAlign')}}</el-radio-button>
    </el-radio-group> -->
        <div class="btns-list">
            <div
                class="btns-item"
                @click="btnChange('label-left-align')"
                :class="{
                    active: optionModel.labelAlign === 'label-left-align',
                }"
            >
                {{ i18nt("designer.setting.leftAlign") }}
            </div>
            <div
                class="btns-item"
                @click="btnChange('label-center-align')"
                :class="{
                    active: optionModel.labelAlign === 'label-center-align',
                }"
            >
                {{ i18nt("designer.setting.centerAlign") }}
            </div>
            <div
                class="btns-item"
                @click="btnChange('label-right-align')"
                :class="{
                    active: optionModel.labelAlign === 'label-right-align',
                }"
            >
                {{ i18nt("designer.setting.rightAlign") }}
            </div>
        </div>
    </el-form-item>
</template>

<script>
import i18n from "@/vform/utils/i18n";

export default {
    name: "labelAlign-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    computed: {
        noLabelSetting() {
            return (
                this.selectedWidget.type === "static-text" ||
                this.selectedWidget.type === "html-text"
            );
            //|| (this.selectedWidget.type === 'divider')
        },
    },
    methods: {
        btnChange(e) {
            if (e === this.optionModel.labelAlign) {
                this.optionModel.labelAlign = "";
                return;
            }
            this.optionModel.labelAlign = e;
        },
    },
};
</script>

<style lang="scss" scoped>
.radio-group-custom {
    ::v-deep .el-radio-button__inner {
        padding-left: 12px;
        padding-right: 12px;
    }
    
}
.btns-list {
        margin: -2.5px;
        display: flex;
        flex-wrap: wrap;
        .btns-item {
            margin: 2.5px;
            border: 1px solid #dcdfe6;
            border-radius: 4px;
            padding: 6px 12px;
            line-height: 1;
            font-size: 12px;
            cursor: pointer;
            &.active {
                color: #ffffff;
                background-color: #1890ff;
                border-color: #1890ff;
            }
        }
    }
</style>
