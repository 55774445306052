import request from '@/utils/request'

// 查询流程分类列表
export function listBpmCategory(query) {
  return request({
    url: '/bpm/category/list',
    method: 'get',
    params: query
  })
}

// 查询流程分类详细
export function getBpmCategory(id) {
  return request({
    url: '/bpm/category/get?id=' + id,
    method: 'get'
  })
}

// 新增流程分类
export function addBpmCategory(data) {
  return request({
    url: '/bpm/category/add',
    method: 'post',
    data: data
  })
}

// 修改流程分类
export function editBpmCategory(data) {
  return request({
    url: '/bpm/category/edit',
    method: 'post',
    data: data
  })
}

// 删除流程分类
export function delBpmCategory(id) {
  return request({
    url: '/bpm/category/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
