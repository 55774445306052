<template>
    <div class="app-container">
        <div class="flow-action-btn">
            <!--<el-button  v-noMoreClick size="mini"  :loading="loading" icon="el-icon-printer">打印</el-button>-->
            <template v-for="(button, index) in buttons">
                <el-button
                    :type="button.type"
                    size="mini"
                    v-noMoreClick
                    v-if="button.show"
                    :loading="loading"
                    :icon="button.icon"
                    :key="index"
                    @click="doSubmit(button)"
                >
                    {{ button.name }}
                </el-button>
            </template>
        </div>
        <el-tabs v-model="activeStep" class="task-tab">
            <el-tab-pane label="表单信息" name="form">
                <span slot="label"> <i class="fa fa-tasks"></i> 表单信息 </span>
                <el-card class="form-card">
                    <div slot="header" class="clearfix">
                        <span><i class="fa fa-edit"></i> 表单信息</span>
                        <el-button
                            style="float: right; padding: 3px 0"
                            size="mini"
                            :icon="
                                expandedLink
                                    ? 'el-icon-d-arrow-right'
                                    : 'el-icon-d-arrow-left'
                            "
                            type="text"
                            @click="expandedLink = !expandedLink"
                            >{{ expandedLink ? "折叠" : "展开" }}</el-button
                        >
                    </div>
                    <el-row>
                        <el-col
                            :span="expandedLink ? 16 : 24"
                            :class="expandedLink ? 'expanded-col' : ''"
                        >
                            <template v-if="showComp">
                                <generate-form
                                    ref="form"
                                    :conf="resourceData.formData"
                                    v-if="resourceData && resourceData.formData"
                                    @formMounted="onFormMounted"
                                />
                            </template>
                        </el-col>
                        <el-col :span="8" v-show="expandedLink">
                            <bpm-process-link
                                :steps-data="stepsData"
                                :flow-name="flowName"
                                :title="title"
                                :process-id="flowInstId"
                                :process-no="processNo"
                                :task-data="taskData"
                                @doPrint="doSumitCode('print')"
                            ></bpm-process-link>
                        </el-col>
                    </el-row>
                </el-card>
            </el-tab-pane>
            <el-tab-pane
                label="执行流"
                name="branch"
                v-if="false && flowInstId > 0"
            >
                <span slot="label">
                    <i class="fa fa-share-alt"></i> 执行流
                </span>
                <el-card class="history-card">
                    <div slot="header" class="clearfix">
                        <span><i class="fa fa-share-alt"></i> 执行流</span>
                    </div>
                    <el-steps
                        direction="vertical"
                        :active="taskStep + 1"
                        :space="100"
                    >
                        <template v-for="(node, index) in nodeList">
                            <el-step
                                :title="node.title"
                                :description="node.content ? node.content : ''"
                            ></el-step>
                        </template>
                    </el-steps>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="流转信息" name="step" v-if="false">
                <span slot="label">
                    <i class="fa fa-history"></i> 流转信息
                </span>
                <el-card class="history-card">
                    <div slot="header" class="clearfix">
                        <span><i class="fa fa-history"></i> 流转信息</span>
                    </div>
                    <el-steps :active="taskList.length">
                        <template v-for="(step, index) in taskList">
                            <el-step
                                :title="step.node_name"
                                :description="`${
                                    step.assignee_name
                                },${parseTime(step.start_time)}`"
                            ></el-step>
                        </template>
                    </el-steps>
                    <br />
                    <el-table :data="taskList" border style="width: 100%">
                        <el-table-column
                            type="index"
                            label="#"
                        ></el-table-column>
                        <el-table-column
                            prop="node_name"
                            label="名称"
                        ></el-table-column>
                        <el-table-column
                            prop="assignee_name"
                            label="处理人"
                        ></el-table-column>
                        <el-table-column
                            prop="assignee_view"
                            label="处理意见"
                        ></el-table-column>
                        <el-table-column prop="start_time" label="开始时间">
                            <template slot-scope="scope">
                                <span>{{
                                    parseTime(scope.row.start_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="end_time" label="结束时间">
                            <template slot-scope="scope">
                                <span>{{ parseTime(scope.row.end_time) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="duration" label="任务历时">
                            <template slot-scope="scope">
                                <span>{{
                                    formatDuration(scope.row.duration)
                                }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="流程图" name="four">
                <span slot="label">
                    <i class="fa fa-file-picture-o"></i> 流程图
                </span>
                <el-card class="chart-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span><i class="fa fa-file-picture-o"></i> 流程图</span>
                    </div>
                    <div class="flow-container">
                        <FlowCard
                            v-if="showComp"
                            :data="resourceData.processData"
                        />
                    </div>
                </el-card>
            </el-tab-pane>
            <el-tab-pane name="processTitle" disabled>
                <template slot="label">
                    <div
                        class="flow-title"
                        style="color: #606266; font-weight: bold"
                    >
                        <i class="fa fa-info-circle"></i> {{ title }}
                    </div>
                </template>
            </el-tab-pane>
        </el-tabs>

        <div
            class="task-footer"
            v-if="taskData.is_initiator && taskData.processFinished === '0'"
        >
            <el-button
                v-noMoreClick
                size="small"
                type="warning"
                :loading="loading"
                icon="fa fa-mail-reply"
                @click="doSumitCode('withdraw')"
            >
                撤回
            </el-button>
            <el-button
                v-noMoreClick
                size="small"
                :loading="loading"
                icon="el-icon-printer"
                v-if="false"
                >打印</el-button
            >
        </div>
    </div>
</template>
<script>
import { getResourceStepData, getResourceTaskData } from "@/api/bpm/resource";
import { getProcessTaskList, withdrawTask } from "@/api/bpm/task";
import { PrintProcess } from "@/api/bpm/process";
import GenerateForm from "@/components/jpgflow/DynamicForm/GenerateForm";
import FlowCard from "@/components/jpgflow/Process/FlowCard/index.vue";
import AdminSelect from "@/components/AdminSelect";

import BpmProcessLink from "../components/ProcessLink";

export default {
    name: "bpm-task-view",
    components: {
        GenerateForm,
        FlowCard,
        AdminSelect,
        BpmProcessLink,
    },
    watch: {
        "taskForm.comment": {
            handler(newVal) {
                //this.$refs.form.setData(newVal,'field2')
            },
        },
    },
    data() {
        return {
            resourceData: {}, // 可选择诸如 $route.param，Ajax获取数据等方式自行注入
            taskFormData: {},
            activeStep: "form", // 激活的步骤面板
            showComp: false,
            loading: false,
            expandedLink: true,
            title: "",
            flowId: 0,
            flowName: "",
            processNo: "",
            flowInstId: this.$route.query.id || 0,
            taskId: this.$route.query.taskId || 0,
            taskStep: 0,
            stepsData: [],
            taskForm: {
                code: "",
                comment: "",
                candidates: null,
                ccs: null,
            },
            taskData: {
                is_candicate: false,
                step: 0,
            },
            resourceId: this.$route.query.resourceId || 0,
            time: this.$route.query.time || this.XEUtils.now(),
            buttons: [
                {
                    code: "print",
                    name: "打印",
                    type: "",
                    icon: "fa fa-print",
                    show: true,
                },
            ],
            taskList: [],
            nodeList: [],
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.showComp = false;
            if (this.resourceId <= 0) {
                return;
            }
            this.$nextTick(() => {
                //读取任务数据 resourceId,taskId,processId
                getResourceTaskData(
                    this.resourceId,
                    this.taskId,
                    this.flowInstId
                )
                    .then((response) => {
                        let data = response.data;
                        if (data) {
                            this.taskData = data;
                            this.nodeList = JSON.parse(
                                data.processExecution.node_infos
                            );
                            let resourceData = JSON.parse(data.resource);
                            //禁用全部
                            resourceData.formData.disabled = true;
                            this.taskStep = data.step;
                            this.activeStep = "form"; // 激活的步骤面板
                            this.showComp = true;
                            this.processNo = data.processNo;
                            this.flowId = data.flow_id;
                            this.flowName = data.flow_name;
                            this.title = data.title;
                            this.taskFormData = data.vars;
                            resourceData.formData.fields.forEach((field) => {
                                field.defaultValue =
                                    this.taskFormData[field.vModel];
                                Array.isArray(field.children) &&
                                    field.children.forEach((childField) => {
                                        childField.defaultValue =
                                            this.taskFormData[
                                                childField.vModel
                                            ];
                                        childField.disabled = true;
                                    });
                                //field.required = false
                                field.disabled = true;
                            });
                            this.resourceData = resourceData;
                        }
                    })
                    .catch((e) => {
                        this.handleError("请求错误", "/bpm/process/myList");
                        return;
                    });
                getResourceStepData(this.resourceId, this.flowInstId).then(
                    (response) => {
                        //处理包含%字符 decodeURIComponent方法报错的bug
                        var stepsorg = response.data.steps;
                        var rpstr = stepsorg.replace("%", "__BFH__");
                        rpstr = decodeURIComponent(rpstr);
                        rpstr = rpstr.replace("__BFH__", "%");
                        let steps = JSON.parse(rpstr);

                        this.stepsData = steps;
                    }
                );
                // 读取任务列表
                getProcessTaskList(this.flowInstId).then((response) => {
                    this.taskList = response.data.list;
                });
            });
        },
        onFormMounted() {
            /*let vars = this.taskFormData
      for (let key in vars)
      {
        this.$refs.form.setData(vars[key], key)
      }*/
        },
        handleError(msg, path) {
            path = path || "/bpm/task/flowList";
            this.msgError(msg);
            this.$store.dispatch("tagsView/delView", this.$route);
            this.$router.push({ path: path });
        },
        handleOpinionChange(value) {
            if (value != "") {
                this.taskForm.comment = value;
            }
        },
        print() {
            //打印
            PrintProcess(this.flowInstId, this.processNo);
        },
        withdraw() {
            //撤回任务
            withdrawTask({
                ...this.taskForm,
                resourceId: this.resourceId,
                processId: this.flowInstId,
                taskId: this.taskId,
                code: this.taskForm.code,
                vars: "{}",
            }).then((response) => {
                //let ret = response.data
                this.msgSuccess("撤回成功");
                this.$store.dispatch("tagsView/delView", this.$route);
                this.$router.push({ path: "/bpm/process/myList" });
            });
        },
        doSumitCode(code) {
            this.doSubmit({
                code: code,
            });
        },
        doSubmit(button) {
            this.activeStep = "form";
            this.taskForm.code = button.code;
            switch (button.code) {
                case "print": // 打印
                    this.print();
                    break;
                case "withdraw": //撤回
                    this.withdraw();
                    break;
                default:
                    break;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.task-card {
    margin-top: 15px;
    margin-bottom: 60px;
}

.card-bottom,
.history-card {
    margin-bottom: 60px;
}

.opinion-role {
    position: relative;

    .action {
        position: absolute;
        top: -1px;
        left: 210px;
    }
}
.task-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 200px;
    height: 60px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: #fff;
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.12);
    transition: inline-block 0.3s, left 0.3s, width 0.3s, margin-left 0.3s,
        font-size 0.3s;
    -webkit-transition: inline-block 0.3s, left 0.3s, width 0.3s,
        margin-left 0.3s, font-size 0.3s;
}

.el-button [class*="fa-"] + span {
    margin-left: 5px;
}

.hideSidebar .task-footer {
    left: 54px;
}

.task-tab {
}

.flow-action-btn {
    right: 20px;
    position: absolute;
    top: 25px;
    z-index: 500;
}

.chart-card {
    margin-bottom: 60px;

    .el-card__body {
        /*padding: 0;*/
        /*background: #f5f5f7;*/
    }

    .flow-container {
        display: inline-block;
        padding: 20px 0;
        background: #f5f5f7;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        text-align: center;
        overflow: auto;
    }
}
.expanded-col {
    margin-right: -1px;
    border-right: 1px solid #e6ebf5;
}
</style>
