<template>
 <div>
   <el-row>      
     <el-col :span="24" class="card-box" >
        <el-card > 
          <vxe-grid            
            ref="xGrid"
            stripe         
            height="500px"            
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="deviceChildrenList"
            :column-config="{resizable: false}"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
            <template #toolbar_buttons >   
              <el-button
                style="margin-left: 10px"  
                icon="el-icon-plus"
                size="mini"     
                 @click="handleAdd"                        
              >添加子设备</el-button>
              <el-button
                type="success"
                icon="el-icon-edit"
                size="mini"
                :disabled="single"
                @click="handleUpdate"
              >修改</el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
              >删除</el-button>
            </template>

            <!--默认操作按钮-->
            <template #defaultopr="{ row }">
              <el-button size="mini" type="text" icon="el-icon-view">
                <router-link :to="'/iot/device/info/'+row.id" class="link-type" style="align-items: center;">
                  <span>查看</span>
                </router-link>
              </el-button>
              <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
              >修改
              </el-button>
              <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
              >删除
              </el-button>
            </template>
            <template #data_list="{ row }">
              <router-link :to="'/iot/device/info/'+row.id" class="link-type">
                <span>{{ row.device_name }}</span>
              </router-link>
            </template>

            <!--自定义空数据模板-->
            <template #empty>
                  <span >
                    <p>暂无数据</p>
                  </span>
            </template>

          </vxe-grid>
          <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
          </vxe-pager>
        </el-card>
      </el-col>      
    </el-row>

    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">        
        <el-form-item label="所属产品" prop="product_id" :required="true">
          <el-select v-model="form.product_id" placeholder="请选择所属产品" clearable size="small" >
            <el-option
              v-for="dict in tempproductList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称" prop="device_name" :required="true">
          <el-input v-model="form.device_name" placeholder="输入设备名称" />
        </el-form-item> 
        <el-form-item label="设备标识码" prop="device_check">
          <el-input v-model="form.device_check" placeholder="不填则系统将自动生成" />
        </el-form-item>               
        <el-form-item label="自定义密钥" prop="device_secret">
          <el-input v-model="form.device_secret" placeholder="不填则系统将自动生成" />
        </el-form-item>                 
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>  
 </div>
</template>

<script>
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";
import { listIotProduct, getIotProduct, addIotProduct, updateIotProduct, delIotProduct } from "@/api/iot/product";

export default {
  props: {
    deviceId: {
      type: String
    }
  },
  data() {   
    return {
      device_id:0,
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [  
        {type: 'checkbox', width: 30, fixed: "left"},
        {field: 'conn_status', title: '状态' , width: 80,formatter:this.ConnectFormat },
        {field: '', title: '设备名称',slots: {default: 'data_list'} },
        {field: 'device_check', title: '设备标识码' },
        {field: 'product_id', title: '所属产品' ,formatter:this.ProductFormat},
        {field: 'device_type', title: '设备类型' },
        {field: 'Remark', title: '描述' },
        {field: '', title: '操作',resizable:false, width: 190, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      title: "",
      // 是否显示弹出层
      open: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        device_name: "",
        device_check:"",
        device_code:"",
        father_id:0
      },     
      deviceChildrenList:[],
      tempproductList:[],
      productList:[],
      statusOptions: [], 
      deviceData:{},
      // 表单参数
      form: {},
      // 表单校验
      rules: {        
        device_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ]
      }
    }
  }, 
  created() {   
    if(this.deviceId != undefined){
      this.device_id=parseInt(this.deviceId);  
      if(this.device_id>0){
        this.getDevice();        
      }   
          
    }else{
      this.msgError("获取设备信息失败！");
    }
    
  },
  methods: {
    getDevice(){      
      this.loading = true;
      getIotDevice(this.device_id).then(response=>{
        this.deviceData=response.data;
        this.getProductList();
      });
    },
    getList() {
      if(this.device_id>0){        
        this.queryParams.father_id=this.device_id;
        listIotDevice(this.queryParams).then(response => {
          this.single = true;
          this.multiple = true;
          this.deviceChildrenList = response.data.list;        
          this.total = response.data.total;
          this.statusOptions = response.data.statusOptions.values;
          this.loading = false;
        });
      }
      
    },
    getProductList(){
      var proquery={
        pageNum: 1,
        pageSize: 10,        
        case_id: this.deviceData.case_id,
        space_id: this.deviceData.space_id
      };
      listIotProduct(proquery).then( response => {
        this.tempproductList=response.data.list;
        this.getList();        
      });
    },
    ProductFormat({row}){
      var label="";     
      this.tempproductList.forEach(element => {
        if (element.id === row.product_id)
          label=element.name;
      });
      return label
    },
    ConnectFormat({row}){
      return this.selectDictLabel(this.statusOptions, row.conn_status + '');
    },
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加子设备";      
    }, 
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getIotDevice(id).then(response => {
        this.form = response.data;
        this.open = true;        
        this.title = "修改设备";
      });
    },
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
            updateIotDevice(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            this.form.father_id=this.device_id;
            this.form.space_id=this.deviceData.space_id;
            this.form.case_id=this.deviceData.case_id,
            addIotDevice(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("添加子设备成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,       
        device_name: "",
        device_secret: "",
        device_check: "",
        conn_status: 0
      };
      this.resetForm("form");
    },
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotDevice(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    }  
  }
}
</script>