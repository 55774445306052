<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="消息标题" prop="keyword">
                <el-input
                    v-model="queryParams.keyword"
                    placeholder="请输入消息标题"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <!--<el-form-item label="发送时间" prop="name">
                <el-date-picker
                    v-model="queryParams.time"
                    type="datetimerange"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                >
                </el-date-picker>
            </el-form-item>-->

            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <!--<el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/message/add']"
                    >发私信</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/message/detele']"
                    >删除</el-button
                >-->
                <el-button
                    type="warning"
                    size="mini"
                    @click="handleReadAll"
                    v-hasPermi="['/message/read']"
                    >全部已读</el-button
                >
            </template>
            <!-- 	<template slot="toolbar_btn_right">
				
			</template> -->
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button size="mini" type="text" icon="el-icon-view" @click.stop="handleView(row)">查看
            </el-button>
                <!-- <el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button> -->
            </template>
        </jf-table>

        <!-- 编辑 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发送人" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入发送人"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="消息标题" prop="title">
                            <el-input
                                v-model="form.title"
                                placeholder="请输入消息标题"
                                clearable
                            />
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="消息类型" prop="type">
                            <el-input
                                v-model="form.type"
                                placeholder="请输入学号"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发送时间" prop="time">
                            <el-date-picker
                                v-model="form.time"
                                type="datetime"
                                prefix-icon="el-icon-date"
                                placeholder="选择发送时间"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="消息内容" prop="message">
                            <el-input
                                v-model="form.message"
                                placeholder="请输入消息内容"
                                type="textarea"
                                :rows="8"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                  
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>

        <message-dialog ref="messageDialog"></message-dialog>
    </div>
</template>
<script>
import { myMessage, readMessage } from '@/api/core/system/message'
import MessageDialog from "./message-dialog"
import {EventBus} from "@/utils/eventBus.js"
export default {
    name: "message-index",
    components: {
        "message-dialog": MessageDialog,
    },
    data() {
        return {
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                keyword: undefined,
            },
            loading: false,
            total: 0,
            ids: [],
            dataList: [],
            open: false,
            form: {},
            title: "",
            single: true,
            multiple: true,
            rules: {
                name: [
                    {
                        required: true,
                        message: "发送人不能为空",
                        trigger: "blur",
                    },
                ],
                title: [
                    {
                        required: true,
                        message: "信息标题不能为空",
                        trigger: "blur",
                    },
                ],
            },
            tableColumn: [
                // {
                //     field: "no",
                //     title: "发送人",
                //     minWidth: 120,
                //     // sortable: true,
                //     isCheck: true,
                //     form_type: "1",
                // },
                {
                    field: "title",
                    title: "消息标题",
                    minWidth: 120,
                    // sortable: true,
                    //isCheck: true,
                    form_type: "1",
                },
                {
                    field: "content",
                    title: "消息内容",
                    minWidth: 120,
                    //isCheck: true,
                    form_type: "1",
                },

                {
                    field: "create_time",
                    title: "发送时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    // sortable: true,
                    //isCheck: true,
                    form_type: "3",
                },
            ],

            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
        };
    },
    created() {
      this.getList()
    },
    methods: {
        tableChange(e) {
            console.log(e);
        },
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },
        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getMessage(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editMessage(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addMessage(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },
        cancel() {
            this.open = false;
            this.reset();
        },
        getList() {
            // this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            myMessage(this.queryParams).then((response) => {
              this.dataList = response.data.list;
              this.total = response.data.total;
              this.loading = false;
           });
        },

        reset() {
            this.form = {
                name: undefined,
                no: undefined,
            };
            this.resetForm("form");
        },

        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleReadAll() {
          this.$confirm('确定设置全部已读吗?', "警告", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              readMessage(0).then(res => {
                this.msgSuccess('设置全部已读成功');
                EventBus.$emit("readMeg", 'readMessage');
              })
            })
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delMessage(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        // 查看
        handleView(row){
            this.$refs.messageDialog.showView(row)
        }
    },
};
</script>