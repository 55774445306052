<template>
 <div>
   <el-row>      
     <el-col :span="24" class="card-box" >
        <el-card >          
          <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px"> 
            <el-form-item  prop="msg_status">
              <el-select v-model="queryParams.msg_status" placeholder="所有消息状态" clearable size="small">
                <el-option
                  v-for="(item,key) in statusList"
                  :key="key"
                  :label="item.value"
                  :value="key"
                />
              </el-select>
            </el-form-item> 
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-form>
          <el-row/>
          <vxe-grid            
            ref="xGrid"
            stripe           
            height="500px"
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="messageList"
            :column-config="{resizable: false}"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
            <template #toolbar_buttons >   
              <el-button
                style="margin-left: 10px"  
                size="mini"                             
              >修改跟踪配置</el-button>
              <el-button
                style="margin-left: 10px"  
                type="danger"
                size="mini"                             
              >导出数据</el-button>
            </template>

            <!--默认操作按钮-->
            <template #defaultopr="{ row }">              
              <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
              >撤销
              </el-button>
            </template>

            <!--自定义空数据模板-->
            <template #empty>
                  <span >
                    <p>暂无数据</p>
                  </span>
            </template>

          </vxe-grid>
          <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
          </vxe-pager>
        </el-card>
      </el-col>      
    </el-row>
 </div>
</template>

<script>
import { addIotDeviceMsg, getIotDeviceMsg, updateIotDeviceMsg, delIotDeviceMsg,listIotDeviceMsg } from "@/api/iot/devicemsg";
export default {
  props: {
    deviceId: {
      type: String
    }
  },
  data() {   
    return {
      device_id:0,
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [  
        {field: 'msg_type', title: '业务类型' },
        {field: 'msg_step', title: '业务步骤' },
        {field: 'order_code', title: '流水单号' },
        {field: 'msg_context', title: '业务详情' ,formatter:this.TopicFormat },
        {field: 'create_time', title: '记录时间' },
        {field: 'msg_status', title: '消息状态' },
        {field: '', title: '操作',resizable:false, width: 60, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      title: "",
      // 是否显示弹出层
      open: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        device_id: 0,
        msg_status: 0
      },      
      messageList:[],
      statusOptions:[],
      statusList:[{key:0,value:"所有消息状态"},{key:1,value:"成功"},{key:1,value:"失败"}],
      // 表单参数
      form: {},
      // 表单校验
      rules: {        
        top_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ]
      }
    }
  }, 
  created() {   
    if(this.deviceId != undefined){
      this.device_id=parseInt(this.deviceId); 
      this.loading=true;
      this.getList();     
    }else{
      this.msgError("获取设备信息失败！");
    }
    
  },
  methods: {
    getList(){
      if(this.device_id>0){
        this.queryParams.device_id=this.device_id;
        listIotDeviceMsg(this.queryParams).then(response=>{
          this.messageList=response.data.list;
          this.statusOptions = response.data.statusOptions.values;
          this.loading=false;
        });
      }
    },
  
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除消息编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotDeviceMsg(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    }
  }
}
</script>