<template>
  <div class="app-container">
    <el-row>
      <el-col :span="24" class="card-box">
        <el-card>
          <div slot="header">
            <div v-if="form.father_id<=0" style="display:inline-block;font-weight:bolder;">设备详情</div>
            <div v-else style="display:inline-block;font-weight:bolder;">子设备详情</div>  
          </div>
          <el-tabs v-model="activeTab" type="border-card">
            <el-tab-pane label="概述" name="deviceInfo" :lazy="true">
              <deviceInfo :deviceId="deviceid" />
            </el-tab-pane>
            <el-tab-pane label="命令" name="deviceCommand" :lazy="true">
              <deviceCommand :deviceId="deviceid" />
            </el-tab-pane>
            <el-tab-pane label="设备影子(属性)" name="deviceShadow" :lazy="true">
              <deviceShadow :deviceId="deviceid" />
            </el-tab-pane>
            <el-tab-pane label="消息跟踪" name="deviceMsg" :lazy="true">
              <deviceMsg :deviceId="deviceid" />
            </el-tab-pane>
            <el-tab-pane label="维护" name="deviceMaintain" :lazy="true">
              <deviceMaintain :deviceId="deviceid" />
            </el-tab-pane>
            <el-tab-pane v-if="form.father_id<=0" label="子设备" name="deviceChildren" :lazy="true">
              <deviceChildren :deviceId="deviceid" />
            </el-tab-pane>
            <el-tab-pane label="设备备注" name="deviceLabel" :lazy="true">
              <deviceLabel :deviceId="deviceid" />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col> 
    </el-row>
  </div>
</template>

<script>
import deviceInfo from "./deviceInfo";
import deviceCommand from "./deviceCommand";
import deviceShadow from "./deviceShadow";
import deviceMsg from "./deviceMsg";
import deviceMaintain from "./deviceMaintain";
import deviceChildren from "./deviceChildren";
import deviceLabel from "./deviceLabel";
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";
export default {
  name: "DeviceInfo",
  components: { deviceInfo,deviceCommand,deviceShadow,deviceMsg,deviceMaintain,deviceChildren,deviceLabel },
  data() {
    return {
      activeTab: "deviceInfo",     
      deviceid:0 ,
      form:{}
      
    };
  },
  created() {
    this.deviceid = this.$route.params && this.$route.params.id;      
    if(this.deviceid>0)
      this.getDevice();
  },
  methods: {   
    getDevice(){
      getIotDevice(this.deviceid).then(response=>{
        this.form=response.data;
      })
    }
  }
};
</script>