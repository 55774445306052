<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
      label-position="left"
    >
      <el-form-item label="系统模块" prop="title">
        <el-input
          v-model="queryParams.title"
          placeholder="请输入系统模块"
          clearable
          style="width: 240px"
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="用户名" prop="username">
        <el-input
          v-model="queryParams.username"
          placeholder="请输入用户名"
          clearable
          style="width: 240px"
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <!--<el-form-item label="类型" prop="business_type">
        <el-select
          v-model="queryParams.business_type"
          placeholder="操作类型"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in typeOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>-->
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="操作状态"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="操作时间">
        <el-date-picker
          v-model="dateRange"
          size="small"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <jf-table
      :loading="loading"
      :columns="tableColumn"
      :data="list"
      :toolbar-config="tableToolbar"
      :query-params="queryParams"
      :total="total"
      @checkbox-change="checkboxChangeEvent"
      @change="tableChange"
      @page-change="handleSizeChange"
    >
      <template slot="toolbar_btn_left">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          >删除</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          @click="handleClean"
          >清空</el-button
        >
      </template>

      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-view"
          @click="handleView(row)"
          >详细</el-button
        >
      </template>

      <template slot="status" slot-scope="{ row }">
        {{ statusFormat(row) }}
      </template>
    </jf-table>
    <!-- 	<vxe-grid resizable ref="xGrid" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="list" @checkbox-change="checkboxChangeEvent"
			@checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="danger" icon="el-icon-delete" size="mini"
					:disabled="multiple" @click="handleDelete">删除</el-button>
				<el-button type="danger" icon="el-icon-delete" size="mini" @click="handleClean">清空</el-button>
			</template> -->

    <!--自定义空数据模板-->
    <!-- 			<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>
			<template #detail="{row}">
				<el-button size="mini" type="text" icon="el-icon-view" @click="handleView(row)">详细</el-button>
			</template>
		</vxe-grid>
		<vxe-pager background size="small" :loading="loading" :current-page="queryParams.pageNum"
			:page-size="queryParams.pageSize" :total="total" @page-change="handleSizeChange">
		</vxe-pager> -->

    <!-- 操作日志详细 -->
    <el-dialog
      title="操作日志详细"
      :visible.sync="open"
      width="700px"
      append-to-body
    >
      <el-form ref="form" :model="form" label-width="100px" size="mini">
        <el-row>
          <el-col v-if="form.title" :span="24">
            <el-form-item label="操作模块：">{{ form.title }} </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="用户ID"
              >{{ form.user_id }} / {{ form.ip }} /
              {{ form.location }}</el-form-item
            >
          </el-col>

          <el-col :span="24">
            <el-form-item label="请求方式：">{{
              form.request_method
            }}</el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="操作方法：">{{ form.method }}</el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="请求参数：">{{
              form.request_param
            }}</el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="返回数据：">{{
              form.json_result
            }}</el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="操作状态：">
              <div v-if="form.status === '1'">成功</div>
              <div v-else>失败</div>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="操作时间：">{{
              parseTime(form.oper_time)
            }}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { list, delOperlog, clearOperlog } from "@/api/ucenter/monitor/operlog";

export default {
  name: "ucenter-monitor-operlog",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 列
      tableColumn: [
        // {
        // 	type: 'checkbox',
        // 	width: 60,
        // 	fixed: "left"
        // },
        {
          field: "id",
          title: "id",
          fixed: "left",
          visible: false,
        },

        {
          field: "username",
          title: "用户名",
        },
        {
          field: "method",
          title: "操作方法",
        },
        {
          field: "request_method",
          title: "操作类型",
        },
        {
          field: "ip",
          title: "ip",
        },
        {
          field: "location",
          title: "操作地点",
          width: 100,
        },
        {
          field: "status",
          title: "操作状态",
          width: 100,
          // formatter: this.statusFormat
          slots: {
            default: "status",
          },
          options: [],
          options_label: "value",
          options_value: "key",
          form_type: 2,
        },
        {
          field: "oper_time",
          title: "操作时间",
          width: 180,
          formatter: "formatDate",
          form_type: 3,
        },
        // {
        // 	field: '',
        // 	title: '操作',
        // 	resizable: false,
        // 	width: 180,
        // 	fixed: "right",
        // 	align: "center",
        // 	slots: {
        // 		default: 'detail'
        // 	}
        // }
      ],

      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 是否显示弹出层
      open: false,
      // 类型数据字典
      typeOptions: [
        {
          key: "1",
          value: "新增",
        },
        {
          key: "2",
          value: "修改",
        },
        {
          key: "3",
          value: "删除",
        },
        {
          key: "4",
          value: "授权",
        },
        {
          key: "5",
          value: "导出",
        },
        {
          key: "6",
          value: "导入",
        },
        {
          key: "7",
          value: "强退",
        },
        {
          key: "8",
          value: "生成代码",
        },
        {
          key: "9",
          value: "清空数据",
        },
      ],
      // 类型数据字典
      statusOptions: [],
      // 日期范围
      dateRange: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: undefined,
        username: undefined,
        business_type: undefined,
        status: undefined,
      },
    };
  },
  created() {
    this.getList();
    // this.getDicts("sys_oper_type").then(response => {
    //   this.typeOptions = response.data;
    // });
    //this.getDicts("sysCommonStatus").then(response => {
    //  this.statusOptions = response.data.values || [];
    //});
  },
  methods: {
    getUserPool() {
      const sps = this.$route.path.split("/");
      return sps[2];
    },
    // 列表筛选
    tableChange(e) {
      console.log(e);
    },
    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },
    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },
    /** 查询登录日志 */
    getList() {
      this.loading = true;
      list(
        this.getUserPool(),
        this.addDateRange(this.queryParams, this.dateRange)
      ).then((response) => {
        this.single = true;
        this.multiple = true;
        this.list = response.data.list;
        this.total = response.data.total;
        this.statusOptions = response.data.searchStatus.values;
        this.loading = false;
        for (let i = 0; i < this.tableColumn.length; i++) {
          if (this.tableColumn[i].field === "status") {
            this.tableColumn[i].options = this.statusOptions;
          }
        }
      });
    },
    // 操作日志状态字典翻译
    statusFormat(row) {
      return this.selectDictLabel(this.statusOptions, row.status);
    },
    // 操作日志类型字典翻译
    typeFormat(row, column) {
      return this.selectDictLabel(this.typeOptions, row.business_type);
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.multiple = !selection.length;
    },
    /** 详细按钮操作 */
    handleView(row) {
      this.open = true;
      this.form = row;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const operIds = row.id || (this.ids ? this.ids.join(",") : "");
      this.$confirm("确定删除吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return delOperlog(this.getUserPool(), operIds);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    /** 清空按钮操作 */
    handleClean() {
      this.$confirm("确定清空所有操作日志数据项吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return clearOperlog(this.getUserPool());
        })
        .then(() => {
          this.getList();
          this.msgSuccess("清空成功");
        })
        .catch(function () {});
    },
  },
};
</script>
