import request from '@/utils/request'
// 生成时间:2022-04-01 16:11:29

// 查询rule列表
export function listRule(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/dncblacklist/rule/list',
        method: 'post',
        data: data
    })
}

// 查询rule详细
export function getRule (id) {
    return request({
        url: '/dncblacklist/rule/get?id='+id,
        method: 'get'
    })
}


// 新增rule
export function addRule(data) {
    return request({
        url: '/dncblacklist/rule/add',
        method: 'post',
        data: data
    })
}

// 修改rule
export function editRule(data) {
    return request({
        url: '/dncblacklist/rule/edit',
        method: 'post',
        data: data
    })
}

// 删除rule
export function delRule(ids) {
    return request({
        url: '/dncblacklist/rule/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 删除rule查询模板
export function advquerydelRule(ids) {
    return request({
        url: '/dncblacklist/rule/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加rule查询模板
export function advquerysaveRule(data) {
    return request({
        url: '/dncblacklist/rule/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表rule查询模板列表
export function advquerylistRule(businessname) {
    return request({
        url: '/dncblacklist/rule/advquerylist?businessname='+businessname,
        method: 'get'
    })
}
// 导出rule
export function exportRule(query,exportfields,tableColumn) {
     var data={
            filterstr:"",
            orderby:"",
            opstr:query.opstr ||{},
        };
        var filterstr={};
        for(var k in query){
         var item=query[k];
         if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
           filterstr[k]=item;
         }
        }
        var tarexportfieldslist={};
        for(var k in tableColumn){
          var tlbf = tableColumn[k];
           for(var i in exportfields){
             var ef = exportfields[i]
             if(tlbf.field!="" && tlbf.field && tlbf.field!=null && tlbf.field == ef){
               tarexportfieldslist.push({"filed":ef,"title":tlbf.title});
               break;
             }
           }
        }

        data["filterstr"] = JSON.stringify(filterstr);
        data["opstr"] = JSON.stringify(data["opstr"]);
        data["exportfields"] = JSON.stringify(tarexportfieldslist);
        return request({
            url: '/dncblacklist/rule/export',
            method: 'post',
            data: data
        })
}
