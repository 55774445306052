var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        _vm.designer.isTopic &&
        _vm.selectedWidget.options.scoringMethod &&
        _vm.selectedWidget.options.scoringMethod * 1 < 5
    )?_c('div',[(_vm.selectedWidget.options.answer)?_c('el-form-item',{attrs:{"label":(_vm.selectedWidget.options.scoringMethod * 1 < 3
                ? '此项'
                : '各项') + _vm.i18nt('designer.setting.scoring')}},[(
                _vm.selectedWidget.options.scoringMethod * 1 < 3 &&
                _vm.selectedWidget.options.answer
            )?_c('div',{staticClass:"d-flex"},[_c('el-input',{attrs:{"type":"number","placeholder":"请输入"},model:{value:(_vm.optionModel.scoring['answer']),callback:function ($$v) {_vm.$set(_vm.optionModel.scoring, 'answer', $$v)},expression:"optionModel.scoring['answer']"}}),_c('div',{staticClass:"ml-10"},[_vm._v("分")])],1):_vm._e()]):_vm._e(),(_vm.selectedWidget.options.scoringMethod * 1 > 2)?_c('div',_vm._l((_vm.optionItems),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-ietms-center mb-10"},[_c('div',{domProps:{"textContent":_vm._s(item[_vm.label])}}),_c('el-input',{staticClass:"ml-10 flex-1",attrs:{"type":"number","placeholder":"请输入"},model:{value:(_vm.optionModel.scoring[item[_vm.value]]),callback:function ($$v) {_vm.$set(_vm.optionModel.scoring, item[_vm.value], $$v)},expression:"optionModel.scoring[item[value]]"}}),_c('div',{staticClass:"ml-10"},[_vm._v("分")])],1)}),0):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }