<template>
  <div class="login-container">
    <el-row>
      <el-col :xs="24" :sm="24" :md="11" :lg="14" :xl="14">
        <div style="color: transparent">占位符</div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="9" :xl="9">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          class="login-form"
          label-position="left"
        >

          <div class="header-title">
            <div class="title">{{ title }}</div>
            
            <el-image fit="cover " :alt="title" :src="require('@/assets/login_images/loginformtopbg.jpg')" class="title-bg"></el-image>
            <div class="mask"></div>
          </div>
          <el-form-item style="margin-top: 40px" prop="username">
            <span class="svg-container svg-container-admin">
              <i class="el-icon-user"></i>
              用户名
            </span>
            <el-input
              v-model.trim="form.username"
              v-focus
              placeholder="请输入用户名"
              tabindex="1"
              type="text"
            />
          </el-form-item>
          <el-form-item prop="password">
            <span class="svg-container">
             <i class="el-icon-lock"></i>
              密码
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model.trim="form.password"
              :type="passwordType"
              tabindex="2"
              placeholder="请输入密码"
            />
          </el-form-item>
           <el-form-item prop="verifiyCode">
            <span class="svg-container">
              <i class="iconfont icon-verification-code"></i>
              验证码
            </span>
            <el-input
              ref="verifiyCode"
              v-model.trim="form.verifiyCode"
              type="text"
              tabindex="3"
              placeholder="请输入验证码"
              @keyup.enter.native="handleLogin"
            />
            <el-image class="code-img" :src="require('@/assets/login_images/v2_rg2j1h.png')"></el-image>
          </el-form-item>
          <div class="remember-password">
            <el-checkbox v-model="form.rememberPSW">记住密码</el-checkbox>
          </div>
          <el-button
            :loading="loading"
            class="login-btn"
            type="primary"
            round
            @click="handleLogin"
          >
          登录
          </el-button>
        </el-form>
      </el-col>
      <el-col :xs="24" :sm="24" :md="1" :lg="1" :xl="1"></el-col>
    </el-row>
  </div>
</template>

<script>
  import { isPassword } from '@/utils/validate'
  import { decryptedData, encryptedData } from '@/utils/encrypt'
  const Base64 = require('js-base64').Base64

  export default {
    name: 'Login',
    directives: {
      focus: {
        inserted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    data() {
      const validateusername = (rule, value, callback) => {
        if ('' == value) {
          callback(new Error('用户名不能为空'))
        } else {
          callback()
        }
      }
      const validatePassword = (rule, value, callback) => {
        if (!isPassword(value)) {
          callback(new Error('密码不能少于6位'))
        } else {
          callback()
        }
      }
      const validateverifiyCode = (rule, value, callback) => {
        if ('' == value) {
          callback(new Error('验证码不能为空'))
        } else {
          callback()
        }
      }
      return {
        nodeEnv: process.env.NODE_ENV,
        title: this.$baseTitle,
        form: {
          username: '',
          password: '',
          verifiyCode: '',
          rememberPSW: false
        },
        rules: {
          username: [
            {
              required: true,
              trigger: 'blur',
              validator: validateusername,
            },
          ],
          password: [
            {
              required: true,
              trigger: 'blur',
              validator: validatePassword,
            },
          ],
          verifiyCode: [
            {
              required: true,
              trigger: 'blur',
              validator: validateverifiyCode,
            },
          ]
        },
        loading: false,
        passwordType: 'password',
        redirect: undefined
      }
    },
    watch: {
      $route: {
        handler(route) {
          this.redirect = (route.query && route.query.redirect) || '/'
        },
        immediate: true,
      },
    },
    created() {
      document.body.style.overflow = 'hidden'
    },
    beforeDestroy() {
      document.body.style.overflow = 'auto'
    },
    mounted() {
      //this.form.username = 'admin'
      //this.form.password = '123456'
      let username = localStorage.getItem('username') 
        
      if(username) {
        this.form.username = username 
        let psw = localStorage.getItem('password')
        this.form.password = Base64.decode(psw)
        this.form.rememberPSW = localStorage.getItem('rememberPSW') == 'true' ? true : false
        console.log('上次登录的信息',this.form)
      }
    },
    methods: {
      handlePassword() {
        this.passwordType === 'password'
          ? (this.passwordType = '')
          : (this.passwordType = 'password')
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      handleLogin() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true
            //
            /* this.$store.dispatch('user/tokenLogin', { token: 'test-accessToken' }).then(() => {
              const routerPath =
                this.redirect === '/404' || this.redirect === '/401'
                  ? '/'
                  : this.redirect
              this.$router.push(routerPath).catch(() => {})
              this.loading = false
            }).catch((err) => {
              console.log('登录失败',err);
              this.loading = false
            }) */
            this.$store.dispatch('user/login', this.form).then(() => {
              //判断是否勾选了记住密码
              if(this.form.rememberPSW) {
                this.handleRememberPSW()
              }
              const routerPath =
                this.redirect === '/404' || this.redirect === '/401'
                  ? '/'
                  : this.redirect
              this.$router.push(routerPath).catch(() => {})
              this.loading = false
            }).catch((err) => {
              console.log('登录失败',err);
              this.loading = false
            })
          } else {
            return false
          }
        })
      },
      async handleRememberPSW() {
        console.log('1记住密码',this.form)
        let psw = Base64.encode(this.form.password)
        localStorage.setItem('password', psw)
        localStorage.setItem('username', this.form.username)
        localStorage.setItem('rememberPSW', this.form.rememberPSW)
      }
    },
  }
</script>

<style lang="scss" scoped>
.el-row{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
}
  .login-container {
    height: 100vh;
    background: url('~@/assets/login_images/background.jpg') center center fixed
      no-repeat;
    background-size: cover;
    
    .header-title{
      height: 124px;
      z-index: 1;
      position: relative;
      width: 100%;
      border-radius: 14px 14px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
        position: absolute;
        z-index: 5;
        letter-spacing: .1em;
      }
      .title-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 10px 10px 0 0;
      }
      .mask{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: 10px 10px 0 0;
        background-color: rgba(0, 2, 14, 0.5);
      }
    }
    

    .title-tips {
      margin-top: 29px;
      font-size: 26px;
      font-weight: 400;
      color: rgba(14, 18, 26, 1);
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .remember-password{
      margin-bottom: 30px;
      display: flex;
      margin-left: 4.5vh;
      padding-left: 95px;
    }
    .login-btn {
      display: inherit;
      width: 220px;
      height: 48px;
      border-radius: 25px;
      margin-top: 5px;
      border: 0;
      margin-right: auto;
      margin-left: auto;
      letter-spacing: .3em;
      font-size: 15px;
      &:hover {
        opacity: 0.9;
      }
    }

    .login-form {
      position: relative;
      max-width: 100%;
      /* margin: calc((100vh - 425px) / 2) 10% 10%; */
      margin: calc(50vh - 277.5px) 5vw 5vw;
      overflow: hidden;
      /* padding: 4.5vh; */
      padding-bottom: 4.5vh;
      background-color: #ffffff;
      border-radius: 14px;
      box-shadow: 0 0 5px 5px #434fc5;
      .forget-password {
        width: 100%;
        margin-top: 40px;
        text-align: left;

        .forget-pass {
          width: 129px;
          height: 19px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(92, 102, 240, 1);
        }
      }
    }

    .tips {
      margin-bottom: 10px;
      font-size: $base-font-size-default;
      color: $base-color-white;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;

      .title {
        margin: 0 auto 40px auto;
        font-size: 34px;
        font-weight: bold;
        color: $base-color-blue;
        text-align: center;
      }
    }

    .svg-container {
      position: absolute;
      top: 14px;
      left: 15px;
      z-index: $base-z-index;
      font-size: 16px;
      color: #808080;
      cursor: pointer;
      user-select: none;
    }

    .show-password {
      position: absolute;
      top: 14px;
      right: 25px;
      font-size: 16px;
      color: #808080;
      cursor: pointer;
      user-select: none;
    }

    .code-img{
      position: absolute;
      right: 0;
      top: 14px;
      cursor: pointer;
    }

    ::v-deep {
      .el-form-item {
        /* padding-right: 0; */
        margin: 20px 0;
        color: #454545;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 2px;
        padding: 0 4.5vh;
        &__content {
          min-height: $base-input-height;
          line-height: $base-input-height;
        }

        &__error {
          position: absolute;
          top: 100%;
          left: 18px;
          font-size: $base-font-size-small;
          line-height: 18px;
          color: $base-color-red;
        }
      }

      .el-input {
        box-sizing: border-box;
        
        input {
          height: 58px;
          padding-left: 95px;
          font-size: $base-font-size-default;
          line-height: 58px;
          color: $base-font-color;
          /* background: #f6f4fc; */
          border: 0;
          caret-color: $base-font-color;
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
</style>
