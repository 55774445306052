<!-- 生成时间:2023-01-12 13:43:41 -->
<template>
  <div class="app-container">
    <!-- 添加或修改对话框 -->
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="上级目录" prop="type">
          <treeselect v-model="form.pid" :options="imenuOptions" :normalizer="normalizer" placeholder="请选择文件夹"/>
        </el-form-item>
        <el-form-item label="存储平台" prop="storage">
          {{ storage }}
        </el-form-item>
        <el-form-item label="名称" prop="remark">
          <el-input v-model="form.remark" placeholder="文件夹名称"/>
        </el-form-item>
        <!--        <el-form-item label="状态">-->
        <!--          <el-radio-group v-model="form.useed">-->
        <!--            <el-radio :label="1">可用</el-radio>-->
        <!--            <el-radio :label="2">禁用</el-radio>-->
        <!--          </el-radio-group>-->
        <!--        </el-form-item>-->


      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;margin-top: 20px;">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <!--        <el-button @click="cancel">返 回</el-button>-->
      </div>
    </div>

  </div>
</template>
<script>


import {
  listOss, addOss, editOss
} from '@/api/dss/oss'

import VDistpicker from "@/components/VDistpicker";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {VDistpicker, Treeselect},
  name: "bucketAdd",
  props: {
    storage: {
      type: String,
      default: '',
      required: true
    },
    source: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: Object,
      default: {},
    },

  },


  data() {
    return {
      imenuOptions: [],

      treeQuery: {
        source: "1",
        mtype: {"=": "2"},
        pid: {"<>": "0"},
        pageNum: 1,
        pageSize: 10,
        storage: undefined
      },
      // 表单参数--文件夹
      form: {
        useed: 1,
        pid: undefined,  //父ID
        filename: "",
        storage: "MinIO",
        mtype: "2",
        source: "2",
        remark: ""
      },

      // 表单校验
      rules: {
        name: [
          {required: true, message: "请输入名称", trigger: "blur"},
          {
            validator: function (rule, value, callback) {
              if (/[a-zA-z]$/.test(value) == false) {
                callback(new Error("请输入英文名称"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        pid: [
          {required: true, message: "请选择目录", trigger: "blur"},

        ],
      }
    };
  },
  created() {

    this.treeQuery.source = this.source;
    this.form = this.defaultValue;
    console.log(this.form);
    this.getTreeselect();

  },
  methods: {
    /** 查询部门下拉树结构 */
    getTreeselect() {
      this.treeQuery.source = this.source
      listOss(this.treeQuery).then(response => {
        this.imenuOptions = [];
        this.imenuOptions = this.handleTree(response.data.list, "id", "pid");
      });
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.remark,
        children: node.children
      };
    },


    // 取消按钮
    cancel() {
      this.open = false;
      this.advqueryopen = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        useed: 1,
        pid: undefined,  //父ID
        filename: "",
        storage: "MinIO",
        mtype: "2",
        source: this.source,
        remark: ""
      };
      this.resetForm("form");
    },

    /** 提交按钮 */
    submitForm() {
      if (this.form.pid == undefined) {
        this.msgError("请选择上级目录");
        return;
      }

      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {

            editOss(this.form).then(response => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300)
              }
            });
          } else {
            addOss(this.form).then(response => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300)
              }
            });
          }
        }
      });
    },
  } //methods结束
};
</script>
