<template>
  <div class="app-container">    
    <el-row>
      <el-col :span="24" class="card-box">
        <el-card style="position: relative;">
          <div slot="header">
            
            <tr style=" display:inline-block ;">
              
              <td>
                <div style="display:inline-block;font-weight:bolder;">
                  {{ form.device_name }}                
                </div>
              </td>
              <td>
                <div  style="display:inline-block;margin-left:10px;font-size:13px;color:#859094;">
                  状态：{{ statusName }}
                </div>
              </td>
              <td>
                <div  style="display:inline-block;margin-left:10px;font-size:13px;color:#859094;">
                  | 所属产品: 
                </div>
              </td>
              <td>
                <div  style="display:inline-block;margin-left:10px;font-size:13px;font-weight:bolder;">
                  {{ productName }}
                </div>
              </td> 
              <td>
                <div  style="display:inline-block;margin-left:50px;font-size:13px;color:#859094;">
                  设备接入username:
                </div>
              </td>
              <td>
                <div  style="display:inline-block;margin-left:10px;font-size:13px;font-weight:bolder;">
                  {{ form.device_username}}
                </div>
              </td> 
              <td>
                <div  style="display:inline-block;margin-left:10px;font-size:13px;color:#859094;">
                  设备接入password:
                </div>
              </td>    
              <td>
                <div  style="display:inline-block;margin-left:10px;font-size:13px;font-weight:bolder;">
                  {{ form.device_password }}
                </div>
              </td>  
            </tr>
            <div style=" display:inline-block ; float:right">  
                <el-button                 
                  style="margin-left: 10px"
                  type="danger"                 
                  size="mini"       
                  @click="handleUpdate"                                        
                >更新设备信息</el-button>
              </div>
              <div  style = "clear:both;" ></div >           
          </div>
          <div>
            <table style="width: 100%;">              
              <tbody>
                <tr >
                  <td><div class="cell" style="margin-top:10px;font-size:13px;"><span style="color:#859094;">所属资源空间:</span>   {{ spaceName }}</div></td>
                  <td><div class="cell" style="margin-top:10px;font-size:13px;"><span style="color:#859094;">设备ID:</span>   {{ form.device_code }}</div></td>
                  <td/>
                </tr>
                <tr >
                  <td><div class="cell" style="margin-top:10px;font-size:13px;"><span style="color:#859094;">设备标识码:</span>   {{ form.device_check }}</div></td>
                  <td><div class="cell" style="margin-top:10px;font-size:13px;"><span style="color:#859094;">认证类型:</span>   {{ form.device_secret }}</div></td>
                  <td/>
                </tr>   
                <tr >
                  <td><div class="cell" style="margin-top:10px;font-size:13px;"><span style="color:#859094;">注册时间:</span>   {{ timetitle }}</div></td>
                  <td><div class="cell" style="margin-top:10px;font-size:13px;"><span style="color:#859094;">设备类型:</span>   {{ form.device_type }}</div></td>
                  <td/>
                </tr>   
                <tr >
                  <td><div class="cell" style="margin-top:10px;font-size:13px;"><span style="color:#859094;">固件版本:</span>   {{ form.firmware_version }}</div></td>
                  <td><div class="cell" style="margin-top:10px;font-size:13px;"><span style="color:#859094;">软件版本:</span>   {{ form.software_version }}</div></td>
                  <td/>
                </tr>         
              </tbody>
            </table>
          </div>        
                 
        </el-card>
      </el-col>
     <el-col :span="24" class="card-box" >
        <el-card style="height:300px;">
          <div slot="header">
            <div style="display:inline-block;font-weight:bolder;">接入说明</div>              
          </div>
          <div style=" display:inline-block;width: 100%;height:200px">
            <span style="display:inline-block;font-size:13px;color:#859094;" >设备接入：平台默认设备使用MQTT协议接入平台，接入地址和端口在当前Iot实例接入页面中查看，接入时使用概述中的设备接入username和password，设备自定义一个ClientId用以标识设备唯一性。</span>
            <span style="display:inline-block;font-size:13px;color:#859094;margin-top: 10px;" >设备属性上传：设备属性上报时，需要向平台发布一个属性的主题消息,，主题Topic使用概述中的设备ID+/system/information，消息内容payload需要包含属性名称和当前数值，以json的数据格式发布，例如{"device_check":"idididid","attribute":[{"service_id":14,"name":"name0","value":"21"},{"service_id":14,"name":"name2","value":"22122"}]}。</span>
            <span style="display:inline-block;font-size:13px;color:#859094;margin-top: 10px;" >设备属性上传说明：device_check用以校验设备安全性使用概述中的设备标识码；service_id属于产品属性中的服务ID，每个属性的服务ID不一定相同，可以在"设备详情"->"设备影子（属性）"->"属性配置"页面查看服务的ID,属性名称name也在"属性配置"中可以查看。</span>
            <span style="display:inline-block;font-size:13px;color:#859094;margin-top: 10px;" >其他说明：相关的topic说明可以在设备对应的产品详情页面topic列表中查看。</span>
          </div>
             
        </el-card>
      </el-col>      
    </el-row>

    <!-- 修改名称基本信息 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">        
        <el-form-item label="设备名称" prop="device_name">
          <el-input v-model="form.device_name" placeholder="输入设备名称" />
        </el-form-item>
        <el-form-item label="设备用户名" prop="device_username">
          <el-input v-model="form.device_username" placeholder="输入设备名称" readonly />
        </el-form-item>
        <el-form-item label="设备密码" prop="device_password">
          <el-input v-model="form.device_password" placeholder="输入设备名称" />
        </el-form-item>                     
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>   
  </div>
</template>

<script>
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";
export default {
  props: {
    deviceId: {
      type: String
    }
  },
  data() {   
    return {
      device_id:0,
      form:{},
      statusName:"",
      productName:"",
      spaceName:"",
      title:"",
      timetitle:"",
      open: false,
      rules: {        
        space_id: [
          { required: true, message: "资源空间必须不能为空", trigger: "blur" }
        ],
        product_id: [
          { required: true, message: "所属产品必须不能为空", trigger: "blur" }
        ],
        device_name: [
          { required: true, message: "设备名称不能为空", trigger: "blur" }
        ]
      },
    }
  }, 
  created() {   
    if(this.deviceId != undefined){
      this.device_id=parseInt(this.deviceId);    
      this.getdevice();     

    }else{
      this.msgError("获取设备信息失败！");
    }
    
  },
  watch: {
    form: {
      handler() {
        this.timer()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {    
    getdevice(){
      if(this.device_id > 0){
        getIotDevice(this.device_id).then(response =>{
          if(response.code===0){
            this.form=response.data;
            this.timetitle=this.timestampToTime(response.data.create_time);
            this.getdeviceList();
          }
        })
      }      
    },    
    getdeviceList(){
      var queryParams={
        pageNum: 1,
        pageSize: 10,
        device_name: "",
        device_check:"",
        device_code:""
      };
      listIotDevice(queryParams).then(response =>{
        response.data.space_list.forEach(element => {
          if (element.Id === this.form.space_id)
            this.spaceName=element.space_name;
        });
        response.data.product_list.forEach(element => {
          if (element.id === this.form.product_id)
            this.productName=element.name;
        });
        response.data.statusOptions.values.forEach(element => {
          if (element.key == this.form.conn_status)
            this.statusName=element.value;
        });
      });
    },
   
    handleUpdate() {
      this.open = true;
      this.title = "更新设备名称";      
    },
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id > 0) {
            updateIotDevice(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getdevice();
              } else {
                this.msgError(response.msg);
              }
            });
          } 
        }
      });
    },
    cancel() {
      this.open = false;      
    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
    timer(){
      return setTimeout(()=>{
        this.getdevice();        
      },1000)
    }    
  },
  destroyed() {
    clearTimeout(this.timer); 
  }
}
</script>