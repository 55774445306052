<template>
<div class="app-container">
	<el-form
		:model="queryParams"
		ref="queryForm"
		:inline="true"
		label-width="68px"
		>
		<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="模型名称" prop="model_name">
			<el-input
				v-model="queryParams.model_name"
				placeholder="请输入模型名称"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="表名称" prop="table_name">
			<el-input
				v-model="queryParams.table_name"
				placeholder="请输入表名称"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item>
			<el-button
				type="cyan"
				icon="el-icon-search"
				size="mini"
				@click="handleQuery"
				>搜索</el-button
			>
			<el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
				>重置</el-button
			>
		</el-form-item>
	</el-form>

	<vxe-grid
		resizable
		ref="xGrid"
		row-id="id"
		stripe
		show-overflow
		highlight-hover-row
		:loading="loading"
		:toolbar-config="tableToolbar"
		:columns="tableColumn"
		:data="dataList"
		@checkbox-change="checkboxChangeEvent"
		@checkbox-all="checkboxChangeEvent"
	>
		<template #toolbar_buttons>
			<el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAdd"
				v-hasPermi="[
					'/bigdata/bddatamodel/add',
				]"
				>新增</el-button
			>

			<el-button
				type="success"
				icon="el-icon-edit"
				size="mini"
				:disabled="single"
				@click="handleUpdate"
				v-hasPermi="[
					'/bigdata/bddatamodel/get',
					'/bigdata/bddatamodel/edit',
				]"
				>修改</el-button
			>

			<el-button
				type="danger"
				icon="el-icon-delete"
				size="mini"
				:disabled="multiple"
				@click="handleDelete"
				v-hasPermi="[
					'/bigdata/bddatamodel/del',
				]"
				>删除</el-button
			>
		</template>

		<template #defaultopr="{ row }">
			<el-button
				size="mini"
				type="text"
				icon="el-icon-edit"
				@click.stop="handleUpdate(row)"
				>修改
			</el-button>
			<el-button
				size="mini"
				type="text"
				icon="el-icon-delete"
				@click.stop="handleDelete(row)"
				>删除
			</el-button>
		</template>

		<template #empty>
			<span>
				<p>暂无数据</p>
			</span>
		</template>
	</vxe-grid>

	<vxe-pager
		background
		size="small"
		:loading="loading"
		:current-page.sync="queryParams.pageNum"
		:page-size.sync="queryParams.pageSize"
		:total="total"
		@page-change="handlePageChange"
	>
	</vxe-pager>

	<el-dialog
		:close-on-click-modal="false"
		:title="title"
		:visible.sync="open"
		width="68%"
		append-to-body
	>
		<el-form ref="form" :model="form" :rules="rules" label-width="120px">
			<el-row>
				<el-col :span="12">
					<el-form-item label="模型名称" prop="model_name">
						<el-input
							v-model="form.model_name"
							placeholder="请输入模型名称"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="表名称" prop="table_name">
						<el-input
							v-model="form.table_name"
							placeholder="请输入表名称"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="表引擎类型" prop="table_engine">
						<dict-select
							v-model="form.table_engine"
							placeholder="请选择表引擎类型"
							type="bigdata_table_engine"
              :disabled="form.id"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="分区参考字段" prop="partition_by">
						<el-input
							v-model="form.partition_by"
							placeholder="请输入分区参考字段"
							clearable
              :disabled="form.id"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="排序参考字段" prop="order_by">
						<el-input
							v-model="form.order_by"
							placeholder="请输入排序参考字段，如果有主键，主键必须放在排序字段前面"
							clearable
              :disabled="form.id"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="备注" prop="remark">
						<el-input
							v-model="form.remark"
							placeholder="请输入备注"
							clearable
							type="textarea"
						/>
					</el-form-item> 
				</el-col>
			</el-row>
      <el-row>
        <el-col :span="24">
            <vxe-grid
              ref="fieldsGrid"
              row-key
              border
              class="sortable-row-demo"
              :columns="fieldsColumn"
              :data="form.columns"
              :edit-config="{trigger: 'click', mode: 'cell'}"
              :toolbar-config="tableToolbar1"
            >
              <template #toolbar_buttons>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"
                  @click="addField"
                >添加字段</el-button>
              </template>
              <template #fieldnameedit="{ row }">
                <el-input v-model="row.name" placeholder="请输入名称" />
              </template>
              <template #fieldtypeedit="{ row }">
                <el-select v-model="row.type" placeholder="请选择类型" filterable>
                  <el-option
                    v-for="dict in columnTypeDictData.values"
                    :key="dict.key"
                    :label="dict.value"
                    :value="dict.key"
                  ></el-option>
                </el-select>
              </template>
              <template #fieldsizeedit="{ row }">
                <el-input v-if="row.type=='FixedString'" v-model="row.size" placeholder="" />
                <el-input v-else :disabled="true" placeholder="" />
              </template>
              <template #fieldscaleedit="{ row }">
                <el-input v-if="row.type=='Decimal32'||row.type=='Decimal64'" v-model="row.scale" placeholder="" />
                <el-input v-else :disabled="true" placeholder="" />
              </template>
              <template #fieldnotnulledit="{ row }">
                <el-checkbox v-model="row.not_null" :disabled="form.id" true-label="1" false-label="0" />
              </template>
              <template #fieldprimarykeyedit="{ row }">
                <el-checkbox v-model="row.primary_key" true-label="1" :disabled="form.id" false-label="0" />
              </template>
              <template #fielddefaultvalueedit="{ row }">
                <el-input v-if="row.not_null=='1'" :disabled="true" placeholder="" />
                <el-input v-else v-model="row.default_value" placeholder="" />
              </template>
              <template #fielddescedit="{ row }">
                <el-input v-model="row.desc" placeholder="请输入注释" />
              </template>
              <template #fieldsortedit="{ row }">
                <span v-if="!form.id" class="drag-btn">
                  <i class="vxe-icon--menu"></i>
                </span>
              </template>
              <template #fielddefaultopr="{ row }">
                <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDeleteField(row)"
                >删除
                </el-button>
              </template>
            </vxe-grid>
        </el-col>
      </el-row>
		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitForm">确 定</el-button>
			<el-button @click="cancel">返 回</el-button>
		</div>
	</el-dialog>
</div>
</template>
<script>
import Sortable from 'sortablejs'
import {
	listBdDatamodel,
	getBdDatamodel,
	addBdDatamodel,
	editBdDatamodel,
	delBdDatamodel,
} from "@/api/bigdata/bd_datamodel";
export default {
	name: "bd-datamodel",
	data() {
		return { 
			tableToolbar: {
				perfect: true,
				zoom: true,
				custom: true,
				refresh: {
					query: this.handleQuery,
				},
				slots: {
					buttons: "toolbar_buttons",
				},
			},
      tableToolbar1: {
        perfect: false,
        zoom: true,
        custom: false,
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
			tableColumn: [
				{ type: "checkbox", width: 60, fixed: "left" },
				{ field: "id", title: "id", minWidth: 120, fixed: "left" },
				{ field: "model_name", title: "模型名称", minWidth: 120 },
				{ field: "table_name", title: "表名称", minWidth: 120 },
				{ field: "table_engine", title: "表引擎类型", minWidth: 120, formatter: this.dictFormat, dictType: 'bigdata_table_engine' },
				{ field: "partition_by", title: "分区参考字段", minWidth: 120 },
				{ field: "order_by", title: "排序参考字段", minWidth: 120 },
				{ field: "create_time", title: "创建时间", minWidth: 120, formatter: "formatDate", visible: false },
				{ field: "create_by", title: "创建者", minWidth: 120, visible: false },
				{ field: "create_by_username", title: "创建者 昵称/姓名", minWidth: 120, visible: false },
				{ field: "update_time", title: "修改时间", minWidth: 120, formatter: "formatDate" },
				{ field: "update_by", title: "修改者", minWidth: 120, visible: false },
				{ field: "update_by_username", title: "更新者 昵称/姓名", minWidth: 120, visible: false },
				{ field: "remark", title: "备注", minWidth: 120 },
				{
					field: "",
					title: "操作",
					width: 180,
					fixed: "right",
					align: "center",
					slots: { default: "defaultopr" },
				},
			],
      fieldsColumn: [
        {field: 'index', title: '序号', width: 80, align: "center"},
        {field: 'name', title: '名称', slots: {default: 'fieldnameedit'}},
        {field: 'type', title: '类型', slots: {default: 'fieldtypeedit'}},
        {field: 'size', title: '长度', width: 100, slots: {default: 'fieldsizeedit'}},
        {field: 'scale', title: '小数点', width: 100, slots: {default: 'fieldscaleedit'}},
        {field: 'not_null', title: '不是null', width: 80, align: "center", slots: {default: 'fieldnotnulledit'}},
        {field: 'primary_key', title: '键', width: 60, align: "center", slots: {default: 'fieldprimarykeyedit'}},
        {field: 'default_value', title: '默认值', slots: {default: 'fielddefaultvalueedit'}},
        {field: 'desc', title: '注释', slots: {default: 'fielddescedit'}},
        {field: 'sort', title: '排序', width: 60, slots: {default: 'fieldsortedit'}},
        //{type: 'checkbox', width: 60 }
        {field: '', title: '操作',resizable:false, fixed: "right", align: "center", slots: {default: 'fielddefaultopr'}}
      ],
			loading: true,
			ids: [],
			single: true,
			multiple: true,
			total: 0,
			dataList: [],
			title: "",
			open: false,
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				id: undefined,
				model_name: undefined,
				table_name: undefined,
			},
			form: {
        columns: []
      },
			rules: { 
				model_name: [{ required: true, message: "模型名称不能为空", trigger: "blur" }],
				table_name: [{ required: true, message: "表名称不能为空", trigger: "blur" }],
				table_engine: [{ required: true, message: "表引擎类型不能为空", trigger: "blur" }],
        order_by: [{ required: true, message: "排序参考字段不能为空", trigger: "blur" }],
			},
      columnTypeDictData: {}
		};
	},
	created() { 
		for (let i = 0; i < this.tableColumn.length; i++) {
			const col = this.tableColumn[i]
			if (col.dictType) {
				this.getDicts(col.dictType).then(response => {
					if (response.code == 0) {
						col.dictData = response.data
						this.dataList = [].concat(this.dataList)
					}
				})
			}
		}
    this.getDicts('bigdata_column_type').then(response => {
      if (response.code == 0) {
        this.columnTypeDictData = response.data
      }
    })
		this.getList();
	},
	methods: {
		getList() {
			this.loading = true;
			this.ids = [];
			this.single = true;
			this.multiple = true;

			listBdDatamodel(this.queryParams).then((response) => { 
				this.dataList = response.data.list;
				this.total = response.data.total;
				this.loading = false;
			});
		},
		
		dictFormat(e) {
			const col = this.tableColumn[e.columnIndex]
			if (col.dictData) {
				const values = e.cellValue ? e.cellValue.split(',') : []
				const labels = []
				for (let i = 0; i < values.length; i++) {
					const v = values[i]
					for (let j = 0; j < col.dictData.values.length; j++) {
						const item = col.dictData.values[j]
						if (v == item.key) {
							labels.push(item.value)
							break
						}
					}
				}
				return labels.join(',')
			}
			return e.cellValue
		},
		
		cancel() {
			this.open = false;
			this.reset();
		},

		reset() {
			this.form = { 
				model_name: undefined,
				table_name: undefined,
				table_engine: undefined,
				partition_by: undefined,
				order_by: undefined,
				remark: undefined,
        columns: []
			};
			this.resetForm("form");
		},

		handleQuery() {
			this.queryParams.pageNum = 1;
			this.getList();
		},

		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},

		handleAdd() {
			this.reset();
			this.open = true;
			this.title = "添加";
		},

		handleUpdate(row) {
			this.reset();
			const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
			getBdDatamodel(id).then((response) => {
				this.form = response.data;
        if (this.form.columns) {
          for (let i = 0; i < this.form.columns.length; i++) {
            this.form.columns[i].index = i + 1
          }
        }
				this.open = true;
				this.title = "修改";
			});
		},

		submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
          for (let i = 0; i < this.form.columns.length; i++) {
            const c = this.form.columns[i]
            c.sort = i + 1
            c.size = c.size || 0
            c.scale = c.scale || 0
          }
          console.log(this.form)
					if (this.form.id != null) {
						editBdDatamodel(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("修改成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					} else {
						addBdDatamodel(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("新增成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					}
				}
			});
		},

		handleDelete(row) {
			const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
			this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(function () {
				return delBdDatamodel(ids);
			})
			.then(() => {
				setTimeout(() => {
					this.getList();
				}, 300);
				this.msgSuccess("删除成功");
			})
			.catch(function () {});
		},

		checkboxChangeEvent({ records }) {
			this.ids = records.map((item) => item.id);
			this.single = records.length != 1;
			this.multiple = !records.length;
		},

		handlePageChange({ currentPage, pageSize }) {
			if (this.queryParams.pageSize == pageSize) {
				this.getList();
			} else {
				this.queryParams.pageSize = pageSize;
				this.handleQuery();
			}
		},

    addField() {
      this.form.columns.push({
        index: this.form.columns.length + 1,
        name: '',
        type: '',
        size: undefined,
        scale: undefined,
        not_null: '0',
        primary_key: '0',
        default_value: '',
        desc: '',
        sort: this.form.columns.length + 1
      })
    },

    handleDeleteField(row) {
      this.$confirm('确定删除序号为"' + row.index + '"的字段?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const columns = []
        let index = 1
        for (let i = 0; i < this.form.columns.length; i++) {
          const p = this.form.columns[i]
          if (p.index != row.index) {
            p.index = index++
            p.sort = p.index
            columns.push(p)
          }
        }
        this.form.columns = columns
      }).catch(function() {});
    },

    rowDropFields () {
      this.$nextTick(() => {
        if (this.sortableFields) {
          return
        }
        const xTable = this.$refs.fieldsGrid
        this.sortableFields = Sortable.create(xTable.$el.querySelector('.body--wrapper>.vxe-table--body tbody'), {
          handle: '.drag-btn',
          onEnd: ({ newIndex, oldIndex }) => {
            const currRow = this.form.columns.splice(oldIndex, 1)[0]
            this.form.columns.splice(newIndex, 0, currRow)
            for (let i = 0; i < this.form.columns.length; i++) {
              this.form.columns[i].sort = i + 1
            }
          }
        })
      })
    },
	},
  watch: {
    open(val) {
      if (val) {
        this.rowDropFields()
      } else {
        if (this.sortableFields) {
          this.sortableFields.destroy()
          this.sortableFields = null
        }
      }
    },
  }
};
</script>

<style scoped>
.sortable-row-demo .drag-btn {
  cursor: move;
  font-size: 12px;
}
.sortable-row-demo .vxe-body--row.sortable-ghost,
.sortable-row-demo .vxe-body--row.sortable-chosen {
  background-color: #dfecfb;
}
</style>
