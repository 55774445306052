<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="120px"> 
      <el-button icon="el-icon-question" style="font-weight:bolder;border: 0;">软固件升级</el-button>
      <el-form-item label="所属资源空间" prop="space_id">
        <el-select v-model="queryParams.space_id" placeholder="所属资源空间" clearable size="small" @change="spaceChange">
          <el-option
            v-for="dict in spaceList"
            :key="dict.Id"
            :label="dict.space_name"
            :value="dict.Id"
          />
        </el-select>
      </el-form-item>      
    </el-form>          
    <el-row/>
    <el-row>
      <el-col :span="24" class="card-box">
        <el-card>          
          <el-tabs v-model="activeTab">
            <!-- 资源包管理 -->
            <el-tab-pane label="资源包管理" name="spaceResource" :lazy="true">
              <el-form :model="resourcequeryParams" ref="resourcequeryForm" :inline="true" label-width="68px"> 
                <el-form-item  prop="firmware_type" label="资源类型">
                  <el-select v-model="resourcequeryParams.firmware_type" placeholder="请选择资源类型" clearable size="small" @change="resourceTypeChange">
                    <el-option
                      v-for="(item,key) in resourceTypeList"
                      :key="key"
                      :label="item.value"
                      :value="key"
                    />
                  </el-select>
                </el-form-item>
              </el-form>
              <el-row/>
              <vxe-grid            
                ref="xGrid"
                stripe           
                height="500px"
                show-overflow
                highlight-hover-row
                :loading="resourceloading"
                :toolbar-config="resourcetableToolbar"
                :columns="resourcetableColumn"
                :data="resourceList"
                :column-config="{resizable: false}"
                @checkbox-change="resourcecheckboxChangeEvent"
                @checkbox-all="resourcecheckboxChangeEvent">
                <template #resourcetoolbar_buttons >   
                  <el-button
                    style="margin-left: 10px"  
                    size="mini"   
                    @click="resourceAdd"
                  ><span v-if="resourcequeryParams.firmware_type==0">上传固件</span>
                    <span v-else>上传软件</span>
                  </el-button>                  
                </template>

                <!--默认操作按钮-->
                <template #defaultopr="{ row }">              
                  <el-button size="mini" type="text" icon="el-icon-delete" 
                  >查看
                  </el-button>
                  <el-button size="mini" type="text" icon="el-icon-delete"
                  >下载
                  </el-button>
                  <el-button size="mini" type="text" icon="el-icon-delete" @click="resourcehandleDelete(row)"
                  >删除
                  </el-button>
                </template>

                <!--自定义空数据模板-->
                <template #empty>
                      <span >
                        <p>暂无数据</p>
                      </span>
                </template>

              </vxe-grid>
              <vxe-pager
                background
                size="small"
                :loading="resourceloading"
                :current-page="resourcequeryParams.pageNum"
                :page-size="resourcequeryParams.pageSize"
                :total="resourcetotal"
                @page-change="resourcehandleSizeChange">
              </vxe-pager>
            </el-tab-pane>
            <!-- 升级任务 -->
            <el-tab-pane label="升级任务" name="spaceCommand" :lazy="true">
              <el-form :model="commandqueryParams" ref="commandqueryForm" :inline="true" label-width="68px"> 
                <el-form-item  prop="command_type" label="任务类型">
                  <el-select v-model="commandqueryParams.command_type" placeholder="请选择任务类型" clearable size="small" @change="commandChange">
                    <el-option
                      v-for="(item,key) in commandTypeList"
                      :key="key"
                      :label="item.value"
                      :value="key"
                    />
                  </el-select>
                </el-form-item> 
              </el-form>
              <el-row/>
              <vxe-grid            
                ref="xGrid"
                stripe           
                height="500px"
                show-overflow
                highlight-hover-row
                :loading="commandloading"
                :toolbar-config="commandtableToolbar"
                :columns="commandtableColumn"
                :data="commandList"
                :column-config="{resizable: false}"
                @checkbox-change="commandcheckboxChangeEvent"
                @checkbox-all="commandcheckboxChangeEvent">
                <template #commandtoolbar_buttons >   
                  <el-button
                    style="margin-left: 10px"  
                    size="mini" 
                    @click="commandAdd"                            
                  >新建任务</el-button>                  
                </template>

                <!--默认操作按钮-->
                <template #defaultopr="{ row }">              
                  <el-button size="mini" type="text" icon="el-icon-delete" 
                  >查看
                  </el-button>                  
                  <el-button size="mini" type="text" icon="el-icon-delete" @click="commandhandleDelete(row)"
                  >删除
                  </el-button>
                </template>

                <!--自定义空数据模板-->
                <template #empty>
                      <span >
                        <p>暂无数据</p>
                      </span>
                </template>

              </vxe-grid>
              <vxe-pager
                background
                size="small"
                :loading="commandloading"
                :current-page="commandqueryParams.pageNum"
                :page-size="commandqueryParams.pageSize"
                :total="commandtotal"
                @page-change="commandhandleSizeChange">
              </vxe-pager>
            </el-tab-pane>           
          </el-tabs>
        </el-card>
      </el-col> 
    </el-row>

    <!-- 资源添加文件 -->
    <el-dialog :close-on-click-modal="false" :title="resourcetitle" :visible.sync="resourceopen" width="600px" append-to-body>
      <el-form ref="resourceform" :model="resourceform" :rules="resourcerules" label-width="120px">
        <el-form-item label="固件文件" prop="file_path">
          <el-input v-model="resourceform.file_path" placeholder="" />
          <el-upload
            class="upload-demo"
            action="/file/upload"
            :headers="uploadHeaders"
            :data="uploadData"
            :multiple="false"
            :limit="1"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadError"
            :on-exceed="handleUploadExceed"
            :on-remove="handleUploadRemove"
            :file-list="fileList">
            <el-button                                 
                    icon="el-icon-plus"
                    size="mini"                    
                  >添加文件</el-button>
            <span style="margin-left:30px;font-size:13px;color:#859094;">上传说明：仅能上传一个文件大小限制KB.........</span>
          </el-upload>
          
        </el-form-item>
        <el-form-item label="固件版本" prop="version">
          <el-input v-model="resourceform.version" placeholder="输入版本" />
        </el-form-item>
        <el-form-item label="选择产品" prop="product_id">
          <el-select v-model="resourceform.product_id" placeholder="请选择所属产品" clearable size="small" >
            <el-option
              v-for="dict in productList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>               
        <el-form-item label="支持源版本" prop="old_version">
          <el-input v-model="resourceform.old_version" placeholder="不填则支持所有源版本升级" />
        </el-form-item>
        <el-form-item v-if="resourcequeryParams.firmware_type > 0" label="软件包分片" prop="soft_slice">
          <el-input-number v-model="resourceform.soft_slice"  :min="1" :max="1000" :step="10"/>
        </el-form-item>
        <el-form-item label="描述" prop="remark">
          <el-input v-model="resourceform.remark" type="textarea" placeholder="可选填" />
        </el-form-item>             
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resourcesubmitForm">确 定</el-button>
        <el-button @click="resourcecancel">取 消</el-button>
      </div>
    </el-dialog>  
    <!-- 新建任务 -->
    <el-dialog :close-on-click-modal="false" :title="commandtitle" :visible.sync="commandopen" width="900px" append-to-body>
      <el-form ref="commandform" :model="commandform" :rules="commandrules" label-width="120px">
        <el-form-item label="任务名称" prop="command_name">
          <el-input v-model="commandform.command_name" placeholder="" />
        </el-form-item>
        <el-form-item label="执行时间" prop="run_time">
          <el-radio-group v-model="commandform.run_time">
            <el-radio-button :label="0">立即执行</el-radio-button>
            <el-radio-button :label="1">指定时间</el-radio-button>
          </el-radio-group>
          <el-date-picker
            v-model="runningtime"
            type="datetime"
            placeholder="选择日期时间"
            default-time="12:00:00"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="启用重试" prop="commandretry">
          <el-switch v-model="commandretry" active-color="#13ce66" />        
        </el-form-item>  
        <el-form-item label="描述" prop="remark">
          <el-input v-model="commandform.remark" type="textarea" placeholder="可选填" />
        </el-form-item>    
        <el-form-item label="选择升级包" >
          <vxe-grid            
                ref="upgradeGrid"
                stripe   
                show-overflow
                highlight-hover-row
                :loading="upgraderesourceloading"
                :toolbar-config="upgraderesourcetableToolbar"
                :columns="upgraderesourcetableColumn"
                :data="upgraderesourceList"
                :column-config="{resizable: false}"
                @radio-change="upgradecheckboxChangeEvent">
                <!--自定义空数据模板-->
                <template #empty>
                      <span >
                        <p>暂无数据</p>
                      </span>
                </template>

          </vxe-grid>
          <vxe-pager
            background
            size="small"
            :loading="upgraderesourceloading"
            :current-page="upgraderesourcequeryParams.pageNum"
            :page-size="upgraderesourcequeryParams.pageSize"
            :total="upgraderesourcetotal"
            @page-change="upgraderesourcehandleSizeChange">
          </vxe-pager>
        </el-form-item>
        <el-form-item label="选择设备" prop="retry">
          <el-tabs v-model="commandTab">
            <el-tab-pane label="选择设备群组" name="groupTab" :lazy="true">
              <vxe-grid
                ref="groupGrid"
                stripe
                show-overflow
                highlight-hover-row
                :loading="grouploading"
                :toolbar-config="grouptableToolbar"
                :columns="grouptableColumn"
                :data="groupList"
                :column-config="{resizable: false}"
                @checkbox-change="groupcheckboxChangeEvent"
                @checkbox-all="groupcheckboxChangeEvent">  
                <!--自定义空数据模板-->
                <template #empty>
                      <span >
                        <p>暂无数据</p>
                      </span>
                </template>

              </vxe-grid>
              <vxe-pager
                background
                size="small"
                :loading="grouploading"
                :current-page="groupqueryParams.pageNum"
                :page-size="groupqueryParams.pageSize"
                :total="grouptotal"
                @page-change="grouphandleSizeChange">
              </vxe-pager>
            </el-tab-pane>
            <el-tab-pane label="选择设备" name="deviceTab" :lazy="true">
              <vxe-grid                
                ref="deviceGrid"
                stripe
                show-overflow
                highlight-hover-row
                :loading="deviceloading"
                :toolbar-config="devicetableToolbar"
                :columns="devicetableColumn"
                :data="deviceList"
                :column-config="{resizable: false}"
                @checkbox-change="devicecheckboxChangeEvent"
                @checkbox-all="devicecheckboxChangeEvent">  
                <!--自定义空数据模板-->
                <template #empty>
                      <span >
                        <p>暂无数据</p>
                      </span>
                </template>

              </vxe-grid>
              <vxe-pager
                background
                size="small"
                :loading="deviceloading"
                :current-page="devicequeryParams.pageNum"
                :page-size="devicequeryParams.pageSize"
                :total="devicetotal"
                @page-change="devicehandleSizeChange">
              </vxe-pager>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commandsubmitForm">确 定</el-button>
        <el-button @click="commandcancel">取 消</el-button>
      </div>
    </el-dialog>  
  </div>
</template>

<script>
import { listIotSpace, getIotSpace, addIotSpace, updateIotSpace, delIotSpace } from "@/api/iot/space";
import { listIotCase, getIotCase, addIotCase, updateIotCase, delIotCase } from "@/api/iot/case";
import { addIotSpaceResource, getIotSpaceResource, updateIotSpaceResource, delIotSpaceResource, listIotSpaceResource } from "@/api/iot/spaceresource";
import { addIotSpaceCommand, getIotSpaceCommand, updateIotSpaceCommand, delIotSpaceCommand, listIotSpaceCommand } from "@/api/iot/spacecomand";
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";
import { bindIotDeviceGroup,unbindIotDeviceGroup,addIotDeviceGroup, getIotDeviceGroup, updateIotDeviceGroup, delIotDeviceGroup, listIotDeviceGroup } from "@/api/iot/devicegroup";
export default {
  name: "UpgradeInfo",
  data() {
    return {
      activeTab: "spaceResource",
      commandTab:"groupTab",
      resourcetableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.resourcehandleQuery
        },
        slots: {
          buttons: 'resourcetoolbar_buttons'
        }
      },
      upgraderesourcetableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.upgraderesourcehandleQuery
        }
      },
      commandtableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.commandhandleQuery
        },
        slots: {
          buttons: 'commandtoolbar_buttons'
        }
      },
      devicetableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.devicehandleQuery
        }
      },
      deviceloading:false,
      deviceList:[],
      devicetableColumn:[
        {type: 'checkbox', width: 30, fixed: "left"},
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'device_name', title: '设备名称'},
        {field: 'device_check', title: '设备标识码' },       
        {field: 'product_id', title: '所属产品' ,formatter:this.ProductFormat}
      ],
      grouptableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.grouphandleQuery
        }
      },
      grouploading:false,
      groupList:[],
      grouptableColumn:[
        {type: 'checkbox', width: 30, fixed: "left"},
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'group_name', title: '群组名称'},
        {field: 'remark', title: '描述' }
      ],
      commandproductList:[],
      resourceform:{},
      commandform:{},
      spaceList:[],
      productList:[],
      queryParams: {
        pageNum: 1,
        pageSize: 10,        
        space_id: undefined
      },
      casequeryParams: {
        pageNum: 1,
        pageSize: 10,
        node_id: undefined
      },
      spacequeryParams: {
        pageNum: 1,
        pageSize: 10,        
        case_id: undefined        
      },   
      resourcequeryParams:{
        pageNum: 1,
        pageSize: 10,        
        firmware_type: 0 ,
        space_id:0       
      },
      upgraderesourcequeryParams:{
        pageNum: 1,
        pageSize: 10,        
        firmware_type: 0 ,
        space_id:0       
      },
      devicequeryParams:{
        pageNum: 1,
        pageSize: 10,
        group_id:-1,
        device_name: "",
        device_check:"",
        device_code:""
      },
      groupqueryParams:{
        pageNum: 1,
        pageSize: 10,        
        space_id: undefined,
        is_system:2
      },
      resourceList:[],
      upgraderesourceList:[],
      resourceProtocolList:[],
      resourceloading:false,
      upgraderesourceloading:false,
      resourceTypeList:[{key:0,value:"固件列表"},{key:1,value:"软件列表"}],
      resourcetableColumn: [  
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'code_id', title: '资源ID' },
        {field: 'file_path', title: '资源名称' },
        {field: 'version', title: '版本' },
        {field: '', title: '归属厂商' ,formatter:this.FirmsFormat},
        {field: '', title: '协议类型' ,formatter:this.ProtocolFormat},
        {field: 'create_time', title: '上传时间' ,formatter:this.timeFormat},
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      upgraderesourcetableColumn:[  
        {type: 'radio', width: 30, fixed: "left"},
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'file_path', title: '资源名称' },
        {field: 'version', title: '版本' },
        {field: '', title: '归属厂商' ,formatter:this.FirmsFormat},
        {field: '', title: '协议类型' ,formatter:this.ProtocolFormat},
        {field: 'remark', title: '描述' }
      ],
      resourceids: [],
      // 非单个禁用
      resourcesingle: true,
      // 非多个禁用
      resourcemultiple: true,
      // 总条数
      resourcetotal: 0,
      upgraderesourcetotal: 0,
      resourcetitle: "",
      resourceopen:false,

      deviceids: [],
      // 非单个禁用
      devicesingle: true,
      // 非多个禁用
      devicemultiple: true,
      // 总条数
      devicetotal: 0,
      groupids: [],
      // 非单个禁用
      groupsingle: true,
      // 非多个禁用
      groupmultiple: true,
      // 总条数
      grouptotal: 0,
      /////
      runningtime:"",
      //创建任务时候提交的资源ID
      commandChooseResourceId:0,
      commandqueryParams:{
        pageNum: 1,
        pageSize: 10,        
        command_type: 0 ,
        space_id:0       
      },
      commandretry:false,
      commandList:[],
      commandStatus:[],
      commandloading:false,
      commandTypeList:[{key:0,value:"固件升级"},{key:1,value:"软件升级"}],
      commandtableColumn: [  
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'command_code', title: '任务名称ID' },
        {field: 'command_status', title: '状态',formatter:this.statusFormat },
        {field: 'command_type', title: '任务类型',formatter:this.typeFormat },
        {field: 'start_time', title: '开始时间',formatter:this.startFormat },
        {field: '', title: '操作',resizable:false, width: 160, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      commandids: [],
      // 非单个禁用
      commandsingle: true,
      // 非多个禁用
      commandmultiple: true,
      // 总条数
      commandtotal: 0,
      commandopen:false,
      commandtitle: "",
      resourcerules:{        
        file_path: [
          { required: true, message: "文件必须不能为空", trigger: "blur" }
        ],
        product_id: [
          { required: true, message: "所属产品必须不能为空", trigger: "blur" }
        ],
        version: [
          { required: true, message: "版本不能为空", trigger: "blur" }
        ]
      },
      commandrules:{        
        command_name: [
          { required: true, message: "任务名称必须不能为空", trigger: "blur" }
        ]
      },
      uploadHeaders: {},
      uploadData: {},
      fileList: [],
    };
  },
  created() {    
    this.getSpace();    
  },
  methods: {   
    getSpace(){
      listIotCase(this.casequeryParams).then(response =>{
          for (var index=0;index<response.data.list.length;index++){
            if (response.data.list[index].is_choose >= 1){
              this.spacequeryParams.case_id=response.data.list[index].Id;              
            }              
          }   
          listIotSpace(this.spacequeryParams).then(response => {        
            this.spaceList = response.data.list;  
            if(this.spaceList.length>0)
              this.queryParams.space_id=this.spaceList[0].Id; 
              this.getResourceList();
              this.getCommandList();
              this.getDeviceList();
              this.getGroupList();           
          }).catch(function (error){
            console.log(error);
          });
      });
      
    },
    getResourceList(){
      if(this.queryParams.space_id>0){
        this.resourceloading=true;
        this.resourcequeryParams.space_id=this.queryParams.space_id;
        listIotSpaceResource(this.resourcequeryParams).then(response=>{
          this.resourceList=response.data.list;
          this.resourcetotal=response.data.total;
          this.productList=response.data.product_list;
          this.resourceProtocolList=response.data.statusOptions.values;
          this.resourceloading=false;
        });
      }
    },
    getCommandList(){
      if(this.queryParams.space_id>0){
        this.commandloading=true;
        this.commandqueryParams.space_id=this.queryParams.space_id;
        listIotSpaceCommand(this.commandqueryParams).then(response=>{
          this.commandList=response.data.list;
          this.commandtotal=response.data.total; 
          this.commandStatus=response.data.statusOptions.values;
          this.commandloading=false;
        });
      }
    },
    getDeviceList(){
      this.deviceloading = true;
      listIotDevice(this.devicequeryParams).then(response => {
        this.devicesingle = true;
        this.devicemultiple = true;
        this.deviceList = response.data.list;
        this.commandproductList = response.data.product_list;
        this.devicetotal = response.data.total;
        this.deviceloading = false;
      });
    },
    getGroupList(){
      this.grouploading=true;
      listIotDeviceGroup(this.groupqueryParams).then(response => {
        this.groupsingle = true;
        this.groupmultiple = true;
        this.groupList = response.data.list;
        this.grouptotal = response.data.total;
        this.grouploading = false;
      });
    },
    getUpgradeResourceList(){
      if(this.queryParams.space_id>0){
        this.upgraderesourceloading=true;
        this.upgraderesourcequeryParams.space_id=this.queryParams.space_id;
        listIotSpaceResource(this.upgraderesourcequeryParams).then(response=>{
          this.upgraderesourceList=response.data.list;
          this.upgraderesourcetotal=response.data.total;                 
          this.upgraderesourceloading=false;
        });
      }
    },
    ProductFormat({row}){
      var label=""
      this.commandproductList.forEach(element => {
        if (element.id === row.product_id)
          label=element.name;
      });
      return label
    },
    FirmsFormat({row}){
      var label="";
      this.productList.forEach(element => {
        if (element.id == row.product_id)
          label=element.firms_name;
      });
      return label
    },
    ProtocolFormat({row}){
      var label="";
      this.productList.forEach(element => {
        if (element.id == row.product_id){
          this.resourceProtocolList.forEach(element2 => {
            if (element2.key == element.protocol_id)
              label=element2.value;
          });
        }          
      });
      
      return label
    },
    timeFormat({row}){
      return this.timestampToTime(row.create_time);
    },
    statusFormat({row}){
      var label="";
      this.commandStatus.forEach(element => {
        if (element.key == row.command_status)
          label=element.value;
      });
      return label
    },
    typeFormat({row}){
      var label="";
      this.commandTypeList.forEach(element => {
        if (element.key == row.command_type)
          label=element.value;
      });
      return label      
    },
    startFormat({row}){
      return this.timestampToTime(row.start_time);
    },
    //空间发生变化
    spaceChange(){
      this.getResourceList(); 
      this.getCommandList();
    },
    //资源类型变化
    resourceTypeChange(){
      this.getResourceList(); 
    },
    //任务类型变化
    commandChange(){
      this.getCommandList();
    },
    //添加升级任务
    commandAdd(){
      if(this.commandqueryParams.command_type==0){
        this.commandtitle="新建固件升级任务";
        this.upgraderesourcequeryParams.firmware_type=0;
      }else{
        this.commandtitle="新建软件升级任务";
        this.upgraderesourcequeryParams.firmware_type=1;
      }
      this.commandreset();
      this.getUpgradeResourceList();
      this.commandopen=true;
    },
    /** 提交按钮 */
    commandsubmitForm: function() {
      this.$refs["commandform"].validate(valid => {
        if (valid) {
          if (this.commandform.id != undefined) {
            /* updateIotDevice(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            }); */
          } else {
            
            if(this.queryParams.space_id>0 &&(this.deviceids.length>0||this.groupids.length>0)&&this.commandform.resource_id>0){
              this.commandform.space_id=this.queryParams.space_id;
              this.commandform.command_type=this.commandqueryParams.command_type;
              if(this.commandretry)
                this.commandform.retry=1;
              else
                this.commandform.retry=0;
              if(this.commandform.run_time>0)
                this.commandform.run_time=Date.parse(new Date(this.runningtime))/1000;
              else
                this.commandform.run_time=0;
              var deviceids=this.deviceids.length>0?this.deviceids.join(',') : '';
              var groupids=this.groupids.length>0?this.groupids.join(',') : '';
              this.commandform.group_ids=groupids;
              this.commandform.device_ids=deviceids;
              this.commandform.resource_id=this.commandChooseResourceId;
              addIotSpaceCommand(this.commandform).then(response => {
                if (response.code ===0) {
                  this.msgSuccess("创建任务成功！");
                  this.commandopen = false;
                  this.getCommandList();
                } else {
                  this.msgError(response.msg);
                }
              });
            }else{
              this.msgError("缺少必选项！");
            }            
          }
        }
      });
    },
    upgradecheckboxChangeEvent({row}){      
      this.commandChooseResourceId=row.id;
    },
    // 取消按钮
    commandcancel() {
      this.commandopen = false;
      this.commandreset();
    },
    // 表单重置
    commandreset() {
      this.commandform = {
        id: undefined,       
        command_name:"",
        remark: ""
      };
      this.commandretry=false;
      this.uploadHeaders = {
        "Authorization": ""
      }
      this.uploadData = {
        "category": "iot",
        "public": "1"
      }
      this.fileList = [];
      this.runningtime="";
      this.grouphandleQuery();     
      this.devicehandleQuery();
      this.resetForm("commandform");
    },

    //添加资源
    resourceAdd(){
      if(this.resourcequeryParams.firmware_type==0){
        this.resourcetitle="上传固件";
        
      }else{
        this.resourcetitle="上传软件";
      }
      this.resourcereset();
      this.resourceopen=true;
    },
    /** 提交按钮 */
    resourcesubmitForm: function() {
      this.$refs["resourceform"].validate(valid => {
        if (valid) {
          if (this.resourceform.id != undefined) {
            /* updateIotDevice(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            }); */
          } else {
            if(this.queryParams.space_id>0){
              this.resourceform.space_id=this.queryParams.space_id;
              this.resourceform.firmware_type=this.resourcequeryParams.firmware_type;
              addIotSpaceResource(this.resourceform).then(response => {
                if (response.code ===0) {
                  this.msgSuccess("上传成功！");
                  this.resourceopen = false;
                  this.getResourceList();
                } else {
                  this.msgError(response.msg);
                }
              });
            }
            
          }
        }
      });
    },
    
    devicecheckboxChangeEvent ({ records }) {
      this.deviceids = records.map(item => item.id)
      this.devicesingle = records .length!=1;
      this.devicemultiple = !records .length

    },
    devicehandleQuery() {
      this.devicequeryParams.pageNum = 1;
      this.getDeviceList();
    },
    upgraderesourcehandleQuery() {
      this.queryParams.pageNum = 1;
      this.getUpgradeResourceList();
    },
    groupcheckboxChangeEvent ({ records }) {
      this.groupids = records.map(item => item.id)
      this.groupsingle = records .length!=1;
      this.groupmultiple = !records .length

    },
    grouphandleQuery() {
      this.groupqueryParams.pageNum = 1;
      this.getGroupList();
    },
    // 取消按钮
    resourcecancel() {
      this.resourceopen = false;
      this.resourcereset();
    },
    // 表单重置
    resourcereset() {
      this.resourceform = {
        id: undefined,       
        version: undefined,
        file_path:undefined,
        remark:"",
        old_version: "",
        product_id: undefined,
        soft_slice:500
      };
      this.resetForm("resourceform");
    },
    resourcecheckboxChangeEvent ({ records }) {
      this.resourceids = records.map(item => item.id)
      this.resourcesingle = records .length!=1;
      this.resourcemultiple = !records .length

    },    
    /** 搜索按钮操作 */
    resourcehandleQuery() {
      this.queryParams.pageNum = 1;
      this.getResourceList();
    },
    /** 重置按钮操作 */
    resourceresetQuery() {
      this.resetForm("resourcequeryForm");
      this.resourcehandleQuery();
    },
    resourcehandleSizeChange ({ currentPage, pageSize }) {
      this.resourcequeryParams.pageNum = currentPage;
      if(this.resourcequeryParams.pageSize == pageSize){
        this.getResourceList();
      }else{
        this.resourcequeryParams.pageSize = pageSize;
        this.resourcehandleQuery();
      }

    },
    upgraderesourcehandleSizeChange ({ currentPage, pageSize }) {
      this.upgraderesourcequeryParams.pageNum = currentPage;
      if(this.upgraderesourcequeryParams.pageSize == pageSize){
        this.getUpgradeResourceList();
      }else{
        this.upgraderesourcequeryParams.pageSize = pageSize;
        this.upgraderesourcehandleQuery();
      }

    },    
    commandcheckboxChangeEvent ({ records }) {
      this.commandids = records.map(item => item.id)
      this.commandsingle = records .length!=1;
      this.commandmultiple = !records .length

    },
    grouphandleSizeChange ({ currentPage, pageSize }) {
      this.groupqueryParams.pageNum = currentPage;
      if(this.groupqueryParams.pageSize == pageSize){
        this.getGroupList();
      }else{
        this.groupqueryParams.pageSize = pageSize;
        this.grouphandleQuery();
      }

    },
    devicehandleSizeChange ({ currentPage, pageSize }) {
      this.devicequeryParams.pageNum = currentPage;
      if(this.devicequeryParams.pageSize == pageSize){
        this.getDeviceList();
      }else{
        this.devicequeryParams.pageSize = pageSize;
        this.devicehandleQuery();
      }

    },
    
    /** 搜索按钮操作 */
    commandhandleQuery() {
      this.commandqueryParams.pageNum = 1;
      this.getCommandList();
    },
    /** 重置按钮操作 */
    commandresetQuery() {
      this.resetForm("commandqueryForm");
      this.commandhandleQuery();
    },
    commandhandleSizeChange ({ currentPage, pageSize }) {
      this.commandqueryParams.pageNum = currentPage;
      if(this.commandqueryParams.pageSize == pageSize){
        this.getCommandList();
      }else{
        this.commandqueryParams.pageSize = pageSize;
        this.commandhandleQuery();
      }

    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
    /** 删除按钮操作 */
    resourcehandleDelete(row) {
      const ids = row.id || (this.resourceids ? this.resourceids.join(',') : '');   
      this.$confirm('是否确认删除资源编号为"' +ids+ '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotSpaceResource(ids);
        }).then(() => {
          this.getResourceList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    commandhandleDelete(row) {
      const ids = row.id || (this.commandids ? this.commandids.join(',') : '');   
      this.$confirm('是否确认删除资源编号为"' +ids+ '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotSpaceCommand(ids);
        }).then(() => {
          this.getCommandList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    handleUploadSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      //file.url = response.data.url
      //this.form.image = response.data.url
    },
    handleUploadError(err) {
      console.log(err)
      //this.form.image = ''
      this.msgError("上传文件失败");
    },
    handleUploadExceed() {
      this.msgError("只能上传一个文件，请删除后重新上传");
    },
    handleUploadRemove() {
      //this.form.image = ''
    }
  }
};
</script>