<template>
    <div>
        <div class="drawing-table">
            <table>
                <tbody>
                    <tr
                        v-for="(row, rowIndex) in tableData"
                        :key="rowIndex"
                        :name="rowIndex"
                        class="drawing-table-tr"
                    >
                        <td
                            class="drawing-table-td"
                            v-for="(cell, cellIndex) in row"
                            :key="cellIndex"
                            :name="rowIndex + '-' + cellIndex"
                            :rowspan="cell.rowSpan"
                            :colspan="cell.colSpan"
                            @dragenter="handleDragEnter(rowIndex, cellIndex)"
                            @dragover="handleDragOver"
                            @drop="handleDrop(rowIndex, cellIndex)"
                        ></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ul
            v-show="isContextMenuVisible"
            :style="{
                left: contextMenuLeft + 'px',
                top: contextMenuTop + 'px',
            }"
            class="context-menu"
        >
            <li @click="operateTable(1)">删除表格</li>
            <li @click="operateTable(2)">删除当前行</li>
            <li @click="operateTable(3)">删除当前列</li>
            <li @click="operateTable(4)">左插入列</li>
            <li @click="operateTable(5)">右插入列</li>
            <li @click="operateTable(6)">前插入行</li>
            <li @click="operateTable(7)">后插入行</li>
            <li @click="operateTable(8)" v-if="isMenuShow(8)">向右合并</li>
            <li @click="operateTable(9)" v-if="isMenuShow(9)">向下合并</li>
        </ul>
    </div>
</template>
<script>
import draggable from "vuedraggable";
import DraggableItem from "./DraggableItem";
export default {
    components: {
        DraggableItem,
    },
    props: {},
    data() {
        return {
            activeId: "",
            activeData: {},
            drawingList: [],

            tableData: [
                [
                    { content: "Cell 1", rowSpan: 1, colSpan: 1, list: [] },
                    { content: "Cell 2", rowSpan: 1, colSpan: 1 },
                    { content: "Cell 3", rowSpan: 1, colSpan: 1 },
                ],
                [
                    { content: "Cell 4", rowSpan: 1, colSpan: 1 },
                    { content: "Cell 5", rowSpan: 1, colSpan: 1 },
                    { content: "Cell 6", rowSpan: 1, colSpan: 1 },
                ],
                [
                    { content: "Cell 7", rowSpan: 1, colSpan: 1 },
                    { content: "Cell 8", rowSpan: 1, colSpan: 1 },
                    { content: "Cell 9", rowSpan: 1, colSpan: 1 },
                ],
            ],

            isContextMenuVisible: false,
            contextMenuTop: 0,
            contextMenuLeft: 0,
            selectedCell: {},
            dragTarget: null,
        };
    },
    watch: {
        defaultActiveId: {
            handler(val) {
                this.activeId = val;
                console.log(this.activeId, " this.activeId");
            },
            immediate: true,
            deep: true,
        },
        defaultActiveData: {
            handler(val) {
                this.activeData = this.deepCopy(val);
                console.log(this.activeData, " this.activeData");
            },
            immediate: true,
            deep: true,
        },
        defaultDrawingList: {
            handler(val) {
                this.drawingList = val;
            },

            deep: true,
            immediate: true,
        },

        // isContextMenuVisible(value) {
        //     if (value) {
        //         document.body.addEventListener("click", this.closeMenu);
        //     } else {
        //         document.body.removeEventListener("click", this.closeMenu);
        //     }
        // },
    },
    created(){
        this.$nextTick(()=>{
            this.itemBindEvent()
        })
    },
    methods: {
        handleDragEnter(rowIndex, cellIndex) {
            this.dragTarget = { rowIndex, cellIndex };
            console.log("拖拽1");
        },
        handleDragOver(event) {
            event.preventDefault();
            console.log("拖拽2");
        },
        handleDrop(rowIndex, cellIndex) {
            const droppedData = event.dataTransfer.getData("text/plain");
            const sourceCell = this.dragTarget;
            const targetCell = { rowIndex, cellIndex };
            console.log("拖拽3", rowIndex, cellIndex);

            // 进行拖放操作，例如交换数据或合并单元格
            // ...

            // 清空拖放目标
            this.dragTarget = null;
        },
        isDropTarget(rowIndex, cellIndex) {
            console.log("拖拽4");
            return (
                this.dragTarget &&
                this.dragTarget.rowIndex === rowIndex &&
                this.dragTarget.cellIndex === cellIndex
            );
        },
        isMenuShow(type) {
            let is_show = true;
            if (!this.selectedCell.rowIndex && !this.selectedCell.cellIndex) {
                return true;
            }

            let { rowIndex, cellIndex } = this.selectedCell;
            console.log(
                this.tableData[rowIndex],
                this.tableData[rowIndex].length - 1,
                this.selectedCell.cellIndex
            );
            switch (type) {
                case 8:
                    is_show =
                        this.tableData[rowIndex].length - 1 !== cellIndex * 1;
                    return is_show;
                case 9:
                    is_show =
                        this.tableData[rowIndex].length - 1 !== rowIndex * 1;
                    return is_show;
            }
        },
        itemBindEvent() {
            this.$nextTick(() => {
                // 获取到所有的tab节点
                let tr_item =
                    document.body.querySelectorAll(".drawing-table-tr");
                console.log(tr_item);

                // 给每个节点绑定对应的事件
                tr_item.forEach((o) => {
                    let td_item = o.querySelectorAll(".drawing-table-td");
                    console.log(td_item);
                    td_item.forEach((item) => {
                        item.addEventListener("contextmenu", (event) => {
                            // 阻止原生的菜单栏显示
                            event.preventDefault();
                            // 获取target
                            let target = event.target;

                            // 获取点击的td
                            let td_indexs = target
                                .getAttribute("name")
                                .split("-");
                            console.log(
                                td_indexs,
                                target.getAttribute("name"),
                                "测试",
                                event,
                                this.$el.getBoundingClientRect().left
                            );
                            // 展开自定义菜单栏

                            this.showContextMenu(
                                td_indexs[0],
                                td_indexs[1],
                                event
                            );
                        });
                    });
                });
            });
        },
        showContextMenu(rowIndex, cellIndex, event) {
            this.selectedCell = { rowIndex, cellIndex };
            this.isContextMenuVisible = true;
            const offsetLeft = this.$el.getBoundingClientRect().left;
            this.contextMenuTop = event.pageY - 205;
            this.contextMenuLeft = event.pageX - offsetLeft + 15;

            document.addEventListener("click", this.hideContextMenu);
        },
        operateTable(type) {
            let max_length = 0;
            let tableData = this.deepCopy(this.tableData);
            const { rowIndex, cellIndex } = this.selectedCell;
            let list = [];
            let Cell_index =
                tableData.length * tableData[tableData.length - 1].length;
            if (type * 1 === 6 || type * 1 === 7) {
                tableData.forEach((o) => {
                    max_length = o.length > max_length ? o.length : max_length;
                });
                console.log(max_length, rowIndex, cellIndex);

                for (let i = 0; i < max_length; i++) {
                    let obj = {
                        content: "Cell " + (Cell_index + i + 1),
                        rowSpan: 1,
                        colSpan: 1,
                    };
                    list.push(obj);
                }
            }

            switch (type) {
                case 1: //删除表格
                    tableData = [];
                    break;
                case 2: //删除当前行
                    // this.tableData.splice(rowIndex + 1, 0, {cols:max_length});
                    tableData.splice(rowIndex, 1);
                    break;
                case 3:
                    tableData.forEach((item) => {
                        item.splice(cellIndex, 1);
                    });
                    break;
                case 4:
                    tableData.forEach((item, i) => {
                        let obj = {
                            content: "Cell " + (Cell_index + 1),
                            rowSpan: 1,
                            colSpan: 1,
                        };
                        item.splice(cellIndex, 0, obj);
                    });
                    break;
                case 5:
                    tableData.forEach((item, i) => {
                        let obj = {
                            content: "Cell " + (Cell_index + 1),
                            rowSpan: 1,
                            colSpan: 1,
                        };
                        item.splice(cellIndex * 1 + 1, 0, obj);
                    });
                    break;
                case 6:
                    tableData.splice(rowIndex, 0, list);
                    break;
                case 7:
                    tableData.splice(rowIndex + 1, 0, list);
                    break;
                case 8:
                    tableData[rowIndex][cellIndex].colSpan = 2;
                    tableData[rowIndex].splice(cellIndex * 1 + 1, 1);
                    break;
                case 9:
                    tableData[rowIndex][cellIndex].rowSpan = 2;
                    tableData[rowIndex * 1 + 1].splice(cellIndex, 1);
                    break;
            }
            this.tableData = this.deepCopy(tableData);
            console.log(this.tableData);
            this.itemBindEvent();
        },
        hideContextMenu() {
            this.isContextMenuVisible = false;
            this.selectedCell = {};

            document.removeEventListener("click", this.hideContextMenu);
        },
      
        // checkColItem(cmp) {
        //     this.$emit("checkColItem", cmp);
        // },
        isFilledPCon(formIds) {
            const processCmp = this.$parent.$children.find(
                (t) => t.isProcessCmp
            );
            return processCmp && processCmp.isFilledPCon(formIds);
        },

        checkColItem(cmp) {
            if (!cmp) return false;
            const isPcon = this.$store.state.jpgflow.processConditions.find(
                (t) => t.formId == cmp.formId
            )
                ? true
                : false;
            return isPcon && this.isFilledPCon([cmp.formId]);
        },
        // 判断是否已被流程图作为条件必填项了
        isProCondition(cmp) {
            if (Array.isArray(cmp.children) && cmp.children.length) {
                // 容器组件需要检查子元素
                if (cmp.rowType === "table") return false; // 表格的子元素不可能为流程条件
                let flag = false;
                const loop = (el) => {
                    if (flag) return; // flag === true 代表找到了一个了 不需要再找下一个
                    if (Array.isArray(el)) {
                        el.some((e) => {
                            if (e.children) loop(e.children);
                            return this.checkColItem(e);
                        }) && (flag = true);
                    }
                };
                loop(cmp.children);
                return flag;
            } else {
                return this.checkColItem(cmp);
            }
        },
        drawingItemDelete(index, parent) {
            // 首先判断是否是流程条件 再判断是否有节点使用过
            if (this.isProCondition(parent[index])) {
                this.$message.warning("该组件已作为流程判断条件，无法删除");
                return;
            }
            this.$store.commit("delPCondition", parent[index].formId);
            parent.splice(index, 1);
            this.$nextTick(() => {
                const len = this.drawingList.length;
                if (len) {
                    this.activeFormItem(this.drawingList[len - 1]);
                }
            });
        },
        drawingItemCopy(item, parent) {
            let clone = JSON.parse(JSON.stringify(item));
            clone = this.createIdAndKey(clone);
            parent.push(clone);
            this.activeFormItem(clone);
        },
        createIdAndKey(item) {
            item.formId = this.getNextId();
            item.renderKey = clone.formId + new Date().getTime();
            if (item.layout === "colFormItem") {
                item.vModel = `${item.type}${item.formId}`; //`field${item.formId}`;
            } else if (item.layout === "rowFormItem") {
                item.componentName = `row${item.formId}`;
            }
            if (Array.isArray(item.children)) {
                item.children = item.children.map((childItem) =>
                    this.createIdAndKey(childItem)
                );
            }
            return item;
        },
        getNextId() {
            let maxId = this.getMaxId();
            let str = maxId.toString();
            if (str.length >= 13) {
                return new Date().getTime();
            }
            return maxId + 1;
        },
        /**
         * 阻止表格中嵌套行容器
         * 定制组件不能添加子组件
         */
        shouldClone(to, from, target, event, conf) {
            // .drawing-row-item —— 行容器的类名 ipad里面的组件才会带有
            // 直接拖拽的行容器 最外层含有.drawing-row-item
            // 定制组件 内部含有.drawing-row-item
            // const hasRow = target.classList.contains('.drawing-row-item') || target.querySelector('.drawing-row-item') !== null
            // const isRowContainer = ['布局容器', '表格/明细'].includes(target.innerText) //是阻止从左侧拖拽嵌套
            // const isCusFromLeft = target.classList.contains('custom-component')
            const targetConf = target._underlying_vm_;
            const isRowContainer =
                conf.cmpType === "common" && conf.rowType === "layout";
            if (isRowContainer) return true;
            if (conf.cmpType === "custom") return false;
            if (conf.rowType === "table") {
                if (targetConf.layout === "rowFormItem") return false;
                if (this.isFilledPCon([targetConf.formId])) return false;
            }
            return true;
        },

        onMianDragEnd(obj, a) {
            console.log(obj, "obj");
            this.activeFormItem(this.drawingList[obj.newIndex]);
            // this.$emit('onMianDragEnd',{obj,a})
        },
        activeFormItem(element) {
            if (element) {
                this.activeData = element;
                this.activeId = element.formId;
                console.log(this.activeData, this.activeId);
                this.$emit("activeFormItem", element);
                // if (element) {
                //     switch (this.styleIndex * 1) {
                //         case 1:
                //             this.activeData = element;
                //             this.activeId = element.formId;
                //             break;
                //         case 3:
                //             this.editableTabs[this.tabIndex].activeData =
                //                 element;
                //             this.editableTabs[this.tabIndex].activeId =
                //                 element.formId;
                //             break;
                //     }
                // }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.drawing-table table {
    border-collapse: collapse;
    width: 100%;
}

.drawing-table tr,
.drawing-table td {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 80px;
    min-height: 40px;
}

.context-menu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.context-menu li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
}

.context-menu li:hover {
    background: #eee;
}
</style>