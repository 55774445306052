import request from '@/utils/request'

// 添加设备命令
export function addIotDeviceCommand(data) {
    return request({
      url: '/iot/devicecommand/add',
      method: 'post',
      data: data
    })
}

// 获取设备命令
export function getIotDeviceCommand(id) {
    return request({
      url: '/iot/devicecommand/get?id=' + id,
      method: 'get'
    })
}

// 更新设备命令
export function updateIotDeviceCommand(data) {
    return request({
      url: '/iot/devicecommand/update',
      method: 'post',
      data: data
    })
  }
  
// 删除设备命令
export function delIotDeviceCommand(id) {
    return request({
      url: '/iot/devicecommand/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 设备列表命令
export function listIotDeviceCommand(query) {
  return request({
    url: '/iot/devicecommand/list',
    method: 'get',
    params: query
  })
}