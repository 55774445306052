<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
    >
      <el-form-item label="用户池">
        <el-select
          v-model="queryParams.userpool"
          placeholder="选择用户池"
          size="small"
          :disabled="userpools?true:false"
        >
          <el-option
            v-for="item in userpoolList"
            :key="item.id"
            :label="item.display_name"
            :value="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="权限" prop="path">
        <el-input
          v-model="queryParams.path"
          placeholder="请输入权限"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="规则" prop="rule">
        <el-select
          v-model="queryParams.rule"
          placeholder="规则"
          clearable
          size="small"
        >
          <el-option label="通过" value="1" />
          <el-option label="拦截" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      stripe
      show-overflow
      highlight-hover-row
      :loading="loading"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="dataList"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
    >
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          >新增</el-button
        >
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          >修改</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          >删除</el-button
        >
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-view"
          @click="handleUpdate(row, true)"
          >查看
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          >修改
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
        <span>
          <p>暂无数据</p>
        </span>
      </template>
    </vxe-grid>
    <vxe-pager
      background
      size="small"
      :loading="loading"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      :total="total"
      @page-change="handleSizeChange"
    >
    </vxe-pager>

    <!-- 添加或修改对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="500px"
      :close-on-click-modal="false"
      append-to-body
    >
      <jf-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        :readonly="readonly"
        :readonly-column="1"
      >
        <el-form-item label="用户池" prop="userpool">
          <el-select
            v-model="form.userpool"
            placeholder="选择用户池"
            size="small"
            :disabled="(title == '修改'||userpools) ? true : false"
          >
            <el-option
              v-for="item in userpoolList"
              :key="item.id"
              :label="item.display_name"
              :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="权限" prop="path">
          <el-input v-model="form.path" placeholder="请输入权限" />
        </el-form-item>
        <el-form-item label="规则" prop="rule">
          <el-radio-group v-model="form.rule">
            <el-radio label="1">通过</el-radio>
            <el-radio label="2">拦截</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--<el-form-item label="排序" prop="sort">
          <el-input-number v-model="form.sort" controls-position="right" :min="0" />
        </el-form-item>-->
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in statusOptions"
              :key="dict.key"
              :label="dict.key"
              >{{ dict.value }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入内容"
          />
        </el-form-item>
      </jf-form>
      <div slot="footer" class="dialog-footer">
        <template v-if="!readonly">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </template>
        <template v-else>
          <el-button @click="cancel">关 闭</el-button>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listUrlWhitelist,
  getUrlWhitelist,
  delUrlWhitelist,
  addUrlWhitelist,
  updateUrlWhitelist,
  exportUrlWhitelist,
} from "@/api/core/system/urlwhitelist";
import { listUserPool } from "@/api/core/system/userpool";
export default {
  name: "UrlWhitelist",
  props: {
    userpools: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 列
      tableColumn: [
        { type: "checkbox", width: 60, fixed: "left" },
        //{field: 'id', title: '编号', width: 100, fixed: "left"},
        {
          field: "userpool",
          title: "用户池",
          formatter: (e) => {
            for (let i = 0; i < this.userpoolList.length; i++) {
              const c = this.userpoolList[i];
              if (c.name == e.cellValue) {
                return c.display_name;
              }
            }
            return "";
          },
        },
        { field: "name", title: "名称" },
        { field: "path", title: "权限" },
        {
          field: "rule",
          title: "规则",
          width: 100,
          formatter: this.ruleFormat,
        },
        //{field: 'sort', title: '排序',  },
        {
          field: "status",
          title: "状态",
          width: 100,
          formatter: this.statusFormat,
        },
        //        {field: 'create_time', title: '创建时间', width: 180, formatter: "formatDate"},
        {
          field: "",
          title: "操作",
          resizable: false,
          width: 180,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 是否查看
      readonly: false,
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        userpool: undefined,
        name: undefined,
        path: undefined,
        rule: undefined,
        status: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        userpool: [
          { required: true, message: "用户池不能为空", trigger: "blur" },
        ],
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        path: [{ required: true, message: "权限不能为空", trigger: "blur" }],
        rule: [{ required: true, message: "规则不能为空", trigger: "blur" }],
        status: [{ required: true, message: "状态不能为空", trigger: "blur" }],
      },
      //   用户池数据
      userpoolList: [],
    };
  },
  created() {
    listUserPool({ pageNum: 1, pageSize: 9999 }).then((response) => {
      this.userpoolList = response.data.list;
      if (this.userpools) {
        this.queryParams.userpool = this.userpools;
      } else {
        this.queryParams.userpool = this.userpoolList[0].name;
      }
      this.getList();
    });
  },
  methods: {
    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },
    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },

    getList() {
      this.loading = true;
      listUrlWhitelist(this.queryParams).then((response) => {
        this.single = true;
        this.multiple = true;
        this.dataList = response.data.list;
        this.total = response.data.total;
        this.statusOptions = response.data.statusOptions.values || [];
        this.loading = false;
      });
    },
    ruleFormat({ row }) {
      if (row.rule == "1") {
        return "通过";
      } else if (row.rule == "2") {
        return "拦截";
      } else {
        return "";
      }
    },
    statusFormat({ row }) {
      return this.selectDictLabel(this.statusOptions, row.status);
    },
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        type: "1",
        name: undefined,
        path: undefined,
        rule: undefined,
        sort: 0,
        status: "1",
        remark: undefined,
        userpool: undefined,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.readonly = false;
      this.title = "添加";
      this.form.userpool = this.queryParams.userpool;
      this.open = true;
    },
    /** 修改按钮操作 */
    handleUpdate(row, readonly) {
      this.reset();
      const id = row.id || this.ids;
      getUrlWhitelist(id).then((response) => {
        //this.form = response.data;
        let data = response.data;
        if (data) {
          this.form = {
            id: data.id,
            type: data.type || "1",
            name: data.name,
            path: data.path,
            rule: data.rule,
            sort: data.sort,
            status: data.status,
            remark: data.remark,
            userpool: data.userpool,
          };
        }

        this.readonly = readonly;
        this.title = readonly ? "查看" : "修改";
        this.open = true;
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          console.log(this.form, "this.form");
          if (this.form.id != undefined) {
            updateUrlWhitelist(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            console.log(this.form, "新增成功");
            addUrlWhitelist(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const postIds = row.id || (this.ids ? this.ids.join(",") : "");
      this.$confirm("确定删除吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delUrlWhitelist(postIds);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
  },
};
</script>
