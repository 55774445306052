<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd" v-hasPermi="['/bpm/category/add']">新增</el-button>
      </el-form-item>
    </el-form>



    <vxe-grid
      resizable
      ref="xGrid"
      row-id="id"
      :tree-config="treeConfig"
      show-overflow
      highlight-hover-row
      :loading="loading"
      :columns="tableColumn"
      :data="dataList"
    >


      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button size="mini" type="text" icon="el-icon-plus" @click.stop="handleAdd(row)" v-hasPermi="['/bpm/category/add']" v-if="false">
          新增下级
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)" v-hasPermi="['/bpm/category/add']"
        >修改
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
                <span >
                  <p>暂无数据</p>
                </span>
      </template>

    </vxe-grid>




    <!-- 添加或修改对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="父ID" prop="parent_id" v-if="false">
          <treeselect
            v-model="form.parent_id"
            :options="treeOptions"
            :normalizer="normalizer"
            :show-count="true"
            placeholder="选择上级"
          />
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <el-input v-model="form.icon" placeholder="请输入图标" />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" />

        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="form.sort" controls-position="right" :min="0" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入备注信息" />

        </el-form-item>


      </el-form>



      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>




  </div>
</template>
<script>
  import { listBpmCategory, getBpmCategory, delBpmCategory, addBpmCategory, editBpmCategory } from '@/api/bpm/category'
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  export default {
    name: "bpm_category_list",
    components: { Treeselect },
    data() {
      return {
        treeConfig:{
          "trigger":"row",
        },
        // 列
        tableColumn: [
          {field: 'name', title: '名称', minWidth: 80  , fixed: "left",treeNode: true  },
          {field: 'sort', title: '排序', minWidth: 80   },
          {field: 'remark', title: '备注', minWidth: 80   },
          {field: 'create_time', title: '创建时间', minWidth: 80 , formatter: "formatDate"  },
          {field: '', title: '操作', width: 230, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
        ],
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 显示搜索条件
        showSearch: true,
        // 总条数
        total: 0,
        // 表格数据
        dataList: [],
        // 弹出层标题
        title: "",
        // 是否显示弹出层
        treeOptions:[],
        open: false,
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          parent_id : undefined,
          name : undefined,
        },
        // 表单参数
        form: {},
        // 表单校验
        rules: {
          name: [
            { required: true, message: "名称不能为空", trigger: "blur" }
          ],


        }
      };
    },
    created() {
      this.getList();
    },
    methods: {
      /** 转换菜单数据结构 */
      normalizer(node) {
        if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.id,
          label: node.name,
          children: node.children
        };
      },
      handleExport(){},
      handleSizeChange ({ currentPage, pageSize }) {
        if(this.queryParams.pageSize == pageSize){
          this.getList();
        }else{
          this.queryParams.pageSize = pageSize
          this.handleQuery();
        }
      },
      /** 查询列表 */
      getList() {
        this.loading = true;
        listBpmCategory(this.queryParams).then(response => {
          this.dataList = this.handleTree(response.data.list, "id","parent_id")
          this.loading = false;
        });
      },
      // 取消按钮
      cancel() {
        this.open = false;
        this.reset();
      },
      // 表单重置
      reset() {
        this.form = {
          parent_id : undefined,
          parent_ids : undefined,
          name : undefined,
          sort : undefined,
        };
        this.resetForm("form");
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.resetForm("queryForm");
        this.handleQuery();
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id)
        this.single = selection.length!==1
        this.multiple = !selection.length
      },
      getTreeselect() {
        listBpmCategory({  pageNum: 1, pageSize: 9999 }).then(response => {
          this.treeOptions = this.handleTree(response.data.list, "id","parent_id");
        });
      },
      /** 新增按钮操作 */
      handleAdd(row) {
        this.reset();
        this.open = true;
        this.title = "添加";
        this.getTreeselect();
        if (row != null) {
          this.form.parent_id = row.id;
        }
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        this.reset();
        this.getTreeselect();
        const id = row.id || this.ids
        getBpmCategory(id).then(response => {
          this.form = response.data;
          if(this.form.parent_id == 0) {
            this.form.parent_id = undefined
          }
          this.open = true;
          this.title = "修改";
        });
      },
      /** 提交按钮 */
      submitForm() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            if (this.form.id != null) {
              if(this.form.id == this.form.parent_id) {
                this.msgError("父级ID不能选择自己");
                return;
              }
              editBpmCategory(this.form).then(response => {
                if (response.code === 0) {
                  this.msgSuccess("修改成功");
                  this.open = false;
                  setTimeout(()=>{this.getList();},300)
                }
              });
            } else {
              addBpmCategory(this.form).then(response => {
                if (response.code === 0) {
                  this.msgSuccess("新增成功");
                  this.open = false;
                  setTimeout(()=>{this.getList();},300)
                }
              });
            }
          }
        });
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        const ids = row.id || this.ids;

        this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delBpmCategory(ids);
        }).then(() => {
          setTimeout(()=>{this.getList();},300)
          this.msgSuccess("删除成功");
        }).catch(function() {});
      },
    } //methods结束
  };
</script>
