<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="类型" prop="type">
                <el-select
                    v-model="queryParams.type"
                    placeholder="类型"
                    clearable
                    size="small"
                >
                    <el-option label="API" value="api" />
                    <el-option label="数据块" value="data" />
                </el-select>
            </el-form-item>
            <el-form-item label="是否显示" prop="is_show">
                <el-select
                    v-model="queryParams.is_show"
                    placeholder="请选择是否显示"
                    clearable
                    size="small"
                >
                    <el-option label="显示" value="1" />
                    <el-option label="隐藏" value="0" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="categoryList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template> -->

        <!--默认操作按钮-->
        <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="categoryList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>
            <template slot="is_show" slot-scope="{ row }">
                {{ is_showFormat(row.is_show) }}
            </template>
            <template slot="type" slot-scope="{ row }">
                <el-tag>
                    {{ row.type == "api" ? "API" : "数据块" }}
                </el-tag>
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改分类对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="500px"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" placeholder="名称" />
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio key="api" label="api">API</el-radio>
                        <el-radio key="data" label="data">数据块</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否显示" prop="is_show">
                    <el-radio-group v-model="form.is_show">
                        <el-radio key="1" label="1">显示</el-radio>
                        <el-radio key="0" label="0">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="排序" prop="sort">
                    <el-input-number
                        v-model="form.sort"
                        controls-position="right"
                        :min="0"
                    />
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input
                        v-model="form.remark"
                        type="textarea"
                        placeholder="请输入备注"
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listCategory,
    getCategory,
    delCategory,
    addCategory,
    editCategory,
} from "@/api/data/category";

export default {
    name: "data-category-list",
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "编号", width: 100, fixed: "left" },
                { field: "icon", title: "图标", width: 80 },
                { field: "name", title: "名称", is_query: true },
                { field: "sort", title: "排序", width: 80 },
                {
                    field: "type",
                    title: "类型",
                    width: 80,
                    slots: {
                        default: "type",
                    },
                    is_query: true,
                    form_type: "2",
                    options: [
                        { label: "API", value: "api" },
                        { label: "数据块", value: "data" },
                    ],
                    options_value: "value",
                    options_label: "label",
                },
                {
                    field: "is_show",
                    title: "是否显示",
                    width: 100,
                    form_type: "2",
                    // formatter: this.is_showFormat,
                    slots: {
                        default: "is_show",
                    },
                    is_query: true,
                    options: [
                        { label: "显示", value: "1" },
                        { label: "隐藏", value: "0" },
                    ],
                    options_value: "value",
                    options_label: "label",
                },
                { field: "remark", title: "备注" },
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 分类表格数据
            categoryList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
                is_show: undefined,
                type: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                name: [
                    {
                        required: true,
                        message: "分类名称不能为空",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "分类顺序不能为空",
                        trigger: "blur",
                    },
                ],
                is_show: [
                    {
                        required: true,
                        message: "显示状态不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询分类列表 */
        getList() {
            this.loading = true;
            listCategory(this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.categoryList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // $comment字典翻译
        is_showFormat({ cellValue }) {
            if (cellValue == "1") return "显示";
            return "隐藏";
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                icon: undefined,
                name: undefined,
                sort: 0,
                remark: undefined,
                is_show: "1",
                type: "api",
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery(e) {
            this.queryParams = e;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加分类";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getCategory(id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    this.form = {
                        id: data.id,
                        name: data.name,
                        type: data.type,
                        is_show: data.is_show,
                        sort: data.sort,
                        remark: data.remark,
                    };
                }

                this.open = true;
                this.title = "修改分类";
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != undefined) {
                        editCategory(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addCategory(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(
                '是否确认删除分类编号为"' + ids + '"的数据项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(function () {
                    return delCategory(ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
    },
};
</script>
