<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
      <el-form-item label="设备名称" prop="device_name">
        <el-input
          v-model="queryParams.device_name"
          placeholder="请输入设备名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="设备标识码" prop="device_check">
        <el-input
          v-model="queryParams.device_check"
          placeholder="请输入设备标识码"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="设备ID" prop="device_code">
        <el-input
          v-model="queryParams.device_code"
          placeholder="请输入设备ID"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row/>

    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="deviceList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >注册设备</el-button>
        <!-- <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>  -->       
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button size="mini" type="text" icon="el-icon-view">
          <router-link :to="'/iot/device/info/'+row.id" class="link-type" style="align-items: center;">
            <span>查看</span>
          </router-link>
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
        >修改
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>
      
      <template #data_list="{ row }">
        <router-link :to="'/iot/device/info/'+row.id" class="link-type">
          <span>{{ row.device_name }}</span>
        </router-link>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="所属资源空间" prop="space_id">
          <el-select v-model="form.space_id" placeholder="请选择所属资源空间" clearable size="small" @change="spaceChange">
            <el-option
              v-for="dict in spaceList"
              :key="dict.Id"
              :label="dict.space_name"
              :value="dict.Id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属产品" prop="product_id">
          <el-select v-model="form.product_id" placeholder="请选择所属产品" clearable size="small" @change="productChange">
            <el-option
              v-for="dict in tempproductList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称" prop="device_name">
          <el-input v-model="form.device_name" placeholder="输入设备名称" />
        </el-form-item> 
        <el-form-item label="设备标识码" prop="device_check">
          <el-input v-model="form.device_check" placeholder="不填则系统将自动生成" />
        </el-form-item>               
        <el-form-item label="自定义密钥" prop="device_secret">
          <el-input v-model="form.device_secret" placeholder="不填则系统将自动生成" />
        </el-form-item>                 
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>   
    
  </div>
</template>

<script>
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";

export default {
  name: "DeviceList",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        /* {type: 'checkbox', width: 30, fixed: "left"}, */
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'conn_status', title: '状态' , width: 80,formatter:this.ConnectFormat },
        {field: '', title: '设备名称',slots: {default: 'data_list'} },
        {field: 'device_check', title: '设备标识码' },
        {field: 'device_code', title: '设备ID' },
        {field: 'space_id', title: '所属资源空间',formatter:this.SpaceFormat },        
        {field: 'product_id', title: '所属产品' ,formatter:this.ProductFormat},
        {field: 'device_type', title: '设备类型' },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,    
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openpub: false,
      opensub: false,
      openTopic:false,
      topictitle:"",
      deviceid:0,
      // 状态数据字典
      statusOptions: [],  
      deviceList: [],    
      spaceList:[],
      productList:[],      
      tempproductList:[],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        group_id:-1,
        device_name: "",
        device_check:"",
        device_code:""
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {        
        space_id: [
          { required: true, message: "资源空间必须不能为空", trigger: "blur" }
        ],
        product_id: [
          { required: true, message: "所属产品必须不能为空", trigger: "blur" }
        ],
        device_name: [
          { required: true, message: "设备名称不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getList();    
  },
  watch: {
    deviceList: {
      handler() {
        this.timer()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length;     
    },

    getDeviceListNoLoad(){
      listIotDevice(this.queryParams).then(response => {
        this.deviceList = response.data.list;
      });
    },

    /** 查询分类列表 */
    getList() {
      this.loading = true;
      listIotDevice(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;
        this.deviceList = response.data.list;
        this.spaceList = response.data.space_list;
        this.productList = response.data.product_list;
        this.total = response.data.total;
        this.statusOptions = response.data.statusOptions.values;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,       
        device_name: "",
        device_secret: "",
        device_check: "",
        conn_status: 0
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },

    //选择变化
    spaceChange(){     
      this.tempproductList=[];      
      this.productList.forEach(element => {
        if (element.space_id === this.form.space_id)
          this.tempproductList.push(element);
      });
      if(this.productList.length>0){
        this.form.product_id=this.tempproductList[0].id;
      }      
    },
    //选择变化
    productChange(){
      //this.msgSuccess(this.form.product_id);
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "注册新设备";
      this.tempproductList=[];
    },   
    ConnectFormat({row}){
      return this.selectDictLabel(this.statusOptions, row.conn_status + '');
    },
    SpaceFormat({row}){
      var label=""
      this.spaceList.forEach(element => {
        if (element.Id === row.space_id)
          label=element.space_name;
      });
      return label
    },
    ProductFormat({row}){
      var label=""
      this.productList.forEach(element => {
        if (element.id === row.product_id)
          label=element.name;
      });
      return label
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getIotDevice(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.tempproductList=this.productList;
        this.title = "修改设备";
      });
    },    
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
            updateIotDevice(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addIotDevice(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("注册设备成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotDevice(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    timer(){
      return setTimeout(()=>{
        this.getDeviceListNoLoad();
      },1000)
    }
  },
  destroyed() {
    clearTimeout(this.timer);
  }
};
</script>
