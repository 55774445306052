import request from '@/utils/request'

// 查询cms标签列表
export function listCmsTags(query) {
  return request({
    url: '/cms/tags/list',
    method: 'get',
    params: query
  })
}

// 查询cms标签详细
export function getCmsTags(id) {
  return request({
    url: '/cms/tags/get?id=' + id,
    method: 'get'
  })
}

// 新增cms标签
export function addCmsTags(data) {
  return request({
    url: '/cms/tags/add',
    method: 'post',
    data: data
  })
}

// 修改cms标签
export function updateCmsTags(data) {
  return request({
    url: '/cms/tags/edit',
    method: 'post',
    data: data
  })
}

// 删除cms标签
export function delCmsTags(id) {
  return request({
    url: '/cms/tags/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
