<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--数据-->
      <el-col :span="24" :xs="24">
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          label-width="68px"
        >
          <el-form-item
            v-for="item in orgcolumnList"
            :label="item.Comment"
            :prop="item.Name"
            :key="item.id"
          >
            <el-input
              v-model="queryParams[item.Name]"
              :placeholder="item.Comment"
              clearable
              size="small"
              style="width: 250px"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-form :inline="true" label-width="68px">
          <el-form-item label="当前学年">
            <el-input
              :disabled="true"
              placeholder=""
              v-model="activ_ptur.name"
            />
          </el-form-item>

          <el-form-item label="当前学期">
            <el-input
              :disabled="true"
              placeholder=""
              v-model="activ_tur.name"
            />
          </el-form-item>

          <el-form-item label="标准">
            <el-select
              v-model="queryParams.type"
              placeholder=""
              @change="typeChange"
              size="small"
            >
              <el-option
                v-for="dict in typeOptions"
                :key="dict.key"
                :label="dict.value"
                :value="dict.key"
              />
            </el-select>
          </el-form-item>
        </el-form>

        <vxe-grid
          resizable
          ref="xGrid"
          stripe
          show-overflow
          highlight-hover-row
          :loading="loading"
          :toolbar-config="tableToolbar"
          :columns="tableColumn"
          :data="dataList"
          @checkbox-change="checkboxChangeEvent"
          @checkbox-all="checkboxChangeEvent"
        >
          <template #toolbar_buttons>
            <el-button
              style="margin-left: 10px"
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="handleCreate"
              v-hasPermi="['/growthindicators/gyxy/achievementsubtotal/create']"
              >生成</el-button
            >
          </template>

          <template #roles="{ row }">
            <span v-for="(item, index) of row.roles" :key="index">
              {{ item.name }}
            </span>
          </template>

          <!--默认操作按钮-->
          <template #defaultopr="{ row }">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleView(row)"
              v-hasPermi="[
                '/growthindicators/gyxy/indexform/get',
                '/growthindicators/gyxy/indexform/edit',
              ]"
              >查看
            </el-button>
          </template>

          <!--自定义空数据模板-->
          <template #empty>
            <span>
              <p>暂无数据</p>
            </span>
          </template>
        </vxe-grid>
        <vxe-pager
          background
          size="small"
          :loading="loading"
          :current-page="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          :total="total"
          @page-change="handleSizeChange"
        >
        </vxe-pager>
      </el-col>
    </el-row>

    <!-- 查看成绩明细 -->
    <el-dialog
      :title="studetailtitle"
      :visible.sync="openviewdialog"
      width="800px"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="scrow" v-for="(item, i) in studetaillist" :key="i">
        <span class="idxname">{{ item.idxname }}</span>
        <span class="score">{{ item.score }}</span>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelstudetail">返 回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  dataList,
  datacolList,
  Create,
  Viewstu,
} from "@/api/growthindicators/gyxy/achievementsubtotal";

import { listStuTurn } from "@/api/stu/stu_turn";

export default {
  name: "Indexform",
  data() {
    return {
      studetailtitle:"",
      openviewdialog: false,
      sexOptions: [],
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 列
      tableColumn: [
        { type: "checkbox", width: 60, fixed: "left" },
        { field: "id", title: "ID", width: 100, fixed: "left", visible: false },

        {
          field: "",
          title: "操作",
          resizable: false,
          width: 180,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],
      orgcolumnList: [],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 机构树选项
      deptOptions: undefined,
      typeOptions: [
        { key: "1", value: "学校" },
        { key: "2", value: "学院" },
      ],
      // 是否显示弹出层
      open: false,
      // 机构名称
      name: undefined,
      // 默认密码
      initPassword: undefined,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      // 性别状态字典
      genderOptions: [],

      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "name",
      },
      // 用户导入参数

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: "1",
        opstr: {},
      },
      index_id: 0,
      // 表单校验
      rules: {},
      activ_ptur: {},
      activ_tur: {},
      studetaillist: [],
    };
  },
  computed: {
    inserteditList: function () {
      return this.orgcolumnList.filter((item) => {
        return item.is_insert == "1" || item.is_edit == "1";
      });
    },
  },
  watch: {},
  created() {
    this.getDicts("stu_gender").then((response) => {
      if (response.code == 0) {
        this.sexOptions = response.data.values || [];
      }
    });

    var tparam = {
      pageNum: 1,
      pageSize: 10,
    };

    listStuTurn(tparam).then((response) => {
      this.turdata = response.data.list;
      var tur_pid = {};
      var tur_id = [];

      var hasfound = false;
      for (var i in response.data.list) {
        var item = response.data.list[i];

        if (item.pid == 0 && item.status == "1" && hasfound == false) {
          tur_pid = item;
          hasfound = true;
        }
        if (hasfound && item.pid == tur_pid.id) {
          tur_id.push(item);
          if (item.status == "1") {
            this.activ_tur = item;
          }
        }
      }
      this.activ_ptur = tur_pid;
    });
    datacolList().then((rs) => {
      //      console.log("rs.data.data",rs.data.data)
      var collist = JSON.parse(rs.data.data.Rows);
      console.log("cols", collist);
      var tableColumn = [];
      var oprstrar = {};
      var orgcolumnList = [];
      for (var i in collist) {
        var col = collist[i];
        var colobj = {};
        if (
          col.Name != "id" &&
          col.Name != "type" &&
          col.Name != "tur_pid" &&
          col.Name != "tur_id" &&
          col.Name != "college_id" &&
          col.Name != "major_id" &&
          col.Name != "tur_pid_name"
        ) {
          colobj = { field: col.Name, title: col.Comment, minWidth: 100 };
          if (col.html_type == "datetime") {
            if (col.column_name.indexOf("time") != -1) {
              colobj["formatter"] = "formatDate";
            } else {
              colobj["formatter"] = ["formatDate", "yyyy-MM-dd"];
            }
          }
          if (col.Name == "gender") {
            colobj["formatter"] = this.sexFormat;
          }
          tableColumn.push(colobj);
          orgcolumnList.push(col);
        }
        oprstrar[col.column_name] = col.query_type;
      }

      tableColumn.push({
        field: "",
        title: "操作",
        resizable: false,
        width: 180,
        fixed: "right",
        align: "center",
        slots: { default: "defaultopr" },
      });
      this.queryParams.opstr = oprstrar;
      this.tableColumn = tableColumn;
      this.orgcolumnList = orgcolumnList;
      this.getList("");
      //    console.log("orgcolumnList",orgcolumnList)
    });
  },
  methods: {
    sexFormat({ cellValue }) {
      return this.selectDictLabel(this.sexOptions, "" + cellValue);
    },
    handleCreate() {
      this.$confirm("确认要生成成绩?(此操作需要较长的时间)", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // this.loading = true;
        this.msgSuccess("操作已在后台运行,请等待结束,期间可随时查看");
        Create(this.queryParams.type, this.activ_ptur.id, this.activ_tur.id)
          .then(() => {
            this.handleQuery();
            this.msgSuccess("操作成功");
            this.loading = false;
          })
          .catch(() => {
            // this.msgSuccess("操作尚未完成,稍等...");
            this.handleQuery();
            this.loading = false;
          });
      });
    },
    typeChange() {
      this.handleQuery();
    },
    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList(this.index_id);
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },
    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },

    getList(index_id) {
      this.loading = true;
      dataList(this.queryParams)
        .then((response) => {
          var data = JSON.parse(response.data.list);
          this.total = response.data.total;
          var rldata = [];
          for (var i in data) {
            var item = data[i];
            rldata.push(item);
          }
          this.dataList = rldata;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.msgError("加载数据出错");
        });
    },
    /** 查询指标下拉树结构 */
    getTreeselect() {
      var queryParams = {
        pageNum: 1,
        pageSize: 9999,
        opstr: {},
      };

      listIndexs(queryParams).then((response) => {
        this.deptOptions = this.handleTree(response.data.list, "id", "pid");
      });
    },
    // 筛选节点
    filterNode(value, data) {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      if (!data.children || data.children.length == 0) {
        this.getcolinfo(data.id);
      }
    },
    getcolinfo(index_id) {
      this.dataList = [];
      this.index_id = index_id;
      datacolList(index_id)
        .then((rs) => {
          var collist = rs.data.list;
          this.orgcolumnList = collist;
          var tableColumn = [];
          var oprstrar = {};
          var rules = {};
          tableColumn.push({ type: "checkbox", width: 60, fixed: "left" });
          tableColumn.push({
            field: "id",
            title: "ID",
            width: 60,
            fixed: "left",
          });
          for (var i in collist) {
            var col = collist[i];
            var colobj = {};
            if (col.is_list == "1") {
              colobj = {
                field: col.column_name,
                title: col.column_comment,
                minWidth: 100,
              };
              if (col.html_type == "datetime") {
                if (col.column_name.indexOf("time") != -1) {
                  colobj["formatter"] = "formatDate";
                } else {
                  colobj["formatter"] = ["formatDate", "yyyy-MM-dd"];
                }
              }
              tableColumn.push(colobj);
            }

            if (col.is_query == "1") {
              oprstrar[col.column_name] = col.query_type;
            }

            if (col.is_required == "1") {
              rules[col.column_name] = [
                {
                  required: true,
                  message: col.column_comment + "不能为空",
                  trigger: "blur",
                },
              ];
            }
          }
          this.queryParams.opstr = oprstrar;
          this.rules = rules;

          tableColumn.push({
            field: "",
            title: "操作",
            resizable: false,
            width: 180,
            fixed: "right",
            align: "center",
            slots: { default: "defaultopr" },
          });

          this.tableColumn = tableColumn;
          console.log("collist", collist);
        })
        .then(() => {
          this.queryParams.pageNum = 1;
          //        this.queryParams.dept_id = data.id;
          this.getList(index_id);
        });
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList(this.index_id);
    },
    /** 重置按钮操作 */
    resetQuery() {
      // this.queryParams.dept_id = 0;
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加数据";
    },
    handleView(row) {
      this.studetailtitle = row.stdname;
      this.openviewdialog = true;
      Viewstu(row.student_no, this.activ_tur.id).then((rs) => {
        var list = JSON.parse(rs.data.list);
        var studetaillist = [];
        for (var i in list) {
          studetaillist.push(list[i]);
        }

        this.studetaillist = studetaillist;
        console.log(studetaillist);
      });
    },
    cancelstudetail() {
      this.openviewdialog = false;
      this.studetaillist = [];
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      //this.getTreeselect();
      const id = row.id || this.ids;
      dataGet(this.index_id, id).then((response) => {
        var data = JSON.parse(response.data.list);
        // console.log(data);
        var tardata = data["0"] || {};
        for (var ti in this.orgcolumnList) {
          var titem = this.orgcolumnList[ti];
          if (titem.is_insert == "1" || titem.is_edit == "1") {
            if (titem.html_type == "datetime" && tardata[titem.column_name]) {
              tardata[titem.column_name] = this.unixTimestamptoDate(
                tardata[titem.column_name],
                "yyyy-MM-dd"
              );
            }
          }
        }
        this.form = tardata;
        //      return true;
        //        const user = response.data.admin
        //        const postIdsStr = user.post_ids ? user.post_ids.split(',') : []
        //        const postIds = []
        //        for (let i = 0; i < postIdsStr.length; i++) {
        //          postIds.push(parseInt(postIdsStr[i]))
        //        }
        //        const roleIdsStr = user.role_ids ? user.role_ids.split(',') : []
        //        const roleIds = []
        //        for (let i = 0; i < roleIdsStr.length; i++) {
        //          roleIds.push(parseInt(roleIdsStr[i]))
        //        }
        //        this.form = {
        //          id: user.id,
        //          dept_id: user.dept_id,
        //          username: user.username,
        //          no: user.no,
        //          realname: user.realname,
        //          idcard: user.idcard,
        //          nickname: user.nickname,
        //          password: undefined,
        //          mobile: user.mobile,
        //          email: user.email,
        //          gender: ''+user.gender,
        //          status: ""+user.status,
        //          remark: user.remark,
        //          postIds: postIds,
        //          post_ids: user.post_ids,
        //          roleIds: roleIds,
        //          role_ids: user.role_ids,
        //          type:user.type
        //        }
        //        this.postOptions = response.data.posts;
        //        this.roleOptions = response.data.roles;
        this.open = true;
        this.title = "修改数据";
      });
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          for (var ti in this.orgcolumnList) {
            var titem = this.orgcolumnList[ti];
            if (titem.is_insert == "1" || titem.is_edit == "1") {
              if (
                titem.html_type == "datetime" &&
                this.form[titem.column_name]
              ) {
                this.form[titem.column_name] = this.DatetounixTimestamp(
                  this.form[titem.column_name]
                );
              }
            }
          }
          //          this.form.post_ids = this.form.postIds.join(',')
          //          this.form.role_ids = this.form.roleIds.join(',')
          if (this.form.id != undefined) {
            dataEdit(this.index_id, this.form.id, this.form).then(
              (response) => {
                if (response.code === 0) {
                  this.msgSuccess("修改成功");
                  this.open = false;
                  this.handleQuery();
                } else {
                  this.msgError(response.msg);
                }
              }
            );
          } else {
            dataAdd(this.index_id, this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.handleQuery();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const did = row.id || (this.ids ? this.ids.join(",") : "");
      this.$confirm('是否确认删除编号为"' + did + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return dataDel(this.index_id, did);
        })
        .then(() => {
          this.handleQuery();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.msgSuccess("敬请期待...");
      return false;
      const queryParams = this.queryParams;
      this.$confirm("是否确认导出所有用户数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return exportUser(queryParams);
        })
        .then((response) => {
          this.download(response.data);
        })
        .catch(function () {});
    },
    /** 导入按钮操作 */
    handleImport() {
      //      this.msgSuccess("敬请期待...");
      //      return false;
      this.upload.title = "数据导入";
      this.upload.open = true;
    },
    /** 下载模板操作 */
    importTemplate() {
      importTemplate(this.index_id).then((response) => {
        this.download(response.data.file);
      });
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(
        response.msg == "success" ? "操作成功" : response.msg,
        "导入结果",
        { dangerouslyUseHTMLString: true }
      );
      this.handleQuery();
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
    normalizerDeptTreeNode(e) {
      //      e.id = e.id
      e.label = e.name;
      return e;
    },
  },
};
</script>
<style scoped>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #2196f3;
  color: #fff;
}
.scrow {
  line-height: 28px;
}
.idxname {
  width: 200px;
  display: inline-block;
}
.score {
  width: 100px;
  color: #00bcd4;
  font-weight: 700;
  font-size: 16px;
}
</style>