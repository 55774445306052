import request from '@/utils/request'
// 生成时间:2022-03-21 14:36:33

// 查询actrecord列表
export function listActrecord(query) {
    var data = {
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
        page_size: query.pageSize,
        page: query.pageNum,
        extfilter:query.extfilter||""
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr" && k != "extfilter") {
            filterstr[k] = item;
        }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/growthindicators/gyxy/actrecord/list',
        method: 'post',
        data: data
    })
}

// 查询actrecord详细
export function getActrecord(id) {
    return request({
        url: '/growthindicators/gyxy/actrecord/get?id=' + id,
        method: 'get'
    })
}


// 新增actrecord
export function addActrecord(data) {
    return request({
        url: '/growthindicators/gyxy/actrecord/add',
        method: 'post',
        data: data
    })
}

//处理流程提交之后的数据
export function afterBpmAction(data) {
    return request({
        url: '/growthindicators/gyxy/afterBpmAction',
        method: 'post',
        data: data
    })
}
// 修改actrecord
export function editActrecord(data) {
    return request({
        url: '/growthindicators/gyxy/actrecord/edit',
        method: 'post',
        data: data
    })
}

// 删除actrecord
export function delActrecord(ids) {
    return request({
        url: '/growthindicators/gyxy/actrecord/del',
        method: 'post',
        data: { ids: ids }
    })
}

// 删除actrecord查询模板
export function advquerydelActrecord(ids) {
    return request({
        url: '/growthindicators/gyxy/actrecord/advquerydel',
        method: 'post',
        data: { ids: ids }
    })
}

// 添加actrecord查询模板
export function advquerysaveActrecord(data) {
    return request({
        url: '/growthindicators/gyxy/actrecord/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表actrecord查询模板列表
export function advquerylistActrecord(businessname) {
    return request({
        url: '/growthindicators/gyxy/actrecord/advquerylist?businessname=' + businessname,
        method: 'get'
    })
}
// 导出actrecord
export function exportActrecord(query, exportfields, tableColumn) {
    var data = {
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr") {
            filterstr[k] = item;
        }
    }
    var tarexportfieldslist = {};
    for (var k in tableColumn) {
        var tlbf = tableColumn[k];
        for (var i in exportfields) {
            var ef = exportfields[i]
            if (tlbf.field != "" && tlbf.field && tlbf.field != null && tlbf.field == ef) {
                tarexportfieldslist.push({ "filed": ef, "title": tlbf.title });
                break;
            }
        }
    }

    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    data["exportfields"] = JSON.stringify(tarexportfieldslist);
    return request({
        url: '/growthindicators/gyxy/actrecord/export',
        method: 'post',
        data: data
    })
}
