<template>
    <el-form-item :label="i18nt('designer.setting.colorName')"  v-if="!designer.isTopic">
        <el-input
            v-model="optionModel.color"
            placeholder="请输入字体颜色值如#000"
        ></el-input>
    </el-form-item>
</template>

<script>
import i18n from "@/vform/utils/i18n";

export default {
    name: "color-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>