import { render, staticRenderFns } from "./showScore-editor.vue?vue&type=template&id=4ef03f76&scoped=true&"
import script from "./showScore-editor.vue?vue&type=script&lang=js&"
export * from "./showScore-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef03f76",
  null
  
)

export default component.exports