<template>
 <div>
   <el-row>      
     <el-col :span="24" class="card-box" >
        <el-card > 
          <vxe-grid            
            ref="xGrid"
            stripe         
            height="500px"
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="lableList"
            :column-config="{resizable: false}"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
            <template #toolbar_buttons >   
              <el-button
                style="margin-left: 10px"  
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"                             
              >绑定标签</el-button>
              <el-button
                type="success"
                icon="el-icon-edit"
                size="mini"
                :disabled="single"
                @click="handleUpdate"
              >修改标签</el-button>
              <el-button                
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
              >解除绑定</el-button>
            </template>

            <!--默认操作按钮-->
            <template #defaultopr="{ row }"> 
              <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
              >修改
              </el-button>                       
              <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
              >解绑
              </el-button>
            </template>

            <!--自定义空数据模板-->
            <template #empty>
                  <span >
                    <p>暂无数据</p>
                  </span>
            </template>

          </vxe-grid>
          <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
          </vxe-pager>
        </el-card>
      </el-col>      
    </el-row>
    <!-- 添加或修改对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">  
        <el-form-item label="备注名称" prop="device_rename" :required="true">
          <el-input v-model="form.device_rename" placeholder="请输入备注名称" />
        </el-form-item>
        <el-form-item label="备注值" prop="device_key" :required="true">
          <el-input v-model="form.device_key" placeholder="请输入备注值" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>  
 </div>
</template>

<script>
import { addIotUserChoose, getIotUserChoose, getIotUserChooseByUserId, updateIotUserChoose, delIotUserChoose,listIotUserChoose } from "@/api/iot/userchoose";

export default {
  props: {
    deviceId: {
      type: String
    }
  },
  data() {   
    return {
      device_id:0,
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [  
        {type: 'checkbox', width: 30, fixed: "left"},
        {field: 'device_rename', title: '标签名' },
        {field: 'device_key', title: '标签值' },
        {field: '', title: '操作',resizable:false, width: 150, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      title: "",
      // 是否显示弹出层
      open: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        device_id: 0
      },      
      lableList:[],
      // 表单参数
      form: {},
      // 表单校验
      rules: {        
        device_rename: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
        device_key: [
          { required: true, message: "值不能为空", trigger: "blur" }
        ]
      }
    }
  }, 
  created() {   
    if(this.deviceId != undefined){
      this.device_id=parseInt(this.deviceId);      
      if(this.device_id>0){
        this.queryParams.device_id=this.device_id;
        this.getList();
      }      
    }else{
      this.msgError("获取设备信息失败！");
    }
    
  },
  methods: {
    getList(){      
      if(this.queryParams.device_id>0)
        this.loading=true;
        listIotUserChoose(this.queryParams).then(response=>{
          this.lableList=response.data.list;
          this.loading=false;
          this.single = true;
          this.multiple = true;
        });
    },
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加设备备注";      
    }, 
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getIotUserChoose(id).then(response => {
        this.form = response.data;
        this.open = true;        
        this.title = "修改设备备注";
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,       
        device_rename: "",
        device_key: ""
      };
      this.resetForm("form");
    },
    //判断是否已有相同标签
    isExist(){      
      var index=0;
      if(this.form.id != undefined){
        if(this.lableList.some(element=>element.device_rename==this.form.device_rename&&element.id!=this.form.id))
          index=1;
        if(this.lableList.some(element=>element.device_key==this.form.device_key&&element.id!=this.form.id))
          index=2;
      }else{
        if(this.lableList.some(element=>element.device_rename==this.form.device_rename))
          index=1;
        if(this.lableList.some(element=>element.device_key==this.form.device_key))
          index=2;
      }      
      return index;
    },
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if(this.isExist()==1){
              this.msgError("备注名称不能重复");
            }
            if(this.isExist()==2){
              this.msgError("备注值不能重复");
            }
            if(this.isExist()==0){
              if (this.form.id != undefined) {
                updateIotUserChoose(this.form).then(response => {
                  if (response.code ===0) {
                    this.msgSuccess("修改标签成功");
                    this.open = false;
                    this.getList();
                  } else {
                    this.msgError(response.msg);
                  }
                });
              } else {
                  this.form.device_id=this.device_id;
                  addIotUserChoose(this.form).then(response => {
                    if (response.code ===0) {
                      this.msgSuccess("绑定标签成功");
                      this.open = false;
                      this.getList();
                    } else {
                      this.msgError(response.msg);
                    }
                  });
                }  
            }
          
        }
      });
    },  
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除主题编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotUserChoose(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    }
  }
}
</script>