<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="100px"
            label-position="left"
        >
            <el-form-item label="姓名" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>

            <el-form-item label="学号/工号" prop="xhgh">
                <el-input
                    v-model="queryParams.xhgh"
                    placeholder="请输入"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>

            <el-form-item label="联系电话" prop="tel">
                <el-input
                    v-model="queryParams.tel"
                    placeholder="请输入"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <jf-table
            :filterBtn="false"
            :loading="loading"
            :columns="tableColumn"
            :data="commentList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >查看</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>
            <template slot="subscribe" slot-scope="{ row }">
                {{ subscribeFormat({ row }) }}
            </template>
            <template slot="status" slot-scope="{ row }">
                {{ statusFormat({ row }) }}
            </template>
            <template slot="type" slot-scope="{ row }">
                {{ typeFormat({ row }) }}
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >查看
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改评论对话框 -->
        <el-dialog
            :title="'查看'"
            :visible.sync="open"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form
                ref="form"
                disabled
                :model="form"
                :rules="rules"
                label-width="80px"
            >
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="form.name" placeholder="" />
                </el-form-item>
                <el-form-item label="学号/工号" prop="xhgh">
                    <el-input
                        type="textarea"
                        v-model="form.xhgh"
                        placeholder=""
                    />
                </el-form-item>
                <el-form-item label="浏览器" prop="browser">
                    <el-input v-model="form.browser" placeholder="" />
                </el-form-item>
                <el-form-item label="IP" prop="ip">
                    <el-input
                        v-model="form.ip"
                        type="textarea"
                        placeholder="请输入内容"
                    />
                </el-form-item>

                <el-form-item label="联系电话" prop="tel">
                    <el-input
                        v-model="form.tel"
                        controls-position="right"
                        :min="0"
                    />
                </el-form-item>
                <el-form-item label="开始时间" prop="bdate">
                    <el-input v-model="form.bdate" />
                </el-form-item>

                <el-form-item label="结束时间" prop="bdate">
                    <el-input v-model="form.edate" />
                </el-form-item>

                <el-form-item label="场地" prop="room">
                    <el-select
                        v-model="form.room"
                        placeholder=""
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in roomList"
                            :key="dict.key"
                            :label="dict.value"
                            :value="dict.key"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item label="提交时间" prop="createtime">
                    <el-input v-model="form.createtime" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { listRoombook, delRoombook, getRoombook } from "@/api/cms/roombook";

export default {
    name: "cms-roombook-list",
    data() {
        return {
            roomList: [],
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                {
                    field: "name",
                    title: "姓名",
                },
                { field: "xhgh", title: "学号/工号" },
                { field: "tel", title: "联系方式" },
                { field: "bdate", title: "开始时间" },
                { field: "edate", title: "结束时间" },
                { field: "room", title: "场地", formatter: this.roomFormat },
                { field: "createtime", title: "提交时间" },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 评论表格数据
            commentList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 状态数据字典
            statusOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                title: undefined,
                contacts: undefined,
                tel: undefined,
                remark: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {},
        };
    },
    created() {
        this.getDicts("fxy_sg_site").then((response) => {
            this.roomList = response.data.values || [];
        });

        this.getList();
    },
    methods: {
        roomFormat({ cellValue }) {
            return this.selectDictLabel(this.roomList, "" + cellValue);
        },
        //列表的排序和筛选
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询评论列表 */
        getList() {
            this.loading = true;
            listRoombook(this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.commentList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        typeFormat({ row }) {
            if (row.type == "page") {
                return "单页";
            } else if (row.type == "archives") {
                return "文档";
            } else {
                return row.type;
            }
        },
        subscribeFormat({ row }) {
            if (row.status == "1") {
                return "是";
            } else {
                return "否";
            }
        },
        statusFormat({ row }) {
            if (row.status == "normal") {
                return "正常";
            } else {
                return "隐藏";
            }
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                code: undefined,
                name: undefined,
                comments: 0,
                subscribe: "0",
                status: "normal",
                content: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加评论";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getRoombook(id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    this.form = data;
                }

                this.open = true;
                this.title = "修改评论";
            });
        },
        /** 提交按钮 */
        submitForm: function () {},
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(
                '是否确认删除该数据项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(function () {
                    return delRoombook(ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {},
    },
};
</script>
