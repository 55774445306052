<template>
    <div>
        <el-dialog
            title="关联题库"
            :visible.sync="open"
            :width="'70%'"
            :close-on-click-modal="false"
            append-to-body
            :before-close="cancel"
        >
            <VFormRender
                v-if="queryformData"
                class="query-form-data"
                ref="queryform"
                :formJson="queryformData"
            ></VFormRender>

            <el-form>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        size="mini"
                        @click="handleQuery"
                        >搜索</el-button
                    >
                    <el-button
                        icon="el-icon-refresh"
                        size="mini"
                        @click="resetQuery"
                        >重置</el-button
                    >
                </el-form-item>
            </el-form>

            <jf-table
                :query-params="queryParams"
                :toolbar-config="tableToolbar"
                :columns="tableColumn"
                :data="dataList"
                :tree-config="treeConfig"
                @page-change="handleSizeChange"
                :total="total"
                :radio="false"
                :checkbox="false"
                @change="tableChange"
            >
                <template slot="status" slot-scope="{ row }">
                    <el-switch
                        v-model="row.status"
                        active-value="1"
                        inactive-value="0"
                        @change="handleStatusChange(row)"
                    ></el-switch>
                </template>
                <template slot="roles" slot-scope="{ row }">
                    <span v-for="(item, index) of row.roles" :key="index">
                        {{ item.name }}
                    </span>
                </template>
                <template slot="roles" slot-scope="{ row }">
                    <span v-for="(item, index) of row.roles" :key="index">
                        {{ item.name }}
                    </span>
                </template>
                <template slot="toolbar_btn_row" slot-scope="{ row }">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="handleQuestion(row)"
                        >打开</el-button
                    >
                </template>
                <template
                    :slot="item.slots.default"
                    slot-scope="{ row }"
                    v-for="item in columnSlots"
                >
                    <div v-html="getFun(item, row)"></div>
                </template>
            </jf-table>
        </el-dialog>
        <!-- <el-dialog
        title="选择题目"
        :visible.sync="open"
        fullscreen
        :width="'90%'"
        :close-on-click-modal="false"
        append-to-body
        :before-close="cancel"
    >
       
    </el-dialog> -->
        <el-dialog
            title="选择题目"
            :visible.sync="showQuestion"
            :width="'1200px'"
            :close-on-click-modal="false"
            append-to-body
            :before-close="closequestionDia"
            custom-class="scrolldialog"
            v-if="showQuestion"
        >
            <question
                :qbid="qrow.id"
                :type="qrow.radio80108"
                :dialog-type="'association'"
                @close="closequestionDia"
                @radioChangeEvent="radioChangeEvent"
            ></question>
            <div slot="footer" class="dialog-footer">
                <el-button v-if="!isview" type="primary" @click="submitForm"
                    >确 定</el-button
                >
                <el-button @click="showQuestion = false">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import VFormDesigner from "@/vform/components/form-designer/index.vue";
import { set } from "vue";
import { form } from "@/mixins/form.js";
import questionbankService from "@/api/survey/questionbank";
import Question from "../question/index";
export default {
    mixins: [form],
    components: {
        Question,
        VFormDesigner,
    },
    props: {
        isListBtn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: "",
            open: false,
            isview: false,
            mid: "",
            // 数据列
            tableColumn: [
                // {
                // 	type: "checkbox",
                // 	width: 60,
                // 	fixed: "left"
                // },
                {
                    field: "id",
                    title: "ID",
                    width: 100,
                    fixed: "left",
                },

                // {
                // 	field: "",
                // 	title: "操作",
                // 	resizable: false,
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: {
                // 		default: "defaultopr"
                // 	},
                // },
            ],
            queryformData: null, //查询表单数据
            formData: null, //表单数据
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: false,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 遮罩层
            loading: false,
            // 总条数
            total: 0,
            dataList: [],
            org_optionItems: {},
            treeConfig: {
                trigger: "row",
                expandRowKeys: [],
            },
            qrow: {},
            showQuestion: false,
            selectData: {},
        };
    },
    computed: {
        columnSlots: function () {
            return this.tableColumn.filter((item) => {
                return item.slots;
            });
            // console.log(this.tableColumn.filter((item) => {return item.slots}))
        },
    },
    methods: {
        radioChangeEvent(e) {
            this.selectData = e;
        },
        getFun(item, row) {
            return this[item.slotsFun.funName](
                item.slotsFun.dt,
                row[item.slotsFun.key],
                item.slotsFun.key
            );
        },
        handleQuestion(row) {
            this.qrow = row;
            this.showQuestion = true;
        },
        closequestionDia() {
            this.showQuestion = false;
        },

        openQuestionBank(data) {
            var skey = "field_focus";

            this.queryParams.sortinfo = this.defsort;
            this.open = true;
            this.mid = "972092983301";
            this.getforminfo(this.mid);
        },
        getInit(data) {
            this.title = data.title;
            this.open = true;
            this.mid = data.mid;
            this.$nextTick(() => {
                var designform = this.$refs["VFormDesigner"];
                if (data.formData) {
                    designform.setFormJson(data.formData);
                }
            });
        },
        /** 查询数据 */
        getList(mid) {
            this.loading = true;

            questionbankService
                .listQuestionbank(this.queryParams)
                .then((response) => {
                    this.loading = false;
                    if (this.treeConfig.parentField != null) {
                        var tlist = [];
                        for (let k in response.data.list) {
                            let tonef = response.data.list[k];
                            tonef["label"] = tonef[this.treeConfig.treeNode];

                            tlist.push(JSON.parse(JSON.stringify(tonef)));
                        }
                        try {
                            this.dataList = this.handleTree(
                                tlist,
                                "id",
                                this.treeConfig.parentField
                            );
                        } catch (error) {
                            console.log(" this.dataList error", error);
                        }
                    } else {
                        this.dataList = response.data.list;
                    }
                    console.log(this.dataList);
                    this.total = response.data.total;
                })
                .catch(() => {
                    this.loading = false;
                    // this.msgError("加载数据出错,请先检查表单配置!");
                });
        },
        cancel() {
            // this.$refs["VFormDesigner"].$refs["toolbarRef"].clearFormWidget();
            this.open = false;
        },
        submitForm() {
            this.$emit("submit", this.selectData);
            this.showQuestion = false;
            this.open = false;
        },
    },
};
</script>
<style lang="scss" scoped>
</style>