<template>
    <div class="">
        <el-dialog
            :loading="loading"
            fullscreen
            title="设计流程"
            custom-class="flowDesignDialog"
            :close-on-click-modal="false"
            :visible.sync="visible"
        >
            <template slot="title" v-if="false">
                <el-button type="primary" size="small" @click="publish"
                    >保存草稿</el-button
                >
                <el-button type="primary" size="small" @click="publish"
                    >保存并发布</el-button
                >
            </template>
            <div class="design-btn">
                <el-button
                    v-hasPermi="['/bpm/flow/createmenu']"
                    type="primary"
                    size="small"
                    @click="handleGenCreateMenu"
                    :loading="loading"
                    ><i class="fa fa-save"></i> 生成菜单</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="publish('0')"
                    :loading="loading"
                    ><i class="fa fa-save"></i> 保存草稿</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="publish('1')"
                    :loading="loading"
                    ><i class="fa fa-send"></i> 保存并发布</el-button
                >
                <el-button
                    type="danger"
                    size="small"
                    @click="exit"
                    :loading="loading"
                    ><i class="fa fa-close"></i> 关闭</el-button
                >
            </div>

            <!--<el-button type="primary" size="small" class="publish-btn " @click="publish">保存</el-button>
      <el-button type="primary" size="small" class="publish-btn " @click="publish">发布</el-button>-->
            <el-tabs
                tab-position="top"
                v-model="activeStep"
                v-if="showComp"
                class="design-tab"
            >
                <el-tab-pane name="processTitle" disabled>
                    <template slot="label">
                        <div class="flow-title">
                            <el-button
                                type="primary"
                                size="mini"
                                icon="el-icon-back"
                                circle
                                @click="exit"
                                title="返回"
                            ></el-button>
                            {{ title ? title : "未命名" }}
                        </div>
                    </template>
                </el-tab-pane>
                <el-tab-pane name="basicSetting" label="1.基础设置">
                    <BasicSetting
                        ref="basicSetting"
                        :conf="resourceData.basicSetting"
                        tabName="basicSetting"
                        @initiatorChange="onInitiatorChange"
                        @flowNameChange="onFlowNameChange"
                    />
                </el-tab-pane>
                <el-tab-pane name="formDesign" label="2.表单设计">
                    <DynamicForm
                        ref="formDesign"
                        :version="version"
                        :conf="resourceData.formData"
                        tabName="formDesign"
                    />
                </el-tab-pane>
                <el-tab-pane name="processDesign" label="3.流程设计">
                    <Process
                        ref="processDesign"
                        :conf="resourceData.processData"
                        tabName="processDesign"
                        @startNodeChange="onStartChange"
                    />
                </el-tab-pane>
                <el-tab-pane name="advancedSetting" label="4.高级设置">
                    <AdvancedSetting
                        ref="advancedSetting"
                        tabName="advancedSetting"
                        :conf="resourceData.advancedSetting"
                        :title="title"
                        :flowId="flowId"
                    />
                </el-tab-pane>
            </el-tabs>
            <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary"  @click="doSubmit(0)" v-noMoreClick>保存</el-button>
      <el-button type="primary"  @click="doSubmit(1)" v-noMoreClick>发布</el-button>
      <el-button @click="visible = false">关闭</el-button>
    </span>-->
        </el-dialog>

        <!--生成菜单选项-->
        <el-dialog
            :close-on-click-modal="false"
            title="选择上级菜单"
            :visible.sync="createmenuopen"
            width="50%"
            top="5vh"
            append-to-body
        >
            <el-form ref="flowmenu" label-width="80px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上级菜单">
                            <treeselect
                                v-model="flowmenupid"
                                :options="flowmenuOptions"
                                :normalizer="flowmenunormalizer"
                                :show-count="true"
                                placeholder="选择上级菜单"
                                @select="selectmenunode"
                            >
                            </treeselect>
                        </el-form-item>
                        <el-form-item label="菜单名称">
                            <el-input
                                v-model="menutitle"
                                placeholder="请输入"
                                clearable
                                size="small"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dogenmenu">确 定</el-button>
                <el-button @click="createmenuopen = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Vue from "vue";
//import '@/components/jpgflow/DynamicForm/icons'
import FormControls from "@/components/jpgflow/FormControls/index.js";
Vue.use(FormControls);
// @ is an alias to /src
import Process from "@/components/jpgflow/Process";
import DynamicForm from "@/components/jpgflow/DynamicForm";
import BasicSetting from "@/components/jpgflow/BasicSetting";
import AdvancedSetting from "@/components/jpgflow/AdvancedSetting";
import { saveBpmFlow, flowCreatemenu } from "@/api/bpm/flow";
import { getResource } from "@/api/bpm/resource";

import { listMenu } from "@/api/core/system/menu";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
const beforeUnload = function (e) {
    let msg = "离开网站可能会丢失您编辑的内容";
    (e || window.event).returnValue = msg; // Gecko and Trident
    return msg; // Gecko and WebKit
};

export default {
    name: "bpm-flow-design",
    data() {
        return {
            menutitle: null,
            flowmenuSelnode: null,
            flowmenupid: null,
            flowmenuOptions: [],
            createmenuopen: false,
            resourceData: {
                basicSetting: {},
                processData: {},
                formData: { fields: [] },
                advancedSetting: {},
            }, // 可选择诸如 $route.param，Ajax获取数据等方式自行注入
            title: "",
            activeStep: "basicSetting", // 激活的步骤面板
            visible: false,
            version: 0,
            showComp: false,
            loading: false,
            id: undefined,
            flowId: 0,
        };
    },
    beforeRouteEnter(to, from, next) {
        window.addEventListener("beforeunload", beforeUnload);
        next();
    },
    beforeRouteLeave(to, from, next) {
        window.removeEventListener("beforeunload", beforeUnload);
        next();
    },
    mounted() {},
    methods: {
        handleGenCreateMenu() {
            this.menutitle = this.resourceData.basicSetting.flowName;
            this.createmenuopen = true;
            if (this.flowmenuOptions.length == 0) {
                listMenu().then((response) => {
                    this.flowmenuOptions = [];
                    const menu = {
                        id: 0,
                        title: "主类目",
                        children: [],
                    };
                    menu.children = this.handleTree(
                        response.data.list,
                        "id",
                        "pid"
                    );
                    this.flowmenuOptions.push(menu);
                });
            }
        },

        selectmenunode(node, instanceId) {
            this.flowmenuSelnode = node;
        },
        flowmenunormalizer(node) {
            if (node.children && !node.children.length) {
                delete node.children;
            }

            return {
                id: node.id,
                label:
                    node.title +
                    (node.menu_type == "0" ? "-[菜单类型:目录]" : ""),
                children: node.children,
            };
        },
        dogenmenu() {
            if (
                this.flowmenuSelnode == null ||
                this.flowmenuSelnode.menu_type != "0"
            ) {
                this.msgError("请选择菜单类型为目录的上级!");
                return;
            }

            this.loading = true;
            var data = {
                menuoption: JSON.stringify({
                    pid: "" + this.flowmenuSelnode.id,
                    menutitle: this.menutitle,
                }),
                flowid: this.flowId,
            };

            return flowCreatemenu(data)
                .then(() => {
                    this.loading = false;
                    this.msgSuccess("操作成功");
                    this.createmenuopen = false;
                })
                .catch((e) => {
                    console.log("catch", e);
                    this.loading = false;
                });
        },

        init(id) {
            this.loading = true;
            this.id = id;
            this.$nextTick(() => {
                if (id == 0) {
                    this.resourceData = {
                        basicSetting: {},
                        processData: {},
                        formData: { fields: [] },
                        advancedSetting: {},
                    };
                    this.title = "";
                    this.activeStep = "basicSetting"; // 激活的步骤面板
                    this.visible = true;
                    this.showComp = true;
                    this.loading = false;
                    this.version = 0;
                    this.flowId = 0;

                    return;
                }
                getResource(id)
                    .then((response) => {
                        //this.loading = false
                        let data = response.data;
                        if (data) {
                            this.flowId = data.flow_id;
                            this.version = data.version;
                            let resourceData = JSON.parse(data.resource);
                            //修复数据
                            resourceData.formData.fields.forEach((field) => {
                                if (
                                    field.tag === "el-input" &&
                                    !field.hasOwnProperty("asOpinion")
                                ) {
                                    field.asOpinion = false;
                                }
                                if (
                                    [
                                        "el-date-picker",
                                        "fc-date-duration",
                                    ].includes(field.tag) &&
                                    !field.hasOwnProperty("dateRange")
                                ) {
                                    field.dateRange = undefined;
                                }
                            });
                            this.resourceData = resourceData;
                            this.title =
                                this.resourceData.basicSetting.flowName;

                            this.activeStep = "basicSetting"; // 激活的步骤面板
                            this.showComp = true;
                            this.loading = false;
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
                this.visible = true;
            });
        },
        doSubmit(status) {},
        publish(status) {
            if (status == "99") {
                //生成菜单

                return false;
            }
            const getCmpData = (name) => this.$refs[name].getData();
            // basicSetting  formDesign processDesign 返回的是Promise 因为要做校验
            // advancedSetting返回的就是值
            const p1 = getCmpData("basicSetting");
            const p2 = getCmpData("formDesign");
            const p3 = getCmpData("processDesign");
            const p4 = getCmpData("advancedSetting");
            Promise.all([p1, p2, p3, p4])
                .then((res) => {
                    const param = {
                        basicSetting: res[0].formData,
                        processData: res[2].formData,
                        formData: res[1].formData,
                        advancedSetting: res[3].formData,
                    };
                    this.sendToServer(status, param);
                })
                .catch((err) => {
                    err.target && (this.activeStep = err.target);
                    err.msg && this.$message.warning(err.msg);
                });
        },
        sendToServer(status, param) {
            this.form = {
                id: this.id,
                status: status,
                resource: JSON.stringify(param),
            };
            this.loading = true;
            //console.log('resource', this.form.resource)
            saveBpmFlow(this.form)
                .then((response) => {
                    this.loading = false;
                    if (response.code === 0) {
                        this.msgSuccess("保存流程成功");
                        this.showComp = false;
                        this.visible = false;
                        this.$emit("refreshList");
                    } else {
                        this.msgError(response.msg);
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        exit() {
            this.$confirm("离开此页面您的修改将会丢失, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.showComp = false;
                    this.visible = false;
                })
                .catch(() => {});
        },
        onFlowNameChange(val) {
            this.title = val;
        },
        /**
         * 同步基础设置发起人和流程节点发起人
         */
        onInitiatorChange(val, labels) {
            const processCmp = this.$refs.processDesign;
            const startNode = processCmp.data;
            startNode.properties.initiator = val;
            startNode.content = labels || "所有人";
            processCmp.forceUpdate();
        },
        /**
         * 监听 流程节点发起人改变 并同步到基础设置 发起人数据
         */
        onStartChange(node) {
            const basicSetting = this.$refs.basicSetting;
            basicSetting.formData.initiator = {
                "dep&user": node.properties.initiator,
            };
        },
    },
    components: {
        Process,
        DynamicForm,
        BasicSetting,
        AdvancedSetting,
        Treeselect,
    },
};
</script>
<style lang="scss" >
.design-btn {
    right: 20px;
    position: absolute;
    top: 6px;
    z-index: 500;
}
.flowDesignDialog {
    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        padding: 5px 0 0;
    }
    .el-button.is-circle {
        border-radius: 50%;
    }
}
.design-tab > .el-tabs__header {
    margin: 0;
}
.flow-title {
    padding-left: 10px;
    font-weight: bold;
    color: #82848a;
}
</style>
