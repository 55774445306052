import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/report/design',
      name: 'design',
      component: () => import('./views/report/design.vue')
    },
    {
      path: '/report/view',
      name: 'view',
      component: () => import('./views/report/view.vue')
    },
    {
      path: '/dashboard/design',
      name: 'design',
      component: () => import('./views/dashboard/design.vue')
    },
    {
      path: '/dashboard/view',
      name: 'view',
      component: () => import('./views/dashboard/view.vue')
    }
  ]
})
