<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
			<el-form-item label="岗位编码" prop="code">
				<el-input v-model="queryParams.code" placeholder="请输入岗位编码" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="岗位名称" prop="name">
				<el-input v-model="queryParams.name" placeholder="请输入岗位名称" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="状态" prop="status">
				<el-select v-model="queryParams.status" placeholder="岗位状态" clearable size="small">
					<el-option v-for="dict in statusOptions" :key="dict.key" :label="dict.value" :value="dict.key" />
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>

	<!-- 	<vxe-grid resizable ref="xGrid" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="postList"
			@checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini"
					@click="handleAdd">新增</el-button>
				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single"
					@click="handleUpdate">修改</el-button>
				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
					@click="handleDelete">删除</el-button>
			</template> -->

			<!--默认操作按钮-->
		<!-- 	<template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-view" @click="handleUpdate(row, true)">查看
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)">删除
				</el-button>
			</template> -->

			<!--自定义空数据模板-->
	<!-- 		<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>

		</vxe-grid>
		<vxe-pager background size="small" :loading="loading" :current-page="queryParams.pageNum"
			:page-size="queryParams.pageSize" :total="total" @page-change="handleSizeChange">
		</vxe-pager> -->


		<jf-table :loading="loading" :columns="tableColumn" :data="postList" :toolbar-config="tableToolbar"
			:query-params="queryParams" @checkbox-change="checkboxChangeEvent" @page-change="handleSizeChange"
			:total="total" @change="tableChange">
			<template slot="toolbar_btn_left">
				<el-button  type="primary" icon="el-icon-plus" size="mini"
					@click="handleAdd">新增</el-button>
				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single"
					@click="handleUpdate">修改</el-button>
				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
					@click="handleDelete">删除</el-button>
			</template>
			<template slot="status" slot-scope="{ row }">
				{{statusFormat(row)}}
			</template>

			<template slot="toolbar_btn_row" slot-scope="{ row }">
				<el-button size="mini" type="text" icon="el-icon-view" @click="handleUpdate(row, true)">查看
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)">删除
				</el-button>
			</template>


		</jf-table>


		<!-- 添加或修改岗位对话框 -->
		<el-dialog :title="title" :visible.sync="open" width="500px" :close-on-click-modal="false" append-to-body>
			<jf-form ref="form" :model="form" :rules="rules" label-width="80px" :readonly="readonly"
				:readonly-column="1">
				<el-form-item label="岗位名称" prop="name">
					<el-input v-model="form.name" placeholder="请输入岗位名称" />
				</el-form-item>
				<el-form-item label="岗位编码" prop="code">
					<el-input v-model="form.code" placeholder="请输入编码名称" />
				</el-form-item>
				<el-form-item label="岗位顺序" prop="sort">
					<el-input-number v-model="form.sort" controls-position="right" :min="0" />
				</el-form-item>
				<el-form-item label="岗位状态" prop="status">
					<el-radio-group v-model="form.status">
						<el-radio v-for="dict in statusOptions" :key="dict.key"
							:label="dict.key">{{dict.value}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="form.remark" type="textarea" placeholder="请输入内容" />
				</el-form-item>
			</jf-form>
			<div slot="footer" class="dialog-footer">
				<template v-if="!readonly">
					<el-button type="primary" @click="submitForm">确 定</el-button>
					<el-button @click="cancel">取 消</el-button>
				</template>
				<template v-else>
					<el-button @click="cancel">关 闭</el-button>
				</template>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		listPost,
		getPost,
		delPost,
		addPost,
		updatePost,
		exportPost
	} from "@/api/core/system/post";

	export default {
		name: "post-list",
		data() {
			return {
				// 工具栏
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery
					},
					slots: {
						buttons: 'toolbar_buttons'
					}
				},
				// 列
				tableColumn: [
					// {
					// 	type: 'checkbox',
					// 	width: 60,
					// 	fixed: "left"
					// },
					//{field: 'id', title: '岗位编号', width: 100, fixed: "left"},
					{
						field: 'code',
						title: '岗位编码',
					},
					{
						field: 'name',
						title: '岗位名称',
					},
					{
						field: 'sort',
						title: '岗位排序',
					},
					{
						field: 'status',
						title: '状态',
						width: 100,
						// formatter: this.statusFormat,
						slots: {
							default: 'status'
						},
						form_type:2,
						options: [],
						
						options_label: 'value',
						options_value: 'key'
					},
					//        {field: 'create_time', title: '创建时间', width: 180, formatter: "formatDate"},
					// {
					// 	field: '',
					// 	title: '操作',
					// 	resizable: false,
					// 	width: 180,
					// 	fixed: "right",
					// 	align: "center",
					// 	slots: {
					// 		default: 'defaultopr'
					// 	}
					// }
				],
				// 遮罩层
				loading: true,
				// 选中数组
				ids: [],
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				// 总条数
				total: 0,
				// 岗位表格数据
				postList: [],
				// 弹出层标题
				title: "",
				// 是否查看
				readonly: false,
				// 是否显示弹出层
				open: false,
				// 状态数据字典
				statusOptions: [],
				// 查询参数
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					code: undefined,
					name: undefined,
					status: undefined
				},
				// 表单参数
				form: {},
				// 表单校验
				rules: {
					name: [{
						required: true,
						message: "岗位名称不能为空",
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: "岗位编码不能为空",
						trigger: "blur"
					}],
					sort: [{
						required: true,
						message: "岗位顺序不能为空",
						trigger: "blur"
					}]
				}
			};
		},
		created() {
			this.getList();

		},
		methods: {
			tableChange(e) {
				console.log(e)
			},
			handleSizeChange({
				currentPage,
				pageSize
			}) {
				this.queryParams.pageNum = currentPage;
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}

			},
			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map(item => item.id)
				this.single = records.length != 1;
				this.multiple = !records.length

			},

			/** 查询岗位列表 */
			getList() {
				this.loading = true;
				listPost(this.queryParams).then(response => {
					this.single = true;
					this.multiple = true;
					this.postList = response.data.list;
					this.total = response.data.total;
					this.statusOptions = response.data.statusOptions.values || []
					for(let i=0;i<this.tableColumn.length;i++){
						if(this.tableColumn[i].field==='status'){
							this.tableColumn[i].options = this.statusOptions
						}
					}
					this.loading = false;
				});
			},
			// 岗位状态字典翻译
			statusFormat(
				row
			) {
				return this.selectDictLabel(this.statusOptions, row.status);
			},
			// 取消按钮
			cancel() {
				this.open = false;
				this.reset();
			},
			// 表单重置
			reset() {
				this.form = {
					id: undefined,
					code: undefined,
					name: undefined,
					sort: 0,
					status: "1",
					remark: undefined
				};
				this.resetForm("form");
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},
			// 多选框选中数据
			handleSelectionChange(selection) {
				this.ids = selection.map(item => item.id)
				this.single = selection.length != 1
				this.multiple = !selection.length
			},
			/** 新增按钮操作 */
			handleAdd() {
				this.reset();
				this.readonly = false;
				this.title = "添加岗位";
				this.open = true;
			},
			/** 修改按钮操作 */
			handleUpdate(row, readonly) {
				this.reset();
				const id = row.id || this.ids
				getPost(id).then(response => {
					//this.form = response.data;
					let data = response.data
					if (data) {
						this.form = {
							id: data.id,
							code: data.code,
							name: data.name,
							sort: data.sort,
							status: data.status,
							remark: data.remark
						}
					}

					this.readonly = readonly;
					this.title = readonly ? "查看岗位" : "修改岗位";
					this.open = true;
				});
			},
			/** 提交按钮 */
			submitForm: function() {
				this.$refs["form"].validate(valid => {
					if (valid) {
						if (this.form.id != undefined) {
							updatePost(this.form).then(response => {
								if (response.code === 0) {
									this.msgSuccess("修改成功");
									this.open = false;
									this.getList();
								} else {
									this.msgError(response.msg);
								}
							});
						} else {
							addPost(this.form).then(response => {
								if (response.code === 0) {
									this.msgSuccess("新增成功");
									this.open = false;
									this.getList();
								} else {
									this.msgError(response.msg);
								}
							});
						}
					}
				});
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				const postIds = row.id || (this.ids ? this.ids.join(',') : '');
				this.$confirm('确定删除吗?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return delPost(postIds);
				}).then(() => {
					this.getList();
					this.msgSuccess("删除成功");
				}).catch(function() {});
			},
			/** 导出按钮操作 */
			handleExport() {
				const queryParams = this.queryParams;
				this.$confirm('是否确认导出所有岗位数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return exportPost(queryParams);
				}).then(response => {
					this.download(response.msg);
				}).catch(function() {});
			}
		}
	};
</script>