<!-- 生成时间:2023-09-12 11:33:48 -->
<template>
    <div class="app-container">
        <!-- 渲染查询表单 -->
        <!-- <VFormRender
            v-if="queryformData"
            class="query-form-data"
            ref="queryform"
            :formJson="queryformData"
        ></VFormRender> -->

        <!-- <el-form v-if="queryformData && queryformData.widgetList.length > 0">
           
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <jf-table
            :tree-config="treeConfig"
            :loading="loading"
            :query-params="queryParams"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @sort-change="sortChange"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
            @toggle-tree-expand="toggleExpandChangeEvent"
            @cell-dblclick="Oncelldblclick"
            @page-change="handleSizeChange"
            :total="total"
            :filterBtn="switchshowbtn('filter')"
            :sortBtn="switchshowbtn('sort')"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    v-if="switchshowbtn('add')"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermiZc="['/ai/bot/add']"
                    >新增</el-button
                >
                <el-button
                    v-if="switchshowbtn('edit')"
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermiZc.all="['/ai/bot/get', '/ai/bot/edit']"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermiZc="['/ai/bot/del']"
                    v-if="switchshowbtn('delete')"
                    >删除</el-button
                >
                <!-- <el-button
                    v-if="switchshowbtn('import')"
                    type="warning"
                    size="mini"
                    @click="handleImport(1)"
                    v-hasPermiZc="['/ai/bot/import']"
                >
                    <i class="iconfont icon-daoru_o"></i>
                    导入
                </el-button>

                <el-button
                    v-if="switchshowbtn('export')"
                    class="btn-orange"
                    size="mini"
                    @click="handleImport(2)"
                    v-hasPermiZc="['/ai/bot/export']"
                >
                    <i class="iconfont icon-daochu_o"></i>
                    导出
                </el-button> -->

                <el-dropdown @command="handleCommand">
                    <el-button
                        size="mini"
                        type="text"
                        style="margin-left: 10px"
                    >
                        更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="operationLogOpen"
                            >操作记录</el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
            </template>
            <!-- 	<template slot="toolbar_btn_right">

					</template> -->
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    v-if="switchshowbtn('get')"
                    type="text"
                    icon="el-icon-view"
                    @click="Oncelldblclick({ row })"
                    v-hasPermiZc="['/ai/bot/get']"
                    >查看
                </el-button>

                <el-button
                    v-if="switchshowbtn('edit')"
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    v-hasPermiZc="['/ai/bot/edit']"
                    >修改
                </el-button>

                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    v-hasPermiZc="['/ai/bot/del']"
                    @click="handleDelete(row)"
                    v-if="switchshowbtn('delete')"
                    >删除
                </el-button>
            </template>
            <template slot="status" slot-scope="{ row }">
                <el-switch
                    v-model="row.status"
                    :active-value="1"
                    :inactive-value="0"
                    @change="handleStatusChange(row)"
                ></el-switch>
            </template>
            <template slot="roles" slot-scope="{ row }">
                <span v-for="(item, index) of row.roles" :key="index">
                    {{ item.name }}
                </span>
            </template>
            <template
                :slot="item.slots.default"
                slot-scope="{ row }"
                v-for="item in columnSlots"
            >
                <div v-html="getFun(item, row)"></div>
            </template>
        </jf-table>

        <!-- 添加或修改对话框  数据 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            v-if="open"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
            :before-close="cancel"
            custom-class="scrolldialog"
        >
            <el-tabs v-model="form.ctype" type="border-card">
                <el-tab-pane
                    v-for="item in crypelist"
                    v-bind:key="item.name"
                    :name="item.name"
                    :label="item.label"
                >
                    <el-input
                        v-if="item.name == '1'"
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="form.text"
                        maxlength="3000"
                        rows="20"
                        show-word-limit
                    ></el-input>

                    <el-upload
                        :ref="'emfile' + item.name"
                        action="/file/upload"
                        class="import-upload"
                        :accept="accept[item.name]"
                        v-if="item.name != '1'"
                        drag
                        :auto-upload="false"
                        :http-request="doupload"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖到此处，或<em>点击上传</em>
                        </div>
                        <div class="el-upload__tip" slot="tip">不超过32M</div>
                    </el-upload>
                </el-tab-pane>
            </el-tabs>

            <div slot="footer" class="dialog-footer">
                <el-button v-if="!isview" type="primary" @click="submitupload"
                    >确 定</el-button
                >
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>

        <!-- 导入导出 -->
        <jf-import
            :show.sync="importOpen"
            v-if="importOpen"
            :type="importType"
            :columns="tableColumn"
            :isfinish="isupladfinish"
            :downloadtplurl="downloadtplurl"
            @importsubmit="importsubmit"
            :importError="importError"
            :exportTotal="total"
            @exportSubmit="exportSubmit"
            @saveExportTemplate="saveExportTemplate"
            :exportTemplateList="exportTemplateList"
        ></jf-import>
        <!-- 操作记录 -->
        <jf-operation-log
            :show.sync="operationLogOpen"
            v-if="operationLogOpen"
        ></jf-operation-log>

        <!-- 导入提示 -->
        <el-dialog
            title="导入提示"
            :close-on-click-modal="false"
            :visible.sync="showerrinfo"
            width="750px"
            append-to-body
            custom-class="scrolldialog"
        >
            <p class="erinfo" v-for="(item, idx) in upload_errinfo" :key="idx">
                {{ item }}
            </p>
        </el-dialog>
    </div>
</template>

<script>
import embeddingService from "./indexi.js";

import botService from "@/api/ai/bot";
import { form } from "@/mixins/form.js";

import { execModel } from "@/api/core/data/datasource/model";
import { getToken } from "@/utils/auth";

import Treeselect from "@riophae/vue-treeselect";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import jfImport from "@/components/jfimport/jfimport";
import JfOperationLog from "@/components/jfoperationlog/jfoperationlog";

import { getFieldWidgetByName, deepClone } from "@/vform/utils/util";
import { loadExtension } from "@/vform/extension/extension-loader";
import VFormRender from "@/vform/components/form-render/index.vue";
import { getUserProfile } from "@/api/core/system/user";
loadExtension();

export default {
    mixins: [form],
    name: "ai-bot-index",
    components: {
        Treeselect,
        VFormRender,
        jfImport,
        JfOperationLog,
    },

    data() {
        return {
            accept: {
                2: ".docx",
                3: ".pdf",
                4: ".csv",
            },
            crypelist: [
                {
                    name: "1",
                    label: "文本",
                },

                {
                    name: "2",
                    label: "word(docx) ",
                },
                {
                    name: "3",
                    label: "pdf",
                },
                {
                    name: "4",
                    label: "cvs",
                },
            ],

            org_optionItems: {},
            istree: false,
            hidequeryparamform: false,
            exportTemplateList: [],
            listconf: null,

            importError: false,
            downloadtplurl: "",
            upload_errinfo: null,
            showerrinfo: false,
            isupladfinish: false,
            //导入导出弹窗
            importOpen: false,
            //导入导出类型
            imporType: 1,
            //操作记录
            operationLogOpen: false,
            //功能id
            mid: "1700105590224748544", //不可修改
            queryformData: null, //查询表单数据
            queryformDataorgstr: "",

            formData: null, //表单数据
            isview: false,
            model_info: {},
            child_model_info: {},
            childcollist: [],
            collist: [],
            LeftTreeData: [],
            LeftTreeDataProc: {},
            treeOptions: [],
            treeselectprops: {},
            treeConfig: {
                trigger: "row",
                expandRowKeys: [],
            },
            TreeAttr: {},

            //左侧分栏比例
            leftrolspan: 0,
            //checkbox 的数据
            CheckBoxData: {},
            //字典库数据
            DictsData: {},
            DsData: {},

            area_dist: {},
            province_dist: {},
            city_dist: {},

            //上传的文件列表
            upload_fileList: {},

            //上传文件参数
            upload: {
                headers: {
                    Authorization: getToken(),
                },
                url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
            },

            //编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },

            //图片预览地址
            dialogImageUrl: "",
            //图片预览对话框
            dialogVisible: false,

            loadingstu: false,
            openselstu: false,

            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.getList,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 数据列
            tableColumn: [
                {
                    type: "checkbox",
                    minWidth: 60,
                    fixed: "left",
                },
                {
                    field: "uuid",
                    title: "ID",
                    minWidth: 100,
                    fixed: "left",
                },
                {
                    field: "question",
                    title: "文本",
                    minWidth: 500,
                    fixed: "left",
                },
                // {
                // 	field: "",
                // 	title: "操作",
                // 	resizable: false,
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: {
                // 		default: "defaultopr"
                // 	},
                // },
            ],

            orgcolumnList: [],
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            totalstu: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 快速检索数据
            Quitfilter_data: {},
            // 快速检索数据 字典类型
            Quitfilter_keys: [],
            // 是否显示弹出层
            open: false,
            // 机构名称
            deptname: undefined,

            //表单
            form: {
                ctype: "1",
            },
            defaultProps: {
                children: "children",
                label: "name",
            },

            // 表单校验
            rules: {},
            userInfo: {},
        };
    },

    computed: {
        columnSlots: function () {
            return this.tableColumn.filter((item) => {
                return item.slots;
            });
            // console.log(this.tableColumn.filter((item) => {return item.slots}))
        },
    },
    watch: {
        // 根据名称筛选机构树
        deptname(val) {
            this.$refs.tree.filter(val);
        },
    },
    beforeCreate() {},
    created() {
        this.downloadtplurl = botService.botimportTemplate(); //下载模版地址
        this.queryParams.sortinfo = { create_time: "desc" };
        // this.getforminfo(this.mid);
        this.getUser();
        this.getList();
    },
    methods: {
        submitupload() {
            console.log("this.$refs", this.$refs, this.form.ctype);
            this.$refs["emfile" + this.form.ctype][0].submit();
        },
        async doupload(reqdata) {
            console.log("reqdata", reqdata);
            let formData = new FormData();
            formData.append("file", reqdata.file);
            formData.append("ctype", parseInt(this.form.ctype));
            formData.append("text", this.form.text);

            embeddingService
                .addEmbedding(this.form.ctype, formData)
                .then((response) => {
                    this.cancel();
                    this.getList();
                    console.log("doupload", response);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        getUser() {
            getUserProfile().then((response) => {
                this.userInfo = response.data.user;
            });
        },
        getName(row) {},

        exportSubmit(fieldlist) {
            var that = this;
            var tfa = [];
            for (var i in fieldlist) {
                var byname = "";
                if (fieldlist[i].byname != null) {
                    byname = fieldlist[i].byname;
                }
                tfa.push(fieldlist[i].field + "=" + byname);
            }
            var durl = botService.botExportUrl(this.mid, this.queryParams, tfa);
            try {
                this.downloadbyurl(durl, null, function (ers) {
                    if (ers.currentTarget.status != 200) {
                        that.msgError("导出错误");
                    }
                });
            } catch (error) {
                console.log(chalk.red(error));
            }
        },
        importsubmit(rows) {
            //开始导入
            botService
                .botImport(this.mid, rows.datalist, rows.importoption)
                .then((rs) => {
                    if (
                        rs.data.row.Failmsg != null &&
                        rs.data.row.Failmsg != ""
                    ) {
                        this.upload_errinfo = rs.data.row.Failmsg.split("|");
                        this.showerrinfo = true;
                        this.importError = true;
                    } else {
                        this.isupladfinish = true;
                        this.msgSuccess("导入成功");
                        this.getList(this.mid);
                    }
                });
        },
        //导入导出
        handleImport(type) {
            this.importError = false;
            this.isupladfinish = false;
            this.importType = type;
            this.importOpen = true;
        },
        handleCommand(e) {
            //  console.log(e);
            this[e] = true;
        },

        getFun(item, row) {
            return this[item.slotsFun.funName](
                item.slotsFun.dt,
                row[item.slotsFun.key],
                item.slotsFun.key
            );
        },

        //查看
        Oncelldblclick(row) {
            this.handleUpdate(row.row, "查看");
            this.isview = true;
        },

        getTreeselect() {
            if (
                this.model_info.Category != "4" &&
                this.model_info.Category != "5"
            ) {
                return false;
            }
            this.treeOptions = [];
            let opt = {
                id: 0,
                children: [],
            };
            opt[this.TreeAttr.tree_name] = "(无上级)";

            if (this.model_info.Category == "5") {
                opt.children = this.LeftTreeData;
            } else {
                opt.children = this.dataList;
            }

            this.treeOptions.push(opt);
            // console.log(this.treeOptions);
        },

        /** 查询数据 */
        getList(mid) {
            this.loading = true;
            console.log("this.queryParams", this.queryParams);
            embeddingService
                .listEmbedding(this.queryParams)
                .then((response) => {
                    this.loading = false;
                    if (this.treeConfig.parentField != null) {
                        var tlist = [];
                        for (let k in response.data.list) {
                            let tonef = response.data.list[k];
                            tonef["label"] = tonef[this.treeConfig.treeNode];

                            tlist.push(JSON.parse(JSON.stringify(tonef)));
                        }
                        try {
                            this.dataList = this.handleTree(
                                tlist,
                                "id",
                                this.treeConfig.parentField
                            );
                        } catch (error) {
                            console.log(" this.dataList error", error);
                        }
                    } else {
                        this.dataList = response.data.rslist;
                    }

                    this.total = response.data.total;
                })
                .catch(() => {
                    this.loading = false;
                    // this.msgError("加载数据出错,请先检查表单配置!");
                });
        },

        // 筛选节点
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 取消按钮
        cancel() {
            this.form = { ctype: "1"};
            this.formData = null;
            this.open = false;
            this.isview = false;
        },
        // 表单重置
        reset() {
            this.form = {};
            this.formData = null;

            this.resetQuery();
        },

        /** 新增按钮操作 */
        handleAdd() {
            //this.reset();
            // this.getTreeselect();
            // this.hidequeryparamform = true;
            // this.formData = null;
            // let tformdata = JSON.parse(this.model_info.Formresource);
            // tformdata.formstatus = "add";

            // this.$nextTick((_) => {
            //     this.formData = this.binddicttotablecolumn(tformdata);
            // });

            this.open = true;
            this.title = "添加数据";
        },

        /** 修改按钮操作 */
        handleUpdate(row, til) {
            // this.reset();
            this.form = {};
            this.formData = null;
            // this.getTreeselect();
            //this.getTreeselect();
            const id = row.id || this.ids[0];
            botService.getBot(id).then((response) => {
                this.form.id = response.data.row.id;

                //填充表单值
                let formvalue = response.data.row; //表单填写内容

                let array = Object.keys(formvalue);
                this.reformatformdata(formvalue);
                let formData = JSON.parse(this.model_info.Formresource); //表单数据(字段,布局等)
                formData.formstatus = "edit";

                this.formData = formData;
                this.$nextTick((_) => {
                    //    formData;
                    this.formData = this.binddicttotablecolumn(formData);
                    console.log(formvalue, "formvalue");
                    this.$refs.form.setFormData(formvalue);
                });

                this.open = true;
                this.title = til || "修改";
            });
        },

        /** 提交按钮 */
        submitForm: function () {
            this.$refs.form
                .getFormData()
                .then((data) => {
                    //console.log("forminputdata", data);
                    if (this.form.id != null) {
                        //修改
                        data.id = this.form.id;
                        botService
                            .editBot(this.mid, data)
                            .then((rs) => {
                                this.msgSuccess("操作成功");
                                this.cancel();
                                this.handleQuery();
                            })
                            .catch(() => {
                                // this.msgError("提交失败!");
                            });
                    } else {
                        //新增
                        botService
                            .addBot(this.mid, data)
                            .then((rs) => {
                                this.msgSuccess("操作成功");
                                this.cancel();
                                this.handleQuery();
                            })
                            .catch(() => {
                                // this.msgError("提交失败!");
                            });
                    }
                })
                .catch((e) => {
                    this.msgError("表单验证不通过,请检查输入");
                });

            return true;
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const did = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(this.islogicdelTips, "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    return botService.delBot(did);
                })
                .then(() => {
                    this.handleQuery();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        normalizerDeptTreeNode(e) {
            //      e.id = e.id
            e.label = e.name;
            return e;
        },
    },
};
</script>
<style rel="stylesheet/scss" scoped>
.form-data {
    margin: 0 -2rem;
}
.btn-orange {
    background-color: #ff9648;
    color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #2196f3;
    color: #fff;
}

::v-deep .scrolldialog {
    max-height: 82vh;
}

::v-deep .el-dialog__body {
    height: calc(100vh - 300px);
    overflow-y: scroll;
}

.head-container {
    font-size: 14px;
}
</style>
<style lang="scss" scoped>
.import-upload {
    width: 100%;

    .el-upload {
        width: 100%;

        .el-upload-dragger {
            width: 100%;
          
        }
    }
}
</style>