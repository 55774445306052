<template>
  <div class="" >
    <el-dialog
      :loading="loading"
      fullscreen
      :title="title"
      custom-class="flowPreviewDialog"
      :close-on-click-modal="false"
      :visible.sync="visible" @close='onCloseDialog'>
      <el-tabs tab-position="left" v-model="activeStep" v-if="showComp" class="preview-tab">
        <el-tab-pane name="formDesign" label="表单信息">
          <span slot="label">
            <i class="fa fa-edit"></i> 表单信息
          </span>
          <generate-form ref="generateForm" :conf="resourceData.formData" :formBtns="true" v-if="showComp"/>
        </el-tab-pane>
        <el-tab-pane name="processDesign" label="流程图">
          <span slot="label">
            <i class="fa fa-file-picture-o"></i> 流程图
          </span>
          <div class="flow-container">
            <FlowCard
              :data="resourceData.processData"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
  import { getResource } from "@/api/bpm/resource";
  import GenerateForm from "@/components/jpgflow/DynamicForm/GenerateForm";
  import FlowCard from "@/components/jpgflow/Process/FlowCard/index.vue";

  export default {
    name:'bpm-flow-preview',
    data() {
      return {
        resourceData: {}, // 可选择诸如 $route.param，Ajax获取数据等方式自行注入
        title:"流程预览",
        activeStep: "formDesign", // 激活的步骤面板
        visible: false,
        showComp:false,
        loading: false,
      };
    },
    mounted() {
    },
    methods: {
      init(id) {
        this.loading = true
        this.showComp = false
        this.$nextTick(() => {
          getResource(id,"1").then(response => {
            //this.loading = false
            let data = response.data
            if (data) {
              this.resourceData = JSON.parse(data.resource)
              this.activeStep = "formDesign" // 激活的步骤面板
              this.showComp = true
              this.title = '流程预览【' +data['flow_name']+ ' V:'+data['version']+'】' ;
            }
          });
          this.visible = true
        })
      },
      onCloseDialog () {
        this.$emit('close')
        this.showComp = false
        this.visible = false
      },
    },
    components: {
      GenerateForm,
      FlowCard
    }
  };
</script>
<style lang="scss" >
  .flowPreviewDialog {
    .el-dialog__body {
      padding: 0;
    }
  }
  .preview-tab {
    .el-tabs__header {
      &.is-left {
        margin-right: -1px;
      }
    }
    .el-tabs__item{
      padding: 0 30px;
    }
    .el-tabs__content {
      padding-left: 0;
      border-left: 2px solid #dfe4ed;
    }
  }
  .flow-title {
    padding-left: 10px;
    font-weight: bold;
    color: #82848a;
  }
  .flow-container {
    display: inline-block;
    background: #f5f5f7;
    padding: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    overflow: auto;
  }

  .scale-slider {
    position: fixed;
    right: 0;
    z-index: 99;
    padding-right: 10px;

    .btn {
      display: inline-block;
      padding: 4px;
      border: 1px solid #cacaca;
      border-radius: 3px;
      background: #FFF;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
</style>
