<template>
    <div class="piechart-main">
        <div v-text="value + '%'" :style="{ fontSize: fontSize + 'px' }"></div>
        <div :id="'chart' + id" class="piechart"></div>
    </div>
</template>
<script>
import $echarts from "echarts";
export default {
    name: "piechart",
    props: {
        id: {
            type: Number,
            default: null,
        },
        dataList: {
            type: Array,
            default: () => [],
        },
        value: {
            type: [Number, String],
            default: 0,
        },
        color: {
            type: String,
            default: "#ffffff",
        },
        fontSize: {
            type: [Number, String],
            default: "14",
        },
    },
    mounted() {
        window.addEventListener("resize", this.changeLinechart);
        this.initChart();
    },
    data() {
        return {
            chart: null,
            // 横坐标
            list: [],
        };
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.changeLinechart);
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }
    },
    methods: {
        initChart() {
            var chartDom = document.getElementById(`chart${this.id}`);
            this.chart = $echarts.init(chartDom);

            var option;

            option = {
                tooltip: {
                    trigger: "item",
                },

                series: [
                    {
                        name: "数据",
                        type: "pie",
                        radius: "100%",
                        data: [
                            { value: this.value },
                            { value: 100 - this.value * 1 },
                        ],
                        labelLine: {
                            show: false,
                        },
                        label: {
                            show: false,
                        },
                    },
                ],
                color: ["#68bbc4", this.color],
            };

            option && this.chart.setOption(option);
            this.chart.resize();
            // this.chart = echarts.init(
            //     document.getElementById(`chart${this.id}`)
            // );
            // this.chart.setOption({
            //     title: {
            //         text: this.title,
            //     },
            //     xAxis: {
            //         type: "category",
            //         data: this.list,
            //     },
            //     yAxis: {
            //         type: "value",
            //     },
            //     series: [
            //         {
            //             data: this.dataList,
            //             type: "line",
            //         },
            //     ],
            // });
            // this.chart.resize();
            // tooltip: {
            //         trigger: "item",
            //         formatter: "{a} <br/>{b}: {c} ({d}%)",
            //     },
            //     series: [
            //         {
            //             name: name,
            //             type: "pie",
            //             radius: ["50%", "70%"],
            //             avoidLabelOverlap: false,
            //             label: {
            //                 show: false,
            //                 position: "center",
            //             },
            //             emphasis: {
            //                 label: {
            //                     show: true,
            //                     fontSize: "30",
            //                     fontWeight: "bold",
            //                 },
            //             },
            //             labelLine: {
            //                 show: false,
            //             },
            //             data: [
            //                 { value: value, name: "Data" },
            //                 { value: 100 - value, name: "Empty" },
            //             ],
            //         },
            //     ],
        },
        // 更新折线图宽度
        changeLinechart() {
            this.chart.resize();
        },
    },
};
</script>
<style scoped>
.piechart-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.piechart {
    width: 70%;
    height: 100%;
    margin-left: 3px;
}
</style>
