var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix d-flex align-items-center",attrs:{"slot":"header"},slot:"header"},[_vm._v(" 表单信息 "),_c('div',{staticClass:"mx-5"},[_vm._v("——")]),(!_vm.is_edit)?_c('div',[_vm._v(_vm._s(_vm.tablename))]):_c('el-input',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入内容"},model:{value:(_vm.newTableNmae),callback:function ($$v) {_vm.newTableNmae=$$v},expression:"newTableNmae"}},[_c('el-button',{attrs:{"slot":"append"},on:{"click":_vm.editTableName},slot:"append"},[_vm._v("确定")])],1),_c('i',{class:!_vm.is_edit ? 'el-icon-edit' : 'el-icon-error',staticStyle:{"font-size":"18px","margin-left":"10px"},style:({ color: !_vm.is_edit ? '#409eff' : '#F56C6C' }),on:{"click":function($event){_vm.is_edit = !_vm.is_edit}}})],1),_c('jf-table',{ref:"jftable",attrs:{"loading":_vm.loading,"columns":_vm.tableColumn,"data":_vm.dataList,"checkbox":false,"pager":false,"operate":false,"height":680,"align":'center'},scopedSlots:_vm._u([{key:"toolbar_btn_row",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"text","icon":"el-icon-delete","disabled":row.is_default},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除 ")])]}},{key:"primaryKey",fn:function(ref){
var row = ref.row;
return [(row.is_default)?_c('i',{staticClass:"iconfont icon-square",staticStyle:{"color":"#d3d3d3"}}):_c('i',{staticClass:"iconfont",class:row.primaryKey
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square'})]}},{key:"insert",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [(row.is_default)?_c('i',{staticClass:"iconfont icon-square",staticStyle:{"color":"#d3d3d3"}}):_c('i',{staticClass:"iconfont",class:row.options &&
                        row.options.showpos &&
                        row.options.showpos.indexOf('新增') > -1
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.toTableEdit('新增', rowIndex)}}})]}},{key:"update",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [_c('i',{staticClass:"iconfont",class:row.options &&
                        row.options.showpos &&
                        row.options.showpos.indexOf('修改') > -1
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.toTableEdit('修改', rowIndex)}}})]}},{key:"table",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [_c('i',{staticClass:"iconfont",class:row.options &&
                        row.options.showpos &&
                        row.options.showpos.indexOf('列表') > -1
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.toTableEdit('列表', rowIndex)}}})]}},{key:"query",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [(row.is_default)?_c('i',{staticClass:"iconfont icon-square",staticStyle:{"color":"#d3d3d3"}}):_c('i',{staticClass:"iconfont",class:row.options &&
                        row.options.showpos &&
                        row.options.showpos.indexOf('查询') > -1
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.toTableEdit('查询', rowIndex)}}})]}},{key:"label",fn:function(ref){
                            var row = ref.row;
return [_c('vxe-input',{attrs:{"disabled":row.is_default},model:{value:(row.options.label),callback:function ($$v) {_vm.$set(row.options, "label", $$v)},expression:"row.options.label"}})]}},{key:"fieldType",fn:function(ref){
                            var row = ref.row;
return [_c('vxe-input',{attrs:{"disabled":row.is_default},model:{value:(row.options.fieldType),callback:function ($$v) {_vm.$set(row.options, "fieldType", $$v)},expression:"row.options.fieldType"}})]}},{key:"requied",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [(row.is_default)?_c('i',{staticClass:"iconfont icon-square",staticStyle:{"color":"#d3d3d3"}}):_c('i',{staticClass:"iconfont",class:row.options.required
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.toTableEdit('requied', rowIndex)}}})]}},{key:"import",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [(row.is_default)?_c('i',{staticClass:"iconfont icon-square",staticStyle:{"color":"#d3d3d3"}}):_c('i',{staticClass:"iconfont",class:row.options &&
                        row.options.showpos &&
                        row.options.showpos.indexOf('导入') > -1
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.toTableEdit('导入', rowIndex)}}})]}},{key:"export",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [(row.is_default)?_c('i',{staticClass:"iconfont icon-square",staticStyle:{"color":"#d3d3d3"}}):_c('i',{staticClass:"iconfont",class:row.options &&
                        row.options.showpos &&
                        row.options.showpos.indexOf('导出') > -1
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.toTableEdit('导出', rowIndex)}}})]}},{key:"sort",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [(row.is_default)?_c('i',{staticClass:"iconfont icon-square",staticStyle:{"color":"#d3d3d3"}}):_c('i',{staticClass:"iconfont",class:row.options.showsortbtn
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.toTableEdit('showsortbtn', rowIndex)}}})]}},{key:"validation",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [(row.options.validation)?_c('el-select',{attrs:{"disabled":row.is_default,"placeholder":"请选择"},model:{value:(row.options.validation),callback:function ($$v) {_vm.$set(row.options, "validation", $$v)},expression:"row.options.validation"}},_vm._l((_vm.fieldValidators),function(item){return _c('el-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1):_vm._e()]}},{key:"querytype",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [_c('el-select',{attrs:{"placeholder":"请选择","disabled":row.is_default},model:{value:(row.options.querytype),callback:function ($$v) {_vm.$set(row.options, "querytype", $$v)},expression:"row.options.querytype"}},_vm._l((_vm.querytype_list),function(item){return _c('el-option',{key:item.label,attrs:{"label":item.label,"value":item.value}})}),1)]}},{key:"dicttype",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [(row.options.dicttype)?_c('el-select',{attrs:{"placeholder":"请选择","disabled":row.is_default},model:{value:(row.options.dicttype),callback:function ($$v) {_vm.$set(row.options, "dicttype", $$v)},expression:"row.options.dicttype"}},_vm._l((_vm.dicttypelist),function(item){return _c('el-option',{key:item.type,attrs:{"value":item.type,"label":item.name}})}),1):_vm._e()]}},{key:"isShow",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
                            var columnIndex = ref.columnIndex;
return [(row.is_default)?_c('i',{staticClass:"iconfont",class:row.isShow
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.isReturnChange(row, rowIndex, columnIndex, 'isShow')}}}):_c('i',{staticClass:"iconfont icon-square",staticStyle:{"color":"#d3d3d3"}})]}},{key:"isReturn",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
                            var columnIndex = ref.columnIndex;
return [_c('i',{staticClass:"iconfont",class:row.isReturn
                            ? 'icon-checkbox-checked-fill text-primary'
                            : ' icon-square',on:{"click":function($event){return _vm.isReturnChange(
                            row,
                            rowIndex,
                            columnIndex,
                            'isReturn'
                        )}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }