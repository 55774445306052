<!-- 生成时间:2022-03-21 14:36:33 -->

<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
    >
      <el-form-item label="完整名称" prop="indexname">
        <el-input
          v-model="queryParams.indexname"
          placeholder="请输入完整名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="课程活动名称" prop="actname">
        <el-input
          v-model="queryParams.actname"
          placeholder="请输入课程活动名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="开始时间" prop="starttime">
        <el-date-picker
          clearable
          size="small"
          style="width: 200px"
          v-model="queryParams.starttime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="endtime">
        <el-date-picker
          clearable
          size="small"
          style="width: 200px"
          v-model="queryParams.endtime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="地址" prop="adr">
        <el-input
          v-model="queryParams.adr"
          placeholder="请输入地址"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          type="cyan"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      stripe
      show-overflow
      highlight-hover-row
      :loading="loading"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="dataList"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
    >
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['/growthindicators/gyxy/actrecord/add']"
          >新增</el-button
        >

        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="[
            '/growthindicators/gyxy/actrecord/get',
            '/growthindicators/gyxy/actrecord/edit',
          ]"
          >修改</el-button
        >

        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['/growthindicators/gyxy/actrecord/del']"
          >删除</el-button
        >
        <el-select
          v-model="datafiltersel"
          filterable
          placeholder="数据过滤"
          @change="handledatafilterChange"
        >
          <el-option
            v-for="item in datafilter"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button
          v-if="row.flow_inst_status == 0"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          >修改
        </el-button>

        <el-button
          v-if="row.flow_inst_status == 1"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          >查看审核
        </el-button>

        <el-button
          v-if="row.flow_inst_status == 2"
          size="mini"
          type="text"
          icon="el-icon"
 
          >审核已通过
        </el-button>

        <el-button
          v-if="row.flow_inst_status == 0"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleBpmStart(row)"
          >提交审核
        </el-button>
        <el-button
          v-if="row.flow_inst_status == 0"
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
        <span>
          <p>暂无数据</p>
        </span>
      </template>
    </vxe-grid>

    <vxe-pager
      background
      size="small"
      :loading="loading"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      :total="total"
      @page-change="handleSizeChange"
    >
    </vxe-pager>

    <!-- 添加或修改对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="关联指标" prop="indexid">
          <el-input
            v-model="form.indexid"
            placeholder="请选择"
            @focus="openselindex"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="openselindex"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="指标名称" prop="indexname">
          <el-input v-model="form.indexname" :disabled="true" placeholder="" />
        </el-form-item>
        <el-form-item label="课程活动名称" prop="actname">
          <el-input v-model="form.actname" placeholder="请输入课程活动名称" />
        </el-form-item>
        <el-form-item label="开始时间" prop="starttime">
          <el-date-picker
            clearable
            size="small"
            style="width: 200px"
            v-model="form.starttime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endtime">
          <el-date-picker
            clearable
            size="small"
            style="width: 200px"
            v-model="form.endtime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="地址" prop="adr">
          <el-input v-model="form.adr" placeholder="请输入地址" />

          <!-- <vue-ueditor-wrap ref="ueditor" v-model="form.adr" :config="ueConfig"></vue-ueditor-wrap> -->
        </el-form-item>
        <el-form-item label="佐证材料" prop="otherfile">
          <el-upload
            class=""
            accept=".jpg,.png,.xls,.doc,.docs,.xlsx"
            :headers="upload.headers"
            :action="upload.url + '&category=actrecord&return_field=otherfile'"
            :on-success="handleImportSuccess"
            :limit="5"
            list-type="picture-card"
            :file-list="otherfile_fileList"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                onerror="this.src='images/default-file-ico.png'"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file, 'otherfile')"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>

            <view slot="tip" class="el-upload__tip"
              >只能上传jpg/png,word文件，且不超过5M</view
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="情况说明" prop="actnote">
          <el-input v-model="form.actnote" placeholder="请输入情况说明" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <div v-if="form.flow_inst_status == 1">
          <el-select
            v-model="opinion"
            filterable
            placeholder="请选择常用语"
            @change="handleOpinionChange"
          >
            <el-option
              v-for="item in opinions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入处理意见"
            resize="none"
            v-model="submitcomment"
            maxlength="100"
            show-word-limit
          >
          </el-input>
          <el-button type="primary" @click="doSubmit('submit')">提交</el-button>
          <el-button @click="cancel">返 回</el-button>
        </div>

        <div v-if="form.flow_inst_status == 0">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">返 回</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <!--选择指标-->
    <el-dialog
      :close-on-click-modal="false"
      title="指标选择"
      :visible.sync="selindex"
      width="800px"
      append-to-body
    >
      <el-tree
        :props="props"
        node-key="id"
        :data="idxdata"
        ref="idxtree"
        highlight-current
      >
      </el-tree>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm_selindex">确 定</el-button>
      </div>
    </el-dialog>

    <!--高级查询对话框-->
    <el-dialog
      title="高级查询"
      :visible.sync="advqueryopen"
      width="1300px"
      append-to-body
    >
      <el-row :gutter="20">
        <el-col :span="15" :xs="24">
          <vxe-grid
            border
            resizable
            keep-source
            ref="xAdvqueryGrid"
            id="id_xAdvqueryGrid"
            height="500"
            :columns="advqueryColumn"
            :toolbar-config="advqueryToolbar"
            :data="advqueryData"
            :custom-config="{ storage: true }"
            :edit-config="{ trigger: 'click', mode: 'row', showStatus: true }"
            @toolbar-button-click="advqtoolbarButtonClickEvent"
          >
            <template #defaultadvqueryopr="{ row }"> </template>
          </vxe-grid>
        </el-col>
        <el-col :span="9" :xs="24">
          <vxe-grid
            border
            resizable
            keep-source
            ref="xAdvqueryGrid_tempfilter"
            id="id_xAdvqueryGrid_tempfilter"
            height="500"
            :columns="advqueryColumn_tempfilter"
            :data="advqueryData_tempfilter"
          >
            <template #defaultadvqueryopr_tempfilter="{ row }">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleadvqueryApplytpl(row)"
                >应用
              </el-button>

              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleadvqueryDeltpl(row)"
                >删除
              </el-button>
            </template>
          </vxe-grid>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="advquery_save">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <!--保存模版-->
    <el-dialog
      :close-on-click-modal="false"
      title="保存模版"
      :visible.sync="advquery_tempsave"
      width="800px"
      append-to-body
    >
      <el-form
        ref="advquery_tempsaveform"
        :model="advquery_tempsaveform"
        :rules="advquery_tempsaverules"
        label-width="80px"
      >
        <el-form-item label="模版名称" prop="tempname">
          <el-input
            v-model="advquery_tempsaveform.tempname"
            placeholder="请输入模版名称"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="advquery_save">确 定</el-button>
      </div>
    </el-dialog>

    <!--导出数据-->
    <el-dialog
      :close-on-click-modal="false"
      title="导出数据"
      :visible.sync="exportdialog"
      width="800px"
      append-to-body
    >
      <el-checkbox
        :indeterminate="exportisIndeterminate"
        v-model="exportcheckAll"
        @change="handleexportCheckAllChange"
        >全选</el-checkbox
      >
      <div style="margin: 15px 0"></div>

      <el-checkbox-group v-model="exportfields">
        <el-checkbox
          v-for="field in tableColumn"
          v-if="field.field"
          :label="field.field"
          :key="field.field"
          >{{ field.title }}</el-checkbox
        >
      </el-checkbox-group>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doExport">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  listActrecord,
  getActrecord,
  delActrecord,
  addActrecord,
  editActrecord,
  advquerydelActrecord,
  advquerysaveActrecord,
  advquerylistActrecord,
  exportActrecord,
  afterBpmAction,
} from "@/api/growthindicators/gyxy/actrecord";
import { listIndexs } from "@/api/growthindicators/gyxy/indexs";
import { getAccessToken } from '@/utils/tokenTool'

import { startProcess } from "@/api/bpm/process";
import { completeTask } from "@/api/bpm/task";
export default {
  components: {},
  name: "actrecord",

  data() {
    return {
      submitcomment: "",
      opinions: [
        { value: "1", label: "同意" },
        { value: "3", label: "不同意" },
        { value: "4", label: "材料不齐全" },
      ],
      datafiltersel: "2",
      datafilter: [
        {
          label: "全部",
          value: "1",
        },
         {
          label: "审核通过",
          value: "4",
        },
        {
          label: "待提交",
          value: "2",
        },
        {
          label: "待我审核",
          value: "3",
        },
      ],
      province: "广东省",
      adr_dist: { province: "", city: "", area: "" },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,

      upload: {
        headers: { Authorization: getAccessToken() },
        url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
      },

      otherfile_fileList: [],
      idxdata: [],
      selindex: false,
      props: {
        label: "name",
        children: "children",
      },
      selindex: false,
      businessname: "actrecord", //请勿改动
      exportfields: [],
      exportcheckAll: false,
      exportisIndeterminate: false,
      exportdialog: false,

      //编辑器配置
      ueConfig: {
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
        enableAutoSave: true,
        elementPathEnable: false,
        wordCount: false,
        UEDITOR_HOME_URL: "/ueditor/",
      },
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },

      // 列
      tableColumn: [
        { type: "checkbox", width: 30, fixed: "left" },
        { field: "id", title: "ID", width: 80, fixed: "left" },
        { field: "indexname", title: "指标名称", minWidth: 80 },
        { field: "actname", title: "课程活动名称", minWidth: 80 },
        {
          field: "starttime",
          title: "开始时间",
          minWidth: 80,
          formatter: "formatDate",
        },
        {
          field: "endtime",
          title: "结束时间",
          minWidth: 80,
          formatter: "formatDate",
        },
        { field: "adr", title: "地址", minWidth: 80 },
        { field: "actnote", title: "情况说明", minWidth: 80 },
        {
          field: "create_time",
          title: "创建时间",
          minWidth: 80,
          formatter: "formatDate",
        },
        {
          field: "update_time",
          title: "更新时间",
          minWidth: 80,
          formatter: "formatDate",
        },

        {
          field: "",
          title: "操作",
          width: 220,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],

      advqueryColumn: [
        {
          field: "qfield",
          title: "查询字段",
          width: 180,
          fixed: "left",
          editRender: {
            name: "select",
            options: [
              { value: "dd", label: "cxvs" },
              { value: "zz", label: "cxvs" },
            ],
          },
        },
        {
          field: "qopr",
          title: "操作符",
          width: 180,
          fixed: "left",
          editRender: { name: "select", options: [] },
        },
        {
          field: "qvalue",
          title: "值",
          width: 180,
          fixed: "left",
          editRender: { name: "input" },
        },
        {
          field: "qlogic",
          title: "逻辑",
          width: 180,
          fixed: "left",
          editRender: { name: "select", options: [] },
        },
        {
          field: "",
          title: "操作",
          resizable: false,
          width: 110,
          fixed: "right",
          align: "center",
          slots: { default: "defaultadvqueryopr" },
        },
      ],
      advqueryColumn_tempfilter: [
        { field: "tempname", title: "模版名称", width: 200, fixed: "left" },
        {
          field: "",
          title: "操作",
          resizable: false,
          width: 180,
          fixed: "right",
          align: "center",
          slots: { default: "defaultadvqueryopr_tempfilter" },
        },
      ],
      advqueryData_tempfilter: [],
      advqueryopen: false,
      advquery_tempsave: false,
      advqueryData: [],
      advqueryToolbar: {
        buttons: [
          { code: "myInsert", name: "新增" },
          { code: "dofilter", name: "使用此组合条件查询" },
        ],
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 字典

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        opstr: {
          indexname: "LIKE",
          actname: "LIKE",
          starttime: "=",
          endtime: "=",
          adr: "=",
        },
        indexname: undefined,
        actname: undefined,
        starttime: undefined,
        endtime: undefined,
        adr: undefined,
        flow_inst_status: "0",
        extfilter: "2",
      },
      // 表单参数
      form: {},
      advquery_tempsaveform: {},
      advquery_tempsaverules: {
        tempname: [
          { required: true, message: "模版名称不能为空", trigger: "blur" },
        ],
      },
      // 表单校验
      rules: {
        actname: [
          { required: true, message: "课程活动名称不能为空", trigger: "blur" },
        ],

        starttime: [
          { required: true, message: "开始时间不能为空", trigger: "blur" },
        ],

        endtime: [
          { required: true, message: "结束时间不能为空", trigger: "blur" },
        ],

        adr: [{ required: true, message: "地址不能为空", trigger: "blur" }],

        otherfile: [
          { required: true, message: "佐证材料不能为空", trigger: "blur" },
        ],

        actnote: [
          { required: true, message: "情况说明不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getList();

    setTimeout(() => {
      const $grid = this.$refs.xAdvqueryGrid;
      // 异步更新下拉选项
      var ta = [{ value: "", label: "请选择" }];
      for (var i in this.tableColumn) {
        var e = this.tableColumn[i];
        if (e.field != null && e.field != "") {
          ta.push({ value: e.field, label: e.title });
        }
      }
      this.advqueryColumn[0].editRender.options = ta;

      this.advqueryColumn[1].editRender.options = this.defaultQlogic();

      ta = [];
      ta.push({ value: "AND", label: "并且" });
      ta.push({ value: "OR", label: "或者" });
      this.advqueryColumn[3].editRender.options = ta;
    }, 300);
    // 加载字典数据
  },
  methods: {
    handleOpinionChange(value) {
      if (value != "") {
        this.submitcomment = value;
      }
    },
    handledatafilterChange(value) {
      console.log("value", value);
      if (value != "") {
        this.datafiltersel = value;
      }
      if (value == "2") {
        this.queryParams.flow_inst_status = "0";
      } else if (value == "4") {
        this.queryParams.flow_inst_status = "2";
      }
      else {
        this.queryParams.flow_inst_status = undefined;
        if (value == "3") {
          this.queryParams.extfilter = "3";
        } else {
          this.queryParams.extfilter = "";
        }
      }
      this.getList();
    },
    adr_dist_province_change(data) {
      console.log("adr_dist_province_change data", data);
      this.adr_dist.province = data.value;
    },
    adr_dist_city_change(data) {
      console.log("adr_dist_city_change data", data);
      this.adr_dist.city = data.value;
    },
    adr_dist_area_change(data) {
      console.log("adr_dist_area_change data", data);
      this.adr_dist.area = data.value;
    },

    ondistpickersel(data, e1) {
      console.log("ondistpickersel", data, e1);
    },
    handleRemove(file, field) {
      // console.log(file,e1,e2,e3);
      // return;
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var index = this.form[field].indexOf(file.url);
          if (index >= 0) {
            this.form[field].splice(index, 1);
          }
          index = -1;
          for (var i in this[field + "_fileList"]) {
            if (this[field + "_fileList"][i].url == file.url) {
              index = i;
              break;
            }
          }
          if (index >= 0) {
            this[field + "_fileList"].splice(index, 1);
          }
        })
        .catch(function () {});
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleImportSuccess(response, file, fileList, e1) {
      console.log(response);

      this.upload.open = false;
      this.upload.isUploading = false;
      var field = response.data.return_field;

      this.form[field] = this.form[field] || [];
      this.form[field].push(response.data.url);
      this[field + "_fileList"].push({ url: response.data.url });
    },
    confirm_selindex() {
      var selindex = this.$refs.idxtree.getCurrentNode();
      console.log(selindex);
      this.form.indexname = selindex.name;
      this.form.indexid = selindex.id;
      this.selindex = false;
    },
    openselindex() {
      var queryParams = {
        pageNum: 1,
        pageSize: 9999,
      };

      listIndexs(queryParams).then((response) => {
        this.idxdata = this.handleTree(response.data.list, "id", "pid");

        this.selindex = true;
        if (this.form.indexs) {
          this.$refs.idxtree.setCheckedKeys(this.form.indexs.split(","));
        }
      });
    },

    handleUpload() {},
    handleExport() {
      if (this.total > 0) {
        this.exportdialog = true;
      } else {
        this.msgError("暂无数据可导出!");
      }
    },
    doExport() {
      const queryParams = this.queryParams;
      this.$confirm("是否确认导出?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return exportActrecord(
            queryParams,
            this.exportfields,
            this.tableColumn
          );
        })
        .then((response) => {
          this.download(response.file);
        })
        .catch(function () {});
    },
    handleexportCheckAllChange(val) {
      this.exportfields = val
        ? this.tableColumn.map((item) => {
            return item.field;
          })
        : [];
      this.exportisIndeterminate = false;
    },
    //删除查询模版
    handleadvqueryDeltpl(row) {
      var ids = row.id;
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          advquerydelActrecord(ids)
            .then(() => {
              const $grid = this.$refs.xAdvqueryGrid_tempfilter;
              $grid.remove(row);
            })
            .then(() => {
              this.msgSuccess("删除成功");
            });
        })
        .then(() => {
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    //打开高级查询
    handleAdvquery() {
      advquerylistActrecord(this.businessname).then((response) => {
        this.advqueryData_tempfilter = response.data.list;
        this.advqueryopen = true;
      });
    },
    //保存条件
    advquery_save() {
      const $grid = this.$refs.xAdvqueryGrid;
      var alldata = $grid.getTableData();
      if (alldata.fullData.length == 0) {
        return false;
      }

      var opstr = {};
      var filteroptview = [];
      var Qlogicar = this.defaultQlogic();
      var queryParams = {};
      var fields = {},
        Qlogic = {};

      for (var i in this.tableColumn) {
        var col = this.tableColumn[i];
        if (col.field != null && col.field != "") {
          fields[col.field] = col.title;
        }
      }

      for (var i in Qlogicar) {
        var lgc = Qlogicar[i];
        Qlogic[lgc.value] = lgc.label;
      }

      for (var i in alldata.fullData) {
        var onerow = alldata.fullData[i];
        queryParams[onerow.qfield] = onerow.qvalue;
        opstr[onerow.qfield] = onerow.qopr;
        filteroptview.push(
          fields[onerow.qfield] + " " + Qlogic[onerow.qopr] + "" + onerow.qvalue
        );
      }

      queryParams.opstr = opstr;
      this.advquery_tempsaveform["businessname"] = this.businessname;
      this.advquery_tempsaveform["filteropt"] = JSON.stringify(queryParams);
      this.advquery_tempsaveform["filteroptview"] = filteroptview.join(";");

      this.$refs["advquery_tempsaveform"].validate((valid) => {
        if (valid) {
          advquerysaveActrecord(this.advquery_tempsaveform).then((response) => {
            if (response.code === 0) {
              this.msgSuccess("保存成功");
              this.advquery_tempsave = false;
              this.handleAdvquery();
            }
          });
        }
      });
    },

    //应用查询模版
    handleadvqueryApplytpl(row) {
      var Filteropt = JSON.parse(row.filteropt);

      Filteropt["pageNum"] = 1;
      Filteropt["pageSize"] = 10;
      this.queryParams = Filteropt;
      this.advqueryopen = false;
      setTimeout(() => {
        this.getList();
      }, 300);
    },
    //删除临时条件
    handleadvqueryDeleted(row) {
      const $grid = this.$refs.xAdvqueryGrid;

      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          $grid.remove(row);
        })
        .then(() => {
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    advqtoolbarButtonClickEvent({ code }) {
      const $grid = this.$refs.xAdvqueryGrid;
      switch (code) {
        case "myInsert":
          var row = $grid.insert({
            qfield: "",
            qopr: "=",
            qlogic: "AND",
          });

          $grid.setActiveRow($grid.getData(0));
          break;

        case "dosavefilter":
          var alldata = $grid.getTableData();
          if (alldata.fullData.length == 0) {
            return false;
          }
          this.advquery_tempsave = true;
          break;
        case "dofilter":
          var alldata = $grid.getTableData();
          if (alldata.fullData.length == 0) {
            return false;
          }

          var opstr = {};

          var queryParams = {
            pageNum: 1,
            pageSize: 10,
            opstr: {},
          };

          for (var i in alldata.fullData) {
            var onerow = alldata.fullData[i];
            queryParams[onerow.qfield] = onerow.qvalue;
            opstr[onerow.qfield] = onerow.qopr;
          }

          queryParams.opstr = opstr;
          this.queryParams = queryParams;
          this.advqueryopen = false;
          setTimeout(() => {
            this.getList();
          }, 300);
          break;
      }
    },

    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },

    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },

    /** 查询列表 */
    getList() {
      this.loading = true;

      listActrecord(this.queryParams).then((response) => {
        this.dataList = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    // $comment字典翻译

    // 取消按钮
    cancel() {
      this.open = false;
      this.advqueryopen = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        indexid: undefined,
        indexname: undefined,
        actname: undefined,
        starttime: undefined,
        endtime: undefined,
        adr: undefined,
        otherfile: undefined,
        actnote: undefined,
        flow_inst_status: 0,
      };
      this.otherfile_fileList = [];
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      getActrecord(id).then((response) => {
        this.form = response.data.data;
        this.form.starttime = this.unixTimestamptoDate(this.form.starttime);
        this.form.endtime = this.unixTimestamptoDate(this.form.endtime);

        this.form.otherfile = this.form.otherfile.split(",") || [];
        this.otherfile_fileList = this.form.otherfile.map((v) => {
          return { url: v };
        });

        this.open = true;
        this.title = "修改";
        if (this.form.flow_inst_status == 1) {
          this.title = "查看审核";
        }
      });
    },
    handleBpmStart(row) {
      this.$confirm("是否确认提交审核?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var starttime = this.$route.query.time || this.XEUtils.now();
          // console.log("startProcess", startProcess);
          startProcess({
            flowId: 46,
            code: "submit",
            dataId: row.id,
            time: starttime,
            title: `${this.$store.state.user.name} 在 ${this.XEUtils.toDateString(
              starttime
            )} 提交了 [课程活动备案审核]`,
            resourceId: 100,
            vars: "{}",
          }).then((response) => {
            // console.log(response);
            //let ret = response.data
            this.msgSuccess("发起流程成功");
            // this.$store.dispatch("tagsView/delView", this.$route);
            // this.$router.push({ path: "/bpm/process/myList" });
            return afterBpmAction({
              resource_id: 100,
              data_id: "" + row.id,
              code: "start",
            });
          });
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
        })
        .catch(function () {}); 
    },
    doSubmit(code) {
      completeTask("submit", {
        code: "submit",
        comment: this.submitcomment,
        candidates: null,
        ccs: null,
        resourceId: 100,
        processId: this.form.flow_inst_id,
        taskId: this.form.task_id,
        vars: "{}",
      }).then(() => {
        afterBpmAction({
          resource_id: 100,
          data_id: ''+this.form.id,
          code: "submit",
        });
        this.msgSuccess("提交成功");
        this.open = false;
        setTimeout(() => {
          this.getList();
        }, 300);
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.starttime = this.DatetounixTimestamp(this.form.starttime);
          this.form.endtime = this.DatetounixTimestamp(this.form.endtime);
          this.form.otherfile = this.form.otherfile || [];
          this.form.otherfile = this.form.otherfile.join(",");
          this.otherfile_fileList = [];
          if (this.form.id != null) {
            editActrecord(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          } else {
            addActrecord(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;

      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delActrecord(ids);
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
  }, //methods结束
};
</script>
 

<style scoped>
.dialog-footer {
  text-align: left;
}
</style>