import request from '@/utils/request'

export function listBdDatamodelColumn(query) {
  return request({
    url: '/bigdata/bddatamodelcolumn/list',
    method: 'get',
    params: query
  })
}

export function getBdDatamodelColumn(id) {
  return request({
    url: '/bigdata/bddatamodelcolumn/get?id=' + id,
    method: 'get'
  })
}

export function addBdDatamodelColumn(data) {
  return request({
    url: '/bigdata/bddatamodelcolumn/add',
    method: 'post',
    data: data
  })
}

export function editBdDatamodelColumn(data) {
  return request({
    url: '/bigdata/bddatamodelcolumn/edit',
    method: 'post',
    data: data
  })
}

export function delBdDatamodelColumn(ids) {
  return request({
    url: '/bigdata/bddatamodelcolumn/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

