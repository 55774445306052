import { render, staticRenderFns } from "./contentPosition-editor.vue?vue&type=template&id=0cc01386&scoped=true&"
import script from "./contentPosition-editor.vue?vue&type=script&lang=js&"
export * from "./contentPosition-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc01386",
  null
  
)

export default component.exports