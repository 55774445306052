<template>
  <div style="position: relative; width: 300px; height: 200px; background-image: url(/static/bi/dashbord/bg/1.png); background-size: 100% 100%; display: flex; flex-direction: column; border: solid 1px rgba(76, 155, 253, 1); border-radius: 15px; overflow: hidden;">
    <template v-if="loading">
      <div style="width: 100%; height: 100%; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center; color: #fff;">
        <i class='el-icon-loading' style="font-size: 20px; margin-bottom: 5px;"></i>
        <div>正在加载</div>
      </div>
    </template>
    <template v-else>
      <div style="display: flex; justify-content: space-between; width: 100%; padding: 25px 10px 5px 10px; font-size: 15px; color: rgb(141,232,243);">
        <span>{{name}}</span>
        <span>no.{{no}}</span>
        <span>就业{{num}}人</span>
        <span>占比{{percent}}%</span>
      </div>
      <div ref="container" style="flex: 1; display: flex; flex-direction: column; align-items: center;">
        <vue-seamless-scroll ref="seamlessScroll" :data="tableData" class="warp" :class-option="classOption">
          <table ref="table" style="width: 100%; border-collapse: collapse;" cellspacing="0" cellpadding="0">
            <tbody>
              <tr v-for="(item, rowIndex) in tableData" :key="rowIndex">
                <td>{{item.dw1}}</td>
                <td nowrap="nowrap">{{item.jyrs}}人</td>
              </tr>
            </tbody>
          </table>
        </vue-seamless-scroll>
      </div>
    </template>
  </div>
</template>

<script>
import { execModel } from "@/api/core/data/datasource/model";
import Qs from 'qs'
import vueSeamlessScroll from '@/components/SeamlessScroll'

export default {
  name: 'shidajiuye',
  components: {
    vueSeamlessScroll
  },
  props: {
    params: {
      type: String,
      default: () => { return undefined }
    },
  },
  data() {
    return {
      classOption: {
        limitMoveNum: 5,
        step: 0.5,
        //singleHeight: 30,
        //waitTime: 1000
        autoPlay: false
      },
      loading: true,
      name: '',
      no: 0,
      num: 0,
      percent: 0,
      tableData: []
    }
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize()
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  created() {
    this.loadData()
  },
  methods: {
    onWindowResize() {
      //this.height = this.$refs.container.offsetHeight
    },
    loadData() {
      this.loading = true
      if (this.loadDataTimerId) {
        clearTimeout(this.loadDataTimerId)
      }
      this.loadDataTimerId = setTimeout(() => {
        this.loadDataTimerId = undefined
        this.no = 0
        this.num = 0
        this.percent = 0
        let id = 0
        const params = Qs.parse(this.params || '')
        let sf = ''
        let cs = ''
        if (params._map_stack_) {
          const sps = params._map_stack_.split('/')
          if (sps.length >= 3) {
            sf = sps[2]
          }
        }
        if (!sf) {
          sf = params.name
        } else {
          cs = params.name
        }
        if (cs.lastIndexOf('市') == (cs.length - 1)) {
          cs = cs.substring(0, cs.length - 1)
        } else if (cs.lastIndexOf('地区') == (cs.length - 2)) {
          cs = cs.substring(0, cs.length - 2)
        }
        // 要统计排行、占比，要返回所有省份城市数据
        //params['sf'] = sf
        //params['cs'] = cs
        if (!cs) {
          id = 310
        } else {
          params['sf'] = sf
          id = 301
        }
        this.name = params.name
        params['__max_count__'] = 1000
        execModel(id, JSON.stringify(params)).then(response => {
          if (this.name != params.name) {
            return
          }
          //console.log(response.data)
          response.data.sort((a, b) => { return b.jyrs - a.jyrs })
          if (!cs) {
            let total = 0
            for (let i = 0; i < response.data.length; i++) {
              const d = response.data[i]
              if (d.sf.indexOf(sf) >= 0) {
                this.no = i + 1
                this.num = d.jyrs
              }
              total += d.jyrs
            }
            if (total > 0) {
              this.percent = (this.num * 100 / total).toFixed(1)
            }
          } else {
            let total = 0
            for (let i = 0; i < response.data.length; i++) {
              const d = response.data[i]
              if (d.sf.indexOf(sf) >= 0 && (d.cs.indexOf(cs) >= 0 || cs.indexOf(d.cs) >= 0)) {
                this.no = i + 1
                this.num = d.jyrs
              }
              total += d.jyrs
            }
            if (total > 0) {
              this.percent = (this.num * 100 / total).toFixed(1)
            }
          }
          id = 307
          params['sf'] = sf
          params['cs'] = cs
          execModel(id, JSON.stringify(params)).then(response => {
            if (this.name != params.name) {
              return
            }
            this.tableData = response.data
            this.classOption = {
              ...this.classOption,
              autoPlay: false
            }
            setTimeout(() => {
              this.classOption = {
                ...this.classOption,
                autoPlay: true
              }
            }, 2000)
            this.loading = false
          }).catch(e => {
            this.loading = false
          })
          //this.loading = false
        }).catch(e => {
          this.loading = false
        })
      }, 500)
    }
  },
  watch: {
    params(val) {
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  padding: 5px 15px;
  table {
    padding: 0;
    color: #fff;
    font-size: 15px;
    tr {
      height: 30px;
      line-height: 30px;
    }
  }
}
.over-item:hover {
  cursor: pointer;
}
</style>