<template>
    <div class="app-container">
        <el-row :gutter="20">
            <el-col :span="12" v-for="item in data" :key="item.id">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span v-text="item.name"></span>
                    </div>
                    <div>
                        <jf-table
                
                            :height="panelContentHeight"
                            :pager="false"
                            :operate="false"
                            :checkbox="false"
                            ref="previewGrid"
                            :columns="item.previewColumn"
                            :data="item.previewList"
                        >
                        </jf-table>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {
    getModel,
    statistics,
    execModel,
} from "@/api/core/data/datasource/model";
export default {
    name: "statistics-index",
    data() {
        return {
            form: {
                list1: [],
            },

            panelContentHeight: 300,
            data: [
                { id: 278, previewColumn: [], previewList: [] },
                { id: 279, previewColumn: [], previewList: [] },
                { id: 281, previewColumn: [], previewList: [] },
            ],
            previewColumn: [],
        };
    },
    created() {
        this.getInit();
    },
    methods: {
        getInit() {
            this.getStatistics();
            this.data.forEach((item) => {
                this.getList(item);
            });
        },
        getStatistics() {
            statistics().then((response) => {
                if (response.code == 0) {
                    this.data.push({
                        name: "搜索词",
                        previewColumn: [
                            { field: "w", title: "搜索词", minWidth: 120 },
                            {
                                field: "count",
                                title: "搜索次数",
                                minWidth: 120,
                            },
                            
                        ],
                        previewList:response.data
                    });
                    console.log(response);
                }
            });
        },
        getList(data) {
            getModel(data.id)
                .then((response) => {
                    if (response.code == 0) {
                        for (let k in response.data) {
                            data[k] = response.data[k];
                        }

                        // this.$emit("refresh-model", this.data);

                        for (let i = 0; i < data.params.length; i++) {
                            data.params[i].index = i + 1;
                        }
                        const previewColumn = [];
                        for (let i = 0; i < data.metas.length; i++) {
                            const m = data.metas[i];
                            m.index = i + 1;
                            previewColumn.push({
                                field: m.name,
                                title: m.label || m.name,
                                minWidth: 120,
                            });
                        }

                        data.previewColumn = previewColumn;
                        const maxCount = 1000;
                        execModel(
                            data.id,
                            JSON.stringify({ __max_count__: maxCount })
                        )
                            .then((response) => {
                                // loading.close();
                                if (response.code == 0) {
                                    if (
                                        previewColumn.length == 0 &&
                                        response.data.length > 0
                                    ) {
                                        const previewColumn = [];
                                        const d = response.data[0];
                                        for (let k in d) {
                                            console.log(k,'k')
                                            previewColumn.push({
                                                field: k,
                                                title: k,
                                                minWidth: 120,
                                            });
                                        }
                                        data.previewColumn = previewColumn;
                                    }
                                    data.previewList = response.data;
                                    console.log(this.data);
                                }
                            })
                            .catch((err) => {
                                // loading.close();
                            });
                    }
                })
                .catch((err) => {
                    // loading.close();
                });
        },
    },
};
</script>
<style lang="sass" scoped>
</style>
