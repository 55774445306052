<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">      
      <el-form-item label="资源空间" prop="space_id">
        <el-select v-model="queryParams.space_id" placeholder="资源空间" clearable size="small">
          <el-option
            v-for="dict in spaceList"
            :key="dict.Id"
            :label="dict.space_name"
            :value="dict.Id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row/>

    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="productList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >创建产品</el-button>
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </template>
      <!--状态栏-->
      <template #status="{row}">
        <el-switch
                v-model="row.product_status"
                active-value="1"
                inactive-value="0"
                @change="handleStatusChange(row)"
        ></el-switch>
      </template>
      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <router-link size="mini" :to="'/iot/product/info/'+row.id" class="link-type" style=" display:inline-block;margin-right:10px">
          <span style="font-size:12px" >查看</span>
        </router-link>
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
        >修改
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>

      <template #data_list="{ row }">
        <router-link :to="'/iot/product/info/'+row.id" class="link-type">
          <span>{{ row.name }}</span>
        </router-link>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="700px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">        
        <el-form-item label="所属资源空间" prop="space_id" :required=true>
          <el-select v-model="form.space_id" placeholder="资源空间" clearable size="small">
            <el-option
              v-for="dict in spaceList"
              :key="dict.Id"
              :label="dict.space_name"
              :value="dict.Id"
            />
        </el-select>
      </el-form-item>
        <el-form-item label="产品名称" prop="name" :required=true>
          <el-input v-model="form.name" placeholder="产品名称" />
        </el-form-item>
        <el-form-item label="协议类型" prop="protocol_id" :required=true>
          <el-select v-model="form.protocol_id" placeholder="协议类型" clearable size="small">
            <el-option
              v-for="(item,key) in statusOptions"
              :key="key"
              :label="item.value"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="数据格式" prop="data_type" :required=true>
          <el-select v-model="form.data_type" placeholder="数据格式" clearable size="small">
            <el-option
              v-for="(item,key) in dataTypeList"
              :key="key"
              :label="item.value"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="厂商名称" prop="firms_name" :required=true>
          <el-input v-model="form.firms_name" placeholder="产品名称" />
        </el-form-item>
        <el-form-item label="所属行业" prop="firms_type" :required=true>
          <el-select v-model="form.firms_type" placeholder="数据格式" clearable size="small">
            <el-option
              v-for="dict in categoryList"
              :key="dict.id"
              :label="dict.field_name"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" prop="device_type" :required=true>
          <el-input v-model="form.device_type" placeholder="设备类型" />
        </el-form-item>
        <div class="cell" style="margin-left: 5px;margin-top: 10px">以下为产品自定义扩展（可不填，系统会自动生成产品ID）</div>
        <el-divider/>
        <el-form-item label="产品ID" prop="product_code" >
          <el-input v-model="form.product_code" placeholder="产品ID" />
        </el-form-item>
        <el-form-item label="产品描述" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入产品描述信息" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listIotProduct, getIotProduct, addIotProduct, updateIotProduct, delIotProduct } from "@/api/iot/product";
import { listIotCase} from "@/api/iot/case";
export default {
  name: "IotProduct",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: '', title: '产品名称' ,slots: {default: 'data_list'}},
        {field: 'product_code', title: '产品ID' },
        {field: 'space_id', title: '资源空间' ,formatter:this.spaceFormat},
        {field: 'device_type', title: '设备类型' },
        {field: 'protocol_id', title: '协议类型' ,formatter:this.protocolFormat},
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 分类表格数据
      productList: [],
      spaceList:[],
      categoryList:[],
      protocolList:[],
      dataTypeList:[],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        node_id: undefined,
        case_id: undefined,
        space_id: undefined
      },
      space_id:0,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
        space_id: [
          { required: true, message: "资源空间不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.queryParams.space_id = this.$route.query && this.$route.query.space_id;
    this.getParams();    
  },
  methods: {
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length
    },
    getParams(){
      var casequeryParams={
        pageNum: 1,
        pageSize: 10,
        node_id: undefined      
      };
      listIotCase(casequeryParams).then(response =>{
          for (var index=0;index<response.data.list.length;index++){
            if (response.data.list[index].is_choose >= 1){
              this.queryParams.case_id=response.data.list[index].Id;
              
            }              
          }   
          this.getList(); 
        })
    },
    /** 查询分类列表 */
    getList() {
      this.loading = true;         
      listIotProduct(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;           
        this.total = response.data.total;
        this.productList = response.data.list; 
        this.spaceList = response.data.space_list; 
        this.categoryList = response.data.category_list; 
        this.protocolList = response.data.protocol_list; 
        this.dataTypeList = response.data.dataType_list.values;  
        this.statusOptions = response.data.statusOptions.values;        
        this.loading = false;
        }).catch(function (error){
          console.log(error);
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        name: undefined,
        product_code: undefined,        
        product_status: "1",
        remark: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "创建产品";
    },
    spaceFormat({row}){
      this.labname="";
      this.spaceList.forEach(element => {
        if (row.space_id == element.Id)
          this.labname= element.space_name;
      });
      return this.labname;
    },
    protocolFormat({row}){
      this.labname="";
      this.statusOptions.forEach(element => {
        if (row.protocol_id == element.key)
          this.labname= element.value;        
      });
      return this.labname;
    },
    handleStatusChange(row) {
      let text = row.product_status === "1" ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.name + '"吗?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          updateIotProduct(row).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
              } else {
                this.msgError(response.msg);
              }
            }).catch(function (error){
              console.log(error);
            });

        }).then(() => {
          this.msgSuccess(text + "成功");
        }).catch(function() {
          row.product_status = row.product_status === "0" ? "1" : "0";
        });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {

      this.reset();
      const id = row.id || this.ids
      getIotProduct(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改产品";
      });
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {            
            updateIotProduct(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addIotProduct(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除产品编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotProduct(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    }
  }
};
</script>
