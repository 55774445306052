<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="标题" prop="title">
                <el-input
                    v-model="queryParams.title"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="pageList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>

            <template #image="{ row }">
                <img v-if="row.image" :src="row.image" class="image" />
            </template> -->

            <!--默认操作按钮-->
            <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

            <!--自定义空数据模板-->
            <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="pageList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>
            <template slot="image" slot-scope="{ row }">
                <img v-if="row.image" :src="row.image" class="image" />
            </template>
            <template slot="status" slot-scope="{ row }">
                {{ statusFormat({ row }) }}
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>
        <!-- 添加或修改单页对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-form-item label="类型" prop="type">
                    <el-select v-model="form.type" placeholder="请选择">
                        <el-option
                            key="page"
                            label="page"
                            value="page"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title" placeholder="标题" />
                </el-form-item>
                <el-form-item label="关键字" prop="keywords">
                    <el-input v-model="form.keywords" placeholder="关键字" />
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input
                        v-model="form.description"
                        type="textarea"
                        placeholder="请输入描述"
                    />
                </el-form-item>
                <el-form-item label="标志">
                    <el-select
                        v-model="form.flags"
                        multiple
                        placeholder="请选择"
                    >
                        <el-option
                            key="hot"
                            label="热门"
                            value="hot"
                        ></el-option>
                        <el-option
                            key="index"
                            label="首页"
                            value="index"
                        ></el-option>
                        <el-option
                            key="recommend"
                            label="推荐"
                            value="recommend"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="图片" prop="image">
                    <el-upload
                        class="upload-demo"
                        accept="image/png, image/jpeg"
                        action="/file/upload"
                        :headers="uploadHeaders"
                        :data="uploadData"
                        :multiple="false"
                        :limit="1"
                        :file-list="imageList"
                        :on-success="handleUploadSuccess"
                        :on-error="handleUploadError"
                        :on-exceed="handleUploadExceed"
                        :on-remove="handleUploadRemove"
                        list-type="picture-card"
                    >
                        <el-button size="small" type="primary"
                            >点击上传</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                            只能上传jpg/png文件，且不超过500kb
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <vue-ueditor-wrap
                        v-model="form.content"
                        :config="ueConfig"
                    ></vue-ueditor-wrap>
                </el-form-item>
                <el-form-item label="自定义名称" prop="diyname">
                    <el-input v-model="form.diyname" placeholder="自定义名称" />
                </el-form-item>
                <el-form-item label="视图模板" prop="showtpl">
                    <el-input v-model="form.showtpl" placeholder="视图模板" />
                </el-form-item>
                <el-form-item label="权重" prop="weigh">
                    <el-input-number
                        v-model="form.weigh"
                        controls-position="right"
                        :min="0"
                    />
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio key="normal" label="normal">正常</el-radio>
                        <el-radio key="hidden" label="hidden">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listCmsPage,
    getCmsPage,
    addCmsPage,
    updateCmsPage,
    delCmsPage,
} from "@/api/cms/page";
import { getAccessToken } from "@/utils/tokenTool";

export default {
    name: "cms-page-list",
    data() {
        return {
            // 编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // {type: 'checkbox', width: 60, fixed: "left"},
                { field: "id", title: "编号", width: 100, fixed: "left" },
                { field: "title", title: "标题" },
                { field: "flag", title: "标志" },
                { field: "image", title: "图片", slots: { default: "image" } },
                { field: "icon", title: "图标" },
                { field: "views", title: "点击" },
                { field: "comments", title: "评论" },
                {
                    field: "status",
                    title: "状态",
                    // formatter: this.statusFormat,
                    slots: {
                        default: "status",
                    },
                    form_type: 2,
                    options: [
                        { label: "正常", value: "normal" },
                        { label: "隐藏", value:"hidden" },
                    ],
                    options_label: "label",
                    options_value: "value",
                },
                // {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 单页表格数据
            pageList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 状态数据字典
            statusOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
            },
            // 表单参数
            form: {},
            uploadHeaders: {},
            uploadData: {},
            imageList: [],
            // 表单校验
            rules: {
                type: [
                    {
                        required: true,
                        message: "类型不能为空",
                        trigger: "blur",
                    },
                ],
                title: [
                    {
                        required: true,
                        message: "标题不能为空",
                        trigger: "blur",
                    },
                ],
                diyname: [
                    {
                        required: true,
                        message: "自定义名称不能为空",
                        trigger: "blur",
                    },
                ],
                showtpl: [
                    {
                        required: true,
                        message: "视图模板不能为空",
                        trigger: "blur",
                    },
                ],
                weigh: [
                    {
                        required: true,
                        message: "权重不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        //列表的筛选和排序
        tableChange(e){},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询单页列表 */
        getList() {
            this.loading = true;
            listCmsPage(this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.pageList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        statusFormat({ row }) {
            if (row.status == "normal") {
                return "正常";
            } else {
                return "隐藏";
            }
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.uploadHeaders = {
                Authorization: getAccessToken(),
            };
            this.uploadData = {
                category: "cms",
                public: "1",
            };
            this.imageList = [];
            this.form = {
                id: undefined,
                flags: [],
                title: undefined,
                weigh: 0,
                status: "normal",
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加单页";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            //this.reset();
            const id = row.id || this.ids;
            this.$nextTick(() => {
                getCmsPage(id).then((response) => {
                    //this.form = response.data;
                    let data = response.data;
                    if (data) {
                        const flags = data.flag ? data.flag.split(",") : [];
                        this.form = {
                            id: data.id,
                            type: data.type,
                            title: data.title,
                            weigh: data.weigh,
                            keywords: data.keywords,
                            description: data.description,
                            flag: data.flag,
                            flags: flags,
                            image: data.image,
                            content: data.content,
                            diyname: data.diyname,
                            showtpl: data.showtpl,
                            status: data.status,
                        };
                    }
                    if (data.image) {
                        this.imageList = [
                            {
                                url: data.image,
                            },
                        ];
                    }
                    this.open = true;
                    this.title = "修改单页";
                });
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            if (this.form.flags.length > 0) {
                this.form.flag = this.form.flags.join(",");
            }
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != undefined) {
                        updateCmsPage(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addCmsPage(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(
                '是否确认删除单页编号为"' + ids + '"的数据项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(function () {
                    return delCmsPage(ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有单页数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportPage(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
        handleUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList);
            file.url = response.data.url;
            this.form.image = response.data.url;
        },
        handleUploadError(err) {
            console.log(err);
            this.form.image = "";
            if (
                err &&
                (err.message.indexOf("larger than") > 0 ||
                    err.message.indexOf("413") > 0)
            ) {
                this.msgError("上传文件失败，文件大小超过限制");
            } else {
                this.msgError("上传文件失败");
            }
        },
        handleUploadExceed() {
            this.msgError("只能上传一个文件，请删除后重新上传");
        },
        handleUploadRemove() {
            this.form.image = "";
        },
    },
};
</script>

<style scoped>
.image {
    height: 35px;
    max-width: 200px;
    margin-top: 4px;
}
</style>
