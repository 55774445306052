<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        >
            <el-form-item label="年份" prop="year">
                <el-select v-model="queryParams.year" placeholder="请选择年份">
                    <el-option
                        v-for="item in years"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >统计</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            row-id="dept_id"
            border
            show-overflow
            highlight-hover-row
            :max-height="maxHeight"
            :loading="loading"
            :toolbar-config="tableToolbar"
            :export-config="exportConfig"
            :print-config="printConfig"
            :columns="tableColumn"
            :data="dataList"
        >
            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid> -->
        <jf-table
            row-id="dept_id"
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :checkbox="false"
            :operate="false"
            :filter-btn="false"
            :columns-btn="false"
            :sort-btn="false"
            :export-config="exportConfig"
            :print-config="printConfig"
            :query-params="queryParams"
            @handleQuery="handleQuery"
          
        >
            <template slot="query_qarams_item">
                <el-form-item label="年份" prop="year">
                    <el-select
                        v-model="queryParams.year"
                        placeholder="请选择年份"
                    >
                        <el-option
                            v-for="item in years"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </template>
        </jf-table>
    </div>
</template>
<script>
import { execModel } from "@/api/core/data/datasource/model";
import moment from "moment";
export default {
    name: "xssq-xssqstat-list",
    data() {
        const year = new Date().getFullYear();
        const years = [];
        for (let i = year; i >= year - 4; i--) {
            years.push({
                value: i,
                label: i + "年",
            });
        }
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                print: true,
                export: true,
                refresh: {
                    query: this.handleQuery,
                },
            },
            exportConfig: {
                modes: ["current"],
            },
            printConfig: {
                modes: ["current"],
            },
            tableColumn: [
                {
                    field: "dept_name",
                    title: "学院",
                    minWidth: 150,
                    fixed: "left",
                    sortable: true,
                },
                { field: "1", title: "1月", sortable: true },
                { field: "2", title: "2月", sortable: true },
                { field: "3", title: "3月", sortable: true },
                { field: "4", title: "4月", sortable: true },
                { field: "5", title: "5月", sortable: true },
                { field: "6", title: "6月", sortable: true },
                { field: "7", title: "7月", sortable: true },
                { field: "8", title: "8月", sortable: true },
                { field: "9", title: "9月", sortable: true },
                { field: "10", title: "10月", sortable: true },
                { field: "11", title: "11月", sortable: true },
                { field: "12", title: "12月", sortable: true },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            queryParams: {
                year: year,
            },
            years: years,
            maxHeight: undefined,
        };
    },
    created() {
        this.getList();
    },
    mounted() {
        window.addEventListener("resize", this.onWindowResize);
        this.onWindowResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.onWindowResize);
    },
    methods: {
      
        onWindowResize() {
            this.maxHeight =
                document.documentElement.clientHeight - 84 - 58 - 40;
        },
        getList() {
            this.loading = true;
            execModel(157, JSON.stringify(this.queryParams)).then(
                (response) => {
                    const dataList = [];
                    const m = {};
                    for (let i = 0; i < response.data.length; i++) {
                        const d = response.data[i];
                        let dept = m[d.dept_id];
                        if (!dept) {
                            dept = {
                                dept_id: d.dept_id,
                                dept_name: d.dept_name,
                            };
                            for (let j = 1; j <= 12; j++) {
                                dept[j + ""] = ""; // 默认值
                            }
                            m[d.dept_id] = dept;
                            dataList.push(dept);
                        }
                        dept[d.month + ""] = d.count;
                    }
                    this.dataList = dataList;
                    this.loading = false;
                }
            );
        },

        handleQuery() {
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
    },
};
</script>
