<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="用户名" prop="username">
                <el-input
                    v-model="queryParams.username"
                    placeholder="请输入用户名"
                    clearable
                    style="width: 130px"
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>

            <el-form-item label="订单号" prop="user_id">
                <el-input
                    v-model="queryParams.ordersn"
                    placeholder="请输入订单号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="支付类型" prop="pay_type">
                <el-select
                    v-model="queryParams.pay_type"
                    placeholder="支付类型"
                    clearable
                    size="small"
                    style="width: 120px"
                >
                    <el-option label="全部" value="" />
                    <el-option label="系统" value="system" />
                    <el-option label="微信支付" value="wechat" />
                    <el-option label="支付宝" value="alipay" />
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select
                    v-model="queryParams.status"
                    placeholder="状态"
                    clearable
                    size="small"
                    style="width: 120px"
                >
                    <el-option label="全部" value="" />
                    <el-option label="待支付" value="0" />
                    <el-option label="已支付" value="1" />
                    <el-option label="已过期" value="2" />
                </el-select>
            </el-form-item>

            <el-form-item label="创建时间">
                <el-date-picker
                    v-model="dateRange"
                    size="small"
                    style="width: 240px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="list"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons> </template> -->
        <!--支付类型-->
        <!-- <template #paytypetpl="{ row }">
                <el-tag type="info" v-if="row.pay_type == 'system'"
                    >系统</el-tag
                >
                <el-tag type="primary" v-else-if="row.pay_type == 'alipay'"
                    >支付宝</el-tag
                >
                <el-tag type="success" v-else-if="row.pay_type == 'wechat'"
                    >微信支付</el-tag
                >
            </template> -->
        <!-- <template #statustpl="{ row }">
                <el-tag type="warning" v-if="row.status == 0">待支付</el-tag>
                <el-tag type="success" v-else-if="row.status == 1"
                    >已支付</el-tag
                >
                <el-tag type="danger" v-else-if="row.status == 2"
                    >已过期</el-tag
                >
            </template> -->
        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="list"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            :checkbox="false"
            :operate="false"
        >
            <template slot="paytypetpl" slot-scope="{ row }">
                <el-tag type="info" v-if="row.pay_type == 'system'"
                    >系统</el-tag
                >
                <el-tag type="primary" v-else-if="row.pay_type == 'alipay'"
                    >支付宝</el-tag
                >
                <el-tag type="success" v-else-if="row.pay_type == 'wechat'"
                    >微信支付</el-tag
                >
            </template>
            <template slot="statustpl" slot-scope="{ row }">
                <el-tag type="warning" v-if="row.status == 0">待支付</el-tag>
                <el-tag type="success" v-else-if="row.status == 1"
                    >已支付</el-tag
                >
                <el-tag type="danger" v-else-if="row.status == 2"
                    >已过期</el-tag
                >
            </template>
        </jf-table>
    </div>
</template>

<script>
import { list } from "@/api/ucenter/recharge";

export default {
    name: "UserRecharge",
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "编号", fixed: "left", visible: false  },
                { field: "ordersn", title: "订单号" },
                { field: "username", title: "用户名" },
                { field: "amount", title: "订单金额" },
                { field: "pay_amount", title: "实付金额" },
                { field: "ip", title: "IP" },
                {
                    field: "pay_type",
                    title: "支付类型",
                    slots: { default: "paytypetpl" },
                    options: [
                        { label: "系统", value: "system" },
                        { label: "支付宝", value: "alipay" },
                        { label: "微信", value: "wechat" },
                    ],
                    options_value:'value',
                    options_label:'label',
                    form_type:2,
                },
                {
                    field: "status",
                    title: "状态",
                    width: 100,
                    slots: { default: "statustpl" },
                    options: [
                        { label: "待支付", value: "0" },
                        { label: "已支付", value: "1" },
                        { label: "已过期", value: "2" },
                    ],
                    options_value:'value',
                    options_label:'label',
                    form_type:2,
                },
                { field: "remark", title: "备注", width: 100 },
                {
                    field: "create_time",
                    title: "创建时间",
                    width: 180,
                    formatter: "formatDate",
                },
                {
                    field: "pay_time",
                    title: "支付时间",
                    width: 180,
                    formatter: "formatDate",
                },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 表格数据
            list: [],
            // 日期范围
            dateRange: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                user_id: undefined,
                ordersn: undefined,
                status: undefined,
                pay_type: undefined,
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
      getUserPool() {
        const sps = this.$route.path.split('/')
        return sps[2]
      },
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },
        /** 查询登录日志列表 */
        getList() {
            this.loading = true;
            list(this.getUserPool(), this.addDateRange(this.queryParams, this.dateRange)).then(
                (response) => {
                    this.single = true;
                    this.multiple = true;
                    this.list = response.data.list;
                    this.total = response.data.total;
                    this.loading = false;
                }
            );
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.multiple = !selection.length;
        },
    },
};
</script>

