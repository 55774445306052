import request from '@/utils/request'

//添加用户操作
export function addIotWarn(data) {
  return request({
    url: '/iot/warn/add',
    method: 'post',
    data: data
  })
}

// 获取用户操作
export function getIotWarn(id) {
    return request({
      url: '/iot/warn/get?id=' + id,
      method: 'get'
    })
}

// 更新用户操作
export function updateIotWarn(data) {
  return request({
    url: '/iot/warn/update',
    method: 'post',
    data: data
  })
}
  
// 删除用户操作
export function delIotWarn(id) {
    return request({
      url: '/iot/warn/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 用户操作列表
export function listIotWarn(query) {
  return request({
    url: '/iot/warn/list',
    method: 'get',
    params: query
  })
}

//添加用户操作
export function addIotWarnDealwith(data) {
  return request({
    url: '/iot/warn/dealwith/add',
    method: 'post',
    data: data
  })
}

// 获取用户操作
export function getIotWarnDealwith(id) {
    return request({
      url: '/iot/warn/dealwith/get?id=' + id,
      method: 'get'
    })
}

// 更新用户操作
export function updateIotWarnDealwith(data) {
  return request({
    url: '/iot/warn/dealwith/update',
    method: 'post',
    data: data
  })
}
  
// 删除用户操作
export function delIotWarnDealwith(id) {
    return request({
      url: '/iot/warn/dealwith/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 用户操作列表
export function listIotWarnDealwith(query) {
  return request({
    url: '/iot/warn/dealwith/list',
    method: 'get',
    params: query
  })
}

// 根据warnid获取用户操作
export function getIotWarnDealwithByWarnId(query) { 
  return request({
    url: '/iot/warn/dealwith/getbywarnid',
    method: 'get',
    params: query
  })
}