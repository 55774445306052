<template>
  <el-form ref="basicInfoForm" :model="info" :rules="rules" label-width="150px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="表名称" prop="table_name">
          <el-input :disabled="true" placeholder="请输入仓库名称" v-model="info.table_name" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="表描述" prop="table_comment">
          <el-input placeholder="请输入" v-model="info.table_comment" />
        </el-form-item>
      </el-col>

      <!--<el-col :span="12">-->
        <!--<el-form-item label="实体类名称" prop="class_name">-->
          <!--<el-input placeholder="请输入" v-model="info.class_name" />-->
        <!--</el-form-item>-->
      <!--</el-col>-->
      <el-col :span="12">
        <el-form-item label="作者" prop="function_author">
          <el-input placeholder="请输入" v-model="info.function_author" />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" :rows="3" v-model="info.remark"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
export default {
  name: "BasicInfoForm",
  props: {
    info: {
      type: Object,
      default: null
    },

  },
  data() {
    return {
      rules: {
        table_name: [
          { required: true, message: "请输入表名称", trigger: "blur" }
        ],
        table_comment: [
          { required: true, message: "请输入表描述", trigger: "blur" }
        ],
        function_author: [
          { required: true, message: "请输入作者", trigger: "blur" }
        ]
      }
    };
  }
};
</script>
