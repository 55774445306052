import { render, staticRenderFns } from "./inactiveColor-editor.vue?vue&type=template&id=1e975977&scoped=true&"
import script from "./inactiveColor-editor.vue?vue&type=script&lang=js&"
export * from "./inactiveColor-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e975977",
  null
  
)

export default component.exports