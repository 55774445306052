<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="用户名" prop="username">
                <el-input
                    v-model="queryParams.username"
                    placeholder="请输入用户名"
                    clearable
                    size="small"
                    style="width: 150px"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="标题" prop="title">
                <el-input
                    v-model="queryParams.title"
                    placeholder="请输入用户ID"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="关联订单" prop="ordersn">
                <el-input
                    v-model="queryParams.ordersn"
                    placeholder="请输入关联订单号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="创建时间" prop="dateRange">
                <el-date-picker
                    v-model="dateRange"
                    size="small"
                    style="width: 240px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>
        <!-- 
    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="userMoneyLogList">
      <template #toolbar_buttons>

      </template> -->

        <!--默认操作按钮-->
        <!-- <template #defaultopr="{ row }">

      </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="userMoneyLogList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            :checkbox="false"
            :operate="false"
        >
        </jf-table>
    </div>
</template>

<script>
import {
    listUserMoneyLog,
    getUserMoneyLog
} from "@/api/ucenter/usermoneylog";
import moment from "moment";

export default {
    name: "ucenter-usermoneylog-list",
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                { field: "id", title: "编号", width: 100, fixed: "left", visible: false  },
                { field: "username", title: "用户名" },
                { field: "title", title: "标题" },
                { field: "ordersn", title: "关联订单号" },
                { field: "money", title: "金额" },
                { field: "before", title: "变更前余额" },
                { field: "after", title: "变更后余额" },
                { field: "create_by_username", title: "操作员" },
                { field: "memo", title: "备注" },
                {
                    field: "create_time",
                    title: "创建时间",
                    formatter: "formatDate",
                },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 用户余额流水表格数据
            userMoneyLogList: [],
            // 日期范围
            dateRange: [
                moment().format("yyyy-MM-DD"),
                moment().add(1, "days").format("yyyy-MM-DD"),
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                title: undefined,
                username: undefined,
                ordersn: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                name: [
                    {
                        required: true,
                        message: "用户余额流水名称不能为空",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "用户余额流水顺序不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
      getUserPool() {
        const sps = this.$route.path.split('/')
        return sps[2]
      },
        //列表的排序和删除
        tableChange(e){},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询用户余额流水列表 */
        getList() {
            this.loading = true;
            listUserMoneyLog(this.getUserPool(), this.addDateRange(this.queryParams, this.dateRange)).then((response) => {
                this.single = true;
                this.multiple = true;
                this.userMoneyLogList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                code: undefined,
                name: undefined,
                sort: 0,
                status: "1",
                remark: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.dateRange = [
                moment().format("yyyy-MM-DD"),
                moment().add(1, "days").format("yyyy-MM-DD"),
            ];
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加用户余额流水";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getUserMoneyLog(this.getUserPool(), id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    this.form = {
                        id: data.id,
                        name: data.name,
                        sort: data.sort,
                        remark: data.remark,
                    };
                }

                this.open = true;
                this.title = "修改用户余额流水";
            });
        },
    },
};
</script>
