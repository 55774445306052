<template>
  <div class="login-page">
    <set-theme></set-theme>
    <transition name="el-zoom-in-bottom">
      <purple-blue v-show="loginTheme == 'purpleBlue'"></purple-blue>
    </transition>
    <transition name="el-zoom-in-bottom">
      <young-blue v-show="loginTheme == 'youngBlue'"></young-blue>
    </transition>
    <transition name="el-zoom-in-bottom">
      <technical-blue v-show="loginTheme == 'technicalBlue'"></technical-blue>
    </transition>

    <footer-copyright
      :color="copyrightColor"
      :border="false"
    ></footer-copyright>
  </div>
</template>
 
<script>
import PurpleBlue from "./components/PurpleBlue.vue";
import YoungBlue from "./components/YoungBlue.vue";
import TechnicalBlue from "./components/TechnicalBlue.vue";
import SetTheme from "./components/SetTheme.vue";
import { mapGetters,mapActions } from "vuex";
export default {
  name: "Login",
  components: {
    PurpleBlue,
    SetTheme,
    YoungBlue,
    TechnicalBlue,
  },
  data() {
    return {};
  },
  beforeCreate() {
    this.$store.dispatch('application/loadConfig')
  },
  computed: {
    ...mapGetters({
      loginTheme: "settings/loginTheme",
    }),
    copyrightColor() {
      let { loginTheme } = this;
      return loginTheme == "youngBlue" ? "#ccc" : "#fff";
    },
  },
  created() {

    if(process.env.VUE_APP_LOGIN_THEME&&process.env.VUE_APP_LOGIN_THEME!=""){
      this.setLoginTheme(process.env.VUE_APP_LOGIN_THEME );

    }
  },
  methods: {
    ...mapActions({
      setLoginTheme: "settings/setLoginTheme",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
