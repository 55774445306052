import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2023-12-13 13:29:22
class Pdftool {
// 查询pdftool列表
 listPdftool(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/digital/pdftool/list',
        method: 'post',
        data: data
    })
}

// 查看pdftool
 getPdftool ( id) {
    return request({
        url: '/digital/pdftool/get?id='+id,
        method: 'get'
    })
}


// 新增pdftool
 addPdftool(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/digital/pdftool/add',
        method: 'post',
        data: postdata
    })
}

// 修改pdftool
 editPdftool(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid,
          dataid:data.id
      }

    return request({
        url: '/digital/pdftool/edit',
        method: 'post',
        data: postdata
    })
}

// 删除pdftool
 delPdftool(ids) {
    return request({
        url: '/digital/pdftool/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 pdftoolImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/digital/pdftool/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 pdftoolExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/digital/pdftool/export?` + parata.join("&") ;


 }

 //下导入模板
  pdftoolimportTemplate() {

     return   `/digital/pdftool/importTemplate`   ;

  }

  // 提取书签
//   /api/pdf/extractTextFromPDF
extractTextFromPDF(postdata) {
      return request({
          url: '/pdf/api/pdf/extractTextFromPDF',
          method: 'post',
          data: postdata
      })
  }

// 写入书签
writeBookmarkByFile(postdata) {
    return request({
        url: '/pdf/api/pdf/edittextfrompdf',
        method: 'post',
        data: postdata
    })
}

// // 写入书签
// writeBookmarkByFile(postdata) {
//     return request({
//         url: '/pdf/api/pdf/transformBookmark',
//         method: 'post',
//         data: postdata
//     })
// }

}

const instance = new Pdftool();
export default instance;