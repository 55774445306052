import { render, staticRenderFns } from "./onCellClick-editor.vue?vue&type=template&id=0cbd6566&scoped=true&"
import script from "./onCellClick-editor.vue?vue&type=script&lang=js&"
export * from "./onCellClick-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cbd6566",
  null
  
)

export default component.exports