<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        >
            <el-form-item label="id" prop="id">
                <el-input
                    v-model="queryParams.id"
                    placeholder="请输入id"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxstudentmanual/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxstudentmanual/get',
                        '/gbpx/gbpxstudentmanual/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxstudentmanual/del']"
                    >删除</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxstudentmanual/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxstudentmanual/get',
                        '/gbpx/gbpxstudentmanual/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxstudentmanual/del']"
                    >删除</el-button
                >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="960px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入名称"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="简介" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入简介"
                                clearable
                                type="textarea"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-tabs>
                <el-tab-pane label="章节">
                    <el-button
                        style="margin-bottom: 10px"
                        type="primary"
                        icon="el-icon-plus"
                        size="mini"
                        @click="handleAddChapter"
                        >添加</el-button
                    >
                    <span style="margin-left: 10px"
                        >章节数:{{ form.chapters.length }}</span
                    >
                    <!-- <vxe-grid
                        ref="chapterGrid"
                        resizable
                        stripe
                        class="sortable-row-demo"
                        :columns="chapterTableColumn"
                        :data="form.chapters"
                        :max-height="350"
                    >
                        <template #defaultopr="{ row, rowIndex }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click.stop="handleEditChapter(row, rowIndex)"
                                >修改
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeleteChapter(row, rowIndex)"
                                >删除
                            </el-button>
                        </template>

                        <template #empty>
                            <span>
                                <p>暂无数据</p>
                            </span>
                        </template>
                    </vxe-grid> -->
                    <jf-table
                        ref="chapterGrid"
                        class="sortable-row-demo"
                        :columns="chapterTableColumn"
                        :data="form.chapters"
                        :max-height="350"
                        :checkbox="false"
                        :pager="false"
                        :is-query="false"
                    >
                        <template
                            slot="toolbar_btn_row"
                            slot-scope="{ row, rowIndex }"
                        >
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click.stop="handleEditChapter(row, rowIndex)"
                                >修改
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeleteChapter(row, rowIndex)"
                                >删除
                            </el-button>
                        </template>
                    </jf-table>
                </el-tab-pane>
            </el-tabs>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :close-on-click-modal="false"
            :title="titleChapter"
            :visible.sync="openChapter"
            width="800px"
            append-to-body
        >
            <el-form
                ref="formChapter"
                :model="formChapter"
                :rules="rulesChapter"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="标题" prop="title">
                            <el-input
                                v-model="formChapter.title"
                                placeholder="请输入标题"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="内容" prop="content">
                            <el-input
                                v-model="formChapter.content"
                                type="textarea"
                                :rows="10"
                                placeholder="请输入内容"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="排序" prop="sort">
                            <el-input-number
                                v-model="formChapter.sort"
                                placeholder="请输入排序"
                                controls-position="right"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitChapter"
                    >确 定</el-button
                >
                <el-button @click="openChapter = false">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listGbpxStudentManual,
    getGbpxStudentManual,
    addGbpxStudentManual,
    editGbpxStudentManual,
    delGbpxStudentManual,
} from "@/api/gbpx/gbpx_student_manual";
import Sortable from "sortablejs";
export default {
    name: "gbpx-gbpxstudentmanual-list",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                {
                    field: "id",
                    title: "id",
                    minWidth: 120,
                    fixed: "left",
                    is_query: true,
                },
                { field: "name", title: "名称", minWidth: 120, is_query: true },
                { field: "remark", title: "简介", minWidth: 120 },
                //{ field: "content", title: "内容", minWidth: 120 },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                //   field: "",
                //   title: "操作",
                //   width: 180,
                //   fixed: "right",
                //   align: "center",
                //   slots: { default: "defaultopr" },
                // },
            ],
            chapterTableColumn: [
                { field: "title", title: "标题", minWidth: 120 },
                {
                    field: "content",
                    title: "内容",
                    minWidth: 120,
                    showOverflow: "ellipsis",
                },
                /*{field: 'sort', title: '排序', width: 60, align: "center", slots: {
                    default: () => {
                        return [
                        <span class="drag-btn">
                            <i class="vxe-icon--menu"></i>
                        </span>
                        ]
                    }}
                },*/
                { field: "sort", title: "排序", width: 60, align: "center" },
                {
                    field: "",
                    title: "操作",
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            titleChapter: "",
            open: false,
            openChapter: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
            },
            form: {
                chapters: [],
            },
            formChapter: {},
            edittingChapterIndex: -1,
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            rulesChapter: {
                title: [
                    {
                        required: true,
                        message: "标题不能为空",
                        trigger: "blur",
                    },
                ],
                content: [
                    {
                        required: true,
                        message: "内容不能为空",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "排序不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listGbpxStudentManual(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.form = {
                name: undefined,
                remark: undefined,
                content: undefined,
                chapters: [],
            };
            this.resetForm("form");
        },

        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleAddChapter() {
            let maxSort = 0;
            for (let i = 0; i < this.form.chapters.length; i++) {
                const chapter = this.form.chapters[i];
                if (chapter.sort > maxSort) {
                    maxSort = chapter.sort;
                }
            }
            this.formChapter = {
                title: undefined,
                content: undefined,
                sort: maxSort + 1,
            };
            this.edittingChapterIndex = -1;
            this.resetForm("formChapter");
            this.openChapter = true;
            this.titleChapter = "添加章节";
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getGbpxStudentManual(id).then((response) => {
                this.form = response.data;
                if (this.form.content) {
                    this.$set(
                        this.form,
                        "chapters",
                        JSON.parse(this.form.content)
                    );
                    //this.form.chapters = JSON.parse(this.form.content)
                } else {
                    this.$set(this.form, "chapters", []);
                    //this.form.chapters = []
                }
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    const chapters = this.form.chapters.sort((a, b) => {
                        return a.sort - b.sort;
                    });
                    this.form.content = JSON.stringify(chapters);
                    if (this.form.id != null) {
                        editGbpxStudentManual(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addGbpxStudentManual(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        submitChapter() {
            this.$refs["formChapter"].validate((valid) => {
                if (valid) {
                    if (this.edittingChapterIndex < 0) {
                        let chapters = [].concat(this.form.chapters);
                        chapters = chapters.sort((a, b) => {
                            return a.sort - b.sort;
                        });
                        chapters.push({
                            title: this.formChapter.title,
                            content: this.formChapter.content,
                            sort: this.formChapter.sort,
                        });
                        this.form.chapters = chapters;
                    } else {
                        const chapter =
                            this.form.chapters[this.edittingChapterIndex];
                        chapter.title = this.formChapter.title;
                        chapter.content = this.formChapter.content;
                        chapter.sort = this.formChapter.sort;
                        let chapters = [].concat(this.form.chapters);
                        chapters = chapters.sort((a, b) => {
                            return a.sort - b.sort;
                        });
                        this.form.chapters = chapters;
                    }
                    this.openChapter = false;
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delGbpxStudentManual(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        handleDeleteChapter(row, rowIndex) {
            this.$confirm("确定删除" + row.title + "?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.form.chapters.splice(rowIndex, 1);
            });
        },

        handleEditChapter(row, rowIndex) {
            this.resetForm("formChapter");
            this.formChapter = {
                title: row.title,
                content: row.content,
                sort: row.sort,
            };
            this.edittingChapterIndex = rowIndex;
            this.openChapter = true;
            this.titleChapter = "修改章节";
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        rowDropChapter() {
            /*this.$nextTick(() => {
                if (this.sortableChapter) {
                    return
                }
                const xTable = this.$refs.chapterGrid
                this.sortableChapter = Sortable.create(xTable.$el.querySelector('.body--wrapper>.vxe-table--body tbody'), {
                    handle: '.drag-btn',
                    onEnd: ({ newIndex, oldIndex }) => {
                        console.log(newIndex, oldIndex)
                        const currRow = this.form.chapters.splice(oldIndex, 1)[0]
                        this.form.chapters.splice(newIndex, 0, currRow)
                        // 排序有问题，Sortable的数据和this.form.chapters不一致
                        for (let i = 0; i < this.form.chapters.length; i++) {
                            this.form.chapters[i].sort = i + 1
                        }
                    }
                })
            })*/
        },
    },
    watch: {
        open(val) {
            if (val) {
                this.rowDropChapter();
            } else {
                if (this.sortableChapter) {
                    this.sortableChapter.destroy();
                    this.sortableChapter = null;
                }
            }
        },
    },
};
</script>

<style scoped>
.sortable-row-demo .drag-btn {
    cursor: move;
    font-size: 12px;
}
.sortable-row-demo .vxe-body--row.sortable-ghost,
.sortable-row-demo .vxe-body--row.sortable-chosen {
    background-color: #dfecfb;
}
</style>
