<template>
    <el-form-item :label="i18nt('designer.setting.maxNum')">
      <el-input-number v-model="maxValue" class="hide-spin-button" style="width: 100%"></el-input-number>
    </el-form-item>
  </template>
  
  <script>
    import i18n from "@/vform/utils/i18n"
  
    export default {
      name: "max-num-editor",
      mixins: [i18n],
      props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
      },
      computed: {
        maxValue: {
          get() {
            return this.optionModel['maxNum']
          },
  
          set(newValue) {
            if (!newValue || isNaN(newValue)) {
              this.optionModel.maxNum = null
            } else {
              this.optionModel.maxNum = Number(newValue)
            }
          }
        },
  
      }
    }
  </script>
  
  <style scoped>
  
  </style>