import request from '@/utils/request'

// 添加设备消息
export function addIotDeviceMsg(data) {
    return request({
      url: '/iot/devicemsg/add',
      method: 'post',
      data: data
    })
}

// 获取设备消息
export function getIotDeviceMsg(id) {
    return request({
      url: '/iot/devicemsg/get?id=' + id,
      method: 'get'
    })
}

// 更新设备消息
export function updateIotDeviceMsg(data) {
    return request({
      url: '/iot/devicemsg/update',
      method: 'post',
      data: data
    })
  }
  
// 删除设备消息
export function delIotDeviceMsg(id) {
    return request({
      url: '/iot/devicemsg/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 设备列表消息
export function listIotDeviceMsg(query) {
  return request({
    url: '/iot/devicemsg/list',
    method: 'get',
    params: query
  })
}