<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        >
            <el-form-item label="id" prop="id">
                <el-input
                    v-model="queryParams.id"
                    placeholder="请输入id"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxquestionnaire/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxquestionnaire/get',
                        '/gbpx/gbpxquestionnaire/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxquestionnaire/del']"
                    >删除</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxquestionnaire/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/gbpx/gbpxquestionnaire/get',
                        '/gbpx/gbpxquestionnaire/edit',
                    ]"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxquestionnaire/del']"
                    >删除</el-button
                >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="960px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入名称"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="简介" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入简介"
                                clearable
                                type="textarea"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-tabs>
                <el-tab-pane label="问题">
                    <el-button
                        style="margin-bottom: 10px"
                        type="primary"
                        icon="el-icon-plus"
                        size="mini"
                        @click="handleAddQuestion"
                        >添加</el-button
                    >
                    <span style="margin-left: 10px"
                        >问题数:{{ form.questions.length }}</span
                    >
                    <!-- <vxe-grid
                        ref="questionGrid"
                        resizable
                        stripe
                        class="sortable-row-demo"
                        :columns="questionTableColumn"
                        :data="form.questions"
                        :max-height="350"
                    >
                        <template #defaultopr="{ row, rowIndex }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click.stop="handleEditQuestion(row, rowIndex)"
                                >修改
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="
                                    handleDeleteQuestion(row, rowIndex)
                                "
                                >删除
                            </el-button>
                        </template>

                        <template #empty>
                            <span>
                                <p>暂无数据</p>
                            </span>
                        </template>
                    </vxe-grid> -->
                    <jf-table
                        ref="questionGrid"
                        class="sortable-row-demo"
                        :columns="questionTableColumn"
                        :data="form.questions"
                        :max-height="350"
                        :checkbox="false"
                        :pager="false"
                        :is-query="false"
                    >
                        <template
                            slot="toolbar_btn_row"
                            slot-scope="{ row, rowIndex }"
                        >
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click.stop="handleEditQuestion(row, rowIndex)"
                                >修改
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="
                                    handleDeleteQuestion(row, rowIndex)
                                "
                                >删除
                            </el-button>
                        </template>
                    </jf-table>
                </el-tab-pane>
            </el-tabs>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :close-on-click-modal="false"
            :title="titleQuestion"
            :visible.sync="openQuestion"
            width="800px"
            append-to-body
        >
            <el-form
                ref="formQuestion"
                :model="formQuestion"
                :rules="rulesQuestion"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="问题" prop="question">
                            <el-input
                                v-model="formQuestion.question"
                                type="textarea"
                                :rows="6"
                                placeholder="请输入问题"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="类型" prop="type">
                            <el-radio-group v-model="formQuestion.type">
                                <el-radio label="1">单选</el-radio>
                                <el-radio label="2">多选</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="排序" prop="sort">
                            <el-input-number
                                v-model="formQuestion.sort"
                                placeholder="请输入排序"
                                controls-position="right"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-tabs>
                <el-tab-pane label="答案">
                    <el-button
                        style="margin-bottom: 10px"
                        type="primary"
                        icon="el-icon-plus"
                        size="mini"
                        @click="handleAddAnswer"
                        >添加</el-button
                    >
                    <!-- <vxe-grid
                        ref="answerGrid"
                        resizable
                        stripe
                        class="sortable-row-demo"
                        :columns="answerTableColumn"
                        :data="formQuestion.answers"
                        :max-height="350"
                    >
                        <template #defaultopr="{ row, rowIndex }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click.stop="handleEditAnswer(row, rowIndex)"
                                >修改
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeleteAnswer(row, rowIndex)"
                                >删除
                            </el-button>
                        </template>

                        <template #empty>
                            <span>
                                <p>暂无数据</p>
                            </span>
                        </template>
                    </vxe-grid> -->
                    <jf-table
                        ref="answerGrid"
                        class="sortable-row-demo"
                        :columns="answerTableColumn"
                        :data="formQuestion.answers"
                        :max-height="350"
                        :checkbox="false"
                        :pager="false"
                        :is-query="false"
                    >
                        <template
                            slot="toolbar_btn_row"
                            slot-scope="{ row, rowIndex }"
                        >
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click.stop="handleEditAnswer(row, rowIndex)"
                                >修改
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeleteAnswer(row, rowIndex)"
                                >删除
                            </el-button>
                        </template>
                    </jf-table>
                </el-tab-pane>
            </el-tabs>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitQuestion"
                    >确 定</el-button
                >
                <el-button @click="openQuestion = false">返 回</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :close-on-click-modal="false"
            :title="titleAnswer"
            :visible.sync="openAnswer"
            width="800px"
            append-to-body
        >
            <el-form
                ref="formAnswer"
                :model="formAnswer"
                :rules="rulesAnswer"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="序号" prop="no">
                            <el-input
                                v-model="formAnswer.no"
                                placeholder="请输入序号，如ABCD"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="答案" prop="answer">
                            <el-input
                                v-model="formAnswer.answer"
                                type="textarea"
                                :rows="6"
                                placeholder="请输入答案"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitAnswer"
                    >确 定</el-button
                >
                <el-button @click="openAnswer = false">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listGbpxQuestionnaire,
    getGbpxQuestionnaire,
    addGbpxQuestionnaire,
    editGbpxQuestionnaire,
    delGbpxQuestionnaire,
} from "@/api/gbpx/gbpx_questionnaire";
export default {
    name: "gbpx-gbpxquestionnaire-list",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "id", minWidth: 120, fixed: "left" },
                { field: "name", title: "名称", minWidth: 120, is_query: true },
                {
                    field: "remark",
                    title: "简介",
                    minWidth: 120,
                    is_query: true,
                },
                //{ field: "content", title: "内容", minWidth: 120 },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                // 	field: "",
                // 	title: "操作",
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: { default: "defaultopr" },
                // },
            ],
            questionTableColumn: [
                {
                    field: "question",
                    title: "问题",
                    minWidth: 120,
                    showOverflow: "ellipsis",
                },
                /*{field: 'sort', title: '排序', width: 60, align: "center", slots: {
                    default: () => {
                        return [
                        <span class="drag-btn">
                            <i class="vxe-icon--menu"></i>
                        </span>
                        ]
                    }}
                },*/
                {
                    field: "type",
                    title: "类型",
                    width: 80,
                    align: "center",
                    formatter: (row) => {
                        if (row.cellValue == 1) {
                            return "单选";
                        } else if (row.cellValue == 2) {
                            return "多选";
                        }
                        return row.cellValue;
                    },
                },
                { field: "sort", title: "排序", width: 60, align: "center" },
                {
                    field: "",
                    title: "操作",
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],
            answerTableColumn: [
                { field: "no", title: "序号", width: 60, align: "left" },
                {
                    field: "answer",
                    title: "答案",
                    minWidth: 120,
                    showOverflow: "ellipsis",
                },
                /*{field: 'sort', title: '排序', width: 60, align: "center", slots: {
                    default: () => {
                        return [
                        <span class="drag-btn">
                            <i class="vxe-icon--menu"></i>
                        </span>
                        ]
                    }}
                },*/
                {
                    field: "",
                    title: "操作",
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            titleQuestion: "",
            titleAnswer: "",
            open: false,
            openQuestion: false,
            openAnswer: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
            },
            form: {
                questions: [],
            },
            formQuestion: {},
            formAnswer: {},
            edittingQuestionIndex: -1,
            edittingAnswerIndex: -1,
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            rulesQuestion: {
                question: [
                    {
                        required: true,
                        message: "问题不能为空",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "类型不能为空",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "排序不能为空",
                        trigger: "blur",
                    },
                ],
            },
            rulesAnswer: {
                no: [
                    {
                        required: true,
                        message: "序号不能为空",
                        trigger: "blur",
                    },
                ],
                answer: [
                    {
                        required: true,
                        message: "答案不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listGbpxQuestionnaire(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.form = {
                name: undefined,
                remark: undefined,
                content: undefined,
                questions: [],
            };
            this.resetForm("form");
        },

        handleQuery(e) {
            if (e) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleAddQuestion() {
            let maxSort = 0;
            for (let i = 0; i < this.form.questions.length; i++) {
                const question = this.form.questions[i];
                if (question.sort > maxSort) {
                    maxSort = question.sort;
                }
            }
            this.formQuestion = {
                question: undefined,
                type: 1,
                answers: [],
                sort: maxSort + 1,
            };
            this.edittingQuestionIndex = -1;
            this.resetForm("formQuestion");
            this.openQuestion = true;
            this.titleQuestion = "添加问题";
        },

        handleAddAnswer() {
            this.formAnswer = {
                no: undefined,
                answer: undefined,
            };
            this.edittingAnswerIndex = -1;
            this.resetForm("formAnswer");
            this.openAnswer = true;
            this.titleAnswer = "添加答案";
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getGbpxQuestionnaire(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editGbpxQuestionnaire(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addGbpxQuestionnaire(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        submitQuestion() {
            this.$refs["formQuestion"].validate((valid) => {
                if (valid) {
                    if (this.edittingQuestionIndex < 0) {
                        let questions = [].concat(this.form.questions);
                        questions = questions.sort((a, b) => {
                            return a.sort - b.sort;
                        });
                        questions.push({
                            question: this.formQuestion.question,
                            type: this.formQuestion.type,
                            answers: this.formQuestion.answers,
                            sort: this.formQuestion.sort,
                        });
                        this.form.questions = questions;
                    } else {
                        const question =
                            this.form.questions[this.edittingQuestionIndex];
                        question.question = this.formQuestion.question;
                        question.type = this.formQuestion.type;
                        question.answers = this.formQuestion.answers;
                        question.sort = this.formQuestion.sort;
                        let questions = [].concat(this.form.questions);
                        questions = questions.sort((a, b) => {
                            return a.sort - b.sort;
                        });
                        this.form.questions = questions;
                    }
                    this.openQuestion = false;
                }
            });
        },

        submitAnswer() {
            this.$refs["formAnswer"].validate((valid) => {
                if (valid) {
                    for (let i = 0; i < this.formQuestion.answers.length; i++) {
                        const answer = this.formQuestion.answers[i];
                        if (
                            this.edittingAnswerIndex != i &&
                            answer.no.trim() == this.formAnswer.no.trim()
                        ) {
                            this.msgError("序号不能重复");
                            return;
                        }
                    }
                    if (this.edittingAnswerIndex < 0) {
                        let answers = [].concat(this.formQuestion.answers);
                        answers = answers.sort((a, b) => {
                            return a.no - b.no;
                        });
                        answers.push({
                            no: this.formAnswer.no.trim(),
                            answer: this.formAnswer.answer,
                        });
                        this.formQuestion.answers = answers;
                    } else {
                        const answer =
                            this.formQuestion.answers[this.edittingAnswerIndex];
                        answer.no = this.formAnswer.no.trim();
                        answer.answer = this.formAnswer.answer;
                        let answers = [].concat(this.formQuestion.answers);
                        answers = answers.sort((a, b) => {
                            return a.no - b.no;
                        });
                        this.formQuestion.answers = answers;
                    }
                    this.openAnswer = false;
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delGbpxQuestionnaire(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        handleDeleteQuestion(row, rowIndex) {
            this.$confirm("确定删除" + row.question + "?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.form.questions.splice(rowIndex, 1);
            });
        },

        handleDeleteAnswer(row, rowIndex) {
            this.$confirm("确定删除" + row.answer + "?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.formQuestion.answers.splice(rowIndex, 1);
            });
        },

        handleEditQuestion(row, rowIndex) {
            this.resetForm("formQuestion");
            this.formQuestion = {
                question: row.question,
                type: row.type,
                answers: row.answers || [],
                sort: row.sort,
            };
            this.edittingQuestionIndex = rowIndex;
            this.openQuestion = true;
            this.titleQuestion = "修改问题";
        },

        handleEditAnswer(row, rowIndex) {
            this.resetForm("formAnswer");
            this.formAnswer = {
                no: row.no,
                answer: row.answer,
            };
            this.edittingAnswerIndex = rowIndex;
            this.openAnswer = true;
            this.titleAnswer = "修改答案";
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
    },
};
</script>
