<template>
  <el-form-item :label="i18nt('designer.setting.actionColumnPosition')" v-if="!designer.isTopic">
    <el-radio-group v-model="optionModel.actionColumnPosition" class="radio-group-custom">
      <el-radio-button label="left">
        {{i18nt('designer.setting.leftAlign')}}</el-radio-button>
      <el-radio-button label="right">
        {{i18nt('designer.setting.rightAlign')}}</el-radio-button>
    </el-radio-group>
  </el-form-item>
</template>

<script>
  import i18n from "@/vform/utils/i18n"

  export default {
    name: "actionColumnPosition-editor",
    mixins: [i18n],
    props: {
      designer: Object,
      selectedWidget: Object,
      optionModel: Object,
    },
    computed: {
      noLabelSetting() {
        return (this.selectedWidget.type === 'static-text') || (this.selectedWidget.type === 'html-text')
        //|| (this.selectedWidget.type === 'divider')
      },

    }
  }
</script>

<style lang="scss" scoped>
  .radio-group-custom {
    ::v-deep .el-radio-button__inner {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
</style>
