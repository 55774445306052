<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="参数名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入参数名称"
                    clearable
                    size="small"
                    style="width: 240px"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="参数键名" prop="key">
                <el-input
                    v-model="queryParams.key"
                    placeholder="请输入参数键名"
                    clearable
                    size="small"
                    style="width: 240px"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="系统内置" prop="type">
                <el-select
                    v-model="queryParams.type"
                    placeholder="系统内置"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in typeOptions.values"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
                <el-date-picker
                    v-model="dateRange"
                    size="small"
                    style="width: 240px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="configList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
                <!-- <el-button
                    type="warning"
                    icon="el-icon-upload"
                    size="mini"
                    @click="handleUpload"
                    >导入</el-button
                >
                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    >导出</el-button
                > -->
                <el-button type="warning" size="mini" @click="handleUpload">
                    <i class="iconfont icon-daoru_o"></i>
                    导入
                </el-button>

                <el-button class="btn-orange" size="mini" @click="handleExport">
                    <i class="iconfont icon-daochu_o"></i>
                    导出
                </el-button>
                <!-- <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleAdvquery"
                    >高级查询</el-button
                > -->
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="handleUpdate(row, true)"
                    >查看
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
            <template slot="type" slot-scope="{ row }">
                {{ typeFormat({ cellValue: row.type }) }}
            </template>
        </jf-table>
        <!-- 
        <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="configList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-refresh"
                    size="mini"
                    @click="handleQuery"
                ></el-button>
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
                <el-button
                    type="warning"
                    icon="el-icon-upload"
                    size="mini"
                    @click="handleUpload"
                    >导入</el-button
                >
                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    >导出</el-button
                >
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleAdvquery"
                    >高级查询</el-button
                >
            </template> -->
        <!--默认操作按钮-->
        <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="handleUpdate(row, true)"
                    >查看
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <jf-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="80px"
                :readonly="readonly"
                :readonly-column="1"
            >
                <el-form-item label="参数名称" prop="name">
                    <el-input
                        v-model="form.name"
                        placeholder="请输入参数名称"
                    />
                </el-form-item>
                <el-form-item label="参数键名" prop="key">
                    <el-input v-model="form.key" placeholder="请输入参数键名" />
                </el-form-item>
                <el-form-item label="参数键值" prop="value">
                    <el-input
                        v-model="form.value"
                        placeholder="请输入参数键值"
                    />
                </el-form-item>
                <el-form-item label="系统内置" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio
                            v-for="dict in typeOptions.values"
                            :key="dict.key"
                            :label="dict.key"
                            >{{ dict.value }}</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input
                        v-model="form.remark"
                        type="textarea"
                        placeholder="请输入内容"
                    />
                </el-form-item>
            </jf-form>
            <div slot="footer" class="dialog-footer">
                <template v-if="!readonly">
                    <el-button type="primary" @click="submitForm"
                        >确 定</el-button
                    >
                    <el-button @click="cancel">取 消</el-button>
                </template>
                <template v-else>
                    <el-button @click="cancel">关 闭</el-button>
                </template>
            </div>
        </el-dialog>

        <!--高级查询对话框-->
        <el-dialog
            title="高级查询"
            :visible.sync="advqueryopen"
            width="1000px"
            append-to-body
        >
            <!--<el-row :gutter="10" class="mb8">-->
            <!--<el-col :span="1.5">-->
            <!--过滤条件匹配-->
            <!--</el-col>-->
            <!--</el-row>-->
            <jf-table
                ref="xAdvqueryGrid"
                id="id_xAdvqueryGrid"
                height="500"
                :columns="advqueryColumn"
                :toolbar-config="advqueryToolbar"
                :data="advqueryData"
                :custom-config="{ storage: true }"
                :edit-config="{
                    trigger: 'click',
                    mode: 'row',
                    showStatus: true,
                }"
                :pager="false"
                :checkbox="false"
                :filter-btn="false"
                :sort-btn="false"
                @toolbar-button-click="toolbarButtonClickEvent"
            >
                <template slot="toolbar_btn_row" slot-scope="{ row }">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        @click="handleDeleted(row)"
                        >删除
                    </el-button>
                </template>
            </jf-table>

            <vxe-grid
                border
                resizable
                keep-source
                ref="xAdvqueryGrid"
                :id="'id_xAdvqueryGrid'"
                height="500"
                :columns="advqueryColumn"
                :toolbar-config="advqueryToolbar"
                :data="advqueryData"
                :custom-config="{ storage: true }"
                :edit-config="{
                    trigger: 'click',
                    mode: 'row',
                    showStatus: true,
                }"
                @toolbar-button-click="toolbarButtonClickEvent"
            >
                <template #defaultadvqueryopr="{ row }">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        @click="handleDeleted(row)"
                        >删除
                    </el-button>
                </template>
            </vxe-grid>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listConfig,
    getConfig,
    delConfig,
    addConfig,
    updateConfig,
    exportConfig,
} from "@/api/core/data/config/config";

export default {
    name: "core-data-config-list",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },

            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                {
                    field: "id",
                    title: "参数编号",
                    width: 100,
                    fixed: "left",
                    visible: false,
                },
                { field: "name", title: "参数名称", minWidth: 100 },
                { field: "key", title: "参数键名", minWidth: 100 },
                { field: "value", title: "参数键值", minWidth: 100 },
                {
                    field: "type",
                    title: "系统内置",
                    width: 100,
                    // formatter: this.typeFormat,
                    slots: {
                        default: "type",
                    },
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    width: 180,
                    formatter: "formatDate",
                    form_type: 3,
                },
                // {
                //     field: "",
                //     title: "操作",
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            advqueryColumn: [
                {
                    field: "qfield",
                    title: "查询字段",
                    // width: 180,
                    fixed: "left",
                    editRender: {
                        name: "select",
                        options: [
                            { value: "dd", label: "cxvs" },
                            { value: "zz", label: "cxvs" },
                        ],
                    },
                },
                {
                    field: "qopr",
                    title: "操作符",
                    // width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "qvalue",
                    title: "值",
                    // width: 180,
                    fixed: "left",
                    editRender: { name: "input" },
                },
                {
                    field: "qlogic",
                    title: "逻辑",
                    // width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultadvqueryopr" },
                // },
            ],
            advqueryData: [],
            advqueryToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                // slots: {
                //     buttons: "toolbar_buttons",
                // },
                buttons: [{ code: "myInsert", name: "新增" }],
            },
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 参数表格数据
            configList: [],
            // 弹出层标题
            title: "",
            // 是否查看
            readonly: false,
            //高级查询对话框
            advqueryopen: false,
            // 是否显示弹出层
            open: false,
            // 类型数据字典
            typeOptions: [],
            // 日期范围
            dateRange: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
                key: undefined,
                type: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                name: [
                    {
                        required: true,
                        message: "参数名称不能为空",
                        trigger: "blur",
                    },
                ],
                key: [
                    {
                        required: true,
                        message: "参数键名不能为空",
                        trigger: "blur",
                    },
                ],
                value: [
                    {
                        required: true,
                        message: "参数键值不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();

        setTimeout(() => {
            // const xAdvqueryGrid = this.$refs.xAdvqueryGrid;
            // console.log(xAdvqueryGrid,'xAdvqueryGrid')
            // const $grid = xAdvqueryGrid.$refs.xGrid;
            // 异步更新下拉选项
            var ta = [];
            for (var i in this.tableColumn) {
                var e = this.tableColumn[i];
                if (e.field != null && e.field != "") {
                    ta.push({ value: e.field, label: e.title });
                }
            }
            this.advqueryColumn[0].editRender.options = ta;
        }, 300);
    },
    methods: {
        tableChange(e) {
            console.log(e);
        },
        toolbarButtonClickEvent({ code }) {
           

            const $grid = this.$refs.xAdvqueryGrid;
          
            switch (code) {
                case "myInsert":
                    $grid.insert({
                        qfield: "id",
                        qopr: "dd",
                        qvalue: "dd",
                        qlogic: "dd",
                    });

                    break;
            }
        },
        toolbarToolClickEvent({ code }) {
            const xAdvqueryGrid = this.$refs.xAdvqueryGrid;
            const $grid = xAdvqueryGrid.$refs.xGrid;
            switch (code) {
                case "myPrint":
                    $grid.print();
                    break;
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        /** 查询参数列表 */
        getList() {
            this.loading = true;
            listConfig(
                this.addDateRange(this.queryParams, this.dateRange)
            ).then((response) => {
                this.single = true;
                this.multiple = true;
                this.typeOptions = response.data.searchTypes;
                this.configList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 参数系统内置字典翻译
        typeFormat({ cellValue }) {
            return this.selectDictLabel(this.typeOptions.values, cellValue);
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.advqueryopen = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                name: undefined,
                key: undefined,
                value: undefined,
                type: "1",
                remark: undefined,
            };
            this.resetForm("form");
        },
        handleAdvquery() {
            this.advqueryopen = true;
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.resetForm("queryForm");
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.readonly = false;
            this.title = "添加参数";
            this.open = true;
        },
        handleUpload() {},
        // 多选框选中数据
        handleSelectionChange({ checked, records }) {
            console.log(checked ? "勾选事件" : "取消事件", records);
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },
        /** 修改按钮操作 */
        handleUpdate(row, readonly) {
            this.reset();
            const id = row.id || this.ids;
            getConfig(id).then((response) => {
                this.form = {
                    id: response.data.id,
                    name: response.data.name,
                    key: response.data.key,
                    value: response.data.value,
                    type: "" + response.data.type,
                    remark: response.data.remark,
                };
                this.readonly = readonly;
                this.title = readonly ? "查看参数" : "修改参数";
                this.open = true;
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != undefined) {
                        updateConfig(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addConfig(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const configIds = row.id || this.ids.join(',');
            this.$confirm("确定删除吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delConfig(configIds);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有参数数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportConfig(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
    },
};
</script>
<style scoped>
.btn-orange {
    background-color: #ff9648;
    color: #fff;
    border: none;
}
</style>