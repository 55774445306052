import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2024-03-11 15:32:45
class Indetailwarning {
// 查询indetailwarning列表
 listIndetailwarning(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/product/indetailwarning/list',
        method: 'post',
        data: data
    })
}

// 查看indetailwarning
 getIndetailwarning ( id) {
    return request({
        url: '/product/indetailwarning/get?id='+id,
        method: 'get'
    })
}


// 新增indetailwarning
 addIndetailwarning(mid,data) {
  var postdata={
          form_org_data:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/product/indetailwarning/add',
        method: 'post',
        data: postdata
    })
}

// 修改indetailwarning
 editIndetailwarning(mid,data) {
  var postdata={
          form_org_data:JSON.stringify(data),
          id:data.id
      }

    return request({
        url: '/product/indetailwarning/edit',
        method: 'post',
        data: postdata
    })
}

// 删除indetailwarning
 delIndetailwarning(ids) {
    return request({
        url: '/product/indetailwarning/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 indetailwarningImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/product/indetailwarning/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 indetailwarningExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/product/indetailwarning/export?` + parata.join("&") ;


 }

 //下导入模板
  indetailwarningimportTemplate() {

     return   `/product/indetailwarning/importTemplate`   ;

  }

}

const instance = new Indetailwarning();
export default instance;