<template>
<div class="app-container">
	<el-form
		:model="queryParams"
		ref="queryForm"
		:inline="true"
		label-width="68px"
		>
		<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="模型" prop="model_id">
			<el-input
				v-model="queryParams.model_id"
				placeholder="请输入模型"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="字段名称" prop="column_name">
			<el-input
				v-model="queryParams.column_name"
				placeholder="请输入字段名称"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item>
			<el-button
				type="cyan"
				icon="el-icon-search"
				size="mini"
				@click="handleQuery"
				>搜索</el-button
			>
			<el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
				>重置</el-button
			>
		</el-form-item>
	</el-form>

	<vxe-grid
		resizable
		ref="xGrid"
		row-id="id"
		stripe
		show-overflow
		highlight-hover-row
		:loading="loading"
		:toolbar-config="tableToolbar"
		:columns="tableColumn"
		:data="dataList"
		@checkbox-change="checkboxChangeEvent"
		@checkbox-all="checkboxChangeEvent"
	>
		<template #toolbar_buttons>
			<el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAdd"
				v-hasPermi="[
					'/bigdata/bddatamodelcolumn/add',
				]"
				>新增</el-button
			>

			<el-button
				type="success"
				icon="el-icon-edit"
				size="mini"
				:disabled="single"
				@click="handleUpdate"
				v-hasPermi="[
					'/bigdata/bddatamodelcolumn/get',
					'/bigdata/bddatamodelcolumn/edit',
				]"
				>修改</el-button
			>

			<el-button
				type="danger"
				icon="el-icon-delete"
				size="mini"
				:disabled="multiple"
				@click="handleDelete"
				v-hasPermi="[
					'/bigdata/bddatamodelcolumn/del',
				]"
				>删除</el-button
			>
		</template>

		<template #defaultopr="{ row }">
			<el-button
				size="mini"
				type="text"
				icon="el-icon-edit"
				@click.stop="handleUpdate(row)"
				>修改
			</el-button>
			<el-button
				size="mini"
				type="text"
				icon="el-icon-delete"
				@click.stop="handleDelete(row)"
				>删除
			</el-button>
		</template>

		<template #empty>
			<span>
				<p>暂无数据</p>
			</span>
		</template>
	</vxe-grid>

	<vxe-pager
		background
		size="small"
		:loading="loading"
		:current-page.sync="queryParams.pageNum"
		:page-size.sync="queryParams.pageSize"
		:total="total"
		@page-change="handlePageChange"
	>
	</vxe-pager>

	<el-dialog
		:close-on-click-modal="false"
		:title="title"
		:visible.sync="open"
		width="800px"
		append-to-body
	>
		<el-form ref="form" :model="form" :rules="rules" label-width="100px">
			<el-row>
				<el-col :span="12">
					<el-form-item label="模型" prop="model_id">
						<el-input-number
							v-model="form.model_id"
							placeholder="请输入模型"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="字段名称" prop="column_name">
						<el-input
							v-model="form.column_name"
							placeholder="请输入字段名称"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="字段描述" prop="column_desc">
						<el-input
							v-model="form.column_desc"
							placeholder="请输入字段描述"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="字段数据类型" prop="column_type">
						<dict-select
							v-model="form.column_type"
							placeholder="请选择字段数据类型"
							type="bigdata_column_type"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="备注" prop="remark">
						<el-input
							v-model="form.remark"
							placeholder="请输入备注"
							clearable
							type="textarea"
						/>
					</el-form-item> 
				</el-col>
			</el-row>
		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitForm">确 定</el-button>
			<el-button @click="cancel">返 回</el-button>
		</div>
	</el-dialog>
</div>
</template>
<script>
import {
	listBdDatamodelColumn,
	getBdDatamodelColumn,
	addBdDatamodelColumn,
	editBdDatamodelColumn,
	delBdDatamodelColumn,
} from "@/api/bigdata/bd_datamodel_column";
export default {
	name: "bd-datamodel-column",
	data() {
		return { 
			tableToolbar: {
				perfect: true,
				zoom: true,
				custom: true,
				refresh: {
					query: this.handleQuery,
				},
				slots: {
					buttons: "toolbar_buttons",
				},
			},
			tableColumn: [
				{ type: "checkbox", width: 60, fixed: "left" },
				{ field: "id", title: "id", minWidth: 120, fixed: "left" },
				{ field: "model_id", title: "模型", minWidth: 120 },
				{ field: "column_name", title: "字段名称", minWidth: 120 },
				{ field: "column_desc", title: "字段描述", minWidth: 120 },
				{ field: "column_type", title: "字段数据类型", minWidth: 120, formatter: this.dictFormat, dictType: 'bigdata_column_type' },
				{ field: "create_time", title: "创建时间", minWidth: 120, formatter: "formatDate", visible: false },
				{ field: "create_by", title: "创建者", minWidth: 120, visible: false },
				{ field: "create_by_username", title: "创建者 昵称/姓名", minWidth: 120, visible: false },
				{ field: "update_time", title: "修改时间", minWidth: 120, formatter: "formatDate" },
				{ field: "update_by", title: "修改者", minWidth: 120, visible: false },
				{ field: "update_by_username", title: "更新者 昵称/姓名", minWidth: 120, visible: false },
				{ field: "remark", title: "备注", minWidth: 120 },
				{
					field: "",
					title: "操作",
					width: 180,
					fixed: "right",
					align: "center",
					slots: { default: "defaultopr" },
				},
			],
			loading: true,
			ids: [],
			single: true,
			multiple: true,
			total: 0,
			dataList: [],
			title: "",
			open: false,
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				id: undefined,
				model_id: undefined,
				column_name: undefined,
			},
			form: {},
			rules: { 
				model_id: [{ required: true, message: "模型不能为空", trigger: "blur" }],
				column_name: [{ required: true, message: "字段名称不能为空", trigger: "blur" }],
				column_type: [{ required: true, message: "字段数据类型不能为空", trigger: "blur" }],
			}
		};
	},
	created() { 
		for (let i = 0; i < this.tableColumn.length; i++) {
			const col = this.tableColumn[i]
			if (col.dictType) {
				this.getDicts(col.dictType).then(response => {
					if (response.code == 0) {
						col.dictData = response.data
						this.dataList = [].concat(this.dataList)
					}
				})
			}
		}
		this.getList();
	},
	methods: {
		getList() {
			this.loading = true;
			this.ids = [];
			this.single = true;
			this.multiple = true;

			listBdDatamodelColumn(this.queryParams).then((response) => { 
				this.dataList = response.data.list;
				this.total = response.data.total;
				this.loading = false;
			});
		},
		
		dictFormat(e) {
			const col = this.tableColumn[e.columnIndex]
			if (col.dictData) {
				const values = e.cellValue ? e.cellValue.split(',') : []
				const labels = []
				for (let i = 0; i < values.length; i++) {
					const v = values[i]
					for (let j = 0; j < col.dictData.values.length; j++) {
						const item = col.dictData.values[j]
						if (v == item.key) {
							labels.push(item.value)
							break
						}
					}
				}
				return labels.join(',')
			}
			return e.cellValue
		},
		
		cancel() {
			this.open = false;
			this.reset();
		},

		reset() {
			this.form = { 
				model_id: undefined,
				column_name: undefined,
				column_desc: undefined,
				column_type: undefined,
				remark: undefined,
			};
			this.resetForm("form");
		},

		handleQuery() {
			this.queryParams.pageNum = 1;
			this.getList();
		},

		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},

		handleAdd() {
			this.reset();
			this.open = true;
			this.title = "添加";
		},

		handleUpdate(row) {
			this.reset();
			const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
			getBdDatamodelColumn(id).then((response) => {
				this.form = response.data;
				this.open = true;
				this.title = "修改";
			});
		},

		submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if (this.form.id != null) {
						editBdDatamodelColumn(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("修改成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					} else {
						addBdDatamodelColumn(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("新增成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					}
				}
			});
		},

		handleDelete(row) {
			const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
			this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(function () {
				return delBdDatamodelColumn(ids);
			})
			.then(() => {
				setTimeout(() => {
					this.getList();
				}, 300);
				this.msgSuccess("删除成功");
			})
			.catch(function () {});
		},

		checkboxChangeEvent({ records }) {
			this.ids = records.map((item) => item.id);
			this.single = records.length != 1;
			this.multiple = !records.length;
		},

		handlePageChange({ currentPage, pageSize }) {
			if (this.queryParams.pageSize == pageSize) {
				this.getList();
			} else {
				this.queryParams.pageSize = pageSize;
				this.handleQuery();
			}
		},
	}
};
</script>
