import request from '@/utils/request'

// 查询cms模型列表
export function listCmsModel(query) {
  return request({
    url: '/cms/model/list',
    method: 'get',
    params: query
  })
}

// 查询cms模型详细
export function getCmsModel(id) {
  return request({
    url: '/cms/model/get?id=' + id,
    method: 'get'
  })
}

// 新增cms模型
export function addCmsModel(data) {
  return request({
    url: '/cms/model/add',
    method: 'post',
    data: data
  })
}

// 修改cms模型
export function updateCmsModel(data) {
  return request({
    url: '/cms/model/edit',
    method: 'post',
    data: data
  })
}

// 删除cms模型
export function delCmsModel(id) {
  return request({
    url: '/cms/model/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
