<template>
    <div ref="container" class="container">
        <div class="header">
            <div class="leftb"></div>
            <div class="title2">服务大厅统计</div>
            <div class="date">
                <el-radio-group v-model="dateType" size="small" @change="changeDate">
                    <el-radio-button label="今日"></el-radio-button>
                    <el-radio-button label="本周" ></el-radio-button>
                    <el-radio-button label="本月"></el-radio-button>
                    <el-radio-button label="本年"></el-radio-button>
                    <el-radio-button label="全部"></el-radio-button>
                    <el-radio-button label="任意"></el-radio-button>
                </el-radio-group>
                <el-date-picker v-if="dateType == '任意'" v-model="dateRange" type="datetimerange"  format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" size="small" @change="handleQuery">
                </el-date-picker>
            </div>
            <div style="flex: 1"></div>
            <div class="rightstat">
                <div>办结数/办结率</div>
                <div>{{ stat.finish }}/{{ getRate(stat.finish,stat.total) }}</div>
            </div>
        </div>
        <div class="body">
            <div class="left" :style="height ? ('min-height: ' + (height - 74) + 'px') : ''">
                <div>
                    <el-select v-model="queryParams.flow_id" placeholder="全部服务" size="small" clearable filterable @change="handleQueryId">
                      <el-option
                        v-for="item in flowList"
                        :key="item.id"
                        :label='item.name'
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <el-button type="primary" size="small">筛选</el-button>
                </div>
                <div ref="echarts" class="echarts"  ></div>
                <div class="block" style="background-color: rgb(252, 202, 0)">
                    <div class="block-num">{{stat.total}}</div>
                    <div class="block-title">服务总数</div>
                </div>
                <div class="block" style="background-color: rgb(222, 134, 143)">
                    <div class="block-num">{{ stat.users }}/{{ stat.total }}</div>
                    <div class="block-title">服务人数/服务次数</div>
                </div>
                <div class="block" style="background-color: rgb(133, 209, 112)">
                    <div class="block-num">{{ stat.running }}/{{stat.finish}}</div>
                    <div class="block-title">可办理/办理总数</div>
                </div>
                <div class="block" style="background-color: rgb(68, 143, 252)">
                    <div class="block-num">{{ stat.total_flows }}/{{stat.used_flows}}</div>
                    <div class="block-title">流程总数/发起总数</div>
                </div>
            </div>
            <div class="right" :style="height ? ('min-height: ' + (height - 74) + 'px') : ''">
                <div>
                  <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                    <el-form-item label="" prop="flow_name">
                      <el-input v-model="queryParams.flow_name" placeholder="请输入服务名称" clearable size="small"
                                @input.native="handleQueryName" />
                    </el-form-item>
                    <el-form-item label="" prop="dept_id">
                      <dept-select v-model="queryParams.dept_id" placeholder="请选择部门" valueField="id" size="small" @change="handleQuery"/>
                    </el-form-item>
                  </el-form>

                </div>
                <div class="table">
                    <el-table v-if="height > 0" :data="tableData" stripe style="width: 100%" :height="height - 74 - 72">
                        <el-table-column prop="Name" label="服务名称">
                        </el-table-column>
                        <el-table-column prop="CategoryName" label="分类">
                        </el-table-column>
                        <el-table-column prop="Total" label="发起总数" width="180">
                        </el-table-column>
                        <el-table-column prop="Finish" label="办结数" width="180">
                        </el-table-column>
                        <el-table-column  label="办结率" width="180">
                          <template slot-scope="scope">
                            {{getRate(scope.row.Finish,scope.row.Total)}}
                          </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $echarts from 'echarts'
import { listBpmFlow } from "@/api/bpm/flow";
import {StatBpm} from "@/api/bpm/bpm";
import Vue from "vue";
export default {
    name: 'bpm-stat',
    data() {
        return {
            height: 0,
            dateType: '今日',
            dateRange: undefined,
            serviceName: '',
            tableData: [],
            flowList:[],
            stat: {
              finish: 0,
              pass:0,
              reject:0,
              total:0,
              running:0,
              users:0,
              used_flows:0,
              total_flows:0,
              rows:""
            },
            // 查询参数
            queryParams: {
              pageNum: 1,
              pageSize: 10,
              beginTime:'',
              endTime:'',
              flow_name: undefined,
              flow_id:undefined,
              dept_id:undefined,
            },
        }
    },
    created() {
      listBpmFlow({ pageNum: 1,pageSize: 500}).then(response => {
        this.flowList = response.data.list;
      });
      this.changeDate()
    },
   /* mounted() {
      this.setEchart()
    },*/
    destroyed() {
        window.removeEventListener("resize", this.onWindowResize);
    },
    methods: {
      setEchart(){
        this.height = this.$refs.container && this.$refs.container.parentNode && this.$refs.container.parentNode.offsetHeight || (document.body.clientHeight - 84)
        this.echarts = $echarts.init(this.$refs.echarts);
        window.addEventListener("resize", this.onWindowResize);
        this.setOption()
      },
      getRate(val,total) {
        if(total == 0 || val == 0) {
          return '0%'
        }
        let result = Number(val)/Number(total)*100;
        return result.toFixed(2) +'%'
      },
      changeDate(){
        let format = 'YYYY-MM-DD HH:mm:ss'
        if(this.dateType == '今日') {
          const start = Vue.prototype.moment().startOf('day').format(format)
          const end = Vue.prototype.moment().endOf('day').format(format)
          this.dateRange = [start, end]
        }else if(this.dateType == '本周') {
          const start = Vue.prototype.moment().startOf('week').format(format)
          const end = Vue.prototype.moment().endOf('week').format(format)
          this.dateRange = [start, end]
        }else if(this.dateType == '本月') {
          const start = Vue.prototype.moment().startOf('month').format(format)
          const end = Vue.prototype.moment().endOf('month').format(format)
          this.dateRange = [start, end]
        }else if(this.dateType == '本年') {
          const start = Vue.prototype.moment().startOf('year').format(format)
          const end = Vue.prototype.moment().endOf('year').format(format)
          this.dateRange = [start, end]
        }else {
          this.dateRange = undefined
        }
        this.handleQuery()
      },
      handleQueryId(){
        this.queryParams.flow_name = ''
      },
      handleQueryName(){
        this.queryParams.flow_id = ''
      },
      handleQuery(){
        this.queryParams.beginTime = ''
        this.queryParams.endTime = ''
        if (this.dateRange && '' != this.dateRange) {
          this.queryParams.beginTime = this.dateRange[0];
          this.queryParams.endTime = this.dateRange[1];
        }
        this.queryParams.pageNum = 1;
        this.tableData = []
        this.stat = {
            finish: 0,
            pass:0,
            reject:0,
            total:0,
            running:0,
            users:0,
            used_flows: 0,
            total_flows: 0,
            rows:""
        }
        this.getStat();
      },
      getStat() {
        this.loading = true;
        StatBpm(this.queryParams).then(response => {
          this.stat = response.data
          this.loading = false
          if(this.stat.rows != "") {
            this.tableData = JSON.parse(this.stat.rows)
          }
          this.setEchart()
        });
      },
        onWindowResize() {
            setTimeout(() => {
                this.resize()
            }, 100)
        },
        resize() {
            this.echarts.resize()
        },
        setOption() {
            const option = {
                legend: {
                    top: 'bottom'
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['0%', '60%'],
                        center: ['50%', '50%'],
                        itemStyle: {
                            borderRadius: 8
                        },
                        label: {
                            //fontSize: 14,
                            formatter: "{b}\n{c}"
                        },
                        data: [
                            { value: this.stat.running, name: '进行中' },
                            { value: this.stat.pass, name: '已审批' },
                            { value: this.stat.reject, name: '已驳回' }
                        ]
                    }
                ],
                color: ['rgb(33, 200, 96)', 'rgb(80, 135, 236)', 'rgb(253, 64, 70)', 'orange']
            };
            this.echarts.clear()
            this.echarts.setOption(option, true)
        }
    }
}
</script>
<style scoped>
.container {
    padding-top: 10px;
}

.header {
    height: 44px;
    border: solid 1px #ccc;
    display: flex;
    align-items: center;
    padding: 5px 10px;
}

.leftb {
    height: 30px;
    width: 5px;
    background-color: rgb(64, 141, 254);
}

.title2 {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
}

.date {
    margin-left: 10px;
}

.rightstat {
    font-size: 12px;
    text-align: center;
    line-height: 16px;
}

.body {
    width: 100%;
    padding: 10px 15px;
    display: flex;
}

.left {
    width: 20%;
    border: solid 1px #ccc;
    padding: 10px;
}

.echarts {
    width: 100%;
    height: 250px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.block {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px 20px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
}

.block-title {
    margin-top: 10px;
}

.right {
    margin-left: 10px;
    margin-right: 15px;
    flex: 1;
    border: solid 1px #ccc;
    padding: 10px;
}
.table {
    margin-top: 10px;
}
</style>
