import { render, staticRenderFns } from "./zcbfgr.vue?vue&type=template&id=47e61973&scoped=true&"
import script from "./zcbfgr.vue?vue&type=script&lang=js&"
export * from "./zcbfgr.vue?vue&type=script&lang=js&"
import style0 from "./zcbfgr.vue?vue&type=style&index=0&id=47e61973&prod&scoped=true&lang=css&"
import style1 from "./zcbfgr.vue?vue&type=style&index=1&id=47e61973&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e61973",
  null
  
)

export default component.exports