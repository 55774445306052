import request from '@/utils/request'

export function listUserPool(query) {
  return request({
    url: '/userpool/list',
    method: 'get',
    params: query
  })
}

export function getUserPool(id) {
  return request({
    url: '/userpool/get?id=' + id,
    method: 'get'
  })
}

export function addUserPool(data) {
  return request({
    url: '/userpool/add',
    method: 'post',
    data: data
  })
}

export function editUserPool(data) {
  return request({
    url: '/userpool/edit',
    method: 'post',
    data: data
  })
}

export function delUserPool(ids) {
  return request({
    url: '/userpool/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

