import OSS from 'ali-oss'
import request from '@/utils/request'
import axios from "axios";

//获取临时票据
export function getStsServer(row) {
    return request({
        url: '/dss/auth/token?platform=' + row.platform + '&type=' + row.type + '&source=' + row.source + "&public=" + row.public,
        method: 'get',
        data: row
    })
}

// 文件信息保存到dss
export function addOss(data) {
    return request({
        url: '/dss/oss/add',
        method: 'post',
        data: data
    })
}


/**
 * 获取oss签名
 * @param params 查询条件
 */


export function uploadFile(params) {

    let stsReq = {
        platform: "AliOss",
        type: "general",
        source: "system",
        public: "1"
    }
    getStsServer(stsReq).then(resp => {

        try {

            let stsConfig = resp.data;

            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
                region: "oss-cn-shenzhen",
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: stsConfig.accessKeyId,
                accessKeySecret: stsConfig.accessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: stsConfig.accessToken,
                // 填写Bucket名称，例如examplebucket。
                bucket: stsConfig.bucket,
                refreshSTSToken: async() => { // 刷新临时访问凭证的回调，在上传大文件的时候会用到
                    const info = await getStsServer({
                        platform: "AliOss",
                        type: "general",
                        source: "system",
                        public: "1"
                    })
                    return {
                      accessKeyId: info.data.accessKeyId,
                      accessKeySecret: info.data.accessKeySecret,
                      stsToken: info.data.securityToken
                    }
                  },
                  // 刷新临时访问凭证的时间间隔，单位为毫秒，在过期前一分钟刷新
                  // 过期时间是后端配的，这里配合后端把时间写死也可以，例如 15 分钟过期，10 分钟就可以刷新一次
                  refreshSTSTokenInterval: new Date(stsConfig.expiration) - new Date() - 1000 * 60

            });

            console.log(stsConfig);

         
            if (params.file.size >= 4 * 1024 * 1024) { //大于4M分片上传
                client.multipartUpload(params.key, params.file, {
                    // 获取分片上传进度、断点和返回值。
                    progress: (p, cpt, res) => {
                        // onUploadProgress && onUploadProgress(p)
                        console.log(p,cpt,res);
                    },
                    // 设置并发上传的分片数量。
                    parallel: 4,
                    // 设置分片大小。默认值为1 MB，最小值为100 KB。
                    partSize: 1024 * 1024,
                    // headers,
                    // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
                    mime: "text/plain",
                    timeout: 120000  // 设置超时时间
                });
            } else { 
                //小于4M直接上传
                client.put(params.key, params.file)
            }
           
        } catch (e) {
            console.log(e);
         
        }

    })


}
