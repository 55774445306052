<template>
    <div v-if="designer.isTopic &&
            selectedWidget.options.scoringMethod &&
            selectedWidget.options.scoringMethod * 1 < 3 ">
        <!-- &&
            selectedWidget.options.scoringMethod &&
            selectedWidget.options.scoringMethod * 1 < 3 -->
        <el-form-item :label="i18nt('designer.setting.answer')">
            <el-select
                v-model="optionModel.answer"
                placeholder="请选择"
                :style="{ width: '100%' }"
                v-if="
                    (selectedWidget.type === 'radio' ||selectedWidget.type === 'select' || selectedWidget.type === 'radio-judge' )&&
                    selectedWidget.options.scoringMethod * 1 === 1
                "
            >
                <el-option
                    v-for="item in optionItems"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                >
                    <span> {{ item.label }}</span>
                </el-option>
            </el-select>

            <!-- <div
                    v-for="item in selectedWidget.options.optionItems"
                    :key="item.label"
                    class="d-flex align-items-center"
                >
                    <el-checkbox
                        v-model="value"
                        :true-label="item.label"
                        @change="checkboxChange"
                        >{{ item.label }}</el-checkbox
                    >
                </div> -->

            <el-checkbox-group
                v-model="optionModel.answer"
                @change="checkboxChange"
                v-if="
                    selectedWidget.type === 'checkbox' &&
                    selectedWidget.options.scoringMethod * 1 === 2
                "
            >
                <el-checkbox
                    v-for="item in optionItems"
                    :label="item.value ? String(item.value) : item.value"
                    :key="item.value ? String(item.value) : item.value"
                    >{{ item.label }}</el-checkbox
                >
            </el-checkbox-group>
            <el-input
                type="textarea"
                placeholder="多个答案每行一个"
                v-model="optionModel.answer"
                v-if="
                    selectedWidget.options.scoringMethod * 1 === 2 &&
                    (selectedWidget.type === 'input' ||
                        selectedWidget.type === 'textarea')
                "
            ></el-input>
        </el-form-item>
    </div>
</template>
<script>
import i18n from "@/vform/utils/i18n";
import { execModel } from "@/api/core/data/datasource/model";
export default {
    name: "answer-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            value: "",
            optionItems: [],
            label: "label",
            value: "value",
        };
    },
    created() {},
    watch: {
        selectedWidget: {
            handler(val) {
                if (val.options.dsEnabled || val.options.dicttype) {
                    this.getOptionItems();
                } else {
                    this.optionItems = val.options.optionItems;
                    this.label = val.options.labelKey;
                    this.value = val.options.valueKey;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        getOptionItems() {
            let val = this.selectedWidget.options;
            if (val.dsEnabled && val.dsConf_dstid) {
                execModel(
                    val.dsConf_dstid,
                    JSON.stringify({ __max_count__: 100 })
                ).then((res) => {
                    if (res.data && res.data.length > 0) {
                        let label = val.dsConf_dst_label
                            ? val.dsConf_dst_label.split(":")[0]
                            : "";
                        let value = val.dsConf_dst_value
                            ? val.dsConf_dst_value.split(":")[0]
                            : "";
                        this.optionItems = res.data.map((o) => {
                            return {
                                label: o[label],
                                value: o[value],
                            };
                        });
                    }
                });
            }
            if (!val.dsEnabled && val.dicttype) {
                this.getDicts(val.dicttype).then((response) => {
                    let dictvalue = response.data.values || [];
                    this.optionItems = dictvalue.map((o) => {
                        return {
                            label: o.value,
                            value: o.key,
                        };
                    });
                });
            }
        },
        checkboxChange(e) {
            // this.optionModel.answer.push()
            // console.log(e, this.optionModel.answer,this.optionItems);
        },
    },
};
</script>