<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
    >
      <el-form-item label="表名称" prop="tableName">
        <el-input
          v-model="queryParams.table_name"
          placeholder="请输入表名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="表描述" prop="tableComment">
        <el-input
          v-model="queryParams.table_comment"
          placeholder="请输入表描述"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="dateRange"
          size="small"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      stripe
      show-overflow
      highlight-hover-row
      :loading="loading"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="tableList"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
    >
      <template #toolbar_buttons>
        <!--<el-button-->
        <!--style="margin-left: 10px"-->
        <!--type="primary"-->
        <!--icon="el-icon-download"-->
        <!--size="mini"-->
        <!--@click="handleGenTable"-->
        <!--&gt;生成</el-button>-->
        <el-button
          type="info"
          style="margin-left: 10px"
          icon="el-icon-upload"
          size="mini"
          @click="openImportTable"
          >导入</el-button
        >

        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleEditTable"
          >修改</el-button
        >

        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          >删除</el-button
        >
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleEditTable(row)"
          >修改
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-download"
          @click="handleGenTable(row)"
          >生成代码</el-button
        >
        <el-button
          type="text"
          size="small"
          icon="el-icon-menu"
          @click="handleGenCreateMenu(row)"
          >生成菜单</el-button
        >
      </template>

      <!--自定义空数据模板-->
      <template #empty>
        <span>
          <p>暂无数据</p>
        </span>
      </template>
    </vxe-grid>
    <vxe-pager
      background
      size="small"
      :loading="loading"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      :total="total"
      @page-change="handleSizeChange"
    >
    </vxe-pager>

    <!-- 预览界面 -->
    <el-dialog
      :title="preview.title"
      :visible.sync="preview.open"
      width="80%"
      top="5vh"
      append-to-body
    >
      <el-tabs v-model="preview.activeName">
        <el-tab-pane
          v-for="(value, key) in preview.data"
          :label="key.substring(key.lastIndexOf('/') + 1, key.indexOf('.vm'))"
          :name="key.substring(key.indexOf('_') + 1, key.indexOf('.vm'))"
          :key="key"
        >
          <pre>{{ value }}</pre>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!--生成菜单选项-->
    <el-dialog
      :close-on-click-modal="false"
      title="选择上级菜单"
      :visible.sync="createmenuopen"
      width="50%"
      top="5vh"
      append-to-body
    >
      <el-form ref="form" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="上级菜单">
              <treeselect
                v-model="menupid"
                :options="menuOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="选择上级菜单"
                @select="selectmenunode"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dogenmenu">确 定</el-button>
        <el-button @click="cancel_createmenuopen">取 消</el-button>
      </div>
    </el-dialog>

    <!--生成代码选项-->
    <el-dialog
      :close-on-click-modal="false"
      title="选项"
      :visible.sync="createcodeopen"
      width="50%"
      top="5vh"
      append-to-body
    >
      <el-form ref="form" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="按钮">
              <el-checkbox-group v-model="genselbtns">
                <el-checkbox
                  v-for="item in genbtns"
                  :label="item.k"
                  :key="item.k"
                  >{{ item.v }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="其他选项">
              <el-switch
                v-model="sw_addsysfield"
                active-text="检查是否包含系统字段"
                active-color="#13ce66"
                inactive-color="#eeeeee"
              >
              </el-switch>

              <el-switch
                v-model="sw_vueonly"
                active-text="仅生成前端页面"
                active-color="#13ce66"
                inactive-color="#eeeeee"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doconfirmgencode">确认生成</el-button> 
        <el-button @click="cancel_createcodeopen">取 消</el-button>
      </div>
    </el-dialog>

   
  </div>
</template>

<script>
import {
  listTable,
  previewTable,
  delTable,
  genCode,
  genMenu,
} from "@/api/form/gen";
import importTable from "./importTable";
import { downLoadZip } from "@/utils/zipdownload";

import { listMenu, treeselect } from "@/api/core/system/menu";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import IconSelect from "@/components/IconSelect";

export default {
  name: "Gen",
  components: { importTable, Treeselect, IconSelect },
  data() {
    return {
      genbtns: [
        { k: "add", v: "添加" },
        { k: "del", v: "删除" },
        { k: "edit", v: "编辑" },
        { k: "view", v: "查看" },
        //        {"k": "get", "v": "查看"},
        { k: "advquerylist", v: "高级查询模板列表" },
        { k: "advquerysave", v: "高级查询保存模板" },
        { k: "advquerydel", v: "高级查询删除模板" },
        { k: "export", v: "导出数据" },
      ],
      genselbtns: ["add", "del", "edit"],
      sw_addsysfield: true,
      sw_vueonly: false,
      selnode: null,
      menupid: 0,
      // 菜单树选项
      menuOptions: [],
      // 弹出层标题
      createmenuopen: false,
      createcodeopen: false,
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 列
      tableColumn: [
        { type: "checkbox", width: 60, fixed: "left" },
        { field: "id", title: "ID", width: 70, fixed: "left" },
        {
          field: "tpl_category",
          title: "类型",
          width: 80,
          fixed: "left",
          formatter: this.form_typeFormat,
        },
        { field: "table_name", title: "表名称", minWidth: 120 },

        { field: "table_comment", title: "表描述", width: 200 },
        {
          field: "is_create",
          title: "状态",
          width: 150,
          formatter: this.is_createFormat,
        },
        {
          field: "create_time",
          title: "创建时间",
          width: 180,
          formatter: "formatDate",
        },
        {
          field: "update_time",
          title: "更新时间",
          width: 180,
          formatter: "formatDate",
        },
        {
          field: "",
          title: "操作",
          resizable: false,
          minWidth: 290,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],
      // 遮罩层
      loading: true,
      // 唯一标识符
      uniqueId: "",
      rowid: 0,
      // 选中数组
      ids: [],
      // 选中表数组
      tableNames: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 表数据
      tableList: [],
      // 日期范围
      dateRange: "",
      // 查询参数
      is_createOptions: [],
      form_typeOptions: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        table_name: undefined,
        table_comment: undefined,
      },
      // 预览参数
      preview: {
        open: false,
        title: "代码预览",
        data: {},
        activeName: "controller.go",
      },
      guide_edit: false,
    };
  },
  created() {
    this.getList();
    this.getDicts("sys_is_create").then((response) => {
      this.is_createOptions = response.data.values || [];
    });

    this.getDicts("gen_form_type").then((response) => {
      this.form_typeOptions = response.data.values || [];
    });
    setTimeout(() => {
      var fromguide = localStorage.getItem("fromguide");
      localStorage.removeItem("fromguide");
      if (fromguide === "1") {
        this.openImportTable();
      } else if (fromguide === "2" && this.tableList.length > 0) {
        this.handleEditTable(this.tableList[0]);
      } else if (fromguide === "3" && this.tableList.length > 0) {
        this.handleGenTable(this.tableList[0]);
      } else if (fromguide === "4" && this.tableList.length > 0) {
        this.handleGenCreateMenu(this.tableList[0]);
      }
    }, 500);
  },
  activated() {
    const time = this.$route.query.t;
    if (time != null && time != this.uniqueId) {
      this.uniqueId = time;
      this.resetQuery();
    }
  },
  methods: {
 
    dogenmenu() {
      if (this.selnode == null) {
        this.msgError("请选择要菜单类型为目录的上级!");
        return false;
      }

      if (this.selnode.menu_type != "0") {
        this.msgError("请选择要菜单类型为目录的上级!");
        return;
      }

      this.loading = true;
      var data = {
        gen_option: JSON.stringify({
          tableid: "" + this.seltid,
          pid: "" + this.selnode.id,
        }),
      };

      return genMenu(data)
        .then(() => {
          this.loading = false;
          this.getList();
          this.loading = false;
          this.msgSuccess("操作成功");
          this.createmenuopen = false;
        })
        .catch((e) => {
          console.log("catch", e);
          this.loading = false;
        });
    },
    selectmenunode(node, instanceId) {
      this.selnode = node;
      //      console.log(node, instanceId)
      //      this.selnode=null
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }

      return {
        id: node.id,
        label: node.title + (node.menu_type == "0" ? "-[菜单类型:目录]" : ""),
        children: node.children,
      };
    },
    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },
    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.table_id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },

    /** 查询表集合 */
    getList() {
      this.loading = true;
      listTable(this.addDateRange(this.queryParams, this.dateRange)).then(
        (response) => {
          this.single = true;
          this.multiple = true;
          this.tableList = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        }
      );
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    handleGenCreateMenu(row) {
      const tid = row.id;
      this.createmenuopen = true;
      listMenu().then((response) => {
        this.menuOptions = [];
        const menu = { id: 0, title: "主类目", children: [] };
        menu.children = this.handleTree(response.data.list, "id", "pid");
        this.menuOptions.push(menu);
      });
      this.seltid = tid;
    },
    cancel_createmenuopen() {
      this.createmenuopen = false;
    },
    cancel_createcodeopen() {
      this.createcodeopen = false;
    },
    /** 生成代码操作 */
    handleGenTable(row) {
      this.rowid = row.id;
      this.createcodeopen = true;
      /*
      const tableIds = row.id;
      if (tableIds == "") {
        this.msgError("请选择要生成的数据");
        return;
      }

      this.$confirm('是否确认生成代码?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(()=> {
        this.loading = true;
        var gendata={
          table_name:row.table_name,
          model_name:row.table_name,
          gen_option:JSON.stringify({tableid: ""+row.id})
        }
        console.log(gendata)
        return genCode(gendata)

      }).then(() => {
        this.loading = false;
        this.getList();

      this.msgSuccess("操作成功");
    }).catch((e) => {
        console.log("catch",e)
        this.loading = false;
      });

*/
    },
    doconfirmgencode() {
      var gen_option = {};
      gen_option["tableid"] = "" + this.rowid;
      gen_option["genselbtns"] = this.genselbtns.join("/");
      gen_option["sw_addsysfield"] = this.sw_addsysfield ? "1" : "0";
      gen_option["sw_vueonly"] = this.sw_vueonly ? "1" : "0";

      this.$confirm("是否确认生成代码?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          var gendata = {
            table_name: "",
            model_name: "",
            gen_option: JSON.stringify(gen_option),
          };
          console.log(gendata);
          this.createcodeopen = false;
          return genCode(gendata);
        })
        .then(() => {
          this.loading = false;
          setTimeout(() => {
            this.getList();
          }, 300);

          this.createcodeopen = false;
          this.msgSuccess("操作成功");
        })
        .catch((e) => {
          console.log("catch", e);
          this.loading = false;
        });
    },
    /** 打开导入表弹窗 */
    openImportTable() {
      this.$refs.import.show();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },

    is_createFormat({ cellValue }) {
      return this.selectDictLabel(this.is_createOptions, "" + cellValue);
    },
    form_typeFormat({ cellValue }) {
      return this.selectDictLabel(this.form_typeOptions, "" + cellValue);
    },
    /** 预览按钮 */
    handlePreview(row) {
      previewTable(row.id).then((response) => {
        this.preview.data = response.data;
        this.preview.open = true;
      });
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.tableNames = selection.map((item) => item.table_name);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 修改按钮操作 */
    handleEditTable(row) {
      const tableId = row.id || this.ids[0];
      this.$router.push({
        path: "/gen/edittable",
        query: { tableId: tableId },
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const tableIds = row.id || this.ids;
      this.$confirm('是否确认删除表编号为"' + tableIds + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delTable(tableIds);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
  },
};
</script>
