import { render, staticRenderFns } from "./pdfrec.vue?vue&type=template&id=cfdee7c6&scoped=true&"
import script from "./pdfrec.vue?vue&type=script&lang=js&"
export * from "./pdfrec.vue?vue&type=script&lang=js&"
import style0 from "./pdfrec.vue?vue&type=style&index=0&id=cfdee7c6&prod&rel=stylesheet%2Fscss&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfdee7c6",
  null
  
)

export default component.exports