import request from '@/utils/request'
// 生成时间:2021-11-13 10:32:32

// 查询indexs列表
export function listIndexs(query) {
    var data = {
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
        page_size: query.pageSize,
        page: query.pageNum
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr") {
            filterstr[k] = item;
        }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/growthindicators/gyxy/indexs/list',
        method: 'post',
        data: data
    })
}

// 查询indexs详细
export function getIndexs(id) {
    return request({
        url: '/growthindicators/gyxy/indexs/get?id=' + id,
        method: 'get'
    })
}


// 新增indexs
export function addIndexs(data) {
    return request({
        url: '/growthindicators/gyxy/indexs/add',
        method: 'post',
        data: data
    })
}

// 修改indexs
export function editIndexs(data) {
    return request({
        url: '/growthindicators/gyxy/indexs/edit',
        method: 'post',
        data: data
    })
}

// 删除indexs
export function delIndexs(ids) {
    return request({
        url: '/growthindicators/gyxy/indexs/del',
        method: 'post',
        data: { ids: ids }
    })
}

// 删除indexs查询模板
export function advquerydelIndexs(ids) {
    return request({
        url: '/growthindicators/gyxy/indexs/advquerydel',
        method: 'post',
        data: { ids: ids }
    })
}

// 添加indexs查询模板
export function advquerysaveIndexs(data) {
    return request({
        url: '/growthindicators/gyxy/indexs/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表indexs查询模板列表
export function advquerylistIndexs(businessname) {
    return request({
        url: '/growthindicators/gyxy/indexs/advquerylist?businessname=' + businessname,
        method: 'get'
    })
}
// 导出indexs
export function exportIndexs(query, exportfields, tableColumn) {
    var data = {
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr") {
            filterstr[k] = item;
        }
    }
    var tarexportfieldslist = {};
    for (var k in tableColumn) {
        var tlbf = tableColumn[k];
        for (var i in exportfields) {
            var ef = exportfields[i]
            if (tlbf.field != "" && tlbf.field && tlbf.field != null && tlbf.field == ef) {
                tarexportfieldslist.push({ "filed": ef, "title": tlbf.title });
                break;
            }
        }
    }

    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    data["exportfields"] = JSON.stringify(tarexportfieldslist);
    return request({
        url: '/growthindicators/gyxy/indexs/export',
        method: 'post',
        data: data
    })
}

//保存学院指标
export function savecollegeindexs(fields) {
    return request({
        url: '/growthindicators/gyxy/indexs/savecollegeindexs',
        method: 'post',
        data: fields
    })
}

//获取本学院指标
export function indexscollegelist() {
    return request({
        url: '/growthindicators/gyxy/indexs/collegelist',
        method: 'post'
    })
}

export function sendIndexs(indexid,step){
    return request({
        url: '/growthindicators/gyxy/indexs/sendindexs',
        method: 'post',
        data:{index_id:indexid,step:step}
    })
}