import request from '@/utils/request'

// 添加规则
export function addIotRule(data) {
    return request({
      url: '/iot/rule/add',
      method: 'post',
      data: data
    })
}

// 获取规则
export function getIotRule(id) {
    return request({
      url: '/iot/rule/get?id=' + id,
      method: 'get'
    })
}

// 更新规则
export function updateIotRule(data) {
    return request({
      url: '/iot/rule/update',
      method: 'post',
      data: data
    })
  }
  
// 删除规则
export function delIotRule(id) {
    return request({
      url: '/iot/rule/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 规则列表
export function listIotRule(query) {
  return request({
    url: '/iot/rule/list',
    method: 'get',
    params: query
  })
}