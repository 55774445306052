<template>
  <div class="container" style="position: relative; width: 100%; height: 100%; background-color: rgb(10, 48, 87); display: flex; flex-direction: column; align-items: center; overflow: auto;">
    <div style="background-color: rgb(19, 93, 166); padding: 10px 0px; width: 250px; text-align: center; color: rgb(201, 245, 252); font-size: 22px; margin-top: 20px;">{{showDetail || '个人信息详情'}}</div>
    <div style="width: 100%; padding: 0px 50px 0px 50px; display: flex; flex: 1;">
      <div v-if="!showDetail" style="width: 100%">
        <el-row class="row">
          <el-col :span="16">
            <el-row>
              <el-col :span="8">
                <div class="cell">
                  <div class="label">姓名：</div>
                  <div class="value">{{data.xm}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="cell">
                  <div class="label">性别：</div>
                  <div class="value">{{data.xb}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="cell">
                  <div class="label">学历：</div>
                  <div class="value">{{data.xueli}}</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <div class="cell">
              <div class="label">出生年月：</div>
              <div class="value">{{data.csny}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="8">
            <div class="cell">
              <div class="label">进驻县（市、区）：</div>
              <div class="value">{{data.xian}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cell">
              <div class="label">进驻乡 （镇、街道）：</div>
              <div class="value">{{data.xiang}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cell">
              <div class="label">进驻村（社区）：</div>
              <div class="value">{{data.cun}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="8">
            <div class="cell">
              <div class="label">选派层级：</div>
              <div class="value">{{data.xpaicj}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cell">
              <div class="label">工作队职务：</div>
              <div class="value">{{data.gzdzw}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cell">
              <div class="label">是否中共党员：</div>
              <div class="value">{{data.zzmm}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="8">
            <div class="cell">
              <div class="label">工作计划：</div>
              <div class="detail-btn" @click="handleShowDetail('工作计划')">点击查看</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cell">
              <div class="label">完成情况及成效：</div>
              <div class="detail-btn" @click="handleShowDetail('完成情况及成效')">点击查看</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cell">
              <div class="label"></div>
              <div class="value"></div>
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="8">
            <div class="cell">
              <div class="label">获奖情况：</div>
              <div class="detail-btn" @click="handleShowDetail('获奖情况')">点击查看</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cell">
              <div class="label">处分情况：</div>
              <div class="detail-btn" @click="handleShowDetail('处分情况')">点击查看</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cell">
              <div class="label">晋升提拔情况：</div>
              <div class="detail-btn" @click="handleShowDetail('晋升提拔情况')">点击查看</div>
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
            <div class="cell">
              <div class="label">加分分数：</div>
              <div class="score-add">+0</div>
              <div class="content-box"></div>
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
            <div class="cell">
              <div class="label">扣分分数：</div>
              <div class="score-dec">-0</div>
              <div class="content-box"></div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-else-if="showDetail == '工作计划'" class="detail">
        {{data.zcbfndjh}}
      </div>
      <div v-else-if="showDetail == '完成情况及成效'" class="detail">
        {{data.wcqkjcx}}
      </div>
      <div v-else-if="showDetail == '获奖情况'" class="detail detail-table">
        <el-table
          :data="tableData"
          v-loading="loading"
          element-loading-text="正在加载"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0)"
          style="width: 100%; background-color: rgb(10, 48, 87);"
          :header-cell-style="{backgroundColor: 'rgb(19, 93, 166)', color: 'rgb(45, 200, 250)', fontSize: '16px', fontWeight: 'bold', borderColor: 'rgb(45, 200, 250)'}"
          :stripe="false"
        >
          <el-table-column
            prop="jxmc"
            label="奖项名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="pjdw"
            label="评奖单位"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="pjsj"
            label="获奖时间"
            align="center"
            >
          </el-table-column>
          <div slot="empty"><div v-if="!loading" style="color: rgb(45, 200, 250)">暂无数据</div></div>
        </el-table>
      </div>
      <div v-else-if="showDetail == '处分情况'" class="detail detail-table">
        <el-table
          :data="tableData"
          v-loading="loading"
          element-loading-text="正在加载"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0)"
          style="width: 100%; background-color: rgb(10, 48, 87);"
          :header-cell-style="{backgroundColor: 'rgb(19, 93, 166)', color: 'rgb(45, 200, 250)', fontSize: '16px', fontWeight: 'bold', borderColor: 'rgb(45, 200, 250)'}"
          :stripe="false"
        >
          <el-table-column
            prop="cfmc"
            label="处分名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="cfdw"
            label="处分单位"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="cfsj"
            label="处分时间"
            align="center"
            >
          </el-table-column>
          <div slot="empty"><div v-if="!loading" style="color: rgb(45, 200, 250)">暂无数据</div></div>
        </el-table>
      </div>
      <div v-else-if="showDetail == '晋升提拔情况'" class="detail detail-table">
        <el-table
          :data="tableData"
          v-loading="loading"
          element-loading-text="正在加载"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0)"
          style="width: 100%; background-color: rgb(10, 48, 87);"
          :header-cell-style="{backgroundColor: 'rgb(19, 93, 166)', color: 'rgb(45, 200, 250)', fontSize: '16px', fontWeight: 'bold', borderColor: 'rgb(45, 200, 250)'}"
          :stripe="false"
        >
          <el-table-column
            prop="lx"
            label="类型"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="sj"
            label="时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="xpqzj"
            label="选派前职级（职称）情况"
            align="center"
            >
          </el-table-column>
          <el-table-column
            prop="xphzj"
            label="选派后职级（职称）情况"
            align="center"
            >
          </el-table-column>
          <div slot="empty"><div v-if="!loading" style="color: rgb(45, 200, 250)">暂无数据</div></div>
        </el-table>
      </div>
    </div>
    <div v-if="showDetail" class="close-button" @click="handleBack">返回</div>
    <div v-else class="close-button" @click="handleClose">关闭</div>
  </div>
</template>

<script>
import { execModel } from "@/api/core/data/datasource/model";
import Qs from 'qs'
export default {
  name: 'zcbfgr',
  props: {
    params: {
      type: String,
      default: () => { return undefined }
    },
  },
  data() {
    return {
      data: {},
      showDetail: '',
      tableData: [],
      loading: false
    }
  },
  created() {
    const loading = this.$loading({
      lock: true,
      text: '正在加载',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0)'
    });
    let id = 174
    const params = Qs.parse(this.params || '')
    params['__max_count__'] = 1000
    execModel(id, JSON.stringify(params)).then(response => {
      console.log(response)
      if (response.data.length > 0) {
        this.data = response.data[0]
      }
      loading.close()
    }).catch(e => {
      loading.close()
    })
  },
  methods: {
    handleShowDetail(detail) {
      this.showDetail = detail
      this.tableData = []
      if (detail == '获奖情况') {
        this.loading = true
        let id = 153
        const params = Qs.parse(this.params || '')
        params['__max_count__'] = 1000
        execModel(id, JSON.stringify(params)).then(response => {
          console.log(response)
          this.tableData = response.data
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      } else if (detail == '处分情况') {
        this.loading = true
        let id = 154
        const params = Qs.parse(this.params || '')
        params['__max_count__'] = 1000
        execModel(id, JSON.stringify(params)).then(response => {
          console.log(response)
          this.tableData = response.data
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      } else if (detail == '晋升提拔情况') {
        this.loading = true
        let id = 155
        const params = Qs.parse(this.params || '')
        params['__max_count__'] = 1000
        execModel(id, JSON.stringify(params)).then(response => {
          console.log(response)
          this.tableData = response.data
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      }
    },
    handleBack() {
      this.showDetail = ''
    },
    handleClose() {
      window.postMessage({
        type: 'bi_dialog_close'
      })
    }
  }
}
</script>

<style scoped>
.container::-webkit-scrollbar {
  width: 0px;
}
.close-button {
  position: absolute; 
  right: 10px; 
  top: 10px; 
  color: rgb(45, 200, 250);
}
.close-button:hover {
  cursor: pointer;
}
.row {
  margin-top: 40px;
}
.cell {
  display: flex;
  align-items: center;
  width: 100%;
}
.label {
  font-size: 20px;
  font-weight: bold;
  color: rgb(30, 201, 250);
}
.value {
  font-size: 20px;
  font-weight: bold;
  color: rgb(255, 255, 255);
}
.detail-btn {
  padding: 5px 8px;
  border-radius: 3px;
  background-color: rgb(47, 201, 250);
  color: #fff;
  font-size: 16px;
}
.detail-btn:hover {
  cursor: pointer;
}
.score-add {
  font-size: 30px;
  color: rgb(12, 252, 48);
}
.score-dec {
  font-size: 30px;
  color: rgb(252, 12, 39);
}
.content-box {
  background-color: rgb(255, 255, 255);
  width: 65%;
  height: 100px;
  margin-left: 50px;
  border-radius: 10px;
}
.detail {
  margin-top: 30px;
  font-size: 18px;
  color: #fff;
  line-height: 25px;
}
.detail-table {
  flex: 1; 
  display: flex; 
  flex-direction: column; 
  align-items: center;
}
</style>
<style scoped>
::v-deep .el-table tr {
    background-color: rgb(10, 48, 87);
    color: rgb(45, 200, 250);
    font-size: 16px;
    border: none;
}
::v-deep .el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
}
::v-deep .el-table--enable-row-hover .el-table__body tr > td {
  border: none;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(255, 255, 255, 0.01) !important;
}
::v-deep .detail-table .el-table__body-wrapper::-webkit-scrollbar {
  width:8px;
  background-color: rgba(10, 48, 87);
}
::v-deep .detail-table .el-table__body-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:8px;
}
::v-deep .detail-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius:8px;
  background:rgba(255,255,255,0.1);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
::v-deep .detail-table .el-table__body-wrapper::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(255,255,255,0.1);
}
::v-deep .detail-table .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background:rgba(255,255,255,0.2);
}
::v-deep .detail-table .el-table__header .has-gutter th.gutter {
  background-color: rgb(19, 93, 166);
  border-bottom: solid 1px rgb(45, 200, 250);
}
</style>