var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.designer.isTopic &&
        _vm.selectedWidget.options.scoringMethod &&
        _vm.selectedWidget.options.scoringMethod * 1 < 3 )?_c('div',[_c('el-form-item',{attrs:{"label":_vm.i18nt('designer.setting.answer')}},[(
                (_vm.selectedWidget.type === 'radio' ||_vm.selectedWidget.type === 'select' || _vm.selectedWidget.type === 'radio-judge' )&&
                _vm.selectedWidget.options.scoringMethod * 1 === 1
            )?_c('el-select',{style:({ width: '100%' }),attrs:{"placeholder":"请选择"},model:{value:(_vm.optionModel.answer),callback:function ($$v) {_vm.$set(_vm.optionModel, "answer", $$v)},expression:"optionModel.answer"}},_vm._l((_vm.optionItems),function(item){return _c('el-option',{key:item.label,attrs:{"label":item.label,"value":item.value}},[_c('span',[_vm._v(" "+_vm._s(item.label))])])}),1):_vm._e(),(
                _vm.selectedWidget.type === 'checkbox' &&
                _vm.selectedWidget.options.scoringMethod * 1 === 2
            )?_c('el-checkbox-group',{on:{"change":_vm.checkboxChange},model:{value:(_vm.optionModel.answer),callback:function ($$v) {_vm.$set(_vm.optionModel, "answer", $$v)},expression:"optionModel.answer"}},_vm._l((_vm.optionItems),function(item){return _c('el-checkbox',{key:item.value ? String(item.value) : item.value,attrs:{"label":item.value ? String(item.value) : item.value}},[_vm._v(_vm._s(item.label))])}),1):_vm._e(),(
                _vm.selectedWidget.options.scoringMethod * 1 === 2 &&
                (_vm.selectedWidget.type === 'input' ||
                    _vm.selectedWidget.type === 'textarea')
            )?_c('el-input',{attrs:{"type":"textarea","placeholder":"多个答案每行一个"},model:{value:(_vm.optionModel.answer),callback:function ($$v) {_vm.$set(_vm.optionModel, "answer", $$v)},expression:"optionModel.answer"}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }