<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
            <!--<el-form-item label="用户id" prop="admin_id">
			<el-input
				v-model="queryParams.admin_id"
				placeholder="请输入用户id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="课程" prop="course_id">
			<el-input
				v-model="queryParams.course_id"
				placeholder="请输入课程"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
            <!-- <el-form-item label="名称" prop="course_name">
                <el-input
                    v-model="queryParams.course_name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="分类" prop="category_id">
                <gbpx-course-category-select
                    v-model="queryParams.category_id"
                    placeholder="请选择分类"
                    valueField="id"
                    size="small"
                    :is-user="true"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            border
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons> -->
                <!--<grid-select
        ref="selectCourseGrid"
        :multiple="true"
        placeholder="请选择课程"
        width="960px"
        :label="
          (form.course && form.course.name) || ''
        "
        :searchItems="[
          { field: 'name', title: '名称' },
          {
            field: 'category_id',
            title: '分类',
            ctype: 'gbpx-course-category',
          },
          //{ field: 'teacher_id', title: '教师', ctype: 'gbpx-teacher' },
        ]"
        :gridItems="[
          { field: 'name', title: '名称' },
          { field: 'category_name', title: '分类' },
          //{ field: 'teacher_name', title: '教师' },
          { field: 'remark', title: '简介' },
        ]"
        :getDataList="listGbpxCourseU"
        @change="handleSelectCourse"
      >
        <div></div>
      </grid-select>
      <el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAddCourse"
				v-hasPermi="[
					'/gbpx/gbpxcourseunit/add',
				]"
				>新增</el-button
			>-->
                <!--<el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAdd"
				v-hasPermi="[
					'/gbpx/gbpxcourseunit/add',
				]"
				>新增</el-button
			>

			<el-button
				type="success"
				icon="el-icon-edit"
				size="mini"
				:disabled="single"
				@click="handleUpdate"
				v-hasPermi="[
					'/gbpx/gbpxcourseunit/get',
					'/gbpx/gbpxcourseunit/edit',
				]"
				>修改</el-button
			>-->

                <!-- <el-button
                    style="margin-left: 10px"
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxcourseunit/del']"
                    >删除</el-button
                >
            </template>

            <template #defaultopr="{ row }"> -->
                <!--<el-button
				size="mini"
				type="text"
				icon="el-icon-edit"
				@click.stop="handleUpdate(row)"
				>修改
			</el-button>-->
                <!-- <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="query_qarams_item">
                <el-form-item label="分类" prop="category_id">
                    <gbpx-course-category-select
                        v-model="queryParams.category_id"
                        placeholder="请选择分类"
                        valueField="id"
                        size="small"
                        :is-user="true"
                    />
                </el-form-item>
            </template>
            <template slot="toolbar_btn_left">
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxcourseunit/del']"
                    >删除</el-button
                >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="用户id" prop="admin_id">
                            <el-input-number
                                v-model="form.admin_id"
                                placeholder="请输入用户id"
                                controls-position="right"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="课程" prop="course_id">
                            <el-input-number
                                v-model="form.course_id"
                                placeholder="请输入课程"
                                controls-position="right"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listGbpxCourseUnit,
    getGbpxCourseUnit,
    addGbpxCourseUnit,
    AddBatchGbpxCourseUnit,
    editGbpxCourseUnit,
    delGbpxCourseUnit,
} from "@/api/gbpx/gbpx_course_unit";
import { listGbpxCourseU } from "@/api/gbpx/gbpx_course";
export default {
    name: "gbpx-gbpxcourseunit-list",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                //{ field: "id", title: "id", minWidth: 120, fixed: "left" },
                //{ field: "admin_id", title: "用户id", minWidth: 120 },
                {
                    field: "course_name",
                    title: "名称",
                    minWidth: 120,
                    is_query: true,
                },
                { field: "teacher.name", title: "主讲人", minWidth: 120 },
                { field: "course.category_name", title: "分类", minWidth: 120 },
                { field: "course.remark", title: "简介", minWidth: 120 },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                //     field: "",
                //     title: "操作",
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                admin_id: undefined,
                course_id: undefined,
                course_name: undefined,
                category_id: undefined,
            },
            form: {},
            rules: {
                admin_id: [
                    {
                        required: true,
                        message: "用户id不能为空",
                        trigger: "blur",
                    },
                ],
                course_id: [
                    {
                        required: true,
                        message: "课程不能为空",
                        trigger: "blur",
                    },
                ],
            },
            listGbpxCourseU: listGbpxCourseU,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listGbpxCourseUnit(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.form = {
                admin_id: undefined,
                course_id: undefined,
            };
            this.resetForm("form");
        },

        handleQuery(e) {
            if (e) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleAddCourse() {
            this.$refs.selectCourseGrid.open();
        },

        handleSelectCourse(e1, e2) {
            console.log(e1, e2);
            AddBatchGbpxCourseUnit(e1).then((response) => {
                if (response.code === 0) {
                    this.msgSuccess("添加成功");
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                }
            });
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getGbpxCourseUnit(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editGbpxCourseUnit(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addGbpxCourseUnit(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm("确定删除吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delGbpxCourseUnit(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
    },
};
</script>
