import request from '@/utils/request'

export function listGbpxStudent(query) {
  return request({
    url: '/gbpx/gbpxstudent/list',
    method: 'get',
    params: query
  })
}

export function getGbpxStudent(id) {
  return request({
    url: '/gbpx/gbpxstudent/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxStudent(data) {
  return request({
    url: '/gbpx/gbpxstudent/add',
    method: 'post',
    data: data
  })
}

export function editGbpxStudent(data) {
  return request({
    url: '/gbpx/gbpxstudent/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxStudent(ids) {
  return request({
    url: '/gbpx/gbpxstudent/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function syncAccount(query) {
  return request({
    url: '/gbpx/gbpxstudent/syncAccount',
    method: 'get',
    params: query
  })
}
