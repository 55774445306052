<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="设备名称" prop="username">
        <el-input
          v-model="queryParams.username"
          placeholder="请输入设备名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>      
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row/>

    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="tempclientList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>   
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">        
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>   
  </div>
</template>

<script>
import { listIotTempclient, getIotTempclient,delIotTempclient} from "@/api/iot/tempclient";

export default {
  name: "IotTempclient",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: 'client_id', title: '设备编号' },
        {field: 'username', title: '用户名' },
        {field: 'password', title: '用户密码' },
        {field: 'version', title: '版本' },
        {field: 'remote_addr', title: '地址' },
        {field: 'connected_at', title: '连接时间' ,formatter:this.ConnectFormat},      
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 分类表格数据
      tempclientList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        username: undefined
      },
      // 表单参数
      form: {}      
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },

    /** 查询分类列表 */
    getList() {
      this.loading = true;
      listIotTempclient(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;
        this.tempclientList= response.data.list;
        this.total = response.data.total;
        this.loading = false;        
      }).catch(function (error){
        console.log(error);
      });
      
    },    

    ConnectFormat({row}){
      return this.timestampToTime(row.connected_at);
    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },       
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除用户编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotTempclient(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    }
  }
};
</script>
