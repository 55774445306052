import request from '@/utils/request'

// 添加模型
export function addIotMould(data) {
    return request({
      url: '/iot/mould/add',
      method: 'post',
      data: data
    })
}

// 获取模型
export function getIotMould(id) {
    return request({
      url: '/iot/mould/get?id=' + id,
      method: 'get'
    })
}

// 更新模型
export function updateIotMould(data) {
    return request({
      url: '/iot/mould/update',
      method: 'post',
      data: data
    })
  }
  
// 删除模型
export function delIotMould(id) {
    return request({
      url: '/iot/mould/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 模型列表
export function listIotMould(query) {
  return request({
    url: '/iot/mould/list',
    method: 'get',
    params: query
  })
}