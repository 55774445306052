<template>
    <div>
        <el-form-item :label="i18nt('designer.setting.tableColumnName')">
            <el-button class="" type="primary" @click="dialogVisible = true"
                >设置</el-button
            >
        </el-form-item>
        <el-dialog
            title="设置显示列"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"
            append-to-body
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="API" prop="columnApi">
                    <el-input
                        placeholder="请输入API"
                        v-model="form.columnApi"
                    ></el-input>
                </el-form-item>
                <el-form-item label="请求参数">
                    <el-input
                        placeholder="请求参数"
                        v-model="form.columnParams"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="i18nt('designer.setting.apiMethodName')">
                    <el-select
                        v-model="form.columnApiMethod"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>列表</span>
                        <el-button
                            style="float: right; padding: 3px 0"
                            type="text"
                            @click="getInfo"
                            >获取</el-button
                        >
                    </div>
                    <div v-if="tableColumn.length">
                    </div>
                    <div v-else class="text-center" style="color: grey">
                        请输入API再点击获取按钮
                    </div>
                </el-card>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
  
  <script>
import i18n from "@/vform/utils/i18n";
import request from "@/utils/request";
import { ReFormatQueryStr } from "@/utils/formatquerystr";
import { getFieldWidgetByName, deepClone } from "@/vform/utils/util";
import { execModel } from "@/api/core/data/datasource/model";
export default {
    name: "table-column-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            dialogVisible: false,
            options: [
                { label: "GET", value: "get" },
                { label: "POST", value: "post" },
            ],
            tableColumn: [],
            form: {},
            rules: {
                columnApi: [
                    {
                        required: true,
                        message: "请输入API",
                        trigger: "blur",
                    },
                ],
            },
            //字典库数据
            DictsData: {},
            //数据源数据
            DsData: {},
            model_info: {},
            formfieldtype: {}, //表单字段类型
            org_optionItems: {},
            // 查询参数
            queryParams: {
                fieldval: {},
                sortinfo: null,
                pageNum: 1,
                pageSize: 10,
            },
          
        };
    },
    methods: {
        handleClose() {},
        getInfo() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    let apiData = {
                        url: this.form.columnApi,
                        method: this.form.columnApiMethod,
                    };
                    let obj = {};
                    let arr = this.form.columnParams
                        ? this.form.columnParams.split(";")
                        : [];
                    for (let i = 0; i < arr.length; i++) {
                        let arr1 = arr[i].split(":");
                        obj[arr1[0]] = arr1[1];
                    }
                    if (this.form.apiMethod === "get") {
                        apiData["params"] = obj;
                    } else {
                        // var data = {
                        //     mid: 89,
                        //     page: this.queryParams.page,
                        //     psize:  this.queryParams.psize,
                        //     qstr: {},
                        // };
                        // var tjson = ReFormatQueryStr(this.queryParams);
                        // data.qstr = JSON.stringify(tjson);
                        // apiData["data"] = data;
                    }
                    request(apiData).then((res) => {
                        if (res.code == 0) {
                            this.getTableColumn(res.data);
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getTableColumn(data) {
            let field_for_list = data.field_for_list;
            let field_for_query = data.field_for_query;
            this.model_info = data.forminfo;
            var tableColumn = [];
            var oprstrar = {};
            var rules = {};
            var DictsTypes = new Set();
            var DsT = new Set();
            var Formresource = JSON.parse(this.model_info.Formresource);
            //处理字典库 数据源 原始选项数据
            for (let dict in Formresource.FieldWidgets) {
                let onefield = Formresource.FieldWidgets[dict].field.options;
                this.formfieldtype[onefield.name] = {
                    type: Formresource.FieldWidgets[dict].type,
                    multiple: onefield.multiple || false,
                }; //记录表单字段类型

                if (
                    onefield["dicttype"] != null &&
                    onefield["dicttype"] != ""
                ) {
                    DictsTypes.add(onefield["dicttype"]);
                }

                if (onefield["dsConf_dstid"] != null) {
                    if (onefield["dsConf_dstid"] != "") {
                        DsT.add(onefield["dsConf_dstid"]);
                    }
                }

                if (onefield["optionItems"] != null) {
                    this.org_optionItems[onefield.name] = deepClone(
                        onefield["optionItems"]
                    );
                }
            }

            if (Formresource.showpkfield) {
                tableColumn.push({
                    field: "id",
                    title: "ID",
                    sortBy: "desc",
                    sortable: true,
                    width: 60,
                    fixed: "left",
                });
            }
            //处理查询表单
            var queryfields = [];

            for (var j in field_for_query) {
                let colq = deepClone(field_for_query[j].fieldinfo.field);
                colq["options"]["required"] = false;

                queryfields.push(colq);
                var defaultValue = colq.options.defaultValue || "";

                if (
                    defaultValue &&
                    typeof defaultValue == "string" &&
                    defaultValue.indexOf("${") > -1
                ) {
                    delete colq.options.defaultValue;
                }

                if (colq.options["querytype"] != null) {
                    this.queryParams.querytype =
                        this.queryParams.querytype || {};
                    this.queryParams.querytype[colq["options"]["name"]] =
                        colq.options["querytype"];
                    if (defaultValue) {
                        this.queryParams.fieldval[colq["options"]["name"]] =
                            defaultValue;
                    }
                }
            }
            // this.oldQueryParams = JSON.stringify(this.queryParams);
            // var queryformData = this.reformatQueryForm(
            //     queryfields,
            //     Formresource.formConfig
            // );
            // this.queryformDataorgstr = JSON.stringify(queryformData);

            this.istree =
                Formresource.listconf && Formresource.listconf.liststyle == "2";

            for (let k in Formresource.FieldWidgets) {
                let onef = Formresource.FieldWidgets[k]["field"]["options"];
                if (
                    onef["istreeparent"] != null &&
                    onef["istreeparent"] == true &&
                    this.istree
                ) {
                    this.treeConfig["rowField"] = "id";
                    this.treeConfig["parentField"] = onef["name"];
                    break;
                }
            }

            for (var i in field_for_list) {
                let col = field_for_list[i].fieldinfo.field;

                let defcol = {
                    field: col.options.name,
                    title: col.options.label,
                    sortable: col.options.showsortbtn,
                    minWidth: 100,
                    treeNode: col.options.istreelabel && this.istree,
                    form_type: col.type === "date" ? 3 : "",
                };
                if (defcol["treeNode"] == true) {
                    this.treeConfig["treeNode"] = defcol.field;
                }
                if (
                    (col.options.dicttype != null &&
                        col.options.dicttype != "") ||
                    col.options["optionItems"] != null
                ) {
                    let dt = col.options.dicttype;

                    let dfieldname = col.options["name"];

                    defcol.slots = {
                        default: "default" + dfieldname,
                    };
                    defcol.slotsFun = {
                        funName: "NewdictFormat",
                        key: dfieldname,
                        dt: dt,
                    };
                    //判断是否有数据源，没有数据源则取默认参数
                    if (dt) {
                        defcol.dictType = dt;
                    } else if (
                        col.options.dsEnabled &&
                        col.options.dsConf_dstid
                    ) {
                        defcol.dsEnabled = col.options.dsEnabled;
                        defcol.dsConf_dstid = col.options.dsConf_dstid;
                        defcol.dsConf_dst_label = col.options.dsConf_dst_label;
                        defcol.dsConf_dst_value = col.options.dsConf_dst_value;
                    } else {
                        defcol.options = this.org_optionItems[dfieldname];
                        defcol.options_value = "value";
                        defcol.options_label = "label";
                        defcol.form_type = 2;
                    }
                }

                if (
                    col.type === "dep-select" ||
                    col.type === "personnel-select"
                ) {
                    defcol.formatter = function ({ cellValue, row, column }) {
                        let selectObj =
                            cellValue && typeof cellValue === "string"
                                ? JSON.parse(cellValue)
                                : {};

                        if (col.type === "dep-select") {
                            defcol.form_type = 4;
                            {
                                return selectObj.dep
                                    ? selectObj.dep
                                          .map((o) => o.deptName)
                                          .join("、")
                                    : "";
                            }
                        }
                        {
                            if (col.type === "personnel-select") {
                                defcol.form_type = 5;
                                return selectObj.user
                                    ? selectObj.user
                                          .map((o) => o.nickName)
                                          .join("、")
                                    : "";
                            }
                        }

                        //return '<div class="el-badge item">'+row.title+'<sup class="el-badge__content is-dot"></sup></div>'
                    };
                }
                if (col.type === "date-range") {
                    defcol.form_type = 3;
                    let that = this;
                    // defcol.slots = {
                    //     default: function (e) {
                    //         return that.dateRangeFormat(e);
                    //     },
                    // };
                }
                tableColumn.push(defcol);
            }

            this.tableColumn = tableColumn;
            console.log(this.tableColumn, " this.tableColumn");
            Formresource = null;
            for (let dstid of DsT.keys()) {
                execModel(dstid, JSON.stringify({ __max_count__: 100 })).then(
                    (res) => {
                        if (res.data && res.data.length > 0) {
                            this.DsData["ds" + dstid] = res.data;
                        }
                    }
                );
            }
            //批量加载字典库数据

            for (let item of DictsTypes.keys()) {
                this.getDicts(item).then((response) => {
                    let dictvalue = response.data.values || [];
                    this.DictsData[item] = dictvalue.map((it) => {
                        return {
                            label: it.value,
                            value: it.key,
                            label_color: it.label_color,
                        };
                    });
                });
            }
        },
    },
};
</script>
  
  <style scoped>
</style>