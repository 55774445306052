import request from '@/utils/request'

// 查询充值日志列表
export function list(userpool, query) {
  return request({
    url: '/ucenter/' + userpool + '/recharge/list',
    method: 'get',
    params: query
  })
}
