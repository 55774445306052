import request from '@/utils/request'

export function listGbpxClassUnit(query) {
  return request({
    url: '/gbpx/gbpxclassunit/list',
    method: 'get',
    params: query
  })
}

export function getGbpxClassUnit(id) {
  return request({
    url: '/gbpx/gbpxclassunit/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxClassUnit(data) {
  return request({
    url: '/gbpx/gbpxclassunit/add',
    method: 'post',
    data: data
  })
}

export function AddBatchGbpxClassUnit(ids) {
  return request({
    url: '/gbpx/gbpxclassunit/addBatch',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function editGbpxClassUnit(data) {
  return request({
    url: '/gbpx/gbpxclassunit/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxClassUnit(ids) {
  return request({
    url: '/gbpx/gbpxclassunit/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

