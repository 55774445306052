<template>
    <div>
        <el-card class="box-card">
            <div slot="header" class="clearfix d-flex align-items-center">
                表单信息
                <div class="mx-5">——</div>
                <div v-if="!is_edit">{{ tablename }}</div>

                <el-input
                    style="width: 300px"
                    v-else
                    placeholder="请输入内容"
                    v-model="newTableNmae"
                >
                    <el-button slot="append" @click="editTableName"
                        >确定</el-button
                    >
                </el-input>
                <i
                    :class="!is_edit ? 'el-icon-edit' : 'el-icon-error'"
                    style="font-size: 18px; margin-left: 10px"
                    :style="{ color: !is_edit ? '#409eff' : '#F56C6C' }"
                    @click="is_edit = !is_edit"
                ></i>
                <!-- <div> <el-button type="text" icon="el-icon-edit"></el-button></div> -->
            </div>
            <jf-table
                ref="jftable"
                :loading="loading"
                :columns="tableColumn"
                :data="dataList"
                :checkbox="false"
                :pager="false"
                :operate="false"
                :height="680"
                :align="'center'"
            >
                <!-- :operate="false" -->
                <template slot="toolbar_btn_row" slot-scope="{ row }">
                </template>
                <template slot="primaryKey" slot-scope="{ row }">
                    <i
                        v-if="row.is_default"
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i>
                    <i
                        v-else
                        class="iconfont"
                        :class="
                            row.primaryKey
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>
                <template slot="insert" slot-scope="{ row, rowIndex }">
                    <i
                        v-if="row.is_default"
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i>
                    <i
                        v-else
                        class="iconfont"
                        @click="toTableEdit('新增', rowIndex)"
                        :class="
                            row.options &&
                            row.options.showpos &&
                            row.options.showpos.indexOf('新增') > -1
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>
                <template slot="update" slot-scope="{ row, rowIndex }">
                    <!-- <i
                        v-if="row.is_default"
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i> -->
                    <i
                      
                        class="iconfont"
                        @click="toTableEdit('修改', rowIndex)"
                        :class="
                            row.options &&
                            row.options.showpos &&
                            row.options.showpos.indexOf('修改') > -1
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>
                <!-- <template slot="update" slot-scope="{ row }">
                <i class="iconfont " :class="row.update ?'icon-checkbox-checked-fill text-primary':' icon-square'"></i>
            </template> -->
                <template slot="table" slot-scope="{ row, rowIndex }">
                    <!-- <i
                        v-if="row.is_default"
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i> -->
                    <!-- v-else -->
                    <i
                   
                        class="iconfont"
                        @click="toTableEdit('列表', rowIndex)"
                        :class="
                            row.options &&
                            row.options.showpos &&
                            row.options.showpos.indexOf('列表') > -1
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>
                <template slot="query" slot-scope="{ row, rowIndex }">
                    <i
                        v-if="row.is_default"
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i>
                    <i
                        v-else
                        class="iconfont"
                        @click="toTableEdit('查询', rowIndex)"
                        :class="
                            row.options &&
                            row.options.showpos &&
                            row.options.showpos.indexOf('查询') > -1
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>
                <template slot="label" slot-scope="{ row }">
                    <vxe-input
                        v-model="row.options.label"
                        :disabled="row.is_default"
                    ></vxe-input>
                </template>
                <template slot="fieldType" slot-scope="{ row }">
                    <vxe-input
                        v-model="row.options.fieldType"
                        :disabled="row.is_default"
                    ></vxe-input>
                </template>
                <template slot="requied" slot-scope="{ row, rowIndex }">
                    <i
                        v-if="row.is_default"
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i>
                    <i
                        v-else
                        class="iconfont"
                        @click="toTableEdit('requied', rowIndex)"
                        :class="
                            row.options.required
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>
                <template slot="import" slot-scope="{ row, rowIndex }">
                    <i
                        v-if="row.is_default"
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i>
                    <i
                        v-else
                        class="iconfont"
                        @click="toTableEdit('导入', rowIndex)"
                        :class="
                            row.options &&
                            row.options.showpos &&
                            row.options.showpos.indexOf('导入') > -1
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>
                <template slot="export" slot-scope="{ row, rowIndex }">
                    <i
                        v-if="row.is_default"
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i>
                    <i
                        v-else
                        class="iconfont"
                        @click="toTableEdit('导出', rowIndex)"
                        :class="
                            row.options &&
                            row.options.showpos &&
                            row.options.showpos.indexOf('导出') > -1
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>
                <template slot="sort" slot-scope="{ row, rowIndex }">
                    <i
                        v-if="row.is_default"
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i>
                    <i
                        v-else
                        class="iconfont"
                        @click="toTableEdit('showsortbtn', rowIndex)"
                        :class="
                            row.options.showsortbtn
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>
                <template slot="validation" slot-scope="{ row, rowIndex }">
                    <!-- <vxe-select
                     
                        type="text"
                        transfer
                      
                    >
                        <vxe-option
                            v-for="item in fieldValidators"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label"
                        ></vxe-option>
                    </vxe-select> -->
                    <el-select
                        v-if="row.options.validation"
                        :disabled="row.is_default"
                        v-model="row.options.validation"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in fieldValidators"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label"
                        >
                        </el-option>
                    </el-select>
                </template>
                <template slot="querytype" slot-scope="{ row, rowIndex }">
                    <!-- <vxe-select
                        v-model="row.options.querytype"
                        type="text"
                        transfer
                        
                    >
                        <vxe-option
                            v-for="item in querytype_list"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value"
                        ></vxe-option>
                    </vxe-select> -->
                    <el-select
                        v-model="row.options.querytype"
                        placeholder="请选择"
                        :disabled="row.is_default"
                    >
                        <el-option
                            v-for="item in querytype_list"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
                <template slot="dicttype" slot-scope="{ row, rowIndex }">
                    <el-select
                        v-model="row.options.dicttype"
                        v-if="row.options.dicttype"
                        placeholder="请选择"
                        :disabled="row.is_default"
                    >
                        <el-option
                            v-for="item in dicttypelist"
                            :key="item.type"
                            :value="item.type"
                            :label="item.name"
                        >
                        </el-option>
                    </el-select>
                </template>
                <template
                    slot="isShow"
                    slot-scope="{ row, rowIndex, columnIndex }"
                >
                    <i
                        v-if="row.is_default"
                        class="iconfont"
                        @click="
                            isReturnChange(row, rowIndex, columnIndex, 'isShow')
                        "
                        :class="
                            row.isShow
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                    <i
                        v-else
                        class="iconfont icon-square"
                        style="color: #d3d3d3"
                    ></i>
                </template>
                <template
                    slot="isReturn"
                    slot-scope="{ row, rowIndex, columnIndex }"
                >
                    <i
                        class="iconfont"
                        @click="
                            isReturnChange(
                                row,
                                rowIndex,
                                columnIndex,
                                'isReturn'
                            )
                        "
                        :class="
                            row.isReturn
                                ? 'icon-checkbox-checked-fill text-primary'
                                : ' icon-square'
                        "
                    ></i>
                </template>

                <template slot="toolbar_btn_row" slot-scope="{ row }">
                    <!-- <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleUpdate(row)"
                        >修改
                    </el-button> -->

                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        :disabled="row.is_default"
                        @click="handleDelete(row)"
                        >删除
                    </el-button>
                </template>
            </jf-table>
        </el-card>
        <!-- <el-card class="box-card" style="margin-top: 20px">
            <div slot="header" class="clearfix">
                <span>系统默认字段</span>
            </div>
            <div>
                <jf-table
                    ref="jftable"
                    :loading="loading"
                    :columns="tableColumn2"
                    :data="dataList2"
                    :operate="false"
                    :checkbox="false"
                    :pager="false"
                    :align="'center'"
                >
                </jf-table>
            </div>
        </el-card>
        <el-card class="box-card" style="margin-top: 20px">
            <div slot="header" class="clearfix">
                <span>接口返回字段</span>
            </div>
            <div style="width: 40%">
                <jf-table
                    ref="jftable"
                    :loading="loading"
                    :columns="tableColumn3"
                    :data="dataList3"
                    :operate="false"
                    :checkbox="false"
                    :pager="false"
                    :align="'center'"
                >
                    <template
                        slot="isQuery"
                        slot-scope="{ row, rowIndex, columnIndex }"
                    >
                        <i
                            class="iconfont"
                            @click="isReturnChange(row, rowIndex, columnIndex)"
                            :class="
                                row.isReturn
                                    ? 'icon-checkbox-checked-fill text-primary'
                                    : ' icon-square'
                            "
                        ></i>
                    </template>
                </jf-table>
            </div>
        </el-card> -->
    </div>
</template>
<script>
//   import i18n from "@/vform/utils/i18n"
export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        dicttypelist: {
            type: Array,
            default: () => [],
        },
        tablename: {
            type: String,
            default: "",
        },
        resourceData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,

            fieldValidators: [
                {
                    value: "number",
                    label: "数字",
                },
                {
                    value: "letter",
                    label: "字母",
                },
                {
                    value: "letterAndNumber",
                    label: "数字字母",
                },
                {
                    value: "mobilePhone",
                    label: "手机号码",
                },
                {
                    value: "email",
                    label: "邮箱",
                },
                {
                    value: "url",
                    label: "网址",
                },
                {
                    value: "noChinese",
                    label: "非中文字符",
                },
                {
                    value: "chinese",
                    label: "仅中文字符",
                },
            ],
            tableColumn: [
                { type: "seq", width: 60 },
                // {
                //     field: "name",
                //     title: "Name",
                //     editRender: {},
                //     slots: { edit: "name_edit" },
                // },
                {
                    title: "字段",
                    children: [
                        {
                            field: "options.name",
                            title: "列名",
                            minWidth: 100,
                        },
                        {
                            field: "options.label",
                            title: "列说明",
                            minWidth: 100,
                            slots: {
                                default: "label",
                            },
                        },
                        {
                            field: "type",
                            title: "控件类型",
                            minWidth: 100,
                        },
                    ],
                },
                {
                    title: "列表",
                    children: [
                        {
                            field: "primaryKey",
                            title: "主键",
                            minWidth: 80,
                            slots: {
                                default: "primaryKey",
                            },
                        },
                        {
                            field: "options.sort",
                            title: "显示排序",
                            minWidth: 80,
                            slots: {
                                default: "sort",
                            },
                        },
                        {
                            field: "insert",
                            title: "新增",
                            minWidth: 80,
                            slots: {
                                default: "insert",
                            },
                        },
                        {
                            field: "update",
                            title: "修改",
                            minWidth: 80,
                            slots: {
                                default: "update",
                            },
                        },
                        {
                            field: "table",
                            title: "列表",
                            minWidth: 80,
                            slots: {
                                default: "table",
                            },
                        },
                        {
                            field: "query",
                            title: "查询",
                            minWidth: 80,
                            slots: {
                                default: "query",
                            },
                        },
                        {
                            field: "options.querytype",
                            title: "查询方式",
                            minWidth: 100,
                            slots: {
                                default: "querytype",
                            },
                        },
                    ],
                },
                {
                    title: "表单",
                    children: [
                        // {
                        //     field: "edit",
                        //     title: "编辑",
                        //     slots: {
                        //         default: "edit",
                        //     },
                        // },
                        {
                            field: "options.requied",
                            title: "必填",
                            minWidth: 80,
                            slots: {
                                default: "requied",
                            },
                        },
                        {
                            field: "options.fieldType",
                            title: "字段类型",
                            minWidth: 100,
                            slots: {
                                default: "fieldType",
                            },
                        },
                        {
                            field: "options.dicttype",
                            title: "字典类型",
                            minWidth: 100,
                            slots: {
                                default: "dicttype",
                            },
                        },
                        {
                            field: "options.validation",
                            title: "字段验证",
                            minWidth: 100,
                            slots: {
                                default: "validation",
                            },
                        },
                        {
                            field: "import",
                            title: "导入",
                            minWidth: 80,
                            slots: {
                                default: "import",
                            },
                        },
                        {
                            field: "export",
                            title: "导出",
                            minWidth: 80,
                            slots: {
                                default: "export",
                            },
                        },
                    ],
                },
                // {
                //     field: "options.isShow",
                //     title: "是否显示",
                //     minWidth: 80,
                //     slots: {
                //         default: "isShow",
                //     },
                // },
                {
                    field: "options.isReturn",
                    title: "是否返回",
                    minWidth: 80,
                    slots: {
                        default: "isReturn",
                    },
                },
            ],
            dataList: [],
            querytype_list: [
                {
                    value: "=",
                    label: "精确匹配",
                },
                {
                    value: "like",
                    label: "模糊查询",
                },
                {
                    value: "in",
                    label: "在列表内",
                },
                {
                    value: "between",
                    label: "范围",
                },
            ],
            tableColumn2: [
                {
                    field: "fmodel_id",
                    title: "表单模型ID",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            // let myStyle = {
                            //     "iconfont icon-checkbox-checked-fill text-primary":
                            //         row.show,
                            //     "iconfont icon-square": !row.show,
                            //     "mr-5": true,
                            // };

                            // return [
                            //     <div>
                            //         <i
                            //             onClick={() =>
                            //                 this.defaultFieldChange(row)
                            //             }
                            //             class={myStyle}
                            //         ></i>
                            //         {row.fmodel_id}
                            //     </div>,
                            // ];
                            // }
                            return this.getDefaultField(
                                row,
                                "fmodel_id",
                                columnIndex,
                                "表单模型ID"
                            );
                        },
                    },
                },
                {
                    field: "dept_id",
                    title: "部门ID",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            return this.getDefaultField(
                                row,
                                "dept_id",
                                columnIndex,
                                "部门ID"
                            );
                        },
                    },
                },
                {
                    field: "dept_name",
                    title: "部门名称",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            return this.getDefaultField(
                                row,
                                "dept_name",
                                columnIndex,
                                "部门名称"
                            );
                        },
                    },
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            return this.getDefaultField(
                                row,
                                "create_time",
                                columnIndex,
                                "创建时间"
                            );
                        },
                    },
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            return this.getDefaultField(
                                row,
                                "update_time",
                                columnIndex,
                                "更新时间"
                            );
                        },
                    },
                },
                {
                    field: "delete_time",
                    title: "删除时间",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            return this.getDefaultField(
                                row,
                                "delete_time",
                                columnIndex,
                                "删除时间"
                            );
                        },
                    },
                },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            return this.getDefaultField(
                                row,
                                "create_by",
                                columnIndex,
                                "创建者"
                            );
                        },
                    },
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            return this.getDefaultField(
                                row,
                                "update_by",
                                columnIndex,
                                "更新者"
                            );
                        },
                    },
                },
                {
                    field: "extinfo",
                    title: "额外配置",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            return this.getDefaultField(
                                row,
                                "extinfo",
                                columnIndex,
                                "额外配置"
                            );
                        },
                    },
                },
                {
                    field: "spdid",
                    title: "分库字段",
                    minWidth: 125,
                    slots: {
                        default: ({ row, columnIndex }) => {
                            return this.getDefaultField(
                                row,
                                "spdid",
                                columnIndex,
                                "分库字段"
                            );
                        },
                    },
                },
            ],
            dataList3: [
                { name: "fmodel_id", label: "表单模型ID" ,options:{showpos:[]},is_select:true},
                { name: "dept_id", label: "部门名称" ,options:{showpos:[]},is_select:true},
                { name: "dept_name", label: "创建时间" ,options:{showpos:[]},is_select:true},
                { name: "create_time", label: "创建时间",options:{showpos:[]},is_select:true },
                { name: "update_time", label: "更新时间",options:{showpos:[]},is_select:true },
                { name: "delete_time", label: "删除时间" ,options:{showpos:[]},is_select:true},
                { name: "create_by", label: "创建者" ,options:{showpos:[]},is_select:true},
                { name: "update_by", label: "更新者",options:{showpos:[]} ,is_select:true},
                { name: "extinfo", label: "额外配置",options:{showpos:[]},is_select:true },
                { name: "spdid", label: "分库字段" ,options:{showpos:[]},is_select:true},
            ],
            dataList2: [
            {
                    options: { name: "ID", label: "id",showpos:[] },
                    is_default: true,
                    is_select:true,
                    name: "id", label: "主键"
                },
                {
                    options: { name: "fmodel_id", label: "表单模型ID" ,showpos:[]},
                    is_select:true,
                    is_default: true,
                    name: "fmodel_id",
                    label: "表单模型ID"
                },
                {
                    options: { name: "dept_id", label: "表单模型ID,部门ID" ,showpos:[]},
                    is_default: true,
                    is_select:true,
                    name: "dept_id", label: "表单模型ID,部门ID" 
                },
                {
                    options: { name: "dept_name", label: "部门名称",showpos:[] },
                    is_default: true,
                    is_select:true,name: "dept_name", label: "部门名称"
                },
                {
                    options: { name: "create_time", label: "创建时间",showpos:[] },
                    is_default: true,
                    is_select:true,name: "create_time", label: "创建时间"
                },
                {
                    options: { name: "update_time", label: "更新时间",showpos:[] },
                    is_default: true,
                    is_select:true,
                    name: "update_time", label: "更新时间"
                },
                {
                    options: { name: "delete_time", label: "删除时间" ,showpos:[]},
                    is_default: true,
                    is_select:true,
                    name: "update_time", label: "更新时间"
                },
                {
                    options: { name: "create_by", label: "创建者",showpos:[] },
                    is_default: true,
                    is_select:true,
                    name: "update_time", label: "更新时间"
                },
                {
                    options: { name: "update_by", label: "更新者" ,showpos:[]},
                    is_default: true,
                    is_select:true,
                    name: "update_time", label: "更新时间"
                },
                {
                    options: { name: "extinfo", label: "额外配置" ,showpos:[]},
                    is_default: true,
                    is_select:true,
                    name: "update_time", label: "更新时间"
                },
                {
                    options: { name: "spdid", label: "分库字段",showpos:[] },
                    is_default: true,
                    is_select:true,
                    name: "update_time", label: "更新时间"
                },

            ],
            tableColumn3: [
                {
                    field: "name",
                    title: "列名",
                    
                    // minWidth: 125,
                },
                {
                    field: "isReturn",
                    title: "是否返回",
                    // minWidth: 125,
                    slots: {
                        default: "isReturn",
                    },
                },
            ],

            newTableNmae: "",
            is_edit: false,
            defaultFiled: [],
            // gridOptions2: {
            //     border: false,
            //     showOverflow: false,
            //     height: 400,
            //     showHeader: false,
            //     columns: [
            //         {
            //             field: "name",
            //             title: "列名",
            //             minWidth: 125,
            //         },
            //         {
            //             field: "dept_id",
            //             title: "列表查询",
            //             minWidth: 125,
            //             slots: {
            //                 default: "isQuery",
            //             },
            //         },
            //     ],
            //     data: [],
            // },
        };
    },
    watch: {
        data: {
            handler(val) {
                this.dataList = val.concat(this.dataList2);
                if (
                    this.resourceData.formData &&
                    this.resourceData.formData.listconf &&
                    this.resourceData.formData.listconf
                ) {
                    this.dataList.forEach((o) => {
                        if (
                            this.resourceData.formData.listconf.dbselectfield &&
                            this.resourceData.formData.listconf.dbselectfield.indexOf(
                                o.options.name
                            ) > -1
                        ) {
                            o.isReturn = true;
                        }
                        if (
                            this.resourceData.formData.listconf.sysfieldshow &&
                            this.resourceData.formData.listconf.sysfieldshow.indexOf(
                                o.options.name
                            ) > -1
                        ) {
                            o.isShow = true;
                        }
                    });
                }
            },
            deep: true,
            immediate: true,
        },
        tablename: {
            handler(val) {
                this.newTableNmae = val;
            },
            deep: true,
            immediate: true,
        },
        resourceData: {
            handler(val) {
                if (
                    val.formData &&
                    val.formData.listconf &&
                    val.formData.listconf.sysfieldshow
                ) {
                    this.defaultFiled = val.formData.listconf.sysfieldshow;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        isReturnChange(row, rowIndex, columnIndex, field) {
         
            row[field] = !row[field];
            this.$set(this.dataList, rowIndex, row);
        },

        // 反转函数
        // reverseTable2(columns, list) {
        //     const buildData = columns.map((column) => {
        //         const item = { col0: column.title };
        //         list.forEach((row, index) => {
        //             item[`col${index + 1}`] = row[column.field];
        //         });
        //         return item;
        //     });
        //     const buildColumns = [
        //         {
        //             field: "col0",
        //             fixed: "left",
        //             width: 80,
        //         },
        //     ];
        //     list.forEach((item, index) => {
        //         buildColumns.push({
        //             field: `col${index + 1}`,
        //             minWidth: 120,
        //         });
        //     });
        //     this.gridOptions2.data = buildData;
        //     this.gridOptions2.columns = buildColumns;
        // },
        // getDefaultField(row, filed, columnIndex, label) {
        //     let index = this.defaultFiled.findIndex((o) => o.name == filed);
        //     let myStyle = {
        //         "iconfont icon-checkbox-checked-fill text-primary": index > -1,
        //         "iconfont icon-square": index === -1,
        //         "mr-5": true,
        //     };

        //     return [
        //         <div>
        //             <i
        //                 onClick={() =>
        //                     this.defaultFieldChange(
        //                         row,
        //                         filed,
        //                         columnIndex,
        //                         label
        //                     )
        //                 }
        //                 class={myStyle}
        //             ></i>
        //             {row[filed]}
        //         </div>,
        //     ];
        // },
        // defaultFieldChange(row, filed, columnIndex, label) {
        //     let index = this.defaultFiled.findIndex((o) => o.name == filed);
        //     if (index > -1) {
        //         this.defaultFiled.splice(index, 1);
        //     } else {
        //         this.defaultFiled.push({ name: filed, label: label });
        //     }
        // },
        editTableName() {
            if (this.is_edit) {
                this.$emit("change", this.newTableNmae);
                this.is_edit = false;
            }
        },
        toTableEdit(row, index) {
            let obj =  index<this.data.length?this.data[index] :this.dataList[index];
           
            if (row === "requied" || row === "showsortbtn") {
                obj.options[row] = !obj.options[row];
            } else {
                
                if (obj.options.showpos.indexOf(row) > -1) {
                    obj.options.showpos = obj.options.showpos.filter(
                        (o) => o !== row
                    );
                } else {
                    obj.options.showpos.push(row);
                }
            }

            this.$set(index<this.data.length?this.data:this.dataList[index], index, obj);

            // row.options.required = !row.options.required
        },
        handleUpdate() {
            const $grid = this.$jftable.$refs.xGrid;
            $grid.setActiveRow(row);
        },
        handleDelete(row) {
            // this.dataList= this.dataList.filter(o=>o.id!==row.id)

            // this.getWidgetRef(row.id)
            this.$emit("handleDelete", row);
        },
    },
};
</script>
<style lang="scss" scoped>
.text-primary {
    color: #1890ff;
}
</style>