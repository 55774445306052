<template>
  <div class="app-container" ref="container">
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
      class="tabs"
    >
      <el-tab-pane name="first">
        <span slot="label"><i class="el-icon-user"></i> 账号密码审计</span>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          label-width="68px"
          label-position="left"
        >
          <el-form-item label="类型：" prop="type">
            <el-radio-group
              v-model="queryParams.type"
              size="small"
              @input="handleQuery"
            >
              <el-radio-button label="">全部问题</el-radio-button>
              <el-radio-button label="1">未修改初始密码</el-radio-button>
              <el-radio-button label="2">使用简单密码</el-radio-button>
              <el-tooltip
                effect="dark"
                placement="bottom"
                content="定期未修改密码的账号 30 天内不被审查"
              >
                <el-radio-button label="3"
                  ><i class="el-icon-question"></i
                  >定期未修改密码</el-radio-button
                >
              </el-tooltip>
              <!-- light -->
              <el-tooltip
                effect="dark"
                placement="bottom"
                content="长期未登录的账号 30 天内不被审查"
              >
                <el-radio-button label="4"
                  ><i class="el-icon-question"></i>长期未登录</el-radio-button
                >
              </el-tooltip>
              <el-radio-button label="5">未设置密保</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="账号：" prop="username">
            <el-input
              v-model="queryParams.username"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="昵称：" prop="nickname">
            <el-input
              v-model="queryParams.nickname"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="机构：" prop="dept_id">
            <el-input
              v-model="deptName"
              clearable
              size="small"
              @focus="orginFocus"
              @keyup.enter.native="handleQuery"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="orginFocus"
              ></el-button>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
        <jf-table
          :loading="loading"
          :checkbox="false"
          :operate="false"
          :columns="tableColumn"
          :data="dataDableList"
          :toolbar-config="tableToolbar"
          :query-params="queryParams"
          @page-change="handlePageChange"
          :total="total"
        >
          <template slot="defaultstatus" slot-scope="{ row }">
            {{ statusFormat(row) }}
          </template>
          <template slot="defaultstype" slot-scope="{ row }">
            {{ typeFormat(row) }}
          </template>
        </jf-table>
      </el-tab-pane>

      <el-tab-pane name="second">
        <span slot="label"><i class="el-icon-user"></i> 菜单权限审计</span>

        <el-row>
          <div style="display: flex">
            <!-- 左侧 列表 -->
            <div :class="[secondDrawer ? 'left' : 'left1']">
              <div class="second">
                <div class="second-title">
                  <div></div>
                  <div @click="onExpandSecondTree" style="margin-right: 10px">
                    <i
                      v-if="expandSecondTreeAll"
                      class="el-icon-arrow-down"
                    ></i>
                    <i v-else class="el-icon-arrow-up"></i>
                  </div>
                </div>
                <el-tree
                  v-loading="treeSecondLoading"
                  class="second-tree"
                  highlight-current
                  :data="secondTreeData"
                  :props="secondDefaultProps"
                  ref="secondTree"
                  node-key="id"
                  :expand-on-click-node="false"
                  @node-click="nodeSecondTreeClick"
                >
                </el-tree>
              </div>
            </div>
            <!-- 折叠展开图片-->
            <div
              style="cursor: pointer; width: 2%; height: 100%; margin: auto"
              :class="[secondDrawer ? 'imgright' : 'imgright1']"
              @click="secondClick"
            >
              <div
                v-show="!secondDrawer"
                style="
                  height: 50px;
                  line-height: 50px;
                  background: #f5f5f5;
                  text-align: center;
                  border-radius: 0 5px 5px 0;
                "
              >
                <i class="el-icon-caret-right"></i>
              </div>
              <div
                v-show="secondDrawer"
                style="
                  height: 50px;
                  line-height: 50px;
                  background: #f5f5f5;
                  text-align: center;
                  border-radius: 5px 0 0 5px;
                "
              >
                <i class="el-icon-caret-left"></i>
              </div>
            </div>
            <!-- 右侧 用户列表 -->
            <div :class="[secondDrawer ? 'right' : 'right1']">
              <div style="background: #ffffff; padding-left: 15px">
                <el-form
                  :model="querySecondForm"
                  ref="querySecondForm"
                  :inline="true"
                  label-width="68px"
                  label-position="left"
                >
                  <el-form-item label="账号：" prop="username">
                    <el-input
                      v-model="querySecondForm.username"
                      clearable
                      size="small"
                      @keyup.enter.native="handleSecondQuery"
                    />
                  </el-form-item>
                  <el-form-item label="昵称：" prop="nickname">
                    <el-input
                      v-model="querySecondForm.nickname"
                      clearable
                      size="small"
                      @keyup.enter.native="handleSecondQuery"
                    />
                  </el-form-item>
                  <el-form-item label="机构：" prop="dept_id">
                    <el-input
                      v-model="deptName"
                      clearable
                      size="small"
                      @focus="orginFocus"
                      @keyup.enter.native="handleSecondQuery"
                    >
                      <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="orginFocus"
                      ></el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      size="mini"
                      @click="handleSecondQuery"
                      >搜索</el-button
                    >
                    <el-button
                      icon="el-icon-refresh"
                      size="mini"
                      @click="resetSecondQuery"
                      >重置</el-button
                    >
                  </el-form-item>
                </el-form>
                <el-table
                  v-loading="secondLoading"
                  row-key="id"
                  :data="secondTableList"
                  stripe
                  highlight-current-row
                  tooltip-effect="dark"
                  border
                  style="width: 100%;max-height: 450px;"
                >
                  <el-table-column type="index" width="50"> </el-table-column>
                  <el-table-column
                    prop="username"
                    label="登录账号"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="nickname"
                    label="用户昵称"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="dept.name"
                    label="归属机构"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="create_time"
                    label="创建时间"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                    :formatter="dateUpdateFormat"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="update_time"
                    label="更新时间"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                    :formatter="dateUpdateFormat"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="status"
                    label="状态"
                    width="80"
                    align="center"
                    show-overflow-tooltip
                    :formatter="statusFormat"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="type"
                    label="类型"
                    width="80"
                    align="center"
                    show-overflow-tooltip
                    :formatter="typeFormat"
                  >
                  </el-table-column>
                </el-table>
                <el-pagination
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="querySecondForm.pageNum"
                  :page-sizes="[10, 15, 20, 50, 100]"
                  :page-size="querySecondForm.pageSize"
                  layout="sizes, prev, pager, next, jumper"
                  :total="secondTotal"
                  :pager-count="5"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </el-row>
      </el-tab-pane>

      <el-tab-pane name="third">
        <span slot="label"><i class="el-icon-user"></i> 用户权限审计</span>

        <el-row>
          <div style="display: flex">
            <!-- 左侧 列表 -->
            <div :class="[thirdDrawer ? 'thirdLeft' : 'thirdLeft1']">
              <div style="padding-right: 15px">
                <el-form
                  :model="querySecondForm"
                  ref="queryThirdForm"
                  :inline="true"
                  label-width="68px"
                  label-position="left"
                >
                  <el-form-item label="账号：" prop="username">
                    <el-input
                      v-model="querySecondForm.username"
                      clearable
                      size="small"
                      @keyup.enter.native="handleSecondQuery"
                    />
                  </el-form-item>
                  <el-form-item label="昵称：" prop="nickname">
                    <el-input
                      v-model="querySecondForm.nickname"
                      clearable
                      size="small"
                      @keyup.enter.native="handleSecondQuery"
                    />
                  </el-form-item>
                  <el-form-item label="机构：" prop="dept_id">
                    <el-input
                      v-model="deptName"
                      clearable
                      size="small"
                      @focus="orginFocus"
                      @keyup.enter.native="handleSecondQuery"
                    >
                      <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="orginFocus"
                      ></el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      size="mini"
                      @click="handleSecondQuery"
                      >搜索</el-button
                    >
                    <el-button
                      icon="el-icon-refresh"
                      size="mini"
                      @click="resetSecondQuery"
                      >重置</el-button
                    >
                  </el-form-item>
                </el-form>
                <el-table
                  v-loading="secondLoading"
                  row-key="id"
                  :data="secondTableList"
                  stripe
                  highlight-current-row
                  tooltip-effect="dark"
                  border
                  style="width: 100%;max-height: 450px;"
                  @row-click="thirdCelldblclick"
                >
                  <el-table-column type="index" width="50"> </el-table-column>
                  <el-table-column
                    prop="username"
                    label="登录账号"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="nickname"
                    label="用户昵称"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="dept.name"
                    label="归属机构"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="create_time"
                    label="创建时间"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                    :formatter="dateUpdateFormat"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="update_time"
                    label="更新时间"
                    min-width="120"
                    align="center"
                    show-overflow-tooltip
                    :formatter="dateUpdateFormat"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="status"
                    label="状态"
                    width="80"
                    align="center"
                    show-overflow-tooltip
                    :formatter="statusFormat"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="type"
                    label="类型"
                    width="80"
                    align="center"
                    show-overflow-tooltip
                    :formatter="typeFormat"
                  >
                  </el-table-column>
                </el-table>
                <el-pagination
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="querySecondForm.pageNum"
                  :page-sizes="[10, 15, 20, 50, 100]"
                  :page-size="querySecondForm.pageSize"
                  layout="sizes, prev, pager, next, jumper"
                  :total="secondTotal"
                  :pager-count="5"
                >
                </el-pagination>
              </div>
            </div>
            <!-- 折叠展开图片-->
            <div
              style="cursor: pointer; width: 2%; height: 100%; margin: auto"
              :class="[thirdDrawer ? 'imgright' : 'imgright1']"
              @click="thirdClick"
            >
              <div
                v-show="!thirdDrawer"
                style="
                  height: 50px;
                  line-height: 50px;
                  background: #f5f5f5;
                  text-align: center;
                  border-radius: 5px 0 0 5px;
                "
              >
                <i class="el-icon-caret-left"></i>
              </div>
              <div
                v-show="thirdDrawer"
                style="
                  height: 50px;
                  line-height: 50px;
                  background: #f5f5f5;
                  text-align: center;
                  border-radius: 0 5px 5px 0;
                "
              >
                <i class="el-icon-caret-right"></i>
              </div>
            </div>
            <!-- 右侧 用户列表 -->
            <div :class="[thirdDrawer ? 'thirdRight' : 'thirdRight1']">
              <div class="third">
                <div class="third-title">
                  <span>菜单权限</span>
                  <i class="el-icon-refresh" @click="getThirdTreelist('')"></i>
                </div>
                <div class="third-content">
                  <div class="third-content-title">
                    <div class="content-title-name">主导航菜单</div>
                    <div style="width: 60px">
                      <el-button
                        type="text"
                        :class="expandThirdAll ? '' : 'dialog-expand-no'"
                        @click="onThirdExpand(true)"
                        >展开</el-button
                      >
                      <span>/</span>
                      <el-button
                        type="text"
                        :class="expandThirdAll ? 'dialog-expand-no' : ''"
                        @click="onThirdExpand(false)"
                        >收缩</el-button
                      >
                    </div>
                  </div>
                  <el-tree
                    v-loading="treeThirdLoading"
                    class="third-tree"
                    :style="'height:' + (height - 150) + 'px'"
                    :data="thirdTreeData"
                    :props="secondDefaultProps"
                    ref="thirdTree"
                    highlight-current
                  >
                  </el-tree>
                  <!-- default-expand-all -->
                </div>
              </div>
            </div>
          </div>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <!-- 机构选择 -->
    <el-dialog
      :close-on-click-modal="false"
      title="机构选择"
      :visible.sync="open"
      width="600px"
      append-to-body
    >
      <div>
        <div class="dialog-search">
          <div style="width: 100px">关键字：</div>
          <el-input v-model="filterText"> </el-input>
          <!-- <el-button size="small" @click="queryDialog">搜索</el-button> -->
        </div>
        <div class="dialog-expand">
          <div>
            <el-button
              type="text"
              :class="expandAll ? '' : 'dialog-expand-no'"
              @click="onExpand(true)"
              >展开</el-button
            >
            <span>/</span>
            <el-button
              type="text"
              :class="expandAll ? 'dialog-expand-no' : ''"
              @click="onExpand(false)"
              >收缩</el-button
            >
          </div>
        </div>
        <el-tree
          ref="tree"
          node-key="id"
          class="dialog-tree"
          :data="treeData"
          :props="defaultProps"
          highlight-current
          :filter-node-method="filterNode"
          :default-expanded-keys="defaultExpanded"
          @node-click="nodeTreeClick"
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmDialog">确 定</el-button>
        <el-button @click="clearDialog">清 除</el-button>
        <el-button @click="open = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { list } from "@/api/core/monitor/securitylog";
import { listDept } from "@/api/core/system/dept";
import { listMenu } from "@/api/core/system/menu";
import { listUser } from "@/api/core/system/user";
import moment from "moment";
export default {
  name: "securityaudit",
  data() {
    return {
      // el-tabs
      activeName: "first",
      // 账号密码审计-查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: "",
        username: "",
        nickname: "",
        dept_id: "",
      },
      // 账号密码审计 -树配置
      defaultProps: {
        children: "children",
        label: "name",
      },
      // 账号密码审计 loading
      loading: false,
      // 账号密码审计 - 表格配置
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {},
      },
      //  账号密码审计 - 表格字段
      tableColumn: [
        {
          type: "seq",
          title: "序号",
          width: 60,
          align: "center",
        },
        {
          field: "username",
          title: "登录账号",
          align: "center",
          minWidth: 100,
        },
        {
          field: "nickname",
          title: "用户昵称",
          align: "center",
          minWidth: 100,
        },
        {
          field: "dept_name",
          title: "归属机构",
          align: "center",
          minWidth: 100,
        },
        {
          field: "content",
          title: "审计结果",
          align: "center",
          width: 300,
        },
        {
          field: "create_time",
          title: "创建时间",
          align: "center",
          formatter: "formatDate",
          width: 180,
        },
        {
          field: "modify_password_time",
          title: "密码修改时间",
          align: "center",
          formatter: "formatDate",
          width: 180,
        },
        {
          field: "last_login_time",
          title: "最后登录时间",
          align: "center",
          formatter: "formatDate",
          width: 180,
        },
        {
          field: "status",
          title: "状态",
          align: "center",
          width: 80,
          slots: {
            default: "defaultstatus",
          },
        },
        {
          field: "type",
          title: "类型",
          align: "center",
          width: 80,
          slots: {
            default: "defaultstype",
          },
        },
      ],
      // 账号密码审计 表格数据
      dataDableList: [],
      // 账号密码审计 total
      total: 0,
      // 机构弹窗 展开收缩
      expandAll: false,
      // 机构弹窗
      open: false,
      // 机构选择的数据
      treeNode: null,
      // 机构选择的名称
      deptName: "",
      // 机构选择-树查询
      filterText: "",
      // 机构选择-树数据
      treeData: [],
      // 默认展开的节点
      defaultExpanded: [],
      height: 600,
      // 菜单权限表格数据
      secondTreeData: [],
      // 菜单权限-树配置
      secondDefaultProps: {
        children: "children",
        label: "title",
      },
      // 菜单权限-树 展开收缩
      expandSecondTreeAll: false,
      // 菜单权限 Loading
      secondLoading: false,
      // 菜单权限-查询
      querySecondForm: {
        pageNum: 1,
        pageSize: 10,
        auth_rule_ids: "",
        username: "",
        nickname: "",
        dept_id: "",
      },
      // 菜单权限 表格数据
      secondTableList: [],
      // 菜单权限Total
      secondTotal: 0,
      // 状态数据字典
      statusDicts: [],
      // 类型数据字典
      typeDicts: [],
      // 用户权限 展开收缩
      expandThirdAll: false,
      // 用户 树数据
      thirdTreeData: [],
      // 菜单权限收缩展开
      secondDrawer: true,
      //用户收缩展开
      thirdDrawer: true,
      // 用户树动画
      treeThirdLoading: false,
      treeSecondLoading:false,
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.getList();
    // 状态
    this.getDicts("sys_normal_disable").then((res) => {
      this.statusDicts = res.data.values;
    });
    // 类型
    this.getDicts("sys_user_type").then((res) => {
      this.typeDicts = res.data.values;
    });
    this.getListDept();
  },
  mounted() {
    let height = this.$refs.container.offsetHeight;
    this.height = height - 85;
  },
  methods: {
    // tabs
    handleClick(tab) {
      this.treeNode = null;
      this.deptName = "";
      this.querySecondForm.pageSize = 10;
      this.querySecondForm.pageNum = 1;
      this.defaultExpanded = [];
      this.getListDept();
      if (tab.name == "second") {
        this.querySecondForm.dept_id = "";
        this.getTreelist();
        this.getUserlist();
      } else if (tab.name == "third") {
        this.querySecondForm.dept_id = "";
        this.getUserlist();
        this.getThirdTreelist();
      } else {
        this.queryParams.dept_id = "";
        this.getList();
      }
    },

    // 账号密码审计-获取表格数据
    getList() {
      this.loading = true;
      list(this.queryParams)
        .then((response) => {
          this.dataDableList = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 账号密码审计 查询
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    // 账号密码审计 重置
    resetQuery() {
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = 10;
      this.queryParams.type = "";
      this.queryParams.username = "";
      this.queryParams.nickname = "";
      this.queryParams.dept_id = "";
      this.deptName = "";
      this.resetForm("queryForm");
      this.handleQuery();
    },

    // 机构数据
    getListDept() {
      listDept().then((response) => {
        this.treeData = this.handleTree(response.data.list, "id", "parent_id");
      });
    },

    // 机构弹窗
    orginFocus() {
      this.filterText = "";
      // console.log(this.treeNode, "this.treeNode");
      if (this.treeNode != null) {
        // 默认展开节点
        this.defaultExpanded.push(this.treeNode.id);
        // 默认选中节点
        let key = [];
        key.push(this.treeNode.id);
        this.$refs.tree.setCheckedKeys(key);
      }
      this.open = true;
    },

    // 机构过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 机构树点击选择
    nodeTreeClick(data) {
      this.treeNode = data;
    },

    // 机构弹窗清除
    clearDialog() {
      this.treeNode = null;
      this.deptName = "";
      this.defaultExpanded = [];
      if (this.activeName == "second" || this.activeName == "third") {
        this.querySecondForm.dept_id = "";
      } else {
        this.queryParams.dept_id = "";
      }
      this.open = false;
      this.getListDept();
    },

    // 机构弹窗确定
    confirmDialog() {
      if (this.activeName == "second" || this.activeName == "third") {
        this.querySecondForm.dept_id = this.treeNode.id;
        this.getTreelist();
      } else {
        this.queryParams.dept_id = this.treeNode.id;
        this.getList();
      }
      this.deptName = this.treeNode.name;
      this.defaultExpanded = [];
      this.open = false;
    },

    // 机构弹窗 树 展开收缩
    onExpand(stu) {
      this.expandAll = stu;
      this.changeTreeNodeStatus(this.$refs.tree.store.root);
    },

    // 改变节点的状态
    changeTreeNodeStatus(node) {
      node.expanded = this.expandAll;
      for (let i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = this.expandAll;
        // 遍历子节点
        if (node.childNodes[i].childNodes.length > 0) {
          this.changeTreeNodeStatus(node.childNodes[i]);
        }
      }
    },
    // 账号密码审计 分页
    handlePageChange({ currentPage, pageSize }) {
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },

    // 菜单权限-获取树数据
    getTreelist() {
      this.treeSecondLoading=true;
      listMenu().then((response) => {
        this.secondTreeData = this.handleTree(response.data.list, "id", "pid");
        this.treeSecondLoading=false;
      }).catch(err=>{
        this.treeSecondLoading=false;
      })
    },

    // 菜单权限-获取表格数据
    getUserlist() {
      this.secondLoading = true;
      listUser(this.querySecondForm)
        .then((response) => {
          this.secondTableList = response.data.list;
          this.secondTotal = response.data.total;
          this.secondLoading = false;
        })
        .catch((err) => {
          this.secondLoading = false;
        });
    },

    // 字典状态字典翻译 - 状态
    statusFormat(row) {
      let find = this.statusDicts.find((v) => {
        return "" + v.key == "" + row.status;
      });
      if (find) {
        return find.value;
      } else {
        return "";
      }
    },

    // 字典状态字典翻译 - 类型
    typeFormat(row) {
      let findType = this.typeDicts.find((v) => {
        return "" + v.key == "" + row.type;
      });
      if (findType) {
        return findType.value;
      } else {
        return "";
      }
    },

    // 菜单权限-树形点击
    nodeSecondTreeClick(data) {
      this.querySecondForm.auth_rule_ids = data.id;
      this.getUserlist();
    },

    // 菜单权限-搜索
    handleSecondQuery() {
      this.querySecondForm.pageNum = 1;
      this.getUserlist();
    },

    // 菜单权限-重置
    resetSecondQuery() {
      this.querySecondForm.pageNum = 1;
      this.querySecondForm.pageSize = 10;
      this.querySecondForm.auth_rule_ids = "";
      this.querySecondForm.username = "";
      this.querySecondForm.nickname = "";
      this.querySecondForm.dept_id = "";
      this.deptName = "";
      this.resetForm("querySecondForm");
      this.handleSecondQuery();
    },

    // 菜单权限收缩展开
    secondClick() {
      this.secondDrawer = !this.secondDrawer;
    },

    // 用户收缩展开
    thirdClick() {
      this.thirdDrawer = !this.thirdDrawer;
    },

    // 菜单权限-树 展开收缩
    onExpandSecondTree() {
      this.expandSecondTreeAll = !this.expandSecondTreeAll;
      this.changeSecondTreeNodeStatus(this.$refs.secondTree.store.root);
    },

    // 改变节点的状态
    changeSecondTreeNodeStatus(node) {
      node.expanded = this.expandSecondTreeAll;
      for (let i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = this.expandSecondTreeAll;
        // 遍历子节点
        if (node.childNodes[i].childNodes.length > 0) {
          this.changeSecondTreeNodeStatus(node.childNodes[i]);
        }
      }
    },
    // 菜单权限审计分页
    secondHandlePageChange({ currentPage, pageSize }) {
      if (this.querySecondForm.pageSize == pageSize) {
        this.getUserlist();
      } else {
        this.querySecondForm.pageSize = pageSize;
        this.handleSecondQuery();
      }
    },

    // 用户权限展开收缩
    onThirdExpand(v) {
      this.expandThirdAll = v;
      this.changeThirdTreeNodeStatus(this.$refs.thirdTree.store.root);
    },

    // 改变节点的状态
    changeThirdTreeNodeStatus(node) {
      node.expanded = this.expandThirdAll;
      for (let i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = this.expandThirdAll;
        // 遍历子节点
        if (node.childNodes[i].childNodes.length > 0) {
          this.changeThirdTreeNodeStatus(node.childNodes[i]);
        }
      }
    },

    // 用户权限格式化时间
    dateUpdateFormat(row, column) {
      var date = row[column.property];
      if (date === undefined || date === 0) {
        return "（无）";
      }
      return moment(date * 1000).format("YYYY-MM-DD HH:mm:ss");
    },

    // 用户权限- 分页
    handleSizeChange(val) {
      this.querySecondForm.pageSize = val;
      this.getUserlist();
    },
    // 用户权限- 分页
    handleCurrentChange(val) {
      this.querySecondForm.pageNum = val;
      this.getUserlist();
    },

    // 用户权限-获取树数据
    getThirdTreelist(id) {
      this.treeThirdLoading = true;
      listMenu({ user_id: id }).then((response) => {
        this.thirdTreeData = this.handleTree(response.data.list, "id", "pid");
        this.treeThirdLoading = false;
      });
    },

    // 用户权限 表格点击
    thirdCelldblclick(e) {
      this.getThirdTreelist(e.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dialog-expand {
  display: flex;
  justify-content: end;
  align-items: center;
}
.dialog-expand-no {
  color: gray;
}
.dialog-tree {
  height: 300px;
  overflow-y: scroll;
}

.second {
  padding-right: 15px;
  .second-title {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 15px 15px 15px 0;
    margin-bottom: 20px;
  }
  .second-tree {
    height: 600px;
    overflow-y: scroll;
  }
}
.third {
  padding-left: 15px;
  .third-title {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 15px 15px 15px 0;
  }
  .third-content {
    background-color: #fafafa;
    margin: 10px;
    .third-content-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .content-title-name {
        font-size: 18px;
        font-weight: 700;
      }
    }
    .third-tree {
      height: 300px;
      overflow: auto;
      background-color: #fafafa;
    }
  }
}

.left {
  width: 20%;
  transition: all 0.2s;
}
.left1 {
  width: 0%;
  display: none;
  transition: all 0.2s;
}
.right {
  width: 80%;
  transition: all 0.2s;
}
.right1 {
  width: 95%;
  transition: all 0.2s;
}
.imgright {
  right: 0;
  transition: all 0.2s;
  // background-color: #f5f5f5;
}
.imgright1 {
  left: 0;
  background-color: #ffffff;
  transition: all 0.2s;
}

.thirdLeft {
  width: 60%;
  transition: all 0.2s;
}
.thirdLeft1 {
  width: 95%;
  transition: all 0.2s;
}
.thirdRight {
  width: 40%;
  transition: all 0.2s;
}
.thirdRight1 {
  width: 0%;
  display: none;
  transition: all 0.2s;
}
</style>
