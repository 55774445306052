import request from '@/utils/request'

// 查询 
export function listCmsfeedback(query) {
  return request({
    url: '/cms/feedback/list',
    method: 'get',
    params: query
  })
}

// 查询 详细
export function getCmsfeedback(id) {
  return request({
    url: '/cms/feedback/get?id=' + id,
    method: 'get'
  })
}

 

// 删除 
export function delCmsfeedback(id) {
  return request({
    url: '/cms/feedback/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
