const { mock } = require('mockjs')

var { studentAppointmentList, collegesList } = mock({
  "studentAppointmentList|100-157":[{
      'no|+1': 1,
      'number': '@integer(10000000000, 99999999999)',
      'name': '@cname(2,4)',
      'gender|1':['男','女'],
      'content': '@csentence(10,20)',
      'statusStr|1': ['已通过','待审批','待咨询','已取消'],
      'time': '@date(yyyy-MM-dd HH:mm:ss)',
      'academy': '@ctitle(2,4)学院',
      'major|1': ['财务管理','软件工程','电子商务','计算机科学与技术','信息安全与通信','土木工程']
  }],
  "collegesList|8":[{
    "name": '@ctitle(2,4)学院',
    "value": '@integer(10, 1000)'
  }]
})

//比较时间
function compare(property){
  return function(a,b){
      var value1 = a[property] && parseInt(a[property]);
      var value2 = b[property] && parseInt(b[property]);
      return value2 - value1;
  }
}

module.exports = [
    {
        url: '/studentAppointmentList',
        type: 'post',
        response(params) {
          let arr = []
          let { pageNum, pageSize } = params.body
            
          pageSize = pageSize>=0 ? pageSize : 7
          pageNum = pageNum>=0 ? pageNum : 1
          
          let total = studentAppointmentList.length
          let len = total / pageSize
          let totalPages = len - parseInt(len) > 0 ? parseInt(len) + 1 : len
          arr = total > 0 ? studentAppointmentList.slice((pageNum - 1) * pageSize, pageNum * pageSize) : []

          return {
            code: 200,
            msg: 'success',
            data: arr,
            total,
            totalPages
          }
        },
    },
    {
      url: '/addStuAppointment',
      type: 'post',
      response(params) {
        //let { number, name, gender, content, statusStr, time, academy, major } = params.body
        let data  = params.body
        data.academy = "计算机科学与信息技术学院"
        data.major = "软件工程"
        data.content = "这里是一些预约内容"
        data.time = new Date().toLocaleDateString() + '  ' + new Date().toLocaleTimeString()
        studentAppointmentList.splice(0, 0, data)
        return {
          code: 200,
          msg: 'success',
          data
        }
      }
    },
    {
      url: "/collegesList",
      type: 'post',
      response(params) {
        
        return {
          code: 200,
          msg: 'success',
          data: collegesList.sort(compare('value'))
        }
      }
    }
]