<template>
    <div>
        <el-form-item
            :label="i18nt('designer.setting.label')"
            v-if="!noLabelSetting"
        >
            <el-input type="text" v-model="optionModel.label"></el-input>
        </el-form-item>
        <el-form-item label="题目类型" v-if="designer.isTopic">
            <el-select v-model="type" @change="typeChange">
                <el-option
                    v-for="(item, index) in basicFields"
                    :key="index"
                    :label="i18nt('designer.widgetLabel.' + item.type)"
                    :value="item.type"
                ></el-option>
            </el-select>
        </el-form-item>
    </div>
</template>

<script>
import i18n from "@/vform/utils/i18n";
import { deepClone } from "@/vform/utils/util";
import { basicFields } from "@/vform/components/form-designer/widget-panel/widgetsConfig-answer";
export default {
    name: "label-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            basicFields,
            type: "",
        };
    },
    created() {
        console.log(this.basicFields, this.optionModel);
    },
    computed: {
        noLabelSetting() {
            return (
                this.selectedWidget.type === "static-text" ||
                this.selectedWidget.type === "html-text"
            );
            //|| (this.selectedWidget.type === 'divider')
        },
    },
    watch: {
        selectedWidget: {
            handler(val) {
                console.log(val);
                this.type = val.type;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        typeChange(e) {
            let val = deepClone(
                this.basicFields[
                    this.basicFields.findIndex((o) => o.type === e)
                ]
            );
            // this.designer.clearSelected()
            this.$nextTick(() => {
                let index = this.designer.widgetList.findIndex(
                    (o) => o.id === this.selectedWidget.id
                );
                if (
                    this.selectedWidget.options.label !==
                    this.i18nt(
                        "designer.widgetLabel." + this.selectedWidget.type
                    )
                ) {
                    val.options.label = this.selectedWidget.options.label;

                    // val.options.answerKey =
                }
                if (
                    this.selectedWidget.options.optionItems &&
                    val.options.optionItems
                ) {
                    val.options.optionItems = this.selectedWidget.options.optionItems;
                }
                val.options.answer = this.selectedWidget.options.answer;
                val.options.answerKey = this.selectedWidget.options.answerKey;
                val.options.scoring= this.selectedWidget.options.scoring;
                this.designer.addContainerByDbClick(val,index,'replace');
                
            });

            // this.designer.selectedWidget = this.basicFields[this.basicFields.findIndex(o=>o.type ===e)]
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
