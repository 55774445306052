import request from '@/utils/request'

export function listXssqHdtb(query) {
  return request({
    url: '/xssq/xssqhdtb/list',
    method: 'get',
    params: query
  })
}

export function getXssqHdtb(id) {
  return request({
    url: '/xssq/xssqhdtb/get?id=' + id,
    method: 'get'
  })
}

export function addXssqHdtb(data) {
  return request({
    url: '/xssq/xssqhdtb/add',
    method: 'post',
    data: data
  })
}

export function editXssqHdtb(data) {
  return request({
    url: '/xssq/xssqhdtb/edit',
    method: 'post',
    data: data
  })
}

export function delXssqHdtb(ids) {
  return request({
    url: '/xssq/xssqhdtb/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

