<!-- 
Codes Generated By VForm:
https://www.vform666.com
-->

<template>
  <div>
    <el-row :gutter="20" class="nopadding">
      <div style="margin-top: 10px"></div>

      <el-col
        :span="12"
        class="grid-cell nopadding"
        style="border: 1px solid #dcdfe6; height: 75vh"
      >
        <iframe
          id="outIframe"
          v-if="fileData.inputPdfUrl_shuqian"
          :src="
            '/pdf/web/viewer.html?file=' +
            fileData.inputPdfUrl_shuqian +
            '#page=' +
            bookTextObj.currentPage
          "
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
        <div v-else>
          <el-empty description="请先上传文件"></el-empty>
        </div>
        <!-- <iframe src="" width="100%" height="100%" frameborder="0"></iframe> -->
      </el-col>
      <el-col :span="12" class="grid-cell">
        <div style="padding: 0px 50px 0px 50px">


          <el-row :gutter="20">
            <el-col :span="6">页码范围：</el-col>
            <el-col :span="18"
              ><div class="grid-content bg-purple">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="formData.input46965"
                    type="text"
                    placeholder="目录页码范围"
                    clearable
                  ></el-input>
                </div></div
            ></el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 20px;">
            <div
              class="static-content-item"
              style="display: block; text-align: center"
            >
              <el-button
                :loading="extTextLoading"
                @click="getShuQian('bookmark')"
                type="success"
                >{{ extTextLoading ? "正在提取..." : "书签提取" }}</el-button
              >
            </div>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <!-- Form -->

    <el-dialog
      :visible.sync="bookTextObj.visibleForm"
      width="96%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :fullscreen="bookTextObj.fullscreen"
      :append-to-body="true"
    >
      <div slot="title">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 96%;
          "
        >
          <div class="el-dialog__title">书签编辑</div>
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="bookTextObj.fullscreen ? '退出全屏' : '开启全屏'"
              placement="top"
            >
              <i
                class="el-icon-full-screen"
                @click="bookTextObj.fullscreen = !bookTextObj.fullscreen"
              ></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <el-row :gutter="10">
        <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" class="nopadding">
          <!-- <div class="tipsMar">提取书签文件预览</div> -->
          <!-- v-if="fileData.inputPdfUrl_shuqian" -->
          <!-- getSelectText -->
          <div class="pborder h80v">

            {{fileData.inputPdfUrl_shuqian}}
            <iframe
              id="myIframe"
              :src="
                '/pdf/web/viewer.html?file=' +
                fileData.inputPdfUrl_shuqian +
                '#page=' +
                bookTextObj.currentPage
              "
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>
          </div>
        </el-col>
        <el-col :xs="13" :sm="13" :md="13" :lg="13" :xl="13" class="nopadding">
          <!-- <div class="tipsMar">提取书签文件编辑</div> -->
          <!-- :toolbars="toolbars" -->
          <div class="pborder h80v">
            <!-- <mavon-editor
                    ref="meditor"
                    :toolbars="toolbars"
                    v-model="bookTextObj.textareaEdit"
                    :subfield="toolbars.subfield"
                    @change="change"
                    style="height: 80vh"
                  /> -->
            <!-- :toolbars="toolbars"
                  :subfield="toolbars.subfield" -->
            <!-- @replace="toReplace" -->
            <!-- <markdown-editor
                    ref="markdownView"
                    :initData="bookTextObj.textareaEdit"
                    @copy="getCopyMd"
                    @change="markdownTextChange"
                  /> -->
            <!-- 
                  @replace="toReplace"
                   @closeMenu="closeMenu" -->

            <markdown-editor
              ref="markdownView"
              :initData="bookTextObj.textareaEdit"
              @copy="getCopyMd"
              height="80vh"
              style="height: 80vh;"
              @change="markdownTextChange"
            ></markdown-editor>
          </div>
        </el-col>
        <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3" class="nopadding">
          <el-scrollbar class="scrollMenuBox h80v pborder">
            <div style="padding: 10px">
              <div class="tips">特殊字符去除</div>
              <el-row class="hljs">
                <el-button
                  size="small"
                  @click="replaceTextFun('缩进')"
                  class="hljs-bootom"
                  plain
                  type="primary"
                  >目录缩进</el-button
                >
                <el-button
                  size="small"
                  @click="replaceTextFun('/')"
                  class="hljs-bootom"
                  plain
                  type="primary"
                  >符号“/“</el-button
                >
                <el-button
                  size="small"
                  @click="replaceTextFun('fxg')"
                  class="hljs-bootom"
                  plain
                  type="primary"
                  >符号“\“</el-button
                >
                <el-button
                  size="small"
                  @click="replaceTextFun('.')"
                  class="hljs-bootom"
                  plain
                  type="success"
                  >符号“.”</el-button
                >
                <el-button
                  size="small"
                  @click="replaceTextFun('、')"
                  class="hljs-bootom"
                  plain
                  type="success"
                  >符号“、”</el-button
                >
                <el-button
                  size="small"
                  @click="replaceTextFun('..')"
                  class="hljs-bootom"
                  plain
                  type="success"
                  >符号“..”</el-button
                >
                <el-button
                  size="small"
                  @click="replaceTextFun('…')"
                  class="hljs-bootom"
                  plain
                  type="info"
                  >符号“…“</el-button
                >
                <el-button
                  size="small"
                  @click="replaceTextFun('目录')"
                  class="hljs-bootom"
                  plain
                  type="warning"
                  >文字“目录“</el-button
                >
                <!-- <el-button
                        size="small"
                        @click="replaceTextFun('空格')"
                        class="hljs-bootom"
                        plain
                        type="danger"
                        >“空格“</el-button
                      > -->
                <el-button
                  size="small"
                  @click="replaceTextFun('将页码移至右边')"
                  class="hljs-bootom"
                  plain
                  type="danger"
                  >将页码移至右边</el-button
                >

                <el-button
                  size="small"
                  @click="replaceTextFun('两行合并')"
                  class="hljs-bootom"
                  plain
                  type="danger"
                  >两行合并</el-button
                >

                <el-button
                  size="small"
                  @click="replaceTextFun('kbh')"
                  class="hljs-bootom"
                  plain
                  type="danger"
                  >空白行</el-button
                >
                <el-button
                  size="small"
                  @click="replaceTextFun('全角转半角')"
                  class="hljs-bootom"
                  plain
                  type="danger"
                  >全角转半角</el-button
                >
              </el-row>

              <div class="tips">自定义替换</div>
              <div class="demo-input-size hljs" style="margin-top: 20px">
                <div>
                  <el-select
                    size="small"
                    v-model="bookTextObj.select"
                    placeholder="请选择"
                  >
                    <el-option label="全部替换" value="all"></el-option>
                    <el-option label="替换第一个" value="first"></el-option>
                    <el-option label="替换最后一个" value="end"></el-option>
                  </el-select>
                </div>
                <div style="margin-top: 10px">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="建议直接复制原文本中要替换的内容"
                    placement="left-start"
                  >
                    <el-input
                      size="small"
                      placeholder="请输入目标文本"
                      v-model="bookTextObj.srcText"
                    >
                    </el-input>
                  </el-tooltip>
                </div>
                <div style="margin-top: 10px">
                  <el-input
                    size="small"
                    placeholder="请输入替换文本"
                    v-model="bookTextObj.tarText"
                  >
                    >
                    <div
                      slot="append"
                      style="cursor: pointer; color: black"
                      @click="replaceTextFun('自定义')"
                    >
                      替换
                    </div>
                    >
                  </el-input>
                </div>

                <div style="margin-top: 10px">
                  <el-input
                    size="small"
                    placeholder="页码偏移"
                    v-model="bookTextObj.offset"
                    type="number"
                  >
                    <template slot="append"
                      ><div
                        style="cursor: pointer; color: black"
                        @click="replaceTextFun('页码偏移')"
                      >
                        页码偏移
                      </div></template
                    >
                  </el-input>
                </div>
              </div>

              <div class="tips">格式处理</div>
              <!-- <div style="margin-top: 10px">
                      <el-input
                        size="small"
                        placeholder="以***开头"
                        v-model="bookTextObj.startText"
                      >
                        <template slot="append"
                          ><div
                            style="cursor: pointer; color: black"
                            @click="replaceTextFun('sj')"
                          >
                            目录缩进
                          </div></template
                        >
                      </el-input>
                    </div> -->

              <div style="margin-top: 10px">
                <el-input
                  size="small"
                  placeholder="正则表达式"
                  v-model="bookTextObj.zzbdText"
                  clearable
                >
                  <template slot="append">
                    <div style="">
                      <div
                        style="cursor: pointer; color: black"
                        @click="replaceTextFun('zzbd')"
                      >
                        目录缩进
                      </div>
                    </div>
                  </template>
                </el-input>
              </div>

              <div style="margin-top: 10px">
                <el-row class="hljs">
                  <el-button
                    size="small"
                    @click="textJoinFun('[0-9]')"
                    class="hljs-bootom"
                    plain
                    type="primary"
                    >0-9</el-button
                  >
                  <el-button
                    size="small"
                    @click="textJoinFun('[a-z]')"
                    class="hljs-bootom"
                    plain
                    type="success"
                    >a-z</el-button
                  >
                  <el-button
                    size="small"
                    @click="textJoinFun('[A-Z]')"
                    class="hljs-bootom"
                    plain
                    type="info"
                    >A-Z</el-button
                  >
                  <el-button
                    size="small"
                    @click="textJoinFun('.')"
                    class="hljs-bootom"
                    plain
                    type="success"
                    >任意数</el-button
                  >
                  <el-button
                    size="small"
                    @click="textJoinFun('+[.]+')"
                    class="hljs-bootom"
                    plain
                    type="primary"
                    >.</el-button
                  >
                  <el-button
                    size="small"
                    @click="textJoinFun('[^.]')"
                    class="hljs-bootom"
                    plain
                    type="primary"
                    >非.</el-button
                  >
                  <div style="margin-top: 15px">
                    <el-input
                      placeholder="请输入层级"
                      type="number"
                      v-model="bookTextObj.selectLevel"
                      class="input-with-select"
                    >
                      >
                      <el-select
                        v-model="bookTextObj.selectType"
                        slot="prepend"
                        placeholder="请选择"
                        @change="selectChange"
                        style="width: 85px !important"
                      >
                        <el-option label="1" value="[0-9]\s"></el-option>
                        <el-option
                          label="1.1"
                          value="[0-9]+[.]+[0-9]\s"
                        ></el-option>
                        <el-option
                          label="1.1.1"
                          value="[0-9]+[.]+[0-9]+[.]+[0-9]\s"
                        ></el-option>
                        <el-option
                          label="1.1.1.1"
                          value="[0-9]+[.]+[0-9]+[.]+[0-9]+[.]+[0-9]\s"
                        ></el-option>

                        <el-option
                          label="一"
                          value="[一|二|三|四|五|六|七|八|九]\s"
                        ></el-option>

                        <el-option
                          label="一、"
                          value="[一|二|三|四|五|六|七|八|九][、]"
                        ></el-option>
                      </el-select>
                      <!-- <template  style="
                                padding: 0 14px !important;
                                cursor: pointer !important;
                                color: black !important;
                              "
                              slot="append" 
                              >
                              <div
                              @click="selectConfirm"
                             
                              
                            >
                              处理
                            </div> -->
                      <el-button
                        alt="处理"
                        slot="append"
                        @click="selectConfirm"
                        icon="el-icon-refresh-right"
                      ></el-button>
                    </el-input>

                    <!-- <el-input
                            placeholder="请输入层级"
                            type="number"
                            v-model="bookTextObj.selectLevel"
                            class="input-with-select"
                          >
                            <el-select
                              v-model="bookTextObj.selectType"
                              slot="prepend"
                              placeholder="请选择"
                              @change="selectChange"
                            >
                              <el-option label="1" value="[0-9]\s"></el-option>
                              <el-option
                                label="1.1"
                                value="[0-9]+[.]+[0-9]\s"
                              ></el-option>
                              <el-option
                                label="1.1.1"
                                value="[0-9]+[.]+[0-9]+[.]+[0-9]\s"
                              ></el-option>
                              <el-option
                                label="1.1.1.1"
                                value="[0-9]+[.]+[0-9]+[.]+[0-9]+[.]+[0-9]\s"
                              ></el-option>
                            </el-select>

                            <el-button
                              @click="selectConfirm"
                              slot="append"
                              icon="el-icon-search"
                            ></el-button>
                          </el-input> -->
                  </div>
                </el-row>
              </div>

              <div class="tips">操作</div>
              <div class="dialog-footer">
                <el-button
                  :loading="writePageLoading"
                  type="primary"
                  @click="addPage"
                  class="hljs-bootom"
                >
                  {{
                    writePageLoading ? "智能补页中..." : "智能补页"
                  }}</el-button
                >

                <el-button
                  @click="replaceTextFun('重置')"
                  plain
                  class="hljs-bootom"
                  type="primary"
                  >重置</el-button
                >

                <!-- <el-popconfirm title="确认写入书签？" @confirm="writeShuQian"> -->
                <el-button
                  :loading="writeTextLoading"
                  type="primary"
                  class="hljs-bootom"
                  @click="writeShuQian"
                >
                  {{
                    writeTextLoading ? "正在写入中..." : "写入书签"
                  }}</el-button
                >
                <!-- </el-popconfirm> -->
              </div>

              <div v-if="bookTextResult.FinalPdf" class="dialog-footer">
                <div class="tips">结果下载</div>
                <div style="margin: 10px; line-height: initial">
                  <div @click="downloadFile(bookTextResult.FileContent, '.pdf')">
                    <el-link type="primary">最终PDF下载</el-link>
                    <i style="margin-left: 10px" class="el-icon-download"></i>
                  </div>
                </div>
                <div style="margin: 10px; line-height: initial">
                  <div @click="downloadFile(bookTextResult.MergedTxt, '.txt')">
                    <el-link type="primary">书签文档下载 </el-link>
                    <i style="margin-left: 10px" class="el-icon-download"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script src="/pdf/web/viewer.js"></script>
<script>
import { uploadServer, getDssInfo } from "@/api/dss/fastdfsServer.js";
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import bookeditService from "@/api/digital/bookedit";
import axios from "axios";
import { saveAs } from "file-saver";

import MarkdownEditor from "./markdown.vue";
import "markdown-it-vue/dist/markdown-it-vue.css";
export default {
  name: "BookMarkView",
  components: {
    mavonEditor,
    MarkdownEditor,
  },
  props: {
    fileId: {
      type: Number,
      default: 0,
    },
    recordId: {
      type: Number,
      default: 0,
    },
    
    filePath: {
      type: String,
      default: "",
    },
    formId: {
      type: Number,
      default: 0,
    },
    action: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedText: "",
      toolbars: {
        bold: true,
        italic: true,
        header: false,
        underline: false,
        strikethrough: false,
        mark: true,
        superscript: false,
        subscript: false,
        quote: true,
        ol: true,
        ul: true,
        link: false,
        imagelink: false,
        code: false,
        table: false,
        undo: true,
        redo: true,
        trash: true,
        save: true,
        alignleft: false,
        aligncenter: false,
        alignright: false,
        navigation: false,
        subfield: true,
        fullscreen: false,
        readmodel: false,
        htmlcode: false,
        help: false,
        preview: false,
      },
      scrollTop2: 0, // 第二个 textarea 的滚动位置
      writeTextLoading: false,
      writePageLoading: false,
      extTextLoading: false,
      uploadLoading: false,
      searchLoading: false,
      dealLoading: false,
      bookTextResult: {},
      bookTextObj: {
        fullscreen: true,
        isLock: true,
        visibleForm: false,
        textarea: "",
        textareaEdit: "",
        textareaLook: "",
        srcText: "",
        tarText: "",
        select: "all",
        offset: 5,
        startText: "",
        zzbdText: "",
        searchText: "",
        level: 1,
        isBtnClick: false,
        feiText: "",
        selectType: "",
        selectLevel: 1,
        pageData: [],
        currentPage: 1,
      },
      fileData: {
        fileList: [],
        isUpload: false,
        public: 1,
        inputPdfUrl_shuqian: "",
        inputPdfUrl_shuqian_path: "",
      },

      //inFile string, outFile string, addOffset int, levelDict []string, deleteLevelBelow int,
      //defaultLevel int, isRemoveBlankLines bool
      transeData: {
        inFile: "",
        addOffset: 0,
        levelDict: [{ id: "" }],
        deleteLevelBelow: 0,
        defaultLevel: 0,
        isRemoveBlankLines: false,
      },
      indentItems: {
        items: [],
        default_level: 0,
        delete_level_below: "0",
        remove_blank_lines: true,
      },

      levelDictTitleOptions: [],

      formData: {
        radio27772: 1,
        input48548: "",
        select87491: "a4",
        input118914: "",
        input37621: "",
        radio43527: "pt",
        input100827: "",
        fileupload107819: null,
        input112344: "",
        fileupload117750: null,
        radio22356: "default",
        radio7799: "asc",
        input87707: "",
        radio37197: "extract",
        select74035: "txt",
        fileupload96019: null,
        input37568: "",
        radio89571: null,
        fileupload84528: null,
        input52597: "",
        input113719: "",
        fileupload111170: null,
        input110585: "",
        radio67115: "font",
        input87135: "",
        fileupload94807: null,
        input78795: "",
        select77031: "pdf2png",
        input74762: "",
        input112129: "",
        fileupload18591: null,
        input72418: "",
        radio45917: "text",
        input46965: "",
        fileupload40978: null,
        input40076: "",
      },
      rules: {
        input118914: [
          {
            required: true,
            message: "字段值不可为空",
          },
          {
            pattern: /^[-]?\d+(\.\d+)?$/,
            trigger: ["blur", "change"],
            message: "",
          },
        ],
        input37621: [
          {
            required: true,
            message: "字段值不可为空",
          },
          {
            pattern: /^[-]?\d+(\.\d+)?$/,
            trigger: ["blur", "change"],
            message: "",
          },
        ],
        input74762: [
          {
            required: true,
            message: "字段值不可为空",
          },
          {
            pattern: /^[-]?\d+(\.\d+)?$/,
            trigger: ["blur", "change"],
            message: "",
          },
        ],
      },
      tab60554ActiveTab: "tab-pane-103761",
      select74035Options: [
        {
          label: "txt",
          value: "txt",
        },
        {
          label: "json",
          value: "json",
        },
      ],
      radio89571Options: [
        {
          label: "书签文件导入",
          value: "file",
        },
        {
          label: "页码书签",
          value: "page",
        },
      ],
      radio67115Options: [
        {
          label: "基于字体属性",
          value: "font",
        },
        {
          label: "基于OCR",
          value: "ocr",
        },
      ],

      radio45917Options: [
        // {
        //   label: "页面",
        //   value: "page",
        // },
        // {
        //   label: "书签",
        //   value: "bookmark",
        // },
        {
          label: "文本",
          value: "text",
        },
        // {
        //   value: "image",
        //   label: "图片",
        // },
      ],
      lastTime:0,
    };
  },
  computed: {},
  watch: {
    fileId: {
      handler(newValue, oldValue) {
        this.$nextTick(() => {
          this.fileData.id = newValue;
          this.fileData.isUpload = true;
          this.fileData.inputPdfUrl_shuqian = this.filePath;
          this.fileData.inputPdfUrl_shuqian_path = this.filePath;
        });
      },
    },
  },
  created() {},
  mounted() {
    // axios
    //   .get(
    //     "http://localhost:9292/group1/digital/system/user_1/general/public/新建文本文档.txt"
    //   )
    //   .then((response) => {
    //     //赋值给textarea用户编辑
    //     this.bookTextObj.textarea = response.data;
    //     this.bookTextObj.textareaEdit = response.data;
    //     this.bookTextObj.visibleForm = true;
    //     this.bookTextObj.fullscreen = true;
    //     this.bookTextResult.Id = 154;
    //   })
    //   .catch((error) => {
    //     this.msgError("获取文本内容时出现错误:" + error);
    //   });

    //这是滑选事件
    // this.getSelectText();
    // this.$refs.myIframe.onload = () => {
    // // 获取iframe内容的document对象
    //  const iframeDoc = this.$refs.myIframe.contentDocument || this.$refs.myIframe.contentWindow.document;

    // }

    // if (this.fileId != 0) {
    //   that.fileData.id = this.fileId;
    //   that.fileData.isUpload = true;
    //   that.fileData.inputPdfUrl_shuqian = this.filePath;
    //   that.fileData.inputPdfUrl_shuqian_path = this.filePath;
    //   that.uploadLoading = false;
    //   this.bookTextObj.fullscreen = true;
    // } else {
    //   let that = this;
    //   let store = localStorage.getItem("fileData");
    //   if (store) {
    //     let obj = JSON.parse(store);
    //     that.fileData = obj;
    //     that.fileData.id = obj.id;
    //     that.fileData.isUpload = true;
    //     that.fileData.inputPdfUrl_shuqian = obj.url;
    //     that.fileData.inputPdfUrl_shuqian_path = obj.path;
    //     that.uploadLoading = false;
    //     this.bookTextObj.fullscreen = true;
    //   }
    // }


    console.log("-----------------------------");
    this.$nextTick(() => {
          this.fileData.id = this.fileId;
          this.fileData.isUpload = true;
          this.fileData.inputPdfUrl_shuqian = this.filePath;
          this.fileData.inputPdfUrl_shuqian_path = this.filePath;
        });
  },
  methods: {
    submitForm() {
      this.$refs["vForm"].validate((valid) => {
        if (!valid) return;
        //TODO: 提交表单
      });
    },
    resetForm() {
      this.$refs["vForm"].resetFields();
    },

    changeFile1(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.fileData.fileList = fileList;
    },
    //请求
    httpRequest(file1) {
      let that = this;
      that.uploadLoading = true;
      let file = that.fileData.fileList[0].raw;
      let tokenReq = {
        platform: "digital",
        type: "general",
        source: "system",
        public: "1",
      };
      uploadServer(tokenReq, file).then((response) => {
        if (response.code == 0) {
          that.msgSuccess("上传成功");

          that.fileData = response.data;
          that.fileData.id = response.data.id;
          that.fileData.isUpload = true;
          that.fileData.inputPdfUrl_shuqian = response.data.url;
          that.fileData.inputPdfUrl_shuqian_path = response.data.path;
          that.uploadLoading = false;
          localStorage.setItem("fileData", JSON.stringify(response.data));
          // 获取当前窗口所有的sessionStorage键名
          for (let key in sessionStorage) {
            // 删除指定键名的数据
            sessionStorage.removeItem(key);
          }
        } else {
          that.msgError("上传失败!" + res.msg);
          that.uploadLoading = false;
        }
      });
      that.uploadLoading = false;
    },

    handleRemove(file, fileList) {
      console.log("移除", file, fileList);
      this.fileData.fileList = [];
    },
    /**
     * 格式化日期
     * @param {string | number | Date} value 指定日期
     * @param {string} format 格式化的规则
     * @example
     * ```js
     * formatDate();
     * formatDate(1603264465956);
     * formatDate(1603264465956, "h:m:s");
     * formatDate(1603264465956, "Y年M月D日");
     * ```
     */
    formatDate(value = Date.now(), format = "h:m:s") {
      const formatNumber = (n) => `0${n}`.slice(-2);
      const date = new Date(value);
      const formatList = ["Y", "M", "D", "h", "m", "s"];
      const res = [];
      res.push(date.getFullYear().toString());
      res.push(formatNumber(date.getMonth() + 1));
      res.push(formatNumber(date.getDate()));
      res.push(formatNumber(date.getHours()));
      res.push(formatNumber(date.getMinutes()));
      res.push(formatNumber(date.getSeconds()));
      for (let i = 0; i < res.length; i++) {
        format = format.replace(formatList[i], res[i]);
      }
      return format;
    },

    getShuQian(type) {
      //提取书签
      let fileId = this.fileId;
      if (this.formData.input46965.trim() == "") {
        this.msgError("请输入页码范围!");
        return false;
      }
      let sqId = this.fileId.toString();
      let rid = this.recordId.toString();


      let postData = {
   
        id: rid,
        fid: sqId,
        pages: this.formData.input46965,
      };

      let that = this;
      that.extTextLoading = true;

      bookeditService
        .extPdftext(this.formId, postData)
        .then((rs) => {
          that.bookTextResult = rs.data.data;
          that.extTextLoading = false;
          //根据链接获取文本内容
          axios
            .get(rs.data.data.OriginTxt)
            .then((response) => {
              //赋值给textarea用户编辑
              this.bookTextObj.textarea = response.data;
              this.bookTextObj.textareaEdit = response.data;
              this.bookTextObj.visibleForm = true;
              that.extTextLoading = false;
            })
            .catch((error) => {
              this.msgError("获取文本内容时出现错误:" + error);
            });
        })
        .catch(() => {
          that.msgError("操作失败，请重新尝试!");
          that.extTextLoading = false;
        });
    },

    //写入书签
    writeShuQian() {

      const timestamp = new Date().getTime();

      // 10秒内不能重复操作
      if ((timestamp-this.lastTime)<10000) {
        this.msgError("操作过快,稍后再试");
        return;
      }

      
      if (null == this.bookTextObj) {
        this.msgError("请先提取书签");
      }

      this.bookTextObj.textareaEdit = this.bookTextObj.textareaLook;
      let eText = this.bookTextObj.textareaEdit;

      if (!JSON.stringify(eText).includes("\\r\\n")) {
        eText = this.bookTextObj.textareaEdit.replaceAll("\n", "\r\n");
      }
      const result = eText.split("\r\n").map((line) => {
   
        line = line.replaceAll("<font color=#FF0000 >", "");
        line = line.replaceAll("</font>", "");
        return line;
      });
      let postEdit = result.join("\r\n");

      let sqId = this.fileId.toString();
      let rid = this.recordId.toString();


      let postData = {
        id: rid,
        fid: sqId,
        content: postEdit,
        offset: 0,
        action: "writeMark",
        tocFile: "",
      };

      // return;
      let that = this;
      that.writeTextLoading = true;
      this.bookTextResult = {};
      that.fileData.inputPdfUrl_shuqian = "";
      bookeditService
        .writeBookmarkByFile(this.formId, postData)
        .then((rs) => {
          that.fileData.inputPdfUrl_shuqian = JSON.parse(rs.data.data.FileContent).url;
          
          that.msgSuccess("书签写入成功");
          that.bookTextResult = rs.data.data;
          this.$emit('customEvent', JSON.stringify(that.bookTextResult)); // 触发自定义事件并传递数据
          that.writeTextLoading = false;
          this.lasttime =  new Date().getTime();
        })
        .catch(() => {
          that.msgError("操作失败，请重新尝试!");
          that.writeTextLoading = false;
        });

      // }
    },

    //转换书签
    transerBookText(flag) {
      if (null == this.bookTextResult) {
        this.msgError("请先提取书签");
        return false;
      }
      let sqId = this.bookTextResult.Id;

      let addOffset = this.bookTextObj.offset;
      let deleteLevelBelow = this.indentItems.delete_level_below;
      let defaultLevel = this.indentItems.default_level;
      let isRemoveBlankLines = this.indentItems.remove_blank_lines;

      if (flag == "zzbd") {
        this.indentItems.items = [
          {
            prefix: this.bookTextObj.zzbdText,
            level: this.bookTextObj.level,
            type: "cumstom",
          },
        ];
        addOffset = 0;
        deleteLevelBelow = 0;
        defaultLevel = 0;
        isRemoveBlankLines = true;
      }

      let items = this.indentItems.items.filter((item) => {
        return item.prefix !== undefined && item.level !== undefined;
      });
      let res = [];
      for (let i = 0; i < items.length; i++) {
        res.push(
          `{"prefix":"${items[i].prefix}","level":"${items[i].level}", "type":"${items[i].type}"}`
        );
      }
      if (items.length == 0) {
        const timestamp = new Date().getTime();
        res.push(
          `{"prefix":"${timestamp}","level":"${timestamp}", "type":"cumstom"}`
        );
      }

      let postData = {
        id: sqId,
        content: this.bookTextObj.textareaEdit,
        addOffset: addOffset,
        levelDict: JSON.stringify(this.indentItems.items),
        deleteLevelBelow: deleteLevelBelow,
        defaultLevel: defaultLevel,
        isRemoveBlankLines: isRemoveBlankLines,
        action: "transelate",
      };
      let that = this;

      that.dealLoading = true;
      bookeditService
        .writeBookmarkByFile(sqId, postData)
        .then((rs) => {
          that.msgSuccess("处理成功！");
          that.bookTextResult = rs.data.data;
          this.bookTextObj.offset = 0;
          that.extTextLoading = false;
          that.dealLoading = false;
          //根据链接获取文本内容
          axios
            .get(rs.data.data.OriginTxt)
            .then((response) => {
              //赋值给textarea用户编辑
              this.bookTextObj.textareaEdit = response.data;
            })
            .catch((error) => {
              this.msgError("获取文本内容时出现错误:" + error);
            });
        })
        .catch(() => {
          that.dealLoading = false;
          that.msgError("操作失败，请重新尝试!");
        });
    },

    replaceTextFun(type) {
      let replacedText = "";
      let msgText = "处理成功";
      let selectText = this.$refs.markdownView.getSelection();
      this.bookTextObj.textareaEdit = this.bookTextObj.textareaLook;

      let targetText = this.bookTextObj.textareaEdit;

      console.log(JSON.stringify(targetText));
      if (selectText != "") {
        targetText = selectText;
      }

      if (!JSON.stringify(targetText).includes("\\r\\n")) {
        targetText = targetText.replaceAll("\n", "\r\n");
      }
      switch (type) {
        case "缩进":
          const result10 = targetText.split("\r\n").map((line3) => {
            let line1 = "\t" + line3;
            return line1;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result10.join("\r\n"));
          } else {
            replacedText = result10.join("\r\n");
          }

          break;
        case "/":
          let result9 = targetText.split("\r\n").map((line) => {
            line = line.replace(/\//g, "");
            line = line.replace(/\/\//g, "");
            return line;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result9.join("\r\n"));
          } else {
            replacedText = result9.join("\r\n");
          }

          break;
        case "fxg":
          let result8 = targetText.split("\r\n").map((line) => {
            line = line.replace(/\\+/g, "");
            return line;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result8.join("\r\n"));
          } else {
            replacedText = result8.join("\r\n");
          }

          break;
        case "、":
          let result7 = targetText.split("\r\n").map((line) => {
            line = line.replace("、", "");
            return line;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result7.join("\r\n"));
          } else {
            replacedText = result7.join("\r\n");
          }

          break;
        case ".":
          let result6 = targetText.split("\r\n").map((line) => {
            line = line.replace(".", "");
            return line;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result6.join("\r\n"));
          } else {
            replacedText = result6.join("\r\n");
          }
          break;
        case "..":
          let result5 = targetText.split("\r\n").map((line) => {
            line = line.replace("..", "");

            return line;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result5.join("\r\n"));
          } else {
            replacedText = result5.join("\r\n");
          }
          break;
        case "…":
          let result4 = targetText.split("\r\n").map((line) => {
            line = line.replace("…", "");
            line = line.replace("...", "");
            return line;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result4.join("\r\n"));
          } else {
            replacedText = result4.join("\r\n");
          }
          break;
        case "目录":
          let result3 = targetText.split("\r\n").map((line) => {
            line = line.replace("目录", "");
            return line;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result3.join("\r\n"));
          } else {
            replacedText = result3.join("\r\n");
          }
          break;

        case "两行合并":
          if (selectText != "") {
            //选择
            let result21 = this.mergeHang(targetText);
            this.$refs.markdownView.replaceSelection(result21);
          } else {
            let result22 = this.mergeHang(this.bookTextObj.textareaEdit);
            replacedText = result22;
          }
          break;
        case "将页码移至右边":
          let result23 = this.movePageNumbers(targetText);
          if (selectText != "") {
            //选择
            this.$refs.markdownView.replaceSelection(result23);
          } else {
            replacedText = result23;
          }

          break;
        case "kbh":
          let replaceText = targetText.replace(/\n\s*\n/g, "\n");
          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(replaceText);
          } else {
            this.bookTextObj.textareaEdit = replaceText;
            replacedText = this.bookTextObj.textareaEdit;
          }
          break;

        case "全角转半角":
          replacedText = this.fullWidthToHalfWidth(this.bookTextObj.textarea);
          break;

        case "页码偏移":
          if (this.bookTextObj.offset.length == 0) {
            this.msgError("请输入页码偏移量");
            return;
          }
          let text = targetText;
          // let text = this.bookTextObj.textareaEdit;
          // let jStr = JSON.stringify(text);
          // if (!jStr.includes("\\r\\n")) {
          //   console.log("无");
          //   text = this.bookTextObj.textareaEdit.replaceAll("\n", "\r\n");
          // }

          const result = text.split("\r\n").map((line) => {
            let offset = this.bookTextObj.offset;
            let newStr1 = line.replace(/\d+$/, function (match) {
              if (offset < 0) {
                //处理负数
                let resNumber =
                  parseInt(match) - parseInt(offset.replace("-", ""));

                if (resNumber < 0) {
                  msgText = "部分页码小于0，已被处理为0，请手动修改";
                  return 0;
                }
                return resNumber;
              }
              return parseInt(match) + parseInt(offset);
            });
            return newStr1;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result.join("\r\n"));
          } else {
            replacedText = result.join("\r\n");
            if (this.bookTextObj.offset == 0) {
              replacedText = this.bookTextObj.textareaEdit;
            }
          }

          break;

        case "sj":
          //正则表达
          // let textStr1 = this.bookTextObj.textareaEdit;
          let textStr1 = targetText;
          let jStr1 = JSON.stringify(textStr1);
          if (!jStr1.includes("\\r\\n")) {
            textStr1 = this.bookTextObj.textareaEdit.replaceAll("\n", "\r\n");
          }

          let patternStr = "^" + this.bookTextObj.startText;
          var pattern = new RegExp(patternStr);
          console.log(pattern);
          let result41 = textStr1.split("\r\n").map((line) => {
            const match = line.match(pattern);
            // console.log(pattern);

            console.log(match);
            if (match) {
              const sliStr = match[0];
              line = line.replace(sliStr, "\t" + sliStr);
            } else {
              //匹配不到做兼容
              line = JSON.stringify(line);
              line = JSON.parse(
                line.replaceAll(
                  "\\t" + this.bookTextObj.startText,
                  "\\t\\t" + this.bookTextObj.startText
                )
              );
            }
            return line;
          });
          replacedText = result41.join("\r\n");
          break;

        case "zzbd":
          //正则表达
          // let textStr = this.bookTextObj.textareaEdit;
          let textStr = targetText;

          let jStr2 = JSON.stringify(textStr);
          if (!jStr2.includes("\\r\\n")) {
            textStr = this.bookTextObj.textareaEdit.replaceAll("\n", "\r\n");
          }

          var pattern = new RegExp(this.bookTextObj.zzbdText);

          let result61 = textStr.split("\r\n").map((line) => {
            const match = line.match(pattern);

            console.log(pattern);
            console.log(line);

            if (match) {
              const sStr = match[0];
              let strLine = line.replaceAll("\t", "");
              if (strLine.startsWith(sStr)) {
                line = line.replace(sStr, "\t" + sStr);
              }
            }
            return line;
          });

          if (selectText != "") {
            this.$refs.markdownView.replaceSelection(result61.join("\r\n"));
          } else {
            replacedText = result61.join("\r\n");
          }

          break;

        case "重置":
          replacedText = this.bookTextObj.textarea;
          break;
        case "自定义":
          let iReplacedText = targetText;
          if (this.bookTextObj.select == "all") {
            iReplacedText = targetText.replaceAll(
              this.bookTextObj.srcText,
              this.bookTextObj.tarText
            );
          }
          if (this.bookTextObj.select == "first") {
            iReplacedText = targetText.replace(
              this.bookTextObj.srcText,
              this.bookTextObj.tarText
            );
          }
          if (this.bookTextObj.select == "end") {
            let lastIndex = targetText.lastIndexOf(targetText);
            if (lastIndex !== -1) {
              iReplacedText =
                targetText.slice(0, lastIndex) +
                "_" +
                targetText.slice(lastIndex + 1);
            }
          }

          if (selectText != "") {
            console.log(iReplacedText);
            this.$refs.markdownView.replaceSelection(iReplacedText);
          } else {
            replacedText = iReplacedText;
          }
          break;

        // 示例：

        default:
          break;
      }

      // if (type == "自定义") {
      //   if (this.bookTextObj.srcText.length == 0) {
      //     this.msgError("请输入要替换的目标文本");
      //     return;
      //   }

      // }

      this.bookTextObj.textareaEdit = replacedText;
      if (type == "重置") {
        this.msgSuccess("重置成功");
      } else {
        this.msgSuccess(msgText);
      }
    },

    selectChange(val) {
      this.bookTextObj.selectType = val;
    },

    selectConfirm() {
      if (this.bookTextObj.selectType == "") {
        this.msgError("请先选择格式");
        return;
      }
      if (
        this.bookTextObj.selectLevel > 5 ||
        this.bookTextObj.selectLevel < 0
      ) {
        this.msgError("层级值需要在0到5之间");
        return;
      }
      //正则表达
      let textStr = this.bookTextObj.textareaEdit;
      let jStr2 = JSON.stringify(textStr);
      if (!jStr2.includes("\\r\\n")) {
        textStr = this.bookTextObj.textareaEdit.replaceAll("\n", "\r\n");
      }

      var pattern = new RegExp(this.bookTextObj.selectType);

      let result1 = textStr.split("\r\n").map((line) => {
        const match = line.match(pattern);
        if (match) {
          let tabStr = "";
          let sStr = match[0];

          const regex = new RegExp("^(\t)*" + sStr + "");
          const match1 = line.match(regex);

          if (line.startsWith(sStr) || match1) {
            if (this.bookTextObj.selectLevel == 1) {
              tabStr = "\t";
            }
            if (this.bookTextObj.selectLevel == 2) {
              tabStr = "\t\t";
            }
            if (this.bookTextObj.selectLevel == 3) {
              tabStr = "\t\t\t";
            }
            if (this.bookTextObj.selectLevel == 4) {
              tabStr = "\t\t\t\t";
            }
            if (this.bookTextObj.selectLevel == 5) {
              tabStr = "\t\t\t\t\t";
            }
          }
          line = line.replace(sStr, tabStr + sStr);
        }

        return line;
      });
      this.bookTextObj.textareaEdit = result1.join("\r\n");
      this.msgSuccess("处理成功");
    },

    mergeHang(textStr) {
      // 使用 split() 方法将文本按行分割成数组

      let jStr2 = JSON.stringify(textStr);
      if (!jStr2.includes("\\r\\n")) {
        textStr = this.bookTextObj.textareaEdit.replaceAll("\n", "\r\n");
      }
      let lines = textStr.split("\r\n");

      // 合并每两行为一行，并存储到新的数组中
      let mergedLines = [];
      for (let i = 0; i < lines.length; i += 2) {
        if (i + 1 < lines.length) {
          let merged = lines[i] + " " + lines[i + 1];
          mergedLines.push(merged);
        } else {
          mergedLines.push(lines[i]);
        }
      }
      // 输出合并后的结果
      let mergedText = mergedLines.join("\n");
      return mergedText;
    },
    movePageNumbers(textStr) {
      // 分割文本为数组
      let jStr2 = JSON.stringify(textStr);
      if (!jStr2.includes("\\r\\n")) {
        textStr = textStr.replaceAll("\n", "\r\n");
      }
      let lines = textStr.split("\r\n");

      // 遍历每一行
      let processedLines = lines.map((line, index) => {
        // 使用正则表达式匹配以数字开头的内容
        const regex = /^\d+\s+/;
        const match = line.match(regex);
        // 如果找到匹配项，则将页码移至行尾
        if (match) {
          return line.replace(match[0], "") + " " + match[0];
        } else {
          // 如果该行没有以数字开头，原样返回
          return line;
        }
      });

      // 将处理后的行重新组合成文本
      return processedLines.join("\n");
    },

    //文件下载
    downloadFile(url, suffix) {
      if (this.bookTextResult == null) {
        this.msgError("未找到相关文件信息！");
        return false;
      }

      let fileName = "-加书签目录.pdf";
      if (suffix == ".txt") {
        //下载书签
        fileName = "-书签目录.txt";
      }

      let FileName = this.bookTextResult.FileName.split(".")[0] + fileName;
      saveAs(url, FileName);
    },

    // 所有操作都会被解析重新渲染
    change(value, render) {
      // render 为 markdown 解析后的结果[html]
      this.bookTextObj.textareaEdit = render;
    },

    addIndentItem() {
      this.indentItems.items.push({
        prefix: undefined,
        level: undefined,
        type: undefined,
      });
    },
    removeIndentItem(item) {
      let index = this.indentItems.items.indexOf(item);
      if (index > -1) {
        this.indentItems.items.splice(index, 1);
      }
    },
    textJoinFun(val) {
      let zzbdTextLogs = localStorage.getItem("zzbdTextLogs");
      let zzbdTextLogsList = [];
      if (zzbdTextLogs) {
        //有记录
        zzbdTextLogsList = JSON.parse(zzbdTextLogs);
        zzbdTextLogsList.push(val);
      }

      this.bookTextObj.zzbdText = this.bookTextObj.zzbdText + val;
      localStorage.setItem("zzbdTextLogs", JSON.stringify(zzbdTextLogsList));
    },
    textBackFun() {
      let zzbdTextLogs = localStorage.getItem("zzbdTextLogs");
      let zzbdTextLogsList = [];
      if (zzbdTextLogs) {
        //有记录
        zzbdTextLogsList = JSON.parse(zzbdTextLogs);
        if (zzbdTextLogsList.length > 0) {
          zzbdTextLogsList.splice(-1, 1);
        }
      }

      this.bookTextObj.zzbdText = zzbdTextLogsList;
      localStorage.setItem("zzbdTextLogs", JSON.stringify(zzbdTextLogsList));
    },
    handleClose() {
      this.$confirm("此操作将关闭编辑, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.bookTextObj.visibleForm = false;
        })
        .catch(() => {});
    },

    // 发送数据(搜索文字)
    getTextHighLight() {
      let vm = this;
      //获取iframe
      let iframe = document.getElementById("outIframe");
      //将滑选数据传入到iframe中
      iframe.contentWindow.postMessage(vm.bookTextObj.searchText, "*");
      //获取iframe
      // iframe监听是否有数据传入，将传入的数据作为参数传递给pdf.js的find接口
      iframe.contentWindow.addEventListener(
        "message",
        function (e) {
          //这里打印一下，看是否拿到了传入的数据

          let sstore = sessionStorage.getItem(e.data);
          if (sstore) {
            //有
            let kk = JSON.parse(sstore);
            console.log(kk);
            const uniqueArr = Array.from(new Set(kk));
            vm.bookTextObj.pageData = uniqueArr;
            sessionStorage.setItem(e.data, JSON.stringify(uniqueArr));
            // return false;
          }
          // 输入查询数据
          iframe.contentWindow.PDFViewerApplication.findBar.findField.value =
            e.data;

          // 要求查询结果全体高亮
          iframe.contentWindow.PDFViewerApplication.findBar.highlightAll.checked = true;
          // 上面两部已经OK，触发highlightallchange方法。OK。全部完成，效果如文章开头，因为项目保密，所以就不这么着吧。
          iframe.contentWindow.PDFViewerApplication.findBar.dispatchEvent(
            "highlightallchange"
          );
        },
        false
      );
    },

    //智能补页
    addPage() {
      let that = this;
      // this.$confirm("请先确保页码已校正, 如已校正,请确定?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {

      //   })
      //   .catch(() => {});

      this.writePageLoading = true;

      let fileId = this.fileId.toString();
      // let sqId = this.bookTextResult.Id;
      // fileId = 154;

      //正则表达
      this.bookTextObj.textareaEdit = this.bookTextObj.textareaLook;
      let textStr1 = this.bookTextObj.textareaEdit;
      let jStr1 = JSON.stringify(textStr1);
      if (!jStr1.includes("\\r\\n")) {
        textStr1 = this.bookTextObj.textareaEdit.replaceAll("\n", "\r\n");
      }

      let findArr = [];
      let pageArr = [];
      let lineArr = [];
      let result2 = textStr1.split("\r\n").map((line, index) => {
        line = line.replaceAll("<font color=#FF0000 >", "");
        line = line.replaceAll("</font>", "");
        if (line.trim() == "") {
          return line;
        }
        const match = line.match(/\d+$/);
        if (!match) {
          //没有匹配到
          //构建pages参数
          let findResp = this.findNearestPageNumbers(textStr1, line.trim());

          let pStr = "";
          if (findResp.length == 1) {
            pStr = findResp[0];
          }
          if (findResp.length == 2) {
            pStr = findResp[0] + "-" + findResp[1];
          }
          pageArr.push(pStr);

          lineArr.push(parseInt(index) + 1);

          //构建findArr参数
          var wordsArray = line.trim().split(" ");
          if (wordsArray[wordsArray.length - 1].trim() != "") {
            // findArr.push(wordsArray[wordsArray.length - 1]);
            findArr.push(line.trim());
          }
        }
        return line;
      });
      if (findArr.length == 0) {
        this.$message({
          message: "没有找到缺失的页码，请仔细检查文本内容",
          type: "warning",
        });
        that.writePageLoading = false;
        return false;
      }

      if (pageArr[0].toString().indexOf("-") == -1) {
        pageArr[0] = "1-" + pageArr[0];
      }
      if (pageArr[pageArr.length - 1].toString().indexOf("-") == -1) {
        pageArr[pageArr.length - 1] =
          pageArr[pageArr.length - 1] + "-" + "9999";
      }

      let rid = this.recordId;

      console.log(rid+"----------------------");
      let postData = {
        id: rid,
        fid: fileId,
        pages: "1-999",
        findStr: findArr.toString(),
        action: "addPage",
      };


      bookeditService
        .writeBookmarkByFile(this.formId, postData)
        .then((rs) => {
          let arrResp = JSON.parse(rs.data.data.Formdata);
          //获取书签页
          let pageArr = [];
          let text = that.formData.input46965.trim();
          if (text.indexOf("-") != -1) {
            for (
              let index = parseInt(text.split("-")[0]);
              index <= parseInt(text.split("-")[1]);
              index++
            ) {
              pageArr.push(index);
            }
          } else {
            pageArr.push(parseInt(text));
          }
          let strArray = pageArr.toString();
          pageArr = strArray.split(",").map(String);

          // console.log(pageArr);

          //去重书签页码
          let resp = [];
          that.writePageLoading = false;
          for (let i = 0; i < arrResp.length; i++) {
            let iarr = arrResp[i].split(",");
            // 计算差集
            let uniqueArray = iarr.filter((value) => !pageArr.includes(value));
            uniqueArray.sort(function (a, b) {
              return a - b;
            });
            resp.push(uniqueArray.toString());
          }

          //--------------------------智能填充-------------------

          let eText = this.bookTextObj.textareaEdit;
          let jStr = JSON.stringify(eText);
          if (!jStr.includes("\\r\\n")) {
            console.log("无");
            eText = this.bookTextObj.textareaEdit.replaceAll("\n", "\r\n");
          }

          //移除缓存记录
          findArr.forEach((element, i) => {
            sessionStorage.removeItem(element);
          });
          // const result = eText.split("\r\n").map((line) => {
          //   findArr.forEach((element, i) => {
          //     if (
          //       line.indexOf(element) != -1 &&
          //       line.trim().endsWith(element)
          //     ) {
          //       let num = 1;
          //       if (sessionStorage.getItem(element)) {
          //         //有值
          //         num = Number(sessionStorage.getItem(element)) + 1;
          //       }
          //       sessionStorage.setItem(element, num);
          //       let myIndex = Number(sessionStorage.getItem(element)) - 1;
          //       let newPage = resp[i].split(",")[myIndex];
          //       if (newPage == undefined || newPage == "undefined") {
          //         newPage = "";
          //       }
          //       console.log(newPage);
          //       line = line.replaceAll(
          //         line,
          //         "==" + element + "==" + " " + newPage
          //       );
          //       findArr.shift();
          //       resp.shift();
          //     }
          //   });
          //   return line;
          // });

          // console.log(lineArr);

          let strArr = eText.split("\r\n");
          let myIndex = 0;
          for (let index = 0; index < strArr.length; index++) {
            let line = strArr[index].trim();

            let linn = index + 1;

            if (lineArr.includes(linn)) {
              let lineRange = this.findNearestPageNumbersByLine(eText, linn);

              let pp = lineRange;
              if (pp.length == 1 && index == 0) {
                //首行没有页码
                let first = pp[0];
                pp = [0];
                pp.push(first);
              }
              if (pp.length == 1 && index == strArr.length - 1) {
                //尾行没有页码
                let end = pp[0];
                pp = [];
                pp.push(end);
                pp.push(999);
              }
              let pageArrg = this.getPageList(pp);
              let numberArray = resp[myIndex]
                .split(",")
                .map((str) => parseInt(str, 10));

              // console.log(pageArrg);
              // console.log(numberArray);

              // 或者使用 Number(str)
              let newPageArr = this.getCommonElements(numberArray, pageArrg);
              myIndex++;

              //该行所有可能出现的页码

              //首行处理
              if (lineRange.length == 1 && index == 0) {
                strArr[index] =
                  "####" + line + "" + " " + newPageArr[newPageArr.length - 1];
                continue;
              }
              //最后一行
              // if (lineRange.length == 1 && index == strArr.length - 1) {
              //   strArr[index] =
              //     "==" + line + "==" + " " + newPageArr[newPageArr.length - 1];
              //   continue;
              // }
              //多个结果
              // if (lineRange.length > 1) {
              //   var wordsArray = line.trim().split(" ");
              //   var key = wordsArray[wordsArray.length - 1];
              //   let keyNum = 1;
              //   if (sessionStorage.getItem(key)) {
              //     keyNum = Number(sessionStorage.getItem(key)) + 1;
              //   }
              //   sessionStorage.setItem(key, keyNum);
              //   let sessionIndex = Number(sessionStorage.getItem(key)) - 1;
              //   console.log(line);
              //   // strArr[index] =
              //   //   "==" + line + "==" + " " + newPageArr[sessionIndex];
              //   // continue;
              // }

              //常规处理
              let ppage = newPageArr[0] == undefined ? "" : newPageArr[0];
              strArr[index] =
                "<font color=#FF0000 >" + line + "" + " " + ppage + "</font>";
            }
          }

          that.bookTextObj.textareaEdit = strArr.join("\r\n");
          //移除缓存记录

          findArr.forEach((element, i) => {
            sessionStorage.removeItem(element);
          });

          that.toolbars.subfield = true;
          that.writePageLoading = false;
        })
        .catch(() => {
          // that.msgError("操作失败，请重新尝试!");
          // that.writePageLoading = false;
        });
      //--------------------------智能填充-------------------
      that.writePageLoading = false;
    },

    getPageList(strArr) {
      if (strArr.length == 1) {
        return strArr;
      }
      let pageArr = [];
      for (
        let index = parseInt(parseInt(strArr[0]));
        index <= parseInt(strArr[1]);
        index++
      ) {
        pageArr.push(index);
      }
      return pageArr;
    },
    getCommonElements(arr1, arr2) {
      return arr1.filter((value) => arr2.includes(value));
    },

    findNearestPageNumbers(text, keyword) {
      // 将文本分割成多行并移除每行两边的空白字符
      const lines = text.split("\r\n").map((line) => line.trim());
      // 找到关键词所在行的索引
      const keywordIndex = lines.findIndex((line) => line === keyword);
      // 初始化结果数组来存储找到的页码
      let result = [];
      // 从前向后查找最近的一个以数字结尾的行（包括关键词所在行之前）
      for (let i = keywordIndex - 1; i >= 0 && result.length < 1; i--) {
        const match = /\d+$/g.exec(lines[i]);
        if (match) {
          result.unshift(parseInt(match[0])); // 添加到结果数组的前面
          break;
        }
      }
      // 从后向前查找最近的一个以数字结尾的行（包括关键词所在行之后）
      for (
        let i = keywordIndex + 1;
        i < lines.length && result.length < 2;
        i++
      ) {
        const match = /\d+$/g.exec(lines[i]);
        if (match) {
          result.push(parseInt(match[0])); // 添加到结果数组的后面
          break;
        }
      }

      return result;
    },
    findNearestPageNumbersByLine(text, lineNumber) {
      const lines = text.split("\r\n");

      // 确保行号有效
      if (lineNumber < 1 || lineNumber > lines.length) {
        return "Invalid line number";
      }

      let nearestPageNumbers = [];

      // 提取目标行及其前后有页码的实际行内容（如果存在）
      let prevLineWithPage;
      let nextLineWithPage;
      for (let i = lineNumber - 1; i >= 0; i--) {
        const match = lines[i].match(/\d+$/);
        if (match) {
          prevLineWithPage = parseInt(match[0], 10);
          break;
        }
      }
      for (let i = lineNumber; i < lines.length; i++) {
        const match = lines[i].match(/\d+$/);
        if (match) {
          nextLineWithPage = parseInt(match[0], 10);
          break;
        }
      }

      // 添加找到的页码到结果数组中
      if (prevLineWithPage !== undefined) {
        nearestPageNumbers.push(prevLineWithPage);
      }
      if (nextLineWithPage !== undefined) {
        nearestPageNumbers.push(nextLineWithPage);
      }

      return nearestPageNumbers;
    },

    fullWidthToHalfWidth(str) {
      let result = "";
      for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);

        // 处理全角空格
        if (charCode === 12288) {
          result += String.fromCharCode(32);
        }
        // 其他全角字符转半角（包括数字和部分符号）
        else if (
          (charCode >= 65281 && charCode <= 65374) ||
          (charCode >= 12293 && charCode <= 12295)
        ) {
          // 全角符号区间补充
          result += String.fromCharCode(charCode - 65248);
        }
        // 对于非全角字符（比如英文、半角字符等），直接保留原样
        else {
          result += str.charAt(i);
        }
      }
      return result;
    },
    getCopyMd(e) {
      //let element = document.querySelector(".editormd-markdown-textarea");
      //let url = element.innerHTML; //拿到想要复制的值
      let copyInput = document.createElement("textarea"); //创建input元素
      document.body.appendChild(copyInput); //向页面底部追加输入框
      copyInput.value = e.text; //添加属性，将url赋值给input元素的value属性
      copyInput.select(); //选择input元素
      document.execCommand("Copy"); //执行复制命令
      //复制之后再删除元素，否则无法成功赋值
      copyInput.remove();
      if (e.type * 1 === 1) {
        this.$message.success("复制成功");
      } else {
        this.$message.success("复制成功");
      }
    },
    markdownTextChange(e) {
      if (this.bookTextObj.textareaLook !== e.markdown) {
        this.bookTextObj.textareaLook = e.markdown;
      }
    },

    // toReplace(e) {
    //     // this.dialogVisible = true;

    //    alert(this.$refs.markdownView.getSelection());

    // },
  },
};
</script>

<style lang="scss">
.el-input-number.full-width-input,
.el-cascader.full-width-input {
  width: 100% !important;
}

.el-form-item--medium {
  .el-radio {
    line-height: 36px !important;
  }

  .el-rate {
    margin-top: 8px;
  }
}

.el-form-item--small {
  .el-radio {
    line-height: 32px !important;
  }

  .el-rate {
    margin-top: 6px;
  }
}

.el-form-item--mini {
  .el-radio {
    line-height: 28px !important;
  }

  .el-rate {
    margin-top: 4px;
  }
}

.clear-fix:before,
.clear-fix:after {
  display: table;
  content: "";
}

.clear-fix:after {
  clear: both;
}

.float-right {
  float: right;
}
div.table-container {
  table.table-layout {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td.table-cell {
      display: table-cell;
      height: 36px;
      border: 1px solid #e1e2e3;
    }
  }
}

div.tab-container {
}

.label-left-align ::v-deep .el-form-item__label {
  text-align: left;
}

.label-center-align ::v-deep .el-form-item__label {
  text-align: center;
}

.label-right-align ::v-deep .el-form-item__label {
  text-align: right;
}

.static-content-item {
  min-height: 20px;
  display: flex;
  align-items: center;

  ::v-deep .el-divider--horizontal {
    margin: 0;
  }
}
::v-deep .el-textarea__inner {
  height: 70vh !important;
}
.my-textarea:focus {
  outline: none;
  border-color: #409eff;
}
// .button-margin {
//   margin: 10px;
// }
.tips {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 20px 0;
}
.tipsNo {
  border-left: none;
  margin-top: 0px;
  text-align: left !important;
  font-weight: bolder;
}
.tipsMar {
  padding: 8px 0px 16px 0px;
  font-weight: bolder;
  text-align: left !important;
}
.h80v {
  height: 80vh !important;
}
.myFlex {
  margin-top: 0px;
  padding-top: 0px;
  display: flex;
  line-height: 23px;
  padding-left: 6px;
}
.hljs {
  //margin: 10px;
}
.hljs-bootom {
  margin-bottom: 10px;
}
.pborder {
  border: 1px solid #eaeefb;
  margin: 5px;
}
.nopadding {
  padding: 0px !important;
}
</style>

<style>
.v-note-help-wrapper {
  z-index: 999999 !important;
}
.el-dialog__body {
  word-break: inherit !important;
}
.editormd{
  /* height: 80vh !important; */
}
</style>
