import request from '@/utils/request'

export function listStuStudentFamily(query) {
  return request({
    url: '/stu/stustudentfamily/list',
    method: 'get',
    params: query
  })
}

export function getStuStudentFamily(id) {
  return request({
    url: '/stu/stustudentfamily/get?id=' + id,
    method: 'get'
  })
}

export function addStuStudentFamily(data) {
  return request({
    url: '/stu/stustudentfamily/add',
    method: 'post',
    data: data
  })
}

export function editStuStudentFamily(data) {
  return request({
    url: '/stu/stustudentfamily/edit',
    method: 'post',
    data: data
  })
}

export function delStuStudentFamily(ids) {
  return request({
    url: '/stu/stustudentfamily/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

