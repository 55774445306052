<template>
  <div v-if="showsetting" class="set-theme">
    <el-dropdown @command="handleCommand" size="medium" trigger="click">
      <div class="setting">
        <i class="el-icon-s-tools"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          command="purpleBlue"
          :class="{ 'is-active': loginTheme == 'purpleBlue' }"
        >
          <div>
            <i class="iconfont icon-zhuti theme1"></i>
            紫蓝主题
          </div>
        </el-dropdown-item>
        <el-dropdown-item
          command="youngBlue"
          :class="{ 'is-active': loginTheme == 'youngBlue' }"
        >
          <div>
            <i class="iconfont icon-zhuti theme2"></i>
            青年蓝
          </div>
        </el-dropdown-item>
        <el-dropdown-item
          command="technicalBlue"
          :class="{ 'is-active': loginTheme == 'technicalBlue' }"
        >
          <div>
            <i class="iconfont icon-keji theme3"></i>
            科技蓝
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      visible: false,
      showsetting:true
    };
  },
  computed: {
    ...mapGetters({
      loginTheme: "settings/loginTheme",
    }),
  },
  created() {
    this.visible = true;

     if(process.env.VUE_APP_LOGIN_THEME&&process.env.VUE_APP_LOGIN_THEME!=""){
      this.showsetting=false

    }

  },
  methods: {
    ...mapActions({
      setLoginTheme: "settings/setLoginTheme",
    }),
    handleCommand(e) {
      this.setLoginTheme(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.set-theme {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 10;
}
.setting {
  i {
    font-size: 36px;
    color: #ffffff;
  }
}
.theme1 {
  background-image: linear-gradient(to right, blue, purple);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.theme2 {
  background-image: linear-gradient(to right, #1890ff, #1890ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.theme3 {
  background-image: linear-gradient(to right, #0a065e, #168ae3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bolder;
}

.gp-dropdown-menu {
  &__item {
    &.is-active {
      position: relative;
      background-color: #e6f3ff;
      color: #349ffc;
    }
  }
}
</style>