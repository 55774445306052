<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">

			<el-form-item label="登录IP" prop="ip">
				<el-input v-model="queryParams.ip" placeholder="请输入登录IP" clearable style="width: 240px;" size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>

			<el-form-item label="用户名" prop="username">
				<el-input v-model="queryParams.username" placeholder="请输入用户名" clearable style="width: 240px;"
					size="small" @keyup.enter.native="handleQuery" />
			</el-form-item>

			<el-form-item label="状态" prop="status">
				<el-select v-model="queryParams.status" placeholder="登录状态" clearable size="small" style="width: 240px">
					<el-option v-for="dict in statusOptions" :key="dict.key" :label="dict.value" :value="dict.key" />
				</el-select>
			</el-form-item>

			<el-form-item label="登录时间">
				<el-date-picker v-model="dateRange" size="small" style="width: 240px" value-format="yyyy-MM-dd"
					type="daterange" range-separator="-" start-placeholder="开始日期"
					end-placeholder="结束日期"></el-date-picker>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>
		<jf-table :loading="loading" :columns="tableColumn" :data="list" :toolbar-config="tableToolbar"
			:query-params="queryParams" :total="total" @checkbox-change="checkboxChangeEvent" @change="tableChange"
			@page-change="handleSizeChange" :operate="false" >
			<template slot="toolbar_btn_left">
				<el-button  type="danger" icon="el-icon-delete" size="mini"
					:disabled="multiple" @click="handleDelete">删除</el-button>
				<el-button type="danger" icon="el-icon-delete" size="mini" @click="handleClean">清空</el-button>

			</template>

		</jf-table>
	<!-- 	<vxe-grid resizable ref="xGrid" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="list" @checkbox-change="checkboxChangeEvent"
			@checkbox-all="checkboxChangeEvent" >
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="danger" icon="el-icon-delete" size="mini"
					:disabled="multiple" @click="handleDelete">删除</el-button>
				<el-button type="danger" icon="el-icon-delete" size="mini" @click="handleClean">清空</el-button>
			</template> -->
			<!--自定义空数据模板-->
	<!-- 		<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>

		</vxe-grid>
		<vxe-pager background size="small" :loading="loading" :current-page="queryParams.pageNum"
			:page-size="queryParams.pageSize" :total="total" @page-change="handleSizeChange">
		</vxe-pager> -->

	</div>
</template>

<script>
	import {
		list,
		delLogininfor,
		cleanLogininfor,
		exportLogininfor
	} from "@/api/core/monitor/loginlog";

	export default {
		name: "core-monitor-loginlog",
		data() {
			return {
				// 工具栏
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery
					},
					slots: {
						buttons: 'toolbar_buttons'
					}
				},
				// 列
				tableColumn: [
					// {
					// 	type: 'checkbox',
					// 	width: 60,
					// 	fixed: "left"
					// },
					{
						field: 'id',
						title: '编号',
						fixed: "left",
						visible: false
					},
					{
						field: 'username',
						title: '用户名',
					},
					{
						field: 'ip',
						title: '登录IP'
					},
					{
						field: 'location',
						title: '登录地点'
					},
					{
						field: 'browser',
						title: '客户端'
					},
					{
						field: 'os',
						title: '操作系统'
					},
					{
						field: 'status',
						title: '登录状态',
						width: 100,
						formatter: this.statusFormat
					},
					{
						field: 'msg',
						title: '操作信息',
						width: 100
					},
					{
						field: 'module',
						title: '登录模块',
						width: 180
					},
					{
						field: 'login_time',
						title: '登录时间',
						width: 180,
						formatter: "formatDate",
						form_type:'3'
					},
				],
				// 遮罩层
				loading: true,
				// 选中数组
				ids: [],
				// 非多个禁用
				multiple: true,
				// 总条数
				total: 0,
				// 表格数据
				list: [],
				// 状态数据字典
				statusOptions: [],
				// 日期范围
				dateRange: [],
				// 查询参数
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					ip: undefined,
					username: undefined,
					status: undefined
				}
			};
		},
		created() {
			this.getList();
		},
		methods: {
			// 列表筛选
			tableChange(e) {
				console.log(e)
			},
			handleSizeChange({
				currentPage,
				pageSize
			}) {
				this.queryParams.pageNum = currentPage;
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}

			},
			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map(item => item.id)
				this.single = records.length != 1;
				this.multiple = !records.length

			},
			/** 查询登录日志列表 */
			getList() {
				this.loading = true;
				list(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
					this.single = true;
					this.multiple = true;
					this.statusOptions = response.data.searchStatus.values || []
					this.list = response.data.list;
					this.total = response.data.total;
					this.loading = false;
				});
			},
			// 登录状态字典翻译
			statusFormat({
				row
			}) {
				return this.selectDictLabel(this.statusOptions, row.status);
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.dateRange = [];
				this.resetForm("queryForm");
				this.handleQuery();
			},
			// 多选框选中数据
			handleSelectionChange(selection) {
				this.ids = selection.map(item => item.id)
				this.multiple = !selection.length
			},
			/** 删除按钮操作 */
			handleDelete() {
				const infoIds = this.ids.join(',');

				this.$confirm('确定删除吗?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return delLogininfor(infoIds);
				}).then(() => {
					this.getList();
					this.msgSuccess("删除成功");
				}).catch(function() {});
			},
			/** 清空按钮操作 */
			handleClean() {
				this.$confirm('确定清空所有登录日志数据项吗?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return cleanLogininfor();
				}).then(() => {
					this.getList();
					this.msgSuccess("清空成功");
				}).catch(function() {});
			},
			/** 导出按钮操作 */
			handleExport() {
				const queryParams = this.queryParams;
				this.$confirm('是否确认导出所有操作日志数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return exportLogininfor(queryParams);
				}).then(response => {
					this.download(response.msg);
				}).catch(function() {});
			}
		}
	};
</script>