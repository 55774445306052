<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        >
            <el-form-item label="名称" prop="app_name">
                <el-input
                    v-model="queryParams.app_name"
                    placeholder="请输入名称"
                    clearable
                    style="width: 150px"
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="会员ID" prop="user_id">
                <el-input
                    v-model="queryParams.user_id"
                    placeholder="请输入会员ID"
                    clearable
                    style="width: 130px"
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="接口ID" prop="api_id">
                <el-input
                    v-model="queryParams.api_id"
                    placeholder="请输入接口ID"
                    clearable
                    style="width: 130px"
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="应用ID" prop="app_id">
                <el-input
                    v-model="queryParams.app_id"
                    placeholder="请输入应用ID"
                    clearable
                    style="width: 130px"
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select
                    v-model="queryParams.status"
                    placeholder="状态"
                    clearable
                    size="small"
                    style="width: 120px"
                >
                    <el-option
                        v-for="dict in statusOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
                <el-date-picker
                    v-model="dateRange"
                    size="small"
                    style="width: 240px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="list"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons> </template>
            <template #statustpl="{ row }">
                <el-tag type="warning" effect="light" v-if="row.status == 0">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="success" effect="light" v-if="row.status == 1">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="danger" effect="light" v-if="row.status == 2">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="" effect="light" v-if="row.status == 3">{{
                    statusFormat(row)
                }}</el-tag>
            </template> -->
            <!--自定义空数据模板-->
            <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template> -->

            <!-- <template #detail="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(row)"
                    >详细</el-button
                >
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="list"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left"> </template>
            <template slot="statustpl" slot-scope="{ row }">
                <el-tag type="warning" effect="light" v-if="row.status == 0">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="success" effect="light" v-if="row.status == 1">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="danger" effect="light" v-if="row.status == 2">{{
                    statusFormat(row)
                }}</el-tag>
                <el-tag type="" effect="light" v-if="row.status == 3">{{
                    statusFormat(row)
                }}</el-tag>
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(row)"
                    >详细</el-button
                >
            </template>
        </jf-table>
        <!-- 应用订单详细 -->
        <el-dialog
            title="应用订单详细"
            :visible.sync="open"
            width="700px"
            append-to-body
        >
            <el-form ref="form" :model="form" label-width="100px" size="mini">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="名称："
                            >{{ form.app_name }}
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="用户订单号：">{{
                            form.out_order_no
                        }}</el-form-item>
                        <el-form-item label="系统订单号：">{{
                            form.order_no
                        }}</el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="订单金额：">{{
                            form.order_amount
                        }}</el-form-item>
                        <el-form-item label="实际金额：">{{
                            form.amount
                        }}</el-form-item>
                        <el-form-item label="费用：">{{
                            form.fee
                        }}</el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="请求参数：">{{
                            form.json_param
                        }}</el-form-item>

                        <el-form-item label="状态：">
                            <el-tag
                                type="warning"
                                effect="light"
                                v-if="form.status == 0"
                                >{{ statusFormat(form) }}</el-tag
                            >
                            <el-tag
                                type="success"
                                effect="light"
                                v-if="form.status == 1"
                                >{{ statusFormat(form) }}</el-tag
                            >
                            <el-tag
                                type="danger"
                                effect="light"
                                v-if="form.status == 2"
                                >{{ statusFormat(form) }}</el-tag
                            >
                            <el-tag
                                type=""
                                effect="light"
                                v-if="form.status == 3"
                                >{{ statusFormat(form) }}</el-tag
                            >
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="备注：">{{
                            form.remark
                        }}</el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="创建时间：">{{
                            parseTime(form.create_time)
                        }}</el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="open = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { listOrder } from "@/api/data/app/order";
import Vue from "vue";
import moment from "moment";

export default {
    name: "data-app-order",
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "编号", width: 80, fixed: "left" },
                { field: "app_name", title: "名称", visible: false ,is_query:true,},
                { field: "user_id", title: "会员ID", visible: false,is_query:true, },
                { field: "api_id", title: "接口ID", visible: false ,is_query:true,},
                { field: "app_id", title: "应用ID", visible: false ,is_query:true,},
                {
                    field: "key_info",
                    title: "基本信息",
                    width: 100,
                    type: "html",
                    formatter: function ({ cellValue, row, column }) {
                        let html = "会员ID：" + row.user_id;
                        html += "<br/>接口ID：" + row.api_id;
                        html += "<br/>应用ID：" + row.app_id;
                        return html;
                    },
                },
                {
                    field: "order_info",
                    title: "订单信息",
                    width: 300,
                    type: "html",
                    formatter: function ({ cellValue, row, column }) {
                        let html = "应用名称：" + row.app_name;
                        html += "<br/>用户订单号：" + row.out_order_no;
                        html += "<br/>系统订单号：" + row.order_no;
                        return html;
                    },
                },
                {
                    field: "money_info",
                    title: "金额信息",
                    type: "html",
                    formatter: function ({ cellValue, row, column }) {
                        let html = "下单金额：" + row.order_amount;
                        html += "<br/>实际金额：" + row.amount;
                        html += "<br/>费用：" + row.fee;
                        /*html+= '<br/>计费类型：<span class="el-tag el-tag--info el-tag--medium el-tag--light"></span>'*/
                        return html;
                    },
                },
                {
                    field: "callback_info",
                    title: "回调信息",
                    type: "html",
                    formatter: function ({ cellValue, row, column }) {
                        let html = "回调次数：" + row.callback_count;
                        html += "<br/>回调状态：" + row.callback_status;
                        html +=
                            "<br/>回调时间：" +
                            Vue.prototype.parseTime(row.callback_time);
                        /*html+= '<br/>计费类型：<span class="el-tag el-tag--info el-tag--medium el-tag--light"></span>'*/
                        return html;
                    },
                },
                {
                    field: "time_info",
                    title: "时间信息",
                    type: "html",
                    formatter: function ({ cellValue, row, column }) {
                        let html =
                            "创建时间：" +
                            Vue.prototype.parseTime(row.create_time);
                        html +=
                            "<br/>过期时间：" +
                            Vue.prototype.parseTime(row.expire_time);
                        html +=
                            "<br/>退款时间：" +
                            Vue.prototype.parseTime(row.refund_time);
                        return html;
                    },
                },
                { field: "out_order_no", title: "用户订单号", visible: false },
                { field: "order_no", title: "系统订单号", visible: false },
                { field: "ip", title: "IP地址", visible: false },
                {
                    field: "order_amount",
                    title: "下单金额",
                    width: 100,
                    visible: false,
                },
                { field: "amount", title: "金额", width: 100, visible: false },
                { field: "fee", title: "费用", width: 100, visible: false },
                {
                    field: "json_param",
                    title: "请求参数",
                    width: 100,
                    visible: false,
                },
                {
                    field: "status",
                    title: "状态",
                    width: 100,
                    slots: { default: "statustpl" },
                    options: [
                        { key: "0", value: "待处理" },
                        { key: "1", value: "处理成功" },
                        { key: "2", value: "处理失败" },
                        { key: "3", value: "已退款" },
                    ],
                    options_label: "value",
                    options_value: "key",
                    is_query:true,
                    form_type:2
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    width: 180,
                    formatter: "formatDate",
                    visible: false,
                    dateRange: [
                        moment().format("yyyy-MM-DD"),
                        moment().add(1, "days").format("yyyy-MM-DD"),
                    ],
                    form_type:3,
                    is_query:true
                },
                {
                    field: "expire_time",
                    title: "过期时间",
                    width: 180,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "callback_time",
                    title: "回调时间",
                    width: 180,
                    formatter: "formatDate",
                    visible: false,
                },
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "detail" },
                // },
            ],

            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 表格数据
            list: [],
            // 是否显示弹出层
            open: false,
            chargeTypeOptions: [
                { key: "0", value: "计次" },
                { key: "1", value: "按实际付费" },
            ],
            // 类型数据字典
            statusOptions: [
                { key: "0", value: "待处理" },
                { key: "1", value: "处理成功" },
                { key: "2", value: "处理失败" },
                { key: "3", value: "已退款" },
            ],
            // 日期范围
            dateRange: [
                moment().format("yyyy-MM-DD"),
                moment().add(1, "days").format("yyyy-MM-DD"),
            ],
            // 表单参数
            form: {},
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                app_name: undefined,
                user_id: undefined,
                app_id: undefined,
                api_id: undefined,
                status: undefined,
                out_order_no: undefined,
                order_no: undefined,
            },
        };
    },
    created() {
        this.queryParams = this.addDateRange(this.queryParams, this.dateRange);
        this.getList();
     
   
    },
    methods: {
        tableChange(e) {},
        chargeTypeFormat(row, column) {
            return this.selectDictLabel(
                this.chargeTypeOptions,
                row.charge_type
            );
        },
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },
        /** 查询登录日志 */
        getList() {
            this.loading = true;
            listOrder(this.queryParams).then(
                (response) => {
                    this.single = true;
                    this.multiple = true;
                    this.list = response.data.list;
                    this.total = response.data.total;
                    this.loading = false;
                }
            );
        },
        // 操作日志状态字典翻译
        statusFormat(row, column) {
            return this.selectDictLabel(this.statusOptions, row.status);
        },
        /** 搜索按钮操作 */
        handleQuery(e) {
            // this.queryParams.pageNum = 1;
            if(e){
                this.queryParams = this.deepCopy(e)
            }
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [
                moment().format("yyyy-MM-DD"),
                moment().add(1, "days").format("yyyy-MM-DD"),
            ];
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.multiple = !selection.length;
        },
        /** 详细按钮操作 */
        handleView(row) {
            this.open = true;
            this.form = row;
        },
    },
};
</script>

