<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="标题" prop="title">
                <el-input
                    v-model="queryParams.title"
                    placeholder="请输入标题"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="规则" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入规则"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            show-overflow
            highlight-hover-row
            row-id="id"
            :tree-config="treeConfig"
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="userRuleList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template> -->

            <!--默认操作按钮-->
            <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

            <!--自定义空数据模板-->
            <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="userRuleList"
            :tree-config="treeConfig"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            :pager="false"
            :checkbox="false"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <!-- <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                > -->
            </template>
            <template slot="is_menu" slot-scope="{ row }">
                {{ isMenuFormat({ row }) }}
            </template>
            <template slot="status" slot-scope="{ row }">
                {{ statusFormat({ row }) }}
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>
        <!-- 添加或修改用户规则对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="600px"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="是否菜单">
                    <el-radio-group v-model="form.is_menu">
                        <el-radio key="1" label="1">是</el-radio>
                        <el-radio key="0" label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="父级" prop="pid" v-if="form.pid !== 0">
                    <treeselect
                        v-model="form.pid"
                        :options="userRuleOptions"
                        :normalizer="normalizer"
                        placeholder="选择父级"
                    />
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title" placeholder="请输入标题" />
                </el-form-item>
                <el-form-item label="规则" prop="name">
                    <el-input v-model="form.name" placeholder="请输入规则" />
                </el-form-item>
                <el-form-item label="权重" prop="weigh">
                    <el-input-number
                        v-model="form.weigh"
                        controls-position="right"
                        :min="0"
                    />
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input
                        v-model="form.remark"
                        type="textarea"
                        placeholder="备注"
                    />
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio-group v-model="form.status">
                        <el-radio
                            v-for="dict in statusOptions.values"
                            :key="dict.key"
                            :label="dict.key"
                            >{{ dict.value }}</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listUserRule,
    getUserRule,
    addUserRule,
    updateUserRule,
    delUserRule,
} from "@/api/ucenter/userrule";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
    name: "ucenter-userrule-list",
    components: { Treeselect },
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            treeConfig: {
                trigger: "row",
                expandAll: true,
            },
            // 列
            tableColumn: [
                /*{
                    field: "id",
                    title: "ID",
                    width: 100,
                    fixed: "left",
                    treeNode: true,
                },
                { field: "title", title: "标题" },*/
                {
                    field: "title",
                    title: "标题",
                    fixed: "left",
                    treeNode: true,
                },
                { field: "name", title: "规则" },
                {
                    field: "is_menu",
                    title: "是否菜单",
                    // formatter: this.isMenuFormat,
                    slots: {
                        default: "is_menu",
                    },
                    form_type:2,
                    options:[{label:'是',value:'1'},{label:'否',value:'0'}],
                    options_label:'label',
                    options_value:'value'
                },
                { field: "remark", title: "备注" },
                {
                    field: "status",
                    title: "状态",
                    // formatter: this.statusFormat,
                    slots: {
                        default: "status",
                    },
                    form_type:2,
                    options:[],
                    options_label:'value',
                    options_value:'key'
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    formatter: "formatDate",
                    form_type:3
                },
                // {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 用户规则表格数据
            userRuleList: [],
            userRuleOptions: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 状态数据字典
            statusOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    {
                        required: true,
                        message: "标题不能为空",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "规则不能为空",
                        trigger: "blur",
                    },
                ],
                weigh: [
                    {
                        required: true,
                        message: "权重不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
      getUserPool() {
        const sps = this.$route.path.split('/')
        return sps[2]
      },
        //列表的排序和筛选
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询用户规则列表 */
        getList() {
            this.loading = true;
            listUserRule(this.getUserPool(), this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.userRuleList = this.handleTree(
                    response.data.list,
                    "id",
                    "pid"
                );
                this.statusOptions = response.data.statusOptions;
                this.total = response.data.total;
                this.loading = false;
                for(let i=0;i<this.tableColumn.length;i++){
                    if(this.tableColumn[i].field === 'status'){
                        this.tableColumn[i].options =  this.statusOptions.values
                        return
                    }
                }
            });
        },
        normalizer(node) {
            console.log(node);
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node.id,
                label: node.title,
                children: node.children,
            };
        },
        isMenuFormat({ row }) {
            if (row.is_menu == "1") {
                return "是";
            } else {
                return "否";
            }
        },
        statusFormat({ row }) {
            return this.selectDictLabel(this.statusOptions.values, row.status);
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                title: undefined,
                name: undefined,
                is_menu: "1",
                weigh: 0,
                status: "1",
                remark: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加用户规则";
            listUserRule(this.getUserPool()).then((response) => {
                this.userRuleOptions = this.handleTree(
                    response.data.list,
                    "id",
                    "pid"
                );
            });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getUserRule(this.getUserPool(), id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    this.form = {
                        id: data.id,
                        pid: data.pid != 0 ? data.pid : undefined,
                        is_menu: data.is_menu,
                        name: data.name,
                        title: data.title,
                        weigh: data.weigh,
                        status: data.status,
                        remark: data.remark,
                    };
                }

                this.open = true;
                this.title = "修改用户规则";
            });

            listUserRule(this.getUserPool()).then((response) => {
                // 去掉本级和下级
                const userRules = [];
                for (let i = 0; i < response.data.list.length; i++) {
                    const userRule = response.data.list[i];
                    if (userRule.id == id || userRule.pid == id) {
                        continue;
                    }
                    userRules.push(userRule);
                }

                this.userRuleOptions = this.handleTree(userRules, "id", "pid");
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != undefined) {
                        updateUserRule(this.getUserPool(), this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addUserRule(this.getUserPool(), this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(
                '是否确认删除用户规则编号为"' + ids + '"的数据项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    return delUserRule(this.getUserPool(), ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有用户规则数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportCategory(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
    },
};
</script>
