<!-- 生成时间:2024-02-01 14:52:22 -->
<template>
  <div class="app-container">
    <!-- 渲染查询表单 -->

    <el-button type="primary" @click="handBookMark">确 定</el-button>
    <el-dialog
      title="书签编辑"
      :visible.sync="bookMarkForm.visible"
      :fullscreen="bookMarkForm.fullscreen"
      :close-on-press-escape="bookMarkForm.closeOnPressEscape"
      append-to-body
      :close-on-click-modal="bookMarkForm.closeOnClickModal"
      width="96%"
      border
    >
      <div slot="title">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 96%;
          "
        >
          <div class="el-dialog__title">书签编辑</div>
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="bookMarkForm.fullscreen ? '退出全屏' : '开启全屏'"
              placement="top"
            >
              <i
                class="el-icon-full-screen"
                @click="bookMarkForm.fullscreen = !bookMarkForm.fullscreen"
              ></i>
            </el-tooltip>
          </div>
        </div>
      </div>

      <MarkView
        v-if="bookMarkForm.visible"
        @handContent="getContent"
        :fileId="FileInfo.id"
        :filePath="FileInfo.url"
        :textMark="bookMarkForm.textMark"
      ></MarkView>
    </el-dialog>
  </div>
</template>

<script>
import { uploadServer, getDssInfo } from "@/api/dss/fastdfsServer.js";
import bookeditService from "@/api/digital/bookedit";
import { form } from "@/mixins/form.js";

import { getToken } from "@/utils/auth";

import Treeselect from "@riophae/vue-treeselect";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import jfImport from "@/components/jfimport/jfimport";
import JfOperationLog from "@/components/jfoperationlog/jfoperationlog";

import { getFieldWidgetByName, deepClone } from "@/vform/utils/util";
import { loadExtension } from "@/vform/extension/extension-loader";
import VFormRender from "@/vform/components/form-render/index.vue";
import { getUserProfile } from "@/api/core/system/user";

import BookMarkView from "../components/bookmarkview.vue";
import MarkView from "../components/markview.vue";

loadExtension();

export default {
  mixins: [form],
  name: "digital-bookedit-index",
  components: {
    Treeselect,
    VFormRender,
    jfImport,
    JfOperationLog,
    BookMarkView,
    MarkView,
  },

  data() {
    return {
      FileInfo: {},
      bookMarkForm: {
        visible: false,
        title: "PDF书签处理",
        fullscreen: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        width: "100%",
        action: "",
        textMark: "",
        list: [],
      },
    };
  },

  computed: {},

  beforeCreate() {},
  created() {},
  methods: {
    handBookMark() {
      this.FileInfo.id = "2327";
      this.FileInfo.url = "http://localhost:9292/group1/digital/system/user_1/general/public/中职生应用文写作.pdf";
      this.bookMarkForm.visible = true;
    },

    //回调方法
    getBookInfo(value) {
      console.log("返回：", value);
    },
    //回调方法
    getContent(value) {
      console.log("返回：", value);
    },
  },
};
</script>
<style rel="stylesheet/scss" scoped>
.form-data {
  margin: 0 -2rem;
}
.btn-orange {
  background-color: #ff9648;
  color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #2196f3;
  color: #fff;
}

::v-deep .scrolldialog {
  max-height: 82vh;
}

::v-deep .el-dialog__body {
  /* height: calc(100vh - 300px); */
  overflow-y: scroll;
}

.head-container {
  font-size: 14px;
}
::v-deep .el-dialog {
  /* height: 90vh; */
  overflow: auto;
}
</style>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
