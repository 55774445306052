import request from '@/utils/request'

export function listGbpxQuestionnaire(query) {
  return request({
    url: '/gbpx/gbpxquestionnaire/list',
    method: 'get',
    params: query
  })
}

export function getGbpxQuestionnaire(id) {
  return request({
    url: '/gbpx/gbpxquestionnaire/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxQuestionnaire(data) {
  return request({
    url: '/gbpx/gbpxquestionnaire/add',
    method: 'post',
    data: data
  })
}

export function editGbpxQuestionnaire(data) {
  return request({
    url: '/gbpx/gbpxquestionnaire/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxQuestionnaire(ids) {
  return request({
    url: '/gbpx/gbpxquestionnaire/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

