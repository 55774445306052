import request from '@/utils/request'

// 查询cms单页列表
export function listCmsPage(query) {
  return request({
    url: '/cms/page/list',
    method: 'get',
    params: query
  })
}

// 查询cms单页详细
export function getCmsPage(id) {
  return request({
    url: '/cms/page/get?id=' + id,
    method: 'get'
  })
}

// 新增cms单页
export function addCmsPage(data) {
  return request({
    url: '/cms/page/add',
    method: 'post',
    data: data
  })
}

// 修改cms单页
export function updateCmsPage(data) {
  return request({
    url: '/cms/page/edit',
    method: 'post',
    data: data
  })
}

// 删除cms单页
export function delCmsPage(id) {
  return request({
    url: '/cms/page/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
