<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>
        <!-- 
        <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            row-id="id"
            :data="channelList"
            :tree-config="treeConfig"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        > -->
        <!-- <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template> -->

        <!--默认操作按钮-->
        <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid> -->
        <jf-table
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="channelList"
            :checkbox="false"
            :tree-config="treeConfig"
            @page-change="handleSizeChange"
            :total="total"
            :pager="false"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <!-- <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                > -->
            </template>

            <template slot="type" slot-scope="{ row }">
                {{ typeFormat({ row }) }}
            </template>
            <template slot="model_id" slot-scope="{ row }">
                {{ modelFormat({ row }) }}
            </template>
            <template slot="status" slot-scope="{ row }">
                {{ statusFormat({ row }) }}
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改栏目对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-form-item label="类型" prop="type">
                    <el-radio-group
                        v-model="form.type"
                        :disabled="form.id?true:false"
                        @change="handleTypeChange"
                    >
                        <el-radio key="channel" label="channel">栏目</el-radio>
                        <el-radio key="list" label="list">列表</el-radio>
                        <el-radio key="link" label="link">外部链接</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="上级栏目" prop="parent_id">
                    <treeselect
                        v-model="form.parent_id"
                        :options="channelTree"
                        :normalizer="normalizer"
                        placeholder="无"
                    />
                </el-form-item>
              
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" placeholder="名称" />
                </el-form-item>
                <el-form-item label="图片" prop="image">
                    <el-upload
                        class="upload-demo"
                        accept="image/png, image/jpeg"
                        action="/file/upload"
                        :headers="uploadHeaders"
                        :data="uploadData"
                        :multiple="false"
                        :limit="1"
                        :file-list="imageList"
                        :on-success="handleUploadSuccess"
                        :on-error="handleUploadError"
                        :on-exceed="handleUploadExceed"
                        :on-remove="handleUploadRemove"
                        list-type="picture-card"
                    >
                        <el-button size="small" type="primary"
                            >点击上传</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                            只能上传jpg/png文件，且不超过500kb
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item
                    v-if="form.type != 'link'"
                    label="关键字"
                    prop="keywords"
                >
                    <el-input v-model="form.keywords" placeholder="关键字" />
                </el-form-item>
                <el-form-item
                    v-if="form.type != 'link'"
                    label="描述"
                    prop="description"
                >
                    <el-input
                        v-model="form.description"
                        type="textarea"
                        placeholder="请输入描述"
                    />
                </el-form-item>
                <el-form-item label="自定义名称" prop="diyname">
                    <el-input v-model="form.diyname" placeholder="自定义名称" />
                </el-form-item>
                <el-form-item
                    v-if="form.type == 'channel'"
                    label="栏目页模板"
                    prop="channeltpl"
                >
                    <el-input
                        v-model="form.channeltpl"
                        placeholder="栏目页模板"
                    />
                </el-form-item>
                <el-form-item
                    v-if="form.type == 'list'"
                    label="模型"
                    prop="model_id"
                >
                    <el-select v-model="form.model_id" placeholder="请选择">
                        <el-option
                            v-for="m in modelList"
                            :key="m.id"
                            :label="m.name"
                            :value="m.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="form.type == 'list'"
                    label="列表页模板"
                    prop="listtpl"
                >
                    <el-input v-model="form.listtpl" placeholder="列表页模板" />
                </el-form-item>
                <el-form-item
                    v-if="form.type == 'list'"
                    label="详情页模板"
                    prop="showtpl"
                >
                    <el-input v-model="form.showtpl" placeholder="详情页模板" />
                </el-form-item>
                <el-form-item
                    v-if="form.type == 'list'"
                    label="分页大小"
                    prop="pagesize"
                >
                    <el-input v-model="form.pagesize" placeholder="分页大小" />
                </el-form-item>
                <el-form-item
                    v-if="form.type == 'link'"
                    label="外部链接"
                    prop="outlink"
                >
                    <el-input v-model="form.outlink" placeholder="外部链接" />
                </el-form-item>
                <el-form-item label="权重" prop="weigh">
                    <el-input-number
                        v-model="form.weigh"
                        controls-position="right"
                        :min="0"
                    />
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio key="normal" label="normal">正常</el-radio>
                        <el-radio key="hidden" label="hidden">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listCmsChannel,
    getCmsChannel,
    addCmsChannel,
    updateCmsChannel,
    delCmsChannel,
} from "@/api/cms/channel";
import { listCmsModel } from "@/api/cms/model";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getAccessToken } from "@/utils/tokenTool";


export default {
    name: "CmsChannel",
    components: { Treeselect },
    data() {
        return {
    
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            treeConfig: {
                trigger: "row",
                expandRowKeys: [100],
            },
            // 列
            tableColumn: [
                //{type: 'checkbox', width: 60, fixed: "left"},
                //{field: 'id', title: '编号', width: 100, fixed: "left"},
                { field: "name", title: "栏目名称", treeNode: true },
                {
                    field: "type",
                    title: "栏目类型",
                    // formatter: this.typeFormat,
                    slots: {
                        default: "type",
                    },
                    options: [
                        { label: "列表", value: "list" },
                        { label: "栏目", value: "channel" },
                        { label: "外部链接", value: "link" },
                    ],
                    options_value: "value",
                    options_label: "label",
                    form_type: 2,
                },
                {
                    field: "model_id",
                    title: "模型名称",
                    // formatter: this.modelFormat,
                    slots: {
                        default: "model_id",
                    },
                    options: [],
                    options_value: "key",
                    options_label: "value",
                    form_type: 2,
                },
                { field: "items", title: "文章数量" },
                {
                    field: "status",
                    title: "状态",
                    // formatter: this.statusFormat,
                    slots: {
                        default: "status",
                    },
                    options: [
                        { label: "正常", value: "normal" },
                        { label: "隐藏", value: "hidden" },
                    ],
                    options_value: "value",
                    options_label: "label",
                    form_type: 2,
                },
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 栏目表格数据
            channelList: [],
            channelTree: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 状态数据字典
            statusOptions: [],
            // 模型列表
            modelList: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
            },
            // 表单参数
            form: {
                pagesize:10
            },
            uploadHeaders: {},
            uploadData: {},
            imageList: [],
            // 表单校验
            rules: {
                type: [
                    {
                        required: true,
                        message: "栏目类型不能为空",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "栏目名称不能为空",
                        trigger: "blur",
                    },
                ],
                diyname: [
                    {
                        required: true,
                        message: "自定义名称不能为空",
                        trigger: "blur",
                    },
                ],
                model_id: [
                    {
                        required: true,
                        message: "模型不能为空",
                        trigger: "blur",
                    },
                ],
                // channeltpl: [
                //     {
                //         required: true,
                //         message: "栏目页模板不能为空",
                //         trigger: "blur",
                //     },
                // ],
                // listtpl: [
                //     {
                //         required: true,
                //         message: "列表页模板不能为空",
                //         trigger: "blur",
                //     },
                // ],
                // showtpl: [
                //     {
                //         required: true,
                //         message: "详情页模板不能为空",
                //         trigger: "blur",
                //     },
                // ],
                pagesize: [
                    {
                        required: true,
                        message: "分页大小不能为空",
                        trigger: "blur",
                    },
                ],
                outlink: [
                    {
                        required: true,
                        message: "外部链接不能为空",
                        trigger: "blur",
                    },
                ],
                weigh: [
                    {
                        required: true,
                        message: "栏目权重不能为空",
                        trigger: "blur",
                    },
                ],
                status: [
                    {
                        required: true,
                        message: "状态不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {

        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询栏目列表 */
        getList() {
            this.loading = true;
            listCmsModel().then((res) => {
                this.modelList = res.data.list;
                listCmsChannel(this.queryParams).then((response) => {
                    this.single = true;
                    this.multiple = true;
                    this.channelList = this.handleTree(
                        response.data.list,
                        "id",
                        "parent_id"
                    );
                    this.channelTree = this.handleTree(
                        response.data.list,
                        "id",
                        "parent_id"
                    );
                    this.total = response.data.total;
                    this.loading = false;
                });
                for (let i = 0; i < this.tableColumn.length; i++) {
                    if (this.tableColumn[i].field === "model_id") {
                        this.tableColumn[i].options = res.data.list;
                        this.tableColumn[i].options_value = "id";
                        this.tableColumn[i].options_label = "name";
                    }
                }
            });
        },
        typeFormat({ row }) {
            if (row.type == "list") {
                return "列表";
            } else if (row.type == "channel") {
                return "栏目";
            } else if (row.type == "link") {
                return "外部链接";
            }
            return row.type;
        },
        statusFormat({ row }) {
            if (row.status == "normal") {
                return "正常";
            } else {
                return "隐藏";
            }
        },
        modelFormat({ row }) {
            for (let i = 0; i < this.modelList.length; i++) {
                const m = this.modelList[i];
                if (m.id == row.model_id) {
                    console.log(m.name, "hhh");
                    return m.name;
                }
            }
            if (row.model_id > 0) {
                return row.model_id;
            } else {
                return "";
            }
        },
        normalizer(node) {
            //console.log(node)
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node.id,
                label: node.name,
                children: node.children,
                isDisabled: node.type !== "channel",
            };
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.uploadHeaders = {
                Authorization: getAccessToken(),
            };
            this.uploadData = {
                category: "cms",
                public: "1",
            };
            this.imageList = [];
            //this.rules = this.channelRules
            this.form = {
                id: undefined,
                type: "channel",
                name: undefined,
                weigh: 0,
                status: "normal",
                description: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.form.pagesize=10
            this.title = "添加栏目";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getCmsChannel(id).then((response) => {
                //this.form = response.data;
                const data = response.data;
                if (data) {
                    this.form = {
                        id: data.id,
                        type: data.type,
                        model_id: data.model_id,
                        parent_id:
                            data.parent_id > 0 ? data.parent_id : undefined,
                        name: data.name,
                        image: data.image,
                        keywords: data.keywords,
                        description: data.description,
                        outlink: data.outlink,
                        diyname: data.diyname,
                        channeltpl: data.channeltpl,
                        listtpl: data.listtpl,
                        showtpl: data.showtpl,
                        pagesize: data.pagesize ||10,
                        weigh: data.weigh,
                        status: data.status,
                    };
                }
                if (data.image) {
                    this.imageList = [
                        {
                            url: data.image,
                        },
                    ];
                }
                this.open = true;
                this.title = "修改栏目";
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != undefined) {
                        updateCmsChannel(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addCmsChannel(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(
                '是否确认删除栏目编号为"' + ids + '"的数据项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(function () {
                    return delCmsChannel(ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有栏目数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportChannel(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
        handleTypeChange(value) {
            this.$refs["form"].clearValidate();
        },
        handleUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList);
            file.url = response.data.url;
            this.form.image = response.data.url;
        },
        handleUploadError(err) {
            console.log(err);
            this.form.image = "";
            if (
                err &&
                (err.message.indexOf("larger than") > 0 ||
                    err.message.indexOf("413") > 0)
            ) {
                this.msgError("上传文件失败，文件大小超过限制");
            } else {
                this.msgError("上传文件失败");
            }
        },
        handleUploadExceed() {
            this.msgError("只能上传一个文件，请删除后重新上传");
        },
        handleUploadRemove() {
            this.form.image = "";
        },
    },
};
</script>
