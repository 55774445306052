<template>
 <el-row style="padding: 10px;">
    <el-col :span="14" class="card-box" style="overflow:auto"> 
      <ul      
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled">
        <li :key="item" v-for="item in warnList"  style="list-style: none">
          <el-card style="height: 120px;margin-top: 10px;position: relative;">          
            <div slot="header" >
              <div style="display:inline-block;float:left;margin-top: 0px;font-size:13px;" >
                警告类型：{{warnType(item.warn_type)}}
              </div>            
              <div style=" display:inline-block ; float:right">              
                <div style="font-size:13px;color:#859094;margin-left: 10px">警告时间：{{timestampToTime(item.create_time)}}</div>               
              </div>
              <div  style = "clear:both;" ></div >
            </div>
            <div style=" display:inline-block;width: 100%;height:30px">
              <span style="font-size:13px;color:#859094;" >{{item.content}}</span>
            </div>
            <div style="border-top:1px dashed skyblue;display:inline-block ; float:bottom;height:20px;width: 100%;">
              <el-button size="mini"  class="link-type" style="border: 0; display:inline-block ; float:right;" 
                @click="toInfoDialog(item)">
                <span style="font-size:12px" >阅读处理 ></span>
              </el-button>
            </div>
          </el-card>          
        </li>
      </ul>
    <div v-if="loading" style="width: 100%;text-align:center">加载中...</div>
    <div v-if="noMore" style="width: 100%;text-align:center">
      没有更多了
    </div> 
    </el-col>
    <el-col :span="10" class="card-box">
        <el-card style="height: 100%;width: 100%;margin-top: 10px">
          <div slot="header">
            警告统计
          </div>
          <div class="el-table el-table--enable-row-hover el-table--medium">              
            <v-chart :options="option"                  
              autoresize />
          </div>
          <div class="verify-line" style="margin-top:15px">
            <v-chart :options="lineOption"                  
              autoresize />
          </div>
        </el-card>
      </el-col>
    <!-- 警告详情修改处理警告 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
      <el-form ref="form" :model="form" > 
        <el-descriptions title="警告信息" :colon="false" :column="3">
            <el-descriptions-item  label="设备名称">{{form.device_name}}</el-descriptions-item>            
            <el-descriptions-item  label="警告类型">{{warnType(form.warn_type)}}</el-descriptions-item>
            <el-descriptions-item label="处理类型">{{dealwithType(form.is_dealwith)}}</el-descriptions-item>
            <el-descriptions-item label="预警数值">{{form.value}}</el-descriptions-item>            
            <el-descriptions-item label="创建时间">{{timestampToTime(form.create_time)}}</el-descriptions-item>
            <el-descriptions-item />
            <el-descriptions-item label="警告备注">
              <el-tag size="small">{{form.remark}}</el-tag>
            </el-descriptions-item>   
            <el-descriptions-item /> 
            <el-descriptions-item />         
            <el-descriptions-item label="警告内容">{{form.content}}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="类型信息" :colon="false" style="margin-top:20px">
            <el-descriptions-item label="警告名称">{{warn.name}}</el-descriptions-item>
            <el-descriptions-item  label="警告类型">{{warnType(warn.warn_type)}}</el-descriptions-item>
            <el-descriptions-item label="当前状态">{{statusType(warn.status)}}</el-descriptions-item>
            <el-descriptions-item label="持续时间">{{warn.keep_time}} S</el-descriptions-item>
            <el-descriptions-item label="预警条件">{{conditionType(warn.condition)}}</el-descriptions-item>
            <el-descriptions-item  label="预警最小值">{{warn.min_value}}</el-descriptions-item>
            <el-descriptions-item label="预警最大值">{{warn.max_value}}</el-descriptions-item>  
            <el-descriptions-item label="触发对象" v-if="warn.device_id==0">产品</el-descriptions-item>   
            <el-descriptions-item label="触发对象" v-if="warn.device_id >0">设备</el-descriptions-item>       
            <el-descriptions-item label="属性名称">{{warn.attribute_name}}</el-descriptions-item>
            <el-descriptions-item label="警告备注">{{warn.remark}}</el-descriptions-item>
            <el-descriptions-item label="警告内容">{{warn.content}}</el-descriptions-item>
        </el-descriptions>     
        <el-descriptions title="操作" style="margin-top:20px"/>  
        <div style=" display:inline-block ; "> 
              <!-- <el-button
                style="margin-left: 10px"
                type="success"                 
                size="mini" 
                @click="updateWarn(1)"
              >标志已读</el-button>
              <el-button
                style="margin-left: 10px"
                type="success"                 
                size="mini" 
                @click="updateWarn(3)"
              >标志已处理</el-button>  
              <el-button
                style="margin-left: 10px"
                type="success"                 
                size="mini" 
                @click="updateWarn(4)"
              >忽略警告</el-button>  -->             
              <el-button
                style="margin-left: 10px"
                type="success"                 
                size="mini" 
                @click="addWarnDealwith"
              >设备上报</el-button>
              <el-button
                style="margin-left: 10px"
                type="success"                 
                size="mini" 
              >
                <router-link :to="'/iot/device/info/'+form.device_id" class="link-type" style="align-items: center;">
                  <span>查看设备</span>
                </router-link>
              </el-button>
            </div>    
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">删 除</el-button>
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>
<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/axis'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import { listIotUserWarn,delIotUserWarn,updateIotUserWarn} from "@/api/iot/userwarn";
import { addIotWarnDealwith,getIotWarnDealwithByWarnId} from "@/api/iot/warn";
export default {  
  components: {
    'v-chart': ECharts
  },
    props: {
    info: {//这里是传进来的折线图数据
      type: Array,
      default: () => {
        return [{total:1000,month:"1",year:"2022"},{total:2000,month:"2",year:"2022"},{total:3000,month:"3",year:"2022"},
              {total:4000,month:"4",year:"2022"},{total:5000,month:"5",year:"2022"},{total:3000,month:"6",year:"2022"},
              {total:1000,month:"7",year:"2022"},{total:2000,month:"8",year:"2022"},{total:3000,month:"9",year:"2022"},
              {total:4000,month:"10",year:"2022"},{total:2000,month:"11",year:"2022"},{total:8000,month:"12",year:"2022"},
            ]
      }
    }
  },
  data() {   
    const splitLineStyle = {
      show: true,
      lineStyle: {
        color: ['rgb(241, 241, 241)'],
        width: 1,
        type: 'solid'
      }
    }
    // 坐标轴线样式
    const axisLine = {
      show: true,
      lineStyle: {
        color: 'rgb(241, 241, 241, .5)'
      }
    } 
    // 坐标文字样式
    const axisLabel = {
      interval: 0,
      textStyle: {
        color: '#50576A' // 坐标值得具体的颜色
      }
    }
    return {
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      form: {},
      warn:{},
      warnList:[],
      warnCount:0,
      warnUnReadCount:0,
      warnTypeOptions:[],
      statusOptions:[],
      dealwithOptions:[],
      warn_time:"2022-06-21 16:00:00",
      warn_level:"一级警告",
      count: 0,
      loading: false,  
      conditionCompareList:[{id:"0",name:"等于"},{id:"1",name:"大于"},{id:"2",name:"小于"},{id:"3",name:"大于等于"},{id:"4",name:"小于等于"},
      {id:"5",name:"范围：大于最小值，小于最大值"},{id:"6",name:"范围：大于等于最小值，小于等于最大值"}],
        
      //饼图配置 一级警告、二级警告、三级警告、预警提示、平台警告、其他提示
      option: {
        // 调整边距(上下左右)
        title: {
          text: '警告占比',
          subtext: 'Scale Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },        
        series: [
          {
            name: '警告消息',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 10, name: '一级警告' },
              { value: 20, name: '二级警告' },
              { value: 30, name: '三级警告' },
              { value: 40, name: '预警提示' },
              { value: 10, name: '平台警告' },
              { value: 60, name: '其他提示' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      //线图配置
      lineOption:{
        title: {
          text: '消息统计',
          subtext: 'Census Data',
          left: 'center'
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false, // 坐标轴两边留白
          data: [],
          axisLine: axisLine,
          axisLabel: {
            // 坐标轴刻度标签的相关设置
            interval: 0, // 如果设置为 1，表示『隔一个标签显示一个标签』
            textStyle: {
              color: '#50576A' // 坐标值得具体的颜色
            },
            // 坐标轴文字的显示格式
            formatter: function (params) {
              return params + '月'
            }
          }
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '30%'],
          axisLine: axisLine,
          axisLabel: axisLabel,
          splitLine: splitLineStyle,
          // 不显示坐标轴刻度
          axisTick: {
            show: false
          }
        },
        // 高亮提示设置
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#3AD4A7'
                  },
                  {
                    offset: 1,
                    color: '#3AD4A7'
                  }
                ],
                global: false
              }
            }
          }
        },
        series: [
          {
            type: 'line',
            smooth: true,
            showSymbol: true, // 显示转折点变大
            symbol: 'circle', // 设定为实心点
            symbolSize: 1, // 设定实心点的大小
            lineStyle: {
              // 数据线的样式
              color: '#4669F5',
              width: 1
            },
            itemStyle: {
              normal: {
                // 配置转折点的样式
                color: '#3AD4A7'
              }
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(70, 105, 245, .8)' // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 255, 255, 0.5)' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0.5)' // 100% 处的颜色
                  }
                ]
              }
            },
            data: []
          }
        ]
      }
    } 
    
  }, 
 watch: {//深度监听传过来的折线图数据
    info: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.length) {
          let xAxis = []
          let yAxis = []
          val.forEach((item, index) => {
            xAxis.push(item.month)
            yAxis.push(item.total)
          })
          this.lineOption.series[0].data = yAxis
          this.lineOption.xAxis.data = xAxis
        } else {
          const lineLen = 12
          let xAxis = []
          for (let i = 1; i <= lineLen; i++) {
            xAxis.push(i)
          }
          this.lineOption.series[0].data = []
          this.lineOption.xAxis.data = xAxis
        }
      }
    }
  },
  created() {   
    this.getWarnList();
  },  
  computed: {
      noMore () {
        return this.count >= 10
      },
      disabled () {
        return this.loading || this.noMore
      }
  },
  methods: {
    addWarnDealwith(){      
      var warn={
        warn_id:this.form.id
      };
      getIotWarnDealwithByWarnId(warn).then(resp=>{
        if(resp.data.id>0){
          this.msgSuccess("请勿重复提交！");
        }else{
          var warndealatt={
            device_id:this.form.device_id,
            device_name:this.form.device_name,
            warn_type:this.form.warn_type,
            warn_content:this.form.content,
            warn_value:this.form.value,
            dealwith_type:6,
            product_id:this.form.warn.product_id,
            service_id:this.form.warn.service_id,
            attribute_name:this.form.warn.attribute_name,
            dept_ids:this.form.dept_ids,
            warn_time:this.form.create_time,
            warn_id:this.form.id,        
          };
          addIotWarnDealwith(warndealatt).then(response=>{
            if(response.code==0){
              this.updateWarn(6);  
              this.msgSuccess("警告上报成功!");                  
            }
          });
        }
      });;      
    },
    updateWarn(type){
      this.form.is_dealwith=type;     
      updateIotUserWarn(this.form).then(response=>{
        if(response.code==0){
          this.msgSuccess("修改成功");
          this.getWarnList();
        }
      });
    },
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
            this.$confirm('是否确认删除该警告的数据项?', "警告", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(function() {
              
            }).then(() => {
              delIotUserWarn(this.form.id).then(response=>{
                this.open=false;
                this.getWarnList();
                this.msgSuccess("删除成功");
              });
              
            }).catch(function() {});  
          } 
        }
      });
    },
    toInfoDialog(item){
      this.form=item;
      this.warn=item.warn;
      this.title= "消息详情";
      this.open=true;        
    },    
    cancel(){
      this.open=false;
    },
    conditionType(type){
       var label="";
       this.conditionCompareList.forEach(element=>{
        if(type==element.id){
          label=element.name 
        }
      });
      return label;
    },
    warnType(type){
      var label="";
      this.warnTypeOptions.forEach(element=>{
        if(type==element.key){
          label=element.value 
        }
      });
      return label;
    },
    statusType(type){
      var label=""
      this.statusOptions.forEach(element=>{
        if(type==element.key){
          label=element.value 
        }
      });
      return label;
    },
    dealwithType(type){
      var label=""
      this.dealwithOptions.forEach(element=>{
        if(type==element.key){
          label=element.value 
        }
      });
      return label;
    },
    getWarnList(){
      var warnqueryParams={
        pageNum: 1,
        pageSize: 10        
      };
      listIotUserWarn(warnqueryParams).then(response=>{        
        this.warnList=response.data.list;
        this.warnCount=response.data.total;  
        this.warnUnReadCount=response.data.unread_total;  
        this.warnTypeOptions=response.data.warnTypeOptions.values;
        this.statusOptions=response.data.statusOptions.values; 
        this.dealwithOptions=response.data.dealwithOptions.values;  
      })
    },
    load () {
        this.loading = true
        setTimeout(() => {
          this.count += 10
          this.loading = false
        }, 100)
    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
  }
}
</script>