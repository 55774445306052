import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2024-01-30 11:51:23
class Intarget {
// 查询intarget列表
 listIntarget(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/product/intarget/list',
        method: 'post',
        data: data
    })
}

// 查看intarget
 getIntarget ( id) {
    return request({
        url: '/product/intarget/get?id='+id,
        method: 'get'
    })
}


// 新增intarget
 addIntarget(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/product/intarget/add',
        method: 'post',
        data: postdata
    })
}

// 修改intarget
 editIntarget(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid,
          dataid:data.id
      }

    return request({
        url: '/product/intarget/edit',
        method: 'post',
        data: postdata
    })
}

// 删除intarget
 delIntarget(ids) {
    return request({
        url: '/product/intarget/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 intargetImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/product/intarget/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 intargetExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/product/intarget/export?` + parata.join("&") ;


 }

 //下导入模板
  intargetimportTemplate() {

     return   `/product/intarget/importTemplate`   ;

  }

}

const instance = new Intarget();
export default instance;