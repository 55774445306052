<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="分类" prop="version">
        <el-input
          v-model="queryParams.version"
          placeholder="请输入分类"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="名称" prop="node_name">
        <el-input
          v-model="queryParams.node_name"
          placeholder="请输入名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="状态" prop="sys_status">
        <el-select v-model="queryParams.sys_status" placeholder="状态" clearable size="small">
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
     <el-row/>

    <el-row :gutter="20">
      <el-col :span="4" v-for="node in iotnodeList" :key="node.id" class="col-item-container">
        <el-card :body-style="{ padding: '0px' }">
          <img :src="node.screenshot || '/images/iot/gd.png'" class="image"  v-if="node.version=='广东'">
          <img :src="node.screenshot || '/images/iot/bj.png'" class="image"  v-if="node.version=='北京'">
          <img :src="node.screenshot || '/images/iot/gx.png'" class="image"  v-if="node.version=='广西'">
          <div style="padding: 10px;">
            <div style="align-items: center; width:100%;">
            <router-link :to="'/iot/node/info/'+node.id" class="link-type" style="align-items: center;">
                <span>{{ node.version }}-{{ node.node_name }}</span>
              </router-link>

            </div>
            <!-- <div class="bottom"  style="padding: 10px;">
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-edit" :command="makeCommand('edit', node)">修改</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-delete" :command="makeCommand('delete', node)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div> -->
          </div>
        </el-card>
      </el-col>
<!--      <el-col :span="4" class="col-item-container">
        <el-card :body-style="{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
          <div class="card-add" @click="handleAdd">
            <div>
              <i class="el-icon-plus" style="font-size: 40px"></i>
            </div>
            <div style="font-size: 14px; margin-top: 10px;">新建节点</div>
          </div>
        </el-card>
      </el-col>-->
    </el-row>

    <pagination
      v-show="total>0"
      layout="total, prev, pager, next, jumper"
      :total="total"
      :limit.sync="queryParams.pageSize"
      :page.sync="queryParams.pageNum"
      @pagination="getList"
    />

    <!-- 添加或修改对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
      <el-form ref="form" :model="form" :rules="form.id ? rulesEdit : rulesAdd" label-width="80px">
        <el-form-item label="分类" prop="version">
          <el-input v-model="form.version" placeholder="分类" />
        </el-form-item>
        <el-form-item label="名称" prop="node_name">
          <el-input v-model="form.node_name" placeholder="名称" />
        </el-form-item>
        <el-form-item label="状态" prop="sys_status">
          <el-radio-group v-model="form.sys_status">
            <el-radio
              v-for="dict in statusOptions"
              :key="dict.key"
              :label="dict.key"
            >{{dict.value}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="主节点" prop="is_main">
          <el-radio-group v-model="form.is_main">
            <el-radio
              v-for="dict in ismain"
              :key="dict.key"
              :label="dict.key"
            >{{dict.value}}</el-radio>
          </el-radio-group>
          <label style="color: red; margin-left: 50px;"  >( 一个IP段只能存在一个主节点 )</label>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listIotNode, getIotNode, addIotNode, updateIotNode, delIotNode } from "@/api/iot/node";

export default {
  name: "IotNode",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 大屏表格数据
      iotnodeList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      ismain:[
        {
          key:'0',
          value:"否"
        },
        {
          key:'1',
          value:"是"
        }
      ],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        node_name: undefined,
        version: undefined,
        sys_status: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rulesAdd: {
        node_name: [
          { required: true, message: "节点名称不能为空", trigger: "blur" }
        ],
        version: [
          { required: true, message: "节点分类不能为空", trigger: "blur" }
        ]
      },
      rulesEdit: {
        node_name: [
          { required: true, message: "节点名称不能为空", trigger: "blur" }
        ],
        version: [
          { required: true, message: "节点分类不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询节点列表 */
    getList() {
      this.loading = true;
      listIotNode(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;
        this.statusOptions = response.data.statusOptions.values;
        this.iotnodeList = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      }).catch(function (error){
        console.log(error);
      });
    },
    statusFormat({row}) {
      return this.selectDictLabel(this.statusOptions, row.sys_status);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        version: undefined,
        node_name: undefined,
        sys_status: "1",
        remark: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },
    handleCommand(cmd) {
      if (cmd.cmd === 'edit') {
        this.handleUpdate(cmd.item)
      } else if (cmd.cmd === 'delete') {
        this.handleDelete(cmd.item)
      }
    },
    makeCommand(cmd, item) {
      return {
        cmd: cmd,
        item: item
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加节点";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getIotNode(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改节点";
      }).catch(function (error){
        console.log(error);
      });
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {            
            updateIotNode(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            }).catch(function (error){
              console.log(error);
            });
          } else {
            //this.form.is_main = '1'
            this.form.run_time = 0
            addIotNode(this.form).then(response => {
              if (response.data.id > 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            }).catch(function (error){
              console.log(error);
            });
          }
        }
      });
    },    
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id;
      this.$confirm('是否确认删除节点"' + row.node_name + '"?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotNode(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    }
  }
};
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.col-item-container {
  margin-bottom: 20px;
}
.col-item-container .el-card {
  height: 260px;
}
.card-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #409EFF;
  cursor: pointer;
}
.col-item-container img {
  width: 100%;
  height: 180px;
}
.col-item-container .bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.col-item-container .bottom .el-dropdown {
  height: 16px;
}
</style>
