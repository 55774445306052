<template>
<div class="app-container">
	<el-form
		:model="queryParams"
		ref="queryForm"
		:inline="true"
		label-width="68px"
		>
		<el-form-item label="用户池" prop="userpool">
			<el-input
				v-model="queryParams.userpool"
				placeholder="请输入用户池"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
    <el-form-item label="分类" prop="category">
      <dict-select v-model="queryParams.category" type="sys_app_category" :multiple="false" placeholder="请选择分类" />
    </el-form-item> 
		<el-form-item label="名称" prop="name">
			<el-input
				v-model="queryParams.name"
				placeholder="请输入名称"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item>
			<el-button
				type="cyan"
				icon="el-icon-search"
				size="mini"
				@click="handleQuery"
				>搜索</el-button
			>
			<el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
				>重置</el-button
			>
		</el-form-item>
	</el-form>

	<vxe-grid
		resizable
		ref="xGrid"
		row-id="id"
		stripe
		show-overflow
		highlight-hover-row
		:loading="loading"
		:toolbar-config="tableToolbar"
		:columns="tableColumn"
		:data="dataList"
		@checkbox-change="checkboxChangeEvent"
		@checkbox-all="checkboxChangeEvent"
	>
		<template #toolbar_buttons>
			<el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAdd"
				v-hasPermi="[
					'/core/application/add',
				]"
				>新增</el-button
			>

			<el-button
				type="success"
				icon="el-icon-edit"
				size="mini"
				:disabled="single"
				@click="handleUpdate"
				v-hasPermi="[
					'/core/application/get',
					'/core/application/edit',
				]"
				>修改</el-button
			>

			<el-button
				type="danger"
				icon="el-icon-delete"
				size="mini"
				:disabled="multiple"
				@click="handleDelete"
				v-hasPermi="[
					'/core/application/del',
				]"
				>删除</el-button
			>
		</template>

    <template #publish="{row}">
      <el-switch v-model="row.publish" active-value="1" inactive-value="0"
        @change="handlePublishChange(row)"></el-switch>
    </template>

		<template #defaultopr="{ row }">
      <el-button
				size="mini"
				type="text"
				icon="el-icon-view"
				@click.stop="handlePreview(row)"
				>预览
			</el-button>
			<el-button
				size="mini"
				type="text"
				icon="el-icon-edit"
				@click.stop="handleUpdate(row)"
				>修改
			</el-button>
      <el-button
				size="mini"
				type="text"
				icon="el-icon-menu"
				@click.stop="handleAuthRule(row)"
        :disabled="row.name == 'admin'"
				>功能
			</el-button>
      <el-button
				size="mini"
				type="text"
				icon="el-icon-setting"
				@click.stop="handleSetting(row)"
				>配置
			</el-button>
			<el-button
				size="mini"
				type="text"
				icon="el-icon-delete"
				@click.stop="handleDelete(row)"
				>删除
			</el-button>
		</template>

		<template #empty>
			<span>
				<p>暂无数据</p>
			</span>
		</template>
	</vxe-grid>

	<vxe-pager
		background
		size="small"
		:loading="loading"
		:current-page.sync="queryParams.pageNum"
		:page-size.sync="queryParams.pageSize"
		:total="total"
		@page-change="handlePageChange"
	>
	</vxe-pager>

	<el-dialog
		:close-on-click-modal="false"
		:title="title"
		:visible.sync="open"
		width="800px"
		append-to-body
	>
		<el-form ref="form" :model="form" :rules="rules" size="medium" label-width="120px">
			<el-row>
				<!--<el-col :span="24">
					<el-form-item label="" prop="userpool">
						<el-input
							v-model="form.userpool"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
        <el-col :span="24">
					<el-form-item label="分类" prop="category">
						<dict-select v-model="form.category" type="sys_app_category" :multiple="false" placeholder="请选择分类" />
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="名称" prop="name">
						<el-input
							v-model="form.name"
							placeholder="请输入名称"
							clearable
              :disabled="form.id > 0"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="显示名称" prop="display_name">
						<el-input
							v-model="form.display_name"
							placeholder="请输入显示名称"
							clearable
						/>
					</el-form-item> 
				</el-col>
        <el-col :span="24">
					<el-form-item label="版本" prop="version">
						<el-input
							v-model="form.version"
							placeholder="请输入版本"
							clearable
						/>
					</el-form-item> 
				</el-col>
        <!--<el-col :span="24">
					<el-form-item label="价格" prop="sell_price">
						<el-input
							v-model="form.sell_price"
							placeholder="请输入价格"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
        <el-col :span="24">
					<el-form-item label="图标" prop="icon">
						<el-popover
              placement="bottom-start"
              width="630"
              trigger="click"
              @show="$refs['iconSelect'].reset()"
            >
              <IconSelect ref="iconSelect" @selected="selected" />
              <el-input slot="reference" v-model="form.icon" placeholder="点击选择图标" readonly>
                <i v-if="form.icon && form.icon.indexOf('el-icon-') === 0" slot="prefix" :class="'el-icon ' +form.icon" style="font-size: 1.2em"/>
                <i v-else-if="form.icon && form.icon.indexOf('fa fa-') === 0" slot="prefix" :class="form.icon" style="font-size: 1.2em"/>
                <svg-icon
                  v-else-if="form.icon"
                  slot="prefix"
                  :icon-class="form.icon"
                  class="el-input__icon"
                  style="height: 32px;width: 16px;"
                />
                <i v-else slot="prefix" class="el-icon-search el-input__icon" />
              </el-input>
            </el-popover>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="LOGO" prop="logo">
						<image-upload v-model="form.logo" :multiple="false" :limit="1" />
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="首页地址" prop="homepage_url">
						<el-input
							v-model="form.homepage_url"
							placeholder="请输入首页地址"
							clearable
						/>
					</el-form-item> 
				</el-col>
        <el-col :span="24">
					<el-form-item label="打开方式" prop="open_type">
						<el-select v-model="form.open_type" placeholder="请选择">
              <el-option label="原窗口" value="current_window"></el-option>
              <el-option label="新窗口" value="new_window"></el-option>
            </el-select>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="描述" prop="description">
						<el-input
							v-model="form.description"
							placeholder="请输入描述"
              type="textarea"
              rows="3"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<!--<el-col :span="24">
					<el-form-item label="" prop="cert">
						<el-input
							v-model="form.cert"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
        <el-col :span="24">
					<el-form-item label="登录方式" prop="signin_types">
           <dict-select v-model="form.signin_types" type="sys_app_signin_type" :multiple="true" placeholder="请选择登录方式" />
					</el-form-item> 
				</el-col>
        <el-col :span="24">
					<el-form-item label="启用注册" prop="enable_sign_up">
            <el-switch v-model="form.enable_sign_up" active-value="1" inactive-value="0"></el-switch>
					</el-form-item> 
				</el-col>
        <el-col v-if="form.enable_sign_up=='1'" :span="24">
					<el-form-item label="注册方式" prop="signup_types">
           <dict-select v-model="form.signup_types" type="sys_app_signup_type" :multiple="true" placeholder="请选择注册方式" />
					</el-form-item> 
				</el-col>
        <el-col :span="24">
					<el-form-item label="启用找回密码" prop="enable_find_password">
            <el-switch v-model="form.enable_find_password" active-value="1" inactive-value="0"></el-switch>
					</el-form-item> 
				</el-col>
        <el-col v-if="form.enable_find_password=='1'" :span="24">
					<el-form-item label="找回密码方式" prop="find_password_types">
           <dict-select v-model="form.find_password_types" type="sys_app_find_password_type" :multiple="true" placeholder="请选择找回密码方式" />
					</el-form-item> 
				</el-col>
				<!--<el-col :span="24">
					<el-form-item label="启用密码登录" prop="enable_password">
            <el-switch v-model="form.enable_password"></el-switch>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_signin_session">
						<el-input
							v-model="form.enable_signin_session"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_auto_signin">
						<el-input
							v-model="form.enable_auto_signin"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="启用验证码登录" prop="enable_code_signin">
            <el-switch v-model="form.enable_code_signin"></el-switch>
					</el-form-item> 
				</el-col>-->
        <el-col :span="24">
					<el-form-item label="启用图形验证码" prop="enable_captcha">
            <el-switch v-model="form.enable_captcha" active-value="1" inactive-value="0"></el-switch>
					</el-form-item> 
				</el-col>
        <el-col :span="24">
					<el-form-item label="首次登录" prop="first_login_actions">
           <dict-select v-model="form.first_login_actions" type="sys_app_first_login_action" :multiple="true" placeholder="" />
					</el-form-item> 
				</el-col>
				<!--<el-col :span="24">
					<el-form-item label="" prop="enable_saml_compress">
						<el-input
							v-model="form.enable_saml_compress"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_web_authn">
						<el-input
							v-model="form.enable_web_authn"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_link_with_email">
						<el-input
							v-model="form.enable_link_with_email"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="saml_reply_url">
						<el-input
							v-model="form.saml_reply_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="providers">
						<el-input
							v-model="form.providers"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signup_items">
						<el-input
							v-model="form.signup_items"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="grant_types">
						<el-input
							v-model="form.grant_types"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col v-if="form.id" :span="24">
					<el-form-item label="client_id" prop="client_id">
						<el-input
							v-model="form.client_id"
							placeholder="请输入client_id"
							clearable
							:readonly="true"
						/>
					</el-form-item> 
				</el-col>
				<el-col v-if="form.id" :span="24">
					<el-form-item label="client_secret" prop="client_secret">
						<el-input
							v-model="form.client_secret"
							placeholder="请输入client_secret"
							clearable
							:readonly="true"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="可跳转地址" prop="redirect_uris">
						<el-input
							v-model="form.redirect_uris"
							placeholder="请输入可跳转地址"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="token_format">
						<el-input
							v-model="form.token_format"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="expire_in_hours">
						<el-input-number
							v-model="form.expire_in_hours"
							placeholder="请输入"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="refresh_expire_in_hours">
						<el-input-number
							v-model="form.refresh_expire_in_hours"
							placeholder="请输入"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signup_url">
						<el-input
							v-model="form.signup_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signin_url">
						<el-input
							v-model="form.signin_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="forget_url">
						<el-input
							v-model="form.forget_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="affiliation_url">
						<el-input
							v-model="form.affiliation_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="terms_of_use">
						<el-input
							v-model="form.terms_of_use"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signup_html">
						<el-input
							v-model="form.signup_html"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signin_html">
						<el-input
							v-model="form.signin_html"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="theme_data">
						<el-input
							v-model="form.theme_data"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_css">
						<el-input
							v-model="form.form_css"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_css_mobile">
						<el-input
							v-model="form.form_css_mobile"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_offset">
						<el-input-number
							v-model="form.form_offset"
							placeholder="请输入"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_side_html">
						<el-input
							v-model="form.form_side_html"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_background_url">
						<el-input
							v-model="form.form_background_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="remark">
						<el-input
							v-model="form.remark"
							placeholder="请输入"
							clearable
							type="textarea"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="create_time">
						<el-input-number
							v-model="form.create_time"
							placeholder="请输入"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>-->
			</el-row>
		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitForm">确 定</el-button>
			<el-button @click="cancel">返 回</el-button>
		</div>
	</el-dialog>
  <el-dialog
		:close-on-click-modal="false"
		title="功能"
		:visible.sync="openAuthRule"
		width="800px"
		append-to-body
	>
    <el-checkbox v-model="checkedForm.checked1" @change="menuChange(1)">展开/折叠</el-checkbox>
    <el-checkbox v-model="checkedForm.checked2" @change="menuChange(2)">全选/全不选</el-checkbox>
    <el-checkbox v-model="checkedForm.checked3">父子联动</el-checkbox>
    <div v-if="openAuthRule" class="down-tree" v-loading="authLoading">
      <el-input placeholder="输入关键字进行过滤" v-model="filterText">
      </el-input>
      <div class="down-tree-list">
        <el-tree :data="menuOptions" :default-expand-all="false" show-checkbox
          @check-change="currentChange" ref="menu" node-key="id" :highlight-current="true"
          :check-strictly="!checkedForm.checked3 || menuCheckStrictly" empty-text="加载中，请稍后" :props="defaultProps"
          :filter-node-method="filterNode" @node-expand="nodeExpand"
          @node-collapse="nodeCollapse">
            <div 
              slot-scope="{ node, data }"
              :class="
                data.children
                  ? 'custom-tree-node tree-node-root'
                  : 'custom-tree-node tree-node-leaf'
              "
            >
              <div class="el-tree-node__label custom-tree-node-label" :title="node.label">{{node.label}}<span v-if="data.rename" style="color: blue">【{{data.rename}}】</span></div>
              <div v-if="data.id > 0" class="custom-tree-node-button">
                <el-dropdown-ex trigger="click" :stopPropagationOnClick="true" @command="handleCommand" @visible-change="(e) => handleDropdownVisibleChange(e, data)">
                  <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>&nbsp;
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-edit" :command="makeCommand('rename', data)">重命名</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown-ex>
              </div>
            </div>
          </el-tree>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitAuthRule">确 定</el-button>
			<el-button @click="openAuthRule=false">返 回</el-button>
		</div>
  </el-dialog>
  <el-dialog
		:close-on-click-modal="false"
		title="重命名"
		:visible.sync="openAuthRuleRename"
		width="800px"
		append-to-body
	>
    <el-form ref="formAuthRuleRename" :model="formAuthRuleRename" :rules="rulesAuthRuleRename" size="medium" label-width="120px">
			<el-row>
				<el-col :span="24">
					<el-form-item label="名称" prop="rename">
						<el-input
							v-model="formAuthRuleRename.rename"
							placeholder="请输入名称"
							clearable
						/>
					</el-form-item> 
				</el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitAuthRuleRename">确 定</el-button>
			<el-button @click="openAuthRuleRename=false">返 回</el-button>
		</div>
  </el-dialog>
  <el-dialog
		:close-on-click-modal="false"
		title="配置"
		:visible.sync="openSetting"
		width="960px"
		append-to-body
	>
    <config-group v-if="openSetting" v-model="setting" :design="designSetting"></config-group>
    <div slot="footer" class="dialog-footer">
      <div style="float: left;">
        <label>设计：</label>
        <el-switch v-model="designSetting"></el-switch>
      </div>
			<el-button type="primary" @click="submitSetting">确 定</el-button>
			<el-button @click="openSetting=false">返 回</el-button>
		</div>
  </el-dialog>
</div>
</template>
<script>
import {
	listApplication,
	getApplication,
	addApplication,
	editApplication,
	delApplication,
  publishApplication,
} from "@/api/core/system/application";
import {
  listMenu
} from "@/api/core/system/menu";
import ConfigGroup from './components/config_group'
import IconSelect from "@/components/IconSelect";
export default {
	name: "application-list",
  components: {
    ConfigGroup,
    IconSelect
  },
	data() {
		return { 
			tableToolbar: {
				perfect: true,
				zoom: true,
				custom: true,
				refresh: {
					query: this.handleQuery,
				},
				slots: {
					buttons: "toolbar_buttons",
				},
			},
			tableColumn: [
				{ type: "checkbox", width: 60, fixed: "left" },
				//{ field: "id", title: "", minWidth: 120, fixed: "left" },
				{ field: "userpool", title: "用户池", minWidth: 120 },
        { field: "category", title: "分类", minWidth: 120, formatter: this.dictFormat, dictType: 'sys_app_category' },
				{ field: "name", title: "名称", minWidth: 120 },
				{ field: "display_name", title: "显示名称", minWidth: 120 },
        { field: "version", title: "版本", minWidth: 120 },
        /*{ field: "installs", title: "安装次数", minWidth: 120 },
				{ field: "logo", title: "", minWidth: 120 },
				{ field: "homepage_url", title: "", minWidth: 120 },
				{ field: "description", title: "", minWidth: 120 },
				{ field: "cert", title: "", minWidth: 120 },
				{ field: "enable_password", title: "", minWidth: 120 },
				{ field: "enable_sign_up", title: "", minWidth: 120 },
				{ field: "enable_signin_session", title: "", minWidth: 120 },
				{ field: "enable_auto_signin", title: "", minWidth: 120 },
				{ field: "enable_code_signin", title: "", minWidth: 120 },
				{ field: "enable_saml_compress", title: "", minWidth: 120 },
				{ field: "enable_web_authn", title: "", minWidth: 120 },
				{ field: "enable_link_with_email", title: "", minWidth: 120 },
				{ field: "saml_reply_url", title: "", minWidth: 120 },
				{ field: "providers", title: "", minWidth: 120 },
				{ field: "signup_items", title: "", minWidth: 120 },
				{ field: "grant_types", title: "", minWidth: 120 },
				{ field: "client_id", title: "client_id", minWidth: 120 },
				{ field: "client_secret", title: "client_secret", minWidth: 120 },
				{ field: "redirect_uris", title: "", minWidth: 120 },
				{ field: "token_format", title: "", minWidth: 120 },
				{ field: "expire_in_hours", title: "", minWidth: 120 },
				{ field: "refresh_expire_in_hours", title: "", minWidth: 120 },
				{ field: "signup_url", title: "", minWidth: 120 },
				{ field: "signin_url", title: "", minWidth: 120 },
				{ field: "forget_url", title: "", minWidth: 120 },
				{ field: "affiliation_url", title: "", minWidth: 120 },
				{ field: "terms_of_use", title: "", minWidth: 120 },
				{ field: "signup_html", title: "", minWidth: 120 },
				{ field: "signin_html", title: "", minWidth: 120 },
				{ field: "theme_data", title: "", minWidth: 120 },
				{ field: "form_css", title: "", minWidth: 120 },
				{ field: "form_css_mobile", title: "", minWidth: 120 },
				{ field: "form_offset", title: "", minWidth: 120 },
				{ field: "form_side_html", title: "", minWidth: 120 },
				{ field: "form_background_url", title: "", minWidth: 120 },
				{ field: "remark", title: "", minWidth: 120 },*/
        {
						field: 'publish',
						title: '应用中心',
						width: 100,
						slots: {
							default: 'publish'
						}
					},
				{ field: "create_by", title: "创建者", minWidth: 120, visible: false },
				{ field: "create_time", title: "创建时间", minWidth: 120, formatter: "formatDate", visible: false },
				{ field: "update_by", title: "更新者", minWidth: 120, visible: false },
				{ field: "update_time", title: "更新时间", minWidth: 120, formatter: "formatDate" },
				{
					field: "",
					title: "操作",
					width: 300,
					fixed: "right",
					align: "center",
					slots: { default: "defaultopr" },
				},
			],
			loading: true,
			ids: [],
			single: true,
			multiple: true,
			total: 0,
			dataList: [],
			title: "",
			open: false,
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				id: undefined,
				userpool: undefined,
				name: undefined,
			},
			form: {
        auth_rules: []
      },
			rules: { 
        category: [{ required: true, message: "分类不能为空", trigger: "blur" }],
				//userpool: [{ required: true, message: "用户池不能为空", trigger: "blur" }],
				name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        display_name: [{ required: true, message: "显示名称不能为空", trigger: "blur" }],
        version: [{ required: true, message: "版本不能为空", trigger: "blur" }],
			},
      menuOptions: [],
      defaultProps: {
        children: "children",
        label: "title"
      },
      checkedForm: {
        checked1: false,
        checked2: false,
        checked3: true,
      },
      menuCheckStrictly: true,
      filterText: '',
      authLoading: false,
      openAuthRule: false,
      openAuthRuleRename: false,
      formAuthRuleRename: {},
      rulesAuthRuleRename: {},
      roleIds: [],
      openSetting: false,
      designSetting: false,
      setting: undefined
		};
	},
	created() { 
    for (let i = 0; i < this.tableColumn.length; i++) {
			const col = this.tableColumn[i]
			if (col.dictType) {
				this.getDicts(col.dictType).then(response => {
					if (response.code == 0) {
						col.dictData = response.data
						this.dataList = [].concat(this.dataList)
					}
				})
			}
		}

		this.getList();

    listMenu().then(response => {
      this.menuList = response.data.list
      const menuOptions = []
      const menu = {
        id: 0,
        title: '主类目',
        children: []
      }
      menu.children = this.handleTree(response.data.list, 'id', 'pid')
      menuOptions.push(menu)
      this.menuOptions = menuOptions
    })
	},
	methods: {
		getList() {
			this.loading = true;
			this.ids = [];
			this.single = true;
			this.multiple = true;
      this.queryParams.type = '0'
			listApplication(this.queryParams).then((response) => { 
				this.dataList = response.data.list;
				this.total = response.data.total;
				this.loading = false;
			});
		},

    eachMenu(list, callback) {
      for (let i = 0; i < list.length; i++) {
        const d = list[i]
        if (callback) {
          if (!callback(d)) {
            return false
          }
        }
        if (d.children) {
          if (!this.eachMenu(d.children, callback)) {
            return false
          }
        }
      }
      return true
    },
		
		cancel() {
			this.open = false;
			this.reset();
		},

		reset() {
			this.form = { 
				userpool: undefined,
				name: undefined,
				display_name: undefined,
				logo: undefined,
				homepage_url: undefined,
				description: undefined,
				cert: undefined,
				enable_password: undefined,
				enable_sign_up: undefined,
				enable_signin_session: undefined,
				enable_auto_signin: undefined,
				enable_code_signin: undefined,
				enable_saml_compress: undefined,
				enable_web_authn: undefined,
				enable_link_with_email: undefined,
				saml_reply_url: undefined,
				providers: undefined,
				signup_items: undefined,
				grant_types: undefined,
				client_id: undefined,
				client_secret: undefined,
				redirect_uris: undefined,
				token_format: undefined,
				expire_in_hours: undefined,
				refresh_expire_in_hours: undefined,
				signup_url: undefined,
				signin_url: undefined,
				forget_url: undefined,
				affiliation_url: undefined,
				terms_of_use: undefined,
				signup_html: undefined,
				signin_html: undefined,
				theme_data: undefined,
				form_css: undefined,
				form_css_mobile: undefined,
				form_offset: undefined,
				form_side_html: undefined,
				form_background_url: undefined,
				remark: undefined,
        auth_rules: []
			};
			this.resetForm("form");
		},

		handleQuery() {
			this.queryParams.pageNum = 1;
			this.getList();
		},

		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},

		handleAdd() {
			this.reset();
			this.open = true;
			this.title = "添加";
		},

    handlePreview(row) {
      let url = ''
      if (row.homepage_url) {
        url = row.homepage_url
      } else {
        url = window.location.origin + '/' + row.name + '/'
      }
      if (row.open_type == 'current_window') {
        window.location.href = url
      } else {
        window.open(url, row.userpool + "_" + row.name)
      }
    },

		handleUpdate(row) {
			this.reset();
			const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
			getApplication(id).then((response) => {
				this.form = response.data;
				this.open = true;
				this.title = "修改";
			});
		},

    handleAuthRule(row) {
			this.reset();
			const id = row.id
			getApplication(id).then((response) => {
        if (!response.data.auth_rules) {
          response.data.auth_rules = []
        }
        this.eachMenu(this.menuOptions, (d) => {
          let rename = ''
          for (let j = 0; j < response.data.auth_rules.length; j++) {
            const ar = response.data.auth_rules[j]
            if (d.id == ar.auth_rule_id) {
              rename = ar.name
              break
            }
          }
          if (rename !== d.rename) {
            d.rename = rename
          }
          return true
        })
        this.checkedForm = {
          checked1: true,
          checked2: false,
          checked3: true,
        }
				this.form = response.data
        this.roleIds = []
				this.openAuthRule = true
        this.checkMenu()
        this.$nextTick(() => {
          this.menuChange(1)
        })
			});
		},

    handleSetting(row) {
      this.reset();
			const id = row.id
			getApplication(id).then((response) => {
				this.form = response.data
        if (this.form.config) {
          this.setting = JSON.parse(this.form.config)
        } else {
          this.setting = undefined
        }
        this.designSetting = false
				this.openSetting = true
			});
    },

		submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
          this.form.type = '0'
					if (this.form.id != null) {
						editApplication(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("修改成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					} else {
						addApplication(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("新增成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					}
				}
			});
		},

    submitAuthRule() {
      const keys = this.getMenuAllCheckedKeys()
      const auth_rules = []
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        let exist = null
        for (let j = 0; j < this.form.auth_rules.length; j++) {
          const ar = this.form.auth_rules[j]
          if (ar.auth_rule_id == key) {
            exist = ar
            break
          }
        }
        if (!exist) {
          let menu = undefined
          this.eachMenu(this.menuOptions, (d) => {
            if (d.id == key) {
              menu = d
              return false
            }
            return true
          })
          auth_rules.push({
            application_id: this.form.id,
            auth_rule_id: key,
            parent_auth_rule_id: 0,
            name: menu.rename
          })
        } else {
          auth_rules.push(exist)
        }
      }
      this.form.auth_rules = auth_rules
      const loading = this.$loading({
        title: '正在提交...'
      })
      editApplication(this.form).then((response) => {
        loading.close()
        if (response.code === 0) {
          this.msgSuccess("修改成功")
          this.openAuthRule = false
        }
      }).catch(e => {
        loading.close()
      });
    },

    submitAuthRuleRename() {
      for (let i = 0; i < this.form.auth_rules.length; i++) {
        const d = this.form.auth_rules[i]
        if (d.auth_rule_id == this.editingAuthRuleRename.id) {
          d.name = this.formAuthRuleRename.rename
          break
        }
      }
      this.editingAuthRuleRename.rename = this.formAuthRuleRename.rename
      this.openAuthRuleRename = false
      this.menuOptions = JSON.parse(JSON.stringify(this.menuOptions))
      this.checkMenu()
      this.$nextTick(() => {
        this.menuChange(1)
        this.$refs.menu.setCurrentNode(this.editingAuthRuleRename)
      })
    },

    submitSetting() {
      if (this.setting) {
        for (let i = 0; i < this.setting.__design__.length; i++) {
          const d = this.setting.__design__[i]
          let value = this.setting[d.name]
          if (!value) {
            value = {}
            this.setting[d.name] = value
          }
          for (let j = 0; j < d.items.length; j++) {
            const item = d.items[j]
            if (value[item.name] === undefined) {
              if (item.type == 'number' || item.type == 'date' || item.type == 'datetime') {
                value[item.name] = item.value || 0
              } else if (item.type == 'switch') {
                value[item.name] = item.value || false
              } else {
                value[item.name] = item.value || ''
              }
            }
          }
        }
        this.form.config = JSON.stringify(this.setting)
      }
      const loading = this.$loading({
        title: '正在提交...'
      })
      editApplication(this.form).then((response) => {
        loading.close()
        if (response.code === 0) {
          this.msgSuccess("修改成功")
          this.openSetting= false
        }
      }).catch(e => {
        loading.close()
      });
    },

		handleDelete(row) {
			const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
			this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(function () {
				return delApplication(ids);
			})
			.then(() => {
				setTimeout(() => {
					this.getList();
				}, 300);
				this.msgSuccess("删除成功");
			})
			.catch(function () {});
		},

    handlePublishChange(row) {
      let text = row.publish === "1" ? "上架应用中心" : "下架应用中心";
      this.$confirm('"' + row.display_name + '"确认要' + text + '吗?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return publishApplication(row.id, row.publish);
      }).then(() => {
        this.msgSuccess(text + "成功");
      }).catch(function() {
        row.publish = row.publish === "0" ? "1" : "0";
      });
    },

		checkboxChangeEvent({ records }) {
			this.ids = records.map((item) => item.id);
			this.single = records.length != 1;
			this.multiple = !records.length;
		},

		handlePageChange({ currentPage, pageSize }) {
			if (this.queryParams.pageSize == pageSize) {
				this.getList();
			} else {
				this.queryParams.pageSize = pageSize;
				this.handleQuery();
			}
		},

    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = [].concat(this.$refs.menu.getHalfCheckedKeys());
      // 半选中的菜单节点
      let halfCheckedKeys = [].concat(this.$refs.menu.getCheckedKeys());
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      for (let i = 0; i < checkedKeys.length; i++) {
        if (checkedKeys[i] == 0) {
          checkedKeys.splice(i, 1)
          break
        }
      }
      return checkedKeys;
    },

    //节点展开事件
    nodeExpand(e) {
      console.log(this.$refs.menu)
      if (e.id * 1 === 0) {
        this.checkedForm.checked1 = true
      }
    },
    //节点关闭事件
    nodeCollapse(e) {
      if (e.id * 1 === 0) {
        this.checkedForm.checked1 = false
      }
    },
    //全选框是否被选中
    currentChange(e) {
      let list1 = this.$refs.menu.getCheckedKeys().sort((a, b) => a - b)
      let list2 = this.roleIds.sort((a, b) => a - b)
      this.checkedForm.checked2 = list1.join(',') === list2.join(',')
    },
    //筛选
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    expandNodeAndChildren(node, level = 1) {
      if (node) {
        node.expanded = this.checkedForm.checked1;
        for (let i = 0; i < node.childNodes.length; i++) {
          const item = node.childNodes[i];
          // 在第三级时停止循环
          if (level === 2) {
            // 执行你的操作或返回结果
            continue;
          }
          // 如果当前元素是数组类型，则进行递归处理
          if (Array.isArray(item.childNodes)) {
            this.expandNodeAndChildren(item, level + 1);
          }
        }
      }
    },

    getTreeData(array) {
      array.forEach(node => {
        this.roleIds.push(node.id)
        if (node.children && node.children.length) {
          this.getTreeData(node.children)
        }
      })
      // return list
    },

    //展开
    menuChange(e) {
      switch (e) {
        case 1:
          this.expandNodeAndChildren(this.$refs.menu.root);
          break;
        case 2:
          const tree = this.$refs.menu
          if (this.checkedForm.checked2) {
            tree.setCheckedKeys(this.roleIds.sort((a, b) => a - b))
          } else {
            tree.setCheckedKeys([])
          }
          break;
      }
    },

    checkMenu() {
      let keys = []
      if (this.form.auth_rules) {
        for (let i = 0; i < this.form.auth_rules.length; i++) {
          const d = this.form.auth_rules[i]
          keys.push(d.auth_rule_id)
        }
        if (keys.length > 0) {
          keys = [0].concat(keys)
        }
      }
      this.menuCheckStrictly = true
      this.$nextTick(() => {
        if (this.$refs.menu) {
          this.$refs.menu.setCheckedKeys(keys);
        }
        this.menuCheckStrictly = false
      });
    },

    selected(name) {
      this.form.icon = name;
    },

    dictFormat(e) {
			const col = this.tableColumn[e.columnIndex]
			if (col.dictData) {
				const values = e.cellValue ? e.cellValue.split(',') : []
				const labels = []
				for (let i = 0; i < values.length; i++) {
					const v = values[i]
					for (let j = 0; j < col.dictData.values.length; j++) {
						const item = col.dictData.values[j]
						if (v == item.key) {
							labels.push(item.value)
							break
						}
					}
				}
				return labels.join(',')
			}
			return e.cellValue
		},

    handleDropdownVisibleChange(e, data) {
      this.$refs.menu.setCurrentNode(data)
    },
    handleCommand(cmd) {
      if (cmd.cmd === 'rename') {
        this.editingAuthRuleRename = cmd.item
        this.formAuthRuleRename = {
          rename: cmd.item.rename || undefined
        }
        this.openAuthRuleRename = true
      }
    },
    makeCommand(cmd, item) {
      return {
        cmd: cmd,
        item: item
      }
    },
	},
  watch: {
    filterText(val) {
      this.$refs.menu.filter(val);
    },

    openAuthRule: {
      handler: function() {
        if (this.openAuthRule && this.roleIds && this.roleIds.length === 0) {
          this.$nextTick(() => {
            this.getTreeData(this.$refs.menu.data)
          })
        }
      },
      // 立即以obj.name的当前值触发回调
      immediate: true
    }
  },
};
</script>

<style lang="scss" scoped>
	.down-tree {
    margin-top: 10px;
		border: 1px solid rgba(0, 0, 0, 0.05);
		padding: 15px;

		&-list {
			margin-top: 15px;
			height: 380px;
			overflow-y: auto;
		}
	}
  .tree-node {
    font-size: 14px;
  }
  .tree-node-leaf {
    /*-webkit-user-drag: element;*/
    user-select: none;
  }
  .tree-node-root {
    user-select: none;
  }
  .custom-tree-node {
    width: 100%;
    display: flex;
    align-items: center;
    height: 26px;
    padding-right: 5px;
  }
  .custom-tree-node-img {
    width:20px; 
    height:20px; 
    margin-right:6px
  }
  .custom-tree-node-label {
    flex: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-tree-node-button {
    display: none;
  }
  .custom-tree-node:hover .custom-tree-node-button {
    display: inline-block;
  }
</style>