<template>
    <div
        v-if="
            designer.isTopic &&
            selectedWidget.options.scoringMethod &&
            selectedWidget.options.scoringMethod * 1 < 5
        "
    >
        <el-form-item
            v-if="selectedWidget.options.answer"
            :label="
                (selectedWidget.options.scoringMethod * 1 < 3
                    ? '此项'
                    : '各项') + i18nt('designer.setting.scoring')
            "
        >
            <div
                class="d-flex"
                v-if="
                    selectedWidget.options.scoringMethod * 1 < 3 &&
                    selectedWidget.options.answer
                "
            >
                <el-input
                    type="number"
                    placeholder="请输入"
                    v-model="optionModel.scoring['answer']"
                ></el-input>
                <div class="ml-10">分</div>
            </div>
        </el-form-item>
        <div v-if="selectedWidget.options.scoringMethod * 1 > 2">
            <div
                v-for="(item, index) in optionItems"
                :key="index"
                class="d-flex align-ietms-center mb-10"
            >
                <div v-text="item[label]"></div>
                <el-input
                    type="number"
                    placeholder="请输入"
                    v-model="optionModel.scoring[item[value]]"
                    class="ml-10 flex-1"
                ></el-input>
                <div class="ml-10">分</div>
            </div>
        </div>
    </div>
</template>
<script>
import i18n from "@/vform/utils/i18n";
import { execModel } from "@/api/core/data/datasource/model";
export default {
    name: "scoring-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            optionItems: [],
            label: "label",
            value: "value",
        };
    },
    watch: {
        selectedWidget: {
            handler(val) {
             
                if (val.options.dsEnabled || val.options.dicttype) {
                 
                    this.getOptionItems();
                } else {
                    
                    this.optionItems = val.options.optionItems;
                 
                    console.log(val.options )
                }
            },
            immediate: true,
            deep: true,
        },
        'optionModel.scoring': {
            handler(val) {
                console.log(val,'optionModel.scoring')
            },
            deep: true,
            immediate:true
        },
    },
    created() {
 
    },
    methods: {
        getOptionItems() {
            let val = this.selectedWidget.options;
            if (val.dsEnabled && val.dsConf_dstid) {
                execModel(
                    val.dsConf_dstid,
                    JSON.stringify({ __max_count__: 100 })
                ).then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.optionItems = res.data;
                        this.label = val.dsConf_dst_label
                            ? val.dsConf_dst_label.split(":")[0]
                            : "";
                        this.value = val.dsConf_dst_value
                            ? val.dsConf_dst_value.split(":")[0]
                            : "";
                   
                    }
                });
            }
            if (!val.dsEnabled && val.dicttype) {
                this.getDicts(val.dicttype).then((response) => {
                    let dictvalue = response.data.values || [];
                    this.optionItems = dictvalue;
                    this.label = "value";
                    this.value = "key";
                });
            }
        },
    },
};
</script>