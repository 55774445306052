import request from '@/utils/request'

export function listGbpxContract(query) {
  return request({
    url: '/gbpx/gbpxcontract/list',
    method: 'get',
    params: query
  })
}

export function getGbpxContract(id) {
  return request({
    url: '/gbpx/gbpxcontract/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxContract(data) {
  return request({
    url: '/gbpx/gbpxcontract/add',
    method: 'post',
    data: data
  })
}

export function editGbpxContract(data) {
  return request({
    url: '/gbpx/gbpxcontract/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxContract(ids) {
  return request({
    url: '/gbpx/gbpxcontract/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

