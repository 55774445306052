<template>
  <div style="padding: 20px;">


    <!--    <div style="margin-top: 20px;">-->
    <!--      上传类型： {{source=="system"?"系统文件":"私人网盘"}}-->
    <!--    </div>-->
    <div style="margin-top: 20px;">
      <el-upload class="upload-demo" ref="upload" action="" :on-change="handleChange" :on-remove="handleRemove"
                 :file-list="fileList" :auto-upload="false" multiple>
        <el-button slot="trigger" size="small" type="primary" :disabled="isUpload">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" :disabled="isUpload"
                   @click="upload">上传至服务器
        </el-button>
      </el-upload>

      <div style="margin-top: 20px;">
        <el-radio-group v-model="public">
          <el-radio :label="1">公开</el-radio>
          <el-radio :label="2">需要登录</el-radio>
        </el-radio-group>
      </div>
    </div>

  </div>
</template>

<script>

import {
  uploadServer,getDssInfo
} from "@/api/dss/fastdfsServer.js";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import VDistpicker from "@/components/VDistpicker";

export default {
  components: {VDistpicker, Treeselect},
  name: "fileUpload",
  data() {
    return {
      fileList: [],
      isUpload: false,
      public: 1,
    };
  },
  props: {
    source: {
      type: String,
      default: 'system'
    }
  },
  created() {

  },
  methods: {
    handleChange(file, fileList) {
      this.showPer = true;
      this.fileList = fileList
    },

    handleRemove(file, fileList) {
      console.log('移除', file, fileList)
      this.fileList = fileList
    },
    /**
     * 格式化日期
     * @param {string | number | Date} value 指定日期
     * @param {string} format 格式化的规则
     * @example
     * ```js
     * formatDate();
     * formatDate(1603264465956);
     * formatDate(1603264465956, "h:m:s");
     * formatDate(1603264465956, "Y年M月D日");
     * ```
     */
    formatDate(value = Date.now(), format = "h:m:s") {
      const formatNumber = n => `0${n}`.slice(-2);
      const date = new Date(value);
      const formatList = ["Y", "M", "D", "h", "m", "s"];
      const res = [];
      res.push(date.getFullYear().toString());
      res.push(formatNumber(date.getMonth() + 1));
      res.push(formatNumber(date.getDate()));
      res.push(formatNumber(date.getHours()));
      res.push(formatNumber(date.getMinutes()));
      res.push(formatNumber(date.getSeconds()));
      for (let i = 0; i < res.length; i++) {
        format = format.replace(formatList[i], res[i]);
      }
      return format;
    },

    async upload() {
      let file = this.fileList[0].raw;
      let tokenReq = {
        platform: "jpgfast",
        type: "general",
        source: this.source,
        public: this.public
      }
      uploadServer(tokenReq, file).then(response => {
        if (response.code == 0) {
          console.log(JSON.stringify(response))
          this.msgSuccess("上传成功");
          this.isUpload = true;

          // getDssInfo(response.data.hash).then(rep1 => {
          //   console.log(JSON.stringify(response))
          // });
        } else {
          this.msgError("上传失败!" + res.msg);
        }
      })

    },
  }
};
</script>
