import { render, staticRenderFns } from "./showModal-editor.vue?vue&type=template&id=185355e1&scoped=true&"
import script from "./showModal-editor.vue?vue&type=script&lang=js&"
export * from "./showModal-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185355e1",
  null
  
)

export default component.exports