<template>
  <div class="app-container">
    <el-row/>
    <el-col :span="24" class="card-box">
        <el-card style="height: 800px;">    
          <el-col :span="6" style="display:inline-block;height: 100%;">  
            <div>                       
              <div slot="header" style="display:inline-block;width:100%;" >              
                <div style="display:inline-block;float:left;margin-top:15px; width:100%;" >
                    部门信息
                  <el-button     
                    style="display:inline-block;float:right;margin-right:0px"           
                    icon="el-icon-refresh"
                    size="mini"
                    @click="deptHandleRefresh"
                  ></el-button>             
                </div>                 
              </div>            
            </div>
            <vxe-grid              
              resizable
              ref="xGrid"
              row-id="id"
              :tree-config="treeConfig"
              show-overflow
              highlight-hover-row
              :highlightCurrentRow="true"
              :loading="deptloading"
              :columns="deptTableColumn"
              :data="deptList"
              @current-change="groupRow"
            ></vxe-grid>
          </el-col > 
          <el-col :span="18" > 
            <div style="display:inline-block;margin-left:20px;margin-top:5px;height:100px;width:100%;background-color:rgb(248,248,249);">
            <div style="font-size:14px;display:inline-block;margin-top:20px;margin-left:10px;" >
              <div style="display:inline-block;color:#859094;margin-left:10px;" >
                部门名称:
              </div>
              <div style="display:inline-block;width:200px;" >
                {{chooserow.name}}
              </div>
              <div style="display:inline-block;color:#859094;margin-left:10px;" >
                部门编码:
              </div>
              <div style="display:inline-block;width:60px;" >
                {{chooserow.no}}
              </div>              
            </div>
            <div style="font-size:14px;display:inline-block;margin-top:20px;margin-left:10px;width:100%;" >
              <div style="display:inline-block;color:#859094;margin-left:10px;" >
                描述:
              </div>
              <div style="display:inline-block;" >
                {{chooserow.remark}}
              </div>
              <div style=";font-size:14px;display:inline-block;margin-top:15px;margin-right:20px;float:right;" >
                           
            </div >
            </div>
            
            </div>
          
          <el-row style="display:inline-block;margin-left:20px;margin-top:10px;height:300px;width:100%;">
            <el-form :model="devicequeryParams" ref="devicequeryParamsForm" :inline="true" label-width="100px">
              <el-form-item label="所属产品" prop="product_id">
                <el-select v-model="devicequeryParams.product_id" placeholder="所属产品" clearable size="small">
                  <el-option
                    v-for="dict in productList"
                    :key="dict.id"
                    :label="dict.name"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="设备名称" prop="device_name">
                <el-input
                  v-model="devicequeryParams.device_name"
                  placeholder="请输入设备名称"
                  clearable
                  size="small"
                  @keyup.enter.native="handleQuery"
                />
              </el-form-item>  
              <el-form-item label="设备标识码" prop="device_check">
                <el-input
                  v-model="devicequeryParams.device_check"
                  placeholder="请输入设备标识码"
                  clearable
                  size="small"
                  @keyup.enter.native="handleQuery"
                />
              </el-form-item>      
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
              </el-form-item>
            </el-form>
            <vxe-grid
                    
                    ref="deviceTable"                    
                    stripe
                    width="100%"
                    show-overflow
                    highlight-hover-row
                    :loading="loading"
                    :toolbar-config="tableToolbar"
                    :columns="tableColumn"
                    :data="deviceList"
                    :column-config="{resizable: false}"
                    @checkbox-change="checkboxChangeEvent"
                    @checkbox-all="checkboxChangeEvent">
              <template #toolbar_buttons>
                <el-button
                  :disabled="chooserow.id > 0?false:true"
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"                  
                  @click="bindingHandleAdd"
                >分配</el-button>                
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :disabled="multiple"
                  @click="bindingHandleDelete"
                >解除</el-button>
              </template>              
              <!--默认操作按钮-->
              <template #defaultopr="{ row }">                
                <el-button size="mini" type="text" icon="el-icon-view">
                  <router-link :to="'/iot/device/info/'+row.id" class="link-type" style="align-items: center;">
                    <span>查看</span>
                  </router-link>
                </el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="bindingHandleDelete(row)"
                >解除
                </el-button>
              </template>

              <!--自定义空数据模板-->
              <template #empty>
                    <span >
                      <p>暂无数据</p>
                    </span>
              </template>

            </vxe-grid>
            <vxe-pager
                    background
                    size="small"
                    :loading="loading"
                    :current-page="devicequeryParams.pageNum"
                    :page-size="devicequeryParams.pageSize"
                    :total="total"
                    @page-change="handleSizeChange">
            </vxe-pager>          
          </el-row>
          </el-col > 
          
      </el-card>
    </el-col >    
    <!-- 绑定设备 -->
    <el-dialog :close-on-click-modal="false" :title="bindingtitle" :visible.sync="bindingopen" width="800px" append-to-body>
      <el-form ref="bindingform" :model="bindingform" label-width="80px">  
        <el-form :model="bindingqueryParams" ref="bindingqueryForm" :inline="true" label-width="100px">
          <el-form-item label="所属产品" prop="product_id">
            <el-select v-model="bindingqueryParams.product_id" placeholder="所属产品" clearable size="small">
              <el-option
                v-for="dict in productList"
                :key="dict.id"
                :label="dict.name"
                :value="dict.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" prop="device_name">
            <el-input
              v-model="bindingqueryParams.device_name"
              placeholder="请输入设备名称"
              clearable
              size="small"
              @keyup.enter.native="bindinghandleQuery"
            />
          </el-form-item>  
          <el-form-item label="设备标识码" prop="device_check">
            <el-input
              v-model="bindingqueryParams.device_check"
              placeholder="请输入设备标识码"
              clearable
              size="small"
              @keyup.enter.native="bindinghandleQuery"
            />
          </el-form-item>      
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="bindinghandleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="bindingresetQuery">重置</el-button>
          </el-form-item>
        </el-form>
        <el-row/>
        <el-row style="display:inline-blockmargin-top:10px;height:300px;width:100%;">
          <vxe-grid
                    
                    ref="bindingdeviceTable"                    
                    stripe
                    width="100%"
                    height="280px"
                    show-overflow
                    highlight-hover-row
                    :loading="bindingloading"
                    :columns="bindingtableColumn"
                    :data="bindingdeviceList"
                    :column-config="{resizable: false}"
                    @checkbox-change="bindingcheckboxChangeEvent"
                    @checkbox-all="bindingcheckboxChangeEvent">
              <!--自定义空数据模板-->
              <template #empty>
                    <span >
                      <p>暂无数据</p>
                    </span>
              </template>

            </vxe-grid>
            <vxe-pager
                    background
                    size="small"
                    :loading="loading"
                    :current-page="bindingqueryParams.pageNum"
                    :page-size="bindingqueryParams.pageSize"
                    :total="total"
                    @page-change="bindinghandleSizeChange">
            </vxe-pager>
        </el-row>        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bindingsubmitForm">确 定</el-button>
        <el-button @click="bindingcancel" >取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice ,listIotDeviceNoDept,bindIotDeviceDept,unbindIotDeviceDept} from "@/api/iot/device";
import { listDept } from "@/api/iot/dept";
export default {  
  name: "DeviceGroup",
  data() {
    return{
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      treeConfig:{
          "trigger":"row",
        },           
      bindingqueryParams:{
        pageNum: 1,
        pageSize: 100,        
        product_id: undefined,       
        dept_ids:"",
        group_id:-1,
        device_name:"",
        device_check:""
      },         
      devicequeryParams:{
        pageNum: 1,
        pageSize: 100,
        product_id: undefined,
        group_id:-1,
        dept_ids:0,
        device_name:"",
        device_check:""
      },
      loading:false,
      deptloading:false,
      bindingloading:false,     
      productList:[],   
      deviceList:[],
      bindingdeviceList:[],    
      chooserow:{},
      bindingform:{},
      bindingopen:false,
      bindingtitle:"",
      form:{},
      ids: [],
      deptList:[],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      bindingids: [],
      // 非单个禁用
      bindingsingle: true,
      // 非多个禁用
      bindingmultiple: true,
      // 总条数
      bindingtotal: 0,
      //显示绑定设备
      tableColumn:[
        {type: 'checkbox', width: 30, fixed: "left"},
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'device_name', title: '设备名称' },
        {field: 'device_check', title: '设备标识码' },        
        {field: 'product_id', title: '所属产品' ,formatter:this.ProductFormat},
        {field: 'device_type', title: '设备类型' },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}],
      
      bindingtableColumn:[
        {type: 'checkbox', width: 30, fixed: "left"},
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'device_name', title: '设备名称'},
        {field: 'device_check', title: '设备标识码' },        
        {field: 'product_id', title: '所属产品' ,formatter:this.ProductFormat}],    
      deptTableColumn:[
        {field: 'name', title: '机构名称',treeNode: true},
      ],
    }
  },
  created() {   
    this.getDeptList();
  },
  methods: {
    getDeptList(){
      var  deptqueryParams= {
        name: undefined,
        status: undefined
      };      
      this.deptloading=true;
      listDept(deptqueryParams).then(response => {
        this.deptList = this.handleTree(response.data.list, "id", "parent_id");
        this.statusOptions = response.data.statusOptions.values || []
        this.typeOptions = response.data.typeOptions.values || []
        this.deptloading=false;
      });
    },    
    getAllDeviceList(){
      this.bindingloading=true;  
      if(this.chooserow.id>0){
        this.bindingqueryParams.dept_ids=this.chooserow.id+"";
        listIotDeviceNoDept(this.bindingqueryParams).then(response=>{
          this.bindingdeviceList=response.data.list;
          this.bindingloading=false;
          this.bindingtotal=response.data.total;
        });
      }else{
        this.bindingloading=false; 
      }
      
    },
    getDeviceList(){
      this.loading=true;
      if(this.chooserow.id>0){
        this.devicequeryParams.dept_ids=this.chooserow.id+"";
        console.log(this.devicequeryParams);
        listIotDevice(this.devicequeryParams).then(response=>{
          this.deviceList=response.data.list;
          this.loading=false;
          this.multiple=true;
          this.single= true,
          this.total=response.data.total;
          this.productList = response.data.product_list;
        });
      }else{
        this.loading=false;
      }    
    },    
    deptHandleRefresh(){
      this.getDeptList();
      this.msgSuccess("刷新成功！");
    },
    groupRow({row}){     
      this.chooserow=row;   
      this.getDeviceList(); 
    },
    ProductFormat({row}){
      var label=""
      this.productList.forEach(element => {
        if (element.id === row.product_id)
          label=element.name;
      });
      return label
    },
    //绑定设备按钮
    bindingHandleAdd(){
      this.getAllDeviceList();
      this.bindingopen=true;
      if(this.chooserow.id>0)
        this.bindingtitle="设备分配给"+this.chooserow.name;
    },
    //绑定设备--确定提交
    bindingsubmitForm(){      
      if(this.chooserow.id>0){        
        bindIotDeviceDept(this.chooserow.id,this.bindingids.join(",")).then(response=>{
          
            if(response.data.count>0){
              this.msgSuccess("绑定设备成功");
              this.getDeviceList();
            }else{
              this.msgError("绑定设备失败");
            }
            this.bindingopen=false;
        });
      }      
    },
    bindingHandleUpdate(row){},
    //解绑
    bindingHandleDelete(row){
      const ids = row.id || (this.ids ? this.ids.join(',') : '');       
      this.$confirm('是否确认解绑编号为"' + ids + '"的设备?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {   
          //this.msgSuccess("解绑设备成功11");       
          //return            
        }).then(() => {
          unbindIotDeviceDept(this.chooserow.id,ids).then(response=>{
            if(response.data.count>0){
              this.getDeviceList();
              this.msgSuccess("解绑设备成功");
            }
          });          
        }).catch(function() {}); 
    },
    // 取消按钮
    bindingcancel() {
      this.bindingopen = false;
      this.bindingreset();
    },
    // 表单重置
    bindingreset() {
      this.bindingform = {
        id: undefined,         
      };
      this.resetForm("bindingform");
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        Id: undefined,         
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.devicequeryParams.pageNum = 1;
      this.getDeviceList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },     
    handleSizeChange ({ currentPage, pageSize }) {
      this.devicequeryParams.pageNum = currentPage;
      if(this.devicequeryParams.pageSize == pageSize){
        this.getDeviceList();
      }else{
        this.devicequeryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },    
    bindinghandleQuery() {
      this.bindingqueryParams.pageNum = 1;
      this.getAllDeviceList();
    },
    /** 重置按钮操作 */
    bindingresetQuery() {
      this.resetForm("bindingqueryForm");
      this.bindinghandleQuery();
    },
    bindinghandleSizeChange ({ currentPage, pageSize }) {
      this.bindingqueryParams.pageNum = currentPage;
      if(this.bindingqueryParams.pageSize == pageSize){
        this.getAllDeviceList();
      }else{
        this.bindingqueryParams.pageSize = pageSize;
        this.bindinghandleQuery();
      }

    },
    bindingcheckboxChangeEvent ({ records }) {
      this.bindingids = records.map(item => item.id)
      this.bindingsingle = records .length!=1;
      this.bindingmultiple = !records .length

    },
  }
}
</script>
