import { render, staticRenderFns } from "./round-editor.vue?vue&type=template&id=13bd1f1e&scoped=true&"
import script from "./round-editor.vue?vue&type=script&lang=js&"
export * from "./round-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13bd1f1e",
  null
  
)

export default component.exports