export default{
  "widgetList": [
    {
      "type": "static-text",
      "icon": "static-text",
      "formItemFlag": false,
      "options": {
        "name": "statictext111193",
        "columnWidth": "200px",
        "hidden": false,
        "textContent": "主从表单",
        "customClass": [],
        "onCreated": "",
        "onMounted": "",
        "label": "static-text"
      },
      "id": "statictext111193"
    },
    {
      "type": "divider",
      "icon": "divider",
      "formItemFlag": false,
      "options": {
        "name": "divider102346",
        "label": "",
        "columnWidth": "200px",
        "direction": "horizontal",
        "contentPosition": "center",
        "hidden": false,
        "customClass": [],
        "onCreated": "",
        "onMounted": ""
      },
      "id": "divider102346"
    },
    {
      "type": "grid",
      "category": "container",
      "icon": "grid",
      "cols": [
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "input",
              "icon": "text-field",
              "formItemFlag": true,
              "options": {
                "name": "input101987",
                "label": "订单编号",
                "labelAlign": "",
                "type": "text",
                "defaultValue": "",
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "readonly": true,
                "disabled": false,
                "hidden": false,
                "clearable": true,
                "showPassword": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "minLength": null,
                "maxLength": null,
                "showWordLimit": false,
                "prefixIcon": "",
                "suffixIcon": "",
                "appendButton": false,
                "appendButtonDisabled": false,
                "buttonIcon": "el-icon-search",
                "onCreated": "",
                "onMounted": "",
                "onInput": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "input101987"
            }
          ],
          "options": {
            "name": "gridCol78314",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-78314"
        },
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [],
          "options": {
            "name": "gridCol38113",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-38113"
        }
      ],
      "options": {
        "name": "grid109613",
        "hidden": false,
        "gutter": 12,
        "customClass": ""
      },
      "id": "grid109613"
    },
    {
      "type": "grid",
      "category": "container",
      "icon": "grid",
      "cols": [
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "input",
              "icon": "text-field",
              "formItemFlag": true,
              "options": {
                "name": "input95851",
                "label": "购买客户",
                "labelAlign": "",
                "type": "text",
                "defaultValue": "",
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "readonly": true,
                "disabled": false,
                "hidden": false,
                "clearable": true,
                "showPassword": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": [],
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "minLength": null,
                "maxLength": null,
                "showWordLimit": false,
                "prefixIcon": "",
                "suffixIcon": "",
                "appendButton": true,
                "appendButtonDisabled": false,
                "buttonIcon": "el-icon-search",
                "onCreated": "",
                "onMounted": "",
                "onInput": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "input95851"
            }
          ],
          "options": {
            "name": "gridCol9704",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-9704"
        },
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "date",
              "icon": "date-field",
              "formItemFlag": true,
              "options": {
                "name": "date75919",
                "label": "下单日期",
                "labelAlign": "",
                "type": "date",
                "defaultValue": null,
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "readonly": false,
                "disabled": false,
                "hidden": false,
                "clearable": true,
                "editable": false,
                "format": "yyyy-MM-dd",
                "valueFormat": "yyyy-MM-dd",
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "onCreated": "",
                "onMounted": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "date75919"
            }
          ],
          "options": {
            "name": "gridCol67512",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-67512"
        }
      ],
      "options": {
        "name": "grid63405",
        "hidden": false,
        "gutter": 12,
        "customClass": ""
      },
      "id": "grid63405"
    },
    {
      "type": "grid",
      "category": "container",
      "icon": "grid",
      "cols": [
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "select",
              "icon": "select-field",
              "formItemFlag": true,
              "options": {
                "name": "select71837",
                "label": "支付方式",
                "labelAlign": "",
                "defaultValue": "",
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": false,
                "hidden": false,
                "clearable": true,
                "filterable": false,
                "allowCreate": false,
                "remote": false,
                "automaticDropdown": false,
                "multiple": false,
                "multipleLimit": 0,
                "optionItems": [
                  {
                    "value": "1",
                    "label": "微信"
                  },
                  {
                    "value": "2",
                    "label": "支付宝"
                  },
                  {
                    "value": "3",
                    "label": "其他"
                  }
                ],
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "onCreated": "",
                "onMounted": "",
                "onRemoteQuery": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "select71837"
            }
          ],
          "options": {
            "name": "gridCol18846",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-18846"
        },
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "switch",
              "icon": "switch-field",
              "formItemFlag": true,
              "options": {
                "name": "switch35050",
                "label": "是否需要发票",
                "labelAlign": "",
                "defaultValue": false,
                "columnWidth": "200px",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": false,
                "hidden": false,
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "switchWidth": 40,
                "activeText": "需要",
                "inactiveText": "不需要",
                "activeColor": null,
                "inactiveColor": null,
                "onCreated": "",
                "onMounted": "",
                "onChange": "",
                "onValidate": ""
              },
              "id": "switch35050"
            }
          ],
          "options": {
            "name": "gridCol74079",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-74079"
        }
      ],
      "options": {
        "name": "grid82940",
        "hidden": false,
        "gutter": 12,
        "customClass": ""
      },
      "id": "grid82940"
    },
    {
      "type": "grid",
      "category": "container",
      "icon": "grid",
      "cols": [
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "select",
              "icon": "select-field",
              "formItemFlag": true,
              "options": {
                "name": "select78072",
                "label": "物流快递",
                "labelAlign": "",
                "defaultValue": "",
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": false,
                "hidden": false,
                "clearable": true,
                "filterable": false,
                "allowCreate": false,
                "remote": false,
                "automaticDropdown": false,
                "multiple": false,
                "multipleLimit": 0,
                "optionItems": [
                  {
                    "value": "1",
                    "label": "中国邮政"
                  },
                  {
                    "value": "2",
                    "label": "顺丰"
                  },
                  {
                    "value": "3",
                    "label": "申通"
                  },
                  {
                    "value": "4",
                    "label": "中通"
                  },
                  {
                    "value": "5",
                    "label": "韵达"
                  }
                ],
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "onCreated": "",
                "onMounted": "",
                "onRemoteQuery": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "select78072"
            }
          ],
          "options": {
            "name": "gridCol14757",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-14757"
        }
      ],
      "options": {
        "name": "grid49116",
        "hidden": false,
        "gutter": 12,
        "customClass": ""
      },
      "id": "grid49116"
    },
    {
      "type": "grid",
      "category": "container",
      "icon": "grid",
      "cols": [
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "input",
              "icon": "text-field",
              "formItemFlag": true,
              "options": {
                "name": "input20247",
                "label": "收货地址",
                "labelAlign": "",
                "type": "text",
                "defaultValue": "",
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "readonly": false,
                "disabled": false,
                "hidden": false,
                "clearable": true,
                "showPassword": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "minLength": null,
                "maxLength": null,
                "showWordLimit": false,
                "prefixIcon": "",
                "suffixIcon": "",
                "appendButton": false,
                "appendButtonDisabled": false,
                "buttonIcon": "el-icon-search",
                "onCreated": "",
                "onMounted": "",
                "onInput": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "input20247"
            }
          ],
          "options": {
            "name": "gridCol10575",
            "hidden": false,
            "span": 24,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-10575"
        }
      ],
      "options": {
        "name": "grid30617",
        "hidden": false,
        "gutter": 12,
        "customClass": ""
      },
      "id": "grid30617"
    },
    {
      "type": "card",
      "category": "container",
      "icon": "card",
      "widgetList": [
        {
          "type": "sub-form",
          "category": "container",
          "icon": "sub-form",
          "widgetList": [
            {
              "type": "select",
              "icon": "select-field",
              "formItemFlag": true,
              "options": {
                "name": "select27919",
                "label": "购买产品",
                "labelAlign": "",
                "defaultValue": "",
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": false,
                "hidden": false,
                "clearable": true,
                "filterable": false,
                "allowCreate": false,
                "remote": false,
                "automaticDropdown": false,
                "multiple": false,
                "multipleLimit": 0,
                "optionItems": [
                  {
                    "value": "p1",
                    "label": "苹果"
                  },
                  {
                    "value": "p2",
                    "label": "菠萝"
                  },
                  {
                    "value": "p3",
                    "label": "荔枝"
                  }
                ],
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "onCreated": "",
                "onMounted": "",
                "onRemoteQuery": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "select27919"
            },
            {
              "type": "input",
              "icon": "text-field",
              "formItemFlag": true,
              "options": {
                "name": "input37637",
                "label": "型号/规格",
                "labelAlign": "",
                "type": "text",
                "defaultValue": "",
                "placeholder": "",
                "columnWidth": "150px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "readonly": false,
                "disabled": false,
                "hidden": false,
                "clearable": true,
                "showPassword": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "minLength": null,
                "maxLength": null,
                "showWordLimit": false,
                "prefixIcon": "",
                "suffixIcon": "",
                "appendButton": false,
                "appendButtonDisabled": false,
                "buttonIcon": "el-icon-search",
                "onCreated": "",
                "onMounted": "",
                "onInput": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "input37637"
            },
            {
              "type": "number",
              "icon": "number-field",
              "formItemFlag": true,
              "options": {
                "name": "number36803",
                "label": "单价",
                "labelAlign": "",
                "defaultValue": 0,
                "placeholder": "",
                "columnWidth": "150px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": false,
                "hidden": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "min": -100000000000,
                "max": 100000000000,
                "precision": 2,
                "step": 1,
                "controlsPosition": "right",
                "onCreated": "",
                "onMounted": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "number36803"
            },
            {
              "type": "number",
              "icon": "number-field",
              "formItemFlag": true,
              "options": {
                "name": "number10017",
                "label": "数量",
                "labelAlign": "",
                "defaultValue": 0,
                "placeholder": "",
                "columnWidth": "150px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": false,
                "hidden": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "min": -100000000000,
                "max": 100000000000,
                "precision": 0,
                "step": 1,
                "controlsPosition": "right",
                "onCreated": "",
                "onMounted": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "number10017"
            },
            {
              "type": "number",
              "icon": "number-field",
              "formItemFlag": true,
              "options": {
                "name": "number9464",
                "label": "小计金额",
                "labelAlign": "",
                "defaultValue": 0,
                "placeholder": "",
                "columnWidth": "150px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": true,
                "hidden": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "min": -100000000000,
                "max": 100000000000,
                "precision": 2,
                "step": 1,
                "controlsPosition": "right",
                "onCreated": "",
                "onMounted": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "number9464"
            }
          ],
          "options": {
            "name": "subform27823",
            "showBlankRow": true,
            "showRowNumber": true,
            "labelAlign": "label-center-align",
            "hidden": false,
            "customClass": "",
            "onSubFormRowAdd": "",
            "onSubFormRowInsert": "",
            "onSubFormRowDelete": "",
            "onSubFormRowChange": ""
          },
          "id": "subform27823"
        }
      ],
      "options": {
        "name": "card105958",
        "label": "订单明细",
        "hidden": false,
        "folded": false,
        "showFold": false,
        "cardWidth": "100%",
        "shadow": "never",
        "customClass": ""
      },
      "id": "card105958"
    },
    {
      "type": "grid",
      "category": "container",
      "icon": "grid",
      "cols": [
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "number",
              "icon": "number-field",
              "formItemFlag": true,
              "options": {
                "name": "number97501",
                "label": "累计金额",
                "labelAlign": "",
                "defaultValue": 0,
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": true,
                "hidden": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "min": -100000000000,
                "max": 100000000000,
                "precision": 2,
                "step": 1,
                "controlsPosition": "right",
                "onCreated": "",
                "onMounted": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "number97501"
            }
          ],
          "options": {
            "name": "gridCol101404",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-101404"
        },
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "number",
              "icon": "number-field",
              "formItemFlag": true,
              "options": {
                "name": "number88459",
                "label": "优惠金额",
                "labelAlign": "",
                "defaultValue": 0,
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": false,
                "hidden": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "min": -100000000000,
                "max": 100000000000,
                "precision": 2,
                "step": 1,
                "controlsPosition": "right",
                "onCreated": "",
                "onMounted": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "number88459"
            }
          ],
          "options": {
            "name": "gridCol42397",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-42397"
        }
      ],
      "options": {
        "name": "grid47470",
        "hidden": false,
        "gutter": 12,
        "customClass": ""
      },
      "id": "grid47470"
    },
    {
      "type": "grid",
      "category": "container",
      "icon": "grid",
      "cols": [
        {
          "type": "grid-col",
          "category": "container",
          "icon": "grid-col",
          "internal": true,
          "widgetList": [
            {
              "type": "number",
              "icon": "number-field",
              "formItemFlag": true,
              "options": {
                "name": "number27788",
                "label": "实付金额",
                "labelAlign": "",
                "defaultValue": 0,
                "placeholder": "",
                "columnWidth": "200px",
                "size": "",
                "labelWidth": null,
                "labelHidden": false,
                "disabled": false,
                "hidden": false,
                "required": false,
                "validation": "",
                "validationHint": "",
                "customClass": "",
                "labelIconClass": null,
                "labelIconPosition": "rear",
                "labelTooltip": null,
                "min": -100000000000,
                "max": 100000000000,
                "precision": 2,
                "step": 1,
                "controlsPosition": "right",
                "onCreated": "",
                "onMounted": "",
                "onChange": "",
                "onFocus": "",
                "onBlur": "",
                "onValidate": ""
              },
              "id": "number27788"
            }
          ],
          "options": {
            "name": "gridCol71662",
            "hidden": false,
            "span": 12,
            "offset": 0,
            "push": 0,
            "pull": 0,
            "responsive": false,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "customClass": ""
          },
          "id": "grid-col-71662"
        }
      ],
      "options": {
        "name": "grid59881",
        "hidden": false,
        "gutter": 12,
        "customClass": ""
      },
      "id": "grid59881"
    }
  ],
  "formConfig": {
    "modelName": "formData",
    "refName": "vForm",
    "rulesName": "rules",
    "labelWidth": 150,
    "labelPosition": "left",
    "size": "",
    "labelAlign": "label-right-align",
    "cssCode": "",
    "customClass": [],
    "functions": "",
    "layoutType": "PC",
    "onFormCreated": "",
    "onFormMounted": "",
    "onFormDataChange": ""
  }
}