import request from '@/utils/request'

// 查询登录日志列表
export function list(userpool, query) {
  return request({
    url: '/ucenter/' + userpool + '/loginlog/list',
    method: 'get',
    params: query
  })
}

// 删除登录日志
export function delLoginLog(userpool, ids) {
  return request({
    url: '/ucenter/' + userpool + '/loginlog/del',
    method: 'post',
    data:{
      ids: ids
    }
  })
}

// 清空登录日志
export function clearLoginLog(userpool) {
  return request({
    url: '/ucenter/' + userpool + '/loginlog/clear',
    method: 'post'
  })
}
