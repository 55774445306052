import request from '@/utils/request'

export function listStuStudentFiling(query) {
  return request({
    url: '/stu/stustudentfiling/list',
    method: 'get',
    params: query
  })
}

export function getStuStudentFiling(id) {
  return request({
    url: '/stu/stustudentfiling/get?id=' + id,
    method: 'get'
  })
}

export function addStuStudentFiling(data) {
  return request({
    url: '/stu/stustudentfiling/add',
    method: 'post',
    data: data
  })
}

export function editStuStudentFiling(data) {
  return request({
    url: '/stu/stustudentfiling/edit',
    method: 'post',
    data: data
  })
}

export function delStuStudentFiling(ids) {
  return request({
    url: '/stu/stustudentfiling/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

