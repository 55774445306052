import request from '@/utils/request'
// 生成时间:2022-07-22 18:27:48

// 查询konghao列表
export function listKonghao(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/dncblacklist/konghao/list',
        method: 'post',
        data: data
    })
}

// 查询konghao详细
export function getKonghao (phone) {
    return request({
        url: '/dncblacklist/konghao/get?phone='+phone,
        method: 'get'
    })
}


// 新增konghao
export function addKonghao(data) {
    return request({
        url: '/dncblacklist/konghao/add',
        method: 'post',
        data: data
    })
}

// 修改konghao
export function editKonghao(data) {
    return request({
        url: '/dncblacklist/konghao/edit',
        method: 'post',
        data: data
    })
}

// 删除konghao
export function delKonghao(ids) {
    return request({
        url: '/dncblacklist/konghao/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 删除konghao查询模板
export function advquerydelKonghao(ids) {
    return request({
        url: '/dncblacklist/konghao/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加konghao查询模板
export function advquerysaveKonghao(data) {
    return request({
        url: '/dncblacklist/konghao/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表konghao查询模板列表
export function advquerylistKonghao(businessname) {
    return request({
        url: '/dncblacklist/konghao/advquerylist?businessname='+businessname,
        method: 'get'
    })
}
// 导出konghao
export function exportKonghao(query,exportfields,tableColumn) {
     var data={
            filterstr:"",
            orderby:"",
            opstr:query.opstr ||{},
        };
        var filterstr={};
        for(var k in query){
         var item=query[k];
         if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
           filterstr[k]=item;
         }
        }
        var tarexportfieldslist={};
        for(var k in tableColumn){
          var tlbf = tableColumn[k];
           for(var i in exportfields){
             var ef = exportfields[i]
             if(tlbf.field!="" && tlbf.field && tlbf.field!=null && tlbf.field == ef){
               tarexportfieldslist.push({"filed":ef,"title":tlbf.title});
               break;
             }
           }
        }

        data["filterstr"] = JSON.stringify(filterstr);
        data["opstr"] = JSON.stringify(data["opstr"]);
        data["exportfields"] = JSON.stringify(tarexportfieldslist);
        return request({
            url: '/dncblacklist/konghao/export',
            method: 'post',
            data: data
        })
}
