<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true">
			<el-form-item label="用户名" prop="username">
				<el-input v-model="queryParams.username" placeholder="请输入用户名" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="登录IP" prop="ip">
				<el-input v-model="queryParams.ip" placeholder="请输入登录IP" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>

		<!-- <vxe-grid resizable ref="xGrid" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="list">
 -->
			<!-- <template #seq="{row}"> -->
			<!-- 	<span>{{ $index}}</span> -->
			<!-- 	<span>{{(queryParams.pageNum - 1) * queryParams.pageSize + $index + 1}}</span> -->
			<!-- </template> -->

			<!--默认操作按钮-->
			<!-- <template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-delete" @click="handleForceLogout(row)">强退</el-button>
			</template> -->

			<!--自定义空数据模板-->
	<!-- 		<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>

		</vxe-grid> -->
	<!-- 	<vxe-pager background size="small" :loading="loading" :current-page="queryParams.pageNum"
			:page-size="queryParams.pageSize" :total="total" @page-change="handleSizeChange">
		</vxe-pager> -->

		<jf-table :loading="loading" :checkbox="false" :columns="tableColumn" :data="list" :toolbar-config="tableToolbar"
			:query-params="queryParams" :total="total" @change="tableChange"
			@page-change="handleSizeChange">
			

			<template slot="toolbar_btn_row" slot-scope="{ row }">

				<el-button size="mini" type="text" icon="el-icon-delete" @click="handleForceLogout(row)">强退</el-button>
			</template>


		</jf-table>

	</div>
</template>

<script>
	import {
		list,
		forceLogout
	} from "@/api/core/monitor/online";

	export default {
		name: "core-monitor-online-list",
		data() {
			return {
				// 工具栏
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery
					},
					slots: {
						buttons: 'toolbar_buttons'
					}
				},
				// 列
				tableColumn: [{
						type: 'seq',
						title: '序号',
						width: 60,
						align: "center"
					},
          {
						field: 'userpool',
						title: '用户池',
						align: "center",
					},
					{
						field: 'username',
						title: '用户名',
						align: "center",
					},
					{
						field: 'ip',
						title: '登录IP',
						align: "center",
					},
					{
						field: 'platform',
						title: '平台',
						align: "center",
					},
					{
						field: 'os',
						title: '操作系统',
						align: "center",
					},
					{
						field: 'browser',
						title: '浏览器',
						align: "center",
					},
					{
						field: 'create_time',
						title: '登录时间',
						width: 180,
						formatter: "formatDate"
					},
					// {
					// 	field: '',
					// 	title: '操作',
					// 	resizable: false,
					// 	width: 180,
					// 	fixed: "right",
					// 	align: "center",
					// 	slots: {
					// 		default: 'defaultopr'
					// 	}
					// }
				],
				// 遮罩层
				loading: true,
				// 总条数
				total: 0,
				// 表格数据
				list: [],
				// 查询参数
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					ip: undefined,
					username: undefined
				}
			};
		},
		created() {
			this.getList();
		},
		methods: {
			tableChange(e){},
			handleSizeChange({
				currentPage,
				pageSize
			}) {
				this.queryParams.pageNum = currentPage;
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}

			},
			/** 查询登录日志列表 */
			getList() {
				this.loading = true;
				list(this.queryParams).then(response => {
					this.list = response.data.list;
					this.total = response.data.total;
					this.loading = false;
				});
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},
			/** 强退按钮操作 */
			handleForceLogout(row) {
				this.$confirm('是否确认强退名称为"' + row.username + '"的用户?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return forceLogout(row.id);
				}).then(() => {
					this.getList();
					this.msgSuccess("强退成功");
				}).catch(function() {});
			}
		}
	};
</script>