import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2023-10-12 10:59:36
class Plan {
// 查询plan列表
 listPlan(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/eyeplan/plan/list',
        method: 'post',
        data: data
    })
}

// 查看plan
 getPlan ( id) {
    return request({
        url: '/eyeplan/plan/get?id='+id,
        method: 'get'
    })
}


// 新增plan
 addPlan(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/eyeplan/plan/add',
        method: 'post',
        data: postdata
    })
}

// 修改plan
 editPlan(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid,
          dataid:data.id
      }

    return request({
        url: '/eyeplan/plan/edit',
        method: 'post',
        data: postdata
    })
}

// 删除plan
 delPlan(ids) {
    return request({
        url: '/eyeplan/plan/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 planImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/eyeplan/plan/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 planExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/eyeplan/plan/export?` + parata.join("&") ;


 }

 //下导入模板
  planimportTemplate() {

     return   `/eyeplan/plan/importTemplate`   ;

  }

}

const instance = new Plan();
export default instance;