import request from '@/utils/request'

export function listGbpxClass(query) {
  return request({
    url: '/gbpx/gbpxclass/list/a',
    method: 'get',
    params: query
  })
}

export function listGbpxClassU(query) {
  return request({
    url: '/gbpx/gbpxclass/list/u',
    method: 'get',
    params: query
  })
}

export function getGbpxClass(id) {
  return request({
    url: '/gbpx/gbpxclass/get/a?id=' + id,
    method: 'get'
  })
}

export function getGbpxClassU(id) {
  return request({
    url: '/gbpx/gbpxclass/get/u?id=' + id,
    method: 'get'
  })
}

export function addGbpxClass(data) {
  return request({
    url: '/gbpx/gbpxclass/add/a',
    method: 'post',
    data: data
  })
}

export function addGbpxClassU(data) {
  return request({
    url: '/gbpx/gbpxclass/add/u',
    method: 'post',
    data: data
  })
}

export function editGbpxClass(data) {
  return request({
    url: '/gbpx/gbpxclass/edit/a',
    method: 'post',
    data: data
  })
}

export function editGbpxClassU(data) {
  return request({
    url: '/gbpx/gbpxclass/edit/u',
    method: 'post',
    data: data
  })
}


export function delGbpxClass(ids) {
  return request({
    url: '/gbpx/gbpxclass/del/a',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function delGbpxClassU(ids) {
  return request({
    url: '/gbpx/gbpxclass/del/u',
    method: 'post',
    data: {
      ids: ids
    }
  })
}
