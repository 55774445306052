import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2023-10-10 14:57:28
class Card {
// 查询card列表
 listCard(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/eyeplan/card/list',
        method: 'post',
        data: data
    })
}

// 查看card
 getCard ( id) {
    return request({
        url: '/eyeplan/card/get?id='+id,
        method: 'get'
    })
}


// 新增card
 addCard(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/eyeplan/card/add',
        method: 'post',
        data: postdata
    })
}

// 修改card
 editCard(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid,
          dataid:data.id
      }

    return request({
        url: '/eyeplan/card/edit',
        method: 'post',
        data: postdata
    })
}

// 删除card
 delCard(ids) {
    return request({
        url: '/eyeplan/card/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 cardImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/eyeplan/card/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 cardExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/eyeplan/card/export?` + parata.join("&") ;


 }

 //下导入模板
  cardimportTemplate() {

     return   `/eyeplan/card/importTemplate`   ;

  }

}

const instance = new Card();
export default instance;