import request from '@/utils/request'

// 添加设备
export function addIotDevice(data) {
  return request({
    url: '/iot/device/add',
    method: 'post',
    data: data
  })
}

// 获取设备
export function getIotDevice(id) {
  return request({
    url: '/iot/device/get?id=' + id,
    method: 'get'
  })
}

// 获取设备实时消息
export function getIotDeviceNewMessage(id) {
  return request({
    url: '/iot/device/message?id=' + id,
    method: 'get'
  })
}

// 更新设备
export function updateIotDevice(data) {
  return request({
    url: '/iot/device/update',
    method: 'post',
    data: data
  })
}

// 删除设备
export function delIotDevice(id) {
  return request({
    url: '/iot/device/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
// 设备列表
export function listIotDevice(query) {
  return request({
    url: '/iot/device/list',
    method: 'get',
    params: query
  })
}
// 设备列表不包含部门
export function listIotDeviceNoDept(query) {
  return request({
    url: '/iot/device/list/noindept',
    method: 'get',
    params: query
  })
}
// 绑定设备群组
export function bindIotDeviceDept(did, id) {
  return request({
    url: '/iot/device/dept/bind',
    method: 'post',
    data: {
      ids: id,
      dept_id: did
    }
  })
}
// 绑定设备群组
export function unbindIotDeviceDept(did, id) {
  return request({
    url: '/iot/device/dept/unbind',
    method: 'post',
    data: {
      ids: id,
      dept_id: did
    }
  })
}
// 获取设备属性列表值（最新更新）
export function getIotDeviceAttributeNew(id) {
  return request({
    url: '/iot/deivce/actual/attribute?id=' + id,
    method: 'get'
  })
}