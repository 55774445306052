import samples_json1 from './templates/samples_json1.js'
import samples_json2 from './templates/samples_json2.js'
import samples_json3 from './templates/samples_json3.js'
import samples_json4 from './templates/samples_json4.js'
import samples_json5 from './templates/samples_json5.js'
import samples_json6 from './templates/samples_json6.js'
import samples_json7 from './templates/samples_json7.js'
import samples_json8 from './templates/samples_json8.js'
export const formTemplates = [
  {
    title: '单列表单',
    imgUrl: require("@/assets/ft-images/t1.png"),
    jsonUrl:  samples_json1,
    description: '表单模板详细说明...'
  },

  {
    title: '多列表单',
    imgUrl:  require("@/assets/ft-images/t2.png"),
    jsonUrl: samples_json2,
    description: '表单模板详细说明...'
  },

  {
    title: '分组表单',
    imgUrl: require("@/assets/ft-images/t3.png"),
    jsonUrl: samples_json3,
    description: '表单模板详细说明...'
  },

  {
    title: '标签页表单',
    imgUrl: require("@/assets/ft-images/t4.png"),
    jsonUrl: samples_json4,
    description: '表单模板详细说明...'
  },

  {
    title: '主从表单',
    imgUrl: require("@/assets/ft-images/t5.png"),
    jsonUrl: samples_json5,
    description: '表单模板详细说明...'
  },

  {
    title: '响应式表单',
    imgUrl: require("@/assets/ft-images/t6.png"),
    jsonUrl: samples_json6,
    description: '表单模板详细说明...'
  },

  {
    title: '问卷调查表',
    imgUrl: require("@/assets/ft-images/t7.png"),
    jsonUrl: samples_json7,
    description: '表单模板详细说明...'
  },

  {
    title: '固定表格表单',
    imgUrl: require("@/assets/ft-images/t8.png"),
    jsonUrl: samples_json8,
    description: '表单模板详细说明...'
  },

]

export function clearFormTemplates() {
  formTemplates.splice(0, formTemplates.length)
}

export function addFormTemplate(newFormTemplate) {
  formTemplates.push(newFormTemplate)
}

export function getAllFormTemplates() {
  return formTemplates
}
