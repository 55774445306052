<template>
    <div class="app-container">
        <!-- <el-form :inline="true">
			<el-form-item label="机构名称">
				<el-input v-model="queryParams.name" placeholder="请输入机构名称" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="状态">
				<el-select v-model="queryParams.status" placeholder="机构状态" clearable size="small">
					<el-option v-for="dict in statusOptions" :key="dict.key" :label="dict.value" :value="dict.key" />
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button class="filter-item" type="primary" icon="el-icon-search" size="mini"
					@click="handleQuery">搜索</el-button> -->
        <!-- <el-button class="filter-item" type="primary" icon="el-icon-plus" size="mini"
					@click="handleAdd">新增</el-button> -->

        <!-- <el-button type="warning" icon="el-icon-upload" size="mini" @click="handleImport">导入</el-button>

				<el-button type="warning" icon="el-icon-download" size="mini" @click="handleExport">导出</el-button>
			 -->
        <!-- </el-form-item>
		</el-form> -->

        <!-- 	<vxe-grid resizable ref="xGrid" row-id="id" :tree-config="treeConfig" show-overflow highlight-hover-row
			:loading="loading" :columns="tableColumn" :data="deptList">
			<template #icon="{row}">
				<svg-icon :icon-class="row.icon" />
			</template> -->
        <!--默认操作按钮-->
        <!-- 		<template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-view" @click.stop="handleUpdate(row, true)">查看
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-plus" @click.stop="handleAdd(row)">新增
				</el-button>
				<el-button v-if="row.parent_id != 0" size="mini" type="text" icon="el-icon-delete"
					@click.stop="handleDelete(row)">删除
				</el-button>
			</template> -->

        <!--自定义空数据模板-->
        <!-- 			<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>

		</vxe-grid> -->
        <jf-table
            :loading="loading"
            ref="jfTable"
            :stripe="false"
            :columns="tableColumn"
            :data="deptList"
            :checkbox="false"
            :pager="false"
            :sortBtn="false"
            :columnsBtn="false"
            :filterBtn="false"
            :defaultoprWidth="320"
            :tree-config="treeConfig"
            :defaultopr-width="'280'"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    class="filter-item"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button type="warning" size="mini" @click="handleImport">
                    <i class="iconfont icon-daoru_o"></i>
                    导入
                </el-button>

                <el-button class="btn-orange" size="mini" @click="handleExport">
                    <i class="iconfont icon-daochu_o"></i>
                    导出
                </el-button>
            </template>
            <template slot="defaulttype" slot-scope="{ row }">
                {{ typeFormat(row) }}
            </template>
            <template slot="defaultstatus" slot-scope="{ row }">
                {{ statusFormat(row) }}
            </template>
            <template slot="icon" slot-scope="{ row }">
                <svg-icon :icon-class="row.icon" />
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="handleUpdate(row, true)"
                    >查看
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-plus"
                    @click.stop="handleAdd(row)"
                    >新增
                </el-button>
                <el-button
                    v-if="row.parent_id != 0"
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改机构对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
        >
            <jf-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="80px"
                :readonly="readonly"
            >
                <el-row>
                    <el-col :span="24" v-if="form.parent_id !== 0 || readonly">
                        <el-form-item label="上级机构" prop="parent_id">
                            <treeselect
                                v-model="form.parent_id"
                                :options="deptOptions"
                                :normalizer="normalizer"
                                placeholder="选择上级机构"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="机构名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入机构名称"
                                maxlength="100"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="机构编码" prop="no">
                            <el-input
                                v-model="form.no"
                                placeholder="请输入机构编码"
                                maxlength="30"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="机构类型">
                            <el-select
                                v-model="form.type"
                                placeholder="请选择"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="dict in typeOptions"
                                    :key="dict.key"
                                    :label="dict.value"
                                    :value="dict.key"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="显示排序" prop="sort">
                            <el-input-number
                                v-model="form.sort"
                                controls-position="right"
                                :min="0"
                                style="width: 100%"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="负责人" prop="leader_nos">
                            <!--<el-input v-model="form.leader_nos" placeholder="请输入负责人" maxlength="20" />-->
                            <admin-selectex
                                size="small"
                                placeholder="请选择用户"
                                title="用户"
                                idField="no"
                                :value="form.leader_nos"
                                :label="form.leader_names"
                                :readonly="true"
                                @getValue="
                                    (ids, names) => {
                                        form.leader_nos = ids;
                                        form.leader_names = names;
                                    }
                                "
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系电话" prop="phone">
                            <el-input
                                v-model="form.phone"
                                placeholder="请输入联系电话"
                                maxlength="255"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="email">
                            <el-input
                                v-model="form.email"
                                placeholder="请输入邮箱"
                                maxlength="50"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="网站" prop="homepage">
                            <el-input
                                v-model="form.homepage"
                                placeholder="请输入网站"
                                maxlength="255"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所在地" prop="address">
                            <el-input
                                v-model="form.address"
                                placeholder="请输入所在地"
                                maxlength="255"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职责" prop="responsibility">
                            <el-input
                                v-model="form.responsibility"
                                placeholder="请输入职责"
                                type="textarea"
                                maxlength="1500"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="机构状态">
                            <el-radio-group v-model="form.status">
                                <el-radio
                                    v-for="dict in statusOptions"
                                    :key="dict.key"
                                    :label="dict.key"
                                    >{{ dict.value }}</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </jf-form>
            <div slot="footer" class="dialog-footer">
                <template v-if="!readonly">
                    <el-button type="primary" @click="submitForm"
                        >确 定</el-button
                    >
                    <el-button @click="cancel">取 消</el-button>
                </template>
                <template v-else>
                    <el-button @click="cancel">关 闭</el-button>
                </template>
            </div>
        </el-dialog>

        <el-dialog
            :title="upload.title"
            :visible.sync="upload.open"
            width="400px"
            append-to-body
        >
            <el-upload
                ref="upload"
                :limit="1"
                accept=".xlsx"
                :headers="upload.headers"
                :action="upload.url + '?update=' + upload.update"
                :disabled="upload.isUploading"
                :on-progress="handleImportProgress"
                :on-success="handleImportSuccess"
                :on-error="handleImportError"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <el-checkbox
                        v-model="upload.update"
                    />是否更新已经存在的数据
                    <el-link
                        type="info"
                        style="font-size: 12px"
                        @click="handleImportTemplate"
                        >下载模板</el-link
                    >
                </div>
                <div class="el-upload__tip" style="color: red" slot="tip">
                    提示：仅允许导入“xlsx”格式文件！
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitImportForm"
                    >确 定</el-button
                >
                <el-button @click="upload.open = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Qs from "qs";
import { getAccessToken } from "@/utils/tokenTool";
import {
    listDept,
    getDept,
    delDept,
    addDept,
    updateDept,
    listDeptExcludeChild,
} from "@/api/core/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
    name: "dept-list",
    components: {
        Treeselect,
    },
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: false,
                custom: false,

                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            treeConfig: {
                trigger: "row",
                expandRowKeys: [100],
            },
            // 列
            tableColumn: [
                {
                    field: "name",
                    title: "机构名称",
                    treeNode: true,
                    is_query: true,
                },
                {
                    field: "no",
                    title: "机构编码",
                    width: 260,
                },
                {
                    field: "type",
                    title: "机构类型",
                    width: 100,
                    slots: {
                        default: "defaulttype",
                    },
                    // formatter: this.typeFormat
                },
                {
                    field: "sort",
                    title: "排序",
                    width: 200,
                },
                {
                    field: "status",
                    title: "状态",
                    width: 100,
                    slots: {
                        default: "defaultstatus",
                    },
                    is_query: true,
                    options: [],
                    options_label: "value",
                    options_value: "key",
                    form_type: 2,
                    // formatter: this.statusFormat
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    width: 180,
                    formatter: "formatDate",
                },
                // {
                // 	field: '',
                // 	title: '操作',
                // 	width: 320,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: {
                // 		default: 'defaultopr'
                // 	}
                // }
            ],
            // 遮罩层
            loading: true,
            // 表格树数据
            deptList: [],
            // 机构树选项
            deptOptions: [],
            // 弹出层标题
            title: "",
            // 是否查看
            readonly: false,
            // 是否显示弹出层
            open: false,
            // 状态数据字典
            statusOptions: [],
            // 机构类型数据字典
            typeOptions: [],
            // 查询参数
            queryParams: {
                name: undefined,
                status: undefined,
            },
            // 表单参数
            form: {},
            upload: {
                open: false,
                title: "",
                isUploading: false,
                update: false,
                headers: {
                    Authorization: getAccessToken(),
                },
                url: process.env.VUE_APP_BASE_API + "/dept/import",
            },
            // 表单校验
            rules: {
                parent_id: [
                    {
                        required: true,
                        message: "上级机构不能为空",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "机构名称不能为空",
                        trigger: "blur",
                    },
                ],
                no: [
                    {
                        required: true,
                        message: "机构编码不能为空",
                        trigger: "blur",
                    },
                ],
                email: [
                    {
                        type: "email",
                        message: "'请输入正确的邮箱地址",
                        trigger: ["blur", "change"],
                    },
                ],
                //phone: [ // 可能会有多个联系电话
                    // {
                    //     pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                    //     message: "请输入正确的手机号码",
                    //     trigger: "blur",
                    // },
                    //{ validator: this.validateContact, trigger: "blur" },
                //],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        validateContact(rule, value, callback) {
            // 自定义校验函数
            const mobilePattern = /^1\d{10}$/; // 手机号正则
            const telPattern = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,8}$/; // 固定电话正则

            if (!value) {
                callback();
            } else if (!mobilePattern.test(value) && !telPattern.test(value)) {
                callback(new Error("电话号码格式不正确"));
            } else {
                callback();
            }
        },
        /** 查询机构列表 */
        getList() {
            this.loading = true;
            listDept(this.queryParams).then((response) => {
                this.deptList = this.handleTree(
                    response.data.list,
                    "id",
                    "parent_id"
                );
                this.statusOptions = response.data.statusOptions.values || [];
                this.typeOptions = response.data.typeOptions.values || [];
                let index = this.tableColumn.findIndex(
                    (o) => o.field === "status"
                );
                if (index > -1) {
                    this.tableColumn[index].options =
                        response.data.statusOptions.values;
                }
                this.loading = false;
            });
        },
        /** 转换机构数据结构 */
        normalizer(node) {
            //console.log(node)
            if (node.children && !node.children.length) {
                delete node.children;
            }
            node.label = node.name;
            return {
                id: node.id,
                label: node.name,
                children: node.children,
            };
        },
        // 字典状态字典翻译
        statusFormat(row) {
            // return this.selectDictLabel(this.statusOptions, row.status);
            let find = this.statusOptions.find((v) => {
                return "" + v.key == "" + row.status;
            });
            if (find) {
                return find.value;
            } else {
                return "";
            }
        },
        // 字典状态字典翻译
        typeFormat(row) {
            return this.selectDictLabel(this.typeOptions, row.type);
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                parent_id: undefined,
                name: undefined,
                sort: undefined,
                leader_nos: undefined,
                leader_names: undefined,
                phone: undefined,
                email: undefined,
                homepage: undefined,
                address: undefined,
                responsibility: undefined,
                status: "1",
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 新增按钮操作 */
        handleAdd(row) {
            this.reset();
            if (row != undefined) {
                this.form.parent_id = row.id;
            }
            this.readonly = false;
            this.title = "添加机构";
            this.open = true;
            listDept().then((response) => {
                this.deptOptions = this.handleTree(
                    response.data.list,
                    "id",
                    "parent_id"
                );
            });
        },
        /** 修改按钮操作 */
        handleUpdate(row, readonly) {
            this.reset();
            getDept(row.id).then((response) => {
                this.form = response.data;
                this.readonly = readonly;
                this.title = readonly ? "查看机构" : "修改机构";
                this.open = true;
            });
            listDept(row.id).then((response) => {
                // 去掉本机构及以下机构
                const depts = [];
                for (let i = 0; i < response.data.list.length; i++) {
                    const dept = response.data.list[i];
                    if (
                        dept.id == row.id ||
                        ("," + dept.ancestors + ",").indexOf(
                            "," + row.id + ","
                        ) >= 0
                    ) {
                        continue;
                    }
                    depts.push(dept);
                }

                this.deptOptions = this.handleTree(depts, "id", "parent_id");
                this.$nextTick(() => {
                    if (this.$refs.form) {
                        this.$refs.form.refresh();
                    }
                });
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != undefined) {
                        updateDept(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addDept(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            this.$confirm(
                '是否确认删除名称为"' + row.name + '"的数据项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(function () {
                    return delDept(row.id);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        handleImport() {
            this.upload.title = "数据导入";
            this.upload.update = false;
            this.upload.open = true;
        },

        handleImportProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },

        handleImportSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            this.$alert(
                response.code == 0 ? "导入成功 " : response.msg,
                "提示",
                {
                    dangerouslyUseHTMLString: true,
                }
            );
            this.getList();
        },

        handleImportError(err, file, fileList) {
            this.upload.isUploading = false;
            this.$alert("导入失败", "提示", {
                dangerouslyUseHTMLString: true,
            });
        },

        submitImportForm() {
            this.$refs.upload.submit();
        },

        handleImportTemplate() {
            this.downloadbyurl("/dept/importTemplate");
        },

        handleExport() {
            this.$confirm("确定导出数据吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.downloadbyurl(
                    "/dept/export?" + Qs.stringify(this.queryParams)
                );
            });
        },
    },
};
</script>