<template>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        append-to-body
        center
        :width="width"
        ref="elDialog"
        v-dialog-drag
    >
        <div class="preview-container" v-if="dialogVisible">
            <generate-form
                ref="generateForm"
                :conf="formData.resourceData"
                :formBtns="false"
                v-if="showRenderJsx"
                @formDataChange="formDataChange"
            />
            <div id="customForm" v-else></div>
        </div>
        <span slot="footer" class="dialog-footer" v-loading="loading">
            <el-button size="small" type="primary" @click="submit"
                >提交</el-button
            >
            <el-button size="small" @click="dialogVisible = false"
                >关闭</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import Vue from "vue";
import GenerateForm from "./GenerateForm";
import { getResource } from "@/api/bpm/resource";
let styleDom = null;
const loadStyle = (cssCode) => {
    styleDom = document.createElement("style");
    styleDom.innerHTML = cssCode;
    document.head.appendChild(styleDom);
};
const loadVueOption = (jsCode, htmlCode, defaultData) => {
    let main = eval(`(${jsCode.replace("export default", "")})`);
    const oldData = main.data();
    Object.assign(oldData.formData, defaultData);
    const newData = Object.assign({}, oldData, {
        /**other data */
    });
    main.data = function () {
        return newData;
    };
    main.template = `<div>${htmlCode}</div>`;
    return main;
};
import * as Formx from "@/api/form/data";
/**
 * 默认数据  预览已经填写过的表单时
 * 将以下数据合并到Vue实例中即可
 */
const defData = {
    // "field2": "22",
    // "field3": "本科",
    // "field4": "男",
    // "field5": [
    //   {
    //     "field6": [
    //       "2020-07-01",
    //       "2020-08-03"
    //     ],
    //     "field7": "212",
    //     "field8": "后端",
    //     "field9": []
    //   }
    // ],
    // "field10": 21,
};
export default {
    props: {
        title: {
            type: String,
            default: "预览",
        },
        width: {
            type: String,
            default: "800px",
        },
    },
    data() {
        return {
            loading: false,
            dialogVisible: false,
            showRenderJsx: true,
            formData: {},
        };
    },
    components: {
        GenerateForm,
    },
    methods: {
        /**
         * 预览表单
         * @param formData 表单数据
         */
        formDataChange(e) {
            if (e.props.formId == 1 && e.props.value == 1) {
                this.formData.resourceData.fields[1].show = true;
                console.log("测试", this.formData.resourceData.fields[1].show);
            }

            console.log(e, "主打一个白干", this.formData.resourceData);
        },
        submit() {
            if (this.showRenderJsx) {
                this.$refs.generateForm.getData().then((data) => {
                    console.log(JSON.stringify(data));
                });
            }
        },
        async init(formData) {
            let resp = await getResource(
                0,
                "1",
                JSON.stringify(formData.resourceData)
            );
            formData.resourceData = JSON.parse(resp.data.resource);
            this.initResourceData(formData.resourceData);
            this.formData = formData;
            this.dialogVisible = true;
            this.loading = false;
            if (this.showRenderJsx) {
                return;
            }
            // defaultData 可以将默认formData覆盖到data中
            this.$nextTick(() => {
                let { resourceData, jsCode, htmlCode, cssCode } = formData;
                loadStyle(cssCode);
                const OPTION = loadVueOption(jsCode, htmlCode, defData);
                const Form = Vue.extend(OPTION);
                new Form().$mount("#customForm");
            });
        },
        async getFormsrvData(mid) {
            var param = {
                fieldval: {},
                sortinfo: null,
                pageNum: 1,
                pageSize: 100,
            };
            let res = await Formx.FormxShowList(mid, param);
            // console.log("getFormsrvData res", res.data.list);
            return res.data.list;
        },

        initResourceData(resourceData) {
            //console.log("resourceData",resourceData)

            resourceData.fields.forEach((field) => {
                this.setFormField(field);
                Array.isArray(field.children) &&
                    field.children.forEach((childField) => {
                        this.setFormField(childField);
                    });
            });
            return resourceData;
        },
        setFormField(field) {
            if (field.formsvr && field.formsvr.id) {
                var formdatalist = this.getFormsrvData(field.formsvr.id);
                formdatalist.then((rs) => {
                    let toption = [];
                    for (let ii in rs) {
                        let onerec = rs[ii];
                        toption.push({
                            label: onerec[field.formsvr.optionLabel],
                            value: onerec[field.formsvr.optionValue],
                            originData: onerec,
                        });
                    }
                    console.log("select toption", toption);
                    field.options = toption;
                });
            }
        },
    },
    beforeDestroy() {
        styleDom && styleDom.remove();
    },
};
</script>
<style lang="scss" scoped>
::v-deep.el-dialog {
    margin: 5vh auto !important;
}

::v-deep .el-dialog__body {
    height: 70vh;
    overflow: auto;
}
</style>
