<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="请选择数据集"
      :visible.sync="visible"
      width="600px"
      append-to-body
    >
      <div style="margin-top: -30px">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="实时数据集" name="direct">
            <el-input
              placeholder="输入关键字进行过滤"
              v-model="filterTextDirect"
              clearable
            >
            </el-input>
            <el-tree
              class="filter-tree"
              node-key="id"
              :data="dataTreeDirect"
              :props="defaultProps"
              :default-expand-all="false"
              :expand-on-click-node="true"
              :filter-node-method="filterNodeDirect"
              :show-checkbox="true"
              :check-on-click-node="false"
              :check-strictly="multiple ? false : true"
              @check="handleCheck"
              ref="treeDirect"
            >
              <div class="custom-tree-node" slot-scope="{ node, data }">
                <img v-if="data.type=='3'||data.type=='4'" :src='imgs.table' class="custom-tree-node-img" />
                <div class="el-tree-node__label custom-tree-node-label" :title="node.label">{{node.label}}</div>
              </div>
            </el-tree>
          </el-tab-pane>
          <el-tab-pane label="抽取数据集" name="extract">
            <el-input
              placeholder="输入关键字进行过滤"
              v-model="filterTextExtract"
              clearable
            >
            </el-input>
            <el-tree
              class="filter-tree"
              node-key="id"
              :data="dataTreeExtract"
              :props="defaultProps"
              :default-expand-all="false"
              :expand-on-click-node="true"
              :filter-node-method="filterNodeExtract"
              :show-checkbox="true"
              :check-on-click-node="false"
              :check-strictly="multiple ? false : true"
              @check="handleCheck"
              ref="treeExtract"
            >
              <div class="custom-tree-node" slot-scope="{ node, data }">
                <img v-if="data.type=='3'||data.type=='4'" :src='imgs.table' class="custom-tree-node-img" />
                <div class="el-tree-node__label custom-tree-node-label" :title="node.label">{{node.label}}</div>
              </div>
            </el-tree>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listModel } from "@/api/core/data/datasource/model";

export default {
  name: 'model-select',
  props: {
    multiple: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      activeTab: 'direct',
      dataTreeDirect: [],
      dataTreeExtract: [],
      selectedIds: [],
      visible: false,
      filterTextDirect: '',
      filterTextExtract: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      imgs: {
        //folder: require("@/assets/image/folder1.png"),
        table: require("@/assets/image/table.png")
      }
    }
  },
  created() {
    this.getTree()
  },
  methods: {
    getTree() {
      if (this.dataTree) {
        return
      }

      listModel().then(response => {
        if (response.code == 0) {
          const dataListDirect = []
          const dataListExtract = []
          for (let i = 0; i < response.data.list.length; i++) {
            const d = response.data.list[i]
            if (!this.multiple && (d.type != '3' && d.type != '4')) {
              d.disabled = true
            }
            if (d.mode == '0') {
              dataListDirect.push(d)
            } else {
              dataListExtract.push(d)
            }
          }
          this.dataTreeDirect = this.handleTree(dataListDirect, 'id', 'pid');
          this.dataTreeExtract = this.handleTree(dataListExtract, 'id', 'pid');
        }
      });
    },
    open(update) {
      if (update) {
        this.dataTree = undefined
      }
      this.selectedKey = undefined
      this.selectedIds = []
      if (this.$refs.treeDirect) {
        this.$refs.treeDirect.setCheckedKeys([], true)
      }
      if (this.$refs.treeExtract) {
        this.$refs.treeExtract.setCheckedKeys([], true)
      }
      this.filterTextDirect = ''
      this.filterTextExtract = ''
      this.getTree()
      this.visible = true;
    },
    confirm() {
      if (this.selectedIds.length == 0) {
        this.$message.info('请选择')
        return
      }
      this.$emit('change', this.selectedIds)
      this.visible = false;
    },
    cancel() {
      this.visible = false;
    },
    filterNodeDirect(value, data) {
      if (data.mode == '1') return false;
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    filterNodeExtract(value, data) {
      if (data.mode == '0') return false;
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleCheck(e1, e2) {
      if (this.multiple) {
        const selectedIds = []
        for (let i = 0; i < e2.checkedNodes.length; i++) {
          const node = e2.checkedNodes[i]
          if (node.type == '3' || node.type == '4') {
            selectedIds.push(node.id)
          }
        }
        this.selectedIds = selectedIds
      } else {
        if (e1.type == '3' || e1.type == '4') {
          this.selectedIds = [e1.id]
        } else {
          this.selectedIds = []
        }
        if (this.activeTab == 'direct') {
          this.$refs.treeDirect.setCheckedKeys(this.selectedIds, true)
        } else {
          this.$refs.treeExtract.setCheckedKeys(this.selectedIds, true)
        }
      }
    }
  },
  watch: {
    activeTab(val) {
      if (this.$refs.treeDirect) {
        this.$refs.treeDirect.setCheckedKeys([], true)
      }
      if (this.$refs.treeExtract) {
        this.$refs.treeExtract.setCheckedKeys([], true)
      }
      this.selectedIds = []
    },
    filterTextDirect(val) {
      this.$refs.treeDirect.filter(val);
    },
    filterTextExtract(val) {
      this.$refs.treeExtract.filter(val);
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  width:8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:8px;
}
::-webkit-scrollbar-thumb {
  border-radius:8px;
  background:rgba(0,0,0,0.1);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb:hover {
  background:rgba(0,0,0,0.2);
}
.filter-tree {
  margin-top: 10px;
  max-height: 500px;
  overflow: auto;
}
.custom-tree-node {
  width: 100%;
  display: flex;
  align-items: center;
  height: 26px;
  padding-right: 5px;
}
.custom-tree-node-img {
  width:20px; 
  height:20px; 
  margin-right:6px
}
.custom-tree-node-label {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>