<template>
<div class="app-container">
	<el-form
		:model="queryParams"
		ref="queryForm"
		:inline="true"
		label-width="68px"
		>
		<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<!--<el-form-item label="用户id" prop="admin_id">
			<el-input
				v-model="queryParams.admin_id"
				placeholder="请输入用户id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
		<!--<el-form-item label="培训班" prop="class_id">
			<grid-select
        v-model="queryParams.class_id"
        :multiple="false"
        placeholder="请选择培训班"
        width="960px"
        :searchItems="[
          { field: 'name', title: '名称' },
          { field: 'no', title: '班次' },
        ]"
        :gridItems="[
          { field: 'name', title: '名称' },
          { field: 'no', title: '班次' },
          { field: 'remark', title: '简介' },
        ]"
        :getDataList="listGbpxClassU"
      >
      </grid-select>
		</el-form-item>-->
    <el-form-item label="名称" prop="class_name">
			<el-input
				v-model="queryParams.class_name"
				placeholder="请输入名称"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
    <el-form-item label="班次" prop="class_no">
			<el-input
				v-model="queryParams.class_no"
				placeholder="请输入班次"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item>
			<el-button
				type="cyan"
				icon="el-icon-search"
				size="mini"
				@click="handleQuery"
				>搜索</el-button
			>
			<el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
				>重置</el-button
			>
		</el-form-item>
	</el-form>

	<vxe-grid
		resizable
		ref="xGrid"
		row-id="id"
		stripe
		show-overflow
		highlight-hover-row
		:loading="loading"
		:toolbar-config="tableToolbar"
		:columns="tableColumn"
		:data="dataList"
		@checkbox-change="checkboxChangeEvent"
		@checkbox-all="checkboxChangeEvent"
	>
		<template #toolbar_buttons>
      <grid-select
        ref="selectClassGrid"
        :multiple="true"
        placeholder="请选择培训班"
        width="960px"
        :label="
          (form.class && form.class.name) || ''
        "
        :searchItems="[
          { field: 'name', title: '名称' },
          { field: 'no', title: '班次' },
        ]"
        :gridItems="[
          { field: 'name', title: '名称' },
          { field: 'no', title: '班次' },
          { field: 'remark', title: '简介' },
        ]"
        :getDataList="listGbpxClassU"
        @change="handleSelectClass"
      >
        <div></div>
      </grid-select>
      <el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAddClass"
				v-hasPermi="[
					'/gbpx/gbpxclassunit/add',
				]"
				>新增</el-button
			>
			<!--<el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAdd"
				v-hasPermi="[
					'/gbpx/gbpxclassunit/add',
				]"
				>新增</el-button
			>

			<el-button
				type="success"
				icon="el-icon-edit"
				size="mini"
				:disabled="single"
				@click="handleUpdate"
				v-hasPermi="[
					'/gbpx/gbpxclassunit/get',
					'/gbpx/gbpxclassunit/edit',
				]"
				>修改</el-button
			>-->

			<el-button
				type="danger"
				icon="el-icon-delete"
				size="mini"
				:disabled="multiple"
				@click="handleDelete"
				v-hasPermi="[
					'/gbpx/gbpxclassunit/del',
				]"
				>删除</el-button
			>
		</template>

		<template #defaultopr="{ row }">
			<!--<el-button
				size="mini"
				type="text"
				icon="el-icon-edit"
				@click.stop="handleUpdate(row)"
				>修改
			</el-button>-->
			<el-button
				size="mini"
				type="text"
				icon="el-icon-delete"
				@click.stop="handleDelete(row)"
				>删除
			</el-button>
		</template>

		<template #empty>
			<span>
				<p>暂无数据</p>
			</span>
		</template>
	</vxe-grid>

	<vxe-pager
		background
		size="small"
		:loading="loading"
		:current-page.sync="queryParams.pageNum"
		:page-size.sync="queryParams.pageSize"
		:total="total"
		@page-change="handlePageChange"
	>
	</vxe-pager>

	<el-dialog
		:close-on-click-modal="false"
		:title="title"
		:visible.sync="open"
		width="800px"
		append-to-body
	>
		<el-form ref="form" :model="form" :rules="rules" label-width="100px">
			<el-row>
				<!--<el-col :span="12">
					<el-form-item label="用户id" prop="admin_id">
						<el-input-number
							v-model="form.admin_id"
							placeholder="请输入用户id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>-->
				<el-col :span="12">
					<el-form-item label="培训班" prop="class_id">
						<grid-select
                v-model="form.class_id"
                :multiple="false"
                placeholder="请选择培训班"
                width="960px"
                :label="
                  (form.class && form.class.name) || ''
                "
                :searchItems="[
                  { field: 'name', title: '名称' },
                  { field: 'no', title: '班次' },
                ]"
                :gridItems="[
                  { field: 'name', title: '名称' },
                  { field: 'no', title: '班次' },
                  { field: 'remark', title: '简介' },
                ]"
                :getDataList="listGbpxClassU"
              >
              </grid-select>
					</el-form-item> 
				</el-col>
			</el-row>
		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitForm">确 定</el-button>
			<el-button @click="cancel">返 回</el-button>
		</div>
	</el-dialog>
</div>
</template>
<script>
import {
	listGbpxClassUnit,
	getGbpxClassUnit,
	addGbpxClassUnit,
  AddBatchGbpxClassUnit,
	editGbpxClassUnit,
	delGbpxClassUnit,
} from "@/api/gbpx/gbpx_class_unit";
import {
  listGbpxClassU,
  getGbpxClass,
} from "@/api/gbpx/gbpx_class";
import moment from "moment";
export default {
	name: "gbpx-class-unit",
	data() {
		return { 
			tableToolbar: {
				perfect: true,
				zoom: true,
				custom: true,
				refresh: {
					query: this.handleQuery,
				},
				slots: {
					buttons: "toolbar_buttons",
				},
			},
			tableColumn: [
				{ type: "checkbox", width: 60, fixed: "left" },
				{ field: "id", title: "id", minWidth: 120, fixed: "left" },
				//{ field: "admin_id", title: "用户id", minWidth: 120 },
				{ field: "class_name", title: "名称", minWidth: 120 },
        { field: "class.no", title: "班次", minWidth: 120 },
        { field: "class.student_num", title: "人数", minWidth: 120 },
        { field: "class.contact", title: "联系人", minWidth: 120 },
        { field: "class.phone", title: "手机号", minWidth: 120 },
        //{ field: "class.begin_time", title: "开始时间", formatter: "formatDate", minWidth: 120 },
        //{ field: "class.end_time", title: "结束时间", formatter: "formatDate", minWidth: 120 },
        {
          field: "time",
          title: "开展时间",
          formatter: this.classTimeFormat,
          minWidth: 120,
        },
        { field: "class.remark", title: "简介", minWidth: 120 },
				{ field: "create_by", title: "创建者", minWidth: 120, visible: false },
				{ field: "create_time", title: "创建时间", minWidth: 120, formatter: "formatDate", visible: false },
				{ field: "update_by", title: "更新者", minWidth: 120, visible: false },
				{ field: "update_time", title: "更新时间", minWidth: 120, formatter: "formatDate" },
				{
					field: "",
					title: "操作",
					width: 180,
					fixed: "right",
					align: "center",
					slots: { default: "defaultopr" },
				},
			],
			loading: true,
			ids: [],
			single: true,
			multiple: true,
			total: 0,
			dataList: [],
			title: "",
			open: false,
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				id: undefined,
				admin_id: undefined,
				class_id: undefined,
        class_name: undefined,
        class_no: undefined,
			},
			form: {},
			rules: { 
				admin_id: [{ required: true, message: "用户id不能为空", trigger: "blur" }],
				class_id: [{ required: true, message: "培训班不能为空", trigger: "blur" }],
			},
      listGbpxClassU
		};
	},
	created() { 
		this.getList();
	},
	methods: {
		getList() {
			this.loading = true;
			this.ids = [];
			this.single = true;
			this.multiple = true;

			listGbpxClassUnit(this.queryParams).then((response) => { 
				this.dataList = response.data.list;
				this.total = response.data.total;
				this.loading = false;
			});
		},
		
		cancel() {
			this.open = false;
			this.reset();
		},

    classTimeFormat(e) {
      if (e.row.class.begin_time && e.row.class.end_time) {
        return (
          moment(e.row.class.begin_time * 1000).format("yyyy-MM-DD HH:mm") +
          " 至 " +
          moment(e.row.class.end_time * 1000).format("yyyy-MM-DD HH:mm")
        );
      }
      return e.cellValue;
    },

		reset() {
			this.form = { 
				admin_id: undefined,
				class_id: undefined,
			};
			this.resetForm("form");
		},

		handleQuery() {
			this.queryParams.pageNum = 1;
			this.getList();
		},

		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},

		handleAdd() {
			this.reset();
			this.open = true;
			this.title = "添加";
		},

    handleAddClass() {
      this.$refs.selectClassGrid.open()
    },

    handleSelectClass(e1, e2) {
      console.log(e1, e2)
      AddBatchGbpxClassUnit(e1).then(response => {
        if (response.code === 0) {
					this.msgSuccess("添加成功");
          setTimeout(() => {
            this.getList();
          }, 300);
        }
      })
    },

		handleUpdate(row) {
			this.reset();
			const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
			getGbpxClassUnit(id).then((response) => {
				this.form = response.data;
				this.open = true;
				this.title = "修改";
			});
		},

		submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if (this.form.id != null) {
						editGbpxClassUnit(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("修改成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					} else {
						addGbpxClassUnit(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("新增成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					}
				}
			});
		},

		handleDelete(row) {
			const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
			this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(function () {
				return delGbpxClassUnit(ids);
			})
			.then(() => {
				setTimeout(() => {
					this.getList();
				}, 300);
				this.msgSuccess("删除成功");
			})
			.catch(function () {});
		},

		checkboxChangeEvent({ records }) {
			this.ids = records.map((item) => item.id);
			this.single = records.length != 1;
			this.multiple = !records.length;
		},

		handlePageChange({ currentPage, pageSize }) {
			if (this.queryParams.pageSize == pageSize) {
				this.getList();
			} else {
				this.queryParams.pageSize = pageSize;
				this.handleQuery();
			}
		},
	}
};
</script>
