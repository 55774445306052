<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
			<el-form-item label="用户池" prop="userpool">
				<el-input v-model="queryParams.userpool" placeholder="请输入用户池" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="名称" prop="name">
				<el-input v-model="queryParams.name" placeholder="请输入名称" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item>
				<el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>

		<!-- <vxe-grid resizable ref="xGrid" row-id="id" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="dataList"
			@checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="[
					'/core/application/add',
				]">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[
					'/core/application/get',
					'/core/application/edit',
				]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="[
					'/core/application/del',
				]">删除</el-button>
			</template>

			<template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>

			<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>
		</vxe-grid> -->

		<!-- <vxe-pager background size="small" :loading="loading" :current-page.sync="queryParams.pageNum"
			:page-size.sync="queryParams.pageSize" :total="total" @page-change="handlePageChange">
		</vxe-pager> -->

		<jf-table :loading="loading" :columns="tableColumn" :data="dataList" :toolbar-config="tableToolbar"
			:query-params="queryParams" @checkbox-change="checkboxChangeEvent" @page-change="handlePageChange"
			:total="total" @change="tableChange">
			<template slot="toolbar_btn_left">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="[
					'/core/application/add',
				]">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[
					'/core/application/get',
					'/core/application/edit',
				]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="[
					'/core/application/del',
				]">删除</el-button>
			</template>


			<template slot="toolbar_btn_row" slot-scope="{ row }">
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>


		</jf-table>


		<el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
			<el-form ref="form" :model="form" :rules="rules" label-position="top" size="big" label-width="100px">
				<el-row>
					<!--<el-col :span="24">
					<el-form-item label="" prop="userpool">
						<el-input
							v-model="form.userpool"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
					<el-col :span="24">
						<el-form-item label="名称" prop="name">
							<el-input v-model="form.name" placeholder="请输入名称" clearable :disabled="form.id > 0" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="显示名称" prop="display_name">
							<el-input v-model="form.display_name" placeholder="请输入显示名称" clearable />
						</el-form-item>
					</el-col>
					<!--<el-col :span="24">
					<el-form-item label="" prop="logo">
						<el-input
							v-model="form.logo"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="homepage_url">
						<el-input
							v-model="form.homepage_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="description">
						<el-input
							v-model="form.description"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="cert">
						<el-input
							v-model="form.cert"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_password">
						<el-input
							v-model="form.enable_password"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_sign_up">
						<el-input
							v-model="form.enable_sign_up"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_signin_session">
						<el-input
							v-model="form.enable_signin_session"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_auto_signin">
						<el-input
							v-model="form.enable_auto_signin"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_code_signin">
						<el-input
							v-model="form.enable_code_signin"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_saml_compress">
						<el-input
							v-model="form.enable_saml_compress"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_web_authn">
						<el-input
							v-model="form.enable_web_authn"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_link_with_email">
						<el-input
							v-model="form.enable_link_with_email"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="saml_reply_url">
						<el-input
							v-model="form.saml_reply_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="providers">
						<el-input
							v-model="form.providers"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signup_items">
						<el-input
							v-model="form.signup_items"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="grant_types">
						<el-input
							v-model="form.grant_types"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
					<el-col v-if="form.id" :span="24">
						<el-form-item label="client_id" prop="client_id">
							<el-input v-model="form.client_id" placeholder="请输入client_id" clearable :readonly="true" />
						</el-form-item>
					</el-col>
					<el-col v-if="form.id" :span="24">
						<el-form-item label="client_secret" prop="client_secret">
							<el-input v-model="form.client_secret" placeholder="请输入client_secret" clearable
								:readonly="true" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="可跳转地址" prop="redirect_uris">
							<el-input v-model="form.redirect_uris" placeholder="请输入可跳转地址" clearable />
						</el-form-item>
					</el-col>
					<!--<el-col :span="24">
					<el-form-item label="" prop="token_format">
						<el-input
							v-model="form.token_format"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="expire_in_hours">
						<el-input-number
							v-model="form.expire_in_hours"
							placeholder="请输入"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="refresh_expire_in_hours">
						<el-input-number
							v-model="form.refresh_expire_in_hours"
							placeholder="请输入"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signup_url">
						<el-input
							v-model="form.signup_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signin_url">
						<el-input
							v-model="form.signin_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="forget_url">
						<el-input
							v-model="form.forget_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="affiliation_url">
						<el-input
							v-model="form.affiliation_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="terms_of_use">
						<el-input
							v-model="form.terms_of_use"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signup_html">
						<el-input
							v-model="form.signup_html"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="signin_html">
						<el-input
							v-model="form.signin_html"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="theme_data">
						<el-input
							v-model="form.theme_data"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_css">
						<el-input
							v-model="form.form_css"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_css_mobile">
						<el-input
							v-model="form.form_css_mobile"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_offset">
						<el-input-number
							v-model="form.form_offset"
							placeholder="请输入"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_side_html">
						<el-input
							v-model="form.form_side_html"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="form_background_url">
						<el-input
							v-model="form.form_background_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="remark">
						<el-input
							v-model="form.remark"
							placeholder="请输入"
							clearable
							type="textarea"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="create_time">
						<el-input-number
							v-model="form.create_time"
							placeholder="请输入"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>-->
				</el-row>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm">确 定</el-button>
				<el-button @click="cancel">返 回</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		listApplication,
		getApplication,
		addApplication,
		editApplication,
		delApplication,
	} from "@/api/core/system/application";
	export default {
		name: "application-list",
		data() {
			return {
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery,
					},
					slots: {
						buttons: "toolbar_buttons",
					},
				},
				tableColumn: [
					// {
					// 	type: "checkbox",
					// 	width: 60,
					// 	fixed: "left"
					// },
					//{ field: "id", title: "", minWidth: 120, fixed: "left" },
					{
						field: "userpool",
						title: "用户池",
						minWidth: 120
					},
					{
						field: "name",
						title: "名称",
						minWidth: 120
					},
					{
						field: "display_name",
						title: "显示名称",
						minWidth: 120
					},
					/*{ field: "logo", title: "", minWidth: 120 },
					{ field: "homepage_url", title: "", minWidth: 120 },
					{ field: "description", title: "", minWidth: 120 },
					{ field: "cert", title: "", minWidth: 120 },
					{ field: "enable_password", title: "", minWidth: 120 },
					{ field: "enable_sign_up", title: "", minWidth: 120 },
					{ field: "enable_signin_session", title: "", minWidth: 120 },
					{ field: "enable_auto_signin", title: "", minWidth: 120 },
					{ field: "enable_code_signin", title: "", minWidth: 120 },
					{ field: "enable_saml_compress", title: "", minWidth: 120 },
					{ field: "enable_web_authn", title: "", minWidth: 120 },
					{ field: "enable_link_with_email", title: "", minWidth: 120 },
					{ field: "saml_reply_url", title: "", minWidth: 120 },
					{ field: "providers", title: "", minWidth: 120 },
					{ field: "signup_items", title: "", minWidth: 120 },
					{ field: "grant_types", title: "", minWidth: 120 },*/
					{
						field: "client_id",
						title: "client_id",
						minWidth: 120
					},
					/*{ field: "client_secret", title: "client_secret", minWidth: 120 },
					{ field: "redirect_uris", title: "", minWidth: 120 },
					{ field: "token_format", title: "", minWidth: 120 },
					{ field: "expire_in_hours", title: "", minWidth: 120 },
					{ field: "refresh_expire_in_hours", title: "", minWidth: 120 },
					{ field: "signup_url", title: "", minWidth: 120 },
					{ field: "signin_url", title: "", minWidth: 120 },
					{ field: "forget_url", title: "", minWidth: 120 },
					{ field: "affiliation_url", title: "", minWidth: 120 },
					{ field: "terms_of_use", title: "", minWidth: 120 },
					{ field: "signup_html", title: "", minWidth: 120 },
					{ field: "signin_html", title: "", minWidth: 120 },
					{ field: "theme_data", title: "", minWidth: 120 },
					{ field: "form_css", title: "", minWidth: 120 },
					{ field: "form_css_mobile", title: "", minWidth: 120 },
					{ field: "form_offset", title: "", minWidth: 120 },
					{ field: "form_side_html", title: "", minWidth: 120 },
					{ field: "form_background_url", title: "", minWidth: 120 },
					{ field: "remark", title: "", minWidth: 120 },*/
					{
						field: "create_by",
						title: "创建者",
						minWidth: 120,
						visible: false
					},
					{
						field: "create_time",
						title: "创建时间",
						minWidth: 120,
						formatter: "formatDate",
						visible: false
					},
					{
						field: "update_by",
						title: "更新者",
						minWidth: 120,
						visible: false
					},
					{
						field: "update_time",
						title: "更新时间",
						minWidth: 120,
						formatter: "formatDate"
					},
					// {
					// 	field: "",
					// 	title: "操作",
					// 	width: 180,
					// 	fixed: "right",
					// 	align: "center",
					// 	slots: {
					// 		default: "defaultopr"
					// 	},
					// },
				],
				loading: true,
				ids: [],
				single: true,
				multiple: true,
				total: 0,
				dataList: [],
				title: "",
				open: false,
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					id: undefined,
					userpool: undefined,
					name: undefined,
				},
				form: {},
				rules: {
					userpool: [{
						required: true,
						message: "用户池不能为空",
						trigger: "blur"
					}],
					name: [{
						required: true,
						message: "名称不能为空",
						trigger: "blur"
					}],
          display_name: [{
						required: true,
						message: "显示名称不能为空",
						trigger: "blur"
					}],
				}
			};
		},
		created() {
			this.getList();
		},
		methods: {
			//列表筛选以及排序
			tableChange(e) {
				console.log(e)
			},
			getList() {
				this.loading = true;
				this.ids = [];
				this.single = true;
				this.multiple = true;
				this.queryParams.type = '1'
				listApplication(this.queryParams).then((response) => {
					this.dataList = response.data.list;
					this.total = response.data.total;
					this.loading = false;
				});
			},

			cancel() {
				this.open = false;
				this.reset();
			},

			reset() {
				this.form = {
					userpool: undefined,
					name: undefined,
					display_name: undefined,
					logo: undefined,
					homepage_url: undefined,
					description: undefined,
					cert: undefined,
					enable_password: undefined,
					enable_sign_up: undefined,
					enable_signin_session: undefined,
					enable_auto_signin: undefined,
					enable_code_signin: undefined,
					enable_saml_compress: undefined,
					enable_web_authn: undefined,
					enable_link_with_email: undefined,
					saml_reply_url: undefined,
					providers: undefined,
					signup_items: undefined,
					grant_types: undefined,
					client_id: undefined,
					client_secret: undefined,
					redirect_uris: undefined,
					token_format: undefined,
					expire_in_hours: undefined,
					refresh_expire_in_hours: undefined,
					signup_url: undefined,
					signin_url: undefined,
					forget_url: undefined,
					affiliation_url: undefined,
					terms_of_use: undefined,
					signup_html: undefined,
					signin_html: undefined,
					theme_data: undefined,
					form_css: undefined,
					form_css_mobile: undefined,
					form_offset: undefined,
					form_side_html: undefined,
					form_background_url: undefined,
					remark: undefined,
					create_time: undefined,
				};
				this.resetForm("form");
			},

			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},

			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},

			handleAdd() {
				this.reset();
				this.open = true;
				this.title = "添加";
			},

			handleUpdate(row) {
				this.reset();
				const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
				getApplication(id).then((response) => {
					this.form = response.data;
					this.open = true;
					this.title = "修改";
				});
			},

			submitForm() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.form.type = '1'
						if (this.form.id != null) {
							editApplication(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("修改成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						} else {
							addApplication(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("新增成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						}
					}
				});
			},

			handleDelete(row) {
				const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
				this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(function() {
						return delApplication(ids);
					})
					.then(() => {
						setTimeout(() => {
							this.getList();
						}, 300);
						this.msgSuccess("删除成功");
					})
					.catch(function() {});
			},

			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map((item) => item.id);
				this.single = records.length != 1;
				this.multiple = !records.length;
			},

			handlePageChange({
				currentPage,
				pageSize
			}) {
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}
			},
		}
	};
</script>