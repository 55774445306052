<template>
    <div class="markdown-editor-box">
        <link rel="stylesheet" href="./static/editor.md/css/editormd.min.css" />
        <div :id="editorId"></div>
      
    </div>
</template>
  
  <script>
import scriptjs from "scriptjs";
import { defaultConfig } from "./config/MarkdownConfig";
// import { streamChats } from "@/api/api";
export default {
    name: "MarkdownEditor",
   
    props: {
        editorId: {
            type: String,
            default: "markdown-editor",
        },
        // onchange: {
        //     type: Function,
        // },
        config: {
            type: Object,
        },
        initData: {
            type: String,
        },
        initDataDelay: {
            type: Number,
            default: 0,
        },
        height:{
            type: String,
            default: "80vh",
        }
    },
    data() {
        return {
            editor: null,
            editorLoaded: false,
            visible: false,
            top: 0,
            left: 0,
            selectedTag: {},
            selectedRoute: {},
            answerAvatar: "",
            dialogVisible: false,
            replaceText: "",
            replaceData: {},
            displayText: "",
            selectText:'',
            is_end: false,
        };
    },
    created() {},
    watch: {
        // visible(value) {
        //     if (value) {
        //         document.body.addEventListener("click", this.closeMenu);
        //     } else {
        //         document.body.removeEventListener("click", this.closeMenu);
        //     }
        // },
        initData: function (newVal) {
            if (newVal) {
                this.editorLoaded && this.editor.setMarkdown(newVal);
            }
        },
    },
    methods: {
        testIcon() {},
        getReplace() {
            this.$emit("replace");
        },
        //替换
        getReplaceMarkdownText() {
            this.replaceSelection(this.replaceData.content);
            // this.handleClose();
        },
        //列表选择
        menuChange(value){
            this.replaceText=this.selectText? `${value}这段'${this.selectText}'文字`:value
            this.onSubmit()
        },
        onSubmit() {
            if (!this.$store.getters["user/accessToken"]) {
                this.$message.warning("请先登录");
                return false;
            }

            if (!this.replaceText) {
                return;
            }

            this.replaceData = { type: 2, loading: true, content: "" };

            let is_one = true;

            streamChats({
                content: this.replaceText,
                complete: (res) => {
                    console.log(res, "该请求结束事件");
                    // 该请求结束事件
                    this.is_end = true;
                },
                chunked: (data) => {
                    // console.log(data.result);
                    // 分段收到的数据
                    this.displayText += data.result;
                    if (data.is_end) {
                        this.is_end = true;
                    }
                    if (is_one) {
                        is_one = false;

                        this.showChatGptResponse(this.replaceData);

                        // this.showChatGptResponse();
                        //防止加载过快导致数据遗漏
                    }
                },
                fail: (err) => {
                    console.log("fail", err);
                    this.is_end = true;
                    this.displayText = "当前网络不太好";
                    this.showChatGptResponse(this.replaceData);
                },
            });
        },
        showChatGptResponse(item) {
            // 逐字显示 ChatGPT 回答

            let i = 0;

            let time = setInterval(() => {
                item.content += this.displayText[i] || "";
                if (this.displayText[i]) {
                    i++;
                }

                if (
                    this.is_end &&
                    item.content.length === this.displayText.length
                ) {
                    this.displayText = "";
                    this.is_end = false;

                    this.disabled = false;
                    item.loading = false;
                    this.loading = false;

                    clearInterval(time);
                }
            }, 40);
        },

        itemBindEvent() {
            const outerDiv = document.querySelector(".CodeMirror-code");

            if (outerDiv) {
                // 在外层 div 元素内部通过类名获取内部的 div 元素
                // const innerDiv = outerDiv.querySelectorAll("div");

                // if (innerDiv) {
                //     // 现在你可以访问内部的 div 元素了
                //     console.log(innerDiv); // 输出 "内部的 div 元素"
                //     // this.$nextTick(() => {
                //     //     console.log(
                //     //         document
                //     //             .querySelector(".markdown-editor-box")
                //     //             .querySelector(".CodeMirror")
                //     //     );
                //     // });

                //     // 获取节点
                //     // 给每个节点绑定对应的事件
                //     innerDiv.forEach((item) => {
                //         console.log(item)
                //         item.addEventListener("contextmenu", (event) => {
                //             console.log('ahhhhhhhhhhhh');
                //             // 阻止原生的菜单栏显示
                //             event.preventDefault();
                //             // 获取target
                //             // let target = event.target;

                //             this.openMenu(event);
                //         });
                //     });
                // }
                outerDiv.addEventListener("contextmenu", (event) => {
                    console.log(event);
                    // 阻止原生的菜单栏显示
                    event.preventDefault();
                    // 获取target
                    // let target = event.target;
                    // this.openMenu(event);
                    this.$emit('replace',event)
                });
            }

            // let editorBox = document.querySelector(`#${this.editorId}`);
        },
        openMenu(e) {
            // this.$nextTick(() => {
            //     let collect = this.findCollect(tag);
            //     if (collect) {
            //         this.isCollect = true;
            //     } else {
            //         this.isCollect = false;
            //     }
            // });
            // e.preventDefault(); //防止默认菜单弹出
            // const inputElement = document.querySelector(".CodeMirror-code"); // 替换为你的输入元素
            // const inputRect = inputElement.getBoundingClientRect();
            // const cursorX =
            //     inputRect.left + window.scrollX + inputElement.selectionStart;
            // const cursorY = inputRect.top + window.scrollY;
            // console.log("光标位置 X:", cursorX, "Y:", cursorY,inputRect);
            // console.log(
            //     document
            //         .querySelector(".CodeMirror-code")
            //         .getBoundingClientRect()
            // );
            // let obj = e.srcElement ? e.srcElement : e.target;
            // const menuMinWidth = 105;
            // const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
            // const offsetWidth = this.$el.offsetWidth; // container width
            // const maxLeft = offsetWidth - menuMinWidth; // left boundary
            // const left = offsetLeft; // 15; // 15: margin right
            // if (left > maxLeft) {
            //     this.left = maxLeft;
            // } else {
            //     this.left = left;
            // }
            this.left = e.offsetX / 2 - 50;
            this.top = e.offsetY + 100;
            this.selectText = this.getSelection()
            this.visible = true;
            // this.selectedTag = tag;
        },
        closeMenu() {
            this.visible = false;
            this.isCollect = false;
           
            this.$emit('closeMenu')
        },
        fetchScript(url) {
            return new Promise((resolve) => {
                scriptjs(url, () => {
                    resolve();
                });
            });
        },
        getConfig() {
            let that = this;
            let obj = {
                toolbarHandlers: {
                    testIcon: function () {
                        that.$emit("copy", {text:that.editor.getMarkdown(),type:'1'});
                    },
                    testIcon2: function () {
                        that.$emit("copy", {text:that.editor.getMarkdown(),type:'2'});
                    },
                },
                height:this.height.replace('px','')
            };
          
            return { ...defaultConfig, ...this.config, ...obj };
        },
        getEditor() {
            return this.editor;
        },
        getDoc() {
            return this.doc;
        },
        watch() {
            return this.editor.watch();
        },
        unwatch() {
            return this.editor.unwatch();
        },
        previewing() {
            return this.editor.previewing();
        },
        getHTML() {
            return this.editor.getHTML();
        },
        getMarkdown() {
            return this.editor.getMarkdown();
        },
        setMarkdown(markdown) {
            return this.editor.setMarkdown(markdown);
        },
        getSelection() {
            return this.editor.getSelection();
        },
        insertValue(text){
            this.editor.insertValue(text);
        },
        getCursor() {
            return this.editor.getCursor();
        },
        replaceSelection(text) {
            this.editor.replaceSelection(text);
        },
        onchange(e){
           this.$emit('change',e)
        },
        initEditor() {
            (async () => {
                await this.fetchScript(
                    "/static/editor.md/jquery_1.11.3_jquery.min.js"
                );
                await this.fetchScript("/static/editor.md/editormd.min.js");
                this.$nextTick(() => {
                    const editor = window.editormd(
                        this.editorId,
                        this.getConfig()
                    );

                    editor.on("load", () => {
                        setTimeout(() => {
                            this.editorLoaded = true;
                            this.initData && editor.setMarkdown(this.initData);
                            this.itemBindEvent();
                        }, this.initDataDelay);
                    });
                    this.onchange &&
                        editor.on("change", () => {
                            const html = editor.getPreviewedHTML();
                            this.onchange({
                                markdown: editor.getMarkdown(),
                                html: html,
                                text: window.$(html).text(),
                            });
                        });
                    this.editor = editor;
                });
            })();
        },
    },
    mounted() {
     
        this.initEditor();
    },
};
</script>
  
  <style scoped lang="scss">
.markdown-editor-box {
    z-index: 2;
    position: relative;
}
.contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    width: 500px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
    padding: 15px;
    .operate-menu {
        position: relative;
        // &::after {
        //     position: absolute;
        //     content: "";
        //     width: 0;
        //     height: 0;
        //     border-left: 20px solid transparent; /* 左边透明 */
        //     border-right: 20px solid transparent; /* 右边透明 */
        //     border-bottom: 20px solid #f00;
        // }
        width: 100px;
        margin-top: 5px;
        box-shadow: 1px 0px 2px 1px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        padding: 10px;
        li {
            width: 100%;
            margin: 0;
            padding: 7px 16px;
            cursor: pointer;
            list-style: none;
            &:hover {
                background: #eee;
            }
        }
    }

    .chat-box-content {
        background: #f6f8f9;
        padding: 10px;
        line-height: 1.5;
        margin-right: 75px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .chat-loading {
            width: 5px;
            height: 20px;
            background: #000 !important;
            animation: chatBlink 1s infinite;
        }
        @keyframes chatBlink {
            0% {
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }
}
</style>
