<template>
  <el-form ref="genInfoForm" :model="info" :rules="rules" label-width="150px">

    <el-row>
        <!--<el-col :span="6">-->
            <!--<el-form-item prop="tpl_category">-->
                <!--<span slot="label">选择归属服务</span>-->
                <!--<el-select v-model="info.tpl_category" @change="service_change">-->
                    <!--<el-option v-for="dict in formtype" v-bind:key="dict.key" :label="dict.value" :value="dict.key" />-->

                <!--</el-select>-->
            <!--</el-form-item>-->
        <!--</el-col>-->

        <el-col :span="6">
            <el-form-item prop="tpl_category">
                <span slot="label">模板选择</span>
                <el-select v-model="info.tpl_category" @change="category_change">
                    <el-option v-for="dict in formtype" v-bind:key="dict.key" :label="dict.value" :value="dict.key" />

                </el-select>
            </el-form-item>
        </el-col>


        <el-col :span="6">
            <el-form-item prop="business_name">
          <span slot="label">
            模块名
            <el-tooltip content="可理解为子系统名，例如 system 注意:生成之后不允许修改" placement="top">
                <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
                <el-input :disabled="info.is_create==2" v-model="info.business_name" />
            </el-form-item>
        </el-col>

    </el-row>
    <el-row>

<!-- 
      <el-col :span="6">
        <el-form-item prop="business_name">
          <span slot="label">
            业务名
            <el-tooltip content="可理解为功能英文名，例如 user  注意:生成之后不允许修改" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-input :disabled="info.is_create==2" v-model="info.business_name" />
        </el-form-item>
      </el-col> -->

      <el-col :span="6">
        <el-form-item prop="function_name">
          <span slot="label">
            功能名
            <el-tooltip content="用作类描述，例如 用户" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-input v-model="info.function_name" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
      <el-form-item prop="service_name">
      <span slot="label">选择归属服务</span>
      <el-select v-model="info.service_name" size="medium" @change="service_change">
      <el-option v-for="svr in servicelist" v-bind:key="svr.id" :label="svr.name +'-'+ svr.remark" :value="svr.name" />

      </el-select>
      </el-form-item>
      </el-col>






    </el-row>

    <el-row v-show="info.tpl_category == '4' || info.tpl_category == '5'">
      <h4 class="form-header">其他信息</h4>
      <el-col :span="12">
        <el-form-item>
          <span slot="label">
            树编码字段
            <el-tooltip content="树显示的编码字段名， 如：id" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-select v-if="info.options" v-model="info.options.tree_code" placeholder="请选择">
            <el-option
              v-for="column in info.columns"
              :key="column.column_name"
              :label="column.column_name + '：' + column.column_comment"
              :value="column.column_name"
            ></el-option>
          </el-select>
        </el-form-item> 
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <span slot="label">
            树父级编码字段
            <el-tooltip content="树显示的父编码字段名， 如：parent_Id,pid" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-select v-if="info.options"  v-model="info.options.tree_parent_code" placeholder="请选择">
            <el-option
              v-for="column in info.columns"
              :key="column.column_name"
              :label="column.column_name + '：' + column.column_comment"
              :value="column.column_name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <span slot="label">
            树名称字段
            <el-tooltip content="树节点的显示名称字段名， 如：dept_name,name" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-select v-if="info.options"  v-model="info.options.tree_name" placeholder="请选择">
            <el-option
              v-for="column in info.columns"
              :key="column.column_name"
              :label="column.column_name + '：' + column.column_comment"
              :value="column.column_name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-show="info.tpl_category == '3' || info.tpl_category == '6'">
      <h4 class="form-header">其他信息</h4>
      <el-col :span="6">
        <el-form-item>
          <span slot="label">
            主表
            <el-tooltip content="选择类型为主表的表单" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-select v-if="info.options" @change="selmainform" v-model="info.options.maintable" placeholder="请选择">
            <el-option
                    v-for="tbl in mainformlist"
                    :key="tbl.id"
                    :label="tbl.table_comment"
                    :value="tbl.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <span slot="label">
            关联主表字段
            <el-tooltip content="一般为主表主键 如：id" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-select v-if="info.options"  v-model="info.options.maintable_field" placeholder="请选择">
            <el-option
                    v-for="column in maintablecolumns"
                    :key="column.column_name"
                    :label="column.column_name + '：' + column.column_comment"
                    :value="column.column_name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <span slot="label">
            当前表字段
            <el-tooltip content="当前表跟主表关联的字段" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-select v-if="info.options"  v-model="info.options.tree_name" placeholder="请选择">
            <el-option
                    v-for="column in info.columns"
                    :key="column.column_name"
                    :label="column.column_name + '：' + column.column_comment"
                    :value="column.column_name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row >
      <h4 class="form-header" style="color: red;">*注意:模块名和业务名生成代码后不允许修改</h4>
      </el-row>
  </el-form>
</template>
<script>
import { formList,formColumns } from "@/api/form/gen";
import { listDevservice } from "@/api/form/gen/devservice";
export default {
  name: "genInfoForm",
  props: {
    info: {
      type: Object,
      default: null
    },
    formtype: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      mainformlist:[],
      servicelist:[],
      maintablecolumns:[],
      rules: {
        tpl_category: [
          { required: true, message: "请选择生成模板", trigger: "blur" }
        ],
        // module_name: [
        //   { required: true, message: "请输入生成模块名", trigger: "blur" }
        // ],
        business_name: [
          { required: true, message: "请输入模块名", trigger: "blur" }
        ],
        function_name: [
          { required: true, message: "请输入生成功能名", trigger: "blur" }
        ],
        service_name: [
          { required: true, message: "请选择归属服务", trigger: "blur" }
        ],
      }
    };
  },
    methods:{
        service_change(){},
        category_change(value){
            if(value=="3" || value=="6"){
                formList("2").then(response => {
                 this.mainformlist = response.data.list;
            }
            )

            }
        },
        selmainform(value){
//            console.log("value",value)
            formColumns(value).then(response => {
            this.maintablecolumns = response.data.list;
            console.log("response.data",response.data)
        } )

        },
    },
    created() {
      listDevservice({pageNum: 1,pageSize: 999}).then(response => {
        this.servicelist = response.data.list;
    })

  },

};
</script>
<style scoped>
.el-select {
    width: 100%;
}
</style>