import request from '@/utils/request'

// 查询会员规则列表
export function listUserRule(userpool, query) {
  return request({
    url: '/ucenter/' + userpool + '/userrule/list',
    method: 'get',
    params: query
  })
}

// 查询会员规则详细
export function getUserRule(userpool, id) {
  return request({
    url: '/ucenter/' + userpool + '/userrule/get?id=' + id,
    method: 'get'
  })
}

// 新增会员规则
export function addUserRule(userpool, data) {
  return request({
    url: '/ucenter/' + userpool + '/userrule/add',
    method: 'post',
    data: data
  })
}

// 修改会员规则
export function updateUserRule(userpool, data) {
  return request({
    url: '/ucenter/' + userpool + '/userrule/edit',
    method: 'post',
    data: data
  })
}

// 删除会员规则
export function delUserRule(userpool, ids) {
  return request({
    url: '/ucenter/' + userpool + '/userrule/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}
