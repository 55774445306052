import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2024-03-21 14:30:59
class Bookedit {
// 查询bookedit列表
 listBookedit(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/digital/bookedit/list',
        method: 'post',
        data: data
    })
}

// 查看bookedit
 getBookedit ( id) {
    return request({
        url: '/digital/bookedit/get?id='+id,
        method: 'get'
    })
}


// 新增bookedit
 addBookedit(mid,data) {
  var postdata={
          form_org_data:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/digital/bookedit/add',
        method: 'post',
        data: postdata
    })
}

// 修改bookedit
 editBookedit(mid,data) {
  var postdata={
          form_org_data:JSON.stringify(data),
          id:data.id
      }

    return request({
        url: '/digital/bookedit/edit',
        method: 'post',
        data: postdata
    })
}

// 删除bookedit
 delBookedit(ids) {
    return request({
        url: '/digital/bookedit/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 提取书签
extPdftext(mid, data) {
    var postdata = {
        formdata: JSON.stringify(data),
        fmodel_id: mid,
        id: data.id
    }

    return request({
        url: `/digital/bookedit/extracttextfrompdf`,
        method: 'post',
        data: postdata
    })
}

// 编辑书签
writeBookmarkByFile(mid, data) {
    var postdata = {
        formdata: JSON.stringify(data),
        fmodel_id: mid,
        id: data.id
    }
    return request({
        url: `/digital/bookedit/edittextfrompdf`,
        method: 'post',
        data: postdata
    })
}


//导入数据
 bookeditImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/digital/bookedit/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 bookeditExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/digital/bookedit/export?` + parata.join("&") ;


 }

 //下导入模板
  bookeditimportTemplate() {

     return   `/digital/bookedit/importTemplate`   ;

  }

}

const instance = new Bookedit();
export default instance;