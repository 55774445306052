import request from '@/utils/request'
// 查询app列表
export function listApp(query) {
  return request({
    url: '/data/api/app/list',
    method: 'get',
    params: query
  })
}

// 查询app详细
export function getApp (id) {
    return request({
        url: '/data/api/app/get?id='+id,
        method: 'get'
    })
}


// 新增app
export function addApp(data) {
    return request({
        url: '/data/api/app/add',
        method: 'post',
        data: data
    })
}

// 修改app
export function editApp(data) {
    return request({
        url: '/data/api/app/edit',
        method: 'post',
        data: data
    })
}

// 删除app
export function delApp(ids) {
    return request({
        url: '/data/api/app/del',
        method: 'post',
        data:{ids:ids}
    })
}

