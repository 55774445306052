<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
			<el-form-item label="关键字" prop="keyword">
				<el-input v-model="queryParams.keyword" placeholder="请输入关键字" clearable style="width: 240px;" size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="时间">
				<el-date-picker v-model="dateRange" size="small" style="width: 240px" value-format="yyyy-MM-dd"
					type="daterange" range-separator="-" start-placeholder="开始日期"
					end-placeholder="结束日期"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>

		<jf-table :loading="loading" :columns="tableColumn" :data="list" :toolbar-config="tableToolbar"
			:query-params="queryParams" :total="total" @checkbox-change="checkboxChangeEvent" @change="tableChange"
			@page-change="handleSizeChange">
			<template slot="toolbar_btn_left">
				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
					@click="handleDelete">删除</el-button>
				<el-button type="danger" icon="el-icon-delete" size="mini" @click="handleClean">清空</el-button>
			</template>

			<template slot="toolbar_btn_row" slot-scope="{ row }">
				<el-button size="mini" type="text" icon="el-icon-view" @click="handleView(row)">详细</el-button>
			</template>


			<template slot="status" slot-scope="{ row }">
				{{statusFormat(row)}}
			</template>


		</jf-table>
		<!-- 	<vxe-grid resizable ref="xGrid" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="list" @checkbox-change="checkboxChangeEvent"
			@checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="danger" icon="el-icon-delete" size="mini"
					:disabled="multiple" @click="handleDelete">删除</el-button>
				<el-button type="danger" icon="el-icon-delete" size="mini" @click="handleClean">清空</el-button>
			</template> -->

		<!--自定义空数据模板-->
		<!-- 			<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>
			<template #detail="{row}">
				<el-button size="mini" type="text" icon="el-icon-view" @click="handleView(row)">详细</el-button>
			</template>
		</vxe-grid>
		<vxe-pager background size="small" :loading="loading" :current-page="queryParams.pageNum"
			:page-size="queryParams.pageSize" :total="total" @page-change="handleSizeChange">
		</vxe-pager> -->

		<!-- 安全日志详细 -->
		<el-dialog title="安全日志详细" :visible.sync="open" width="700px" append-to-body>
			<el-form ref="form" :model="form" label-width="100px" size="mini">
				<el-row>
					<el-col v-if="form.title" :span="24">
						<el-form-item label="类型：">{{ form.type }} </el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="内容：">{{ form.content }}</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="时间：">{{ parseTime(form.create_time) }}</el-form-item>
					</el-col>

				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="open = false">关 闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list,
		delSecurityLog,
		cleanSecurityLog,
		exportSecurityLog
	} from "@/api/core/monitor/securitylog";

	export default {
		name: "core-monitor-securitylog",
		data() {
			return {
				// 工具栏
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery
					},
					slots: {
						buttons: 'toolbar_buttons'
					}
				},
				// 列
				tableColumn: [
					// {
					// 	type: 'checkbox',
					// 	width: 60,
					// 	fixed: "left"
					// },
					{
						field: 'id',
						title: '编号',
						fixed: "left",
						visible: false
					},
					/*{
						field: 'type',
						title: '类型'
					},*/
					{
						field: 'content',
						title: '内容',
					},
					{
						field: 'create_time',
						title: '时间',
						width: 180,
						formatter: "formatDate",
						form_type: 3
					},
					// {
					// 	field: '',
					// 	title: '操作',
					// 	resizable: false,
					// 	width: 180,
					// 	fixed: "right",
					// 	align: "center",
					// 	slots: {
					// 		default: 'detail'
					// 	}
					// }

				],

				// 遮罩层
				loading: true,
				// 选中数组
				ids: [],
				// 非多个禁用
				multiple: true,
				// 总条数
				total: 0,
				// 表格数据
				list: [],
				// 是否显示弹出层
				open: false,
				// 类型数据字典
				statusOptions: [],
				// 日期范围
				dateRange: [],
				// 表单参数
				form: {},
				// 查询参数
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					type: undefined,
					keyword: undefined,
				}
			};
		},
		created() {
			this.getList();
			// this.getDicts("sys_oper_type").then(response => {
			//   this.typeOptions = response.data;
			// });
			//this.getDicts("sysCommonStatus").then(response => {
			//  this.statusOptions = response.data.values || [];
			//});
		},
		methods: {
			// 列表筛选
			tableChange(e) {
				console.log(e)
			},
			handleSizeChange({
				currentPage,
				pageSize
			}) {
				this.queryParams.pageNum = currentPage;
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}

			},
			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map(item => item.id)
				this.single = records.length != 1;
				this.multiple = !records.length

			},
			/** 查询登录日志 */
			getList() {
				this.loading = true;
				list(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
					this.single = true;
					this.multiple = true;
					this.list = response.data.list;
					this.total = response.data.total;
					//this.statusOptions = response.data.searchStatus.values
					this.loading = false;
				});
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.dateRange = [];
				this.resetForm("queryForm");
				this.handleQuery();
			},
			// 多选框选中数据
			handleSelectionChange(selection) {
				this.ids = selection.map(item => item.id)
				this.multiple = !selection.length
			},
			/** 详细按钮操作 */
			handleView(row) {
				this.open = true;
				this.form = row;
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				const operIds = row.id || (this.ids ? this.ids.join(',') : '');
				this.$confirm('确定删除吗?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return delSecurityLog(operIds);
				}).then(() => {
					this.getList();
					this.msgSuccess("删除成功");
				}).catch(function() {});
			},
			/** 清空按钮操作 */
			handleClean() {
				this.$confirm('确定清空所有安全日志数据项吗?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return cleanSecurityLog();
				}).then(() => {
					this.getList();
					this.msgSuccess("清空成功");
				}).catch(function() {});
			},
			/** 导出按钮操作 */
			handleExport() {
				const queryParams = this.queryParams;
				this.$confirm('是否确认导出所有安全日志数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return exportSecurityLog(queryParams);
				}).then(response => {
					this.download(response.msg);
				}).catch(function() {});
			}
		}
	};
</script>