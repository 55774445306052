<template>
    <el-dialog
        :title="title"
        :visible.sync="open"
        fullscreen
        :width="'90%'"
        :close-on-click-modal="false"
        append-to-body
        :before-close="cancel"
        class="vFormDialog"
    >
        <div class="btn-submit">
            <div class="flex-1 ml-5">{{ title }}</div>
            <div>
                <el-button
                    v-if="!isview && !isListBtn && title !== '修改题库'"
                    :disabled="isview"
                    @click="importDialog = true"
                    type="warning"
                    >导 入</el-button
                >

                <el-button
                    v-if="!isview"
                    :disabled="isview"
                    type="primary"
                    @click="submitForm"
                    >确 定</el-button
                >
                <el-button
                    v-if="isListBtn"
                    type="primary"
                    @click="openQuestionBank"
                    >题 库</el-button
                >
                <el-button @click="cancel">返 回</el-button>
            </div>
        </div>
        <VFormDesigner
            ref="VFormDesigner"
            :is-topic="true"
            :modleid="mid"
            :isAddTopic="!isListBtn"
            :isEdit="title === '修改题库'"
        ></VFormDesigner>

        <question-bank-list
            ref="questionBankList"
            @submit="questionChange"
        ></question-bank-list>
        <el-dialog
            title="Excel模板导入题库"
            :visible.sync="importDialog"
            :width="'40%'"
            :close-on-click-modal="false"
            append-to-body
            :before-close="closeImportDialog"
            v-loading="loading"
        >
            <el-upload
                class="import-upload"
                :auto-upload="true"
                :multiple="false"
                drag
                :before-upload="upload"
                action="#"
                :accept="'.xls , .xlsx,.csv'"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>，上传文件题目数量不得超过1000条
                </div>
            </el-upload>
            <div
                v-if="fileName && !loading"
                style="font-size: 12px; align-items: center; display: flex"
            >
                <i class="el-icon-paperclip"></i>
                <div v-text="fileName"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeImportDialog">取 消</el-button>
                <el-button @click="downloadTemplate">下载导入模板</el-button>
                <el-button type="primary" @click="importSubmit">导入</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>
<script>
import VFormDesigner from "@/vform/components/form-designer/index.vue";
import { read, utils } from "xlsx";
import { set } from "vue";
import QuestionBankList from "./questionBankList";
import { form } from "../../../mixins/form";
import { basicFields } from "@/vform/components/form-designer/widget-panel/widgetsConfig-answer";
import i18n from "@/vform/utils/i18n";
import Papa from "papaparse";
import jschardet from "jschardet";
export default {
    components: {
        VFormDesigner,
        QuestionBankList,
    },
    props: {
        isListBtn: {
            type: Boolean,
            default: false,
        },
        isview: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [i18n],
    data() {
        return {
            basicFields,
            title: "",
            open: false,
            // isview: false,
            mid: "",
            formData: {},
            worksheet: [],
            importColumns: [],
            importDialog: false,
            divLoading: false,
            widgetList: [],
            fileName: "",
            loading: false,
            csvData: [],
        };
    },
    methods: {
        //下载模板
        downloadTemplate() {
            const link = document.createElement("a");
            link.href =
                "http://shengchan.connectbilian.com/static/%E9%A2%98%E5%BA%93%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx";
            link.download = "题库导入模板.xlsx";
            link.click();
        },
        //上传
        upload(file, fileList) {
            let files = [file];
            this.loading = true;
            let imgSize = Number(file.size / 1024 / 1024);
            if (files.length <= 0) {
                this.$msgbox({
                    title: "",
                    message: "上传失败请重新上传",
                    type: "warning",
                });
                return false;
            }
            if (imgSize > 10) {
                this.$msgbox({
                    title: "",
                    message: "文件大小不能超过10MB，请重新上传。",
                    type: "warning",
                });
                return false;
            }
            if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
                this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
                return false;
            }
            console.log(file);
            if (file.name.indexOf(".csv") > -1) {
                this.handleFileUpload(files[0]);
            } else {
                this.parseExcel(files[0]);
            }

            this.fileName = files[0].name;
            return false; //false表示不需要上传~
        },
        checkEncoding(base64Str) {
            // 这种方式得到的是一种二进制串
            var str = atob(base64Str.split(";base64,")[1]);
            // console.log(str);
            // 要用二进制格式
            var encoding = jschardet.detect(str);
            encoding = encoding.encoding;
            // console.log( encoding );
            if (encoding === "windows-1252") {
                // 有时会识别错误（如UTF8的中文二字）
                encoding = "ANSI";
            }
            return encoding;
        },
        // 处理csv文件
        handleFileUpload(event) {
            let that = this;
            const file = event;
            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onload = function (evt) {
                const data = evt.target.result;
                // console.log( data );
                const encoding = that.checkEncoding(data);

                // 转换成二维数组，需要引入Papaparse.js
                Papa.parse(file, {
                    encoding: encoding,
                    complete: function (results) {
                        // UTF8 \r\n与\n混用时有可能会出问题
                        const res = results.data;
                        // console.log(res, "res");
                        if (res[res.length - 1] === "") {
                            // 去除最后的空行
                            res.pop();
                        }
                        that.getCsvWidgetList(res);
                    },
                });
            };
        },
        // csv数据处理
        getCsvWidgetList(res) {
            let that = this;
            let arr = [];
            let obj = {};
            const upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            for (let i = 0; i < res.length; i++) {
                let val = res[i];
                // 5条数据为一组
                if (i % 5 === 0) {
                    obj["label"] = val[1];
                }
                if (i % 5 === 1) {
                    let index = 0;
                    // if (val[1] !== "判断题") {
                    index = that.basicFields.findIndex(
                        (o) =>
                            that.i18n2t(
                                `designer.widgetLabel.${o.type}`,
                                `extension.widgetLabel.${o.type}`
                            ) === val[1]
                    );
                    // } else {
                    //     index = that.basicFields.findIndex(
                    //         (o) => o.type === "radio"
                    //     );
                    // }
                    obj["type"] = that.basicFields[index].type;
                }
                if (i % 5 === 2) {
                    if (
                        obj.type === "radio" ||
                        obj.type === "checkbox" ||
                        obj.type === "radio-judge" ||
                        (obj.type === "select" && val.length > 0)
                    ) {
                        obj.optionItems = val
                            .slice(1, val.length)
                            .map((o, j) => {
                                return {
                                    label: upperCaseLetters[j] + "." + o,
                                    value: upperCaseLetters[j],
                                };
                            });
                    }
                }

                if (i % 5 === 3) {
                    if (obj.type === "checkbox") {
                        let list = obj.optionItems.map((o, j) => {
                            let list = val.slice(1, val.length);
                            if (list[j] === "正确答案") {
                                return o.value;
                            }
                        });
                        obj["answer"] = list.filter((o) => o);
                    } else {
                        let j = val.findIndex((o) => o === "正确答案");

                        obj["answer"] =
                            j > -1
                                ? obj.optionItems[j - 1] &&
                                  obj.optionItems[j - 1].value
                                : "";
                    }
                }
                if (i % 5 === 4) {
                    obj["answerKey"] = val[1];
                    let filed = JSON.parse(
                        JSON.stringify(
                            that.basicFields[
                                that.basicFields.findIndex(
                                    (o) => o.type === obj.type
                                )
                            ]
                        )
                    );
                    filed.options.answer = obj.answer;
                    filed.options.label = obj.label;
                    filed.options.optionItems = obj.optionItems;
                    filed.options.answerKey = obj.answerKey;
                    filed.options.scoring["answer"] = 2;
                    let id =
                        obj.type + Math.floor(Math.random() * 900000 + 9000);
                    filed.options.name = id;
                    filed.id = id;
                    arr.push(filed);
                    obj = {};
                }
            }
            that.widgetList = arr;
            console.log(that.widgetList, "that.widgetList");
            that.loading = false;
        },
        // 解析excel
        parseExcel(file) {
            var that = this;
            this.fileName = file.name;
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = e.target.result;
                    const workbook = read(data, { type: "binary" });
                    this.worksheet = [];
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    console.log("解析中222---");
                    // 生成json表格内容
                    workbook.SheetNames.forEach((wsname, index) => {
                        let ws = utils.sheet_to_json(workbook.Sheets[wsname]);
                        let list = [];
                        const jsonData = utils.sheet_to_json(
                            workbook.Sheets[wsname],
                            {
                                header: 1,
                            }
                        );
                        // 获取表头，通常是第一行
                        const headerRow = jsonData[0] || [];

                        let obj = {
                            wsname: wsname,
                            ws: ws,
                            // columns: array,
                        };
                        this.worksheet.push(obj);
                        for (let j = 0; j < this.basicFields.length; j++) {
                            let widget = this.basicFields[j];
                            if (!!widget.alias) {
                                //优先显示组件别名
                                console.log(
                                    this.i18n2t(
                                        `designer.widgetLabel.${widget.alias}`,
                                        `extension.widgetLabel.${widget.alias}`
                                    ),
                                    "i18n2t"
                                );
                            }
                            if (
                                this.i18n2t(
                                    `designer.widgetLabel.${widget.type}`,
                                    `extension.widgetLabel.${widget.type}`
                                ) === wsname ||
                                (widget.type === "radio" && wsname === "判断题")
                            ) {
                                this.getImportList(ws, widget, index);
                            }
                        }
                    });
                } catch (e) {
                    this.divLoading = false;
                    return false;
                }

                // 将表头和数据存储在组件的数据属性中
                // this.excelHeader = headerRow;
                // this.excelData = dataRows;
            };
            reader.readAsBinaryString(file);
        },
        getImportList(list, widget, index) {
            let widgetList = [];
            if (index == 0) {
                this.widgetList = [];
            }
            for (let i = 0; i < list.length; i++) {
                let val = JSON.parse(JSON.stringify(widget));
                let id = val.type + Math.floor(Math.random() * 900000 + 9000);
                let item = list[i];
                let arr = [];
                for (let o in item) {
                    if (o.indexOf("选项") > -1) {
                        let obj = {
                            label:
                                o.split("选项")[1].replace(/\s/g, "") +
                                "." +
                                item[o],
                            value: o.split("选项")[1].replace(/\s/g, ""),
                        };
                        arr.push(obj);
                    }
                    if (o === "题干") {
                        val.options.label = item[o];
                    }
                    if (o === "解析") {
                        val.options.answerKey = item[o];
                    }
                    if (o === "分数") {
                        val.options.scoring["answer"] = item[o];
                    }
                    if (o === "答案") {
                        if (val.type === "checkbox") {
                            val.options.answer = item[o].split("");
                        } else {
                            val.options.answer = item[o];
                        }
                    }
                }
                if (
                    (val.type === "radio" ||
                        val.type === "checkbox" ||
                        val.type === "select") &&
                    arr.length > 0
                ) {
                    val.options.optionItems = arr;
                }
                val.id = id;
                val.options.name = id;
                widgetList.push(val);

                // val.options.optionItems=[{label:}]
            }
            this.widgetList = this.widgetList.concat(widgetList);

            if (index === this.worksheet.length - 1) {
                this.loading = false;
            }
        },
        importSubmit() {
            if (!this.widgetList.length) {
                this.$message({
                    message: "当前文件未获取到数据，请重新上传文件",
                    type: "warning",
                });
                return;
            }
            if (this.widgetList.length > 1000) {
                this.$message({
                    message: "一次导入不允许超过1000条，请重新上传文件",
                    type: "warning",
                });
                return;
            }
            this.divLoading = true;
            let formData = {
                widgetList: this.widgetList,
            };
            this.$nextTick(() => {
                var designform = this.$refs["VFormDesigner"];
                console.log(formData);
                if (formData) {
                    this.formData = formData;
                    designform.setFormJson(formData);
                }
                this.closeImportDialog();
            });
        },
        closeImportDialog() {
            this.importDialog = false;
            this.fileName = "";
            this.widgetList = [];
        },
        openQuestionBank() {
            let data = {
                mid: this.mid,
            };
            this.$refs["questionBankList"].openQuestionBank(data);
        },
        getInit(data) {
            this.title = data.title;
            this.open = true;
            this.mid = data.mid;
            this.$nextTick(() => {
                var designform = this.$refs["VFormDesigner"];
                if (data.formData) {
                    this.formData = data.formData;
                    designform.setFormJson(data.formData);
                }
            });
        },
        cancel() {
            this.open = false;
            // localStorage.setItem('v_form_locale','zh-CN-answer')
            this.$refs["VFormDesigner"].$refs["toolbarRef"].clearFormWidget();
        },
        submitForm() {
            var designform = this.$refs["VFormDesigner"];

            var formData = designform.getFormJson();

            formData["FieldWidgets"] = designform.getFieldWidgets();
            console.log(formData, "formData");
            this.$emit("submit", formData);
        },
        questionChange(e) {
            var designform = this.$refs["VFormDesigner"];
            let list = [];
            e.forEach((o) => {
                let obj = JSON.parse(o.options).field;
                list.push(obj);
            });

            let formData = designform.getFormJson();
            formData.widgetList = formData.widgetList.concat(list);
            formData.FieldWidgets = formData.widgetList;
            designform.setFormJson(formData);
        },
    },
};
</script>
<style lang="scss" scoped>
.vFormDialog ::v-deep .el-dialog__header {
    display: none;
}
.vFormDialog .btn-submit {
    font-size: 18px;
    border-bottom: #9e9e9e38 solid 1px;
    padding-bottom: 10px;
    margin-top: -7px;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>