import request from '@/utils/request'

export function listBiDatascreen(query) {
  return request({
    url: '/bi/bidatascreen/list',
    method: 'get',
    params: query
  })
}

export function getBiDatascreen(id) {
  return request({
    url: '/bi/bidatascreen/get?id=' + id,
    method: 'get'
  })
}

export function addBiDatascreen(data) {
  return request({
    url: '/bi/bidatascreen/add',
    method: 'post',
    data: data
  })
}

export function editBiDatascreen(data) {
  return request({
    url: '/bi/bidatascreen/edit',
    method: 'post',
    data: data
  })
}

export function delBiDatascreen(ids) {
  return request({
    url: '/bi/bidatascreen/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function copyBiDatascreen(id) {
  return request({
    url: '/bi/bidatascreen/copy',
    method: 'post',
    data: {
      id: id
    }
  })
}
