<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="节点" prop="nodeinfo_id">
        <el-select v-model="queryParams.node_id" placeholder="节点" clearable size="small" @change="nodechange">
          <el-option
            v-for="dict in nodeList"
            :key="dict.id"
            :label="dict.version+'-'+dict.node_name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>      
    </el-form>    
    <el-row style="padding: 10px;">
      <el-col :span="16" class="card-box">        
        <el-card style="height: 200px;margin-top: 10px;position: relative;">          
          <div slot="header">
            <div v-if="choosecase==1" style="position: absolute;top: 0px;right:10px;background-color: rgb(94,124,224);color:white;height:50px;width:100px; border-bottom-left-radius:10px;border-bottom-right-radius:10px;text-align: center;">
              <span style="display:inline-block;margin-top: 15px">当前实例</span>
            </div>
            <div style="display:inline-block;float:left;margin-top: 5px" >
              基础版实例
            </div> 
            <div style=" display:inline-block ; float:right">                
              <el-button
                v-if="btnBaseUse == 0"
                style="margin-left: 10px"                                  
                size="mini"    
                :disabled="(btnBaseBoolean == true)== 1 ? true : false"
                @click="handleBaseEnable()"
              >免费激活</el-button>              
              <el-button
                v-if="btnBaseUse == 1 && choosecase != 1"
                style="margin-left: 10px"
                type="success"                 
                size="mini"      
                @click="chooseTheCase(0)"
              >使用实例</el-button>
            </div>
            <div  style = "clear:both;" ></div >
          </div>
          <div style=" display:inline-block;width: 100%;height:100px">
            <span style="font-size:13px;color:#859094;" >针对小型企业客户场景，设备并发性能要求较低</span>
          </div>
          <div v-if="btnBaseUse != 0 && baseid >0" style="border-top:1px dashed skyblue;display:inline-block ; float:bottom;height:20px;width: 100%;">
            <div style="display:inline-block;float:left;margin-top: 10px;font-size:12px;" >
              按需计量
            </div>
            <router-link size="mini" :to="'/iot/case/info/'+baseid" class="link-type" style=" display:inline-block ; float:right;margin-top: 5px" >
              <span style="font-size:12px" >接入 ></span>
            </router-link>
          </div>
        </el-card>
        <el-card style="height: 200px;margin-top: 10px;position: relative;">          
          <div slot="header" >
            <div v-if="choosecase == 2" style="position: absolute;top: 0px;right:10px;background-color: rgb(94,124,224);color:white;height:50px;width:100px; border-bottom-left-radius:10px;border-bottom-right-radius:10px;text-align: center;">
              <span style="display:inline-block;margin-top: 15px">当前实例</span>
            </div>
            <div style="display:inline-block;float:left;margin-top: 5px" >
              标准版实例
            </div> 
            <el-button  
                v-if="btnStandardUse == 1"              
                style="margin-left: 10px"                                  
                size="mini"                    
                @click="handleEdit(2)"
              >修改</el-button>
            <div style=" display:inline-block ; float:right">
              <el-button
                v-if="btnStandardTry == 1"
                style="margin-left: 10px"                                  
                size="mini"    
                :disabled="(btnStandardBoolean == true)== 1 ? true : false"   
                @click="handleStandardEnable()"                          
               >免费试用</el-button>
              <el-button
                v-if="btnStandardUse == 0"
                style="margin-left: 10px"
                type="danger"                 
                size="mini"
                @click="handleBuyCase(2)"
              >申请实例</el-button>
              <el-button
                v-if="btnStandardUse == 1 && choosecase != 2"
                style="margin-left: 10px"
                type="success"                 
                size="mini"
                @click="chooseTheCase(1)"                    
              >使用实例</el-button>               
            </div>
            <div  style = "clear:both;" ></div >
          </div>
          <div style=" display:inline-block;width: 100%;height:100px">
            <span style="font-size:13px;color:#859094;" >提供灵活可配置的实例规格，适用于各类企业客户场景，可根据业务模型选购更经济的平台实例</span>
          </div>
          <div v-if="btnStandardUse != 0 &&standardid>0" style="border-top:1px dashed skyblue;display:inline-block ; float:bottom;height:20px;width: 100%;">
            <router-link size="mini" :to="'/iot/case/info/'+standardid" class="link-type" style=" display:inline-block ; float:right;margin-top: 5px" >
              <span style="font-size:12px" >接入 ></span>
            </router-link>
          </div>
        </el-card>
        <el-card style="height: 200px;margin-top: 10px;position: relative;">          
          <div slot="header" >
            <div v-if="choosecase==3" style="position: absolute;top: 0px;right:10px;background-color: rgb(94,124,224);color:white;height:50px;width:100px; border-bottom-left-radius:10px;border-bottom-right-radius:10px;text-align: center;">
              <span style="display:inline-block;margin-top: 15px">当前实例</span>
            </div>
            <div style="display:inline-block;float:left;margin-top: 5px" >
              企业版实例
            </div> 
            <el-button    
                v-if="btnBusinessUse == 1"            
                style="margin-left: 10px"                                  
                size="mini" 
                @click="handleEdit(3)"
              >修改</el-button>
            <div style=" display:inline-block ; float:right">                
                <el-button
                  v-if="btnBusinessUse == 0"
                  style="margin-left: 10px"
                  type="danger"                 
                  size="mini"
                  @click="handleBuyCase(3)"               
                >申请实例</el-button>
                <el-button
                  v-if="btnBusinessUse == 1 && choosecase != 3"
                  style="margin-left: 10px"
                  type="success"                 
                  size="mini"    
                  @click="chooseTheCase(2)"               
                >使用实例</el-button>
            </div>
            <div  style = "clear:both;" ></div >
          </div>
          <div style=" display:inline-block;width: 100%;height:100px">
            <span style="font-size:13px;color:#859094;" >中大型企业客户场景， 提供完全独享的实例，支持更高的并发能力， 具备更高的安全性和隔离性</span>
          </div>
          <div v-if="btnBusinessUse != 0 && businessid>0" style="border-top:1px dashed skyblue;display:inline-block ; float:bottom;height:20px;width: 100%;">
            <router-link size="mini" :to="'/iot/case/info/'+businessid" class="link-type" style=" display:inline-block ; float:right;margin-top: 5px" >
              <span style="font-size:12px" >接入 ></span>
            </router-link>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8" class="card-box">
        <el-card style="height: 800px;width: 300px;margin-top: 10px">
          <div slot="header">
            实例版本说明
          </div>
          <div class="el-table el-table--enable-row-hover el-table--medium">
            
          </div>
        </el-card>
      </el-col>
    </el-row>   
    
    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="900px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="实例名称" prop="case_name" :required=true>
          <el-input v-model="form.case_name" placeholder="必填项" />
        </el-form-item>
        <el-form-item label="实例描述" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="可选填" />
        </el-form-item>
        <el-divider/>
        <el-form-item label="计费模式" prop="bill_mode"  :required=true>
          <el-select v-model="form.bill_mode" placeholder="计费模式" clearable size="small">
            <el-option
              v-for="(item,key) in billModeOptions"
              :key="key"
              :label="item.value"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="申请时长" prop="dur_time"  :required=true>
          <el-select v-model="form.dur_time" placeholder="申请时长" clearable size="small">
            <el-option
              v-for="dict in buytimelist"
              :key="dict.key"
              :label="dict.value"
              :value="dict.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="delete_time">
          <el-checkbox
            style="font-size: 12px"
            label="按月自动续费周期为一个月，按年自动续费为一年。"
            v-model="form.delete_time"
          ></el-checkbox>
        </el-form-item>
        <el-divider/>      
        <el-form-item v-if="form.level==3" label="网络" prop="net_id" :required=true>
          <el-select v-model="form.net_id" placeholder="网络" clearable size="small">
            <el-option
              v-for="(item,key) in netOptions"
              :key="key"
              :label="item.value"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.level==3" label="安全组" prop="safe_id" :required=true>
          <el-select v-model="form.safe_id" placeholder="安全组" clearable size="small">
            <el-option
              v-for="(item,key) in safeOptions"
              :key="key"
              :label="item.value"
              :value="key"
            />
          </el-select>
        </el-form-item> 
        <el-divider v-if="form.level==3"/>     
        <el-row v-if="form.level==3">      
          <el-col :span="3">
            <div class="cell" style="margin-left: 30px;margin-top: 10px">应用接入</div>
          </el-col>      
          <el-col :span="4">           
            <el-form-item label="HTTPS" prop="address">
              <el-input v-model="apphttps.address" placeholder="443" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="AMQPS" prop="address">
              <el-input v-model="appamqps.address" placeholder="5671" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.level==3">
          <el-col :span="3">
            <div class="cell" style="margin-left: 30px;margin-top: 10px">设备接入</div>
          </el-col>
          <el-col :span="4">
            <el-form-item label="CoAP" prop="address">
              <el-input v-model="devicecoap.address" placeholder="5683" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="CoAPS" prop="address">
              <el-input v-model="devicecoaps.address" placeholder="5684" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="MQTT" prop="address">
              <el-input v-model="devicemqtt.address" placeholder="1883" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="MQTTS" prop="address">
              <el-input v-model="devicemqtts.address" placeholder="8883" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="HTTPS" prop="address">
              <el-input v-model="devicehttps.address" placeholder="8943" />
            </el-form-item>
          </el-col>          
        </el-row>
        <el-divider v-if="form.level==3" />  
        <el-row>
          <el-col :span="11">
            <el-form-item label="设备消息并发上限" prop="concurrent_max" clearable size="small" label-width="160px" :required=true>
              <el-select v-model="form.concurrent_max" placeholder="设备消息并发上限"  >
                <el-option
                  v-for="dict in connectmaxlist"
                  :key="dict.key"
                  :label="dict.value"
                  :value="dict.key"
                />
              </el-select>          
            </el-form-item>
          </el-col> 
          <el-col :span="13">
            <div class="cell" style="margin-top: 10px">设备消息并发上限和注册上限按需进行选择，需额外上限请提交工单。</div>
          </el-col>
        </el-row>
        <el-form-item label="注册设备数量上限" prop="device_max" clearable size="small"  label-width="160px" :required=true>
          <el-select v-model="form.device_max" placeholder="注册设备数量上限"  >
            <el-option
              v-for="dict in devicemaxlist"
              :key="dict.key"
              :label="dict.value"
              :value="dict.key"
            />
          </el-select>
        </el-form-item> 
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import { listIotCase, getIotCase, addIotCase, updateIotCase, delIotCase } from "@/api/iot/case";
import { addIotCaseProtocol,listIotCaseProtocol ,updateIotCaseProtocol} from "@/api/iot/caseprotocol";
import { listIotNode } from "@/api/iot/node";
//import { addIotUserChoose,getIotUserChoose,getIotUserChooseByUserId,updateIotUserChoose,delIotUserChoose,listIotUserChoose } from "@/api/iot/userchoose";
//import caseinfo from "./info";
export default {
  name: "IotCase",
  components: { },
  data() {
    return {     
      // 遮罩层
      baseid:0,
      standardid:0,
      businessid:0,
      choosecase:0,      
      btnBaseUse:0,//基础版使用实例
      btnStandardTry:1,//标准版免费试用      
      btnStandardUse:0,//标准版使用实例      
      btnBusinessUse:0,//企业版使用实例
      btnBaseBoolean:false,//免费激活
      btnStandardBoolean:true,//免费试用
      loading: true,
      // 选中数组
      ids: [],
      // 总条数
      total: 0,
      // 弹出层标题
      title: "",        
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      billModeOptions: [],
      netOptions: [],
      safeOptions: [],
      caseList:[],
      nodeList:[],
      protocolList:[],
      buytimelist:[
        {key:1,value:"1个月"},{key:2,value:"2个月"},{key:3,value:"3个月"},{key:4,value:"4个月"}
        ,{key:5,value:"5个月"},{key:6,value:"6个月"},{key:7,value:"7个月"},{key:8,value:"8个月"}
        ,{key:9,value:"9个月"},{key:10,value:"10个月"},{key:12,value:"1年"},{key:24,value:"2年"}
        ,{key:36,value:"3年"}
      ],
      connectmaxlist:[{key:100,value:"100"},{key:1000,value:"1,000"},{key:2000,value:"2,000"},{key:3000,value:"3,000"},
          {key:5000,value:"5,000"},{key:10000,value:"10,000"},{key:20000,value:"20,000"},{key:50000,value:"50,000"},
          {key:100000,value:"100,000"}],
      devicemaxlist:[{key:1000,value:"1000"},{key:10000,value:"1万"},{key:100000,value:"10万"},{key:200000,value:"20万"},{key:300000,value:"30万"},
          {key:500000,value:"50万"},{key:1000000,value:"100万"},{key:2000000,value:"200万"},{key:5000000,value:"500万"},
          {key:10000000,value:"1000万"}],

      apphttps:{address:443},
      appamqps:{address:5671},
      devicecoap:{address:5683},
      devicecoaps:{address:5684},
      devicemqtt:{address:1883},
      devicemqtts:{address:8883},
      devicehttps:{address:8943},

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        node_id: undefined
      },
      nodequeryParams: {
        pageNum: 1,
        pageSize: 10        
      },
      // 表单参数
      form: {},
      formprotocol:{},
      formuserchoose:{},
      formcase:{},
      userchoosecase:[{},{},{}],      
      // 表单校验
      rules: {
        case_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getList();    
  },
  methods: {        
    //激活实例，添加一个case
    handleBaseEnable(){      
      this.form = {     
        
        level: 1,
        node_id:this.queryParams.node_id,
        bill_mode:0,
        net_id:0,
        safe_id: 0,
        concurrent_max: 1000,
        device_max: 10000,
      };
      addIotCase(this.form).then(response => {        
        if (response.code == 0) {
          this.msgSuccess("激活成功！");          
          this.btnBaseUse=1;
        } else {
          this.msgError(response.msg);
        }
      }).catch(function (error){
        console.log(error);
      });

    },
    //企业账号免费试用-标准版
    handleStandardEnable(){
      this.msgSuccess("激活试用！");
    },
     //购买实例 把购买改成申请
    handleBuyCase(i){
      this.reset();
      this.open = true;      
      this.form.level=i;
      this.form.node_id=this.queryParams.node_id;
      this.form.is_choose=0;
      if (this.form.level == 2) {
        this.title="申请标准版实例";
        this.form.net_id=0;
        this.form.safe_id=0;
      }        
      if (this.form.level == 3)
        this.title="申请企业版实例";      
    },   
    //修改属性1,2,3
    handleEdit(index){
      
      this.open = true;
      this.form.level=index;
      this.form.bill_mode=1;
      if(index==2){
        this.form=this.userchoosecase[1];
        this.title="修改标准版实例";
      }        
      if(index==3){
        this.form=this.userchoosecase[2];
        this.title="修改企业版实例";
        var protocolparams={
          pageNum:1,
          pageSize:10,
          case_id:this.form.Id,
          base_level:3,
          is_default:0
        };
        listIotCaseProtocol(protocolparams).then(response =>{          
          for (var index=0;index<response.data.list.length;index++){
            if(response.data.list[index].protocol_name == "HTTPS" && response.data.list[index].type == 0){
              this.apphttps=response.data.list[index];
            }
            if(response.data.list[index].protocol_name == "AMQPS" && response.data.list[index].type == 0){
              this.appamqps=response.data.list[index];
            }
            if(response.data.list[index].protocol_name == "CoAP" && response.data.list[index].type == 1){
              this.devicecoap=response.data.list[index];
            }
            if(response.data.list[index].protocol_name == "CoAPS" && response.data.list[index].type == 1){
              this.devicecoaps=response.data.list[index];
            }
            if(response.data.list[index].protocol_name == "MQTT" && response.data.list[index].type == 1){
              this.devicemqtt=response.data.list[index];
            }
            if(response.data.list[index].protocol_name == "MQTTS" && response.data.list[index].type == 1){
              this.devicemqtts=response.data.list[index];
            }
            if(response.data.list[index].protocol_name == "HTTPS" && response.data.list[index].type == 1){
              this.devicehttps=response.data.list[index];
            }
          }
        }).catch(function (error){
          console.log(error);
        });
      }
    },
    //改变节点 
    nodechange(data){
      this.getcaselist();   
    },
    //使用实例
    chooseTheCase(i){
      this.formcase = this.userchoosecase[i];
      this.formcase.is_choose = 1;
      for (var index=0;index<this.caseList.length;index++){        
        if(this.caseList[index].Id==this.formcase.Id){
            updateIotCase(this.formcase).then(response => {
              this.choosecase=i+1;
              this.msgSuccess("切换实例成功！");
            }).catch(function (error){
              console.log(error);
            });
        }else{    
          this.caseList[index].is_choose = -1;
          updateIotCase(this.caseList[index]).then(response => {
              
            }).catch(function (error){
              console.log(error);
            });
        }
      }
      
      
    },
    /** 查询分类列表 */
    getList() {
      this.loading = true;
      listIotNode(this.nodequeryParams).then(response => {
        this.nodeList = response.data.list;             
        this.queryParams.node_id=this.nodeList[0].id;
        //this.statusOptions = response.data.statusOptions.values;        
        this.getcaselist();
        
      }).catch(function (error){
        console.log(error);
      });
    },
    getcaselist(){
      this.baseid=0;
      this.standardid=0;
      this.businessid=0;
      this.choosecase=0;
      this.btnBaseUse=0;
      this.btnStandardTry=1;
      this.btnStandardUse=0;
      this.btnBusinessUse=0;
      this.btnBaseBoolean=false;
      this.btnStandardBoolean=false;     
        listIotCase(this.queryParams).then(response =>{
          this.caseList = response.data.list;
          this.total = response.data.total; 
          this.billModeOptions = response.data.billModeOptions.values;
          this.netOptions = response.data.netOptions.values;
          this.safeOptions = response.data.safeOptions.values;          
          this.checkCase();
          this.loading = false;          
        })
    },
    //核实节点下的实例信息
    checkCase(){          
      if(this.caseList.length > 0){
        for(var i = 0; i < this.caseList.length; i++) {
          if(this.caseList[i].level == 1){
            this.baseid=this.caseList[i].Id;
            this.btnBaseUse=1;
            this.btnBaseBoolean=true;
            if (this.caseList[i].is_choose >= 1){
              this.choosecase=1;
            }
            this.userchoosecase[0]=this.caseList[i];
          }
          if(this.caseList[i].level == 2){
            this.standardid=this.caseList[i].Id;
            this.btnStandardUse=1;
            if (this.caseList[i].is_choose >= 1){
              this.choosecase=2;              
            }
            this.userchoosecase[1]=this.caseList[i];
          }
          if(this.caseList[i].level == 3){
            this.businessid=this.caseList[i].Id;
            this.btnBusinessUse=1;
            if (this.caseList[i].is_choose >= 1){
              this.choosecase=3;              
            }
            this.userchoosecase[2]=this.caseList[i];
          }
        }
      }
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },    
    // 表单重置
    reset() {
      this.apphttps={address:443};
      this.appamqps={address:5671};
      this.devicecoap={address:5683};
      this.devicecoaps={address:5684};
      this.devicemqtt={address:1883};
      this.devicemqtts={address:8883};
      this.devicehttps={address:8943};
      this.form = {
        Id: undefined,
        case_name: undefined,
        level: 0,
        node_id:this.queryParams.node_id,
        bill_mode:0,
        dur_time:1,
        net_id:0,
        safe_id: 0,
        concurrent_max: 100,
        device_max: 1000,        
      };
      this.resetForm("form");
    },       
    /** 提交按钮 */
    submitForm: function() {      
      this.$refs["form"].validate(valid => {
        if (valid) {  
          if (this.form.Id != undefined) {
            updateIotCase(this.form).then(response => {
              if (response.code ===0) {                
                this.open = false;
                this.updateProtocol(this.apphttps);
                this.updateProtocol(this.appamqps);
                this.updateProtocol(this.devicecoap);
                this.updateProtocol(this.devicecoaps);
                this.updateProtocol(this.devicemqtt);
                this.updateProtocol(this.devicemqtts);
                this.updateProtocol(this.devicehttps);
                this.msgSuccess("修改成功");
              } else {
                this.msgError(response.msg);
              }
            });
            
          } else {    
            addIotCase(this.form).then(response => { 
              if (response.code == 0) {                
                if(this.form.level==2){
                  this.btnStandardUse=1;
                  this.msgSuccess("工单生成！申请成功！");
                  this.open = false;
                }                     
                if(this.form.level==3) {                  
                  this.addProtocol(response.data.Id,0,this.apphttps.address,"HTTPS");
                  this.addProtocol(response.data.Id,0,this.appamqps.address,"AMQPS");
                  this.addProtocol(response.data.Id,1,this.devicecoap.address,"CoAP");
                  this.addProtocol(response.data.Id,1,this.devicecoaps.address,"CoAPS");
                  this.addProtocol(response.data.Id,1,this.devicemqtt.address,"MQTT");
                  this.addProtocol(response.data.Id,1,this.devicemqtts.address,"MQTTS");
                  this.addProtocol(response.data.Id,1,this.devicehttps.address,"HTTPS");                  
                  this.open = false;
                  this.msgSuccess("工单生成！申请成功！");
                }
                  
              } else {
                this.msgError(response.msg);
              }
            }).catch(function (error){
              console.log(error);
            });            
          }
        }
      });
    },
    //添加协议
    addProtocol(id,type,port,name) {      
      this.formprotocol={
        case_id:id,
        base_level:3,
        type:type,
        protocol_name:name,
        protocol_version:"3",
        is_default:0,
        address:port
      };
      addIotCaseProtocol(this.formprotocol).then(response => {        
        if (response.code == 0) {
          this.btnBusinessUse=1;              
        } else {
          this.msgError(response.msg);
        }
      }).catch(function (error){
        console.log(error);
      });
    },
    //修改协议
    updateProtocol(protocolfrom){
      if (protocolfrom.Id != undefined){
        updateIotCaseProtocol(protocolfrom).then(response => {

        }).catch(function (error){
          console.log(error);
        });
      }else{
        this.msgError("获取失败！");
      }
    },    
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除模型编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotMould(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    }
  }
};
</script>
