<!-- 生成时间:2022-06-16 16:03:23 -->

<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
        >
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="父ID" prop="parent_id">
                <el-input
                    v-model="queryParams.parent_id"
                    placeholder="请输入父ID"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input
                    v-model="queryParams.remark"
                    placeholder="请输入备注"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/xlzx/category/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/xlzx/category/get', '/xlzx/category/edit']"
                    >修改</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            :tree-config="treeConfig"
            show-overflow
            highlight-hover-row
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
        > -->
            <!--默认操作按钮-->
            <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-plus"
                    @click.stop="handleAdd(row)"
                >
                    新增
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

            <!--自定义空数据模板-->
            <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :tree-config="treeConfig"
            :query-params="queryParams"
         
            @change="tableChange"
            @handleQuery="handleQuery"
            :pager="false"
            :checkbox="false"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/xlzx/category/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/xlzx/category/get', '/xlzx/category/edit']"
                    >修改</el-button
                >
            </template>
          
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-plus"
                    @click.stop="handleAdd(row)"
                >
                    新增
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改对话框 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入名称" />
                </el-form-item>
                <el-form-item label="父ID" prop="parent_id">
                    <treeselect
                        v-model="form.parent_id"
                        :options="treeOptions"
                        :normalizer="normalizer"
                        :show-count="true"
                        placeholder="选择上级"
                    />
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" placeholder="请输入备注" />
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listCategory,
    getCategory,
    delCategory,
    addCategory,
    editCategory,
} from "@/api/xlzx//category";
import Treeselect from "@riophae/vue-treeselect";
import { listMenu, treeselect } from "@/api/core/system/menu";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
    name: "xlzx-category-list",
    components: { Treeselect },
    data() {
        return {
            treeConfig: {
                trigger: "row",
            },
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                {
                    field: "name",
                    title: "名称",
                    minWidth: 80,
                    treeNode: true,
                    fixed: "left",
                    is_query:true
                },
                { field: "id", title: "ID", minWidth: 80 },
                { field: "parent_id", title: "父ID", minWidth: 80 , is_query:true},
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 80,
                    formatter: "formatDate",
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 80,
                    formatter: "formatDate",
                },
                { field: "remark", title: "备注", minWidth: 80, is_query:true },

                // {
                //     field: "",
                //     title: "操作",
                //     width: 230,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],

            advqueryColumn: [
                {
                    field: "qfield",
                    title: "查询字段",
                    width: 180,
                    fixed: "left",
                    editRender: {
                        name: "select",
                        options: [
                            { value: "dd", label: "cxvs" },
                            { value: "zz", label: "cxvs" },
                        ],
                    },
                },
                {
                    field: "qopr",
                    title: "操作符",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "qvalue",
                    title: "值",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "input" },
                },
                {
                    field: "qlogic",
                    title: "逻辑",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultadvqueryopr" },
                },
            ],
            advqueryopen: false,
            advqueryData: [],
            advqueryToolbar: {
                buttons: [{ code: "myInsert", name: "新增" }],
            },
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            treeOptions: [],
            open: false,
            // 字典

            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 9999,
                opstr: {
                    name: "LIKE",
                    parent_id: "=",
                    remark: "LIKE",
                },
                name: undefined,
                parent_id: undefined,
                remark: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],

                parent_id: [
                    {
                        required: true,
                        message: "父ID不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();

        setTimeout(() => {
            const $grid = this.$refs.xAdvqueryGrid;
            // 异步更新下拉选项
            var ta = [{ value: "", label: "请选择" }];
            for (var i in this.tableColumn) {
                var e = this.tableColumn[i];
                if (e.field != null && e.field != "") {
                    ta.push({ value: e.field, label: e.title });
                }
            }
            this.advqueryColumn[0].editRender.options = ta;

            this.advqueryColumn[1].editRender.options = this.defaultQlogic();

            ta = [];
            ta.push({ value: "AND", label: "并且" });
            ta.push({ value: "OR", label: "或者" });
            this.advqueryColumn[3].editRender.options = ta;
        }, 300);
        // 加载字典数据
    },
    methods: {
        tableChange(e) {},
        /** 转换菜单数据结构 */
        normalizer(node) {
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node.id,
                label: node.name,
                children: node.children,
            };
        },

        handleUpload() {},
        handleExport() {},
        handleAdvquery() {
            this.advqueryopen = true;
        },
        //保存条件
        advquery_save() {
            const $grid = this.$refs.xAdvqueryGrid;
            var alldata = $grid.getTableData();
            console.log("alldata", alldata);
            $grid.reloadData(alldata.fullData); //去掉编辑状态
            //      this.advqueryopen=false;
        },
        handleadvqueryDeleted(row) {
            const $grid = this.$refs.xAdvqueryGrid;

            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    $grid.remove(row);
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        handleSizeChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        /** 查询列表 */
        getList() {
            this.loading = true;

            listCategory(this.queryParams).then((response) => {
                this.dataList = this.handleTree(
                    response.data.list,
                    "id",
                    "parent_id"
                );
                this.loading = false;
            });
        },
        // $comment字典翻译

        // 取消按钮
        cancel() {
            this.open = false;
            this.advqueryopen = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                name: undefined,
                parent_id: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery(e) {
            if (e && Object.keys(e).length) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        getTreeselect() {
            listCategory({ pageNum: 1, pageSize: 999 }).then((response) => {
                this.treeOptions = [];
                const opt = { id: 0, name: "主类目", children: [] };
                opt.children = this.handleTree(
                    response.data.list,
                    "id",
                    "parent_id"
                );
                this.treeOptions.push(opt);
            });
        },

        /** 新增按钮操作 */
        handleAdd(row) {
            this.reset();
            this.open = true;
            this.title = "添加";
            this.getTreeselect();
            if (row != null) {
                this.form.pid = row.id;
            }
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.getTreeselect();
            const id = row.id || this.ids;
            getCategory(id).then((response) => {
                this.form = response.data.data;

                this.open = true;
                this.title = "修改";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editCategory(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addCategory(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;

            this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delCategory(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
    }, //methods结束
};
</script>
