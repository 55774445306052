<template>
    <el-row class="center-board-row" :gutter="formConf.gutter">
        <el-form
            :size="formConf.size"
            :label-position="formConf.labelPosition"
            :disabled="formConf.disabled"
            :label-width="formConf.labelWidth + 'px'"
        >
            <draggable
                class="drawing-board"
                :list="drawingList"
                :animation="200"
                group="componentsGroup"
                @end="onMianDragEnd"
            >
                <draggable-item
                    v-for="(element, index) in drawingList"
                    :key="element.renderKey"
                    :drawing-list="drawingList"
                    :element="element"
                    :index="index"
                    :active-id="activeId"
                    :form-conf="formConf"
                    :put="shouldClone"
                    @activeItem="activeFormItem"
                    @copyItem="drawingItemCopy"
                    @deleteItem="drawingItemDelete"
                />
            </draggable>
            <div v-show="!drawingList.length" class="empty-info">
                从左侧拖入或点选组件进行表单设计
            </div>
        </el-form>
    </el-row>
</template>
<script>
import draggable from "vuedraggable";
import DraggableItem from "./DraggableItem";
export default {
    components: {
        draggable,

        DraggableItem,
    },
    props: {
        defaultDrawingList: {
            type: Array,
            default: () => [],
        },
        formConf: {
            type: Object,
            default: () => {},
        },
        defaultActiveId: {
            type: [String, Number],
            default: "",
        },
        defaultActiveData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            activeId: "",
            activeData: {},
            drawingList: [],
        };
    },
    watch: {
        defaultActiveId: {
            handler(val) {
                this.activeId = val;
                console.log( this.activeId,' this.activeId');
            },
            immediate: true,
            deep: true,
        },
        defaultActiveData: {
            handler(val) {
                this.activeData = this.deepCopy(val);
                console.log( this.activeData,' this.activeData');
            },
            immediate: true,
            deep: true,
        },
        defaultDrawingList: {
            handler(val) {
                this.drawingList = val;
                
            },

            deep: true,
            immediate: true,
        },
    },
    methods: {
        // checkColItem(cmp) {
        //     this.$emit("checkColItem", cmp);
        // },
        isFilledPCon(formIds) {
            const processCmp = this.$parent.$children.find(
                (t) => t.isProcessCmp
            );
            return processCmp && processCmp.isFilledPCon(formIds);
        },

        checkColItem(cmp) {
            if (!cmp) return false;
            const isPcon = this.$store.state.jpgflow.processConditions.find(
                (t) => t.formId == cmp.formId
            )
                ? true
                : false;
            return isPcon && this.isFilledPCon([cmp.formId]);
        },
        // 判断是否已被流程图作为条件必填项了
        isProCondition(cmp) {
            if (Array.isArray(cmp.children) && cmp.children.length) {
                // 容器组件需要检查子元素
                if (cmp.rowType === "table") return false; // 表格的子元素不可能为流程条件
                let flag = false;
                const loop = (el) => {
                    if (flag) return; // flag === true 代表找到了一个了 不需要再找下一个
                    if (Array.isArray(el)) {
                        el.some((e) => {
                            if (e.children) loop(e.children);
                            return this.checkColItem(e);
                        }) && (flag = true);
                    }
                };
                loop(cmp.children);
                return flag;
            } else {
                return this.checkColItem(cmp);
            }
        },
        drawingItemDelete(index, parent) {
            // 首先判断是否是流程条件 再判断是否有节点使用过
            if (this.isProCondition(parent[index])) {
                this.$message.warning("该组件已作为流程判断条件，无法删除");
                return;
            }
            this.$store.commit("delPCondition", parent[index].formId);
            parent.splice(index, 1);
            this.$nextTick(() => {
                const len = this.drawingList.length;
                if (len) {
                    this.activeFormItem(this.drawingList[len - 1]);
                }
            });
        },
        drawingItemCopy(item, parent) {
            let clone = JSON.parse(JSON.stringify(item));
            clone = this.createIdAndKey(clone);
            parent.push(clone);
            this.activeFormItem(clone);
        },
        createIdAndKey(item) {
            item.formId = this.getNextId();
            item.renderKey = clone.formId + new Date().getTime();
            if (item.layout === "colFormItem") {
                item.vModel = `${item.type}${item.formId}`; //`field${item.formId}`;
            } else if (item.layout === "rowFormItem") {
                item.componentName = `row${item.formId}`;
            }
            if (Array.isArray(item.children)) {
                item.children = item.children.map((childItem) =>
                    this.createIdAndKey(childItem)
                );
            }
            return item;
        },
        getNextId() {
            let maxId = this.getMaxId();
            let str = maxId.toString();
            if (str.length >= 13) {
                return new Date().getTime();
            }
            return maxId + 1;
        },
        /**
         * 阻止表格中嵌套行容器
         * 定制组件不能添加子组件
         */
        shouldClone(to, from, target, event, conf) {
            // .drawing-row-item —— 行容器的类名 ipad里面的组件才会带有
            // 直接拖拽的行容器 最外层含有.drawing-row-item
            // 定制组件 内部含有.drawing-row-item
            // const hasRow = target.classList.contains('.drawing-row-item') || target.querySelector('.drawing-row-item') !== null
            // const isRowContainer = ['布局容器', '表格/明细'].includes(target.innerText) //是阻止从左侧拖拽嵌套
            // const isCusFromLeft = target.classList.contains('custom-component')
            const targetConf = target._underlying_vm_;
            const isRowContainer =
                conf.cmpType === "common" && conf.rowType === "layout";
            if (isRowContainer) return true;
            if (conf.cmpType === "custom") return false;
            if (conf.rowType === "table") {
                if (targetConf.layout === "rowFormItem") return false;
                if (this.isFilledPCon([targetConf.formId])) return false;
            }
            return true;
        },

    
        onMianDragEnd(obj, a) {
            console.log(obj, "obj");
            this.activeFormItem(this.drawingList[obj.newIndex]);
            // this.$emit('onMianDragEnd',{obj,a})
        },
        activeFormItem(element) {
            if (element) {
                this.activeData = element;
                this.activeId = element.formId;
                console.log(this.activeData , this.activeId)
                this.$emit("activeFormItem", element);
                // if (element) {
                //     switch (this.styleIndex * 1) {
                //         case 1:
                //             this.activeData = element;
                //             this.activeId = element.formId;
                //             break;
                //         case 3:
                //             this.editableTabs[this.tabIndex].activeData =
                //                 element;
                //             this.editableTabs[this.tabIndex].activeId =
                //                 element.formId;
                //             break;
                //     }
                // }
            }
        },
    },
};
</script>