<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      stripe
      show-overflow
      highlight-hover-row
      :loading="loading"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="flowList"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </template>

      <template #flowstatus="{ row }">
        <el-tag
          v-for="dict in statusOptions"
          :type="dict.type"
          v-if="dict.key == row.status"
        >{{dict.value}}</el-tag>
      </template>
      <template #flowversion="{ row }">
        <el-tag type="primary" >V:{{row.version}}</el-tag>
      </template>
      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click.stop="handleDesign(row)">设计</el-button>
        <el-button type="text" size="mini" icon="el-icon-view" @click.stop="handlePreview(row)">预览</el-button>
        <el-button  type="text" size="mini" icon="el-icon-time" @click.stop="handleVersion(row)">版本管理</el-button>

        <el-dropdown  size="small" style="margin-left: 10px;" v-if="false">
          <el-button type="text" size="small">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item v-if="row.status != '1'"><el-button type="text" size="mini" icon="el-icon-video-play" @click.stop="handelActive(row)">激活</el-button></el-dropdown-item>
            <el-dropdown-item v-if="row.status == '1'"><el-button type="text" size="mini"  icon="el-icon-video-pause" @click.stop="handleSuspend(row)">挂起</el-button></el-dropdown-item>
            <el-dropdown-item><el-button  type="text" size="mini" icon="el-icon-download" @click.stop="exportJson(row)">导出</el-button></el-dropdown-item>
            <el-dropdown-item> <el-button  type="text" size="mini" icon="el-icon-copy-document" @click.stop="handleCopy(row)">复制</el-button> </el-dropdown-item>
            <el-dropdown-item> <el-button  type="text" size="mini" icon="el-icon-delete" @click.stop="handleDelete(row)">删除</el-button> </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!--
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>-->
      </template>

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
      background
      size="small"
      :loading="loading"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      :total="total"
      @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改流程对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="名称" />
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <design-form ref="designForm" @refreshList="getList"></design-form>
    <preview-form ref="previewForm"></preview-form>
  </div>
</template>

<script>
  import { listBpmFlow, getBpmFlow, delBpmFlow } from "@/api/bpm/flow";
  import DesignForm from './design'
  import PreviewForm from './preview'

  const beforeUnload = function (e) {
    let msg = '离开网站可能会丢失您编辑的内容';
    (e || window.event).returnValue = msg;     // Gecko and Trident
    return msg;                                // Gecko and WebKit
  }
  export default {
    name: "bpm_flow_list",
    data() {
      return {
        // 工具栏
        tableToolbar: {
          perfect: true,
          zoom: true,
          custom: true,
          refresh: {
            query: this.handleQuery
          },
          slots: {
            buttons: 'toolbar_buttons'
          }
        },
        // 列
        tableColumn: [
          {type: 'checkbox', width: 60, fixed: "left"},
          {field: 'id', title: '编号', width: 100, fixed: "left"},
          {field: 'name', title: '流程名称' },
          {field: 'flow_code', title: '流程编码',visible:false},
          {field: 'category_name', title: '分类' },
          {field: 'version', title: '版本', slots: {default: 'flowversion'}},
          {field: 'status', title: '状态', slots: {default: 'flowstatus'}},
          {field: 'update_time', title: '更新时间',  formatter: "formatDate"},
          {field: '', title: '操作',resizable:false, width: 280, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
        ],
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 总条数
        total: 0,
        // 流程表格数据
        flowList: [],
        // 弹出层标题
        title: "",
        // 是否显示弹出层
        open: false,
        // 状态数据字典
        statusOptions: [
          {key:"0",value:'未发布', type:'danger'},
          {key:"1",value:'已发布', type:'success'},
          {key:"2",value:'已挂起', type:'primary'},
        ],
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          name: undefined,
          status:undefined,
        },
        // 表单参数
        form: {},
        // 表单校验
        rules: {
          category_id: [
            { required: true, message: "分类不能为空", trigger: "blur" }
          ],
          name: [
            { required: true, message: "名称不能为空", trigger: "blur" }
          ]
        }
      };
    },
    components: {
      DesignForm,
      PreviewForm
    },
    beforeRouteEnter(to, from, next) {
      window.addEventListener('beforeunload', beforeUnload)
      next()
    },
    beforeRouteLeave(to, from, next) {
      window.removeEventListener('beforeunload', beforeUnload)
      next()
    },
    created() {
      this.getList();
    },
    methods: {
      handleSizeChange ({ currentPage, pageSize }) {
        this.queryParams.pageNum = currentPage;
        if(this.queryParams.pageSize == pageSize){
          this.getList();
        }else{
          this.queryParams.pageSize = pageSize;
          this.handleQuery();
        }

      },
      checkboxChangeEvent ({ records }) {
        this.ids = records.map(item => item.id)
        this.single = records .length!=1;
        this.multiple = !records .length

      },

      /** 查询流程列表 */
      getList() {
        this.loading = true;
        listBpmFlow(this.queryParams).then(response => {
          this.single = true;
          this.multiple = true;
          this.flowList = response.data.list;
          console.log(this.flowList,'this.flowList')
          this.total = response.data.total;
          this.loading = false;
        });
      },
      // 取消按钮
      cancel() {
        this.open = false;
        this.reset();
      },
      // 表单重置
      reset() {
        this.form = {
          id: undefined,
          category_id: undefined,
          status: undefined,
          name: undefined,
          remark: undefined
        };
        this.resetForm("form");
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.resetForm("queryForm");
        this.handleQuery();
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id)
        this.single = selection.length!=1
        this.multiple = !selection.length
      },
      /** 新增按钮操作 */
      handleAdd() {
        this.reset();
        this.$refs['designForm'].init(0)
      },
      handleDesign(row){
        this.$refs['designForm'].init(row.resource_id)
      },
      handlePreview(row){
        this.$refs['previewForm'].init(row.resource_id)
      },
      handleVersion(row) {
        this.$router.push({ path: "/bpm/flow/version", query: {id:row.id}});
      },
      handelActive(row){},
      handleSuspend(row){},
      exportJson(row){},
      handleCopy(row){},
      /** 提交按钮 */
      submitForm: function() {
        this.$refs["form"].validate(valid => {
          if (valid) {
          }
        });
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        const ids = row.id || (this.ids ? this.ids.join(',') : '');
        this.$confirm('确定删除该流程（影响流程实例及相关历史数据，不可恢复）吗?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delBpmFlow(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
      },
      /** 导出按钮操作 */
      handleExport() {
        const queryParams = this.queryParams;
        this.$confirm('是否确认导出所有流程数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportBpmFlow(queryParams);
        }).then(response => {
          this.download(response.msg);
        }).catch(function() {});
      }
    }
  };
</script>
