import request from '@/utils/request'

// 查询生成表数据
export function listTable(query) {
  return request({
    url: '/gen/gentablelist',
    method: 'get',
    params: query
  })
}
// 查询db数据库列表
export function listDbTable(query) {
  return request({
    url: '/gen/dbtablelist',
    method: 'get',
    params: query
  })
}

// 查询表详细信息
export function getGenTable(tableId) {
  return request({
    url: '/gen/gencolumnlist?table_id=' + tableId,
    method: 'get'
  })
}

// 修改代码生成信息
export function updateGenTable(data) {
  var headers={}
  headers["Content-Type"] = "application/json;charset=utf-8"
  //data["columns"] = JSON.stringify(data["columns"] )
  return request({
    url: '/gen/gencolumnsave',
    method: 'post',
    data: data,
    headers:headers
  })
}

// 导入表
export function importTable(data) {
  return request({
    url: '/gen/import_table_save',
    method: 'post',
    params: data
  })
}
// 预览生成代码
export function previewTable(tableId) {
  return request({
    url: '/system/tools/gen/preview?tableId=' + tableId,
    method: 'get'
  })
}
// 生成代码
export function genCode(data) {
  return request({
    url: '/gen/gencode',
    method: 'post',
    data:data
  })
}
// 删除表数据
export function delTable(tableId) {
  return request({
    url: '/gen/deltable?ids='+tableId, 
    method: 'get'
  })
}

// 根据类型查找表单
export function formList(form_type) {
  return request({
    url: '/gen/formlist?form_type=' + form_type,
    method: 'get'
  })
}
// 根据表单查找字段
export function formColumns(table_id) {
  return request({
    url: '/gen/formcolumns?table_id=' + table_id,
    method: 'get'
  })
}
export function genMenu(data) {
  return request({
    url: '/gen/genmenu',
    method: 'post',
    data:data
  })
}