<template>
 <el-row style="padding: 10px;">
    <el-col :span="14" class="card-box" style="overflow:auto;margin-top:20px;"> 
      <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px"> 
        <el-form-item  prop="name">
          <el-input
            v-model="queryParams.name"
            placeholder="模糊查找名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
          />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
        <el-row/>
       <vxe-grid            
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="warnList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
        <template #toolbar_buttons>
          <el-button
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
          >创建警告规则</el-button>
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
          >修改</el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
          >删除</el-button>
        </template>
        <!--状态栏-->
        <template #status="{row}">
          <el-switch
            v-model="row.status"
            :active-value="1"
            :inactive-value="0"
            @change="handleStatusChange(row)"
          ></el-switch>
        </template>
        <!--默认操作按钮-->
        <template #defaultopr="{ row }">         
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
          >修改
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
          >删除
          </el-button>
        </template>
        
        <!--自定义空数据模板-->
        <template #empty>
              <span >
                <p>暂无数据</p>
              </span>
        </template>

      </vxe-grid>
      <vxe-pager
              background
              size="small"
              :loading="loading"
              :current-page="queryParams.pageNum"
              :page-size="queryParams.pageSize"
              :total="total"
              @page-change="handleSizeChange">
      </vxe-pager>
    </el-col>
    <el-col :span="10" class="card-box">
        <el-card style="height: 100%;width: 100%;margin-top: 10px">
          <div slot="header">
            警告统计
          </div>
          <div class="el-table el-table--enable-row-hover el-table--medium">              
            <v-chart :options="option"                  
              autoresize />
          </div>
          <div class="verify-line" style="margin-top:15px">
            <v-chart :options="lineOption"                  
              autoresize />
          </div>
        </el-card>
      </el-col>
      <!-- 添加修改触发条件 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="820px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">  
        <el-form-item label="规则名称" prop="name" >
          <el-input v-model="form.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="激活规则" >
          <el-switch v-model="form.status" :active-value="1" :inactive-value="0" />        
        </el-form-item>   
        <el-form-item label="警告类型" prop="warn_type">
          <el-select v-model="form.warn_type" placeholder="请选择触发警告的类型" clearable @change="TypeChange">
            <el-option              
              v-for="(item,key) in warnTypeList"
              :key="key+1"
              :label="item.value"
              :value="key+1"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="触发对象">
          <el-select v-model="form.object" placeholder="请选择触发对象" clearable  @change="ObjectChange">
            <el-option            
              v-for="dict in ObjectList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
            />
          </el-select>
          <vxe-grid         
                style="margin-top:5px"   
                height="260"
                ref="objectGrid"
                row-id="id"
                stripe   
                show-overflow
                highlight-hover-row
                :loading="loading"
                :toolbar-config="tableToolbar"
                :columns="objectTableColumn"
                :data="objectTableList"
                :column-config="{resizable: false}"
                :radio-config="{checkRowKey: radiodefaultSelecteRow}"
                @radio-change="radiocheckboxChangeEvent">
                <!--自定义空数据模板-->
                <template #empty>
                      <span >
                        <p>暂无数据</p>
                      </span>
                </template>

          </vxe-grid>
          <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
          </vxe-pager>
        </el-form-item>
        <el-form-item label="服务选择" prop="service_id">
          <el-select v-model="form.service_id" placeholder="请选择服务" clearable  @change="ServiceChange">
            <el-option
              v-for="dict in serviceList"
              :key="dict.id"
              :label="dict.service_name"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="属性名称" prop="attribute_name">
          <el-select v-model="form.attribute_name" placeholder="请选择属性" clearable >
            <el-option
              v-for="dict in attributeList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="条件选择" prop="condition">
          <el-select v-model="form.condition" placeholder="请选择条件" clearable >
            <el-option
              v-for="dict in conditionCompareList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="预警数值" prop="min_value" v-if="parseInt(form.condition)<=4">
          <el-input v-model="form.min_value" placeholder="请输入预警数值" />
        </el-form-item>
        <el-form-item label="最小值" prop="min_value" v-if="parseInt(form.condition)>4">
          <el-input v-model="form.min_value" placeholder="请输入最小值" />
        </el-form-item>
        <el-form-item label="最大值" prop="max_value" v-if="parseInt(form.condition)>4">
          <el-input v-model="form.max_value" placeholder="请输入最大值" />
        </el-form-item>
        <el-form-item label="持续时效(S)" prop="keep_time">
          <el-input v-model="form.keep_time" placeholder="请输入数值"  type="number"/>
        </el-form-item>
        <el-form-item label="警告内容" >
          <el-input v-model="form.content" type="textarea" placeholder="请填写警告接收时的提示内容" />
        </el-form-item>
        <el-form-item label="转发部门" >
          <vxe-grid              
              resizable
              ref="deptGrid"
              row-id="id"
              :tree-config="treeConfig"
              show-overflow
              highlight-hover-row
              :highlightCurrentRow="true"
              :loading="deptloading"
              :columns="deptTableColumn"
              :data="deptList"
              :checkbox-config="{checkRowKeys: defaultSelecteRows, highlight: true}"
              @checkbox-change="deptcheckboxChangeEvent"
              @checkbox-all="deptcheckboxChangeEvent"
            />  
        </el-form-item>
        <el-form-item label="描述" >
          <el-input v-model="form.remark" type="textarea" placeholder="可选填" />
        </el-form-item>        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>
<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/axis'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import { listIotWarn, delIotWarn, updateIotWarn, getIotWarn, addIotWarn } from "@/api/iot/warn";
import { listDept } from "@/api/iot/dept";
import { listIotProduct} from "@/api/iot/product";
import { listIotCase} from "@/api/iot/case";
import { listIotDevice } from "@/api/iot/device";
import { listIotProductService, getIotProductService, addIotProductService, updateIotProductService, delIotProductService } from "@/api/iot/productservice";
import { listIotServiceAttribute, getIotServiceAttribute, addIotServiceAttribute, updateIotServiceAttribute, delIotServiceAttribute ,delIotServiceAttributeByCommandId} from "@/api/iot/serviceattribute";

export default {  
  components: {
    'v-chart': ECharts
  },
    props: {
    info: {//这里是传进来的折线图数据
      type: Array,
      default: () => {
        return [{total:1000,month:"1",year:"2022"},{total:2000,month:"2",year:"2022"},{total:3000,month:"3",year:"2022"},
              {total:4000,month:"4",year:"2022"},{total:5000,month:"5",year:"2022"},{total:3000,month:"6",year:"2022"},
              {total:1000,month:"7",year:"2022"},{total:2000,month:"8",year:"2022"},{total:3000,month:"9",year:"2022"},
              {total:4000,month:"10",year:"2022"},{total:2000,month:"11",year:"2022"},{total:8000,month:"12",year:"2022"},
            ]
      }
    }
  },
  data() {   
    const splitLineStyle = {
      show: true,
      lineStyle: {
        color: ['rgb(241, 241, 241)'],
        width: 1,
        type: 'solid'
      }
    }
    // 坐标轴线样式
    const axisLine = {
      show: true,
      lineStyle: {
        color: 'rgb(241, 241, 241, .5)'
      }
    } 
    // 坐标文字样式
    const axisLabel = {
      interval: 0,
      textStyle: {
        color: '#50576A' // 坐标值得具体的颜色
      }
    }
    return {
      radiodefaultSelecteRow:0,
      defaultSelecteRows:[],
      ObjectList:[{id:1,name:"产品"},{id:2,name:"设备"}],
      warn_time:"2022-06-21 16:00:00",
      warn_level:"一级警告",
      count: 4,
      //表格相关
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 80, fixed: "left"},
        {field: 'name', title: '名称' , width: 100},
        {field: 'status', title: '状态', width: 80, slots: {default: 'status'}},
        {field: '', title: '类型' , width: 100,formatter:this.warnTypeFormat},
        {field: '', title: '对象',formatter:this.warnObjectFormat},
        {field: 'remark', title: '描述' ,formatter:this.protocolFormat},
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      //表格数据  
      warnList:[],
      queryParams:{
        pageNum: 1,
        pageSize: 10,
        status:0,
        dept_ids:"",
        name:""
      },
      ///部门
      treeConfig:{
        "trigger":"row",
      },
      depttableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      deptTableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'name', title: '机构名称',treeNode: true}        
      ],
      // 遮罩层
      deptloading: true,
      // 选中数组
      deptids: [],
      // 非单个禁用
      deptsingle: true,
      // 非多个禁用
      deptmultiple: true,
      // 总条数
      depttotal: 0,
      deptList:[],
      //触发对象{type: 'radio', width: 30, fixed: "left"},
      objectTableColumn: [
        {type: 'radio', width: 30, fixed: "left"},
        {field: 'id', title: '编号', width: 80, fixed: "left"},
        {field: '', title: '名称',formatter:this.nameFormat},
        {field: '', title: 'ID',formatter:this.idFormat},
        {field: 'device_type', title: '设备类型'},    
      ],

      //表格数据  
      warnList:[],
      queryParams:{
        pageNum: 1,
        pageSize: 10,
        status:0,
        dept_ids:"",
        name:""
      },
      statusOptions:[],
      warnTypeList:[],
      title:"",
      open:false,
      form:{},
      rules:{        
        name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ]
      },
      productqueryParams:{
        pageNum: 1,
        pageSize: 10,
        node_id: undefined,
        case_id: undefined,
        space_id: undefined
      },
      devicequeryParams:{
        pageNum: 1,
        pageSize: 10,
        group_id:-1,
        device_name: "",
        device_check:"",
        device_code:""
      },
      sevicequeryParams:{
        pageNum: 1,
        pageSize: 10,
        product_id:undefined
      },
      productList:[],
      deviceList:[],
      objectTableList:[],
      chooseObject:{},//选择触发的对象(产品或者设备)
      serviceList:[],
      attributeList:[],
      conditionCompareList:[{id:"0",name:"等于"},{id:"1",name:"大于"},{id:"2",name:"小于"},{id:"3",name:"大于等于"},{id:"4",name:"小于等于"},
      {id:"5",name:"范围：大于最小值，小于最大值"},{id:"6",name:"范围：大于等于最小值，小于等于最大值"}],
      
      ////////////////////
      //饼图配置
      option: {
        // 调整边距(上下左右)
        title: {
          text: '警告占比',
          subtext: 'Scale Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },        
        series: [
          {
            name: '警告消息',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '一级警告' },
              { value: 735, name: '二级警告' },
              { value: 580, name: '三级警告' },
              { value: 484, name: '预警提示' },
              { value: 300, name: '平台警告' },
              { value: 200, name: '其他警告' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      //线图配置
      lineOption:{
        title: {
          text: '消息统计',
          subtext: 'Census Data',
          left: 'center'
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false, // 坐标轴两边留白
          data: [],
          axisLine: axisLine,
          axisLabel: {
            // 坐标轴刻度标签的相关设置
            interval: 0, // 如果设置为 1，表示『隔一个标签显示一个标签』
            textStyle: {
              color: '#50576A' // 坐标值得具体的颜色
            },
            // 坐标轴文字的显示格式
            formatter: function (params) {
              return params + '月'
            }
          }
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '30%'],
          axisLine: axisLine,
          axisLabel: axisLabel,
          splitLine: splitLineStyle,
          // 不显示坐标轴刻度
          axisTick: {
            show: false
          }
        },
        // 高亮提示设置
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#3AD4A7'
                  },
                  {
                    offset: 1,
                    color: '#3AD4A7'
                  }
                ],
                global: false
              }
            }
          }
        },
        series: [
          {
            type: 'line',
            smooth: true,
            showSymbol: true, // 显示转折点变大
            symbol: 'circle', // 设定为实心点
            symbolSize: 1, // 设定实心点的大小
            lineStyle: {
              // 数据线的样式
              color: '#4669F5',
              width: 1
            },
            itemStyle: {
              normal: {
                // 配置转折点的样式
                color: '#3AD4A7'
              }
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(70, 105, 245, .8)' // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 255, 255, 0.5)' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0.5)' // 100% 处的颜色
                  }
                ]
              }
            },
            data: []
          }
        ]
      }
    } 
    
  }, 
 watch: {//深度监听传过来的折线图数据
    info: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.length) {
          let xAxis = []
          let yAxis = []
          val.forEach((item, index) => {
            xAxis.push(item.month)
            yAxis.push(item.total)
          })
          this.lineOption.series[0].data = yAxis
          this.lineOption.xAxis.data = xAxis
        } else {
          const lineLen = 12
          let xAxis = []
          for (let i = 1; i <= lineLen; i++) {
            xAxis.push(i)
          }
          this.lineOption.series[0].data = []
          this.lineOption.xAxis.data = xAxis
        }
      }
    }
  },
  created() {   
    this.getWarnList();
    this.getDeptList();
    this.getParams();
  },  
  computed: {
      noMore () {
        return this.count >= 4
      },
      disabled () {
        return this.loading || this.noMore
      }
  },
  methods: {
    handleStatusChange(row) {
        let text = row.status == 1 ? "启用" : "停用";
        this.$confirm('确认要"' + text + '""' + row.name + '"吗?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            }).then(function() {
              updateIotWarn(row).then(response => {
                    if (response.code ==0) {
                      
                    } else {
                      this.msgError(response.msg);
                    }
                    }).catch(function (error){
                      console.log(error);
                });
            }).then(() => {             
              this.msgSuccess(text + "成功");
            }).catch(function() {
              row.status = row.status == 0 ? 1 : 0;
        });        
    },
    ServiceChange(){
      this.attributeList=[];
        var attqueryParams={            
            pageNum: 1,
            pageSize: 10,
            service_id: this.form.service_id,
            rule_id: 0,
            command_id:0     
        };
        listIotServiceAttribute(attqueryParams).then(response =>{
          if (response.code ===0){
            this.attributeList=response.data.list;
            if(this.attributeList.length>0)
              this.form.attribute_name=this.attributeList[0].name;
            else
              this.form.attribute_name=undefined;
          }else {
            this.msgError(response.msg);
          }
        })
    },
    radiocheckboxChangeEvent({row}){      
      this.chooseObject=row;
      if(this.form.object==1){
        this.sevicequeryParams.product_id=row.id;
      }else{
        this.sevicequeryParams.product_id=row.product_id;
      }      
      this.getServiceList();
    },
     getServiceList(){
      listIotProductService(this.sevicequeryParams).then(response =>{
        this.serviceList=response.data.list;        
        this.form.service_id=undefined;
        this.form.attribute_name=undefined;
      })
    },
    nameFormat({row}){
      if(this.form.object==1){
        return "产品："+row.name;
      }else{
        return "设备："+row.device_name;
      }
    },
    idFormat({row}){
      if(this.form.object==1){
        return row.product_code;
      }else{
        return row.device_code;
      }
    },
    warnTypeFormat({row}){
      var label="";
        this.warnTypeList.forEach(element =>{
            if(element.key==row.warn_type)
                label=element.value;
        })
        return label
    },
    warnObjectFormat({row}){
      var label="";
      if(row.device_id==0){
        label="指定产品";
      }else{
        label="指定设备";
      }
      return label
    },
    getParams(){
      var casequeryParams={
        pageNum: 1,
        pageSize: 10,
        node_id: undefined      
      };
      listIotCase(casequeryParams).then(response =>{
          for (var index=0;index<response.data.list.length;index++){
            if (response.data.list[index].is_choose >= 1){
              this.productqueryParams.case_id=response.data.list[index].Id;
              
            }              
          }   
          this.getProductList(); 
          this.getDeviceList();
        })
    },
    getProductList(){
      listIotProduct(this.productqueryParams).then(response => {       
        this.productList = response.data.list;  
        }).catch(function (error){
          console.log(error);
        });
    },
    getDeviceList(){
      listIotDevice(this.devicequeryParams).then(response => {
        this.deviceList = response.data.list;
      });
    },
    ObjectChange(){
      if(this.form.object==1){
        this.objectTableList=this.productList;
      }else if(this.form.object==2){
        this.objectTableList=this.deviceList;
      }      
    },
    submitForm: function() {
      const ids = this.deptids ? this.deptids.join('|') : '';
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
             if(this.sevicequeryParams.product_id>0){
              this.form.product_id=this.sevicequeryParams.product_id;
            }
            if(this.form.object==1){
              this.form.device_id=0;
            }else if(this.form.object==2){
              this.form.device_id=this.chooseObject.id;
            }
            this.form.dept_ids=ids;
            updateIotWarn(this.form).then(response=>{
              if (response.code ==0){
                this.msgSuccess("修改规则成功");
                this.open = false;               
                this.getWarnList();
              }else{
                this.msgError(response.msg);
              }
            });
           
          } else {
            if(this.sevicequeryParams.product_id>0){
              this.form.product_id=this.sevicequeryParams.product_id;
            }
            if(this.form.object==1){
              this.form.device_id=0;
            }else if(this.form.object==2){
              this.form.device_id=this.chooseObject.id;
            }
            this.form.dept_ids=ids;
            if(this.form.condition=="0"||this.form.condition=="1"||
            this.form.condition=="2"||this.form.condition=="3"||this.form.condition=="4"){
              this.form.max_value=this.form.min_value;
            }
            addIotWarn(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("创建规则成功");
                this.open = false;
                //this.$refs.objectGrid.clearRadioRow();
                //this.$refs.deptGrid.clearCheckboxRow();
                this.getWarnList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    handleUpdate(row){
      this.reset();
      //this.form = row;
      getIotWarn(row.id).then(resp=>{
        this.form=resp.data;
        if(this.form.device_id>0){
          this.form.object=2;
          this.objectTableList=this.deviceList;
          this.radiodefaultSelecteRow=this.form.device_id;
        }else{
          this.form.object=1;
          this.objectTableList=this.productList;
          this.radiodefaultSelecteRow=this.form.product_id;
        }
        this.sevicequeryParams.product_id=this.form.product_id;
        listIotProductService(this.sevicequeryParams).then(response =>{
          this.serviceList=response.data.list;
        });
        this.title = "修改警告规则";
        this.defaultSelecteRows=this.form.dept_ids.split("|");
        this.open = true;
      });      
    },
    deptcheckboxChangeEvent ({ records }) {
      this.deptids = records.map(item => item.id)
      this.deptsingle = records .length!=1;
      this.deptmultiple = !records .length
    },
    getDeptList(){
      var  deptqueryParams= {
        name: undefined,
        status: undefined
      };      
      this.deptloading=true;
      listDept(deptqueryParams).then(response => {
        this.deptList = this.handleTree(response.data.list, "id", "parent_id");
        this.statusOptions = response.data.statusOptions.values || []
        this.typeOptions = response.data.typeOptions.values || []
        this.deptloading=false;
      });
    }, 
    handleAdd(){
      this.reset();
      this.open = true;
      this.title = "创建警告规则";
      this.objectTableList=this.productList;      
    },// 取消按钮
    cancel() {
      this.open = false;     
      //this.$refs.objectGrid.clearRadioRow(); 
      //this.$refs.deptGrid.clearCheckboxRow();
      //this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id:undefined,
        name:"",
        min_value:0,
        max_value:100,
        object:1,
        warn_type:1,
        status:1,
        condition:"0",
        keep_time:10
      };
      this.radiodefaultSelecteRow=null;
      this.defaultSelecteRows=[];      
      this.resetForm("form");
    },
    getWarnList(){
      this.loading=true;
      listIotWarn(this.queryParams).then(resp=>{
        if(resp.code==0){
          this.single = true;
          this.multiple = true;
          this.total = resp.data.total;
          this.warnList=resp.data.list;
          this.statusOptions=resp.data.statusOptions.values;
          this.warnTypeList=resp.data.typeOptions.values;
        }
        this.loading=false;
      })
    },
    load () {
        this.loading = true
        setTimeout(() => {
          this.count += 2
          this.loading = false
        }, 500)
    },    
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getWarnList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认撤销属性为"' + row.name + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
           //return getIotDeviceAttribute(ids);
        }).then((res) => {
          /* res.data.will_value=" ";
          updateIotDeviceAttribute(res.data).then(response=>{
            this.getTableAttribute();
            this.msgSuccess("撤销成功");
          }); */
        }).catch(function() {});
    }
  }
}
</script>