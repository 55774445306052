<template>
    <el-form-item :label="i18nt('designer.setting.showPosName')" v-if="!designer.isTopic">
        <div class="show-pos-btns">
            <!-- <el-button
                :class="pos_i === 0 ? 'mr-5' : 'btns-item'"
                @click="showposChange(pos)"
                v-for="(pos, pos_i) in fieldposlist"
                size="mini"
                :type="optionModel.showPos.indexOf(pos) > -1 ? 'primary' : ''"
                :key="pos"
                >{{ pos }}
            </el-button> -->
            <div
                class="btns-item"
                @click="showposChange(pos)"
                v-for="pos in fieldposlist"
                :class="
                    optionModel.showpos && optionModel.showpos.indexOf(pos) > -1
                        ? 'active'
                        : ''
                "
                :key="pos"
            >
                {{ pos }}
            </div>
        </div>
    </el-form-item>
</template>
<script>
import i18n from "@/vform/utils/i18n";
export default {
    name: "field-pos-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            activeData: { showpos: [] },
            fieldposlist: ["列表", "查询", "新增", "修改", "导入", "导出"],
        };
    },
    created() {
   
    },
    methods: {
        showposChange(row) {
            console.log(this.optionModel.showpos);
            if (this.optionModel.showpos) {
                let index = this.optionModel.showpos.findIndex(
                    (o) => o === row
                );
                if (index > -1) {
                    this.optionModel.showpos.splice(index, 1);
                    return false;
                }
                this.optionModel.showpos.push(row);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.show-pos-btns {
    margin: -2.5px;
    display: flex;
    flex-wrap: wrap;
    .btns-item {
        margin: 2.5px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding: 6px 12px;
        line-height: 1;
        font-size: 12px;
        cursor: pointer;
        &.active {
            color: #ffffff;
            background-color: #1890ff;
            border-color: #1890ff;
        }
    }
}
</style>