<template>
  <div class="app-container">   
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="120px"> 
      <el-button icon="el-icon-question" style="font-weight:bolder;border: 0;">群组</el-button>
      <el-form-item label="所属资源空间" prop="space_id">
        <el-select v-model="queryParams.space_id" placeholder="所属资源空间" clearable size="small" @change="spaceChange">
          <el-option
            v-for="dict in spaceList"
            :key="dict.Id"
            :label="dict.space_name"
            :value="dict.Id"
          />
        </el-select>
      </el-form-item>      
    </el-form>          
    <el-row/>
    <el-col :span="24" class="card-box">
        <el-card style="height: 800px;">    
          <el-col :span="6" style="display:inline-block;width:200px;height: 100%;">  
            <div>                       
              <div slot="header" style="display:inline-block;width:100%;" >              
                <div style="display:inline-block;float:left;margin-top:15px; width:200px;" >
                  群组操作
                  <el-button                                 
                    icon="el-icon-plus"
                    size="mini"
                    @click="groupHandleAddBase"
                  >添加根群组</el-button>
                </div> 
                <div style="margin-top:10px; float:right;width:180px;"> 
                  <el-button                                 
                    icon="el-icon-plus"
                    size="mini"
                    @click="groupHandleAdd"
                  ></el-button>
                   <el-button                                
                    icon="el-icon-delete"
                    size="mini"
                    @click="groupHandleDel"
                  ></el-button>
                  <el-button                                
                    icon="el-icon-refresh"
                    size="mini"
                    @click="groupHandleRefresh"
                  ></el-button>
                </div>
              </div>            
            </div>
            <vxe-table
              ref="groupTable"
              height="720"           
              border
              width="100%"
              show-overflow
              highlight-hover-row
              row-key
              highlight-current-row
              row-id="id"
              :loading="grouploading"
              :row-config="{isCurrent: true, isHover: true}"
              :tree-config="{children: 'children', line: true,reserve:true,
                          expandRowKeys: defaultExpandKeys,
                          iconOpen: 'fa fa-minus-square-o', iconClose: 'fa fa-plus-square-o'}"
              :show-header="false"
              :column-config="{resizable: false}"
              :checkbox-config="{labelField: 'group_name', highlight: true}"
              :data="groupList"
              @current-change="groupRow"
              @checkbox-change="selectChangeEvent">
              <vxe-table-column type="checkbox" tree-node></vxe-table-column>
            </vxe-table>
          </el-col > 
          <el-col :span="18" > 
            <div style="display:inline-block;margin-left:20px;margin-top:5px;height:100px;width:100%;background-color:rgb(248,248,249);">
            <div style="font-size:14px;display:inline-block;margin-top:20px;margin-left:10px;" >
              <div style="display:inline-block;color:#859094;margin-left:10px;" >
                群组名称:
              </div>
              <div style="display:inline-block;width:100px;" >
                {{chooserow.group_name}}
              </div>
              <div style="display:inline-block;color:#859094;margin-left:10px;" >
                群组ID:
              </div>
              <div style="display:inline-block;width:60px;" >
                {{chooserow.group_key}}
              </div>              
            </div>
            <div style="font-size:14px;display:inline-block;margin-top:20px;margin-left:10px;width:100%;" >
              <div style="display:inline-block;color:#859094;margin-left:10px;" >
                描述:
              </div>
              <div style="display:inline-block;" >
                {{chooserow.remark}}
              </div>
              <div style=";font-size:14px;display:inline-block;margin-top:15px;margin-right:20px;float:right;" >
              <el-button
                  type="text"
                  icon="el-icon-edit"
                  size="mini"
                  @click="updateGroup"
                >修改群组</el-button>              
            </div >
            </div>
            
            </div>
          
          <el-row style="display:inline-block;margin-left:20px;margin-top:10px;height:300px;width:100%;">
            <el-form :model="devicequeryParams" ref="devicequeryParamsForm" :inline="true" label-width="100px">
              <el-form-item label="所属产品" prop="product_id">
                <el-select v-model="devicequeryParams.product_id" placeholder="所属产品" clearable size="small">
                  <el-option
                    v-for="dict in productList"
                    :key="dict.id"
                    :label="dict.name"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="设备名称" prop="device_name">
                <el-input
                  v-model="devicequeryParams.device_name"
                  placeholder="请输入设备名称"
                  clearable
                  size="small"
                  @keyup.enter.native="handleQuery"
                />
              </el-form-item>  
              <el-form-item label="设备标识码" prop="device_check">
                <el-input
                  v-model="devicequeryParams.device_check"
                  placeholder="请输入设备标识码"
                  clearable
                  size="small"
                  @keyup.enter.native="handleQuery"
                />
              </el-form-item>      
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
              </el-form-item>
            </el-form>
            <vxe-grid
                    
                    ref="deviceTable"                    
                    stripe
                    width="100%"
                    show-overflow
                    highlight-hover-row
                    :loading="loading"
                    :toolbar-config="tableToolbar"
                    :columns="tableColumn"
                    :data="deviceList"
                    :column-config="{resizable: false}"
                    @checkbox-change="checkboxChangeEvent"
                    @checkbox-all="checkboxChangeEvent">
              <template #toolbar_buttons>
                <el-button
                  :disabled="chooserow.id > 0?false:true"
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"                  
                  @click="bindingHandleAdd"
                >绑定</el-button>                
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :disabled="multiple"
                  @click="bindingHandleDelete"
                >解绑</el-button>
              </template>              
              <!--默认操作按钮-->
              <template #defaultopr="{ row }">                
                <el-button size="mini" type="text" icon="el-icon-view">
                  <router-link :to="'/iot/device/info/'+row.id" class="link-type" style="align-items: center;">
                    <span>查看</span>
                  </router-link>
                </el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="bindingHandleDelete(row)"
                >解绑
                </el-button>
              </template>

              <!--自定义空数据模板-->
              <template #empty>
                    <span >
                      <p>暂无数据</p>
                    </span>
              </template>

            </vxe-grid>
            <vxe-pager
                    background
                    size="small"
                    :loading="loading"
                    :current-page="devicequeryParams.pageNum"
                    :page-size="devicequeryParams.pageSize"
                    :total="total"
                    @page-change="handleSizeChange">
            </vxe-pager>          
          </el-row>
          </el-col > 
          
      </el-card>
    </el-col >
    <!-- 群组 -->
    <el-dialog :close-on-click-modal="false" :title="grouptitle" :visible.sync="groupopen" width="700px" append-to-body>
      <el-form ref="groupform" :model="groupform" :rules="grouprules" label-width="80px">  
        <el-form-item label="群组名称" prop="group_name" :required=true>
          <el-input v-model="groupform.group_name" placeholder="请输入名称" />
        </el-form-item> 
        <el-form-item label="群组描述" prop="remark">
          <el-input v-model="groupform.remark" type="textarea" placeholder="请输入描述信息" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="groupsubmitForm">确 定</el-button>
        <el-button @click="groupcancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 绑定设备 -->
    <el-dialog :close-on-click-modal="false" :title="bindingtitle" :visible.sync="bindingopen" width="800px" append-to-body>
      <el-form ref="bindingform" :model="bindingform" label-width="80px">  
        <el-form :model="bindingqueryParams" ref="bindingqueryForm" :inline="true" label-width="100px">
          <el-form-item label="所属产品" prop="product_id">
            <el-select v-model="bindingqueryParams.product_id" placeholder="所属产品" clearable size="small">
              <el-option
                v-for="dict in productList"
                :key="dict.id"
                :label="dict.name"
                :value="dict.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" prop="device_name">
            <el-input
              v-model="bindingqueryParams.device_name"
              placeholder="请输入设备名称"
              clearable
              size="small"
              @keyup.enter.native="bindinghandleQuery"
            />
          </el-form-item>  
          <el-form-item label="设备标识码" prop="device_check">
            <el-input
              v-model="bindingqueryParams.device_check"
              placeholder="请输入设备标识码"
              clearable
              size="small"
              @keyup.enter.native="bindinghandleQuery"
            />
          </el-form-item>      
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="bindinghandleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="bindingresetQuery">重置</el-button>
          </el-form-item>
        </el-form>
        <el-row/>
        <el-row style="display:inline-blockmargin-top:10px;height:300px;width:100%;">
          <vxe-grid
                    
                    ref="bindingdeviceTable"                    
                    stripe
                    width="100%"
                    show-overflow
                    highlight-hover-row
                    :loading="bindingloading"
                    :columns="bindingtableColumn"
                    :data="bindingdeviceList"
                    :column-config="{resizable: false}"
                    @checkbox-change="bindingcheckboxChangeEvent"
                    @checkbox-all="bindingcheckboxChangeEvent">
              <!--自定义空数据模板-->
              <template #empty>
                    <span >
                      <p>暂无数据</p>
                    </span>
              </template>

            </vxe-grid>
            <vxe-pager
                    background
                    size="small"
                    :loading="loading"
                    :current-page="bindingqueryParams.pageNum"
                    :page-size="bindingqueryParams.pageSize"
                    :total="total"
                    @page-change="bindinghandleSizeChange">
            </vxe-pager>
        </el-row>        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bindingsubmitForm">确 定</el-button>
        <el-button @click="bindingcancel" >取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { bindIotDeviceGroup,unbindIotDeviceGroup,addIotDeviceGroup, getIotDeviceGroup, updateIotDeviceGroup, delIotDeviceGroup, listIotDeviceGroup } from "@/api/iot/devicegroup";
import { listIotCase, getIotCase, addIotCase, updateIotCase, delIotCase } from "@/api/iot/case";
import { listIotSpace, getIotSpace, addIotSpace, updateIotSpace, delIotSpace } from "@/api/iot/space";
import { listIotProduct, getIotProduct, addIotProduct, updateIotProduct, delIotProduct } from "@/api/iot/product";
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";
export default {  
  name: "DeviceGroup",
  data() {
    return{
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      treeConfig:{
          "trigger":"row",
        },
      queryParams: {
        pageNum: 1,
        pageSize: 10,        
        space_id: undefined,
        is_system:2
      },
      bindingqueryParams:{
        pageNum: 1,
        pageSize: 10,        
        product_id: undefined,
        space_id: undefined,
        group_id:undefined,
        device_name:"",
        device_check:""
      },
      casequeryParams: {
        pageNum: 1,
        pageSize: 10,
        node_id: undefined
      },
      spacequeryParams: {
        pageNum: 1,
        pageSize: 10,        
        case_id: undefined        
      },
      devicequeryParams:{
        pageNum: 1,
        pageSize: 10,
        product_id: undefined,
        space_id: undefined,
        group_id:undefined,
        device_name:"",
        device_check:""
      },
      loading:false,
      grouploading:false,
      bindingloading:false,
      space_id:0,
      spaceList:[],
      productList:[],
      groupList:[],
      deviceList:[],
      bindingdeviceList:[],
      groupopen:false,
      grouptitle:"添加群组",
      groupform:{},
      chooserow:{},
      bindingform:{},
      bindingopen:false,
      bindingtitle:"",
      form:{},
      ids: [],
      groupids:[],
      groupname:[],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      bindingids: [],
      // 非单个禁用
      bindingsingle: true,
      // 非多个禁用
      bindingmultiple: true,
      // 总条数
      bindingtotal: 0,
      //显示绑定设备
      tableColumn:[
        {type: 'checkbox', width: 30, fixed: "left"},
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'device_name', title: '设备名称' },
        {field: 'device_check', title: '设备标识码' },        
        {field: 'product_id', title: '所属产品' ,formatter:this.ProductFormat},
        {field: 'device_type', title: '设备类型' },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}],
      grouprules:{
        group_name: [
          { required: true, message: "名称不能为空！", trigger: "blur" }
        ]
      },
      bindingtableColumn:[
        {type: 'checkbox', width: 30, fixed: "left"},
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'device_name', title: '设备名称'},
        {field: 'device_check', title: '设备标识码' },        
        {field: 'product_id', title: '所属产品' ,formatter:this.ProductFormat}],
      defaultExpandKeys: [0],
      isbase:1
    }
  },
  created() {
    this.getCaseList();
  },
  methods: {
    getCaseList(){      
      listIotCase(this.casequeryParams).then(response =>{
          for (var index=0;index<response.data.list.length;index++){
            if (response.data.list[index].is_choose >= 1){
              this.spacequeryParams.case_id=response.data.list[index].Id;              
            }              
          }   
          this.getSpaceList(); 
        })
    },
    getSpaceList() {      
      listIotSpace(this.spacequeryParams).then(response => {        
        this.spaceList = response.data.list;
        if(this.spaceList.length>0){
          this.queryParams.space_id=this.spaceList[0].Id;
          this.getGroupList(); 
          this.getProductList();
        }
        
      }).catch(function (error){
        console.log(error);
      });
    },
    getProductList(){      
      listIotProduct(this.queryParams).then(response => {        
        this.productList = response.data.list;          
        }).catch(function (error){
          console.log(error);
        });
    },
    getGroupList(){
      this.grouploading = true;
      listIotDeviceGroup(this.queryParams).then(response => {
        //this.groupList = response.data.list; 
        response.data.list.filter(element => {
          if (element.father_id > 0){
              let index =response.data.list.findIndex(item =>item.id==element.father_id);
              if(index>=0){                
                if(response.data.list[index].children==undefined){
                  var arr=[];
                  arr.push(element);
                  this.$set(response.data.list[index],'children',arr);
                }else{
                  response.data.list[index].children.push(element);
                }                
              }              
            }            
          }); 
        var timeindex = 0;
        for(let i=0;i<response.data.list.length;i++){
          if(response.data.list[i].father_id>0){
            timeindex++;
          }
        }
        for(var j=0;j<timeindex;j++){
          for(let i=0;i<response.data.list.length;i++){
            if(response.data.list[i].father_id>0){
              response.data.list.splice(i,1);
            }
          }
        }
        this.groupList=response.data.list; 
        if(this.groupList.length>0)
          this.defaultExpandKeys[0]=this.groupList[0].id;
        this.grouploading = false;
      }).catch(function (error){
        console.log(error);
      });
    },
    //
    getDindingDeviceList(){
      this.bindingloading=true;
      if(this.queryParams.space_id>0)
        this.bindingqueryParams.space_id=this.queryParams.space_id;
      this.bindingqueryParams.group_id=0;
      listIotDevice(this.bindingqueryParams).then(response=>{
          this.bindingdeviceList=response.data.list;
          this.bindingloading=false;
          this.bindingtotal=response.data.total;
        });
    },
    getDeviceList(){
      this.loading=true;
      if(this.queryParams.space_id>0)
        this.devicequeryParams.space_id=this.queryParams.space_id;
      if(this.chooserow.id>0){
        this.devicequeryParams.group_id=this.chooserow.id;
        listIotDevice(this.devicequeryParams).then(response=>{
          this.deviceList=response.data.list;
          this.loading=false;
          this.total=response.data.total;
        });
      }else{
        this.loading=false;
      }    
    },
    //选择资源空间发生变化
    spaceChange(){
      this.getGroupList(); 
      this.getProductList();
    },
    //修改
    updateGroup(){
      if(this.chooserow.id!=undefined){
        getIotDeviceGroup(this.chooserow.id).then(response=>{
          this.groupform=response.data;
          this.groupopen = true;
          this.grouptitle="修改群组";
        });       
        
      }
    },
    delGroup(){
      
    },
    //添加群组
    groupHandleAdd(){
      if(this.queryParams.space_id>0){
        this.groupreset();
        this.groupopen = true; 
        this.isbase=0;
        if(this.chooserow.id!=undefined){
          this.grouptitle="为群组名为"+this.chooserow.group_name+"添加子群组"; 
        }else{
          this.grouptitle="添加根群组"; 
        }
      }   
    },
    //添加根群组
    groupHandleAddBase(){
      if(this.queryParams.space_id>0){
        this.groupreset();
        this.groupopen = true; 
        this.isbase=1;
        this.grouptitle="添加根群组";
      }   
    },
    groupHandleDel(){
      var ids=(this.groupids ? this.groupids.join(',') : '');
      if (ids!=''){
        this.$confirm('是否确认删除群组名为"' + this.groupname + '"的群组，并解绑所有已绑定的设备?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            delIotDeviceGroup(ids).then(response=>{
              if(response.data.count>0){
                this.getGroupList();
                this.msgSuccess("解绑设备成功"); 
              }
            });
                    
          }).catch(function() {}); 
      }else{
        this.msgError("请勾选要删除的群组");
      }
    },
    groupHandleRefresh(){
      this.getGroupList();
      this.msgSuccess("刷新成功！");
    },
    groupreset() {
      this.groupform = {
        id: undefined,
        group_name: "",
        remark: ""
      };      
    },
    groupcancel(){
      this.groupopen = false;
      this.groupreset();  
      this.getGroupList();      
    },
    groupsubmitForm() {
      this.$refs["groupform"].validate(valid => {
        if (valid) {
          if (this.groupform.id != undefined) {            
            updateIotDeviceGroup(this.groupform).then(response => {
              if (response.code ===0) {
                this.chooserow=this.groupform;
                this.msgSuccess("修改成功");
                this.groupopen = false;
                this.getGroupList();                
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            this.groupform.space_id=this.queryParams.space_id;
            if(this.chooserow.id!=undefined &&this.isbase==0)
              this.groupform.father_id=this.chooserow.id;
            else
              this.groupform.father_id=0;
            this.groupform.is_system=2;
            addIotDeviceGroup(this.groupform).then(response=>{
              if (response.code ===0) {
                this.msgSuccess("新增成功");
                this.groupopen = false;
                this.getGroupList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    groupRow({row}){
      //this.msgSuccess(row.id);
      this.chooserow=row;   
      this.getDeviceList(); 
    },
    ProductFormat({row}){
      var label=""
      this.productList.forEach(element => {
        if (element.id === row.product_id)
          label=element.name;
      });
      return label
    },
    //绑定设备按钮
    bindingHandleAdd(){
      this.getDindingDeviceList();
      this.bindingopen=true;
      this.bindingtitle="绑定设备";
    },
    //绑定设备--确定提交
    bindingsubmitForm(){      
      if(this.chooserow.id>0){        
        bindIotDeviceGroup(this.chooserow.id,this.bindingids.join(",")).then(response=>{
          
            if(response.data.count>0){
              this.msgSuccess("绑定设备成功");
              this.getDeviceList();
            }else{
              this.msgError("绑定设备失败");
            }
            this.bindingopen=false;
        });
      }      
    },
    bindingHandleUpdate(row){},
    //解绑
    bindingHandleDelete(row){
      const ids = row.id || (this.ids ? this.ids.join(',') : '');       
      this.$confirm('是否确认解绑编号为"' + ids + '"的设备?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {   
          //this.msgSuccess("解绑设备成功11");       
          //return            
        }).then(() => {
          unbindIotDeviceGroup(this.chooserow.id,ids).then(response=>{
            if(response.data.count>0){
              this.getDeviceList();
              this.msgSuccess("解绑设备成功");
            }
          });          
        }).catch(function() {}); 
    },
    // 取消按钮
    bindingcancel() {
      this.bindingopen = false;
      this.bindingreset();
    },
    // 表单重置
    bindingreset() {
      this.bindingform = {
        id: undefined,         
      };
      this.resetForm("bindingform");
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        Id: undefined,         
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getDeviceList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },
    selectChangeEvent({ records }) {
      this.groupids = records.map(item => item.id);  
      this.groupname = records.map(item => item.group_name);  
    },
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getDeviceList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },    
    bindinghandleQuery() {
      this.bindingqueryParams.pageNum = 1;
      this.getDindingDeviceList();
    },
    /** 重置按钮操作 */
    bindingresetQuery() {
      this.resetForm("bindingqueryForm");
      this.bindinghandleQuery();
    },
    bindinghandleSizeChange ({ currentPage, pageSize }) {
      this.devicequeryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getDindingDeviceList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.bindinghandleQuery();
      }

    },
    bindingcheckboxChangeEvent ({ records }) {
      this.bindingids = records.map(item => item.id)
      this.bindingsingle = records .length!=1;
      this.bindingmultiple = !records .length

    },
  }
}
</script>
