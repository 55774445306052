<template>
  <div style="position: relative; width: 100%; height: 100%; background-color: rgb(10, 48, 87); display: flex; flex-direction: column;">
    <div style="height: 60px; display: flex; justify-content: center; align-items: center; color: rgb(45, 200, 250); font-size: 20px; font-weight: bold;">{{title}}</div>
    <div ref="container" style="flex: 1; display: flex; flex-direction: column; align-items: center;" class="bi-zzbzcy">
      <el-table v-if="height"
        :data="tableData"
        v-loading="loading"
        element-loading-text="正在加载"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        style="width: 100%; background-color: rgb(10, 48, 87)"
        :header-cell-style="{backgroundColor: 'rgb(19, 93, 166)', color: 'rgb(45, 200, 250)', fontSize: '16px', fontWeight: 'bold', borderColor: 'rgb(45, 200, 250)'}"
        :height="height"
        :stripe="false"
      >
        <el-table-column
          prop="党组织名称"
          label="党组织名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="姓名"
          label="姓名"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="职务"
          label="职务"
          align="center"
          >
        </el-table-column>
        <el-table-column
          prop="性别"
          label="性别"
          align="center"
          >
        </el-table-column>
        <el-table-column
          prop="出生年月"
          label="出生年月"
          align="center"
          >
        </el-table-column>
        <div slot="empty"><div v-if="!loading" style="color: rgb(45, 200, 250)">暂无数据</div></div>
      </el-table>
    </div>
    <div class="close-button" @click="handleClose">关闭</div>
  </div>
</template>

<script>
import { execModel } from "@/api/core/data/datasource/model";
import Qs from 'qs'

export default {
  name: 'zzbzcy',
  props: {
    params: {
      type: String,
      default: () => { return undefined }
    },
  },
  data() {
    const params = Qs.parse(this.params || '')
    let title = '组织班子成员'
    return {
      type: params.type,
      title: title,
      tableData: [],
      loading: false,
      height: 0,
    }
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize()
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  created() {
    this.loading = true
    let id = 165
    const params = Qs.parse(this.params || '')
    params['__max_count__'] = 1000
    execModel(id, JSON.stringify(params)).then(response => {
      console.log(response)
      this.tableData = response.data
      this.loading = false
    }).catch(e => {
      this.loading = false
    })
  },
  methods: {
    onWindowResize() {
      this.height = this.$refs.container.offsetHeight
    },
    handleClose() {
      window.postMessage({
        type: 'bi_dialog_close'
      })
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute; 
  right: 10px; 
  top: 10px; 
  color: rgb(45, 200, 250);
}
.close-button:hover {
  cursor: pointer;
}
::v-deep .el-table tr {
    background-color: rgb(10, 48, 87);
    color: rgb(45, 200, 250);
    font-size: 16px;
    border: none;
}
::v-deep .el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
}
::v-deep .el-table--enable-row-hover .el-table__body tr > td {
  border: none;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(255, 255, 255, 0.01) !important;
}
::v-deep .bi-zzbzcy .el-table__body-wrapper::-webkit-scrollbar {
  width:8px;
  background-color: rgba(10, 48, 87);
}
::v-deep .bi-zzbzcy .el-table__body-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:8px;
}
::v-deep .bi-zzbzcy .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius:8px;
  background:rgba(255,255,255,0.1);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(255,255,255,0.1);
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background:rgba(255,255,255,0.2);
}
::v-deep .el-table__header .has-gutter th.gutter {
  background-color: rgb(19, 93, 166);
  border-bottom: solid 1px rgb(45, 200, 250);
}
</style>