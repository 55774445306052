import request from '@/utils/request'
//表单数据列表
export function dataList(query) {

    var data = {
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
        page_size: query.pageSize,
        page: query.pageNum
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr") {
            filterstr[k] = item;
        }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);

    return request({
        url: '/growthindicators/gyxy/achievementsubtotal/datalist',
        method: 'post',
        data: data
    })
}

//字段信息
export function datacolList() {
    return request({
        url: '/growthindicators/gyxy/achievementsubtotal/tableinfo',
        method: 'post',
    })


}

//生成成绩单
export function Create(type, turpid, turid) {
    return request({
        url: '/growthindicators/gyxy/achievementsubtotal/create',
        method: 'post',
        data: {type: type, turpid: turpid, turid: turid}
    })

}
//查看单个学生成绩明细
export function Viewstu(stuno, turid) {
    return request({
        url: '/growthindicators/gyxy/achievementsubtotal/viewstu',
        method: 'post',
        data: {stuno: stuno, turid: turid}
    })

}
