<template>
  <div>
    <el-tabs
      v-model="activeTab"
      type="card"
      :editable="design"
      @edit="handleTabsEdit"
    >
      <el-tab-pane
        v-for="(item, index) in config.__design__"
        :key="index"
        :label="item.display_name"
        :name="item.name"
      >
        <el-form
          :model="activeForm"
          ref="activeForm"
          label-width="120px"
          class="demo-dynamic"
          size="medium"
        >
          <el-form-item
            v-for="(item, index) in activeForm.items"
            :label="item.display_name"
            :key="item.name"
            :prop="'items.' + index + '.value'"
            :rules="{
              required: item.required,
              message: item.display_name + '不能为空',
              trigger: 'blur',
            }"
          >
            <div class="item-container">
              <div class="item-left">
                <el-input v-if="item.type == 'text'" v-model="item.value" :placeholder="'请输入' + item.display_name" @change="handleConfigChange"></el-input>
                <el-input v-else-if="item.type == 'textarea'" type='textarea' :rows="item.num || 3" v-model="item.value" :placeholder="'请输入' + item.display_name" @change="handleConfigChange"></el-input>
                <el-input v-if="item.type == 'password'" v-model="item.value" :placeholder="'请输入' + item.display_name" @change="handleConfigChange"></el-input>
                <el-input-number v-else-if="item.type == 'number'" v-model="item.value" controls-position="right" :placeholder="'请输入' + item.display_name" @change="handleConfigChange"></el-input-number>
                <el-switch v-else-if="item.type == 'switch'" v-model="item.value" :placeholder="'请选择' + item.display_name" @change="handleConfigChange"></el-switch>
                <dict-select v-if="item.type == 'select' || item.type == 'selectm'" v-model="item.value" :type="item.dict" :multiple="item.type == 'selectm'" :placeholder="'请选择' + item.display_name" @change="handleConfigChange" />
                <dict-radio v-else-if="item.type == 'radio'" v-model="item.value" :type="item.dict" :placeholder="'请选择' + item.display_name" @change="handleConfigChange" />
                <dict-checkbox v-else-if="item.type == 'checkbox'" v-model="item.value" :type="item.dict" :placeholder="'请选择' + item.display_name" @change="handleConfigChange" />
                <datetime-select v-else-if="item.type == 'date'" v-model="item.value" type="date" :placeholder="'请选择' + item.display_name" @change="handleConfigChange" />
                <datetime-select v-else-if="item.type == 'datetime'" v-model="item.value" type="datetime" :placeholder="'请选择' + item.display_name" @change="handleConfigChange" />
                <file-upload-ex v-else-if="item.type == 'file'" v-model="item.value" list-type="text" :multiple="false" :limit="1" @change="handleConfigChange" />
                <file-upload-ex v-else-if="item.type == 'file-multiple'" v-model="item.value" list-type="text" :multiple="false" @change="handleConfigChange" />
                <image-upload v-else-if="item.type == 'image'" v-model="item.value" :multiple="false" :limit="1" @change="handleConfigChange" />
                <image-upload v-else-if="item.type == 'image-multiple'" v-model="item.value" :multiple="false" @change="handleConfigChange" />
              </div>
              <div v-if="design" class="item-right">
                <el-button type="text" @click.prevent="editItem(item)">编辑</el-button>
                <el-button type="text" @click.prevent="removeItem(item)">删除</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="design">
            <el-button type="primary" icon="el-icon-plus" @click="addItem">配置项</el-button>
            <el-button style="float: right" @click="editGroup">编辑分组</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="分组"
      :close-on-click-modal="false"
      :visible.sync="groupDialogVisible"
      append-to-body
      width="500px"
    >
      <el-form
        ref="formGroup"
        label-position="left"
        label-width="80px"
        size="medium"
        :model="formGroup"
      >
        <el-form-item
          label="名称"
          prop="name"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <el-input :disabled="formGroup.name == 'default'" v-model="formGroup.name"></el-input>
        </el-form-item>
        <el-form-item
          label="显示名称"
          prop="display_name"
          :rules="[
            { required: true, message: '请输入显示名称', trigger: 'blur' },
          ]"
        >
          <el-input v-model="formGroup.display_name"></el-input>
        </el-form-item>
        <el-form-item
          label="作用域"
          prop="scope"
          :rules="[{ required: true, message: '请选择作用域', trigger: 'blur' }]"
        >
          <el-select v-model="formGroup.scope" placeholder="请选择">
            <el-option label="全部" value="all"></el-option>
            <el-option label="仅后台" value="backend"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="登录验证"
          prop="auth"
          :rules="[
            { required: true, message: '请选择是否登录验证', trigger: 'blur' },
          ]"
        >
          <el-switch v-model="formGroup.auth"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="groupDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitGroup">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="配置项"
      :close-on-click-modal="false"
      :visible.sync="itemDialogVisible"
      append-to-body
      width="500px"
    >
      <el-form
        ref="formItem"
        label-position="left"
        label-width="80px"
        size="medium"
        :model="formItem"
      >
        <el-form-item
          label="名称"
          prop="name"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <el-input v-model="formItem.name"></el-input>
        </el-form-item>
        <el-form-item
          label="显示名称"
          prop="display_name"
          :rules="[
            { required: true, message: '请输入显示名称', trigger: 'blur' },
          ]"
        >
          <el-input v-model="formItem.display_name"></el-input>
        </el-form-item>
        <el-form-item
          label="类型"
          prop="type"
          :rules="[{ required: true, message: '请选择类型', trigger: 'blur' }]"
        >
          <el-select v-model="formItem.type" placeholder="请选择" :disabled="formItem.type == 'password' && formItem.value != undefined && formItem.value != ''">
            <el-option label="文本(单行)" value="text"></el-option>
            <el-option label="文本(多行)" value="textarea"></el-option>
            <el-option label="密码" value="password"></el-option>
            <el-option label="数字" value="number"></el-option>
            <el-option label="开关" value="switch"></el-option>
            <el-option label="下拉框" value="select"></el-option>
            <el-option label="单选框" value="radio"></el-option>
            <el-option label="多选框" value="checkbox"></el-option>
            <el-option label="文件(单个)" value="file"></el-option>
            <el-option label="文件(多个)" value="file-multiple"></el-option>
            <el-option label="图片(单张)" value="image"></el-option>
            <el-option label="图片(多张)" value="image-multiple"></el-option>
            <el-option label="日期" value="date"></el-option>
            <el-option label="日期时间" value="datetime"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="formItem.type == 'textarea'"
          label="行数"
          prop="num"
          :rules="[
            { required: true, message: '请输入行数', trigger: 'blur' },
          ]"
        >
          <el-input-number controls-position="right" v-model="formItem.num" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item v-else-if="formItem.type == 'select' || formItem.type == 'checkbox' || formItem.type == 'radio'"
          label="字典"
          prop="dict"
          :rules="[
            { required: true, message: '请输入字典', trigger: 'blur' },
          ]"
        >
          <el-input v-model="formItem.dict"></el-input>
        </el-form-item>
        <el-form-item
          label="排序"
          prop="sort"
          :rules="[
            { required: true, message: '请输入排序', trigger: 'blur' },
          ]"
        >
          <el-input-number controls-position="right" v-model="formItem.sort"></el-input-number>
        </el-form-item>
        <!--<el-form-item
          label="必填"
          prop="required"
          :rules="[
            { required: true, message: '请选择是否必填', trigger: 'blur' },
          ]"
        >
          <el-switch v-model="formItem.required"></el-switch>
        </el-form-item>-->
        <el-form-item
          label="作用域"
          prop="scope"
          :rules="[{ required: true, message: '请选择作用域', trigger: 'blur' }]"
        >
          <el-select v-model="formItem.scope" placeholder="请选择">
            <el-option label="全部" value="all"></el-option>
            <el-option label="仅后台" value="backend"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="登录验证"
          prop="auth"
          :rules="[
            { required: true, message: '请选择是否登录验证', trigger: 'blur' },
          ]"
        >
          <el-switch v-model="formItem.auth"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="itemDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitItem">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "config-group",
  props: {
    value: {
      type: Object,
      default: undefined
    },
    design: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let config = undefined
    if (this.value) {
      config = this.value
    } else {
      config = {
        "__design__": [
          {
            name: 'default',
            display_name: '默认',
            scope: 'all',
            auth: false,
            items: []
          }
        ]
      }
    }
    return {
      config: config,
      activeTab: "default",
      groupDialogVisible: false,
      formGroup: {
        name: "",
        display_name: "",
        scope: 'all',
        auth: true
      },
      itemDialogVisible: false,
      formItem: {
        name: "",
        display_name: "",
        type: '',
        value: undefined,
        value_type: '',
        dict: '',
        num: 3,
        required: 0,
        sort: 0,
        scope: 'all',
        auth: true
      },
    };
  },
  methods: {
    handleTabsEdit(targetName, action) {
      if (action === "add") {
        this.editingGroup = undefined
        this.formGroup = {
          name: "",
          display_name: "",
          scope: 'all',
          auth: true
        };
        this.resetForm("formGroup");
        this.groupDialogVisible = true;
      }
      if (action === "remove") {
        if (targetName == "default") {
          this.msgError("不能删除默认设置");
          return;
        }
        let display_name = "";
        for (let i = 0; i < this.config.__design__.length; i++) {
          const d = this.config.__design__[i];
          if (d.name == targetName) {
            display_name = d.display_name;
            break;
          }
        }
        if (!display_name) {
          return;
        }
        this.$confirm("确定删除" + display_name + "吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let tabs = this.config.__design__;
          let activeName = this.activeTab;
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                  activeName = nextTab.name;
                }
              }
            });
          }

          this.config.__design__ = tabs.filter((tab) => tab.name !== targetName);
          this.handleConfigChange()
          this.activeTab = activeName;
        });
      }
    },
    editGroup() {
      this.editingGroup = this.activeForm
      this.formGroup = {
        ...this.editingGroup
      }
      this.groupDialogVisible = true;
    },
    submitGroup() {
      this.$refs["formGroup"].validate((valid) => {
        if (valid) {
          if (this.formGroup.name == '__design__') {
            this.msgError("非法名称，请重新输入");
              return;
          }
          for (let i = 0; i < this.config.__design__.length; i++) {
            const d = this.config.__design__[i];
            if (d != this.editingGroup && d.name == this.formGroup.name) {
              this.msgError("名称冲突，请重新输入");
              return;
            }
            if (d != this.editingGroup && d.display_name == this.formGroup.display_name) {
              this.msgError("显示名称冲突，请重新输入");
              return;
            }
          }
          if (this.editingGroup) {
            this.editingGroup.name = this.formGroup.name
            this.editingGroup.display_name = this.formGroup.display_name
            this.editingGroup.scope = this.formGroup.scope
            this.editingGroup.auth = this.formGroup.auth
          } else {
            this.config.__design__.push({
              ...this.formGroup,
              items: []
            });
          }
          this.groupDialogVisible = false;
          this.handleConfigChange()
          this.activeTab = this.formGroup.name;
        }
      });
    },
    removeItem(item) {
      this.$confirm("确定删除" + item.display_name + "吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var index = this.activeForm.items.indexOf(item)
        if (index !== -1) {
          this.activeForm.items.splice(index, 1)
          this.handleConfigChange()
        }
      })
    },
    editItem(item) {
      this.editingItem = item
      this.formItem = {
        ...item
      }
      this.itemDialogVisible = true
    },
    addItem() {
      this.editingItem = undefined
      this.formItem = {
        name: "",
        display_name: "",
        type: '',
        value: undefined,
        value_type: '',
        dict: '',
        num: 3,
        required: false,
        sort: 0,
        scope: 'all',
        auth: true
      };
      this.resetForm("formItem");
      this.itemDialogVisible = true
    },
    submitItem() {
      this.$refs["formItem"].validate((valid) => {
        if (valid) {
          for (let i = 0; i < this.activeForm.items.length; i++) {
            const d = this.activeForm.items[i];
            if (d != this.editingItem && d.name == this.formItem.name) {
              this.msgError("名称冲突，请重新输入");
              return;
            }
            if (d != this.editingItem && d.display_name == this.formItem.display_name) {
              this.msgError("显示名称冲突，请重新输入");
              return;
            }
          }
          if (this.editingItem) {
            for (let key in this.formItem) {
              this.editingItem[key] = this.formItem[key]
            }
          } else {
            this.activeForm.items.push({
              ...this.formItem
            });
          }
          this.activeForm.items.sort((a, b) => {
            return a.sort - b.sort
          })
          this.itemDialogVisible = false;
          this.handleConfigChange()
        }
      });
    },
    handleConfigChange() {
      const value = {
        '__design__': []
      }
      for (let i = 0; i < this.config.__design__.length; i++) {
        const d = this.config.__design__[i]
        const group = {
          ...d,
          items: []
        }
        const gv = {}
        for (let j = 0; j < d.items.length; j++) {
          const gd = d.items[j]
          group.items.push({
            ...gd
          })
          gv[gd.name] = gd.value
        }
        value[d.name] = gv
        value.__design__.push(group)
      }
      this.$emit('input', value)
      this.$emit('change', value)
    }
  },
  computed: {
    activeForm() {
      for (let i = 0; i < this.config.__design__.length; i++) {
        const d = this.config.__design__[i]
        if (d.name == this.activeTab) {
          return d
        }
      }
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.config = this.value
      } else {
        this.config = {
          "__design__": [
            {
              name: 'default',
              display_name: '默认',
              scope: 'all',
              auth: false,
              items: []
            }
          ]
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .item-container {
    width: 100%;
    display: flex;
    .item-left {
      flex: 1
    }
    .item-right {
      margin-left: 10px;
    }
  }
</style>