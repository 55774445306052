<template>
  <div class="p-20" id="cache-list">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card class="box-card" :style="{ height: listHeight + 'px' }">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-s-grid mr-5"></i> 缓存列表</span>
            <el-input
              v-model="cacheMatch"
              style="width: 200px; margin-left: 10px"
              placeholder="请输入关键字"
              clearable
              @change="handleCacheMatchChange"
            />
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              icon="el-icon-refresh-right"
              @click="loadCacheListRefresh"
            ></el-button>
          </div>

          <jf-table
            :loading="loading"
            :columns="cacheTableColumn"
            :data="cacheList"
            :is-query="false"
            :checkbox="false"
            :pager="false"
            :defaultopr-width="80"
            :max-height="listHeight - 50"
            :highlight-current-row="true"
            @current-change="currentChangeCache"
          >
            <template slot="toolbar_btn_row" slot-scope="{ row }">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click.stop="handleDelCache(row)"
                >删除
              </el-button>
            </template>
          </jf-table>
          <el-button-group class="button-group">
            <el-button type="primary"  size="mini" @click="loadCacheListRefresh"
              >首页</el-button
            >
            <el-button
              type="primary"  size="mini"
              @click="loadCacheList"
              :disabled="
                buttonDisabled <= 0 ||
                buttonDisabled == null ||
                buttonDisabled == undefined ||
                buttonDisabled == ''
                  ? true
                  : false
              "
              >下一页<i class="el-icon-arrow-right el-icon--right"></i
            ></el-button>
          </el-button-group>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card" :style="{ height: listHeight + 'px' }">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-key mr-5"></i> 键名列表</span>
            <el-input
              v-model="keyMatch"
              style="width: 200px; margin-left: 10px"
              placeholder="请输入关键字"
              clearable
              @change="handleKeyMatchChange"
            />
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              icon="el-icon-refresh-right"
              @click="toRefresh(2)"
            ></el-button>
          </div>
          <jf-table
            :loading="loading"
            :columns="keyTableColumn"
            :data="keyList"
            :is-query="false"
            :checkbox="false"
            :pager="false"
            :defaultopr-width="80"
            :max-height="listHeight - 50"
            :highlight-current-row="true"
            @current-change="currentChangeKey"
          >
            <template slot="toolbar_btn_row" slot-scope="{ row }">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click.stop="handleDelKey(row)"
                >删除
              </el-button>
            </template>
          </jf-table>
          <el-button-group class="button-group">
            <el-button type="primary" size="mini" @click="toRefresh(2)">首页</el-button>
            <el-button
              type="primary" size="mini"
              @click="onKeyListNext(2)"
              :disabled="
                buttonKeyDisabled <= 0 ||
                buttonKeyDisabled == null ||
                buttonKeyDisabled == undefined ||
                buttonKeyDisabled == ''
                  ? true
                  : false
              "
              >下一页<i class="el-icon-arrow-right el-icon--right"></i
            ></el-button>
          </el-button-group>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card" :style="{ height: listHeight + 'px' }">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-document mr-5"></i>缓存内容</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              icon="el-icon-delete"
              @click="toRefresh(3)"
              >删除</el-button
            >
          </div>
          <el-form :model="cacheForm">
            <el-form-item label="缓存名称">
              <el-input
                placeholder="缓存名称"
                v-model="cacheForm.name"
                readonly
              ></el-input>
            </el-form-item>
            <el-form-item label="缓存键名">
              <el-input
                placeholder="缓存键名"
                v-model="cacheForm.keyName"
                readonly
              ></el-input>
            </el-form-item>
            <el-form-item label="缓存内容">
              <el-input
                placeholder="缓存内容"
                type="textarea"
                :rows="8"
                v-model="cacheForm.content"
                readonly
              ></el-input>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as cache from "@/api/core/monitor/cache";
export default {
  name: "core-monitor-cache-list",
  data() {
    return {
      loading: false,
      listHeight: "",
      cacheMatch: "",
      cacheList: [],
      cacheTableColumn: [
        {
          type: "seq",
          width: 60,
          fixed: "left",
          field: "序号",
        },
        {
          field: "value",
          title: "缓存名称",
          fixed: "left",
        },
        {
          field: "type",
          title: "缓存类型",
          fixed: "left",
          width: 80,
        },
      ],
      keyMatch: "",
      keyList: [],
      keyTableColumn: [
        {
          type: "seq",
          width: 60,
          fixed: "left",
          field: "序号",
        },
        {
          field: "value",
          title: "缓存键名",
          fixed: "left",
        },
      ],
      cacheForm: {
        name: "",
        keyName: "",
        content: "",
      },
      buttonDisabled: 0,
      buttonKeyDisabled: 0,
    };
  },
  mounted() {
    this.getListHeight();
  },
  created() {
    this.loadCacheList();
  },
  methods: {
    getListHeight() {
      console.log(document.querySelector("#cache-list").clientHeight);
      this.listHeight = document.querySelector("#cache-list").clientHeight - 40;
    },
    toRefresh(type) {
      switch (type * 1) {
        case 1:
          this.loadCacheList();
          break;
        case 2:
          if (this.selectedCacheRow && this.selectedCacheRow.type == "hash") {
            this.buttonKeyDisabled = 0;
            this.loadKeyList(this.selectedCacheRow.value);
          }
          break;
        case 3:
          if (this.selectedCacheRow) {
            if (this.selectedCacheRow.type == "hash") {
              if (this.selectedKeyRow) {
                this.hDelCache(
                  this.selectedCacheRow.value,
                  this.selectedKeyRow.value
                ).then(() => {
                  this.loadKeyList(this.selectedCacheRow.value);
                });
              }
            } else {
              this.delCache(this.selectedCacheRow.value).then(() => {
                this.loadCacheList();
              });
            }
          }
          break;
      }
    },
    loadCacheListRefresh() {
      this.buttonDisabled = 0;
      this.loadCacheList();
    },
    loadCacheList() {
      const loading = this.$loading({
        text: "加载中...",
      });
      cache
        .keys(this.buttonDisabled, this.cacheMatch)
        .then((res) => {
          loading.close();
          this.cacheList = res.data.keys;
          this.buttonDisabled = res.data.cursor;
        })
        .catch((e) => {
          loading.close();
        });
      this.keyMatch = "";
      this.keyList = [];
      this.cacheForm = {
        name: "",
        keyName: "",
        content: "",
      };
    },
    //
    onKeyListNext() {
      if (this.selectedCacheRow && this.selectedCacheRow.type == "hash") {
        this.loadKeyList(this.selectedCacheRow.value);
      }
    },
    loadKeyList(key) {
      const loading = this.$loading({
        text: "加载中...",
      });
      cache
        .hkeys(key, this.buttonKeyDisabled, this.keyMatch)
        .then((res) => {
          loading.close();
          this.keyList = res.data.keys;
          this.buttonKeyDisabled = res.data.cursor;
        })
        .catch((e) => {
          loading.close();
        });
      this.cacheForm = {
        name: "",
        keyName: "",
        content: "",
      };
    },
    getCache(key) {
      const loading = this.$loading({
        text: "加载中...",
      });
      cache
        .get(key)
        .then((res) => {
          loading.close();
          this.cacheForm = {
            name: key,
            keyName: "",
            content: res.data.value,
          };
        })
        .catch((e) => {
          loading.close();
        });
    },
    hGetCache(key, field) {
      const loading = this.$loading({
        text: "加载中...",
      });
      cache
        .hget(key, field)
        .then((res) => {
          loading.close();
          this.cacheForm = {
            name: key,
            keyName: field,
            content: res.data.value,
          };
        })
        .catch((e) => {
          loading.close();
        });
    },
    delCache(key) {
      return new Promise((resolve, reject) => {
        this.$confirm("确定删除吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const loading = this.$loading({
              text: "删除中...",
            });
            cache
              .del(key)
              .then((res) => {
                loading.close();
                resolve();
              })
              .catch((e) => {
                loading.close();
                reject();
              });
          })
          .catch((e) => {
            reject();
          });
      });
    },
    hDelCache(key, field) {
      return new Promise((resolve, reject) => {
        this.$confirm("确定删除吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const loading = this.$loading({
              text: "删除中...",
            });
            cache
              .hdel(key, field)
              .then((res) => {
                loading.close();
                resolve();
              })
              .catch((e) => {
                loading.close();
                reject();
              });
          })
          .catch((e) => {
            reject();
          });
      });
    },
    currentChangeCache({ row }) {
      this.selectedCacheRow = row;
      if (row.type == "string") {
        this.keyList = [];
        this.getCache(row.value);
      } else if (row.type == "hash") {
        this.loadKeyList(row.value);
      }
    },
    currentChangeKey({ row }) {
      this.selectedKeyRow = row;
      this.hGetCache(this.selectedCacheRow.value, row.value);
    },
    handleDelCache(row) {
      this.delCache(row.value).then(() => {
        this.loadCacheList();
      });
    },
    handleDelKey(row) {
      if (this.selectedCacheRow && this.selectedCacheRow.type == "hash") {
        this.hDelCache(this.selectedCacheRow.value, row.value).then(() => {
          this.loadKeyList(this.selectedCacheRow.value);
        });
      }
    },
    handleCacheMatchChange(e) {
      this.loadCacheList();
    },
    handleKeyMatchChange(e) {
      if (this.selectedCacheRow && this.selectedCacheRow.type == "hash") {
        this.loadKeyList(this.selectedCacheRow.value);
      }
    },
  },
};
</script>
<style scoped>
.button-group {
  float: right;
  margin-top: 10px;
}
</style>
