<!-- 生成时间:2024-03-14 18:08:58 -->
<template>
  <div class="app-container">
    <!-- 渲染查询表单 -->
    <VFormRender
      v-if="queryformData"
      class="query-form-data"
      ref="queryform"
      :formJson="queryformData"
    ></VFormRender>

    <el-form>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery1"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <jf-table
      :tree-config="treeConfig"
      :loading="loading"
      :query-params="queryParams"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="dataList"
      @sort-change="sortChange"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
      @toggle-tree-expand="toggleExpandChangeEvent"
      @cell-dblclick="Oncelldblclick"
      @page-change="handleSizeChange"
      :total="total"
      :filterBtn="switchshowbtn('filter')"
      :sortBtn="switchshowbtn('sort')"
      @change="tableChange"
    >
      <!-- <ijf-table
      :columnConfig="{ resizable: true }"
      :loading="loading"
      ref="xTable"
      :checkbox="false"
      class="sortable-row-demo"
      :columns="fieldsColumnEdit"
      :defaultopr-width="80"
      :columns-btn="false"
      :filter-btn="false"
      :sort-btn="false"
      :data="dataList"
      :toolbar-config="tableToolbar"
      :mergeColumn="'to_range'"
      :scroll-y="{ enabled: false }"

      :footerClass="'col-red'"
    > -->
      <template slot="toolbar_btn_left">
        <el-button
          v-if="switchshowbtn('add')"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleUpdateAdd"
          v-hasPermiZc="['/product/zyjkreportdata/add']"
          >新增</el-button
        >
        <el-button
          v-if="switchshowbtn('edit')"
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermiZc.all="[
            '/product/zyjkreportdata/get',
            '/product/zyjkreportdata/edit',
          ]"
          >修改</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermiZc="['/product/zyjkreportdata/del']"
          v-if="switchshowbtn('delete')"
          >删除</el-button
        >
        <el-button
          v-if="switchshowbtn('import')"
          type="warning"
          size="mini"
          @click="handleImport(1)"
          v-hasPermiZc="['/product/zyjkreportdata/import']"
        >
          <i class="iconfont icon-daoru_o"></i>
          导入
        </el-button>

        <el-button
          v-if="switchshowbtn('export')"
          class="btn-orange"
          size="mini"
          @click="handleImport(2)"
          v-hasPermiZc="['/product/zyjkreportdata/export']"
        >
          <i class="iconfont icon-daochu_o"></i>
          导出
        </el-button>

        <el-dropdown @command="handleCommand">
          <el-button size="mini" type="text" style="margin-left: 10px">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="operationLogOpen"
              >操作记录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <!-- 	<template slot="toolbar_btn_right">

					</template> -->
      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          size="mini"
          v-if="switchshowbtn('get')"
          type="text"
          icon="el-icon-view"
          @click="Oncelldblclick({ row })"
          v-hasPermiZc="['/product/zyjkreportdata/get']"
          >查看
        </el-button>

        <el-button
          v-if="switchshowbtn('edit')"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          v-hasPermiZc="['/product/zyjkreportdata/edit']"
          >修改
        </el-button>

        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          v-hasPermiZc="['/product/zyjkreportdata/del']"
          @click="handleDelete(row)"
          v-if="switchshowbtn('delete')"
          >删除
        </el-button>
      </template>
      <template slot="status" slot-scope="{ row }">
        <el-switch
          v-model="row.status"
          active-value="1"
          inactive-value="0"
          @change="handleStatusChange(row)"
        ></el-switch>
      </template>
      <template slot="roles" slot-scope="{ row }">
        <span v-for="(item, index) of row.roles" :key="index">
          {{ item.name }}
        </span>
      </template>
      <template
        :slot="item.slots.default"
        slot-scope="{ row }"
        v-for="item in columnSlots"
      >
        <div v-html="getFun(item, row)"></div>
      </template>
    </jf-table>

    <!-- 添加或修改对话框  数据 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      :width="
        formData && formData.formConfig && formData.formConfig.dialogWidth
          ? formData.formConfig.dialogWidth
          : '1000px'
      "
      :close-on-click-modal="false"
      append-to-body
      :before-close="cancel"
      custom-class="scrolldialog"
    >
      <VFormRender
        v-if="formData != null"
        ref="form"
        :disabledMode="isview"
        :isReadOnly="isview"
        :formJson="formData"
        :userInfo="userInfo"
      ></VFormRender>

      <div slot="footer" class="dialog-footer">
        <el-button v-if="!isview" type="primary" @click="submitForm"
          >确 定</el-button
        >
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <!-- 导入导出 -->
    <jf-import
      :show.sync="importOpen"
      v-if="importOpen"
      :type="importType"
      :columns="tableColumn"
      :isfinish="isupladfinish"
      :downloadtplurl="downloadtplurl"
      @importsubmit="importsubmit"
      :importError="importError"
      :exportTotal="total"
      @exportSubmit="exportSubmit"
      @saveExportTemplate="saveExportTemplate"
      :exportTemplateList="exportTemplateList"
    ></jf-import>
    <!-- 操作记录 -->
    <jf-operation-log
      :show.sync="operationLogOpen"
      v-if="operationLogOpen"
    ></jf-operation-log>

    <!-- 导入提示 -->
    <el-dialog
      title="导入提示"
      :close-on-click-modal="false"
      :visible.sync="showerrinfo"
      width="750px"
      append-to-body
      custom-class="scrolldialog"
    >
      <p class="erinfo" v-for="(item, idx) in upload_errinfo" :key="idx">
        {{ item }}
      </p>
    </el-dialog>
  </div>
</template>

<script>
import zyjkreportdataService from "@/api/product/zyjkreportdata";
import { form } from "@/mixins/form.js";

import { execModel } from "@/api/core/data/datasource/model";
import { getToken } from "@/utils/auth";

import Treeselect from "@riophae/vue-treeselect";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import jfImport from "@/components/jfimport/jfimport";
import JfOperationLog from "@/components/jfoperationlog/jfoperationlog";
import ijfTable from "@/views/product/components/ijftable.vue";
import { getFieldWidgetByName, deepClone } from "@/vform/utils/util";
import { loadExtension } from "@/vform/extension/extension-loader";
import VFormRender from "@/vform/components/form-render/index.vue";
import { getUserProfile } from "@/api/core/system/user";
loadExtension();

export default {
  mixins: [form],
  name: "product-zyjkreportdata-index",
  components: {
    ijfTable,
    Treeselect,
    VFormRender,
    jfImport,
    JfOperationLog,
  },
  props: {
    //功能id
    rid: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      org_optionItems: {},
      istree: false,
      hidequeryparamform: false,
      exportTemplateList: [],
      listconf: null,

      importError: false,
      downloadtplurl: "",
      upload_errinfo: null,
      showerrinfo: false,
      isupladfinish: false,
      //导入导出弹窗
      importOpen: false,
      //导入导出类型
      imporType: 1,
      //操作记录
      operationLogOpen: false,
      //功能id
      mid: "23825777207301", //不可修改
      queryformData: null, //查询表单数据
      queryformDataorgstr: "",

      formData: null, //表单数据
      isview: false,
      model_info: {},
      child_model_info: {},
      childcollist: [],
      collist: [],
      LeftTreeData: [],
      LeftTreeDataProc: {},
      treeOptions: [],
      treeselectprops: {},
      treeConfig: {
        trigger: "row",
        expandRowKeys: [],
      },
      TreeAttr: {},

      //左侧分栏比例
      leftrolspan: 0,
      //checkbox 的数据
      CheckBoxData: {},
      //字典库数据
      DictsData: {},
      DsData: {},

      area_dist: {},
      province_dist: {},
      city_dist: {},

      //上传的文件列表
      upload_fileList: {},

      //上传文件参数
      upload: {
        headers: {
          Authorization: getToken(),
        },
        url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
      },

      //编辑器配置
      ueConfig: {
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
        enableAutoSave: true,
        elementPathEnable: false,
        wordCount: false,
        UEDITOR_HOME_URL: "/ueditor/",
      },

      //图片预览地址
      dialogImageUrl: "",
      //图片预览对话框
      dialogVisible: false,

      loadingstu: false,
      openselstu: false,

      // 工具栏
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 数据列
      tableColumn: [
        // {
        // 	type: "checkbox",
        // 	width: 60,
        // 	fixed: "left"
        // },

        // {
        //   field: "name",
        //   title: "ID",
        //   width: 100,
        // },

        {
          field: "",
          title: "操作",
          resizable: false,
          width: 180,
          fixed: "right",
          align: "center",
          slots: {
            default: "defaultopr",
          },
        },
      ],

      orgcolumnList: [],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      totalstu: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 快速检索数据
      Quitfilter_data: {},
      // 快速检索数据 字典类型
      Quitfilter_keys: [],
      // 是否显示弹出层
      open: false,
      // 机构名称
      deptname: undefined,

      //表单
      form: {},
      defaultProps: {
        children: "children",
        label: "name",
      },

      // 表单校验
      rules: {},
      userInfo: {},

      // 数据列
      fieldsColumnEdit: [
        // {
        // 	type: "checkbox",
        // 	width: 60,
        // 	fixed: "left"
        // },
        {
          field: "id",
          title: "ID",
          width: 100,
          fixed: "left",
        },
        {
          field: "name",
          title: "使用范围",
          width: 140,
          fixed: "left",
        },

        // {
        // 	field: "",
        // 	title: "操作",
        // 	resizable: false,
        // 	width: 180,
        // 	fixed: "right",
        // 	align: "center",
        // 	slots: {
        // 		default: "defaultopr"
        // 	},
        // },
      ],
    };
  },

  computed: {
    columnSlots: function () {
      return this.tableColumn.filter((item) => {
        return item.slots;
      });
      console.log(
        this.tableColumn.filter((item) => {
          return item.slots;
        })
      );
    },
  },
  watch: {
    // 根据名称筛选机构树
    deptname(val) {
      this.$refs.tree.filter(val);
    },
    // tableColumn:{
    //     handler(val){
    //         console.log(val)
    //         this.tableColumn = this.fieldsColumnEdit
    //     },immediate:true
    // }
    rid: {
      handler(val) {
        this.rid = val;
        this.getList(this.mid);
      },
      immediate: true,
      deep: true,
    },
  },
  beforeCreate() {},
  created() {
    this.downloadtplurl = zyjkreportdataService.zyjkreportdataimportTemplate(); //下载模版地址
    this.queryParams.sortinfo = this.defsort;

    this.getforminfo(this.mid, true);

    this.getUser();
  },
  methods: {
    getUser() {
      getUserProfile().then((response) => {
        this.userInfo = response.data.user;
      });
    },
    getName(row) {
      console.log(row, "测试");
    },

    exportSubmit(fieldlist) {
      var that = this;
      var tfa = [];
      for (var i in fieldlist) {
        var byname = "";
        if (fieldlist[i].byname != null) {
          byname = fieldlist[i].byname;
        }
        tfa.push(fieldlist[i].field + "=" + byname);
      }
      var durl = zyjkreportdataService.zyjkreportdataExportUrl(
        this.mid,
        this.queryParams,
        tfa
      );
      try {
        this.downloadbyurl(durl, null, function (ers) {
          if (ers.currentTarget.status != 200) {
            that.msgError("导出错误");
          }
        });
      } catch (error) {
        console.log(chalk.red(error));
      }
    },
    importsubmit(rows) {
      //开始导入
      zyjkreportdataService
        .zyjkreportdataImport(this.mid, rows.datalist, rows.importoption)
        .then((rs) => {
          if (rs.data.row.Failmsg != null && rs.data.row.Failmsg != "") {
            this.upload_errinfo = rs.data.row.Failmsg.split("|");
            this.showerrinfo = true;
            this.importError = true;
          } else {
            this.isupladfinish = true;
            this.msgSuccess("导入成功");
            this.getList(this.mid);
          }
        });
    },
    //导入导出
    handleImport(type) {
      this.importError = false;
      this.isupladfinish = false;
      this.importType = type;
      this.importOpen = true;
    },
    handleCommand(e) {
      //  console.log(e);
      this[e] = true;
    },

    getFun(item, row) {
      return this[item.slotsFun.funName](
        item.slotsFun.dt,
        row[item.slotsFun.key],
        item.slotsFun.key
      );
    },

    //查看
    Oncelldblclick(row) {
      this.handleUpdate(row.row, "查看");
      this.isview = true;
    },

    getTreeselect() {
      if (this.model_info.Category != "4" && this.model_info.Category != "5") {
        return false;
      }
      this.treeOptions = [];
      let opt = {
        id: 0,
        children: [],
      };
      opt[this.TreeAttr.tree_name] = "(无上级)";

      if (this.model_info.Category == "5") {
        opt.children = this.LeftTreeData;
      } else {
        opt.children = this.dataList;
      }

      this.treeOptions.push(opt);
      // console.log(this.treeOptions);
    },

    /** 查询数据 */
    getList(mid) {
      this.loading = true;

      this.queryParams.fieldval["r_id"] = this.rid;

      zyjkreportdataService
        .listZyjkreportdata(this.queryParams)
        .then((response) => {
          this.loading = false;
          if (this.treeConfig.parentField != null) {
            var tlist = [];
            for (let k in response.data.list) {
              let tonef = response.data.list[k];
              tonef["label"] = tonef[this.treeConfig.treeNode];

              tlist.push(JSON.parse(JSON.stringify(tonef)));
            }
            try {
              this.dataList = this.handleTree(
                tlist,
                "id",
                this.treeConfig.parentField
              );
            } catch (error) {
              console.log(" this.dataList error", error);
            }
          } else {
            this.dataList = response.data.list;
          }

          let rrtype = this.queryParams.fieldval.rtype;
          let ptype =
            rrtype == "既往史"
              ? "1"
              : rrtype == "职业史"
              ? "2"
              : rrtype == "检查结果"
              ? "3"
              : rrtype == "历年作业"
              ? "4":"";
          this.tableColumn = this.getRtypeCoum(ptype);
          this.total = response.data.total;
        })
        .catch(() => {
          this.loading = false;
          // this.msgError("加载数据出错,请先检查表单配置!");
        });
    },

    // 筛选节点
    filterNode(value, data) {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 取消按钮
    cancel() {
      this.reset();
      this.open = false;
      this.isview = false;
    },
    // 表单重置
    reset() {
      this.form = {};
      this.formData = null;

      this.resetQuery();
    },

    /** 新增按钮操作 */
    handleAdd() {
      //this.reset();
      // this.getTreeselect();
      this.hidequeryparamform = true;
      this.formData = null;
      let tformdata = JSON.parse(this.model_info.Formresource);

      console.log("-------" + this.model_info.Formresource);
      tformdata.formstatus = "add";

      this.$nextTick((_) => {
        this.formData = this.binddicttotablecolumn(tformdata);
      });

      this.open = true;
      this.title = "添加数据";
    },
    /** 修改按钮操作 */
    handleUpdateAdd(row, til) {
      this.reset();
      // this.getTreeselect();
      //this.getTreeselect();
      

    


        let formvalue = {
            "r_id": this.rid,
        }; //表单填写内容

        let array = Object.keys(formvalue);
        this.reformatformdata(formvalue);
        let formData = JSON.parse(this.model_info.Formresource); //表单数据(字段,布局等)
        formData.formstatus = "edit";

        this.formData = formData;
        this.$nextTick((_) => {
          //    formData;
          this.formData = this.binddicttotablecolumn(formData);
          this.$refs.form.setFormData(formvalue);
        });

        this.open = true;
        this.title = til || "添加";
     
    },

    /** 修改按钮操作 */
    handleUpdate(row, til) {
      this.reset();
      // this.getTreeselect();
      //this.getTreeselect();
      const id = row.id || this.ids[0];
      zyjkreportdataService.getZyjkreportdata(id).then((response) => {
        this.form.id = response.data.row.id;

        //填充表单值
        let formvalue = response.data.row; //表单填写内容

        let array = Object.keys(formvalue);
        this.reformatformdata(formvalue);
        let formData = JSON.parse(this.model_info.Formresource); //表单数据(字段,布局等)
        formData.formstatus = "edit";

        this.formData = formData;
        this.$nextTick((_) => {
          //    formData;
          this.formData = this.binddicttotablecolumn(formData);
          this.$refs.form.setFormData(formvalue);
        });

        this.open = true;
        this.title = til || "修改";
      });
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form
        .getFormData()
        .then((data) => {
          if (this.form.id != null) {
            //修改
            data.id = this.form.id;
            zyjkreportdataService
              .editZyjkreportdata(this.mid, data)
              .then((rs) => {
                console.log(data);

                this.msgSuccess("操作成功");
                this.cancel();
                this.handleQuery();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          } else {
            //新增
            zyjkreportdataService
              .addZyjkreportdata(this.mid, data)
              .then((rs) => {
                this.msgSuccess("操作成功");
                this.cancel();
                this.handleQuery();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          }
        })
        .catch((e) => {
          this.msgError("表单验证不通过,请检查输入");
        });

      return true;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const did = row.id || (this.ids ? this.ids.join(",") : "");
      this.$confirm("是否确认删除所选数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return zyjkreportdataService.delZyjkreportdata(did);
        })
        .then(() => {
          this.handleQuery();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    handleQuery1() {
      let fieldval = {};
      this.$refs.queryform.getFormData().then((qdata) => {
        for (let qfield in qdata) {
          let qvalue = qdata[qfield];

          if (qvalue && qvalue != "") {
            fieldval[qfield] = qvalue;
          } else if (typeof qvalue === "number" && qvalue * 1 === 0) {
            fieldval[qfield] = qvalue;
          }
        }

        // console.log(fieldval.rtype);

        let ptype =
          fieldval.rtype == "既往史"
            ? "1"
            : fieldval.rtype == "职业史"
            ? "2"
            : fieldval.rtype == "检查结果"
            ? "3"
            : fieldval.rtype == "历年作业"
              ? "4":"";
        this.tableColumn = this.getRtypeCoum(ptype);

        // console.log(this.tableColumn);
        this.handleQuery();
      });
    },

    getRtypeCoum(type) {
      let result = [];
      switch (type) {
        case "1":
          result = [
            {
              field: "info",
              title: "疾病说明",
            },
            {
              field: "name",
              title: "疾病名称",
            },

            {
              field: "sick_date",
              title: "诊断日期",
            },
            {
              field: "sick_unit",
              title: "诊断单位",
            },
            {
              field: "sick_is_heal",
              title: "是否痊愈",
            },

            {
              field: "",
              title: "操作",
              resizable: false,
              width: 180,
              fixed: "right",
              align: "center",
              slots: {
                default: "defaultopr",
              },
            },
          ];
          break;
        case "2":
          result = [
            {
              field: "name",
              title: "疾病名称",
            },

            {
              field: "s_e_date",
              title: "起始时间",
            },
            {
              field: "work_unit",
              title: "工作单位",
            },
            {
              field: "work_type",
              title: "工种",
            },
            {
              field: "hazard_type",
              title: "接触职业危害种类",
            },
            {
              field: "measure",
              title: "防护措施",
            },

            {
              field: "",
              title: "操作",
              resizable: false,
              width: 180,
              fixed: "right",
              align: "center",
              slots: {
                default: "defaultopr",
              },
            },
          ];
          break;
        case "3":
          result = [
            {
              field: "name",
              title: "病名",
            },

            {
              field: "exam_date",
              title: "体检时间",
            },
            {
              field: "exam_woker_type",
              title: "体检时从事工种",
            },
            {
              field: "exam_result",
              title: "结论",
            },
            {
              field: "exam_unit",
              title: "检查单位",
            },
            {
              field: "exam_re_item",
              title: "复查项目",
            },
            {
              field: "exam_re_result",
              title: "复查结论",
            },
            {
              field: "exam_re_unit",
              title: "复查机构",
            },
            {
              field: "",
              title: "操作",
              resizable: false,
              width: 180,
              fixed: "right",
              align: "center",
              slots: {
                default: "defaultopr",
              },
            },
          ];
          break;
        case "4":
          result = [
            {
              field: "name",
              title: "病名",
            },

            {
              field: "monitor_date",
              title: "监测时间",
            },
            {
              field: "monitor_ill_reason",
              title: "危害因素",
            },
            {
              field: "monitor_deal_result",
              title: "监测结果",
            },
            {
              field: "monitor_commont",
              title: "评价情况",
            },

            {
              field: "monitor_unit",
              title: "监测单位",
            },
            {
              field: "monitor_deal_result",
              title: "处理结果",
            },

            {
              field: "",
              title: "操作",
              resizable: false,
              width: 180,
              fixed: "right",
              align: "center",
              slots: {
                default: "defaultopr",
              },
            },
          ];
          break;
      }

      return result;
    },

    normalizerDeptTreeNode(e) {
      //      e.id = e.id
      e.label = e.name;
      return e;
    },
  },
};
</script>
<style rel="stylesheet/scss" scoped>
.form-data {
  margin: 0 -2rem;
}
.btn-orange {
  background-color: #ff9648;
  color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #2196f3;
  color: #fff;
}

::v-deep .scrolldialog {
  max-height: 82vh;
}

::v-deep .el-dialog__body {
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.head-container {
  font-size: 14px;
}
</style>
