<template>
	<div class="app-container">
		<el-row :gutter="20">
			<!--用户数据-->
			<el-col :span="24" :xs="24">
				<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
					<el-form-item label="关键字" prop="keyword">
						<el-input v-model="queryParams.keyword" placeholder="账号、姓名、昵称、工号、手机号" clearable size="small"
							style="width: 250px" @keyup.enter.native="handleQuery" />
					</el-form-item>
					<!--<el-form-item label="工号" prop="no">
            <el-input
              v-model="queryParams.no"
              placeholder="请输入工号"
              clearable
              size="small"
              style="width: 160px"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>-->
					<!--<el-form-item label="手机号码" prop="mobile">
            <el-input
              v-model="queryParams.mobile"
              placeholder="请输入手机号码"
              clearable
              size="small"
              style="width: 160px"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>-->
					<el-form-item label="类型" prop="type">
						<el-select v-model="queryParams.type" placeholder="用户类型" clearable size="small"
							style="width: 160px">
							<el-option v-for="dict in typeOptions.values" :key="dict.key" :label="dict.value"
								:value="dict.key" />
						</el-select>
					</el-form-item>
					<!--<el-form-item label="角色" prop="role_ids">
            <el-select
              v-model="queryParams.role_ids"
              placeholder="用户角色"
              clearable
              size="small"
              style="width: 160px"
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="queryParams.status"
              placeholder="用户状态"
              clearable
              size="small"
              style="width: 160px"
            >
              <el-option
                v-for="dict in statusOptions.values"
                :key="dict.key"
                :label="dict.value"
                :value="dict.key"
              />
            </el-select>
          </el-form-item>-->
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
						<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
					</el-form-item>
				</el-form>
				<jf-table :loading="loading" :columns="tableColumn" :data="userList" :toolbar-config="tableToolbar"
					:query-params="queryParams" @checkbox-change="checkboxChangeEvent"
					@page-change="handleSizeChange" :total="total" @change="tableChange">
					<template slot="toolbar_btn_left">
						<el-button  type="primary" icon="el-icon-plus" size="mini"
							@click="handleAdd" v-hasPermi="['/user/add']">新增</el-button>
						<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
							@click="handleDelete" v-hasPermi="['/user/del']">删除</el-button>
					</template>
					
					<template slot="device_id" slot-scope="{row}">
						<el-button v-if="row.device_id" type="text" @click.stop="handleUnBindDevice(row)">解绑</el-button>
						<div v-else class="unbind-device">未绑定</div>
					</template>
					
					<template slot="status" slot-scope="{ row }">
						<el-switch v-model="row.status" active-value="1" inactive-value="0"
							@change="handleStatusChange(row)"></el-switch>
					</template>
					
					<template slot="roles"  slot-scope="{row}">
						<span v-for="(item,index) of row.roles" :key="index"> {{item.name}} </span>
					</template>

					<template slot="toolbar_btn_row" slot-scope="{ row }">
						<el-button size="mini" type="text" icon="el-icon-view" @click="handleUpdate(row, true)"
							v-hasPermi="['/user/get']">查看
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-delete" v-hasPermi="['/user/edit']"
							@click="handleDelete(row)">删除
						</el-button>
					</template>


				</jf-table>

				<!-- <vxe-grid resizable ref="xGrid" stripe show-overflow highlight-hover-row :loading="loading"
					:toolbar-config="tableToolbar" :columns="tableColumn" :data="userList"
					@checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent">
					<template #toolbar_buttons>
						<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini"
							@click="handleAdd" v-hasPermi="['/user/add']">新增</el-button>
						<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
							@click="handleDelete" v-hasPermi="['/user/del']">删除</el-button>
					</template>

					<template #device_id="{row}">
						<el-button v-if="row.device_id" type="text" @click.stop="handleUnBindDevice(row)">解绑</el-button>
						<div v-else class="unbind-device">未绑定</div>
					</template>

					<template #status="{row}">
						<el-switch v-model="row.status" active-value="1" inactive-value="0"
							@change="handleStatusChange(row)"></el-switch>
					</template>

					<template #roles="{row}">
						<span v-for="(item,index) of row.roles" :key="index"> {{item.name}} </span>
					</template> -->

					<!--默认操作按钮-->
				<!-- 	<template #defaultopr="{ row }">
						<el-button size="mini" type="text" icon="el-icon-view" @click="handleUpdate(row, true)"
							v-hasPermi="['/user/get']">查看
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-delete" v-hasPermi="['/user/edit']"
							@click="handleDelete(row)">删除
						</el-button>
					</template> -->

					<!--自定义空数据模板-->
			<!-- 		<template #empty>
						<span>
							<p>暂无数据</p>
						</span>
					</template>

				</vxe-grid>
				<vxe-pager background size="small" :loading="loading" :current-page="queryParams.pageNum"
					:page-size="queryParams.pageSize" :total="total" @page-change="handleSizeChange">
				</vxe-pager> -->

			</el-col>
		</el-row>

		<!-- 添加或修改用户对话框 -->
		<el-dialog :title="title" :visible.sync="open" width="800px" :close-on-click-modal="false" append-to-body>
			<jf-form ref="form" :model="form" :rules="rules" label-width="80px" :readonly="readonly">
				<el-row>
					<el-col :span="12">
						<el-form-item label="用户姓名" prop="nickname">
							<el-input v-model="form.nickname" placeholder="请输入用户姓名" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="归属机构" prop="dept_id">
							<treeselect v-model="form.dept_id" :options="deptOptions" placeholder="请选择归属机构"
								:normalizer="normalizerDeptTreeNode" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item v-if="form.id == undefined || readonly" label="用户账号" prop="username">
							<el-input v-model="form.username" placeholder="请输入用户账号" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item v-if="form.id == undefined" label="用户密码" prop="password">
							<el-input v-model="form.password" placeholder="请输入用户密码" type="password" />
						</el-form-item>
					</el-col>
					<!--<el-col :span="12">
            <el-form-item label="工号" prop="no">
              <el-input v-model="form.no" placeholder="请输入工号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="realname">
              <el-input v-model="form.realname" placeholder="请输入姓名" />
            </el-form-item>
          </el-col>-->
					<el-col :span="12">
						<el-form-item label="身份证号" prop="idcard">
							<el-input v-model="form.idcard" placeholder="请输入身份证号" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="手机号码" prop="mobile">
							<el-input v-model="form.mobile" placeholder="请输入手机号码" maxlength="11" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="邮箱" prop="email">
							<el-input v-model="form.email" placeholder="请输入邮箱" maxlength="50" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="用户性别">
							<el-select v-model="form.gender" placeholder="请选择">
								<el-option v-for="dict in genderOptions.values" :key="dict.key" :label="dict.value"
									:value="dict.key"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="用户类型">
							<el-select v-model="form.type" placeholder="请选择">
								<el-option v-for="dict in typeOptions.values" :key="dict.key" :label="dict.value"
									:value="dict.key"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="岗位">
							<el-select v-model="form.postIds" multiple placeholder="请选择">
								<el-option v-for="item in postOptions" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="角色">
							<el-select v-model="form.roleIds" multiple placeholder="请选择">
								<el-option v-for="item in roleOptions" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="主管" prop="leader_nos">
							<!--<el-input v-model="form.leader_nos" placeholder="请输入主管" maxlength="20" />-->
							<admin-selectex size="small" placeholder="请选择用户" title="用户" idField="no"
								:value="form.leader_nos" :label="form.leader_names" :readonly="true"
								@getValue='(ids, names)=>{form.leader_nos=ids;form.leader_names=names;}' />
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="备注">
							<el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="状态">
							<el-radio-group v-model="form.status">
								<el-radio v-for="dict in statusOptions.values" :key="dict.key"
									:label="dict.key">{{dict.value}}</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>

				</el-row>
			</jf-form>
			<div slot="footer" class="dialog-footer">
				<template>
					<el-button @click="cancel">关 闭</el-button>
				</template>
			</div>
		</el-dialog>

		<el-dialog title="添加用户" :visible.sync="openUser" width="500px" :close-on-click-modal="false" append-to-body>
			<el-form ref="formUser" :model="formUser" :rules="rulesUser" label-width="80px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="用户" prop="user_ids">
							<admin-selectex size="small" placeholder="请选择用户" title="用户" idField="id"
								:value="formUser.user_ids" :label="form.user_names" :readonly="true"
								@getValue='(ids, names)=>{formUser.user_ids=ids;formUser.user_names=names;}' />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<template>
					<el-button type="primary" @click="submitForm">确 定</el-button>
					<el-button @click="openUser=false">取 消</el-button>
				</template>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		listUser,
		getUser,
		getEditUser,
		delUser,
		addUser,
		updateUser,
		exportUser,
		resetUserPwd,
		unBindDevice,
		changeUserStatus,
		importTemplate
	} from "@/api/core/system/user";
	import {
		listRole,
		delRoleUser,
		addRoleUser
	} from "@/api/core/system/role";
	import Qs from 'qs'
	import {
		getAccessToken
	} from '@/utils/tokenTool'
	import {
		treeselect
	} from "@/api/core/system/dept";
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";

	export default {
		name: "User",
		components: {
			Treeselect
		},
		props: {
			id: {
				type: [String, Number],
				default: undefined
			},
		},
		data() {
			return {
				// 工具栏
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery
					},
					slots: {
						buttons: 'toolbar_buttons'
					}
				},
				// 列
				tableColumn: [
					// {
					// 	type: 'checkbox',
					// 	width: 60,
					// 	fixed: "left"
					// },
					//{field: 'id', title: '用户编号', width: 100, fixed: "left"},
					{
						field: 'username',
						title: '用户账号'
					},
					{
						field: 'nickname',
						title: '用户姓名'
					},
					//{field: 'no', title: '工号' },
					{
						field: 'dept.name',
						title: '机构'
					},
					//{field: 'roles', title: '角色', slots: {default: 'roles'} },
					{
						field: 'mobile',
						title: '手机号码'
					},
					//{field: 'device_id', title: '绑定设备', align:"center", width: 100,slots: {default: 'device_id'}  },
					//{field: 'status', title: '状态', align:"center", width: 100,slots: {default: 'status'} },
					//{field: 'create_time', title: '创建时间', formatter: "formatDate"},
					//{field: 'update_time', title: '更新时间', formatter: "formatDate"},
					// {
					// 	field: '',
					// 	title: '操作',
					// 	resizable: false,
					// 	width: 150,
					// 	fixed: "right",
					// 	align: "center",
					// 	slots: {
					// 		default: 'defaultopr'
					// 	}
					// }
				],
				// 遮罩层
				loading: true,
				// 选中数组
				ids: [],
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				// 总条数
				total: 0,
				// 用户表格数据
				userList: [],
				// 弹出层标题
				title: "",
				// 是否查看
				readonly: false,
				// 机构树选项
				deptOptions: undefined,
				// 是否显示弹出层
				open: false,
				// 角色用户
				openUser: false,
				// 机构名称
				name: undefined,
				// 默认密码
				initPassword: undefined,
				// 日期范围
				dateRange: [],
				// 状态数据字典
				statusOptions: [],
				// 性别状态字典
				genderOptions: [],
				typeOptions: [],
				// 岗位选项
				postOptions: [],
				// 角色选项
				roleOptions: [],
				roleList: [],
				// 表单参数
				form: {},
				formUser: {},
				defaultProps: {
					children: "children",
					label: "name"
				},
				upload: {
					open: false,
					title: "",
					isUploading: false,
					update: false,
					headers: {
						Authorization: getAccessToken()
					},
					url: process.env.VUE_APP_BASE_API + "/user/import"
				},
				// 查询参数
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					keyword: undefined,
					no: undefined,
					mobile: undefined,
					status: undefined,
					dept_id: undefined,
					with_sub_dept_admin: true
				},
				// 表单校验
				rules: {
					username: [{
						required: true,
						message: "用户账号不能为空",
						trigger: "blur"
					}],
					no: [{
						required: true,
						message: "工号不能为空",
						trigger: "blur"
					}],
					nickname: [{
						required: true,
						message: "用户姓名不能为空",
						trigger: "blur"
					}],
					dept_id: [{
						required: true,
						message: "归属机构不能为空",
						trigger: "blur"
					}],
					password: [{
						required: true,
						message: "用户密码不能为空",
						trigger: "blur"
					}],
					email: [{
							required: false,
							message: "邮箱地址不能为空",
							trigger: "blur"
						},
						{
							type: "email",
							message: "'请输入正确的邮箱地址",
							trigger: ["blur", "change"]
						}
					],
					mobile: [{
							required: true,
							message: "手机号码不能为空",
							trigger: "blur"
						},
						{
							pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
							message: "请输入正确的手机号码",
							trigger: "blur"
						}
					]
				},
				rulesUser: {
					user_ids: [{
						required: true,
						message: "用户不能为空",
						trigger: "blur"
					}]
				}
			};
		},
		watch: {
			// 根据名称筛选机构树
			name(val) {
				this.$refs.tree.filter(val);
			}
		},
		created() {
			this.getList();
			this.getTreeselect();
			listRole({
				pageSize: 1000
			}).then(response => {
				this.roleList = response.data.list
			})
		},
		methods: {
			tableChange(e) {
				console.log(e)
			},
			handleSizeChange({
				currentPage,
				pageSize
			}) {
				this.queryParams.pageNum = currentPage;
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}

			},
			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map(item => item.id)
				this.single = records.length != 1;
				this.multiple = !records.length

			},

			/** 查询用户列表 */
			getList() {
				this.loading = true;
				this.queryParams.role_ids = this.id || '-1'
				listUser(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
					let list = []
					if (response.data.list) {
						response.data.list.forEach(item => {
							item.status = '' + item.status
							list.push(item)
						})
					}
					this.single = true;
					this.multiple = true;
					this.userList = list;
					this.total = response.data.total;
					this.statusOptions = response.data.statusOptions;
					this.genderOptions = response.data.genderOptions;
					this.typeOptions = response.data.typeOptions;
					this.loading = false;
				});
			},
			/** 查询机构下拉树结构 */
			getTreeselect() {
				treeselect().then(response => {
					this.deptOptions = response.data;
				});
			},
			// 筛选节点
			filterNode(value, data) {
				console.log(value, data)
				if (!value) return true;
				return data.name.indexOf(value) !== -1;
			},
			// 节点单击事件
			handleNodeClick(data) {
				this.queryParams.pageNum = 1;
				this.queryParams.dept_id = data.id;
				this.getList();
			},
			// 用户解绑设备
			handleUnBindDevice(row) {
				this.$confirm('确认解绑"' + row.username + '"用户设备吗?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return unBindDevice(row.id);
				}).then(() => {
					this.msgSuccess("解绑设备成功");
					row.device_id = '';
				}).catch(function() {});
			},
			// 用户状态修改
			handleStatusChange(row) {
				let text = row.status === '1' ? "启用" : "停用";
				this.$confirm('确认要' + text + '"' + row.username + '"用户吗?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					return changeUserStatus(row.id, row.status);
				}).then(() => {
					this.msgSuccess(text + "成功");
				}).catch(function() {
					row.status = row.status === "0" ? "1" : "0";
				});
			},
			// 取消按钮
			cancel() {
				this.open = false;
				this.reset();
			},
			// 表单重置
			reset() {
				this.formUser = {
					user_ids: undefined,
					user_names: undefined
				};
				this.resetForm("formUser");
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},
			/** 重置按钮操作 */
			resetQuery() {
				//this.$refs.tree.setCurrentKey(null)
				this.queryParams.dept_id = 0;
				this.dateRange = [];
				this.resetForm("queryForm");
				this.handleQuery();
			},
			// 多选框选中数据
			handleSelectionChange(selection) {
				this.ids = selection.map(item => item.id);
				this.single = selection.length != 1;
				this.multiple = !selection.length;
			},
			/** 新增按钮操作 */
			handleAdd() {
				this.reset();
				this.openUser = true
			},
			/** 修改按钮操作 */
			handleUpdate(row, readonly) {
				this.reset();
				//this.getTreeselect();
				const id = row.id || this.ids;
				getEditUser(id).then(response => {
					const user = response.data.admin
					const postIdsStr = user.post_ids ? user.post_ids.split(',') : []
					const postIds = []
					for (let i = 0; i < postIdsStr.length; i++) {
						postIds.push(parseInt(postIdsStr[i]))
					}
					const roleIdsStr = user.role_ids ? user.role_ids.split(',') : []
					const roleIds = []
					for (let i = 0; i < roleIdsStr.length; i++) {
						roleIds.push(parseInt(roleIdsStr[i]))
					}
					this.form = {
						id: user.id,
						dept_id: user.dept_id,
						username: user.username,
						no: user.no,
						realname: user.realname,
						idcard: user.idcard,
						nickname: user.nickname,
						password: undefined,
						mobile: user.mobile,
						email: user.email,
						gender: '' + user.gender,
						status: "" + user.status,
						leader_nos: user.leader_nos,
						leader_names: user.leader_names,
						remark: user.remark,
						postIds: postIds,
						post_ids: user.post_ids,
						roleIds: roleIds,
						role_ids: user.role_ids,
						type: user.type
					}
					this.postOptions = response.data.posts;
					this.roleOptions = response.data.roles;
					this.form.password = "";
					this.readonly = readonly;
					this.title = readonly ? "查看用户" : "修改用户";
					this.open = true;
				});
			},
			/** 提交按钮 */
			submitForm: function() {
				this.$refs["formUser"].validate(valid => {
					if (valid) {
						addRoleUser(this.id, this.formUser.user_ids).then(response => {
							if (response.code === 0) {
								this.msgSuccess("新增成功");
								this.openUser = false;
								this.getList();
							} else {
								this.msgError(response.msg);
							}
						});
					}
				});
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				const userIds = row.id || (this.ids ? this.ids.join(',') : '');
				this.$confirm('确定删除吗?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					return delRoleUser(this.id, userIds);
				}).then(() => {
					this.getList();
					this.msgSuccess("删除成功");
				}).catch(function() {});
			},

			normalizerDeptTreeNode(e) {
				e.id = e.id
				e.label = e.name
				return e
			}
		}
	};
</script>

<style scoped>
	.unbind-device {
		color: #ccc;
		font-size: 14px;
	}
</style>