import request from '@/utils/request'

export function listTemplateStudent(query) {
  return request({
    url: '/template/templatestudent/list',
    method: 'get',
    params: query
  })
}

export function getTemplateStudent(id) {
  return request({
    url: '/template/templatestudent/get?id=' + id,
    method: 'get'
  })
}

export function addTemplateStudent(data) {
  return request({
    url: '/template/templatestudent/add',
    method: 'post',
    data: data
  })
}

export function editTemplateStudent(data) {
  return request({
    url: '/template/templatestudent/edit',
    method: 'post',
    data: data
  })
}

export function delTemplateStudent(ids) {
  return request({
    url: '/template/templatestudent/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

