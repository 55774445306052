<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">      
      <el-form-item label="处理状态" prop="dealwith_type">
        <el-select v-model="queryParams.dealwith_type" placeholder="处理状态" clearable size="small">
          <el-option
            v-for="dict in dealwithTypeList"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row/>

    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="appearList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>        
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >详情</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </template>
      <!--状态栏-->
     
      <!--默认操作按钮-->
      <template #defaultopr="{ row }">        
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
        >详情
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>     

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="700px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">        
        
        <el-descriptions title="警告信息" :colon="false" :column="3">
            <el-descriptions-item  label="设备名称">{{form.device_name}}</el-descriptions-item>            
            <el-descriptions-item  label="警告类型">{{warnType(form.warn_type)}}</el-descriptions-item>
            <el-descriptions-item label="处理类型">{{dealwithType(form.dealwith_type)}}</el-descriptions-item>
            <el-descriptions-item label="属性名称">{{form.attribute_name}}</el-descriptions-item>
            <el-descriptions-item label="预警数值">{{form.warn_value}}</el-descriptions-item>            
            <el-descriptions-item label="警告时间">{{timestampToTime(form.warn_time)}}</el-descriptions-item>
            <el-descriptions-item label="上报人员">{{form.user_name}}</el-descriptions-item> 
            <el-descriptions-item label="上报时间">{{timestampToTime(form.appear_time)}}</el-descriptions-item>    
            
            <el-descriptions-item />         
            <el-descriptions-item label="警告内容">{{form.warn_content}}</el-descriptions-item>
        </el-descriptions> 
        <el-descriptions title="处理结果" :colon="false" :column="3" style="margin-top:20px">
          <el-descriptions-item label="处理人员">{{form.dealwith_username}}</el-descriptions-item> 
          <el-descriptions-item label="处理时间">{{form.dealwith_time>0?timestampToTime(form.dealwith_time):""}}</el-descriptions-item>
        </el-descriptions>      
        <el-descriptions title="操作" style="margin-top:20px"/>  
          

        <div style=" display:inline-block ; "> 
              <el-button
                style="margin-left: 10px"
                type="success"                 
                size="mini" 
                @click="updateWarn(1)"
              >标志已读</el-button>
              <el-button
                style="margin-left: 10px"
                type="success"                 
                size="mini" 
                @click="updateWarn(3)"
              >标志已处理</el-button>  
              <el-button
                style="margin-left: 10px"
                type="success"                 
                size="mini" 
                @click="updateWarn(4)"
              >忽略警告</el-button> 
              <el-button
                style="margin-left: 10px"
                type="success"                 
                size="mini" 
              >
                <router-link :to="'/iot/device/info/'+form.device_id" class="link-type" style="align-items: center;">
                  <span>查看设备</span>
                </router-link>
              </el-button>
            </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addIotWarnDealwith, getIotWarnDealwith, updateIotWarnDealwith, delIotWarnDealwith, listIotWarnDealwith } from "@/api/iot/warn";
import { listIotUserWarn,delIotUserWarn,updateIotUserWarn} from "@/api/iot/userwarn";
export default {
  name: "Appear",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: 'device_name', title: '设备名称' },
        {field: 'warn_type', title: '警告类型' ,formatter:this.warnFormat},
        {field: 'dealwith_type', title: '处理状态' ,formatter:this.dealwithFormat},
        {field: 'user_name', title: '上报人员'},
        {field: 'appear_time', title: '上报时间',formatter:this.timeFormat },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 分类表格数据
      warnTypeListList: [],
      dealwithTypeList:[],
      appearList:[],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dealwith_type: undefined,
        user_id: undefined,
        dept_ids: undefined
      },
      // 表单参数
      form: {},
      warn:{},
      // 表单校验
      rules: {
        name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
        space_id: [
          { required: true, message: "资源空间不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
      this.getList();
  },
  methods: {
    updateWarn(type){
      this.form.dealwith_type=type;
      updateIotWarnDealwith(this.form).then(response=>{
        if(response.code==0){
          var warn={
            id:this.form.warn_id,
            is_dealwith:type
          };   
          updateIotUserWarn(warn).then(response=>{
            if(response.code==0){
              this.msgSuccess("操作成功");  
              this.open=false;        
            }
          });
        }
      });
      
    },
    conditionType(type){
       var label="";
       
      return label;
    },
    warnType(type){
      var label="";
      this.warnTypeListList.forEach(element=>{
        if(type==element.key){
          label=element.value 
        }
      });
      return label;
    },
    statusType(type){
      var label=""
      
      return label;
    },
    dealwithType(type){
      var label=""
      this.dealwithTypeList.forEach(element=>{
        if(type==element.key){
          label=element.value 
        }
      });
      return label;
    },
    warnFormat({row}){
      this.labname="";
      this.warnTypeListList.forEach(element => {
        if (row.warn_type == element.key)
          this.labname= element.value;        
      });
      return this.labname;
    },
    dealwithFormat({row}){
      this.labname="";
      this.dealwithTypeList.forEach(element => {
        if (row.dealwith_type == element.key)
          this.labname= element.value;        
      });
      return this.labname;
    },
    timeFormat({row}){     
      return this.timestampToTime(row.appear_time);
    },
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length
    },    
    /** 查询分类列表 */
    getList() {
      this.loading = true;         
      listIotWarnDealwith(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;           
        this.total = response.data.total;
        this.appearList = response.data.list;        
        this.warnTypeListList = response.data.warnTypeOptions.values;  
        this.dealwithTypeList = response.data.dealwithTypeOptions.values;        
        this.loading = false;
        }).catch(function (error){
          console.log(error);
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },        
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getIotWarnDealwith(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "警告详情";
      });
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {            
            
          }
          this.open = false;
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除警告编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotWarnDealwith(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
  }
};
</script>
