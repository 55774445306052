import request from '@/utils/request'

// 查询cms评论列表
export function listCmsComment(query) {
  return request({
    url: '/cms/comment/list',
    method: 'get',
    params: query
  })
}

// 查询cms评论详细
export function getCmsComment(id) {
  return request({
    url: '/cms/comment/get?id=' + id,
    method: 'get'
  })
}

// 新增cms评论
export function addCmsComment(data) {
  return request({
    url: '/cms/comment/add',
    method: 'post',
    data: data
  })
}

// 修改cms评论
export function updateCmsComment(data) {
  return request({
    url: '/cms/comment/edit',
    method: 'post',
    data: data
  })
}

// 删除cms评论
export function delCmsComment(id) {
  return request({
    url: '/cms/comment/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
