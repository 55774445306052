<template>
    <div>
        <el-form-item :label="i18nt('designer.setting.apiMethodName')">
            <el-select v-model="optionModel.apiMethod" placeholder="请选择">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </el-form-item>
    </div>
</template>
  
  <script>
import i18n from "@/vform/utils/i18n";

export default {
    name: "api-method-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            options:[{label:'GET',value:'get'},{label:'POST',value:'post'}]
        };
    },
};
</script>
  
  <style scoped>
</style>