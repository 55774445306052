<template>
    <el-form-item :label="i18nt('designer.setting.fontWeightName')" v-if="!designer.isTopic">
        <el-input
            v-model="optionModel.font_weight"
            placeholder="请输入字体加粗值如500"
        ></el-input>
    </el-form-item>
</template>

<script>
import i18n from "@/vform/utils/i18n";

export default {
    name: "font-weight-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>