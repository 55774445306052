<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
			<el-form-item label="id" prop="id">
				<el-input v-model="queryParams.id" placeholder="请输入id" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="姓名" prop="name">
				<el-input v-model="queryParams.name" placeholder="请输入姓名" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="学号" prop="no">
				<el-input v-model="queryParams.no" placeholder="请输入学号" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary"   icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>
		<jf-table :loading="loading" :total="total"form_type :columns="tableColumn" :data="dataList" :toolbar-config="tableToolbar"
			:query-params="queryParams" @checkbox-change="checkboxChangeEvent" @page-change="handlePageChange"
			@change="tableChange">
			<template slot="toolbar_btn_left">
				<el-button  type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="[
						'/template/templatestudent/add',
					]">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[
						'/template/templatestudent/get',
						'/template/templatestudent/edit',
					]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="[
						'/template/templatestudent/del',
					]">删除</el-button>
			</template>

			<template slot="toolbar_btn_row" slot-scope="{ row }">
				<!-- <div>123213</div>
				<div>{{row}}</div> -->
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>

		</jf-table>
		<!-- <vxe-grid resizable ref="xGrid" row-id="id" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="dataList"
			@checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="[
					'/template/templatestudent/add',
				]">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[
					'/template/templatestudent/get',
					'/template/templatestudent/edit',
				]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="[
					'/template/templatestudent/del',
				]">删除</el-button>
			</template>

			<template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>

			<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>
		</vxe-grid> -->

<!-- 		<vxe-pager background size="small" :loading="loading" :current-page.sync="queryParams.pageNum"
			:page-size.sync="queryParams.pageSize" :total="total" @page-change="handlePageChange">
		</vxe-pager> -->

		<el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
			<el-form ref="form" :model="form" :rules="rules" label-width="100px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="姓名" prop="name">
							<el-input v-model="form.name" placeholder="请输入姓名" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="学号" prop="no">
							<el-input v-model="form.no" placeholder="请输入学号" clearable />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm">确 定</el-button>
				<el-button @click="cancel">返 回</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		listTemplateStudent,
		getTemplateStudent,
		addTemplateStudent,
		editTemplateStudent,
		delTemplateStudent,
	} from "@/api/template/template_student";
	export default {
		name: "template-templatestudent-list",
		data() {
			return {
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery,
					},
					slots: {
						buttons: "toolbar_buttons",
					},
				},
				tableColumn: [
					{
						field: "id",
						title: "id",
						minWidth: 120,
						fixed: "left",
						isCheck: true,
						form_type: '1'
					},
					{
						field: "name",
						title: "姓名",
						minWidth: 120,
						isCheck: true,
						form_type: '1'
					},
					{
						field: "no",
						title: "学号",
						minWidth: 120,
						isCheck: true,
						form_type: '1'
					},
					{
						field: "create_by",
						title: "创建者",
						minWidth: 120,
						visible: false,
						isCheck: true,
						form_type: '1'
					},
					{
						field: "create_time",
						title: "创建时间",
						minWidth: 120,
						formatter: "formatDate",
						visible: false,
						isCheck: true,
						form_type: '1'
					},
					{
						field: "update_by",
						title: "更新者",
						minWidth: 120,
						visible: false,
						isCheck: true,
						form_type: '1'
					},
					{
						field: "update_time",
						title: "更新时间",
						minWidth: 120,
						formatter: "formatDate",
						isCheck: true,
						form_type: '3'
					},
					// {
					// 	field: "",
					// 	title: "操作",
					// 	width: 180,
					// 	fixed: "right",
					// 	align: "center",
					// 	slots: {
					// 		default: "defaultopr"
					// 	},
					// },
				],
				loading: true,
				ids: [],
				single: true,
				multiple: true,
				total: 0,
				dataList: [],
				title: "",
				open: false,
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					id: undefined,
					name: undefined,
					no: undefined
				},
				form: {},
				rules: {
					name: [{
						required: true,
						message: "姓名不能为空",
						trigger: "blur"
					}],
					no: [{
						required: true,
						message: "学号不能为空",
						trigger: "blur"
					}],
				}
			};
		},
		created() {
			this.getList();
		},
		methods: {
			tableChange(e){
				console.log(e)
			},
			getList() {
				this.loading = true;
				this.ids = [];
				this.single = true;
				this.multiple = true;

				listTemplateStudent(this.queryParams).then((response) => {
					this.dataList = response.data.list;
					this.total = response.data.total;
					this.loading = false;
				});
			},

			cancel() {
				this.open = false;
				this.reset();
			},

			reset() {
				this.form = {
					name: undefined,
					no: undefined,
				};
				this.resetForm("form");
			},

			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},

			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},

			handleAdd() {
				this.reset();
				this.open = true;
				this.title = "添加";
			},

			handleUpdate(row) {
				this.reset();
				const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
				getTemplateStudent(id).then((response) => {
					this.form = response.data;
					this.open = true;
					this.title = "修改";
				});
			},

			submitForm() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						if (this.form.id != null) {
							editTemplateStudent(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("修改成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						} else {
							addTemplateStudent(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("新增成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						}
					}
				});
			},

			handleDelete(row) {
				const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
				this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(function() {
						return delTemplateStudent(ids);
					})
					.then(() => {
						setTimeout(() => {
							this.getList();
						}, 300);
						this.msgSuccess("删除成功");
					})
					.catch(function() {});
			},

			checkboxChangeEvent({
				records
			}) {
				console.log(records)
				this.ids = records.map((item) => item.id);
				this.single = records.length != 1;
				this.multiple = !records.length;
			},

			handlePageChange({
				currentPage,
				pageSize
			}) {
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}
			},
		}
	};
</script>