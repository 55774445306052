<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
      <el-form-item label="类型" prop="category">
        <dict-select v-model="queryParams.category" type="sys_message_template_category" placeholder="请选择类型" />
      </el-form-item>
      <el-form-item label="名称" prop="name">
				<el-input v-model="queryParams.name" placeholder="请输入名称" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
      <el-form-item label="代码" prop="code">
				<el-input v-model="queryParams.code" placeholder="请输入代码" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery" >搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>

		<jf-table :loading="loading" :columns="tableColumn" :data="dataList" :toolbar-config="tableToolbar"
			:query-params="queryParams" @checkbox-change="checkboxChangeEvent" @page-change="handlePageChange"
			:total="total" @change="tableChange">
			<template slot="toolbar_btn_left">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="[
					'/core/userpool/add',
				]">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[
					'/core/userpool/get',
					'/core/userpool/edit',
				]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="[
					'/core/userpool/del',
				]">删除</el-button>
			</template>


			<template slot="toolbar_btn_row" slot-scope="{ row }">
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>


		</jf-table>
		<el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
			<el-form ref="form" :model="form" :rules="rules" label-width="100px">
				<el-row>
          <el-col :span="24">
						<el-form-item label="类型" prop="category">
							<dict-select v-model="form.category" type="sys_message_template_category" placeholder="请选择类型" :disabled="form.id" />
						</el-form-item>
					</el-col>
          <el-col :span="24">
						<el-form-item label="名称" prop="name">
							<el-input v-model="form.name" placeholder="请输入名称" clearable />
						</el-form-item>
					</el-col>
          <el-col v-if="form.id" :span="24">
						<el-form-item label="代码" prop="code">
							<el-input v-model="form.code" placeholder="请输入代码" :disabled="true" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="签名" prop="sign">
							<el-input v-model="form.sign" placeholder="请输入签名" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="内容" prop="content">
							<el-input v-model="form.content" type="textarea" :rows="5" placeholder="请输入内容" clearable />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm">确 定</el-button>
				<el-button @click="cancel">返 回</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		listMessageTemplate,
		getMessageTemplate,
		addMessageTemplate,
		editMessageTemplate,
		delMessageTemplate,
	} from "@/api/core/system/message";
	export default {
		name: "message-list",
		data() {
			return {
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery,
					},
					slots: {
						buttons: "toolbar_buttons",
					},
				},
				tableColumn: [
					// { type: "checkbox", width: 60, fixed: "left" },
					//{ field: "id", title: "id", minWidth: 120, fixed: "left" },
					{
						field: "name",
						title: "名称",
						minWidth: 120
					},
          {
						field: "code",
						title: "代码",
						minWidth: 120
					},
					{
						field: "sign",
						title: "签名",
						minWidth: 120
					},
          {
						field: "content",
						title: "内容",
						minWidth: 120
					},
					/*
					{ field: "remark", title: "", minWidth: 120 },*/
					{
						field: "create_by",
						title: "创建者",
						minWidth: 120,
						visible: false
					},
					{
						field: "create_time",
						title: "创建时间",
						minWidth: 120,
						formatter: "formatDate",
						visible: false
					},
					{
						field: "update_by",
						title: "更新者",
						minWidth: 120,
						visible: false
					},
					{
						field: "update_time",
						title: "更新时间",
						minWidth: 120,
						formatter: "formatDate",
						form_type:'3'
					},
					// {
					// 	field: "",
					// 	title: "操作",
					// 	width: 180,
					// 	fixed: "right",
					// 	align: "center",
					// 	slots: { default: "defaultopr" },
					// },
				],
				loading: true,
				ids: [],
				single: true,
				multiple: true,
				total: 0,
				dataList: [],
				title: "",
				open: false,
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					category: undefined,
          name: undefined,
					code: undefined,
				},
				form: {},
				rules: {
          category: [{
						required: true,
						message: "分类不能为空",
						trigger: "blur"
					}],
          name: [{
						required: true,
						message: "名称不能为空",
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: "代码不能为空",
						trigger: "blur"
					}],
          /*sign: [{
						required: true,
						message: "签名不能为空",
						trigger: "blur"
					}],*/
          content: [{
						required: true,
						message: "内容不能为空",
						trigger: "blur"
					}],
				}
			};
		},
		created() {
			this.getList();
		},
		methods: {
			tableChange(e) {
				console.log(e)
			},
			getList() {
				this.loading = true;
				this.ids = [];
				this.single = true;
				this.multiple = true;

				listMessageTemplate(this.queryParams).then((response) => {
					this.dataList = response.data.list;
					this.total = response.data.total;
					this.loading = false;
				});
			},

			cancel() {
				this.open = false;
				this.reset();
			},

			reset() {
				this.form = {
          id: undefined,
					category: undefined,
					type: undefined,
          name: undefined,
					code: undefined,
					sign: undefined,
          summary: undefined,
					content: undefined,
          scene: undefined,
          scene_url: undefined,
					remark: undefined,
          status: undefined
				};
				this.resetForm("form");
			},

			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},

			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},

			handleAdd() {
				this.reset();
				this.open = true;
				this.title = "添加";
			},

			handleUpdate(row) {
				this.reset();
				const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
				getMessageTemplate(id).then((response) => {
					this.form = response.data;
					this.open = true;
					this.title = "修改";
				});
			},

			submitForm() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						if (this.form.id != null) {
							editMessageTemplate(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("修改成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						} else {
							addMessageTemplate(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("新增成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						}
					}
				});
			},

			handleDelete(row) {
				const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
				this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(function() {
						return delMessageTemplate(ids);
					})
					.then(() => {
						setTimeout(() => {
							this.getList();
						}, 300);
						this.msgSuccess("删除成功");
					})
					.catch(function() {});
			},

			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map((item) => item.id);
				this.single = records.length != 1;
				this.multiple = !records.length;
			},

			handlePageChange({
				currentPage,
				pageSize
			}) {
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}
			},
		}
	};
</script>