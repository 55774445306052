import request from '@/utils/request'
// 生成时间:2022-03-11 14:51:58

// 查询board列表
export function listBoard(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/iotdev/board/list',
        method: 'post',
        data: data
    })
}

export function boardSelectData() {
  return request({
    url: '/iotdev/board/selectData',
    method: 'get'
  })
}

// 查询board详细
export function getBoard (id) {
    return request({
        url: '/iotdev/board/get?id='+id,
        method: 'get'
    })
}


// 新增board
export function addBoard(data) {
    return request({
        url: '/iotdev/board/add',
        method: 'post',
        data: data
    })
}

// 修改board
export function editBoard(data) {
    return request({
        url: '/iotdev/board/edit',
        method: 'post',
        data: data
    })
}

// 删除board
export function delBoard(ids) {
    return request({
        url: '/iotdev/board/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 删除board查询模板
export function advquerydelBoard(ids) {
    return request({
        url: '/iotdev/board/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加board查询模板
export function advquerysaveBoard(data) {
    return request({
        url: '/iotdev/board/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表board查询模板列表
export function advquerylistBoard(businessname) {
    return request({
        url: '/iotdev/board/advquerylist?businessname='+businessname,
        method: 'get'
    })
}
// 导出board
export function exportBoard(query,exportfields,tableColumn) {
     var data={
            filterstr:"",
            orderby:"",
            opstr:query.opstr ||{},
        };
        var filterstr={};
        for(var k in query){
         var item=query[k];
         if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
           filterstr[k]=item;
         }
        }
        var tarexportfieldslist={};
        for(var k in tableColumn){
          var tlbf = tableColumn[k];
           for(var i in exportfields){
             var ef = exportfields[i]
             if(tlbf.field!="" && tlbf.field && tlbf.field!=null && tlbf.field == ef){
               tarexportfieldslist.push({"filed":ef,"title":tlbf.title});
               break;
             }
           }
        }

        data["filterstr"] = JSON.stringify(filterstr);
        data["opstr"] = JSON.stringify(data["opstr"]);
        data["exportfields"] = JSON.stringify(tarexportfieldslist);
        return request({
            url: '/iotdev/board/export',
            method: 'post',
            data: data
        })
}
