import request from '@/utils/request'

// 添加领域分类
export function addIotCategory(data) {
    return request({
      url: '/iot/category/add',
      method: 'post',
      data: data
    })
}

// 获取领域分类
export function getIotCategory(id) {
    return request({
      url: '/iot/category/get?id=' + id,
      method: 'get'
    })
}

// 更新领域分类
export function updateIotCategory(data) {
    return request({
      url: '/iot/category/update',
      method: 'post',
      data: data
    })
  }
  
// 删除领域分类
export function delIotCategory(id) {
    return request({
      url: '/iot/category/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 领域分类列表
export function listIotCategory(query) {
  return request({
    url: '/iot/category/list',
    method: 'get',
    params: query
  })
}