<template>
    <el-form-item :label="i18nt('designer.setting.queryTypeName')" v-if="!designer.isTopic">
        <el-select
            v-model="optionModel.querytype"
            placeholder="请选择"
            :style="{ width: '100%' }"
        >
            <el-option
                v-for="item in querytype_list"
                :key="item.label"
                :label="item.label"
                :value="item.value"
            >
                <span> {{ item.label }}</span>
            </el-option>
        </el-select>
    </el-form-item>
</template>
<script>
import i18n from "@/vform/utils/i18n";
export default {
    name: "query-type-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            querytype_list: [
                {
                    value: "=",
                    label: "精确匹配",
                },
                {
                    value: "like",
                    label: "模糊查询",
                },
                {
                    value: "in",
                    label: "在列表内",
                },
                {
                    value: "between",
                    label: "范围",
                },
            ],
        };
    },
};
</script>