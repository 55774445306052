<!-- 生成时间:2022-07-22 18:27:48 -->

<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
                          <el-form-item label="手机号" prop="phone">
                                      <el-input
                                        v-model="queryParams.phone"
                                        placeholder="请输入手机号"
                                        clearable
                                        size="small"
                                        @keyup.enter.native="handleQuery"
                                       />
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                                      <el-input
                                        v-model="queryParams.status"
                                        placeholder="请输入状态"
                                        clearable
                                        size="small"
                                        @keyup.enter.native="handleQuery"
                                       />
                    </el-form-item>

        <el-form-item>
            <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
    </el-form>



     <vxe-grid
                resizable
                ref="xGrid"
                stripe
                show-overflow
                highlight-hover-row
                :loading="loading"
                :toolbar-config="tableToolbar"
                :columns="tableColumn"
                :data="dataList"
                @checkbox-change="checkboxChangeEvent"
                @checkbox-all="checkboxChangeEvent">
                <template #toolbar_buttons>

                        <el-button
                         style="margin-left: 10px"
                          type="primary"
                          icon="el-icon-plus"
                          size="mini"
                          @click="handleAdd"
                          v-hasPermi="['/dncblacklist/konghao/add']"
                        >新增</el-button>


                        <el-button
                          type="success"
                          icon="el-icon-edit"
                          size="mini"
                          :disabled="single"
                          @click="handleUpdate"
                          v-hasPermi="['/dncblacklist/konghao/get','/dncblacklist/konghao/edit']"
                        >修改</el-button>


                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          size="mini"
                          :disabled="multiple"
                          @click="handleDelete"
                          v-hasPermi="['/dncblacklist/konghao/del']"
                        >删除</el-button>










                </template>

          <!--默认操作按钮-->
          <template #defaultopr="{ row }">

            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
            >修改
            </el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
            >删除
            </el-button>


          </template>

          <!--自定义空数据模板-->
          <template #empty>
                <span >
                  <p>暂无数据</p>
                </span>
          </template>

        </vxe-grid>

        <vxe-pager
                background
                size="small"
                :loading="loading"
                :current-page="queryParams.pageNum"
                :page-size="queryParams.pageSize"
                :total="total"
                @page-change="handleSizeChange">
        </vxe-pager>


            <!-- 添加或修改对话框 -->
                <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
                  <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                    <el-form-item label="手机号" prop="phone">
                      <el-input v-model="form.phone" placeholder="请输入手机号" />

                    </el-form-item>
                    <el-form-item label="来源" prop="source">
                      <el-input v-model="form.source" placeholder="请输入来源" />

                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                      <el-input v-model="form.status" placeholder="请输入状态" />

                    </el-form-item>


                   </el-form>



          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">返 回</el-button>
          </div>
        </el-dialog>

        <!--高级查询对话框-->
                        <el-dialog title="高级查询" :visible.sync="advqueryopen" width="1300px" append-to-body>
                        <el-row :gutter="20">
                         <el-col :span="15" :xs="24">
                          <vxe-grid
                              border
                              resizable
                              keep-source
                              ref="xAdvqueryGrid"
                              id="id_xAdvqueryGrid"
                              height="500"
                              :columns="advqueryColumn"
                              :toolbar-config="advqueryToolbar"
                              :data="advqueryData"
                              :custom-config="{storage: true}"
                              :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
                              @toolbar-button-click="advqtoolbarButtonClickEvent"
                           >
                            <template #defaultadvqueryopr="{ row }">

                            </template>
                          </vxe-grid>

                          </el-col>
                             <el-col :span="9" :xs="24">
                                  <vxe-grid
                                          border
                                          resizable
                                          keep-source
                                          ref="xAdvqueryGrid_tempfilter"
                                          id="id_xAdvqueryGrid_tempfilter"
                                          height="500"
                                          :columns="advqueryColumn_tempfilter"

                                          :data="advqueryData_tempfilter"

                                  >
                                      <template #defaultadvqueryopr_tempfilter="{ row }">
                                          <el-button size="mini" type="text" icon="el-icon-delete"
                                                     @click="handleadvqueryApplytpl(row)"
                                          >应用
                                          </el-button>

                                          <el-button size="mini" type="text" icon="el-icon-delete"
                                                     @click="handleadvqueryDeltpl(row)"
                                          >删除
                                          </el-button>
                                      </template>
                                  </vxe-grid>

                              </el-col>
                          </el-row>


                          <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="advquery_save">确 定</el-button>
                            <el-button @click="cancel">取 消</el-button>
                          </div>
                        </el-dialog>

                        <!--保存模版-->
                              <el-dialog :close-on-click-modal="false" title="保存模版" :visible.sync="advquery_tempsave" width="800px"
                                         append-to-body>
                                  <el-form ref="advquery_tempsaveform" :model="advquery_tempsaveform" :rules="advquery_tempsaverules" label-width="80px">
                                      <el-form-item label="模版名称" prop="tempname">
                                          <el-input v-model="advquery_tempsaveform.tempname"   placeholder="请输入模版名称"/>

                                      </el-form-item>
                                  </el-form>

                                  <div slot="footer" class="dialog-footer">
                                      <el-button type="primary" @click="advquery_save">确 定</el-button>

                                  </div>

                              </el-dialog>

                              <!--导出数据-->
                                    <el-dialog :close-on-click-modal="false" title="导出数据" :visible.sync="exportdialog" width="800px"
                                               append-to-body>
                                        <el-checkbox :indeterminate="exportisIndeterminate" v-model="exportcheckAll" @change="handleexportCheckAllChange">全选</el-checkbox>
                                        <div style="margin: 15px 0;"></div>

                                        <el-checkbox-group v-model="exportfields" >
                                            <el-checkbox v-for="field in tableColumn" v-if="field.field" :label="field.field" :key="field.field">{{field.title}}</el-checkbox>
                                        </el-checkbox-group>

                                        <div slot="footer" class="dialog-footer">
                                            <el-button type="primary" @click="doExport">确 定</el-button>

                                        </div>

                               </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

      </div>
</template>
<script>
import { getAccessToken } from '@/utils/tokenTool'
import { listKonghao, getKonghao, delKonghao,
 addKonghao, editKonghao,advquerydelKonghao,
 advquerysaveKonghao, advquerylistKonghao,
  exportKonghao } from '@/api/dncblacklist/konghao'
  import VDistpicker from "@/components/VDistpicker";
export default {
components: { VDistpicker },
  name: "konghao",

  data() {
    return {
    dialogImageUrl:"",
     businessname:"konghao",//请勿改动
     exportfields:[],
     exportcheckAll: false,
     exportisIndeterminate: false,
     exportdialog:false,

    //编辑器配置
    ueConfig: {
         // 初始容器高度
         initialFrameHeight: 300,
         // 初始容器宽度
         initialFrameWidth: '100%',
         // 上传文件接口
         serverUrl: process.env.VUE_APP_BASE_API +  "/file/ueditorupload",
         enableAutoSave: true,
         elementPathEnable: false,
         wordCount: false,
         UEDITOR_HOME_URL: '/ueditor/',
     },
    tableToolbar: {
            perfect: true,
            zoom: true,
            custom: true,
            refresh: {
               query: this.handleQuery
            },
            slots: {
             buttons: 'toolbar_buttons'
            }
          },

          // 列
          tableColumn: [
            {type: 'checkbox', width: 60, fixed: "left"},
            {field: 'phone', title: '手机号', minWidth: 80 , fixed: "left"  },
            {field: 'source', title: '来源', minWidth: 80   },
            {field: 'status', title: '状态', minWidth: 80   },
            {field: 'create_time', title: '创建时间', minWidth: 80   , formatter: "formatDate"},
            {field: 'update_time', title: '更新时间', minWidth: 80   , formatter: "formatDate"},

            {field: '', title: '操作', width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
          ],

      advqueryColumn:[
        {field: 'qfield', title: '查询字段', width: 180, fixed: "left", editRender: { name: 'select', options: [{ value: "dd", label: "cxvs"},{ value: "zz", label: "cxvs"} ] }, },
        {field: 'qopr', title: '操作符', width: 180, fixed: "left",   editRender: { name: 'select', options: [] }},
        {field: 'qvalue', title: '值', width: 180, fixed: "left",   editRender: { name: 'input'  }},
        {field: 'qlogic', title: '逻辑', width: 180, fixed: "left",   editRender: { name: 'select', options: [] } },
        {field: '', title: '操作',resizable:false, width: 110, fixed: "right", align: "center", slots: {default: 'defaultadvqueryopr'}}
      ],
      advqueryColumn_tempfilter:[
                  {field: 'tempname', title: '模版名称', width: 200, fixed: "left" },
                  {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultadvqueryopr_tempfilter'}}
              ],
        advqueryData_tempfilter:[],
        advqueryopen:false,
        advquery_tempsave:false,
        advqueryData:[],
        advqueryToolbar: {
        buttons: [
          { code: 'myInsert', name: '新增' },
          { code: 'dofilter', name: '使用此组合条件查询' },

        ],

      },
      upload: {
              headers: { Authorization: getAccessToken() },
              url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
            },
      dialogVisible:false,

      
      
          // 遮罩层
          loading: true,
          // 选中数组
          ids: [],
          // 非单个禁用
          single: true,
          // 非多个禁用
          multiple: true,
          // 显示搜索条件
          showSearch: true,
          // 总条数
          total: 0,
          // 表格数据
          dataList: [],
          // 弹出层标题
          title: "",
          // 是否显示弹出层
          open: false,
            // 字典
            
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          opstr:{
          phone : "=",
          status : "=",

          },
          phone : undefined,
          status : undefined,

        },
        // 表单参数
          form: {},
          advquery_tempsaveform:{},
          advquery_tempsaverules:{
              tempname:[{ required: true, message: "模版名称不能为空", trigger: "blur" }]
          },
          // 表单校验
          rules: {
              phone: [
                  { required: true, message: "手机号不能为空", trigger: "blur" }
               ],

              source: [
                  { required: true, message: "来源不能为空", trigger: "blur" }
               ],

              status: [
                  { required: true, message: "状态不能为空", trigger: "blur" }
               ],


          }
      };
    },
    created() {
        this.getList();

        setTimeout(() => {
              const $grid = this.$refs.xAdvqueryGrid
              // 异步更新下拉选项
             var ta=[{value: "", label: "请选择" }];
            for( var i in this.tableColumn){
              var e = this.tableColumn[i]
              if(e.field!=null && e.field!=""){
                ta.push({ value: e.field, label: e.title })
              }
            }
            this.advqueryColumn[0].editRender.options =ta;

            this.advqueryColumn[1].editRender.options =this.defaultQlogic();

            ta=[];
            ta.push({value: "AND", label: "并且" });
            ta.push({value: "OR", label: "或者" });
            this.advqueryColumn[3].editRender.options =ta;



            }, 300);
           // 加载字典数据



    },
    methods: {
    //区域选择事件
    
    //表单上传字段成功处理
    handleImportSuccess(response, file, fileList ) {
          this.upload.open = false;
          this.upload.isUploading = false;
          var field = response.data.return_field;

          this.form[field] = this.form[field] || [];
          this.form[field].push(response.data.url);
          this[field + "_fileList"].push({ url: response.data.url });
        },
            handleUpload(){},
            handleExport(){
             if(this.total>0){
                this.exportdialog=true;
              }else{
              this.msgError("暂无数据可导出!");
              }
            },
            doExport(){
                 const queryParams = this.queryParams;
                 this.$confirm('是否确认导出?', "警告", {
                     confirmButtonText: "确定",
                     cancelButtonText: "取消",
                     type: "warning"
                 }).then(function() {
                     return exportKonghao(queryParams,this.exportfields,this.tableColumn);
                 }).then(response => {
                     this.download(response.file);
             }).catch(function() {});
             },
           handleexportCheckAllChange(val){
              this.exportfields = val ? this.tableColumn.map((item)=>{ return item.field;}) : [];
              this.exportisIndeterminate = false;
          },
            //删除查询模版
            handleadvqueryDeltpl(row){
            var ids=row.phone;
                  this.$confirm('是否确认删除?', "警告", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                  }).then(function () {
                      advquerydelKonghao(ids).then(()=>{
                          const $grid = this.$refs.xAdvqueryGrid_tempfilter;
                          $grid.remove(row)}).then(() => {   this.msgSuccess("删除成功"); });

                  }).then(() => {
                      this.msgSuccess("删除成功");
              }).catch(function () {
                  });


              },
              //打开高级查询
            handleAdvquery(){
                advquerylistKonghao(this.businessname).then(response => {
                    this.advqueryData_tempfilter = response.data.list
                     this.advqueryopen=true
                });

            },
           //保存条件
                advquery_save(){
                  const $grid = this.$refs.xAdvqueryGrid;
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false
                    }

                    var opstr = {};
                    var filteroptview=[];
                    var Qlogicar = this.defaultQlogic();
                    var queryParams = {
                    };
                    var fields={},Qlogic={};

                    for( var i in this.tableColumn){
                        var col = this.tableColumn[i];
                        if(col.field!=null && col.field!=""){
                            fields[col.field] = col.title
                        }
                    }


                    for( var i in Qlogicar){
                        var lgc = Qlogicar[i];
                        Qlogic[lgc.value] = lgc.label
                    }

                    for (var i in alldata.fullData) {
                        var onerow = alldata.fullData[i];
                        queryParams[onerow.qfield] = onerow.qvalue;
                        opstr[onerow.qfield] = onerow.qopr;
                        filteroptview.push(fields[onerow.qfield]+" "+Qlogic[onerow.qopr]+""+onerow.qvalue )
                    }

                    queryParams.opstr = opstr;
                    this.advquery_tempsaveform["businessname"] = this.businessname;
                    this.advquery_tempsaveform["filteropt"] = JSON.stringify(queryParams);
                    this.advquery_tempsaveform["filteroptview"] =filteroptview.join(";");

                    this.$refs["advquery_tempsaveform"].validate(valid => {
                        if (valid) {
                            advquerysaveKonghao(this.advquery_tempsaveform).then(response => {
                                 if (response.code === 0) {
                                 this.msgSuccess("保存成功");
                                 this.advquery_tempsave=false ;
                                 this.handleAdvquery();
                            }
                            });

                        }
                    });
                },

           //应用查询模版
            handleadvqueryApplytpl(row){
                 var Filteropt = JSON.parse( row.filteropt)

                 Filteropt['pageNum']=1;
                 Filteropt['pageSize']=10;
                 this.queryParams = Filteropt
                 this.advqueryopen=false
                 setTimeout(()=>{this.getList()},300)

             },
             //删除临时条件
          handleadvqueryDeleted (row){
            const $grid = this.$refs.xAdvqueryGrid;

            this.$confirm('是否确认删除?', "警告", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(function () {
              $grid.remove(row)
            }).then(() => {
              this.msgSuccess("删除成功");
          }).catch(function () {
            });


          },
          advqtoolbarButtonClickEvent ({ code }) {
            const $grid = this.$refs.xAdvqueryGrid
            switch (code) {
              case 'myInsert':
                var row=$grid.insert({
                  qfield: '',
                  qopr: '=',
                  qlogic: 'AND',
                })

                $grid.setActiveRow($grid.getData(0));
                break;

                case 'dosavefilter':
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false
                    }
                     this.advquery_tempsave=true ;
                    break;
                case 'dofilter':
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false
                    }

                    var opstr = {}

                    var queryParams = {
                        pageNum: 1,
                        pageSize: 10,
                        opstr: {}
                    };

                    for (var i in alldata.fullData) {
                        var onerow = alldata.fullData[i]
                        queryParams[onerow.qfield] = onerow.qvalue
                        opstr[onerow.qfield] = onerow.qopr
                    }

                    queryParams.opstr = opstr;
                    this.queryParams = queryParams;
                    this.advqueryopen = false;
                    setTimeout(()=>{this.getList()},300)
                    break;

                      }
                    },

          checkboxChangeEvent ({ records }) {
            this.ids = records.map(item => item.phone)
            this.single = records .length!=1
            this.multiple = !records .length

          },

          handleSizeChange ({ currentPage, pageSize }) {
          this.queryParams.pageNum = currentPage;
            if(this.queryParams.pageSize == pageSize){
              this.getList();
            }else{
              this.queryParams.pageSize = pageSize
              this.handleQuery();
            }
          },

        /** 查询列表 */
        getList() {
          this.loading = true;

          listKonghao(this.queryParams).then(response => {
            this.dataList = response.data.list;
            this.total = response.data.total;
            this.loading = false;
          });
        },
        // $comment字典翻译
        
        // 取消按钮
        cancel() {
          this.open = false;
          this.advqueryopen=false;
          this.reset();
        },
        // 表单重置
        reset() {
          this.form = {
            phone : undefined,
            source : undefined,
            status : undefined,

          };
          this.resetForm("form");
        },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.phone)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.phone || this.ids
      getKonghao(id).then(response => {
        this.form = response.data.data;
        
        this.open = true;
        this.title = "修改";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
        
          if (this.form.create_time) {
            editKonghao(this.form).then(response => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                setTimeout(()=>{this.getList();},300)
              }
            });
          } else {
            addKonghao(this.form).then(response => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
               setTimeout(()=>{this.getList();},300)
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.phone || this.ids.join(',');

      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delKonghao(ids);
        }).then(() => {
         setTimeout(()=>{this.getList();},300)
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    } //methods结束
};
</script>