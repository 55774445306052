import request from '@/utils/request'

// 添加设备影子
export function addIotDeviceShadow(data) {
    return request({
      url: '/iot/deviceshadow/add',
      method: 'post',
      data: data
    })
}

// 获取设备影子
export function getIotDeviceShadow(id) {
    return request({
      url: '/iot/deviceshadow/get?id=' + id,
      method: 'get'
    })
}

// 更新设备影子
export function updateIotDeviceShadow(data) {
    return request({
      url: '/iot/deviceshadow/update',
      method: 'post',
      data: data
    })
  }
  
// 删除设备影子
export function delIotDeviceShadow(id) {
    return request({
      url: '/iot/deviceshadow/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 设备列表影子
export function listIotDeviceShadow(query) {
  return request({
    url: '/iot/deviceshadow/list',
    method: 'get',
    params: query
  })
}