<template>
    <el-dialog title="导入" :visible.sync="dialogVisible" :show-close="false">
        <div class="dialog-content">
            <el-steps :active="step" style="padding: 10px 0" align-center>
                <el-step title="选择Excel表" icon="el-icon-folder-opened"></el-step>
                <el-step title="数据预览" icon="el-icon-document"></el-step>
                <el-step title="导入数据" icon="el-icon-folder-checked"></el-step>
            </el-steps>
            <step-one v-show="step == 0"/>
            <step-two v-show="step == 1"/>
            <step-three v-show="step == 2"/>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="next">{{ step == 2 ? '关闭' : '确 定'}}</el-button>
            <el-button v-if="step != 2" @click="pre">返 回</el-button>
        </div>
    </el-dialog>
</template>

<script>
import StepOne from './StepOne.vue';
import StepTwo from './StepTwo.vue';
import StepThree from './StepThree.vue'
export default {
    components: {
        StepOne,
        StepTwo,
        StepThree
    },
    data() {
        return {
            dialogVisible: false,
            step: 0
        };
    },
    methods: {
        open() {
            this.dialogVisible = true;
        },
        close() {
            this.dialogVisible = false;
            let that = this;
            setTimeout(() => {
                that.step = 0;
            }, 200);
        },
        confirm() {
            
        },
        next() {
            if (this.step++ >= 2) {
                this.close()
                return false
            }
        },
        pre() {
            if (this.step-- <= 0) {
                // this.step = 0;
                this.close();
                return false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-content {
    position: relative;
}
</style>