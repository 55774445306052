import request from '@/utils/request'
// 查询access列表
export function listAccess(query) {
  return request({
    url: '/data/api/access/list',
    method: 'get',
    params: query
  })
}
// 查询access详细
export function getAccess (id) {
    return request({
        url: '/data/api/access/get?id='+id,
        method: 'get'
    })
}

// 设置access
export function setAccess(data) {
  return request({
    url: '/data/api/access/set',
    method: 'post',
    data: data
  })
}


