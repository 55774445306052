import request from '@/utils/request'

// 添加设备属性
export function addIotDeviceAttribute(data) {
    return request({
      url: '/iot/deviceattribute/add',
      method: 'post',
      data: data
    })
}

// 获取设备属性
export function getIotDeviceAttribute(id) {
    return request({
      url: '/iot/deviceattribute/get?id=' + id,
      method: 'get'
    })
}

// 更新设备属性
export function updateIotDeviceAttribute(data) {
    return request({
      url: '/iot/deviceattribute/update',
      method: 'post',
      data: data
    })
  }
  
// 删除设备属性
export function delIotDeviceAttribute(id) {
    return request({
      url: '/iot/deviceattribute/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 删除产品服务命令属性
export function delIotDeviceAttributeByCommandId(id) {
  return request({
    url: '/iot/deviceattribute/delbycommand',
    method: 'post',
    data: {
      ids: id
    }
  })
}
// 设备列表属性
export function listIotDeviceAttribute(query) {
  return request({
    url: '/iot/deviceattribute/list',
    method: 'get',
    params: query
  })
}