import request from '@/utils/request'

export function listReportTemplate(query) {
  return request({
    url: '/report/reporttemplate/list',
    method: 'get',
    params: query
  })
}

export function getReportTemplate(id) {
  return request({
    url: '/report/reporttemplate/get?id=' + id,
    method: 'get'
  })
}

export function addReportTemplate(data) {
  return request({
    url: '/report/reporttemplate/add',
    method: 'post',
    data: data
  })
}

export function editReportTemplate(data) {
  return request({
    url: '/report/reporttemplate/edit',
    method: 'post',
    data: data
  })
}

export function delReportTemplate(ids) {
  return request({
    url: '/report/reporttemplate/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function execTemplate(id, params) {
  return request({
    url: '/report/reporttemplate/exec',
    method: 'post',
    data: {
      id: id,
      params: params
    }
  })
}

export function exportTemplate(id, params, type) {
  window.location.href = process.env.VUE_APP_BASE_API + '/report/reporttemplate/export?id=' + id + '&params=' + params + '&type=' + type
}
