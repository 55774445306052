<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="databaseList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="handleUpdate(row, true)"
                    >查看
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
            <template slot="type" slot-scope="{ row }">
                {{ typeFormat({ row: row }) }}
            </template>
        </jf-table>

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="databaseList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template> -->

        <!--默认操作按钮-->
        <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="handleUpdate(row, true)"
                    >查看
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->

        <!-- 添加或修改数据库连接对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
        >
            <jf-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
                :readonly="readonly"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="form.name" placeholder="名称" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="英文名称" prop="en_name">
                            <el-input
                                v-model="form.en_name"
                                placeholder="英文名称"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="type">
                            <el-select
                                v-model="form.type"
                                placeholder="请选择数据库类型"
                            >
                                <el-option
                                    v-for="item in typeOptions"
                                    :key="item.key"
                                    :label="item.value"
                                    :value="item.key"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="form.type != 'odbc'" label="主机地址" prop="host">
                            <el-input
                                v-model="form.host"
                                placeholder="主机地址"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="form.type != 'odbc'" label="端口" prop="port">
                            <el-input-number
                                v-model="form.port"
                                controls-position="right"
                                :min="0"
                                placeholder="端口"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="form.type != 'odbc'" label="数据库名" prop="db_name">
                            <el-input
                                v-model="form.db_name"
                                placeholder="数据库名"
                            />
                        </el-form-item>
                        <el-form-item v-else label="ODBC名称" prop="db_name">
                            <el-input
                                v-model="form.db_name"
                                placeholder="ODBC名称"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="form.type != 'odbc'" label="用户名" prop="username">
                            <el-input
                                v-model="form.username"
                                placeholder="数据库用户名"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="form.type != 'odbc'" label="密码" prop="password">
                            <el-input
                                v-model="form.password"
                                placeholder="数据库密码"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="连接参数" prop="extra_params">
                            <el-input
                                v-model="form.extra_params"
                                placeholder="连接参数"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备注" prop="remark">
                            <el-input
                                v-model="form.remark"
                                type="textarea"
                                placeholder="请输入备注"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </jf-form>
            <div slot="footer" class="dialog-footer">
                <template v-if="!readonly">
                    <el-button style="float: left" @click="testConnect"
                        >测试连接</el-button
                    >
                    <el-button type="primary" @click="submitForm"
                        >确 定</el-button
                    >
                    <el-button @click="cancel">取 消</el-button>
                </template>
                <template v-else>
                    <el-button @click="cancel">关 闭</el-button>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listDatabase,
    getDatabase,
    addDatabase,
    updateDatabase,
    delDatabase,
    testConnect,
} from "@/api/core/data/datasource/database";
import { Loading } from "element-ui";

export default {
    name: "core-data-datasource-database",
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                {
                    field: "id",
                    title: "编号",
                    width: 100,
                    fixed: "left",
                    visible: false,
                },
                { field: "name", title: "名称" },
                { field: "en_name", title: "英文" },
                {
                    field: "type",
                    title: "数据库类型",
                    // formatter: this.typeFormat,
                    slots: {
                        default: "type",
                    },
                    form_type:2,
                    options:[],
                    options_label:'',
                    options_value:''
                },
                { field: "host", title: "主机地址" },
                { field: "port", title: "端口" },
                { field: "db_name", title: "数据库/ODBC" },
                { field: "username", title: "数据库用户名" },
                //{ field: "password", title: "数据库密码" },
                { field: "extra_params", title: "连接参数" },
                { field: "remark", title: "备注" },
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 数据库连接表格数据
            databaseList: [],
            // 弹出层标题
            title: "",
            // 是否查看
            readonly: false,
            // 是否显示弹出层
            open: false,
            // 数据库类型数据字典
            typeOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                en_name: [
                    {
                        required: true,
                        message: "英文名称不能为空",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "数据库类型不能为空",
                        trigger: "blur",
                    },
                ],
                host: [
                    {
                        required: true,
                        message: "主机地址不能为空",
                        trigger: "blur",
                    },
                ],
                port: [
                    {
                        required: true,
                        message: "端口不能为空",
                        trigger: "blur",
                    },
                ],
                db_name: [
                    {
                        required: true,
                        message: "数据库名不能为空",
                        trigger: "blur",
                    },
                ],
                username: [
                    {
                        required: true,
                        message: "用户名不能为空",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "密码不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        //列表排序或者筛选
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询数据库连接列表 */
        getList() {
            this.loading = true;
            listDatabase(this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.typeOptions = response.data.typeOptions.values;

                this.databaseList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
                for (let i = 0; i < this.tableColumn.length; i++) {
                    
                    if (this.tableColumn[i].field === "type") {
                        this.tableColumn[i].form_type = 2;
                        this.tableColumn[i].options = this.typeOptions;
                        this.tableColumn[i].options_value = "key";
                        this.tableColumn[i].options_label ="value"
                    }
                }
            });
        },
        // 数据库类型字典翻译
        typeFormat({ row }) {
            return this.selectDictLabel(this.typeOptions, row.type);
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                code: undefined,
                name: undefined,
                sort: 0,
                status: "1",
                remark: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.readonly = false;
            this.title = "添加数据库连接";
            this.open = true;
        },
        /** 修改按钮操作 */
        handleUpdate(row, readonly) {
            this.reset();
            const id = row.id || this.ids;
            getDatabase(id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    this.form = {
                        id: data.id,
                        name: data.name,
                        en_name: data.en_name,
                        type: data.type,
                        host: data.host,
                        port: data.port,
                        db_name: data.db_name,
                        username: data.username,
                        password: data.password,
                        extra_params: data.extra_params,
                        remark: data.remark,
                    };
                }

                this.readonly = readonly;
                this.title = readonly ? "查看数据库连接" : "修改数据库连接";
                this.open = true;
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (!this.form.type) {
                        this.msgError("请选择数据库类型");
                        return;
                    }
                    if (this.form.id != undefined) {
                        updateDatabase(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addDatabase(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        testConnect: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (!this.form.type) {
                        this.msgError("请选择数据库类型");
                        return;
                    }
                    this.loadingInstance = Loading.service({
                        text: "拼命加载中",
                        background: "rgba(0,0,0,0.2)",
                        spinner: "el-icon-loading",
                    });
                    testConnect(this.form)
                        .then((response) => {
                            this.loadingInstance.close();
                            if (response.code === 0) {
                                this.msgSuccess("连接成功");
                            } else {
                                this.msgError(response.msg);
                            }
                        })
                        .catch((e) => {
                            this.loadingInstance.close();
                        });
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm("确定删除吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delDatabase(ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有数据库连接数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportCategory(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
    },
};
</script>
