<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="版本" prop="version">
        <el-input
          v-model="queryParams.version"
          placeholder="请输入版本"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否激活" prop="is_active">
        <el-select
          v-model="queryParams.is_active"
          placeholder="是否激活"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in activeOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      stripe
      show-overflow
      highlight-hover-row
      :loading="loading"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="dataList"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>
       <span style="padding-left:5px;color: #1890ff;">{{flow.title}}</span>
      </template>

      <template #flowstatus="{ row }">
        <el-tag
          v-for="dict in statusOptions"
          :type="dict.type"
          v-if="dict.key === row.status"
        >{{dict.value}}</el-tag>
      </template>
      <template #flowversion="{ row }">
        <el-tag type="primary" >{{row.version}}</el-tag>
      </template>
      <template #flowactive="{ row }">
        <el-tag
          v-for="dict in activeOptions"
          :type="dict.type"
          v-if="dict.key === row.is_active"
        >{{dict.value}}</el-tag>
    </template>
      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button type="text" size="mini" icon="el-icon-view" @click.stop="handlePreview(row)" v-hasPermi="['/bpm/resource/get']">预览</el-button>
        <el-button  type="text" size="mini" icon="el-icon-video-play" @click.stop="handleVersion(row)" v-if="false">启用</el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)" v-hasPermi="['/bpm/resource/del']" v-if="false">删除</el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
      background
      size="small"
      :loading="loading"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      :total="total"
      @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改流程对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="名称" />
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <preview-form ref="previewForm"></preview-form>
  </div>
</template>

<script>
  import { listResource, getResource, delResource } from "@/api/bpm/resource";
  import PreviewForm from './preview'
  export default {
    name:'bpm-flow-version',
    data() {
      return {
        // 工具栏
        tableToolbar: {
          perfect: true,
          zoom: true,
          custom: true,
          refresh: {
            query: this.handleQuery
          },
          slots: {
            buttons: 'toolbar_buttons'
          }
        },
        // 列
        tableColumn: [
          {type: 'checkbox', width: 60, fixed: "left"},
          {field: 'id', title: '编号', width: 100, fixed: "left"},
          {field: 'flow_name', title: '流程名称' },
          {field: 'version', title: '版本号' , slots: {default: 'flowversion'}},
          {field: 'status', title: '状态', slots: {default: 'flowstatus'}},
          {field: 'is_active', title: '是否激活', slots: {default: 'flowactive'}},
          {field: 'update_time', title: '更新时间',  formatter: "formatDate"},
          {field: '', title: '操作',resizable:false, width: 250, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
        ],
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 总条数
        total: 0,
        // 流程表格数据
        dataList: [],
        flow: {
          id: 0,
          name: '',
          title: '',
        },
        // 弹出层标题
        title: '',
        // 是否显示弹出层
        open: false,
        // 状态数据字典
        statusOptions: [
          {key:"0",value:'未发布', type:'danger'},
          {key:"1",value:'已发布', type:'success'},
          {key:"2",value:'已挂起', type:'primary'},
        ],
        activeOptions: [
          {key:"0",value:'未激活', type:'danger'},
          {key:"1",value:'已激活', type:'success'},
        ],
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          flow_id:0,
          is_active:undefined,
          name: undefined,
          status:undefined,
          version:undefined,
        },
        // 表单参数
        form: {},
        // 表单校验
        rules: {
          category_id: [
            { required: true, message: "分类不能为空", trigger: "blur" }
          ],
          name: [
            { required: true, message: "名称不能为空", trigger: "blur" }
          ]
        }
      };
    },
    components: {
      PreviewForm
    },
    created() {
      this.flow.id = this.$route.query.id
      this.queryParams.flow_id = this.flow.id
      this.getList();
    },
    methods: {
      handleSizeChange ({ currentPage, pageSize }) {
        this.queryParams.pageNum = currentPage;
        if(this.queryParams.pageSize == pageSize){
          this.getList();
        }else{
          this.queryParams.pageSize = pageSize;
          this.handleQuery();
        }

      },
      checkboxChangeEvent ({ records }) {
        this.ids = records.map(item => item.id)
        this.single = records .length!=1;
        this.multiple = !records .length

      },

      /** 查询流程列表 */
      getList() {
        this.loading = true;
        listResource(this.queryParams).then(response => {
          this.single = true;
          this.multiple = true;
          this.dataList = response.data.list;
          this.flow = response.data.flow;
          this.flow.title = '【' +this.flow['category_name']+'】' +this.flow.name + ' V:'+this.flow.version;
          this.total = response.data.total;
          this.loading = false;
        });
      },
      // 取消按钮
      cancel() {
        this.open = false;
        this.reset();
      },
      // 表单重置
      reset() {
        this.form = {
          id: undefined,
          category_id: undefined,
          status: undefined,
          name: undefined,
          remark: undefined
        };
        this.resetForm("form");
      },
      handlePreview(row){
        this.$refs['previewForm'].init(row.id)
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.resetForm("queryForm");
        this.handleQuery();
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id)
        this.single = selection.length!=1
        this.multiple = !selection.length
      },
      /** 提交按钮 */
      submitForm: function() {
        this.$refs["form"].validate(valid => {
          if (valid) {
          }
        });
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        const ids = row.id || (this.ids ? this.ids.join(',') : '');
        this.$confirm('确定删除该流程版本（影响流程实例及相关历史数据，不可恢复）吗?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delResource(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
      },
      handleVersion(row){

      },
      /** 导出按钮操作 */
      handleExport() {
        const queryParams = this.queryParams;
        this.$confirm('是否确认导出所有流程数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportResource(queryParams);
        }).then(response => {
          this.download(response.msg);
        }).catch(function() {});
      }
    }
  };
</script>
