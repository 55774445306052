<template>
  <div style="position: relative; width: 100%; height: 100%; background-color: rgb(10, 48, 87); display: flex; flex-direction: column;">
    <div style="height: 60px; display: flex; justify-content: center; align-items: center; color: rgb(45, 200, 250); font-size: 20px; font-weight: bold;">{{title}}</div>
    <div ref="container" style="flex: 1; display: flex; flex-direction: column; align-items: center;" class="bi-dzz">
      <el-table v-if="height"
        :data="tableData"
        v-loading="loading"
        element-loading-text="正在加载"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        style="width: 100%; background-color: rgb(10, 48, 87)"
        :header-cell-style="{backgroundColor: 'rgb(19, 93, 166)', color: 'rgb(45, 200, 250)', fontSize: '16px', fontWeight: 'bold', borderColor: 'rgb(45, 200, 250)'}"
        :height="height"
        :stripe="false"
      >
        <el-table-column
          prop="党组织名称"
          label="党组织名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="领域"
          label="领域"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="组织属性"
          label="组织属性"
          align="center"
          >
        </el-table-column>
        <el-table-column
          prop="党组织书记"
          label="党组织书记"
          align="center"
          >
        </el-table-column>
        <el-table-column
          prop="联系电话"
          label="联系电话"
          align="center"
          >
        </el-table-column>
        <div slot="empty"><div v-if="!loading" style="color: rgb(45, 200, 250)">暂无数据</div></div>
      </el-table>
    </div>
    <div class="close-button" @click="handleClose">关闭</div>
  </div>
</template>

<script>
import { execModel } from "@/api/core/data/datasource/model";
import Qs from 'qs'

export default {
  name: 'dzz',
  props: {
    params: {
      type: String,
      default: () => { return undefined }
    },
  },
  data() {
    const params = Qs.parse(this.params || '')
    let title = ''
    if (params.type == '1') {
      title = '优秀基层党组织'
    } else if (params.type == '2') {
      title = '五星级党组织'
    } else if (params.type == '3') {
      title = '四星级党组织'
    } else if (params.type == '4') {
      title = '三星级党组织'
    } else if (params.type == '5') {
      title = '软弱涣散党组织'
    } else if (params.type == '6') {
      title = '后进村党组织'
    } else if (params.type == '7') {
      title = '各级党委（党组）每年至少专题研究1次基层党建工作'
    } else if (params.type == '8') {
      title = '联系党支部工作联系点'
    } else if (params.type == '9') {
      title = '国企和国有控股企业“党建入章”得到落实'
    } else if (params.type == '10') {
      title = '公立医院党委领导下的院长负责制得到落实'
    } else if (params.type == '11') {
      title = '足额落实村级组织办公经费'
    } else if (params.type == '12') {
      title = '落实党组织活动经费'
    }
    return {
      type: params.type,
      title: title,
      tableData: [],
      loading: false,
      height: 0,
    }
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize()
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  created() {
    this.loading = true
    let id = 0
    const params = Qs.parse(this.params || '')
    if (this.type == '1' || this.type == '2' || this.type == '3' || this.type == '4') {
      id = 161
      params.mc = this.title
    } else if (this.type == '5') {
      id = 162
    } else if (this.type == '6') {
      id = 163
    } else if (this.type == '7') {
      id = 168
    } else if (this.type == '8') {
      id = 169
    } else if (this.type == '9') {
      id = 170
    } else if (this.type == '10') {
      id = 171
    } else if (this.type == '11') {
      id = 172
    } else if (this.type == '12') {
      id = 173
    }
    this.tableData = [{'党组织名称': '党组织名称'}, {'党组织名称': '党组织名称'}]
    params['__max_count__'] = 1000
    execModel(id, JSON.stringify(params)).then(response => {
      console.log(response)
      this.tableData = response.data
      this.loading = false
    }).catch(e => {
      this.loading = false
    })
  },
  methods: {
    onWindowResize() {
      this.height = this.$refs.container.offsetHeight
    },
    handleClose() {
      window.postMessage({
        type: 'bi_dialog_close'
      })
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute; 
  right: 10px; 
  top: 10px; 
  color: rgb(45, 200, 250);
}
.close-button:hover {
  cursor: pointer;
}
::v-deep .el-table tr {
    background-color: rgb(10, 48, 87);
    color: rgb(45, 200, 250);
    font-size: 16px;
    border: none;
}
::v-deep .el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
}
::v-deep .el-table--enable-row-hover .el-table__body tr > td {
  border: none;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(255, 255, 255, 0.01) !important;
}
::v-deep .bi-dzz .el-table__body-wrapper::-webkit-scrollbar {
  width:8px;
  background-color: rgba(10, 48, 87);
}
::v-deep .bi-dzz .el-table__body-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:8px;
}
::v-deep .bi-dzz .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius:8px;
  background:rgba(255,255,255,0.1);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
::v-deep .bi-dzz .el-table__body-wrapper::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(255,255,255,0.1);
}
::v-deep .bi-dzz .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background:rgba(255,255,255,0.2);
}
::v-deep .bi-dzz .el-table__header .has-gutter th.gutter {
  background-color: rgb(19, 93, 166);
  border-bottom: solid 1px rgb(45, 200, 250);
}
</style>