import request from '@/utils/request'

// 查询操作日志列表
export function list(userpool, query) {
  return request({
    url: '/ucenter/' + userpool + '/operlog/list',
    method: 'get',
    params: query
  })
}

// 删除操作日志
export function delOperlog(userpool, ids) {
  return request({
    url: '/ucenter/' + userpool + '/operlog/del',
    method: 'post',
    data:{
      ids: ids
    }
  })
}

// 清空操作日志
export function clearOperlog(userpool) {
  return request({
    url: '/ucenter/' + userpool + '/operlog/clear',
    method: 'post'
  })
}
