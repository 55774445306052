import request from '@/utils/request'
// 指标列表
export function Indexlist(query) {
    var data = query;

    return request({
        url: '/growthindicators/gyxy/indexform/indexlist',
        method: 'post',
        data: data
    })
}
// 列表
export function indextableinfoList(query) {
    var data = query;

    return request({
        url: '/growthindicators/gyxy/indextableinfo/list',
        method: 'post',
        data: data
    })
}


// 列表
export function indextableinfoSave(query) {
    var data = query;

    return request({
        url: '/growthindicators/gyxy/indextableinfo/save',
        method: 'post',
        data: data
    })
}
export function indextableinfoApply(query) {
    var data = query;

    return request({
        url: '/growthindicators/gyxy/indextableinfo/apply',
        method: 'post',
        data: data
    })
}

//表单数据列表
export function dataList(index_id, query) {

    var data = {
        index_id: index_id,
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
        page_size: query.pageSize,
        page: query.pageNum
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr") {
            filterstr[k] = item;
        }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);

    return request({
        url: '/growthindicators/gyxy/indexform/datalist',
        method: 'post',
        data: data
    })
}

export function datacolList(index_id) {
    return request({
        url: '/growthindicators/gyxy/indexform/info',
        method: 'post',
        data: {index_id: index_id}
    })


}
export function dataAdd(index_id, datalist) {
    return request({
        url: '/growthindicators/gyxy/indexform/add',
        method: 'post',
        data: {index_id: index_id, datalist: JSON.stringify([datalist])}
    })

}
export function dataEdit(index_id, id, datalist) {
    return request({
        url: '/growthindicators/gyxy/indexform/edit',
        method: 'post',
        data: {index_id: index_id, id: id, datalist: JSON.stringify([datalist])}
    })

}
export function dataGet(index_id,id) {
    return request({
        url: '/growthindicators/gyxy/indexform/get',
        method: 'post',
        data: {index_id:index_id,id: id}
    })

}
export function dataDel(index_id,id) {
    return request({
        url: '/growthindicators/gyxy/indexform/del',
        method: 'post',
        data: {index_id:index_id,id: id}
    })

}export function importTemplate(index_id) {
    return request({
        url: '/growthindicators/gyxy/indexform/downtpl',
        method: 'post',
        data: {index_id:index_id}
    })

}