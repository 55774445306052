import request from '@/utils/request'

export function listGbpxCourseUnit(query) {
  return request({
    url: '/gbpx/gbpxcourseunit/list',
    method: 'get',
    params: query
  })
}

export function getGbpxCourseUnit(id) {
  return request({
    url: '/gbpx/gbpxcourseunit/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxCourseUnit(data) {
  return request({
    url: '/gbpx/gbpxcourseunit/add',
    method: 'post',
    data: data
  })
}

export function AddBatchGbpxCourseUnit(ids) {
  return request({
    url: '/gbpx/gbpxcourseunit/addBatch',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function editGbpxCourseUnit(data) {
  return request({
    url: '/gbpx/gbpxcourseunit/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxCourseUnit(ids) {
  return request({
    url: '/gbpx/gbpxcourseunit/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

