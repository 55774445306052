import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2023-10-30 15:43:06
class Comment {
// 查询comment列表
 listComment(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/moments/comment/list',
        method: 'post',
        data: data
    })
}

// 查看comment
 getComment ( id) {
    return request({
        url: '/moments/comment/get?id='+id,
        method: 'get'
    })
}


// 新增comment
 addComment(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/moments/comment/add',
        method: 'post',
        data: postdata
    })
}

// 修改comment
 editComment(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid,
          dataid:data.id
      }

    return request({
        url: '/moments/comment/edit',
        method: 'post',
        data: postdata
    })
}

// 删除comment
 delComment(ids) {
    return request({
        url: '/moments/comment/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 commentImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/moments/comment/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 commentExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/moments/comment/export?` + parata.join("&") ;


 }

 //下导入模板
  commentimportTemplate() {

     return   `/moments/comment/importTemplate`   ;

  }

}

const instance = new Comment();
export default instance;