<!-- 生成时间:2022-06-16 16:37:28 -->

<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
        >
            <el-form-item label="工号" prop="no">
                <el-input
                    v-model="queryParams.no"
                    placeholder="请输入工号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="姓名" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入姓名"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="所属部门" prop="dept_no">
                <dept-select
                    v-model="queryParams.dept_no"
                    placeholder="请选择所属部门"
                    valueField="no"
                    size="small"
                />
            </el-form-item>
            <el-form-item label="性别" prop="gender">
                <el-select
                    v-model="queryParams.gender"
                    placeholder="请选择性别"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in genderOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="问题类型" prop="category_names">
                <el-input
                    v-model="queryParams.category_names"
                    placeholder="请输入问题类型"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="职称" prop="title">
                <el-input
                    v-model="queryParams.title"
                    placeholder="请输入职称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="是否生成" prop="sync_account">
                <el-select
                    v-model="queryParams.sync_account"
                    placeholder="请选择是否生成"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in sync_accountOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/jyzx/teacher/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/jyzx/teacher/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/jyzx/teacher/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-refresh-left"
                    size="mini"
                    @click="handleSyncAccount"
                    v-hasPermi="['/jyzx/teacher/syncAccount']"
                    >同步账号</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-upload"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['/jyzx/teacher/import']"
                    >导入</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/jyzx/teacher/export']"
                    v-if="false"
                    >导出</el-button
                >
            </template> -->

            <!--默认操作按钮-->
            <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    v-hasPermi="['/jyzx/teacher/edit']"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    v-hasPermi="['/jyzx/teacher/del']"
                    >删除
                </el-button>
            </template> -->

            <!--自定义空数据模板-->
            <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="query_qarams_item"> 
                <el-form-item label="所属部门" prop="dept_no">
                <dept-select
                    v-model="queryParams.dept_no"
                    placeholder="请选择所属部门"
                    valueField="no"
                    size="small"
                />
            </el-form-item>
            </template>
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/jyzx/teacher/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/jyzx/teacher/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/jyzx/teacher/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-refresh-left"
                    size="mini"
                    @click="handleSyncAccount"
                    v-hasPermi="['/jyzx/teacher/syncAccount']"
                    >同步账号</el-button
                >

                <el-button
                    type="warning"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['/jyzx/teacher/import']"
                >
                    <i class="iconfont icon-daoru_o"></i>
                    导入
                </el-button>

                <el-button
                    class="btn-orange"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/jyzx/teacher/export']"
                >
                    <i class="iconfont icon-daochu_o"></i>
                    导出
                </el-button>
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    v-hasPermi="['/jyzx/teacher/edit']"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    v-hasPermi="['/jyzx/teacher/del']"
                    >删除
                </el-button>
            </template>
        </jf-table>
        <!-- 添加或修改对话框 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="工号" prop="no">
                    <el-input
                        v-model="form.no"
                        placeholder="请输入工号"
                        :disabled="form.id > 0"
                    />
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="form.name" placeholder="请输入姓名" />
                </el-form-item>
                <el-form-item label="拼音首字母" prop="letter">
                    <el-input
                        v-model="form.letter"
                        placeholder="请输入大写的姓命拼音首字母"
                    />
                </el-form-item>
                <el-form-item label="所属部门" prop="dept_no">
                    <dept-select
                        v-model="form.dept_no"
                        placeholder="请选择所属部门"
                        valueField="no"
                        size="small"
                    />
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                    <el-select
                        v-model="form.gender"
                        placeholder="请选择性别"
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in genderOptions"
                            :key="dict.key"
                            :label="dict.value"
                            :value="dict.key"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="用户邮箱" prop="email">
                    <el-input
                        v-model="form.email"
                        placeholder="请输入用户邮箱"
                    />
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input
                        v-model="form.mobile"
                        placeholder="请输入手机号"
                    />
                </el-form-item>
                <el-form-item label="QQ" prop="qq">
                    <el-input v-model="form.qq" placeholder="请输入QQ" />
                </el-form-item>
                <el-form-item label="问题类型" prop="category_ids">
                    <el-select
                        v-model="form.category_ids"
                        placeholder="请选择"
                        multiple
                        style="width: 100%"
                    >
                        <template v-for="key in categoryMap.keys()">
                            <el-option-group
                                :key="key"
                                :label="parentMap.get(key)"
                            >
                                <el-option
                                    v-for="(item, index) in categoryMap.get(
                                        key
                                    )"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-option-group>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="职称" prop="title">
                    <el-input v-model="form.title" placeholder="请输入职称" />
                </el-form-item>
                <el-form-item label="简介" prop="intro">
                    <el-input
                        v-model="form.intro"
                        type="textarea"
                        placeholder="请输入简介"
                    />
                </el-form-item>
                <el-form-item label="备注信息" prop="remark">
                    <el-input
                        v-model="form.remark"
                        type="textarea"
                        placeholder="请输入备注信息"
                    />
                </el-form-item>
                <el-form-item label="是否生成" prop="sync_account">
                    <el-select
                        v-model="form.sync_account"
                        placeholder="请选择是否生成"
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in sync_accountOptions"
                            :key="dict.key"
                            :label="dict.value"
                            :value="dict.key"
                        />
                    </el-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>

        <!--高级查询对话框-->
        <el-dialog
            title="高级查询"
            :visible.sync="advqueryopen"
            width="1300px"
            append-to-body
        >
            <el-row :gutter="20">
                <el-col :span="15" :xs="24">
                    <vxe-grid
                        border
                        resizable
                        keep-source
                        ref="xAdvqueryGrid"
                        id="id_xAdvqueryGrid"
                        height="500"
                        :columns="advqueryColumn"
                        :toolbar-config="advqueryToolbar"
                        :data="advqueryData"
                        :custom-config="{ storage: true }"
                        :edit-config="{
                            trigger: 'click',
                            mode: 'row',
                            showStatus: true,
                        }"
                        @toolbar-button-click="advqtoolbarButtonClickEvent"
                    >
                        <template #defaultadvqueryopr="{ row }"> </template>
                    </vxe-grid>
                </el-col>
                <el-col :span="9" :xs="24">
                    <vxe-grid
                        border
                        resizable
                        keep-source
                        ref="xAdvqueryGrid_tempfilter"
                        id="id_xAdvqueryGrid_tempfilter"
                        height="500"
                        :columns="advqueryColumn_tempfilter"
                        :data="advqueryData_tempfilter"
                    >
                        <template #defaultadvqueryopr_tempfilter="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleadvqueryApplytpl(row)"
                                >应用
                            </el-button>

                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleadvqueryDeltpl(row)"
                                >删除
                            </el-button>
                        </template>
                    </vxe-grid>
                </el-col>
            </el-row>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="advquery_save"
                    >确 定</el-button
                >
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!--保存模版-->
        <el-dialog
            :close-on-click-modal="false"
            title="保存模版"
            :visible.sync="advquery_tempsave"
            width="800px"
            append-to-body
        >
            <el-form
                ref="advquery_tempsaveform"
                :model="advquery_tempsaveform"
                :rules="advquery_tempsaverules"
                label-width="80px"
            >
                <el-form-item label="模版名称" prop="tempname">
                    <el-input
                        v-model="advquery_tempsaveform.tempname"
                        placeholder="请输入模版名称"
                    />
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="advquery_save"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <!--导出数据-->
        <el-dialog
            :close-on-click-modal="false"
            title="导出数据"
            :visible.sync="exportdialog"
            width="800px"
            append-to-body
        >
            <el-checkbox
                :indeterminate="exportisIndeterminate"
                v-model="exportcheckAll"
                @change="handleexportCheckAllChange"
                >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>

            <el-checkbox-group v-model="exportfields">
                <el-checkbox
                    v-for="field in tableColumn"
                    v-if="field.field"
                    :label="field.field"
                    :key="field.field"
                    >{{ field.title }}</el-checkbox
                >
            </el-checkbox-group>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doExport">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>

        <el-dialog
            :title="upload.title"
            :visible.sync="upload.open"
            width="400px"
            append-to-body
        >
            <el-upload
                ref="upload"
                :limit="1"
                accept=".xlsx"
                :headers="upload.headers"
                :action="upload.url + '?update=' + upload.update"
                :disabled="upload.isUploading"
                :on-progress="handleImportProgress"
                :on-success="handleImportSuccess"
                :on-error="handleImportError"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <el-checkbox
                        v-model="upload.update"
                    />是否更新已经存在的数据
                    <el-link
                        type="info"
                        style="font-size: 12px"
                        @click="handleImportTemplate"
                        >下载模板</el-link
                    >
                </div>
                <div class="el-upload__tip" style="color: red" slot="tip">
                    提示：仅允许导入“xlsx”格式文件！
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitImportForm"
                    >确 定</el-button
                >
                <el-button @click="upload.open = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getAccessToken } from "@/utils/tokenTool";
import {
    listTeacher,
    getTeacher,
    delTeacher,
    addTeacher,
    editTeacher,
    advquerydelTeacher,
    advquerysaveTeacher,
    advquerylistTeacher,
    exportTeacher,
    syncAccount,
} from "@/api/jyzx/teacher";

import { listCategory } from "@/api/jyzx/category";
import VDistpicker from "@/components/VDistpicker";
export default {
    components: { VDistpicker },
    name: "jyzx-teacher-list",

    data() {
        return {
            dialogImageUrl: "",
            businessname: "teacher", //请勿改动
            exportfields: [],
            exportcheckAll: false,
            exportisIndeterminate: false,
            exportdialog: false,

            //编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },

            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "id", minWidth: 80, visible: false },
                {
                    field: "no",
                    title: "工号",
                    minWidth: 80,
                    fixed: "left",
                    is_query: true,
                },
                { field: "name", title: "姓名", minWidth: 80, is_query: true },
                {
                    field: "dept_no",
                    title: "所属部门",
                    minWidth: 80,
                    visible: false,
                },
                {
                    field: "dept_name",
                    title: "所属部门",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "gender",
                    title: "性别",
                    minWidth: 80,
                    formatter: this.genderFormat,
                    options: [
                        { key: "1", value: "男" },
                        { key: "2", value: "女" },
                        { key: "0", value: "保密" },
                    ],
                    options_label: "value",
                    options_value: "key",
                    is_query: true,
                    form_type:2
                },
                { field: "email", title: "用户邮箱", minWidth: 80 },
                { field: "mobile", title: "手机号", minWidth: 80 },
                { field: "qq", title: "QQ", minWidth: 80 },
                {
                    field: "category_names",
                    title: "问题类型",
                    minWidth: 80,
                    is_query: true,
                },
                { field: "title", title: "职称", minWidth: 80, is_query: true },
                {
                    field: "remark",
                    title: "备注信息",
                    minWidth: 80,
                    visible: false,
                },
                {
                    field: "sync_account",
                    title: "是否生成账号",
                    minWidth: 80,
                    formatter: this.sync_accountFormat,
                    options: [
                        { key: "1", value: "是" },
                        { key: "0", value: "否" },
                    ],
                    options_label: "value",
                    options_value: "key",
                    is_query: true,
                    form_type:2
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 80,
                    formatter: "formatDate",
                },

                // {
                //     field: "",
                //     title: "操作",
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],

            advqueryColumn: [
                {
                    field: "qfield",
                    title: "查询字段",
                    width: 180,
                    fixed: "left",
                    editRender: {
                        name: "select",
                        options: [
                            { value: "dd", label: "cxvs" },
                            { value: "zz", label: "cxvs" },
                        ],
                    },
                },
                {
                    field: "qopr",
                    title: "操作符",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "qvalue",
                    title: "值",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "input" },
                },
                {
                    field: "qlogic",
                    title: "逻辑",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 110,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultadvqueryopr" },
                },
            ],
            advqueryColumn_tempfilter: [
                {
                    field: "tempname",
                    title: "模版名称",
                    width: 200,
                    fixed: "left",
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultadvqueryopr_tempfilter" },
                },
            ],
            advqueryData_tempfilter: [],
            advqueryopen: false,
            advquery_tempsave: false,
            advqueryData: [],
            advqueryToolbar: {
                buttons: [
                    { code: "myInsert", name: "新增" },
                    { code: "dofilter", name: "使用此组合条件查询" },
                ],
            },
            upload: {
                open: false,
                title: "",
                isUploading: false,
                update: false,
                headers: { Authorization: getAccessToken() },
                url: process.env.VUE_APP_BASE_API + "/jyzx/teacher/import",
            },
            dialogVisible: false,

            categoryMap: new Map(),
            parentMap: new Map(),
            categoryAllMap: new Map(),
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 字典
            genderOptions: [
                { key: "1", value: "男" },
                { key: "2", value: "女" },
                { key: "0", value: "保密" },
            ],
            sync_accountOptions: [
                { key: "1", value: "是" },
                { key: "0", value: "否" },
            ],

            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                opstr: {
                    no: "=",
                    name: "LIKE",
                    dept_no: "=",
                    gender: "=",
                    category_names: "=",
                    title: "=",
                    remark: "LIKE",
                    sync_account: "=",
                },
                no: undefined,
                name: undefined,
                dept_no: undefined,
                gender: undefined,
                category_names: undefined,
                title: undefined,
                remark: undefined,
                sync_account: undefined,
            },
            // 表单参数
            form: {},
            advquery_tempsaveform: {},
            advquery_tempsaverules: {
                tempname: [
                    {
                        required: true,
                        message: "模版名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            // 表单校验
            rules: {
                no: [
                    {
                        required: true,
                        message: "工号不能为空",
                        trigger: "blur",
                    },
                ],

                name: [
                    {
                        required: true,
                        message: "姓名不能为空",
                        trigger: "blur",
                    },
                ],

                dept_no: [
                    {
                        required: true,
                        message: "所属部门不能为空",
                        trigger: "blur",
                    },
                ],

                gender: [
                    {
                        required: true,
                        message: "性别不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.categoryMap = new Map();
        this.categoryAllMap = new Map();
        listCategory({
            opstr: {},
            pageSize: 999,
            pageNum: 1,
        }).then((response) => {
            response.data.list.forEach((item) => {
                this.categoryAllMap.set(item.id, item.name);
                if (item.parent_id === 0) {
                    this.categoryMap.set(item.id, []);
                    this.parentMap.set(item.id, item.name);
                } else {
                    if (this.categoryMap.has(item.parent_id)) {
                        let list = this.categoryMap.get(item.parent_id);
                        list.push(item);
                    } else {
                        this.categoryMap.set(item.parent_id, [item]);
                    }
                }
            });
        });
        this.getList();

        setTimeout(() => {
            const $grid = this.$refs.xAdvqueryGrid;
            // 异步更新下拉选项
            var ta = [{ value: "", label: "请选择" }];
            for (var i in this.tableColumn) {
                var e = this.tableColumn[i];
                if (e.field != null && e.field != "") {
                    ta.push({ value: e.field, label: e.title });
                }
            }
            this.advqueryColumn[0].editRender.options = ta;

            this.advqueryColumn[1].editRender.options = this.defaultQlogic();

            ta = [];
            ta.push({ value: "AND", label: "并且" });
            ta.push({ value: "OR", label: "或者" });
            this.advqueryColumn[3].editRender.options = ta;
        }, 300);
        // 加载字典数据
        this.getDicts("sys_user_gender").then((response) => {
            this.genderOptions = response.data.values || [];
        });

        this.getDicts("stu_yes_no").then((response) => {
            this.sync_accountOptions = response.data.values || [];
        });
    },
    methods: {
        //列表的筛选和排序
        tableChange(e) {},
        //区域选择事件
        handleSyncAccount() {
            this.$confirm("确定同步账号吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: "正在同步账号",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                syncAccount(this.queryParams)
                    .then((response) => {
                        loading.close();
                        if (response.code == 0) {
                            this.msgSuccess(
                                "成功同步" + response.data.count + "个账号"
                            );
                        }
                    })
                    .catch((err) => {
                        loading.close();
                    });
            });
        },
        handleImport() {
            this.upload.title = "数据导入";
            this.upload.update = false;
            this.upload.open = true;
        },
        handleImportTemplate() {
            this.downloadbyurl("/jyzx/teacher/importTemplate");
        },
        submitImportForm() {
            this.$refs.upload.submit();
        },
        handleImportProgress(event, file, fileList) {
            this.upload.isUploading = true;
            if (event.percent == 100) {
                this.upload.open = false;
                this.upload.loading = this.$loading({
                    lock: true,
                    text: "正在导入数据",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
        },
        //表单上传字段成功处理
        handleImportSuccess(response, file, fileList) {
            if (this.upload.loading) {
                this.upload.loading.close();
            }
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            this.$alert(
                response.code == 0 ? "导入成功 " : response.msg,
                "提示",
                { dangerouslyUseHTMLString: true }
            );
            this.getList();
        },
        handleImportError(err, file, fileList) {
            if (this.upload.loading) {
                this.upload.loading.close();
            }
            this.upload.isUploading = false;
            this.$alert("导入失败", "提示", { dangerouslyUseHTMLString: true });
        },
        handleUpload() {},
        handleExport() {
            if (this.total > 0) {
                this.exportdialog = true;
            } else {
                this.msgError("暂无数据可导出!");
            }
        },
        doExport() {
            let that = this;
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportTeacher(
                        queryParams,
                        that.exportfields,
                        that.tableColumn
                    );
                })
                .then((response) => {
                    this.download(response.file);
                });
        },
        handleexportCheckAllChange(val) {
            this.exportfields = val
                ? this.tableColumn.map((item) => {
                      return item.field;
                  })
                : [];
            this.exportisIndeterminate = false;
        },
        //删除查询模版
        handleadvqueryDeltpl(row) {
            var ids = row.id;
            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    advquerydelTeacher(ids)
                        .then(() => {
                            const $grid = this.$refs.xAdvqueryGrid_tempfilter;
                            $grid.remove(row);
                        })
                        .then(() => {
                            this.msgSuccess("删除成功");
                        });
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        //打开高级查询
        handleAdvquery() {
            advquerylistTeacher(this.businessname).then((response) => {
                this.advqueryData_tempfilter = response.data.list;
                this.advqueryopen = true;
            });
        },
        //保存条件
        advquery_save() {
            const $grid = this.$refs.xAdvqueryGrid;
            var alldata = $grid.getTableData();
            if (alldata.fullData.length == 0) {
                return false;
            }

            var opstr = {};
            var filteroptview = [];
            var Qlogicar = this.defaultQlogic();
            var queryParams = {};
            var fields = {},
                Qlogic = {};

            for (var i in this.tableColumn) {
                var col = this.tableColumn[i];
                if (col.field != null && col.field != "") {
                    fields[col.field] = col.title;
                }
            }

            for (var i in Qlogicar) {
                var lgc = Qlogicar[i];
                Qlogic[lgc.value] = lgc.label;
            }

            for (var i in alldata.fullData) {
                var onerow = alldata.fullData[i];
                queryParams[onerow.qfield] = onerow.qvalue;
                opstr[onerow.qfield] = onerow.qopr;
                filteroptview.push(
                    fields[onerow.qfield] +
                        " " +
                        Qlogic[onerow.qopr] +
                        "" +
                        onerow.qvalue
                );
            }

            queryParams.opstr = opstr;
            this.advquery_tempsaveform["businessname"] = this.businessname;
            this.advquery_tempsaveform["filteropt"] =
                JSON.stringify(queryParams);
            this.advquery_tempsaveform["filteroptview"] =
                filteroptview.join(";");

            this.$refs["advquery_tempsaveform"].validate((valid) => {
                if (valid) {
                    advquerysaveTeacher(this.advquery_tempsaveform).then(
                        (response) => {
                            if (response.code === 0) {
                                this.msgSuccess("保存成功");
                                this.advquery_tempsave = false;
                                this.handleAdvquery();
                            }
                        }
                    );
                }
            });
        },

        //应用查询模版
        handleadvqueryApplytpl(row) {
            var Filteropt = JSON.parse(row.filteropt);

            Filteropt["pageNum"] = 1;
            Filteropt["pageSize"] = 10;
            this.queryParams = Filteropt;
            this.advqueryopen = false;
            setTimeout(() => {
                this.getList();
            }, 300);
        },
        //删除临时条件
        handleadvqueryDeleted(row) {
            const $grid = this.$refs.xAdvqueryGrid;

            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    $grid.remove(row);
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        advqtoolbarButtonClickEvent({ code }) {
            const $grid = this.$refs.xAdvqueryGrid;
            switch (code) {
                case "myInsert":
                    var row = $grid.insert({
                        qfield: "",
                        qopr: "=",
                        qlogic: "AND",
                    });

                    $grid.setActiveRow($grid.getData(0));
                    break;

                case "dosavefilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }
                    this.advquery_tempsave = true;
                    break;
                case "dofilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }

                    var opstr = {};

                    var queryParams = {
                        pageNum: 1,
                        pageSize: 10,
                        opstr: {},
                    };

                    for (var i in alldata.fullData) {
                        var onerow = alldata.fullData[i];
                        queryParams[onerow.qfield] = onerow.qvalue;
                        opstr[onerow.qfield] = onerow.qopr;
                    }

                    queryParams.opstr = opstr;
                    this.queryParams = queryParams;
                    this.advqueryopen = false;
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    break;
            }
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        /** 查询列表 */
        getList() {
            this.loading = true;

            listTeacher(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // $comment字典翻译
        genderFormat({ cellValue }) {
            return this.selectDictLabel(this.genderOptions, "" + cellValue);
        },

        sync_accountFormat({ cellValue }) {
            return this.selectDictLabel(
                this.sync_accountOptions,
                "" + cellValue
            );
        },

        // 取消按钮
        cancel() {
            this.open = false;
            this.advqueryopen = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                no: undefined,
                name: undefined,
                letter: undefined,
                dept_no: undefined,
                gender: undefined,
                email: undefined,
                mobile: undefined,
                qq: undefined,
                category_ids: undefined,
                category_names: undefined,
                title: undefined,
                intro: undefined,
                sync_account: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery(e) {
            if (e && Object.keys(e).length) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getTeacher(id).then((response) => {
                this.form = response.data.data;
                let categoryIdsStr = this.form.category_ids
                    ? this.form.category_ids.split(",")
                    : [];
                this.form.category_ids = [];
                for (let i = 0; i < categoryIdsStr.length; i++) {
                    this.form.category_ids.push(parseInt(categoryIdsStr[i]));
                }
                this.open = true;
                this.title = "修改";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    let categoryIds = this.form.category_ids;
                    let categoryNames = [];
                    for (let i = 0; i < categoryIds.length; i++) {
                        categoryNames.push(
                            this.categoryAllMap.get(categoryIds[i])
                        );
                    }
                    this.form.category_ids = categoryIds.join(",");
                    //获取对应的名称
                    this.form.category_names = categoryNames.join("、");
                    if (this.form.id != null) {
                        editTeacher(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addTeacher(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;

            this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delTeacher(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
    }, //methods结束
};
</script>
