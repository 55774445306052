<template>
    <div class="ai-create-index">
        <el-row :gutter="20">
            <el-col :span="8">
                <el-card class="box-card">
                    <div>
                        <el-tabs
                            type="border-card"
                            tab-position="left"
                            @tab-click="tabsClick"
                            :stretch="true"
                            v-model="tabActive"
                            :style="{ height: contentHeight - 45 + 'px' }"
                        >
                            <el-tab-pane
                                :label="item.title"
                                v-for="(item, index) in menus1"
                                :key="index"
                            ></el-tab-pane>
                            <div v-if="menus1[tabActive].title === '智能会话'">
                                <div
                                    class="menu-item menu-item-input d-flex justify-content-center align-items-center text-primary"
                                    style=""
                                    @click="addChatMenu()"
                                >
                                    <i class="el-icon-plus"></i>
                                    <div
                                        v-text="'新建会话'"
                                        class="ml-10 font-size-15"
                                    ></div>
                                </div>
                                <div
                                    class="menu-list"
                                    :style="{
                                        height: contentHeight - 155 + 'px',
                                    }"
                                >
                                    <div
                                        class="menu-item d-flex justify-content-between align-items-center"
                                        :class="{
                                            active:
                                                chatMenusActive * 1 === index,
                                        }"
                                        v-for="(item, index) in chatMenus"
                                        :key="index"
                                        @click.stop="chatMenuChange(index)"
                                    >
                                        <i class="el-icon-chat-dot-round"></i>
                                        <div class="ml-10 flex-1 font-size-15">
                                            <el-input
                                                v-model="editTlite"
                                                size="mini"
                                                v-if="item.type === 'input'"
                                            ></el-input>
                                            <div
                                                v-else
                                                v-text="item.title"
                                                class="text-overflow"
                                            ></div>
                                        </div>

                                        <i
                                            @click="editChatMenu(index)"
                                            v-if="chatMenusActive * 1 === index"
                                            class="el-icon-edit cursor-pointer ml-10"
                                            :class="
                                                item.type === 'input'
                                                    ? 'el-icon-check'
                                                    : 'el-icon-edit'
                                            "
                                        ></i>
                                        <i
                                            class="ml-2 cursor-pointer"
                                            v-if="chatMenusActive * 1 === index"
                                            @click="deleteChatMenu(index)"
                                            :class="
                                                item.type === 'input'
                                                    ? 'el-icon-close'
                                                    : 'el-icon-delete'
                                            "
                                        ></i>
                                    </div>
                                </div>

                                <div class="px-15">
                                    <el-button
                                        @click="clearChatMenu"
                                        icon="el-icon-delete"
                                        class="w-100 delete-btn update-chat-btn"
                                        >清除所有会话</el-button
                                    >
                                </div>
                            </div>
                            <div v-if="menus1[tabActive].title !== '智能会话'">
                                <div
                                    class="menu-item d-flex align-items-center justify-content-between menu-item-input"
                                >
                                    <el-input
                                        placeholder="请输入关键词进行搜索功能"
                                        v-model="keywords"
                                        clearable
                                        @clear="getMenus"
                                        @keyup.enter.native="getMenus"
                                    ></el-input>
                                    <i
                                        class="el-icon-search color-primary"
                                        @click="getMenus"
                                    ></i>
                                </div>
                                <div
                                    class="menu-list"
                                    :style="{
                                        height: contentHeight - 125 + 'px',
                                    }"
                                >
                                    <div
                                        class="menu-item d-flex align-items-center"
                                        v-for="(item, index) in menus"
                                        :key="index"
                                        @click="menuChange(item, index)"
                                        :class="{
                                            active: menuActive === index,
                                        }"
                                    >
                                        <el-image
                                            class="menu-item-icon"
                                            :src="item.icon || menuIcon"
                                        ></el-image>
                                        <div
                                            v-text="item.name"
                                            class="ml-10 font-size-15"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </el-tabs>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card
                    class="box-card"
                    :style="{
                        height: contentHeight + 'px',
                        position: 'relative',
                    }"
                >
                    <div
                        v-text="getTitle"
                        class="text-center font-size-18 font-weight-500 pb-25"
                    ></div>
                    <div
                        class="chat-box"
                        ref="chatBox"
                        :style="{
                            height: chatBoxHeight,
                            'overflow-y': markdownText.length
                                ? 'hidden'
                                : 'auto',
                        }"
                        @scroll="getScroll"
                    >
                        <template v-if="markdownText">
                            <markdown-editor
                                ref="markdownView"
                                :initData="markdownText"
                                @replace="toReplace"
                                @closeMenu="closeMenu"
                                @copy="getCopyMd"
                                :height="chatBoxHeight"
                            ></markdown-editor>
                        </template>

                        <template v-if="chatList.length && !markdownText">
                            <div v-for="(item, index) in chatList" :key="index">
                                <div v-if="item.history">
                                    <div
                                        v-if="index !== 0"
                                        class="divider-line"
                                    >
                                        <el-divider
                                            ><span
                                                class="font-size-14 text-9A9A9A"
                                                >以上是历史会话</span
                                            ></el-divider
                                        >
                                    </div>
                                    <div
                                        class="chat-box-answer"
                                        v-if="item.bot_name"
                                    >
                                        <div
                                            class="chat-box-answer-content flex-1 ml-75"
                                        >
                                            <div
                                                class="font-size-16"
                                                v-if="item.bot_desc"
                                                v-text="item.bot_desc"
                                            ></div>
                                            <div
                                                class="font-size-14"
                                                v-if="item.template"
                                                v-text="item.template"
                                            ></div>
                                            <ul
                                                class="tag-detail"
                                                v-if="
                                                    item.example &&
                                                    item.example.length
                                                "
                                            >
                                                <template
                                                    v-for="(
                                                        example_item,
                                                        example_index
                                                    ) in item.example"
                                                >
                                                    <li
                                                        v-if="
                                                            example_item.content
                                                        "
                                                        :key="example_index"
                                                        v-text="
                                                            example_item.content
                                                        "
                                                        @click="
                                                            exampleChange(
                                                                example_item
                                                            )
                                                        "
                                                    ></li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-box-ask" v-if="item.content">
                                    <div
                                        class="chat-box-ask-content flex-1"
                                        style="font-size: 16px"
                                        v-html="getConvert(item.content)"
                                    ></div>
                                    <el-image
                                        class="chat-box-ask-avatar ml-15"
                                        :src="avatar"
                                    ></el-image>
                                </div>
                                <div
                                    class="chat-box-answer"
                                    v-if="item.result != undefined"
                                >
                                    <div class="chat-box-answer-avatar">
                                        <el-image
                                            style="width: 60px; height: 60px"
                                            :src="answerAvatar"
                                            fit="cover"
                                        ></el-image>
                                    </div>
                                    <div
                                        class="chat-box-answer-content flex-1 ml-15"
                                    >
                                        <markdown-it-vue
                                            :content="item.result.trim()"
                                        />
                                        <!-- <div>
                                            <el-button type="primary" icon="el-icon-video-play" @></el-button>
                                           </div> -->

                                        <div
                                            class="chat-loading"
                                            v-if="
                                                !item.result.length &&
                                                item.loading
                                            "
                                        ></div>

                                        <div class="mt-5" v-if="!item.loading">
                                            <el-button
                                                class="text-primary"
                                                type="text"
                                                @click="getCopy(item)"
                                                >复制</el-button
                                            >
                                            <el-button
                                                class="text-grey"
                                                type="text"
                                                @click="getCopy2(item.result)"
                                                >编辑</el-button
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="!markdownText && !chatList.length">
                            <div class="tag-list">
                                <div
                                    class="tag-list-item"
                                    v-for="(item, index) in tagList"
                                    :key="index"
                                    @click="tagChange(item, index)"
                                >
                                    <!-- :class="{ active: tagIndex === item }" -->
                                    {{ item.bot_name }}
                                </div>
                            </div>
                        </template>
                    </div>
                    <!-- {{tagIndex}} -->
                    <div
                        class="sending-chat"
                        v-if="
                            !markdownText &&
                            (tabActive * 1 === 1 ? tagIndex * 1 > -1 : true)
                        "
                    >
                        <el-button
                            class="mt-1 update-chat-btn"
                            v-if="this.chatList.length"
                            type="primary"
                            size="small"
                            icon="el-icon-refresh"
                            plain
                            @click="removeContext"
                        >
                            全新会话
                        </el-button>
                        <div class="d-flex justify-content-between pos-r mt-10">
                            <el-input
                                :placeholder="getPlaceholder"
                                class="chat-input chat-textarea"
                                type="textarea"
                                :rows="2"
                                resize="none"
                                :autosize="{ minRows: 2, maxRows: 7 }"
                                v-model="value"
                                @keydown.enter.prevent.native="sendMessage"
                                @keydown.enter.shift.native="addNewLine"
                                ref="myTextarea"
                            ></el-input>
                            <!-- @input="getChatBoxHeight"
                                    @blur="getChatBoxHeight" -->
                            <div class="pos-a bottom-10 right-15">
                                <!-- <i class=" color-primary el-icon-s-promotion"></i> -->
                                <i
                                    class="btn-bg-primary btn-submit font-size-24 el-icon-s-promotion"
                                    type="primary"
                                    @click="onSubmit"
                                    :loading="loading"
                                    v-if="!showStop && !markdownText"
                                ></i>
                                <i
                                    class="btn-bg-primary btn-submit font-size-24 el-icon-video-play"
                                    type="primary"
                                    v-if="showStop && !markdownText"
                                    @click="getStop"
                                ></i>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div class="answer-avatar" v-if="markdownText">
            <el-tooltip
                class="item"
                effect="dark"
                content="退出编辑"
                placement="top"
            >
                <img :src="answerAvatar" alt="" @click="exitMarkdownEdit" />
            </el-tooltip>
        </div>
        <div
            v-show="visible"
            :style="[{ left: left + 'px', top: top + 'px' }, boxStyle]"
            class="contextmenu"
            ref="contextmenu"
            @mousedown="startDrag"
        >
            <div class="contextmenu-close" style="">
                <i
                    class="text-primary el-icon-circle-close"
                    @click="handleClose"
                ></i>
            </div>
            <div class="d-flex align-items-center">
                <div class="flex-1">
                    <el-input
                        placeholder="请输入问题或从列表中选择"
                        v-model="replaceText"
                        class="chat-input"
                        ref="myInput"
                    >
                    </el-input>
                </div>
                <div class="ml-15">
                    <!-- <el-button
                        @click="replaceChatSubmit"
                        icon="el-icon-s-promotion"
                        type="primary"
                        v-if="!showStop"
                    ></el-button> -->
                    <i
                        class="btn-bg-primary btn-submit font-size-24 el-icon-s-promotion"
                        type="primary"
                        v-if="!showStop"
                        @click="replaceChatSubmit"
                    ></i>
                    <!-- <el-button
                        @click="getStop"
                        icon="el-icon-video-play"
                        type="primary"
                        v-if="showStop"
                    > -->
                    <i
                        class="btn-bg-primary btn-submit font-size-24 el-icon-video-play"
                        type="primary"
                        v-if="showStop"
                        @click="getStop"
                    ></i>
                    <!-- </el-button> -->
                </div>
                <!-- <i
                    class="text-primary font-size-24 ml-15"
                    v-if="showStop"
                    @click="getStop"
                    :class="
                        isStop ? 'el-icon-video-play' : 'el-icon-video-pause'
                    "
                ></i> -->
            </div>
            <ul v-if="!replaceText" class="operate-menu">
                <li @click="contextMenuChange('帮我润色')">帮我润色</li>
                <li @click="contextMenuChange('丰富内容')">丰富内容</li>
                <li @click="contextMenuChange('精简内容')">精简内容</li>
                <li @click="contextMenuChange('改变语气')">改变语气</li>
                <li @click="contextMenuChange('总结')">总结</li>
            </ul>
            <div
                class="chat-box-content"
                v-if="Object.keys(replaceData).length"
            >
                <div style="" class="answer" ref="chatBoxContent">
                    <markdown-it-vue :content="replaceData.result.trim()" />
                </div>
                <div
                    class="chat-loading"
                    v-if="!replaceData.result && replaceData.loading"
                ></div>
                <div
                    class="mt-5 d-flex align-item-center"
                    v-if="!replaceData.loading"
                >
                    <el-button
                        class="text-primary"
                        v-if="!replaceData.loading && selectText.length"
                        type="text"
                        @click="getInsertMarkdownText('before')"
                        ><i
                            class="iconfont icon-yousuojin mr-1 font-size-16"
                        ></i>
                        段前插入</el-button
                    >
                    <el-button
                        class="text-primary"
                        v-if="!replaceData.loading"
                        type="text"
                        @click="
                            getInsertMarkdownText(
                                selectText.length > 0 ? 'after' : ''
                            )
                        "
                        ><i
                            class="iconfont icon-zuosuojin mr-1 font-size-16"
                        ></i>
                        {{
                            selectText.length > 0 ? "段后插入" : "插入"
                        }}</el-button
                    >
                    <el-button
                        class="text-primary"
                        type="text"
                        v-if="!replaceData.loading"
                        @click="getReplaceMarkdownText"
                        ><i class="iconfont icon-tihuan3 mr-1 font-size-16"></i
                        >替换</el-button
                    >
                    <!-- <el-button
                        class="text-primary"
                        type="text"
                        v-if="!replaceData.loading"
                        @click="onSubmit()"
                        ><i class="iconfont icon-refresh mr-1 font-size-16"></i
                        >换一换</el-button
                    > -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as Formx from "@/api/form/model";
import { streamChats } from "@/api/ai/index";
import chatService from "@/api/ai/chat";
import botService from "@/api/ai/bot";
import recordService from "@/api/ai/record";
import MarkdownItVue from "markdown-it-vue";
import MarkdownEditor from "@/components/MarkdownEditor/index";

import "markdown-it-vue/dist/markdown-it-vue.css";
import { optionselect as getDictOptionselect } from "@/api/core/data/dict/type";
import hljs from "highlight.js";

export default {
    name: "ai-create-index",
    components: {
        MarkdownItVue,
        MarkdownEditor,
    },
    data() {
        return {
            keywords: "",
            value: "",
            contentHeight: "",
            tagIndex: -1,
            tabActive: "0",
            menus1: [
                {
                    icon: require("@/assets/approverIcon/A (11).png"),
                    title: "智能会话",
                },
                {
                    icon: require("@/assets/approverIcon/A (11).png"),
                    title: "智能助手",
                },
            ],
            menus: [],
            chatList: [],
            loading: false,
            answerAvatar: require("@/assets/image/AI-chat.png"),
            menuIcon: require("@/assets/approverIcon/A (11).png"),
            displayText: "",
            displayIndex: 0,
            is_end: false,
            disabled: false,

            chatTitle: "",
            tagList: [],
            markdownText: "",
            dialogVisible: false,
            replaceData: {},
            replaceText: "",
            visible: false,
            selectText: "",
            left: 0,
            top: 0,
            showChatTime: null,
            isStop: false,
            showStop: false,
            chatMenus: [
                {
                    title: "新建会话",
                    id: 0,
                    chatList: [],
                },
            ],
            chatMenusActive: 0,
            editTlite: "",
            menuActive: "",
            oldChat: [],
            is_one: true,
            chatBoxHeight: "",
            recordQueryParams: {
                pageNum: 1,
                pageSize: 100,
            },
            is_loading: true,
            chat_id: 0,
            chatMessageTotal: 0,
            isDragging: false,
            startX: 0,
            startY: 0,
            initialX: 0,
            initialY: 0,
        };
    },
    mounted() {
        // 获取表详细信息
        this.getInit();
    },
    beforeRouteLeave(to, from, next) {
        // 在离开当前路由前清除循环
        clearInterval(this.showChatTime); // 清除 setInterval
        // 清除其他循环，如果有的话

        next(); // 继续离开路由
    },
    beforeDestroy() {
        // 在组件销毁前清除循环
        clearInterval(this.showChatTime); // 清除 setInterval
        // 清除其他循环，如果有的话
    },
    computed: {
        ...mapGetters({
            avatar: "user/avatar",
        }),
        boxStyle() {
            return {
                transform: `translate(${this.initialX}px, ${this.initialY}px)`,
                cursor: this.isDragging ? "grabbing" : "grab",
            };
        },
        getPlaceholder: function () {
            if (this.tabActive * 1 === 0) {
                return "请输入内容,Shift+Enter换行";
            } else if (
                this.tabActive * 1 === 1 &&
                this.tagIndex * 1 > -1 &&
                this.tagList[this.tagIndex]
            ) {
                return (
                    this.tagList[this.tagIndex].bot_desc + ",Shift+Enter换行"
                );
            }

            return "请输入内容,Shift+Enter换行";
        },

        getTitle: function () {
            if (this.tabActive * 1 === 0 && this.chatMenusActive * 1 > -1) {
                return this.chatMenus[this.chatMenusActive]
                    ? this.chatMenus[this.chatMenusActive].title
                    : "";
            } else if (this.tabActive * 1 === 1 && this.tagIndex > -1) {
                return this.tagList[this.tagIndex]
                    ? this.tagList[this.tagIndex].bot_name
                    : "";
            } else if (this.menus && this.menus.length) {
                return this.menus[this.menuActive].name
                    ? this.menus[this.menuActive].name
                    : "";
            } else {
                return "";
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        // 在离开当前路由前清除循环
        clearInterval(this.showChatTime); // 清除 setInterval
        // 清除其他循环，如果有的话

        next(); // 继续离开路由
    },
    methods: {
        startDrag(e) {
            if (e.button !== 0) return; // 只处理鼠标左键

            this.isDragging = true;
            this.startX = e.clientX;
            this.startY = e.clientY;
            document.addEventListener("mousemove", this.drag);
            document.addEventListener("mouseup", this.stopDrag);
        },
        drag(e) {
            if (!this.isDragging) return;

            const offsetX = e.clientX - this.startX;
            const offsetY = e.clientY - this.startY;

            this.initialX += offsetX;
            this.initialY += offsetY;

            this.startX = e.clientX;
            this.startY = e.clientY;
        },
        stopDrag() {
            this.isDragging = false;
            document.removeEventListener("mousemove", this.drag);
            document.removeEventListener("mouseup", this.stopDrag);
        },
        getChatBoxHeight() {
            if (!this.$refs.myTextarea) {
                this.chatBoxHeight = this.contentHeight - 120 + "px";
                return false;
            }
            // 获取 el-input 的引用
            const textInput =
                this.$refs.myTextarea.$el.querySelector("textarea");

            //初始化
            this.chatBoxHeight =
                this.contentHeight -
                (textInput.clientHeight || 64) -
                145 +
                "px";

            // 添加输入事件监听器
            textInput.addEventListener("input", () => {
                const scrollHeight = textInput.clientHeight;

                if (this.markdownText || !this.chatList.length) {
                    this.chatBoxHeight = this.contentHeight - 130 + "px";
                } else {
                    // let height = chatInputBox.scrollHeight || 0;

                    if (70 < scrollHeight && scrollHeight <= 90) {
                        this.chatBoxHeight =
                            this.contentHeight -
                            (textInput.clientHeight || 64) -
                            160 +
                            "px";
                    } else {
                        this.chatBoxHeight =
                            this.contentHeight -
                            (textInput.clientHeight || 64) -
                            145 +
                            "px";
                    }
                    this.$nextTick(() => {
                        this.scrollToBottom();
                    });
                }
            });
            // let chatInputBox = document.querySelector(".chat-textarea");
            // console.log(chatInputBox.scrollHeight, "chatInputBox.scrollHeight");
            // if (this.markdownText || !this.chatList.length) {
            //     this.chatBoxHeight = this.contentHeight - 130 + "px";
            // } else {
            //     let height = chatInputBox.scrollHeight || 0;

            //     if (70 < height && height <= 90) {
            //         this.chatBoxHeight =
            //             this.contentHeight -
            //             (chatInputBox ? height : 64) -
            //             160 +
            //             "px";
            //     } else {
            //         this.chatBoxHeight =
            //             this.contentHeight -
            //             (chatInputBox ? height : 64) -
            //             140 +
            //             "px";
            //     }
            // }
        },
        //获取可视区域高度
        getContentHeight() {
            this.$nextTick(() => {
                this.contentHeight = document.body.clientHeight - 165;
            });
            // // 添加窗口大小变化事件监听器
            // window.addEventListener("resize", () => {
            //     // 在窗口大小变化时触发的代码
            //     const width = window.innerWidth; // 获取窗口宽度
            //     const height = window.innerHeight; // 获取窗口高度

            //     // 在这里执行你想要的操作，例如更新页面布局或响应窗口大小变化
            //     console.log(`窗口宽度: ${width}px, 窗口高度: ${height}px`);
            // });
        },
        //滚动到底部加载
        getScroll() {
            const scrollableDiv = document.querySelector(".chat-box");

            // 检查是否滚动接近底部
            if (
                scrollableDiv.scrollHeight - scrollableDiv.scrollTop ===
                scrollableDiv.clientHeight
            ) {
                // 触底操作，例如刷新内容

                //判断是否还有未加载的数据，如果会话历史记录和返回的总数量一致或者为0不再加载
                if (
                    !this.markdownText.length &&
                    this.chatList.length > 0 &&
                    this.chatMessageTotal * 1 >
                        this.chatList.filter((o) => !o.history).length
                ) {
                    this.recordQueryParams.pageNum++;
                    let chat_id =
                        this.tabActive * 1 === 0
                            ? this.chatMenus[this.chatMenusActive].id
                            : this.chat_id;

                    this.getRecordList(chat_id);
                }

                // 这里可以执行你的刷新操作，例如加载更多内容
                // 例如：appendMoreContent();
            }
        },
        //退出编辑
        exitMarkdownEdit() {
            this.markdownText = "";
            this.$nextTick(() => {
                this.getChatBoxHeight();
                this.scrollToBottom();
            });
        },
        removeLastNewline(e) {},
        handleEnter(event) {
            // 英文下｜中文下： 13 Enter Enter
            // 中文下有文字没进入输入框情况是：299 Enter Enter
            // console.log(e);

            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                // 获取消息内容
                this.onSubmit();
                // 这里可以将 message 发送给服务器或执行其他操作
                // 例如，将消息显示在控制台上

                // 清空消息
            }
        },
        autoResize() {
            this.$refs.myTextarea.style.height = "auto";
            this.$refs.myTextarea.style.height =
                Math.min(
                    this.$refs.myTextarea.scrollHeight,
                    parseInt(this.maxTextareaHeight)
                ) + "px";
        },
        sendMessage() {
            if (this.value.trim() !== "" && !event.shiftKey && !this.loading) {
                // 在这里处理发送消息的逻辑
                this.onSubmit();
                // this.message = ""; // 清空文本框
            }
        },
        addNewLine() {
            this.value += "\n";
        },
        isInput() {
            if (this.loading) {
                this.$message.warning(
                    "正在回答，请稍候再试 或手动结束后可输入"
                );
                this.value = "";
            }
        },

        //初始化
        getInit() {
            this.getContentHeight();
            this.$nextTick(() => {
                this.getChatBoxHeight();
            });

            this.getChatMenus();
        },
        //获取聊天记录
        getRecordList(chat_id) {
            recordService
                .listRecord({
                    pageNum: this.recordQueryParams.pageNum,
                    pageSize: this.recordQueryParams.pageSize,
                    fieldval: {
                        chat_id: chat_id,
                    },
                    querytype: {
                        //不传默认=
                        bot_type: "=",
                        bot_name: "like",
                    },
                })
                .then((res) => {
                    if (this.recordQueryParams.pageNum * 1 === 1) {
                        this.chatList = res.data.list;

                        let obj = {};
                        if (this.tabActive * 1 === 1) {
                            obj = JSON.parse(
                                JSON.stringify(this.tagList[this.tagIndex])
                            );
                            obj.history = true;
                            //判断是否需要转译
                            if (
                                obj.example &&
                                typeof obj.example === "string"
                            ) {
                                obj.example = JSON.parse(obj.example);
                            }
                            this.chatList.push(obj);
                        }

                        if (this.tabActive * 1 === 0) {
                            if (res.data.list.length > 0) {
                                obj.history = true;
                                this.chatList.push(obj);
                            } else {
                                this.tagList = [
                                    { bot_name: "我公司的产品写一份宣传文案" },
                                    { bot_name: "给文章纠错并进行内容扩写" },

                                    { bot_name: "写财务总结，主要工作是出纳" },
                                    { bot_name: "写短视频脚本，泡菜怎么制作" },
                                    { bot_name: "写心得体会，读《夏洛的网》" },
                                    { bot_name: "写演讲稿，幼儿园校长演讲" },
                                    { bot_name: "写篇企业互联网保险调研报告" },
                                    {
                                        bot_name:
                                            "生活中最重要的价值到底是什么？",
                                    },
                                ];
                            }
                        }
                    } else {
                        this.chatList = this.chatList.concat(res.data.list);
                    }
                    this.chatMessageTotal = res.data.total;

                    this.$nextTick(() => {
                        this.getChatBoxHeight();
                        this.scrollToBottom();
                    });
                });
        },
        //清除当前会话记录
        removeContext() {
            let chatId = this.chatList[0].chat_id;
            let botId =
                this.tabActive * 1 === 0 ? 0 : this.tagList[this.tagIndex].id;

            recordService.removeContext(chatId, botId).then((res) => {
                if (res.code === 0) {
                    this.$message.success("操作成功");

                    this.recordQueryParams.pageNum = 1;

                    this.getRecordList(chatId);
                }
            });
        },
        getChatMenus(bot_id) {
            //是否存在会话记录
            chatService
                .listChat({
                    pageNum: 1,
                    pageSize: bot_id ? 1 : 100,
                    fieldval: {
                        bot_id: bot_id ? bot_id : "0",
                    },
                    sortinfo: {
                        id: "desc",
                    },
                })
                .then((res) => {
                    if (res.data.total == 0) {
                        this.addChatMenu(bot_id);
                        // this.getMenus();

                        return;
                    }
                    if (this.tabActive * 1 === 0) {
                        this.chatMenus = res.data.list;

                        this.recordQueryParams.pageNum = 1;

                        this.getRecordList(
                            this.chatMenus[this.chatMenusActive].id
                        );
                        this.$refs.myTextarea.focus();
                    } else {
                        this.recordQueryParams.pageNum = 1;

                        this.chat_id = res.data.list[0].id;
                        this.getRecordList(res.data.list[0].id);
                    }
                    // let oldChatMenus = JSON.parse(
                    //     localStorage.getItem("chatMenus")
                    // );

                    // if (oldChatMenus && oldChatMenus.length) {
                    //     this.chatMenus.forEach((item) => {
                    //         let index = oldChatMenus.findIndex(
                    //             (o) => o.id * 1 === item.id
                    //         );
                    //         if (index > -1) {
                    //             item.chatList = oldChatMenus[index].chatList;
                    //         }
                    //     });
                    // }
                    // this.chatList =
                    //     this.chatMenus[this.chatMenusActive].chatList || [];
                });
        },
        getMenus() {
            //查询助手分类

            botService
                .listTypes(this.keywords)
                .then((res) => {
                    if (res.code === 0) {
                        this.menus = res.data;
                        this.menus.unshift({
                            key: "-2",
                            name: "我的",
                        });
                        this.menus.unshift({ key: "-1", name: "全部" });
                        this.getListMine();
                        this.getBot(this.menus[this.menuActive]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //查询我的
        getListMine() {
            botService.listMine().then((res) => {
                if (res.code === 0) {
                    this.tagList = res.data.list;
                }
            });
        },
        //助手列表
        getBot(item) {
            botService
                .listBot({
                    pageNum: 1,
                    pageSize: 100,
                    fieldval: {
                        bot_type: item.key * 1 === -1 ? "" : item.key,
                        bot_name: this.keywords,
                        status: 1,
                    },
                    querytype: {
                        //不传默认=
                        bot_type: "=",
                        bot_name: "like",
                    },
                    sortinfo: {
                        hots: "desc",
                    },
                })
                .then((res) => {
                    if (res.code === 0) {
                        this.tagList = res.data.list;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //一级菜单切换
        tabsClick() {
            this.markdownText = "";

            if (this.showStop) {
                this.getStop();
            }
            this.chatList = [];
            this.keywords = "";
            this.recordQueryParams.pageNum = 1;
            if (this.tabActive * 1 === 0) {
                if (!this.$store.getters["user/accessToken"]) {
                    return;
                }
                if (!this.chatMenus.length) {
                    this.getChatMenus();
                } else {
                    this.tagList = [
                        { bot_name: "我公司的产品写一份宣传文案" },
                        { bot_name: "给文章纠错并进行内容扩写" },

                        { bot_name: "写财务总结，主要工作是出纳" },
                        { bot_name: "写短视频脚本，泡菜怎么制作" },
                        { bot_name: "写心得体会，读《夏洛的网》" },
                        { bot_name: "写演讲稿，幼儿园校长演讲" },
                        { bot_name: "写篇企业互联网保险调研报告" },
                        { bot_name: "生活中最重要的价值到底是什么？" },
                    ];

                    this.chatMenusActive = 0;

                    this.getRecordList(this.chatMenus[this.chatMenusActive].id);
                }
            } else {
                this.menuActive = 0;
                this.$nextTick(() => {
                    this.getChatBoxHeight();
                });
                this.getMenus();
            }
        },
        //智能助手
        menuChange(item, index) {
            this.tagList = [];
            this.menuActive = index;
            this.chatList = [];
            this.markdownText = "";
            this.recordQueryParams.pageNum = 1;
            // this.keywords=""
            this.tagIndex = -1;
            if (this.showStop) {
                this.getStop();
            }
            if (item.key * 1 === -2) {
                this.getListMine();

                return;
            }
            this.getBot(item);
        },
        //会话菜单切换
        chatMenuChange(index) {
            this.markdownText = "";
            this.chatMenusActive = index;

            this.chatList = [];

            if (this.showStop) {
                this.getStop();
            }
            this.recordQueryParams.pageNum = 1;
            this.getRecordList(this.chatMenus[this.chatMenusActive].id);
        },
        //增加会话
        addChatMenu(bot_id) {
            if (this.showStop) {
                this.getStop();
            }
            chatService
                .createChat({
                    title: "新建会话",
                    bot_id: bot_id ? bot_id : "0",
                    bot_name: "",
                })
                .then((res) => {
                    this.chatList = [];
                    // this.chatMenus.unshift(res.data);
                    this.getChatMenus(this.tabActive * 1 === 1 ? bot_id : "");
                    this.chatMenusActive = 0;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //修改会话名称
        editChatMenu(index) {
            let obj = JSON.parse(JSON.stringify(this.chatMenus[index]));
            if (obj.type === "input") {
                //更新
                chatService
                    .editChat(obj.fmodel_id, {
                        id: obj.id,
                        title: this.editTlite,
                    })
                    .then((res) => {
                        obj.title = this.editTlite;
                        obj.type = "text";
                        this.$message.success("修改成功");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.editTlite = obj.title;
                obj.type = "input";
            }

            this.$set(this.chatMenus, index, obj);
        },
        //删除当前会话
        deleteChatMenu(index, is_message = true) {
            let obj = this.chatMenus[index];
            if (obj.type === "input") {
                obj.type = "text";
                this.$set(this.chatMenus, index, obj);
            } else {
                chatService
                    .delChat(obj.id)
                    .then((res) => {
                        if (obj.type === "input") {
                            obj.type = "text";
                            this.$set(this.chatMenus, index, obj);
                        } else {
                            // this.chatMenus.splice(index, 1);
                            this.getChatMenus();
                            let chatMenusActive = this.chatMenusActive * 1 - 1;
                            this.chatMenusActive =
                                chatMenusActive < 0 ? 0 : chatMenusActive;
                        }
                        if (is_message) {
                            this.$message.success("删除成功");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },

        //tag选项
        tagChange(item, index) {
            this.tagIndex = index;

            if (this.showStop) {
                this.getStop();
            }

            if (this.tabActive * 1 === 1) {
                this.getChatMenus(item.id);

                this.$nextTick(() => {
                    this.scrollToBottom();
                });
            } else {
                this.value = item.bot_name;
                this.onSubmit();
            }

            //如果是智能助手下的我的菜单则不直接发送
        },
        //请除全部会话
        clearChatMenu() {
            let ids = this.chatMenus.map((o) => o.id);

            let id = ids.join(",");
            let that = this;
            this.$confirm("是否清除当前所有会话?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    chatService.delChat(id).then((res) => {
                        // this.chatMenus.splice(index, 1);
                        if (res.code === 0) {
                            that.getChatMenus();
                            that.chatList = [];
                            that.$message.success("清除成功");
                        } else {
                            that.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        exampleChange(item) {
            this.value = item.content;
            this.onSubmit();
        },
        //中断对话
        getStop(item) {
            let obj = this.visible
                ? this.replaceData
                : this.chatList[this.chatList.length - 1];

            if (obj && obj.result && obj.result.length) {
                clearInterval(this.showChatTime);
            }
            this.loading = false;
            this.showStop = false;
            this.is_end = false;
            this.showStop = false;
            this.disabled = false;
            obj.loading = false;

            obj.result =
                obj.result && obj.result.length ? obj.result : "回答已中断";
            //
            this.loading = false;

            this.$nextTick(() => {
                // this.getChatBoxHeight();
                // if (this.visible) {
                //     this.$refs.myInput.focus();
                // } else {
                //     this.$refs.myTextarea.focus();
                // }
                this.scrollToBottom();
            });
        },
        getCopyMd(e) {
            //let element = document.querySelector(".editormd-markdown-textarea");
            //let url = element.innerHTML; //拿到想要复制的值
            let copyInput = document.createElement("textarea"); //创建input元素
            document.body.appendChild(copyInput); //向页面底部追加输入框
            copyInput.value = e.text; //添加属性，将url赋值给input元素的value属性
            copyInput.select(); //选择input元素
            document.execCommand("Copy"); //执行复制命令
            //复制之后再删除元素，否则无法成功赋值
            copyInput.remove();
            // if (e.type * 1 === 1) {
            //     this.$confirm("复制成功！是否立即前往PPT工场?", "提示", {
            //         confirmButtonText: "确定",
            //         cancelButtonText: "取消",
            //         type: "success",
            //     })
            //         .then(() => {
            //             // this.$router.push({ path: "/ppt" });
            //             let url = window.location.href.split("/#/")[0];

            //             window.open(url + "/#/ppt", "_blank");
            //         })
            //         .catch(() => {});
            // } else {
            this.$message.success("复制成功");
            // }
        },

        closeMenu() {
            let element = document.querySelector(".contextmenu");
            // 获取要操作的元素

            // 添加新的 class 类名
            element.classList.add("shaking-div"); // 替换为你要添加的新 class 类名
            setTimeout(() => {
                element.classList.remove("shaking-div");
            }, 800);
        },
        //弹出列表选择
        contextMenuChange(value) {
            let index = this.selectText.indexOf("1.");
            let fullText = this.$refs.markdownView.getMarkdown().slice(0.1);

            let selectText =
                index === 0 ? this.selectText.split("1.")[1] : this.selectText;

            let contextText = selectText
                ? `${value}这段'${selectText}'文字`
                : value;

            this.value = `全文如下："${fullText}",在全文大纲下,${contextText}`;

            this.replaceText = value;
            this.onSubmit();
        },
        //插入文字
        getInsertMarkdownText(type) {
            if (type === "before") {
                this.$refs.markdownView.replaceSelection(
                    this.replaceData.result + this.selectText
                );
            } else if (type === "after") {
                this.$refs.markdownView.replaceSelection(
                    this.selectText + this.replaceData.result
                );
            } else {
                this.$refs.markdownView.insertValue(this.replaceData.result);
            }

            //

            this.handleClose();
        },
        //替换
        getReplaceMarkdownText() {
            this.$refs.markdownView.replaceSelection(this.replaceData.result);
            this.handleClose();
        },
        replaceChatSubmit() {
            this.value = this.replaceText;
            this.onSubmit();
        },
        handleClose() {
            this.visible = false;
            this.replaceData = {};
            this.replaceText = "";
            this.value = "";
        },
        toReplace(e) {
            // this.dialogVisible = true;
            this.isDragging = false;
            this.startX = 0;
            this.startY = 0;
            this.initialX = 0;
            this.initialY = 0;
            this.left = e.pageX - 200;
            this.top = e.pageY - 110;
            this.selectText = this.$refs.markdownView.getSelection();
            this.visible = true;

            this.$nextTick(() => {
                this.$refs.myInput.focus();
            });
        },
        toEdit() {},
        getReplaceData() {},
        getCopy2(item) {
            this.markdownText = item;
            this.$nextTick(() => {
                this.getChatBoxHeight();
            });
        },

        init() {},
        toClear() {
            if (!this.$store.getters["user/accessToken"]) {
                this.$message.warning("请先登录");
                return false;
            }
            this.chatList = [];
        },
        //将输入框中的\n换成\<br>
        getConvert(content) {
            return content && content.length
                ? content.replace(/\n/g, "<br/>")
                : "";
        },
        onSubmit() {
            this.showStop = false;
            //判断是否登录
            if (!this.$store.getters["user/accessToken"]) {
                this.$message.warning("请先登录");
                return false;
            }
            //判断是否输入
            if (this.visible ? !this.replaceText : !this.value && !this.loading)
                return;
            //创建会话
            if (this.visible) {
                this.replaceData = { type: 2, loading: true, result: "" };
            } else {
                this.chatList.push({
                    type: 1,
                    content: this.value,
                    loading: true,
                    result: "",
                });
                if (this.tabActive * 1 === 0) {
                    if (
                        this.chatMenus[this.chatMenusActive].title ===
                        "新建会话"
                    ) {
                        this.chatMenus[this.chatMenusActive].title =
                            this.value.length > 15
                                ? this.value.slice(0, 15)
                                : this.value;
                        chatService
                            .editChat(
                                this.chatMenus[this.chatMenusActive].fmodel_id,
                                {
                                    id: this.chatMenus[this.chatMenusActive].id,
                                    title: this.chatMenus[this.chatMenusActive]
                                        .title,
                                }
                            )
                            .then((res) => {});
                    }
                }
            }

            this.is_one = true;

            this.showStop = true;
            this.isStop = false;
            this.displayText = "";
            this.loading = true;
            this.displayIndex = 0;
            let is_error = true;
            let chat_id = this.visible
                ? 0
                : this.tabActive * 1 === 0
                ? this.chatMenus[this.chatMenusActive].id
                : this.chat_id;
            let bot_id =
                this.tabActive * 1 === 0
                    ? this.chatMenus[this.chatMenusActive].bot_id
                    : this.tagList[this.tagIndex].id;
            this.scrollToBottom();
            // this.visible ? this.replaceText :
            streamChats({
                content: this.value,
                chat_id: chat_id,
                bot_id: bot_id,
                complete: (res) => {
                    console.log(res, "该请求结束事件");
                    // 该请求结束事件
                    this.is_end = true;
                },
                chunked: (data) => {
                    // 分段收到的数据
                    this.displayText += data.result;
                    //是否结束
                    if (data.is_end) {
                        this.is_end = true;
                    }

                    if (
                        this.is_one &&
                        is_error &&
                        this.loading &&
                        this.displayText
                    ) {
                        this.is_one = false;

                        this.showChatGptResponse();
                    }
                    //如果need_clear_history返回为true，则删除当前会话
                    if (data.need_clear_history) {
                        this.getStop();
                        if (!this.visible && this.tabActive * 1 === 1) {
                            this.deleteChatMenu(this.chatMenusActive, false);
                        }
                    }
                },
                fail: (err) => {
                    console.log("fail", err);
                    if (is_error) {
                        if (this.showChatTime) {
                            clearInterval(this.showChatTime);
                        }
                        is_error = false;
                        this.is_one = false;
                        this.is_end = true;
                        let chatObj = this.visible
                            ? this.replaceData
                            : this.chatList[this.chatList.length - 1];
                        if (!chatObj.result) {
                            this.displayText = err ||"当前网络不太好";
                        }

                        this.showChatGptResponse();
                    }
                },
            });
            if (!this.visible) {
                this.value = "";
            }
        },
        showChatGptResponse(item) {
            // 逐字显示 ChatGPT 回答
            let index = this.chatList.length - 1;
            let obj = this.visible
                ? this.replaceData
                : this.chatList[this.chatList.length - 1];
            let i = 0;

            this.showChatTime = setInterval(() => {
                obj.result += this.displayText[i] || "";
                if (this.displayText[i]) {
                    i++;
                }
                // this.getChatBoxHeight();
                if (
                    this.is_end &&
                    obj.result.length === this.displayText.length
                ) {
                    this.displayText = "";

                    this.is_end = false;
                    this.showStop = false;
                    this.disabled = false;
                    obj.loading = false;
                    this.loading = false;
                    this.scrollToBottom();
                 
                    if (!this.visible && this.tabActive * 1 === 0) {
                        localStorage.setItem(
                            "chatMenus",
                            JSON.stringify(this.chatMenus)
                        );
                    }

                    clearInterval(this.showChatTime);
                }

                this.scrollToBottom();

                //
            }, 20);
        },
        getCopy(item) {
            let url = item.result; //拿到想要复制的值
            let copyInput = document.createElement("input"); //创建input元素
            document.body.appendChild(copyInput); //向页面底部追加输入框
            copyInput.setAttribute("value", url); //添加属性，将url赋值给input元素的value属性
            copyInput.select(); //选择input元素
            document.execCommand("Copy"); //执行复制命令
            this.$message.success("复制成功！"); //弹出提示信息，不同组件可能存在写法不同
            //复制之后再删除元素，否则无法成功赋值
            copyInput.remove();
        },
        scrollToBottom() {
            // 使用 $nextTick 来确保 DOM 更新完成后执行滚动操作
            this.$nextTick(() => {
                const container = this.visible
                    ? this.$refs.chatBoxContent
                    : this.$refs.chatBox;

                if (container) {
                    container.scrollTop = container
                        ? container.scrollHeight
                        : 0;
                }
                if (this.visible) {
                    this.$refs.myInput.focus();
                } else {
                    this.$refs.myTextarea.focus();
                }
            });
        },
    },
};
</script>
<style lang="scss" >
.ai-create-index {
    background: #f6f8f9 !important;
    .delete-btn {
        padding: 13px 15px;
    }
    .btn-bg-primary {
        background-color: var(--color-primary) !important;
        border: 1px solid var(--color-primary) !important;
    }
    .vip-info {
        // height: 100px;

        width: 100%;
        border-radius: 5px;
        // background: #e8f4ff;
        background-color: var(--column-menu-background);
        padding: 20px 10px;
    }
    .text-primary {
        color: var(--color-primary);
    }
    .text-warning {
        color: #ffba00;
    }
    .text-grey {
        color: #999;
    }
    .update-chat-btn {
        background: var(--column-menu-background);
        color: var(--color-primary);
        border-color: var(--column-menu-background);
        &:hover {
            background: var(--color-primary);
            color: #fff;
            border-color: var(--color-primary);
        }
    }
    .answer-avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: fixed;
        right: 20px;
        bottom: 150px;
        // padding: 10px;
        z-index: 111;
        // background: #1d9f3a;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .menu-list {
        padding: 0 15px;
        overflow-y: auto;
        // height: 100%;
        margin-top: 15px;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }
        .menu-item {
            box-shadow: 0 0 6px #dcdfe6;
            background: transparent;
            border-radius: 5px;
            margin-bottom: 15px;
            min-height: 40px;
            // &:hover {
            //     // border: 1px solid #1d9f3a;
            //     background: rgba(29, 159, 58, 0.2);
            //     color: rgb(29, 159, 58);
            // }
            &.active {
                background: var(--column-menu-background);
                color: var(--color-primary);
            }
        }
    }
    .menu-item {
        padding: 5px 15px;
        cursor: pointer;
        background: #f6f8f9;

        &-input {
            height: 40px;
            input {
                // padding: 5px 15px;
                // height: 100%;
                border: none;
                background: transparent;
                width: 100%;
                // height: 100%;
                color: var(--color-primary);
                font-size: 15px;
                &::placeholder {
                    color: var(--color-primary);
                }
                // margin-left: -10px;
                padding: 0 !important;
            }

            border-bottom: 2px solid var(--color-primary);
        }
        &-icon {
            width: 37px;
            height: 37px;
            border-radius: 50%;
            background: #fff;
        }
    }

    .chat {
        &-title {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 20px;
        }
        &-box {
            overflow-y: auto;
            height: 660px;
            margin: 0 -20px;
            padding: 0 20px 20px 20px;
            &-answer {
                display: flex;
                margin-bottom: 20px;
                &-avatar {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: var(--color-primary);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &-content {
                    // background: #46a6ff;
                    // color: #fff;
                    background: #f6f8f9;
                    padding: 10px;
                    line-height: 1.5;
                    margin-right: 75px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .loading {
                        width: 5px;
                        height: 20px;
                        background: #000;
                        animation: blink 1s infinite;
                    }
                }
            }
            &-ask {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 20px;
                &-avatar {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: var(--color-primary);
                }
                &-content {
                    background: var(--color-primary);
                    color: #fff;
                    padding: 15px;
                    line-height: 1.5;
                    border-radius: 10px;
                    margin-left: 75px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            .tag-list {
                display: flex;
                flex-wrap: wrap;
                margin: -10px 0;
                // justify-content: center;
                &-item {
                    background: #fff;
                    border: 1px solid #eef2f2;
                    border-radius: 5px;
                    box-shadow: 0 2px 8px #f5f6f8;
                    margin: 10px 15px;
                    padding: 10px;
                    width: 160px;
                    display: flex;
                    justify-content: center;
                    font-size: 14px;
                    cursor: pointer;
                    &:hover {
                        background: var(--column-menu-background);
                        color: var(--color-primary);
                    }
                    &.active {
                        background: var(--column-menu-background);
                        color: var(--color-primary);
                    }
                }
            }
        }
        &-input {
            // margin-right: 15px;
            // background: #f6f8f9;
            border-radius: 4px;
            // border-color:#fff;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

            // width: 100%;
            input {
                // border: none;
                &:focus {
                    outline: 0;

                    border-color: var(--color-primary);
                }
                height: 45px;
                border-radius: 5px;
            }
            textarea {
                border: 1px solid #fff;
                // background: #f6f8f9;
                // height: 45px;
                border-radius: 5px;
            }
            .btn-submit {
                height: 45px;
                /* padding: 0 25px; */
                width: 45px;
                border-radius: 5px;
                color: #fff;
                text-align: center;
                line-height: 45px;
            }
        }
    }
    .btn-bg-primary {
        background-color: var(--color-primary) !important;
        border: 1px solid var(--color-primary) !important;
        height: 42px;
        /* padding: 0 25px; */
        width: 42px;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        line-height: 42px;
    }
    .bottom-10 {
        bottom: 10px;
    }
    .right-15 {
        right: 15px;
    }
    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 3000;
        position: absolute;
        list-style-type: none;
        width: 500px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
        padding: 15px;
        &-close {
            font-size: 22px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
        }
        .operate-menu {
            position: relative;
            // &::after {
            //     position: absolute;
            //     content: "";
            //     width: 0;
            //     height: 0;
            //     border-left: 20px solid transparent; /* 左边透明 */
            //     border-right: 20px solid transparent; /* 右边透明 */
            //     border-bottom: 20px solid #f00;
            // }
            width: 100px;
            margin-top: 5px;
            box-shadow: 1px 0px 2px 1px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            padding: 10px;
            li {
                width: 100%;
                margin: 0;
                padding: 7px 16px;
                cursor: pointer;
                list-style: none;
                &:hover {
                    background: var(--column-menu-background);
                    color: var(--color-primary);
                }
            }
        }
        .chat-box-content {
            // max-height: 200px;
            // overflow-y: auto;
            background: #f6f8f9;
            margin-right: 0;
            margin-top: 15px;
            padding: 10px;
            line-height: 1.5;

            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .answer {
                max-height: 200px;
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width: 6px;

                    height: 10px;
                }
                &::-webkit-scrollbar-track {
                    background: #fff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 20px;
                    background-color: #d3d3d3;
                }
            }
        }
    }
    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(-5px);
        }
        50% {
            transform: translateX(5px);
        }
        75% {
            transform: translateX(-5px);
        }
        100% {
            transform: translateX(0);
        }
    }

    .shaking-div {
        animation: shake 0.5s ease infinite; /* 抖动动画，无限循环 */
        display: inline-block; /* 使动画生效 */
    }
    .chat-loading {
        width: 5px;
        height: 20px;
        background: #000 !important;
        animation: chatBlink 1s infinite;
    }
    @keyframes chatBlink {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .el-tabs {
        // height: 667px;
        overflow-x: hidden;
        .el-tabs__content {
            height: 100%;
            padding: 0;
        }
    }
    .el-tabs--left .el-tabs__nav-wrap.is-left.is-scrollable,
    .el-tabs--left .el-tabs__nav-wrap.is-right.is-scrollable,
    .el-tabs--right .el-tabs__nav-wrap.is-left.is-scrollable,
    .el-tabs--right .el-tabs__nav-wrap.is-right.is-scrollable {
        padding: 0;
    }
    .el-tabs__nav-prev {
        display: none;
    }
    .el-tabs__nav-next {
        display: none;
    }
    .el-tabs__nav-scroll .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .el-tabs--left {
        .el-tabs__nav-wrap {
            &.is-left {
                margin-right: 0 !important;
            }

            &.is-left::after {
                width: 0;
                background: #dcdfe6;
            }
        }

        .el-tabs__header {
            &.is-left {
                margin-right: 0 !important;
            }

            .el-tabs__nav-scroll {
                height: 100%;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                    background: transparent;
                }
            }
        }
    }

    .el-tabs--border-card > .el-tabs__header .el-tabs__item {
        font-size: 17px;
        text-align: left;
        &:hover {
            color: var(--color-primary);
        }
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
        color: var(--color-primary);
    }
    .tag-detail {
        // // width: 40%;
        // height: 200px;
        // background: #f5f6f8;
        // // ma
        padding: 10px 0 0 0 !important;
        margin: 0 !important;
        cursor: pointer;
        li {
            list-style: none;
            padding: 10px 30px;
            margin: 10px 0;
            border-radius: 5px;
            background: #eef2f2;
            // color:#6c6c6c;
            font-size: 15px;
            &:hover {
                background: var(--column-menu-background);
                color: var(--color-primary);
            }
        }
    }
    .divider-line {
        margin: 40px 75px;
    }
    .el-textarea.is-disabled .el-textarea__inner {
        height: 100% !important;
    }
    .el-textarea__inner {
        padding: 10px 80px 10px 15px !important;
        border: 1px solid #fff;
        &:focus {
            outline: 0;

            border-color: var(--color-primary);
        }
        &::-webkit-scrollbar {
            width: 6px;

            height: 8px;
        }
        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background-color: #d3d3d3;
        }
    }
    .sending-chat {
        position: absolute;
        bottom: 20px;
        width: 100%;
        left: 0;
        padding: 0 35px;
        background: #fff;
    }
}
</style>