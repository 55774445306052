export function saveFormData(data, form) {
    localStorage.setItem(form, JSON.stringify(data))
};

export function getFormData(data, form) {
    var tstr = localStorage.getItem(form)

    if (tstr != null && tstr != "" && tstr != "undefined") {
        try {
            let objet = JSON.parse(tstr)
            localStorage.removeItem(form)
            return objet
        } catch (error) {
            localStorage.removeItem(form)
            return data
        }

    }
    return data


}