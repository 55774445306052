import request from '@/utils/request'

//添加
export function addIotTempclient(data) {
  return request({
    url: '/iot/tempclient/add',
    method: 'post',
    data: data
  })
}

// 获取临时用户
export function getIotTempclient(id) {
    return request({
      url: '/iot/tempclient/get?id=' + id,
      method: 'get'
    })
}

// 获取用户by clientID
export function getIotTempclientByClientId(id) {
  return request({
    url: '/iot/tempclient/getbyclientid?client_id=' + id,
    method: 'get'
  })
}

// 更新临时用户
export function updateIotTempclient(data) {
  return request({
    url: '/iot/tempclient/update',
    method: 'post',
    data: data
  })
}
  
// 删除临时用户
export function delIotTempclient(id) {
    return request({
      url: '/iot/tempclient/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 临时用户列表
export function listIotTempclient(query) {
  return request({
    url: '/iot/tempclient/list',
    method: 'get',
    params: query
  })
}