import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2023-10-30 15:43:41
class Follow {
// 查询follow列表
 listFollow(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/moments/follow/list',
        method: 'post',
        data: data
    })
}

// 查看follow
 getFollow ( id) {
    return request({
        url: '/moments/follow/get?id='+id,
        method: 'get'
    })
}


// 新增follow
 addFollow(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/moments/follow/add',
        method: 'post',
        data: postdata
    })
}

// 修改follow
 editFollow(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid,
          dataid:data.id
      }

    return request({
        url: '/moments/follow/edit',
        method: 'post',
        data: postdata
    })
}

// 删除follow
 delFollow(ids) {
    return request({
        url: '/moments/follow/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 followImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/moments/follow/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 followExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/moments/follow/export?` + parata.join("&") ;


 }

 //下导入模板
  followimportTemplate() {

     return   `/moments/follow/importTemplate`   ;

  }

}

const instance = new Follow();
export default instance;