import request from '@/utils/request'
// 生成时间:2022-01-21 09:43:26



// 查询zorecode列表
export function listZorecode(query) {
    var data = {
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
        page_size: query.pageSize,
        page: query.pageNum
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr") {
            filterstr[k] = item;
        }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/gen/gen/zorecode/list',
        method: 'post',

        data: data
    })
}

// 查询zorecode详细
export function getZorecode(id) {
    return request({
        url: '/gen/gen/zorecode/get?id=' + id,
        method: 'get'
    })
}


// 新增zorecode
export function addZorecode(data) {
    return request({
        url: '/gen/gen/zorecode/add',
        method: 'post',
        data: data
    })
}

// 修改zorecode
export function editZorecode(data) {
    return request({
        url: '/gen/gen/zorecode/edit',
        method: 'post',
        data: data
    })
}

// 删除zorecode
export function delZorecode(ids) {
    return request({
        url: '/gen/gen/zorecode/del',
        method: 'post',
        data: { ids: ids }
    })
}

// 删除zorecode查询模板
export function advquerydelZorecode(ids) {
    return request({
        url: '/gen/gen/zorecode/advquerydel',
        method: 'post',
        data: { ids: ids }
    })
}

// 添加zorecode查询模板
export function advquerysaveZorecode(data) {
    return request({
        url: '/gen/gen/zorecode/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表zorecode查询模板列表
export function advquerylistZorecode(businessname) {
    return request({
        url: '/gen/gen/zorecode/advquerylist?businessname=' + businessname,
        method: 'get'
    })
}
// 导出zorecode
export function exportZorecode(query, exportfields, tableColumn) {
    var data = {
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr") {
            filterstr[k] = item;
        }
    }
    var tarexportfieldslist = {};
    for (var k in tableColumn) {
        var tlbf = tableColumn[k];
        for (var i in exportfields) {
            var ef = exportfields[i]
            if (tlbf.field != "" && tlbf.field && tlbf.field != null && tlbf.field == ef) {
                tarexportfieldslist.push({ "filed": ef, "title": tlbf.title });
                break;
            }
        }
    }

    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    data["exportfields"] = JSON.stringify(tarexportfieldslist);
    return request({
        url: '/gen/gen/zorecode/export',
        method: 'post',
        data: data
    })
}


// 
export function forminfoList(query) {
    var data = query;

    return request({
        url: '/gen/gen/zorecode/forminfo/list',
        method: 'post',
        data: data
    })
}



// 保存
export function forminfoSave(query) {
    var data = query;

    return request({
        url: '/gen/gen/zorecode/forminfo/save',
        method: 'post',
        data: data
    })
}

export function forminfoApply(query) {
    var data = query;

    return request({
        url: '/gen/gen/zorecode/forminfo/apply',
        method: 'post',
        data: data
    })
}
//生成菜单
export function createMenu(query) {
    var data = query;

    return request({
        url: '/gen/gen/zorecode/createmenu',
        method: 'post',
        data: data
    })
}
// ======================================

//表单数据列表
export function zcdataList(model_id, query) {

    var data = {
        model_id: model_id,
        filterstr: "",
        orderby: "",
        opstr: query.opstr || {},
        page_size: query.pageSize,
        page: query.pageNum
    };
    var filterstr = {};
    for (var k in query) {
        var item = query[k];
        if (k != "pageSize" && k != "pageNum" && k != "opstr") {
            filterstr[k] = item;
        }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);

    return request({
        url: '/gen/zorecode/zcdata/list' + '/' + model_id,
        method: 'post',
        headers: {
            "model_id": model_id
        },
        data: data
    })
}
//表单字段列表
export function zcdatacolList(model_id) {
    return request({
        url: '/gen/zorecode/zcdata/colinfo' + '/' + model_id,
        method: 'post',
        headers: {
            "model_id": model_id
        },
        data: { model_id: model_id }
    })


}
export function zcdataAdd(model_id, datalist) {
    return request({
        url: '/gen/zorecode/zcdata/add' + '/' + model_id,
        method: 'post',
        headers: {
            "model_id": model_id
        },
        data: { model_id: model_id, datalist: JSON.stringify([datalist]) }
    })

}
export function zcdataEdit(model_id, id, datalist) {
    return request({
        url: '/gen/zorecode/zcdata/save' + '/' + model_id,
        method: 'post',
        headers: {
            "model_id": model_id
        },
        data: { model_id: model_id, id: id, datalist: JSON.stringify([datalist]) }
    })

}
export function zcdataGet(model_id, id) {
    return request({
        url: '/gen/zorecode/zcdata/get' + '/' + model_id,
        method: 'post',
        headers: {
            "model_id": model_id
        },
        data: { model_id: model_id, id: id }
    })

}
export function zcdataDel(model_id, id) {
    return request({
        url: '/gen/zorecode/zcdata/del' + '/' + model_id,
        method: 'post',
        headers: {
            "model_id": model_id
        },
        data: { model_id: model_id, id: id }
    })

}
