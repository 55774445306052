export default {
  widgetList: [
    {
      type: "static-text",
      icon: "static-text",
      formItemFlag: false,
      options: {
        name: "statictext111193",
        columnWidth: "200px",
        hidden: false,
        textContent: "单列表单",
        customClass: [],
        onCreated: "",
        onMounted: "",
        label: "static-text",
      },
      id: "statictext111193",
    },
    {
      type: "divider",
      icon: "divider",
      formItemFlag: false,
      options: {
        name: "divider102346",
        label: "",
        columnWidth: "200px",
        direction: "horizontal",
        contentPosition: "center",
        hidden: false,
        customClass: [],
        onCreated: "",
        onMounted: "",
      },
      id: "divider102346",
    },
    {
      type: "input",
      icon: "text-field",
      formItemFlag: true,
      options: {
        name: "input12931",
        label: "发件人姓名",
        labelAlign: "",
        type: "text",
        defaultValue: "",
        placeholder: "",
        columnWidth: "200px",
        size: "",
        labelWidth: null,
        labelHidden: false,
        readonly: false,
        disabled: false,
        hidden: false,
        clearable: true,
        showPassword: false,
        required: true,
        validation: "",
        validationHint: "",
        customClass: [],
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        minLength: null,
        maxLength: null,
        showWordLimit: false,
        prefixIcon: "",
        suffixIcon: "",
        appendButton: false,
        appendButtonDisabled: false,
        buttonIcon: "el-icon-search",
        onCreated: "",
        onMounted: "",
        onInput: "",
        onChange: "",
        onFocus: "",
        onBlur: "",
        onValidate: "",
      },
      id: "input12931",
    },
    {
      type: "switch",
      icon: "switch-field",
      formItemFlag: true,
      options: {
        name: "switch96070",
        label: "是否保密",
        labelAlign: "",
        defaultValue: true,
        columnWidth: "200px",
        labelWidth: null,
        labelHidden: false,
        disabled: false,
        hidden: false,
        customClass: "",
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        switchWidth: 40,
        activeText: "",
        inactiveText: "",
        activeColor: null,
        inactiveColor: null,
        onCreated: "",
        onMounted: "",
        onChange: "",
        onValidate: "",
      },
      id: "switch96070",
    },
    {
      type: "input",
      icon: "text-field",
      formItemFlag: true,
      options: {
        name: "input23031",
        label: "发件人号码",
        labelAlign: "",
        type: "text",
        defaultValue: "",
        placeholder: "",
        columnWidth: "200px",
        size: "",
        labelWidth: null,
        labelHidden: false,
        readonly: false,
        disabled: false,
        hidden: false,
        clearable: true,
        showPassword: false,
        required: true,
        validation: "",
        validationHint: "",
        customClass: "",
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        minLength: null,
        maxLength: null,
        showWordLimit: false,
        prefixIcon: "",
        suffixIcon: "",
        appendButton: false,
        appendButtonDisabled: false,
        buttonIcon: "el-icon-search",
        onCreated: "",
        onMounted: "",
        onInput: "",
        onChange: "",
        onFocus: "",
        onBlur: "",
        onValidate: "",
      },
      id: "input23031",
    },
    {
      type: "textarea",
      icon: "textarea-field",
      formItemFlag: true,
      options: {
        name: "textarea21654",
        label: "发件人地址",
        labelAlign: "",
        rows: 3,
        defaultValue: "",
        placeholder: "",
        columnWidth: "200px",
        size: "",
        labelWidth: null,
        labelHidden: false,
        readonly: false,
        disabled: false,
        hidden: false,
        required: true,
        validation: "",
        validationHint: "",
        customClass: "",
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        minLength: null,
        maxLength: null,
        showWordLimit: false,
        onCreated: "",
        onMounted: "",
        onInput: "",
        onChange: "",
        onFocus: "",
        onBlur: "",
        onValidate: "",
      },
      id: "textarea21654",
    },
    {
      type: "divider",
      icon: "divider",
      formItemFlag: false,
      options: {
        name: "divider69240",
        label: "",
        columnWidth: "200px",
        direction: "horizontal",
        contentPosition: "center",
        hidden: false,
        customClass: "",
        onCreated: "",
        onMounted: "",
      },
      id: "divider69240",
    },
    {
      type: "input",
      icon: "text-field",
      formItemFlag: true,
      options: {
        name: "input113152",
        label: "收件人姓名",
        labelAlign: "",
        type: "text",
        defaultValue: "",
        placeholder: "",
        columnWidth: "200px",
        size: "",
        labelWidth: null,
        labelHidden: false,
        readonly: false,
        disabled: false,
        hidden: false,
        clearable: true,
        showPassword: false,
        required: true,
        validation: "",
        validationHint: "",
        customClass: [],
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        minLength: null,
        maxLength: null,
        showWordLimit: false,
        prefixIcon: "",
        suffixIcon: "",
        appendButton: false,
        appendButtonDisabled: false,
        buttonIcon: "el-icon-search",
        onCreated: "",
        onMounted: "",
        onInput: "",
        onChange: "",
        onFocus: "",
        onBlur: "",
        onValidate: "",
      },
      id: "input113152",
    },
    {
      type: "checkbox",
      icon: "checkbox-field",
      formItemFlag: true,
      options: {
        name: "checkbox63174",
        label: "接收时间段",
        labelAlign: "",
        defaultValue: [],
        columnWidth: "200px",
        size: "",
        displayStyle: "inline",
        buttonStyle: false,
        border: false,
        labelWidth: null,
        labelHidden: false,
        disabled: false,
        hidden: false,
        optionItems: [
          { label: "上午9:00 - 11:30", value: 1 },
          { label: "下午12:30 - 18:00", value: 2 },
          { label: "晚上18:00 - 21:00", value: 3 },
        ],
        required: true,
        validation: "",
        validationHint: "",
        customClass: "",
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        onCreated: "",
        onMounted: "",
        onChange: "",
        onValidate: "",
      },
      id: "checkbox63174",
    },
    {
      type: "input",
      icon: "text-field",
      formItemFlag: true,
      options: {
        name: "input40240",
        label: "收件人号码",
        labelAlign: "",
        type: "text",
        defaultValue: "",
        placeholder: "",
        columnWidth: "200px",
        size: "",
        labelWidth: null,
        labelHidden: false,
        readonly: false,
        disabled: false,
        hidden: false,
        clearable: true,
        showPassword: false,
        required: true,
        validation: "",
        validationHint: "",
        customClass: "",
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        minLength: null,
        maxLength: null,
        showWordLimit: false,
        prefixIcon: "",
        suffixIcon: "",
        appendButton: false,
        appendButtonDisabled: false,
        buttonIcon: "el-icon-search",
        onCreated: "",
        onMounted: "",
        onInput: "",
        onChange: "",
        onFocus: "",
        onBlur: "",
        onValidate: "",
      },
      id: "input40240",
    },
    {
      type: "input",
      icon: "text-field",
      formItemFlag: true,
      options: {
        name: "input78584",
        label: "收件人地址",
        labelAlign: "",
        type: "text",
        defaultValue: "",
        placeholder: "",
        columnWidth: "200px",
        size: "",
        labelWidth: null,
        labelHidden: false,
        readonly: false,
        disabled: false,
        hidden: false,
        clearable: true,
        showPassword: false,
        required: true,
        validation: "",
        validationHint: "",
        customClass: "",
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        minLength: null,
        maxLength: null,
        showWordLimit: false,
        prefixIcon: "",
        suffixIcon: "",
        appendButton: false,
        appendButtonDisabled: false,
        buttonIcon: "el-icon-search",
        onCreated: "",
        onMounted: "",
        onInput: "",
        onChange: "",
        onFocus: "",
        onBlur: "",
        onValidate: "",
      },
      id: "input78584",
    },
    {
      type: "divider",
      icon: "divider",
      formItemFlag: false,
      options: {
        name: "divider75887",
        label: "",
        columnWidth: "200px",
        direction: "horizontal",
        contentPosition: "center",
        hidden: false,
        customClass: [],
        onCreated: "",
        onMounted: "",
      },
      id: "divider75887",
    },
    {
      type: "time-range",
      icon: "time-range-field",
      formItemFlag: true,
      options: {
        name: "timerange47503",
        label: "送货时间",
        labelAlign: "",
        defaultValue: null,
        startPlaceholder: "",
        endPlaceholder: "",
        columnWidth: "200px",
        size: "",
        labelWidth: null,
        labelHidden: false,
        readonly: false,
        disabled: false,
        hidden: false,
        clearable: true,
        editable: false,
        format: "HH:mm:ss",
        required: true,
        validation: "",
        validationHint: "",
        customClass: "",
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        onCreated: "",
        onMounted: "",
        onChange: "",
        onFocus: "",
        onBlur: "",
        onValidate: "",
      },
      id: "timerange47503",
    },
    {
      type: "slider",
      icon: "slider-field",
      formItemFlag: true,
      options: {
        name: "slider54714",
        label: "价格保护",
        labelAlign: "",
        columnWidth: "200px",
        size: "",
        labelWidth: null,
        labelHidden: false,
        disabled: false,
        hidden: false,
        required: false,
        validation: "",
        validationHint: "",
        customClass: [],
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        min: 0,
        max: 100,
        step: 10,
        range: false,
        height: null,
        onCreated: "",
        onMounted: "",
        onChange: "",
        onValidate: "",
        showStops: true,
      },
      id: "slider54714",
    },
    {
      type: "textarea",
      icon: "textarea-field",
      formItemFlag: true,
      options: {
        name: "textarea64794",
        label: "其他信息",
        labelAlign: "",
        rows: 3,
        defaultValue: "",
        placeholder: "",
        columnWidth: "200px",
        size: "",
        labelWidth: null,
        labelHidden: false,
        readonly: false,
        disabled: false,
        hidden: false,
        required: false,
        validation: "",
        validationHint: "",
        customClass: "",
        labelIconClass: null,
        labelIconPosition: "rear",
        labelTooltip: null,
        minLength: null,
        maxLength: null,
        showWordLimit: false,
        onCreated: "",
        onMounted: "",
        onInput: "",
        onChange: "",
        onFocus: "",
        onBlur: "",
        onValidate: "",
      },
      id: "textarea64794",
    },
  ],
  formConfig: {
    modelName: "formData",
    refName: "vForm",
    rulesName: "rules",
    labelWidth: 150,
    labelPosition: "left",
    size: "",
    labelAlign: "label-right-align",
    cssCode: "",
    customClass: [],
    functions: "",
    layoutType: "PC",
    onFormCreated: "",
    onFormMounted: "",
    onFormDataChange: "",
  },
};
