<template>
  <div class="setting-container">
    <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px"
      label-position="top">
      <el-form-item label="审批人去重" prop="autoRepeat">
        <el-select v-model="formData.autoRepeat" placeholder="请选择去重类型" :style="{width: '100%'}">
          <el-option v-for="(item, index) in autoRepeatOptions" :key="index" :label="item.label"
            :value="item.value" :disabled="item.disabled"></el-option>
        </el-select>
        <el-checkbox v-model="formData.myAuditAutoPass" :checked="formData.myAuditAutoPass=='1'?true:false" true-label="1" false-label="0">发起人审批时自动通过</el-checkbox>
      </el-form-item>
      <el-form-item :label="`打印模板${formData.customPrint?'（先保存再设计模板）':''}`">
        <div style="display: inline-block">
        <el-switch inactive-text="系统默认" active-text="自定义"
                   v-model="formData.customPrint"></el-switch>
          <el-button
            v-if="formData.customPrint"
            type="default"
            icon="el-icon-document"
            size="mini"
            @click="handleDesign"
            v-noMoreClick
            style="margin-left: 10px"
          >设计模板</el-button>
        </div>
      </el-form-item>

      <el-form-item label="可发起时间段" prop="periodDateRange" :rules="formData.customPeriod?rules.periodDateRange:[{required:false}]">
        <div style="display: inline-block">
          <el-switch inactive-text="任意" active-text="自定义" v-model="formData.customPeriod"></el-switch>
        </div>
        <div v-if="formData.customPeriod">
          <span>时间：</span>
          <el-date-picker
            v-model="formData.periodDateRange"
            size="small"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </div>
        <div v-if="formData.customPeriod">
          <span>重复：</span>
          <el-radio-group v-model="formData.periodRepeatType">
            <el-radio label="">不重复</el-radio>
            <el-radio label="day">每天</el-radio>
            <el-radio label="week">每周</el-radio>
            <el-radio label="month">每月</el-radio>
            <el-radio label="year">每年</el-radio>
          </el-radio-group>
        </div>
        <div v-if="formData.customPeriod">
          <span>通过：</span>
          <el-input-number v-model="formData.periodPassNum" :min="0" :step="1" :precision="0"></el-input-number>
          <span style="font-size: 12px; color: #aaa;">指可审批通过的最大次数</span>
        </div>
      </el-form-item>

      <el-form-item label="可同时发起未完成数量">
        <div style="display: inline-block">
          <el-input-number v-model="formData.startTimes" :min="0" :step="1" :precision="0"></el-input-number>
          <span style="font-size: 12px; color: #aaa;">指最多的未完成数，0 表示不限制</span>
        </div>
      </el-form-item>
      <el-form-item label="审批意见">
        <el-checkbox v-model="formData.commentRequired" :checked="formData.commentRequired=='1'?true:false" true-label="1" false-label="0">必填</el-checkbox>
        <el-checkbox v-model="formData.commentInitiator" :checked="formData.commentInitiator=='1'?true:false" true-label="1" false-label="0">对发起人可见</el-checkbox>
      </el-form-item>
      <el-form-item label="审批意见填写提示" prop="commentTip">
        <el-input v-model="formData.commentTip" type="textarea" placeholder="请输入" :maxlength="100"
          show-word-limit :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
      </el-form-item>

    </el-form>
  </div>
</template>
<script>
import {getBpmFlow} from '@/api/bpm/flow'
import { getToken } from '@/utils/auth'
import {ProcessMetas} from '@/api/bpm/process'
export default {
  components: {},
  props: ['tabName', 'conf','title','flowId'],
  data() {
    return {
      formData: {
        autoRepeat: "1", //审批人去重
        myAuditAutoPass: "1", //发起人审批时自动通过
        commentTip: '', //审批意见填写提示
        commentRequired: "1",
        commentInitiator: "1",
        reportId:0,    //报表ID
        datasourceId:0, //数据源ID
        customPrint:false,
        customPeriod:false,
        periodDateRange: [],
        periodRepeatType:"",
        periodPassNum:0,
        startTimes:0,
      },
      rules: {
        autoRepeat: [{
          required: true,
          message: '请选择去重类型',
          trigger: 'change'
        }],
        periodDateRange:[{
          required: true,
          message: '请选择时间段',
          trigger: 'change'
        }]
      },
      autoRepeatOptions: [{
        "label": "启用自动去重",
        "value": "1"
      }, {
        "label": "不启用自动去重",
        "value": "0"
      }],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            const start = Vue.prototype.moment().startOf('day').toDate()
            const end = Vue.prototype.moment().endOf('day').toDate()
            picker.$emit('pick', [start, end])
          }
        },{
          text: '昨天',
          onClick (picker) {
            const start = Vue.prototype.moment().subtract(1, 'days').startOf('day').toDate()
            const end = Vue.prototype.moment().subtract(1, 'days').endOf('day').toDate()
            picker.$emit('pick', [start, end])
          }
        },{
          text: '最近7天',
          onClick (picker) {
            const start = Vue.prototype.moment().subtract(6, 'days').startOf('day').toDate()
            const end = Vue.prototype.moment().endOf('day').toDate()
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近30天',
          onClick (picker) {
            const start = Vue.prototype.moment().subtract(29, 'days').startOf('day').toDate()
            const end = Vue.prototype.moment().endOf('day').toDate()
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '本月',
          onClick (picker) {
            const start = Vue.prototype.moment().startOf('month').toDate()
            const end = Vue.prototype.moment().endOf('month').toDate()
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '上月',
          onClick (picker) {
            const start = Vue.prototype.moment().subtract(1, 'month').startOf('month').toDate()
            const end = Vue.prototype.moment().subtract(1, 'month').endOf('month').toDate()
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '本年',
          onClick (picker) {
            const start = Vue.prototype.moment().startOf('year').toDate()
            const end = Vue.prototype.moment().endOf('year').toDate()
            picker.$emit('pick', [start, end])
          }
        }]
      },
    }
  },
  created() {
    if (typeof this.conf === 'object' && this.conf !== null) {
      Object.assign(this.formData, this.conf)
    }
    if(this.flowId > 0) {
      getBpmFlow(this.flowId).then(resp=>{
        this.formData.customPrint = false
        if(resp.data.report_id > 0) {
          this.formData.customPrint = true
          this.formData.reportId = resp.data.report_id
        }
      })
    }

  },
  methods: {
    handleDesign() {
      if(this.flowId <= 0) {
        this.msgError("请先保存流程");
        return
      }
      ProcessMetas({flowId:this.flowId,isReport:true}).then(resp=>{
        //this.handleDesign2(JSON.parse(resp.data.metas))
        if(resp.data.reportId > 0) {
          this.formData.customPrint = true
        }
        window.open('bi.html#/report/design?_=' + resp.data.reportId+"&procInstId=1"+"&token="+getToken())
      })
   },

    getData() {
      return new Promise((resolve, reject) => {
        this.$refs['elForm'].validate(valid => {
          if (!valid) {
            reject({ target: this.tabName})
            return
          }
          resolve({ formData: this.formData, target: this.tabName})  // TODO 提交表单
        })
      })
    },
  }
}

</script>
<style lang="stylus" scoped>
.setting-container{
  width: 600px;
  height: 100%;
  margin: auto;
  background: white;
  padding: 16px;

  >>>.el-form--label-top .el-form-item__label{
    padding-bottom: 0;
  }
}
</style>>

