<template>
  <div class="app-container app-store-wrapper">
    <el-row :gutter="20">
      <el-col
        :span="4"
        v-for="(item, index) in stepList"
        :key="index"
        class="app-card-container"
      >
        <el-popover
          placement="right-start"
          width="250"
          trigger="hover"
          :content="item.remark"
          popper-class="app-card-popover"
        >
          <div class="app-card-popover-content">
            <p>{{ item.remark }}</p>
          </div>
          <el-card
            :body-style="{ padding: '0px' }"
            @click.native="doclick(item)"
            shadow="hover"
            class="app-card"
            slot="reference"
          >
            <div class="category">
              <el-tag size="medium">第{{ index + 1 }}步</el-tag>
            </div>

            <div class="image">
              <i
                v-if="item.icon && item.icon.indexOf('el-icon-') === 0"
                slot="prefix"
                :class="'el-icon ' + item.icon"
              />
              <i
                v-else-if="item.icon && item.icon.indexOf('fa fa-') === 0"
                slot="prefix"
                :class="item.icon"
              />
              <svg-icon
                v-else-if="item.icon"
                slot="prefix"
                :icon-class="item.icon"
                class="el-input__icon"
              />
            </div>
            <div style="padding: 14px">
              <div class="title">{{ item.name }}</div>
            </div>
          </el-card>
        </el-popover>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "zorecodeguide",

  data() {
    return {
      stepList: [
        {
          name: "新增功能",
          remark: "选择功能类型,填写功能名称等信息",
          icon: "fa fa-cogs",
          url: "/form/model/list",
          query: "0",
        },

        {
          name: "编辑表单字段",
          remark:
            "新增或修改字段后,需要及时保存,保存后要及时应用才能使用该字段",
          icon: "fa fa-th-list",
          url: "/form/model/list",
          query: "1",
        },
        {
          name: "生成菜单",
          remark: "选择上级菜单目录并生成菜单",
          icon: "nested",
          url: "/form/model/list",
          query: "2",
        },
        {
          name: "刷新页面",
          remark: "刷新页面后方可看得到菜单",
          icon: "fa fa-refresh",
        },
      ],
    };
  },
  created() {},
  activated() {},
  methods: {
    doclick(item) {
      if (!item.url || item.url == "") {
        return;
      }

      this.$router.push({ path: item.url });

      if (item.query && item.query != "") {
        localStorage.setItem("fromguide_zc", item.query);
      }
    },
  },
};
</script>
<style lang="scss" scoped >
.el-col-4 {
  min-width: 180px;
  max-width: 360px;
}

.app-card-container::after {
  content: "→";
  position: absolute;
  right: -30px;
  top: 50%;
  color: #03a9f4;
  font-weight: 900;
  font-size: 33px;
  transform: translateY(-50%);
}
.app-card-container:last-child::after {
  content: " ";
}
.app-card-popover {
  padding-bottom: 5px;
  .app-card-popover-content {
    .el-divider--horizontal {
      margin-bottom: 10px;
      margin-top: 0;
    }
    .el-descriptions-item__container {
      color: #909399;
    }
  }
}
.app-store-wrapper {
  .app-item {
    height: 30px;
    line-height: 30px;
    a {
      margin-left: 15px;
      &:hover {
        text-decoration: underline dotted;
      }
    }
    a:first-child {
      margin-right: 5px;
      margin-left: 0;
      text-decoration: none;
      /*color:#303133;*/
    }
    a.is-active {
      color: #1890ff;
      text-decoration: underline dotted;
    }
  }
  .app-item-condition {
    height: 33px;
    line-height: 33px;
    /*margin-top: 10px;*/
    margin-bottom: 5px;
  }
  .app-card-container {
    margin-left: 26px;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    .category {
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .el-card {
      /*height: 260px;*/
      &:hover {
        border: 1px solid #1890ff;
      }
    }
    .app-card {
      .title {
        display: block;
        overflow: hidden;
        /*font-size: 14px;*/
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
      .bottom {
        margin-top: 13px;
        line-height: 12px;
        font-size: 13px;
      }
      .cost {
        padding: 0;
        float: right;
      }
      .image {
        width: 100%;
        height: 130px;
        line-height: 180px;
        text-align: center;
        color: #1890ff;
        font-size: 5em;
        display: block;
      }
      .clearfix:before,
      .clearfix:after {
        display: table;
        content: "";
      }
      .clearfix:after {
        clear: both;
      }
    }
  }
}
</style>