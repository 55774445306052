import request from '@/utils/request'
// 生成时间:2021-09-08 10:25:56

// 查询devservice列表
export function listDevservice(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/gen/gen/devservice/list',
        method: 'post',
        data: data
    })
}

// 查询devservice详细
export function getDevservice (id) {
    return request({
        url: '/gen/gen/devservice/get?id='+id,
        method: 'get'
    })
}


// 新增devservice
export function addDevservice(data) {
    return request({
        url: '/gen/gen/devservice/add',
        method: 'post',
        data: data
    })
}

// 修改devservice
export function editDevservice(data) {
    return request({
        url: '/gen/gen/devservice/edit',
        method: 'post',
        data: data
    })
}

// 删除devservice
export function delDevservice(ids) {
    return request({
        url: '/gen/gen/devservice/del',
        method: 'post',
        data:{ids:ids}
    })
}



// 删除devservice查询模板
export function advquerydelDevservice(ids) {
    return request({
        url: '/gen/gen/devservice/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加devservice查询模板
export function advquerysaveDevservice(data) {
    return request({
        url: '/gen/gen/devservice/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表devservice查询模板列表
export function advquerylistDevservice(businessname) {
    return request({
        url: '/gen/gen/devservice/advquerylist?businessname='+businessname,
        method: 'get'
    })
}