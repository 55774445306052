import request from '@/utils/request'

// 查询在线用户列表
export function list(query) {
  return request({
    url: '/useronline/list',
    method: 'get',
    params: query
  })
}

// 强退用户
export function forceLogout(id) {
  return request({
    url: '/useronline/del',
    method: 'post',
    data:{
      ids:id
    }
  })
}
