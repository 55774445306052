<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-select v-model="selectValue" @change="selectChange">
          <el-option label="内容1" value="1"></el-option>
          <el-option label="内容2" value="2"></el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        <el-button @click="click3">测试弹窗</el-button>
      </el-col>
    </el-row>
    <div v-if="selectValue == '1'" style="margin-top: 10px; width: 100px; height: 100px; background-color: rgb(255, 255, 222);">内容1</div>
    <div v-if="selectValue == '2'" style="margin-top: 10px; width: 100px; height: 100px; background-color: rgb(222, 255, 222);">内容2</div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      append-to-body
      width="30%"
      :show-close="false"
      :before-close="handleClose">
      <span>这是一段信息</span>
      <span slot="title">
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "test",
  props: {
    params: {
      type: Object,
      default: () => { return undefined }
    },
    data: {
      type: Array,
      default: () => { return undefined }
    },
    settings: {
      type: Object,
      default: () => { return undefined }
    },
    defaultSettings: {
      type: Object,
      default: () => { return undefined }
    },
    readonly: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      selectValue: '',
      dialogVisible: false
    }
  },
  methods: {
    selectChange() {
      // 如果其他组件和这个值有关联，需要发送事件通知，如果没关联，可以忽略
      window.postMessage({
        type: 'bi_param_set',
        key: 'test_param',
        value: this.selectValue
      })
    },
    click3() {
      this.dialogVisible = true
    }
  }
}
</script>
<style scoped>
::v-deep .el-dialog__header {
  border-bottom: none;
  padding: 0px;
  padding-bottom: 0px;
}
</style>