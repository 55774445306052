import request from '@/utils/request'

// 查询流程列表
export function listBpmFlow(query) {
  return request({
    url: '/bpm/flow/list',
    method: 'get',
    params: query
  })
}

// 查询流程详细
export function getBpmFlow(id) {
  return request({
    url: '/bpm/flow/get?id=' + id,
    method: 'get'
  })
}

// 保存流程
export function saveBpmFlow(data) {
  return request({
    url: '/bpm/flow/save',
    method: 'post',
    data: data
  })
}

// 拷贝流程
export function copyBpmFlow(data) {
  return request({
    url: '/bpm/flow/copy',
    method: 'post',
    data: data
  })
}
// 导出流程
export function exportBpmFlow(query) {
  return request({
    url: '/bpm/flow/export',
    method: 'get',
    params: query
  })
}

// 删除流程
export function delBpmFlow(id) {
  return request({
    url: '/bpm/flow/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
// 流程创建菜单
export function flowCreatemenu(data) {
  return request({
    url: '/bpm/flow/createmenu',
    method: 'post',
    data: data
  })
}