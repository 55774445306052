<template>
  <div>
    <div ref="uppy"></div>
  </div>
</template>

<script>

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')
import Uppy from '@uppy/core';
import Tus from '@uppy/tus'
import StatusBar from '@uppy/status-bar';

import Dashboard from '@uppy/dashboard';

import {
  getNameByPath,getStsServer, addBigOss
} from "@/api/dss/fastdfsServer.js";

export default {
  mounted() {
    this.uppy = new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        // maxFileSize: 9000000, // 限制文件大小
        // maxNumberOfFiles: 3, // 限制文件数量
        // allowedFileTypes: ['image/jpeg', 'image/png', 'application/pdf', 'video/mp4', ''], // 允许的文件类型
      },
    })
        .use(Tus, {endpoint: '/group1/big/upload/',})
        .use(Dashboard, {
          inline: true,
          target: this.$refs.uppy,
          trigger: '#select-files',
          locale: {
            strings: {
              closeModal: '关闭上传框',
              addMoreFiles: '添加更多文件',
              addingMoreFiles: '正在添加文件',
              dashboardTitle: '文件上传',
              back: '返回',
              removeFile: '移除文件',
              saveChanges: '保存修改',

              dropHint: '拖曳你的文件',

              uploadComplete: '上传完成',
              complete:'完成',
              uploadPaused: '暂停',
              // Used as the hover text and screen reader label for the buttons to resume paused uploads.
              resumeUpload: '继续上传',
              // Used as the hover text and screen reader label for the buttons to pause uploads.
              pauseUpload: '暂停',
              // Used as the hover text and screen reader label for the buttons to retry failed uploads.
              retryUpload: '重试',
              // Used as the hover text and screen reader label for the buttons to cancel uploads.
              cancelUpload: '取消上传',
              // Used in a title, how many files are currently selected
              xFilesSelected: {
                0: '%{smart_count} file selected',
                1: '%{smart_count} files selected',
              },
              uploadingXFiles: {
                0: '上传中 %{smart_count} 个文件',
                1: '上传中 %{smart_count} 个文件夹',
              },

              // The "powered by Uppy" link at the bottom of the Dashboard.
              poweredBy:'',
              poweredBy2: '',


              editFileWithFilename: 'Edit file %{file}',
              done: '完成上传',
              save: '保存',
              cancel: '取消',
              dropPasteFiles: '拖曳文件至此 或 %{browseFiles}',
              dropPasteFolders: '拖曳文件夹至此 %{browseFolders}',
              browseFiles: '浏览文件',

            },
          },
        })
        ;

    let stsReq = {
      platform: "jpgfast",
      type: "general",
      source: "system",
      public: "1"
    }
    getStsServer(stsReq).then(response => {
      if (response.code == -1) { return; }
      let obj = response.data;
      let path = obj.fastdfspath;
      let scene = obj.fastdfsscene;
      let token = obj.accessToken;
      let accessExpire = obj.accessExpire
      let uploadurl = obj.uploadurl;
      let host = obj.fastdfshost;
      let group = obj.fastdfsgroup;

      let data = {
        "auth_token": token,
        "path": path,
        "scene": scene
      }
      this.uppy.setMeta(data)
    })

    this.uppy.on('upload-success', (file, response) => {
      let md5 = getNameByPath(response.uploadURL);
      addBigOss(stsReq,md5,this.uppy).then(ossResp => {
        if (ossResp.code === 0) {
          this.$message.success({message: '图片上传成功', duration: 2000});
        } else {
          this.$message.error({message: '图片上传失败,请重新上传', duration: 2000});
        }
      });
      console.log('Upload complete', file, response);
    });
  },
  methods: {
    openDashboard() {
      this.uppy.getPlugin('Dashboard').openModal();
    },
  },
  beforeDestroy() {
    this.uppy.close();
  },
};
</script>
