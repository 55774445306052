import request from '@/utils/request'

// 添加联动规则
export function addIotRuleLinkage(data) {
    return request({
      url: '/iot/rule/linkage/add',
      method: 'post',
      data: data
    })
}

// 获取联动规则
export function getIotRuleLinkage(id) {
    return request({
      url: '/iot/rule/linkage/get?id=' + id,
      method: 'get'
    })
}

// 更新联动规则
export function updateIotRuleLinkage(data) {
    return request({
      url: '/iot/rule/linkage/update',
      method: 'post',
      data: data
    })
  }
  
// 删除联动规则
export function delIotRuleLinkage(id) {
    return request({
      url: '/iot/rule/linkage/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 联动规则列表
export function listIotRuleLinkage(query) {
  return request({
    url: '/iot/rule/linkage/list',
    method: 'get',
    params: query
  })
}