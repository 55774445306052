<template>
<div class="app-container">
	<el-form
		:model="queryParams"
		ref="queryForm"
		:inline="true"
		label-width="68px"
		>
		<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="学员id" prop="student_id">
			<el-input
				v-model="queryParams.student_id"
				placeholder="请输入学员id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="评教id" prop="teaching_evaluation_id">
			<el-input
				v-model="queryParams.teaching_evaluation_id"
				placeholder="请输入评教id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="课程id" prop="course_id">
			<el-input
				v-model="queryParams.course_id"
				placeholder="请输入课程id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item>
			<el-button
				type="cyan"
				icon="el-icon-search"
				size="mini"
				@click="handleQuery"
				>搜索</el-button
			>
			<el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
				>重置</el-button
			>
		</el-form-item>
	</el-form>

	<vxe-grid
		resizable
		ref="xGrid"
		row-id="id"
		stripe
		show-overflow
		highlight-hover-row
		:loading="loading"
		:toolbar-config="tableToolbar"
		:columns="tableColumn"
		:data="dataList"
		@checkbox-change="checkboxChangeEvent"
		@checkbox-all="checkboxChangeEvent"
	>
		<template #toolbar_buttons>
			<el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAdd"
				v-hasPermi="[
					'/gbpx/gbpxstudentteachingevaluation/add',
				]"
				>新增</el-button
			>

			<el-button
				type="success"
				icon="el-icon-edit"
				size="mini"
				:disabled="single"
				@click="handleUpdate"
				v-hasPermi="[
					'/gbpx/gbpxstudentteachingevaluation/get',
					'/gbpx/gbpxstudentteachingevaluation/edit',
				]"
				>修改</el-button
			>

			<el-button
				type="danger"
				icon="el-icon-delete"
				size="mini"
				:disabled="multiple"
				@click="handleDelete"
				v-hasPermi="[
					'/gbpx/gbpxstudentteachingevaluation/del',
				]"
				>删除</el-button
			>
		</template>

		<template #defaultopr="{ row }">
			<el-button
				size="mini"
				type="text"
				icon="el-icon-edit"
				@click.stop="handleUpdate(row)"
				>修改
			</el-button>
			<el-button
				size="mini"
				type="text"
				icon="el-icon-delete"
				@click.stop="handleDelete(row)"
				>删除
			</el-button>
		</template>

		<template #empty>
			<span>
				<p>暂无数据</p>
			</span>
		</template>
	</vxe-grid>

	<vxe-pager
		background
		size="small"
		:loading="loading"
		:current-page.sync="queryParams.pageNum"
		:page-size.sync="queryParams.pageSize"
		:total="total"
		@page-change="handlePageChange"
	>
	</vxe-pager>

	<el-dialog
		:close-on-click-modal="false"
		:title="title"
		:visible.sync="open"
		width="800px"
		append-to-body
	>
		<el-form ref="form" :model="form" :rules="rules" label-width="100px">
			<el-row>
				<el-col :span="12">
					<el-form-item label="培训班id" prop="class_id">
						<el-input-number
							v-model="form.class_id"
							placeholder="请输入培训班id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="学员id" prop="student_id">
						<el-input-number
							v-model="form.student_id"
							placeholder="请输入学员id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="评教id" prop="teaching_evaluation_id">
						<el-input-number
							v-model="form.teaching_evaluation_id"
							placeholder="请输入评教id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="课程id" prop="course_id">
						<el-input-number
							v-model="form.course_id"
							placeholder="请输入课程id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="问卷id" prop="questionnaire_id">
						<el-input-number
							v-model="form.questionnaire_id"
							placeholder="请输入问卷id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="问题id" prop="question_id">
						<el-input-number
							v-model="form.question_id"
							placeholder="请输入问题id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="答案id" prop="answer_id">
						<el-input-number
							v-model="form.answer_id"
							placeholder="请输入答案id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
			</el-row>
		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitForm">确 定</el-button>
			<el-button @click="cancel">返 回</el-button>
		</div>
	</el-dialog>
</div>
</template>
<script>
import {
	listGbpxStudentTeachingEvaluation,
	getGbpxStudentTeachingEvaluation,
	addGbpxStudentTeachingEvaluation,
	editGbpxStudentTeachingEvaluation,
	delGbpxStudentTeachingEvaluation,
} from "@/api/gbpx/gbpx_student_teaching_evaluation";
export default {
	name: "gbpx-student-teaching-evaluation",
	data() {
		return { 
			tableToolbar: {
				perfect: true,
				zoom: true,
				custom: true,
				refresh: {
					query: this.handleQuery,
				},
				slots: {
					buttons: "toolbar_buttons",
				},
			},
			tableColumn: [
				{ type: "checkbox", width: 60, fixed: "left" },
				{ field: "id", title: "id", minWidth: 120, fixed: "left" },
				{ field: "class_id", title: "培训班id", minWidth: 120 },
				{ field: "student_id", title: "学员id", minWidth: 120 },
				{ field: "teaching_evaluation_id", title: "评教id", minWidth: 120 },
				{ field: "course_id", title: "课程id", minWidth: 120 },
				{ field: "questionnaire_id", title: "问卷id", minWidth: 120 },
				{ field: "question_id", title: "问题id", minWidth: 120 },
				{ field: "answer_id", title: "答案id", minWidth: 120 },
				{ field: "create_by", title: "创建者", minWidth: 120, visible: false },
				{ field: "create_time", title: "创建时间", minWidth: 120, formatter: "formatDate", visible: false },
				{ field: "update_by", title: "更新者", minWidth: 120, visible: false },
				{ field: "update_time", title: "更新时间", minWidth: 120, formatter: "formatDate" },
				{
					field: "",
					title: "操作",
					width: 180,
					fixed: "right",
					align: "center",
					slots: { default: "defaultopr" },
				},
			],
			loading: true,
			ids: [],
			single: true,
			multiple: true,
			total: 0,
			dataList: [],
			title: "",
			open: false,
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				id: undefined,
				student_id: undefined,
				teaching_evaluation_id: undefined,
				course_id: undefined,
			},
			form: {},
			rules: { 
				class_id: [{ required: true, message: "培训班id不能为空", trigger: "blur" }],
				student_id: [{ required: true, message: "学员id不能为空", trigger: "blur" }],
				teaching_evaluation_id: [{ required: true, message: "评教id不能为空", trigger: "blur" }],
				course_id: [{ required: true, message: "课程id不能为空", trigger: "blur" }],
				questionnaire_id: [{ required: true, message: "问卷id不能为空", trigger: "blur" }],
				question_id: [{ required: true, message: "问题id不能为空", trigger: "blur" }],
				answer_id: [{ required: true, message: "答案id不能为空", trigger: "blur" }],
			}
		};
	},
	created() { 
		this.getList();
	},
	methods: {
		getList() {
			this.loading = true;
			this.ids = [];
			this.single = true;
			this.multiple = true;

			listGbpxStudentTeachingEvaluation(this.queryParams).then((response) => { 
				this.dataList = response.data.list;
				this.total = response.data.total;
				this.loading = false;
			});
		},
		
		cancel() {
			this.open = false;
			this.reset();
		},

		reset() {
			this.form = { 
				class_id: undefined,
				student_id: undefined,
				teaching_evaluation_id: undefined,
				course_id: undefined,
				questionnaire_id: undefined,
				question_id: undefined,
				answer_id: undefined,
			};
			this.resetForm("form");
		},

		handleQuery() {
			this.queryParams.pageNum = 1;
			this.getList();
		},

		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},

		handleAdd() {
			this.reset();
			this.open = true;
			this.title = "添加";
		},

		handleUpdate(row) {
			this.reset();
			const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
			getGbpxStudentTeachingEvaluation(id).then((response) => {
				this.form = response.data;
				this.open = true;
				this.title = "修改";
			});
		},

		submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if (this.form.id != null) {
						editGbpxStudentTeachingEvaluation(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("修改成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					} else {
						addGbpxStudentTeachingEvaluation(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("新增成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					}
				}
			});
		},

		handleDelete(row) {
			const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
			this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(function () {
				return delGbpxStudentTeachingEvaluation(ids);
			})
			.then(() => {
				setTimeout(() => {
					this.getList();
				}, 300);
				this.msgSuccess("删除成功");
			})
			.catch(function () {});
		},

		checkboxChangeEvent({ records }) {
			this.ids = records.map((item) => item.id);
			this.single = records.length != 1;
			this.multiple = !records.length;
		},

		handlePageChange({ currentPage, pageSize }) {
			if (this.queryParams.pageSize == pageSize) {
				this.getList();
			} else {
				this.queryParams.pageSize = pageSize;
				this.handleQuery();
			}
		},
	}
};
</script>
