<template>
    <el-dialog
        title="配置"
        :visible.sync="show"
        width="60%"
        :before-close="handleClose"
    >
        <jf-table
            ref="jftable"
            :loading="loading"
            :columns="tableColumn"
            :data="list"
            :height="600"
            :pager="false"
            :checkbox="false"
            :operate="false"
        >
            <template slot="defaultIsNull" slot-scope="{ row }">
                <div>{{ row.IS_NULLABLE === "NO" ? "否" : "是" }}</div>
            </template>

            <template slot="defaultEdit" slot-scope="{ row }">
                <el-select
                    v-model="row.type"
                    @change="(e) => typeChange(e, row)"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in formList"
                        :key="item.type"
                        :label="item.label"
                        :value="item.type"
                    >
                    </el-option>
                </el-select>
            </template>

            <!-- <template slot="defaultcategory" slot-scope="{ row }">
                {{ categoryFormat(row) }}
            </template> -->
        </jf-table>

        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import {
    containers,
    basicFields,
    advancedFields,
    customFields,
} from "@/vform/components/form-designer/widget-panel/widgetsConfig";
import designer from "@/vform/lang/zh-CN";
export default {
    props: {
        // data: {
        //     type: Array,
        //     default: () => [],
        // },
        // dicttypelist: {
        //     type: Array,
        //     default: () => [],
        // },
        // tablename: {
        //     type: String,
        //     default: "",
        // },
    },
    data() {
        return {
            show: false,
            list: [],
            containers,
            basicFields,
            advancedFields,
            customFields,
            designer,
            tableColumn: [
                { field: "COLUMN_NAME", title: "字段列名" },
                { field: "DATA_TYPE", title: "物理类型" },
                {
                    field: "IS_NULLABLE",
                    title: "允许为空",
                    slots: {
                        default: "defaultIsNull",
                    },
                },

                { field: "COLUMN_DEFAULT", title: "默认值" },
                { field: "COLUMN_COMMENT", title: "字段描述" },
                {
                    field: "",
                    title: "选择表单",
                    slots: {
                        default: "defaultEdit",
                    },
                },
            ],

            loading: false,
            formList: [],
            widgetList: [],
        };
    },
    methods: {
        typeChange(e, row) {},
        getlabelText(value) {
            const widgetLabel = this.designer.designer.widgetLabel;
            return widgetLabel[value];
        },
        getInit(list) {
            this.show = true;
            this.loading = true;
            this.list = list;
            console.log(this.designer);
            this.formList = this.basicFields.concat(this.advancedFields);
            const widgetLabel = this.designer.designer.widgetLabel;
            this.formList.forEach((item) => {
                item.label = widgetLabel[item.type];
            });
            this.loading = false;
        },
        handleClose() {
            this.show = false;
        },
        generateRandomFourDigitNumber() {
            // 生成一个0到9999之间的随机整数
            const randomNumber = Math.floor(Math.random() * 900000 + 9000);

            // 如果随机数不足四位数，前面补0，以确保是四位数
            const fourDigitNumber = randomNumber.toString().padStart(4, "0");

            return fourDigitNumber;
        },
        onSubmit() {
            let widgetList = [];
            this.list.forEach((item) => {
                if (!item.type) {
                    item.type = this.formList[0].type;
                }
                let index = this.formList.findIndex(
                    (o) => o.type === item.type
                );
                if (index > -1) {
                    let obj = JSON.parse(JSON.stringify(this.formList[index]));
                    if (item.IS_NULLABLE === "NO") {
                        obj.required = true;
                    }
                    obj.options.label = item.COLUMN_COMMENT || item.COLUMN_NAME;
                    if (item.COLUMN_DEFAULT) {
                        obj.options.defaultValue = item.COLUMN_DEFAULT;
                    }
                    if (item.COLUMN_COMMENT) {
                        obj.options.placeholder = item.COLUMN_COMMENT;
                    }
                    obj.id = item.COLUMN_NAME;
                    // this.generateRandomFourDigitNumber()
                    obj.options.name = obj.id;
                    delete obj.label;
                    widgetList.push(obj);
                }
            });
            console.log(widgetList);
            this.$emit("getWidgetList", widgetList);
            this.show = false;
        },
    },
};
</script>