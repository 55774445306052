<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        >
            <el-form-item label="标题" prop="title">
                <el-input
                    v-model="queryParams.title"
                    placeholder="请输入标题"
                    clearable
                    style="width: 150px"
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="会员ID" prop="user_id">
                <el-input
                    v-model="queryParams.user_id"
                    placeholder="请输入会员ID"
                    clearable
                    style="width: 130px"
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="接口ID" prop="api_id">
                <el-input
                    v-model="queryParams.api_id"
                    placeholder="请输入接口ID"
                    clearable
                    style="width: 130px"
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="应用ID" prop="app_id">
                <el-input
                    v-model="queryParams.app_id"
                    placeholder="请输入应用ID"
                    clearable
                    style="width: 130px"
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select
                    v-model="queryParams.status"
                    placeholder="状态"
                    clearable
                    size="small"
                    style="width: 120px"
                >
                    <el-option
                        v-for="dict in statusOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
                <el-date-picker
                    v-model="dateRange"
                    size="small"
                    style="width: 240px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="list"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons> </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
            <template #detail="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(row, index)"
                    >详细</el-button
                >
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="list"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left"> </template>
            <template slot="status" slot-scope="{ row }">
                {{ statusFormat({ row }) }}
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(row)"
                    >详细</el-button
                >
            </template>
        </jf-table>

        <!-- Api日志详细 -->
        <el-dialog
            title="Api日志详细"
            :visible.sync="open"
            width="700px"
            append-to-body
        >
            <el-form ref="form" :model="form" label-width="100px" size="mini">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="操作模块：" v-if="false"
                            >{{ form.title }}
                        </el-form-item>

                        <el-form-item label="访问信息："
                            >{{ form.user_id }} /{{ form.api_id }} /{{
                                form.app_id
                            }}
                            / {{ form.ip }} / {{ form.location }}
                            （会员ID/ApiID/应用ID/IP/地点）
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="请求方式：">{{
                            form.request_method
                        }}</el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="操作方法：">{{
                            form.method
                        }}</el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="请求参数：">{{
                            form.request_param
                        }}</el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="返回参数：">{{
                            form.json_result || form.error_msg
                        }}</el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="状态：">
                            <div v-if="form.status === '1'">成功</div>
                            <div v-else>失败</div>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="创建时间：">{{
                            parseTime(form.create_time)
                        }}</el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="open = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { list } from "@/api/data/api/log";
import moment from "moment";

export default {
    name: "data-api-log",
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "编号", fixed: "left" },
                { field: "title", title: "标题",   is_query: true, },
                { field: "user_id", title: "会员ID", is_query: true },
                { field: "api_id", title: "接口ID", is_query: true },
                { field: "app_id", title: "应用ID", is_query: true },
                { field: "request_url", title: "请求URL" },
                { field: "request_method", title: "请求方式" },
                { field: "ip", title: "IP地址" },
                { field: "location", title: "地点", width: 100 },
                {
                    field: "status",
                    title: "状态",
                    width: 100,
                    // formatter: this.statusFormat,
                    slots: {
                        default: "status",
                    },
                    options: [
                        { key: "0", value: "失败" },
                        { key: "1", value: "成功" },
                    ],
                    options_label: "value",
                    options_value: "key",
                    is_query: true,
                    form_type: 2,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    width: 180,
                    formatter: "formatDate",
                    form_type: 3,
                    is_query: true,
                    dateRange: [
                        moment().format("yyyy-MM-DD"),
                        moment().add(1, "days").format("yyyy-MM-DD"),
                    ],
                },
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "detail" },
                // },
            ],

            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 表格数据
            list: [],
            // 是否显示弹出层
            open: false,
            // 类型数据字典
            statusOptions: [
                { key: "0", value: "失败" },
                { key: "1", value: "成功" },
            ],
            // 日期范围
            dateRange: [
                moment().format("yyyy-MM-DD"),
                moment().add(1, "days").format("yyyy-MM-DD"),
            ],
            // 表单参数
            form: {},
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                title: undefined,
                user_id: undefined,
                app_id: undefined,
                api_id: undefined,
                status: undefined,
            },
        };
    },
    created() {
        this.queryParams = this.addDateRange(this.queryParams, this.dateRange);
        this.getList();
      
    },
    methods: {
        //筛选和排序
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },
        /** 查询登录日志 */
        getList() {
            this.loading = true;
            list(this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.list = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 操作日志状态字典翻译
        statusFormat({ row }) {
            return this.selectDictLabel(this.statusOptions, row.status);
        },
        /** 搜索按钮操作 */
        handleQuery(e) {
           if(e){    this.queryParams = this.deepCopy(e);};
            console.log(e);
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [
                moment().format("yyyy-MM-DD"),
                moment().add(1, "days").format("yyyy-MM-DD"),
            ];
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.multiple = !selection.length;
        },
        /** 详细按钮操作 */
        handleView(row) {
            this.open = true;
            this.form = row;
        },
    },
};
</script>

