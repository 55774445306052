<template>
    <el-form-item :label="i18nt('designer.setting.widgetFieldType')" v-if="!designer.isTopic">
        <!-- <el-select v-model="optionModel.fieldType" @change="change">
        <el-option v-for="(item,index) in designer.dbtableList" :key="index" :label="item.COLUMN_TYPE"
                   :value="item.COLUMN_TYPE">
        </el-option>
      </el-select> -->
        <el-input
            v-model="optionModel.fieldType"
            placeholder="请输入字段类型"
        ></el-input>
    </el-form-item>
</template>
  
  <script>
import i18n from "@/vform/utils/i18n";

export default {
    name: "field-type-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            //   dbtableList: designer.dbtableList||[]
        };
    },
    watch: {
        optionModel: {
            handler(val) {
                if (!this.optionModel.fieldType) {
                    let index = this.designer.dbtableList.findIndex(
                        (o) => o.COLUMN_NAME == this.selectedWidget.id
                    );
                    this.optionModel.fieldType =
                        index > -1
                            ? this.designer.dbtableList[index].COLUMN_TYPE
                            : "";
                }
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
      
    },
    methods: {},
};
</script>
  
  <style scoped>
</style>
  