<template>
    <div >
        <el-form
            :model="formConfig"
            size="mini"
            label-position="left"
            label-width="120px"
            class="setting-form"
            @submit.native.prevent
        >
            <el-collapse
                v-model="formActiveCollapseNames"
                class="setting-collapse"
            >
                <el-collapse-item
                    name="1"
                    :title="i18nt('designer.setting.basicSetting')"
                >
                    <el-form-item
                        :label="
                            i18nt('designer.setting.survey_project_setting')
                        "
                        v-if="designer.isTopic && !designer.isAddTopic"
                    >
                        <el-button type="primary" @click="openTopicSetting"
                            >配置</el-button
                        >
                    </el-form-item>
                    <div class="" v-if="!designer.isTopic">
                        <el-form-item
                            :label="i18nt('designer.setting.formSize')"
                        >
                            <el-select v-model="formConfig.size">
                                <el-option
                                    v-for="item in formSizes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item
                            :label="i18nt('designer.setting.labelPosition')"
                        >
                            <!-- <el-radio-group v-model="formConfig.labelPosition" class="radio-group-custom">
              <el-radio-button label="left">{{i18nt('designer.setting.leftPosition')}}</el-radio-button>
              <el-radio-button label="top">{{i18nt('designer.setting.topPosition')}}</el-radio-button>
            </el-radio-group> -->
                            <div class="btns-list">
                                <div
                                    class="btns-item"
                                    @click="btnChange('left', 'labelPosition')"
                                    :class="{
                                        active:
                                            formConfig.labelPosition === 'left',
                                    }"
                                >
                                    {{ i18nt("designer.setting.leftPosition") }}
                                </div>
                                <div
                                    class="btns-item"
                                    @click="btnChange('top', 'labelPosition')"
                                    :class="{
                                        active:
                                            formConfig.labelPosition === 'top',
                                    }"
                                >
                                    {{ i18nt("designer.setting.topPosition") }}
                                </div>
                                <!-- <div
                class="btns-item"
                @click="btnChange('right','labelPosition')"
                :class="{
                    active: formConfig.labelPosition === 'right',
                }"
            >
                {{ i18nt("designer.setting.rightPosition") }}
            </div> -->
                            </div>
                        </el-form-item>
                        <el-form-item
                            :label="i18nt('designer.setting.labelAlign')"
                            v-if="formConfig.labelPosition !== 'top'"
                        >
                            <!-- <el-radio-group v-model="formConfig.labelAlign" class="radio-group-custom">
              <el-radio-button label="label-left-align">{{i18nt('designer.setting.leftAlign')}}</el-radio-button>
              <el-radio-button label="label-center-align">{{i18nt('designer.setting.centerAlign')}}</el-radio-button>
              <el-radio-button label="label-right-align">{{i18nt('designer.setting.rightAlign')}}</el-radio-button>
            </el-radio-group> -->
                            <div class="btns-list">
                                <div
                                    class="btns-item"
                                    @click="
                                        btnChange(
                                            'label-left-align',
                                            'labelAlign'
                                        )
                                    "
                                    :class="{
                                        active:
                                            formConfig.labelAlign ===
                                            'label-left-align',
                                    }"
                                >
                                    {{ i18nt("designer.setting.leftAlign") }}
                                </div>
                                <div
                                    class="btns-item"
                                    @click="
                                        btnChange(
                                            'label-center-align',
                                            'labelAlign'
                                        )
                                    "
                                    :class="{
                                        active:
                                            formConfig.labelAlign ===
                                            'label-center-align',
                                    }"
                                >
                                    {{ i18nt("designer.setting.centerAlign") }}
                                </div>
                                <div
                                    class="btns-item"
                                    @click="
                                        btnChange(
                                            'label-right-align',
                                            'labelAlign'
                                        )
                                    "
                                    :class="{
                                        active:
                                            formConfig.labelAlign ===
                                            'label-right-align',
                                    }"
                                >
                                    {{ i18nt("designer.setting.rightAlign") }}
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item
                            :label="i18nt('designer.setting.labelWidth')"
                        >
                            <el-input-number
                                v-model="formConfig.labelWidth"
                                :min="0"
                                style="width: 100%"
                            ></el-input-number>
                        </el-form-item>
                        <!-- <el-form-item :label="i18nt('designer.setting.labelWidth')">
            <el-input-number v-model="formConfig.labelWidth" :min="0" style="width: 100%"></el-input-number>
          </el-form-item> -->
                        <el-form-item
                            :label="i18nt('designer.setting.formCache')"
                        >
                            <el-switch
                                v-model="formConfig.formCache"
                            ></el-switch>
                            <!-- <el-button type="info" icon="el-icon-edit" plain round @click="editFormCss">{{i18nt('designer.setting.addCss')}}</el-button> -->
                        </el-form-item>
                        <el-form-item
                            :label="i18nt('designer.setting.islogicdel')"
                        >
                            <el-switch
                                v-model="formConfig.islogicdel"
                            ></el-switch>
                            <!-- <el-button type="info" icon="el-icon-edit" plain round @click="editFormCss">{{i18nt('designer.setting.addCss')}}</el-button> -->
                        </el-form-item>
                        <!-- -->
                        <el-form-item
                            :label="i18nt('designer.setting.formCss')"
                        >
                            <el-button
                                type="info"
                                icon="el-icon-edit"
                                plain
                                round
                                @click="editFormCss"
                                >{{
                                    i18nt("designer.setting.addCss")
                                }}</el-button
                            >
                        </el-form-item>
                        <el-form-item
                            :label="i18nt('designer.setting.customClass')"
                        >
                            <el-select
                                v-model="formConfig.customClass"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                            >
                                <el-option
                                    v-for="(item, idx) in cssClassList"
                                    :key="idx"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- -->
                        <el-form-item
                            :label="i18nt('designer.setting.globalFunctions')"
                        >
                            <el-button
                                type="info"
                                icon="el-icon-edit"
                                plain
                                round
                                @click="editGlobalFunctions"
                                >{{
                                    i18nt("designer.setting.addEventHandler")
                                }}</el-button
                            >
                        </el-form-item>
                        <el-form-item label-width="0">
                            <el-divider class="custom-divider">{{
                                i18nt("designer.setting.formSFCSetting")
                            }}</el-divider>
                        </el-form-item>
                        <el-form-item
                            :label="i18nt('designer.setting.formModelName')"
                        >
                            <el-input
                                type="text"
                                v-model="formConfig.modelName"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            :label="i18nt('designer.setting.formRefName')"
                        >
                            <el-input
                                type="text"
                                v-model="formConfig.refName"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            :label="i18nt('designer.setting.formRulesName')"
                        >
                            <el-input
                                type="text"
                                v-model="formConfig.rulesName"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            :label="i18nt('designer.setting.dialogWidthName')"
                        >
                            <el-input
                                type="text"
                                v-model="formConfig.dialogWidth"
                            ></el-input>
                            <!-- <div class="font-size-12" style="color:red"></div> -->
                        </el-form-item>
                    </div>
                </el-collapse-item>

                <el-collapse-item
                    v-if="showEventCollapse()"
                    name="2"
                    :title="i18nt('designer.setting.eventSetting')"
                >
                    <el-form-item label="onFormCreated" label-width="150px">
                        <el-button
                            type="info"
                            icon="el-icon-edit"
                            plain
                            round
                            :class="[
                                getFormEventHandled('onFormCreated')
                                    ? 'button-text-highlight'
                                    : '',
                            ]"
                            @click="editFormEventHandler('onFormCreated')"
                        >
                            {{
                                i18nt("designer.setting.addEventHandler")
                            }}</el-button
                        >
                    </el-form-item>
                    <el-form-item label="onFormMounted" label-width="150px">
                        <el-button
                            type="info"
                            icon="el-icon-edit"
                            plain
                            round
                            :class="[
                                getFormEventHandled('onFormMounted')
                                    ? 'button-text-highlight'
                                    : '',
                            ]"
                            @click="editFormEventHandler('onFormMounted')"
                        >
                            {{
                                i18nt("designer.setting.addEventHandler")
                            }}</el-button
                        >
                    </el-form-item>
                    <!-- -->
                    <el-form-item label="onFormDataChange" label-width="150px">
                        <el-button
                            type="info"
                            icon="el-icon-edit"
                            plain
                            round
                            :class="[
                                getFormEventHandled('onFormDataChange')
                                    ? 'button-text-highlight'
                                    : '',
                            ]"
                            @click="editFormEventHandler('onFormDataChange')"
                        >
                            {{
                                i18nt("designer.setting.addEventHandler")
                            }}</el-button
                        >
                    </el-form-item>
                    <!-- -->
                    <!--
          <el-form-item label="onFormValidate">
            <el-button type="info" icon="el-icon-edit" plain round @click="editFormEventHandler('onFormValidate')">
              {{i18nt('designer.setting.addEventHandler')}}</el-button>
          </el-form-item>
          -->
                </el-collapse-item>
            </el-collapse>
        </el-form>

        <el-dialog
            :title="i18nt('designer.setting.editFormEventHandler')"
            :visible.sync="showFormEventDialogFlag"
            v-if="showFormEventDialogFlag"
            :show-close="true"
            class="small-padding-dialog"
            v-dialog-drag
            append-to-body
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :destroy-on-close="true"
        >
            <el-alert
                type="info"
                :closable="false"
                :title="'form.' + eventParamsMap[curEventName]"
            ></el-alert>
            <code-editor
                :mode="'javascript'"
                :readonly="false"
                v-model="formEventHandlerCode"
                ref="ecEditor"
            ></code-editor>
            <el-alert type="info" :closable="false" title="}"></el-alert>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showFormEventDialogFlag = false">
                    {{ i18nt("designer.hint.cancel") }}</el-button
                >
                <el-button type="primary" @click="saveFormEventHandler">
                    {{ i18nt("designer.hint.confirm") }}</el-button
                >
            </div>
        </el-dialog>

        <el-dialog
            :title="i18nt('designer.setting.formCss')"
            :visible.sync="showEditFormCssDialogFlag"
            v-if="showEditFormCssDialogFlag"
            :show-close="true"
            class="small-padding-dialog"
            v-dialog-drag
            append-to-body
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :destroy-on-close="true"
        >
            <code-editor
                :mode="'css'"
                :readonly="false"
                v-model="formCssCode"
            ></code-editor>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showEditFormCssDialogFlag = false">
                    {{ i18nt("designer.hint.cancel") }}</el-button
                >
                <el-button type="primary" @click="saveFormCss">
                    {{ i18nt("designer.hint.confirm") }}</el-button
                >
            </div>
        </el-dialog>

        <el-dialog
            :title="i18nt('designer.setting.globalFunctions')"
            :visible.sync="showEditFunctionsDialogFlag"
            v-if="showEditFunctionsDialogFlag"
            :show-close="true"
            class="small-padding-dialog"
            v-dialog-drag
            append-to-body
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :destroy-on-close="true"
        >
            <code-editor
                :mode="'javascript'"
                :readonly="false"
                v-model="functionsCode"
                ref="gfEditor"
            ></code-editor>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showEditFunctionsDialogFlag = false">
                    {{ i18nt("designer.hint.cancel") }}</el-button
                >
                <el-button type="primary" @click="saveGlobalFunctions">
                    {{ i18nt("designer.hint.confirm") }}</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            :title="i18nt('designer.setting.survey_project_setting')"
            :visible.sync="topicSettingShow"
            v-if="topicSettingShow"
            :show-close="true"
            class="small-padding-dialog"
            width="70%"
            v-dialog-drag
            append-to-body
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :destroy-on-close="true"
        >
            <el-form
                :model="surveyForm"
                :rules="rules"
                ref="surveyForm"
                label-width="70px"
                class="demo-ruleForm"
            >
                <el-card>
                    <div
                        slot="header"
                        class=""
                        style="display: flex; justify-content: flex-end"
                    >
                        <el-button type="primary" @click="add"
                            >新增区间</el-button
                        >
                    </div>
                    <el-table :data="surveySetting" border style="width: 100%">
                        <el-table-column
                            prop="date"
                            align="center"
                            label="分数1"
                            min-width="120"
                        >
                            <template slot-scope="scope">
                                <el-input
                                    v-model="scope.row.value1"
                                    @blur="
                                        intervalInterval(scope.$index, 'value1')
                                    "
                                ></el-input>
                                <div
                                    style="color: red; font-size: 12px"
                                    :id="'tips' + scope.$index + 'value1'"
                                ></div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            align="center"
                            label="分数2"
                            min-width="120"
                        >
                            <template slot-scope="scope">
                                <el-input
                                    v-model="scope.row.value2"
                                    @blur="
                                        intervalInterval(scope.$index, 'value2')
                                    "
                                ></el-input>
                                <div
                                    style="color: red; font-size: 12px"
                                    :id="'tips' + scope.$index + 'value2'"
                                ></div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="province"
                            align="center"
                            label="标题"
                            min-width="120"
                        >
                            <template slot-scope="{ row }">
                                <el-input v-model="row.title"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            align="center"
                            label="等级"
                            min-width="120"
                        >
                            <template slot-scope="{ row }">
                                <el-select
                                    v-model="row.levelname"
                                    clearable
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in options"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label"
                                    >
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="city"
                            align="center"
                            label="描述"
                            min-width="120"
                        >
                            <template slot-scope="{ row }">
                                <el-input
                                    type="textarea"
                                    rows="3"
                                    v-model="row.detail"
                                ></el-input>
                            </template>
                        </el-table-column>

                        <el-table-column
                            label="操作"
                            align="center"
                            width="100"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    type="danger"
                                    size="mini"
                                    @click="del(scope.$index)"
                                    >删除</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <!-- <div class="survey-setting-box">
                    <el-card
                        class="box-card"
                        v-for="(item, index) in surveySetting"
                        :key="index"
                    >
                        <div
                            slot="header"
                            class=""
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <div>设置{{ index + 1 }}</div>

                            <el-button
                                type="danger"
                                size="mini"
                                @click="del(index)"
                                >删除</el-button
                            >
                        </div>

                        <el-form-item label="分数区间" prop="name">
                            <div class="d-flex align-items-center">
                                <el-input
                                    v-model="item.value1"
                                    @blur="intervalInterval(index, 'value1')"
                                ></el-input>
                                <div class="" style="padding: 0 5px">——</div>
                                <el-input
                                    v-model="item.value2"
                                    @blur="intervalInterval(index, 'value2')"
                                ></el-input>
                            </div>
                            <div style="color: red" :id="'tips' + index"></div>
                        </el-form-item>
                        <el-form-item label="标题" prop="name">
                            <el-input v-model="item.title"></el-input>
                        </el-form-item>
                        <el-form-item label="等级" prop="name">
                            <el-input v-model="item.levelname"></el-input>
                        </el-form-item>
                        <el-form-item label="描述" prop="name">
                            <el-input
                                type="textarea"
                                rows="6"
                                v-model="item.detail"
                            ></el-input>
                        </el-form-item>
                    </el-card>
                    <el-card
                        class="box-card"
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        "
                    >
                        <i
                            class="el-icon-plus"
                            @click="add"
                            style="font-size: 30px"
                        ></i>
                    </el-card>
                </div> -->
            </el-form>
            <div
                style="
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 10px;
                "
            >
                <el-button type="primary" @click="onSubmit">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import i18n from "@/vform/utils/i18n";
import CodeEditor from "@/vform/components/code-editor/index";
import {
    deepClone,
    insertCustomCssToHead,
    insertGlobalFunctionsToHtml,
} from "@/vform/utils/util";

export default {
    name: "form-setting",
    mixins: [i18n],
    components: {
        CodeEditor,
    },
    props: {
        designer: Object,
        formConfig: Object,
    },
    inject: ["getDesignerConfig"],
    data() {
        return {
            topicSettingShow: false,
            designerConfig: this.getDesignerConfig(),

            formActiveCollapseNames: ["1", "2"],

            formSizes: [
                { label: "default", value: "" },
                { label: "large", value: "large" },
                { label: "medium", value: "medium" },
                { label: "small", value: "small" },
                { label: "mini", value: "mini" },
            ],

            showEditFormCssDialogFlag: false,
            formCssCode: "",
            cssClassList: [],

            showEditFunctionsDialogFlag: false,
            functionsCode: "",

            showFormEventDialogFlag: false,
            formEventHandlerCode: "",
            curEventName: "",

            eventParamsMap: {
                onFormCreated: "onFormCreated() {",
                onFormMounted: "onFormMounted() {",
                onFormDataChange:
                    "onFormDataChange(fieldName, newValue, oldValue, formModel, subFormName, subFormRowIndex) {",
                //'onFormValidate':     'onFormValidate() {',
            },
            rules: {},
            surveyForm: {},
            surveySetting: [{}],
            options: ["优秀", "良好", "合格", "不合格"],
        };
    },
    created() {
        //导入表单JSON后需要重新加载自定义CSS样式！！！
        this.designer.handleEvent("form-json-imported", () => {
            this.formCssCode = this.formConfig.cssCode;
            insertCustomCssToHead(this.formCssCode);
            this.extractCssClass();
            this.designer.emitEvent(
                "form-css-updated",
                deepClone(this.cssClassList)
            );
            this.getLevel();
        });
    },
    mounted() {
        /* SettingPanel和FormWidget为兄弟组件, 在FormWidget加载formConfig时，
         此处SettingPanel可能无法获取到formConfig.cssCode, 故加个延时函数！ */
        setTimeout(() => {
            this.formCssCode = this.formConfig.cssCode;
            insertCustomCssToHead(this.formCssCode);
            this.extractCssClass();
            this.designer.emitEvent(
                "form-css-updated",
                deepClone(this.cssClassList)
            );
        }, 1200);
        //
    },
    methods: {
        getLevel() {
            this.getDicts("eye_assessment_sub_type").then((response) => {
                let dictvalue = response.data.values || [];
                this.options = dictvalue.map((it) => {
                    return {
                        label: it.value,
                        value: it.key,
                        label_color: it.label_color,
                    };
                });
            });
        },

        openTopicSetting() {
            this.topicSettingShow = true;

            if (
                this.formConfig.survey_project_setting &&
                Object.keys(this.formConfig.survey_project_setting).length > 0
            ) {
                let arr = Object.keys(this.formConfig.survey_project_setting);
                let list = [];
                arr.forEach((o) => {
                    let val = {
                        value1: o.split("-")[0],
                        value2: o.split("-")[1],
                        title: this.formConfig.survey_project_setting[o].title,
                        detail: this.formConfig.survey_project_setting[o]
                            .detail,
                        levelname:
                            this.formConfig.survey_project_setting[o].levelname,
                    };
                    list.push(val);
                });
                this.surveySetting = list;
            }
        },
        onSubmit() {
            let val = {};
            for (let i = 0; i < this.surveySetting.length; i++) {
                let item = this.surveySetting[i];
                if (!item.value1 || !item.value2) {
                    return this.$message.warning(
                        "请检查是否所有数据都输入了分数"
                    );
                }
                val[item.value1 + "-" + item.value2] = {
                    title: item.title,
                    detail: item.detail,
                    levelname: item.levelname,
                };
            }
            this.formConfig.survey_project_setting = val;

            this.topicSettingShow = false;
        },
        intervalInterval(index, name) {
            let val = this.surveySetting[index];
            let html = document.querySelector("#tips" + index + name);
            //如果当前数据不是第一条，则判断当前数据分数1是否大于上一天的分数
            if (index > 0) {
                let val1 = this.surveySetting[index - 1];
                if (
                    val["value1"] * 1 <= val1["value1"] * 1 ||
                    val["value1"] * 1 <= val1["value2"] * 1
                ) {
                    html.innerHTML = "当前分数必须大于上一条的分数";
                    val[name] = "";
                    return;
                } else {
                    html.innerHTML = "";
                }
            }
            //如果当前输入的是分数2 则判断分数2是否大于分数1
            if (name === "value2") {
                if (val.value1 && val.value2 * 1 <= val.value1 * 1) {
                    html.innerHTML = "分数2必须大于分数1";
                    val[name] = "";
                } else {
                    html.innerHTML = "";
                }
            } else {
                if (val.value2 && val.value2 * 1 <= val.value1 * 1) {
                    html.innerHTML = "分数2必须大于分数1";
                    val[name] = "";
                } else {
                    html.innerHTML = "";
                }
            }
        },
        add() {
            let val = this.surveySetting[this.surveySetting.length - 1];
            if (!val.value1 || !val.value2) {
                this.$message.warning("请先输入最后一条数据的分数1和分数2");
                return;
            }
            this.surveySetting.push({});
        },
        del(index) {
            if (index == 0) {
                this.$message.warning("必须保留一条数据");
                return;
            }
            this.surveySetting.splice(index, 1);
        },
        btnChange(e, type) {
            if (e === this.formConfig[type]) {
                this.formConfig[type] = "";
                return;
            }
            this.formConfig[type] = e;
        },

        getFormEventHandled(eventName) {
            return (
                !!this.formConfig[eventName] &&
                this.formConfig[eventName].length > 0
            );
        },

        showEventCollapse() {
            if (this.designerConfig["eventCollapse"] === undefined) {
                return true;
            }

            return !!this.designerConfig["eventCollapse"];
        },

        editFormCss() {
            this.formCssCode = this.designer.formConfig.cssCode;
            this.showEditFormCssDialogFlag = true;
        },

        extractCssClass() {
            let regExp = /\..*{/g;
            let result = this.formCssCode.match(regExp);
            let cssNameArray = [];

            if (!!result && result.length > 0) {
                result.forEach((rItem) => {
                    let classArray = rItem.split(","); //切分逗号分割的多个class
                    if (classArray.length > 0) {
                        classArray.forEach((cItem) => {
                            let caItem = cItem.trim();
                            if (caItem.indexOf(".", 1) !== -1) {
                                //查找第二个.位置
                                let newClass = caItem.substring(
                                    caItem.indexOf(".") + 1,
                                    caItem.indexOf(".", 1)
                                ); //仅截取第一、二个.号之间的class
                                if (!!newClass) {
                                    cssNameArray.push(newClass.trim());
                                }
                            } else if (caItem.indexOf(" ") !== -1) {
                                //查找第一个空格位置
                                let newClass = caItem.substring(
                                    caItem.indexOf(".") + 1,
                                    caItem.indexOf(" ")
                                ); //仅截取第一、二个.号之间的class
                                if (!!newClass) {
                                    cssNameArray.push(newClass.trim());
                                }
                            } else {
                                if (caItem.indexOf("{") !== -1) {
                                    //查找第一个{位置
                                    let newClass = caItem.substring(
                                        caItem.indexOf(".") + 1,
                                        caItem.indexOf("{")
                                    );
                                    cssNameArray.push(newClass.trim());
                                } else {
                                    let newClass = caItem.substring(
                                        caItem.indexOf(".") + 1
                                    );
                                    cssNameArray.push(newClass.trim());
                                }
                            }
                        });
                    }
                });
            }

            //this.cssClassList.length = 0
            this.cssClassList.splice(0, this.cssClassList.length); //清除数组必须用splice，length=0不会响应式更新！！
            this.cssClassList = Array.from(new Set(cssNameArray)); //数组去重
        },

        saveFormCss() {
            this.extractCssClass();
            this.designer.formConfig.cssCode = this.formCssCode;
            insertCustomCssToHead(this.formCssCode);
            this.showEditFormCssDialogFlag = false;

            this.designer.emitEvent(
                "form-css-updated",
                deepClone(this.cssClassList)
            );
        },

        editGlobalFunctions() {
            this.functionsCode = this.designer.formConfig.functions;
            this.showEditFunctionsDialogFlag = true;
        },

        saveGlobalFunctions() {
            const codeHints = this.$refs.gfEditor.getEditorAnnotations();
            let syntaxErrorFlag = false;
            if (!!codeHints && codeHints.length > 0) {
                codeHints.forEach((chItem) => {
                    if (chItem.type === "error") {
                        syntaxErrorFlag = true;
                    }
                });

                if (syntaxErrorFlag) {
                    this.$message.error(
                        this.i18nt("designer.setting.syntaxCheckWarning")
                    );
                    return;
                }
            }

            this.designer.formConfig.functions = this.functionsCode;
            insertGlobalFunctionsToHtml(this.functionsCode);
            this.showEditFunctionsDialogFlag = false;
        },

        editFormEventHandler(eventName) {
            this.curEventName = eventName;
            this.formEventHandlerCode = this.formConfig[eventName];
            this.showFormEventDialogFlag = true;
        },

        saveFormEventHandler() {
            const codeHints = this.$refs.ecEditor.getEditorAnnotations();
            let syntaxErrorFlag = false;
            if (!!codeHints && codeHints.length > 0) {
                codeHints.forEach((chItem) => {
                    if (chItem.type === "error") {
                        syntaxErrorFlag = true;
                    }
                });

                if (syntaxErrorFlag) {
                    this.$message.error(
                        this.i18nt("designer.setting.syntaxCheckWarning")
                    );
                    return;
                }
            }

            //this.formConfig[this.curEventName] = this.formEventHandlerCode
            /* 注意：如果是options新增的事件属性，保存事件代码必须使用$set方法，！！ */
            this.$set(
                this.formConfig,
                this.curEventName,
                this.formEventHandlerCode
            );

            this.showFormEventDialogFlag = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.setting-form {
    ::v-deep .el-form-item__label {
        font-size: 13px;
        //text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    ::v-deep .el-form-item--mini.el-form-item {
        margin-bottom: 6px;
    }

    .radio-group-custom {
        ::v-deep .el-radio-button__inner {
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    .custom-divider.el-divider--horizontal {
        margin: 10px 0;
    }
}

.setting-collapse {
    ::v-deep .el-collapse-item__content {
        padding-bottom: 6px;
    }

    ::v-deep .el-collapse-item__header {
        font-style: italic;
        font-weight: bold;
    }
}

.small-padding-dialog {
    ::v-deep .el-dialog__body {
        padding: 6px 15px 12px 15px;
    }
}
.btns-list {
    margin: -2.5px;
    display: flex;
    flex-wrap: wrap;
    .btns-item {
        margin: 2.5px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding: 6px 12px;
        line-height: 1;
        font-size: 12px;
        cursor: pointer;
        &.active {
            color: #ffffff;
            background-color: #1890ff;
            border-color: #1890ff;
        }
    }
}
.survey-setting-box {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    max-height: 750px;
    overflow-y: auto;
}
.box-card {
    width: 350px;
    margin: 10px;
    min-height: 350px;
}
</style>
