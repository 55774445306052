import request from '@/utils/request'
// 生成时间:2023-01-12 13:43:41

// 查询bucket列表
export function listPath(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/dss/path/list',
        method: 'post',
        data: data
    })
}

// 查询bucket详细
export function getPath (id) {
    return request({
        url: '/dss/path/get?id='+id,
        method: 'get'
    })
}


// 新增bucket
export function addPath(data) {
    return request({
        url: '/dss/path/add',
        method: 'post',
        data: data
    })
}

// 修改bucket
export function editPath(data) {
    return request({
        url: '/dss/path/edit',
        method: 'post',
        data: data
    })
}

// 删除bucket
export function delPath(ids) {
    return request({
        url: '/dss/path/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 删除bucket查询模板
export function advquerydelPath(ids) {
    return request({
        url: '/dss/path/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加bucket查询模板
export function advquerysavePath(data) {
    return request({
        url: '/dss/path/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表bucket查询模板列表
export function advquerylistPath(businessname) {
    return request({
        url: '/dss/path/advquerylist?businessname='+businessname,
        method: 'get'
    })
}
// 导出bucket
export function exportPath(query,exportfields,tableColumn) {
     var data={
            filterstr:"",
            orderby:"",
            opstr:query.opstr ||{},
        };
        var filterstr={};
        for(var k in query){
         var item=query[k];
         if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
           filterstr[k]=item;
         }
        }
        var tarexportfieldslist={};
        for(var k in tableColumn){
          var tlbf = tableColumn[k];
           for(var i in exportfields){
             var ef = exportfields[i]
             if(tlbf.field!="" && tlbf.field && tlbf.field!=null && tlbf.field == ef){
               tarexportfieldslist.push({"filed":ef,"title":tlbf.title});
               break;
             }
           }
        }

        data["filterstr"] = JSON.stringify(filterstr);
        data["opstr"] = JSON.stringify(data["opstr"]);
        data["exportfields"] = JSON.stringify(tarexportfieldslist);
        return request({
            url: '/dss/path/export',
            method: 'post',
            data: data
        })
}
