import request from '@/utils/request'

// 添加节点
export function publishMessage(data) {
    return request({
      url: '/iot/publish/message',
      method: 'post',
      data: data
    })
}
export function subscripteMessage(data) {
    return request({
      url: '/iot/subscripte/message',
      method: 'post',
      data: data
    })
}

