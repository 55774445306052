import request from '@/utils/request'

export function getData(query) {
  return request({
    url: '/syslog/getdata',
    method: 'get',
    params: query
  })
}
export function deldata(data) {
    return request({
      url: '/syslog/deldata',
      method: 'post',
      data: data
    })
  }
