import request from '@/utils/request'

export function listStuStudentAward(query) {
  return request({
    url: '/stu/stustudentaward/list',
    method: 'get',
    params: query
  })
}

export function getStuStudentAward(id) {
  return request({
    url: '/stu/stustudentaward/get?id=' + id,
    method: 'get'
  })
}

export function addStuStudentAward(data) {
  return request({
    url: '/stu/stustudentaward/add',
    method: 'post',
    data: data
  })
}

export function editStuStudentAward(data) {
  return request({
    url: '/stu/stustudentaward/edit',
    method: 'post',
    data: data
  })
}

export function delStuStudentAward(ids) {
  return request({
    url: '/stu/stustudentaward/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

