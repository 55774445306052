<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="文件类别" prop="category">
                <el-select
                    v-model="queryParams.category"
                    placeholder="文件类别"
                    clearable
                    size="small"
                    style="width: 240px"
                >
                    <el-option
                        v-for="dict in categoryOptions.values"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="文件名称" prop="filename">
                <el-input
                    v-model="queryParams.filename"
                    placeholder="请输入文件名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="fileList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template> -->

        <!--默认操作按钮-->
        <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="fileList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>
            <template slot="category" slot-scope="{ row }">
                {{ categoryFormat({row: row }) }}
            </template>
            <template slot="filesize" slot-scope="{ row }">
                {{ filesizeFormat({ row: row  }) }}
            </template>
            <template slot="filesize" slot-scope="{ row }">
                {{ filesizeFormat({ row: row  }) }}
            </template>
            <template slot="public" slot-scope="{ row }">
                {{ publicFormat({ row: row  }) }}
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>
    </div>
</template>

<script>
import {
    listFile,
    getFile,
    addFile,
    updateFile,
    delFile,
} from "@/api/file/file";

export default {
    filename: "file-list",
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "编号", width: 100, fixed: "left" },
                {
                    field: "category",
                    title: "文件类别",
                    // formatter: this.categoryFormat,
                    slots: {
                        default: "category",
                    },
                    options: [],
                    options_value: "key",
                    options_label: "value",
                    form_type: 2,
                },
                {
                    field: "filename",
                    title: "文件名称",
                },
                {
                    field: "filesize",
                    title: "文件大小",
                    // formatter: this.filesizeFormat,
                    slots: {
                        default: "filesize",
                    },
                },
                { field: "mime_type", title: "mime类型" },
                { field: "image_width", title: "图片宽度", visible: false },
                { field: "image_height", title: "图片高度", visible: false },
                { field: "image_type", title: "图片类型", visible: false },
                { field: "storage", title: "存储位置" },
                { field: "hash", title: "文件哈希", visible: false },
                { field: "url", title: "文件url", visible: false },
                {
                    field: "public",
                    title: "是否公开",
                    // formatter: this.publicFormat,
                    slots: {
                        default: "public",
                    },
                    options: [
                        { value: 1, label: "是" },
                        { value: 0, label: "否" },
                    ],
                    options_value: "value",
                    options_label: "label",
                    form_type: 2,
                },
                {
                    field: "update_time",
                    title: "上传时间",
                    formatter: "formatDate",
                    form_type: 3,
                },
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 分类表格数据
            fileList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 状态数据字典
            categoryOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                filename: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                filename: [
                    {
                        required: true,
                        message: "分类文件名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },
        /** 查询分类列表 */
        getList() {
            this.loading = true;
            listFile(this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.categoryOptions = response.data.categoryOptions;
                this.fileList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
                for (let i = 0; i < this.tableColumn.length; i++) {
                    if(this.tableColumn[i].field ==='category'){
                        this.tableColumn[i].options= this.categoryOptions.values
                        return
                    }
                }
            });
        },
        // 字典翻译
        categoryFormat({ row }) {
            return this.selectDictLabel(
                this.categoryOptions.values,
                row.category + ""
            );
        },
        filesizeFormat({ row }) {
            const fileSize = row.filesize;
            if (fileSize < 1024) {
                return fileSize + "B";
            } else if (fileSize < 1024 * 1024) {
                var temp = fileSize / 1024;
                temp = temp.toFixed(2);
                return temp + "KB";
            } else if (fileSize < 1024 * 1024 * 1024) {
                var temp = fileSize / (1024 * 1024);
                temp = temp.toFixed(2);
                return temp + "MB";
            } else {
                var temp = fileSize / (1024 * 1024 * 1024);
                temp = temp.toFixed(2);
                return temp + "GB";
            }
        },
        publicFormat({ row }) {
            if (row.public == "1") {
                return "是";
            } else {
                return "否";
            }
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                code: undefined,
                filename: undefined,
                sort: 0,
                status: "1",
                remark: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(
                '是否确认删除分类编号为"' + ids + '"的数据项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(function () {
                    return delFile(ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有分类数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportCategory(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
    },
};
</script>
