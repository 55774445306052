<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="用户名" prop="username">
                <el-input
                    v-model="queryParams.username"
                    placeholder="请输入用户名"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="昵称" prop="nickname">
                <el-input
                    v-model="queryParams.nickname"
                    placeholder="请输入昵称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="userList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>

            <template #avatar="{ row }">
                <img v-if="row.avatar" :src="row.avatar" class="avatar" />
            </template> -->

            <!--默认操作按钮-->
            <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-money"
                    @click="handleRecharge(row)"
                    >充值
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

            <!--自定义空数据模板-->
            <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="userList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>
            <template slot="avatar" slot-scope="{ row }">
                <img v-if="row.avatar" :src="imgTranslate(row.avatar)" class="avatar" />
            </template>
            <template slot="group_id" slot-scope="{ row }">
                {{ groupFormat({ row }) }}
            </template>
            <template slot="gender" slot-scope="{ row }">
                {{ genderFormat({ row }) }}
            </template>
            <template slot="status" slot-scope="{ row }">
                {{ statusFormat({ row }) }}
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-money"
                    @click="handleRecharge(row)"
                    >充值
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>
        <el-dialog
            title="用户充值"
            :visible.sync="recharge_open"
            class="recharge-dialog"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
            v-dialogDrag
        >
            <div class="recharge-container">
                <el-row align="middle" type="flex">
                    <el-col :span="2">
                        <el-avatar
                            size="large"
                            :src="rechargeRow.avatar"
                            shape="square"
                        ></el-avatar>
                    </el-col>
                    <el-col :span="12">
                        <p>ID: {{ rechargeRow.id }}</p>
                        <p>昵称: {{ rechargeRow.nickname }}</p>
                        <p>手机号: {{ rechargeRow.phone }}</p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-tabs v-model="activeRecharge" style="font-size: 12px">
                        <el-tab-pane label="充值余额" name="money">
                            <el-form
                                ref="moneyForm"
                                :model="moneyForm"
                                :rules="moneyRules"
                                label-width="80px"
                            >
                                <el-row>
                                    <el-col :span="20" :offset="2">
                                        <el-form-item label="用户余额">
                                            <span>{{ rechargeRow.money }}</span>
                                        </el-form-item>
                                        <el-form-item label="变化" prop="type">
                                            <el-radio-group
                                                v-model="moneyForm.type"
                                            >
                                                <el-radio label="0"
                                                    >增加</el-radio
                                                >
                                                <el-radio label="1"
                                                    >减少</el-radio
                                                >
                                                <!--<el-radio label="2">最终余额</el-radio>-->
                                            </el-radio-group>
                                        </el-form-item>
                                        <el-form-item
                                            label="充值数目"
                                            prop="num"
                                        >
                                            <el-input
                                                type="text"
                                                v-model="moneyForm.num"
                                                autocomplete="off"
                                                clearable
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item
                                            label="备注"
                                            prop="remark"
                                        >
                                            <el-input
                                                type="textarea"
                                                v-model="moneyForm.remark"
                                                resize="none"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="充值积分" name="score">
                            <el-form
                                ref="scoreForm"
                                :model="scoreForm"
                                :rules="scoreRules"
                                label-width="80px"
                            >
                                <el-row>
                                    <el-col :span="20" :offset="2">
                                        <el-form-item label="用户积分">
                                            <span>{{ rechargeRow.score }}</span>
                                        </el-form-item>
                                        <el-form-item label="变化" prop="type">
                                            <el-radio-group
                                                v-model="scoreForm.type"
                                            >
                                                <el-radio label="0"
                                                    >增加</el-radio
                                                >
                                                <el-radio label="1"
                                                    >减少</el-radio
                                                >
                                                <!--<el-radio label="2" >最终积分</el-radio>-->
                                            </el-radio-group>
                                        </el-form-item>
                                        <el-form-item
                                            label="充值数目"
                                            prop="num"
                                        >
                                            <el-input
                                                type="text"
                                                v-model="scoreForm.num"
                                                autocomplete="off"
                                                clearable
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item
                                            label="备注"
                                            prop="remark"
                                        >
                                            <el-input
                                                type="textarea"
                                                v-model="scoreForm.remark"
                                                resize="none"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="submitRechargeForm">{{
                    activeRecharge == "money" ? "确定充值余额" : "确定充值积分"
                }}</el-button>
                <el-button @click="recharge_open = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 添加或修改用户对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
            v-dialogDrag
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="角色组" prop="group_id">
                            <el-select
                                v-model="form.group_id"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in userGroupList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="头像" prop="avatar">
                            <el-upload
                                class="upload-demo"
                                accept="image/png, image/jpeg"
                                action="/file/upload"
                                :headers="uploadHeaders"
                                :data="uploadData"
                                :multiple="false"
                                :limit="1"
                                :file-list="imageList"
                                :on-success="handleUploadSuccess"
                                :on-error="handleUploadError"
                                :on-exceed="handleUploadExceed"
                                :on-remove="handleUploadRemove"
                                list-type="picture"
                            >
                                <el-button size="small" type="primary"
                                    >点击上传</el-button
                                >
                                <div slot="tip" class="el-upload__tip">
                                    只能上传jpg/png文件，且不超过500kb
                                </div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用户名" prop="username">
                            <el-input
                                v-model="form.username"
                                placeholder="用户名"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="昵称" prop="nickname">
                            <el-input
                                v-model="form.nickname"
                                placeholder="昵称"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="密码" prop="password">
                            <el-input
                                v-model="form.password"
                                type="password"
                                :placeholder="
                                    form.id ? '不修改密码请留空' : '请输入密码'
                                "
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="form.phone"
                                placeholder="手机号"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="form.email" placeholder="邮箱" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别">
                            <el-select
                                v-model="form.gender"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="dict in genderOptions.values"
                                    :key="dict.key"
                                    :label="dict.value"
                                    :value="dict.key"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="状态" prop="status">
                            <el-radio-group v-model="form.status">
                                <el-radio
                                    v-for="dict in statusOptions.values"
                                    :key="dict.key"
                                    :label="dict.key"
                                    >{{ dict.value }}</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="格言" prop="bio">
                            <el-input
                                v-model="form.bio"
                                type="textarea"
                                placeholder="格言"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listUser,
    getUser,
    addUser,
    updateUser,
    rechargeUser,
    delUser,
} from "@/api/ucenter/user";
import { listUserGroup } from "@/api/ucenter/usergroup";
import { getAccessToken } from "@/utils/tokenTool";
import {staticUrl} from '@/config'
export default {
    name: "ucenter-user-list",
    data() {
        let validateNum = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("此项必须填写"));
            } else if (this.activeRecharge === "money") {
                if (
                    !/^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/.test(
                        value
                    )
                ) {
                    callback(new Error("请输入正确金额（正浮点数）"));
                }
                if (!/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(value)) {
                    callback(new Error("请输入最多两位小数"));
                }
                if (parseFloat(value) < 0.01) {
                    callback(new Error("请输入一个最小为 0.01 的值"));
                }
            } else if (this.activeRecharge === "score") {
                if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
                    callback(new Error("请输入正整数"));
                }
            }
            callback();
        };
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // {type: 'checkbox', width: 60, fixed: "left"},
                { field: "id", title: "id", width: 100, fixed: "left", visible: false },
                {
                    field: "group_id",
                    title: "角色组",
                    // formatter: this.groupFormat,
                    slots: {
                        default: "group_id",
                    },
                    form_type: 2,
                    options:[],
                    options_label:'name',
                    options_value:'id'
                },
                { field: "username", title: "用户名" },
                { field: "nickname", title: "昵称" },
                {
                    field: "gender",
                    title: "性别",
                    // formatter: this.genderFormat,
                    slots: {
                        default: "gender",
                    },
                    form_type: 2,
                    options:[],
                    options_label:'value',
                    options_value:'key'
                },
                {
                    field: "avatar",
                    title: "头像",
                    slots: { default: "avatar" },
                },
                { field: "phone", title: "手机号" },
                { field: "money", title: "余额" },
                { field: "score", title: "积分" },
                { field: "referrer_info.nickname", title: "上级用户" },
                {
                    field: "status",
                    title: "状态",
                    // formatter: this.statusFormat,
                    slots: {
                        default: "status",
                    },
                    form_type: 2,
                    options:[],
                    options_label:'value',
                    options_value:'key'
                },
                {
                    field: "login_time",
                    title: "登录时间",
                    formatter: "formatDate",
                    form_type:3
                },
                // {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 用户表格数据
            userList: [],
            userGroupList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            recharge_open: false,
            activeRecharge: "money",
            rechargeRow: {},
            // 状态数据字典
            statusOptions: [],
            genderOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
                with_referrer_info:1,
            },
            // 表单参数
            form: {},
            moneyForm: {
                user_id: undefined,
                category: "money",
                type: "0",
                num: undefined,
                remark: undefined,
            },
            scoreForm: {
                user_id: undefined,
                category: "score",
                type: "0",
                num: undefined,
                remark: undefined,
            },
            uploadHeaders: {},
            uploadData: {},
            imageList: [],
            // 表单校验
            rules: {
                group_id: [
                    {
                        required: true,
                        message: "角色组不能为空",
                        trigger: "blur",
                    },
                ],
                username: [
                    {
                        required: true,
                        message: "用户名不能为空",
                        trigger: "blur",
                    },
                ],
                nickname: [
                    {
                        required: true,
                        message: "昵称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            moneyRules: {
                num: [
                    { required: true, message: "此项必须填写" },
                    { validator: validateNum, trigger: "blur" },
                ],
            },
            scoreRules: {
                num: [
                    { required: true, message: "此项必须填写" },
                    { validator: validateNum, trigger: "blur" },
                ],
            },
        };
    },
    created() {
        listUserGroup(this.getUserPool()).then((response) => {
            this.userGroupList = response.data.list;
            this.getList();
        });
    },
    methods: {
        imgTranslate(img) {
            if (img.indexOf("http") > -1 || img.indexOf("file/") > -1) {
                return img;
            } else if (img.indexOf("group1/") > -1) {
                return staticUrl + img;
            }
        },
      getUserPool() {
        const sps = this.$route.path.split('/')
        return sps[2]
      },
        //列表的排序和筛选
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询用户列表 */
        getList() {
            this.loading = true;
            listUser(this.getUserPool(), this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.userList = response.data.list;
                this.statusOptions = response.data.statusOptions;
                this.genderOptions = response.data.genderOptions;
                this.total = response.data.total;
                this.loading = false;
                this.tableColumn.forEach(item => {
                    if(item.field === 'status'){
                        item.options = this.statusOptions.values
                    }
                    if(item.field === 'gender'){
                        item.options = this.genderOptions.values
                    }
                    if(item.field === 'group_id'){
                        item.options = this.userGroupList
                    }
                });
            });
        },
        groupFormat({ row }) {
            for (let i = 0; i < this.userGroupList.length; i++) {
                const u = this.userGroupList[i];
                if (u.id == row.group_id) {
                    return u.name;
                }
            }
            return "";
        },
        statusFormat({ row }) {
            return this.selectDictLabel(this.statusOptions.values, row.status);
        },
        genderFormat({ row }) {
            return this.selectDictLabel(this.genderOptions.values, row.gender);
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.uploadHeaders = {
                Authorization: getAccessToken(),
            };
            this.uploadData = {
                category: "avatar",
                public: "1",
            };
            this.imageList = [];
            this.form = {
                id: undefined,
                status: "1",
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加用户";
        },
        handleRecharge(row) {
            this.activeRecharge = "money";
            this.moneyForm = {
                user_id: row.id,
                category: "money",
                type: "0",
                num: undefined,
                remark: undefined,
            };
            this.scoreForm = {
                user_id: row.id,
                category: "score",
                type: "0",
                num: undefined,
                remark: undefined,
            };
            const id = row.id || this.ids;
            getUser(this.getUserPool(), id).then((response) => {
                let data = response.data;
                if (data) {
                    this.rechargeRow = {
                        id: data.id,
                        money: data.money,
                        score: data.score,
                        group_id: data.group_id,
                        username: data.username,
                        nickname: data.nickname,
                        phone: data.phone,
                        email: data.email,
                        gender: data.gender,
                        avatar: data.avatar,
                        bio: data.bio,
                        status: data.status,
                    };
                    this.recharge_open = true;
                }
            });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getUser(this.getUserPool(), id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    this.form = {
                        id: data.id,
                        group_id: data.group_id,
                        username: data.username,
                        nickname: data.nickname,
                        phone: data.phone,
                        email: data.email,
                        gender: data.gender,
                        avatar: data.avatar,
                        bio: data.bio,
                        status: data.status,
                    };
                }
                if (data.avatar) {
                    if(data.avatar.indexOf('/group1/')>-1&&data.avatar.indexOf('http')==-1){
                        data.avatar = staticUrl+data.avatar
                    }
                    this.imageList = [
                        {
                            url: data.avatar,
                        },
                    ];
                }
                this.open = true;
                this.title = "修改用户";
            });
        },
        submitRechargeForm: function () {
            //充值
            if (this.activeRecharge === "money") {
                //充值余额
                this.$refs["moneyForm"].validate((valid) => {
                    if (valid) {
                        rechargeUser(this.getUserPool(), this.moneyForm).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("充值成功");
                                this.recharge_open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                });
            } else if (this.activeRecharge === "score") {
                //充值积分
                this.$refs["scoreForm"].validate((valid) => {
                    if (valid) {
                        rechargeUser(this.getUserPool(), this.scoreForm).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("充值成功");
                                this.recharge_open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                });
            }
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != undefined) {
                        updateUser(this.getUserPool(), this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        if (!this.form.password) {
                            this.msgError("请输入密码");
                            return;
                        }
                        addUser(this.getUserPool(), this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(
                '确定删除吗?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    return delUser(this.getUserPool(), ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有用户数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    return exportUser(this.getUserPool(), queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
        handleUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList);
            file.url = response.data.url;
            this.form.avatar = response.data.url;
        },
        handleUploadError(err) {
            console.log(err);
            this.form.avatar = "";
            if (
                err &&
                (err.message.indexOf("larger than") > 0 ||
                    err.message.indexOf("413") > 0)
            ) {
                this.msgError("上传文件失败，文件大小超过限制");
            } else {
                this.msgError("上传文件失败");
            }
        },
        handleUploadExceed() {
            this.msgError("只能上传一个文件，请删除后重新上传");
        },
        handleUploadRemove() {
            this.form.avatar = "";
        },
    },
};
</script>

<style scoped>
.avatar {
    width: 35px;
    height: 35px;
    margin-left: 0px;
    margin-top: 4px;
}
.recharge-container {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -20px;
}
</style>
