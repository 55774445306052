import { getToken } from "@/utils/auth";
import { fetchEventSource } from '@microsoft/fetch-event-source'
import {
    baseURL,
} from '@/config'
import store from "@/store";

export function streamChats(options={}) {
    const abortController = new AbortController();
    let accessToken = ''
    if (store.getters['user/accessToken']) {
        accessToken = store.getters['user/accessToken']
    }
    const eventSource = fetchEventSource(baseURL + '/ai/chat/stream/chats', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken,
        },
        openWhenHidden: true,
        body: JSON.stringify({
            content: options.content,
            model: '',
            chat_id: options.chat_id,
            bot_id:options.bot_id
        }),
        signal: abortController.signal,
        async onopen(response) {
            if (response.ok && response.headers.get('content-type') === 'text/event-stream') {
                // everything's good
                //console.log('everything\'s good')
                return
            } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                console.log('请求错误')
            } else {
                console.log('其他错误')
            }
        },
        async onmessage(e) {
            const data = JSON.parse(e.data)
            if (data['error_code'] != 0) {
                typeof options.fail === 'function' && options.fail(data['error_msg'])
            } else {
                typeof options.chunked === 'function' && options.chunked(data)
                if (data.is_end) {
                    typeof options.complete === 'function' && options.complete()
                    abortController.abort()
                }
            }
        },
        async onerror(error) {
            console.error('Error:', error)
            typeof options.fail === 'function' && options.fail('连接断开')
            abortController.abort()
        },
        async onclose() {
            // if the server closes the connection unexpectedly, retry:
            //console.log('关闭连接')
            //typeof options.complete === 'function' && options.complete()
            typeof options.fail === 'function' && options.fail('连接断开')
            abortController.abort()
        }
    })
}

