import request from '@/utils/request'

// 添加转发规则
export function addIotRuleForware(data) {
    return request({
      url: '/iot/rule/forware/add',
      method: 'post',
      data: data
    })
}

// 获取转发规则
export function getIotRuleForware(id) {
    return request({
      url: '/iot/rule/forware/get?id=' + id,
      method: 'get'
    })
}

// 更新转发规则
export function updateIotRuleForware(data) {
    return request({
      url: '/iot/rule/forware/update',
      method: 'post',
      data: data
    })
  }
  
// 删除转发规则
export function delIotRuleForware(id) {
    return request({
      url: '/iot/rule/forware/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 转发规则列表
export function listIotRuleForware(query) {
  return request({
    url: '/iot/rule/forware/list',
    method: 'get',
    params: query
  })
}