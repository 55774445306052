import request from '@/utils/request'

export function listBdDatamodel(query) {
  return request({
    url: '/bigdata/bddatamodel/list',
    method: 'get',
    params: query
  })
}

export function getBdDatamodel(id) {
  return request({
    url: '/bigdata/bddatamodel/get?id=' + id,
    method: 'get'
  })
}

export function addBdDatamodel(data) {
  return request({
    url: '/bigdata/bddatamodel/add',
    method: 'post',
    data: data
  })
}

export function editBdDatamodel(data) {
  return request({
    url: '/bigdata/bddatamodel/edit',
    method: 'post',
    data: data
  })
}

export function delBdDatamodel(ids) {
  return request({
    url: '/bigdata/bddatamodel/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

