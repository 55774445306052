import request from '@/utils/request'

export function listGbpxStudentTeachingEvaluation(query) {
  return request({
    url: '/gbpx/gbpxstudentteachingevaluation/list',
    method: 'get',
    params: query
  })
}

export function getGbpxStudentTeachingEvaluation(id) {
  return request({
    url: '/gbpx/gbpxstudentteachingevaluation/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxStudentTeachingEvaluation(data) {
  return request({
    url: '/gbpx/gbpxstudentteachingevaluation/add',
    method: 'post',
    data: data
  })
}

export function editGbpxStudentTeachingEvaluation(data) {
  return request({
    url: '/gbpx/gbpxstudentteachingevaluation/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxStudentTeachingEvaluation(ids) {
  return request({
    url: '/gbpx/gbpxstudentteachingevaluation/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

