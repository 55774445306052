<template>
  <!-- 导入表 -->
  <el-dialog :close-on-click-modal="false" title="导入表" :visible.sync="visible" width="1000px" top="5vh" append-to-body>
    <el-form :model="queryParams" ref="queryForm" :inline="true">
      <el-form-item label="表名称" prop="table_name">
        <el-input
          v-model="queryParams.table_name"
          placeholder="请输入表名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="表描述" prop="table_comment">
        <el-input
          v-model="queryParams.table_comment"
          placeholder="请输入表描述"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <!--<el-table @row-click="clickRow" ref="table" :data="dbTableList" @selection-change="handleSelectionChange" height="500px">-->
        <!--<el-table-column type="selection" width="55"></el-table-column>-->
        <!--<el-table-column prop="table_name" label="表名称"></el-table-column>-->
        <!--<el-table-column prop="table_comment" label="表描述"></el-table-column>-->
        <!--<el-table-column prop="create_time" label="创建时间"></el-table-column>-->
        <!--<el-table-column prop="update_time" label="更新时间"></el-table-column>-->
      <!--</el-table>-->
      <!--<pagination-->
        <!--v-show="total>0"-->
        <!--:total="total"-->
        <!--:page.sync="queryParams.pageNum"-->
        <!--:limit.sync="queryParams.pageSize"-->
        <!--@pagination="getList"-->
      <!--/>-->
      <!---->

      <vxe-grid
              resizable
              ref="xGridimporttbl"
              stripe
              show-overflow
              highlight-hover-row
              :loading="loading"

              :columns="tableColumn"
              :data="dbTableList"
              @radio-change="checkboxChangeEvent"

              >




        <!--自定义空数据模板-->
        <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
        </template>

      </vxe-grid>
      <vxe-pager
              background
              size="small"
              :loading="loading"
              :current-page="queryParams.pageNum"
              :page-size="queryParams.pageSize"
              :total="total"
              @page-change="handleSizeChange">
      </vxe-pager>

    </el-row>
    <el-form ref="form"  label-width="80px">

      </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleImportTable">确 定</el-button>
      <el-button @click="visible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { listDbTable, importTable } from "@/api/bpm/table";
export default {
  name:'bpm-table-importTable',
  data() {
    return {
      // 列
      tableColumn: [
        {type: 'radio', width: 60, fixed: "left"},
        {field: 'table_name', title: '表名称',minWidth:120, fixed: "left" },
        {field: 'table_comment', title: '表描述', width: 200 },
        {field: 'create_time', title: '创建时间', width: 180, formatter: "formatDate"},
        {field: 'update_time', title: '更新时间', width: 180, formatter: "formatDate"},
//        {field: '', title: '操作',resizable:false, minWidth: 290, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: false,
      // 遮罩层
      visible: false,
      // 选中数组值
      tables: [],
      // 总条数
      total: 0,
      // 表数据
      dbTableList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        table_name: undefined,
        table_comment: undefined
      }
    };
  },
  methods: {
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ newValue }) {
      console.log("newValue",newValue)
      this.tables =[newValue.table_name]


    },

    // 显示弹框
    show() {
      this.getList();
      this.visible = true;
    },
    clickRow(row) {
      console.log('clickRow',row,this.$refs.xGridimporttbl)
//      this.$refs.xGridimporttbl.setRadioRow(row);
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.tables = selection.map(item => item.table_name);
    },
    // 查询表数据
    getList() {
      this.loading=true;
      listDbTable(this.queryParams).then(res => {
        if (res.code === 0) {
          this.dbTableList = res.data.list;
          this.total = res.data.total;
        this.loading=false;
        }
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 导入按钮操作 */
    handleImportTable() {
      if(this.tables.length==0){
        this.msgError("请选择要导入的表格");
        return
      }
      this.loading=true;
      var opts={};

      importTable({ tables: this.tables.join(","),opts:JSON.stringify(opts) }).then(res => {
        this.msgSuccess(res.msg);
        if (res.code === 0) {
          this.visible = false;
          this.loading=false;
          this.$emit("ok");
        }
      });
    }
  }
};
</script>
