<!-- 生成时间:2024-01-30 10:38:42 -->
<template>
  <div class="app-container product-indetail-index ">
    <!-- 渲染查询表单 -->
    <!-- <VFormRender
      v-if="queryformData"
      class="query-form-data"
      ref="queryform"
      :formJson="queryformData"
    ></VFormRender> -->

    <el-form>
      <!-- label-width="68px" -->

      <div  style="display: flex">
        <div>
          <el-date-picker
            v-model="queryYear"
            type="year"
            value-format="yyyy"
            format="yyyy"
            placeholder="选择年"
          >
          </el-date-picker>
        </div>
        <div style="padding-left: 20px;">
          <el-button
          type="primary"
          icon="el-icon-search"
   
          @click="handleQuery"
          >搜索</el-button
        >
        </div>
        
        <!-- <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        > -->
        <!-- <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >生成台账</el-button
        > -->
      </div>
    </el-form>
    
                <jf-table
                      :tree-config="treeConfig"
                      :edit-config="{
                    trigger: 'click',
                    mode: 'cell',
                  }"
                       :loading="loading"
                       :query-params="queryParams"
                       :toolbar-config="tableToolbar"
                       :columns="tableColumn"
                       :data="dataList"
                       @sort-change="sortChange"
                       @checkbox-change="checkboxChangeEvent"
                       @checkbox-all="checkboxChangeEvent"
                       @toggle-tree-expand="toggleExpandChangeEvent"
                       @cell-dblclick="Oncelldblclick"
                       @page-change="handleSizeChange"
                       :total="total"
                       :filterBtn="switchshowbtn('filter')"
                       :sortBtn="switchshowbtn('sort')"
                       @change="tableChange"
                >
                    <template slot="toolbar_btn_left">
                        <el-button
                        v-if="switchshowbtn('add')"
                            type="primary"
                            icon="el-icon-plus"
                            size="mini"
                            @click="handleAdd"
                            v-hasPermiZc="['/product/indetail/add']"
                            >新增</el-button
                        >
                        <el-button
                        v-if="switchshowbtn('edit')"
                            type="success"
                            icon="el-icon-edit"
                            size="mini"
                            :disabled="single"
                            @click="handleUpdate"
                            v-hasPermiZc.all="[
                                '/product/indetail/get',
                                '/product/indetail/edit',
                            ]"
                            >修改</el-button
                        >
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="mini"
                            :disabled="multiple"
                            @click="handleDelete"
                            v-hasPermiZc="['/product/indetail/del']"
                            v-if="switchshowbtn('delete')"
                            >删除</el-button
                        >
                        <el-button
                         v-if="switchshowbtn('import')"
                            type="warning"
                            size="mini"
                            @click="handleImport(1)"
                            v-hasPermiZc="['/product/indetail/import']"
                        >
                            <i class="iconfont icon-daoru_o"></i>
                            导入
                        </el-button>

                        <el-button
                         v-if="switchshowbtn('export')"
                            class="btn-orange"
                            size="mini"
                            @click="handleImport(2)"
                            v-hasPermiZc="['/product/indetail/export']"
                        >
                            <i class="iconfont icon-daochu_o"></i>
                            导出
                        </el-button>

                        <el-dropdown @command="handleCommand">
                            <el-button
                                size="mini"
                                type="text"
                                style="margin-left: 10px"
                            >
                                更多<i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="operationLogOpen"
                                    >操作记录</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>

                    <template slot="toolbar_btn_row" slot-scope="{ row }">
                        <el-button
                            size="mini"
                            v-if="switchshowbtn('get')"
                            type="text"
                            icon="el-icon-view"
                            @click="Oncelldblclick({ row })"
                            v-hasPermiZc="['/product/indetail/get']"
                            >查看
                        </el-button>

                        <el-button
                        v-if="switchshowbtn('edit')"
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(row)"
                            v-hasPermiZc="['/product/indetail/edit']"
                            >修改
                        </el-button>

                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            v-hasPermiZc="['/product/indetail/del']"
                            @click="handleDelete(row)"
                            v-if="switchshowbtn('delete')"
                            >删除
                        </el-button>
                    </template>
                    <template slot="status" slot-scope="{ row }">
                        <el-switch
                            v-model="row.status"
                            active-value="1"
                            inactive-value="0"
                            @change="handleStatusChange(row)"
                        ></el-switch>
                    </template>
                    <template slot="roles" slot-scope="{ row }">
                        <span v-for="(item, index) of row.roles" :key="index">
                            {{ item.name }}
                        </span>
                    </template>
                    <template
                        :slot="item.slots.default"
                        slot-scope="{ row }"
                        v-for="item in columnSlots"
                    >
                        <div v-html="getFun(item, row)"></div>
                    </template>
                </jf-table>
    <!-- {{ dataList }} -->

    <vxe-toolbar ref="xToolbar" print></vxe-toolbar>

    <!-- <vxe-table
      border
      resizable
      show-footer
      show-overflow
      ref="xTable"
      align="center"
      :edit-config="{ trigger: 'click', mode: 'row' }"
      :print-config="{}"
      :merge-cells="mergeCells"
      :merge-footer-items="mergeFooterItems"
      :footer-method="footerMethod"
      :data="tableData"
      @toggle-tree-expand="toggleExpandChangeEvent"
      :scroll-y="{ enabled: false }"
    >
      <template slot="toolbar_btn_left">
        <el-button
          v-if="switchshowbtn('add')"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermiZc="['/product/indetail/add']"
          >新增</el-button
        >
        <el-button
          v-if="switchshowbtn('edit')"
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermiZc.all="['/product/indetail/get', '/product/indetail/edit']"
          >修改</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermiZc="['/product/indetail/del']"
          v-if="switchshowbtn('delete')"
          >删除</el-button
        >
        <el-button
          v-if="switchshowbtn('import')"
          type="warning"
          size="mini"
          @click="handleImport(1)"
          v-hasPermiZc="['/product/indetail/import']"
        >
          <i class="iconfont icon-daoru_o"></i>
          导入
        </el-button>

        <el-button
          v-if="switchshowbtn('export')"
          class="btn-orange"
          size="mini"
          @click="handleImport(2)"
          v-hasPermiZc="['/product/indetail/export']"
        >
          <i class="iconfont icon-daochu_o"></i>
          导出
        </el-button>

        <el-dropdown @command="handleCommand">
          <el-button size="mini" type="text" style="margin-left: 10px">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="operationLogOpen"
              >操作记录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <vxe-colgroup :title="queryYearTitle + '年安全生产费用使用台帐'">

        <vxe-column field="to_range" width="140" title="使用范围" tree-node>
        </vxe-column>
        <vxe-column field="to_name" width="140" title="投入项目名称">
        </vxe-column>

        <vxe-colgroup title="第一季度">
          <vxe-column field="one" title="1月份" :edit-render="{}">
            <template #edit="{ row }">
              <vxe-input v-model="row.one" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="two" title="2月份" :edit-render="{}">
            <template #edit="{ row }">
              <vxe-input v-model="row.two" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="three" title="3月份" :edit-render="{}">
            <template #edit="{ row }">
              <vxe-input v-model="row.three" type="text"></vxe-input> </template
          ></vxe-column>
        </vxe-colgroup>

        <vxe-colgroup title="第二季度">
          <vxe-column field="four" title="4月份" :edit-render="{}"
            ><template #edit="{ row }">
              <vxe-input v-model="row.four" type="text"></vxe-input> </template
          ></vxe-column>
          <vxe-column field="five" title="5月份" :edit-render="{}"
            ><template #edit="{ row }">
              <vxe-input v-model="row.five" type="text"></vxe-input> </template
          ></vxe-column>
          <vxe-column field="six" title="6月份" :edit-render="{}"
            ><template #edit="{ row }">
              <vxe-input v-model="row.six" type="text"></vxe-input> </template
          ></vxe-column>
        </vxe-colgroup>

        <vxe-colgroup title="第三季度">
          <vxe-column field="seven" title="7月份" :edit-render="{}"
            ><template #edit="{ row }">
              <vxe-input v-model="row.seven" type="text"></vxe-input> </template
          ></vxe-column>
          <vxe-column field="eight" title="8月份" :edit-render="{}"
            ><template #edit="{ row }">
              <vxe-input v-model="row.eight" type="text"></vxe-input> </template
          ></vxe-column>
          <vxe-column field="nine" title="9月份" :edit-render="{}"
            ><template #edit="{ row }">
              <vxe-input v-model="row.nine" type="text"></vxe-input> </template
          ></vxe-column>
        </vxe-colgroup>

        <vxe-colgroup title="第四季度">
          <vxe-column field="ten" title="10月份" :edit-render="{}"
            ><template #edit="{ row }">
              <vxe-input v-model="row.ten" type="text"></vxe-input> </template
          ></vxe-column>
          <vxe-column field="eleven" title="11月份" :edit-render="{}"
            ><template #edit="{ row }">
              <vxe-input
                v-model="row.eleven"
                type="text"
              ></vxe-input> </template
          ></vxe-column>
          <vxe-column field="twelve" title="12月份" :edit-render="{}"
            ><template #edit="{ row }">
              <vxe-input
                v-model="row.twelve"
                type="text"
              ></vxe-input> </template
          ></vxe-column>
        </vxe-colgroup>
        <vxe-column title="合计">
          <template #default="{ row }">
            <span>{{ countTotal(row) }} </span>
          </template>
        </vxe-column>

        <vxe-colgroup title="操作" width="120">
          <template #default="{ row }">
            <el-button
              size="mini"
              v-if="switchshowbtn('get')"
              type="text"
              icon="el-icon-view"
              @click="Oncelldblclick({ row })"
              v-hasPermiZc="['/product/indetail/get']"
              >查看
            </el-button>

            <el-button
              v-if="switchshowbtn('edit')"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(row)"
              v-hasPermiZc="['/product/indetail/edit']"
              >修改
            </el-button>

          </template>
        </vxe-colgroup>
      </vxe-colgroup>
    </vxe-table> -->

    <div class="savebtn">
      <el-button type="text" :disabled="isSaveLoading" @click="saveData()"
        >保存</el-button
      >
    </div>

    <!-- 添加或修改对话框  数据 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      :width="
        formData && formData.formConfig && formData.formConfig.dialogWidth
          ? formData.formConfig.dialogWidth
          : '1000px'
      "
      :close-on-click-modal="false"
      append-to-body
      :before-close="cancel"
      custom-class="scrolldialog"
    >
      <VFormRender
        v-if="formData != null"
        ref="form"
        :disabledMode="isview"
        :isReadOnly="isview"
        :formJson="formData"
        :userInfo="userInfo"
      >
        <template #t_id_cc>
          <!-- 这里放插槽内容 -->
          <el-form-item label="所属目标">
            <el-cascader
              required
              style="width: 100% !important"
              :options="options"
              label="name"
              value="id"
              v-model="t_id"
              @change="tidChange"
            ></el-cascader>
          </el-form-item>
        </template>
      </VFormRender>

      <div slot="footer" class="dialog-footer">
        <el-button v-if="!isview" type="primary" @click="submitForm"
          >确 定</el-button
        >
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <!-- 导入导出 -->
    <jf-import
      :show.sync="importOpen"
      v-if="importOpen"
      :type="importType"
      :columns="tableColumn"
      :isfinish="isupladfinish"
      :downloadtplurl="downloadtplurl"
      @importsubmit="importsubmit"
      :importError="importError"
      :exportTotal="total"
      @exportSubmit="exportSubmit"
      @saveExportTemplate="saveExportTemplate"
      :exportTemplateList="exportTemplateList"
    ></jf-import>
    <!-- 操作记录 -->
    <jf-operation-log
      :show.sync="operationLogOpen"
      v-if="operationLogOpen"
    ></jf-operation-log>

    <!-- 导入提示 -->
    <el-dialog
      title="导入提示"
      :close-on-click-modal="false"
      :visible.sync="showerrinfo"
      width="750px"
      append-to-body
      custom-class="scrolldialog"
    >
      <p class="erinfo" v-for="(item, idx) in upload_errinfo" :key="idx">
        {{ item }}
      </p>
    </el-dialog>
  </div>
</template>

<script>
import indetailService from "@/api/product/indetail";
import indexdataService from "@/api/product/indexdata";
import { form } from "@/mixins/form.js";

import { execModel } from "@/api/core/data/datasource/model";
import { getToken } from "@/utils/auth";

import Treeselect from "@riophae/vue-treeselect";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import jfImport from "@/components/jfimport/jfimport";
import JfOperationLog from "@/components/jfoperationlog/jfoperationlog";

import { getFieldWidgetByName, deepClone } from "@/vform/utils/util";
import { loadExtension } from "@/vform/extension/extension-loader";
import VFormRender from "@/vform/components/form-render/index.vue";
import { getUserProfile } from "@/api/core/system/user";
import { json } from "body-parser";
loadExtension();

export default {
  mixins: [form],
  name: "product-indetail-index",
  components: {
    Treeselect,
    VFormRender,
    jfImport,
    JfOperationLog,
  },

  data() {
    return {
      org_optionItems: {},
      istree: false,
      hidequeryparamform: false,
      exportTemplateList: [],
      listconf: null,

      importError: false,
      downloadtplurl: "",
      upload_errinfo: null,
      showerrinfo: false,
      isupladfinish: false,
      //导入导出弹窗
      importOpen: false,
      //导入导出类型
      imporType: 1,
      //操作记录
      operationLogOpen: false,
      //功能id
      mid: "17417436681989", //不可修改
      queryformData: null, //查询表单数据
      queryformDataorgstr: "",

      formData: null, //表单数据
      isview: false,
      model_info: {},
      child_model_info: {},
      childcollist: [],
      collist: [],
      LeftTreeData: [],
      LeftTreeDataProc: {},
      treeOptions: [],
      treeselectprops: {},
      treeConfig: {
        trigger: "row",
        expandRowKeys: [],
      },
      TreeAttr: {},

      //左侧分栏比例
      leftrolspan: 0,
      //checkbox 的数据
      CheckBoxData: {},
      //字典库数据
      DictsData: {},
      DsData: {},

      area_dist: {},
      province_dist: {},
      city_dist: {},

      //上传的文件列表
      upload_fileList: {},

      //上传文件参数
      upload: {
        headers: {
          Authorization: getToken(),
        },
        url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
      },

      //编辑器配置
      ueConfig: {
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
        enableAutoSave: true,
        elementPathEnable: false,
        wordCount: false,
        UEDITOR_HOME_URL: "/ueditor/",
      },

      //图片预览地址
      dialogImageUrl: "",
      //图片预览对话框
      dialogVisible: false,

      loadingstu: false,
      openselstu: false,

      // 工具栏
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 数据列
      tableColumn: [
        // {
        // 	type: "checkbox",
        // 	width: 60,
        // 	fixed: "left"
        // },
        {
          field: "id",
          title: "ID",
          width: 100,
          fixed: "left",
        },

        // {
        // 	field: "",
        // 	title: "操作",
        // 	resizable: false,
        // 	width: 180,
        // 	fixed: "right",
        // 	align: "center",
        // 	slots: {
        // 		default: "defaultopr"
        // 	},
        // },
      ],

      orgcolumnList: [],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      totalstu: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 快速检索数据
      Quitfilter_data: {},
      // 快速检索数据 字典类型
      Quitfilter_keys: [],
      // 是否显示弹出层
      open: false,
      // 机构名称
      deptname: undefined,

      //表单
      form: {},
      defaultProps: {
        children: "children",
        label: "name",
      },

      // 表单校验
      rules: {},
      userInfo: {},
      options: [],
      optionsProps: {
        label: "name",
        value: "id",
      },
      indexList: [],
      t_id: [],
      tableData: [],
      mergeCells: [],
      mergeFooterItems: [],
      queryYear: "",
      queryYearTitle: "",
      isSaveLoading: false,
    };
  },

  computed: {
    // columnSlots: function () {
    //   return this.tableColumn.filter((item) => {
    //     return item.slots;
    //   });
    //   // console.log(this.tableColumn.filter((item) => {return item.slots}))
    // },
  },
  watch: {
    // 根据名称筛选机构树
    deptname(val) {
      this.$refs.tree.filter(val);
    },
  },
  beforeCreate() {},
  created() {
    this.downloadtplurl = indetailService.indetailimportTemplate(); //下载模版地址
    this.queryParams.sortinfo = this.defsort;

    // this.queryYear =new Date();
    // this.queryYearTitle = this.queryYear.getFullYear();

    this.getforminfo(this.mid);
    this.getUser();

    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联
      this.$refs.xTable.connect(this.$refs.xToolbar);
    });
    this.loadList();
  },
  methods: {
    getUser() {
      getUserProfile().then((response) => {
        this.userInfo = response.data.user;
      });
    },
    getName(row) {
      console.log(row, "测试");
    },

    exportSubmit(fieldlist) {
      var that = this;
      var tfa = [];
      for (var i in fieldlist) {
        var byname = "";
        if (fieldlist[i].byname != null) {
          byname = fieldlist[i].byname;
        }
        tfa.push(fieldlist[i].field + "=" + byname);
      }
      var durl = indetailService.indetailExportUrl(
        this.mid,
        this.queryParams,
        tfa
      );
      try {
        this.downloadbyurl(durl, null, function (ers) {
          if (ers.currentTarget.status != 200) {
            that.msgError("导出错误");
          }
        });
      } catch (error) {
        console.log(chalk.red(error));
      }
    },
    importsubmit(rows) {
      //开始导入
      indetailService
        .indetailImport(this.mid, rows.datalist, rows.importoption)
        .then((rs) => {
          if (rs.data.row.Failmsg != null && rs.data.row.Failmsg != "") {
            this.upload_errinfo = rs.data.row.Failmsg.split("|");
            this.showerrinfo = true;
            this.importError = true;
          } else {
            this.isupladfinish = true;
            this.msgSuccess("导入成功");
            this.getList(this.mid);
          }
        });
    },
    //导入导出
    handleImport(type) {
      this.importError = false;
      this.isupladfinish = false;
      this.importType = type;
      this.importOpen = true;
    },
    handleCommand(e) {
      //  console.log(e);
      this[e] = true;
    },

    getFun(item, row) {
      return this[item.slotsFun.funName](
        item.slotsFun.dt,
        row[item.slotsFun.key],
        item.slotsFun.key
      );
    },

    //查看
    Oncelldblclick(row) {
      this.handleUpdate(row.row, "查看");
      this.isview = true;
    },

    getTreeselect() {
      if (this.model_info.Category != "4" && this.model_info.Category != "5") {
        return false;
      }
      this.treeOptions = [];
      let opt = {
        id: 0,
        children: [],
      };
      opt[this.TreeAttr.tree_name] = "(无上级)";

      if (this.model_info.Category == "5") {
        opt.children = this.LeftTreeData;
      } else {
        opt.children = this.dataList;
      }

      this.treeOptions.push(opt);
      // console.log(this.treeOptions);
    },

    /** 查询数据 */
    getList(mid) {
      this.loading = true;

      this.queryParams.sortinfo = { order_num: "asc" };
      this.queryParams.pageSize = 999;
      // this.queryParams.year = this.queryYear;
      if (this.queryYear != "") {
        this.queryParams.fieldval = { year: this.queryYear };
        this.queryYearTitle = this.queryYear;
      } else {
        //获取当前年份
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear(); // 返回当前年份
        this.queryParams.fieldval = { year: currentYear };
        this.queryYearTitle = currentYear;
      }

      console.log(this.queryParams);

      indetailService
        .listIndetail(this.queryParams)
        .then((response) => {
          this.loading = false;
          if (this.treeConfig.parentField != null) {
            var tlist = [];
            for (let k in response.data.list) {
              let tonef = response.data.list[k];
              tonef["label"] = tonef[this.treeConfig.treeNode];

              tlist.push(JSON.parse(JSON.stringify(tonef)));
            }
            try {
              this.dataList = this.handleTree(
                tlist,
                "id",
                this.treeConfig.parentField
              );
            } catch (error) {
              console.log(" this.dataList error", error);
            }
          } else {
            this.dataList = response.data.list;
          }
          this.tableData = this.dataList;

          for (let index = 0; index < this.tableData.length; index++) {
            this.tableData[index].id = this.tableData[index].id.toString();
            this.tableData[index].parentId =
              this.tableData[index].p_id.toString();
            this.tableData[index].name =
              this.tableData[index].to_range.toString();
          }

          // this.dataList = this.handleTree(this.tableData, "id", "parentId");
          // this.tableData = this.dataList;

          //   this.$nextTick(() => {
          //   console.log(this.$refs.jfTable);
          //   const jfTable = this.$refs.xTable;
          //   const xGrid = jfTable.$refs.xGrid;
          //   const tableFullData = xGrid.getTableData().fullData;
          //   const rows = [];
          //   for (let j = 0; j < this.treeConfig.expandRowKeys.length; j++) {
          //     const k = this.treeConfig.expandRowKeys[j];
          //     for (let i = 0; i < tableFullData.length; i++) {
          //       const d = tableFullData[i];
          //       if (k == d.id) {
          //         rows.push(d);
          //         break;
          //       }
          //     }
          //   }
          //   xGrid.setTreeExpand(rows, true);
          // });

          let mergeCells = [];
          let myList = this.tableData;

          // let map = new Map()
          // // set设置值
          // map.set('a', 97)
          // // get获取设置值
          // map.get('a') //=> 97
          // // has判断是否存在值，存在为true，不存在为false
          // map.has('a') //=> true

          // let mergeCells1 = [];
          // for (let index = 0; index < myList.length; index++) {
          //   const element = myList[index];
          //   mergeCells1.push({name:element.to_range,id:element.id});

          // }

          // console.log(JSON.stringify(mergeCells1));

          let jsonData = this.tableData;

          // 使用Map统计相同"name"的个数
          var nameCountMap = new Map();
          for (const item of jsonData) {
            if (nameCountMap.has(item.name)) {
              nameCountMap.set(item.name, nameCountMap.get(item.name) + 1);
            } else {
              nameCountMap.set(item.name, 1);
            }
          }

          // 将Map转换为包含{name: '名称', count: '数量'}的对象数组
          var result = [];
          for (let [name, count] of nameCountMap.entries()) {
            result.push({ name, count });
          }

          for (let index = 0; index < result.length; index++) {
            let inn = index;
            if (index != 0) {
              inn = mergeCells[index - 1].row + mergeCells[index - 1].rowspan;
              // inn = this.getNum(index,result)
            }

            let item = {
              row: inn,
              col: 0,
              rowspan: result[index].count,
              colspan: 0,
            };
            mergeCells.push(item);
          }

          // let item1 = {
          //     row: 3,
          //     col: 0,
          //     rowspan: 3,
          //     colspan: 0,
          //   };
          //   mergeCells.push(item1);
          // let item = {
          //     row: 6,
          //     col: 0,
          //     rowspan: 3,
          //     colspan: 0,
          //   };
          //   mergeCells.push(item);

          //   let item2= {
          //     row: 9,
          //     col: 0,
          //     rowspan: 3,
          //     colspan: 0,
          //   };
          //   mergeCells.push(item2);

          console.log(mergeCells);
          // {
          //   row: 0,
          //   col: 0,

          //   rowspan: 0,
          //   colspan: 3,
          // };

          this.mergeCells = mergeCells;

          this.total = response.data.total;
        })
        .catch(() => {
          this.loading = false;
          // this.msgError("加载数据出错,请先检查表单配置!");
        });
    },

    getNum(data, length) {
      let num = 0;
      for (let index = 0; index <= length; index++) {
        num = num + data[index].count;
      }
      return count;
    },
    countTotal(row) {
      let total =
        Number(row.one) +
        Number(row.two) +
        Number(row.three) +
        Number(row.four) +
        Number(row.five) +
        Number(row.six) +
        Number(row.seven) +
        Number(row.eight) +
        Number(row.nine) +
        Number(row.ten) +
        Number(row.eleven) +
        Number(row.twelve);
      return total;
    },

    // 筛选节点
    filterNode(value, data) {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 取消按钮
    cancel() {
      this.reset();
      this.open = false;
      this.isview = false;
    },
    // 表单重置
    reset() {
      this.form = {};
      this.formData = null;

      this.resetQuery();
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.getIndexList();
      //this.reset();
      // this.getTreeselect();
      this.hidequeryparamform = true;
      this.formData = null;
      let tformdata = JSON.parse(this.model_info.Formresource);
      tformdata.formstatus = "add";

      this.$nextTick((_) => {
        this.formData = this.binddicttotablecolumn(tformdata);
      });

      this.open = true;
      this.title = "添加数据";
    },

    /** 修改按钮操作 */
    handleUpdate(row, til) {
      this.reset();
      this.getIndexList();
      // this.getTreeselect();
      //this.getTreeselect();
      const id = row.id || this.ids[0];
      indetailService.getIndetail(id).then((response) => {
        this.form.id = response.data.row.id;

        //填充表单值
        let formvalue = response.data.row; //表单填写内容

        let array = Object.keys(formvalue);
        this.reformatformdata(formvalue);
        let formData = JSON.parse(this.model_info.Formresource); //表单数据(字段,布局等)
        formData.formstatus = "edit";

        this.formData = formData;
        this.$nextTick((_) => {
          //    formData;
          this.formData = this.binddicttotablecolumn(formData);
          this.$refs.form.setFormData(formvalue);
        });

        this.open = true;
        this.title = til || "修改";
      });
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form
        .getFormData()
        .then((data) => {
          if (this.form.id != null) {
            //修改
            data.id = this.form.id;
            indetailService
              .editIndetail(this.mid, data)
              .then((rs) => {
                this.msgSuccess("操作成功");
                this.cancel();
                this.handleQuery();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          } else {
            //新增
            indetailService
              .addIndetail(this.mid, data)
              .then((rs) => {
                this.msgSuccess("操作成功");
                this.cancel();
                this.handleQuery();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          }
        })
        .catch((e) => {
          this.msgError("表单验证不通过,请检查输入");
        });

      return true;
    },
    saveData() {
      let queryParams = this.tableData;
      for (let index = 0; index < queryParams.length; index++) {
        queryParams[index].id = Number(queryParams[index].id);
      }

      let data = {
        action: "updateList",
        list: JSON.stringify(queryParams),
      };
      this.isSaveLoading = true;
      //修改
      indetailService
        .editIndetail(this.mid, data)
        .then((rs) => {
          this.msgSuccess("保存成功");
          this.cancel();
          this.handleQuery();
          this.isSaveLoading = false;
        })
        .catch(() => {
          this.isSaveLoading = false;
        });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const did = row.id || (this.ids ? this.ids.join(",") : "");
      this.$confirm("是否确认删除所选数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return indetailService.delIndetail(did);
        })
        .then(() => {
          this.handleQuery();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    normalizerDeptTreeNode(e) {
      //      e.id = e.id
      e.label = e.name;
      return e;
    },
    /** 指标列表 */ getIndexList() {
      let queryParamsarm = {
        qstr: '{"id":"' + '{"orderby":"' + "desc" + '"}' + '"}',
        pageNum: 1,
        pageSize: 200,
        year: this.queryParams.year,
      };
      // {page: 1, psize: 20, qstr: {"id":{"orderby":"desc"}}}
      indexdataService
        .listIndexdata(queryParamsarm)
        .then((response) => {
          // 原始数据
          const data = response.data.list;
          this.indexList = data;
          // 创建一个空对象用于存储节点及其子节点
          const treeDataMap = {};

          // 遍历原始数据，构建map
          data.forEach((item) => {
            item.value = item.id;
            item.label = item.name;
            treeDataMap[item.id] = { ...item, test: [] };
            if (item.p_id == "0") {
              treeDataMap[item.id] = { ...item, children: [] };
            }
          });

          // 再次遍历原始数据，构建层级关系
          data.forEach((item) => {
            if (item.p_id !== "0") {
              const parent = treeDataMap[item.p_id];
              if (parent) {
                parent.children.push(treeDataMap[item.id]);
              }
            }
          });

          // 提取根节点（即p_id为0的节点）到新的数组中作为树形结构
          const treeOptions = Object.values(treeDataMap).filter(
            (item) => item.p_id === "0"
          );

          // 现在你可以将treeOptions赋值到Vue实例的数据属性中
          this.options = treeOptions;
        })
        .catch(() => {
          this.loading = false;
          // this.msgError("加载数据出错,请先检查表单配置!");
        });
    },
    toggleExpandChangeEvent() {
      const jfTable = this.$refs.xTable;
      const xGrid = jfTable.$refs.xGrid;
      const treeExpandRecords = xGrid.getTreeExpandRecords();
      const expandRowKeys = [];
      for (let i = 0; i < treeExpandRecords.length; i++) {
        expandRowKeys.push(treeExpandRecords[i].id);
      }
      this.treeConfig.expandRowKeys = expandRowKeys;
    },
    tidChange(value) {
      this.$refs.form.setFieldValue("t_id", value[1]);
      this.$refs.form.setFieldValue(
        "to_range",
        this.getIndexNameById(this.indexList, value[0]).name
      );
      this.$refs.form.setFieldValue(
        "to_name",
        this.getIndexNameById(this.indexList, value[1]).name
      );
    },
    getIndexNameById(data, id) {
      // 使用find方法查找id为1的元素

      const foundItem = data.find((item) => {
        console.log(item.id + "-----------" + id);

        return item.id === id; //
      });
      if (foundItem) {
        return foundItem;
      }
    },
    loadList() {
      const list = [];
      for (let index = 0; index < 15; index++) {
        list.push({
          a: "a" + index,
          b: "b" + index,
          c: "c" + index,
          d: "d" + index,
          e: "e" + index,
          f: "f" + index,
          g: "g" + index,
          h: "h" + index,
          i: "i" + index,
          j: "j" + index,
          k: "k" + index,
          l: "l" + index,
          m: "m" + index,
          n: "n" + index,
          o: "o" + index,
          p: "p" + index,
          q: "q" + index,
          r: "r" + index,
          s: "s" + index,
          t: "t" + index,
          u: "u" + index,
          v: "v" + index,
          w: "w" + index,
        });
      }
      // this.tableData = list;
      this.handleMerge();
    },
    handleMerge() {
      // 根据行数据计算合并规则
      const mergeCells = [
        // { row: 0, col: 0, rowspan: 12, colspan: 0 },
        // { row: 0, col: 1, rowspan: 2, colspan: 1 },
        // { row: 0, col: 2, rowspan: 2, colspan: 1 },
        // { row: 0, col: 3, rowspan: 2, colspan: 1 },
        // { row: 0, col: 4, rowspan: 2, colspan: 1 },
        // { row: 0, col: 5, rowspan: 2, colspan: 1 },
        // { row: 0, col: 6, rowspan: 2, colspan: 1 },
        // { row: 0, col: 7, rowspan: 2, colspan: 1 },
        // { row: 0, col: 8, rowspan: 2, colspan: 1 },
        // { row: 0, col: 9, rowspan: 2, colspan: 1 },
        // { row: 3, col: 4, rowspan: 2, colspan: 2 },
        // { row: 6, col: 0, rowspan: 3, colspan: 1 },
        // { row: 6, col: 1, rowspan: 3, colspan: 1 },
        // { row: 6, col: 4, rowspan: 3, colspan: 1 },
        // { row: 6, col: 5, rowspan: 3, colspan: 1 },
        // { row: 6, col: 6, rowspan: 3, colspan: 1 },
        // { row: 6, col: 7, rowspan: 3, colspan: 1 },
        // { row: 6, col: 8, rowspan: 3, colspan: 1 },
        // { row: 6, col: 9, rowspan: 3, colspan: 1 },
        // { row: 6, col: 2, rowspan: 1, colspan: 2 },
        // { row: 8, col: 2, rowspan: 1, colspan: 2 },
      ];

      this.mergeCells = mergeCells;
    },
    footerMethod({ columns }) {
      return [];
    },
  },
};
</script>
<style rel="stylesheet/scss" scoped>
.form-data {
  margin: 0 -2rem;
}
.btn-orange {
  background-color: #ff9648;
  color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #2196f3;
  color: #fff;
}

::v-deep .scrolldialog {
  max-height: 82vh;
}

::v-deep .el-dialog__body {
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.head-container {
  font-size: 14px;
}
.savebtn {
  position: fixed;
  right: 0px;
  top: 50%;
  z-index: 9999999;
  background-color: lightsteelblue;
  width: 60px;
  height: 30px;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  cursor: pointer;
}
.vxe-table .vxe-body--column {
  word-break: break-all;
  word-wrap: break-word;
}
</style>
<style lang="scss">
.product-indetail-index {
    .vxe-table--render-default
  .vxe-body--column.col--ellipsis:not(.col--actived)
  > .vxe-cell,
.vxe-table--render-default
  .vxe-footer--column.col--ellipsis:not(.col--actived)
  > .vxe-cell,
.vxe-checkbox .vxe-checkbox--label {
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
  /* word-break: break-all; */
  /* word-wrap: break-word; */
  max-height: 200px;
}
.vxe-table--render-default
  .vxe-body--column.col--ellipsis
  > .vxe-cell
  .vxe-tree-cell {
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
  /* word-break: break-all; */
  /* word-wrap: break-word; */
  max-height: 200px;
  padding-left: 0px;
}
}

</style>
