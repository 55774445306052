import request from '@/utils/request'


// 添加消息
export function addIotMessage(data) {
  return request({
    url: '/iot/message/add',
    method: 'post',
    data: data
  })
}

// 获取消息
export function getIotMessage(id) {
    return request({
      url: '/iot/message/get?id=' + id,
      method: 'get'
    })
}

// 更新消息
export function updateIotMessage(data) {
  return request({
    url: '/iot/message/update',
    method: 'post',
    data: data
  })
}

// 删除消息
export function delIotMessage(id) {
  return request({
    url: '/iot/message/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}

// 获取设备实时消息
/* export function getIotNewMessage(id) {
  return request({
    url: '/iot/message/getnew?id=' + id,
    method: 'get'
  })
} */
// 消息列表
export function listIotMessage(query) {
  return request({
    url: '/iot/message/list',
    method: 'get',
    params: query
  })
}