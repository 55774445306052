<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
            <!--<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
            <!-- <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="标签" prop="label">
                <el-input
                    v-model="queryParams.label"
                    placeholder="请输入标签"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/stu/stustudentextfield/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/stu/stustudentextfield/get',
                        '/stu/stustudentextfield/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/stu/stustudentextfield/del']"
                    >删除</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->
        
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/stu/stustudentextfield/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="[
                        '/stu/stustudentextfield/get',
                        '/stu/stustudentextfield/edit',
                    ]"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/stu/stustudentextfield/del']"
                    >删除</el-button
                >
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入名称"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="标签" prop="label">
                            <el-input
                                v-model="form.label"
                                placeholder="请输入标签"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="type">
                            <dict-select
                                v-model="form.type"
                                placeholder="请选择类型"
                                type="stu_ext_field_type"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="默认值" prop="default_value">
                            <el-input
                                v-model="form.default_value"
                                placeholder="请输入默认值"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="排序" prop="sort">
                            <el-input-number
                                v-model="form.sort"
                                placeholder="请输入排序"
                                controls-position="right"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否必填" prop="require">
                            <dict-select
                                v-model="form.require"
                                placeholder="请选择是否必填"
                                type="stu_yes_no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否查询" prop="searchable">
                            <dict-select
                                v-model="form.searchable"
                                placeholder="请选择是否查询"
                                type="stu_yes_no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否隐藏" prop="hidden">
                            <dict-select
                                v-model="form.hidden"
                                placeholder="请选择是否隐藏"
                                type="stu_yes_no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否导入" prop="import">
                            <dict-select
                                v-model="form.import"
                                placeholder="请选择是否导入"
                                type="stu_yes_no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否导出" prop="export">
                            <dict-select
                                v-model="form.export"
                                placeholder="请选择是否导出"
                                type="stu_yes_no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="额外信息" prop="ext">
                            <el-input
                                v-model="form.ext"
                                placeholder="请输入额外信息"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listStuStudentExtField,
    getStuStudentExtField,
    addStuStudentExtField,
    editStuStudentExtField,
    delStuStudentExtField,
} from "@/api/stu/stu_student_ext_field";
export default {
    name: "stu-stustudentextfield-list",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                {
                    field: "id",
                    title: "id",
                    minWidth: 120,
                    fixed: "left",
                    visible: false,
                },
                { field: "name", title: "名称", minWidth: 120, is_query: true },
                {
                    field: "label",
                    title: "标签",
                    minWidth: 120,
                    is_query: true,
                },
                {
                    field: "type",
                    title: "类型",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_ext_field_type",
                },
                { field: "default_value", title: "默认值", minWidth: 120 },
                { field: "sort", title: "排序", minWidth: 120 },
                {
                    field: "require",
                    title: "是否必填",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_yes_no",
                },
                {
                    field: "searchable",
                    title: "是否查询",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_yes_no",
                },
                {
                    field: "hidden",
                    title: "是否隐藏",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_yes_no",
                },
                {
                    field: "import",
                    title: "是否导入",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_yes_no",
                },
                {
                    field: "export",
                    title: "是否导出",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_yes_no",
                },
                { field: "ext", title: "额外信息", minWidth: 120 },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                // 	field: "",
                // 	title: "操作",
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: { default: "defaultopr" },
                // },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
                label: undefined,
            },
            form: {},
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                label: [
                    {
                        required: true,
                        message: "标签不能为空",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "类型不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        // for (let i = 0; i < this.tableColumn.length; i++) {
        // 	const col = this.tableColumn[i]
        // 	if (col.dictType) {
        // 		this.getDicts(col.dictType).then(response => {
        // 			if (response.code == 0) {
        // 				col.dictData = response.data
        // 				this.dataList = [].concat(this.dataList)
        // 			}
        // 		})
        // 	}
        // }
        this.getList();
    },
    methods: {
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listStuStudentExtField(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        dictFormat(e) {
            const col = this.tableColumn[e.columnIndex];
            if (col.dictData) {
                const values = e.cellValue ? e.cellValue.split(",") : [];
                const labels = [];
                for (let i = 0; i < values.length; i++) {
                    const v = values[i];
                    for (let j = 0; j < col.dictData.values.length; j++) {
                        const item = col.dictData.values[j];
                        if (v == item.key) {
                            labels.push(item.value);
                            break;
                        }
                    }
                }
                return labels.join(",");
            }
            return e.cellValue;
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.form = {
                name: undefined,
                label: undefined,
                type: undefined,
                default_value: undefined,
                sort: undefined,
                require: undefined,
                searchable: undefined,
                hidden: undefined,
                import: undefined,
                export: undefined,
                ext: undefined,
            };
            this.resetForm("form");
        },

        handleQuery(e) {
           if(e){    this.queryParams = this.deepCopy(e);};
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getStuStudentExtField(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editStuStudentExtField(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addStuStudentExtField(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delStuStudentExtField(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
    },
};
</script>
