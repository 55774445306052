<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-position="left"
    >
      <el-form-item label="角色名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入角色名称"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="角色编码" prop="code">
        <el-input
          v-model="queryParams.code"
          placeholder="请输入角色编码"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="角色状态"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in statusOptions.values"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 
		<vxe-grid resizable ref="xGrid" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="roleList"
			@checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini"
					@click="handleAdd">新增</el-button>
				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single"
					@click="handleUpdate">修改</el-button>
				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
					@click="handleDelete">删除</el-button>
			</template>

			<template #status="{row}">
				<el-switch v-model="row.status" active-value="1" inactive-value="0"
					@change="handleStatusChange(row)"></el-switch>
			</template> -->

    <!--默认操作按钮-->
    <!-- 	<template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-view" @click="handleUpdate(row, true)">查看
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-circle-check"
					@click="handleDataScope(row)">数据权限</el-button>
				<el-button size="mini" type="text" icon="el-icon-user" @click="handleUser(row)">用户</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)">删除
				</el-button>
			</template> -->

    <!--自定义空数据模板-->
    <!-- 	<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>

		</vxe-grid>
		<vxe-pager background size="small" :loading="loading" :current-page="queryParams.pageNum"
			:page-size="queryParams.pageSize" :total="total" @page-change="handleSizeChange">
		</vxe-pager> -->

    <jf-table
      :loading="loading"
      :columns="tableColumn"
      :data="roleList"
      :toolbar-config="tableToolbar"
      :query-params="queryParams"
      :total="total"
      @checkbox-change="checkboxChangeEvent"
      @change="tableChange"
      @page-change="handleSizeChange"
      :defaultoprWidth="320"
    >
      <template slot="toolbar_btn_left">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          >新增</el-button
        >
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          >修改</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          >删除</el-button
        >
      </template>
      <template slot="status" slot-scope="{ row }">
        <el-switch
          v-model="row.status"
          active-value="1"
          inactive-value="0"
          @change="handleStatusChange(row)"
        ></el-switch>
      </template>
      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-view"
          @click="handleUpdate(row, true)"
          >查看
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          >修改
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-circle-check"
          @click="handleDataScope(row)"
          v-if="userpools == 'admin'"
          >数据权限</el-button
        >
        <el-button
          size="mini"
          type="text"
          icon="el-icon-user"
          v-if="userpools == 'admin'"
          @click="handleUser(row)"
          >用户</el-button
        >
        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除
        </el-button>
      </template>
    </jf-table>

    <!-- 添加或修改角色配置对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="600px"
      :close-on-click-modal="false"
      append-to-body
    >
      <jf-form
        v-if="open"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        :readonly="readonly"
        :readonly-column="1"
      >
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入角色名称" />
        </el-form-item>
        <el-form-item label="角色编码" prop="code">
          <el-input v-model="form.code" placeholder="请输入角色编码" />
        </el-form-item>
        <el-form-item label="角色顺序" prop="sort">
          <el-input-number
            v-model="form.sort"
            controls-position="right"
            :min="0"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in statusOptions.values"
              :key="dict.key"
              :label="dict.key"
              >{{ dict.value }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <jf-form-item label="菜单权限" slots="menu">
          <el-checkbox v-model="checkedForm.checked1" @change="roleChange(1)"
            >展开/折叠</el-checkbox
          >
          <el-checkbox v-model="checkedForm.checked2" @change="roleChange(2)"
            >全选/全不选</el-checkbox
          >
          <el-checkbox v-model="checkedForm.checked3">父子联动</el-checkbox>
          <div class="down-tree" v-if="!readonly" v-loading="roleLoading">
            <el-input placeholder="输入关键字进行过滤" v-model="filterText">
            </el-input>
            <!-- roleMenuCheckStrictly -->
            <div class="down-tree-list">
              <el-tree
                v-if="!readonly"
                :data="menuOptions"
                :default-expand-all="false"
                show-checkbox
                @check-change="currentChange"
                ref="menu"
                node-key="id"
                :check-strictly="!checkedForm.checked3"
                empty-text="加载中，请稍后"
                :props="defaultProps"
                :filter-node-method="filterNode"
                @node-expand="nodeExpand"
                @node-collapse="nodeCollapse"
              ></el-tree>
            </div>
          </div>
        </jf-form-item>
        <template #menu>
          <el-descriptions-item>
            <template slot="label"> 菜单权限 </template>
            <div class="down-tree" v-if="readonly">
              <el-tree
                v-if="readonly"
                :data="menuOptions"
                show-checkbox
                ref="menu"
                node-key="id"
                :check-strictly="roleMenuCheckStrictly"
                :expand="getExpandState"
                empty-text="加载中，请稍后"
                :props="defaultProps"
              ></el-tree>
            </div>
          </el-descriptions-item>
        </template>

        <el-form-item v-if="readonly" label="权限范围">
          <el-select v-model="form.data_scope">
            <el-option
              v-for="item in dataScopeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <jf-form-item
          v-if="readonly && form.data_scope == 2"
          label="机构"
          slots="dept"
        >
        </jf-form-item>
        <template #dept>
          <el-descriptions-item>
            <template slot="label"> 机构 </template>
            <el-tree
              v-if="open"
              :data="deptOptions"
              show-checkbox
              ref="dept"
              node-key="id"
              empty-text="加载中，请稍后"
              :props="defaultDeptProps"
            ></el-tree>
          </el-descriptions-item>
        </template>
        <jf-form-item
          v-if="readonly && form.data_scope == 6"
          label="数据规则"
          slots="datascope"
        >
        </jf-form-item>
        <template #datascope>
          <el-descriptions-item>
            <template slot="label"> 数据规则 </template>
            <el-tree
              v-if="open"
              :data="menuDataScopeOptions"
              show-checkbox
              ref="menuDataScope"
              node-key="id"
              :check-strictly="roleDataScopeCheckStrictly"
              empty-text="暂无数据"
              :props="defaultProps"
            ></el-tree>
          </el-descriptions-item>
        </template>

        <el-form-item label="备注">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </jf-form>
      <div slot="footer" class="dialog-footer">
        <template v-if="!readonly">
          <el-button
            type="primary"
            @click="submitForm"
            :disabled="submit != '确 定'"
            >{{ submit }}</el-button
          >
          <el-button @click="cancel">取 消</el-button>
        </template>
        <template v-else>
          <el-button @click="cancel">关 闭</el-button>
        </template>
      </div>
    </el-dialog>

    <!-- 分配角色数据权限对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="openDataScope"
      width="600px"
      append-to-body
    >
      <el-form :model="form" label-width="80px">
        <el-form-item label="角色名称">
          <el-input v-model="form.name" :disabled="true" />
        </el-form-item>

        <el-form-item label="权限范围">
          <el-select v-model="form.data_scope">
            <el-option
              v-for="item in dataScopeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构" v-show="form.data_scope == 2">
          <el-tree
            v-if="openDataScope"
            :data="deptOptions"
            show-checkbox
            ref="dept"
            node-key="id"
            empty-text="加载中，请稍后"
            :props="defaultDeptProps"
          ></el-tree>
        </el-form-item>
        <el-form-item label="数据规则" v-show="form.data_scope == 6">
          <el-tree
            v-if="openDataScope"
            :data="menuDataScopeOptions"
            show-checkbox
            ref="menuDataScope"
            node-key="id"
            :check-strictly="roleDataScopeCheckStrictly"
            empty-text="暂无数据"
            :props="defaultProps"
          ></el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitDataScope">确 定</el-button>
        <el-button @click="cancelDataScope">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-if="openUserRow && openUser"
      :title="openUserRow.name + ' - 角色用户'"
      :visible.sync="openUser"
      width="960px"
      :close-on-click-modal="false"
      append-to-body
    >
      <role-user :id="openUserRow.id"></role-user>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openUser = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listRole,
  getRole,
  delRole,
  addRole,
  updateRole,
  exportRole,
  dataScope,
  changeRoleStatus,
  addRoleOption,
} from "@/api/core/system/role";
import { listMenu } from "@/api/core/system/menu";
import { roleDeptTreeselect } from "@/api/core/system/dept";
import roleUser from "./user.vue";

export default {
  name: "core-system-role",
  components: {
    "role-user": roleUser,
  },
  props: {
    userpools: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      expandedNodes: [], // 存储节点的展开状态,
      roleLoading: false,
      level: 0, //加载层级
      filterText: "",
      checkedForm: {
        checked1: false,
        checked2: false,
        checked3: false,
      },
      // 工具栏
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 列
      tableColumn: [
        // {
        // 	type: 'checkbox',
        // 	width: 60,
        // 	fixed: "left"
        // },
        //{field: 'id', title: '角色编号', width: 100, fixed: "left"},
        {
          field: "name",
          title: "角色名称",
          width: 150,
        },
        {
          field: "code",
          title: "角色编码",
          width: 150,
        },
        {
          field: "sort",
          title: "显示顺序",
          width: 100,
        },
        {
          field: "status",
          title: "状态",
          width: 100,
          slots: {
            default: "status",
          },
        },
        {
          field: "remark",
          title: "备注",
          minWidth: 100,
        },
        {
          field: "create_time",
          title: "创建时间",
          width: 180,
          formatter: "formatDate",
        },
        // {
        // 	field: '',
        // 	title: '操作',
        // 	resizable: false,
        // 	width: 320,
        // 	fixed: "right",
        // 	align: "center",
        // 	slots: {
        // 		default: 'defaultopr'
        // 	}
        // }
      ],

      submit: "确 定",
      // 遮罩层
      loading: true,
      // 选中数组
      dept_ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 角色表格数据
      roleList: [],
      // 弹出层标题
      title: "",
      // 是否查看
      readonly: false,
      // 是否显示弹出层
      open: false,
      // 用户角色
      openUser: false,
      openUserRow: undefined,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      // 数据范围选项
      dataScopeOptions: [
        {
          value: "1",
          label: "全部数据",
        },
        {
          value: "3",
          label: "本机构数据",
        },
        {
          value: "4",
          label: "本机构及以下数据",
        },
        {
          value: "5",
          label: "仅本人数据",
        },
        {
          value: "2",
          label: "自定机构数据",
        },
        {
          value: "6",
          label: "自定义数据规则数据",
        },
      ],
      // 菜单列表
      menuList: [],
      menuOptions: [],
      menuDataScopeOptions: [],
      roleMenuCheckStrictly: false,
      roleDataScopeCheckStrictly: false,
      // 机构列表
      deptOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: undefined,
        code: undefined,
        roleKey: undefined,
        status: undefined,
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "title",
      },
      defaultDeptProps: {
        children: "children",
        label: "name",
      },
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "角色名称不能为空",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "角色编码不能为空",
            trigger: "blur",
          },
        ],
        roleKey: [
          {
            required: true,
            message: "权限字符不能为空",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "角色顺序不能为空",
            trigger: "blur",
          },
        ],
      },
      roleIds: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.menu.filter(val);
    },

    open: {
      handler: function () {
        if (this.open && this.roleIds.length === 0) {
          this.$nextTick(() => {
            this.getTreeData(this.$refs.menu.data);
          });
        } else {
          this.checkedForm = {
            checked1: false,
            checked2: false,
            checked3: false,
          };
        }
      },
      // 立即以obj.name的当前值触发回调
      immediate: true,
    },
  },
  // 其他组件的生命周期钩子方法...
  beforeRouteLeave(to, from, next) {
    // 在页面离开前，将数据保存到本地存储或 Vuex 等状态管理工具中
    // localStorage.setItem('queryParams', JSON.stringify(this.queryParams));
    next();
  },

  created() {
    // const queryParams = localStorage.getItem('queryParams');
    // if (queryParams) {
    // 	this.queryParams = JSON.parse(queryParams);
    // }
    this.getList();
    let userpoolInfo = {};
    if (this.userpools) {
      userpoolInfo.userpool = this.userpools;
    }

    listMenu(userpoolInfo).then((response) => {
      this.menuList = response.data.list;
      const menuOptions = [];
      const menu = {
        id: 0,
        title: "主类目",
        children: [],
      };

      menu.children = this.handleTree(response.data.list, "id", "pid");
      menuOptions.push(menu);
      this.menuOptions = menuOptions;

      const menuDataScopeOptions = JSON.parse(JSON.stringify(this.menuOptions));
      this.menuDataScopeOptions =
        this.menuDataScopeFilter(menuDataScopeOptions);
    });
  },

  methods: {
    //筛选排序
    tableChange(e) {
      console.log(e);
    },
    //节点展开事件
    nodeExpand(e) {
      if (e.id * 1 === 0) {
        this.checkedForm.checked1 = true;
      }
    },
    //节点关闭事件
    nodeCollapse(e) {
      if (e.id * 1 === 0) {
        this.checkedForm.checked1 = false;
      }
    },
    //全选框是否被选中
    currentChange(e) {
      let list1 = this.$refs.menu.getCheckedKeys().sort((a, b) => a - b);

      let list2 = this.roleIds.sort((a, b) => a - b);
      this.checkedForm.checked2 = list1.join(",") === list2.join(",");
    },
    //筛选
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    expandNodeAndChildren(node, level = 1) {
      if (node) {
        node.expanded = this.checkedForm.checked1;
        for (let i = 0; i < node.childNodes.length; i++) {
          const item = node.childNodes[i];
          // 在第三级时停止循环
          if (level === 2) {
            // 执行你的操作或返回结果
            continue;
          }
          // 如果当前元素是数组类型，则进行递归处理
          if (Array.isArray(item.childNodes)) {
            this.expandNodeAndChildren(item, level + 1);
          }
        }
      }
    },
    getTreeData(array) {
      array.forEach((node) => {
        this.roleIds.push(node.id);
        if (node.children && node.children.length) {
          this.getTreeData(node.children);
        }
      });

      // return list
    },
    //展开
    roleChange(e) {
      switch (e) {
        case 1:
          this.expandNodeAndChildren(this.$refs.menu.root);
          break;
        case 2:
          const tree = this.$refs.menu;
          if (this.checkedForm.checked2) {
            tree.setCheckedKeys(this.roleIds.sort((a, b) => a - b));
          } else {
            tree.setCheckedKeys([]);
          }
          break;
      }
    },
    getExpandState(node) {
      return this.expandedNodes.includes(node.id);
    },
    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },
    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },

    /** 查询角色列表 */
    getList() {
      this.loading = true;
      if (this.userpools) {
        this.queryParams.userpool = this.userpools;
      }
      this.queryParams.name = this.queryParams.name;
      listRole(this.addDateRange(this.queryParams, this.dateRange)).then(
        (response) => {
          this.queryParams.name = "";
          this.statusOptions = response.data.searchTypes;
          let list = [];
          if (response.data.list) {
            response.data.list.forEach((item) => {
              item.status = "" + item.status;
              list.push(item);
            });
          }

          this.single = true;
          this.multiple = true;
          this.roleList = list;
          this.total = response.data.total;
          this.loading = false;
        }
      );
    },

    checkMenu() {
      let keys = [];
      if (this.form.auth_rule_ids) {
        keys = this.form.auth_rule_ids.split(",");
        if (keys.length > 0) {
          keys = [0].concat(keys);
        }
      }
      this.roleMenuCheckStrictly = true;

      this.$nextTick(() => {
        if (this.$refs.menu) {
          this.$refs.menu.setCheckedKeys(keys.sort((a, b) => a - b));
        }
        this.roleMenuCheckStrictly = false;
        setTimeout(() => {
          this.checkedForm.checked3 = true;
        });
      });
    },

    checkMenuDataScope() {
      const keys = [];
      if (this.form.data_rule_ids) {
        const data_rule_ids = this.form.data_rule_ids.split(",");
        for (let k = 0; k < data_rule_ids.length; k++) {
          let id = data_rule_ids[k];
          let key = "";
          for (let i = 0; i < this.menuList.length; i++) {
            const m = this.menuList[i];
            if (m.data_rules && m.data_rules.length > 0) {
              for (let j = 0; j < m.data_rules.length; j++) {
                const dr = m.data_rules[j];
                if (dr.id == id) {
                  key = m.id + "_" + id;
                  break;
                }
              }
            }
            if (key != "") {
              break;
            }
          }
          if (key != "") {
            keys.push(key);
          }
        }
      }

      //this.roleDataScopeCheckStrictly = true
      this.$nextTick(() => {
        if (this.$refs.menuDataScope) {
          this.$refs.menuDataScope.setCheckedKeys(keys);
        }
        //this.roleDataScopeCheckStrictly = false
      });
    },

    menuDataScopeFilter(menuDataScopes) {
      const mdss = [];
      for (let i = 0; i < menuDataScopes.length; i++) {
        const ds = menuDataScopes[i];
        if (ds.children && ds.children.length > 0) {
          ds.children = this.menuDataScopeFilter(ds.children);
        }
        if (
          (ds.data_rules && ds.data_rules.length > 0) ||
          (ds.children && ds.children.length > 0)
        ) {
          if (!ds.children) {
            ds.children = [];
          }
          if (ds.data_rules && ds.data_rules.length > 0) {
            for (let j = 0; j < ds.data_rules.length; j++) {
              const dr = ds.data_rules[j];
              ds.children.push({
                id: ds.id + "_" + dr.id,
                title: "$" + dr.name,
                isDataRule: true,
              });
            }
          }
          mdss.push(ds);
        }
      }
      return mdss;
    },
    /** 查询机构树结构 */
    getDeptTreeselect() {
      deptTreeselect().then((response) => {
        this.deptOptions = response.data;
      });
    },
    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = [].concat(this.$refs.menu.getHalfCheckedKeys());
      // 半选中的菜单节点
      let halfCheckedKeys = [].concat(this.$refs.menu.getCheckedKeys());
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      for (let i = 0; i < checkedKeys.length; i++) {
        if (checkedKeys[i] == 0) {
          checkedKeys.splice(i, 1);
          break;
        }
      }
      return checkedKeys;
    },
    getMenuDataScopeAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = [].concat(
        this.$refs.menuDataScope.getHalfCheckedKeys()
      );
      // 半选中的菜单节点
      let halfCheckedKeys = [].concat(
        this.$refs.menuDataScope.getCheckedKeys()
      );
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      for (let i = 0; i < checkedKeys.length; i++) {
        if (checkedKeys[i] == 0) {
          checkedKeys.splice(i, 1);
          break;
        }
      }
      return checkedKeys;
    },
    // 所有机构节点数据
    getDeptAllCheckedKeys() {
      // 目前被选中的机构节点
      let checkedKeys = this.$refs.dept.getCheckedKeys();
      return checkedKeys;
    },

    /** 根据角色ID查询机构树结构 */
    getRoleDeptTreeselect(id) {
      roleDeptTreeselect(id).then((response) => {
        this.deptOptions = response.data;
        if (this.$refs.dept) {
          this.$refs.dept.setCheckedKeys(this.form.dept_ids.split(","));
        }
      });
    },
    // 角色状态修改
    handleStatusChange(row) {
      let text = row.status === "1" ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.name + '"角色吗?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return changeRoleStatus(row.id, row.status);
        })
        .then(() => {
          this.msgSuccess(text + "成功");
        })
        .catch(function () {
          row.status = row.status === "0" ? "1" : "0";
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false;
      this.reset();
    },
    // 表单重置
    reset() {
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([]);
      }
      if (this.$refs.menuDataScope != undefined) {
        this.$refs.menuDataScope.setCheckedKeys([]);
      }
      this.form = {
        id: undefined,
        name: undefined,
        code: undefined,
        roleKey: undefined,
        sort: 0,
        status: "1",
        auth_rule_ids: "",
        dept_ids: "",
        remark: "",
        data_scope: "3",
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.dept_ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.readonly = false;
      this.open = true;
      this.title = "添加角色";
    },
    /** 修改按钮操作 */
    handleUpdate(row, readonly) {
      this.reset();
      const id = row.id || this.ids;
      getRole(id).then((response) => {
        this.form = {
          id: response.data.id,
          name: response.data.name,
          code: response.data.code,
          roleKey: undefined,
          sort: response.data.sort,
          status: "" + response.data.status,
          auth_rule_ids: response.data.auth_rule_ids,
          data_rule_ids: response.data.data_rule_ids,
          dept_ids: response.data.dept_ids,
          remark: response.data.remark,
          data_scope: response.data.data_scope,
        };
        this.readonly = readonly;
        this.title = readonly ? "查看角色" : "修改角色";
        this.open = true;
        this.checkMenu();
        this.checkMenuDataScope();

        this.$nextTick(() => {
          this.getRoleDeptTreeselect(row.id);
        });
      });
    },
    /** 分配数据权限操作 */
    handleDataScope(row) {
      this.reset();
      getRole(row.id).then((response) => {
        this.form = {
          id: response.data.id,
          name: response.data.name,
          code: response.data.code,
          roleKey: undefined,
          sort: response.data.sort,
          status: "" + response.data.status,
          auth_rule_ids: response.data.auth_rule_ids,
          data_rule_ids: response.data.data_rule_ids,
          dept_ids: response.data.dept_ids,
          remark: response.data.remark,
          data_scope: "" + response.data.data_scope,
        };
        this.title = "分配数据权限";
        this.openDataScope = true;
        this.checkMenuDataScope();

        this.$nextTick(() => {
          this.getRoleDeptTreeselect(row.id);
        });
      });
    },
    handleUser(row) {
      this.openUserRow = row;
      this.openUser = true;
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit = "loading";
          this.form.auth_rule_ids = this.getMenuAllCheckedKeys().join(",");
          if (this.userpools) {
            this.form.userpool = this.userpools;
          }
          if (this.form.id != undefined) {
            updateRole(this.form)
              .then((response) => {
                if (response.code === 0) {
                  this.msgSuccess("修改成功");
                  this.open = false;
                  this.getList();
                } else {
                  this.msgError(response.msg);
                }
                this.submit = "确 定";
              })
              .catch((e) => {
                this.submit = "确 定";
              });
          } else {
            addRole(this.form)
              .then((response) => {
                if (response.code === 0) {
                  this.msgSuccess("新增成功");
                  this.open = false;
                  this.getList();
                } else {
                  this.msgError(response.msg);
                }
                this.submit = "确 定";
              })
              .catch((e) => {
                this.submit = "确 定";
              });
          }
        }
      });
    },
    /** 提交按钮（数据权限） */
    submitDataScope: function () {
      if (this.form.id != undefined) {
        this.form.dept_ids = this.getDeptAllCheckedKeys().join(",");
        const keys = this.getMenuDataScopeAllCheckedKeys();
        let data_rule_ids = "";
        for (let i = 0; i < keys.length; i++) {
          const k = keys[i] + "";
          const sps = k.split("_");
          if (sps.length == 2) {
            if (data_rule_ids != "") {
              data_rule_ids += ",";
            }
            data_rule_ids += sps[1];
          }
        }
        this.form.data_rule_ids = data_rule_ids;
        dataScope(this.form).then((response) => {
          if (response.code === 0) {
            this.msgSuccess("修改成功");
            this.openDataScope = false;
            this.getList();
          } else {
            this.msgError(response.msg);
          }
        });
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const roleIds = row.id || (this.ids ? this.ids.join(",") : "");
      this.$confirm("确定删除吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delRole(roleIds);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm("是否确认导出所有角色数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return exportRole(queryParams);
        })
        .then((response) => {
          this.download(response.msg);
        })
        .catch(function () {});
    },
  },
};
</script>
<style lang="scss" scoped>
.down-tree {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 15px;

  &-list {
    margin-top: 15px;
    height: 380px;
    overflow-y: auto;
  }
}
</style>
