import request from '@/utils/request'

export function listGbpxCourse(query) {
  return request({
    url: '/gbpx/gbpxcourse/list/a',
    method: 'get',
    params: query
  })
}

export function listGbpxCourseU(query) {
  return request({
    url: '/gbpx/gbpxcourse/list/u',
    method: 'get',
    params: query
  })
}

export function getGbpxCourse(id) {
  return request({
    url: '/gbpx/gbpxcourse/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxCourse(data) {
  return request({
    url: '/gbpx/gbpxcourse/add',
    method: 'post',
    data: data
  })
}

export function editGbpxCourse(data) {
  return request({
    url: '/gbpx/gbpxcourse/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxCourse(ids) {
  return request({
    url: '/gbpx/gbpxcourse/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

