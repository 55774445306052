import request from '@/utils/request'

// 查询cms分类列表
export function listCmsCategory(query) {
  return request({
    url: '/cms/category/list',
    method: 'get',
    params: query
  })
}

// 查询cms分类详细
export function getCmsCategory(id) {
  return request({
    url: '/cms/category/get?id=' + id,
    method: 'get'
  })
}

// 新增cms分类
export function addCmsCategory(data) {
  return request({
    url: '/cms/category/add',
    method: 'post',
    data: data
  })
}

// 修改cms分类
export function updateCmsCategory(data) {
  return request({
    url: '/cms/category/edit',
    method: 'post',
    data: data
  })
}

// 删除cms分类
export function delCmsCategory(id) {
  return request({
    url: '/cms/category/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
