<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
        <!--<el-form-item label="id" prop="id">
        <el-input
          v-model="queryParams.id"
          placeholder="请输入id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>-->
        <!-- <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="班次" prop="no">
                <el-input
                    v-model="queryParams.no"
                    placeholder="请输入班次"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="联系人" prop="contact">
                <el-input
                    v-model="queryParams.contact"
                    placeholder="请输入联系人"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input
                    v-model="queryParams.phone"
                    placeholder="请输入手机号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            border
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxclass/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/gbpx/gbpxclass/get', '/gbpx/gbpxclass/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxclass/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-s-grid"
                    size="mini"
                    @click="handleCostStat"
                    v-hasPermi="['/gbpx/gbpxclass/get']"
                    >费用统计</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="previewSchedule(row.id)"
                    >日程
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/gbpx/gbpxclass/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/gbpx/gbpxclass/get', '/gbpx/gbpxclass/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/gbpx/gbpxclass/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-s-grid"
                    size="mini"
                    @click="handleCostStat"
                    v-hasPermi="['/gbpx/gbpxclass/get']"
                    >费用统计</el-button
                >
            </template>
            <template slot="time" slot-scope="{ row }">
                {{ classTimeFormat({ row: row, cellValue: row.time }) }}
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="previewSchedule(row.id)"
                    >日程
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="960px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入名称"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="班次" prop="no">
                            <el-input
                                v-model="form.no"
                                :disabled="form.id > 0"
                                placeholder="请输入班次"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="人数" prop="student_num">
                            <el-input-number
                                v-model="form.student_num"
                                placeholder="请输入人数"
                                controls-position="right"
                            />
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="12">
            <el-form-item label="联系人" prop="contact">
              <el-input
                v-model="form.contact"
                placeholder="请输入联系人"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
                clearable
              />
            </el-form-item>
          </el-col>-->
                    <el-col :span="12">
                        <el-form-item label="简介" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入简介"
                                clearable
                                type="textarea"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开展日期" prop="dateRange">
                            <el-date-picker
                                v-model="form.dateRange"
                                type="daterange"
                                format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-tabs v-model="activeTab">
                <el-tab-pane label="课程" name="course" :lazy="true">
                    <el-button
                        style="margin-bottom: 10px"
                        type="primary"
                        icon="el-icon-plus"
                        size="mini"
                        @click="handleAddCourse"
                        >添加</el-button
                    >
                    <span style="margin-left: 10px"
                        >课程数:{{ form.courses.length }}</span
                    >
                    <!-- <vxe-grid
                        resizable
                        stripe
                        border
                        :columns="courseTableColumn"
                        :data="form.courses"
                        :max-height="350"
                    >
                        <template #course="{ row }">
                            <div>
                                <div v-if="row.course">
                                    <div style="color: red">
                                        专题授课：{{ row.course.name }}
                                    </div>
                                    <div style="color: green">
                                        主讲人：{{ row.teacher.name }}（{{
                                            row.teacher.remark
                                        }}）
                                    </div>
                                </div>
                                <div v-else>&nbsp;<br />&nbsp;</div>
                            </div>
                        </template>
                        <template #defaultopr="{ row, rowIndex }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click.stop="handleEditCourse(row, rowIndex)"
                                >修改
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeleteCourse(row, rowIndex)"
                                >删除
                            </el-button>
                        </template>

                        <template #empty>
                            <span>
                                <p>暂无数据</p>
                            </span>
                        </template>
                    </vxe-grid> -->
                    <jf-table
                        :columns="courseTableColumn"
                        :data="form.courses"
                        :max-height="350"
                        :pager="false"
                        :checkbox="false"
                        :is-query="false"
                    >
                        <template slot="course" slot-scope="{ row }">
                            <div>
                                <div v-if="row.course">
                                    <div style="color: red">
                                        专题授课：{{ row.course.name }}
                                    </div>
                                    <div style="color: green">
                                        主讲人：{{ row.teacher.name }}（{{
                                            row.teacher.remark
                                        }}）
                                    </div>
                                </div>
                                <div v-else>&nbsp;<br />&nbsp;</div>
                            </div>
                        </template>
                        <template
                            slot="toolbar_btn_row"
                            slot-scope="{ row, rowIndex }"
                        >
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click.stop="handleEditCourse(row, rowIndex)"
                                >修改
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeleteCourse(row, rowIndex)"
                                >删除
                            </el-button>
                        </template>
                    </jf-table>
                </el-tab-pane>
                <el-tab-pane label="学员" name="student" :lazy="true">
                    <grid-select
                        ref="selectStudentGrid"
                        :multiple="true"
                        placeholder="请选择学员"
                        width="960px"
                        :searchItems="[
                            { field: 'name', title: '姓名' },
                            { field: 'phone', title: '手机号' },
                        ]"
                        :gridItems="[
                            { field: 'name', title: '姓名' },
                            {
                                field: 'gender',
                                title: '性别',
                                ctype: 'dict',
                                cvalue: 'gbpx_gender',
                            },
                            { field: 'company', title: '单位' },
                            { field: 'phone', title: '手机号' },
                        ]"
                        :getDataList="listGbpxStudent"
                        @change="handleSelectStudent"
                    >
                        <el-button
                            style="margin-bottom: 10px"
                            type="primary"
                            icon="el-icon-plus"
                            size="mini"
                            @click="handleAddStudent"
                            >添加</el-button
                        >
                        <span style="margin-left: 10px"
                            >学员数:{{ form.students.length }}</span
                        >
                    </grid-select>
                    <vxe-grid
                        resizable
                        stripe
                        :columns="studentTableColumn"
                        :data="form.students"
                        :max-height="350"
                    >
                        <template #defaultopr="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click.stop="handleDeleteStudent(row)"
                                >删除
                            </el-button>
                        </template>

                        <template #empty>
                            <span>
                                <p>暂无数据</p>
                            </span>
                        </template>
                    </vxe-grid>
                </el-tab-pane>
            </el-tabs>

            <div slot="footer" class="dialog-footer">
                <!--<el-button v-if="form.id" style="float: left" @click="previewSchedule(form.id)">预览日程</el-button>-->
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :close-on-click-modal="false"
            :title="titleCourse"
            :visible.sync="openCourse"
            width="960px"
            append-to-body
        >
            <el-form
                ref="formCourse"
                :model="formCourse"
                :rules="rulesCourse"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课程" prop="course_id">
                            <grid-select
                                v-model="formCourse.course_id"
                                :multiple="false"
                                placeholder="请选择课程"
                                width="960px"
                                :label="formCourse.course_name"
                                :searchItems="[
                                    { field: 'name', title: '名称' },
                                    {
                                        field: 'category_id',
                                        title: '分类',
                                        ctype: 'gbpx-course-category',
                                    },
                                    //{ field: 'teacher_id', title: '教师', ctype: 'gbpx-teacher' },
                                ]"
                                :gridItems="[
                                    { field: 'name', title: '名称' },
                                    { field: 'category_name', title: '分类' },
                                    //{ field: 'teacher_name', title: '教师' },
                                    { field: 'remark', title: '简介' },
                                ]"
                                :getDataList="listGbpxCourse"
                                @change="handleSelectCourse"
                            >
                            </grid-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="教师" prop="teacher_id">
                            <gbpx-teacher-select
                                v-model="formCourse.teacher_id"
                                :label="formCourse.teacher_name"
                                placeholder="请选择教师"
                                @change="handleSelectTeacher"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="时间" prop="dateRange">
                            <el-date-picker
                                v-model="formCourse.dateRange"
                                type="datetimerange"
                                format="yyyy-MM-dd HH:mm"
                                :default-time="['08:00:00', '08:00:00']"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地点" prop="address">
                            <!--<el-input v-model="formCourse.address" placeholder="请输入地点" />-->
                            <dict-select
                                v-model="formCourse.address"
                                placeholder="请选择地点"
                                type="gbpx_course_place"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitCourse"
                    >确 定</el-button
                >
                <el-button @click="openCourse = false">返 回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listGbpxClass,
    getGbpxClass,
    addGbpxClass,
    editGbpxClass,
    delGbpxClass,
} from "@/api/gbpx/gbpx_class";
import { listGbpxCourse } from "@/api/gbpx/gbpx_course";
import { listGbpxStudent } from "@/api/gbpx/gbpx_student";
import moment from "moment";
export default {
    name: "gbpx-gbpxclass-list-a",
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                //{ field: "id", title: "id", minWidth: 120, fixed: "left" },
                { field: "name", title: "名称", minWidth: 120, is_query: true },
                { field: "no", title: "班次", minWidth: 120, is_query: true },
                { field: "student_num", title: "人数", minWidth: 120 },
                {
                    field: "contact",
                    title: "联系人",
                    minWidth: 120,
                    is_query: true,
                    visible: false,
                },
                {
                    field: "phone",
                    title: "手机号",
                    minWidth: 120,
                    is_query: true,
                    visible: false,
                },
                //{ field: "begin_time", title: "开始时间", formatter: "formatDate", minWidth: 120 },
                //{ field: "end_time", title: "结束时间", formatter: "formatDate", minWidth: 120 },
                {
                    field: "time",
                    title: "开展时间",
                    // formatter: this.classTimeFormat,
                    minWidth: 120,
                    slots: {
                        default: "time",
                    },
                },
                { field: "remark", title: "简介", minWidth: 120 },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                // {
                //   field: "",
                //   title: "操作",
                //   width: 180,
                //   fixed: "right",
                //   align: "center",
                //   slots: { default: "defaultopr" },
                // },
            ],
            /*courseTableColumn: [
        //{ field: "id", title: "id", minWidth: 60, fixed: "left" },
        { field: "course_name", title: "名称", minWidth: 120 },
        //{ field: "category_name", title: "分类", minWidth: 120 },
        { field: "teacher_name", title: "教师", minWidth: 60 },
        //{ field: "remark", title: "简介", minWidth: 120 },
        {
          field: "time",
          title: "时间",
          minWidth: 60,
          formatter: this.courseTimeFormat,
        },
        { field: "address", title: "地点", minWidth: 60 },
        {
          field: "",
          title: "操作",
          width: 150,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],*/
            courseTableColumn: [
                {
                    field: "date",
                    title: "日期",
                    width: 150,
                    type: "html",
                    formatter: this.courseDateFormat,
                },
                {
                    field: "time",
                    title: "时间",
                    width: 150,
                    type: "html",
                    formatter: this.courseTimeFormat,
                },
                {
                    field: "course",
                    title: "课程",
                    slots: { default: "course" },
                },
                { field: "address", title: "地点", width: 150 },
                {
                    field: "",
                    title: "操作",
                    width: 150,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],
            studentTableColumn: [
                //{ field: "id", title: "id", minWidth: 60, fixed: "left" },
                { field: "student.name", title: "姓名", minWidth: 60 },
                { field: "student_no", title: "学号", minWidth: 60 },
                {
                    field: "student.gender",
                    title: "性别",
                    minWidth: 60,
                    formatter: this.dictFormatStudent,
                    dictType: "gbpx_gender",
                },
                { field: "student.company", title: "单位", minWidth: 120 },
                //{ field: "student.job", title: "职务", minWidth: 120 },
                { field: "student.phone", title: "手机号", minWidth: 60 },
                {
                    field: "",
                    title: "操作",
                    width: 80,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            titleCourse: "",
            open: false,
            openCourse: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
                no: undefined,
                begin_time: undefined,
                end_time: undefined,
                contact: undefined,
                phone: undefined,
            },
            form: {
                courses: [],
                students: [],
            },
            formCourse: {
                course_id: undefined,
                teacher_id: undefined,
                address: undefined,
                dateRange: undefined,
            },
            edittingCourseIndex: -1,
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                no: [
                    {
                        required: true,
                        message: "班次不能为空",
                        trigger: "blur",
                    },
                ],
                student_num: [
                    {
                        required: true,
                        message: "人数不能为空",
                        trigger: "blur",
                    },
                ],
                //begin_time: [{ required: true, message: "开始时间不能为空", trigger: "blur" }],
                //end_time: [{ required: true, message: "结束时间不能为空", trigger: "blur" }],
                dateRange: [
                    {
                        required: true,
                        message: "培训时间不能为空",
                        trigger: "blur",
                    },
                ],
                contact: [
                    {
                        required: true,
                        message: "联系人不能为空",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "手机号不能为空",
                        trigger: "blur",
                    },
                ],
            },
            rulesCourse: {
                course_id: [
                    {
                        required: true,
                        message: "课程不能为空",
                        trigger: "blur",
                    },
                ],
                teacher_id: [
                    {
                        required: true,
                        message: "教师不能为空",
                        trigger: "blur",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "地点不能为空",
                        trigger: "blur",
                    },
                ],
                dateRange: [
                    {
                        required: true,
                        message: "时间不能为空",
                        trigger: "blur",
                    },
                ],
            },
            activeTab: "course",
            listGbpxCourse: listGbpxCourse,
            listGbpxStudent: listGbpxStudent,
        };
    },
    created() {
        for (let i = 0; i < this.studentTableColumn.length; i++) {
            const col = this.studentTableColumn[i];
            if (col.dictType) {
                this.getDicts(col.dictType).then((response) => {
                    if (response.code == 0) {
                        col.dictData = response.data;
                        this.dataList = [].concat(this.dataList);
                    }
                });
            }
        }
        this.getList();
    },
    methods: {
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;

            listGbpxClass(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        classTimeFormat(e) {
            if (e.row.begin_time && e.row.end_time) {
                return (
                    moment(e.row.begin_time * 1000).format("yyyy-MM-DD") +
                    " 至 " +
                    moment(e.row.end_time * 1000).format("yyyy-MM-DD")
                );
            }
            return e.cellValue;
        },

        /*courseTimeFormat(e) {
      if (e.row.begin_time && e.row.end_time) {
        return (
          moment(e.row.begin_time * 1000).format("yyyy-MM-DD HH:mm") +
          " - " +
          moment(e.row.end_time * 1000).format("HH:mm")
        );
      }
      return e.cellValue;
    },*/
        courseDateFormat(e) {
            if (e.row.begin_time && e.row.end_time) {
                return moment(e.row.begin_time * 1000).format(
                    "yyyy-MM-DD<br/>dddd"
                );
            }
            return e.cellValue;
        },

        courseTimeFormat(e) {
            if (e.row.begin_time && e.row.end_time) {
                return (
                    moment(e.row.begin_time * 1000)
                        .format("a<br/>HH:mm")
                        .replace("早上", "上午") +
                    " - " +
                    moment(e.row.end_time * 1000).format("HH:mm")
                );
            }
            return e.cellValue;
        },

        dictFormatStudent(e) {
            const col = this.studentTableColumn[e.columnIndex];
            if (col.dictData) {
                const values = e.cellValue ? e.cellValue.split(",") : [];
                const labels = [];
                for (let i = 0; i < values.length; i++) {
                    const v = values[i];
                    for (let j = 0; j < col.dictData.values.length; j++) {
                        const item = col.dictData.values[j];
                        if (v == item.key) {
                            labels.push(item.value);
                            break;
                        }
                    }
                }
                return labels.join(",");
            }
            return e.cellValue;
        },

        reset() {
            this.form = {
                name: undefined,
                no: undefined,
                student_num: undefined,
                begin_time: undefined,
                end_time: undefined,
                contact: undefined,
                phone: undefined,
                remark: undefined,
                dateRange: [],
                courses: [],
                students: [],
            };
            this.activeTab = "course";
            this.resetForm("form");
        },

        handleQuery(e) {
            if (e) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleAddCourse() {
            this.formCourse = {
                course_id: undefined,
                teacher_id: undefined,
                address: undefined,
                dateRange: undefined,
            };
            this.edittingCourseIndex = -1;
            this.resetForm("formCourse");
            this.openCourse = true;
            this.titleCourse = "添加课程";
        },

        handleAddStudent() {
            this.$refs.selectStudentGrid.open();
        },

        handleSelectCourse(e1, e2) {
            if (e2.length > 0) {
                this.formCourse.course_name = e2[0].name;
            }
        },

        handleSelectTeacher(e1, e2) {
            if (e2.length > 0) {
                this.formCourse.teacher_name = e2[0].name;
            }
        },

        handleSelectStudent(e1, e2) {
            for (let i = 0; i < e2.length; i++) {
                const student = e2[i];
                let exist = false;
                for (let j = 0; j < this.form.students.length; j++) {
                    if (this.form.students[j].student_id == student.id) {
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    this.form.students.push({
                        student_id: student.id,
                        student: student,
                    });
                }
            }
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getGbpxClass(id).then((response) => {
                this.form = response.data;
                if (this.form.begin_time && this.form.end_time) {
                    //this.form.dateRange = [new Date(this.form.begin_time * 1000), new Date(this.form.end_time * 1000)]
                    this.$set(this.form, "dateRange", [
                        new Date(this.form.begin_time * 1000),
                        new Date(this.form.end_time * 1000),
                    ]);
                }
                this.open = true;
                this.title = "修改";
            });
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (!/^[0-9]*$/.test(this.form.no)) {
                        this.msgError("班次只能填数字");
                        return;
                    }
                    let conflict1 = undefined;
                    let conflict2 = undefined;
                    for (let i = 0; i < this.form.courses.length; i++) {
                        const c1 = this.form.courses[i];
                        for (let j = 0; j < this.form.courses.length; j++) {
                            if (i != j) {
                                const c2 = this.form.courses[j];
                                if (
                                    c1.begin_time <= c2.begin_time &&
                                    c1.end_time > c2.begin_time
                                ) {
                                    // c1.bt|------------|c1.et
                                    //           c2.bt|---------------|c2.et
                                    conflict1 = c1;
                                    conflict2 = c2;
                                    break;
                                }
                                if (
                                    c1.begin_time >= c2.begin_time &&
                                    c1.begin_time < c2.end_time
                                ) {
                                    //           c1.bt|---------------|c1.et
                                    // c2.bt|------------|c2.et
                                    conflict1 = c1;
                                    conflict2 = c2;
                                    break;
                                }
                                if (
                                    c1.begin_time <= c2.begin_time &&
                                    c1.end_time >= c2.end_time
                                ) {
                                    // c1.bt|---------------------|c1.et
                                    //         c2.bt|----|c2.et
                                    conflict1 = c1;
                                    conflict2 = c2;
                                    break;
                                }
                                if (
                                    c1.begin_time >= c2.begin_time &&
                                    c1.end_time <= c2.end_time
                                ) {
                                    //         c1.bt|----|c1.et
                                    // c2.bt|---------------------|c2.et
                                    conflict1 = c1;
                                    conflict2 = c2;
                                    break;
                                }
                            }
                        }
                        if (conflict1 && conflict2) {
                            break;
                        }
                    }
                    if (conflict1 && conflict2) {
                        this.msgError(
                            conflict1.course.name +
                                "和" +
                                conflict2.course.name +
                                "时间冲突，请重新调整"
                        );
                        return;
                    }
                    const form = this.form;
                    form.begin_time = parseInt(
                        this.form.dateRange[0].getTime() / 1000
                    );
                    form.end_time = parseInt(
                        this.form.dateRange[1].getTime() / 1000
                    );
                    if (form.id != null) {
                        editGbpxClass(form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addGbpxClass(form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        submitCourse() {
            this.$refs["formCourse"].validate((valid) => {
                if (valid) {
                    if (
                        moment(this.formCourse.dateRange[0]).format(
                            "yyyyMMDD"
                        ) !=
                        moment(this.formCourse.dateRange[1]).format("yyyyMMDD")
                    ) {
                        this.msgError("日期必须是同一天");
                        return;
                    }
                    if (this.edittingCourseIndex < 0) {
                        this.form.courses.push({
                            course_id: this.formCourse.course_id,
                            course_name: this.formCourse.course_name,
                            teacher_id: this.formCourse.teacher_id,
                            teacher_name: this.formCourse.teacher_name,
                            address: this.formCourse.address,
                            begin_time: parseInt(
                                this.formCourse.dateRange[0].getTime() / 1000
                            ),
                            end_time: parseInt(
                                this.formCourse.dateRange[1].getTime() / 1000
                            ),
                        });
                    } else {
                        const course =
                            this.form.courses[this.edittingCourseIndex];
                        course.course_id = this.formCourse.course_id;
                        course.course_name = this.formCourse.course_name;
                        course.teacher_id = this.formCourse.teacher_id;
                        course.teacher_name = this.formCourse.teacher_name;
                        course.address = this.formCourse.address;
                        course.begin_time = parseInt(
                            this.formCourse.dateRange[0].getTime() / 1000
                        );
                        course.end_time = parseInt(
                            this.formCourse.dateRange[1].getTime() / 1000
                        );
                        this.form.courses = [].concat(this.form.courses);
                    }
                    this.openCourse = false;
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm("确定删除吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delGbpxClass(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        handleEditCourse(row, rowIndex) {
            this.resetForm("formCourse");
            this.formCourse = {
                id: row.id,
                course_id: row.course_id,
                course_name: row.course_name,
                teacher_id: row.teacher_id,
                teacher_name: row.teacher_name,
                begin_time: row.begin_time,
                end_time: row.end_time,
                dateRange:
                    row.begin_time && row.end_time
                        ? [
                              new Date(row.begin_time * 1000),
                              new Date(row.end_time * 1000),
                          ]
                        : undefined,
                address: row.address,
            };
            this.edittingCourseIndex = rowIndex;
            this.openCourse = true;
            this.titleCourse = "修改课程";
        },

        handleDeleteCourse(row, rowIndex) {
            this.$confirm("确定删除" + row.course.name + "?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.form.courses.splice(rowIndex, 1);
            });
        },

        handleDeleteStudent(row) {
            this.$confirm("确定删除" + row.student.name + "?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.form.students.splice(
                    this.form.students.map((row) => row.id).indexOf(row.id),
                    1
                );
            });
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        previewSchedule(id) {
            window.open("bi.html#/report/view?_=23&class_id=" + id);
        },

        handleCostStat() {
            window.open("bi.html#/report/view?_=24");
        },
    },
};
</script>
