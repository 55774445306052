import request from '@/utils/request'

// 查询安全日志列表
export function list(query) {
  return request({
    url: '/securitylog/list',
    method: 'get',
    params: query
  })
}

// 删除安全日志
export function delOperlog(operId) {
  return request({
    url: '/securitylog/del',
    method: 'post',
    data:{
      ids: operId
    }
  })
}

// 清空安全日志
export function cleanOperlog() {
  return request({
    url: '/securitylog/clear',
    method: 'post'
  })
}
