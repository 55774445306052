<template>
    <div>
        <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="1080px" append-to-body>
            <jf-form v-if="open" ref="form" :model="form" :rules="rules" label-width="100px" :readonly="title == '查看'" :readonly-column="2">
                <template #attachments>
                    <el-descriptions-item :span="2">
                        <template slot="label">
                            附件
                        </template>
                        <file-upload-ex v-model="form.attachments" list-type="text" :readonly="true" />
                    </el-descriptions-item>
                </template>
                <el-row>
                    <!--<el-col :span="12">
                    <el-form-item label="归属部门" prop="dept_id">
                        <el-input-number
                            v-model="form.dept_id"
                            placeholder="请输入归属部门"
                            controls-position="right"
                        />
                    </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="分类" prop="category">
                            <dict-select
                                v-model="form.category"
                                placeholder="请选择分类"
                                type="oa_task_category"
                            />
                        </el-form-item> 
                    </el-col>-->
                    <el-col :span="12">
                        <jf-form-item label="类型" prop="type" :span="2">
                            <dict-select :disabled="form.pid > 0"
                                v-model="form.type"
                                placeholder="请选择类型"
                                type="oa_task_type"
                            />
                        </jf-form-item> 
                    </el-col>
                    <el-col :span="24">
                        <jf-form-item label="名称" prop="title" :span="2">
                            <el-input v-model="form.title" placeholder="请输入名称" clearable  :disabled="form.pid > 0" />
                        </jf-form-item>
                    </el-col>
                    <el-col :span="24">
                        <jf-form-item label="内容" prop="content" :span="2">
                            <el-input v-model="form.content" placeholder="请输入内容" clearable type="textarea" rows="5" />
                        </jf-form-item>
                    </el-col>
                    <el-col :span="24">
                    <jf-form-item label="附件" prop="attachments" slots="attachments">
                        <file-upload-ex v-model="form.attachments" list-type="text" />
                    </jf-form-item> 
                    </el-col>
                    <!--<el-col :span="12">
                        <el-form-item label="状态" prop="status">
                            <dict-select
                                v-model="form.status"
                                placeholder="请选择状态"
                                type="oa_task_status"
                            />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="紧急程度" prop="emergent_level">
                            <dict-select
                                v-model="form.emergent_level"
                                placeholder="请选择紧急程度"
                                type="oa_task_emergent_level"
                            />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="重要程度" prop="important_level">
                            <dict-select
                                v-model="form.important_level"
                                placeholder="请选择重要程度"
                                type="oa_task_important_level"
                            />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否发送短信通知" prop="send_sms">
                            <dict-select
                                v-model="form.send_sms"
                                placeholder="请选择是否发送短信通知"
                                type="sys_yes_no"
                            />
                        </el-form-item> 
                    </el-col>-->
                    <el-col :span="24">
                        <el-form-item label="开始时间" prop="begin_date">
                            <datetime-select v-model="form.begin_date" type="datetime" placeholder="请选择开始时间" />
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="12">
                        <el-form-item label="阅读截止时间" prop="view_closing_date">
                            <datetime-select
                                v-model="form.view_closing_date"
                                type="date"
                                placeholder="请选择阅读截止时间"
                            />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否发送给自己" prop="send_myself">
                            <dict-select
                                v-model="form.send_myself"
                                placeholder="请选择是否发送给自己"
                                type="sys_yes_no"
                            />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否发送给本部门" prop="send_mydept">
                            <dict-select
                                v-model="form.send_mydept"
                                placeholder="请选择是否发送给本部门"
                                type="sys_yes_no"
                            />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否需要提交材料" prop="need_submit_file">
                            <dict-select
                                v-model="form.need_submit_file"
                                placeholder="请选择是否需要提交材料"
                                type="sys_yes_no"
                            />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="提交材料是否可见" prop="submit_files_visible">
                            <dict-select
                                v-model="form.submit_files_visible"
                                placeholder="请选择提交材料是否可见"
                                type="sys_yes_no"
                            />
                        </el-form-item> 
                    </el-col>-->
                    <el-col :span="24">
                        <el-form-item label="截止时间" prop="submit_closing_date">
                            <datetime-select v-model="form.submit_closing_date" type="datetime" placeholder="请选择截止时间" />
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="12">
                    <el-form-item label="到期提醒" prop="expire_notify">
                        <dict-select
                            v-model="form.expire_notify"
                            placeholder="请选择到期提醒"
                            type="sys_yes_no"
                        />
                    </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否获取定位" prop="need_position">
                            <dict-select
                                v-model="form.need_position"
                                placeholder="请选择是否获取定位"
                                type="sys_yes_no"
                            />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="接收类型" prop="receive_type">
                            <dict-select
                                v-model="form.receive_type"
                                placeholder="请选择接收类型"
                                type="oa_task_receive_type"
                            />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注信息" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入备注信息"
                                clearable
                                type="textarea"
                            />
                        </el-form-item> 
                    </el-col>-->
                    <template v-if="title != '查看'">
                    <el-col :span="24">
                        <el-form-item label="接收对象" prop="receive_type">
                            <el-select v-model="form.receive_type" placeholder="请选择" :disabled="form.id && form.id > 0">
                                <el-option
                                v-for="item in receiveTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                ></el-option>
                            </el-select>
                            &nbsp;<el-checkbox v-if="form.receive_type && form.receive_type != '#' && form.receive_type != '*'" v-model="form.send_mydept" true-label="1" false-label="" :disabled="form.id && form.id > 0">只发送本部门</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.receive_type == '#'" :span="24">
                        <el-form-item label="接收部门" prop="receive_depts">
                            <dept-select v-model="form.receive_depts" placeholder="请选择部门" valueField="id" :disabled="form.id && form.id > 0" :multiple="true" :checkStrictly="true" />
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.receive_type == '*'" :span="24">
                        <el-row>
                            <el-col :span="23">
                                <el-form-item label="接收用户" prop="receive_user_ids">
                                    <admin-selectex size="small" placeholder="请选择用户" title="用户" idField="id" :value="form.receive_user_ids" :label="form.receive_user_names" :readonly="true" @getValue='(ids, names)=>{form.receive_user_ids=ids;form.receive_user_names=names;}' :disabled="form.id && form.id > 0" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="1">
                                <el-dropdown trigger="click" @command="handleCommand">
                                    <span class="el-dropdown-link">
                                        <el-button size="small" style="margin-top: 1px" icon="el-icon-more"></el-button>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item icon="el-icon-plus" command="save">保存分组</el-dropdown-item>
                                        <el-dropdown-item icon="el-icon-delete" command="del">删除分组</el-dropdown-item>
                                        <el-dropdown-item v-for="(item, index) in selectUserGroups" :key="index" :icon="item.name == selectedUserGroup ? 'el-icon-check': 'el-icon-tickets'" :divided="index==0" :command="item">{{item.name}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown> 
                            </el-col>
                        </el-row>
                    </el-col>
                    </template>
                    <template v-else>
                        <jf-form-item label="接收人数" :value="form.receive_num" />
                        <jf-form-item label="转派人数" :value="form.transfer_num"  />
                        <jf-form-item label="已查阅" :value="form.read_num"  />
                        <jf-form-item label="未查阅" :value="form.receive_num - form.read_num"  />
                        <jf-form-item label="已提交" :value="form.submit_num"  />
                        <jf-form-item label="未提交" :value="form.receive_num - form.submit_num"  />
                        <jf-form-item label="已评分" :value="form.approve_num"  />
                        <jf-form-item label="未评分" :value="form.receive_num - form.approve_num"  />
                    </template>
                </el-row>
            </jf-form>

            <el-form v-if="open && title == '查看'" :model="queryRecordParams" ref="queryRecordForm" :inline="true" label-width="68px" style="margin-top: 10px; margin-bottom: -10px;">
                <el-form-item label="查阅状态" prop="readed">
                    <el-select v-model="queryRecordParams.readed" placeholder="请选择">
                        <el-option label="已查阅" value="1"></el-option>
                        <el-option label="未查阅" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="提交状态" prop="submit_status">
                    <el-select v-model="queryRecordParams.submit_status" placeholder="请选择">
                        <el-option label="已提交" value="1"></el-option>
                        <el-option label="未提交" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="评分状态" prop="approve_status">
                    <el-select v-model="queryRecordParams.approve_status" placeholder="请选择">
                        <el-option label="已评分" value="1"></el-option>
                        <el-option label="未评分" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleRecordQuery">搜索</el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetRecordQuery">重置</el-button>
                </el-form-item>
            </el-form>

            <vxe-grid v-if="open && title == '查看'"
                row-key
                max-height="400"
                border
                :columns="recordsColumn"
                :data="records"
                :toolbar-config="undefined"
                style="margin-top: 0px;"
                :loading="loadingRecord"
            >
                <template #readed="{ row, rowIndex }">
                    <el-tag v-if="row.readed=='1'">已查阅</el-tag>
                    <el-tag v-else type="info">未查阅</el-tag>
                </template>
                <template #submit_status="{ row, rowIndex }">
                    <el-tag v-if="row.submit_status=='1'" type="success">已提交</el-tag>
                    <el-tag v-else type="info">未提交</el-tag>
                </template>
                <template #headersdefaultopr="{ row, rowIndex }">
                    <el-button size="mini" type="text" icon="el-icon-view" @click="handleOpenApprove(row, true)"
                    >查看
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="handleOpenApprove(row)"
                    >评分
                    </el-button>
                </template>
            </vxe-grid>

            <vxe-pager v-if="open && title == '查看'" background size="small" :loading="loadingRecord" :current-page.sync="queryRecordParams.pageNum"
                :page-size="queryRecordParams.pageSize" :total="recordTotal" @page-change="handleRecordPageChange">
            </vxe-pager>

            <div slot="footer" class="dialog-footer">
                <el-button v-if="title != '查看'" type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>

        <el-dialog
			:close-on-click-modal="false"
			:title="approveTitle"
			:visible.sync="approveOpen"
			width="800px"
			append-to-body
		>
			<jf-form v-if="approveOpen" ref="approveForm" :model="approveForm" label-width="100px" :readonly="true" :readonly-column="1">
				<template #attachments>
                    <el-descriptions-item>
                        <template slot="label">
                            任务附件
                        </template>
                        <file-upload-ex v-model="approveForm.task.attachments" list-type="text" :readonly="true" />
                    </el-descriptions-item>
                </template>
                <template #score>
					<el-descriptions-item>
						<template slot="label">
							分数
						</template>
						<el-input-number
								v-model="approveForm.score"
								placeholder="请输入分数"
								controls-position="right"
								:min="0"
								:max="100"
								:precision="0"
								:step="1"
							/>
					</el-descriptions-item>
				</template>
				<template #submit_files>
					<el-descriptions-item>
						<template slot="label">
							提交材料
						</template>
						<file-upload-ex v-model="approveForm.submit_files" list-type="text" :readonly="true" />
					</el-descriptions-item>
				</template>
				<el-row>
					<el-col :span="12">
						<el-form-item label="接收人" prop="user_name">
							<el-input
								v-model="approveForm.username"
								placeholder=""
								type="textarea"
							/>
						</el-form-item> 
					</el-col>
					<el-col :span="12">
						<el-form-item label="所属部门" prop="dept_name">
							<el-input
								v-model="approveForm.dept_name"
								placeholder=""
								type="textarea"
							/>
						</el-form-item> 
					</el-col>
					<el-col :span="12" v-hasPermi="[
						'/oa/oataskrecord/transfer',
						'/oa/oataskrecord/submit',
						'/oa/oataskrecord/reject',
						'/oa/oataskrecord/approve',
					]">
						<el-form-item label="状态" prop="status">
							<dict-select
								v-model="approveForm.status"
								placeholder="请选择状态"
								type="oa_task_record_status"
							/>
						</el-form-item> 
					</el-col>
					<el-col :span="12" v-if="approveForm.submit_status=='1'" v-hasPermi="[
						'/oa/oataskrecord/submit',
					]">
						<jf-form-item label="提交内容" :value="approveForm.submit_content">
						</jf-form-item> 
					</el-col>
					<el-col :span="12" v-if="approveForm.submit_status=='1'" v-hasPermi="[
						'/oa/oataskrecord/submit',
					]">
						<jf-form-item label="提交材料" prop="submit_files" slots="submit_files">
							<el-input
								v-model="approveForm.submit_files"
								placeholder="请输入提交材料"
								clearable
							/>
						</jf-form-item> 
					</el-col>
					<el-col :span="12" v-if="approveForm.submit_status=='1'" v-hasPermi="[
						'/oa/oataskrecord/submit',
					]">
						<el-form-item label="提交时间" prop="submit_time">
							<datetime-select v-model="approveForm.submit_time" type="datetime" placeholder="请选择提交时间" />
						</el-form-item> 
					</el-col>
					<el-col :span="12" v-if="approveForm.submit_status=='1'" v-hasPermi="[
						'/oa/oataskrecord/submit',
					]">
						<jf-form-item v-if="approveTitle=='评分'" label="分数" prop="score" slots="score">
							<el-input-number
								v-model="approveForm.score"
								placeholder="请输入分数"
								controls-position="right"
							/>
						</jf-form-item> 
						<el-form-item v-else label="分数" prop="score">
							<el-input-number
								v-model="approveForm.score"
								placeholder="请输入分数"
								controls-position="right"
							/>
						</el-form-item> 
					</el-col>
					<el-col :span="12">
						<el-form-item label="阅读时间" prop="read_time">
							<datetime-select v-model="approveForm.read_time" type="datetime" placeholder="请选择阅读时间" />
						</el-form-item> 
					</el-col>
					<el-col v-if="approveTitle=='查看' && approveForm.submit_status=='1' && approveForm.approve_time" :span="12">
						<el-form-item label="评分时间" prop="approve_time">
							<datetime-select v-model="approveForm.approve_time" type="datetime" placeholder="请选择评分时间" />
						</el-form-item> 
					</el-col>
                    <el-col v-if="approveTitle=='查看' && approveForm.status=='3' && approveForm.reject_reason" :span="12">
                        <jf-form-item label="拒绝原因" :value="approveForm.reject_reason">
                        </jf-form-item> 
                    </el-col>
				</el-row>
			</jf-form>
	
			<div slot="footer" class="dialog-footer">
				<template v-if="approveTitle=='查看'">
					<el-button @click="approveOpen = false">关 闭</el-button>
				</template>
				<template v-else-if="approveTitle=='评分'">
					<el-button v-if="approveForm.submit_status=='1'" type="primary" @click="handleApprove">确 定</el-button>
					<el-button @click="approveOpen = false">返 回</el-button>
				</template>
			</div>
		</el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
	listOaTask,
	getOaTask,
	addOaTask,
	editOaTask,
	delOaTask,
} from "@/api/core/oa/oa_task";
import {
    listOaTaskRecord,
    getOaTaskRecord,
    approveOaTaskRecord
} from "@/api/core/oa/oa_task_record";
import { listRole } from "@/api/core/system/role";
export default {
    name: "form-dialog",
    props: {
    },
    data() {
        return {
            title: "",
			open: false,
            form: {},
			rules: {
                type: [{ required: true, message: "类型不能为空", trigger: "blur" }],
				title: [{ required: true, message: "名称不能为空", trigger: "blur" }],
				content: [{ required: true, message: "内容不能为空", trigger: "blur" }],
                begin_date: [{ required: true, message: "开始时间不能为空", trigger: "blur" }],
				submit_closing_date: [{ required: true, message: "截止时间不能为空", trigger: "blur" }],
				receive_type: [{ required: true, message: "接收对象不能为空", trigger: "blur" }],
				receive_roles: [{ required: true, message: "接收角色不能为空", trigger: "blur" }],
				receive_depts: [{ required: true, message: "接收部门不能为空", trigger: "blur" }],
				receive_user_ids: [{ required: true, message: "接收用户不能为空", trigger: "blur" }],
			},
            queryRecordParams: {
				pageNum: 1,
				pageSize: 5,
				task_id: 0,
                readed: '',
                submit_status: '',
                approve_status: ''
			},
            recordsColumn: [
                {field: 'username', title: '接收人' },
                {field: 'dept_name', title: '所属部门' },
                {field: 'readed', title: '查阅状态', slots: {default: 'readed'}, width: 80 },
                {field: 'read_time', title: '查阅时间', formatter: "formatDate", width: 150 },
                {field: 'submit_status', title: '提交状态', slots: {default: 'submit_status'}, width: 80 },
                {field: 'submit_time', title: '提交时间', formatter: "formatDate", width: 150 },
                {field: 'score', title: '分数', align: "center", width: 60 },
                {field: '', title: '操作',resizable:false, width: 120, fixed: "right", align: "center", slots: {default: 'headersdefaultopr'}}
            ],
            records: [],
            recordTotal: 0,
            loadingRecord: true,
            roleOptions: [],
            receiveTypeOptions: [],
            approveTitle: '',
            approveOpen: false,
            approveForm: {},
            selectUserGroups: [],
            selectedUserGroup: ''
        }
    },
    created() {
        listRole({
			pageSize: 1000,
			name: ''
		}).then(response => {
			this.roleOptions = response.data.list
			for (let i = 0; i < this.roleOptions.length; i++) {
				const d = this.roleOptions[i]
				this.receiveTypeOptions.push({
					label: d.name,
					value: d.id + ''
				}) 
			}
			this.receiveTypeOptions.push({
				label: '自定义接收部门',
				value: '#'
			})
			this.receiveTypeOptions.push({
				label: '自定义接收人',
				value: '*'
			})
		})

        const key = 'task-user-select-group-' + this.uid
        this.selectUserGroups = JSON.parse(localStorage.getItem(key) || '[]')
    },
    computed: {
        ...mapGetters([
            'uid'
        ])
    },
    methods: {
        add(pid) {
			this.reset();
            if (pid) {
                this.form.pid = pid
                this.form.distribute_type = "1"
                const loading = this.$loading({
                    text: '正在加载...'
                })
                getOaTask(pid).then((response) => {
                    loading.close()
                    this.form.category = response.data.category
                    this.form.type = response.data.type
                    this.form.title = response.data.title
                    this.form.content = response.data.content
                    this.form.attachments = response.data.attachments
                    this.form.begin_date = response.data.begin_date
                    this.form.submit_closing_date = response.data.submit_closing_date
                    this.open = true;
			        this.title = "添加";
                }).catch(e => {
                    loading.close()
                })
            } else {
                this.form.distribute_type = "0"
                this.open = true;
			    this.title = "添加";
            }
		},

		update(id, readonly) {
			this.reset();
            this.records = []
            const loading = this.$loading({
                text: '正在加载...'
            })
			getOaTask(id).then((response) => {
                loading.close()
				const form = response.data;
				if (form.receive_type == 'dept') {
					form.receive_type = '#'
				} else if (form.receive_type == 'user') {
					form.receive_type = '*'
				} else if (form.receive_type == 'role') {
					form.receive_type = form.receive_roles
				} else {
					form.receive_type = ''
				}
                if (readonly) {
                    this.title = "查看";
                } else {
				    this.title = "修改";
                }
				this.form = form
				this.open = true;

                if (readonly) {
                    this.queryRecordParams.task_id = id
                    this.handleRecordQuery()
                }
			}).catch(e => {
                loading.close()
            })
		},

        submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					const form = JSON.parse(JSON.stringify(this.form))
					if (form.receive_type == '#') {
						form.receive_type = 'dept'
						form.receive_roles = undefined
						form.receive_user_ids = undefined
						form.receive_user_names = undefined
						form.receive_user_types = undefined
                        form.datascope_filter = undefined
					} else if (form.receive_type == '*') {
						form.receive_type = 'user'
						form.receive_roles = undefined
						form.receive_depts = undefined
                        form.datascope_filter = undefined
					} else {
						form.receive_roles = form.receive_type
						form.receive_type = 'role'
						form.receive_depts = undefined
						form.receive_user_ids = undefined
						form.receive_user_names = undefined
						form.receive_user_types = undefined
					}

                    const loading = this.$loading({
                        text: '正在加载...'
                    })
					if (form.id != null) {
						editOaTask(form).then((response) => {
                            loading.close()
							if (response.code === 0) {
								this.msgSuccess("修改成功");
								this.open = false;
								setTimeout(() => {
									//this.getList();
                                    this.$emit('list-change')
								}, 300);
							}
						}).catch(e => {
                            loading.close()
                        })
					} else {
						addOaTask(form).then((response) => {
                            loading.close()
							if (response.code === 0) {
                                if (form.pid) {
                                    this.msgSuccess("转派成功，请到任务管理查看");
                                } else {
								    this.msgSuccess("新增成功");
                                }
								this.open = false;
								setTimeout(() => {
									//this.getList();
                                    this.$emit('list-change')
								}, 300);
							}
						}).catch(e => {
                            loading.close()
                        })
					}
				}
			});
		},

        cancel() {
			this.open = false;
			this.reset();
		},

		reset() {
			this.form = {
                pid: undefined,
				dept_id: undefined,
				category: undefined,
				type: undefined,
                distribute_type: undefined,
				title: undefined,
				content: undefined,
				attachments: undefined,
				status: undefined,
				emergent_level: undefined,
				important_level: undefined,
				send_sms: undefined,
                begin_date: undefined,
				view_closing_date: undefined,
				send_myself: undefined,
				send_mydept: undefined,
				need_submit_file: undefined,
				submit_files_visible: undefined,
				submit_closing_date: undefined,
				expire_notify: undefined,
				need_position: undefined,
				receive_type: undefined,
				receive_depts: undefined,
				receive_post: undefined,
				receive_roles: undefined,
				receive_user_types: undefined,
				receive_user_ids: undefined,
				receive_user_names: undefined,
                datascope_filter: undefined,
				receive_num: undefined,
				read_num: undefined,
				unread_num: undefined,
				submit_num: undefined,
				finish_num: undefined,
				reject_num: undefined,
				score: undefined,
				remark: undefined,
			};
			this.resetForm("form");
            this.selectedUserGroup = ''
		},

        handleCommand(cmd) {
            if (cmd === 'save') {
                if (!this.form.receive_user_ids) {
                    this.msgError('请选择用户')
                    return
                }
                
                this.$prompt('请输入分组名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    if (value) {
                        const key = 'task-user-select-group-' + this.uid
                        const groups = JSON.parse(localStorage.getItem(key) || '[]')
                        groups.push({
                            name: value,
                            ids: this.form.receive_user_ids,
                            names: this.form.receive_user_names
                        })
                        localStorage.setItem(key, JSON.stringify(groups))
                        this.selectUserGroups = groups
                    } else {
                        this.msgError('分组名称不能为空')
                    }
                });
            } else if (cmd == "del") {
                if (!this.selectedUserGroup) {
                    this.msgError('请选择分组再删除')
                    return
                }
                this.$confirm('确定删除分组' + this.selectedUserGroup + '吗?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(res => {
                    for (let i = 0; i < this.selectUserGroups.length; i++) {
                        const d = this.selectUserGroups[i]
                        if (d.name == this.selectedUserGroup) {
                            this.selectUserGroups.splice(i, 1)
                            break
                        }
                    }
                    const key = 'task-user-select-group-' + this.uid
                    localStorage.setItem(key, JSON.stringify(this.selectUserGroups))
                    this.selectedUserGroup = ''
                    this.form.receive_user_ids = undefined
                    this.form.receive_user_names = undefined
                })
            } else if (cmd && cmd.name && cmd.ids && cmd.names) {
                this.selectedUserGroup = cmd.name
                this.form.receive_user_ids = cmd.ids
                this.form.receive_user_names = cmd.names
            }
        },

        getRecordList() {
            this.loadingRecord = true
            listOaTaskRecord(this.queryRecordParams).then(res => {
                this.recordTotal = res.data.total
                this.records = res.data.list
                this.loadingRecord = false
            })
        },

        handleRecordQuery() {
			this.queryRecordParams.pageNum = 1;
			this.getRecordList();
		},

		resetRecordQuery() {
			this.resetForm("queryRecordForm");
			this.handleRecordQuery();
		},

        handleRecordPageChange({ currentPage, pageSize }) {
			if (this.queryRecordParams.pageSize == pageSize) {
				this.getRecordList();
			} else {
				this.queryRecordParams.pageSize = pageSize;
				this.handleRecordQuery();
			}
		},

        handleOpenApprove(row, readonly) {
            const id = row.id
            getOaTaskRecord(id).then((response) => {
                if (!response.data.approve_time && !response.data.score) {
                    response.data.score = undefined
                }
                this.approveForm = response.data;
                this.approveTitle = readonly ? '查看' : '评分';
                this.approveOpen = true;
            });
        },

        handleApprove() {
            if (this.approveForm.score === undefined) {
                this.msgError('请输入分数')
                return
            }
            approveOaTaskRecord({
                id: this.approveForm.id,
                score: this.approveForm.score,
                approve_status: '1'
            }).then(response => {
                this.msgSuccess('评分成功')
                this.approveOpen = false
                this.getRecordList()

                getOaTask(this.approveForm.task_id).then((response) => {
                    const form = response.data;
                    if (form.receive_type == 'dept') {
                        form.receive_type = '#'
                    } else if (form.receive_type == 'user') {
                        form.receive_type = '*'
                    } else if (form.receive_type == 'role') {
                        form.receive_type = form.receive_roles
                    } else {
                        form.receive_type = ''
                    }
                    this.form = form
                });
            })
        }
    }
}
</script>