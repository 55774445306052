<template>
    <el-form-item
        v-if="showForm&&!designer.isTopic"
        :label="i18nt('designer.setting.dsConf_dstid')"
    >
        <!--  -->
        <el-select
            v-model="optionModel.dsConf_dstid"
            filterable
            clearable
            @change="getDataSetList"
            class="ds-conf-dstid"
            @clear="clearData"
        >
            <el-option
                v-for="(item, idx) in dataSourceList"
                :key="idx"
                :title="item.name"
                :label="item.name"
                :value="item.id"
            ></el-option>
        </el-select>
    </el-form-item>
</template>
<script>
import i18n from "@/vform/utils/i18n";
export default {
    name: "data-source-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    computed: {
        dataSourceList() {
            if (
                !this.designer.formConfig ||
                !this.designer.formConfig.dataSources
            ) {
                this.setTips();
                return [];
            } else {
                this.setTips();
                return this.designer.formConfig.dataSources;
            }
        },
        showForm() {
            let array = Object.keys(this.optionModel);
            if (array.indexOf("dsName") > -1) {
                return !!this.optionModel.dsEnabled;
            }
            return true;
        },
    },
    created() {},
    methods: {
        setTips() {
            if (
                !this.designer.formConfig ||
                !this.designer.formConfig.dataSources ||
                !this.designer.formConfig.dataSources.length
            ) {
                this.optionModel.dsConf_dstid = "";
                this.optionModel.dsConf_dstattr = "";
                this.$nextTick(() => {
                    let text = document.querySelector(
                        ".ds-conf-dstid .el-select-dropdown__empty"
                    );
                    if (text) {
                        text.innerHTML = "请先添加数据源";
                    }
                });
            }
        },
        getDataSetList() {
            this.optionModel.dsConf_dstattr = "";
            // this.loadDataSet(this.optionModel.dsConf_dstid)
        },
        clearData() {
            this.optionModel.dsConf_dstid = "";
            this.optionModel.dsConf_dstattr = "";
        },
    },
};
</script>