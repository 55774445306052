import request from '@/utils/request'

export function listGbpxSite(query) {
  return request({
    url: '/gbpx/gbpxsite/list',
    method: 'get',
    params: query
  })
}

export function getGbpxSite(id) {
  return request({
    url: '/gbpx/gbpxsite/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxSite(data) {
  return request({
    url: '/gbpx/gbpxsite/add',
    method: 'post',
    data: data
  })
}

export function editGbpxSite(data) {
  return request({
    url: '/gbpx/gbpxsite/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxSite(ids) {
  return request({
    url: '/gbpx/gbpxsite/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

