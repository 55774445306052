<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="用户ID" prop="user_id">
                <el-input
                    v-model="queryParams.user_id"
                    placeholder="请输入用户ID"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="commentList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template> -->

            <!--默认操作按钮-->
            <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

            <!--自定义空数据模板-->
            <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>
        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="commentList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    >新增</el-button
                >
                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    >删除</el-button
                >
            </template>
            <template slot="subscribe" slot-scope="{ row }">
                {{ subscribeFormat({ row }) }}
            </template>
            <template slot="status" slot-scope="{ row }">
                {{ statusFormat({ row }) }}
            </template>
            <template slot="type" slot-scope="{ row }">
                {{ typeFormat({ row }) }}
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改评论对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="类型" prop="type">
                    <el-select v-model="form.type" placeholder="请选择">
                        <el-option
                            key="archives"
                            label="文档"
                            value="archives"
                        ></el-option>
                        <el-option
                            key="page"
                            label="单页"
                            value="page"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="关联ID" prop="aid">
                    <el-input v-model="form.aid" placeholder="关联ID" />
                </el-form-item>
                <el-form-item label="父ID" prop="pid">
                    <el-input v-model="form.pid" placeholder="父ID" />
                </el-form-item>
                <el-form-item label="用户ID" prop="user_id">
                    <el-input v-model="form.user_id" placeholder="用户ID" />
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <el-input
                        v-model="form.content"
                        type="textarea"
                        placeholder="请输入内容"
                    />
                </el-form-item>
                <el-form-item label="评论数" prop="comments">
                    <el-input-number
                        v-model="form.comments"
                        controls-position="right"
                        :min="0"
                    />
                </el-form-item>
                <el-form-item label="订阅" prop="subscribe">
                    <el-radio-group v-model="form.subscribe">
                        <el-radio key="0" label="0">否</el-radio>
                        <el-radio key="1" label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio key="normal" label="normal">正常</el-radio>
                        <el-radio key="hidden" label="hidden">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listCmsComment,
    getCmsComment,
    addCmsComment,
    updateCmsComment,
    delCmsComment,
} from "@/api/cms/comment";

export default {
    name: "cms-comment-list",
    data() {
        return {
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // {type: 'checkbox', width: 60, fixed: "left"},
                { field: "id", title: "编号", width: 100, fixed: "left" },
                {
                    field: "type",
                    title: "类型",
                    // formatter: this.typeFormat,
                    slots: {
                        default: "type",
                    },
                    form_type:2,
                    options: [
                        { label: "文档", value: "archives" },
                        { label: "单页", value:"page" },
                    ],
                    options_label: "label",
                    options_value: "value",
                },
                { field: "aid", title: "关联id" },
                { field: "user_id", title: "用户id" },
                { field: "comments", title: "评论数" },
                { field: "ip", title: "IP" },
                {
                    field: "subscribe",
                    title: "订阅",
                    // formatter: this.subscribeFormat,
                    slots: {
                        default: "subscribe",
                    },
                    form_type:2,
                    options: [
                        { label: "否", value: "0" },
                        { label: "是", value:"1" },
                    ],
                    options_label: "label",
                    options_value: "value",
                },
                {
                    field: "status",
                    title: "状态",
                    // formatter: this.statusFormat,
                    slots: {
                        default: "status",
                    },
                    form_type: 2,
                    options: [
                        { label: "正常", value: "normal" },
                        { label: "隐藏", value:"hidden" },
                    ],
                    options_label: "label",
                    options_value: "value",
                },
                // {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 评论表格数据
            commentList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 状态数据字典
            statusOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                type: [
                    {
                        required: true,
                        message: "类型不能为空",
                        trigger: "blur",
                    },
                ],
                aid: [
                    {
                        required: true,
                        message: "关联ID不能为空",
                        trigger: "blur",
                    },
                ],
                user_id: [
                    {
                        required: true,
                        message: "用户ID不能为空",
                        trigger: "blur",
                    },
                ],
                content: [
                    {
                        required: true,
                        message: "内容不能为空",
                        trigger: "blur",
                    },
                ],
                comments: [
                    {
                        required: true,
                        message: "评论数不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        //列表的排序和筛选
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询评论列表 */
        getList() {
            this.loading = true;
            listCmsComment(this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.commentList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        typeFormat({ row }) {
            if (row.type == "page") {
                return "单页";
            } else if (row.type == "archives") {
                return "文档";
            } else {
                return row.type;
            }
        },
        subscribeFormat({ row }) {
            if (row.status == "1") {
                return "是";
            } else {
                return "否";
            }
        },
        statusFormat({ row }) {
            if (row.status == "normal") {
                return "正常";
            } else {
                return "隐藏";
            }
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                code: undefined,
                name: undefined,
                comments: 0,
                subscribe: "0",
                status: "normal",
                content: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加评论";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getCmsComment(id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    this.form = {
                        id: data.id,
                        type: data.type,
                        aid: data.aid,
                        pid: data.pid,
                        user_id: data.user_id,
                        content: data.content,
                        comments: data.comments,
                        subscribe: data.subscribe + "",
                        status: data.status,
                    };
                }

                this.open = true;
                this.title = "修改评论";
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != undefined) {
                        updateCmsComment(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addCmsComment(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm(
                '是否确认删除评论编号为"' + ids + '"的数据项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(function () {
                    return delCmsComment(ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有评论数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportComment(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
    },
};
</script>
