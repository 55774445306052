import request from '@/utils/request'
// 生成时间:2022-06-16 16:39:08

// 查询course列表
export function listCourse(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/xlzx/course/list',
        method: 'post',
        data: data
    })
}

// 查询course详细
export function getCourse (id) {
    return request({
        url: '/xlzx/course/get?id='+id,
        method: 'get'
    })
}


// 新增course
export function addCourse(data) {
    return request({
        url: '/xlzx/course/add',
        method: 'post',
        data: data
    })
}

// 修改course
export function editCourse(data) {
    return request({
        url: '/xlzx/course/edit',
        method: 'post',
        data: data
    })
}

// 删除course
export function delCourse(ids) {
    return request({
        url: '/xlzx/course/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 删除course查询模板
export function advquerydelCourse(ids) {
    return request({
        url: '/xlzx/course/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加course查询模板
export function advquerysaveCourse(data) {
    return request({
        url: '/xlzx/course/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表course查询模板列表
export function advquerylistCourse(businessname) {
    return request({
        url: '/xlzx/course/advquerylist?businessname='+businessname,
        method: 'get'
    })
}
// 导出course
export function exportCourse(query,exportfields,tableColumn) {
     var data={
            filterstr:"",
            orderby:"",
            opstr:query.opstr ||{},
        };
        var filterstr={};
        for(var k in query){
         var item=query[k];
         if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
           filterstr[k]=item;
         }
        }
        var tarexportfieldslist={};
        for(var k in tableColumn){
          var tlbf = tableColumn[k];
           for(var i in exportfields){
             var ef = exportfields[i]
             if(tlbf.field!="" && tlbf.field && tlbf.field!=null && tlbf.field == ef){
               tarexportfieldslist.push({"filed":ef,"title":tlbf.title});
               break;
             }
           }
        }

        data["filterstr"] = JSON.stringify(filterstr);
        data["opstr"] = JSON.stringify(data["opstr"]);
        data["exportfields"] = JSON.stringify(tarexportfieldslist);
        return request({
            url: '/xlzx/course/export',
            method: 'post',
            data: data
        })
}
