import ModelSelect from "./modelselect";
import dzz from './dangjian/dzz'
import zzbzcy from './dangjian/zzbzcy'
import jtjjc from './dangjian/jtjjc'
import jtjjxm from './dangjian/jtjjxm'
import zcbfgr from './dangjian/zcbfgr'
import xczx from './dangjian/xczx'
import shidajiuye from './shida/shidajiuye'
import test from './test/test'
import StudentList from './studentlist/studentlist'
export default {
  install: (Vue) => {
    Vue.component('model-select', ModelSelect)
    Vue.component('dzz', dzz)
    Vue.component('zzbzcy', zzbzcy)
    Vue.component('jtjjc', jtjjc)
    Vue.component('jtjjxm', jtjjxm)
    Vue.component('zcbfgr', zcbfgr)
    Vue.component('xczx', xczx)
    Vue.component('shidajiuye', shidajiuye)
    Vue.component('test', test)
    Vue.component('student-list', StudentList)
  }
} 