<!-- 生成时间:2022-01-21 09:43:26 -->

<template>
    <div class="app-container">
        <el-row :gutter="20">
            <el-col :span="3" :xs="24">
                <div class="head-container">
                    <el-input
                        v-model="formcatename"
                        placeholder="请输入表单分类"
                        clearable
                        size="small"
                        prefix-icon="el-icon-search"
                        style="margin-bottom: 20px"
                    />
                </div>

                <div class="head-container">
                    <el-tree
                        :data="formcateOptions"
                        :props="formcateProps"
                        :expand-on-click-node="false"
                        :highlight-current="true"
                        :filter-node-method="filterNode"
                        ref="tree"
                        node-key="id"
                        @node-click="handleNodeClick"
                    />
                </div>
            </el-col>

            <el-col :span="21" :xs="24">
                <el-form
                    :model="queryParams"
                    ref="queryForm"
                    :inline="true"
                    v-show="showSearch"
                    label-width="68px"
                    label-position="left"
                >
                   
                    <el-form-item label="表单名称" prop="module_name">
                        <el-input
                            v-model="queryParams.fieldval.module_name"
                            placeholder="请输入名称"
                            clearable
                            size="small"
                            @keyup.enter.native="handleQuery"
                        />
                    </el-form-item>
                    <!-- <el-form-item label="业务名" prop="business_name">
        <el-input
          v-model="queryParams.business_name"
          placeholder="请输入业务名"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->

                    <el-form-item>
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            size="mini"
                            @click="handleQuery"
                            >搜索</el-button
                        >
                        <el-button
                            icon="el-icon-refresh"
                            size="mini"
                            @click="resetQuery"
                            >重置</el-button
                        >
                    </el-form-item>
                </el-form>
                <jf-table
                    v-if="isinit"
                    ref="jftable"
                    :loading="loading"
                    :columns="tableColumn"
                    :data="dataList"
                    :toolbar-config="tableToolbar"
                    :query-params="queryParams"
                    :total="total"
                    @checkbox-change="checkboxChangeEvent"
                    @change="tableChange"
                    @page-change="handleSizeChange"
                    @cell-dblclick="Oncelldblclick"
                    :defaultopr-width="defaultoprWidth"
                >
                    <template slot="toolbar_btn_left">
                        <el-button
                            type="primary"
                            icon="el-icon-plus"
                            size="mini"
                            @click="handleAdd"
                            v-hasPermi="['/form/model/add']"
                            >创建表单</el-button
                        >

                        <el-button
                            type="success"
                            icon="el-icon-edit"
                            size="mini"
                            :disabled="single"
                            @click="handleUpdate"
                            v-hasPermi="['/form/model/get', '/form/model/edit']"
                            >修改</el-button
                        >

                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="mini"
                            :disabled="multiple"
                            @click="handleDelete"
                            v-hasPermi="['/form/model/del']"
                            >删除</el-button
                        >
                    </template>
                    <template slot="menupidFormat" slot-scope="{ row }">
                        <div
                            v-text="getMenupidFormat(row)"
                            v-if="row.menupid"
                        ></div>
                    </template>

                    <template slot="toolbar_btn_row" slot-scope="{ row }">
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(row)"
                            v-hasPermi="['/form/model/get']"
                            >修改
                        </el-button>

                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit-outline"
                            @click="handleForm(row)"
                            v-hasPermi="['/form/model/formdesign']"
                            >设计
                        </el-button>
                        <el-button
                            size="mini"
                            v-if="enginetype == '2' || enginetype == '3'"
                            type="text"
                            icon="el-icon-document"
                            @click="ongegncode(row)"
                            v-hasPermi="['/form/model/formdesign']"
                            >生成代码
                        </el-button>

                        <el-button
                            type="text"
                            size="small"
                            icon="el-icon-menu"
                            @click="handleGenCreateMenu(row)"
                            v-hasPermi="['/form/model/createmenu']"
                            >{{
                                row.menupid == "" ? "生成菜单" : "查看菜单"
                            }}</el-button
                        >

                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="handleDelete(row)"
                            :disabled="row.is_create * 1 === 1"
                            v-hasPermi="['/form/model/del']"
                            >删除
                        </el-button>
                    </template>

                    <template slot="defaultcategory" slot-scope="{ row }">
                        {{ categoryFormat(row) }}
                    </template>
                    <template slot="defaultis_createt" slot-scope="{ row }">
                        {{ is_createFormat(row) }}
                    </template>
                    <template slot="defaultis_isgen" slot-scope="{ row }">
                        {{ is_genFormat(row) }}
                    </template>
                </jf-table>
            </el-col>
        </el-row>

        <!-- 添加或修改对话框 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <!-- enginetype == '1' &&  -->
                <el-form-item label="分类" prop="cate">
                <el-select
                    v-model="form.cate"
                    placeholder="请选择分类"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in cateOptions"
                        :key="dict.id"
                        :label="dict.label"
                        :value="dict.id"
                    />
                </el-select>
            </el-form-item> 
                <el-form-item
                    v-if="this.form.id == null"
                    label="创建方式"
                    prop="category"
                >
                    <!-- <el-select v-model="form.category" placeholder="请选择类型" size="small" style="width: 100%;">
						<el-option v-for="dict in categoryOptions" :key="dict.key" :label="dict.value"
							:value="dict.key" />
					</el-select> -->
                    <div class="form-typesel">
                        <div
                            @click="onSelformtype(item)"
                            v-for="item in formtypelist"
                            :key="item.type"
                            :class="
                                'form-typeitem ' +
                                (formtype == item.type
                                    ? 'form-itemselected'
                                    : '')
                            "
                        >
                            <img :src="item.img" class="image" />
                            <div>{{ item.label }}</div>
                        </div>
                    </div>
                </el-form-item>
                <div
                    v-if="selected_tplname != ''"
                    class="text-center seltplname"
                >
                    已选择模板:{{ selected_tplname }}
                </div>
                <div
                    v-if="selected_tablename != '' && form.id == null"
                    class="text-center seltplname"
                >
                    已选择数据库表:{{ selected_tablename }}
                    <el-button
                        type="priamry"
                        icon="el-icon-edit"
                        @click="editSelectedTab"
                        circle
                    ></el-button>
                </div>

                <el-form-item label="名称" prop="module_name">
                    <el-input
                        v-model="form.module_name"
                        placeholder="请输入名称"
                        maxlength="30"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item
                    v-if="enginetype == '2' || enginetype == '3'"
                    label="业务名"
                    prop="business_name"
                >
                    <el-input
                        type="text"
                        v-model="form.business_name"
                        :disabled="form.is_gen == '1'"
                        maxlength="30"
                        show-word-limit
                        placeholder="填写业务名(只支持大小写字母)"
                    />
                </el-form-item>
                <el-form-item
                    v-if="enginetype == '2'"
                    label="所属微服务"
                    prop="service_name"
                >
                    <el-input
                        type="text"
                        :disabled="form.is_gen == '1'"
                        maxlength="30"
                        show-word-limit
                        v-model="form.service_name"
                        placeholder="填写微服务名(只支持大小写字母)"
                    />
                </el-form-item>

                <el-form-item
                    v-if="enginetype == '3'"
                    label="所属微服务"
                    prop="service_name"
                >
                    <el-input
                        type="text"
                        maxlength="30"
                        show-word-limit
                        disabled
                        v-model="form.service_name"
                        placeholder="填写微服务名(只支持大小写字母)"
                    />
                </el-form-item>

                <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" placeholder="请输入备注" />
                </el-form-item>

                <!-- <el-form-item label="启用流程">
					<el-switch v-model="form.enable_bpm" active-value="1" inactive-value="0">
					</el-switch>
				</el-form-item>

				<el-form-item v-show="form.enable_bpm == '1'" label="流程id" prop="remark">
					<el-input type="number" v-model.number="form.flow_id" placeholder="请输入流程id" />
				</el-form-item>

				<el-form-item v-show="form.enable_bpm == '1'" label="流程资源ID" prop="remark">
					<el-input type="number" v-model.number="form.flow_resource_id" placeholder="流程资源ID" />
				</el-form-item> -->
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>

        <!--导出数据-->
        <!-- <el-dialog
            :close-on-click-modal="false"
            title="导出数据"
            :visible.sync="exportdialog"
            width="800px"
            append-to-body
        >
            <el-checkbox
                :indeterminate="exportisIndeterminate"
                v-model="exportcheckAll"
                @change="handleexportCheckAllChange"
                >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>

            <el-checkbox-group v-model="exportfields">
                <el-checkbox
                    v-for="field in tableColumn"
                    v-if="field.field"
                    :label="field.field"
                    :key="field.field"
                    >{{ field.title }}</el-checkbox
                >
            </el-checkbox-group>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doExport">确 定</el-button>
            </div>
        </el-dialog> -->

        <!--生成菜单选项-->
        <el-dialog
            :close-on-click-modal="false"
            title="选择上级菜单"
            :visible.sync="createmenuopen"
            width="50%"
            top="5vh"
            append-to-body
        >
            <el-form ref="formmenu" label-width="80px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上级菜单">
                            <treeselect
                                v-model="menupid"
                                :options="menuOptions"
                                :normalizer="normalizer"
                                :show-count="true"
                                placeholder="选择上级菜单"
                                @select="selectmenunode"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dogenmenu">确 定</el-button>
                <el-button @click="createmenuopen = false">取 消</el-button>
            </div>
        </el-dialog>

        <!--生成代码选项-->
        <el-dialog
            :close-on-click-modal="false"
            title="选项"
            :visible.sync="createcodeopen"
            width="50%"
            top="5vh"
            append-to-body
        >
            <el-form ref="form" label-width="120px">
                <el-row>
                    <!-- <el-col :span="24">
                        <el-form-item label="前端显示按钮">
                            <el-checkbox-group v-model="genselbtns">
                                <el-checkbox
                                    v-for="item in genbtns"
                                    :label="item.k"
                                    :key="item.k"
                                    >{{ item.v }}</el-checkbox
                                >
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col> -->

                    <el-col :span="24">
                        <el-form-item label="其他选项">
                            <el-switch
                                v-model="genoption.sw_vueonly"
                                active-text="仅生成前端页面"
                                active-color="#13ce66"
                                inactive-color="#eeeeee"
                            >
                            </el-switch>
                            <el-switch
                                v-model="genoption.sw_codeonly"
                                active-text="仅生成后端代码"
                                active-color="#13ce66"
                                inactive-color="#eeeeee"
                            >
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doconfirmgencode"
                    >确认生成</el-button
                >
                <el-button
                    @click="
                        () => {
                            createcodeopen = false;
                        }
                    "
                    >取 消</el-button
                >
            </div>
        </el-dialog>
        <!-- 创建表单弹窗 -->
        <el-dialog
            title="从模板创建"
            :visible.sync="templateOpen"
            width="80%"
            top="5vh"
            append-to-body
        >
            <div class="template-create">
                <el-row>
                    <el-col :span="4">
                        <div class="left-classify">
                            <li
                                v-for="(item, index) in templateClassify"
                                :key="index"
                                v-text="item.value"
                                :class="{
                                    active:
                                        templateClassifyId * 1 === item.key * 1,
                                }"
                                @click="tpclasschange(item.key)"
                            ></li>
                        </div>
                    </el-col>
                    <el-col :span="20">
                        <div class="template-list">
                            <div
                                class="template-list-item"
                                v-for="item in tplist"
                                :key="item.id"
                            >
                                <div
                                    class="template-list-item-content"
                                    @mouseenter="templateIndexChange(item)"
                                    @mouseleave="templateIndexChange(null)"
                                >
                                    <el-image
                                        class="w-100 h-100 border-radius-5"
                                        fit="cover"
                                        :src="
                                            require('@/assets/image/form-tpl.png')
                                        "
                                    ></el-image>
                                    <div
                                        class="operate-btn"
                                        v-if="templateIndex * 1 === item.id * 1"
                                    >
                                        <div class="flex-1 w-100">
                                            <el-button
                                                class="w-100"
                                                @click="templatePreview(item)"
                                                >预览</el-button
                                            >
                                        </div>
                                        <div class="flex-1 w-100 ml-10">
                                            <el-button
                                                type="primary"
                                                class="w-100"
                                                @click="useSubimit(item)"
                                                >使用</el-button
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center">{{ item.name }}</div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
        <div
            tabindex="-1"
            class="el-image-viewer__wrapper"
            style="z-index: 3500"
            v-if="previewOpen"
        >
            <div
                class="el-image-viewer__mask"
                @click="previewOpen = false"
            ></div>
            <span
                class="el-image-viewer__btn el-image-viewer__close"
                @click="previewOpen = false"
                ><i class="el-icon-close"></i
            ></span>
            <!-- <span class="el-image-viewer__btn el-image-viewer__prev"
                ><i class="el-icon-arrow-left"></i></span
            ><span class="el-image-viewer__btn el-image-viewer__next"
                ><i class="el-icon-arrow-right"></i
            ></span> -->
            <!-- <div class="el-image-viewer__btn el-image-viewer__actions">
                <div class="el-image-viewer__actions__inner">
                    <i class="el-icon-zoom-out"></i
                    ><i class="el-icon-zoom-in"></i
                    ><i class="el-image-viewer__actions__divider"></i
                    ><i class="el-icon-full-screen"></i
                    ><i class="el-image-viewer__actions__divider"></i
                    ><i class="el-icon-refresh-left"></i
                    ><i class="el-icon-refresh-right"></i>
                </div>
            </div> -->
            <div class="el-image-viewer__canvas">
                <img
                    :src="previewUrl"
                    referrerpolicy="no-referrer"
                    class="el-image-viewer__img"
                    style="
                        transform: scale(1) rotate(0deg);
                        margin-left: 0px;
                        margin-top: 0px;
                        max-height: 100%;
                        max-width: 100%;
                    "
                />
            </div>
        </div>
        <form-model-formdesign
            ref="formModelFormdesign"
            :mid="formInfoId"
            :enginetype-value="enginetype"
        ></form-model-formdesign>

        <!-- create-template -->

        <import-table ref="seltable" @onsel="handleSeltable" />
        <data-sheet-list
            ref="dataSheetList"
            @getWidgetList="getWidgetList"
        ></data-sheet-list>
    </div>
</template>
<script>
import {
    listZorecode,
    getZorecode,
    delZorecode,
    addZorecode,
    editZorecode,
    advquerydelZorecode,
    advquerysaveZorecode,
    advquerylistZorecode,
    exportZorecode,
    createMenu,
} from "@/api/form/gen/zorecode";
import * as Formx from "@/api/form/model";
import formModelFormdesign from "./forminfo";
import { mapGetters } from "vuex";
import { listMenu, treeselect } from "@/api/core/system/menu";
import Treeselect from "@riophae/vue-treeselect";
import importTable from "../importTable";
import dataSheetList from "../data-sheet-list";

const beforeUnload = function (e) {
    let msg = "离开网站可能会丢失您编辑的内容";
    (e || window.event).returnValue = msg; // Gecko and Trident
    return msg; // Gecko and WebKit
};
export default {
    name: "form-model-list",
    components: {
        Treeselect,
        formModelFormdesign,
        importTable,
        dataSheetList,
    },
    data() {
        return {
            formcateProps: {},
            formcateOptions: [],
            formcatename: undefined,
            selected_tablename: "",
            showFormdesign: false,
            formInfoOpen: false,
            formInfoId: 0,
            selected_tplid: 0,
            selected_tplname: "",
            tplist: [],
            //从模板创建
            templateOpen: false,
            //模板分类
            templateClassify: [],
            //模板分类id
            templateClassifyId: "",
            templateIndex: "",
            //预览图片地址
            previewUrl: "",
            previewOpen: false,
            genbtns: [
                { k: "add", v: "新增" },
                { k: "save", v: "修改" },
                { k: "del", v: "删除" },

                { k: "import", v: "导入数据" },
                { k: "get", v: "查看" },
                { k: "export", v: "导出数据" },
            ],
            genselbtns: ["add", "del", "get", "save"],
            createcodeopen: false,
            genoption: {
                sw_vueonly: false,
                sw_codeonly: false,
            },
            isinit: false,
            defaultoprWidth: 280,
            enginetype: "1", //1零代码 2代码生成(静态结构) 3动态结构
            defsort: {
                create_time: "desc",
            },
            formtype: "1",

            formtypelist: [
                {
                    type: "1",
                    label: "从空白表单创建",
                    enable: true,
                    img: require("@/assets/image/form-blank.png"),
                },
                {
                    type: "2",
                    label: "从系统模板创建",
                    enable: true,
                    img: require("@/assets/image/form-tpl.png"),
                },
                {
                    type: "4",
                    label: "从现有数据表创建",
                    enable: true,
                    img: require("@/assets/image/form-tpl.png"),
                },
                {
                    type: "3",
                    label: "从Excel创建",
                    enable: false,
                    img: require("@/assets/image/form-excel.png"),
                },
            ],

            maintablelist: [],
            Getmaintableloading: false,
            extattrinfo: {},

            // 菜单树选项
            menuOptions: [],
            menupid: 0,
            selnode: null,
            createmenuopen: false,
            // 菜单树选项
            menuOptions: [],
            businessname: "zorecode", //请勿改动
            exportfields: [],
            exportcheckAll: false,
            exportisIndeterminate: false,
            exportdialog: false,

            //编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },

            // 列
            tableColumn: [
                // {
                // 	type: "checkbox",
                // 	width: 60,
                // 	fixed: "left"
                // },
                // {
                //     field: "category",
                //     title: "类型",
                //     width: 100,
                //     // formatter: this.categoryFormat,
                //     slots: {
                //         default: "defaultcategory",
                //     },

                //     isCheck: true,
                //     form_type: "2",
                //     options: [],
                //     options_label: "value",
                //     options_value: "key",
                //     // slots: {
                //     // 	// 使用 JSX 渲染
                //     // 	default: ({
                //     // 		cellValue
                //     // 	}) => {
                //     // 		return this.selectDictLabel(this.categoryOptions, "" + cellValue)
                //     // 	}
                //     // },
                // },
                {
                    field: "module_name",
                    title: "表单名称",
                    minWidth: 80,
                    isCheck: true,
                    form_type: "1",
                    // slots: {
                    //     default: ({ row }) => {
                    //         if (row.category == "3" || row.category == "6") {
                    //             let myStyle = {
                    //                 color: "blue",
                    //                 cursor: "pointer",
                    //             };
                    //             return [
                    //                 <div>
                    //                     {" "}
                    //                     {row.module_name}【 关联主表:{" "}
                    //                     <span
                    //                         onClick={() =>
                    //                             this.handleUpdate({
                    //                                 id: row.main_table_id,
                    //                             })
                    //                         }
                    //                         style={myStyle}
                    //                     >
                    //                         {" "}
                    //                         {row.main_table_name}{" "}
                    //                     </span>
                    //                     】
                    //                 </div>,
                    //             ];
                    //         }
                    //         return row.module_name;
                    //     },
                    // },
                },
                // { field: "business_name", title: "业务名", minWidth: 80 },
                {
                    field: "menupid",
                    title: "菜单名称",
                    minWidth: 80,
                    // formatterName: 'is_createFormat',
                    slots: {
                        default: "menupidFormat",
                    },
                    visible: false,
                    // isCheck: true,
                    // form_type: "2",
                    // options: [],
                    // options_label: "value",
                    // options_value: "key",
                },
                {
                    field: "remark",
                    title: "备注",
                    minWidth: 80,
                    isCheck: true,
                    form_type: "1",
                },
                {
                    field: "is_create",
                    title: "实体表",
                    minWidth: 80,
                    // formatterName: 'is_createFormat',
                    slots: {
                        default: "defaultis_createt",
                    },
                    isCheck: true,
                    form_type: "2",
                    options: [],
                    options_label: "value",
                    options_value: "key",
                },

                {
                    field: "is_gen",
                    title: "是否生成代码",
                    minWidth: 80,
                    visible: this.enginetype == "2",
                    // formatterName: 'is_createFormat',
                    slots: {
                        default: "defaultis_isgen",
                    },
                    isCheck: true,
                    form_type: "2",
                    options: [],
                    options_label: "value",
                    options_value: "key",
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 80,
                    formatter: "formatDate",
                    isCheck: true,
                    form_type: "3",
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 80,
                    formatter: "formatDate",
                    isCheck: true,
                    form_type: "3",
                },

                // {
                // 	field: "",
                // 	title: "操作",
                // 	width: 280,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: {
                // 		default: "defaultopr"
                // 	},
                // },
            ],

            advqueryColumn: [
                {
                    field: "qfield",
                    title: "查询字段",
                    width: 180,
                    fixed: "left",
                    editRender: {
                        name: "select",
                        options: [
                            {
                                value: "dd",
                                label: "cxvs",
                            },
                            {
                                value: "zz",
                                label: "cxvs",
                            },
                        ],
                    },
                },
                {
                    field: "qopr",
                    title: "操作符",
                    width: 180,
                    fixed: "left",
                    editRender: {
                        name: "select",
                        options: [],
                    },
                },
                {
                    field: "qvalue",
                    title: "值",
                    width: 180,
                    fixed: "left",
                    editRender: {
                        name: "input",
                    },
                },
                {
                    field: "qlogic",
                    title: "逻辑",
                    width: 180,
                    fixed: "left",
                    editRender: {
                        name: "select",
                        options: [],
                    },
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 110,
                    fixed: "right",
                    align: "center",
                    slots: {
                        default: "defaultadvqueryopr",
                    },
                },
            ],
            advqueryColumn_tempfilter: [
                {
                    field: "tempname",
                    title: "模版名称",
                    width: 200,
                    fixed: "left",
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: {
                        default: "defaultadvqueryopr_tempfilter",
                    },
                },
            ],
            advqueryData_tempfilter: [],
            advqueryopen: false,
            advquery_tempsave: false,
            advqueryData: [],
            advqueryToolbar: {
                buttons: [
                    {
                        code: "myInsert",
                        name: "新增",
                    },
                    {
                        code: "dofilter",
                        name: "使用此组合条件查询",
                    },
                ],
            },
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 字典
            categoryOptions: [],
            is_createOptions: [],
            is_genOptions: [],

            // 查询参数
            queryParams: {
                page: 1,
                psize: 10,
                fieldval: {},
                querytype: {
                    module_name: "like",
                },
                sortinfo: { id: "desc" },
            },
            // 表单参数
            form: {},
            advquery_tempsaveform: {},
            advquery_tempsaverules: {
                tempname: [
                    {
                        required: true,
                        message: "模版名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            // 表单校验
            rules: {
                module_name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            mid: 0,
            dbtableList: [],
            widgetList: [],
            menuRoutes: [],
        };
    },
    watch: {
			 
			formcatename(val) {
				this.$refs.tree.filter(val);
			}
		},


    created() {
        // console.log("this.$route", this.menuRoutes);
        var form_cate = "";

        var routepath = this.$route.fullPath;
        if (routepath.indexOf("/form/gen") != -1) {
            form_cate = "form_cate_gen";
            this.enginetype = "2";
            this.defaultoprWidth = 340;
            this.rules["service_name"] = [
                {
                    required: true,
                    message: "微服务名不能为空",
                    trigger: "blur",
                },
                { validator: this.validatebusiness_name, trigger: "blur" },
            ];
            this.rules["business_name"] = [
                {
                    required: true,
                    message: "业务名不能为空",
                    trigger: "blur",
                },
                { validator: this.validatebusiness_name, trigger: "blur" },
            ];
            this.tableColumn[1].visible = true;
            this.tableColumn[3].visible = true;
        } else if (routepath.indexOf("/form/dync") != -1) {
            form_cate = "";
            this.enginetype = "3";
            this.defaultoprWidth = 340;
        } else {
            form_cate = "form_cate_zc";
        }

        this.queryParams.sortinfo = this.defsort;
        this.isinit = true;
        setTimeout(() => {
            const $grid = this.$refs.jftable;
            $grid.$refs.xGrid.refreshColumn();

            this.getList();
        }, 300);
        // 加载字典数据
        if (form_cate != "") {
            this.getDicts(form_cate).then((response) => {
                console.log(response, "response");
                var values=this.categoryOptions = response.data.values || [];
                var toption=[{id:null,label:'全部'}]
                 for(let i in values){
                    toption.push({id:values[i].key,label:values[i].value})
                 }
                 this.formcateOptions = toption
            });
        }
        this.getDicts("gen_form_type").then((response) => {
            this.categoryOptions = response.data.values || [];
            for (let i = 0; i < this.tableColumn.length; i++) {
                if (this.tableColumn[i].field === "category") {
                    this.tableColumn[i].options = this.categoryOptions;
                    break;
                }
            }
        });

        this.getDicts("sys_is_create").then((response) => {
            this.is_createOptions = response.data.values || [];
            this.is_genOptions = response.data.values || [];
            console.log(this.is_createOptions, "this.is_createOptions");
            for (let i = 0; i < this.tableColumn.length; i++) {
                if (this.tableColumn[i].field === "is_create") {
                    this.tableColumn[i].options = this.categoryOptions;
                    break;
                }
            }
        });
        this.getDicts("form_tpl_cate").then((response) => {
            if (response.code === 0) {
                this.templateClassify = response.data.values || [];
                this.templateClassifyId = this.templateClassify[0].key;
                this.gettplist(this.templateClassifyId);
            }
        });

        setTimeout(() => {
            const $grid = this.$refs.xAdvqueryGrid;
            // 异步更新下拉选项
            var ta = [
                {
                    value: "",
                    label: "请选择",
                },
            ];
            for (var i in this.tableColumn) {
                var e = this.tableColumn[i];
                if (e.field != null && e.field != "") {
                    ta.push({
                        value: e.field,
                        label: e.title,
                    });
                }
            }
            this.advqueryColumn[0].editRender.options = ta;

            this.advqueryColumn[1].editRender.options = this.defaultQlogic();

            ta = [];
            ta.push({
                value: "AND",
                label: "并且",
            });
            ta.push({
                value: "OR",
                label: "或者",
            });
            this.advqueryColumn[3].editRender.options = ta;
        }, 300);
        // 加载字典数据

        setTimeout(() => {
            var fromguide = localStorage.getItem("fromguide_zc");
            localStorage.removeItem("fromguide_zc");
            if (fromguide === "0") {
                this.handleAdd();
            } else if (fromguide === "1" && this.dataList.length > 0) {
                this.handleForm(this.dataList[0]);
            } else if (fromguide === "2" && this.dataList.length > 0) {
                this.handleGenCreateMenu(this.dataList[0]);
            }
        }, 500);
    },
    computed: {
        ...mapGetters({
            routes: "routes/routes",
        }),
        cateOptions(){
            return this.formcateOptions.filter((item) => {
                return item.id !==null;
            });
        },
    },
    beforeRouteLeave(to, from, next) {
        // ...
        // 获取目标 <div> 元素
        var divElement = document.querySelector(".vxe-table--tooltip-wrapper");

        // 移除指定的类名
        if (divElement) {
            divElement.classList.remove("placement--top");
            divElement.classList.remove("is--visible");
        }

        next(); // 确保继续进行路由导航
    },

    methods: {
        handleNodeClick(data) {
            this.queryParams.pageNum = 1;
            this.queryParams.fieldval.cate = data.id;
            this.getList();
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        //翻译菜单
        getMenupidFormat(row) {
            if (!row.menupid) return "";

            let obj = this.getMenuName(row.menupid, this.routes);
            return obj ? obj.title : "";
        },
        getMenuName(id, data) {
            let result = null;

            function recursiveSearch(arr) {
                for (const item of arr) {
                    if (item.id * 1 === id * 1) {
                        result = item;
                        break;
                    } else if (item.children && item.children.length) {
                        recursiveSearch(item.children);
                    }
                }
            }

            recursiveSearch(data);

            return result;
        },
        getWidgetList(e) {
            this.widgetList = e;
        },
        //编辑数据表
        editSelectedTab() {
            this.$refs.dataSheetList.getInit(this.dbtableList);
        },
        handleSeltable({ table_name, table_comment }) {
            this.selected_tablename = table_name;
            this.form.module_name = table_comment;
            Formx.dbtablecolumns(table_name).then((res) => {
                if (res.code === 0) {
                    this.dbtableList = res.data.list;
                    this.$refs.dataSheetList.getInit(res.data.list);
                }
            });
        },
        //获取模板
        gettplist(cid) {
            var tqueryParams = {};
            tqueryParams["fieldval"] = {
                cate: cid,
            };
            tqueryParams["sortinfo"] = {
                id: "desc",
            };
            tqueryParams["pageNum"] = 1;
            tqueryParams["pageSize"] = 30;

            Formx.Formxformtpllist(tqueryParams).then((res) => {
                // console.log("gettplist res", res);
                this.tplist = res.data.list;
            });
        },
        tpclasschange(key) {
            this.templateClassifyId = key;
            this.gettplist(this.templateClassifyId);
        },
        //使用
        useSubimit(item) {
            this.templateOpen = false;
            this.selected_tplid = item.id;
            this.selected_tplname = item.name;
        },
        //模板预览
        templatePreview(item) {
            // this.previewUrl =
            //     item.url ||
            //     "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg";
            // this.previewOpen = true;
        },
        templateIndexChange(item) {
            this.templateIndex = item ? item.id : -1;
        },
        ongegncode(row) {
            this.selrow = row;
            this.createcodeopen = true;
        },
        doconfirmgencode() {
            var gen_option = {};
            gen_option["genselbtns"] = this.genselbtns.join("/");
            gen_option["sw_vueonly"] = this.genoption.sw_vueonly ? "1" : "0";
            gen_option["sw_codeonly"] = this.genoption.sw_codeonly ? "1" : "0";

            this.$confirm("是否确认生成代码?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.loading = true;
                var gendata = {
                    mid: this.selrow.id,
                    genoption: JSON.stringify(gen_option),
                };
                console.log(gendata);
                this.createcodeopen = false;
                Formx.FormxGencode(gendata)
                    .then(() => {
                        this.loading = false;
                        setTimeout(() => {
                            this.getList();
                        }, 300);

                        this.createcodeopen = false;
                        this.msgSuccess("操作成功");
                    })
                    .catch((e) => {
                        console.log("catch", e);
                        this.loading = false;
                    });
            });
        },
        validatebusiness_name(rule, value, callback) {
            const varreg = /^[a-zA-Z]+$/;

            if (value.length > 30) {
                callback(new Error("长度不能大于30"));
            } else if (!varreg.test(value)) {
                callback(new Error("只支持大小写字母!"));
            } else {
                callback();
            }
        },
        Oncelldblclick({ row }) {
            this.handleForm(row);
            // console.log("row", row);
            // const mid = row.id;
            // this.$router.push({
            //     path: "/form/model/formdesign",
            //     query: {
            //         mid: mid,
            //     },
            // });
        },
        tableChange(e) {
            console.log("tableChange data", e);
            switch (e.name) {
                case "filter":
                    let ftdata = e.data;
                    console.log("ftdata", ftdata);
                    for (let i in ftdata) {
                        let oneft = ftdata[i];
                        this.queryParams.querytype[oneft.field] =
                            oneft.querytype;
                        this.queryParams.fieldval[oneft.field] = oneft.value;
                    }
                    this.queryParams.pageNum = 1;

                    this.getList(this.mid);

                    break;
                case "srot":
                    if (JSON.stringify(e.data) == "{}") {
                        this.queryParams.sortinfo = this.defsort;
                    } else {
                        this.queryParams.sortinfo = JSON.parse(
                            JSON.stringify(e.data)
                        );
                    }

                    this.getList(this.mid);
                    break;
            }
            // console.log("tableChange this.queryParams", this.queryParams);
        },
        onSelformtype(item) {
            if (!item.enable) {
                this.msgSuccess("敬请期待");

                return false;
            }
            this.formtype = item.type;
            if (this.formtype * 1 === 2) {
                this.templateOpen = true;
            } else if (this.formtype * 1 === 4) {
                this.$refs.seltable.show();
            }
        },

        Onselmaintable(value, value2) {
            console.log("Onselmaintable", value, this.maintablelist);
        },
        GetmaintableremoteMethod(query) {
            console.log("query", query);
            this.Getmaintableloading = true;
            let category = this.form.category == "3" ? "2" : "5";
            let tmpparam = {
                category: category,
                module_name: query,
                page: 1,
                pageSize: 100,
                opstr: {
                    module_name: "LIKE",
                },
            };

            listZorecode(tmpparam).then((response) => {
                this.maintablelist = response.data.list;

                this.Getmaintableloading = false;
            });
        },
        //打开其他设置
        onopenextattr(row) {
            const xGrid = this.$refs.xGrid;
            var rowindex = xGrid.getRowIndex(row);
            this.currowindex = rowindex;
            console.log("row rowindex", row, rowindex);
            // return false;
            this.drawer_extattr = true;
            this.drawer_title =
                row.column_name + "[" + row.column_comment + "]" + " 其他配置";
            this.drawer_extattr_field = row.column_name;
            this.extattrinfo =
                (this.colList[rowindex].extattr &&
                    JSON.parse(this.colList[rowindex].extattr)) ||
                {};
        },

        selectmenunode(node, instanceId) {
            this.selnode = node;
            //      console.log(node, instanceId)
            //      this.selnode=null
        },

        /** 转换菜单数据结构 */
        normalizer(node) {
            if (node.children && !node.children.length) {
                delete node.children;
            }

            return {
                id: node.id,
                label:
                    node.title +
                    (node.menu_type == "0" ? "-[菜单类型:目录]" : ""),
                children: node.children,
            };
        },

        dogenmenu() {
            if (this.selnode == null || this.selnode.menu_type != "0") {
                this.msgError("请选择菜单类型为目录的上级!");
                return;
            }

            this.loading = true;
            var data = {
                menuoption: JSON.stringify({
                    pid: "" + this.selnode.id,
                    enginetype: this.enginetype,
                }),
                mid: this.mid,
            };

            return Formx.FormxCreatemenu(data)
                .then(() => {
                    this.loading = false;
                    this.msgSuccess("操作成功");
                    this.createmenuopen = false;
                    this.getList(this.mid);
                })
                .catch((e) => {
                    console.log("catch", e);
                    this.loading = false;
                });
        },

        handleGenCreateMenu(row) {
            if (row.is_create * 1 === 0) {
                this.$message.warning("当前数据未生成实体表，暂不能生成菜单");
                return;
            }
            const tid = row.id;
            this.createmenuopen = true;
            listMenu().then((response) => {
                this.menuOptions = [];
                const menu = {
                    id: 0,
                    title: "主类目",
                    children: [],
                };
                menu.children = this.handleTree(
                    response.data.list,
                    "id",
                    "pid"
                );
                this.menuOptions.push(menu);
            });
            this.mid = tid;
            if (row.menupid != "") this.menupid = row.menupid;
        },

        handleUpload() {},
        handleExport() {
            if (this.total > 0) {
                this.exportdialog = true;
            } else {
                this.msgError("暂无数据可导出!");
            }
        },
        doExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportZorecode(
                        queryParams,
                        this.exportfields,
                        this.tableColumn
                    );
                })
                .then((response) => {
                    this.download(response.file);
                })
                .catch(function () {});
        },
        handleexportCheckAllChange(val) {
            this.exportfields = val
                ? this.tableColumn.map((item) => {
                      return item.field;
                  })
                : [];
            this.exportisIndeterminate = false;
        },
        //删除查询模版
        handleadvqueryDeltpl(row) {
            var ids = row.id;
            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    advquerydelZorecode(ids)
                        .then(() => {
                            const $grid = this.$refs.xAdvqueryGrid_tempfilter;
                            $grid.remove(row);
                        })
                        .then(() => {
                            this.msgSuccess("删除成功");
                        });
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        //打开高级查询
        handleAdvquery() {
            advquerylistZorecode(this.businessname).then((response) => {
                this.advqueryData_tempfilter = response.data.list;
                this.advqueryopen = true;
            });
        },
        //保存条件
        // advquery_save() {
        //   const $grid = this.$refs.xAdvqueryGrid;
        //   var alldata = $grid.getTableData();
        //   if (alldata.fullData.length == 0) {
        //     return false;
        //   }

        //   var opstr = {};
        //   var filteroptview = [];
        //   var Qlogicar = this.defaultQlogic();
        //   var queryParams = {};
        //   var fields = {},
        //     Qlogic = {};

        //   for (var i in this.tableColumn) {
        //     var col = this.tableColumn[i];
        //     if (col.field != null && col.field != "") {
        //       fields[col.field] = col.title;
        //     }
        //   }

        //   for (var i in Qlogicar) {
        //     var lgc = Qlogicar[i];
        //     Qlogic[lgc.value] = lgc.label;
        //   }

        //   for (var i in alldata.fullData) {
        //     var onerow = alldata.fullData[i];
        //     queryParams[onerow.qfield] = onerow.qvalue;
        //     opstr[onerow.qfield] = onerow.qopr;
        //     filteroptview.push(
        //       fields[onerow.qfield] + " " + Qlogic[onerow.qopr] + "" + onerow.qvalue
        //     );
        //   }

        //   queryParams.opstr = opstr;
        //   this.advquery_tempsaveform["businessname"] = this.businessname;
        //   this.advquery_tempsaveform["filteropt"] = JSON.stringify(queryParams);
        //   this.advquery_tempsaveform["filteroptview"] = filteroptview.join(";");

        //   this.$refs["advquery_tempsaveform"].validate((valid) => {
        //     if (valid) {
        //       advquerysaveZorecode(this.advquery_tempsaveform).then((response) => {
        //         if (response.code === 0) {
        //           this.msgSuccess("保存成功");
        //           this.advquery_tempsave = false;
        //           this.handleAdvquery();
        //         }
        //       });
        //     }
        //   });
        // },

        //应用查询模版
        // handleadvqueryApplytpl(row) {
        //   var Filteropt = JSON.parse(row.filteropt);

        //   Filteropt["pageNum"] = 1;
        //   Filteropt["pageSize"] = 10;
        //   this.queryParams = Filteropt;
        //   this.advqueryopen = false;
        //   setTimeout(() => {
        //     this.getList();
        //   }, 300);
        // },
        //删除临时条件
        // handleadvqueryDeleted(row) {
        //   const $grid = this.$refs.xAdvqueryGrid;

        //   this.$confirm("是否确认删除?", "警告", {
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消",
        //     type: "warning",
        //   })
        //     .then(function () {
        //       $grid.remove(row);
        //     })
        //     .then(() => {
        //       this.msgSuccess("删除成功");
        //     })
        //     .catch(function () {});
        // },
        advqtoolbarButtonClickEvent({ code }) {
            const $grid = this.$refs.xAdvqueryGrid;
            switch (code) {
                case "myInsert":
                    var row = $grid.insert({
                        qfield: "",
                        qopr: "=",
                        qlogic: "AND",
                    });

                    $grid.setActiveRow($grid.getData(0));
                    break;

                case "dosavefilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }
                    this.advquery_tempsave = true;
                    break;
                case "dofilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }

                    var opstr = {};

                    var queryParams = {
                        pageNum: 1,
                        pageSize: 10,
                        opstr: {},
                    };

                    for (var i in alldata.fullData) {
                        var onerow = alldata.fullData[i];
                        queryParams[onerow.qfield] = onerow.qvalue;
                        opstr[onerow.qfield] = onerow.qopr;
                    }

                    queryParams.opstr = opstr;
                    this.queryParams = queryParams;
                    this.advqueryopen = false;
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    break;
            }
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
            let is_create_list = records.filter(
                (item) => item.is_create * 1 === 1
            );

            if (is_create_list.length > 0) {
                this.multiple = true;
            }
        },

        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.page = currentPage;
            if (this.queryParams.psize == pageSize) {
                this.getList();
            } else {
                this.queryParams.psize = pageSize;
                this.handleQuery();
            }
        },

        /** 查询列表 */
        getList() {
            this.loading = true;

            // listZorecode(this.queryParams).then((response) => {
            //   this.dataList = response.data.list;
            //   this.total = response.data.total;
            //   this.loading = false;
            // });
            this.queryParams.fieldval["category"] = this.enginetype;
            Formx.Formxlist(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // $comment字典翻译
        categoryFormat(row) {
            return this.selectDictLabel(
                this.categoryOptions,
                "" + row.category
            );
        },

        is_createFormat(row) {
            return this.selectDictLabel(
                this.is_createOptions,
                "" + row.is_create
            );
        },
        is_genFormat(row) {
            return this.selectDictLabel(this.is_genOptions, "" + row.is_gen);
        },

        // 取消按钮
        cancel() {
            this.open = false;
            this.advqueryopen = false;
            setTimeout(() => {
                this.reset();
            }, 300);
        },
        // 表单重置
        reset() {
            this.form = {
                category: undefined,
                module_name: undefined,
                business_name: undefined,
                options: undefined,
                is_create: undefined,
                service_name: undefined,
                enable_bpm: "0",
                flow_id: undefined,
                flow_resource_id: undefined,
                main_table_id: undefined,
                main_table_name: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
            this.selected_tplid = 0;
            this.selected_tplname = "";
            this.selected_tablename = "";
            this.widgetList = [];
            this.dbtableList = [];
            this.formtype = 1;
            if (this.enginetype == "3") {
                this.form.service_name = "core";
            } else {
                this.form.service_name = "";
            }
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            Formx.FormxGet(id).then((response) => {
                this.form = response.data.row;
                delete this.form["formresource"];
                this.maintablelist = [
                    {
                        id: this.form.main_table_id,
                        module_name: this.form.main_table_name,
                    },
                ];

                this.open = true;
                this.title = "修改";
            });
        },
        handleForm(row) {
            const mid = row.id || this.ids[0];
            const enginetype = this.enginetype;
            // this.formInfoId = mid;
            // this.formInfoOpen = true;
            // setTimeout(() => {
            this.$refs["formModelFormdesign"].getInit({ mid, enginetype });
            // });
            // this.$router.push({
            //     path: "/form/model/formdesign",
            //     query: {
            //         mid: mid,
            //         enginetype: this.enginetype,
            //     },
            // });
        },
        /** 提交按钮 */
        submitForm() {
            // if (this.form.enable_bpm == "1") {
            //     if (this.form.flow_id == "" || this.form.flow_id == 0) {
            //         return this.msgError("启用流程后,流程id不能为空!");
            //     }
            //     // if (
            //     //   this.form.flow_resource_id == "" ||
            //     //   this.form.flow_resource_id == 0
            //     // ) {
            //     //   return this.msgError("启用流程后,资源ID不能为空!");
            //     // }
            // }
            // if (this.form.category == "3") {
            //     if (!this.form.main_table_id || this.form.main_table_id == "") {
            //         return this.msgError("当类型选择 附表时,主表功能不能为空!");
            //     }
            // }
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    // this.form.create_time = this.DatetounixTimestamp(
                    //   this.form.create_time
                    // );
                    // this.form.update_time = this.DatetounixTimestamp(
                    //   this.form.update_time
                    // );
                    this.form.category = this.enginetype;
                    if (this.enginetype == "1") {
                        this.form.business_name = null;
                    } else {
                        if (this.form.business_name == this.form.service_name) {
                            this.msgError("业务名不能和微服务名一样!");

                            return false;
                        }
                    }

                    if (this.form.id != null) {
                        const param = {
                            id: this.form.id,
                            row: JSON.stringify(this.form),
                        };

                        Formx.FormxSave(param).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        var option = "";
                        if (this.selected_tplid != "") {
                            option = "tpl-" + this.selected_tplid;
                        } else if (this.selected_tablename != "") {
                            option = "table-" + this.selected_tablename;
                        }
                        const param = {
                            row: JSON.stringify(this.form),
                            option: option,
                        };

                        Formx.FormxAdd(param).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                                if (
                                    this.formtype * 1 === 4 &&
                                    this.widgetList.length
                                ) {
                                    let mid = response.data.id;
                                    let enginetype = this.enginetype;
                                    let widgetList = this.widgetList;
                                    this.$refs["formModelFormdesign"].getInit({
                                        mid,
                                        enginetype,
                                        widgetList,
                                    });
                                }
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            console.log("row", row);

            console.log("this.ids", this.ids);
            const ids = row.id || this.ids.join(",");

            this.$confirm("是否确认删除所选数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return Formx.FormxDel(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
    }, //methods结束
};
</script>
<style scoped>
::v-deep .el-drawer__body {
    flex: 1;
    overflow: auto;
    padding: 20px;
    padding-top: 0;
}

::v-deep .el-drawer__header {
    margin-bottom: 0;
}

::v-deep .sub-title {
    color: #fc6363;
}
.form-typesel {
    display: flex;
}
.form-typeitem {
    flex: 1;
    height: 150px;
    text-align: center;
    justify-content: center;
    justify-items: center;
    border: 2px solid #eee;
    border-radius: 8px;
    margin: 0 9px;
    padding-top: 8px;
    cursor: pointer;
}
.form-typeitem:hover,
.form-itemselected {
    border-color: dodgerblue;
}
.form-typeitem .image {
    max-width: 100%;
    display: inline-flex;
}

.template-create {
    border-radius: 10px;
    width: 100%;

    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}
.left-classify {
    width: 100%;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: -10px;
    height: 600px;

    overflow-y: auto;
    padding: 10px;
}
.left-classify li {
    list-style: none;
    padding: 10px;
    margin: 0 10px 10px 10px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
}
.left-classify li:hover {
    background: #ecf5ff;
    color: #409eff;
    /* border-radius: 5px; */
}
.left-classify .active {
    background: #ecf5ff;
    color: #409eff;
    /* border-radius: 5px; */
}
.template-list {
    display: flex;
    flex-wrap: wrap;
    /* margin: -10px; */
    padding: 0 10px;
    height: 600px;
    overflow-y: auto;
    /* box-sizing: border-box; */
}
.template-list-item {
    /* margin: 10px; */
    width: calc(100% / 4);

    padding: 10px;
}
.template-list-item-content {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    height: 210px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    margin-bottom: 5px;
}
.w-100 {
    width: 100% !important;
}
.h-100 {
    height: 100% !important;
}
.border-radius-5 {
    border-radius: 5px;
}
.operate-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    padding: 10px;
}
.flex-1 {
    flex: 1;
}
.ml-10 {
    margin-left: 10px;
}
.seltplname {
    margin: 10px;
}
</style>