import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2023-11-02 08:46:51
class Mooc {
// 查询mooc列表
 listMooc(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/ai/mooc/list',
        method: 'post',
        data: data
    })
}

// 查看mooc
 getMooc ( id) {
    return request({
        url: '/ai/mooc/get?id='+id,
        method: 'get'
    })
}


// 新增mooc
 addMooc(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/ai/mooc/add',
        method: 'post',
        data: postdata
    })
}

// 修改mooc
 editMooc(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid,
          dataid:data.id
      }

    return request({
        url: '/ai/mooc/edit',
        method: 'post',
        data: postdata
    })
}

// 删除mooc
 delMooc(ids) {
    return request({
        url: '/ai/mooc/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 moocImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/ai/mooc/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 moocExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/ai/mooc/export?` + parata.join("&") ;


 }

 //下导入模板
  moocimportTemplate() {

     return   `/ai/mooc/importTemplate`   ;

  }

}

const instance = new Mooc();
export default instance;