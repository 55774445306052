var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"关联题库","visible":_vm.open,"width":'70%',"close-on-click-modal":false,"append-to-body":"","before-close":_vm.cancel},on:{"update:visible":function($event){_vm.open=$event}}},[(_vm.queryformData)?_c('VFormRender',{ref:"queryform",staticClass:"query-form-data",attrs:{"formJson":_vm.queryformData}}):_vm._e(),_c('el-form',[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search","size":"mini"},on:{"click":_vm.handleQuery}},[_vm._v("搜索")]),_c('el-button',{attrs:{"icon":"el-icon-refresh","size":"mini"},on:{"click":_vm.resetQuery}},[_vm._v("重置")])],1)],1),_c('jf-table',{attrs:{"query-params":_vm.queryParams,"toolbar-config":_vm.tableToolbar,"columns":_vm.tableColumn,"data":_vm.dataList,"tree-config":_vm.treeConfig,"total":_vm.total,"radio":false,"checkbox":false},on:{"page-change":_vm.handleSizeChange,"change":_vm.tableChange},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0"},on:{"change":function($event){return _vm.handleStatusChange(row)}},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}})]}},{key:"roles",fn:function(ref){
var row = ref.row;
return _vm._l((row.roles),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item.name)+" ")])})}},{key:"toolbar_btn_row",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleQuestion(row)}}},[_vm._v("打开")])]}},_vm._l((_vm.columnSlots),function(item){return {key:item.slots.default,fn:function(ref){
var row = ref.row;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFun(item, row))}})]}}})],null,true)})],1),(_vm.showQuestion)?_c('el-dialog',{attrs:{"title":"选择题目","visible":_vm.showQuestion,"width":'1200px',"close-on-click-modal":false,"append-to-body":"","before-close":_vm.closequestionDia,"custom-class":"scrolldialog"},on:{"update:visible":function($event){_vm.showQuestion=$event}}},[_c('question',{attrs:{"qbid":_vm.qrow.id,"type":_vm.qrow.radio80108,"dialog-type":'association'},on:{"close":_vm.closequestionDia,"radioChangeEvent":_vm.radioChangeEvent}}),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(!_vm.isview)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")]):_vm._e(),_c('el-button',{on:{"click":function($event){_vm.showQuestion = false}}},[_vm._v("返 回")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }