<template>
	<div class="app-container template-record">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
			<el-form-item label="学号" prop="id">
				<el-input v-model="queryParams.id" placeholder="请输入id" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="姓名" prop="name">
				<el-input v-model="queryParams.name" placeholder="请输入姓名" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="性别" prop="no" v-if="!isMerge">
				<el-input v-model="queryParams.no" placeholder="请输入学号" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="教师工号" prop="no" v-if="!isMerge">
				<el-input v-model="queryParams.no" placeholder="请输入教师工号" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="教师姓名" prop="no" v-if="!isMerge">
				<el-input v-model="queryParams.no" placeholder="请输入教师姓名" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="是否线上" prop="no" v-if="!isMerge">
				<el-input v-model="queryParams.no" placeholder="请选择是否线上" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="周几" prop="no" v-if="!isMerge">
				<el-input v-model="queryParams.no" placeholder="请选择周几" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="是否反馈" prop="no" v-if="!isMerge">
				<el-input v-model="queryParams.no" placeholder="请选择是否反馈" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
				<el-button type="text" size="mini" @click="isMerge = !isMerge">{{isMerge?'展开':'合并'}} <i
						:class="isMerge?'el-icon-arrow-down':'el-icon-arrow-up'"></i></el-button>
			</el-form-item>
		</el-form>


		<jf-table :loading="loading" :columns="tableColumn" :data="dataList" :toolbar-config="tableToolbar"
			:query-params="queryParams" @checkbox-change="checkboxChangeEvent" @page-change="handlePageChange"
			:total="total" @change="tableChange">
			<template slot="toolbar_btn_left">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="['/template/templatestudent/add']">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[ '/template/templatestudent/get', '/template/templatestudent/edit', ]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="['/template/templatestudent/del']">删除</el-button>
				<el-button type="warning" size="mini" @click="handleImport(1)">
					<i class="iconfont icon-daoru_o"></i>
					导入
				</el-button>

				<el-button class="btn-orange" size="mini" @click="handleImport(2)">
					<i class="iconfont icon-daochu_o"></i>
					导出
				</el-button>


				<el-dropdown @command="handleCommand">
					<el-button size="mini" type="text" style="margin-left: 10px;">
						更多<i class="el-icon-arrow-down el-icon--right"></i>
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="operationLogOpen">操作记录</el-dropdown-item>

					</el-dropdown-menu>
				</el-dropdown>
			</template>
			<!-- 	<template slot="toolbar_btn_right">
				
			</template> -->
			<template slot="toolbar_btn_row" slot-scope="{ row }">
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>


		</jf-table>

		<!-- 编辑 -->
		<el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
			<el-form ref="form" :model="form" :rules="rules" label-width="100px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="院系名称" prop="name">
							<!-- <el-input v-model="form.name" placeholder="请选择学院" clearable /> -->
							<el-select v-model="form.schoolName" placeholder="请选择学院" style="width: 100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="专业名称" prop="name">
							<!-- <el-input v-model="form.name" placeholder="请选择学院" clearable /> -->
							<el-select v-model="form.speciality" placeholder="请选择专业" style="width: 100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="学号" prop="no">
							<el-input v-model="form.no" placeholder="请输入学号" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="姓名" prop="name">
							<el-input v-model="form.name" placeholder="请输入姓名" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="性别" prop="sex">
							<el-select v-model="form.sex" placeholder="请选择专业" style="width: 100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="预约内容" prop="reservation">
							<el-input type="textarea" :rows="8" placeholder="请输入预约内容" v-model="form.reservation">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="图片上传" prop="reservation">
							<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
								:show-file-list="false" :on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload">
								<img v-if="imageUrl" :src="imageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>

				</el-row>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm">确 定</el-button>
				<el-button @click="cancel">返 回</el-button>
			</div>
		</el-dialog>
		<!-- 导入导出 -->
		<jf-import :show.sync='importOpen' v-if="importOpen" :type="type" :columns="tableColumn"></jf-import>
		<!-- 操作记录 -->
		<jf-operation-log :show.sync='operationLogOpen' v-if="operationLogOpen"></jf-operation-log>



	</div>
</template>
<script>
	import {
		listTemplateStudent,
		getTemplateStudent,
		addTemplateStudent,
		editTemplateStudent,
		delTemplateStudent,
	} from "@/api/template/template_student";
	import jfImport from "@/components/jfimport/jfimport";
	import JfOperationLog from "@/components/jfoperationlog/jfoperationlog";
	import draggable from "vuedraggable";

	export default {
		components: {
			draggable,
			jfImport,
			JfOperationLog
		},
		name: "template-templaterecord-list",
		data() {
			return {
				//操作记录弹窗
				operationLogOpen: false,
				operationLogQueryParams: {
					pageNum: 1,
					pageSize: 10,
					id: undefined,
					name: undefined,
					no: undefined,
				},
				total: 0,
				operationLog: [],
				// 合并
				isMerge: false,
				checked: false,
				importData: [{}, {}],
				setUpData: [{}],
				stepsActive: 1,
				importTitle: '导入',
				importOpen: false,
				tableColumn: [{
						field: "no",
						title: "学号",
						minWidth: 120,
						// sortable: true,
						isCheck: true,
						form_type: '1'
					},
					{
						field: "id",
						title: "序号",
						minWidth: 120,
						// sortable: true,
						isCheck: true,
						form_type: '1'

					},
					{
						field: "name",
						title: "姓名",
						minWidth: 120,
						isCheck: true,
						form_type: '1'
					},
					{
						field: "sex",
						title: "性别",
						minWidth: 120,
						// sortable: true,
						isCheck: true,
						form_type: '2'
					},
					{
						field: "schoolName",
						title: "院系名称",
						minWidth: 120,
						// sortable: true,
						isCheck: true,
						form_type: '2'
					},
					{
						field: "speciality",
						title: "专业",
						minWidth: 120,
						sortable: true,
						isCheck: true,
						form_type: '2'
					},
					{
						field: "reservation",
						title: "预约内容",
						minWidth: 120,
						// sortable: true,
						isCheck: true,
						form_type: '2'
					},
					{
						field: "status",
						title: "预约状态",
						minWidth: 120,
						// sortable: true,
						isCheck: true,
						form_type: '2'
					},

					{
						field: "create_time",
						title: "时间",
						minWidth: 120,
						formatter: "formatDate",
						visible: false,
						// sortable: true,
						isCheck: true,
						form_type: '3'
					},
				],

				tabActive: '1',
				imageUrl: "",
				options: [],
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery,
					},
					slots: {
						buttons: "toolbar_buttons",
					},
				},
				loading: true,
				ids: [],
				single: true,
				multiple: true,
				total: 0,
				dataList: [],
				title: "",
				open: false,
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					id: undefined,
					name: undefined,
					no: undefined,
					total: undefined
				},
				form: {
					radio: '当前导入人'
				},
				rules: {
					name: [{
						required: true,
						message: "姓名不能为空",
						trigger: "blur"
					}],
					no: [{
						required: true,
						message: "学号不能为空",
						trigger: "blur"
					}],
				},
				filterList: [],
				filterKeywords: "", //查询关键字
				type: ''



			};
		},
		created() {
			this.getList();

			this.filterList = JSON.parse(JSON.stringify(this.tableColumn))

		},
		computed: {
			filterData: function() {
				return this.filterList.filter(o => o.title && o.title !== '操作' && o.title.indexOf(this
					.filterKeywords) !== -1)
			},
			checkboxLength: function() {
				let index = 0
				this.filterList.forEach(item => {
					if (item.isCheck) {
						index += 1
					}
				})
				return index
			},
			operationLogColumn: function() {
				if (this.tabActive * 1 === 5) {
					return [{
						field: "name",
						title: "名称",
					}, {
						field: "username",
						title: "时间",
					}, {
						field: "time",
						title: "打印人",
					}, {
						field: "num",
						title: "打印条数",
					}, {
						field: "status",
						title: "打印状态",
					}, {
						field: "",
						title: "操作",
					}]
				}
				return [{
					field: "name",
					title: "文件名称",
				}, {
					field: "username",
					title: "操作人",
				}, {
					field: "time",
					title: "操作时间",
				}, {
					field: "status",
					title: "状态",
				}, {
					field: "",
					title: "操作",
				}]

			},
		},

		methods: {
			tableChange(e) {
				console.log(e)
			},
			
			handleCommand(e) {
				console.log(e)
				this[e] = true
			},
	
		
			//导入
			handleImport(type) {
				this.type = type
				this.importOpen = true
			},
			
		
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			getList() {
				// this.loading = true;
				this.ids = [];
				this.single = true;
				this.multiple = true;

				// listTemplateStudent(this.queryParams).then((response) => {
				this.dataList = [{
					"id": 11,
					"name": "lvhy",
					"no": "1610819040",
					"sex": '男',
					"schoolName": '人工智能学院',
					"speciality": '软件工程',
					"reservation": '2022-07-01 星期一 09：00-10：00',
					"status": '已通过',
					"delete_time": 0,
					"create_by": 1,
					"create_time": 1685033330,
					"update_by": 1,
					"update_time": 1685033556
				}]
				// this.dataList = response.data.list;
				this.queryParams.total = 1
				//  response.data.total;
				this.loading = false;
				// });
			},

			cancel() {
				this.open = false;
				this.reset();
			},

			reset() {
				this.form = {
					name: undefined,
					no: undefined,
				};
				this.resetForm("form");
			},

			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},

			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},

			handleAdd() {
				this.reset();
				this.open = true;
				this.title = "添加";
			},

			handleUpdate(row) {
				this.reset();
				const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
				getTemplateStudent(id).then((response) => {
					this.form = response.data;
					this.open = true;
					this.title = "修改";
				});
			},

			submitForm() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						if (this.form.id != null) {
							editTemplateStudent(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("修改成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						} else {
							addTemplateStudent(this.form).then((response) => {
								if (response.code === 0) {
									this.msgSuccess("新增成功");
									this.open = false;
									setTimeout(() => {
										this.getList();
									}, 300);
								}
							});
						}
					}
				});
			},

			handleDelete(row) {
				const ids = row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
				this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(function() {
						return delTemplateStudent(ids);
					})
					.then(() => {
						setTimeout(() => {
							this.getList();
						}, 300);
						this.msgSuccess("删除成功");
					})
					.catch(function() {});
			},

			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map((item) => item.id);
				this.single = records.length != 1;
				this.multiple = !records.length;
			},

			handlePageChange({
				currentPage,
				pageSize
			}) {
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}
			},

		},
	};
</script>
<style lang="scss">
	.app-container {
		// .vxe-pager {
		// 	text-align: right;
		// 	// margin-top: 20px;
		// }

		.btn-orange {
			background-color: #FF9648;
			color: #FFF;
            border:none
		}


	}

	.font-size-14 {
		font-size: 14px;
	}

	.font-weight-400 {
		font-weight: 400;
	}

	.ml {
		&-10 {
			margin-left: 10px;
		}

		&-20 {
			margin-left: 20px;
		}
	}

	.flex-1 {
		flex: 1
	}

	.my {
		&-10 {
			margin: 10px 0;
		}

		&-20 {
			margin: 20px 0;
		}

		&-50 {
			margin: 50px 0;
		}

	}

	.mb-20 {
		margin-bottom: 20px
	}

	.d-flex {
		display: flex;


	}

	.align-items {
		&-center {
			align-items: center;
		}
	}

	.justify-content {
		&-center {
			justify-content: center;
		}

		&-between {
			justify-content: space-between;
		}

		&-end {
			justify-content: end;
		}
	}





	.template-record .vxe-tools--operate {
		.vxe-button {
			border: none !important;

			&:hover {
				border: none !important;
				box-shadow: none;

			}

			&:focus {
				border: none !important;
				color: #1890ff;
				box-shadow: none;
			}
		}

	}

	// .icon-:before {
	//   font-family: 'Your-Icon-Library';
	//   /* 其他样式设置 */
	// }
	.import-upload {
		width: 100%;
		margin-bottom: 15px;

		&-tips1 {
			// font-size: 12px;
			line-height: 1.5;
			color: #000;
			font-weight: 600;
			margin-bottom: 25px;
			// font-size: 15px;
		}

		&-tips {
			// font-size: 12px;
			line-height: 1.5;
		}

		.el-upload {
			width: 100%;

			.el-upload-dragger {
				width: 100%;
			}
		}

		&-list {
			width: 90%;
			margin: 0 auto;
			// padding-top: 10px;
		}

		&-form {
			display: flex;
			// justify-content: space-between;
			width: 100%;
			padding: 10px 0;

			&-item {
				flex: 1;

				.required-tips {
					font-size: 12px;
					margin-top: 5px;
					color: red;
					display: none;
				}
			}

			&-label {
				margin-bottom: 5px;

				.import-required {
					color: red;
					line-height: 14px;
				}
			}
		}
	}

	.import-success {
		text-align: center;
		padding: 50px 0;

		&-icon {
			font-size: 40px;
			color: #67C23A;
			margin-bottom: 10px;
		}

		&-tips {
			margin-top: 10px;

			.text-primary {
				color: #1890ff;
				cursor: pointer;
			}
		}
	}


	.avatar-uploader {

		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 178px;
		height: 178px;


		&:hover {
			border-color: #409EFF;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 178px;
			height: 178px;
			line-height: 178px;
			text-align: center;
		}

		.avatar {
			width: 178px;
			height: 178px;
			display: block;
		}

	}

	.column {
		margin-top: 10px;
		// border: 1px solid #d9d9d9;
		// border-radius: 6px;
		// padding: 15px;

		.column-list {
			// margin-top: 20px;
			padding: 5px 15px 15px 0;
			max-height: 300px;
			overflow-y: auto;




			&-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-radius: 5px;
				line-height: 1;
				padding: 10px;
				margin-top: 10px;
				border: 1px solid rgba(0, 0, 0, 0.05);
				background: #fff;

				.icon {
					position: relative;
					z-index: 9999;
				}

				// &:hover {
				// 	border: 1px solid #1890ff;
				// 	color: #1890ff;
				// }

			}
		}

	}


	// .custom-dragging {
	// 	background-color:#1890ff;
	// }

	.sortable-ghost {
		background: #fff !important;
		// opacity: 0 !important;

	}

	.sortable-chosen {
		.icon-caiddang {
			color: #1890ff;
		}

		background: #fff !important;
		border: 1px solid #1890ff;
		color: #1890ff;
		opacity: 1 !important;
	}
</style>