import request from '@/utils/request'

// 添加协议
export function addIotProtocol(data) {
    return request({
      url: '/iot/protocol/add',
      method: 'post',
      data: data
    })
}

// 获取协议
export function getIotProtocol(id) {
    return request({
      url: '/iot/protocol/get?id=' + id,
      method: 'get'
    })
}

// 更新协议
export function updateIotProtocol(data) {
    return request({
      url: '/iot/protocol/update',
      method: 'post',
      data: data
    })
  }
  
// 删除协议
export function delIotProtocol(id) {
    return request({
      url: '/iot/protocol/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 协议列表
export function listIotProtocol(query) {
  return request({
    url: '/iot/protocol/list',
    method: 'get',
    params: query
  })
}