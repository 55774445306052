<template>
 <div>
   <el-row>      
     <el-col :span="24" class="card-box" >
        <el-card >          
          <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px"> 
            <el-form-item  prop="name">
              <el-input
                v-model="queryParams.name"
                placeholder="模糊查找属性"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-form>
          <el-row/>
          <vxe-grid            
            ref="xGrid"
            stripe         
            height="500px"  
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="tableAttributeList"
            :column-config="{resizable: false}"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
            <template #toolbar_buttons >   
              <el-button
                style="margin-left: 10px"  
                size="mini"    
                @click="changeAttribute"                         
              >属性配置</el-button>
            </template>

            <!--默认操作按钮-->
            <template #defaultopr="{ row }">
              <div v-if="row.will_value==''||row.will_value==' '"></div>
              <el-button v-else size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
              >撤销
              </el-button>
            </template>

            <!--自定义空数据模板-->
            <template #empty>
                  <span >
                    <p>暂无数据</p>
                  </span>
            </template>

          </vxe-grid>
          <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
          </vxe-pager>
        </el-card>
      </el-col>      
    </el-row>
    <!-- 弹窗获取属性列表 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">          
       
          <vxe-table                    
                    ref="sendattributeTable"
                    height="300"
                    stripe
                    show-overflow
                    highlight-hover-row
                    :lazy="true"
                    :loading="attloading" 
                    :data="attributeList"
                    :column-config="{resizable: false}"
                    :edit-config="{trigger: 'click', mode: 'cell'}"
                    > 
              <vxe-column field="service_id" title="服务ID" />    
              <vxe-column field="service_id" title="服务名称" >
                <template #default="{ row }">
                  <span>{{ serviceFormat(row.service_id) }}</span>
                </template>
              </vxe-column>
              <vxe-column field="name" title="属性名称" />
              <vxe-column field="will_value" title="期望值" :edit-render="{autofocus: '.vxe-input--inner'}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.will_value" type="text"></vxe-input>
                </template>
              </vxe-column>
              <!--自定义空数据模板-->
              <template #empty>
                    <span >
                      <p>暂无数据</p>
                    </span>
              </template>            
            </vxe-table>               
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
 </div>
</template>

<script>
import { listIotProductService, getIotProductService, addIotProductService, updateIotProductService, delIotProductService } from "@/api/iot/productservice";
import { listIotServiceAttribute, getIotServiceAttribute, addIotServiceAttribute, updateIotServiceAttribute, delIotServiceAttribute ,delIotServiceAttributeByCommandId} from "@/api/iot/serviceattribute";
import { listIotServiceCommand, getIotServiceCommand, addIotServiceCommand, updateIotServiceCommand, delIotServiceCommand } from "@/api/iot/servicecommand";
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";
import { addIotDeviceCommand, getIotDeviceCommand, updateIotDeviceCommand, delIotDeviceCommand, listIotDeviceCommand } from "@/api/iot/devicecommand";
import { addIotDeviceAttribute, getIotDeviceAttribute, updateIotDeviceAttribute, delIotDeviceAttribute,delIotDeviceAttributeByCommandId, listIotDeviceAttribute } from "@/api/iot/deviceattribute";

export default {
  props: {
    deviceId: {
      type: String
    }
  },
  data() {   
    return {
      device_id:0,
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      atttableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        }
      },
      // 列
      tableColumn: [  
        {field: 'service_id', title: '服务',formatter:this.rowServiceFormat  },
        {field: 'name', title: '属性' },
        {field: 'auth', title: '访问方式' ,formatter:this.authFormat},
        {field: 'value', title: '上报值' ,formatter:this.TopicFormat },
        {field: 'will_value', title: '期望值' },
        {field: '', title: '操作',resizable:false, width: 60, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      attributeauth:[{key:0,value:"仅可读"},{key:1,value:"仅可写"},{key:2,value:"可读、可写"}],
      // 遮罩层
      loading: false,
      attloading:false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      title: "",
      // 是否显示弹出层
      open: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: "",
        device_id: 0,
        command_id:0
      },      
      tableAttributeList:[],
      // 表单参数
      form: {},
      attributeList:[],
      serviceList:[],
      serviceAttributeList:[],
      // 表单校验
      rules: {        
        top_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ]
      }
    }
  }, 
  created() {   
    if(this.deviceId != undefined){
      this.device_id=parseInt(this.deviceId);
      this.attloading=true;
      this.getServiceAttribute();    
      this.handleQuery();  
    }else{
      this.msgError("获取设备信息失败！");
    }    
  },
  methods: {
    getServiceAttribute(){
      if(this.device_id>0){
        getIotDevice(this.device_id).then(resp=>{  
          var servicequery={
            pageNum: 1,
            pageSize: 10,
            product_id: resp.data.product_id,
          };
          listIotProductService(servicequery).then(response=>{
            this.serviceList=response.data.list;
            this.attloading=false;
            this.getTableAttribute();
            response.data.list.forEach(element =>{
              var attqueryParams={
                pageNum: 1,
                pageSize: 10,
                service_id: element.id,                
                rule_id: 0,
                command_id:0
              };
              listIotServiceAttribute(attqueryParams).then(response2=>{ 
                response2.data.list.forEach(element=>{
                  this.serviceAttributeList.push(element);
                })
              });
            });
            
          });
        });
      }
    },    
    getAttribute(){
      if(this.device_id>0){
        var query={
          pageNum: 1,
          pageSize: 10,
          device_id: this.device_id,
          name:"",
          command_id: 0
        };
        this.attributeList=[];
        listIotDeviceAttribute(query).then(response=>{ 
          this.attributeList=response.data.list;         
          if(this.serviceAttributeList.length>0){
            this.serviceAttributeList.forEach(element=>{
              var sid=element.id;
              var indexarr=this.attributeList.findIndex(element2=>element2.attribute_id==sid);
              if(indexarr >= 0){
                this.attributeList[indexarr].name=element.name;
              }else{
                if(element.id){
                  element.attribute_id=sid;
                  element.device_id=this.device_id;  
                  element.service_id=element.service_id;
                  this.attributeList.push(element);
                }     
              }
            });           
          }
        });    
      }
    },
    getTableAttribute(){
      if(this.device_id>0){
        this.queryParams.device_id=this.device_id;        
        listIotDeviceAttribute(this.queryParams).then(response=>{
          this.tableAttributeList=response.data.list;
        });
      }
    },
    submitForm: function() {
      if(this.device_id >0 && this.attributeList.length>0){
        var query={
              pageNum: 1,
              pageSize: 10,
              device_id: this.device_id,
              name:"",
              command_id: 0
            };
        //获取设备影子属性
        listIotDeviceAttribute(query).then(response=>{
          this.attributeList.forEach(element=>{
            var indexarr=response.data.list.findIndex(element2=>element2.attribute_id==element.attribute_id); 
            if(indexarr>=0){
              response.data.list[indexarr].name=element.name;
              response.data.list[indexarr].will_value=element.will_value;
              updateIotDeviceAttribute(response.data.list[indexarr]).then(resp=>{
                this.getTableAttribute();
              });
            }else{              
              element.value="";
              element.id=undefined;
              addIotDeviceAttribute(element).then(resp=>{
                this.getTableAttribute();
              });;
            }  

          });
                    
        }).then(() =>{
             
          this.open=false;
        });
      }else{
        this.open=false;
      }
    },
    serviceFormat(service_id){
      var titlename="";
      this.serviceList.forEach(element =>{
        if(service_id==element.id)
          titlename=element.service_name;
      });
      return titlename;
    },
    rowServiceFormat({row}){
      var titlename="";
      this.serviceList.forEach(element =>{
        if(row.service_id==element.id)
          titlename=element.service_name;
      });
      return titlename;
    },
    authFormat({row}){
      this.labname="";
      this.attributeauth.forEach(element => {
        if (row.data_type == element.key)
          this.labname= element.value;        
      });
      return this.labname;
    },
    changeAttribute(){
      this.title="属性配置";
      this.open=true;
      this.getAttribute();
    },  
    cancel(){
      this.open=false;
    },
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getTableAttribute();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },    
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getTableAttribute();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认撤销属性为"' + row.name + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
           return getIotDeviceAttribute(ids);
        }).then((res) => {
          res.data.will_value=" ";
          updateIotDeviceAttribute(res.data).then(response=>{
            this.getTableAttribute();
            this.msgSuccess("撤销成功");
          });
        }).catch(function() {});
    }
  }
}
</script>