<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
    >
      <el-form-item label="指标名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入指标名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <!-- <el-form-item label="分值" prop="score">
        <el-input
          v-model="queryParams.score"
          placeholder="请输入分值"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->
      <!-- <el-form-item label="学院名称" prop="college_name">
        <el-input
          v-model="queryParams.college_name"
          placeholder="请输入学院名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->
      <!-- <el-form-item label="学校标准_数值" prop="school_standard">
        <el-input
          v-model="queryParams.school_standard"
          placeholder="请输入学校标准_数值"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->
      <!-- <el-form-item label="学院标准_数值" prop="college_standard">
        <el-input
          v-model="queryParams.college_standard"
          placeholder="请输入学院标准_数值"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->
      <!-- <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="请选择状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="备注" prop="remark">
        <el-input
          v-model="queryParams.remark"
          placeholder="请输入备注"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->

      <el-form-item>
        <el-button
          type="cyan"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      row-id="id"
      id="id_xGrid"
      :tree-config="treeConfig"
      show-overflow
      highlight-hover-row
      :toolbar-config="tableToolbar"
      :loading="loading"
      :columns="tableColumn"
      :data="dataList"
      :keep-source="true"
      :custom-config="{ storage: true }"
      :edit-config="editConfig"
    >
      <template #toolbar_buttons>
        <el-button
          type="primary"
          style="margin-left: 10px"
          icon="el-icon-plus"
          size="mini"
          @click="savecindexdata"
          >保存</el-button
        >
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <template v-if="showformbtn(row)">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-plus"
            @click.stop="handleForm(row)"
          >
            表单
          </el-button>
        </template>

        <el-button
          size="mini"
          type="text"
          icon="el-icon-plus"
          @click.stop="handleAdd(row)"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          >修改
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
        <span>
          <p>暂无数据</p>
        </span>
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button
          v-if="istopnode(row)"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleSend(row)"
          >发送
        </el-button>
      </template>
    </vxe-grid>

    <!-- 添加或修改对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="上级编码" prop="pid">
          <treeselect
            v-model="form.pid"
            :options="treeOptions"
            :normalizer="normalizer"
            :show-count="true"
            placeholder="选择上级"
          />
        </el-form-item>
        <el-form-item label="指标名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入指标名称" />
        </el-form-item>
        <el-form-item label="分值" prop="score">
          <el-input v-model="form.score" placeholder="请输入分值" />
        </el-form-item>
        <el-form-item label="学校标准_频率" prop="school_standard">
          <el-input
            v-model="form.school_standard"
            placeholder="请输入学校标准_频率"
          />
        </el-form-item>
        <el-form-item label="学校标准_单位" prop="school_standard_unit">
          <el-select
            v-model="form.school_standard_unit"
            placeholder="请选择学校标准_单位"
            clearable
            size="small"
          >
            <el-option
              v-for="dict in school_standard_unitOptions"
              :key="dict.key"
              :label="dict.value"
              :value="dict.key"
            />
          </el-select>
        </el-form-item>
        <!--<el-form-item label="学院标准_数值" prop="college_standard">-->
        <!--<el-input v-model="form.college_standard" placeholder="请输入学院标准_数值" />-->

        <!--</el-form-item>-->

        <el-form-item label="状态" prop="status">
          <el-select
            v-model="form.status"
            placeholder="请选择状态"
            clearable
            size="small"
          >
            <el-option
              v-for="dict in statusOptions"
              :key="dict.key"
              :label="dict.value"
              :value="dict.key + ''"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  listIndexs,
  getIndexs,
  delIndexs,
  addIndexs,
  editIndexs,
  savecollegeindexs,
  indexscollegelist,
  sendIndexs,
} from "@/api/growthindicators/gyxy/indexs";
import Treeselect from "@riophae/vue-treeselect";
import { listMenu, treeselect } from "@/api/core/system/menu";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "indexs",
  components: { Treeselect },
  data() {
    return {
      errmsg: "",
      expandRowKeys: [],
      saveKeyvalues: [],
      editConfig: {
        trigger: "click",
        mode: "cell",
        showStatus: true,
        icon: "none",

        activeMethod: this.activeCellMethod,
      },
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      treeConfig: {
        trigger: "row",
        expandRowKeys: [1, 2],
      },
      // 列
      tableColumn: [
        {
          field: "id",
          title: "id",
          minWidth: 50,
          maxWidth: 50,
          fixed: "left",
          visible: false,
        },
        { field: "name", title: "指标名称", minWidth: 220, treeNode: true },
        { field: "score", title: "分值", minWidth: 60 },
        //  {field: 'college_name', title: '学院名称', minWidth: 80   },
        {
          field: "school_standard",
          title: "学校标准",
          children: [
            {
              field: "school_standard_sesson_11",
              width: 50,
              title: "大一上学期",
            },
            {
              field: "school_standard_sesson_12",
              width: 50,
              title: "大一下学期",
            },
            {
              field: "school_standard_sesson_21",
              width: 50,
              title: "大二上学期",
            },
            {
              field: "school_standard_sesson_22",
              width: 50,
              title: "大二下学期",
            },
            {
              field: "school_standard_sesson_31",
              width: 50,
              title: "大三上学期",
            },
            {
              field: "school_standard_sesson_32",
              width: 50,
              title: "大三下学期",
            },
          ],
        },
        {
          field: "college_standard",
          title: "学院标准",
          minWidth: 80,
          children: [
            {
              field: "college_standard_sesson_11",
              width: 100,
              editRender: { name: "input" },
              title: "大一上学期",
            },
            {
              field: "college_standard_sesson_12",
              width: 100,
              editRender: { name: "input" },
              title: "大一下学期",
            },
            {
              field: "college_standard_sesson_21",
              width: 100,
              editRender: { name: "input" },
              title: "大二上学期",
            },
            {
              field: "college_standard_sesson_22",
              width: 100,
              editRender: { name: "input" },
              title: "大二下学期",
            },
            {
              field: "college_standard_sesson_31",
              width: 100,
              editRender: { name: "input" },
              title: "大三上学期",
            },
            {
              field: "college_standard_sesson_32",
              width: 100,
              editRender: { name: "input" },
              title: "大三下学期",
            },
          ],
        },

        // {field: 'status', title: '状态', minWidth: 60   , formatter: this.statusFormat},
        // {field: 'create_time', title: '创建时间', minWidth: 80   , formatter: "formatDate"},
        // {field: 'update_time', title: '更新时间', minWidth: 80   , formatter: "formatDate"},
        // {field: 'remark', title: '备注', minWidth: 80   },

        {
          field: "",
          title: "操作",
          width: 100,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],

      advqueryColumn: [
        {
          field: "qfield",
          title: "查询字段",
          width: 180,
          fixed: "left",
          editRender: {
            name: "select",
            options: [
              { value: "dd", label: "cxvs" },
              { value: "zz", label: "cxvs" },
            ],
          },
        },
        {
          field: "qopr",
          title: "操作符",
          width: 180,
          fixed: "left",
          editRender: { name: "select", options: [] },
        },
        {
          field: "qvalue",
          title: "值",
          width: 180,
          fixed: "left",
          editRender: { name: "input" },
        },
        {
          field: "qlogic",
          title: "逻辑",
          width: 180,
          fixed: "left",
          editRender: { name: "select", options: [] },
        },
        {
          field: "",
          title: "操作",
          resizable: false,
          width: 180,
          fixed: "right",
          align: "center",
          slots: { default: "defaultadvqueryopr" },
        },
      ],
      advqueryopen: false,
      advqueryData: [],
      advqueryToolbar: {
        buttons: [{ code: "myInsert", name: "新增" }],
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      treeOptions: [],
      open: false,
      // 字典
      school_standard_unitOptions: [],
      college_standard_unitOptions: [],
      statusOptions: [],

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 9999,
        opstr: {
          name: "LIKE",
          score: "=",
          college_name: "LIKE",
          school_standard: "=",
          college_standard: "=",
          status: "=",
          remark: "LIKE",
        },
        name: undefined,
        score: undefined,
        college_name: undefined,
        school_standard: undefined,
        college_standard: undefined,
        status: undefined,
        remark: undefined,
      },
      // 表单参数
      form: {
        status: "1",
      },
      // 表单校验
      rules: {
        pid: [{ required: true, message: "上级编码不能为空", trigger: "blur" }],

        name: [
          { required: true, message: "指标名称不能为空", trigger: "blur" },
        ],

        score: [{ required: true, message: "分值不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    var rec = localStorage.getItem("ExpandRecords");
    if (rec) {
      rec = JSON.parse(rec);
      this.expandRowKeys = [];
      this.getallchild(rec);
      this.treeConfig.expandRowKeys = this.expandRowKeys;
    }
    this.getList();

    setTimeout(() => {
      const $grid = this.$refs.xAdvqueryGrid;
      // 异步更新下拉选项
      var ta = [{ value: "", label: "请选择" }];
      for (var i in this.tableColumn) {
        var e = this.tableColumn[i];
        if (e.field != null && e.field != "") {
          ta.push({ value: e.field, label: e.title });
        }
      }
      this.advqueryColumn[0].editRender.options = ta;

      this.advqueryColumn[1].editRender.options = this.defaultQlogic();

      ta = [];
      ta.push({ value: "AND", label: "并且" });
      ta.push({ value: "OR", label: "或者" });
      this.advqueryColumn[3].editRender.options = ta;
    }, 300);
    // 加载字典数据
    this.getDicts("gyxy_indexs_unit").then((response) => {
      this.school_standard_unitOptions = response.data.values || [];
      this.college_standard_unitOptions = response.data.values || [];
    });

    this.getDicts("gyxy_indexsta").then((response) => {
      this.statusOptions = response.data.values || [];
    });
  },
  beforeDestroy() {
    const $grid = this.$refs.xGrid;
    localStorage.setItem(
      "ExpandRecords",
      JSON.stringify($grid.getTreeExpandRecords())
    );
  },
  methods: {
    //保存指标
    savecindexdata() {
      this.saveKeyvalues = [];
      const $grid = this.$refs.xGrid;
      var alldata = $grid.getTableData();
      // console.log("alldata", alldata);
      this.errmsg = "";
      this.getallchildvalues(alldata.tableData);
      if (this.errmsg != "") {
        return this.msgError(this.errmsg);
      }
      var saveKeyvaluesstr = JSON.stringify(this.saveKeyvalues);
      // console.log("saveKeyvalues",  saveKeyvaluesstr);
      savecollegeindexs({ fields: saveKeyvaluesstr }).then(() => {
        this.msgSuccess("保存成功");
        this.getList();
      });
    },
    activeCellMethod({ row }) {
      return !row.children || row.children.length == 0;
    },

    getallchild(rows) {
      for (var j in rows) {
        var row = rows[j];

        if (row.children) {
          this.getallchild(row.children);
        }
        this.expandRowKeys.push(row.id);
      }
    },

    getallchildvalues(rows) {
      if (this.errmsg != "") {
        return false;
      }
      for (var j in rows) {
        var row = rows[j];

        if (row.children) {
          this.getallchildvalues(row.children);
        } else {
          var testfar = ["11", "12", "21", "22", "31", "32"];

          for (var i in testfar) {
            var tk = testfar[i];
            var cs = this.strtoint(row["college_standard_sesson_" + tk]);
            var ss = this.strtoint(row["school_standard_sesson_" + tk]);
            if (ss > 0 && cs > 0 && cs < ss) {
              this.errmsg =
                "指标名称为:" + row["name"] + " 的学院标准不能小于学校标准!";
              return false;
            }
          }

          this.saveKeyvalues.push({
            index_id: row.id + "",
            college_standard_sesson_11: row.college_standard_sesson_11 || "0",
            college_standard_sesson_12: row.college_standard_sesson_12 || "0",
            college_standard_sesson_21: row.college_standard_sesson_21 || "0",
            college_standard_sesson_22: row.college_standard_sesson_22 || "0",
            college_standard_sesson_31: row.college_standard_sesson_31 || "0",
            college_standard_sesson_32: row.college_standard_sesson_32 || "0",
          });
        }
      }
    },
    //转int
    strtoint(v) {
      var rsint = parseInt(v);
      if (isNaN(rsint)) {
        return 0;
      }
      return rsint;
    },

    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },

    handleUpload() {},
    handleExport() {},
    handleAdvquery() {
      this.advqueryopen = true;
    },
    //保存条件
    advquery_save() {
      const $grid = this.$refs.xAdvqueryGrid;
      var alldata = $grid.getTableData();
      console.log("alldata", alldata);
      $grid.reloadData(alldata.fullData); //去掉编辑状态
      //      this.advqueryopen=false;
    },
    handleadvqueryDeleted(row) {
      const $grid = this.$refs.xAdvqueryGrid;

      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          $grid.remove(row);
        })
        .then(() => {
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },
    istopnode(row) {
      return row.pid == 0 && row.issend == "2";
    },
    //发送到下级
    handleSend(row) {
      const ids = row.id;

      this.$confirm("是否确认发送指标到下级填写?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return sendIndexs(ids, "3");
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("发送成功");
        })
        .catch(function () {});
    },

    /** 查询列表 */
    getList() {
      this.loading = true;

      listIndexs(this.queryParams).then((response) => {
        this.loading = false;

        //加载学院指标
        indexscollegelist().then((rs) => {
          // console.log("indexscollegelist rs", rs);
          var newdataArr2 = [].concat(response.data.list);
          var colindexlist = rs.data.list;
          for (var i in newdataArr2) {
            var nitem = newdataArr2[i];
            for (var j in colindexlist) {
              var colitem = colindexlist[j];
              if (colitem.index_id == nitem.id) {
                newdataArr2[i].college_standard_sesson_11 =
                  colitem.college_standard_sesson_11 || "";
                newdataArr2[i].college_standard_sesson_12 =
                  colitem.college_standard_sesson_12 || "";
                newdataArr2[i].college_standard_sesson_21 =
                  colitem.college_standard_sesson_21 || "";
                newdataArr2[i].college_standard_sesson_22 =
                  colitem.college_standard_sesson_22 || "";
                newdataArr2[i].college_standard_sesson_31 =
                  colitem.college_standard_sesson_31 || "";
                newdataArr2[i].college_standard_sesson_32 =
                  colitem.college_standard_sesson_32 || "";
                break;
              }
            }
          }
          var treearr = this.handleTree(newdataArr2, "id", "pid");
          var newtreearr = [];
          for (var i in treearr) {
            if (treearr[i].issend == "2" || treearr[i].issend == "3") {
              newtreearr.push(treearr[i]);
            }
          }
          treearr.length = 0;
          newdataArr2.length = 0;
          colindexlist.length = 0;
          this.dataList = newtreearr;
          // this.dataList = newdataArr2;
          // console.log("newdataArr2", newdataArr2);
        });
      });
    },
    // $comment字典翻译
    // school_standard_unitFormat({cellValue} ) {    return this.selectDictLabel(this.school_standard_unitOptions,""+cellValue); },

    // college_standard_unitFormat({cellValue} ) {    return this.selectDictLabel(this.college_standard_unitOptions,""+cellValue); },

    statusFormat({ cellValue }) {
      return this.selectDictLabel(this.statusOptions, "" + cellValue);
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.advqueryopen = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        pid: undefined,
        name: undefined,
        score: undefined,
        college_id: undefined,
        college_name: undefined,
        school_standard: undefined,
        school_standard_unit: undefined,
        college_standard: undefined,
        college_standard_unit: undefined,
        status: "1",
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    getTreeselect() {
      listIndexs({ pageNum: 1, pageSize: 999 }).then((response) => {
        this.treeOptions = [];
        const opt = { id: 0, name: "主类目", children: [] };
        opt.children = this.handleTree(response.data.list, "id", "pid");
        this.treeOptions.push(opt);
      });
    },

    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset();
      this.open = true;
      this.title = "添加";
      this.getTreeselect();
      if (row != null) {
        this.form.pid = row.id;
      }
    },
    showformbtn(row) {
      return !row.children || row.children.length == 0;
    },
    handleForm(row) {
      const tableId = row.id || this.ids[0];
      this.$router.push({
        path: "/growthindicators/indextableinfo/index",
        query: { index_id: tableId },
      });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.getTreeselect();
      const id = row.id || this.ids;
      getIndexs(id).then((response) => {
        response.data.data.status = "" + response.data.data.status;
        this.form = response.data.data;
        this.open = true;
        this.title = "修改";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            this.form.score = parseFloat(this.form.score);
            editIndexs(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          } else {
            addIndexs(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;

      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delIndexs(ids);
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
  }, //methods结束
};
</script>