import request from '@/utils/request'

// 查询url白名单列表
export function listUrlWhitelist(query) {
  return request({
    url: '/urlwhitelist/list',
    method: 'get',
    params: query
  })
}

// 查询url白名单详细
export function getUrlWhitelist(id) {
  return request({
    url: '/urlwhitelist/get?id=' + id,
    method: 'get'
  })
}

// 新增url白名单
export function addUrlWhitelist(data) {
  return request({
    url: '/urlwhitelist/add',
    method: 'post',
    data: data
  })
}

// 修改url白名单
export function updateUrlWhitelist(data) {
  return request({
    url: '/urlwhitelist/edit',
    method: 'post',
    data: data
  })
}

// 删除url白名单
export function delUrlWhitelist(ids) {
  return request({
    url: '/urlwhitelist/del',
    method: 'post',
    data:{
      ids:ids
    }
  })
}
