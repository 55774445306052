<template>
    <div class="formInfo">
        <el-dialog
            fullscreen
            v-loading="loading"
            :element-loading-text="loadingText"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :title="enginetype + '-' + modelname"
            class="formInfoDialog"
            :close-on-click-modal="false"
            :visible.sync="visible"
        >
            <div class="p-20">
                <div class="ptil">
                    <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-back"
                        circle
                        @click="closeDialog('close')"
                        title="返回"
                    ></el-button>

                    <div class="flex-1 ml-5">
                        {{ enginetype }}-{{ modelname }}
                    </div>
                    <div>
                        <el-button
                            type="primary"
                            size="small"
                            @click="publish('')"
                            :loading="loading"
                            ><i class="fa fa-save"></i> 保存</el-button
                        >
                        <el-button
                            type="primary"
                            size="small"
                            @click="publish('synctable')"
                            :loading="loading"
                            ><i class="fa fa-send"></i> 保存并应用</el-button
                        >
                        <el-button
                            type="primary"
                            size="small"
                            @click="publish('tpl')"
                            :loading="loading"
                            ><i class="fa fa-send"></i> 保存为模版</el-button
                        >
                        <el-button
                            type="danger"
                            size="small"
                            @click="closeDialog('close')"
                            :loading="loading"
                            ><i class="fa fa-close"></i> 关闭</el-button
                        >
                    </div>
                </div>
                <el-tabs v-model="activeName" tab-position="top">
                    <el-tab-pane
                        v-if="resourceData.isload"
                        label="表单配置"
                        name="formsetting"
                    >
                        <!-- <DynamicForm
                        ref="formDesign"
                        :version="version"
                        :dicttypelist="dicttypelist"
                        :conf="resourceData.formData"
                        tabName="formDesign"
                    /> -->
                        <VFormDesigner
                            ref="VFormDesigner"
                            :dicttypelist="dicttypelist"
                            :modleid="mid"
                            :table-list="dbtableList"
                        ></VFormDesigner>
                    </el-tab-pane>
                    <el-tab-pane label="列表配置" name="listConfig">
                        <div class="list-config">
                            <div class="d-flex">
                                <div>前端显示按钮：</div>
                                <div>
                                    <el-checkbox
                                        v-model="checkAll"
                                        :indeterminate="isIndeterminate"
                                        @change="handleCheckAllChange"
                                        >全选</el-checkbox
                                    >
                                    <el-checkbox-group
                                        v-model="checkBtnList"
                                        class="mt-20"
                                        @change="handleCheckedCitiesChange"
                                    >
                                        <el-checkbox
                                            :label="item.value"
                                            v-for="(item, index) in btns"
                                            :key="index"
                                            >{{ item.label }}</el-checkbox
                                        >
                                    </el-checkbox-group>
                                </div>
                            </div>
                            <div class="mt-50">
                                <div>显示样式</div>
                                <div
                                    class="mt-20 d-flex"
                                    style="margin-left: 98px"
                                >
                                    <div
                                        v-for="(item, index) in styleBtns"
                                        :key="index"
                                        class="style-btn-item"
                                        @click="styleRadio = item.value"
                                        :class="{
                                            active: styleRadio === item.value,
                                        }"
                                    >
                                        <img
                                            class="style-btn-item-cover"
                                            :src="item.img"
                                        />

                                        <div class="mt-5 style-btn-item-label">
                                            {{ item.label }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-50 d-flex">
                                <div>高级设置</div>
                                <table class="pagination-table">
                                    <th>分页默认值</th>
                                    <th>分页最大值</th>
                                    <tr>
                                        <!-- <div style="margin-left: 30px">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>分页默认值</div>
                                            </div>
                                            <div
                                                class="d-flex mt-20 align-items-center"
                                            >
                                                <div>分页最大值</div>
                                            </div>
                                        </div> -->
                                        <td>
                                            <el-input
                                                placeholder="请输入"
                                                v-model="pagination.deflimit"
                                                style="
                                                    width: 300px;
                                                    margin-left: 10px;
                                                "
                                            ></el-input>
                                        </td>
                                        <td>
                                            <el-input
                                                placeholder="请输入"
                                                v-model="pagination.maxlimit"
                                                style="
                                                    width: 300px;
                                                    margin-left: 10px;
                                                "
                                            ></el-input>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="字段信息" name="infoPreview">
                        <info-preview
                            :data="infoPreviewList"
                            :tablename="tablename"
                            ref="infoPreview"
                            :resourceData="resourceData"
                            :dicttypelist="dicttypelist"
                            @handleDelete="handlePreviewDelete"
                            @change="tablenameChange"
                        ></info-preview>
                    </el-tab-pane>
                </el-tabs>
            </div>

            <!-- 其他配置弹窗 -->
            <el-drawer
                :title="drawer_title"
                :visible.sync="drawer_extattr"
                direction="rtl"
                size="40%"
                :wrapperClosable="false"
            >
                <el-divider></el-divider>
                <el-form
                    ref="extattrinfoform"
                    :model="extattrinfo"
                    label-width="160px"
                >
                    <el-form-item label="数据验证">
                        <el-input
                            placeholder="请输入正在表达式"
                            v-model="extattrinfo['validate']"
                            class="input-with-select"
                        >
                            <el-dropdown
                                slot="append"
                                @command="handleextattrinfotmp_validate"
                            >
                                <span class="el-dropdown-link">
                                    模版选择<i
                                        class="el-icon-arrow-down el-icon--right"
                                    ></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-for="(
                                            item, index
                                        ) in extattr_tmplist_validate"
                                        :key="index"
                                        :command="item.value"
                                        >{{ item.label }}</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="验证提示信息">
                        <el-input
                            placeholder="请输入提示"
                            v-model="extattrinfo['validate_msg']"
                            class="input-with-select"
                        >
                        </el-input>
                        <div class="sub-title">验证不通过时提示的信息</div>
                    </el-form-item>

                    <el-form-item label="作为左侧快速检索">
                        <el-switch
                            v-model="extattrinfo['isquitfilter']"
                            active-value="1"
                            inactive-value="0"
                        >
                        </el-switch>

                        <el-switch
                            v-model="extattrinfo['ismuiltfilter']"
                            active-value="1"
                            inactive-value="0"
                            active-text="多选"
                        >
                        </el-switch>

                        <div class="sub-title">
                            只对配置了字典类型的字段有效
                        </div>
                    </el-form-item>

                    <el-form-item label="限制上传文件类型">
                        <el-input
                            placeholder="请输入扩展名,多个用','隔开"
                            v-model="extattrinfo['upfileext']"
                            class="input-with-select"
                        >
                            <el-dropdown
                                slot="append"
                                @command="handleextattrinfotmp_upfileext"
                            >
                                <span class="el-dropdown-link">
                                    模版选择<i
                                        class="el-icon-arrow-down el-icon--right"
                                    ></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-for="(
                                            item, index
                                        ) in extattr_tmplist_upfileext"
                                        :key="index"
                                        :command="item.value"
                                        >{{ item.label }}</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="日期类型">
                        <el-input
                            placeholder="请输入选择"
                            v-model="extattrinfo['datetype']"
                            :readonly="true"
                            class="input-with-select"
                        >
                            <el-dropdown
                                slot="append"
                                @command="handleextattrinfotmp_datetype"
                            >
                                <span class="el-dropdown-link">
                                    类型选择<i
                                        class="el-icon-arrow-down el-icon--right"
                                    ></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-for="(
                                            item, index
                                        ) in extattr_tmplist_datetype"
                                        :key="index"
                                        :command="item.value"
                                        >{{ item.label }}</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="日期格式">
                        <el-input
                            placeholder="请输入选择"
                            v-model="extattrinfo['dateformat']"
                            class="input-with-select"
                        >
                            <el-dropdown
                                slot="append"
                                @command="handleextattrinfotmp_dateformat"
                            >
                                <span class="el-dropdown-link">
                                    格式选择<i
                                        class="el-icon-arrow-down el-icon--right"
                                    ></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-for="(
                                            item, index
                                        ) in extattr_tmplist_dateformat"
                                        :key="index"
                                        :command="item.value"
                                        >{{ item.label }}</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-input>
                        <!-- <div class="sub-title"></div> -->
                    </el-form-item>

                    <el-form-item label="文本域行数">
                        <el-input
                            placeholder="请输入数字"
                            type="number"
                            v-model.number="extattrinfo['textarea_row']"
                            class="input-with-select"
                        >
                        </el-input>
                        <div class="sub-title">只对文本域有效</div>
                    </el-form-item>

                    <!-- <el-form-item label="树编码字段">
          
           <el-switch
            v-model="extattrinfo['tree_code']"
            active-value="1"
            inactive-value="0"
        
          >
          </el-switch>
   <div class="sub-title">只对树结构表有效</div>
        </el-form-item> -->

                    <el-form-item label="作为树父级编码字段">
                        <el-switch
                            v-model="extattrinfo['tree_parent_code']"
                            active-value="1"
                            inactive-value="0"
                        >
                        </el-switch>
                        <div class="sub-title">
                            只对树结构表有效,默认id作为树编码字段
                        </div>
                    </el-form-item>

                    <el-form-item label="作为树名称字段">
                        <el-switch
                            v-model="extattrinfo['tree_name']"
                            active-value="1"
                            inactive-value="0"
                        >
                        </el-switch>
                        <div class="sub-title">只对树结构表有效</div>
                    </el-form-item>

                    <el-form-item label="作为附表外键">
                        <el-switch
                            v-model="extattrinfo['foreign_key']"
                            active-value="1"
                            inactive-value="0"
                        >
                        </el-switch>
                        <div class="sub-title">只对附表有效</div>
                    </el-form-item>
                </el-form>

                <div class="dialog-footer">
                    <el-button type="primary" @click="onsaveextattr()"
                        >保 存</el-button
                    >
                </div>
            </el-drawer>
            <!-- 保存模板弹窗 -->
            <el-dialog
                title="模板信息"
                :visible.sync="templateOpen"
                width="30%"
                :before-close="templateClose"
                append-to-body
            >
                <el-form
                    :rules="templateRules"
                    :model="templateFom"
                    ref="templateFom"
                    label-width="80px"
                >
                    <el-form-item
                        label="模板名称"
                        prop="name"
                        style="margin-bottom: 18px"
                    >
                        <el-input
                            placeholder="请填写模板名称"
                            v-model="templateFom.name"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="模板分类"
                        prop="classify"
                        style="margin-bottom: 18px"
                    >
                        <el-select
                            v-model="templateFom.classify"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in tplcatelist"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key"
                            >
                            </el-option>
                        </el-select>

                        <!-- <el-select v-model="value" placeholder="请选择">
                        <el-option label="" value=""> </el-option>
                    </el-select> -->
                    </el-form-item>
                    <el-form-item label="是否共享" prop="is_share">
                        <el-radio v-model="templateFom.is_share" label="1"
                            >是</el-radio
                        >
                        <el-radio v-model="templateFom.is_share" label="0"
                            >否</el-radio
                        >
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="templateClose">取 消</el-button>
                    <el-button type="primary" @click="templateSubmit"
                        >确 定</el-button
                    >
                </span>
            </el-dialog>
        </el-dialog>
    </div>
</template>
<script>
import * as Formx from "@/api/form/model";
import {
    forminfoList,
    forminfoSave,
    forminfoApply,
} from "@/api/form/gen/zorecode";

import { optionselect as getDictOptionselect } from "@/api/core/data/dict/type";
//import basicInfoForm from "./basicInfoForm";
//import genInfoForm from "./genInfoForm";
// import Sortable from "sortablejs";
import DynamicForm from "@/components/form/DynamicForm";
import VFormDesigner from "@/vform/components/form-designer/index.vue";
import { loadExtension } from "@/vform/extension/extension-loader";
import { customFields } from "@/vform/components/form-designer/widget-panel/widgetsConfig.js";
import InfoPreview from "./infoPreview";
export default {
    name: "form-model-formdesign",
    components: { DynamicForm, InfoPreview, VFormDesigner },
    props: {
        // mid: {
        //     type: [String, Number],
        //     default: 0,
        // },
        // enginetypeValue: {
        //     type: [String, Number],
        //     default: "",
        // },
    },
    data() {
        return {
            formJson: {},
            // formIfoOpen:true,
            enginetype: "",
            tplcatelist: [],
            visible: false,
            //全选按钮
            checkAll: false,
            //前端显示按钮
            checkBtnList: [],
            //是否全部选中
            isIndeterminate: false,
            //按钮列表
            btns: [
                { label: "新增", value: "add" },
                { label: "修改", value: "edit" },
                { label: "删除", value: "delete" },
                { label: "导入", value: "import" },
                { label: "导出", value: "export" },
                { label: "查看", value: "get" },
                { label: "筛选", value: "filter" },
                { label: "排序", value: "sort" },
            ],
            //样式
            styleRadio: "1",
            styleBtns: [
                {
                    label: "普通列表",
                    value: "1",
                    img: require("@/assets/image/list-nomal.jpg"),
                },
                {
                    label: "树结构表",
                    value: "2",
                    img: require("@/assets/image/list-tree.jpg"),
                },
                {
                    label: "左树右表",
                    value: "3",
                    img: require("@/assets/image/list-lefttree.jpg"),
                },
            ],
            //模板信息
            templateOpen: false,
            templateFom: {
                name: "",
                is_share: "1",
                classify: "",
            },
            templateRules: {
                name: [
                    {
                        required: true,
                        message: "请输入模板名称",
                        trigger: "blur",
                    },
                ],
                classify: [
                    {
                        required: true,
                        message: "请输入模板分类",
                        trigger: "blur",
                    },
                ],
                is_share: [
                    {
                        required: true,
                        message: "请选择是否共享",
                        trigger: "blur",
                    },
                ],
            },
            modelname: "",
            resourceData: {
                basicSetting: {},
                processData: {},
                formData: { fields: [] },
                advancedSetting: {},
                isload: false,
            }, // 可选择诸如 $route.param，Ajax获取数据等方式自行注入
            dicttypelist: null,

            version: 1,

            extattr_tmplist_dateformat: [
                {
                    label: "xxxx-xx-xx",
                    value: "yyyy-MM-dd",
                },
                {
                    label: "xxxx-x-x",
                    value: "yyyy-M-d",
                },
                {
                    label: "xxxx-xx-xx xx:xx:xx",
                    value: "yyyy-MM-dd HH:mm:ss",
                },
                {
                    label: "xxxx-xx-xx x:x:x",
                    value: "yyyy-MM-dd H:m:s",
                },
            ],

            extattr_tmplist_datetype: [
                {
                    label: "日期",
                    value: "date",
                },
                {
                    label: "时间",
                    value: "time",
                },
                {
                    label: "日期+时间",
                    value: "datetime",
                },
            ],

            extattr_tmplist_datetype: [
                {
                    label: "日期",
                    value: "date",
                },
                {
                    label: "时间",
                    value: "time",
                },
                {
                    label: "日期+时间",
                    value: "datetime",
                },
            ],

            extattr_tmplist_upfileext: [
                {
                    label: "办公文档",
                    value: ".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt",
                },
                {
                    label: "图片文件",
                    value: ".jpg,.jpeg,.png,.bmp,.gif",
                },
            ],
            extattr_tmplist_validate: [
                {
                    label: "手机号",
                    value: "^1\\d{10}$",
                },
                {
                    label: "身份证号",
                    value: "^\\d{17}[0-9Xx]$",
                },
                {
                    label: "电子邮箱",
                    value: "^[\\w!#$%&'*+/=?^_`{|}~-]+(?:.[\\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\\w](?:[\\w-]*[\\w])?.)+[\\w](?:[\\w-]*[\\w])?$",
                },

                {
                    label: "国内电话号码",
                    value: "^\\d{3,4}-d{8}|\\d{4}-{7,8}$",
                },
                {
                    label: "IP地址(v4)",
                    value: "^\\d{1,3}.d{1,3}.\\d{1,3}.\\d{1,3}$",
                },
                {
                    label: "中文",
                    value: "^[\\u4e00-\\u9fa5]+$",
                },
                {
                    label: "网址URL",
                    value: "^[a-zA-z]+://[^s]*$",
                },
            ],
            extattrinfo: {},
            drawer_title: "",
            drawer_extattr: false,
            editConfig: {
                trigger: "click",
                mode: "cell",
                showStatus: true,
                icon: "none",

                activeMethod: this.activeCellMethod,
            },
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                table_name: undefined,
                table_comment: undefined,
            },
            tableToolbar: {
                perfect: true,
                zoom: true,
                custom: true,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            colList: [],
            html_type_list: [
                { label: "文本框", value: "input" },
                { label: "文本域", value: "textarea" },
                { label: "html编辑器", value: "editor" },
                { label: "下拉框", value: "select" },
                { label: "单选框", value: "radio" },
                { label: "复选框", value: "checkbox" },
                { label: "日期控件", value: "datetime" },
                { label: "文件上传", value: "upload" },
                { label: "省市区", value: "city" },
                { label: "会员选择", value: "userselect" },
                { label: "用户选择", value: "adminselect" },
                { label: "树结构上级选择", value: "treeselect" },
                { label: "树结构下拉选择", value: "lefttreeselect" },
            ],
            query_type_list: [
                { label: "=", value: "EQ" },
                { label: "!=", value: "NE" },
                { label: ">", value: "GT" },
                { label: ">=", value: "GTE" },
                { label: "<", value: "LT" },
                { label: "<=", value: "LTE" },
                { label: "LIKE", value: "LIKE" },
                { label: "BETWEEN", value: "BETWEEN" },
            ],
            column_type_list: [
                { label: "字符串(50)", value: "varchar(50)" },
                { label: "字符串(64)", value: "varchar(64)" },
                { label: "字符串(150)", value: "varchar(150)" },
                { label: "字符串(250)", value: "varchar(250)" },
                { label: "字符串(500)", value: "varchar(500)" },
                { label: "文本", value: "text" },
                { label: "大文本", value: "longtext" },
                { label: "整型", value: "bigint" },
                { label: "浮点(2位小数)", value: "float(10,2)" },
                { label: "金额(2位小数)", value: "decimal(18,2)" },
            ],
            // 列
            tableColumn: [
                { type: "seq", title: "序号", width: 60, fixed: "left" },
                {
                    field: "sort",
                    title: "排序",
                    width: 80,
                    titleHelp: { message: "数值越大越靠前" },
                    fixed: "left",
                    editRender: { name: "input" },
                },
                {
                    field: "column_name",
                    title: "字段名",
                    titleHelp: { message: "生成物理字段后不允许修改" },
                    minWidth: 180,
                    fixed: "left",
                    editRender: { name: "input" },
                    slots: {
                        // 使用 JSX 渲染
                        default: ({ row }) => {
                            if (row.is_create == "1") {
                                return [
                                    <span style="color: rgb(24,144,255);font-weight: bold;">
                                        {row.column_name}
                                    </span>,
                                ];
                            }

                            return row.column_name;
                        },
                    },
                },
                {
                    field: "column_comment",
                    title: "描述",
                    minWidth: 180,
                    editRender: { name: "input" },
                },
                {
                    field: "column_type",
                    title: "类型",
                    width: 150,
                    editRender: {},
                    slots: { default: "column_type", edit: "column_type" },
                },
                {
                    field: "is_insert",
                    title: "插入",
                    width: 60,
                    editRender: {},
                    slots: { default: "is_insert", edit: "is_insert" },
                },
                {
                    field: "is_edit",
                    title: "编辑",
                    width: 60,
                    editRender: {},
                    slots: { default: "is_edit", edit: "is_edit" },
                },
                {
                    field: "is_list",
                    title: "列表",
                    width: 60,
                    editRender: {},
                    slots: { default: "is_list", edit: "is_list" },
                },
                {
                    field: "is_query",
                    title: "查询",
                    width: 60,
                    editRender: {},
                    slots: { default: "is_query", edit: "is_query" },
                },
                {
                    field: "query_type",
                    title: "查询方式",
                    width: 120,
                    editRender: {},
                    slots: { default: "query_type", edit: "query_type" },
                },
                {
                    field: "is_required",
                    title: "必填",
                    width: 60,
                    editRender: {},
                    slots: { default: "is_required", edit: "is_required" },
                },
                {
                    field: "html_type",
                    title: "输入类型",
                    width: 160,
                    editRender: {},
                    slots: { default: "html_type", edit: "html_type" },
                },
                {
                    field: "dict_type",
                    title: "字典类型",
                    width: 160,
                    editRender: {},
                    slots: { default: "dict_type", edit: "dict_type" },
                },

                {
                    field: "dict_type",
                    title: "其他配置",
                    width: 130,

                    slots: { default: "extattr" },
                },

                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    minWidth: 90,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],

            defsort: { order: "ascending", prop: "sort" },
            // 选中选项卡的 name
            activeName: "formsetting",
            // 表格的高度
            tableHeight: document.documentElement.scrollHeight - 245 + "px",
            // 表列信息
            cloumns: [],
            // 字典信息
            dictOptions: [],
            form_typeOptions: [],
            // 表详细信息
            info: {},
            multiple: false,
            loading: false,
            mid: 0,
            drawer_extattr_field: "",
            currowindex: -1,
            infoPreviewList: [],
            tablename: "",
            update_time: 0,
            loadingText: "处理中",
            oldEnginetype: "",
            dbtableList: [],
            pagination: {
                maxlimit: 5000, //一次最大返回行数
                deflimit: 20, //默认返回行数
            },
        };
    },
    // beforeCreate() {
    //     const  mid  = this.mid;
    //     // .$route.query
    //     if (mid) {
    //         // this.mid = mid;

    //         /** 查询字典下拉列表 */
    //         getDictOptionselect().then((response) => {
    //             this.dicttypelist = response.data;
    //         });

    //         // this.getDicts("gen_form_type").then((response) => {
    //         //   this.form_typeOptions = response.data.values || [];
    //         // });
    //     }
    // },
    watch: {
        activeName(val) {
            if (val === "infoPreview") {
                // const getCmpData = (name) => this.$refs[name].getData();
                // // basicSetting  formDesign processDesign 返回的是Promise 因为要做校验
                // // advancedSetting返回的就是值
                // // const p1 = getCmpData('basicSetting')
                // const p = getCmpData("formDesign");
                // Promise.all([p])
                //     .then((res) => {
                //         var formData = res[0].formData;
                //         console.log(formData);
                //         this.infoPreviewList = formData.fields;
                //     })
                //     .catch((err) => {
                //         err.msg && this.$message.warning(err.msg);
                //     });
            }
        },
    },
    created() {
        //判断是否已经有数据，有则不再加载
        console.log(customFields, "customFields");
        if (!customFields || !customFields.length) {
            loadExtension();
        }
        // if(){

        // }
    },

    methods: {
        getTableData() {
            Formx.dbtablecolumns(this.tablename).then((res) => {
                if (res.code === 0) {
                    this.dbtableList = res.data.list;
                }
            });
        },
        //修改表名
        tablenameChange(e) {
            this.tablename = e;
            console.log(this.mid);

            this.publish("", "editName");
        },
        resetData() {
            this.resourceData = {
                basicSetting: {},
                processData: {},
                formData: { fields: [] },
                advancedSetting: {},
                isload: false,
            };
            this.loadingText = "加载中";
            this.$refs["VFormDesigner"].$refs["toolbarRef"].clearFormWidget();
            this.visible = false;
        },
        closeDialog(type) {
            var designform = this.$refs["VFormDesigner"];

            if (type !== "close") {
                this.resetData();
                return false;
            }
            this.$confirm("离开此页面您的修改将会丢失, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    //清空弹窗
                    this.resetData();
                })
                .catch(() => {});
        },
        getInit({ mid, enginetype, widgetList }) {
            localStorage.removeItem('v_form_locale')
            //
            this.mid = mid;
            this.oldEnginetype = enginetype;

            getDictOptionselect().then((response) => {
                this.dicttypelist = response.data;
            });
            this.getDicts("form_tpl_cate").then((response) => {
                this.tplcatelist = response.data.values || [];
            });
            if (enginetype * 1 === 1) {
                this.enginetype = "零代码开发";
            } else if (enginetype * 1 === 2) {
                this.enginetype = "静态结构开发";
            } else if (enginetype * 1 === 3) {
                this.enginetype = "动态结构开发";
            }

            this.visible = true;
            this.loading = true;
            this.loadingText = "加载中";
            setTimeout(() => {
                this.loadingText = "数据较多，正在努力加载，请静心等待";
            }, 1000);
            // 获取表详细信息
            Formx.FormxGet(mid)
                .then((res) => {
                    this.modelname = res.data.row.module_name;
                    this.tablename = res.data.row.tablename;
                    this.getTableData();
                    this.update_time = res.data.row.update_time;
                    if (
                        res.data.row.formresource != null &&
                        res.data.row.formresource != ""
                    ) {
                        this.resourceData.formData = JSON.parse(
                            res.data.row.formresource
                        );
                        if (this.resourceData.formData["listconf"]) {
                            this.checkBtnList =
                                this.resourceData.formData["listconf"][
                                    "showbtns"
                                ];
                            this.styleRadio =
                                this.resourceData.formData["listconf"][
                                    "liststyle"
                                ];
                        }

                        this.handleCheckedCitiesChange();
                    }
                })
                .then(() => {
                    this.resourceData.isload = true;

                    this.$nextTick((_) => {
                        var designform = this.$refs["VFormDesigner"];
                        if (
                            (!this.resourceData.formData.widgetList ||
                                this.resourceData.formData.widgetList.length ===
                                    0) &&
                            widgetList &&
                            widgetList.length
                        ) {
                            this.resourceData.formData.widgetList = widgetList;
                        }
                        console.log(this.resourceData.formData.widgetList,this.resourceData)
                        designform.setFormJson(this.resourceData.formData);
                        this.loading = false;
                        this.loadingText = "处理中";

                        let list = designform.getFieldWidgets(); //所有的字段组件
                        this.infoPreviewList = list.map((o) => o.field);
                    });
                })
                .catch((_) => {
                    console.log("加载表单出错");
                });
        },
        handlePreviewDelete(row) {
            this.infoPreviewList = this.infoPreviewList.filter(
                (o) => o.id !== row.id
            );

            this.$refs.VFormDesigner.toDeleteWidgetRef(row);
        },
        templateClose() {
            this.templateOpen = false;
            this.templateFom = {
                name: "",
                is_share: "1",
                classify: "",
            };
        },
        //保存模板
        templateSubmit() {
            this.$refs.templateFom.validate((valid) => {
                if (valid) {
                    var designform = this.$refs["VFormDesigner"];
                    var tpldata = {
                        name: this.templateFom["name"],
                        isshare: this.templateFom["is_share"],
                        cate: this.templateFom["classify"],
                    };
                    var formData = designform.getFormJson();
                    formData["listconf"] = {
                        showbtns: this.checkBtnList,
                    };
                    formData["FieldWidgets"] = designform.getFieldWidgets();

                    tpldata["formresource"] = JSON.stringify(formData);
                    //console.log("formDesign res", res);
                    Formx.FormxSaveformtpl({ row: JSON.stringify(tpldata) })
                        .then((res) => {
                            this.msgSuccess("保存成功");
                            this.templateClose();
                        })
                        .catch((_) => {});

                    // this.$refs["formDesign"].getData().then((res) => {

                    // });
                    //
                }
            });
        },
        handleCheckAllChange() {
            if (this.checkBtnList.length === this.btns.length) {
                this.checkBtnList = [];
                this.isIndeterminate = false;
                return;
            }
            this.checkBtnList = this.btns.map((o) => o.value);
            // this.isIndeterminate=
        },
        handleCheckedCitiesChange() {
            this.isIndeterminate = this.checkBtnList.length > 0 ? true : false;
            this.checkAll = this.checkBtnList.length === this.btns.length;
        },
        publish(option, name) {
            if (option == "tpl") {
                this.templateOpen = true;

                return false;
            }

            this.loading = true;
            this.loadingText = "处理中";
            var designform = this.$refs["VFormDesigner"];
            // console.log("getFieldWidgets", designform.getFieldWidgets());
            var formData = designform.getFormJson();
            formData["FieldWidgets"] = designform.getFieldWidgets();
            let sysfieldshow = this.$refs.infoPreview.dataList
                .filter((o) => o.is_default && o.isShow)
                .map((o) => o.options.name);
            let dbselectfield = this.$refs.infoPreview.dataList
                .filter((o) => o.isReturn)
                .map((o) => o.options.name);
            let sysf_list=[]
            let    sysf_edit = [];
            this.$refs.infoPreview.dataList.forEach((o) => {
          
                if (
                    o.is_select &&
                    o.options &&
                    o.options.showpos &&
                    o.options.showpos.length 
                ) {
                    if(
                    o.options.showpos.indexOf("列表")){
                        sysf_list.push({ name: o.name, label: o.label });
                    }
                    if(
                    o.options.showpos.indexOf("修改")){
                        sysf_edit.push({ name: o.name, label: o.label });
                    }
                }
                
            });
         
            formData["listconf"] = {
                showbtns: this.checkBtnList,
                liststyle: this.styleRadio, //列表样式
                sysfieldshow: sysfieldshow,
                dbselectfield: dbselectfield,
                sysf_list:sysf_list,
                sysf_edit:sysf_edit,
                pagination: this.pagination,

            };

         
            const param = {
                id: this.mid,
                option: option,
                row: JSON.stringify({
                    formresource: JSON.stringify(formData),
                    update_time: this.update_time,
                    tablename: this.tablename,
                }),
                // listconf:this.$refs.infoPreview.defaultFiled
            };

            Formx.FormxSave(param)
                .then((res) => {
                    this.msgSuccess("操作成功!");

                    if (name !== "editName") {
                        this.closeDialog();
                    } else {
                        this.getInit({
                            mid: this.mid,
                            enginetype: this.oldEnginetype,
                        });
                    }

                    this.loading = false;
                    window.localStorage.removeItem(
                        "widget__list__backup_" + this.mid
                    );
                    window.localStorage.removeItem(
                        "form__config__backup_" + this.mid
                    );
                })
                .catch(() => {
                    if (name === "editName") {
                        this.getInit({
                            mid: this.mid,
                            enginetype: this.oldEnginetype,
                        });
                    }
                    this.loading = false;
                });
        },
        handleextattrinfotmp_dateformat(cmd) {
            if (this.drawer_extattr_field == "") {
                return false;
            }
            this.$set(this.extattrinfo, "dateformat", cmd);
        },
        handleextattrinfotmp_datetype(cmd) {
            if (this.drawer_extattr_field == "") {
                return false;
            }
            this.$set(this.extattrinfo, "datetype", cmd);
        },

        handleextattrinfotmp_upfileext(cmd) {
            if (this.drawer_extattr_field == "") {
                return false;
            }
            this.$set(this.extattrinfo, "upfileext", cmd);
        },

        handleextattrinfotmp_validate(cmd) {
            if (this.drawer_extattr_field == "") {
                return false;
            }
            this.$set(this.extattrinfo, "validate", cmd);
        },

        //打开其他设置
        onopenextattr(row) {
            if (row["unsave"] != null) {
                this.msgError("新添加的字段请保存后再进行此操作!");
                return false;
            }
            const xGrid = this.$refs.xGrid;
            var rowindex = xGrid.getRowIndex(row);
            this.currowindex = rowindex;
            // console.log("row rowindex", row, rowindex);
            // return false;
            this.drawer_extattr = true;
            this.drawer_title =
                row.column_name + "[" + row.column_comment + "]" + " 其他配置";
            this.drawer_extattr_field = row.column_name;
            this.extattrinfo =
                (this.colList[rowindex].extattr &&
                    JSON.parse(this.colList[rowindex].extattr)) ||
                {};
        },
        //保存其他设置
        onsaveextattr() {
            var rowindex = this.currowindex;
            this.colList[rowindex].extattr = JSON.stringify(this.extattrinfo);
            this.drawer_extattr = false;
            //  this.msgSuccess("")
        },
        activeCellMethod(row) {
            if (
                row.columnIndex == 2 &&
                row.data[row.rowIndex] &&
                row.data[row.rowIndex].is_create == "1"
            ) {
                return false;
            }
            return true;
        },
        editDisabledEvent({ row, column }) {
            //      console.log('禁止编辑')
            this.msgError("该列已生成物理字段,无法修改");
        },

        handleQuery() {
            this.loading = true;
            const { mid } = this;
            forminfoList({ mid: mid }).then((res) => {
                this.colList = res.data.list;
                this.loading = false;
            });
        },
        checkfields() {
            const $grid = this.$refs.xGrid;
            var alldata = $grid.getTableData();
            var tableData = alldata.tableData;

            for (var i = 0; i < tableData.length; i++) {
                if (!/^[\w]+$/.test(alldata.tableData[i].column_name)) {
                    this.msgError(
                        "第" +
                            (i + 1) +
                            "行有错误:字段名只允许字母,数字和下划线"
                    );
                    return false;
                }

                if (alldata.tableData[i].column_comment == "") {
                    this.msgError("第" + (i + 1) + "行有错误:字段描述不能为空");
                    return false;
                }

                //检测 select  radio checkbox 是否正确配置了数据字典
                if (
                    alldata.tableData[i].html_type == "select" ||
                    alldata.tableData[i].html_type == "radio" ||
                    alldata.tableData[i].html_type == "checkbox"
                ) {
                    if (alldata.tableData[i].dict_type == "") {
                        this.msgError(
                            "第" +
                                (i + 1) +
                                "行有错误:该显示类型必须配置字典类型!"
                        );

                        return false;
                    }
                }

                for (var j = i + 1; j < tableData.length; j++) {
                    if (
                        alldata.tableData[i].column_name ==
                        alldata.tableData[j].column_name
                    ) {
                        this.msgError(
                            "第" +
                                (j + 1) +
                                "行的字段名" +
                                alldata.tableData[j].column_name +
                                "与现有字段重复,请检查"
                        );
                        return false;
                    }
                }
            }
            return true;
        },
        applycolumn() {
            if (!this.checkfields()) {
                return false;
            }
            this.loading = true;
            forminfoApply({
                mid: this.mid,
            })
                .then((response) => {
                    this.loading = false;
                    this.msgSuccess("操作成功");
                    setTimeout(() => {
                        this.handleQuery();
                    }, 300);
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        savecolumn() {
            if (!this.checkfields()) {
                return false;
            }
            const $grid = this.$refs.xGrid;
            var alldata = $grid.getTableData();
            this.loading = true;
            // console.log("alldata.tableData",alldata.tableData);
            // return false;
            forminfoSave({
                mid: this.mid,
                fields: JSON.stringify(alldata.tableData),
            })
                .then((response) => {
                    this.loading = false;
                    this.msgSuccess("操作成功");
                    setTimeout(() => {
                        this.handleQuery();
                    }, 300);
                })
                .catch(() => {
                    this.loading = false;
                });
            //      console.log('alldata', alldata)
        },
        formathtml_type(value) {
            for (var i in this.html_type_list) {
                var item = this.html_type_list[i];
                if (item.value == value) {
                    return item.label;
                }
            }
        },

        handleDelete(row) {
            const ids = row.id || this.ids;
            const $grid = this.$refs.xGrid;
            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    $grid.remove(row);
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        addone() {
            const $grid = this.$refs.xGrid;
            var row = $grid.insertAt(
                {
                    column_name: "field1",
                    column_comment: "字段1",
                    column_type: "varchar(50)",
                    is_insert: "1",
                    is_edit: "1",
                    is_list: "1",
                    is_query: "0",
                    query_type: "=",
                    is_required: "0",
                    html_type: "input",
                    dict_type: "",
                    sort: 1,
                    extattr: "",
                    unsave: true,
                },
                -1
            );

            setTimeout(() => {
                window.scroll({ top: 99999, left: 0, behavior: "smooth" });
            }, 300);
        },
        adddef() {
            const $grid = this.$refs.xGrid;
            var alldata = $grid.getTableData();
            var tableData = alldata.tableData;

            for (var i = 0; i < tableData.length; i++) {
                if (tableData[i].column_name == "dept_id") {
                    this.msgError("默认字段已添加,请勿重新添加!");
                    return false;
                }
            }
            var dfcol = [
                {
                    column_name: "create_time",
                    column_comment: "创建时间",
                    column_type: "bigint",
                    is_query: "0",
                    is_list: "1",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "datetime",
                },
                {
                    column_name: "update_time",
                    column_comment: "更新时间",
                    column_type: "bigint",
                    is_query: "0",
                    is_list: "1",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "datetime",
                },
                {
                    column_name: "create_by",
                    column_comment: "创建者",
                    column_type: "bigint",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "input",
                },
                {
                    column_name: "create_by_username",
                    column_comment: "创建者名称",
                    column_type: "bigint",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "input",
                },

                {
                    column_name: "update_by",
                    column_comment: "更新者",
                    column_type: "bigint",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "input",
                },
                {
                    column_name: "update_by_username",
                    column_comment: "更新者名称",
                    column_type: "bigint",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "input",
                },
                {
                    column_name: "delete_time",
                    column_comment: "删除时间",
                    column_type: "bigint",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "datetime",
                },

                {
                    column_name: "remark",
                    column_comment: "备注",
                    column_type: "varchar(250)",
                    is_query: "0",
                    is_list: "1",
                    is_insert: "1",
                    is_edit: "1",
                },

                {
                    column_name: "dept_id",
                    column_comment: "所属部门",
                    column_type: "bigint",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                },

                {
                    column_name: "dept_name",
                    column_comment: "所属部门名称",
                    column_type: "varchar(250)",
                    is_query: "1",
                    is_list: "1",
                    is_insert: "0",
                    is_edit: "0",
                },

                {
                    column_name: "tenant_id",
                    column_comment: "租户id",
                    column_type: "bigint",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                },
            ];

            var sort = 99;
            for (var i in dfcol) {
                var citem = dfcol[i];
                var row = $grid.insertAt(
                    {
                        ...citem,

                        query_type: "=",

                        dict_type: "",
                        sort: sort--,
                        unsave: true,
                    },
                    -1
                );
            }
        },
        addBpmfield() {
            const $grid = this.$refs.xGrid;
            var alldata = $grid.getTableData();
            var tableData = alldata.tableData;

            for (var i = 0; i < tableData.length; i++) {
                if (tableData[i].column_name == "flow_inst_id") {
                    this.msgError("流程字段已添加,请勿重新添加!");
                    return false;
                }
            }
            var dfcol = [
                {
                    column_name: "flow_inst_id",
                    column_comment: "流程实例id",
                    column_type: "int",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "input",
                },
                {
                    column_name: "flow_inst_status",
                    column_comment: "流程实例状态",
                    column_type: "int",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "input",
                },
                {
                    column_name: "flow_inst_title",
                    column_comment: "流程实例标题",
                    column_type: "varchar(250)",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "input",
                },

                {
                    column_name: "flow_inst_node",
                    column_comment: "流程实例节点",
                    column_type: "varchar(250)",
                    is_query: "0",
                    is_list: "0",
                    is_insert: "0",
                    is_edit: "0",
                    html_type: "input",
                },
            ];

            var sort = 990;
            for (var i in dfcol) {
                var citem = dfcol[i];
                var row = $grid.insertAt(
                    {
                        ...citem,

                        query_type: "=",

                        dict_type: "",
                        sort: sort--,
                        unsave: true,
                    },
                    -1
                );
            }

            this.msgSuccess("添加成功,请注意保存并应用!");
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.table_id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },
        nexttab() {
            if (this.activeName == "basic") {
                this.activeName = "cloum";
            } else if (this.activeName == "cloum") {
                this.activeName = "genInfo";
            }
        },
        /** 提交按钮 */
        submitForm() {
            const basicForm = this.$refs.basicInfo.$refs.basicInfoForm;
            const genForm = this.$refs.genInfo.$refs.genInfoForm;
            Promise.all([basicForm, genForm].map(this.getFormPromise)).then(
                (res) => {
                    const validateResult = res.every((item) => !!item);
                    if (validateResult) {
                        const genTable = Object.assign(
                            {},
                            basicForm.model,
                            genForm.model
                        );
                        genTable.columns = this.cloumns;
                        //          genTable.options = {
                        //            tree_code: genTable.tree_code,
                        //            tree_name: genTable.tree_name,
                        //            tree_parent_code: genTable.tree_parent_code
                        //          };

                        genTable.options = JSON.stringify(genTable.options);

                        updateGenTable(genTable).then((res) => {
                            this.msgSuccess(res.msg);
                            if (res.code === 0) {
                                this.close();
                            }
                        });
                    } else {
                        this.msgError("表单校验未通过，请重新检查提交内容");
                    }
                }
            );
        },
        //子组件修改info的column
        setInfoColumn(column) {
            this.info.column = column;
        },
        getFormPromise(form) {
            return new Promise((resolve) => {
                form.validate((res) => {
                    resolve(res);
                });
            });
        },
        /** 关闭按钮 */
        close() {
            this.$store.dispatch("tagsView/delView", this.$route);
            this.$router.push({
                path: "/fastdev/lesscodedev/gencode",
                query: { t: Date.now() },
            });
        },
    },
    mounted() {
        //    const el = this.$refs.dragTable.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
        //    const sortable = Sortable.create(el, {
        //      handle: ".allowDrag",
        //      onEnd: evt => {
        //              var tempcloumns =this.cloumns.concat();
        //            const targetRow = tempcloumns.splice(evt.oldIndex, 1)[0];
        //            tempcloumns.splice(evt.newIndex, 0, targetRow);
        //            for (let index in tempcloumns) {
        //              tempcloumns[index].sort = parseInt(index) + 1;
        //
        //              for (var i in this.cloumns) {
        //                if (this.cloumns[i].column_name == tempcloumns[index].column_name) {
        //                  this.cloumns[i].sort = tempcloumns[index].sort
        //                  break
        //                }
        //
        //              }
        //            }
        //    console.log(this.cloumns, tempcloumns, evt.oldIndex, evt.newIndex)
        //      }
        //    });
    },
};
</script>
<style lang="scss" scoped>
.formInfo {
    .formInfoDialog {
    }
    .design-tab > .el-tabs__header {
        margin: 0;
    }
}
.pagination-table {
    margin-left: 40px;
    border-collapse: collapse;
    th,
    td {
        border: 1px solid rgb(232, 236, 234);
        height: 48px;
        text-align: center;
        line-height: 48px;
        padding: 0 20px;
    }
}
.formInfoDialog ::v-deep .el-dialog__header {
    display: none;
}
.formInfoDialog ::v-deep .el-dialog__body {
    padding: 0;
}
::v-deep .el-drawer__body {
    flex: 1;
    overflow: auto;
    padding: 20px;
    padding-top: 0;
}

::v-deep .el-drawer__header {
    margin-bottom: 0;
}
::v-deep .sub-title {
    color: #fc6363;
}
::v-deep .el-form-item {
    margin-bottom: 8px;
}
.list-config {
    height: 560px;
}
.design-btn {
    // right: 20px;
    // position: absolute;
    // top: 6px;
    // z-index: 500;
}
.style-btn-item {
    width: 250px;
    height: 173px;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    margin-right: 15px;
    border: 1px solid#DCDFE6;
    &-cover {
        width: 100%;
        height: 100%;
        border-radius: 15px;

        object-fit: cover;
    }
    &:hover {
        border: 1px solid #1890ff;
    }
    &.active {
        border: 1px solid #1890ff;
    }
    &-label {
        position: absolute;
        bottom: 10px;
        text-align: center;
        width: 100%;
    }
}
.ptil {
    // text-align: center;
    font-size: 18px;
    border-bottom: #9e9e9e38 solid 1px;
    padding-bottom: 10px;
    margin-top: -7px;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>