<template>
    <div class="app-container">
        <el-row :gutter="20">
            <!--机构数据-->
            <el-col :span="4" :xs="24">
                <div class="head-container">
                    <el-input
                        v-model="channel_name"
                        placeholder="请输入栏目名称"
                        clearable
                        size="small"
                        prefix-icon="el-icon-search"
                        style="margin-bottom: 20px"
                    />
                </div>
                <div
                    class="head-container"
                    style="overflow-y: auto; height: 700px"
                >
                    <el-tree
                        :data="channelTree"
                        :props="defaultProps"
                        :expand-on-click-node="false"
                        :filter-node-method="filterNode"
                        ref="tree"
                        default-expand-all
                        @node-click="handleNodeClick"
                    />
                </div>
            </el-col>
            <!--文章数据-->
            <el-col :span="20" :xs="24">
                <el-form
                    :model="queryParams"
                    ref="queryForm"
                    :inline="true"
                    label-width="68px"
                    label-position="left"
                >
                    <el-form-item label="标题" prop="title">
                        <el-input
                            v-model="queryParams.title"
                            placeholder="请输入标题"
                            clearable
                            size="small"
                            style="width: 250px"
                            @keyup.enter.native="handleQuery"
                        />
                    </el-form-item>
                    <el-form-item label="显示状态" prop="status">
                        <el-select
                            v-model="queryParams.status"
                            placeholder="请选择状态"
                            clearable
                            size="small"
                        >
                            <el-option
                                v-for="dict in statusOptions"
                                :key="dict.key"
                                :label="dict.value"
                                :value="dict.key"
                            />
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            size="mini"
                            @click="handleQuery"
                            >搜索</el-button
                        >
                        <el-button
                            icon="el-icon-refresh"
                            size="mini"
                            @click="resetQuery"
                            >重置</el-button
                        >
                    </el-form-item>
                </el-form>

                <!-- <vxe-grid
                    resizable
                    ref="xGrid"
                    stripe
                    show-overflow
                    highlight-hover-row
                    :loading="loading"
                    :toolbar-config="tableToolbar"
                    :columns="tableColumn"
                    :data="archivesList"
                    @checkbox-change="checkboxChangeEvent"
                    @checkbox-all="checkboxChangeEvent"
                >
                    <template #toolbar_buttons>
                        <el-button
                            style="margin-left: 10px"
                            type="primary"
                            icon="el-icon-plus"
                            size="mini"
                            @click="handleAdd"
                            >新增</el-button
                        >
                        <el-button
                            type="success"
                            icon="el-icon-edit"
                            size="mini"
                            :disabled="single"
                            @click="handleUpdate"
                            >修改</el-button
                        >
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="mini"
                            :disabled="multiple"
                            @click="handleDelete"
                            >删除</el-button
                        >
                    </template>

                    <template #image="{ row }">
                        <img v-if="row.image" :src="row.image" class="image" />
                    </template> -->

                <!--默认操作按钮-->
                <!-- <template #defaultopr="{ row }">
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(row)"
                            >修改
                        </el-button>
                        <el-button
                            v-if="row.id !== 1"
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="handleDelete(row)"
                            >删除
                        </el-button>
                    </template> -->

                <!--自定义空数据模板-->
                <!-- <template #empty>
                        <span>
                            <p>暂无数据</p>
                        </span>
                    </template>
                </vxe-grid>
                <vxe-pager
                    background
                    size="small"
                    :loading="loading"
                    :current-page="queryParams.pageNum"
                    :page-size="queryParams.pageSize"
                    :total="total"
                    @page-change="handleSizeChange"
                >
                </vxe-pager> -->
                <jf-table
                    :loading="loading"
                    :columns="tableColumn"
                    :data="archivesList"
                    :toolbar-config="tableToolbar"
                    :query-params="queryParams"
                    @checkbox-change="checkboxChangeEvent"
                    @page-change="handleSizeChange"
                    :total="total"
                    @change="tableChange"
                >
                    <template slot="toolbar_btn_left">
                        <el-button
                            type="primary"
                            icon="el-icon-plus"
                            size="mini"
                            @click="handleAdd"
                            >新增</el-button
                        >
                        <el-button
                            type="success"
                            icon="el-icon-edit"
                            size="mini"
                            :disabled="single"
                            @click="handleUpdate"
                            >修改</el-button
                        >
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="mini"
                            :disabled="multiple"
                            @click="handleDelete"
                            >删除</el-button
                        >
                    </template>
                    <template slot="image" slot-scope="{ row }">
                        <img
                            v-if="row.image"
                            :src="row.image"
                            class="archives-image"
                        />
                    </template>
                    <template slot="channel_id" slot-scope="{ row }">
                        {{ channelFormat({ row }) }}
                    </template>

                    <template slot="toolbar_btn_row" slot-scope="{ row }">
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(row)"
                            >修改
                        </el-button>
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-menu"
                            @click="handleQrCode(row)"
                            >生成二维码
                        </el-button>
                        <el-button
                            v-if="row.id !== 1"
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="handleDelete(row)"
                            >删除
                        </el-button>
                    </template>
                </jf-table>
            </el-col>
        </el-row>

        <!-- 添加或修改文章对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="80%"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form
                v-if="open"
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row :gutter="40">
                    <el-col :span="18">
                        <el-card class="box-card" shadow="never">
                            <div slot="header" class="clearfix">
                                <span>基础信息</span>
                            </div>
                            <div class="text item">
                                <el-form-item label="栏目" prop="channel_id">
                                    <treeselect
                                        v-model="form.channel_id"
                                        :flat="true"
                                        :default-expand-level="1"
                                        :options="channelTree"
                                        :normalizer="normalizer"
                                        placeholder="请选择栏目"
                                    />
                                </el-form-item>
                                <el-form-item label="标题" prop="title">
                                    <el-input
                                        v-model="form.title"
                                        placeholder="请输入标题"
                                    />
                                </el-form-item>
                                <el-form-item label="部门" prop="dept_id">
                                    <!-- <el-input v-model="form.name" placeholder="名称" /> -->
                                    <el-button
                                        type="primary"
                                        @click="dialogVisible = true"
                                        >选择部门</el-button
                                    >
                                    <jf-select
                                        :value="form.dept_id"
                                        :tabList="tabList"
                                        :searchable="true"
                                        :disabled="false"
                                        :maxNum="maxNum"
                                        :show.sync="dialogVisible"
                                        @input="onConfirm"
                                        :title="'部门'"
                                    ></jf-select>
                                </el-form-item>
                                <el-form-item label="缩略图" prop="image">
                                    <el-upload
                                        class="upload-demo"
                                        accept="image/png, image/jpeg"
                                        ref="fieldEditor"
                                        action="/file/upload"
                                        :headers="uploadHeaders"
                                        :data="uploadData"
                                        :multiple="false"
                                        :limit="1"
                                        :file-list="imageList"
                                        :on-success="handleUploadSuccess"
                                        :on-error="handleUploadError"
                                        :on-exceed="handleUploadExceed"
                                        :on-remove="handleUploadRemove"
                                        :http-request="uploadDss"
                                        list-type="picture-card"
                                    >
                                        <el-button size="small" type="primary"
                                            >点击上传</el-button
                                        >
                                        <div slot="tip" class="el-upload__tip">
                                            只能上传jpg/png文件，且不超过500kb
                                        </div>
                                    </el-upload>
                                </el-form-item>
                                <el-form-item label="标签">
                                    <el-select
                                        v-model="selectedTags"
                                        multiple
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            v-for="item in tagList"
                                            :key="item.name"
                                            :label="item.name"
                                            :value="item.name"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="自定义URL" prop="diyname">
                                    <el-input
                                        v-model="form.diyname"
                                        placeholder="请输入自定义URL"
                                    />
                                </el-form-item>
                                <el-form-item label="内容" prop="content">
                                    <vue-ueditor-wrap
                                        v-model="form.content"
                                        :config="ueConfig"
                                        class="archives-ueditor"
                                    ></vue-ueditor-wrap>
                                </el-form-item>
                                <el-form-item label="附件" prop="attachment">
                                    <file-upload-ex
                                        v-model="form.attachment"
                                        list-type="text"
                                        category="cms"
                                    />
                                </el-form-item>
                                <el-form-item label="关键字" prop="keywords">
                                    <el-input
                                        v-model="form.keywords"
                                        placeholder="请输入关键字"
                                    />
                                </el-form-item>
                                <el-form-item label="描述" prop="description">
                                    <el-input
                                        v-model="form.description"
                                        type="textarea"
                                        placeholder="请输入描述"
                                    />
                                </el-form-item>
                                <el-form-item label="推荐位" prop="position">
                                    <el-input
                                        v-model="form.position"
                                        placeholder="请输入推荐位"
                                    />
                                </el-form-item>
                                <!-- <el-form-item
                                    label="文章标签"
                                    prop="articleTag"
                                >
                                    <el-input
                                        v-model="form.articleTag"
                                        placeholder="请输入文章标签"
                                    />
                                </el-form-item> -->
                                <el-form-item label="来源" prop="source">
                                    <el-input
                                        v-model="form.source"
                                        placeholder="请输入来源"
                                    />
                                </el-form-item>
                                <el-form-item label="作者" prop="author">
                                    <el-input
                                        v-model="form.author"
                                        placeholder="请输入作者"
                                    />
                                </el-form-item>
                                <el-form-item label="摘要" prop="abstract">
                                    <el-input
                                        v-model="form.abstract"
                                        placeholder="请输入摘要"
                                    />
                                </el-form-item>
                                <el-form-item label="排序" prop="weigh">
                                    <el-input
                                        type="number"
                                        v-model="form.weigh"
                                        min="0"
                                        placeholder="请输入排序"
                                    />
                                    <div
                                        class=""
                                        style="color: red; font-size: 12px"
                                    >
                                        值越小排越前
                                    </div>
                                </el-form-item>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card class="box-card" shadow="never">
                            <div slot="header" class="clearfix">
                                <span>相关信息</span>
                            </div>
                            <div class="text item">
                                <el-form-item label="浏览" prop="views">
                                    <el-input-number
                                        v-model="form.views"
                                        controls-position="right"
                                        :min="0"
                                    />
                                </el-form-item>
                                <el-form-item label="评论" prop="comments">
                                    <el-input-number
                                        v-model="form.comments"
                                        controls-position="right"
                                        :min="0"
                                    />
                                </el-form-item>
                                <el-form-item label="点赞" prop="likes">
                                    <el-input-number
                                        v-model="form.likes"
                                        controls-position="right"
                                        :min="0"
                                    />
                                </el-form-item>
                                <el-form-item label="点踩" prop="dislikes">
                                    <el-input-number
                                        v-model="form.dislikes"
                                        controls-position="right"
                                        :min="0"
                                    />
                                </el-form-item>
                            </div>
                        </el-card>
                        <el-card
                            class="box-card"
                            shadow="never"
                            style="margin-top: 20px"
                        >
                            <div slot="header" class="clearfix">
                                <span>状态</span>
                            </div>
                            <div class="text item">
                                <el-form-item label="标志">
                                    <el-select
                                        v-model="selectedFlags"
                                        multiple
                                        placeholder="请选择"
                                    >
                                    <el-option
                                            key="firstEvaluation"
                                            label="首次测评"
                                            value="firstEvaluation"
                                        ></el-option>
                                        <el-option
                                            key="hot"
                                            label="热门"
                                            value="hot"
                                        ></el-option>
                                        <el-option
                                            key="new"
                                            label="New"
                                            value="new"
                                        ></el-option>
                                        <el-option
                                            key="recommend"
                                            label="推荐"
                                            value="recommend"
                                        ></el-option>
                                        
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="状态" prop="status">
                                    <el-radio-group v-model="form.status">
                                        <el-radio key="normal" label="normal"
                                            >正常</el-radio
                                        >
                                        <el-radio key="hidden" label="hidden"
                                            >隐藏</el-radio
                                        >
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </el-card>

                        <el-card
                            class="box-card"
                            shadow="never"
                            style="margin-top: 20px"
                        >
                            <div slot="header" class="clearfix">
                                <span>关联测评项目</span>
                            </div>
                            <div class="text item">
                                <el-form-item label="项目">
                                    <el-select
                                        v-model="selectedsurProject"
                                        clearable=""
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            v-for="item in surveyprojectList"
                                            :key="item.id"
                                            :label="item.input92928"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>

                                <!-- <el-form-item label="必须观看时长" prop="weigh">
                                    <el-input
                                        type="number"
                                        v-model.number="
                                            form.survey_video_needdur
                                        "
                                        min="0"
                                        placeholder="请输入"
                                    />
                                    <div
                                        class=""
                                        style="color: red; font-size: 12px"
                                    >
                                        观看足够时长才能测评,单位:秒
                                    </div>
                                </el-form-item> -->
                                <el-form-item label="是否强制测评" prop="weigh">
                                    <el-switch
                                        v-model="form.survey_video_fast_forward"
                                        active-value="1"
                                        inactive-value="0"
                                    >
                                    </el-switch>
                                </el-form-item>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listCmsArchives,
    getCmsArchives,
    addCmsArchives,
    updateCmsArchives,
    delCmsArchives,
} from "@/api/cms/archives";
import { listCmsChannel } from "@/api/cms/channel";
import { listCmsTags } from "@/api/cms/tags";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getAccessToken } from "@/utils/tokenTool";
import JfSelect from "@/components/jfselect/index";
import QRCode from "qrcode";
import surveyprojectService from "@/api/survey/project";
import {
    uploadServer,
    uploadBigApp,
    addBigOss,
    getNameByPath,
} from "@/api/dss/fastdfsServer.js";

export default {
    name: "cms-archives-list",
    components: { Treeselect, JfSelect },
    data() {
        return {
            selectedsurProject: null,
            surveyprojectList: [],
            statusOptions: [],
            dialogVisible: false,
            tabList: ["dep"],
            depSelectList: [],
            dept_id_form: {},
            maxNum: 1,
            // 编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                serverUrl:
                    process.env.VUE_APP_BASE_API +
                    "/file/ueditorupload?token=" +
                    getAccessToken(),
                headers: {
                    Authorization: getAccessToken(),
                },
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },
            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                {
                    field: "id",
                    title: "编号",
                    width: 100,
                    fixed: "left",
                    visible: true,
                },
                {
                    field: "channel_id",
                    title: "栏目",
                    // formatter: this.channelFormat,
                    slots: {
                        default: "channel_id",
                    },
                    form_type: 2,
                    options: [],
                    options_value: "",
                    options_label: "",
                },
                { field: "title", title: "标题" },
                {
                    field: "image",
                    title: "缩略图",
                    slots: { default: "image" },
                },
                {
                    field: "status",
                    title: "显示状态",
                    formatter: this.statusFormat,
                },

                //{field: 'views', title: '浏览' },
                //{field: 'comments', title: '评论' },
                { field: "link", title: "链接" },
                {
                    field: "update_time",
                    title: "更新时间",
                    formatter: "formatDate",
                    form_type: 3,
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 250,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 文章表格数据
            archivesList: [],
            channelList: [],
            channelTree: [],
            tagList: [],
            selectedTags: [],
            selectedFlags: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 状态数据字典
            statusOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: undefined,
            },
            // 表单参数
            form: {},
            model_data: {},
            uploadHeaders: {},
            uploadData: {},
            imageList: [],
            // 表单校验
            rules: {
                channel_id: [
                    {
                        required: true,
                        message: "栏目不能为空",
                        trigger: "blur",
                    },
                ],
                title: [
                    {
                        required: true,
                        message: "标题不能为空",
                        trigger: "blur",
                    },
                ],
                content: [
                    {
                        required: true,
                        message: "内容不能为空",
                        trigger: "blur",
                    },
                ],
            },
            channel_name: "",
            defaultProps: {
                children: "children",
                label: "name",
            },
        };
    },
    created() {
        this.getDicts("cms_status").then((response) => {
            this.statusOptions = response.data.values || [];
        });

        this.getList();

        var queryParams = {
            sortinfo: {
                id: "desc",
            },
        };
        surveyprojectService.listProject(queryParams).then((rs) => {
            // console.log("listProject",rs)
            this.surveyprojectList = rs.data.list;
        });
    },
    watch: {
        // 根据名称筛选机构树
        channel_name(val) {
            this.$refs.tree.filter(val);
        },
    },
    methods: {
        statusFormat({ cellValue }) {
            return this.selectDictLabel(this.statusOptions, "" + cellValue);
        },
        handleQrCode(row) {
            let urls = window.location.href.split("#/");
            const text =
                (urls && urls.length ? urls[0] : process.env.VUE_APP_BASE_URL) +
                "qrcode/content.html?id=" +
                row.id; // 你要生成二维码的内容
            const options = {
                width: 200, // 二维码宽度
                height: 200, // 二维码高度
            };

            QRCode.toDataURL(text, options, (err, url) => {
                if (err) {
                    console.error("Error generating QR Code:", err);
                } else {
                    console.log(url);
                    // window.open(url);
                    const newWindow = window.open();
                    newWindow.document.write(
                        '<html><body style="margin: 0; display: flex; justify-content: center; align-items: center;">'
                    );
                    newWindow.document.write(
                        `<img src="${url}" style="max-width: 100%; max-height: 100%;" />`
                    );
                    newWindow.document.write("</body></html>");
                    newWindow.document.close();

                    // this.qrcodeUrl = url;
                }
            });
        },
        onConfirm(e) {
            console.log(e, "返回数据");
            this.dept_id_form = e;
        },
        tableChange(e) {},
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        /** 查询文章列表 */
        getList() {
            this.loading = true;
            listCmsChannel().then((response) => {
                this.channelList = response.data.list;
                let channels = [];
                for (let i = 0; i < response.data.list.length; i++) {
                    const c = response.data.list[i];
                    if (c.type != "link") {
                        channels.push(c);
                    }
                }
                this.channelTree = this.handleTree(channels, "id", "parent_id");
                for (let i = 0; i < this.tableColumn.length; i++) {
                    if (this.tableColumn[i].field === "channel_id") {
                        this.tableColumn[i].options = this.channelList;
                        this.tableColumn[i].options_value = "channel_id";
                        this.tableColumn[i].options_label = "title";
                        return;
                    }
                }
            });
            listCmsArchives(this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.archivesList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
            listCmsTags().then((response) => {
                this.tagList = response.data.list;
            });
        },
        channelFormat({ row }) {
            for (let i = 0; i < this.channelList.length; i++) {
                const c = this.channelList[i];
                if (c.id == row.channel_id) {
                    return c.name;
                }
            }
            return row.channel_id;
        },
        normalizer(node) {
            //console.log(node)
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node.id,
                label: node.name,
                children: node.children,
                isDisabled: node.type != "list",
            };
        },
        // 筛选节点
        filterNode(value, data) {
            console.log(value, data);
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 节点单击事件
        handleNodeClick(data) {
            this.queryParams.channel_id = data.id;
            this.getList();
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.uploadHeaders = {
                Authorization: getAccessToken(),
            };
            this.uploadData = {
                category: "cms",
                public: "1",
            };
            this.imageList = [];
            this.selectedTags = [];
            this.selectedFlags = [];
            this.form = {
                id: undefined,
                channel_id: undefined,
                title: undefined,
                views: 0,
                comments: 0,
                likes: 0,
                dislikes: 0,
                status: "normal",
                content: "",
                attachment: "",
            };
            this.model_data = {};
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParams.channel_id = 0;
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加文章";

            // this.selectedsurProject=null
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getCmsArchives(id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    if (data.model_data) {
                        let model_data = data.model_data.replace(
                            /[\r|\n|\t]/g,
                            ""
                        );
                        this.model_data = JSON.parse(model_data);
                    } else {
                        this.model_data = {};
                    }
                    this.selectedTags = data.tags ? data.tags.split(",") : [];
                    this.selectedFlags = data.flag ? data.flag.split(",") : [];
                    this.form = {
                        id: data.id,
                        channel_id: data.channel_id,
                        title: data.title,
                        views: data.views,
                        comments: data.comments,
                        likes: data.likes,
                        dislikes: data.dislikes,
                        status: data.status,
                        flag: data.flag,
                        image: data.image,
                        keywords: data.keywords,
                        description: data.description,
                        tags: data.tags,
                        diyname: data.diyname,
                        publish_time: data.publish_time,
                        content: this.model_data.content,
                        attachment: this.model_data.attachment,
                        dept_id: data.dept_id,
                        position: data.position,
                        source: data.source,
                        author: data.author,
                        abstract: data.abstract,
                        weigh: data.weigh,
                        surveyproject: data.surveyproject,
                        survey_video_needdur: data.survey_video_needdur,
                        survey_video_fast_forward:
                            data.survey_video_fast_forward,
                    };
                    this.dept_id_form = JSON.parse(data.dept_id);
                }
                if (data.image) {
                    this.imageList = [
                        {
                            url: data.image,
                        },
                    ];
                }
                this.open = true;
                this.title = "修改文章";
            });
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.form.tags = this.selectedTags.join(",");
                    this.form.flag = this.selectedFlags.join(",");
                    this.model_data.content = this.form.content;
                    this.model_data.attachment = this.form.attachment;
                    this.form.model_data = JSON.stringify(this.model_data);
                    this.form.dept_id = JSON.stringify(this.dept_id_form);
                    this.form.surveyproject = this.selectedsurProject;
                    if (this.form.id != undefined) {
                        updateCmsArchives(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.dept_id_form = {};
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addCmsArchives(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.dept_id_form = {};
                                this.open = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm("确定删除吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delCmsArchives(ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有文章数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportArchives(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                })
                .catch(function () {});
        },
        //自定义上传
        async uploadDss(request) {
            // 默认需要登录
            let tokenReq = {
                platform: "jpgfast",
                type: "general",
                source: "system",
                public: 1,
            };
            this.loading = true;
            uploadServer(tokenReq, request.file).then((resp) => {
                // console.log(123);
                if (resp.code == 0) {
                    // const value =
                    // //console.log(value)
                    // this.$emit("input", value);
                    // this.$emit("change", value);
                    this.form.image = resp.data.url
                    // this.imageList.push({
                    //     url: resp.data.url,
                    //     name: resp.data.filename,
                    // });
                  
                } else {
                    this.$refs.fieldEditor.uploadFiles =
                        this.$refs.fieldEditor.uploadFiles.filter((e) => {
                            if (e.name !== file.name || e.status !== "ready") {
                                return e;
                            }
                        });
                  
                    this.$message.error({
                        message: "上传失败,原因：" + res.msg,
                        duration: 2000,
                    });
                }
            });
        },
        handleUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList);
            // file.url = response.data.url;
            // this.form.image = response.data.url;
        },
        handleUploadError(err) {
            console.log(err);
            this.form.image = "";
            if (
                err &&
                (err.message.indexOf("larger than") > 0 ||
                    err.message.indexOf("413") > 0)
            ) {
                this.msgError("上传文件失败，文件大小超过限制");
            } else {
                this.msgError("上传文件失败");
            }
        },
        handleUploadExceed() {
            this.msgError("只能上传一个文件，请删除后重新上传");
        },
        handleUploadRemove() {
            this.form.image = "";
        },
    },
};
</script>

<style >
.archives-image {
    height: 35px;
    max-width: 200px;
    margin-top: 4px;
}
.archives-ueditor .edui-editor {
    z-index: 2000 !important;
}
</style>