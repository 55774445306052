export default {
  toolbar: {
    undo: 'Rückgängig machen',
    redo: 'Wiederherstellen',
    paintformat: 'Format kopieren/einfügen',
    clearformat: 'Format löschen',
    format: 'Format',
    font: 'Schriftart',
    fontSize: 'Schriftgrad',
    fontBold: 'Fett',
    fontItalic: 'Kursiv',
    underline: 'Betonen',
    strike: 'Streichen',
    textColor: 'Text Farbe',
    fillColor: 'Füllung Farbe',
    border: 'Umrandung',
    merge: 'Zellen verbinden',
    align: 'Waagrechte Ausrichtung',
    valign: 'Vertikale uitlijning',
    textwrap: 'Textumbruch',
    freeze: 'Zelle sperren',
    formula: 'Funktionen',
    more: 'Mehr',
  },
  contextmenu: {
    copy: 'Kopieren',
    cut: 'Ausschneiden',
    paste: 'Einfügen',
    pasteValue: 'Nur Werte einfügen',
    pasteFormat: 'Nur Format einfügen',
    insertRow: 'Zeile einfügen',
    insertColumn: 'Spalte einfügen',
    deleteRow: 'Zeile löschen',
    deleteColumn: 'Spalte löschen',
    deleteCell: 'Zelle löschen',
    deleteCellText: 'Zellentext löschen',
  },
  format: {
    normal: 'Regulär',
    text: 'Text',
    number: 'Nummer',
    percent: 'Prozent',
    rmb: 'RMB',
    usd: 'USD',
    date: 'Datum',
    time: 'Termin',
    datetime: 'Datum Termin',
    duration: 'Dauer',
  },
  formula: {
    sum: 'Summe',
    average: 'Durchschnittliche',
    max: 'Max',
    min: 'Min',
    concat: 'Concat',
  },
};
