<template>
    <el-form-item v-if="!designer.isTopic">
        <span slot="label"
            >{{ i18nt("designer.setting.validation") }}
            <el-tooltip
                effect="light"
                :content="i18nt('designer.setting.validationHelp')"
            >
                <i class="el-icon-info"></i
            ></el-tooltip>
        </span>
        <el-select
            v-model="optionModel.validation"
            filterable
            clearable
            allow-create
            default-first-option
            @change="fieldValidatorsChange"
        >
            <el-option
                v-for="(fv, fvIdx) in fieldValidators"
                :key="fvIdx"
                :label="fv.label"
                :value="fv.value"
            >
            </el-option>
        </el-select>
    </el-form-item>
</template>

<script>
import i18n from "@/vform/utils/i18n";

export default {
    name: "validation-editor",
    mixins: [i18n],
    props: {
        designer: Object,
        selectedWidget: Object,
        optionModel: Object,
    },
    data() {
        return {
            fieldValidators: [
                {
                    value: "number",
                    label: this.i18nt("designer.hint.numberValidator"),
                },
                {
                    value: "letter",
                    label: this.i18nt("designer.hint.letterValidator"),
                },
                {
                    value: "letterAndNumber",
                    label: this.i18nt("designer.hint.letterAndNumberValidator"),
                },
                {
                    value: "mobilePhone",
                    label: this.i18nt("designer.hint.mobilePhoneValidator"),
                },
                {
                    value: "email",
                    label: this.i18nt("designer.hint.emailValidator"),
                },
                {
                    value: "url",
                    label: this.i18nt("designer.hint.urlValidator"),
                },
                {
                    value: "noChinese",
                    label: this.i18nt("designer.hint.noChineseValidator"),
                },
                {
                    value: "chinese",
                    label: this.i18nt("designer.hint.chineseValidator"),
                },
                {
                    value: "idCard",
                    label: this.i18nt("designer.hint.idCardValidator"),
                },
            ],
        };
    },
    methods: {
        hasSlashesAroundRegex(str) {
            const regex = /\/.+\/[gimuy]{0,5}/;
            return regex.test(str);
        },
        fieldValidatorsChange() {
            if (this.optionModel.validation) {
                this.optionModel.required = true;
                let val = this.optionModel.validation
            
                let index = this.fieldValidators.findIndex(
                    (o) => o.value === val
                );
                if (index === -1 && !this.hasSlashesAroundRegex(val)) {
                    this.optionModel.validation =
                        "/" + this.optionModel.validation + "/";
                }
            }
        },
    },
};
</script>

<style scoped>
</style>
