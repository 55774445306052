import request from '@/utils/request'
// 生成时间:2023-05-23 17:14:49

// 查询permiss列表
export function listPermiss(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/dss/permiss/list',
        method: 'post',
        data: data
    })
}

// 查询permiss详细
export function getPermiss (id) {
    return request({
        url: '/dss/permiss/get?id='+id,
        method: 'get'
    })
}


// 新增permiss
export function addPermiss(data) {
    return request({
        url: '/dss/permiss/add',
        method: 'post',
        data: data
    })
}

// 修改permiss
export function editPermiss(data) {
    return request({
        url: '/dss/permiss/edit',
        method: 'post',
        data: data
    })
}

// 删除permiss
export function delPermiss(ids) {
    return request({
        url: '/dss/permiss/del',
        method: 'post',
        data:{ids:ids}
    })
}

// 删除permiss查询模板
export function advquerydelPermiss(ids) {
    return request({
        url: '/dss/permiss/advquerydel',
        method: 'post',
        data:{ids:ids}
    })
}

// 添加permiss查询模板
export function advquerysavePermiss(data) {
    return request({
        url: '/dss/permiss/advquerysave',
        method: 'post',
        data: data
    })
}
// 列表permiss查询模板列表
export function advquerylistPermiss(businessname) {
    return request({
        url: '/dss/permiss/advquerylist?businessname='+businessname,
        method: 'get'
    })
}
// 导出permiss
export function exportPermiss(query,exportfields,tableColumn) {
     var data={
            filterstr:"",
            orderby:"",
            opstr:query.opstr ||{},
        };
        var filterstr={};
        for(var k in query){
         var item=query[k];
         if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
           filterstr[k]=item;
         }
        }
        var tarexportfieldslist={};
        for(var k in tableColumn){
          var tlbf = tableColumn[k];
           for(var i in exportfields){
             var ef = exportfields[i]
             if(tlbf.field!="" && tlbf.field && tlbf.field!=null && tlbf.field == ef){
               tarexportfieldslist.push({"filed":ef,"title":tlbf.title});
               break;
             }
           }
        }

        data["filterstr"] = JSON.stringify(filterstr);
        data["opstr"] = JSON.stringify(data["opstr"]);
        data["exportfields"] = JSON.stringify(tarexportfieldslist);
        return request({
            url: '/dss/permiss/export',
            method: 'post',
            data: data
        })
}
