<!-- 生成时间:2021-11-19 16:52:21 -->

<template>
    <el-card>
        <el-tabs v-model="activeName">
            <el-tab-pane label="学年" name="xn">
                <el-form ref="xnform" :model="xnform"   label-width="180px">
                    <el-col :span="12">
                    <el-form-item label="当前学年" prop="tur_pid_name">
                        <el-input :disabled="true" v-model="xnform.tur_pid_name" placeholder="" />
                    </el-form-item>

                    <el-form-item v-for="tur in tur_id" :label="tur.name"  >
                        <el-input type="number" v-model.number="xnform['turid'+ tur.id]" placeholder="请输入比例" >
                            <template slot="append">%</template>
                        </el-input>

                    </el-form-item>
                 </el-col>
                    <el-col :span="24">
                        <el-form-item style="text-align: center;margin-left:-100px;margin-top:10px;">
                            <el-button   type="primary" @click="submitxnForm()">保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-form>


            </el-tab-pane>

            <el-tab-pane label="学业" name="xy">

                <el-form ref="xyform"  :model="xyform"   label-width="180px">
                    <el-col :span="12">
                    <el-form-item v-for="tur in turdata" v-if="tur.pid==0"  :label="tur.name" prop="title">
                        <el-input v-model="xyform['tuid'+ tur.id]" placeholder="请输入比例" >
                            <template slot="append">%</template>
                            </el-input>
                    </el-form-item>
                        </el-col>
                    <el-col :span="24">
                        <el-form-item style="text-align: center;margin-left:-100px;margin-top:10px;">
                            <el-button   type="primary" @click="submitxyForm()">保存</el-button>
                        </el-form-item>
                    </el-col>

                </el-form>

            </el-tab-pane>

       </el-tabs>


  </el-card>
</template>
<script>


import {
        listStuTurn
} from "@/api/stu/stu_turn";

import {
        Savedata,Datalist
} from "@/api/growthindicators/gyxy/achievementstandard";

export default {
  name: "achievementstandard",

  data() {
    return {
        tur_id:[],
        tur_pid:{},
        activeName:"xn",
        selindex:false,

          // 遮罩层
          loading: true,
          // 选中数组
          ids: [],
          // 非单个禁用
          single: true,
          // 非多个禁用
          multiple: true,
          // 显示搜索条件
          showSearch: true,
          // 总条数
          total: 0,
          // 表格数据
          dataList: [],
          // 弹出层标题
          title: "",
          // 是否显示弹出层
          open: false,
            // 字典
        // 表单参数
          xnform: {
              tur_pid_name:''
          },
        xyform:{},
        turdata:[]


      };
    },
    created() {
        var  tparam= {
            pageNum: 1,
            pageSize: 10,

        };
        listStuTurn(tparam).then((response) => {
            this.turdata = response.data.list
            var tur_pid={}
            var tur_id=[];
        var hasfound=false
        for(var i in response.data.list){
            var item = response.data.list[i]

            if(item.pid==0 && item.status=="1"&& hasfound==false){
                tur_pid = item
                hasfound=true
            }
            if(hasfound && item.pid==tur_pid.id){
                tur_id.push(item)
            }

        }
        this.tur_pid =tur_pid

        this.xnform.tur_pid_name = this.tur_pid.name
        this.xnform.tur_pid = this.tur_pid.id

        //学年设置
        Datalist({type:1,turid:this.xnform.tur_pid}).then((rs)=>{
             var data = JSON.parse(rs.data.list)
            var tf ={};// this.xnform;
            for(var i in data){
            var onedata = data[i]
                tf['turid'+onedata.tur_id] =parseInt( onedata.score_rate)
            }
         this.xnform ={
                ...this.xnform,
                ...tf
         }
        this.tur_id =tur_id
        })

        //学年设置
        Datalist({type:2,turid:this.xnform.tur_pid}).then((rs)=>{
            var data = JSON.parse(rs.data.list)

            var tf ={};// this.xnform;
        for(var i in data){
            var onedata = data[i]
            console.log("onedata",onedata)
            tf['tuid'+onedata.tur_pid] =parseInt( onedata.score_rate)
        }
        this.xyform ={
                ...this.xyform,
                ...tf
    }

    })

    });




    },
    methods: {


        // 表单重置
        reset() {
          this.form = {
                        title : undefined,
            year : undefined,
            semester : undefined,
            bdate : undefined,
            edate : undefined,
            note : undefined,
            status : undefined,
            indexs : undefined,
            college_id : undefined,
            college_name : undefined,

          };
          this.resetForm("form");
        },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
        submitxyForm(){

            var datalist=[];

            for(var i in this.turdata){

                var onetid = this.turdata[i]
                if (onetid.pid!=0){
                    continue
                }
                var postform={};
                postform.type=2;
                postform.tur_pid=onetid.id;
                postform.tur_pid_name=onetid.name;

                postform.score_rate= this.xyform['tuid'+onetid.id]
//                console.log("postform",postform)
                datalist.push(postform)
            }
//            console.log("datalist",datalist)
            Savedata({type:2,turid:this.xyform.tur_pid,datalist:JSON.stringify(datalist)}).then(()=>{
                this.msgSuccess("保存成功");
        })

        },
    /** 提交按钮 */
    submitxnForm() {
      this.$refs["xnform"].validate(valid => {
        if (valid) {

            var datalist=[];

            for(var i in this.tur_id){
                var onetid = this.tur_id[i]
                var postform={};
                postform.type=1;
                postform.tur_pid=this.xnform.tur_pid;
                postform.tur_pid_name=this.xnform.tur_pid_name;

                postform.tur_id = onetid.id
                postform.tur_id_name=onetid.name
                postform.score_rate= this.xnform['turid'+onetid.id]
                console.log("postform",postform)
                datalist.push(postform)
            }
            console.log("datalist",datalist)

            Savedata({type:1,turid:this.xnform.tur_pid,datalist:JSON.stringify(datalist)}).then(()=>{
                this.msgSuccess("保存成功");
            })


        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;

      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delTask(ids);
        }).then(() => {
         setTimeout(()=>{this.getList();},300)
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    } //methods结束
};
</script>