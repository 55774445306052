<template>
<div class="app-container">
	<el-form
		:model="queryParams"
		ref="queryForm"
		:inline="true"
		label-width="68px"
		>
		<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="任务ID" prop="task_id">
			<el-input
				v-model="queryParams.task_id"
				placeholder="请输入任务ID"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="接收人id" prop="user_id">
			<el-input
				v-model="queryParams.user_id"
				placeholder="请输入接收人id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item>
			<el-button
				type="cyan"
				icon="el-icon-search"
				size="mini"
				@click="handleQuery"
				>搜索</el-button
			>
			<el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
				>重置</el-button
			>
		</el-form-item>
	</el-form>

	<vxe-grid
		resizable
		ref="xGrid"
		row-id="id"
		stripe
		show-overflow
		highlight-hover-row
		:loading="loading"
		:toolbar-config="tableToolbar"
		:columns="tableColumn"
		:data="dataList"
		@checkbox-change="checkboxChangeEvent"
		@checkbox-all="checkboxChangeEvent"
	>
		<template #toolbar_buttons>
			<el-button
				style="margin-left: 10px"
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="handleAdd"
				v-hasPermi="[
					'/oa/oataskrecord/add',
				]"
				>新增</el-button
			>

			<el-button
				type="success"
				icon="el-icon-edit"
				size="mini"
				:disabled="single"
				@click="handleUpdate"
				v-hasPermi="[
					'/oa/oataskrecord/get',
					'/oa/oataskrecord/edit',
				]"
				>修改</el-button
			>

			<el-button
				type="danger"
				icon="el-icon-delete"
				size="mini"
				:disabled="multiple"
				@click="handleDelete"
				v-hasPermi="[
					'/oa/oataskrecord/del',
				]"
				>删除</el-button
			>
		</template>

		<template #defaultopr="{ row }">
			<el-button
				size="mini"
				type="text"
				icon="el-icon-edit"
				@click.stop="handleUpdate(row)"
				>修改
			</el-button>
			<el-button
				size="mini"
				type="text"
				icon="el-icon-delete"
				@click.stop="handleDelete(row)"
				>删除
			</el-button>
		</template>

		<template #empty>
			<span>
				<p>暂无数据</p>
			</span>
		</template>
	</vxe-grid>

	<vxe-pager
		background
		size="small"
		:loading="loading"
		:current-page.sync="queryParams.pageNum"
		:page-size.sync="queryParams.pageSize"
		:total="total"
		@page-change="handlePageChange"
	>
	</vxe-pager>

	<el-dialog
		:close-on-click-modal="false"
		:title="title"
		:visible.sync="open"
		width="800px"
		append-to-body
	>
		<el-form ref="form" :model="form" :rules="rules" label-width="100px">
			<el-row>
				<el-col :span="12">
					<el-form-item label="任务ID" prop="task_id">
						<el-input-number
							v-model="form.task_id"
							placeholder="请输入任务ID"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="接收人id" prop="user_id">
						<el-input-number
							v-model="form.user_id"
							placeholder="请输入接收人id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="接收人名称" prop="user_name">
						<el-input
							v-model="form.user_name"
							placeholder="请输入接收人名称"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="接收人部门id" prop="dept_id">
						<el-input-number
							v-model="form.dept_id"
							placeholder="请输入接收人部门id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="接收人部门名称" prop="dept_name">
						<el-input
							v-model="form.dept_name"
							placeholder="请输入接收人部门名称"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="转发人id" prop="transfer_ids">
						<el-input
							v-model="form.transfer_ids"
							placeholder="请输入转发人id"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="转发人名称" prop="transfer_names">
						<el-input
							v-model="form.transfer_names"
							placeholder="请输入转发人名称"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="是否已读" prop="readed">
						<el-input
							v-model="form.readed"
							placeholder="请输入是否已读"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="阅读时间" prop="read_time">
						<el-input-number
							v-model="form.read_time"
							placeholder="请输入阅读时间"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="任务是否完成" prop="finish">
						<el-input
							v-model="form.finish"
							placeholder="请输入任务是否完成"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="是否拒绝任务" prop="reject">
						<el-input
							v-model="form.reject"
							placeholder="请输入是否拒绝任务"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="位置" prop="submit_position">
						<el-input
							v-model="form.submit_position"
							placeholder="请输入位置"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="位置描述" prop="submit_position_remark">
						<el-input
							v-model="form.submit_position_remark"
							placeholder="请输入位置描述"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="提交状态" prop="submit_status">
						<el-input
							v-model="form.submit_status"
							placeholder="请输入提交状态"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="提交内容" prop="submit_content">
						<el-input
							v-model="form.submit_content"
							placeholder="请输入提交内容"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="提交材料" prop="submit_files">
						<el-input
							v-model="form.submit_files"
							placeholder="请输入提交材料"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="提交时间" prop="submit_time">
						<el-input-number
							v-model="form.submit_time"
							placeholder="请输入提交时间"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="分数" prop="score">
						<el-input-number
							v-model="form.score"
							placeholder="请输入分数"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="审核状态" prop="approve_status">
						<el-input
							v-model="form.approve_status"
							placeholder="请输入审核状态"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="审核结果及原因" prop="approve_content">
						<el-input
							v-model="form.approve_content"
							placeholder="请输入审核结果及原因"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="审核人" prop="approve_by">
						<el-input-number
							v-model="form.approve_by"
							placeholder="请输入审核人"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="审核时间" prop="approve_time">
						<el-input-number
							v-model="form.approve_time"
							placeholder="请输入审核时间"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
			</el-row>
		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitForm">确 定</el-button>
			<el-button @click="cancel">返 回</el-button>
		</div>
	</el-dialog>
</div>
</template>
<script>
import {
	listOaTaskRecord,
	getOaTaskRecord,
	addOaTaskRecord,
	editOaTaskRecord,
	delOaTaskRecord,
} from "@/api/oa/oa_task_record";
export default {
	name: "oa-task-record",
	data() {
		return { 
			tableToolbar: {
				perfect: true,
				zoom: true,
				custom: true,
				refresh: {
					query: this.handleQuery,
				},
				slots: {
					buttons: "toolbar_buttons",
				},
			},
			tableColumn: [
				{ type: "checkbox", width: 60, fixed: "left" },
				{ field: "id", title: "id", minWidth: 120, fixed: "left" },
				{ field: "task_id", title: "任务ID", minWidth: 120 },
				{ field: "user_id", title: "接收人id", minWidth: 120 },
				{ field: "user_name", title: "接收人名称", minWidth: 120 },
				{ field: "dept_id", title: "接收人部门id", minWidth: 120 },
				{ field: "dept_name", title: "接收人部门名称", minWidth: 120 },
				{ field: "transfer_ids", title: "转发人id", minWidth: 120 },
				{ field: "transfer_names", title: "转发人名称", minWidth: 120 },
				{ field: "readed", title: "是否已读", minWidth: 120 },
				{ field: "read_time", title: "阅读时间", minWidth: 120 },
				{ field: "status", title: "状态", minWidth: 120 },
				{ field: "submit_position", title: "位置", minWidth: 120 },
				{ field: "submit_position_remark", title: "位置描述", minWidth: 120 },
				{ field: "submit_status", title: "提交状态", minWidth: 120 },
				{ field: "submit_content", title: "提交内容", minWidth: 120 },
				{ field: "submit_files", title: "提交材料", minWidth: 120 },
				{ field: "submit_time", title: "提交时间", minWidth: 120 },
				{ field: "score", title: "分数", minWidth: 120 },
				{ field: "approve_status", title: "审核状态", minWidth: 120 },
				{ field: "approve_content", title: "审核结果及原因", minWidth: 120 },
				{ field: "approve_by", title: "审核人", minWidth: 120 },
				{ field: "approve_time", title: "审核时间", minWidth: 120 },
				{ field: "create_time", title: "创建时间", minWidth: 120, formatter: "formatDate", visible: false },
				{ field: "update_time", title: "更新时间", minWidth: 120, formatter: "formatDate" },
				{
					field: "",
					title: "操作",
					width: 180,
					fixed: "right",
					align: "center",
					slots: { default: "defaultopr" },
				},
			],
			loading: true,
			ids: [],
			single: true,
			multiple: true,
			total: 0,
			dataList: [],
			title: "",
			open: false,
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				id: undefined,
				task_id: undefined,
				user_id: undefined,
			},
			form: {},
			rules: { 
				task_id: [{ required: true, message: "任务ID不能为空", trigger: "blur" }],
				user_id: [{ required: true, message: "接收人id不能为空", trigger: "blur" }],
			}
		};
	},
	created() { 
		this.getList();
	},
	methods: {
		getList() {
			this.loading = true;
			this.ids = [];
			this.single = true;
			this.multiple = true;

			listOaTaskRecord(this.queryParams).then((response) => { 
				this.dataList = response.data.list;
				this.total = response.data.total;
				this.loading = false;
			});
		},
		
		cancel() {
			this.open = false;
			this.reset();
		},

		reset() {
			this.form = { 
				task_id: undefined,
				user_id: undefined,
				user_name: undefined,
				dept_id: undefined,
				dept_name: undefined,
				transfer_ids: undefined,
				transfer_names: undefined,
				readed: undefined,
				read_time: undefined,
				finish: undefined,
				reject: undefined,
				submit_position: undefined,
				submit_position_remark: undefined,
				submit_status: undefined,
				submit_content: undefined,
				submit_files: undefined,
				submit_time: undefined,
				score: undefined,
				approve_status: undefined,
				approve_content: undefined,
				approve_by: undefined,
				approve_time: undefined,
			};
			this.resetForm("form");
		},

		handleQuery() {
			this.queryParams.pageNum = 1;
			this.getList();
		},

		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},

		handleAdd() {
			this.reset();
			this.open = true;
			this.title = "添加";
		},

		handleUpdate(row) {
			this.reset();
			const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
			getOaTaskRecord(id).then((response) => {
				this.form = response.data;
				this.open = true;
				this.title = "修改";
			});
		},

		submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if (this.form.id != null) {
						editOaTaskRecord(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("修改成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					} else {
						addOaTaskRecord(this.form).then((response) => {
						if (response.code === 0) {
							this.msgSuccess("新增成功");
							this.open = false;
							setTimeout(() => {
								this.getList();
							}, 300);
						}
						});
					}
				}
			});
		},

		handleDelete(row) {
			const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
			this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(function () {
				return delOaTaskRecord(ids);
			})
			.then(() => {
				setTimeout(() => {
					this.getList();
				}, 300);
				this.msgSuccess("删除成功");
			})
			.catch(function () {});
		},

		checkboxChangeEvent({ records }) {
			this.ids = records.map((item) => item.id);
			this.single = records.length != 1;
			this.multiple = !records.length;
		},

		handlePageChange({ currentPage, pageSize }) {
			if (this.queryParams.pageSize == pageSize) {
				this.getList();
			} else {
				this.queryParams.pageSize = pageSize;
				this.handleQuery();
			}
		},
	}
};
</script>
