import request from '@/utils/request'
// 查询category列表
export function listCategory(query) {
  return request({
    url: '/data/category/list',
    method: 'get',
    params: query
  })
}

// 查询category详细
export function getCategory (id) {
    return request({
        url: '/data/category/get?id='+id,
        method: 'get'
    })
}


// 新增category
export function addCategory(data) {
    return request({
        url: '/data/category/add',
        method: 'post',
        data: data
    })
}

// 修改category
export function editCategory(data) {
    return request({
        url: '/data/category/edit',
        method: 'post',
        data: data
    })
}

// 删除category
export function delCategory(ids) {
    return request({
        url: '/data/category/del',
        method: 'post',
        data:{ids:ids}
    })
}

