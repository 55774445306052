import request from '@/utils/request'


// 获取用户警告
export function getIotUserWarn(id) {
    return request({
      url: '/iot/userwarn/get?id=' + id,
      method: 'get'
    })
}

// 更新用户警告
export function updateIotUserWarn(data) {
  return request({
    url: '/iot/userwarn/update',
    method: 'post',
    data: data
  })
}
  
// 删除用户警告
export function delIotUserWarn(id) {
    return request({
      url: '/iot/userwarn/del',
      method: 'post',
      data: {
        id: id
      }
    })
  }
// 用户警告列表
export function listIotUserWarn(query) {
  return request({
    url: '/iot/userwarn/list',
    method: 'get',
    params: query
  })
}