<template>
  <div class="setting-container">
    <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px"
      label-position="top">
      <el-form-item label="流程编码" prop="flowCode" v-if="false">
        <el-input v-model="formData.flowCode" placeholder="请输入流程编码" clearable :style="{width: '100%'}" >
        </el-input>
      </el-form-item>
      <el-form-item label="流程名称" prop="flowName">
        <el-input v-model="formData.flowName" placeholder="请输入流程名称" clearable :style="{width: '100%'}" @input="emitChangeFlowName" @change="emitChangeFlowName">
        </el-input>
      </el-form-item>
      <el-form-item label="选择分类" prop="flowCategory">
        <treeselect
          v-model="formData.flowCategory"
          :options="flowCategoryOptions"
          :normalizer="normalizer"
          :show-count="true"
          placeholder="选择分类"
        />
      </el-form-item>
      <el-form-item label="流程图标" prop="icon" >
        <el-upload
          :class="{disUploadSty:hideUploadBtnImg}"
          ref="uploadIconImg"
          accept="image/png, image/jpeg"
          action="/file/upload?category=flow&public=1"
          :before-upload="beforeUpload"
          :headers="uploadHeaders"
          :multiple="false"
          :limit="1"
          :file-list="uploadIconList"
          :on-success="handleUploadSuccess"
          :on-error="handleUploadError"
          :on-remove="handleUploadRemove"
          :on-exceed="handleUploadExceed"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，宽高比例200*200，且不超过10KB</div>
        </el-upload>
        <template v-if="false">
        <img :src="activeIconSrc" style="width: 28px;height: 28px;vertical-align: middle;">
        <el-button  plain size="mini" @click="dialogVisible = true" style="margin-left: 10px;">选择图标</el-button>
        </template>
      </el-form-item>
      <el-form-item label="谁可以发起流程" prop="initiator" >
        <el-row>
          <el-col :span="20">
            <fc-org-select
              ref="org-select"
              v-model="formData.initiator"
              :tabList="['dep&user','role']"
              title="发起人"
              @input="emitInitiator" />
          </el-col>
          <el-col :span="3">
            <div style="margin-left:5px;line-height: 30px">
            <el-button
              type="primary"
              icon="el-icon-upload"
              size="mini"
              @click="handleImport('initiator')"
            >上传</el-button>
            </div>
          </el-col>
        </el-row>
        <span style="font-size: 12px; color: #aaa;">默认所有人</span>
        <el-checkbox v-model="formData.notify" v-if="false">是否给发起人发送通知</el-checkbox>

      </el-form-item>
      <el-form-item label="禁止谁发起流程" prop="unInitiator" >
        <el-row>
          <el-col :span="20">
            <fc-org-select
              ref="org-select"
              v-model="formData.unInitiator"
              :tabList="['dep&user','role']"
              title="禁用发起人"/>
          </el-col>
          <el-col :span="3">
            <div style="margin-left:5px;line-height: 30px">
              <el-button
                type="primary"
                icon="el-icon-upload"
                size="mini"
                @click="handleImport('unInitiator')"
              >上传</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="消息通知方式" v-if="false" >
        <el-input size="medium" v-model="formData.notifyTitle"  title="消息通知标题" placeholder="消息通知标题">
          <el-select slot="prepend" v-model="formData.notifyType" value-key="name" title="消息通知方式" style="width:180px"
                     placeholder="选择消息通知方式"
                     size="medium" clearable multiple collapse-tags>
            <el-option v-for="(nt, index) in notifyTypes" :label="nt.name" :key="index" :value="nt"></el-option>
          </el-select>
        </el-input>


      </el-form-item>
      <el-form-item label="流程说明" prop="flowRemark">
        <el-input v-model="formData.flowRemark" type="textarea" placeholder="请输入流程说明" :maxlength="100"
          show-word-limit :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
      </el-form-item>
    </el-form>
    <el-dialog
      title="选择图标"
      :visible.sync="dialogVisible"
      width="612px" :append-to-body="true">
      <img
      v-for="(icon, index) in iconList"
      :key="index" :src="icon.src"
      class="icon-item"
      :class="{active: selectedIcon === icon.id}"
      @click="selectedIcon = icon.id">
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false; selectedIcon = activeIcon" size="small">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false; activeIcon = selectedIcon" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body>
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx"
        :headers="upload.headers"
        :action="upload.url"
        :disabled="upload.isUploading"
        :on-progress="handleImportProgress"
        :on-success="handleImportSuccess"
        :on-error="handleImportError"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          <el-link type="info" style="font-size:12px" @click="handleImportTemplate">下载模板</el-link>
        </div>
        <div class="el-upload__tip" style="color:red" slot="tip">提示：仅允许导入”xlsx”格式文件！</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitImportForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { listBpmCategory } from '@/api/bpm/category'
import { getAccessToken } from '@/utils/tokenTool'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {Treeselect},
  props: ['tabName', 'initiator', 'conf'],
  data() {
    const req = require.context( '@/assets/approverIcon', false, /\.png$/ )
    const iconList = req.keys().map((t, idx) => ({src: req(t), id: "" +idx}))
    return {
      dialogVisible: false,
      activeIcon: iconList[0].id,
      selectedIcon: iconList[0].id,
      uploadIconList:[],
      uploadHeaders: {},
      hideUploadBtnImg:false,
      upload: {
        open: false,
        title: "",
        type:"initiator",
        isUploading: false,
        headers: { Authorization: getAccessToken() },
        url: process.env.VUE_APP_BASE_API + "/bpm/import/initiator"
      },
      formData: {
        flowCode: '',
        flowName: '',
        flowImg: '',
        flowCategory: undefined,
        flowRemark: undefined,
        initiator: null,
        unInitiator:null,
        adminId: undefined,
        notify: false,
        notifyTitle:'',
        notifyType:''
      },
      notifyTypes:[
        {type:'app',name:'App通知'},
        {type:'gxyx',name:'办事大厅'},
        {type:'yiban',name:'易班通知'},
        {type:'sms',name:'短信通知'},
        {type:'weixin',name:'微信通知'},
      ],
      rules: {
        flowCode: [{
          required: true,
          message: '请输入流程Key',
          trigger: 'blur'
        }],
        flowName: [{
          required: true,
          message: '请输入流程名称',
          trigger: 'blur'
        }],
        flowCategory: [{
          required: true,
          message: '请选择选择分类',
          trigger: 'change'
        }],
      },
      iconList,
      flowCategoryOptions: [],
    }
  },
  computed: {
    activeIconSrc(){
      const icon = this.iconList.find(t => t.id === this.activeIcon)
      return icon ? icon.src : ''
    }
  },
  created() {
    this.uploadHeaders = {
      "Authorization": getAccessToken()
    }
    if (typeof this.conf === 'object' && this.conf !== null) {
      Object.assign(this.formData, this.conf)
        if(!this.formData.flowCode) {
            this.formData.flowCode = 'process_' + new Date().getTime()
        }
      if(this.formData.flowImg && this.formData.flowImg != "" && this.formData.flowImg.length>10) {
        this.uploadIconList.push({
          url:this.formData.flowImg
        })
        this.hideUploadBtnImg = true
      }else {
        this.formData.flowImg = ''
      }
      //默认不发送
      this.formData.notify = false

    }

    listBpmCategory({  pageNum: 1, pageSize: 9999 }).then(response => {
      this.flowCategoryOptions = this.handleTree(response.data.list, "id","parent_id");
    });
  },

  methods: {
    handleUploadSuccess(response, file, fileList) {
      file.url = response.data.url
      this.formData.flowImg = response.data.url
      this.hideUploadBtnImg = true
    },
    handleUploadError(err) {
      this.formData.flowImg = ''
      this.hideUploadBtnImg = false
      this.msgError("上传图标失败");
    },
    handleUploadRemove() {
      this.hideUploadBtnImg = false
      this.formData.flowImg = ''
    },
    handleUploadExceed() {
      this.msgError("只能上传一个图片，请删除后重新上传");
    },
    beforeUpload(file){
      return new Promise((resolve, reject) => {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        let fileType = [ "png","jpg"]
        //如果文件类型不在允许上传的范围内
        if(!fileType.includes(FileExt)){
          reject(this.msgError("上传文件格式不正确!"))
          return;
        }
        //计算文件的大小
        const isLt = file.size / 1024 < 10; //这里做文件大小限制
        //如果大于10K
        if (!isLt) {
          reject(this.msgError('上传文件大小不能超过 10KB!'))
          return;
        }
        const _URL = window.URL || window.webkitURL
        const img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== 200 || img.height !== 200) {
            reject(this.msgError(`请上传尺寸为 200*200的图片`))
          }
          resolve(true);
        }
      })
    },
    handleImport(type) {
      this.upload.title = "发起人导入"
      this.upload.type = type
      this.upload.url = process.env.VUE_APP_BASE_API + "/bpm/import/"+type
      this.upload.open = true
    },
    handleImportTemplate() {
      this.downloadbyurl('/bpm/importTemplate/initiator')
    },
    handleImportProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },

    handleImportSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      let data = response.data
      this.$alert(response.code == 0 ? '导入成功'+data.success+"条" : response.msg, "提示", { dangerouslyUseHTMLString: true });
      let initiator = {
        'dep&user':JSON.parse(data.data),
        'role':[]
      }
      if(this.upload.type == 'initiator') {
        this.formData.initiator = initiator
        this.emitInitiator()
      }else {
        this.formData.unInitiator = initiator
      }
    },

    handleImportError(err, file, fileList) {
      this.upload.isUploading = false;
      this.$alert('导入失败', "提示", { dangerouslyUseHTMLString: true });
    },

    submitImportForm() {
      this.$refs.upload.submit();
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },
    emitChangeFlowName() {
      this.$emit('flowNameChange', this.formData.flowName)
    },
    emitInitiator(){
      this.$nextTick(()=>{
        this.$emit('initiatorChange', this.formData.initiator, this.$refs['org-select'].selectedLabels)
      })
    },
    // 给父级页面提供得获取本页数据得方法
    getData() {
      return new Promise((resolve, reject) => {
        this.$refs['elForm'].validate(valid => {
          if (!valid) {
            reject({ target: this.tabName})
            return
          }
          resolve({ formData: this.formData, target: this.tabName})  // TODO 提交表单
        })
      })
    },
  },
  watch:{
    initiator:{
      handler (val) {
        this.formData.initiator = val
      },
      immediate: true,
      deep:true
    }
  }
}
</script>
<style lang="stylus" scoped>
/deep/ .disUploadSty .el-upload--picture-card{
  display:none;   /* 上传按钮隐藏 */
}
.icon-item{
  width 40px
  height 40px
  margin: 6px;
  position relative
  cursor pointer
  opacity .5

  &.active{
    opacity 1
  }
  &:hover{
    opacity 1
  }
}

.setting-container{
  width: 600px;
  height: 100%;
  margin: auto;
  background: white;
  padding: 16px;

  >>>.el-form--label-top .el-form-item__label{
    padding-bottom: 0;
  }
}
</style>>

