<template>
 
    <el-dialog
      :visible.sync="linkDialogVisible"
      title="表单联动规则"
      width="800px"
      append-to-body
      :before-close="close"
    >
    <div class="link-dialog">
      <el-tabs v-model="activeName" style="width: 100%">
        <el-tab-pane label="已有规则" name="second">
          <div class="mb-10">
            <el-button type="primary" @click="addRule">新增</el-button>
          </div>
          <jf-table
            :columns="tableColumn"
            :data="formLinks"
            :pager="false"
            :checkbox="false"
          >
            <template slot="toolbar_btn_row" slot-scope="row">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="toEdit(row.row)"
                >修改
              </el-button>
              <el-button
                @click="toRemove('list', row.rowIndex, 'list')"
                size="mini"
                type="text"
                icon="el-icon-delete"
                >删除
              </el-button>
            </template>
          </jf-table>
        </el-tab-pane>
        <el-tab-pane label="添加规则" name="first">
          <el-input placeholder="规则名称，如规则1" v-model="form.name">
          </el-input>
          <div class="fields-box d-flex pos-r mt-20 align-items-center">
            <div
              class="fields-box-left pos-a mb-15"
              v-if="fieldData.length > 1"
            >
              <div class="d-flex align-items-center">
                <el-select
                  v-model="form.calculationType"
                  size="mini"
                  style="width: 60px !important"
                  v-if="form.fieldRules.length > 1"
                  placeholder="请选择"
                >
                  <el-option label="且" value="&&"> </el-option>
                  <el-option label="或" value="||"> </el-option>
                </el-select>
                <i
                  class="el-icon-circle-plus-outline font-size-24"
                  @click="toAdd('fieldRules')"
                ></i>
              </div>
              <div
                class="condition-box-line-left"
                v-if="form.fieldRules.length > 1"
              ></div>
            </div>
            <div
              class="w-100 fields-box-right pos-r"
              :class="[
                form.fieldRules.length > 1 ? 'condition-box-line-left' : '',
                fieldData.length > 1 ? 'ml-30' : '',
              ]"
            >
              <div
                class="hide-line-top"
                v-if="form.fieldRules.length > 1"
              ></div>
              <div
                class="hide-line-bottom"
                v-if="form.fieldRules.length > 1"
              ></div>
              <div
                class="pos-r d-flex align-items-center mb-15"
                v-for="(item, index) in form.fieldRules"
                :key="index"
              >
                <div
                  class="condition-box-line-top"
                  v-if="form.fieldRules.length > 1"
                  style="padding-left: 120px"
                ></div>
                <el-row :gutter="10" class="link-condition-box">
                  <el-col :span="6">
                    <el-select
                      v-model="item.condition"
                      @change="(e) => conditionChange(e, index)"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in fieldData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.formId"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="6" v-if="item.condition">
                    <el-select
                      v-model="item.calculationType"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in calculationTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="6" v-if="item.condition" style="width: 121px">
                    <el-radio-group v-model="item.valueType">
                      <el-radio-button label="1">固定</el-radio-button>
                      <el-radio-button label="2">动态</el-radio-button>
                    </el-radio-group>
                  </el-col>
                  <el-col :span="6" v-if="item.condition">
                    <el-input
                      v-model="item.value"
                      placeholder="请输入"
                      v-if="item.valueType != 2"
                    ></el-input>
                    <el-select
                      v-model="item.value"
                      v-if="item.valueType == 2"
                      style="width: 100%"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in fieldData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.formId"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>

                <div class="pos-a condition-box-btns d-flex align-items-center">
                  <i
                    class="el-icon-circle-plus-outline font-size-18"
                    v-if="fieldData.length > 1"
                    @click="toAdd('fieldRules')"
                  ></i>
                  <i
                    class="el-icon-circle-plus-outline font-size-18 ml-10 el-icon-delete"
                    v-if="form.fieldRules.length > 1"
                    @click="toRemove('fieldRules', index)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-20">
            <div
              class="rule-actions pos-r"
              v-for="(item, index) in form.actionList"
              :key="index"
            >
              <i
                class="el-icon-circle-plus-outline font-size-18 z-index-3 el-icon-delete pos-a condition-box-btns"
                v-if="form.fieldRules.length > 1"
                @click="toRemove('actionList', index)"
              ></i>
              <div class="btn">
                <el-button
                  style="background: #fff"
                  size="medium"
                  type="text"
                  icon="el-icon-circle-plus-outline"
                  @click="toAdd('actionList')"
                  >添加动作（满足条件时执行）</el-button
                >
              </div>
              <el-row :gutter="10" class="link-condition-box">
                <el-col :span="12">
                  <el-select v-model="item.action" placeholder="请选择">
                    <el-option
                      v-for="item in actionTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12" v-if="item.action">
                  <el-select v-model="item.field" clearable  placeholder="请选择" multiple>
                    <el-option
                      v-for="item in fieldData"
                      :key="item.value"
                      :label="item.label"
                      :value="item.formId"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    type="textarea"
                    rows="3"
                    v-if="item.action == 5"
                    placeholder="请输入"
                    v-model="item.value"
                    class="mt-10"
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="d-flex justify-content-end mt-20">
        <el-button @click="close">关闭</el-button>
        <el-button type="warning" v-if="activeName === 'first'" @click="cancel"
          >清空</el-button
        >
        <el-button
          type="primary"
          v-if="activeName === 'first'"
          @click="onSubmit"
          >保存</el-button
        >
      </div>
    </div>
    </el-dialog>
 
</template>

<script>
import JfTable from "@/components/jftable/jftable";

export default {
  components: {
    JfTable,
  },
  props: {
    fieldList: {
      type: Array,
      default: () => [],
    },
    formLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
      fieldData: [],
      tableColumn: [
        {
          field: "name",
          title: "规则名称",
          width: "80%",
        },
        {
          field: "",
          title: "操作",
          width: "20%",
          // fixed: "right",
          align: "center",
          slots: {
            default: "defaultopr",
          },
        },
      ],
      activeName: "second",
      linkDialogVisible: false,
      form: {
        name: "",
        calculationType: "&&",
        fieldRules: [
          {
            condition: "",
            calculationType: "",
            valueType: "1",
            value: "",
          },
        ],
        actionList: [{ action: "" }],
      },

      calculationTypes: [
        {
          value: "=",
          label: "等于",
        },
        {
          value: "≠",
          label: "不等于",
        },
        {
          value: "⊆",
          label: "包含",
        },
        {
          value: "⊄",
          label: "不包含",
        },
      ],

      actionTypes: [
        { value: "1", label: "显示表单字段" },
        { value: "2", label: "隐藏表单字段" },
        { value: "3", label: "允许编辑表单字段" },
        { value: "4", label: "禁止编辑表单字段" },
        { value: "5", label: "设置表单字段值" },
      ],
      name: "",
    };
  },
  watch: {
    formLinks: {
      handler(val) {
      },
      immediate: true,
      deep: true,
    },
    fieldList: {
      handler(val) {
        // let arr = []
        // val.forEach(o=>{
        //     if(o.layout==='rowFormItem'){
        //         if(o.children.length){
        //             console.log(o.children)
        //             for(let i=0;i<o.children.length;i++){
        //                 arr.push( o.children[i])
        //             }
        //         }
        //     }else{
        //         arr.push(o)
        //     }

        // })
        this.fieldData = val;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toEdit(e) {
      this.form = e;
      this.activeName = "first";
    },
    addRule() {
      this.form = {
        name: "",
        calculationType: "&&",
        fieldRules: [
          {
            condition: "",
            calculationType: "",
            valueType: "1",
            value: "",
          },
        ],
        actionList: [{ action: "" }],
      };
      this.activeName = "first";
    },
    toAdd(list) {
      this.form[list].push({
        condition: "",
        calculationType: "",
        valueType: "1",
        value: "",
      });
    },
    toRemove(list, index, type) {
      if (type === "list") {
        this.$emit("toRemove", index);
        return;
      }
      this.form[list].splice(index, 1);
    },
    cancel() {
      this.form = {
        name: "",
        calculationType: "&&",
        fieldRules: [
          {
            condition: "",
            calculationType: "",
            valueType: "1",
            value: "",
          },
        ],
        actionList: [{ action: "" }],
      };
      this.list = [];
    },
    close() {
      this.cancel();
      this.$emit('close')
      this.linkDialogVisible = false;
    },
    conditionChange(e, index) {
      //   let i = this.fieldData.findIndex((o) => o.formId == e);
      //   this.form.fieldRules[index]["vModel"] = this.fieldData[i].vModel;
    },
    onSubmit() {
      if (!this.form.name) {
        this.$message.warning("请输入规则名称");
        return;
      }
      if (
        this.form.actionList.filter((o) => !o.field || !o.action).length > 0
      ) {
        this.$message.warning("请选择字段和动作");
        return;
      }
      if (
        this.form.fieldRules.filter(
          (o) => !o.condition || !o.calculationType
        ).length > 0
      ) {
        this.$message.warning("请选择条件、计算类型、值");
        return;
      }
      let list = JSON.parse(JSON.stringify(this.formLinks||[]));
      if (this.form.id) {
        let index = list.findIndex((o) => o.id == this.form.id);
        list.splice(index, 1, this.form);
      } else {
        this.form.id = list.length+'_'+new Date().getTime();
        list.push(this.form);
      }

      this.activeName = "second";
      console.log(list)
      this.$emit("submit", list);
      // this.cancel()
    },
  },
};
</script>
<style lang="scss" scoped>
.link-dialog {
  .fields-box {
    height: 100%;
    .hide-line {
      &-top {
        position: absolute;
        top: 0;
        left: -1px;
        height: 26px;
        width: 2px;
        z-index: 2;
        background: #fff;
      }
      &-bottom {
        position: absolute;
        bottom: -2px;
        left: -1px;
        height: 41px;
        width: 2px;
        z-index: 2;
        background: #fff;
      }
    }
  }
  .fields-box-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    //   position: relative;
    z-index: 1;
    background: #fff;
  }
  // .fields-box-right {
  //   margin-left: -9%;
  // }
  .link-condition-box {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    background: #f7f7f9;
  }
  .condition-box-btns {
    right: 0;
    top: -10px;
  }
  .d-flex {
    display: flex;
  }
  .condition-box-line {
    &-top {
      width: 80px;
      border-top: 1px dashed #e5e5e5;
    }
    &-left {
      height: 100%;
      border-left: 1px dashed #e5e5e5;
    }
  }
  .rule-actions {
    padding: 15px;
    border: 1px dashed #e5e5e5;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    .btn {
      position: absolute;
      z-index: 1;
      top: -20px;
      width: 100%;
      display: flex;
      justify-content: center;
      // background: #fff;
    }
  }
}
</style>
