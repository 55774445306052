<template>
 <div class="app-container">    
  <el-col class="card-box">
    <el-card style="height:400px;">
      <div slot="header">
        <div style="display:inline-block;font-weight:bolder;">设备属性</div>              
      </div>
      <div class="v-chart" id="mychart" :style="myChartStyle"></div>
    </el-card>
  </el-col> 
  <el-col class="card-box" >
    <el-card style="height:600px;">
      <div slot="header">
        <div style="display:inline-block;font-weight:bolder;">设备通信</div>  
        <div style="display:inline-block;float:right;margin-right: 5px;font-size:13px;color:#859094;" >
          【说明】平台发送给设备的topic默认组合成为：{设备ID}+/platform/+{主题Topic}
        </div>            
      </div>  
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">        
        <el-form-item label="主题Topic" prop="topic">
          <el-input v-model="form.topic" />
        </el-form-item> 
        <el-form-item label="消息内容" prop="payload">
          <el-input style=" width: 100%; " v-model="form.payload" type="textarea" />
        </el-form-item>
      </el-form>      
      <div style="display: flex">
        <div style="flex: 1"></div>
        <el-button type="primary" @click="submitForm">发 送</el-button>
        <div style="display: flex;flex: 1">
          <div style="flex: 1"></div>
          <i style="flex: 1;text-align: center;margin: auto;" class="el-icon-sort"></i>
          <div style="flex: 1"></div>
        </div>        
        <el-button @click="cancel">重 置</el-button>
        <div style="flex: 1"></div>        
      </div>
      <el-form ref="form" :model="form" label-width="120px" style="margin-top: 20px;">   
        <el-form-item label="平台最新接收" prop="backpayload">
          <el-input readonly style=" width: 100%; " v-model="form.backpayload" type="textarea" :autosize="{ minRows: 14, maxRows: 14}"/>
        </el-form-item>
      </el-form>  
    </el-card>    
  </el-col>  
 </div>
</template>

<script>
	
import * as echarts from "echarts"
//getIotDeviceAttributeNew
import { getIotDeviceAttributeNew,getIotDeviceNewMessage} from "@/api/iot/device";
import {publishMessage} from "@/api/iot/platform";
export default {
  
  props: {
    deviceId: {
      type: String
    }
  },
  data() {   
    return {
      sendcommandAttributeList:[{name:"name1",value:"1000"},{name:"name2",value:"2000"}],
      is_now:0,
      sendmaster:[{key:0,value:"缓存下发"},{key:1,value:"立即下发"}],
      form:{},
      commandtitle:"这里是动作名称",
      drawer: false,
      direction: 'btt',
      attList:[],
      attTotal:0,
      timer: null,
      myChart: null,
      option: null,
      device_id:0,
      xData: [], //属性名称
      yData: [], //数据
      myChartStyle: { float: "left", width: "100%", height: "300px" }, //图表样式  
      rules:{
        topic: [
          { required: true, message: "topic不能为空", trigger: "blur" }
        ]
      }    
    }
  }, 
  created() {  
    if(this.deviceId != undefined){
      this.device_id=parseInt(this.deviceId);   
    }else{
      this.msgError("获取设备信息失败！");
    }    
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {         
          this.form.device_id=this.device_id;
          this.form.delay_time=0;
          publishMessage(this.form).then(response => {
            if (response.code ===0) {
              this.msgSuccess("发送消息成功！");              
            } else {
              this.msgError(response.msg);
            }
          });          
        }
      });
    },
     // 取消按钮
    cancel() {      
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        topic: "",       
        payload: "",        
      };
      //this.resetForm("form");
    },
    getMessage(){
      getIotDeviceNewMessage(this.device_id).then(response=>{  
        if(response.data!=null&&response.data.device_id==this.device_id){     
          this.$set(this.form, "backpayload", "上报时间:"+this.timestampToTime(response.data.create_time)+
          "  \r\n接入用户名:"+response.data.dept_name+"  设备ClientID:"+response.data.client_id+
          "  \r\nTopic主题:"+response.data.topic_name+"  \r\nQos:"+response.data.qos+
          "  \r\n数据内容(payload):"+response.data.payload);          
        }
      });
    },    
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
    getAttributeList(){
      getIotDeviceAttributeNew(this.device_id).then(response=>{       
        for(var i=0;i<response.data.list.length;i++){
          this.xData[i]=response.data.list[i].attribute_name+"\r\n"+this.timestampToTime(response.data.list[i].update_time);
          this.yData[i]=response.data.list[i].value;         
        } 
        //this.attList=response.data.list;
        this.attTotal=response.data.total;
      })
          
    },
    initEcharts() {
      // 基本柱状图
      this.option = {
        title: {
          text: '实时上报属性',          
          left: 'center'
        },
        xAxis: {
          data: this.xData
        },
        yAxis: {},
        // 图例
        /* legend: {
          data: ["属性上报值"],
          left: 'left'
        }, */
        series: [
          {
            type: "bar", //形状为柱状图
            name: "属性上报值",
            label:{
              show:true,
              position:'top',
            },
            data: this.yData            
          }
        ]
      };
      this.myChart = echarts.init(document.getElementById("mychart"));
      this.myChart.setOption(this.option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
      this.timer = setInterval(()=>{ 
        this.getMessage();
        this.getAttributeList();
        this.myChart.setOption(this.option);         
      },1000)
    }
  },
  destroyed() {
    clearTimeout(this.timer);
  }
}
</script>