<template>
  <div>
    <el-dialog
    :title="title"
    width="1000px"
    :close-on-click-modal="false"
    :append-to-body="true"
     v-dialogDrag
     class="userDialog"
    :visible.sync="visible">
    <el-container style="height: 500px">
    <el-container>
      <el-header style="text-align: left; font-size: 12px;height:30px">
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" @submit.native.prevent>
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="queryParams.username"
              placeholder="请输入用户名"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="昵称" prop="nickname">
            <el-input
              v-model="queryParams.nickname"
              placeholder="请输入昵称"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
      </el-header>
      <el-main>
        <el-table
          :data="userList"
          v-loading="loading"
          size="small"
          ref="table"
          @selection-change="handleSelectionChange"
          height="calc(100% - 80px)"
          style="width: 100%;">
          <el-table-column
            header-align="center"
            align="center"
            v-if="limit <= 1"
            width="50">
            <template slot-scope="scope">
              <el-radio :label="scope.row.id" :value="userListAllSelections[0]&&userListAllSelections[0].id" @change.native="getTemplateRow(scope.$index,scope.row)"><span></span></el-radio>
            </template>
          </el-table-column>
          <el-table-column
            type="selection"
            header-align="center"
            v-if="limit > 1"
            align="center"
            width="50">
          </el-table-column>
          <el-table-column
            prop="avatar"
            header-align="center"
            align="center"
            label="头像">
            <template slot-scope="scope">
              <img v-if="scope.row.avatar" :src="scope.row.avatar" class="avatar"/>
            </template>
          </el-table-column>
          <el-table-column
            prop="username"
            header-align="center"
            align="center"
            min-width="90"
            label="用户名">
          </el-table-column>
          <el-table-column
            prop="nickname"
            header-align="center"
            align="center"
            min-width="90"
            label="昵称">
          </el-table-column>
          <el-table-column
            prop="group_id"
            header-align="center"
            align="center"
            min-width="90"
            label="角色组">
            <template slot-scope="scope">
              {{groupFormat(scope.row.group_id)}}
            </template>
          </el-table-column>
          <el-table-column
            prop="gender"
            header-align="center"
            align="center"
            min-width="90"
            label="性别">
            <template slot-scope="scope">
              {{genderFormat(scope.row.gender)}}
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            header-align="center"
            align="center"
            min-width="100"
            label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === '1'" size="small" type="success">正常</el-tag>
              <el-tag v-else-if="scope.row.status === '0'" size="small" type="danger">禁用</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="paginationChange"
        />
      </el-main>
    </el-container>

    <el-aside width="150px">
      <el-tag
        :key="tag.id"
        v-for="tag in userListAllSelections"
        closable
        :disable-transitions="false"
        @close="del(tag)">
        {{tag.nickname}}
      </el-tag>
  </el-aside>
</el-container>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doSubmit()">确 定</el-button>
        <el-button @click="visible = false">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import { listUser, getUser } from "@/api/ucenter/user";
  import { listUserGroup } from "@/api/ucenter/usergroup";
  export default {
    data () {
      return {
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          nickname: undefined,
          username: undefined,
        },
        userListAllSelections: [],   // 所有选中的数据包含跨页数据
        userListSelections: [],
        idKey: 'id', // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
        // 总条数
        total: 0,
        userList: [],
        userGroupList: [],
        // 状态数据字典
        statusOptions: [],
        genderOptions: [],
        loading: false,
        visible: false
      }
    },
    props: {
      selectData: {
        type: Array,
        default: () => { return [] }
      },
      title: {
        type: String,
        default: () => { return '会员选择' }
      },
      limit: {
        type: Number,
        default: 999999
      }
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          this.userListAllSelections = JSON.parse(JSON.stringify(this.selectData))
          listUserGroup().then(response => {
            this.userGroupList = response.data.list
          })
          this.resetQuery();
        })
      },
      getTemplateRow (index, row) {                                 // 获取选中数据
        this.userListSelections = [row]
        this.$nextTick(() => {
          this.changePageCoreRecordData()
        })
      },
      // 设置选中的方法
      setSelectRow () {
        if (!this.userListAllSelections || this.userListAllSelections.length <= 0) {
          //清空选择
          this.$refs.table.clearSelection()
          return
        }
        // 标识当前行的唯一键的名称
        let idKey = this.idKey
        let selectAllIds = []
        this.userListAllSelections.forEach(row => {
          selectAllIds.push(row[idKey])
        })
        this.$refs.table.clearSelection()
        for (var i = 0; i < this.userList.length; i++) {
          if (selectAllIds.indexOf(this.userList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.table.toggleRowSelection(this.userList[i], true)
          }
        }
      },
      // 记忆选择核心方法
      changePageCoreRecordData () {
        // 标识当前行的唯一键的名称
        let idKey = this.idKey
        let that = this
        // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
        if (this.userListAllSelections.length <= 0) {
          this.userListSelections.forEach(row => {
            that.userListAllSelections.push(row)
          })
          return
        }
        // 总选择里面的key集合
        let selectAllIds = []
        this.userListAllSelections.forEach(row => {
          selectAllIds.push(row[idKey])
        })
        let selectIds = []
                // 获取当前页选中的id
        this.userListSelections.forEach(row => {
          selectIds.push(row[idKey])
          // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
          if (selectAllIds.indexOf(row[idKey]) < 0) {
            that.userListAllSelections.push(row)
          }
        })
        let noSelectIds = []
              // 得到当前页没有选中的id
        this.userList.forEach(row => {
          if (selectIds.indexOf(row[idKey]) < 0) {
            noSelectIds.push(row[idKey])
          }
        })
        noSelectIds.forEach(id => {
          if (selectAllIds.indexOf(id) >= 0) {
            for (let i = 0; i < that.userListAllSelections.length; i++) {
              if (that.userListAllSelections[i][idKey] === id) {
                                // 如果总选择中有未被选中的，那么就删除这条
                that.userListAllSelections.splice(i, 1)
                break
              }
            }
          }
        })
      },
     // 得到选中的所有数据
      getAllSelectionData () {
         // 再执行一次记忆勾选数据匹配，目的是为了在当前页操作勾选后直接获取选中数据
        this.changePageCoreRecordData()
      },
      del (tag) {
        this.userListAllSelections.splice(this.userListAllSelections.indexOf(tag), 1)
        this.$nextTick(() => {
          this.setSelectRow()
        })
      },
      groupFormat(groupid) {
        for (let i = 0; i < this.userGroupList.length; i++) {
          const u = this.userGroupList[i]
          if (u.id == groupid) {
            return u.name
          }
        }
        return ''
      },
      statusFormat({row}) {
        return this.selectDictLabel(this.statusOptions.values, row.status);
      },
      genderFormat(gender) {
        return this.selectDictLabel(this.genderOptions.values, gender);
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.resetForm("queryForm");
        this.handleQuery();
      },
      // 获取数据列表
      getList () {
        this.loading = true
        listUser(this.queryParams).then(response => {
          this.userList = response.data.list;
          this.statusOptions = response.data.statusOptions;
          this.genderOptions = response.data.genderOptions; 
          this.total = response.data.total;
          this.loading = false;
          this.$nextTick(() => {
            this.setSelectRow()
          })
        });
      },
      paginationChange() {
        this.getList()
        this.$nextTick(() => {
          this.changePageCoreRecordData()
        })
      },
      // 多选框选中数据
      handleSelectionChange(val) {
        this.userListSelections = val
        this.$nextTick(() => {
          this.changePageCoreRecordData()
        })
      },
      doSubmit () {
        if (this.limit < this.userListAllSelections.length) {
          this.$message.error(`你最多只能选择${this.limit}个用户`)
          return
        }
        this.visible = false
        this.$emit('doSubmit', this.userListAllSelections)
      }
    }
  }
</script>
<style lang="scss">
.avatar {
    width: 35px;
    height: 35px;
    margin-left: 0px;
    margin-top: 4px;
  }
.userDialog{
  .el-dialog__body {
    padding: 10px 0px 0px 10px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}
</style> 
