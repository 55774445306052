import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2023-09-12 12:51:14
class Record {
// 查询record列表
 listRecord(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/ai/record/list',
        method: 'post',
        data: data
    })
}

// 查看record
 getRecord ( id) {
    return request({
        url: '/ai/record/get?id='+id,
        method: 'get'
    })
}


// 新增record
 addRecord(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/ai/record/add',
        method: 'post',
        data: postdata
    })
}

// 修改record
 editRecord(mid,data) {
  var postdata={
          formdata:JSON.stringify(data),
          mid:mid,
          dataid:data.id
      }

    return request({
        url: '/ai/record/edit',
        method: 'post',
        data: postdata
    })
}

// 删除record
 delRecord(ids) {
    return request({
        url: '/ai/record/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 recordImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/ai/record/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 recordExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/ai/record/export?` + parata.join("&") ;


 }

 //下导入模板
  recordimportTemplate() {

     return   `/ai/record/importTemplate`   ;

  }
  removeContext(chatId,botId=0) {
    return request({
        url: '/ai/record/removeContext',
        method: 'post',
        data:{chat_id:chatId, bot_id:botId}
    })
}

}

const instance = new Record();
export default instance;