import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
// 生成时间:2024-03-19 14:38:32
class Zyjktijian {
// 查询zyjktijian列表
 listZyjktijian(query) {
    var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {}
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

    return request({
        url: '/product/zyjktijian/list',
        method: 'post',
        data: data
    })
}

// 查看zyjktijian
 getZyjktijian ( id) {
    return request({
        url: '/product/zyjktijian/get?id='+id,
        method: 'get'
    })
}


// 新增zyjktijian
 addZyjktijian(mid,data) {
  var postdata={
          form_org_data:JSON.stringify(data),
          mid:mid
      }
    return request({
        url: '/product/zyjktijian/add',
        method: 'post',
        data: postdata
    })
}

// 修改zyjktijian
 editZyjktijian(mid,data) {
  var postdata={
          form_org_data:JSON.stringify(data),
          id:data.id
      }

    return request({
        url: '/product/zyjktijian/edit',
        method: 'post',
        data: postdata
    })
}

// 删除zyjktijian
 delZyjktijian(ids) {
    return request({
        url: '/product/zyjktijian/del',
        method: 'post',
        data:{ids:ids}
    })
}



//导入数据
 zyjktijianImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/product/zyjktijian/import/" + mid,
        method: 'post',
        data: data
    })

}


//获取导出下载连接
 zyjktijianExportUrl(mid, query,fieldlist) {
     var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist:Array.isArray(fieldlist)?fieldlist.join("|"):fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata=[]
    for(let i in data){
        parata.push(i+"="+data[i])
    }


    return   `/product/zyjktijian/export?` + parata.join("&") ;


 }

 //下导入模板
  zyjktijianimportTemplate() {

     return   `/product/zyjktijian/importTemplate`   ;

  }

}

const instance = new Zyjktijian();
export default instance;