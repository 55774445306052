import request from '@/utils/request'
// 生成时间:2021-09-07 17:26:12

// 查询bookstype列表
export function listBookstype(query) {
    var data={
        filterstr:"",
        orderby:"",
        opstr:query.opstr ||{},
        page_size:query.pageSize,
        page:query.pageNum
    };
    var filterstr={};
    for(var k in query){
     var item=query[k];
     if(k!="pageSize" && k!="pageNum"&&k!="opstr"){
       filterstr[k]=item;
     }
    }
    data["filterstr"] = JSON.stringify(filterstr);
    data["opstr"] = JSON.stringify(data["opstr"]);
    return request({
        url: '/gen/library/bookstype/list',
        method: 'post',
        data: data
    })
}

// 查询bookstype详细
export function getBookstype (id) {
    return request({
        url: '/gen/library/bookstype/get?id='+id,
        method: 'get'
    })
}


// 新增bookstype
export function addBookstype(data) {
    return request({
        url: '/gen/library/bookstype/add',
        method: 'post',
        data: data
    })
}

// 修改bookstype
export function editBookstype(data) {
    return request({
        url: '/gen/library/bookstype/edit',
        method: 'post',
        data: data
    })
}

// 删除bookstype
export function delBookstype(ids) {
    return request({
        url: '/gen/library/bookstype/del',
        method: 'post',
        data:{ids:ids}
    })
}

