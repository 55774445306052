<!-- 生成时间:2021-08-26 17:45:23 -->

<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
              <el-form-item label="id" prop="id">
  <el-input
  v-model="queryParams.id"
  placeholder="请输入id"
  clearable
  size="small"
  @keyup.enter.native="handleQuery"
 />
</el-form-item>
        <el-form-item label="姓名" prop="name">
  <el-input
  v-model="queryParams.name"
  placeholder="请输入姓名"
  clearable
  size="small"
  @keyup.enter.native="handleQuery"
 />
</el-form-item>
        <el-form-item label="性别" prop="sex">
  
  <el-select v-model="queryParams.sex" placeholder="请选择性别"
      clearable size="small">
      <el-option
      v-for="dict in sexOptions"
      :key="dict.key"
      :label="dict.value"
      :value="dict.key"
      />
  </el-select>
</el-form-item>
        <el-form-item label="出生日期" prop="birthdate">
   <el-date-picker clearable size="small" style="width: 200px"
  v-model="queryParams.birthdate"
  type="date"
  value-format="yyyy-MM-dd"
  placeholder="选择出生日期">
 </el-date-picker>
</el-form-item>

        <el-form-item>
            <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
    </el-form>



     <vxe-grid
                resizable
                ref="xGrid"
                stripe
                show-overflow
                highlight-hover-row
                :loading="loading"
                :toolbar-config="tableToolbar"
                :columns="tableColumn"
                :data="dataList"
                @checkbox-change="checkboxChangeEvent"
                @checkbox-all="checkboxChangeEvent">
         <template #toolbar_buttons>
                 <el-button
                         style="margin-left: 10px"
                         type="primary"
                         icon="el-icon-plus"
                         size="mini"
                         @click="handleAdd"
                         v-hasPermi="['gentest:test_men:add']"
                 >新增</el-button>
                 <el-button
                         type="success"
                         icon="el-icon-edit"
                         size="mini"
                         :disabled="single"
                         @click="handleUpdate"
                         v-hasPermi="['gentest:test_men:get','gentest:test_men:edit']"
                 >修改</el-button>
                 <el-button
                         type="danger"
                         icon="el-icon-delete"
                         size="mini"
                         :disabled="multiple"
                         @click="handleDelete"
                         v-hasPermi="['gentest:test_men:del']"
                 >删除</el-button>
                 <el-button
                         type="warning"
                         icon="el-icon-upload"
                         size="mini"
                         @click="handleUpload"
                         v-hasPermi="['gentest:test_men:upload']"
                 >导入</el-button>

                 <el-button
                         type="warning"
                         icon="el-icon-download"
                         size="mini"
                         @click="handleExport"
                         v-hasPermi="['gentest:test_men:download']"
                 >导出</el-button>
                 <el-button
                         type="primary"
                         icon="el-icon-search"
                         size="mini"
                         @click="handleAdvquery"

                 >高级查询</el-button>

         </template>
          <!--默认操作按钮-->
          <template #defaultopr="{ row }">
            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
            >修改
            </el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
            >删除
            </el-button>
          </template>

          <!--自定义空数据模板-->
          <template #empty>
                <span >
                  <p>暂无数据</p>
                </span>
          </template>

        </vxe-grid>

        <vxe-pager
                background
                size="small"
                :loading="loading"
                :current-page="queryParams.pageNum"
                :page-size="queryParams.pageSize"
                :total="total"
                @page-change="handleSizeChange">
        </vxe-pager>


            <!-- 添加或修改对话框 -->
                <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
                  <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                    <el-form-item label="姓名" prop="name">
   <el-input v-model="form.name" placeholder="请输入姓名" />

</el-form-item>
<el-form-item label="性别" prop="sex">
    <el-select v-model="form.sex" placeholder="请选择性别"
      clearable size="small">
      <el-option
      v-for="dict in sexOptions"
      :key="dict.key"
      :label="dict.value"
      :value="dict.key"
      />
  </el-select>
</el-form-item>
<el-form-item label="出生日期" prop="birthdate">
   <el-date-picker clearable size="small" style="width: 200px"
  v-model="form.birthdate"
  type="date"
  value-format="yyyy-MM-dd"
  placeholder="选择出生日期">
 </el-date-picker>
</el-form-item>


                   </el-form>



          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">返 回</el-button>
          </div>
        </el-dialog>

        <!--高级查询对话框-->
                        <el-dialog title="高级查询" :visible.sync="advqueryopen" width="1000px" append-to-body>
                          <!--<el-row :gutter="10" class="mb8">-->
                            <!--<el-col :span="1.5">-->
                              <!--过滤条件匹配-->
                            <!--</el-col>-->
                          <!--</el-row>-->

                          <vxe-grid
                                  border
                                  resizable
                                  keep-source
                                  ref="xAdvqueryGrid"
                                  id="id_xAdvqueryGrid"
                                  height="500"
                                  :columns="advqueryColumn"
                                  :toolbar-config="advqueryToolbar"
                                  :data="advqueryData"
                                  :custom-config="{storage: true}"
                                  :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
                                  @toolbar-button-click="toolbarButtonClickEvent"
                                   >
                            <template #defaultadvqueryopr="{ row }">
                              <el-button size="mini" type="text" icon="el-icon-delete" @click="handleadvqueryDeleted(row)"
                              >删除
                              </el-button>
                            </template>
                          </vxe-grid>


                          <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="advquery_save">确 定</el-button>
                            <el-button @click="cancel">取 消</el-button>
                          </div>
                        </el-dialog>

      </div>
</template>
<script>
import { listTestMen, getTestMen, delTestMen, addTestMen, editTestMen} from '@/api/gentest/test_men'
export default {
  name: "test_men",
  data() {
    return {
    //编辑器配置
    ueConfig: {
         // 初始容器高度
         initialFrameHeight: 300,
         // 初始容器宽度
         initialFrameWidth: '100%',
         // 上传文件接口
         enableAutoSave: true,
         elementPathEnable: false,
         wordCount: false,
         UEDITOR_HOME_URL: '/uediter/',
     },
    tableToolbar: {
            perfect: true,
            zoom: true,
            custom: true,
            refresh: {
               query: this.handleQuery
            },
        slots: {
            buttons: 'toolbar_buttons'
        }
          },

          // 列
          tableColumn: [
            {type: 'checkbox', width: 60, fixed: "left"},
            {field: 'id', title: 'id', minWidth: 80  },
            {field: 'name', title: '姓名', minWidth: 80  },
            {field: 'sex', title: '性别', minWidth: 80  , formatter: this.sexFormat},
            {field: 'birthdate', title: '出生日期', minWidth: 80  },

            {field: '', title: '操作', width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
          ],

      advqueryColumn:[
        {field: 'qfield', title: '查询字段', width: 180, fixed: "left", editRender: { name: 'select', options: [{ value: "dd", label: "cxvs"},{ value: "zz", label: "cxvs"} ] }, },
        {field: 'qopr', title: '操作符', width: 180, fixed: "left",   editRender: { name: 'select', options: [] }},
        {field: 'qvalue', title: '值', width: 180, fixed: "left",   editRender: { name: 'input'  }},
        {field: 'qlogic', title: '逻辑', width: 180, fixed: "left",   editRender: { name: 'select', options: [] } },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultadvqueryopr'}}
      ],
        advqueryopen:false,
        advqueryData:[],
        advqueryToolbar: {
        buttons: [
          { code: 'myInsert', name: '新增' },
        ],

      },
          // 遮罩层
          loading: true,
          // 选中数组
          ids: [],
          // 非单个禁用
          single: true,
          // 非多个禁用
          multiple: true,
          // 显示搜索条件
          showSearch: true,
          // 总条数
          total: 0,
          // 表格数据
          dataList: [],
          // 弹出层标题
          title: "",
          // 是否显示弹出层
          open: false,
            // 字典
            sexOptions: [],

        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          opstr:{
          id : "=",
          name : "LIKE",
          sex : "=",
          birthdate : "=",

          },
          id : undefined,
          name : undefined,
          sex : undefined,
          birthdate : undefined,

        },
        // 表单参数
          form: {},
          // 表单校验
          rules: {
              name: [
              { required: true, message: "姓名不能为空", trigger: "blur" }
              ],

          }
      };
    },
    created() {
        this.getList();

        setTimeout(() => {
              const $grid = this.$refs.xAdvqueryGrid
              // 异步更新下拉选项
              var ta=[{value: "", label: "请选择" }];
            for( var i in this.tableColumn){
              var e = this.tableColumn[i]
              if(e.field!=null && e.field!=""){
                ta.push({ value: e.field, label: e.title })
              }
            }
            this.advqueryColumn[0].editRender.options =ta;
            ta=[];
            ta.push({value: "=", label: "等于" });
            ta.push({value: "!=", label: "不等于" });
            ta.push({value: ">", label: "大于" });
            ta.push({value: ">=", label: "大于等于" });
            ta.push({value: "<", label: "小于" });
            ta.push({value: "<=", label: "小于等于" });
            ta.push({value: "LIKE", label: "包含" });
            ta.push({value: "NOT LIKE", label: "不包含" });
            ta.push({value: "BETWEEN", label: "在范围内" });
            this.advqueryColumn[1].editRender.options =ta;

            ta=[];
            ta.push({value: "AND", label: "并且" });
            ta.push({value: "OR", label: "或者" });
            this.advqueryColumn[3].editRender.options =ta;





            }, 300);
        // 加载字典数据
        this.getDicts("sys_user_gender").then(response => {   this.sexOptions = response.data.values || [];  });


    },
    methods: {
            handleUpload(){},
            handleExport(){},
            handleAdvquery(){
               this.advqueryopen=true
            },
      //保存条件
          advquery_save(){
            const $grid = this.$refs.xAdvqueryGrid;
            var alldata = $grid.getTableData();
            console.log("alldata",alldata);
            $grid.reloadData(alldata.fullData);//去掉编辑状态
      //      this.advqueryopen=false;

          },
          handleadvqueryDeleted (row){
            const $grid = this.$refs.xAdvqueryGrid;

            this.$confirm('是否确认删除?', "警告", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(function () {
              $grid.remove(row)
            }).then(() => {
              this.msgSuccess("删除成功");
          }).catch(function () {
            });


          },
          toolbarButtonClickEvent ({ code }) {
            const $grid = this.$refs.xAdvqueryGrid
            switch (code) {
              case 'myInsert':
                var row=$grid.insert({
                  qfield: '',
                  qopr: '=',
                  qlogic: 'AND',
                })

                $grid.setActiveRow($grid.getData(0));
                break

            }
          },

          checkboxChangeEvent ({ records }) {
            this.ids = records.map(item => item.configId)
            this.single = records .length!=1
            this.multiple = !records .length

          },

          handleSizeChange ({ currentPage, pageSize }) {
            if(this.queryParams.pageSize == pageSize){
              this.getList();
            }else{
              this.queryParams.pageSize = pageSize
              this.handleQuery();
            }
          },

        /** 查询列表 */
        getList() {
          this.loading = true;

          listTestMen(this.queryParams).then(response => {
            this.dataList = response.data.list;
            this.total = response.data.total;
            this.loading = false;
          });
        },
        // $comment字典翻译
                    sexFormat({cellValue} ) {    return this.selectDictLabel(this.sexOptions,""+cellValue); },


        // 取消按钮
        cancel() {
          this.open = false;
          this.advqueryopen=false;
          this.reset();
        },
        // 表单重置
        reset() {
          this.form = {
            name : undefined,
sex : undefined,
birthdate : undefined,

          };
          this.resetForm("form");
        },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getTestMen(id).then(response => {
        this.form = response.data.data;
        this.open = true;
        this.title = "修改";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            editTestMen(this.form).then(response => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              }
            });
          } else {
            addTestMen(this.form).then(response => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;

      this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delTestMen(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    } //methods结束
};
</script>