var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"字段信息","name":"cloum"}},[_c('vxe-grid',{ref:"xGrid",attrs:{"resizable":"","id":"id_xGrid","stripe":"","highlight-hover-row":"","loading":_vm.loading,"toolbar-config":_vm.tableToolbar,"columns":_vm.tableColumn,"data":_vm.colList,"keep-source":true,"custom-config":{ storage: true },"edit-config":_vm.editConfig},on:{"checkbox-change":_vm.checkboxChangeEvent,"edit-disabled":_vm.editDisabledEvent,"checkbox-all":_vm.checkboxChangeEvent},scopedSlots:_vm._u([{key:"html_type_default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formathtml_type(row.html_type)))])]}},{key:"html_type",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"type":"text","transfer":""},model:{value:(row.html_type),callback:function ($$v) {_vm.$set(row, "html_type", $$v)},expression:"row.html_type"}},_vm._l((_vm.html_type_list),function(item){return _c('vxe-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)]}},{key:"query_type",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"type":"text","transfer":""},model:{value:(row.query_type),callback:function ($$v) {_vm.$set(row, "query_type", $$v)},expression:"row.query_type"}},_vm._l((_vm.query_type_list),function(item){return _c('vxe-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)]}},{key:"column_type",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"type":"text","transfer":""},model:{value:(row.column_type),callback:function ($$v) {_vm.$set(row, "column_type", $$v)},expression:"row.column_type"}},_vm._l((_vm.column_type_list),function(item){return _c('vxe-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)]}},{key:"dict_type",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"clearable":true,"type":"text","transfer":""},model:{value:(row.dict_type),callback:function ($$v) {_vm.$set(row, "dict_type", $$v)},expression:"row.dict_type"}},_vm._l((_vm.dictOptions),function(item){return _c('vxe-option',{key:item.type,attrs:{"value":item.type,"label":item.name}})}),1)]}},{key:"is_insert",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0"},model:{value:(row.is_insert),callback:function ($$v) {_vm.$set(row, "is_insert", $$v)},expression:"row.is_insert"}})]}},{key:"is_edit",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0"},model:{value:(row.is_edit),callback:function ($$v) {_vm.$set(row, "is_edit", $$v)},expression:"row.is_edit"}})]}},{key:"is_list",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0"},model:{value:(row.is_list),callback:function ($$v) {_vm.$set(row, "is_list", $$v)},expression:"row.is_list"}})]}},{key:"is_query",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0"},model:{value:(row.is_query),callback:function ($$v) {_vm.$set(row, "is_query", $$v)},expression:"row.is_query"}})]}},{key:"is_required",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0"},model:{value:(row.is_required),callback:function ($$v) {_vm.$set(row, "is_required", $$v)},expression:"row.is_required"}})]}},{key:"toolbar_buttons",fn:function(){return [_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"danger","icon":"el-icon-plus","size":"mini"},on:{"click":_vm.addone}},[_vm._v("添加字段")]),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"danger","icon":"el-icon-plus","size":"mini"},on:{"click":_vm.adddef}},[_vm._v("添加默认字段")]),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"danger","icon":"el-icon-plus","size":"mini"},on:{"click":_vm.savecolumn}},[_vm._v("保存")]),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"danger","icon":"el-icon-plus","size":"mini"},on:{"click":_vm.applycolumn}},[_vm._v("应用")])]},proxy:true},{key:"defaultopr",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"text","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除 ")])]}},{key:"empty",fn:function(){return [_c('span',[_c('p',[_vm._v("暂无数据")])])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }