import request from '@/utils/request'
// 查询app订单列表
export function listOrder(query) {
  return request({
    url: '/data/app/order/list',
    method: 'get',
    params: query
  })
}

// 查询app订单详细
export function getOrder (id) {
    return request({
        url: '/data/app/order/get?id='+id,
        method: 'get'
    })
}


// 删除app
export function delOrder(ids) {
    return request({
        url: '/data/app/order/del',
        method: 'post',
        data:{ids:ids}
    })
}

