<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
			<el-form-item label="任务名称" prop="name">
				<el-input v-model="queryParams.name" placeholder="请输入任务名称" clearable size="small"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="任务组名" prop="group">
				<el-select v-model="queryParams.group" placeholder="请选择任务组名" clearable size="small">
					<el-option v-for="dict in jobGroupOptions" :key="dict.key" :label="dict.value" :value="dict.key" />
				</el-select>
			</el-form-item>
			<el-form-item label="任务状态" prop="status">
				<el-select v-model="queryParams.status" placeholder="请选择任务状态" clearable size="small">
					<el-option v-for="dict in statusOptions" :key="dict.key" :label="dict.value" :value="dict.key" />
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>

		<!-- 		<vxe-grid resizable ref="xGrid" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="jobList" @checkbox-change="checkboxChangeEvent"
			@checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini"
					@click="handleAdd">新增</el-button>
			</template>

			<template #status="{row}">
				<el-switch v-model="row.status" active-value="0" inactive-value="1"
					@change="handleStatusChange(row)"></el-switch>
			</template> -->


		<!--默认操作按钮-->
		<!-- <template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-view" @click.stop="handleUpdate(row, true)">查看
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)">删除
				</el-button>
			</template> -->

		<!--自定义空数据模板-->
		<!-- 		<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>

		</vxe-grid> -->
		<!-- <vxe-pager background size="small" :loading="loading" :current-page="queryParams.pageNum"
			:page-size="queryParams.pageSize" :total="total" @page-change="handleSizeChange">
		</vxe-pager> -->

		<jf-table :loading="loading" :columns="tableColumn" :data="jobList" :toolbar-config="tableToolbar"
			:query-params="queryParams" @checkbox-change="checkboxChangeEvent" @page-change="handleSizeChange"
			:total="total" @change="tableChange">
			<template slot="toolbar_btn_left">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
			</template>


			<template slot="defaultjob_group" slot-scope="{ row }">
				{{jobGroupFormat(row)}}
			</template>

			<template slot="defaultstatus"  slot-scope="{ row }">
				<el-switch v-model="row.status" active-value="1" inactive-value="0"
					@change="handleStatusChange(row)"></el-switch>
			</template>
			<template slot="toolbar_btn_row" slot-scope="{ row }">
				<el-button size="mini" type="text" icon="el-icon-view" @click.stop="handleUpdate(row, true)">查看
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)">删除
				</el-button>
			</template>


		</jf-table>


		<!-- 添加或修改定时任务对话框 -->
		<el-dialog :title="title" :visible.sync="open" width="860px" :close-on-click-modal="false" append-to-body>
			<jf-form v-if="open" ref="form" :model="form" :rules="rules" label-width="120px" :readonly="readonly"
				:readonly-column="1">
				<el-row>
					<el-col :span="12">
						<el-form-item label="任务名称" prop="name">
							<el-input v-model="form.name" placeholder="请输入任务名称" />
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="任务分组" prop="group">
							<el-select v-model="form.group" placeholder="请选择">
								<el-option v-for="dict in jobGroupOptions" :key="dict.key" :label="dict.value"
									:value="dict.key"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="调用目标" prop="invoke_target">
							<span slot="label">
								调用目标
								<el-tooltip placement="top">
									<div slot="content">
										示例：demo1
									</div>
									<i class="el-icon-question"></i>
								</el-tooltip>
							</span>
							<el-input v-model="form.invoke_target" placeholder="请输入调用目标字符串" />
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item prop="params" label="执行参数">
							<span slot="label">
								执行参数
								<el-tooltip placement="top">
									<div slot="content">
										以|分隔多个参数 示例：param1|param1
										<br />参数说明：支持字符串，布尔类型，长整型，浮点型，整型
									</div>
									<i class="el-icon-question"></i>
								</el-tooltip>
							</span>
							<el-input v-model="form.params" placeholder="若有参数，请输入。" />
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="cron表达式" prop="cron_expression">
							<el-input v-model="form.cron_expression" placeholder="请输入cron执行表达式" />
							<ul style="list-style: none;">
								<li><i class="el-icon-warning"></i> */5 * * * * ? : 每隔5秒执行一次</li>
								<li><i class="el-icon-warning"></i> 20 */1 * * * ? : 每隔1分钟执行一次</li>
								<li><i class="el-icon-warning"></i> 30 0 23 * * ? : 每天23点执行一次</li>
								<li><i class="el-icon-warning"></i> 0 0 1 * * ? : 每天凌晨1点执行一次</li>
								<li><i class="el-icon-warning"></i> 0 0 1 1 * ? : 每月1号凌晨1点执行一次</li>
							</ul>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="任务开始时间">
							<span slot="label">
								任务开始时间
								<el-tooltip placement="top">
									<div slot="content">
										从这个时间开始执行，可留空
									</div>
									<i class="el-icon-question"></i>
								</el-tooltip>
							</span>
							<datetime-select v-model="form.begin_time" type="datetime" placeholder="" />
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="任务结束时间">
							<span slot="label">
								任务结束时间
								<el-tooltip placement="top">
									<div slot="content">
										到这个时间结束执行，可留空
									</div>
									<i class="el-icon-question"></i>
								</el-tooltip>
							</span>
							<datetime-select v-model="form.end_time" type="datetime" placeholder="" />
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="执行策略">
							<el-radio-group v-model="form.misfire_policy">
								<el-radio label="1">重复执行</el-radio>
								<el-radio label="2">执行1次</el-radio>
								<el-radio label="3">执行N次</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>

					<el-col :span="12" v-if="form.misfire_policy=='3'">
						<el-form-item label="执行次数" prop="misfire_count">
							<el-input-number v-model="form.misfire_count" :min="1" :step="1" placeholder="请输入执行次数" />
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="等待执行结果">
							<span slot="label">
								等待执行结果
								<el-tooltip placement="top">
									<div slot="content">
										只有返回结果了才会执行下一次调用
									</div>
									<i class="el-icon-question"></i>
								</el-tooltip>
							</span>
							<el-radio-group v-model="form.wait_callback">
								<el-radio label="1">等待</el-radio>
								<el-radio label="0">不等待</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="状态">
							<el-radio-group v-model="form.status">
								<el-radio v-for="dict in statusOptions" :key="dict.key"
									:label="dict.key">{{dict.value}}</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>

					<el-col v-if="readonly" :span="24">
						<el-form-item label="执行状态">
							<el-radio-group v-model="form.invoke_status">
								<el-radio label="1">正在执行</el-radio>
								<el-radio label="0">空闲</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col v-if="readonly" :span="24">
						<el-form-item label="上次执行开始时间">
							<datetime-select v-model="form.invoke_time" type="datetime" placeholder="" />
						</el-form-item>
					</el-col>

					<el-col v-if="readonly && form.invoke_status == '0'" :span="24">
						<el-form-item label="上次执行完成时间">
							<datetime-select v-model="form.invoke_result_time" type="datetime" placeholder="" />
						</el-form-item>
					</el-col>

					<el-col v-if="readonly && form.invoke_status == '0'" :span="24">
						<el-form-item label="上次执行结果">
							<el-input v-model="form.invoke_result"/>
						</el-form-item>
					</el-col>

					<el-col v-if="readonly && form.last_invoke_error_time" :span="24">
						<el-form-item label="最后一次执行错误时间">
							<datetime-select v-model="form.last_invoke_error_time" type="datetime" placeholder="" />
						</el-form-item>
					</el-col>

					<el-col v-if="readonly && form.last_invoke_error" :span="24">
						<el-form-item label="最后一次执行错误">
							<el-input v-model="form.last_invoke_error"/>
						</el-form-item>
					</el-col>

					<el-col v-if="readonly" :span="24">
						<el-form-item label="已执行次数">
							<el-input-number v-model="form.invoke_count"/>
						</el-form-item>
					</el-col>

				</el-row>
			</jf-form>
			<div slot="footer" class="dialog-footer">
				<template v-if="!readonly">
					<el-button type="primary" @click="submitForm">确 定</el-button>
					<el-button @click="cancel">取 消</el-button>
				</template>
				<template v-else>
					<el-button @click="cancel">关 闭</el-button>
				</template>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		listJob,
		getJob,
		delJob,
		addJob,
		updateJob,
		exportJob,
		runJob,
		changeJobStatus,
		start,
		stop,
	} from "@/api/core/monitor/job";

	export default {
		name: "core-monitor-job",
		data() {
			return {
				// 工具栏
				tableToolbar: {
					perfect: false,
					zoom: true,
					custom: false,
					refresh: {
						query: this.handleQuery
					},
					slots: {
						buttons: 'toolbar_buttons'
					}
				},
				// 列
				tableColumn: [
					{
						type: 'checkbox',
						width: 60,
						fixed: "left"
					},
					
					{
						field: 'name',
						title: '任务名称',
						fixed: "left",
					},
					{
						field: 'group',
						title: '任务分组',
						slots: {
							default: 'defaultjob_group'
						},
						form_type: 2,
						options: [],
						options_label: 'value',
						options_value: 'key'

					},
					{
						field: 'invoke_target',
						title: '任务方法名'
					},
					{
						field: 'cron_expression',
						title: 'cron执行表达式'
					},
					{
						field: 'invoke_time',
						title: '执行完成时间',
						formatter: 'formatDate',
						form_type: 3,
					},
					{
						field: 'invoke_result',
						title: '执行结果'
					},
					{
						field: 'last_invoke_error',
						title: '上次执行错误'
					},
					{
						field: 'last_invoke_error_time',
						title: '上次执行错误时间',
						formatter: 'formatDate',
						visible: false
					},
					{
						field: 'invoke_count',
						title: '已执行次数'
					},
					{
						field: 'status',
						title: '状态',
						width: 100,
						slots: {
							default: 'defaultstatus'
						},

						form_type: 2,
						options: [{
								"key": '0',
								"value": "正常"
							},
							{
								"key": '1',
								"value": "停用"
							}
						],
						options_label: 'value',
						options_value: 'key'
					},
					// {
					// 	field: '',
					// 	title: '操作',
					// 	resizable: false,
					// 	width: 210,
					// 	fixed: "right",
					// 	align: "center",
					// 	slots: {
					// 		default: 'defaultopr'
					// 	}
					// }
				],

				// 遮罩层
				loading: true,
				// 选中数组
				ids: [],
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				// 总条数
				total: 0,
				// 定时任务表格数据
				jobList: [],
				// 弹出层标题
				title: "",
				// 是否查看
				readonly: false,
				// 是否显示弹出层
				open: false,
				// 是否显示详细弹出层
				openView: false,
				// 任务组名字典
				jobGroupOptions: [],
				// 状态字典
				statusOptions: [],
				// 查询参数
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					name: undefined,
					group: undefined,
					status: undefined
				},
				// 表单参数
				form: {},
				// 表单校验
				rules: {
					name: [{
						required: true,
						message: "任务名称不能为空",
						trigger: "blur"
					}],
					invoke_target: [{
						required: true,
						message: "调用目标字符串不能为空",
						trigger: "blur"
					}],
					cron_expression: [{
						required: true,
						message: "cron执行表达式不能为空",
						trigger: "blur"
					}],
					misfire_count: [{
						required: true,
						message: "执行次数不能为空",
						trigger: "blur"
					}]
				}
			};
		},
		created() {
			this.getList();

			// this.getDicts("sys_normal_disable").then(response => {
			//   this.statusOptions = response.data.values || []
			// });

			this.statusOptions = [{
					"key": '1',
					"value": "正常"
				},
				{
					"key": '0',
					"value": "停用"
				}
			]
		},
		methods: {
			// 列表筛选
			tableChange(e) {
				console.log(e)
			},
			handleSizeChange({
				currentPage,
				pageSize
			}) {
				this.queryParams.pageNum = currentPage;
				if (this.queryParams.pageSize == pageSize) {
					this.getList();
				} else {
					this.queryParams.pageSize = pageSize;
					this.handleQuery();
				}

			},
			checkboxChangeEvent({
				records
			}) {
				this.ids = records.map(item => item.id)
				this.single = records.length != 1;
				this.multiple = !records.length

			},
			/** 查询定时任务列表 */
			getList() {
				this.loading = true;
				listJob(this.queryParams).then(response => {
					this.jobList = response.data.list;
					this.total = response.data.total;
					this.loading = false;

					this.jobGroupOptions = response.data.searchGroup.values;

					for (let i = 0; i < this.tableColumn.length; i++) {
						if (this.tableColumn[i].field === 'group') {
							this.tableColumn[i].options = this.jobGroupOptions
						}
					}

				});
			},
			// 任务组名字典翻译
			jobGroupFormat(
				row
			) {
				return this.selectDictLabel(this.jobGroupOptions, row.group);
			},
			// 状态字典翻译
			statusFormat(row, column) {
				return this.selectDictLabel(this.statusOptions, row.status);
			},
			// 取消按钮
			cancel() {
				this.open = false;
				this.reset();
			},
			// 表单重置
			reset() {
				this.form = {
					id: undefined,
					name: undefined,
					params: undefined,
					group: undefined,
					invoke_target: undefined,
					cron_expression: undefined,
					misfire_policy: '1',
					concurrent: '1',
					wait_callback: '0',
					status: '0'
				};
				this.resetForm("form");
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},
			// 多选框选中数据
			handleSelectionChange(selection) {
				this.ids = selection.map(item => item.jobId);
				this.single = selection.length != 1;
				this.multiple = !selection.length;
			},
			// 任务状态修改
			handleStatusChange(row) {
				console.log(row.status)
				let text = row.status === '1' ? "启用" : "停用";
				this.$confirm(
					'确认要"' + text + '""' + row.name + '"任务吗?',
					"警告", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning"
					}
				).then(function() {

					if (row.status === '1') {
						return start(row.id);
					} else {
						return stop(row.id);
					}

				}).then(() => {
					this.msgSuccess(text + "成功");
				}).catch(function() {
					row.status = row.status === "0" ? "1" : "0";
				});

			},

			/** 任务日志列表查询 */
			handleJobLog() {
				this.$router.push("/job/log");
			},
			/** 新增按钮操作 */
			handleAdd() {
				this.reset();
				this.readonly = false;
				this.title = "添加任务";
				this.open = true;
			},
			/** 修改按钮操作 */
			handleUpdate(row, readonly) {
				this.reset();
				const jobId = row.id || 0;
				getJob(jobId).then(response => {
					this.form = response.data;
					this.readonly = readonly;
					this.title = readonly ? "查看任务" : "修改任务";
					this.open = true;
				});
			},

			/** 删除按钮操作 */
			handleDelete(row) {
				const jobIds = row.id || 0;
				this.$confirm(
						'是否确认删除该数据项?',
						"警告", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning"
						}
					)
					.then(function() {
						return delJob(jobIds);
					})
					.then(() => {
						this.getList();
						this.msgSuccess("删除成功");
					})
					.catch(function() {});
			},
			/** 提交按钮 */
			submitForm: function() {
				this.$refs["form"].validate(valid => {
					if (valid) {
						if (this.form.id != undefined) {
							updateJob(this.form).then(response => {
								if (response.code === 0) {
									this.msgSuccess("修改成功");
									this.open = false;
									this.getList();
								} else {
									this.msgError(response.msg);
								}
							});
						} else {
							addJob(this.form).then(response => {
								if (response.code === 0) {
									this.msgSuccess("新增成功");
									this.open = false;
									this.getList();
								} else {
									this.msgError(response.msg);
								}
							});
						}
					}
				});
			},

			/** 导出按钮操作 */
			handleExport() {
				const queryParams = this.queryParams;
				this.$confirm("是否确认导出所有定时任务数据项?", "警告", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning"
					})
					.then(function() {
						return exportJob(queryParams);
					})
					.then(response => {
						this.download(response.msg);
					})
					.catch(function() {});
			}
		}
	};
</script>