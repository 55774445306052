<!-- 生成时间:2022-06-16 16:41:24 -->

<template>
    <div class="app-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
        >
            <el-form-item label="学号" prop="stu_no">
                <el-input
                    v-model="queryParams.stu_no"
                    placeholder="请输入学号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="姓名" prop="stu_name">
                <el-input
                    v-model="queryParams.stu_name"
                    placeholder="请输入姓名"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="性别" prop="stu_gender">
                <el-select
                    v-model="queryParams.stu_gender"
                    placeholder="请选择性别"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in stu_genderOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="教师工号" prop="teacher_no">
                <el-input
                    v-model="queryParams.teacher_no"
                    placeholder="请输入教师工号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="教师姓名" prop="teacher_name">
                <el-input
                    v-model="queryParams.teacher_name"
                    placeholder="请输入教师姓名"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="是否线上" prop="online">
                <el-select
                    v-model="queryParams.online"
                    placeholder="请选择是否线上"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in onlineOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="周几" prop="week">
                <el-select
                    v-model="queryParams.week"
                    placeholder="请选择周几"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in weekOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="是否反馈" prop="is_feeback">
                <el-select
                    v-model="queryParams.is_feeback"
                    placeholder="请选择是否反馈"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in is_feebackOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select
                    v-model="queryParams.status"
                    placeholder="请选择状态"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in statusOptions"
                        :key="dict.key"
                        :label="dict.value"
                        :value="dict.key"
                    />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->

        <!-- <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/xlzx/record/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/xlzx/record/get', '/xlzx/record/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/xlzx/record/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/xlzx/record/export']"
                    >导出</el-button
                >
            </template> -->

        <!--默认操作按钮-->
        <!-- <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template> -->

        <!--自定义空数据模板-->
        <!-- <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid> -->

        <!-- <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handleSizeChange"
            :total="total"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="toolbar_btn_left">
                <el-button
                  
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/xlzx/record/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/xlzx/record/get', '/xlzx/record/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/xlzx/record/del']"
                    >删除</el-button
                >

                <el-button
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/xlzx/record/export']"
                    class="btn-orange"
                    ><i class="iconfont icon-daoru_o"></i> 导出</el-button
                >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <!-- 添加或修改对话框 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="学号" prop="stu_no">
                    <el-input v-model="form.stu_no" placeholder="请输入学号" />
                </el-form-item>
                <el-form-item label="姓名" prop="stu_name">
                    <el-input
                        v-model="form.stu_name"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item label="性别" prop="stu_gender">
                    <el-select
                        v-model="form.stu_gender"
                        placeholder="请选择性别"
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in stu_genderOptions"
                            :key="dict.key"
                            :label="dict.value"
                            :value="dict.key"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="学生头像" prop="stu_avatar">
                    <el-input
                        v-model="form.stu_avatar"
                        placeholder="请输入学生头像"
                    />
                </el-form-item>
                <el-form-item label="所属院系" prop="dept_no">
                    <el-input
                        v-model="form.dept_no"
                        placeholder="请输入所属院系"
                    />
                </el-form-item>
                <el-form-item label="所属专业" prop="major_no">
                    <el-input
                        v-model="form.major_no"
                        placeholder="请输入所属专业"
                    />
                </el-form-item>
                <el-form-item label="专业名称" prop="major_name">
                    <el-input
                        v-model="form.major_name"
                        placeholder="请输入专业名称"
                    />
                </el-form-item>
                <el-form-item label="所属班级" prop="classes_no">
                    <el-input
                        v-model="form.classes_no"
                        placeholder="请输入所属班级"
                    />
                </el-form-item>
                <el-form-item label="班级名称" prop="classes_name">
                    <el-input
                        v-model="form.classes_name"
                        placeholder="请输入班级名称"
                    />
                </el-form-item>
                <el-form-item label="教师ID" prop="teacher_id">
                    <el-input
                        v-model.number="form.teacher_id"
                        placeholder="请输入教师ID"
                    />
                </el-form-item>
                <el-form-item label="教师工号" prop="teacher_no">
                    <el-input
                        v-model="form.teacher_no"
                        placeholder="请输入教师工号"
                    />
                </el-form-item>
                <el-form-item label="教师姓名" prop="teacher_name">
                    <el-input
                        v-model="form.teacher_name"
                        placeholder="请输入教师姓名"
                    />
                </el-form-item>
                <el-form-item label="教师头像" prop="teacher_avatar">
                    <el-input
                        v-model="form.teacher_avatar"
                        placeholder="请输入教师头像"
                    />
                </el-form-item>
                <el-form-item label="教师性别" prop="teacher_gender">
                    <el-select
                        v-model="form.teacher_gender"
                        placeholder="请选择教师性别"
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in teacher_genderOptions"
                            :key="dict.key"
                            :label="dict.value"
                            :value="dict.key"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="用户登录邮箱" prop="email">
                    <el-input
                        v-model="form.email"
                        placeholder="请输入用户登录邮箱"
                    />
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input
                        v-model="form.mobile"
                        placeholder="请输入手机号"
                    />
                </el-form-item>
                <el-form-item label="QQ" prop="qq">
                    <el-input v-model="form.qq" placeholder="请输入QQ" />
                </el-form-item>
                <el-form-item label="地址" prop="addr">
                    <el-input v-model="form.addr" placeholder="请输入地址" />
                </el-form-item>
                <el-form-item label="希望解决" prop="question">
                    <el-input
                        v-model="form.question"
                        placeholder="请输入希望解决"
                    />
                </el-form-item>
                <el-form-item label="问题描述" prop="intro">
                    <el-input
                        v-model="form.intro"
                        placeholder="请输入问题描述"
                    />
                </el-form-item>
                <el-form-item label="名称" prop="method">
                    <el-input v-model="form.method" placeholder="请输入名称" />
                </el-form-item>
                <el-form-item label="是否线上" prop="online">
                    <el-select
                        v-model="form.online"
                        placeholder="请选择是否线上"
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in onlineOptions"
                            :key="dict.key"
                            :label="dict.value"
                            :value="dict.key"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="类型ID" prop="category_id">
                    <el-input
                        v-model.number="form.category_id"
                        placeholder="请输入类型ID"
                    />
                </el-form-item>
                <el-form-item label="问题类型" prop="category">
                    <el-input
                        v-model="form.category"
                        placeholder="请输入问题类型"
                    />
                </el-form-item>
                <el-form-item label="问题类型路径" prop="category_path">
                    <el-input
                        v-model="form.category_path"
                        placeholder="请输入问题类型路径"
                    />
                </el-form-item>
                <el-form-item label="周几" prop="week">
                    <el-select
                        v-model="form.week"
                        placeholder="请选择周几"
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in weekOptions"
                            :key="dict.key"
                            :label="dict.value"
                            :value="dict.key"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间" prop="start_time">
                    <el-input
                        v-model="form.start_time"
                        placeholder="请输入开始时间"
                    />
                </el-form-item>
                <el-form-item label="结束时间" prop="end_time">
                    <el-input
                        v-model="form.end_time"
                        placeholder="请输入结束时间"
                    />
                </el-form-item>
                <el-form-item label="预约日期" prop="book_date">
                    <el-date-picker
                        clearable
                        size="small"
                        style="width: 200px"
                        v-model="form.book_date"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择预约日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="预约标题" prop="book_title">
                    <el-input
                        v-model="form.book_title"
                        placeholder="请输入预约标题"
                    />
                </el-form-item>
                <el-form-item label="人数" prop="book_nums">
                    <el-input
                        v-model.number="form.book_nums"
                        placeholder="请输入人数"
                    />
                </el-form-item>
                <el-form-item label="是否反馈" prop="is_feeback">
                    <el-select
                        v-model="form.is_feeback"
                        placeholder="请选择是否反馈"
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in is_feebackOptions"
                            :key="dict.key"
                            :label="dict.value"
                            :value="dict.key"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="反馈内容" prop="feeback">
                    <el-input
                        v-model="form.feeback"
                        placeholder="请输入反馈内容"
                    />
                </el-form-item>
                <el-form-item label="评分" prop="rate">
                    <el-input
                        v-model.number="form.rate"
                        placeholder="请输入评分"
                    />
                </el-form-item>
                <el-form-item label="评分描述" prop="rate_text">
                    <el-input
                        v-model="form.rate_text"
                        placeholder="请输入评分描述"
                    />
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio
                            v-for="dict in statusOptions"
                            :key="dict.key"
                            :label="dict.key"
                            >{{ dict.value }}</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="审核时间" prop="check_time">
                    <el-date-picker
                        clearable
                        size="small"
                        style="width: 200px"
                        v-model="form.check_time"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择审核时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="审批原因" prop="check_reason">
                    <el-input
                        v-model="form.check_reason"
                        placeholder="请输入审批原因"
                    />
                </el-form-item>
                <el-form-item label="备注信息" prop="remark">
                    <el-input
                        v-model="form.remark"
                        placeholder="请输入备注信息"
                    />
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>

        <!--高级查询对话框-->
        <el-dialog
            title="高级查询"
            :visible.sync="advqueryopen"
            width="1300px"
            append-to-body
        >
            <el-row :gutter="20">
                <el-col :span="15" :xs="24">
                    <vxe-grid
                        border
                        resizable
                        keep-source
                        ref="xAdvqueryGrid"
                        id="id_xAdvqueryGrid"
                        height="500"
                        :columns="advqueryColumn"
                        :toolbar-config="advqueryToolbar"
                        :data="advqueryData"
                        :custom-config="{ storage: true }"
                        :edit-config="{
                            trigger: 'click',
                            mode: 'row',
                            showStatus: true,
                        }"
                        @toolbar-button-click="advqtoolbarButtonClickEvent"
                    >
                        <template #defaultadvqueryopr="{ row }"> </template>
                    </vxe-grid>
                </el-col>
                <el-col :span="9" :xs="24">
                    <vxe-grid
                        border
                        resizable
                        keep-source
                        ref="xAdvqueryGrid_tempfilter"
                        id="id_xAdvqueryGrid_tempfilter"
                        height="500"
                        :columns="advqueryColumn_tempfilter"
                        :data="advqueryData_tempfilter"
                    >
                        <template #defaultadvqueryopr_tempfilter="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleadvqueryApplytpl(row)"
                                >应用
                            </el-button>

                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleadvqueryDeltpl(row)"
                                >删除
                            </el-button>
                        </template>
                    </vxe-grid>
                </el-col>
            </el-row>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="advquery_save"
                    >确 定</el-button
                >
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!--保存模版-->
        <el-dialog
            :close-on-click-modal="false"
            title="保存模版"
            :visible.sync="advquery_tempsave"
            width="800px"
            append-to-body
        >
            <el-form
                ref="advquery_tempsaveform"
                :model="advquery_tempsaveform"
                :rules="advquery_tempsaverules"
                label-width="80px"
            >
                <el-form-item label="模版名称" prop="tempname">
                    <el-input
                        v-model="advquery_tempsaveform.tempname"
                        placeholder="请输入模版名称"
                    />
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="advquery_save"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <!--导出数据-->
        <el-dialog
            :close-on-click-modal="false"
            title="导出数据"
            :visible.sync="exportdialog"
            width="800px"
            append-to-body
        >
            <el-checkbox
                :indeterminate="exportisIndeterminate"
                v-model="exportcheckAll"
                @change="handleexportCheckAllChange"
                >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>

            <el-checkbox-group v-model="exportfields">
                <el-checkbox
                    v-for="field in tableColumn"
                    v-if="field.field"
                    :label="field.field"
                    :key="field.field"
                    >{{ field.title }}</el-checkbox
                >
            </el-checkbox-group>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doExport">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>
<script>
import { getAccessToken } from "@/utils/tokenTool";
import {
    listRecord,
    getRecord,
    delRecord,
    addRecord,
    editRecord,
    advquerydelRecord,
    advquerysaveRecord,
    advquerylistRecord,
    exportRecord,
} from "@/api/xlzx/record";
import VDistpicker from "@/components/VDistpicker";
export default {
    components: { VDistpicker },
    name: "xlzx-record-list",

    data() {
        return {
            dialogImageUrl: "",
            businessname: "record", //请勿改动
            exportfields: [],
            exportcheckAll: false,
            exportisIndeterminate: false,
            exportdialog: false,

            //编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },

            // 列
            tableColumn: [
                { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "id", minWidth: 80 },
                {
                    field: "stu_no",
                    title: "学号",
                    minWidth: 80,
                    is_query: true,
                },
                {
                    field: "stu_name",
                    title: "姓名",
                    minWidth: 80,
                    is_query: true,
                },
                {
                    field: "stu_gender",
                    title: "性别",
                    minWidth: 80,
                    formatter: this.stu_genderFormat,
                    is_query: true,
                },
                { field: "stu_avatar", title: "学生头像", minWidth: 80 },
                { field: "dept_name", title: "院系名称", minWidth: 80 },
                { field: "major_name", title: "专业名称", minWidth: 80 },
                { field: "classes_name", title: "班级名称", minWidth: 80 },
                {
                    field: "teacher_no",
                    title: "教师工号",
                    minWidth: 80,
                    is_query: true,
                },
                {
                    field: "teacher_name",
                    title: "教师姓名",
                    minWidth: 80,
                    is_query: true,
                },
                { field: "teacher_avatar", title: "教师头像", minWidth: 80 },
                { field: "method", title: "名称", minWidth: 80 },
                { field: "book_title", title: "预约标题", minWidth: 80 },
                { field: "book_nums", title: "人数", minWidth: 80 },
                {
                    field: "is_feeback",
                    title: "是否反馈",
                    minWidth: 80,
                    formatter: this.is_feebackFormat,
                    is_query: true,
                },
                {
                    field: "week",
                    title: "周几",
                    minWidth: 80,
                    visible: false,
                    is_query: true,
                },
                {
                    field: "online",
                    title: "是否线上",
                    minWidth: 80,
                    visible: false,
                    is_query: true,
                },
                {
                    field: "status",
                    title: "状态",
                    minWidth: 80,
                    options: [
                        { key: "todo", value: "待审批" },
                        { key: "pass", value: "待咨询" },
                        { key: "finish", value: "已完成" },
                        { key: "cancel", value: "已取消" },
                    ],
                    options_label: "value",
                    options_value: "key",
                    form_type: 2,
                    is_query: true,
                },
                {
                    field: "check_time",
                    title: "审核时间",
                    minWidth: 80,
                    formatter: "formatDate",
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 80,
                    formatter: "formatDate",
                },

                {
                    field: "",
                    title: "操作",
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],

            advqueryColumn: [
                {
                    field: "qfield",
                    title: "查询字段",
                    width: 180,
                    fixed: "left",
                    editRender: {
                        name: "select",
                        options: [
                            { value: "dd", label: "cxvs" },
                            { value: "zz", label: "cxvs" },
                        ],
                    },
                },
                {
                    field: "qopr",
                    title: "操作符",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "qvalue",
                    title: "值",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "input" },
                },
                {
                    field: "qlogic",
                    title: "逻辑",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 110,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultadvqueryopr" },
                },
            ],
            advqueryColumn_tempfilter: [
                {
                    field: "tempname",
                    title: "模版名称",
                    width: 200,
                    fixed: "left",
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultadvqueryopr_tempfilter" },
                },
            ],
            advqueryData_tempfilter: [],
            advqueryopen: false,
            advquery_tempsave: false,
            advqueryData: [],
            advqueryToolbar: {
                buttons: [
                    { code: "myInsert", name: "新增" },
                    { code: "dofilter", name: "使用此组合条件查询" },
                ],
            },
            upload: {
                headers: { Authorization: getAccessToken() },
                url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
            },
            dialogVisible: false,

            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 字典
            stu_genderOptions: [],
            teacher_genderOptions: [],
            onlineOptions: [],
            weekOptions: [],
            is_feebackOptions: [],
            statusOptions: [
                { key: "todo", value: "待审批" },
                { key: "pass", value: "待咨询" },
                { key: "finish", value: "已完成" },
                { key: "cancel", value: "已取消" },
            ],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                opstr: {
                    stu_no: "=",
                    stu_name: "LIKE",
                    stu_gender: "=",
                    stu_avatar: "=",
                    dept_no: "=",
                    major_no: "=",
                    major_name: "LIKE",
                    classes_no: "=",
                    classes_name: "LIKE",
                    teacher_id: "=",
                    teacher_no: "=",
                    teacher_name: "LIKE",
                    teacher_avatar: "=",
                    teacher_gender: "=",
                    email: "=",
                    mobile: "=",
                    qq: "=",
                    addr: "=",
                    question: "=",
                    intro: "=",
                    method: "=",
                    online: "=",
                    category: "=",
                    week: "=",
                    start_time: "=",
                    end_time: "=",
                    book_date: "=",
                    book_title: "=",
                    book_nums: "=",
                    is_feeback: "=",
                    feeback: "=",
                    rate: "=",
                    rate_text: "=",
                    status: "=",
                    check_time: "=",
                    check_reason: "=",
                    remark: "LIKE",
                },
                stu_no: undefined,
                stu_name: undefined,
                stu_gender: undefined,
                stu_avatar: undefined,
                dept_no: undefined,
                major_no: undefined,
                major_name: undefined,
                classes_no: undefined,
                classes_name: undefined,
                teacher_id: undefined,
                teacher_no: undefined,
                teacher_name: undefined,
                teacher_avatar: undefined,
                teacher_gender: undefined,
                email: undefined,
                mobile: undefined,
                qq: undefined,
                addr: undefined,
                question: undefined,
                intro: undefined,
                method: undefined,
                online: undefined,
                category: undefined,
                week: undefined,
                start_time: undefined,
                end_time: undefined,
                book_date: undefined,
                book_title: undefined,
                book_nums: undefined,
                is_feeback: undefined,
                feeback: undefined,
                rate: undefined,
                rate_text: undefined,
                status: undefined,
                check_time: undefined,
                check_reason: undefined,
                remark: undefined,
            },
            // 表单参数
            form: {},
            advquery_tempsaveform: {},
            advquery_tempsaverules: {
                tempname: [
                    {
                        required: true,
                        message: "模版名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            // 表单校验
            rules: {
                stu_no: [
                    {
                        required: true,
                        message: "学号不能为空",
                        trigger: "blur",
                    },
                ],

                stu_name: [
                    {
                        required: true,
                        message: "姓名不能为空",
                        trigger: "blur",
                    },
                ],

                stu_gender: [
                    {
                        required: true,
                        message: "性别不能为空",
                        trigger: "blur",
                    },
                ],

                dept_no: [
                    {
                        required: true,
                        message: "所属院系不能为空",
                        trigger: "blur",
                    },
                ],

                major_no: [
                    {
                        required: true,
                        message: "所属专业不能为空",
                        trigger: "blur",
                    },
                ],

                classes_no: [
                    {
                        required: true,
                        message: "所属班级不能为空",
                        trigger: "blur",
                    },
                ],

                teacher_gender: [
                    {
                        required: true,
                        message: "教师性别不能为空",
                        trigger: "blur",
                    },
                ],

                email: [
                    {
                        required: true,
                        message: "用户登录邮箱不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        setTimeout(() => {
            const $grid = this.$refs.xAdvqueryGrid;
            // 异步更新下拉选项
            var ta = [{ value: "", label: "请选择" }];
            for (var i in this.tableColumn) {
                var e = this.tableColumn[i];
                if (e.field != null && e.field != "") {
                    ta.push({ value: e.field, label: e.title });
                }
            }
            this.advqueryColumn[0].editRender.options = ta;

            this.advqueryColumn[1].editRender.options = this.defaultQlogic();

            ta = [];
            ta.push({ value: "AND", label: "并且" });
            ta.push({ value: "OR", label: "或者" });
            this.advqueryColumn[3].editRender.options = ta;
        }, 300);
        // 加载字典数据
        this.getDicts("stu_gender").then((response) => {
            this.stu_genderOptions = response.data.values || [];
            let i = this.tableColumn.findIndex((o) => o.field === "stu_gender");
            this.tableColumn[i].options = this.stu_genderOptions;
            this.tableColumn[i].options_label = "value";
            this.tableColumn[i].options_value = "key";
            this.tableColumn[i].form_type = "2";
        });

        this.getDicts("stu_gender").then((response) => {
            this.teacher_genderOptions = response.data.values || [];
        });

        this.getDicts("stu_yes_no").then((response) => {
            this.onlineOptions = response.data.values || [];
            for (let index=0 ;index<2;index++) {
               
                let i = this.tableColumn.findIndex((o) => o.field === (index===0?"online":'is_feeback'));
                this.tableColumn[i].options = this.onlineOptions;
                this.tableColumn[i].options_label = "value";
                this.tableColumn[i].options_value = "key";
                this.tableColumn[i].form_type = "2";
            }
        });

        this.getDicts("sys_week").then((response) => {
            this.weekOptions = response.data.values || [];
            let i = this.tableColumn.findIndex((o) => o.field === "week");
            this.tableColumn[i].options = this.weekOptions;
            this.tableColumn[i].options_label = "value";
            this.tableColumn[i].options_value = "key";
            this.tableColumn[i].form_type = "2";
        });

        this.getDicts("stu_yes_no").then((response) => {
            this.is_feebackOptions = response.data.values || [];
        });
        // for (let i = 0; i < this.tableColumn.length; i++) {
        //     switch (this.tableColumn[i].field) {
        //         case "week":
        //             this.tableColumn[i].options = this.weekOptions;
        //             break;
        //         case "is_feeback":
        //             this.tableColumn[i].options = this.is_feebackOptions;
        //             break;
        //         case "stu_gender":
        //             this.tableColumn[i].options = this.stu_genderOptions;
        //             break;
        //         case "online":
        //             this.tableColumn[i].options = this.onlineOptions;
        //             break;
        //     }
        //     this.tableColumn[i].options_label = "value";
        //     this.tableColumn[i].options_value = "key";
        //     this.tableColumn[i].form_type = "2";
        // }

        this.getList();
    },
    methods: {
        tableChange(e) {},
        //区域选择事件

        //表单上传字段成功处理
        handleImportSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            var field = response.data.return_field;

            this.form[field] = this.form[field] || [];
            this.form[field].push(response.data.url);
            this[field + "_fileList"].push({ url: response.data.url });
        },
        handleUpload() {},
        handleExport() {
            if (this.total > 0) {
                this.exportdialog = true;
            } else {
                this.msgError("暂无数据可导出!");
            }
        },
        doExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportRecord(
                        queryParams,
                        this.exportfields,
                        this.tableColumn
                    );
                })
                .then((response) => {
                    this.download(response.file);
                })
                .catch(function () {});
        },
        handleexportCheckAllChange(val) {
            this.exportfields = val
                ? this.tableColumn.map((item) => {
                      return item.field;
                  })
                : [];
            this.exportisIndeterminate = false;
        },
        //删除查询模版
        handleadvqueryDeltpl(row) {
            var ids = row.id;
            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    advquerydelRecord(ids)
                        .then(() => {
                            const $grid = this.$refs.xAdvqueryGrid_tempfilter;
                            $grid.remove(row);
                        })
                        .then(() => {
                            this.msgSuccess("删除成功");
                        });
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        //打开高级查询
        handleAdvquery() {
            advquerylistRecord(this.businessname).then((response) => {
                this.advqueryData_tempfilter = response.data.list;
                this.advqueryopen = true;
            });
        },
        //保存条件
        advquery_save() {
            const $grid = this.$refs.xAdvqueryGrid;
            var alldata = $grid.getTableData();
            if (alldata.fullData.length == 0) {
                return false;
            }

            var opstr = {};
            var filteroptview = [];
            var Qlogicar = this.defaultQlogic();
            var queryParams = {};
            var fields = {},
                Qlogic = {};

            for (var i in this.tableColumn) {
                var col = this.tableColumn[i];
                if (col.field != null && col.field != "") {
                    fields[col.field] = col.title;
                }
            }

            for (var i in Qlogicar) {
                var lgc = Qlogicar[i];
                Qlogic[lgc.value] = lgc.label;
            }

            for (var i in alldata.fullData) {
                var onerow = alldata.fullData[i];
                queryParams[onerow.qfield] = onerow.qvalue;
                opstr[onerow.qfield] = onerow.qopr;
                filteroptview.push(
                    fields[onerow.qfield] +
                        " " +
                        Qlogic[onerow.qopr] +
                        "" +
                        onerow.qvalue
                );
            }

            queryParams.opstr = opstr;
            this.advquery_tempsaveform["businessname"] = this.businessname;
            this.advquery_tempsaveform["filteropt"] =
                JSON.stringify(queryParams);
            this.advquery_tempsaveform["filteroptview"] =
                filteroptview.join(";");

            this.$refs["advquery_tempsaveform"].validate((valid) => {
                if (valid) {
                    advquerysaveRecord(this.advquery_tempsaveform).then(
                        (response) => {
                            if (response.code === 0) {
                                this.msgSuccess("保存成功");
                                this.advquery_tempsave = false;
                                this.handleAdvquery();
                            }
                        }
                    );
                }
            });
        },

        //应用查询模版
        handleadvqueryApplytpl(row) {
            var Filteropt = JSON.parse(row.filteropt);

            Filteropt["pageNum"] = 1;
            Filteropt["pageSize"] = 10;
            this.queryParams = Filteropt;
            this.advqueryopen = false;
            setTimeout(() => {
                this.getList();
            }, 300);
        },
        //删除临时条件
        handleadvqueryDeleted(row) {
            const $grid = this.$refs.xAdvqueryGrid;

            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    $grid.remove(row);
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        advqtoolbarButtonClickEvent({ code }) {
            const $grid = this.$refs.xAdvqueryGrid;
            switch (code) {
                case "myInsert":
                    var row = $grid.insert({
                        qfield: "",
                        qopr: "=",
                        qlogic: "AND",
                    });

                    $grid.setActiveRow($grid.getData(0));
                    break;

                case "dosavefilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }
                    this.advquery_tempsave = true;
                    break;
                case "dofilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }

                    var opstr = {};

                    var queryParams = {
                        pageNum: 1,
                        pageSize: 10,
                        opstr: {},
                    };

                    for (var i in alldata.fullData) {
                        var onerow = alldata.fullData[i];
                        queryParams[onerow.qfield] = onerow.qvalue;
                        opstr[onerow.qfield] = onerow.qopr;
                    }

                    queryParams.opstr = opstr;
                    this.queryParams = queryParams;
                    this.advqueryopen = false;
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    break;
            }
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        /** 查询列表 */
        getList() {
            this.loading = true;

            listRecord(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // $comment字典翻译
        stu_genderFormat({ cellValue }) {
            return this.selectDictLabel(this.stu_genderOptions, "" + cellValue);
        },

        teacher_genderFormat({ cellValue }) {
            return this.selectDictLabel(
                this.teacher_genderOptions,
                "" + cellValue
            );
        },

        onlineFormat({ cellValue }) {
            return this.selectDictLabel(this.onlineOptions, "" + cellValue);
        },

        weekFormat({ cellValue }) {
            return this.selectDictLabel(this.weekOptions, "" + cellValue);
        },

        is_feebackFormat({ cellValue }) {
            return this.selectDictLabel(this.is_feebackOptions, "" + cellValue);
        },

        // 取消按钮
        cancel() {
            this.open = false;
            this.advqueryopen = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                stu_no: undefined,
                stu_name: undefined,
                stu_gender: undefined,
                stu_avatar: undefined,
                dept_no: undefined,
                major_no: undefined,
                major_name: undefined,
                classes_no: undefined,
                classes_name: undefined,
                teacher_id: undefined,
                teacher_no: undefined,
                teacher_name: undefined,
                teacher_avatar: undefined,
                teacher_gender: undefined,
                email: undefined,
                mobile: undefined,
                qq: undefined,
                addr: undefined,
                question: undefined,
                intro: undefined,
                method: undefined,
                online: undefined,
                category_id: undefined,
                category: undefined,
                category_path: undefined,
                week: undefined,
                start_time: undefined,
                end_time: undefined,
                book_date: undefined,
                book_title: undefined,
                book_nums: undefined,
                is_feeback: undefined,
                feeback: undefined,
                rate: undefined,
                rate_text: undefined,
                status: undefined,
                check_time: undefined,
                check_reason: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery(e) {
            if (e && Object.keys(e).length > 0) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getRecord(id).then((response) => {
                this.form = response.data.data;
                this.form.book_date = this.unixTimestamptoDate(
                    this.form.book_date,
                    "yyyy-MM-dd"
                );
                this.form.check_time = this.unixTimestamptoDate(
                    this.form.check_time
                );

                this.open = true;
                this.title = "修改";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.form.book_date = this.DatetounixTimestamp(
                        this.form.book_date
                    );
                    this.form.check_time = this.DatetounixTimestamp(
                        this.form.check_time
                    );

                    if (this.form.id != null) {
                        editRecord(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addRecord(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;

            this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delRecord(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
    }, //methods结束
};
</script>
