<template>
  <div v-if="qrcodeLogin" class="scancode">
    <div class="semiangle-code" v-show="visible">
      <el-image
        @click="showCode"
        fit="cover "
        :src="require('@/assets/login_images/v2_rnzr0k.png')"
        class="semiangle-code__iamge"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  watch: {
    value: {
      handler() {
        this.visible = this.value != 4;
      },
    },
  },
  computed: {
    ...mapGetters({
      application: 'application/application',
    }),
    qrcodeLogin() {
      return this.application.signin_types && this.application.signin_types.indexOf('qrcode') >= 0
    }
  },
  methods: {
    showCode() {
      // this.$emit("input", "4");
    },
    hiddenCode() {
      this.visible = false;
      this.$emit("input", "1");
    },
    handleCancel() {
      this.hiddenCode();
    },
    
  },
};
</script>

<style lang="scss" scoped>
.animate__animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.semiangle-code {
  position: absolute;
  z-index: 1;
  right: 2px;
  bottom: -2px;
  &__iamge {
    width: 85px;
    height: 84px;
  }
}
.code {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  ::v-deep {
    .el-tabs {
      &__nav-scroll {
        width: 50%;
        margin: 0 auto;
      }
      &__nav {
        transform: translateX(50%) !important;
      }
    }
  }
  .code-content {
    margin-top: 30px;
    .code-img {
      width: 164px;
      height: 164px;
    }
    .text {
      color: #000;
      font-size: 16px;
      margin-top: 20px;
      letter-spacing: 1px;
    }
  }
  .cacel-btn {
    display: inherit;
    width: 280px;
    height: 42px;
    border-radius: 25px;
    margin-top: 5px;
    border: 0;
    margin-right: auto;
    margin-left: auto;
    letter-spacing: 0.3em;
    font-size: 15px;
    margin-top: 30px;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>