<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
			<!--<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="归属部门" prop="dept_id">
			<el-input
				v-model="queryParams.dept_id"
				placeholder="请输入归属部门"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>
		<el-form-item label="分类" prop="category">
			<dict-select
				v-model="queryParams.category"
				placeholder="请选择分类"
              	size="small"
				type="oa_task_category"
            />
		</el-form-item>-->
		<el-form-item label="类型" prop="type">
			<dict-select
				v-model="queryParams.type"
				placeholder="请选择类型"
              	size="small"
				type="oa_task_type"
            />
		</el-form-item>
        <el-form-item label="名称" prop="title">
            <el-input v-model="queryParams.title" placeholder="请输入名称" clearable size="small"
                @keyup.enter.native="handleQuery" />
        </el-form-item>
        <el-form-item label="派发性质" prop="distribute_type">
            <dict-select
                v-model="queryParams.distribute_type"
                placeholder="请选择派发性质"
                size="small"
                type="oa_task_distribute_type"
            />
        </el-form-item>
        <!--<el-form-item label="状态" prop="status">
        <dict-select
            v-model="queryParams.status"
            placeholder="请选择状态"
            size="small"
            type="oa_task_status"
        />
		</el-form-item>
		<el-form-item label="紧急程度" prop="emergent_level">
			<dict-select
				v-model="queryParams.emergent_level"
				placeholder="请选择紧急程度"
              	size="small"
				type="oa_task_emergent_level"
            />
		</el-form-item>
		<el-form-item label="重要程度" prop="important_level">
			<dict-select
				v-model="queryParams.important_level"
				placeholder="请选择重要程度"
              	size="small"
				type="oa_task_important_level"
            />
		</el-form-item>
		<el-form-item label="是否需要提交材料" prop="need_submit_file">
			<dict-select
				v-model="queryParams.need_submit_file"
				placeholder="请选择是否需要提交材料"
              	size="small"
				type="sys_yes_no"
            />
		</el-form-item>-->
			<el-form-item>
				<el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>

		<!-- <vxe-grid resizable ref="xGrid" row-id="id" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="dataList"
			@checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="[
						'/oa/oatask/add',
					]">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[
						'/oa/oatask/get',
						'/oa/oatask/edit',
					]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="[
						'/oa/oatask/del',
					]">删除</el-button>
			</template>

			<template #defaultopr="{ row }">
                <el-button size="mini" type="text" icon="el-icon-view" @click.stop="handleUpdate(row, true)">查看
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>

			<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>
		</vxe-grid>

		<vxe-pager background size="small" :loading="loading" :current-page.sync="queryParams.pageNum"
			:page-size.sync="queryParams.pageSize" :total="total" @page-change="handlePageChange">
		</vxe-pager> -->

    <jf-table
        :loading="loading"
        :columns="tableColumn"
        :data="dataList"
        :toolbar-config="tableToolbar"
        :query-params="queryParams"
        @checkbox-change="checkboxChangeEvent"
        @page-change="handlePageChange"
        :total="total"
        @change="tableChange"
        @handleQuery="handleQuery"
    >
        <template slot="toolbar_btn_left">
            <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
                v-hasPermi="['/oa/oatask/add']"
                >新增</el-button
            >

            <el-button
                type="success"
                icon="el-icon-edit"
                size="mini"
                :disabled="single"
                @click="handleUpdate"
                v-hasPermi="['/oa/oatask/get', '/oa/oatask/edit']"
                >修改</el-button
            >

            <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
                v-hasPermi="['/oa/oatask/del']"
                >删除</el-button
            >
        </template>
      
        <template slot="toolbar_btn_row" slot-scope="{ row }">
            <el-button size="mini" type="text" icon="el-icon-view" @click.stop="handleUpdate(row, true)">查看
            </el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click.stop="handleUpdate(row)"
                >修改
            </el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click.stop="handleDelete(row)"
                >删除
            </el-button>
        </template>
    </jf-table>

        <form-dialog ref="formDialog" @list-change="getList"></form-dialog>
	</div>
</template>
<script>
import {
	listOaTask,
	getOaTask,
	addOaTask,
	editOaTask,
	delOaTask,
} from "@/api/oa/oa_task";
import {
    listOaTaskRecord,
    getOaTaskRecord,
    approveOaTaskRecord
} from "@/api/oa/oa_task_record";
import FormDialog from "./form-dialog"
export default {
	name: "oa-task",
    components: {
        "form-dialog": FormDialog,
    },
	data() {
		return {
			tableToolbar: {
				perfect: false,
				zoom: true,
				custom: true,
				refresh: {
					query: this.handleQuery,
				},
				slots: {
					buttons: "toolbar_buttons",
				},
			},
			tableColumn: [
				{ type: "checkbox", width: 60, fixed: "left" },
				//{ field: "id", title: "id", minWidth: 120, fixed: "left" },
				//{ field: "dept_id", title: "归属部门", minWidth: 120 },
				//{ field: "category", title: "分类", minWidth: 120, formatter: this.dictFormat, dictType: 'oa_task_category' },
				{ field: "type", title: "类型", minWidth: 120, formatter: this.dictFormat, dictType: 'oa_task_type' },
				{ field: "title", title: "名称", minWidth: 120 },
				{ field: "content", title: "内容", minWidth: 120 },
                { field: "distribute_type", title: "派发性质", minWidth: 80, formatter: this.dictFormat, dictType: 'oa_task_distribute_type' },
				//{ field: "attachments", title: "附件", minWidth: 120 },
				//{ field: "status", title: "状态", minWidth: 120, formatter: this.dictFormat, dictType: 'oa_task_status' },
				//{ field: "emergent_level", title: "紧急程度", minWidth: 120, formatter: this.dictFormat, dictType: 'oa_task_emergent_level' },
				//{ field: "important_level", title: "重要程度", minWidth: 120, formatter: this.dictFormat, dictType: 'oa_task_important_level' },
				//{ field: "send_sms", title: "是否发送短信通知", minWidth: 120, formatter: this.dictFormat, dictType: 'sys_yes_no' },
                { field: "begin_date", title: "开始时间", minWidth: 120, formatter: 'formatDate' },
				//{ field: "view_closing_date", title: "阅读截止时间", minWidth: 120, formatter: ['formatDate', 'yyyy-MM-dd'] },
				//{ field: "send_myself", title: "是否发送给自己", minWidth: 120, formatter: this.dictFormat, dictType: 'sys_yes_no' },
				//{ field: "send_mydept", title: "是否发送给本部门", minWidth: 120, formatter: this.dictFormat, dictType: 'sys_yes_no' },
				//{ field: "need_submit_file", title: "是否需要提交材料", minWidth: 120, formatter: this.dictFormat, dictType: 'sys_yes_no' },
				//{ field: "submit_files_visible", title: "提交材料是否可见", minWidth: 120, formatter: this.dictFormat, dictType: 'sys_yes_no' },
				{ field: "submit_closing_date", title: "截止时间", minWidth: 120, formatter: 'formatDate' },
				//{ field: "expire_notify", title: "到期提醒", minWidth: 120, formatter: this.dictFormat, dictType: 'sys_yes_no' },
				//{ field: "need_position", title: "是否获取定位", minWidth: 120, formatter: this.dictFormat, dictType: 'sys_yes_no' },
				//{ field: "receive_type", title: "接收类型", minWidth: 120, formatter: this.dictFormat, dictType: 'oa_task_receive_type' },
				//{ field: "receive_depts", title: "接收部门", minWidth: 120 },
				//{ field: "receive_post", title: "接收岗位", minWidth: 120 },
				//{ field: "receive_roles", title: "接收角色", minWidth: 120 },
				//{ field: "receive_user_types", title: "接收用户类型", minWidth: 120 },
				{ field: "receive_num", title: "接收人数", minWidth: 120 },
				{ field: "transfer_num", title: "转派人数", minWidth: 120 },
				{ field: "read_num", title: "已读人数", minWidth: 120 },
				{ field: "submit_num", title: "提交人数", minWidth: 120 },
				//{ field: "finish_num", title: "完成人数", minWidth: 120 },
				//{ field: "reject_num", title: "拒绝人数", minWidth: 120 },
                //{ field: "approve_num", title: "评分人数", minWidth: 120 },
				{ field: "score", title: "分数", minWidth: 120 },
				//{ field: "remark", title: "备注信息", minWidth: 120, visible: false },
				{ field: "create_by", title: "创建者", minWidth: 120, visible: false },
				{ field: "create_time", title: "创建时间", minWidth: 120, formatter: "formatDate", visible: false },
				{ field: "update_by", title: "更新者", minWidth: 120, visible: false },
				{ field: "update_time", title: "更新时间", minWidth: 120, formatter: "formatDate" },
				{
					field: "",
					title: "操作",
					width: 180,
					fixed: "right",
					align: "center",
					slots: { default: "defaultopr" },
				},
			],
			loading: true,
			ids: [],
			single: true,
			multiple: true,
			total: 0,
			dataList: [],
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				id: undefined,
				dept_id: undefined,
				category: undefined,
				type: undefined,
				title: undefined,
				status: undefined,
				emergent_level: undefined,
				important_level: undefined,
				need_submit_file: undefined,
			}
		};
	},
	created() {
		for (let i = 0; i < this.tableColumn.length; i++) {
			const col = this.tableColumn[i]
			if (col.dictType) {
				this.getDicts(col.dictType).then(response => {
					if (response.code == 0) {
						col.dictData = response.data
						this.dataList = [].concat(this.dataList)
					}
				})
			}
		}
		this.getList();
	},
	methods: {
        tableChange(){},
		getList() {
			this.loading = true;
			this.ids = [];
			this.single = true;
			this.multiple = true;

			listOaTask(this.queryParams).then((response) => {
				this.dataList = response.data.list;
				this.total = response.data.total;
				this.loading = false;
			});
		},

		dictFormat(e) {
			const col = this.tableColumn[e.columnIndex]
			if (col.dictData) {
				const values = e.cellValue ? e.cellValue.split(',') : []
				const labels = []
				for (let i = 0; i < values.length; i++) {
					const v = values[i]
					for (let j = 0; j < col.dictData.values.length; j++) {
						const item = col.dictData.values[j]
						if (v == item.key) {
							labels.push(item.value)
							break
						}
					}
				}
				return labels.join(',')
			}
			return e.cellValue
		},

		handleQuery() {
			this.queryParams.pageNum = 1;
			this.getList();
		},

		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},

		handleAdd() {
            console.log(this.$refs.formDialog)
			this.$refs.formDialog.add();
		},

		handleUpdate(row, readonly) {
            this.$refs.formDialog.update(row.id, readonly);
		},

		handleDelete(row) {
			const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
			this.$confirm('确定删除吗?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(function () {
					return delOaTask(ids);
				})
				.then(() => {
					setTimeout(() => {
						this.getList();
					}, 300);
					this.msgSuccess("删除成功");
				})
				.catch(function () { });
		},

		checkboxChangeEvent({ records }) {
			this.ids = records.map((item) => item.id);
			this.single = records.length != 1;
			this.multiple = !records.length;
		},

		handlePageChange({ currentPage, pageSize }) {
			if (this.queryParams.pageSize == pageSize) {
				this.getList();
			} else {
				this.queryParams.pageSize = pageSize;
				this.handleQuery();
			}
		}
	}
};
</script>
