<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="名称" prop="device_name">
        <el-input
          v-model="queryParams.device_name"
          placeholder="请输入名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="IP地址" prop="ip_address">
        <el-input
          v-model="queryParams.ip_address"
          placeholder="请输入IP地址"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="状态" prop="conn_status">
        <el-select v-model="queryParams.conn_status" placeholder="状态" clearable size="small">
          <el-option
            v-for="dict in statusOptions"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="模型" prop="mould_id">
        <el-select v-model="queryParams.mould_id" placeholder="模型" clearable size="small">
          <el-option
            v-for="dict in mouldList"
            :key="dict.id"
            :label="dict.name"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row/>

    <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="deviceList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleGetInfo(row)"
        >详情
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
        >修改
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="device_name">
          <el-input v-model="form.device_name" placeholder="名称" />
        </el-form-item>
        <el-form-item label="IP地址" prop="ip_address">
          <el-input v-model="form.ip_address" placeholder="IP地址" />
        </el-form-item>
        <el-form-item label="状态" prop="conn_status">
          <el-select v-model="form.conn_status" placeholder="状态" clearable size="small">
            <el-option
              v-for="dict in statusOptions"
              :key="dict.key"
              :label="dict.value"
              :value="dict.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属模型" prop="mould_id">
          <el-select v-model="form.mould_id" placeholder="模型" clearable size="small">
            <el-option
              v-for="dict in mouldList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addIotDevice, getIotDevice, updateIotDevice, delIotDevice, listIotDevice } from "@/api/iot/device";

export default {
  name: "IotMaintain",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      // 列
      tableColumn: [
        {type: 'checkbox', width: 30, fixed: "left"},
        {field: 'id', title: '编号', width: 60, fixed: "left"},
        {field: 'device_name', title: '名称' },
        {field: 'ip_address', title: 'IP地址' },
        {field: 'client_id', title: 'ClientID' },
        {field: 'keep_alive', title: 'KeepAlive', width: 100 },
        {field: 'session_expiry', title: 'Session时效' },
        {field: 'version', title: '版本', width: 60},
        {field: 'mould_id', title: '模型',formatter:this.MouldFormat },
        {field: 'conn_status', title: '状态' , width: 60,formatter:this.TopicFormat },
        {field: 'connect_time', title: '连接时间' ,formatter:this.ConnectFormat},
        {field: 'disconnect_time', title: '离线时间' ,formatter:this.DisconnectFormat},
        {field: 'remark', title: '备注' },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 分类表格数据
      deviceList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      mouldList:[],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        device_name: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        mould_id: [
          { required: true, message: "模型不能为空", trigger: "blur" }
        ],
        device_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getList();
  },
  watch: {
    deviceList: {
      handler() {
        this.timer()
    },
    deep: true,
    immediate: true
    }
  },
  methods: {
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },

    /** 查询分类列表 */
    getList() {
      this.loading = true;
      listIotDevice(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;
        this.deviceList = response.data.list;
        this.mouldList = response.data.mouldList;
        this.total = response.data.total;
        this.statusOptions = response.data.statusOptions.values;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        mould_id: undefined,
        device_name: undefined,
        ip_address: undefined,
        conn_status: "0",
        usefor_des: undefined,
        remark: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加主题";
    },
    MouldFormat({row}){
      this.labname="";
      this.mouldList.forEach(element => {
        if (row.mould_id === element.id)
          this.labname= element.name;
      });
      return this.labname;
    },
    TopicFormat({row}){
      return this.selectDictLabel(this.statusOptions, row.conn_status + '');
    },
    ConnectFormat({row}){
      return this.timestampToTime(row.connect_time);
    },
    DisconnectFormat({row}){
      return this.timestampToTime(row.disconnect_time);
    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s
    },
    /** 修改按钮操作 */
    handleUpdate(row) {

      this.reset();
      const id = row.id || this.ids
      getIotDevice(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改主题";
      });
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
            updateIotDevice(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addIotDevice(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除主题编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotDevice(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    // 定时器
    timer () {
      return setTimeout(() => {
        listIotDevice(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;
        this.deviceList = response.data.list;
        this.mouldList = response.data.mouldList;
        this.total = response.data.total;
        this.statusOptions = response.data.statusOptions.values;
        this.loading = false;
      });
      }, 1000)
    }
  },
  destroyed () {
    clearTimeout(this.timer)
  }
};
</script>
